import { ReactComponent as IconDelete } from "@/assets/images/IconDelete.svg";
import { ReactComponent as IconEdit } from "@/assets/images/IconEdit.svg";
import { ReactComponent as IconPlus } from "@/assets/images/IconPlus.svg";
import { useConfirmModal } from "@/components/common/ConfirmModal/NewConfirmModal";
import DropdownAction, {
  Action,
} from "@/components/common/DropdownAction/DropdownAction";
import {
  TaskChecklistFieldsFragment,
  TaskFieldsFragment,
  TaskStatus,
} from "@/graphql";
import formatErrorMessage from "@/utils/formatErrorMessage";
import formatLabel from "@/utils/formatLabel";
import { EllipsisOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Store,
  message,
} from "@thepiquelab/archus-components-web";
import { isEqual } from "lodash";
import React, { useEffect, useState } from "react";
import {
  useCreateTaskChecklist,
  useDeleteTaskChecklist,
  useUpdateTaskChecklist,
} from "../hooks";
import ChecklistItemTable from "./ChecklistItemTable";
import ChecklistModal from "./ChecklistModal";
import EditableAssignee from "./EditableAssignee";

interface Props {
  task?: TaskFieldsFragment;
  callback?: () => void;
}

const ChecklistDetail: React.FC<Props> = (props) => {
  const { task, callback } = props;
  const isReadonly = [TaskStatus.Complete, TaskStatus.Void].includes(
    task?.status
  );
  const [form] = Form.useForm();
  const [createChecklist, { loading: createLoading }] =
    useCreateTaskChecklist();
  const [updateChecklist, { loading: updateLoading }] =
    useUpdateTaskChecklist();
  const [deleteChecklist, { loading: deleteLoading }] =
    useDeleteTaskChecklist();
  const { modal, show, close, setModalProps } = useConfirmModal();
  const [visible, setVisible] = useState<boolean>(false);
  const [selectedChecklistId, setSelectedChecklistId] = useState<string>();

  const handleCancel = (): void => {
    setVisible(false);
    setSelectedChecklistId(null);
    form.resetFields();
  };

  const handleSubmit = async (value: Store): Promise<void> => {
    try {
      const { name, assigneeIds } = value;
      if (selectedChecklistId) {
        await updateChecklist({
          variables: {
            input: {
              id: selectedChecklistId,
              name,
              assigneeIds,
            },
          },
        });
      } else {
        await createChecklist({
          variables: {
            input: {
              taskId: task?.id,
              name,
            },
          },
        });
      }
      handleCancel();
      callback?.();
    } catch (error) {
      message.error(formatErrorMessage(error));
    }
  };

  const handleDeleteChecklist = async (id: string): Promise<void> => {
    try {
      await deleteChecklist({
        variables: {
          id,
        },
      });
      close();
      callback?.();
    } catch (error) {
      message.error(formatErrorMessage(error));
    }
  };

  const editAction: Action = {
    icon: <IconEdit className="w-4" />,
    label: "Edit Checklist",
    onClick: (id: string) => {
      const checklist = task?.checklists?.find((l) => l.id === id);
      setSelectedChecklistId(id);
      form.setFieldsValue({ name: checklist?.name });
      setVisible(true);
    },
  };

  const deleteAction: Action = {
    icon: <IconDelete className="w-4" />,
    label: "Delete Checklist",
    onClick: (id: string) => {
      setModalProps({
        onConfirm: () => {
          handleDeleteChecklist(id);
        },
        onClose: close,
      });
      show();
    },
  };

  const renderChecklist = (
    checklist: TaskChecklistFieldsFragment
  ): React.ReactElement => {
    const { id, name, items, assignees } = checklist;

    return (
      <div>
        <div className="flex justify-between">
          <div className="font-semibold">{name}</div>
          <div className="flex gap-2 items-center">
            <EditableAssignee
              assignees={assignees}
              onEdit={() => {
                setSelectedChecklistId(id);
              }}
              onSave={(assigneeIds) => {
                const originalAssigneeIds = assignees?.map((item) => item?._id);
                if (!isEqual(assigneeIds, originalAssigneeIds))
                  handleSubmit({
                    assigneeIds,
                  });
              }}
              isReadonly={isReadonly}
            />
            {!isReadonly && (
              <DropdownAction id={id} actions={[editAction, deleteAction]}>
                <Button icon={<EllipsisOutlined />} />
              </DropdownAction>
            )}
          </div>
        </div>
        <ChecklistItemTable
          checklistId={id}
          items={items}
          isReadonly={isReadonly}
        />
      </div>
    );
  };

  useEffect(() => {
    setModalProps({ confirmLoading: deleteLoading });
  }, [deleteLoading]);

  if (!task) {
    return <></>;
  }

  return (
    <div>
      {!isReadonly && (
        <div className="flex justify-end">
          <Button
            icon={<IconPlus className="mr-2" />}
            onClick={() => {
              setVisible(true);
            }}
          >
            {formatLabel("Add Checklist")}
          </Button>
        </div>
      )}
      <div className="flex flex-col gap-4 mt-4">
        {task.checklists?.map(renderChecklist)}
      </div>
      <ChecklistModal
        title={formatLabel(
          selectedChecklistId ? "Edit Checklist" : "Add Checklist"
        )}
        visible={visible}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
        loading={createLoading || updateLoading}
        form={form}
        isEdit={!!selectedChecklistId}
      />
      {modal}
    </div>
  );
};

export default ChecklistDetail;
