import { Input, Row } from "@thepiquelab/archus-components-web";
import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";
import StartDateCalendar, {
  Props,
} from "../RegularModal/Calendar/StartDateCalendar";

interface WaitListProp extends Props {
  registrationStartDate: Date;
  onTextAreaChange: (v: string) => void;
  onDueDateChange: (v: Date) => void;
  hideRemarks?: boolean;
}

export const disabledDate = (
  currentDate: Moment,
  registrationStartDate: Date
): boolean => currentDate.isAfter(registrationStartDate, "day");

const WaitListModal: React.FC<WaitListProp> = ({
  registrationStartDate,
  availableDates,
  onSelect,
  onTextAreaChange,
  onDueDateChange,
  hideRemarks,
}) => {
  const [checkedDate, setCheckedDate] = useState(false);

  useEffect(() => {
    if (checkedDate) {
      handleDatePickerChange(moment(registrationStartDate));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registrationStartDate]);

  const handleDatePickerChange = (date: Moment): void => {
    onDueDateChange(date.toDate());
    if (!date.isSame(registrationStartDate, "day")) {
      setCheckedDate(!checkedDate);
    }
  };

  return (
    <div data-testid="WaitListModal">
      <Row>
        <StartDateCalendar
          availableDates={availableDates}
          onSelect={onSelect}
        />
      </Row>

      {!hideRemarks && (
        <>
          <Row className={"mt-4 font-bold"}>Remarks</Row>
          <Row>
            <Input.TextArea
              rows={2}
              allowClear
              onChange={(event) => onTextAreaChange(event.target.value)}
            />
          </Row>
        </>
      )}
    </div>
  );
};

export default WaitListModal;
