import { CommonCacheContext } from "@/components/common/CacheProvider";
import { FeatureAccessKeyword } from "@/graphql";
import { useContext, useMemo } from "react";

export enum KEYWORD_ENUM {
  "global_customise_table" = FeatureAccessKeyword.Customise,
  "global_filters" = FeatureAccessKeyword.Filters,
  "global_exports" = FeatureAccessKeyword.Exports,
  "global_rewards" = FeatureAccessKeyword.Rewards,
  "global_learning_arrangement" = FeatureAccessKeyword.LearningArrangement,
  "global_promotion_exercise" = FeatureAccessKeyword.Promotion,
  "global_whatsApp" = FeatureAccessKeyword.WhatsApp,
  "global_email" = FeatureAccessKeyword.Email,
  "global_replacement" = FeatureAccessKeyword.Replacement,
  "global_gap" = FeatureAccessKeyword.Gap,
  "global_hybrid_grouping" = FeatureAccessKeyword.HybridGrouping,
  "global_campaign" = FeatureAccessKeyword.Campaign,
  "global_report" = FeatureAccessKeyword.Report,
  "global_dashboard" = FeatureAccessKeyword.Dashboard,
  "global_logistics" = FeatureAccessKeyword.Logistics,
}

export const KEYWORDS = [
  KEYWORD_ENUM.global_customise_table,
  KEYWORD_ENUM.global_filters,
  KEYWORD_ENUM.global_exports,
  KEYWORD_ENUM.global_rewards,
  KEYWORD_ENUM.global_learning_arrangement,
  KEYWORD_ENUM.global_promotion_exercise,
  KEYWORD_ENUM.global_whatsApp,
  KEYWORD_ENUM.global_email,
  KEYWORD_ENUM.global_replacement,
  KEYWORD_ENUM.global_gap,
  KEYWORD_ENUM.global_hybrid_grouping,
  KEYWORD_ENUM.global_campaign,
  KEYWORD_ENUM.global_report,
  KEYWORD_ENUM.global_dashboard,
  KEYWORD_ENUM.global_logistics,
] as const;

export const useFeatureHook = (
  keywords: (typeof KEYWORDS)[number][]
): boolean => {
  const { enabledFeatureData } = useContext(CommonCacheContext);

  const features = useMemo(
    () => enabledFeatureData?.map((i) => i.keywords),
    [enabledFeatureData]
  );

  if (!enabledFeatureData) return true;

  return keywords.every((k) =>
    features?.includes(k as unknown as FeatureAccessKeyword)
  );
};
