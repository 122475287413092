import {
  ReminderType,
  TemplateStatus,
  TemplateType,
  TemplateUsageType,
} from "@/graphql";
import { Store } from "@thepiquelab/archus-components-web";
import { FormInstance } from "antd";
import { ControlType } from "braft-editor";
import Case from "case";
import { VariableNameArr } from "./TemplateVariableConfig";

export type PreviewModalProps = {
  subject?: string;
  type?: ReminderType;
  message?: string;
  title?: string;
};
export interface TemplateItem {
  id: string;
  whatsAppMessage?: string;
  title?: string;
  subject?: string;
  emailMessage?: string;
  content?: string;
  isArchived?: boolean;
  type?: TemplateType;
  status?: TemplateStatus;
  usageType?: TemplateUsageType;
}

export type MessageType = {
  whatsAppMessageHTML?: string;
  emailMessageHTML?: string;
  contentHTML?: string;
};

export interface TemplateFormProps {
  form: FormInstance;
  isEdit?: boolean;
  onFinish: (value: Store) => void;
  onPreview: (val: PreviewModalProps) => void;
  loading?: boolean;
  messages?: MessageType;
  onEdit?: (val: boolean) => void;
  onCancel?: (template: TemplateItem) => void;
  isArchived?: boolean;
  template?: TemplateItem;
}

export const controls: ControlType[] = [
  "undo",
  "redo",
  "headings",
  "bold",
  "italic",
  "underline",
  "strike-through",
  "list-ul",
  "list-ol",
  "emoji",
];

export const formatVariableName = (
  str?: string,
  isSerialize = true
): string => {
  if (!str) return "";

  let text = str;
  const reg = /\{\{.*?\}\}/g;

  const textArr = [...str.matchAll(reg)];
  if (!textArr) return "";

  textArr.forEach((item) => {
    if (
      !item[0]?.match(/\bif\b/g)?.length &&
      !item[0]?.match(/\belse\b/g)?.length &&
      !item[0]?.match(/\bwith\b/g)?.length &&
      !item[0]?.match(/\beach\b/g)?.length
    ) {
      const current = VariableNameArr.find(
        (i) => item[0] === `{{${isSerialize ? i.text : i.val}}}`
      );
      if (current)
        text = text.replace(
          item[0],
          `{{${isSerialize ? current?.val : current?.text}}}`
        );
    }
  });

  return text;
};
export const isUndefined = (val: string): string => {
  if (!val) {
    return undefined;
  }
  return val;
};

export const formatSingleVariableName = (str?: string): string[] => {
  if (!str) return [];
  const reg = /\{\{.*?\}\}/g;

  const textArr = [...str.matchAll(reg)];
  if (!textArr) return [];

  const names = textArr?.map((item) => {
    if (
      item[0]?.includes("undefined") ||
      item[0]?.includes("else") ||
      item[0]?.includes("/if") ||
      item[0]?.includes("/with") ||
      item[0]?.includes("/each") ||
      item[0]?.includes("#each") ||
      item[0]?.includes("#with")
    )
      return [];

    if (item[0]?.includes("if")) {
      return item[0]
        .replace(/[{{|}}]/g, "")
        ?.replaceAll("#", "")
        ?.replaceAll("if", "")
        ?.trim();
    }

    return Case.capital(item[0]?.replace(/[{{|}}]/g, "")?.trim() || "");
  });
  return [...new Set([...names?.flat(), "Parent Mobile Number"])];
};

export const formatTextBoldFromWhatsAppFormatToEmailFormat = (str?: string) => {
  if (!str) return "";
  let text = str;
  const reg = /\*.*?\*/g;
  const textArr = [...str?.matchAll(reg)];

  textArr.forEach((item) => {
    text = text.replace(
      item[0],
      `<strong>${item[0].replaceAll("*", "")}</strong>`
    );
  });
  return text;
};

export const formatFromWhatsAppFormatToEmailFormat = (str?: string) => {
  let text = str;
  text = formatTextBoldFromWhatsAppFormatToEmailFormat(str);
  text = prevWhatsAppMessage(text);
  text = addFontFamily(text);
  return text;
};

export const prevWhatsAppMessage = (str?: string, order = true): string => {
  if (order) {
    return str?.replace(/\n/g, "<br/>");
  }
  return str?.replace(/<br>/gi, "\n");
};

export const removeBraftEditorClassName = (className: string): void => {
  const dom: any = document.getElementsByClassName(className)[0];
  let classNames: string = dom.getAttribute("class");
  classNames = classNames?.replace("active", "");
  dom.setAttribute("class", classNames);
  dom.click();
};

export const formatTemplateBr = (str: string): string =>
  str?.replaceAll("<p></p>", "<br/>");

export const addFontFamily = (str?: string): string => {
  if (str) {
    return `<div style="font-family:Arial">${str}</div>`;
  }
  return undefined;
};
