import {
  MutationHookOptions,
  MutationTuple,
  QueryHookOptions,
  QueryResult,
  QueryTuple,
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import {
  CreateParent,
  CreateParentMutation,
  CreateParentMutationVariables,
  GenerateUserId,
  GenerateUserIdQueryVariables,
  GetAdmin,
  GetAdminQuery,
  GetAdminQueryVariables,
  GetAuditLog,
  GetAuditLogQuery,
  GetAuditLogQueryVariables,
  GetAuditLogs,
  GetAuditLogsQuery,
  GetAuditLogsQueryVariables,
  GetCurrentSystemUser,
  GetCurrentSystemUserQuery,
  GetCurrentSystemUserQueryVariables,
  GetEmailValidation,
  GetEmailValidationQuery,
  GetEmailValidationQueryVariables,
  GetExamScore,
  GetExamScoreQuery,
  GetExamScoreQueryVariables,
  GetOptionParents,
  GetOptionParentsQuery,
  GetOptionParentsQueryVariables,
  GetParent,
  GetParentQuery,
  GetParentQueryVariables,
  GetParents,
  GetParentsQuery,
  GetParentsQueryVariables,
  GetSearchParents,
  GetSearchParentsQuery,
  GetSearchParentsQueryVariables,
  GetStudent,
  GetStudentCell,
  GetStudentCellQuery,
  GetStudentCellQueryVariables,
  GetStudentQuery,
  GetStudentQueryVariables,
  GetStudents,
  GetStudentsQuery,
  GetStudentsQueryVariables,
  GetTeacher,
  GetTeacherQuery,
  GetTeacherQueryVariables,
  RecordExamScore,
  RecordExamScoreMutation,
  RecordExamScoreMutationVariables,
  RequestChangeSystemUserPassword,
  RequestChangeSystemUserPasswordMutation,
  RequestChangeSystemUserPasswordMutationVariables,
  StudentLinkParent,
  StudentLinkParentMutation,
  StudentLinkParentMutationVariables,
  UpdateExamScore,
  UpdateExamScoreMutation,
  UpdateExamScoreMutationVariables,
  UpdateParent,
  UpdateParentMutation,
  UpdateParentMutationVariables,
  UpdateRelationName,
  UpdateRelationNameMutation,
  UpdateRelationNameMutationVariables,
  UpdateStudent,
  UpdateStudentMutation,
  UpdateStudentMutationVariables,
  UpdateStudentRemarks,
  UpdateStudentRemarksMutation,
  UpdateStudentRemarksMutationVariables,
} from "../../../graphql";

export const useQueryParent = (id: string): QueryResult<GetParentQuery> =>
  useQuery<GetParentQuery, GetParentQueryVariables>(GetParent, {
    variables: { id },
    fetchPolicy: "network-only",
  });

export const useQueryParents = (
  options?: QueryHookOptions<GetParentsQuery, GetParentsQueryVariables>
): QueryResult<GetParentsQuery, GetParentsQueryVariables> =>
  useQuery<GetParentsQuery, GetParentsQueryVariables>(GetParents, {
    variables: {},
    ...options,
  });

export const useLazyQueryParent = (): QueryTuple<
  GetParentQuery,
  GetParentQueryVariables
> => useLazyQuery<GetParentQuery, GetParentQueryVariables>(GetParent);

export const useLazyQueryParents = (): QueryTuple<
  GetParentsQuery,
  GetParentsQueryVariables
> =>
  useLazyQuery<GetParentsQuery, GetParentsQueryVariables>(GetParents, {
    fetchPolicy: "no-cache",
  });

export const useLazySearchParents = (): QueryTuple<
  GetSearchParentsQuery,
  GetSearchParentsQueryVariables
> =>
  useLazyQuery<GetSearchParentsQuery, GetSearchParentsQueryVariables>(
    GetSearchParents,
    {
      fetchPolicy: "cache-and-network",
    }
  );

export const useCreateParent = (): MutationTuple<
  CreateParentMutation,
  CreateParentMutationVariables
> =>
  useMutation<CreateParentMutation, CreateParentMutationVariables>(
    CreateParent,
    {
      refetchQueries: [
        {
          query: GetParents,
        },
      ],
    }
  );

export const useUpdateParent = (
  id?: string
): MutationTuple<UpdateParentMutation, UpdateParentMutationVariables> =>
  useMutation<UpdateParentMutation, UpdateParentMutationVariables>(
    UpdateParent,
    { refetchQueries: [{ query: GetParent, variables: { id } }] }
  );

export const useStudentLinkParent = (): MutationTuple<
  StudentLinkParentMutation,
  StudentLinkParentMutationVariables
> =>
  useMutation<StudentLinkParentMutation, StudentLinkParentMutationVariables>(
    StudentLinkParent,
    {
      refetchQueries: [{ query: GetStudents }],
    }
  );

export const useUpdateRelationName = (): MutationTuple<
  UpdateRelationNameMutation,
  UpdateRelationNameMutationVariables
> =>
  useMutation<UpdateRelationNameMutation, UpdateRelationNameMutationVariables>(
    UpdateRelationName
  );

export const useLazyQueryStudents = (): QueryTuple<
  GetStudentsQuery,
  GetStudentsQueryVariables
> =>
  useLazyQuery<GetStudentsQuery, GetStudentsQueryVariables>(GetStudents, {
    fetchPolicy: "no-cache",
  });

export const useQueryStudent = (id: string): QueryResult<GetStudentQuery> =>
  useQuery<GetStudentQuery, GetStudentQueryVariables>(GetStudent, {
    variables: { id },
  });

export const useQueryStudentCell = (
  id: string
): QueryResult<GetStudentCellQuery> =>
  useQuery<GetStudentCellQuery, GetStudentCellQueryVariables>(GetStudentCell, {
    variables: { id },
  });

export const useLazyQueryStudent = (): QueryTuple<
  GetStudentQuery,
  GetStudentQueryVariables
> =>
  useLazyQuery<GetStudentQuery, GetStudentQueryVariables>(GetStudent, {
    fetchPolicy: "network-only",
  });

/**
 * Hook for Updating student remarks mutation
 */
export const useUpdateStudentRemarks = (
  options?: MutationHookOptions<
    UpdateStudentRemarksMutation,
    UpdateStudentRemarksMutationVariables
  >
): MutationTuple<
  UpdateStudentRemarksMutation,
  UpdateStudentRemarksMutationVariables
> =>
  useMutation<
    UpdateStudentRemarksMutation,
    UpdateStudentRemarksMutationVariables
  >(UpdateStudentRemarks, options);

export const useUpdateStudent = (
  id?: string
): MutationTuple<UpdateStudentMutation, UpdateStudentMutationVariables> =>
  useMutation<UpdateStudentMutation, UpdateStudentMutationVariables>(
    UpdateStudent,
    {
      refetchQueries: id ? [{ query: GetStudent, variables: { id } }] : [],
    }
  );

export const useQueryTeacher = (id: string): QueryResult<GetTeacherQuery> =>
  useQuery<GetTeacherQuery, GetTeacherQueryVariables>(GetTeacher, {
    variables: { id },
    fetchPolicy: "network-only",
  });

export const useLazyQueryTeacher = (): QueryTuple<
  GetTeacherQuery,
  GetTeacherQueryVariables
> => useLazyQuery<GetTeacherQuery, GetTeacherQueryVariables>(GetTeacher);

export const useQueryAdmin = (id: string): QueryResult<GetAdminQuery> =>
  useQuery<GetAdminQuery, GetAdminQueryVariables>(GetAdmin, {
    variables: { id },
  });

export const useCurrentSystemUser = (
  options?: QueryHookOptions<
    GetCurrentSystemUserQuery,
    GetCurrentSystemUserQueryVariables
  >
): QueryResult<GetCurrentSystemUserQuery> =>
  useQuery<GetCurrentSystemUserQuery, GetCurrentSystemUserQueryVariables>(
    GetCurrentSystemUser,
    options
  );

export const useLazyQueryAdmin = (): QueryTuple<
  GetAdminQuery,
  GetAdminQueryVariables
> => useLazyQuery<GetAdminQuery, GetAdminQueryVariables>(GetAdmin);

export const useRecordStudentExamScore = (): MutationTuple<
  RecordExamScoreMutation,
  RecordExamScoreMutationVariables
> =>
  useMutation<RecordExamScoreMutation, RecordExamScoreMutationVariables>(
    RecordExamScore
  );

export const useUpdateStudentExamScore = (): MutationTuple<
  UpdateExamScoreMutation,
  UpdateExamScoreMutationVariables
> =>
  useMutation<UpdateExamScoreMutation, UpdateExamScoreMutationVariables>(
    UpdateExamScore
  );

export const useQueryExamScores = (
  id: string
): QueryResult<GetExamScoreQuery> =>
  useQuery<GetExamScoreQuery, GetExamScoreQueryVariables>(GetExamScore, {
    variables: { id },
  });

export const useQueryGenerateUserId = () => {
  const client = useApolloClient();
  return (variables: GenerateUserIdQueryVariables) =>
    client.query({
      query: GenerateUserId,
      fetchPolicy: "network-only",
      variables,
    });
};

export const useQueryAuditLog = (id: string) =>
  useQuery<GetAuditLogQuery, GetAuditLogQueryVariables>(GetAuditLog, {
    variables: { id },
  });

export const useQueryAuditLogs = () => {
  useQuery<GetAuditLogsQuery, GetAuditLogsQueryVariables>(GetAuditLogs);
};

export const useLazyQueryAuditLog = () => {
  useLazyQuery<GetAuditLogsQuery, GetAuditLogQueryVariables>(GetAuditLog);
};

export const useLazyQueryAuditLogs = () =>
  useLazyQuery<GetAuditLogsQuery, GetAuditLogsQueryVariables>(GetAuditLogs);

export const useLazyQueryValidateEmail = (
  callback?: (data: GetEmailValidationQuery) => void
) =>
  useLazyQuery<GetEmailValidationQuery, GetEmailValidationQueryVariables>(
    GetEmailValidation,
    { onCompleted: callback }
  );

export const useLazyQueryOptionParents = (): QueryTuple<
  GetOptionParentsQuery,
  GetOptionParentsQueryVariables
> =>
  useLazyQuery<GetOptionParentsQuery, GetOptionParentsQueryVariables>(
    GetOptionParents
  );

export const useChangeAdminPassword = (): MutationTuple<
  RequestChangeSystemUserPasswordMutation,
  RequestChangeSystemUserPasswordMutationVariables
> =>
  useMutation<
    RequestChangeSystemUserPasswordMutation,
    RequestChangeSystemUserPasswordMutationVariables
  >(RequestChangeSystemUserPassword);
