import { useWithoutTeacherOptions } from "@/components/widget/FilterFormOption/hooks/useWithoutTeacherOptions";
import { Select } from "@thepiquelab/archus-components-web";
import React from "react";
import AssigneeAvatar from "../TaskDetail/AssigneeAvatar";

const { Option } = Select;

interface AssigneeSelectProps {
  value?: string[];
  onChange?: (value: string[]) => void;
  onBlur?: () => void;
  disabled?: boolean;
  autoFocus?: boolean;
}

const AssigneeSelect: React.FC<AssigneeSelectProps> = (props) => {
  const { value, onChange, onBlur, disabled, autoFocus = true } = props;
  const { users } = useWithoutTeacherOptions();

  return (
    <Select
      className="min-w-24"
      disabled={disabled}
      mode="multiple"
      size="large"
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      optionLabelProp="label"
      showSearch
      autoFocus={autoFocus}
      filterOption={(input, option) =>
        ((option?.fullName as string) ?? "")
          ?.toLowerCase()
          ?.includes(input?.toLowerCase())
      }
      dropdownMatchSelectWidth={false}
      maxTagCount={3}
    >
      {users.map((admin) => (
        <Option
          key={admin._id}
          value={admin._id}
          label={<AssigneeAvatar assignee={admin} />}
          fullName={admin.fullName}
        >
          <AssigneeAvatar assignee={admin} />
          <span className="ml-2">{admin.fullName}</span>
        </Option>
      ))}
    </Select>
  );
};

export default AssigneeSelect;
