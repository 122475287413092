import { AdvanceTableColumnType } from "@/components/common/AdvanceTable/AdvanceTable";
import {
  EnumEnhancedTableWidthType,
  enumEnhancedTableWidthType,
} from "@/components/common/EnhancedTable/EnhancedTable";
import ClassLink from "@/components/widget/CommonLink/ClassLink";
import { TaskFieldsFragment, TaskType } from "@/graphql";
import formatLabel from "@/utils/formatLabel";
import { formatDate } from "@thepiquelab/archus-library";
import TaskTable, { CustomCommonColumn } from "./TaskTable";
import { TaskTableProps } from "./types";

const AbsentFollowUpTask: React.FC<TaskTableProps> = (props) => {
  const { data, callback, visible, pagination, count, loading } = props;

  const columns: AdvanceTableColumnType[] = [
    CustomCommonColumn.StudentName,
    CustomCommonColumn.StudentID,
    CustomCommonColumn.PrimaryParentName,
    CustomCommonColumn.PrimaryParentMobile,
    CustomCommonColumn.PrimaryParentWALine,
    {
      title: formatLabel("Class Shorthand"),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(value, record: TaskFieldsFragment) {
        const { attendance } = record;
        const { id: classId, shorthand } = attendance?.class || {};
        return <ClassLink classId={classId}>{shorthand}</ClassLink>;
      },
    },
    {
      title: formatLabel("Lesson Date"),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(value, record: TaskFieldsFragment) {
        return formatDate(record?.attendance?.lesson?.lessonStart, {
          showDayOfWeek: true,
        });
      },
    },
    {
      title: formatLabel("Lesson Number"),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(value, record: TaskFieldsFragment) {
        return `Lesson No. ${record?.attendance?.lesson?.lessonNumber}`;
      },
    },
    {
      title: formatLabel("Attendance Status"),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(value, record: TaskFieldsFragment) {
        const { attendance } = record;
        return <>{formatLabel(attendance?.status || "")}</>;
      },
    },
  ];
  if (!visible) {
    return <></>;
  }

  return (
    <div>
      <TaskTable
        data={data}
        loading={loading}
        count={count}
        pagination={{
          ...pagination,
        }}
        columns={columns}
        title={formatLabel("Absent Follow Up")}
        taskType={TaskType.FollowUpAbsent}
        callback={callback}
      />
    </div>
  );
};

export default AbsentFollowUpTask;
