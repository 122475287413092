import { useCheckInteger } from "@/utils/checkAmount";
import { Input } from "@thepiquelab/archus-components-web";
import { InputProps } from "antd";
import { FC } from "react";

interface IntegerInputProps {
  onChange?: (val: string) => void;
  className?: string;
  disabled?: boolean;
  type?: "number" | "string";
  value?: string | number;
}
const IntegerInput: FC<IntegerInputProps & InputProps> = ({
  onChange,
  className,
  disabled,
  type = "string",
  value,
  ...res
}) => {
  const [amountValue, onChangeValue] = useCheckInteger(
    value || value === 0 ? String(value) : undefined
  );
  return (
    <Input
      {...res}
      value={amountValue === "undefined" ? undefined : amountValue}
      disabled={disabled}
      className={className}
      autoComplete="off"
      onChange={(value) => {
        onChangeValue(value?.target?.value, onChange);
      }}
      data-testid="integer-input"
    />
  );
};

export default IntegerInput;
