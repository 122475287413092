import React from "react";
import { Link } from "react-router-dom";

interface UserRequestLinkProps {
  id: string;
  linkText?: string;
}
const UserRequestLink: React.FC<UserRequestLinkProps> = ({
  id,
  linkText,
  children,
}) => {
  if (!id) return <>{children}</>;

  const linkUrl = `/parent-centre/arrangement-requests/${id?.trim()}`;
  return (
    <Link
      to={linkUrl}
      target={"_blank"}
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <span className="font_semibold text-primary-blue">
        {linkText || children}
      </span>
    </Link>
  );
};

export default UserRequestLink;
