import { TelephoneType } from "@/components/common/Profile/types";
import { CoreServiceApiClient } from "../clients/CoreServiceApiClient";
import { UserServiceApiClient } from "../clients/UserServiceApiClient";
import { Page, PageQuery } from "../types";
import {
  FilterOptionSystemUserDTO,
  FilterOptionTeacherDTO,
  UserRole,
  UserRoleFilter,
} from "./types";

export class UserApi {
  private static client = UserServiceApiClient;

  private static coreServiceClient = CoreServiceApiClient;

  private static userPathPrefix = "/v1/system-users";

  private static rolePathPrefix = "/v1/user-roles";

  private static filterOptionPathPrefix = "/v1/filter-options";

  public static async me(): Promise<any> {
    return this.client.get<any, any>(`${this.userPathPrefix}/me`);
  }

  public static async syncRole(): Promise<void> {
    return this.client.put(`${this.userPathPrefix}/me/sync-role`);
  }

  public static async isMobileUnique(data: {
    mobilePhone: TelephoneType;
    id?: string;
  }): Promise<any> {
    return this.coreServiceClient.post<
      {
        mobilePhone: TelephoneType;
        id?: string;
      },
      any
    >("/isMobilePhoneUnique", data);
  }

  public static async isUserIdExists(data: {
    userId: string;
    alternateUserId?: string;
    id?: string;
  }): Promise<any> {
    return this.coreServiceClient.post<
      {
        userId: string;
        alternateUserId?: string;
        id?: string;
      },
      any
    >("/isUserIdExists", data);
  }

  public static async getRoles(
    filter: UserRoleFilter = {},
    pageQuery: PageQuery = {}
  ): Promise<Page<UserRole>> {
    const { name } = filter;
    return this.client.get(`${this.rolePathPrefix}`, {
      name,
      ...pageQuery,
    });
  }

  public static async getTeacherOptions(): Promise<FilterOptionTeacherDTO[]> {
    const response = await this.client.get<null, FilterOptionTeacherDTO[]>(
      `${this.filterOptionPathPrefix}/teachers`
    );
    return response;
  }

  public static async getWithoutTeacherOptions(): Promise<
    FilterOptionSystemUserDTO[]
  > {
    const response = await this.client.get<null, FilterOptionSystemUserDTO[]>(
      `${this.filterOptionPathPrefix}/without-teachers`
    );
    return response;
  }
}
