import { AppConfig } from "@/config";
import { useRef, useState } from "react";

import {
  Registration,
  SubscriptionData,
  SubscriptionType,
} from "@/apis/enrollment/types";
import { useCurrentUser } from "@/hooks/useCurrentUser";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { EventSourcePolyfill } from "event-source-polyfill";

const useRegistrationEventSource = () => {
  const { getToken } = useCurrentUser();
  const evtSource = useRef<EventSource>();
  const [data, setData] = useState<Registration>();

  const initEventSource = async () => {
    if (!evtSource.current) {
      const authorization = await getToken();
      const url = `${AppConfig.EnrollmentServiceUrl}/v1/subscriptions`;

      evtSource.current = new EventSourcePolyfill(url, {
        headers: {
          Authorization: `Bearer ${authorization}`,
        },
        heartbeatTimeout: 1000 * 60 * 60 * 24 * 30 * 6,
      });
      evtSource.current.onmessage = (e) => {
        const res: string = e?.data || "";
        if (res.includes(`"type":`)) {
          const subscriptionData: SubscriptionData<Registration> =
            JSON.parse(res);

          if (subscriptionData.type === SubscriptionType.Registration) {
            setData(subscriptionData.data);
          }
        }
      };
    }
  };

  return {
    data,
    initEventSource,
  };
};

export default useRegistrationEventSource;
