import { useAuth0 } from "@/Auth0Wrapper";

export function useCurrentUser(): {
  dbUser: any;
  getToken: () => Promise<string>;
} {
  const { getToken, dbUser } = useAuth0();

  return {
    dbUser,
    getToken,
  };
}
