import { AdvanceTableColumnType } from "@/components/common/AdvanceTable/AdvanceTable";
import {
  EnumEnhancedTableWidthType,
  enumEnhancedTableWidthType,
} from "@/components/common/EnhancedTable/EnhancedTable";
import { requestStatusMap } from "@/components/parent-centre/UserRequestDetail/RequestStatusTag";
import ClassLink from "@/components/widget/CommonLink/ClassLink";
import UserRequestLink from "@/components/widget/CommonLink/UserRequestLink";
import { TaskIndexFieldsFragment, TaskType } from "@/graphql";
import formatLabel from "@/utils/formatLabel";
import { formatDate } from "@thepiquelab/archus-library";
import Case from "case";
import TaskTable, { CustomCommonColumn } from "./TaskTable";
import { TaskTableProps } from "./types";

const TrialRequestFollowUpTask: React.FC<TaskTableProps> = (props) => {
  const { data, callback, visible, pagination, count, loading } = props;

  const columns: AdvanceTableColumnType[] = [
    CustomCommonColumn.StudentName,
    CustomCommonColumn.StudentID,
    CustomCommonColumn.PrimaryParentName,
    CustomCommonColumn.PrimaryParentMobile,
    CustomCommonColumn.PrimaryParentWALine,
    {
      title: formatLabel("Class Shorthand"),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(value, record: TaskIndexFieldsFragment) {
        const { id: classId, shorthand } = record?.class || {};

        return <ClassLink classId={classId}>{shorthand}</ClassLink>;
      },
    },
    {
      title: formatLabel("Request Number"),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(value, record: TaskIndexFieldsFragment) {
        const { userRequest } = record;
        if (userRequest?.referenceNumber)
          return (
            <UserRequestLink id={userRequest._id}>
              {userRequest?.referenceNumber}
            </UserRequestLink>
          );
        return "-";
      },
    },
    {
      title: formatLabel("Preferred Trial Date"),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(value, record: TaskIndexFieldsFragment) {
        const { userRequest } = record;
        return (
          <>
            {userRequest?.startDate ? formatDate(userRequest?.startDate) : "-"}
          </>
        );
      },
    },
    {
      title: formatLabel("Request Status"),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(value, record: TaskIndexFieldsFragment) {
        const { userRequest } = record;
        return (
          <div
            style={{
              color: requestStatusMap?.get(Case.upper(userRequest?.status))
                ?.color,
            }}
          >
            {userRequest?.status || "-"}{" "}
          </div>
        );
      },
    },
  ];
  if (!visible) {
    return <></>;
  }

  return (
    <div>
      <TaskTable
        data={data}
        loading={loading}
        count={count}
        columns={columns}
        title={formatLabel("Trial Request Follow Up")}
        taskType={TaskType.FollowUpTrialRequest}
        callback={callback}
        pagination={{
          ...pagination,
        }}
      />
    </div>
  );
};

export default TrialRequestFollowUpTask;
