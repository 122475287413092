export const formatLocation = (
  branch: {
    name: string;
    alias: string;
  },
  venue: {
    shorthand: string;
    unitNo?: string;
  }
): string => {
  if (!venue) return null;
  return `${branch?.alias || ""} ${formatVenueName(venue)}`;
};

export const formatVenueName = (venue: {
  shorthand: string;
  unitNo?: string;
}): string => {
  if (!venue) {
    return null;
  }
  const { shorthand, unitNo } = venue;
  if (unitNo) {
    return `#${unitNo} ${shorthand}`;
  }
  return shorthand || "";
};
