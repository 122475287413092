/* eslint-disable max-lines */
import { TemplateUsageType } from "@/graphql";
import { uniqBy } from "lodash";

type TemplateVariableConfigType = {
  [key in TemplateUsageType]?: {
    val: string;
    text: string;
    isHelper?: boolean;
  }[];
};

const ifHelperGenerator = (
  variableName: string
): { val: string; text: string; isHelper?: boolean } => ({
  text: `if ${variableName}`,
  val: `{{#if ${variableName}}}\n\n{{else}}\n\n{{/if}}`,
  isHelper: true,
});

const ParentPreferredName = {
  val: "ParentPreferredName",
  text: "Parent Preferred Name",
};
const StudentPreferredName = {
  val: "StudentPreferredName",
  text: "Student Preferred Name",
};
const TotalFee = {
  val: "TotalFee",
  text: "Total Fee",
};
const TotalAmountPayable = {
  val: "TotalAmountPayable",
  text: "Total Amount Payable",
};
const ClassTeacher = {
  val: "ClassTeacher",
  text: "Class Teacher",
};
const CourseLevel = {
  val: "CourseLevel",
  text: "Course Level",
};
const ClassLessonDay = {
  val: "ClassLessonDay",
  text: "Class Lesson Day",
};
const ClassLessonDuration = {
  val: "ClassLessonDuration",
  text: "Class Lesson Duration",
};
const ClassLearningArrangement = {
  val: "ClassLearningArrangement",
  text: "Class Learning Arrangement",
};
const FirstLessonDateAfterTrialDate = {
  val: "FirstLessonDateAfterTrialDate",
  text: "First Lesson Date After Trial Date",
};
const AmountRefunded = {
  val: "AmountRefunded",
  text: "Amount Refunded",
};
const TotalRefund = {
  val: "TotalRefund",
  text: "Total Refund",
};
const AdminFee = {
  val: "AdminFee",
  text: "Admin Fee",
};
const RefundDate = {
  val: "RefundDate",
  text: "Refund Date",
};
const StudentName = {
  val: "StudentName",
  text: "Student Name",
};
const CourseShorthand = {
  val: "CourseShorthand",
  text: "Course Shorthand",
};
const ClassRunNumber = {
  val: "ClassRunNumber",
  text: "Class Run Number",
};
const AmountPaid = {
  val: "AmountPaid",
  text: "Amount Paid",
};
const ParentName = {
  val: "ParentName",
  text: "Parent Name",
};
const AmountPaidByBalance = {
  val: "AmountPaidByBalance",
  text: "Amount Paid by Balance",
};
const PaymentStatus = {
  val: "PaymentStatus",
  text: "Payment Status",
};
const TotalAmountPaid = {
  val: "TotalAmountPaid",
  text: "Total Amount Paid",
};
const AcademicYear = {
  val: "AcademicYear",
  text: "Academic Year",
};
const CourseName = {
  val: "CourseName",
  text: "Course Name",
};
const PerLessonFee = {
  val: "PerLessonFee",
  text: "Per Lesson Fee",
};
const LessonPeriod = {
  val: "LessonPeriod",
  text: "Lesson Period",
};
const Subject = {
  val: "Subject",
  text: "Subject",
};
const ReplacementCreditsLeft = {
  val: "ReplacementCreditsLeft",
  text: "Replacement Credits Left",
};
const LessonDay = {
  val: "LessonDay",
  text: "Lesson Day",
};
const LessonDate = {
  val: "LessonDate",
  text: "Lesson Date",
};
const LessonDateLong = {
  val: "LessonDateLong",
  text: "Lesson Date Long",
};
const LessonDuration = {
  val: "LessonDuration",
  text: "Lesson Duration",
};
const LessonTeacher = {
  val: "LessonTeacher",
  text: "Lesson Teacher",
};
const OriginalLessonDay = {
  val: "OriginalLessonDay",
  text: "Original Lesson Day",
};
const PreviousLessonVenue = {
  val: "PreviousLessonVenue",
  text: "Previous Lesson Venue",
};
const OriginalLessonDate = {
  val: "OriginalLessonDate",
  text: "Original Lesson Date",
};
const OriginalLessonDuration = {
  val: "OriginalLessonDuration",
  text: "Original Lesson Duration",
};
const OriginalLessonTeacher = {
  val: "OriginalLessonTeacher",
  text: "Original Lesson Teacher",
};
const ZoomMeetingID = {
  val: "ZoomMeetingID",
  text: "Zoom Meeting ID",
};
const ZoomMeetingPassCode = {
  val: "ZoomMeetingPassCode",
  text: "Zoom Meeting Passcode",
};
const Term = {
  val: "Term",
  text: "Term",
};
const WorkshopClassSessions = {
  val: "WorkshopClassSessions",
  text: "Workshop Class Sessions",
};
const NumberOfReplacementLessonsArrangedWithinTerm = {
  val: "NumberOfReplacementLessonsArrangedWithinTerm",
  text: "Number of Replacement Lessons Arranged Within Term",
};
const LessonVenue = {
  val: "LessonVenue",
  text: "Lesson Venue",
};

const AdditionalLessonDate = {
  val: "AdditionalLessonDate",
  text: "Additional Lesson Date",
};
const AdditionalLessonFee = {
  val: "AdditionalLessonFee",
  text: "Additional Lesson Fee",
};
const TrialDate = {
  val: "TrialDate",
  text: "Trial Date",
};
const TrialFee = {
  val: "TrialFee",
  text: "Trial Fee",
};
const RegistrationFee = {
  val: "RegistrationFee",
  text: "Registration Fee",
};
const ReservationClassLessonDay = {
  val: "ReservationClassLessonDay",
  text: "Reservation Class Lesson Day",
};
const ReservationClassLessonDuration = {
  val: "ReservationClassLessonDuration",
  text: "Reservation Class Lesson Duration",
};
const ReservationDate = {
  val: "ReservationDate",
  text: "Reservation Date",
};
const ReservationClassLearningArrangement = {
  val: "ReservationClassLearningArrangement",
  text: "Reservation Class Learning Arrangement",
};
const ReservationClassTeacher = {
  val: "ReservationClassTeacher",
  text: "Reservation Class Teacher",
};
const StudentAddress = {
  val: "StudentAddress",
  text: "Student Address",
};
const LessonMaterials = {
  val: "LessonMaterials",
  text: "Lesson Materials",
};
const LastLessonDate = {
  val: "LastLessonDate",
  text: "Last Lesson Date",
};
const FirstLessonDate = {
  val: "FirstLessonDate",
  text: "First Lesson Date",
};
const SessionDates = {
  val: "SessionDates",
  text: "Session Dates",
};
const SessionDays = {
  val: "SessionDays",
  text: "Session Days",
};
const HolidayType = {
  val: "HolidayType",
  text: "Holiday Type",
};
const SessionDuration = {
  val: "SessionDuration",
  text: "Session Duration",
};
const WorkshopSessionNumber = {
  val: "WorkshopSessionNumber",
  text: "Workshop Session Number",
};
const Branch = {
  val: "Branch",
  text: "Branch",
};
const DateToSkip = {
  val: "DateToSkip",
  text: "Date to Skip",
};
const WorkshopLessonDay = {
  val: "WorkshopLessonDay",
  text: "Workshop Lesson Day",
};
const WorkshopLessonDate = {
  val: "WorkshopLessonDate",
  text: "Workshop Lesson Date",
};
const CourseMaterial = {
  val: "CourseMaterial",
  text: "Course Material",
};
const LessonNote = {
  val: "LessonNote",
  text: "Lesson Note",
};
const LessonClassroom = {
  val: "LessonClassroom",
  text: "Lesson Classroom",
};
const AttendanceType = {
  val: "AttendanceType",
  text: "Attendance Type",
};
const AttendanceLearningArrangement = {
  val: "AttendanceLearningArrangement",
  text: "Attendance Learning Arrangement",
};
const LearningResourceLink = {
  val: "LearningResourceLink",
  text: "Learning Resource Link",
};
const TeacherSharedWhatsAppNumber = {
  val: "TeacherSharedWhatsAppNumber",
  text: "Teacher Shared WhatsApp Number",
};
const AvailableMULessonList = {
  val: "AvailableMULessonList",
  text: "Available MU Lesson List",
};

const InvoiceDueDateAndTime = {
  val: "InvoiceDueDateAndTime",
  text: "Invoice Due Date and Time",
};
const NextDate = {
  val: "NextDate",
  text: "Next Date",
};
const Holiday = {
  val: "Holiday",
  text: "Holiday",
};
const LessonAvailableReplacementSeat = {
  val: "LessonAvailableReplacementSeat",
  text: "Lesson Available Replacement Seat",
};
const LessonAvailableRegularSeat = {
  val: "LessonAvailableRegularSeat",
  text: "Lesson Available Regular Seat",
};
const WaitingListClassList = {
  val: "WaitingListClassList",
  text: "Waiting List Class List",
};
const AvailableClassList = {
  val: "AvailableClassList",
  text: "Available Class List",
};
const PreviousLessonTeacher = {
  val: "PreviousLessonTeacher",
  text: "Previous Lesson Teacher",
};

const OriginalClassLessonDay = {
  val: "OriginalClassLessonDay",
  text: "Original Class Lesson Day",
};
const OriginalClassTeacher = {
  val: "OriginalClassTeacher",
  text: "Original Class Teacher",
};
const OriginalClassTeacherLinkedWhatsAppNumber = {
  val: "OriginalClassTeacherLinkedWhatsAppNumber",
  text: "Original Class Teacher Linked WhatsApp Number",
};
const OriginalClassLearningArrangement = {
  val: "OriginalClassLearningArrangement",
  text: "Original Class Learning Arrangement",
};
const OriginalClassLessonDuration = {
  val: "OriginalClassLessonDuration",
  text: "Original Class Lesson Duration",
};
const HolidayName = {
  val: "HolidayName",
  text: "Holiday Name",
};
const HolidayDate = {
  val: "HolidayDate",
  text: "Holiday Date",
};
const GamePlanLink = {
  val: "GamePlanLink",
  text: "Game Plan Link",
};
const TeacherLinkedWhatsAppNumber = {
  val: "TeacherLinkedWhatsAppNumber",
  text: "Teacher Linked WhatsApp Number",
};
const TermNumber = {
  val: "TermNumber",
  text: "Term Number",
};
const InvoiceDueDate = {
  val: "InvoiceDueDate",
  text: "Invoice Due Date",
};
const InvoiceDueDateLong = {
  val: "InvoiceDueDateLong",
  text: "Invoice Due Date Long",
};
const InvoiceDueDatePlus7Days = {
  val: "InvoiceDueDatePlus7Days",
  text: "Invoice Due Date Plus 7 Days",
};
const CourseFee = {
  val: "CourseFee",
  text: "Course Fee",
};
const OtherFee = {
  val: "OtherFee",
  text: "Other Fee",
};
const TermLessonDates = {
  val: "TermLessonDates",
  text: "Term Lesson Dates",
};
const AppliedPaymentAmount = {
  val: "AppliedPaymentAmount",
  text: "Applied Payment Amount",
};
const OtherPendingAmount = {
  val: "OtherPendingAmount",
  text: "Other Pending Amount",
};
const InvoiceLessons = {
  val: "InvoiceLessons",
  text: "Invoice Lessons",
};
const NumberOfSeatsLeft = {
  val: "NumberOfSeatsLeft",
  text: "Number of Seats Left",
};
const NextLessonDay = {
  val: "NextLessonDay",
  text: "Next Lesson Day",
};

// TODO: helper is under variables.
const IsOnsite = ifHelperGenerator("IsOnsite");
const IsHybrid = ifHelperGenerator("IsHybrid");
const IsWorkshop = ifHelperGenerator("IsWorkshop");
const IsPaid = ifHelperGenerator("IsPaid");
const IsBranch = ifHelperGenerator("IsBranch");
const IsTomorrow = ifHelperGenerator("IsTomorrow");
const IsReplacementRegular = ifHelperGenerator("IsReplacementRegular");
const IsReplacementLessonCopySchedule = ifHelperGenerator(
  "IsReplacementLessonCopySchedule"
);
const IsCopySchedule = ifHelperGenerator("IsCopySchedule");
const IsAvailableClassMoreThanOne = ifHelperGenerator(
  "IsAvailableClassMoreThanOne"
);
const IsGMLAnalyticsCopyWithoutFormat = ifHelperGenerator(
  "IsGMLAnalyticsCopyWithoutFormat"
);
const IsLessonReplacementSeatAvailable = ifHelperGenerator(
  "IsLessonReplacementSeatAvailable"
);
const IsGMLAnalyticsCopyWithFormat = ifHelperGenerator(
  "IsGMLAnalyticsCopyWithFormat"
);
const IsSMSAnalyticsCopyWithoutFormat = ifHelperGenerator(
  "IsSMSAnalyticsCopyWithoutFormat"
);
const IsLessonRegularSeatAvailable = ifHelperGenerator(
  "IsLessonRegularSeatAvailable"
);
const IsSMSAnalyticsCopyWithFormat = ifHelperGenerator(
  "IsSMSAnalyticsCopyWithFormat"
);
const IsFirstFollowUp = ifHelperGenerator("IsFirstFollowUp");
const IsMUClassesAvailable = ifHelperGenerator("IsMUClassesAvailable");
const IsToday = ifHelperGenerator("IsToday");
const IsNextWeek = ifHelperGenerator("IsNextWeek");
const IsRegularFirstLesson = ifHelperGenerator("IsRegularFirstLesson");
const IsTransferFirstLessonDate = ifHelperGenerator(
  "IsTransferFirstLessonDate"
);
const IsChangeOfVenue = ifHelperGenerator("IsChangeOfVenue");
const IsChangeOfTeacher = ifHelperGenerator("IsChangeOfTeacher");
const IsChangeOfLearningArrangement = ifHelperGenerator(
  "IsChangeOfLearningArrangement"
);
const IsLastLessonAfterArrangementDate = ifHelperGenerator(
  "IsLastLessonAfterArrangementDate"
);
const HasRemainingReplacementCredits = ifHelperGenerator(
  "HasRemainingReplacementCredits"
);
const HasReservation = ifHelperGenerator("HasReservation");
const HasWaitingList = ifHelperGenerator("HasWaitingList");
const HasAvailableClass = ifHelperGenerator("HasAvailableClass");
const HasMultipleSessionsSameDay = ifHelperGenerator(
  "HasMultipleSessionsSameDay"
);
const IsDueToday = ifHelperGenerator("IsDueToday");
const IsOverdue = ifHelperGenerator("IsOverdue");
const IsOtherFee = ifHelperGenerator("IsOtherFee");
const IsBeforeOneDay = ifHelperGenerator("IsBeforeOneDay");
const HasPaymentApplied = ifHelperGenerator("HasPaymentApplied");
const IsAffectedByHoliday = ifHelperGenerator("IsAffectedByHoliday");
const HasOtherPendingAmount = ifHelperGenerator("HasOtherPendingAmount");
const IsRLLesson = ifHelperGenerator("IsRLLesson");
const HasPreferredLearningArrangement = ifHelperGenerator(
  "HasPreferredLearningArrangement"
);
const IsPreferredLearningArrangementOnsite = ifHelperGenerator(
  "IsPreferredLearningArrangementOnsite"
);

const EachArray = {
  text: "each array",
  val: `{{#each array}}{{#with this}}\n\n{{/with}}{{/each}}`,
  isHelper: true,
};

const GeneralVariables = [
  StudentPreferredName,
  CourseShorthand,
  Subject,
  IsWorkshop,
  IsOnsite,
  CourseName,
  ClassLearningArrangement,
  ZoomMeetingID,
  ZoomMeetingPassCode,
  CourseLevel,
  AcademicYear,
  LessonVenue,
];

export const defaultGeneralVariablesValue = {
  StudentPreferredName: "",
  CourseShorthand: "",
  Subject: "",
  IsWorkshop: false,
  IsOnsite: false,
  CourseName: "",
  ClassLearningArrangement: "",
  ZoomMeetingID: "",
  ZoomMeetingPassCode: "",
  CourseLevel: "",
  AcademicYear: "",
  LessonVenue: "",
};

const originalTemplateVariableConfig: TemplateVariableConfigType = {
  [TemplateUsageType.FollowUpPaymentAdvice]: [
    ParentPreferredName,
    StudentPreferredName,
    TotalFee,
    TotalAmountPayable,
  ],
  [TemplateUsageType.FollowUpTrial]: [
    ParentPreferredName,
    StudentPreferredName,
    ClassTeacher,
    CourseLevel,
    ClassLessonDay,
    ClassLessonDuration,
    ClassLearningArrangement,
    Subject,
    CourseName,
    FirstLessonDateAfterTrialDate,
    IsFirstFollowUp,
  ],
  [TemplateUsageType.FollowUpTermBilling]: [
    AcademicYear,
    StudentPreferredName,
    TermNumber,
    TotalAmountPayable,
    InvoiceDueDate,
    InvoiceDueDateLong,
    InvoiceDueDatePlus7Days,
    Term,
    TotalFee,
    CourseLevel,
    Subject,
    CourseShorthand,
    ClassLessonDay,
    ClassLessonDuration,
    ClassLearningArrangement,
    ClassTeacher,
    ClassRunNumber,
    CourseFee,
    OtherFee,
    TermLessonDates,
    AppliedPaymentAmount,
    OtherPendingAmount,
    InvoiceLessons,
    PerLessonFee,
    HolidayName,
    Branch,
    IsDueToday,
    IsOverdue,
    IsHybrid,
    IsOnsite,
    IsOtherFee,
    HasPaymentApplied,
    IsAffectedByHoliday,
    HasOtherPendingAmount,
  ],
  [TemplateUsageType.ConfirmationRefund]: [
    ParentPreferredName,
    AmountRefunded,
    TotalRefund,
    AdminFee,
    RefundDate,
  ],
  [TemplateUsageType.ConfirmationReceipt]: [
    ParentPreferredName,
    StudentName,
    StudentPreferredName,
    CourseShorthand,
    ClassRunNumber,
    ClassTeacher,
    CourseLevel,
    ClassLessonDay,
    ClassLessonDuration,
    AmountPaid,
  ],
  [TemplateUsageType.ConfirmationInvoice]: [
    ParentName,
    StudentName,
    CourseShorthand,
    ClassRunNumber,
    TotalFee,
    AmountPaidByBalance,
    PaymentStatus,
    TotalAmountPaid,
  ],
  [TemplateUsageType.FollowUpReservation]: [
    AcademicYear,
    StudentPreferredName,
    CourseShorthand,
    ClassTeacher,
    CourseLevel,
    ClassLessonDuration,
    CourseName,
    ClassLearningArrangement,
    PerLessonFee,
    LessonPeriod,
  ],
  [TemplateUsageType.ConfirmationReplacementLesson]: [
    StudentPreferredName,
    CourseShorthand,
    ClassRunNumber,
    CourseLevel,
    Subject,
    ReplacementCreditsLeft,
    ClassLearningArrangement,
    LessonDay,
    LessonDate,
    LessonDuration,
    LessonTeacher,
    OriginalLessonDay,
    OriginalLessonDate,
    OriginalLessonDuration,
    OriginalLessonTeacher,
    ZoomMeetingID,
    ZoomMeetingPassCode,
    Term,
    WorkshopClassSessions,
    NumberOfReplacementLessonsArrangedWithinTerm,
    LessonVenue,
    CourseName,
    IsOnsite,
    IsWorkshop,
    HasRemainingReplacementCredits,
  ],
  [TemplateUsageType.ConfirmationAdditionalLesson]: [
    StudentPreferredName,
    CourseLevel,
    ClassTeacher,
    ClassLearningArrangement,
    LessonDay,
    LessonDate,
    LessonDuration,
    AdditionalLessonFee,
    IsOnsite,
    IsHybrid,
  ],
  [TemplateUsageType.ConfirmationTrial]: [
    StudentPreferredName,
    ClassRunNumber,
    ClassTeacher,
    CourseLevel,
    ClassLessonDay,
    ClassLessonDuration,
    TrialDate,
    TrialFee,
    RegistrationFee,
    ClassLearningArrangement,
    ZoomMeetingID,
    LessonVenue,
    ReservationClassLessonDay,
    ReservationClassLessonDuration,
    ReservationDate,
    ReservationClassLearningArrangement,
    ReservationClassTeacher,
    StudentAddress,
    IsOnsite,
    HasReservation,
  ],
  [TemplateUsageType.ConfirmationTransfer]: [
    StudentPreferredName,
    CourseName,
    ClassLessonDuration,
    ClassLessonDay,
    ClassLearningArrangement,
    ClassTeacher,
    FirstLessonDate,
    LessonVenue,
    ZoomMeetingID,
    ZoomMeetingPassCode,
    OriginalClassLessonDuration,
    OriginalClassLessonDay,
    OriginalClassTeacher,
    OriginalClassLearningArrangement,
    LastLessonDate,
    IsOnsite,
    IsWorkshop,
    IsLastLessonAfterArrangementDate,
  ],
  [TemplateUsageType.ConfirmationRegistration]: [
    StudentPreferredName,
    ClassRunNumber,
    ClassTeacher,
    TotalFee,
    CourseLevel,
    ClassLessonDay,
    ClassLessonDuration,
    CourseName,
    ClassLearningArrangement,
    ZoomMeetingID,
    LessonVenue,
    SessionDates,
    SessionDays,
    HolidayType,
    SessionDuration,
    IsWorkshop,
    IsPaid,
  ],
  [TemplateUsageType.ConfirmationWaitingList]: [
    StudentPreferredName,
    CourseName,
    AcademicYear,
    WaitingListClassList,
    AvailableClassList,
    SessionDates,
    SessionDays,
    SessionDuration,
    IsWorkshop,
    HasAvailableClass,
  ],
  [TemplateUsageType.CopyListReplacement]: [
    StudentPreferredName,
    CourseShorthand,
    ClassRunNumber,
    Subject,
    WorkshopSessionNumber,
    ClassLearningArrangement,
    LessonDay,
    LessonDate,
    LessonDuration,
    LessonTeacher,
    Branch,
    DateToSkip,
    WorkshopLessonDay,
    WorkshopLessonDate,
    HolidayType,
    IsBranch,
    IsReplacementRegular,
    IsReplacementLessonCopySchedule,
    IsAvailableClassMoreThanOne,
    EachArray,
  ],
  [TemplateUsageType.CopyListAdditionalLesson]: [
    StudentPreferredName,
    ClassLearningArrangement,
    LessonDay,
    LessonDate,
    LessonDuration,
    LessonTeacher,
    Branch,
    HolidayName,
    IsBranch,
    IsCopySchedule,
  ],
  [TemplateUsageType.ReminderLesson]: [
    NextLessonDay,
    StudentName,
    LessonDate,
    ClassLearningArrangement,
    CourseName,
    CourseLevel,
    StudentPreferredName,
    WorkshopClassSessions,
    ClassRunNumber,
    ClassTeacher,
    LessonVenue,
    ClassLessonDuration,
    LearningResourceLink,
    TeacherSharedWhatsAppNumber,
    FirstLessonDate,
    ClassLessonDay,
    ZoomMeetingID,
    TeacherLinkedWhatsAppNumber,
    WaitingListClassList,
    GamePlanLink,
    CourseMaterial,
    HasWaitingList,
    HasMultipleSessionsSameDay,
    IsWorkshop,
    IsToday,
    IsNextWeek,
    IsOnsite,
    IsBeforeOneDay,
    IsRegularFirstLesson,
    IsTransferFirstLessonDate,
    HasPreferredLearningArrangement,
    IsPreferredLearningArrangementOnsite,
  ],
  [TemplateUsageType.ReminderWorkshopReplacementLesson]: [
    IsTomorrow,
    IsToday,
    CourseMaterial,
    StudentName,
    ClassRunNumber,
    LessonDay,
    LessonDate,
    LessonDuration,
    LessonTeacher,
  ],
  [TemplateUsageType.FollowUpAbsent]: [
    CourseName,
    CourseLevel,
    StudentPreferredName,
    ClassTeacher,
    AvailableMULessonList,
    ClassLearningArrangement,
    LessonDay,
    LessonDate,
    LessonDateLong,
    LessonDuration,
    LearningResourceLink,
    TeacherLinkedWhatsAppNumber,
    LessonTeacher,
    OriginalClassTeacher,
    OriginalClassTeacherLinkedWhatsAppNumber,
    Subject,
    WorkshopSessionNumber,
    IsRLLesson,
    IsWorkshop,
    IsMUClassesAvailable,
    HasRemainingReplacementCredits,
  ],
  [TemplateUsageType.ReminderAdditionalLesson]: [
    LessonDate,
    StudentPreferredName,
    CourseLevel,
    Subject,
    LessonDay,
    LessonDuration,
    LessonTeacher,
    ZoomMeetingID,
    ZoomMeetingPassCode,
    LessonVenue,
    IsTomorrow,
    IsOnsite,
    IsHybrid,
  ],
  [TemplateUsageType.ReminderTrialLesson]: [
    TrialDate,
    ClassLessonDuration,
    CourseLevel,
    ClassLessonDay,
    ClassTeacher,
    LessonVenue,
    ZoomMeetingID,
    StudentPreferredName,
    // LessonMaterials,
    IsOnsite,
    IsToday,
  ],
  [TemplateUsageType.ReminderTeacherOrVenueUpdate]: [
    LessonDateLong,
    LessonTeacher,
    ClassRunNumber,
    IsToday,
    IsHybrid,
    IsTomorrow,
    LessonVenue,
    PreviousLessonVenue,
    LessonDate,
    CourseLevel,
    ClassLessonDay,
    ClassLessonDuration,
    PreviousLessonTeacher,
    ClassTeacher,
    StudentPreferredName,
    ZoomMeetingID,
    IsChangeOfVenue,
    IsChangeOfTeacher,
    IsChangeOfLearningArrangement,
    IsOnsite,
  ],
  [TemplateUsageType.FollowUpPayment]: [
    CourseShorthand,
    ClassRunNumber,
    ClassTeacher,
    TotalAmountPayable,
    ClassLessonDay,
    ClassLessonDuration,
    ClassLearningArrangement,
    InvoiceDueDateAndTime,
    NextDate,
    Term,
    IsWorkshop,
  ],
  [TemplateUsageType.CopyListClass]: [
    ClassRunNumber,
    Holiday,
    SessionDates,
    SessionDays,
    SessionDuration,
    ClassTeacher,
    ClassLearningArrangement,
    Branch,
    ClassLessonDay,
    ClassLessonDuration,
    NumberOfSeatsLeft,
    EachArray,
    IsOnsite,
    IsWorkshop,
  ],
  [TemplateUsageType.CopyListGml]: [
    ClassLearningArrangement,
    LessonDay,
    LessonDuration,
    LessonTeacher,
    FirstLessonDate,
    LessonAvailableReplacementSeat,
    IsGMLAnalyticsCopyWithFormat,
    IsGMLAnalyticsCopyWithoutFormat,
    IsLessonReplacementSeatAvailable,
  ],
  [TemplateUsageType.CopyListSms]: [
    ClassLearningArrangement,
    LessonDay,
    LessonDuration,
    LessonTeacher,
    FirstLessonDate,
    LessonAvailableRegularSeat,
    IsSMSAnalyticsCopyWithFormat,
    IsSMSAnalyticsCopyWithoutFormat,
    IsLessonRegularSeatAvailable,
  ],
  [TemplateUsageType.ReminderLessonUnderHoliday]: [
    HolidayName,
    HolidayDate,
    LessonDay,
    LessonDate,
    StudentPreferredName,
    CourseLevel,
    ClassLessonDuration,
    ClassTeacher,
    IsNextWeek,
  ],
  [TemplateUsageType.FollowUpReplacementLessonOffer]: [
    AvailableMULessonList,
    LessonDate,
    LessonDuration,
    LessonTeacher,
    LessonDay,
  ],
};

const TemplateUsageTypeWithGeneralVariables = [
  TemplateUsageType.ConfirmationAdditionalLesson,
  TemplateUsageType.ConfirmationAddressVerification,
  TemplateUsageType.ConfirmationInvoice,
  TemplateUsageType.ConfirmationMaterialsNote,
  TemplateUsageType.ConfirmationReceipt,
  TemplateUsageType.ConfirmationRefund,
  TemplateUsageType.ConfirmationRegistration,
  TemplateUsageType.ConfirmationReplacementLesson,
  TemplateUsageType.ConfirmationReservation,
  TemplateUsageType.ConfirmationTransfer,
  TemplateUsageType.ConfirmationTrial,
  TemplateUsageType.ConfirmationWaitingList,
  TemplateUsageType.ConfirmationWithdrawal,
  TemplateUsageType.CopyListAdditionalLesson,
  TemplateUsageType.CopyListClass,
  TemplateUsageType.CopyListGml,
  TemplateUsageType.CopyListReplacement,
  TemplateUsageType.CopyListSms,
  TemplateUsageType.FollowUpAbsent,
  TemplateUsageType.FollowUpPayment,
  TemplateUsageType.FollowUpPaymentAdvice,
  TemplateUsageType.FollowUpReplacementLessonOffer,
  TemplateUsageType.FollowUpReservation,
  TemplateUsageType.FollowUpTermBilling,
  TemplateUsageType.FollowUpTrial,
  TemplateUsageType.FollowUpWaitingList,
  // TemplateUsageType.General,
  TemplateUsageType.ReminderAdditionalLesson,
  TemplateUsageType.ReminderAdditionalLessonOffer,
  TemplateUsageType.ReminderAddressVerification,
  TemplateUsageType.ReminderLesson,
  TemplateUsageType.ReminderLessonUnderHoliday,
  TemplateUsageType.ReminderTeacherOrVenueUpdate,
  TemplateUsageType.ReminderTrialLesson,
  TemplateUsageType.ReminderWorkshopReplacementLesson,
  // TemplateUsageType.SystemDisclaimer,
  // TemplateUsageType.SystemDropOffRemarks,
  // TemplateUsageType.SystemPickupRemarks,
  // TemplateUsageType.SystemTermsConditions,
];
export const TemplateVariableConfig = Object.keys(
  originalTemplateVariableConfig
).reduce(
  (
    result: TemplateVariableConfigType,
    templateUsageType: keyof TemplateVariableConfigType
  ) => {
    if (!TemplateUsageTypeWithGeneralVariables?.includes(templateUsageType)) {
      if (result[templateUsageType]) {
        /**
         * All the variables are sorted by isHelper, so that the helper variables are always at the bottom.
         */
        result[templateUsageType] = [
          ...result[templateUsageType].filter((item) => !item.isHelper),
          ...result[templateUsageType].filter((item) => item.isHelper),
        ];
      }
      return result;
    }
    if (originalTemplateVariableConfig?.[templateUsageType]?.length) {
      /**
       * GeneralVariables are always at the top. And also we would keep the variables unique.
       */
      result[templateUsageType] = uniqBy(
        [
          ...GeneralVariables,
          ...originalTemplateVariableConfig[templateUsageType],
        ],
        "val"
      );
      /**
       * All the variables are sorted by isHelper, so that the helper variables are always at the bottom.
       */
      result[templateUsageType] = [
        ...result[templateUsageType].filter((item) => !item.isHelper),
        ...result[templateUsageType].filter((item) => item.isHelper),
      ];
    }
    return result;
  },
  {} as TemplateVariableConfigType
);

export const VariableNameArr = Object.values(TemplateVariableConfig).flat();
