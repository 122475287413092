import InsufficientCredentials from "@/assets/images/InsufficientCredentials.svg";
import formatLabel from "@/utils/formatLabel";
import { ErrorScreen } from "@thepiquelab/archus-components-web";
import { useHistory } from "react-router-dom";

const PageNotFound: React.FC = () => {
  const history = useHistory();

  return (
    <ErrorScreen
      logo=""
      title="404"
      description="The page you’re looking for is currently under maintenance and will be back soon."
      primaryButtonLabel={formatLabel("Go back")}
      onPressPrimaryButton={history.goBack}
      illustration={InsufficientCredentials}
    />
  );
};

export default PageNotFound;
