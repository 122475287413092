import { Skeleton } from "antd";
import { TableItemSkeleton } from "./TableSkeleton";

const OperationalTaskDetailSkeleton = () => (
  <div className="px-6 flex-row gap-4 w-a4 py-6">
    <div className="flex gap-2 justify-between">
      <Skeleton.Button active />
      <Skeleton.Button active />
    </div>
    <div className="flex gap-4 justify-between mt-8 items-center">
      <Skeleton.Input active className="flex-grow w-full" />
      <Skeleton.Button active size="default" />
      <Skeleton.Button active size="default" />
    </div>
    <div className="flex gap-4 justify-between mt-8 items-center">
      <Skeleton.Input active className="flex-grow w-full" />
      <Skeleton.Button active size="default" />
      <Skeleton.Button active size="default" />
    </div>
    <div className="flex gap-4 justify-between mt-8 items-center">
      <Skeleton.Input active className="flex-grow w-full" />
      <Skeleton.Button active size="default" />
      <Skeleton.Button active size="default" />
    </div>
    <div className="flex gap-4 justify-between mt-8 items-center">
      <Skeleton.Input active className="flex-grow w-full" />
      <Skeleton.Button active size="default" />
      <Skeleton.Button active size="default" />
    </div>
    <div className="flex gap-2 flex-row-reverse mt-8 ">
      <Skeleton.Button active className="w-40" />
    </div>
    <div className="mt-8">
      <TableItemSkeleton header active />
      {new Array(3).fill(null).map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <TableItemSkeleton key={index} active />
      ))}
    </div>
  </div>
);

export default OperationalTaskDetailSkeleton;
