import { EnumPermissions } from "@/components/authorize/Permissions";
import CollectFeeDetailSkeleton from "@/components/common/Skeleton/CollectFeeDetailSkeleton";
import { useAccessControl } from "@/hooks/useAccessControl";
import formatLabel from "@/utils/formatLabel";
import { Col, Tabs } from "@thepiquelab/archus-components-web";
import cls from "classnames";

export interface IPaymentRecordStatusProps {
  loading: boolean;
  verticalAlign: boolean;
  isFormVisible: boolean;
  permissions: EnumPermissions[];
  paymentRecordStatus: string;
  setPaymentRecordStatus: (val: string) => void;
  children: JSX.Element;
}

export default function PaymentRecordStatus(
  props: IPaymentRecordStatusProps
): JSX.Element {
  const {
    loading,
    permissions,
    isFormVisible,
    verticalAlign,
    paymentRecordStatus,
    setPaymentRecordStatus,
    children,
  } = props;
  const { hasAllPermissions } = useAccessControl();
  const permission = hasAllPermissions(permissions);

  if (!permission) return null;

  if (loading) {
    return <CollectFeeDetailSkeleton />;
  }

  return (
    <Col
      span={isFormVisible && !verticalAlign ? 10 : 24}
      className={cls(
        "border-gray-400",
        "border-solid",
        verticalAlign ? "border-t" : "border-l"
      )}
    >
      <div
        className={cls("overflow-auto", "flex-1 ", "relative", {
          "max-h-100": !verticalAlign,
        })}
      >
        <Tabs
          activeKey={paymentRecordStatus}
          defaultActiveKey="paid"
          className="px-6 py-3"
          onChange={setPaymentRecordStatus}
        >
          <Tabs.TabPane tab={formatLabel("Payments made")} key="paid" />
          <Tabs.TabPane tab={formatLabel("Voided Payments")} key="void" />
        </Tabs>
        <div>{children}</div>
      </div>
    </Col>
  );
}
