import { ReactComponent as IconInfo } from "@/assets/images/IconInfo.svg";
import AuthorizedByPermission, {
  EnumAuthHandleType,
} from "@/components/authorize/AuthorizedByPermission";
import { CaretDownOutlined, PlusCircleFilled } from "@ant-design/icons";
import {
  Button,
  Dropdown,
  Menu,
  Typography,
} from "@thepiquelab/archus-components-web";
import { Divider, Space, Tooltip } from "antd";
import { isNil } from "lodash";
import React from "react";
import "./DataPageHeader.scss";
import { AddButtonProp } from "./types";

export interface DataPageHeaderProps {
  title: React.ReactNode;
  total?: number | React.ReactNode;
  /**
   * @deprecated use the `description` prop instead. The way the prop value is
   * used is not correct as prefix should be before something. It is also made
   * redundant by `description` which is more widely used.
   */
  prefix?: React.ReactNode;
  rightBar?: React.ReactNode;
  description?: string | React.ReactNode;
  toolTip?: string | React.ReactNode;
  addButtonProp?: AddButtonProp;
  className?: string;
}

const DataPageHeader: React.FC<DataPageHeaderProps> = (
  props: DataPageHeaderProps
) => {
  const {
    title,
    total,
    toolTip,
    description,
    className = "",
    prefix,
    addButtonProp,
    rightBar: RightBar,
  } = props;

  return (
    <div
      className={`flex justify-between items-center mb-6 flex-shrink-0 ${className}`}
    >
      <div className="flex justify-between items-center flex-shrink-0 min-w-min">
        <Space align="center">
          <div data-testid="data-header-title">
            <Typography.Heading>{title}</Typography.Heading>
          </div>
          {total || description || prefix ? (
            <Divider type="vertical" orientation="center" className="h-7.5" />
          ) : null}
          {!isNil(total) && (
            <div data-testid="data-header-total-count">
              <Typography.Heading className="text-primary-blue">
                {total}
              </Typography.Heading>
            </div>
          )}
          {description && (
            <Typography.Heading level={3} className="text-gray-500">
              {description}
            </Typography.Heading>
          )}
          {toolTip && (
            <Typography.Heading level={3} className="text-gray-500">
              <Tooltip
                title={toolTip}
                color="#4F537B"
                overlayInnerStyle={{ width: "400px", padding: "1rem 0.5rem" }}
              >
                <IconInfo className="w-4 h-4 text-primary-navy" />
              </Tooltip>
            </Typography.Heading>
          )}
          {prefix && (
            <Typography.Heading level={3} className="text-gray-500 m-0">
              {prefix}
            </Typography.Heading>
          )}
          {addButtonProp && !addButtonProp?.items && (
            <AuthorizedByPermission
              permissions={addButtonProp.permissions}
              authHandleType={EnumAuthHandleType.HIDE}
            >
              <Button
                onClick={addButtonProp.onClick}
                type="primary"
                disabled={addButtonProp.disable}
                data-testid="data-header-add-button"
                className="ml-2"
              >
                {addButtonProp.icon || <PlusCircleFilled className="mr-2" />}
                <span>{addButtonProp.label}</span>
              </Button>
            </AuthorizedByPermission>
          )}
          {addButtonProp && addButtonProp?.items && (
            <AuthorizedByPermission
              permissions={addButtonProp.permissions}
              authHandleType={EnumAuthHandleType.HIDE}
            >
              <Dropdown.Button
                icon={<CaretDownOutlined />}
                onClick={addButtonProp.onClick}
                type="primary"
                disabled={addButtonProp.disable}
                className="ml-2"
                overlay={
                  <Menu onClick={addButtonProp.onItemClick}>
                    {addButtonProp?.items?.map((i) => (
                      <Menu.Item
                        icon={i.icon}
                        key={i.key}
                        disabled={i?.disable}
                      >
                        {i.content}
                      </Menu.Item>
                    ))}
                  </Menu>
                }
              >
                <div>
                  {addButtonProp.icon || <PlusCircleFilled className="mr-2" />}
                  {addButtonProp.label}
                </div>
              </Dropdown.Button>
            </AuthorizedByPermission>
          )}
        </Space>
      </div>
      {RightBar && (
        <div className="pl-28 flex-shrink-0 flex flex-row-reverse">
          {RightBar}
        </div>
      )}
    </div>
  );
};

export default DataPageHeader;
