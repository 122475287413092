import { TaskStatus } from "@/graphql";

export const TaskStatusTagMap = {
  [TaskStatus.Pending]: {
    bgColor: "#5B9DFF33",
    textColor: "#5B9DFF",
  },
  [TaskStatus.Complete]: {
    bgColor: "#64DD1733",
    textColor: "#64DD17",
  },
  [TaskStatus.Overdue]: {
    textColor: "#F36B7F",
    bgColor: "#F36B7F33",
  },
  [TaskStatus.Void]: {
    textColor: "#F36B7F",
    bgColor: "#F36B7F33",
  },
};
