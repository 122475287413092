import {
  attendanceCapacity,
  AttendanceCapacityView,
  attendanceHoldingCapacity,
  attendancePaidCapacity,
  attendanceRegularCapacity,
  attendanceReservedCapacity,
  attendanceUnpaidCapacity,
} from "@/components/class/LessonCapacity/LessonType";
import {
  enrollmentHoldingCapacity,
  enrollmentRegularCapacity,
  enrollmentReservedCapacity,
  enrollmentTransferInCapacity,
  enrollmentTransferOutCapacity,
  enrollmentUnpaidCapacity,
  enrollmentWithdrawingCapacity,
  SeatBgColor,
  SeatColor,
  SeatTextColor,
} from "@/components/class/NewClassCapacity/classType";
import { ClassSeatDetailDTO } from "@/components/class/NewClassCapacity/classUtils";
import {
  Attendance,
  AttendanceStatus,
  CapacitySeatType,
  Enrollment,
  EnrollmentStatus,
  EnrollmentType,
  Lesson,
  LessonSeatDto,
} from "@/graphql";
import { EnrollmentCapacityView } from "@/utils/getEnrollmentCapacityView";
import { formatDate } from "@thepiquelab/archus-library";
import { LessonFields } from "../GML/utils";

export const filterSeatsOrEnrollments = (
  seatInfo: ClassSeatDetailDTO,
  enrollmentData: Enrollment[]
): Enrollment => {
  if (!seatInfo) return null;

  return enrollmentData?.find(
    (i) =>
      (i?.student as any)?.id === (seatInfo?.student as any)?.id &&
      i?.type === EnrollmentType.Regular
  );
};

export const getAttendanceCapacityView = (
  seat: LessonSeatDto
): AttendanceCapacityView[] => {
  const { type, attendanceStatus, hasPaid, isEnrollmentStarted } = seat;

  if (type === CapacitySeatType.Reserved) return [attendanceReservedCapacity];

  if (attendanceStatus === AttendanceStatus.Holding)
    return [attendanceHoldingCapacity];

  const capacity = (attendanceCapacity as any)[type];
  if (capacity?.label === "Additional" && hasPaid) {
    return [
      capacity,
      {
        label: "Incoming",
        color: SeatColor.Paid,
        hasPaid: true,
        bgColor: SeatBgColor.Paid,
        textColor: SeatTextColor.Paid,
        text: "Paid",
      },
    ];
  }
  if (capacity) return [capacity];

  if (!hasPaid) return [attendanceUnpaidCapacity];

  const regular = (attendanceRegularCapacity as any)[type];
  if (regular) return [regular];

  const paid = (attendancePaidCapacity as any)[type]?.find(
    (e: any) =>
      e.checkFirstLesson === isEnrollmentStarted && e.hasPaid === hasPaid
  );
  if (paid) return [paid];

  return [
    {
      status: attendanceStatus,
      label: attendanceStatus?.toLowerCase(),
      color: SeatColor.Holding,
      text: attendanceStatus?.toLowerCase(),
    },
  ];
};

export const getEnrollmentCapacityView = (
  seat: ClassSeatDetailDTO,
  currentEnrollment: Enrollment
): Omit<EnrollmentCapacityView, "isHidden">[] => {
  const {
    type,
    enrollmentStatus,
    hasPaid,
    isEnrollmentStarted: checkFirstLesson,
  } = seat;
  if (
    type === CapacitySeatType.Reserved &&
    currentEnrollment?.registration?.trialRegistration?.startDate
  )
    return [
      {
        status: EnrollmentStatus.Active,
        label: "Trial",
        color: SeatColor.Trial,
        bgColor: SeatBgColor.Trial,
        textColor: SeatTextColor.Trial,
        text: "Trial",
      },
      enrollmentReservedCapacity,
    ];

  if (type === CapacitySeatType.Reserved) return [enrollmentReservedCapacity];

  if (enrollmentStatus === EnrollmentStatus.Holding)
    return [enrollmentHoldingCapacity];

  if (!hasPaid) return [enrollmentUnpaidCapacity];

  if (type === CapacitySeatType.TransferIn)
    return [enrollmentTransferInCapacity];

  if (type === CapacitySeatType.TransferOut)
    return [enrollmentTransferOutCapacity];

  if (type === CapacitySeatType.Withdrawing)
    return [enrollmentWithdrawingCapacity];

  const regular = (enrollmentRegularCapacity as any)[type]?.find(
    (e: any) => e.checkFirstLesson === checkFirstLesson
  );
  if (regular) return [regular];

  return [
    {
      status: enrollmentStatus,
      label: enrollmentStatus?.toLowerCase(),
      color: SeatColor.Holding,
      text: enrollmentStatus?.toLowerCase(),
    },
  ];
};

export const filterLessonStatusText = ({
  texts,
  currentLesson,
  currentAttendance,
  dueDate,
}: {
  texts: AttendanceCapacityView[];
  currentLesson?: LessonFields;
  currentAttendance?: Attendance;
  dueDate?: string;
}): string => {
  const filterTextArray = ["Paid", "Unpaid", "Reserved", "Transferring In"];
  if (texts?.length === 1) {
    const { text } = texts?.[0] || {};
    if (text === "Additional Lesson") {
      return `${dueDate}from ${
        currentAttendance?.linkArrangement?.fromClass?.shorthand ?? ""
      }`;
    }
    if (text === "Replacement") {
      return `Replacement From ${
        currentAttendance?.linkArrangement?.fromClass?.shorthand || ""
      }`;
    }
    if (text === "Trial") {
      return `Trial on ${formatDate(currentLesson?.lessonStart, {
        format: "SHORT",
      })} (Lesson ${currentLesson?.lessonNumber})`;
    }

    if (filterTextArray.includes(text)) {
      return `${dueDate}Starts on ${formatDate(currentLesson?.lessonStart, {
        format: "SHORT",
      })} (Lesson ${currentLesson?.lessonNumber})`;
    }

    return `Last Lesson on ${formatDate(currentLesson?.lessonStart, {
      format: "SHORT",
    })} (Lesson ${currentLesson?.lessonNumber})`;
  }

  return `From ${
    currentAttendance?.linkArrangement?.fromClass?.shorthand ?? ""
  }`;
};

export const filterClassStatusText = ({
  texts,
  currentLesson,
  currentEnrollment,
  dueDate,
}: {
  texts: Omit<EnrollmentCapacityView, "isHidden">[];
  currentLesson?: Lesson;
  currentEnrollment?: Enrollment;
  dueDate?: string;
}): string => {
  const filterTextArray = ["Paid", "Unpaid", "Reserved", "Transferring In"];
  if (texts?.length === 1 && currentLesson) {
    const { text } = texts?.[0] || {};

    if (filterTextArray.includes(text)) {
      return `${dueDate}Starts on ${formatDate(currentLesson?.lessonStart, {
        format: "SHORT",
      })} (Lesson ${currentLesson?.lessonNumber})`;
    }
    return `${dueDate}Last Lesson on ${formatDate(currentLesson?.lessonStart, {
      format: "SHORT",
    })} (Lesson ${currentLesson?.lessonNumber})`;
  }
  // Trial +  Reserved
  if (texts?.length === 2) {
    const res = currentEnrollment?.registration?.trialRegistration;

    // if (res?.shorthand === currentEnrollment?.class?.name) {
    //   return `Trial on ${formatDate(res?.startDate, {
    //     format: "SHORT",
    //   })}`;
    // }

    return `Trial on ${res?.class?.shorthand} on ${formatDate(res?.startDate, {
      format: "SHORT",
    })}`;
  }

  return "";
};
