import {
  Checkbox,
  CheckboxValueType,
  Select,
  Typography,
} from "@thepiquelab/archus-components-web";
import { useEffect, useMemo, useState } from "react";
import { useTeacherOptions } from "./hooks/useTeacherOptions";

interface Props {
  label: string;
  onChange?: (value: CheckboxValueType[]) => void;
  value?: CheckboxValueType[];
}

export const TeacherOptions: React.FC<Props> = (props) => {
  const { onChange, label, value } = props;
  const { options } = useTeacherOptions();
  const [checkAll, setCheckAll] = useState(false);

  const indeterminate = useMemo(() => {
    if (!options) {
      return false;
    }

    return value?.length && value.length < options.length;
  }, [value, options]);

  const handleSelectAll = async (checked: boolean): Promise<void> => {
    setCheckAll(checked);
    const data = options;
    if (checked) {
      onChange?.(data.map((o) => o.value));
    } else {
      onChange?.(undefined);
    }
  };

  useEffect(() => {
    if (value && value.length === options?.length) {
      setCheckAll(true);
    } else {
      setCheckAll(false);
    }
  }, [value]);

  return (
    <div>
      <div className="mb-2">
        <Checkbox
          key={`teacher-check-all`}
          className="text-sm"
          checked={checkAll}
          indeterminate={indeterminate}
          onChange={(e) => handleSelectAll(e.target.checked)}
        >
          <Typography.Heading level={4}>{label}</Typography.Heading>
        </Checkbox>
      </div>
      <div>
        <Select
          className="w-full"
          key={`teacher-select`}
          allowClear
          mode="multiple"
          showArrow
          value={value}
          options={options}
          onChange={onChange}
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
          filterOption={(input, option) =>
            ((option?.label as string) ?? "")
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        />
      </div>
    </div>
  );
};
