import { Link } from "react-router-dom";

interface ICourseLinkProps {
  courseId?: string;
  linkText?: string;
}
const CourseLink: React.FC<ICourseLinkProps> = ({
  courseId,
  linkText,
  children,
}) => {
  if (!courseId) return <>{children}</>;
  const linkUrl = `/course-management/course/${courseId?.trim()}`;
  return (
    <Link
      to={linkUrl}
      target={"_blank"}
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <span className="font_semibold text-primary-blue">
        {linkText || children}
      </span>
    </Link>
  );
};

export default CourseLink;
