import { useConfirmModal } from "@/components/common/ConfirmModal/NewConfirmModal";
import { TaskType } from "@/graphql";
import formatErrorMessage from "@/utils/formatErrorMessage";
import { message } from "@thepiquelab/archus-components-web";
import { useEffect, useState } from "react";
import { useCompleteTasks, useVoidTasks } from "./hooks";

export const useUpdateTasks = (
  type: TaskType,
  callback?: (ids?: string[]) => void
): {
  completeTasks: (ids?: string[]) => void;
  voidTasks: (ids?: string[]) => void;
  completeTaskLoading: boolean;
  voidTaskLoading: boolean;
  modal: React.ReactNode;
  selectedIds: string[];
  setSelectedIds: (ids: string[]) => void;
} => {
  const [completeTasks, { loading: completeTaskLoading }] = useCompleteTasks();
  const [voidTasks, { loading: voidTaskLoading }] = useVoidTasks();
  const { modal, show, close, setModalProps } = useConfirmModal();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const handleComplete = async (ids?: string[]): Promise<void> => {
    try {
      const res = await completeTasks({
        variables: {
          command: {
            type,
            ids,
          },
        },
      });
      const completeTaskIds = res?.data?.completeTasks?.map((e) => e?.id) ?? [];
      close();
      setSelectedIds([]);
      callback?.(completeTaskIds);
    } catch (error) {
      message.error(formatErrorMessage(error));
    }
  };

  const handleVoid = async (ids?: string[]): Promise<void> => {
    try {
      await voidTasks({
        variables: {
          command: {
            type,
            ids,
          },
        },
      });
      close();
      setSelectedIds([]);
      callback?.();
    } catch (error) {
      message.error(formatErrorMessage(error));
    }
  };

  const handleOpenCompleteModal = (ids?: string[]): void => {
    setModalProps({
      onConfirm: () => handleComplete(ids),
      onClose: close,
    });
    show();
  };

  const handleOpenVoidModal = (ids?: string[]): void => {
    setModalProps({
      onConfirm: () => handleVoid(ids),
      onClose: close,
    });
    show();
  };

  useEffect(() => {
    setModalProps({ confirmLoading: completeTaskLoading || voidTaskLoading });
  }, [completeTaskLoading, voidTaskLoading]);

  return {
    completeTasks: handleOpenCompleteModal,
    voidTasks: handleOpenVoidModal,
    completeTaskLoading,
    voidTaskLoading,
    modal,
    selectedIds,
    setSelectedIds,
  };
};
