import { AdvanceTableColumnType } from "@/components/common/AdvanceTable/AdvanceTable";
import {
  EnumEnhancedTableWidthType,
  enumEnhancedTableWidthType,
} from "@/components/common/EnhancedTable/EnhancedTable";
import { InvoiceStatusMap } from "@/components/finance/Modules/InvoiceDetail/InvoiceDetailCard";
import ClassLink from "@/components/widget/CommonLink/ClassLink";
import RegistrationLink from "@/components/widget/CommonLink/RegistrationLink";
import TransferLink from "@/components/widget/CommonLink/TransferLink";
import {
  ArrangementType,
  InvoiceStatus,
  TaskFieldsFragment,
  TaskOriginRecordType,
  TaskType,
} from "@/graphql";
import { enumToArray } from "@/utils/enumUnit";
import formatLabel from "@/utils/formatLabel";
import { Select, Typography } from "@thepiquelab/archus-components-web";
import { formatDate } from "@thepiquelab/archus-library";
import { sortBy } from "lodash";
import React, { useEffect, useState } from "react";
import TaskTable, { CustomCommonColumn } from "./TaskTable";
import { CreationContextTypeMap } from "./TaskTableUtil";
import { TaskInnerFilterType, TaskTableProps } from "./types";

enum SelectType {
  WorkshopLessonReminderOneWeek = "Workshop Lesson Reminder (1 Week)",
  WorkshopLessonReminderOneDay = "Workshop Lesson Reminder (1 Day)",
  TransferFirstLessonReminder = "Transfer 1st Lesson Reminder",
  GapFirstLessonReminder = "GAP 1st Lesson Reminder",
}

const InnerFilerValueMap: Record<SelectType, TaskInnerFilterType> = {
  [SelectType.WorkshopLessonReminderOneWeek]: {
    originRecordTypes: [TaskOriginRecordType.WorkshopWeekLessonReminder],
  },
  [SelectType.WorkshopLessonReminderOneDay]: {
    originRecordTypes: [
      TaskOriginRecordType.WorkshopDayContinuouslyLessonReminder,
      TaskOriginRecordType.WorkshopDayNonContinuouslyLessonReminder,
    ],
  },
  [SelectType.TransferFirstLessonReminder]: {
    isTransferLessonReminder: true,
  },
  [SelectType.GapFirstLessonReminder]: {
    originRecordTypes: [TaskOriginRecordType.GapLessonReminder],
    isTransferLessonReminder: false,
  },
};

const ReminderTaskLesson: React.FC<TaskTableProps> = (props) => {
  const {
    data,
    callback,
    visible,
    pagination,
    count,
    loading,
    onInnerFilterChange,
  } = props;
  const [value, setValue] = useState<SelectType>();
  const columns: AdvanceTableColumnType[] = [
    {
      title: formatLabel("Task Name"),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.M_MEDIUM],
      render(value, record: TaskFieldsFragment) {
        const { creationContext } = record;
        const isTransfer = creationContext?.isTransferLessonReminder;
        if (isTransfer) {
          return <>Transfer 1st Lesson Reminder</>;
        }
        const creationContextType = creationContext?.type;
        const text = CreationContextTypeMap[creationContextType] || "-";
        return <>{text}</>;
      },
    },
    CustomCommonColumn.StudentName,
    CustomCommonColumn.StudentID,
    CustomCommonColumn.PrimaryParentName,
    CustomCommonColumn.PrimaryParentMobile,
    CustomCommonColumn.PrimaryParentWALine,
    {
      title: formatLabel("Class Shorthand"),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(value, record: TaskFieldsFragment) {
        const { enrollment } = record;
        const { id: classId, shorthand } = enrollment?.class || {};

        return <ClassLink classId={classId}>{shorthand}</ClassLink>;
      },
    },
    {
      title: formatLabel("Enrollment No."),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render: (value, record: TaskFieldsFragment) => {
        const { enrollment } = record;

        if (!enrollment?.registration?.name) {
          return <>-</>;
        }
        return (
          <RegistrationLink id={enrollment?.registration?.id}>
            {enrollment?.registration?.name}
          </RegistrationLink>
        );
      },
    },
    {
      title: formatLabel("Transfer No."),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(value, record: TaskFieldsFragment) {
        const { enrollment } = record;
        const arrangements = enrollment?.arrangements;

        const transferArrangements = arrangements?.filter(
          (arrangement) =>
            arrangement?.type === ArrangementType.Transfer &&
            arrangement?.newEnrollmentId === enrollment?.id
        );
        if (!transferArrangements?.length) {
          return <>-</>;
        }
        return transferArrangements?.map((transfer) => (
          <div>
            <TransferLink id={transfer?.id}>{transfer?.name}</TransferLink>
          </div>
        ));
      },
    },

    {
      title: formatLabel("Payment Status"),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(value, record: TaskFieldsFragment) {
        const { enrollment } = record;
        let invoices = enrollment?.invoices || [];
        invoices = invoices?.filter(
          (invoice) => invoice?.status !== InvoiceStatus.Void
        );
        invoices = sortBy(invoices, ["billingPeriod"]);
        const invoice = invoices?.[0];

        if (!invoice) {
          return <>-</>;
        }
        return (
          <div>
            <Typography.Text
              size="middle"
              style={{ color: InvoiceStatusMap?.[invoice?.status]?.color }}
            >
              {`${invoice.status}`}
            </Typography.Text>
          </div>
        );
      },
    },

    {
      title: formatLabel("Enrollment Start Date"),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render: (value, record: TaskFieldsFragment) => {
        const { enrollment } = record;
        const startData = enrollment?.startDate;
        return (
          <>{startData ? formatDate(startData, { format: "SHORT" }) : "-"}</>
        );
      },
    },
  ];

  const options = enumToArray(SelectType).map((e) => ({
    label: e.value,
    value: e.value,
  }));

  const handleFilterChange = (type: SelectType): void => {
    setValue(type);
    if (!type) {
      onInnerFilterChange?.({});
      return;
    }
    onInnerFilterChange?.(InnerFilerValueMap[type]);
  };

  useEffect(() => {
    if (!visible) {
      setValue(null);
    }
  }, [visible]);

  const innerFilter = (
    <Select
      className="w-80"
      size="small"
      value={value}
      allowClear
      options={options}
      onChange={handleFilterChange}
    />
  );

  if (!visible) {
    return <></>;
  }

  return (
    <div>
      <TaskTable
        data={data}
        loading={loading}
        count={count}
        columns={columns}
        innerFilter={innerFilter}
        title={formatLabel("Lesson Reminder")}
        taskType={TaskType.ReminderLesson}
        callback={callback}
        pagination={{
          ...pagination,
        }}
      />
    </div>
  );
};

export default ReminderTaskLesson;
