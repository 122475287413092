import { Button, Col, Row, Space } from "@thepiquelab/archus-components-web";
import { ButtonType } from "antd/lib/button";
import React from "react";
import formatLabel from "../../../utils/formatLabel";

interface ModalButtonGroupProps {
  okText?: string;
  cancelText?: string;
  backText?: string;
  showOk?: boolean;
  showCancel?: boolean;
  showBack?: boolean;
  okButtonProps?: ButtonProps;
  cancelButtonProps?: ButtonProps;
  backButtonProps?: ButtonProps;
  className?: string;
  confirmLoading?: boolean;

  option?: React.ReactElement;

  onOK?: React.MouseEventHandler<HTMLElement>;
  onCancel?: React.MouseEventHandler<HTMLElement>;
  onBack?: React.MouseEventHandler<HTMLElement>;
}

type ButtonProps = {
  type?: ButtonType;
  loading?: boolean;
  disabled?: boolean;
  className?: string;
  icon?: React.ReactNode;
  iconAlign?: "left" | "right";
  onClick?: React.MouseEventHandler<HTMLElement>;
};

const ButtonContent = (
  text: string,
  defaultText: string,
  props?: ButtonProps
): JSX.Element => {
  const { icon, iconAlign = "left", ...rest } = props || {};
  return (
    <Button {...rest}>
      {icon && iconAlign === "left" && icon}
      {formatLabel(text || defaultText)}
      {icon && iconAlign === "right" && icon}
    </Button>
  );
};

const ModalButtonGroup: React.FC<ModalButtonGroupProps> = (props) => {
  const {
    okText,
    cancelText,
    backText,
    showBack = false,
    showCancel = true,
    showOk = true,
    okButtonProps,
    cancelButtonProps,
    backButtonProps,
    className,
    confirmLoading,
    option,
    onOK,
    onCancel,
    onBack,
  } = props;

  return (
    <Row
      justify={showBack ? "space-between" : "end"}
      className={className}
      key="footer"
    >
      {showBack && (
        <Col>
          {ButtonContent(backText, "Back", {
            ...backButtonProps,
            onClick: onBack,
          })}
        </Col>
      )}

      <Col className="flex">
        <Space className="space-x-1">
          {option}
          {showCancel &&
            ButtonContent(cancelText, "Cancel", {
              ...cancelButtonProps,
              onClick: onCancel,
            })}
          {showOk &&
            ButtonContent(okText, "OK", {
              ...okButtonProps,
              type: "primary",
              onClick: onOK,
              loading: confirmLoading,
            })}
        </Space>
      </Col>
    </Row>
  );
};

export default ModalButtonGroup;
