import { ReactComponent as IconClose } from "@/assets/images/IconClose.svg";
import CollapseList from "@/components/common/CollapseList/CollapseList";
import { useConfirmModal } from "@/components/common/ConfirmModal/NewConfirmModal";
import DropdownAction, {
  Action,
} from "@/components/common/DropdownAction/DropdownAction";
import {
  EnumEnhancedTableWidthType,
  enumEnhancedTableWidthType,
} from "@/components/common/EnhancedTable/EnhancedTable";
import StudentLink from "@/components/widget/CommonLink/StudentLink";
import {
  CourseNotificationsQuery,
  CourseType,
  Enrollment,
  EnrollmentType,
  RegistrationsStatus,
} from "@/graphql";
import formatErrorMessage from "@/utils/formatErrorMessage";
import { Button, Divider, message } from "@thepiquelab/archus-components-web";
import {
  formatDate,
  formatPhoneNumber,
  formatTime,
} from "@thepiquelab/archus-library";
import { ColumnsType } from "antd/lib/table";
import { FC, memo, useEffect, useState } from "react";

import AuthorizedByPermission, {
  EnumAuthHandleType,
} from "@/components/authorize/AuthorizedByPermission";
import { EnumPermissions } from "@/components/authorize/Permissions";
import ClassLink from "@/components/widget/CommonLink/ClassLink";
import { useAccessControl } from "@/hooks/useAccessControl";
import formatLabel from "@/utils/formatLabel";
import { PlusCircleFilled } from "@ant-design/icons";
import { uniqBy } from "lodash";
import {
  useDeleteCourseNotification,
  useLazyQueryCourseNotifications,
} from "../hooks";
import CourseNotificationListModal from "./CourseNotificationListModal";

interface Props {
  courseData?: {
    id: string;
    name?: string;
    type?: CourseType;
  };
}

const formatEnrollmentRecords = (enrollment: Enrollment): string => {
  if (enrollment?.registration?.status === RegistrationsStatus.Reserved) {
    return `${enrollment?.registration?.status} from ${formatDate(
      enrollment?.startDate
    )} to ${formatDate(enrollment?.endDate)}`;
  }
  if (enrollment?.type === EnrollmentType.Regular) {
    return `${enrollment?.type} Enrollment from ${formatDate(
      enrollment?.startDate
    )} to ${formatDate(enrollment?.endDate)}`;
  }
  if (enrollment?.type === EnrollmentType.Trial) {
    return `${enrollment?.type} at ${formatDate(enrollment?.startDate)}`;
  }
  return "-";
};

const CollapseTable: FC<Props> = (props) => {
  const { courseData } = props;

  const { modal, show, close, setModalProps } = useConfirmModal();

  const [open, setOpen] = useState(false);

  const [getCourseNotifications, { loading, data, refetch }] =
    useLazyQueryCourseNotifications();

  const [deleteCourseNotification, { loading: deleteLoading }] =
    useDeleteCourseNotification();

  const { hasAllPermissions } = useAccessControl();

  const removeAction: Action = {
    icon: <IconClose className="w-3 h-3" />,
    label: "Remove Student",
    permissions: [EnumPermissions.COURSE_NOTIFICATION_LIST_UPDATE],
    onClick: (id: string) => {
      setModalProps({
        onClose: close,
        onConfirm: async () => {
          try {
            await deleteCourseNotification({
              variables: { id },
            });
            refetch?.();
            close?.();
          } catch (error) {
            message.error(formatErrorMessage(error));
          }
        },
      });
      show();
    },
  };

  const optionActions = [removeAction].filter((i) =>
    hasAllPermissions(i.permissions)
  );

  const columns: ColumnsType<
    CourseNotificationsQuery["courseNotifications"]["items"][0]
  > = [
    {
      title: "Student Name",
      dataIndex: "student.name",
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.M_MEDIUM],
      render: (text, record) => (
        <div className="flex flex-col">
          <StudentLink id={record?.student?.id}>
            {record?.student?.fullName}
          </StudentLink>
        </div>
      ),
    },
    {
      title: "Student ID",
      dataIndex: "student.userId",
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render: (text, record) => <>{record?.student?.userId || "-"}</>,
    },
    {
      title: "Primary Parent Name",
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.M_MEDIUM],
      render(value, record) {
        const { student } = record;
        return <>{student?.primaryParent?.fullName || "-"}</>;
      },
    },
    {
      title: formatLabel("Primary Parent Mobile"),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(value, record) {
        const { student } = record || {};
        return (
          <>
            {student?.primaryParent?.mobile
              ? formatPhoneNumber(student?.primaryParent?.mobile)
              : "-"}
          </>
        );
      },
    },
    {
      title: "Primary Parent WA Line",
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(value, record) {
        const { student } = record;
        const { linkedWhatsappAccount } = student?.primaryParent || {};

        return linkedWhatsappAccount ? (
          <span className="font_regular">
            {`[${linkedWhatsappAccount?.number?.number?.slice(0, 4)}]`}
          </span>
        ) : (
          <>-</>
        );
      },
    },
    {
      title: "Secondary Parent Name",
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.M_MEDIUM],
      render(value, record) {
        const { student } = record;
        const secondaryParents = student?.parents?.filter(
          (i) => i.id !== student?.primaryParent?.id
        );
        if (!secondaryParents?.length) {
          return <>-</>;
        }
        return (
          <>
            {secondaryParents?.map((parent) => (
              <div>{parent?.fullName || "-"}</div>
            ))}
          </>
        );
      },
    },
    {
      title: formatLabel("Secondary Parent Mobile"),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(value, record) {
        const { student } = record || {};
        const secondaryParents = student?.parents?.filter(
          (i) => i.id !== student?.primaryParent?.id
        );
        if (!secondaryParents?.length) {
          return <>-</>;
        }
        return (
          <>
            {secondaryParents?.map((i) => (
              <div>{i?.mobile ? formatPhoneNumber(i?.mobile) : "-"}</div>
            ))}
          </>
        );
      },
    },
    {
      title: "Secondary Parent WA Line",
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(value, record) {
        const { student } = record;
        const secondaryParents = student?.parents?.filter(
          (i) => i.id !== student?.primaryParent?.id
        );
        if (!secondaryParents?.length) {
          return <>-</>;
        }

        const accounts = uniqBy(
          secondaryParents
            ?.map((i) => i?.linkedWhatsappAccount)
            ?.filter(Boolean) || [],
          (account: any) => account?.id
        );

        if (!accounts?.length) {
          return <>-</>;
        }

        return (
          <>
            {accounts?.map((i) => (
              <div>{i ? `[${i?.number?.number?.slice(0, 4)}]` : "-"}</div>
            ))}
          </>
        );
      },
    },
    {
      title: "Waiting Year",
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(value, record) {
        const { academicYear } = record;
        return <>{academicYear?.year || "-"}</>;
      },
    },
    {
      title: "Enrollment Records",
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.L_LARGE],
      render: (text, record) => {
        const { enrollments = [] } = record;
        if (!enrollments?.length) {
          return <>-</>;
        }
        return (
          <>
            {enrollments?.map((i) => (
              <div>
                {i?.class?.shorthand}
                <Divider
                  type="vertical"
                  className="border-primary-navyDark mx-1"
                />
                {formatEnrollmentRecords(i as unknown as Enrollment)}
              </div>
            ))}
          </>
        );
      },
    },
    {
      title: "New Class",
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(value, record) {
        const { newClasses } = record;
        if (!newClasses?.length) {
          return <>-</>;
        }
        return (
          <>
            {newClasses?.map((i) => (
              <div>
                <ClassLink classId={i?.id}>{`${i.shorthand}`}</ClassLink>
              </div>
            ))}
          </>
        );
      },
    },
    {
      title: "Date Created",
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(value, record) {
        return (
          <div>
            {record?.createDateTime
              ? `${formatDate(record?.createDateTime, {
                  format: "SHORT",
                })}, ${formatTime(record?.createDateTime)}`
              : "-"}
          </div>
        );
      },
    },
  ];

  if (optionActions.length > 0) {
    columns.push({
      title: "Options",
      align: "center",
      render: (text, record) => (
        <DropdownAction actions={optionActions} id={record?.id} />
      ),
    });
  }

  const handleChange = (key: string[]): void => {
    if (key.length > 0) {
      getCourseNotifications({
        variables: {
          filterInput: { courseIds: key },
        },
      });
    }
  };

  useEffect(() => {
    setModalProps({ confirmLoading: deleteLoading });
  }, [deleteLoading]);

  return (
    <>
      <CollapseList.TableItem
        panelKey={courseData?.id}
        dataSource={data?.courseNotifications?.items || []}
        header={
          <div className="w-10/12 truncate leading-7">{courseData?.name}</div>
        }
        className="mb-6"
        columns={columns}
        extra={
          <AuthorizedByPermission
            permissions={[EnumPermissions.COURSE_NOTIFICATION_LIST_UPDATE]}
            authHandleType={EnumAuthHandleType.HIDE}
          >
            <Button
              type="primary"
              className="ml-2"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setOpen(true);
              }}
            >
              {<PlusCircleFilled className="mr-2" />}
              <span>Add Student</span>
            </Button>
          </AuthorizedByPermission>
        }
        loading={loading}
        onChange={handleChange}
      />
      {modal}
      <CourseNotificationListModal
        visible={open}
        onCancel={() => {
          setOpen(false);
        }}
        courseData={courseData}
        refetch={refetch}
      />
    </>
  );
};

export default memo(CollapseTable);
