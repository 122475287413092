import { PaymentMode } from "@/graphql";

export enum PaymentMethod {
  Cash = "Cash",
  CreditCard = "Credit Card",
  BankTransfer = "Bank Transfer",
  ATMTransfer = "ATM Transfer",
  /**
   * @deprecated Use PayNow (Stripe)/PayNow (Bank) instead
   */
  PayNow = "Pay Now",
  PayNowStripe = "PayNow (Stripe)",
  PayNowBank = "PayNow (Bank)",
  DBSMax = "DBS MAX!",
  Cheque = "Cheque",
  Credit = "Credit",

  CreditCardStripe = "Credit Card (Stripe)",
  GrabPayStripe = "GrabPay (Stripe)",
}

export const PaymentModeMap = {
  [PaymentMode.Cash]: PaymentMethod.Cash,
  [PaymentMode.CreditCard]: PaymentMethod.CreditCard,
  [PaymentMode.BankTransfer]: PaymentMethod.BankTransfer,
  [PaymentMode.AtmTransfer]: PaymentMethod.ATMTransfer,
  [PaymentMode.PayNow]: PaymentMethod.PayNow,
  [PaymentMode.PayNowStripe]: PaymentMethod.PayNowStripe,
  [PaymentMode.PayNowBank]: PaymentMethod.PayNowBank,
  [PaymentMode.DbsMax]: PaymentMethod.DBSMax,
  [PaymentMode.Cheque]: PaymentMethod.Cheque,
  [PaymentMode.Credit]: PaymentMethod.Credit,
  [PaymentMode.CreditCardStripe]: PaymentMethod.CreditCardStripe,
  [PaymentMode.GrabPayStripe]: PaymentMethod.GrabPayStripe,
};

export interface InvoicePaymentCommand {
  invoiceIds: string[];

  method: PaymentMethod;

  balanceAmount: number;

  amount: number;

  depositDate?: string;

  paymentDate: string;

  remarks?: string;

  reference?: string;

  bankName?: string;

  chequeNumber?: string;

  paymentProcessor?: string;

  lastDigits?: string;

  isManual?: boolean;
}
