import { useEffect, useState } from "react";

export const useCheckAmount = (
  val?: string
): [string, (value: string, callback?: (value: string) => void) => void] => {
  const [state, setState] = useState<string>(val);

  useEffect(() => {
    setState(val);
  }, [val]);

  const onCheckAmount = (
    value: string,
    callback?: (value: string) => void
  ): void => {
    let checkPlan = value?.toString();
    checkPlan = checkPlan?.toString()?.replace(/[^\d.]/g, "");
    checkPlan = checkPlan?.toString()?.replace(/^\./g, "");
    checkPlan = checkPlan?.toString()?.replace(/\.{2,}/g, "");
    checkPlan = checkPlan
      ?.toString()
      ?.replace(".", "$#$")
      .replace(/\./g, "")
      .replace("$#$", ".");
    checkPlan = checkPlan
      ?.toString()
      // eslint-disable-next-line no-useless-escape
      ?.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3");

    callback(checkPlan);
    setState(checkPlan);
  };
  return [state, onCheckAmount];
};

export const useCheckInteger = (
  val?: string
): [string, (value: string, callback?: (value: string) => void) => void] => {
  const [state, setState] = useState<string>(val);
  useEffect(() => {
    setState(val);
  }, [val]);

  const onCheckInteger = (
    value: string,
    callback?: (value: string) => void
  ): void => {
    let checkPlan = value?.toString();
    checkPlan = checkPlan?.toString()?.replace(/[^\d]/g, "");
    callback(checkPlan);
    setState(checkPlan);
  };
  return [state, onCheckInteger];
};
