import { AttendanceStatus, CapacitySeatType } from "@/graphql";
import {
  SeatBgColor,
  SeatColor,
  SeatTextColor,
} from "../NewClassCapacity/classType";

export type AttendanceCapacityView = {
  status?: AttendanceStatus;
  label?: string;
  color?: string;
  bgColor?: string;
  textColor?: string;
  template?: string;
  hasPaid?: boolean;
  text?: string;
};

export const attendanceHoldingCapacity = {
  status: AttendanceStatus.Holding,
  label: "Holding",
  color: SeatColor.Holding,
  bgColor: SeatBgColor.Holding,
  textColor: SeatTextColor.Holding,
  text: "Holding",
};

export const attendanceReservedCapacity = {
  status: AttendanceStatus.Unmarked,
  label: "Reservation",
  template: "Reserved on {{date}}",
  color: SeatColor.Reservation,
  bgColor: SeatBgColor.Reservation,
  textColor: SeatTextColor.Reservation,
  text: "Reserved",
};

export const attendanceRequestCapacity = {
  status: AttendanceStatus.Unmarked,
  label: "Request",
  template: "Request",
  color: SeatColor.Request,
  bgColor: SeatColor.Request,
  textColor: SeatColor.Request,
  text: "Request",
};

export const attendanceWaitingListCapacity = {
  label: "Waiting List",
  template: "Waiting List",
  color: SeatColor.WaitingList,
  bgColor: SeatBgColor.WaitingList,
  textColor: SeatTextColor.WaitingList,
  text: "Waiting List",
  hasPaid: true,
};

export const attendancePaidCapacity = {
  [`${CapacitySeatType.Regular}`]: [
    {
      label: "Regular",
      color: SeatColor.Regular,
      template: "Regular",
      hasPaid: true,
      checkFirstLesson: true,
    },
    {
      label: "Incoming",
      color: SeatColor.Paid,
      template: "Start on {{date}}",
      bgColor: SeatBgColor.Paid,
      textColor: SeatTextColor.Paid,
      hasPaid: true,
      checkFirstLesson: false,
      text: "Paid",
    },
  ],
};

export const attendanceUnpaidCapacity = {
  status: AttendanceStatus.Unmarked,
  label: "Incoming",
  color: SeatColor.Unpaid,
  bgColor: SeatBgColor.Unpaid,
  textColor: SeatTextColor.Unpaid,
  template: "Start on {{date}}",
  text: "Unpaid",
};

export const attendanceRegularCapacity = {
  [`${CapacitySeatType.Withdrawing}`]: {
    status: AttendanceStatus.Unmarked,
    label: "Withdrawn",
    template: "Withdrawn on {{date}}",
    color: SeatColor.Withdraw,
    bgColor: SeatBgColor.Withdraw,
    textColor: SeatTextColor.Withdraw,
    text: "Withdrawn",
  },

  [`${CapacitySeatType.TransferOut}`]: {
    status: AttendanceStatus.Unmarked,
    label: "Transfer Out",
    template: "Transferred Out on {{date}}",
    color: SeatColor.TransferOut,
    bgColor: SeatBgColor.TransferOut,
    textColor: SeatTextColor.TransferOut,
    text: "Transferred Out",
  },

  [`${CapacitySeatType.TransferIn}`]: {
    status: AttendanceStatus.Unmarked,
    label: "Transfer In",
    template: "Transferring In on {{date}}",
    color: SeatColor.TransferIn,
    bgColor: SeatBgColor.TransferIn,
    textColor: SeatTextColor.TransferIn,
    text: "Transferring In",
    hasPaid: true,
  },
};

export const attendanceCapacity = {
  [`${CapacitySeatType.Trial}`]: {
    status: AttendanceStatus.Unmarked,
    label: "Trial",
    color: SeatColor.Trial,
    bgColor: SeatBgColor.Trial,
    textColor: SeatTextColor.Trial,
    text: "Trial",
  },

  [`${CapacitySeatType.Additional}`]: {
    status: AttendanceStatus.Unmarked,
    label: "Additional",
    color: SeatColor.Additional,
    bgColor: SeatBgColor.Additional,
    textColor: SeatTextColor.Additional,
    text: "Additional Lesson",
  },
  [`${CapacitySeatType.MakeupIn}`]: {
    status: AttendanceStatus.Unmarked,
    label: "Replacement",
    color: SeatColor.MakeUpIn,
    bgColor: SeatBgColor.MakeUpIn,
    textColor: SeatTextColor.MakeUpIn,
    text: "Replacement",
  },
};
