import { ReactComponent as IconDropUp } from "@/assets/images/IconDropUp.svg";
import { ReactComponent as IconDropDown } from "@/assets/images/IconDropdown.svg";
import {
  GetActivitiesBySourceIds,
  GetActivitiesBySourceIdsQuery,
  GetActivitiesBySourceIdsQueryVariables,
} from "@/graphql";
import { useLoadMore } from "@/hooks/useLoadMore";
import { Skeleton } from "antd";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { renderActivity } from "./Utils";

interface Props {
  cacheKey?: string;
  sourceId: string;
}

const Activity: React.FC<Props> = (props) => {
  const [open, setOpen] = useState(true);
  const { sourceId, cacheKey } = props;
  const [
    queryActivities,
    { data: ActivityData, loading },
    // { loadMore, loadMoreLoading },
  ] = useLoadMore<
    GetActivitiesBySourceIdsQuery,
    GetActivitiesBySourceIdsQueryVariables
  >(GetActivitiesBySourceIds, "activityLogs", false, false, false, {
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    if (sourceId) {
      queryActivities({
        variables: {
          query: {
            sourceIds: [sourceId],
          },
          pageInfo: {
            pageIndex: 0,
            pageSize: 0,
          },
        },
      });
    }
  }, [sourceId, cacheKey]);

  return (
    <div className="flex flex-col">
      <div
        className="flex justify-between border-solid border-b h-14 items-center"
        onClick={() => setOpen(!open)}
      >
        <div className="font-semibold">Activity</div>
        <div className="px-6">
          {open ? (
            <IconDropUp className={`cursor-pointer`} />
          ) : (
            <IconDropDown className={`cursor-pointer`} />
          )}
        </div>
      </div>
      {open && (
        <div
          className={`flex flex-col gap-4 ${classNames({
            "py-6": loading || ActivityData?.activityLogs?.items?.length,
          })}`}
        >
          {loading ? (
            <Skeleton />
          ) : (
            ActivityData?.activityLogs?.items?.map(renderActivity)
          )}
        </div>
      )}
    </div>
  );
};

export default Activity;
