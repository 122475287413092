import {
  GetClassesForImportRegistration,
  GetClassesForImportRegistrationQuery,
  GetClassesForImportRegistrationQueryVariables,
} from "@/graphql";
import { useQuery } from "@apollo/client";
import { uniqBy } from "lodash";
import { useState } from "react";
import {
  EnumImportRegistrationTempTitle,
  ImportRegistrationRecord,
} from "./Config";

export type ClassForValidate =
  GetClassesForImportRegistrationQuery["classes"]["items"][0];
export const useClassListToValidate = (
  registrationData: ImportRegistrationRecord[]
) => {
  const [classList, setClassList] = useState<ClassForValidate[]>([]);
  const { refetch: queryClasses } = useQuery<
    GetClassesForImportRegistrationQuery,
    GetClassesForImportRegistrationQueryVariables
  >(GetClassesForImportRegistration, {
    skip: true,
    variables: {
      pageInfo: {
        pageSize: 0,
        pageIndex: 0,
      },
    },
  });

  const clearClassList = () => {
    setClassList([]);
  };

  const mergeClassListUniq = (classes: ClassForValidate[]) => {
    setClassList((prevStudentList) =>
      uniqBy([...classes, ...prevStudentList], "id")
    );
  };

  const queryInitialClasses = async () => {
    const classCodes = registrationData
      .map(
        (registration) =>
          registration[EnumImportRegistrationTempTitle.ClassCode]
      )
      .filter(Boolean);
    if (!classCodes?.length) return;

    const dataWithClassCodes = await queryClasses({
      filterInput: {
        classCodes,
      },
    });

    const studentWithStudentId = dataWithClassCodes?.data?.classes?.items || [];
    mergeClassListUniq(studentWithStudentId);
  };

  return {
    classList,
    mergeClassListUniq,
    clearClassList,
    queryClasses,
    queryInitialClasses,
  };
};
