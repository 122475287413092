import { CurrentAdminContext } from "@/components/navigation/SideBar/UserContent";
import { useChangeAdminPassword } from "@/components/users/UserHooks";
import AdminLink from "@/components/widget/CommonLink/AdminLink";
import { UploadFilePath } from "@/graphql";
import formatErrorMessage from "@/utils/formatErrorMessage";
import {
  Col,
  message,
  Row,
  Space,
  Spin,
  Typography,
} from "@thepiquelab/archus-components-web";
import React, { useContext } from "react";
import AvatarUpload from "../../common/AvatarUpload";

const UserSettings: React.FC = () => {
  const { user } = useContext(CurrentAdminContext);
  const [changeAdminPassword, { loading }] = useChangeAdminPassword();

  const handleUpdateAvatar = async (url: string): Promise<void> => {
    if (!url) return;
    try {
      // await updateAdmin({
      //   variables: {
      //     command: {
      //       id: user.adminId,
      //       avatarUrl: url,
      //     },
      //   },
      // });
    } catch (error) {
      message.error(formatErrorMessage(error));
    }
  };

  const resetPassword = async (): Promise<void> => {
    try {
      await changeAdminPassword({
        variables: {
          id: user.adminId,
        },
      });
      message.info(
        "A link to reset password has been sent to your registered Email."
      );
    } catch (error) {
      message.error(formatErrorMessage(error));
    }
  };
  return (
    <Spin spinning={loading}>
      <Row className="w-full" wrap={false}>
        <Col flex="48px">
          <AvatarUpload
            size={48}
            avatarUrl={user?.avatar}
            path={UploadFilePath.ImagesUser}
            onUploaded={(url) => {
              handleUpdateAvatar(url);
            }}
          />
        </Col>
        <Col flex="auto" className="ml-6">
          <Row wrap={false} className="w-full" gutter={[16, 0]}>
            <Col span={10}>
              <Space direction="vertical" size={4}>
                <Typography.Heading level={4} data-testid="fullNameLabel">
                  Full Name
                </Typography.Heading>
                <AdminLink id={user?.adminId} data-testid="fullName">
                  {user?.fullName || ""}
                </AdminLink>
              </Space>
            </Col>
            <Col span={14}>
              <Space direction="vertical" size={4}>
                <Typography.Heading level={4}>Department</Typography.Heading>
                <Typography.Text size="middle">
                  {user?.department || ""}
                </Typography.Text>
              </Space>
            </Col>
          </Row>

          <Row wrap={false} className="w-full my-6" gutter={[16, 0]}>
            <Col span={10}>
              <Space direction="vertical" size={4}>
                <Typography.Heading level={4}>Contact No.</Typography.Heading>
                <Typography.Text size="middle">
                  {user?.mobile || ""}
                </Typography.Text>
              </Space>
            </Col>
            <Col span={14}>
              <Space direction="vertical" size={4}>
                <Typography.Heading level={4}>Email</Typography.Heading>
                <Typography.Text size="middle">
                  {user?.email || ""}
                </Typography.Text>
              </Space>
            </Col>
          </Row>

          <span
            className="font_semibold text-primary-blue cursor-pointer"
            onClick={resetPassword}
          >
            Reset Password
          </span>
        </Col>
      </Row>
    </Spin>
  );
};

export default UserSettings;
