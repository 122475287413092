import { WatchQueryFetchPolicy } from "@apollo/client/core/watchQueryOptions";
import React from "react";
import { Link } from "react-router-dom";
import { useSalutation } from "../../../hooks/reference.hook";
import UserAvatar from "../../common/UserAvatar/UserAvatar";
import OverlappedIcon from "../../lesson/OverlappedIcon";

interface TeacherCellProps {
  profile: {
    id?: string;
    fullName: string;
    gender?: string;
    avatarUrl?: string;
    salutation?: string;
    initials?: string;
    colorCode?: string;
  };
  avatarSize?: number;
  isShowInitials?: boolean;
  isShowOverlapped?: boolean;
  teacherNameClass?: string;
  className?: string;
  isShowColorCode?: boolean;
  avatarHidden?: boolean;
  description?: string;
  allowJump?: boolean;
  fetchPolicy?: WatchQueryFetchPolicy;
}

export const TeacherCell: React.FC<TeacherCellProps> = (props) => {
  const {
    profile,
    avatarSize = 36,
    isShowInitials = true,
    teacherNameClass,
    isShowOverlapped,
    className,
    isShowColorCode = false,
    avatarHidden,
    description,
    allowJump = true,
    fetchPolicy = "cache-first",
  } = props;

  const { formatSalutation } = useSalutation();

  if (!profile?.id) {
    return <div>To Be Assigned</div>;
  }
  const { fullName, salutation, gender, avatarUrl, initials, colorCode, id } =
    profile;

  return (
    <div className={`flex justify-between ${className}`}>
      <div className="flex space-x-2 items-center">
        {/* TODO: different behaviour by size */}

        {!avatarHidden &&
          (id && allowJump ? (
            <Link to={`/system-user/profile/${id}`} target="_blank">
              <UserAvatar
                name={fullName}
                gender={gender}
                avatarUrl={avatarUrl}
                size={avatarSize}
              />
            </Link>
          ) : (
            <UserAvatar
              name={fullName}
              gender={gender}
              avatarUrl={avatarUrl}
              size={avatarSize}
            />
          ))}

        <div>
          <div
            className={`flex flex-no-wrap justify-around ${teacherNameClass} `}
          >
            {id && allowJump ? (
              <Link
                className={`${
                  isShowOverlapped ? "text-red-400" : "text-primary-blue"
                } font_regular`}
                to={`/system-user/profile/${id}`}
                target="_blank"
              >
                {formatSalutation(salutation, fullName)}
              </Link>
            ) : (
              <span
                className={`${
                  isShowOverlapped ? "text-red-400" : ""
                } font_semibold`}
              >
                {formatSalutation(salutation, fullName)}
              </span>
            )}
            {isShowOverlapped ? (
              <>
                &nbsp;&nbsp;
                <OverlappedIcon />
              </>
            ) : null}
          </div>
          {isShowInitials && (
            <div className="text-primary-navyLight mt-1">
              {`${description || initials}`}
            </div>
          )}
        </div>
      </div>
      {isShowColorCode ? (
        <>
          {colorCode && (
            <div
              className="w-4 h-4 rounded-full"
              style={{ background: colorCode }}
            />
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
