import {
  GetStudentsForImportRegistration,
  GetStudentsForImportRegistrationQuery,
  GetStudentsForImportRegistrationQueryVariables,
  StudentBaseFieldsFragment,
} from "@/graphql";
import { useQuery } from "@apollo/client";
import { uniqBy } from "lodash";
import { useState } from "react";
import {
  EnumImportRegistrationTempTitle,
  ImportRegistrationRecord,
} from "./Config";

export const useStudentListToValidate = (
  registrationData: ImportRegistrationRecord[]
) => {
  const [studentList, setStudentList] = useState<StudentBaseFieldsFragment[]>(
    []
  );
  const { refetch: queryStudents } = useQuery<
    GetStudentsForImportRegistrationQuery,
    GetStudentsForImportRegistrationQueryVariables
  >(GetStudentsForImportRegistration, {
    skip: true,
    variables: {
      pageInfo: {
        pageSize: 0,
        pageIndex: 0,
      },
    },
  });

  const clearStudentList = () => {
    setStudentList([]);
  };

  const mergeStudentListUniq = (students: StudentBaseFieldsFragment[]) => {
    setStudentList((prevStudentList) =>
      uniqBy([...students, ...prevStudentList], "id")
    );
  };
  const queryInitialStudents = async () => {
    let students: StudentBaseFieldsFragment[] = [];
    const studentNames = registrationData
      .map(
        (registration) =>
          registration[EnumImportRegistrationTempTitle.StudentName]
      )
      .filter(Boolean);
    const userIds = registrationData
      .map(
        (registration) =>
          registration[EnumImportRegistrationTempTitle.StudentID]
      )
      .filter(Boolean);
    if (studentNames.length) {
      const dataWithStudentName = await queryStudents({
        filterInput: {
          studentNames,
        },
      });
      const studentWithStudentName =
        dataWithStudentName?.data?.students?.items || [];
      // mergeStudentListUniq([...studentWithStudentName]);
      students = [...studentWithStudentName, ...students];
    }

    if (userIds?.length) {
      const dataWithStudentId = await queryStudents({
        filterInput: {
          userIds,
        },
      });
      const studentWithStudentId =
        dataWithStudentId?.data?.students?.items || [];
      students = [...studentWithStudentId, ...students];
    }
    mergeStudentListUniq([...students]);
  };

  return {
    studentList,
    mergeStudentListUniq,
    clearStudentList,
    queryStudents,
    queryInitialStudents,
  };
};
