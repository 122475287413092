import { FC, ReactElement } from "react";
import { Parent, School, Student } from "../../../../graphql";
import formatLabel from "../../../../utils/formatLabel";

export interface ContactInfo {
  title: string;
  fullName?: string;
  line1?: string;
  alignRight?: boolean;
}

export const Contact = ({
  title,
  fullName,
  line1,
  alignRight,
}: ContactInfo): ReactElement => (
  <div className={`${alignRight ? "text-right" : "text-left"}`}>
    <div className="text-primary-navyLight mb-1 font-semibold">
      {formatLabel(title)}
    </div>
    {fullName && (
      <div className="text-primary-navyDark typography_heading mb-1">
        {fullName}
      </div>
    )}
    {line1 && (
      <div className="text-primary-navyLight typography_body mb-1">{line1}</div>
    )}
  </div>
);

export interface BillContactProps {
  parent: Pick<Parent, "fullName">;
  student?: Pick<Student, "fullName" | "userId" | "alternateUserId"> & {
    primarySchool: Pick<School, "name">;
  };
  isNote?: boolean;
}

const BillContact: FC<BillContactProps> = (props) => {
  const { parent, isNote = false } = props;
  return (
    <div className="flex flex-no-wrap justify-between mt-6">
      <Contact
        title={isNote ? "NOTE TO" : "BILL TO"}
        fullName={parent?.fullName}
      />
    </div>
  );
};

export default BillContact;
