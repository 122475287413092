import {
  GetUserCustomiseColumns,
  GetUserCustomiseColumnsQuery,
  GetUserCustomiseColumnsQueryVariables,
  ResetDefault,
  ResetDefaultMutation,
  ResetDefaultMutationVariables,
  UpsertUserCustomiseColumns,
  UpsertUserCustomiseColumnsMutation,
  UpsertUserCustomiseColumnsMutationVariables,
} from "@/graphql";
import { MutationTuple, useLazyQuery, useMutation } from "@apollo/client";

export const useLazyQueryUserCustomiseColumns = () =>
  useLazyQuery<
    GetUserCustomiseColumnsQuery,
    GetUserCustomiseColumnsQueryVariables
  >(GetUserCustomiseColumns, {
    fetchPolicy: "network-only",
  });

export const useResetDefault = (): MutationTuple<
  ResetDefaultMutation,
  ResetDefaultMutationVariables
> =>
  useMutation<ResetDefaultMutation, ResetDefaultMutationVariables>(
    ResetDefault
  );

export const useUpsertUserCustomiseColumns = (): MutationTuple<
  UpsertUserCustomiseColumnsMutation,
  UpsertUserCustomiseColumnsMutationVariables
> =>
  useMutation<
    UpsertUserCustomiseColumnsMutation,
    UpsertUserCustomiseColumnsMutationVariables
  >(UpsertUserCustomiseColumns);
