import { Col } from 'antd';
import React from 'react';

export interface ContentProps {
  children?: React.ReactNode;
  className?: string;
}

const Content: React.FC<ContentProps> = (props) => {
  const { children, ...res } = props;
  return (
    <Col {...res} flex="auto">
      {children}
    </Col>
  );
};

export default Content;
