import { message } from "@thepiquelab/archus-components-web";

export default function downloadURI(uri: string, name: string) {
  const link = document.createElement("a");
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const download = (url: string, messageKey?: string): void => {
  if (url) {
    const link = document.createElement("a");
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    message.success({
      content: "Download success!",
      key: messageKey || "download",
    });
  } else {
    message.error({
      content: "Export was unsuccessful!",
      key: messageKey || "download",
    });
  }
};
