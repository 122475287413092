import { Col } from 'antd';
import React from 'react';

export interface HeaderProps {
  children?: React.ReactNode;
  className?: string;
}

const Header: React.FC<HeaderProps> = (props) => {
  const { children, className = '', ...res } = props;
  return (
    <Col {...res} flex="0 0 auto" className={`${className} c-h-maxcontent`}>
      {children}
    </Col>
  );
};

export default Header;
