import { IMPORT_CLASSES_DATE_FORMAT } from "@/components/class/ImportClasses/Config";
import {
  CopyTaskListInput,
  CreateTaskListInput,
  GetTaskListQuery,
  UpdateTaskListInput,
} from "@/graphql";
import { enumToArray } from "@/utils/enumUnit";
import formatErrorMessage from "@/utils/formatErrorMessage";
import { WeekDay } from "@/utils/getWeeksOfYears";
import { graphqlValidator } from "@/utils/graphqlValidator";
import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  Modal,
  ModalButtonGroup,
  Row,
  Select,
  TimePicker,
  message,
} from "@thepiquelab/archus-components-web";
import { formatDate, formatTime } from "@thepiquelab/archus-library";
import Input from "antd/lib/input/Input";
import moment from "moment";
import { FC, ReactNode, useEffect } from "react";
import {
  useCopyTaskList,
  useCreateTaskList,
  useUpdateTaskList,
} from "../hooks";

export enum ActionType {
  Add = "Add",
  Edit = "Edit",
  Copy = "Copy",
}

interface OperationalTaskListModalProps {
  visible?: boolean;
  taskListData?: GetTaskListQuery["taskList"];
  onCancel?: () => void;
  refetch?: () => void;
  actionType: ActionType;
}

const formatStartAt = (date: string): string =>
  date ? moment(date).startOf("day").toISOString() : undefined;

const formatEndAt = (date: string): string =>
  date ? moment(date).endOf("day").toISOString() : undefined;

const OperationalTaskListModal: FC<OperationalTaskListModalProps> = (props) => {
  const { visible, taskListData, actionType, onCancel, refetch } = props;
  const [form] = Form.useForm();
  const isAutoGenerate = Form.useWatch("isAutoGenerate", form);
  const startAt = Form.useWatch("startAt", form);
  const weekDay = Form.useWatch("weekDay", form);
  const generateAt = Form.useWatch("generateAt", form);
  const [createTaskList, { loading: createLoading }] = useCreateTaskList();
  const [updateTaskList, { loading: updateLoading }] = useUpdateTaskList();
  const [copyTaskList, { loading: copyLoading }] = useCopyTaskList();

  useEffect(() => {
    form.setFieldsValue(formatInitialValue(taskListData));
  }, [taskListData]);

  const formatInitialValue = (
    taskList: GetTaskListQuery["taskList"]
  ): Partial<CreateTaskListInput> => {
    if (!taskList?.id) {
      return {};
    }
    const { parent } = taskList || {};

    const initData = parent?.id ? parent : taskList;

    return {
      name: taskList?.name,
      isAutoGenerate: initData?.isAutoGenerate,
      startAt: initData?.startAt ? moment(initData?.startAt) : undefined,
      endAt: initData?.endAt ? moment(initData?.endAt) : undefined,
      generateAt: initData?.generateAt
        ? moment(initData?.generateAt)
        : undefined,
      weekDay: initData?.weekDay,
    };
  };

  const handleFinish = (value: CreateTaskListInput): void => {
    if (actionType === ActionType.Edit && taskListData?.id) {
      handleUpdateTaskList({ ...value, id: taskListData.id });
    }
    if (actionType === ActionType.Add) {
      handleCreateTaskList(value);
    }
    if (actionType === ActionType.Copy) {
      handleCopyTaskList({ ...value, listId: taskListData.id });
    }
  };

  const handleCreateTaskList = async (
    input: CreateTaskListInput
  ): Promise<void> => {
    const { startAt: startDate, endAt: endDate, ...res } = input;
    try {
      await createTaskList({
        variables: {
          input: {
            ...res,
            isAutoGenerate: res?.isAutoGenerate ? res?.isAutoGenerate : false,
            startAt: formatStartAt(startDate),
            endAt: formatEndAt(endDate),
          },
        },
      });
      message.success("List added successfully!");
      refetch?.();
      handleClose();
    } catch (error) {
      if (error?.graphQLErrors) {
        graphqlValidator(error?.graphQLErrors, form);
      } else {
        message.error(formatErrorMessage(error));
      }
    }
  };

  const handleCopyTaskList = async (
    input: CopyTaskListInput
  ): Promise<void> => {
    const { startAt: startDate, endAt: endDate, ...res } = input;
    try {
      await copyTaskList({
        variables: {
          input: {
            ...res,
            isAutoGenerate: res?.isAutoGenerate ? res?.isAutoGenerate : false,
            startAt: formatStartAt(startDate),
            endAt: formatEndAt(endDate),
          },
        },
      });
      message.success("List added successfully!");
      refetch?.();
      handleClose();
    } catch (error) {
      if (error?.graphQLErrors) {
        graphqlValidator(error?.graphQLErrors, form);
      } else {
        message.error(formatErrorMessage(error));
      }
    }
  };

  const handleUpdateTaskList = async (
    input: UpdateTaskListInput
  ): Promise<void> => {
    const { startAt: startDate, endAt: endDate, name, ...res } = input;
    const { parent } = taskListData || {};

    const commonInput = {
      isAutoGenerate: res?.isAutoGenerate ? res?.isAutoGenerate : false,
      startAt: formatStartAt(startDate),
      endAt: formatEndAt(endDate),
    };

    const defaultInput: Omit<UpdateTaskListInput, "id"> = {
      isAutoGenerate: false,
      endAt: null,
      startAt: null,
      generateAt: null,
      weekDay: null,
    };

    let currentInput: UpdateTaskListInput = {
      ...res,
      name,
      ...commonInput,
    };

    try {
      if (parent?.id) {
        currentInput = { name, id: res?.id };

        await updateTaskList({
          variables: {
            input: !res?.isAutoGenerate
              ? { id: parent.id, ...defaultInput }
              : { ...res, id: parent.id, ...commonInput },
          },
        });
      }

      await updateTaskList({
        variables: {
          input: !res?.isAutoGenerate
            ? { name, id: res?.id, ...defaultInput }
            : currentInput,
        },
      });

      refetch?.();
      handleClose();
    } catch (error) {
      if (error?.graphQLErrors) {
        graphqlValidator(error?.graphQLErrors, form);
      } else {
        message.error(formatErrorMessage(error));
      }
    }
  };

  const handleClose = (): void => {
    form?.resetFields();
    onCancel?.();
  };

  const formatGenerateNode = (args: {
    startAt: string;
    weekDay: WeekDay;
    generateAt: string;
  }): ReactNode => {
    if (args?.startAt && args?.weekDay && args?.generateAt) {
      return (
        <div className="mt-6">
          {`Generate every ${args?.weekDay} starting ${formatTime(
            args?.generateAt
          )} ${args?.weekDay}, ${formatDate(args?.startAt, {
            format: "SHORT",
          })}`}
        </div>
      );
    }
    return <></>;
  };

  return (
    <Modal
      visible={visible}
      onClose={handleClose}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      destroyOnClose
    >
      <Modal.Header
        title={actionType === ActionType.Edit ? "Edit List" : "Add List"}
      />
      <Modal.Content>
        <Form form={form} layout="vertical" onFinish={handleFinish}>
          <Form.Item
            name={"name"}
            label="List Name"
            className="no-margin"
            rules={[{ required: true, message: "Field is required." }]}
          >
            <Input maxLength={200} />
          </Form.Item>
          <Form.Item
            name="isAutoGenerate"
            className="no-margin mt-3"
            label={null}
            valuePropName="checked"
          >
            <Checkbox>Automatically generate every week</Checkbox>
          </Form.Item>
          {isAutoGenerate ? (
            <>
              <Form.Item
                name={"startAt"}
                label="Start"
                className="mt-2"
                rules={[{ required: true, message: "Field is required." }]}
              >
                <DatePicker
                  className="w-full"
                  format={IMPORT_CLASSES_DATE_FORMAT}
                  getPopupContainer={(triggerNode: any) =>
                    triggerNode.parentNode
                  }
                />
              </Form.Item>
              <Form.Item
                name={"endAt"}
                label="End"
                rules={[{ required: true, message: "Field is required." }]}
              >
                <DatePicker
                  className="w-full"
                  format={IMPORT_CLASSES_DATE_FORMAT}
                  getPopupContainer={(triggerNode: any) =>
                    triggerNode.parentNode
                  }
                />
              </Form.Item>
              <Form.Item
                label="Generate on"
                className="no-margin"
                requiredMark
                required
              >
                <Row className="w-full">
                  <Col span={11}>
                    <Form.Item
                      name={"weekDay"}
                      className="no-margin"
                      rules={[
                        { required: true, message: "Field is required." },
                      ]}
                    >
                      <Select
                        allowClear
                        getPopupContainer={(triggerNode: any) =>
                          triggerNode.parentNode
                        }
                      >
                        {enumToArray(WeekDay).map((i) => (
                          <Select.Option key={i.key} value={i.value}>
                            {i.key}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={11} offset={2}>
                    <Form.Item
                      name={"generateAt"}
                      className="no-margin"
                      rules={[
                        { required: true, message: "Field is required." },
                      ]}
                    >
                      <TimePicker
                        use12Hours
                        hourStep={1}
                        format="hA"
                        className="w-full"
                        getPopupContainer={(triggerNode: any) =>
                          triggerNode.parentNode
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              {formatGenerateNode({ startAt, weekDay, generateAt })}
            </>
          ) : (
            <></>
          )}
        </Form>
      </Modal.Content>
      <Modal.Footer>
        <ModalButtonGroup
          okText={actionType === ActionType.Edit ? "Edit" : "Add"}
          cancelText="Cancel"
          onOK={form.submit}
          onCancel={handleClose}
          confirmLoading={createLoading || updateLoading || copyLoading}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default OperationalTaskListModal;
