import classNames from "classnames";
import React from "react";
import { Buttons } from "../Buttons";
import { ModalFooterProps } from "./types";

/**
 * Modal Footer component
 * that is rendered in the bottom section of Modal component
 *
 * @example Footer with primary and secondary button
 * ```
 * <Modal.Footer
 *  primaryButton={{id: 'ok', label: 'OK'}}
 *  secondaryButtons={[
 *    {id: 'cancel', label: 'Cancel'},
 *    {id: 'reset', label: 'Reset'}
 *  ]}
 *  onButtonClick={(buttonData) => console.log('Button clicked with id: ', buttonData.id )}
 * />
 * ```
 *
 * @example Footer with custom button nodes
 * ```
 * <Modal.Footer
 *  customButtons={
 *    <>
 *      <Button onClick={handleOk}>OK</Button>
 *      <Button onClick={handleCancel}>Cancel</Button>
 *    </>
 *  }
 * />
 * ```
 */
const ModalFooter: React.FC<ModalFooterProps> = ({
  className,
  children,
  primaryButton,
  secondaryButtons = [],
  customButtons,
  onButtonClick,
  ...props
}) => {
  const classes = classNames(
    className,
    "common-modal__footer flex items-center"
  );

  const buttons = React.useMemo(() => {
    if (!primaryButton && !secondaryButtons.length) return undefined;
    return [...(primaryButton ? [primaryButton] : []), ...secondaryButtons];
  }, [primaryButton, secondaryButtons]);

  return (
    <div className={classes} {...props}>
      <Buttons buttons={buttons} onClick={onButtonClick}>
        {customButtons}
      </Buttons>
      {children}
    </div>
  );
};

export default ModalFooter;
