import { ReactComponent as IconDelete } from "@/assets/images/IconDelete.svg";
import { useConfirmModal } from "@/components/common/ConfirmModal/NewConfirmModal";
import DropdownAction, {
  Action,
} from "@/components/common/DropdownAction/DropdownAction";
import {
  RemarkType,
  TaskFieldsFragment,
  TaskStatus,
  UpdateRemark,
  UpdateRemarkMutation,
  UpdateRemarkMutationVariables,
} from "@/graphql";
import { useCreateRemark, useDeleteRemark } from "@/hooks/remarksHooks";
import formatErrorMessage from "@/utils/formatErrorMessage";
import formatLabel from "@/utils/formatLabel";
import { EllipsisOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { Button, Form, Input, Spin, message } from "antd";
import { trim } from "lodash";
import moment from "moment";
import React, { useState } from "react";
import AssigneeAvatar from "../TaskDetail/AssigneeAvatar";

interface Props {
  task?: TaskFieldsFragment;
  callback?: () => void;
}

const TaskRemarks: React.FC<Props> = (props) => {
  const { task, callback } = props;
  const isReadonly = [TaskStatus.Complete, TaskStatus.Void].includes(
    task?.status
  );
  const [remarkIdToEdit, setRemarkIdToEdit] = useState("");
  const [idsIsDeleting, setIdsIsDeleting] = useState<string[]>([]);
  const toggleIdsIsDeleting = (currentId: string): void => {
    if (idsIsDeleting?.includes(currentId)) {
      setIdsIsDeleting(idsIsDeleting?.filter((id) => id !== currentId));
    } else {
      setIdsIsDeleting([...idsIsDeleting, currentId]);
    }
  };

  const [editForm] = Form.useForm();
  const [addForm] = Form.useForm();

  const { modal, show, close, setModalProps } = useConfirmModal();

  const [createRemark, { loading: createRemarkLoading }] = useCreateRemark({});
  const [deleteRemark, { loading: deleteRemarkLoading }] = useDeleteRemark();

  const [updateRemark, { loading: updateLoading }] = useMutation<
    UpdateRemarkMutation,
    UpdateRemarkMutationVariables
  >(UpdateRemark);

  const addOrUpdateRemark = async () => {
    if (
      trim(addForm.getFieldValue("remarkComment"))?.length &&
      !remarkIdToEdit
    ) {
      try {
        await createRemark({
          variables: {
            input: {
              subjectId: task?.id,
              type: RemarkType.Task,
              content: trim(addForm.getFieldValue("remarkComment")),
            },
          },
        });
        addForm.setFieldValue("remarkComment", "");
        callback?.();
      } catch (error) {
        message.error(formatErrorMessage(error));
      }
    }
    if (
      trim(editForm.getFieldValue("remarkComment"))?.length &&
      remarkIdToEdit
    ) {
      try {
        await updateRemark({
          variables: {
            input: {
              id: remarkIdToEdit,
              content: trim(editForm.getFieldValue("remarkComment")),
            },
          },
        });
        editForm.setFieldValue("remarkComment", "");
        setRemarkIdToEdit("");
        callback?.();
      } catch (error) {
        message.error(formatErrorMessage(error));
      }
    }
  };

  const deleteAction: Action = {
    icon: <IconDelete className="w-4" />,
    label: "Delete Remark",
    onClick: (id: string) => {
      setModalProps({
        title: "Confirmation",
        contentDesc: "Are you sure you want to proceed?",
        onConfirm: async () => {
          try {
            toggleIdsIsDeleting(id);
            await deleteRemark({
              variables: {
                input: {
                  id,
                },
              },
            });
            toggleIdsIsDeleting(id);
            close?.();
          } catch (error) {
            message.error(formatErrorMessage(error));
            toggleIdsIsDeleting(id);
          }
        },
        onClose: close,
      });
      show();
    },
  };

  if (!task) {
    return <></>;
  }

  return (
    <div className="flex flex-col gap-3">
      <div className="font-semibold">Remarks</div>
      <div className="border-solid border-t py-6 flex flex-col gap-6">
        {task?.remarks?.length > 0 && (
          <div className="flex flex-col gap-4">
            {task?.remarks?.map((item) => (
              <div
                key={item?.id}
                onClick={() => {
                  if (isReadonly) return;
                  if (!remarkIdToEdit) {
                    setRemarkIdToEdit(item?.id);
                    editForm.setFieldValue("remarkComment", item?.content);
                  }
                }}
                className="flex gap-4"
              >
                <div className="flex gap-2 justify-between content-between place-content-between flex-grow">
                  <AssigneeAvatar
                    assignee={{
                      _id: item?.lastModifiedBy?.id,
                      initials: item?.lastModifiedBy?.alias,
                      fullName: item?.lastModifiedBy?.fullName,
                      avatarUrl: item?.lastModifiedBy?.avatarUrl,
                    }}
                  />
                  <div className="flex flex-col gap-2 flex-grow">
                    <div className="flex gap-2">
                      <div className="font-semibold">
                        {item?.lastModifiedBy?.fullName}
                      </div>
                      <div>{moment(item?.lastModifiedDateTime).fromNow()}</div>
                      <div>
                        {moment(item?.createDateTime).isBefore(
                          moment(item?.lastModifiedDateTime)
                        )
                          ? "(Edited)"
                          : ""}
                      </div>
                    </div>
                    {remarkIdToEdit !== item?.id && <div>{item?.content}</div>}
                    {remarkIdToEdit === item?.id && (
                      <div className="flex flex-col">
                        <Form
                          form={editForm}
                          onFinish={() => {
                            addOrUpdateRemark();
                          }}
                        >
                          <Form.Item
                            name="remarkComment"
                            rules={[
                              {
                                required: true,
                                message: formatLabel("Field is required."),
                              },
                            ]}
                          >
                            <Input.TextArea />
                          </Form.Item>
                        </Form>
                        <div className="flex flex-row-reverse gap-2">
                          <Button
                            type="primary"
                            onClick={() => {
                              editForm.submit();
                            }}
                            loading={updateLoading}
                          >
                            Save
                          </Button>
                          <Button
                            onClick={(e) => {
                              setRemarkIdToEdit("");
                              editForm.setFieldValue("remarkComment", "");
                              e?.stopPropagation();
                            }}
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {!isReadonly && remarkIdToEdit !== item?.id && (
                  <div className="flex items-center">
                    <DropdownAction id={item?.id} actions={[deleteAction]}>
                      <Spin
                        spinning={
                          deleteRemarkLoading &&
                          idsIsDeleting?.includes(item?.id)
                        }
                      >
                        <EllipsisOutlined />
                      </Spin>
                    </DropdownAction>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
        {!remarkIdToEdit && !isReadonly && (
          <div className="flex flex-col">
            <Form
              form={addForm}
              onFinish={() => {
                addOrUpdateRemark();
              }}
            >
              <Form.Item
                name="remarkComment"
                rules={[
                  {
                    required: true,
                    message: formatLabel("Field is required."),
                  },
                ]}
              >
                <Input.TextArea />
              </Form.Item>
            </Form>

            <div className="flex flex-row-reverse">
              <Button
                type="primary"
                onClick={() => {
                  addForm.submit();
                }}
                loading={createRemarkLoading}
              >
                Add Remark
              </Button>
            </div>
          </div>
        )}
      </div>
      {modal}
    </div>
  );
};

export default TaskRemarks;
