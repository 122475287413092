import { download } from "@/utils/download";
import formatErrorMessage from "@/utils/formatErrorMessage";
import { DocumentNode, useLazyQuery, useMutation } from "@apollo/client";
import { message } from "@thepiquelab/archus-components-web";
import { isEmpty } from "lodash";

const messageKey = "csv-export";
export const useExportCsv = <
  T extends {
    csv?: { url: string };
    exportAttendanceReportCsv?: { url: string };
    exportAttendanceCsv?: { url: string };
    exportPaymentReportCsv?: { url: string };
    exportInvoiceRevenueReportCsv?: { url: string };
    exportInvoiceReportCsv?: { url: string };
    exportStudentReportCsv?: { url: string };
    exportFailedImportClasses?: { url: string };
    exportFailedImportRegistrations?: { url: string };
  },
  K
>(
  documentNode: DocumentNode,
  options?: any
): {
  exportCsv: (query: K) => void;
  exportLoading: boolean;
} => {
  const onCompleteOrError = () => ({
    onCompleted(data: any) {
      const csvUrl =
        data?.csv?.url ||
        data?.exportAttendanceReportCsv?.url ||
        data?.exportAttendanceCsv?.url ||
        data?.exportPaymentReportCsv?.url ||
        data?.exportInvoiceRevenueReportCsv?.url ||
        data?.exportInvoiceReportCsv?.url ||
        data?.exportStudentReportCsv?.url ||
        data?.exportFailedImportClasses?.url ||
        data?.exportFailedImportRegistrations?.url;
      download(csvUrl, messageKey);
    },
    onError(e: Error) {
      message.error({ content: formatErrorMessage(e), key: messageKey });
    },
  });
  const [queryCsvExport, { loading }] = useLazyQuery<T, K>(documentNode, {
    fetchPolicy: "network-only",
    ...options,
    ...onCompleteOrError(),
  });

  const exportCsv = (query: K): void => {
    if (loading) {
      message.error("Please wait while file is processing.");
      return;
    }

    if (isEmpty(query)) {
      message.error("No Data for Export");
      return;
    }

    message.loading({
      content: "Downloading",
      key: messageKey,
      duration: 0,
    });
    queryCsvExport({
      variables: {
        ...query,
      },
    });
  };

  return { exportCsv, exportLoading: loading };
};

export const useExportCsvMutation = <
  T extends {
    exportFailedImportClasses?: { url: string };
    exportFailedImportRegistrations?: { url: string };
  },
  K
>(
  documentNode: DocumentNode,
  options?: any
): {
  exportCsv: (query: K) => void;
  exportLoading: boolean;
} => {
  const onCompleteOrError = () => ({
    onCompleted(data: any) {
      const csvUrl =
        data?.exportFailedImportClasses?.url ||
        data?.exportFailedImportRegistrations?.url;
      download(csvUrl, messageKey);
    },
    onError(e: Error) {
      message.error({ content: formatErrorMessage(e), key: messageKey });
    },
  });

  const [mutationCsvExport, { loading }] = useMutation<T, K>(documentNode, {
    ...options,
    ...onCompleteOrError(),
  });

  const exportCsv = (query: K): void => {
    if (loading) {
      message.error("Please wait while file is processing.");
      return;
    }

    if (isEmpty(query)) {
      message.error("No Data for Export");
      return;
    }

    message.loading({
      content: "Downloading",
      key: messageKey,
      duration: 0,
    });
    mutationCsvExport({
      variables: {
        ...query,
      },
    });
  };

  return { exportCsv, exportLoading: loading };
};
