import { Checkbox, Typography } from "@thepiquelab/archus-components-web";
import React, { useMemo } from "react";
import {
  GetReminderFormParentQuery,
  ReferenceDataCategory,
  ReminderType,
} from "../../../../graphql";
import { useReferenceDataMap } from "../../../../hooks/reference.hook";
import "./ReceiverCheckbox.scss";

interface Parent {
  id: string;
  salutation?: string;
  fullName: string;
  email?: string;
  alias?: string;
  mobile?: {
    countryCode: string;
    number: string;
  };
}

interface ReceiverCheckBoxProps {
  primaryParent: GetReminderFormParentQuery["parent"];
  parents?: Parent[];
  onChange?: (data: string[]) => void;
  reminderType: ReminderType;
  value?: string[];
  disabledPrimaryParent?: boolean;
}

const ReceiverCheckBox: React.FC<ReceiverCheckBoxProps> = ({
  primaryParent,
  parents = [],
  onChange,
  reminderType,
  value,
  disabledPrimaryParent = false,
}) => {
  const { Email } = ReminderType;
  const salutationMap = useReferenceDataMap(ReferenceDataCategory.Salutation);

  const restParents = useMemo(
    () => parents.filter((p) => p.id !== primaryParent?.id),
    [primaryParent, parents]
  );

  const whatsappNumber = primaryParent?.linkedWhatsappAccount?.number?.number;
  const whatsappLine = whatsappNumber
    ? `| [${whatsappNumber?.slice(0, 4)}]`
    : "";

  return (
    <Checkbox.Group
      onChange={onChange}
      value={value}
      className="notification-receiver-list"
    >
      {primaryParent && (
        <Checkbox
          className="flex flex-row items-start"
          value={primaryParent.id}
          key={primaryParent.id}
          disabled={disabledPrimaryParent}
        >
          <div className="flex flex-col">
            <Typography.Text className="font_semibold ml-2" size="middle">
              {`${salutationMap?.get(primaryParent.salutation) || ""} ${
                primaryParent.alias || primaryParent.fullName
              } - Primary Contact
            `}
            </Typography.Text>
            <Typography.Text className="font_regular ml-2 mt-2" size="middle">
              {`${primaryParent.fullName} | ${
                reminderType === Email
                  ? primaryParent.email || "-"
                  : `+${primaryParent.mobile?.countryCode} ${primaryParent.mobile?.number} ${whatsappLine}`
              }`}
            </Typography.Text>
          </div>
        </Checkbox>
      )}
      {restParents.map((parent) => {
        const { id, salutation, fullName, alias } = parent;

        return (
          <Checkbox
            className="flex flex-row mt-4 items-start"
            value={id}
            key={id}
          >
            <div className="flex flex-col">
              <Typography.Text className="font_semibold ml-2" size="middle">
                {`${salutationMap?.get(salutation) || ""} ${alias || fullName}`}
              </Typography.Text>
              <Typography.Text className="font_regular ml-2 mt-2" size="middle">
                {`${fullName} | ${
                  reminderType === Email
                    ? parent.email
                    : `+${parent.mobile?.countryCode} ${parent.mobile?.number}`
                }`}
              </Typography.Text>
            </div>
          </Checkbox>
        );
      })}
    </Checkbox.Group>
  );
};

export default ReceiverCheckBox;
