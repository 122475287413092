import { StepStatus } from "..";
import { InvoiceStatus, RegistrationsStatus } from "../../../../graphql";

// Check has paid full or partial, and if is void
export const hasPaid = (payments: Array<{ isVoid?: boolean }>): boolean => {
  if (!payments?.length) return false; // If has not payments, that's not paid

  const voidNumber = payments.filter((payment) => !!payment?.isVoid).length;

  if (voidNumber === payments.length) return false; // If void number equals to payments length, that's not paid

  return true;
};

export const obtainPaymentSubTitle = (
  invoiceStatus: InvoiceStatus,
  payments: Array<{ isVoid?: boolean }>
): string => {
  const isPaid = hasPaid(payments);

  if (
    (invoiceStatus === InvoiceStatus.Pending && isPaid) ||
    invoiceStatus === InvoiceStatus.Paid
  )
    return "Last payment";

  if (invoiceStatus === InvoiceStatus.Overdue && isPaid)
    return "Overdue, Last payment";

  if (invoiceStatus === InvoiceStatus.Overdue) return "Overdue";

  return "";
};

export const obtainAvailabilitySubTitle = (
  seatsAvailable: boolean,
  registrationStatus: RegistrationsStatus
): string => {
  if (seatsAvailable && registrationStatus === RegistrationsStatus.Expired)
    return "Expired";

  return seatsAvailable ? "Seats available" : "No seats available";
};

export const obtainApprovalSubTitle = (
  registrationStatus: RegistrationsStatus
): string => {
  if (registrationStatus === RegistrationsStatus.Reserved) return "Reserved";
  if (registrationStatus === RegistrationsStatus.Expired) return "Expired";

  return "";
};

export const obtainStatusByInvoice = (
  invoiceStatus: InvoiceStatus,
  payments: Array<{ isVoid?: boolean }>
): StepStatus => {
  const status: { [key in InvoiceStatus]?: StepStatus } = {
    [InvoiceStatus.Paid]: "finish",
    [InvoiceStatus.Overdue]: "error",
  };

  if (!status) return "wait";

  const stepStatus: StepStatus = status[invoiceStatus]
    ? status[invoiceStatus]
    : "wait";

  if (invoiceStatus === InvoiceStatus.Pending && hasPaid(payments))
    return "process";

  return stepStatus;
};
