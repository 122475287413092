import {
  calcRemaining,
  ClassSeatDetailDTO,
  filterSeatType,
} from "@/components/class/NewClassCapacity/classUtils";
import { handleFlatCapacity } from "@/components/registration/RegisterModal/RegistrationTypeModal/ListEnrollmentsInModal";
import { EnrollmentStatus, SeatType, SmsFieldsFragment } from "@/graphql";
import { isEqual, uniqWith } from "lodash";

export type SMSFragment = SmsFieldsFragment;

export const filterVenueName = (preset: SMSFragment[]): string[] =>
  [
    ...new Set<string>(
      preset?.map((p) =>
        p?.venue?.unitNo
          ? `#${p.venue?.unitNo} ${p.venue?.shorthand}`
          : p.venue?.shorthand
      )
    ),
  ]
    .sort()
    .reverse();

export const handleMergeVenueLesson = (
  preset: SMSFragment[]
): SMSFragment[][] => {
  const venueName = filterVenueName(preset);

  return venueName.reduce((total: SMSFragment[][], item) => {
    total.push(
      preset.filter((k) => {
        const name = k?.venue?.unitNo
          ? `#${k.venue?.unitNo} ${k?.venue?.shorthand}`
          : k?.venue?.shorthand;
        return name === item;
      })
    );
    return total;
  }, []);
};

export const filterHolding = (
  studentId: string,
  seat: ClassSeatDetailDTO[]
): ClassSeatDetailDTO[] =>
  seat?.filter((i) =>
    i?.student?.id === studentId
      ? i.enrollmentStatus !== EnrollmentStatus.Holding
      : true
  );

export const generateGmlSeatData = (
  info: SMSFragment,
  studentId?: string
): {
  regularSeats: Array<ClassSeatDetailDTO>;
  makeupSeats: Array<ClassSeatDetailDTO>;
  bypassSeats: Array<ClassSeatDetailDTO>;
  regularSeatsEmpty: Array<{ _id: string }>;
  makeUpSeatsEmpty: Array<{ _id: string }>;
} => {
  const regularSeats = filterHolding(
    studentId,
    handleFlatCapacity(filterSeatType(info?.capacity?.seats, SeatType.Regular))
  );

  const makeupSeats = filterHolding(
    studentId,
    handleFlatCapacity(filterSeatType(info?.capacity?.seats, SeatType.Makeup))
  );

  const bypassSeats = filterHolding(
    studentId,
    handleFlatCapacity(filterSeatType(info?.capacity?.seats, SeatType.Bypass))
  );

  const regularEmpty = calcRemaining(
    info?.capacity?.regular,
    regularSeats.length
  );
  const makeUpEmpty = calcRemaining(
    info?.capacity?.replacement,
    makeupSeats.length
  );

  const regularSeatsEmpty = [...Array(regularEmpty)].map((_, index) => ({
    _id: `regular_${index}`,
  }));
  const makeUpSeatsEmpty = [...Array(makeUpEmpty)].map((_, index) => ({
    _id: `makeUp_${index}`,
  }));

  return {
    regularSeats,
    makeupSeats,
    bypassSeats,
    regularSeatsEmpty,
    makeUpSeatsEmpty,
  };
};

export type ProcessSessionInfoSourceType =
  | "GML"
  | "SMS"
  | "class_student"
  | "registration_list"
  | "add_student"
  | "add_parent"
  | "class_schedule"
  | "user_Request";

export type ProcessSessionInfo = {
  process: "registration" | "student" | "parent";
  source: ProcessSessionInfoSourceType;
  sourceDetails: {
    currentDay?: string;
    currentYear?: string;
    currentWeek?: string;
    isDelete?: string;
    url?: string;
    tabKey?: string;
  };
};

const parseProcessSessionInfo = (): ProcessSessionInfo[] => {
  const data = sessionStorage.getItem("processSessionInfo");
  if (!data) return [];
  return JSON.parse(data);
};

export const setProcessSessionInfo = (
  processSessionInfo: ProcessSessionInfo[]
): void => {
  if (sessionStorage) {
    const data = parseProcessSessionInfo();

    const info = data?.filter((i) => i?.sourceDetails?.isDelete === "false");

    sessionStorage.setItem(
      "processSessionInfo",
      JSON.stringify(uniqWith([...processSessionInfo, ...info], isEqual))
    );
  }
};

export const getProcessSessionInfo = ({
  source,
  process,
}: {
  source?: ProcessSessionInfo["source"];
  process?: ProcessSessionInfo["process"];
}): ProcessSessionInfo => {
  const info = parseProcessSessionInfo();
  if (!info?.length) return null;

  const obj = info?.find((i) => i.source === source || i.process === process);
  if (obj?.sourceDetails?.isDelete !== "false") {
    return null;
  }
  return obj;
};

export const checkProcessSessionInfo = (
  type: ProcessSessionInfo["source"]
): void => {
  const parse = parseProcessSessionInfo();
  if (!parse?.length) return;

  const info = parse
    ?.map((i) => {
      if (i.source === type) {
        if (i?.sourceDetails?.isDelete === "false")
          return {
            ...i,
            sourceDetails: {
              ...i.sourceDetails,
              isDelete: "true",
            },
          };
        return [];
      }
      return i;
    })
    ?.flat(2);

  if (!info?.length) {
    removeProcessSessionInfo();
  } else {
    sessionStorage.setItem("processSessionInfo", JSON.stringify(info));
  }
};

export const removeProcessSessionInfo = (): void => {
  if (sessionStorage.getItem("processSessionInfo"))
    sessionStorage.removeItem("processSessionInfo");
};
