import {
  Form,
  FormInstance,
  Input,
  InputNumber,
} from "@thepiquelab/archus-components-web";
import { Store } from "rc-field-form/lib/interface";
import React from "react";
import { AddLineItemCommand } from "../../../../graphql";
import formatLabel from "../../../../utils/formatLabel";
import ModalButtonGroup from "../../../common/ModalButtonGroup";

interface AddFeesFormProps {
  onSubmit: (items: AddLineItemCommand) => void;
  onCancel: () => void;
  loading: boolean;
  form?: FormInstance;
}

const { Item } = Form;

const AddFeesForm: React.FC<AddFeesFormProps> = ({
  onSubmit,
  onCancel,
  loading,
  form: formInstance,
}) => {
  const [form] = Form.useForm(formInstance);

  const handleSubmit = (values: Store): void => {
    const { description, quantity, unitAmount } = values;

    onSubmit({ description, quantity, unitAmount });
  };

  return (
    <Form form={form} onFinish={handleSubmit} layout="vertical">
      <Item
        label={formatLabel("Name")}
        name="description"
        rules={[
          {
            required: true,
            message: formatLabel("Field is required."),
          },
        ]}
      >
        <Input className="w-full" maxLength={500} />
      </Item>
      <Item
        label={formatLabel("Qty")}
        name="quantity"
        rules={[
          {
            required: true,
            message: formatLabel("Field is required."),
          },
        ]}
      >
        <InputNumber className="w-full" min={0} step="1" precision={0} />
      </Item>
      <Item
        label={formatLabel("Price")}
        name="unitAmount"
        rules={[
          {
            required: true,
            message: formatLabel("Field is required."),
          },
        ]}
      >
        <InputNumber
          className="w-full"
          min={0.0}
          precision={2}
          prefix="$"
          step="0.01"
        />
      </Item>
      <ModalButtonGroup
        okText="Confirm"
        onOK={form.submit}
        onCancel={onCancel}
        confirmLoading={loading}
      />
    </Form>
  );
};

export default AddFeesForm;
