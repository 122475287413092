import {
  GetWhatsappAccounts,
  GetWhatsappAccountsQuery,
  GetWhatsappAccountsQueryVariables,
} from "@/graphql";
import { QueryTuple, useLazyQuery } from "@apollo/client";

export const useLazyQueryWhatsappAccounts = (): QueryTuple<
  GetWhatsappAccountsQuery,
  GetWhatsappAccountsQueryVariables
> =>
  useLazyQuery<GetWhatsappAccountsQuery, GetWhatsappAccountsQueryVariables>(
    GetWhatsappAccounts,
    { fetchPolicy: "network-only" }
  );
