import { ReactComponent as IconCompleteTask } from "@/assets/images/IconCheck.svg";
import { ReactComponent as IconClose } from "@/assets/images/IconCircleClose.svg";
import { ReactComponent as IconEdit } from "@/assets/images/IconEdit.svg";
import { ReactComponent as IconReopen } from "@/assets/images/IconRefresh.svg";
import { useConfirmModal } from "@/components/common/ConfirmModal/NewConfirmModal";
import DropdownAction, {
  Action,
} from "@/components/common/DropdownAction/DropdownAction";

import { TaskStatus, TaskType } from "@/graphql";
import formatErrorMessage from "@/utils/formatErrorMessage";
import { message } from "@thepiquelab/archus-components-web";
import { useEffect, useState } from "react";
import OperationalTaskDetailModal from "./TaskDetail/OperationalTaskDetailModal";
import {
  getIsNotAllowedToComplete,
  getIsNotAllowedToEdit,
  getIsNotAllowedToReopen,
  getIsNotAllowedToVoid,
} from "./TaskTableUtil";
import { useCompleteTasks, useReopenTask, useVoidTask } from "./hooks";

interface TaskActionsProps {
  taskId: string;
  status: TaskStatus;
  taskType: TaskType;
  callback?: () => void | Promise<void>;
}

const TaskActions: React.FC<TaskActionsProps> = (props) => {
  const { taskId, callback, status, taskType } = props;
  const [voidTask, { loading: voidLoading }] = useVoidTask();
  const [reopenTask, { loading: reopenLoading }] = useReopenTask();
  const [taskIdToEdit, setTaskIdToEdit] = useState("");
  const [completeTasks, { loading: completeLoading }] = useCompleteTasks();
  const { modal, setModalProps, show, close } = useConfirmModal();

  const noVoid = getIsNotAllowedToVoid(taskType, status);
  const noReopen = getIsNotAllowedToReopen(taskType, status);
  const noComplete = getIsNotAllowedToComplete(taskType, status);
  const noEdit = getIsNotAllowedToEdit(taskType, status);

  const handleVoidTask = async (): Promise<void> => {
    try {
      await voidTask({ variables: { id: taskId } });
      callback?.();
      close();
    } catch (error) {
      message.error(formatErrorMessage(error));
    }
  };

  const handleReopenTask = async (): Promise<void> => {
    try {
      await reopenTask({ variables: { id: taskId } });
      callback?.();
      close();
    } catch (error) {
      message.error(formatErrorMessage(error));
    }
  };

  const handleComplete = async (ids: string[]): Promise<void> => {
    try {
      await completeTasks({
        variables: {
          command: {
            type: taskType,
            ids,
          },
        },
      });
      callback?.();
    } catch (error) {
      message.error(formatErrorMessage(error));
    }
  };

  const voidTaskAction: Action = {
    icon: (
      <span className="anticon anticon-printer">
        <IconClose className="w-5 h-4" />
      </span>
    ),
    label: "Void Task",
    onClick: () => {
      setModalProps({
        onConfirm: handleVoidTask,
        onClose: close,
      });
      show();
    },
    hide: noVoid,
  };

  const reopenTaskAction: Action = {
    icon: (
      <span className="anticon anticon-printer">
        <IconReopen className="w-5 h-4" />
      </span>
    ),
    label: "Reopen Task",
    onClick: () => {
      setModalProps({
        onConfirm: handleReopenTask,
        onClose: close,
      });
      show();
    },
    hide: noReopen,
  };

  const completeTaskAction: Action = {
    icon: (
      <span className="anticon anticon-printer">
        <IconCompleteTask className="w-5 h-4" />
      </span>
    ),
    label: "Mark As Complete",
    onClick: (id) => {
      setModalProps({
        onConfirm: async () => {
          try {
            await handleComplete([id]);
            close();
          } catch (error) {
            message.error(formatErrorMessage(error));
          }
        },
        onClose: close,
      });
      show();
    },
    hide: noComplete,
  };
  const editTaskAction: Action = {
    icon: (
      <span className="anticon anticon-printer">
        <IconEdit className="w-5 h-4" />
      </span>
    ),
    label: "Edit Task",
    onClick: (id) => {
      setTaskIdToEdit(id);
    },
    hide: noEdit,
  };

  const actions = [
    voidTaskAction,
    editTaskAction,
    completeTaskAction,
    reopenTaskAction,
  ];

  useEffect(() => {
    setModalProps({
      confirmLoading: voidLoading || completeLoading || reopenLoading,
    });
  }, [voidLoading, completeLoading, reopenLoading]);

  return (
    <>
      <DropdownAction id={taskId} actions={actions} />
      {modal}
      <OperationalTaskDetailModal
        callback={callback}
        taskId={taskIdToEdit}
        onClose={() => {
          setTaskIdToEdit("");
        }}
      />
    </>
  );
};

export default TaskActions;
