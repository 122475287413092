import { Card, Grid, Skeleton } from "antd";
import classNames from "classnames";
import React, { useMemo } from "react";
import { Line, PageHeaderSkeleton } from "./List";

export type DescriptionsPageSkeletonProps = {
  active?: boolean;
  pageHeader?: false;
  list?: false | number;
};

const MediaQueryKeyEnum = {
  xs: 1,
  sm: 2,
  md: 3,
  lg: 3,
  xl: 3,
  xxl: 4,
};

const DescriptionsLargeItemSkeleton: React.FC<{
  active?: boolean;
}> = ({ active }) => (
  <div
    style={{
      marginBlockStart: 32,
    }}
  >
    <Skeleton.Button
      active={active}
      size="small"
      style={{ width: 100, marginBlockEnd: 16 }}
    />
    <div
      style={{
        width: "100%",
        justifyContent: "space-between",
        display: "flex",
      }}
    >
      <div
        style={{
          flex: 1,
          marginInlineEnd: 24,
          maxWidth: 300,
        }}
      >
        <Skeleton
          active={active}
          paragraph={false}
          title={{
            style: { marginBlockStart: 0 },
          }}
        />
        <Skeleton
          active={active}
          paragraph={false}
          title={{
            style: { marginBlockStart: 8 },
          }}
        />
        <Skeleton
          active={active}
          paragraph={false}
          title={{
            style: { marginBlockStart: 8 },
          }}
        />
      </div>
      <div
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            maxWidth: 300,
            margin: "auto",
          }}
        >
          <Skeleton
            active={active}
            paragraph={false}
            title={{
              style: { marginBlockStart: 0 },
            }}
          />
          <Skeleton
            active={active}
            paragraph={false}
            title={{
              style: { marginBlockStart: 8 },
            }}
          />
        </div>
      </div>
    </div>
  </div>
);

const DescriptionsItemSkeleton: React.FC<{
  size?: number;
  active?: boolean;
}> = ({ size, active }) => {
  const defaultCol = useMemo(
    () => ({
      lg: true,
      md: true,
      sm: false,
      xl: false,
      xs: false,
      xxl: false,
    }),
    []
  );
  const col = Grid.useBreakpoint() || defaultCol;

  const colSize =
    Object.keys(col).filter((key) => col[key as "lg"] === true)[0] || "md";

  const arraySize =
    size === undefined ? MediaQueryKeyEnum[colSize as "md"] || 3 : size;
  return (
    <div
      style={{
        width: "100%",
        justifyContent: "space-between",
        display: "flex",
      }}
    >
      {new Array(arraySize).fill(null).map((_, index) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          style={{
            flex: 1,
            paddingInlineStart: index === 0 ? 0 : 24,
            paddingInlineEnd: index === arraySize - 1 ? 0 : 24,
          }}
        >
          <Skeleton
            active={active}
            paragraph={false}
            title={{
              style: { marginBlockStart: 0 },
            }}
          />
          <Skeleton
            active={active}
            paragraph={false}
            title={{
              style: { marginBlockStart: 8 },
            }}
          />
          <Skeleton
            active={active}
            paragraph={false}
            title={{
              style: { marginBlockStart: 8 },
            }}
          />
        </div>
      ))}
    </div>
  );
};

/**
 * Table Item Skelton
 *
 * @param param0
 */
export const TableItemSkeleton = ({
  active,
  header = false,
  columnSize,
  style,
  columnWidth,
  emptyCell = () => false,
}: {
  active: boolean;
  header?: boolean;
  style?: React.CSSProperties;
  emptyCell?: (index: number) => boolean;
  columnSize?: number;
  columnWidth?: (column: { index?: number }) => number;
}) => {
  const defaultCol = useMemo(
    () => ({
      lg: true,
      md: true,
      sm: false,
      xl: false,
      xs: false,
      xxl: false,
    }),
    []
  );
  const col = Grid.useBreakpoint() || defaultCol;

  const colSize =
    Object.keys(col).filter((key) => col[key as "md"] === true)[0] || "md";

  const arraySize = columnSize || MediaQueryKeyEnum[colSize as "md"] || 3;
  return (
    <>
      <div
        style={{
          display: "flex",
          background: header ? "rgba(0,0,0,0.02)" : "none",
          padding: "24px 8px",
          ...style,
        }}
      >
        {new Array(arraySize).fill(null).map((_, index) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            style={{
              flex: 1,
              paddingInlineStart: header && index === 0 ? 0 : 20,
              paddingInlineEnd: 32,
              flexBasis: columnWidth?.({ index }) || undefined,
            }}
          >
            {!emptyCell(index) && (
              <Skeleton
                active={active}
                paragraph={false}
                title={{
                  style: {
                    margin: 0,
                    height: 24,
                    width: header ? "75px" : "100%",
                  },
                }}
              />
            )}
          </div>
        ))}
        <div
          style={{
            flex: columnWidth ? 1 : 3,
            flexBasis: columnWidth?.({ index: arraySize }) || undefined,
            paddingInlineStart: 32,
          }}
        >
          {!emptyCell(arraySize) && (
            <Skeleton
              active={active}
              paragraph={false}
              title={{
                style: {
                  margin: 0,
                  height: 24,
                  width: header ? "75px" : "100%",
                },
              }}
            />
          )}
        </div>
      </div>
      <Line padding="0px 0px" />
    </>
  );
};

export const TableSkeleton: React.FC<{
  active: boolean;
  size?: number;
  hasTableButton?: boolean;
  bordered?: boolean;
  columnSize?: number;
  columnWidth?: (column?: { index?: number }) => number;
  emptyCell?: (index: number) => boolean;
  showHeader?: boolean;
  showBody?: boolean;
  showPagination?: boolean;
  className?: string;
  style?: React.CSSProperties;
}> = ({
  active,
  size = 4,
  hasTableButton = true,
  columnSize,
  bordered = false,
  emptyCell = () => false,
  columnWidth,
  showHeader = true,
  showBody = true,
  showPagination = true,
  className,
  style,
}) => (
  <div
    style={style}
    className={`${classNames({
      "border border-gray-300": bordered,
    })} ${className}`}
  >
    {hasTableButton && (
      <Skeleton.Button
        active={active}
        size="small"
        style={{ width: 100, marginBlockEnd: 16 }}
      />
    )}
    {showHeader && (
      <TableItemSkeleton
        header
        active={active}
        columnSize={columnSize}
        columnWidth={columnWidth}
      />
    )}
    {showBody &&
      new Array(size).fill(null).map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <TableItemSkeleton
          key={index}
          active={active}
          columnSize={columnSize}
          emptyCell={emptyCell}
          columnWidth={columnWidth}
        />
      ))}
    {showPagination && (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingBlockStart: 16,
        }}
      >
        <Skeleton
          active={active}
          paragraph={false}
          title={{
            style: {
              margin: 0,
              height: 32,
              float: "right",
              maxWidth: "630px",
            },
          }}
        />
      </div>
    )}
  </div>
);

/**
 * Table Skelton
 *
 * @param param0
 */
export const TableInCardSkeleton: React.FC<{
  active: boolean;
  size?: number;
}> = ({ active, size = 4 }) => (
  <Card bordered={false}>
    <TableSkeleton active={active} size={size} hasTableButton />
  </Card>
);

export const DescriptionsSkeleton = ({ active }: { active: boolean }) => (
  <Card
    bordered={false}
    style={{
      borderStartEndRadius: 0,
      borderTopLeftRadius: 0,
    }}
  >
    <Skeleton.Button
      active={active}
      size="small"
      style={{ width: 100, marginBlockEnd: 16 }}
    />
    <DescriptionsItemSkeleton active={active} />
    <DescriptionsLargeItemSkeleton active={active} />
  </Card>
);

const DescriptionsPageSkeleton: React.FC<DescriptionsPageSkeletonProps> = ({
  active = true,
  pageHeader,
  list,
}) => (
  <div
    style={{
      width: "100%",
    }}
  >
    {pageHeader !== false && <PageHeaderSkeleton active={active} />}
    <DescriptionsSkeleton active={active} />
    {list !== false && <Line />}
    {list !== false && <TableInCardSkeleton active={active} size={list} />}
  </div>
);

export default DescriptionsPageSkeleton;
