import { UserOutlined } from "@ant-design/icons/lib";
import { Avatar } from "@thepiquelab/archus-components-web";
import React, { useMemo } from "react";
import { ReactComponent as Female } from "../../../assets/images/ParentFemale.svg";
import { ReactComponent as Male } from "../../../assets/images/ParentMale.svg";
import { ReactComponent as Girl } from "../../../assets/images/StudentFemale.svg";
import { ReactComponent as Boy } from "../../../assets/images/StudentMale.svg";

export declare interface UserAvatarProps {
  name?: string;
  gender?: "male" | "female" | string;
  avatarUrl?: string;
  size?: number;
  isStudent?: boolean;
  /**
   * props from Avatar
   */
  gap?: number;
  style?: React.CSSProperties;
}
const UserAvatar: React.FC<UserAvatarProps> = (props) => {
  const { avatarUrl, name, gender, size = 38, isStudent, ...config } = props;
  const iconSize = { width: size, height: size };

  const userIcon = useMemo(() => {
    switch (gender) {
      case "female":
        return isStudent ? <Girl {...iconSize} /> : <Female {...iconSize} />;
      case "male":
        return isStudent ? <Boy {...iconSize} /> : <Male {...iconSize} />;
      default:
        return <UserOutlined style={{ fontSize: size }} />;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gender]);

  return (
    <div
      className="flex justify-center items-center"
      style={{ width: size, height: size }}
    >
      <Avatar
        data-testid="UserAvatar"
        {...config}
        alt={name}
        src={avatarUrl}
        size={size}
        icon={userIcon}
      />
    </div>
  );
};

export default UserAvatar;
