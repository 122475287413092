import {
  MutationTuple,
  QueryResult,
  QueryTuple,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import {
  AdjustBalance,
  AdjustBalanceMutation,
  AdjustBalanceMutationVariables,
  CreatePaymentRecord,
  CreatePaymentRecordMutation,
  CreatePaymentRecordMutationVariables,
  CreateRefund,
  CreateRefundMutation,
  CreateRefundMutationVariables,
  GenerateNextInvoice,
  GenerateNextInvoiceMutation,
  GenerateNextInvoiceMutationVariables,
  GetFinanceRecordOfAccountNumber,
  GetFinanceRecordOfAccountNumberQuery,
  GetFinanceRecordOfAccountNumberQueryVariables,
  GetFinanceRecordOfInvoice,
  GetFinanceRecordOfInvoiceQuery,
  GetFinanceRecordOfInvoiceQueryVariables,
  GetFinanceRecordOfReceipt,
  GetFinanceRecordOfReceiptQuery,
  GetFinanceRecordOfReceiptQueryVariables,
  GetFinanceRecordOfRefund,
  GetFinanceRecordOfRefundQuery,
  GetFinanceRecordOfRefundQueryVariables,
  GetInVoicePaymentRecord,
  GetInVoicePaymentRecordQuery,
  GetInVoicePaymentRecordQueryVariables,
  GetInvoice,
  GetInvoiceExport,
  GetInvoiceExportQuery,
  GetInvoiceExportQueryVariables,
  GetInvoiceQuery,
  GetInvoiceQueryVariables,
  GetListRegistrationData,
  GetReceipt,
  GetReceiptDetails,
  GetReceiptDetailsQuery,
  GetReceiptDetailsQueryVariables,
  GetReceiptExport,
  GetReceiptExportQuery,
  GetReceiptExportQueryVariables,
  GetReceiptQuery,
  GetReceiptQueryVariables,
  GetReceipts,
  GetReceiptsQuery,
  GetReceiptsQueryVariables,
  GetRefund,
  GetRefundExport,
  GetRefundExportQuery,
  GetRefundExportQueryVariables,
  GetRefundQuery,
  GetRefundQueryVariables,
  GetRefunds,
  GetRefundsQuery,
  GetRefundsQueryVariables,
  GetStatementOfAccountInfo,
  GetStatementOfAccountInfoQuery,
  GetStatementOfAccountInfoQueryVariables,
  OnHoldInvoice,
  OnHoldInvoiceMutation,
  OnHoldInvoiceMutationVariables,
  RegenerateInvoice,
  RegenerateInvoiceMutation,
  RegenerateInvoiceMutationVariables,
  UpdateInvoiceDueDate,
  UpdateInvoiceDueDateMutation,
  UpdateInvoiceDueDateMutationVariables,
  UpdatePaymentRecord,
  UpdatePaymentRecordMutation,
  UpdatePaymentRecordMutationVariables,
  VoidInvoice,
  VoidInvoiceMutation,
  VoidInvoiceMutationVariables,
  VoidPaymentRecord,
  VoidPaymentRecordMutation,
  VoidPaymentRecordMutationVariables,
  VoidRefund,
  VoidRefundMutation,
  VoidRefundMutationVariables,
} from "../../../graphql";
import { ReturnType, useLoadMore } from "../../../hooks/useLoadMore";

export const useLazyQueryPaymentRecordInModal = (
  id: string
): QueryTuple<
  GetInVoicePaymentRecordQuery,
  GetInVoicePaymentRecordQueryVariables
> =>
  useLazyQuery<
    GetInVoicePaymentRecordQuery,
    GetInVoicePaymentRecordQueryVariables
  >(GetInVoicePaymentRecord, {
    variables: { id },
  });

export const useLazyQueryReceipts = (): QueryTuple<
  GetReceiptsQuery,
  GetReceiptsQueryVariables
> => useLazyQuery<GetReceiptsQuery, GetReceiptsQueryVariables>(GetReceipts);

export const useQueryReceipt = (id: string) =>
  useQuery<GetReceiptQuery, GetReceiptQueryVariables>(GetReceipt, {
    variables: { id },
  });

export const useLazyQueryReceipt = () =>
  useLazyQuery<GetReceiptQuery, GetReceiptQueryVariables>(GetReceipt, {
    fetchPolicy: "network-only",
  });

export const useLazyQueryReceiptDetails = () =>
  useLazyQuery<GetReceiptDetailsQuery, GetReceiptDetailsQueryVariables>(
    GetReceiptDetails,
    {
      fetchPolicy: "no-cache",
    }
  );

export const useLazyQueryRefund = (): QueryTuple<
  GetRefundQuery,
  GetRefundQueryVariables
> => useLazyQuery<GetRefundQuery, GetRefundQueryVariables>(GetRefund);

export const useQueryInvoice = (id: string) =>
  useQuery<GetInvoiceQuery, GetInvoiceQueryVariables>(GetInvoice, {
    variables: { id },
    skip: true,
  });

export const useLazyQueryInvoice = () =>
  useLazyQuery<GetInvoiceQuery, GetInvoiceQueryVariables>(GetInvoice, {
    fetchPolicy: "network-only",
  });

export const useLazyQueryInvoiceExport = (): QueryTuple<
  GetInvoiceExportQuery,
  GetInvoiceExportQueryVariables
> =>
  useLazyQuery<GetInvoiceExportQuery, GetInvoiceExportQueryVariables>(
    GetInvoiceExport,
    { fetchPolicy: "no-cache" }
  );

export const useLazyQueryReceiptExport = (): QueryTuple<
  GetReceiptExportQuery,
  GetReceiptExportQueryVariables
> =>
  useLazyQuery<GetReceiptExportQuery, GetReceiptExportQueryVariables>(
    GetReceiptExport,
    { fetchPolicy: "no-cache" }
  );

export const useLazyQueryRefundExport = (): QueryTuple<
  GetRefundExportQuery,
  GetRefundExportQueryVariables
> =>
  useLazyQuery<GetRefundExportQuery, GetRefundExportQueryVariables>(
    GetRefundExport,
    { fetchPolicy: "no-cache" }
  );

export const useUpdateInvoiceDueDate = (): MutationTuple<
  UpdateInvoiceDueDateMutation,
  UpdateInvoiceDueDateMutationVariables
> =>
  useMutation<
    UpdateInvoiceDueDateMutation,
    UpdateInvoiceDueDateMutationVariables
  >(UpdateInvoiceDueDate);

export const useLazyQueryRefunds = (): QueryTuple<
  GetRefundsQuery,
  GetRefundsQueryVariables
> => useLazyQuery<GetRefundsQuery, GetRefundsQueryVariables>(GetRefunds);

export const useLoadMoreRefunds = (): ReturnType<
  GetRefundsQuery,
  GetRefundsQueryVariables
> =>
  useLoadMore<GetRefundsQuery, GetRefundsQueryVariables>(
    GetRefunds,
    "refunds",
    false,
    false,
    false,
    {
      fetchPolicy: "network-only",
    }
  );

export const useLazyQueryStatement = (): QueryTuple<
  GetStatementOfAccountInfoQuery,
  GetStatementOfAccountInfoQueryVariables
> =>
  useLazyQuery<
    GetStatementOfAccountInfoQuery,
    GetStatementOfAccountInfoQueryVariables
  >(GetStatementOfAccountInfo);

export const useFinanceRecordOfAccountNumber = (
  id: string
): QueryResult<GetFinanceRecordOfAccountNumberQuery> =>
  useQuery<
    GetFinanceRecordOfAccountNumberQuery,
    GetFinanceRecordOfAccountNumberQueryVariables
  >(GetFinanceRecordOfAccountNumber, {
    variables: { parentId: id },
    fetchPolicy: "network-only",
  });

export const useLazyQueryFinanceRecordOfAccountNumber = () =>
  useLazyQuery<
    GetFinanceRecordOfAccountNumberQuery,
    GetFinanceRecordOfAccountNumberQueryVariables
  >(GetFinanceRecordOfAccountNumber, { fetchPolicy: "network-only" });

export const useQueryStatementOfAccountInfo = (
  id: string
): QueryResult<GetStatementOfAccountInfoQuery> =>
  useQuery<
    GetStatementOfAccountInfoQuery,
    GetStatementOfAccountInfoQueryVariables
  >(GetStatementOfAccountInfo, {
    variables: { parentId: id },
    fetchPolicy: "cache-and-network",
  });

export const useLazyQueryStatementOfAccountInfo = () =>
  useLazyQuery<
    GetStatementOfAccountInfoQuery,
    GetStatementOfAccountInfoQueryVariables
  >(GetStatementOfAccountInfo, { fetchPolicy: "network-only" });

export const useLoadMoreFinanceRecordOfInvoice = (): ReturnType<
  GetFinanceRecordOfInvoiceQuery,
  GetFinanceRecordOfInvoiceQueryVariables
> =>
  useLoadMore<
    GetFinanceRecordOfInvoiceQuery,
    GetFinanceRecordOfInvoiceQueryVariables
  >(GetFinanceRecordOfInvoice, "invoices", true, false, false);

export const useLoadMoreFinanceRecordOfReceipt = (): ReturnType<
  GetFinanceRecordOfReceiptQuery,
  GetFinanceRecordOfReceiptQueryVariables
> =>
  useLoadMore<
    GetFinanceRecordOfReceiptQuery,
    GetFinanceRecordOfReceiptQueryVariables
  >(GetFinanceRecordOfReceipt, "receipts", true, false, false);

export const useQueryFinanceRecordOfRefund = (): ReturnType<
  GetFinanceRecordOfRefundQuery,
  GetFinanceRecordOfRefundQueryVariables
> =>
  useLoadMore<
    GetFinanceRecordOfRefundQuery,
    GetFinanceRecordOfRefundQueryVariables
  >(GetFinanceRecordOfRefund, "refunds", true, false, true, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  });

export const useCreatePaymentRecord = (): MutationTuple<
  CreatePaymentRecordMutation,
  CreatePaymentRecordMutationVariables
> =>
  useMutation<
    CreatePaymentRecordMutation,
    CreatePaymentRecordMutationVariables
  >(CreatePaymentRecord, {
    refetchQueries: [
      { query: GetReceipts },
      { query: GetListRegistrationData },
    ],
  });

export const useUpdatePaymentRecord = (): MutationTuple<
  UpdatePaymentRecordMutation,
  UpdatePaymentRecordMutationVariables
> =>
  useMutation<
    UpdatePaymentRecordMutation,
    UpdatePaymentRecordMutationVariables
  >(UpdatePaymentRecord, {});

export const useCreateRefund = (): MutationTuple<
  CreateRefundMutation,
  CreateRefundMutationVariables
> =>
  useMutation<CreateRefundMutation, CreateRefundMutationVariables>(
    CreateRefund,
    { refetchQueries: [{ query: GetRefunds }] }
  );

export const useVoidRefund = (): MutationTuple<
  VoidRefundMutation,
  VoidRefundMutationVariables
> => useMutation<VoidRefundMutation, VoidRefundMutationVariables>(VoidRefund);

export const useRegenerateInvoice = () =>
  useMutation<RegenerateInvoiceMutation, RegenerateInvoiceMutationVariables>(
    RegenerateInvoice
  );

export const useGenerateNextInvoice = () =>
  useMutation<
    GenerateNextInvoiceMutation,
    GenerateNextInvoiceMutationVariables
  >(GenerateNextInvoice);

export const useVoidPaymentRecord = () =>
  useMutation<VoidPaymentRecordMutation, VoidPaymentRecordMutationVariables>(
    VoidPaymentRecord
  );

export const useVoidInvoice = () =>
  useMutation<VoidInvoiceMutation, VoidInvoiceMutationVariables>(VoidInvoice);

export const useOnHoldInvoice = () =>
  useMutation<OnHoldInvoiceMutation, OnHoldInvoiceMutationVariables>(
    OnHoldInvoice,
    {
      refetchQueries: [GetStatementOfAccountInfo],
    }
  );

export const useAdjustBalance = (): MutationTuple<
  AdjustBalanceMutation,
  AdjustBalanceMutationVariables
> =>
  useMutation<AdjustBalanceMutation, AdjustBalanceMutationVariables>(
    AdjustBalance
  );
