import { Skeleton } from "@thepiquelab/archus-components-web";

const SelectCheckboxDateSkeleton = () => (
  <div className="flex flex-col gap-6 overflow-auto">
    <Skeleton.Button active className="w-full" />

    <div className="flex  gap-4">
      <Skeleton.Input active className="w-5 h-5 overflow-auto rounded-sm" />
      <div className="flex flex-col gap-1">
        <Skeleton.Input size="small" className="h-5 w-56" />
        <Skeleton.Input size="small" className="h-5 w-56" />
      </div>
    </div>
    <div className="flex  gap-4">
      <Skeleton.Input active className="w-5 h-5 overflow-auto rounded-sm" />
      <div className="flex flex-col gap-1">
        <Skeleton.Input size="small" className="h-5 w-56" />
        <Skeleton.Input size="small" className="h-5 w-56" />
      </div>
    </div>
    <div className="flex  gap-4">
      <Skeleton.Input active className="w-5 h-5 overflow-auto rounded-sm" />
      <div className="flex flex-col gap-1">
        <Skeleton.Input size="small" className="h-5 w-56" />
        <Skeleton.Input size="small" className="h-5 w-56" />
      </div>
    </div>
  </div>
);

export default SelectCheckboxDateSkeleton;
