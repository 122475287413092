import { useAcademicLevel } from "@/components/course/CourseHooks";
import { useUpdateStudent } from "@/components/users/UserHooks";
import { Student, StudentCell } from "@/components/widget/UserCell/StudentCell";
import formatErrorMessage from "@/utils/formatErrorMessage";
import formatLabel from "@/utils/formatLabel";
import { Alert, ModalButtonGroup } from "@thepiquelab/archus-components-web";
import { Form, message, Modal, Select } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";

interface Props {
  visible: boolean;
  profile?: Student;
  onCancel?: () => void;
}

const SetAcademicLevelModal: React.FC<Props> = (props) => {
  const { visible, profile, onCancel } = props;
  const history = useHistory();
  const [form] = Form.useForm();
  const { data: academicLevelsData } = useAcademicLevel();
  const [updateStudentDetail, { loading: updateStudentLoading }] =
    useUpdateStudent();

  const onFinish = async (res: { academicLevelId: string }): Promise<void> => {
    if (!profile?.id) return;
    try {
      await updateStudentDetail({
        variables: {
          input: { id: profile.id, ...res },
        },
      });
      onCancel();
      history.push(
        `/class-arrangement/registration/create/step1/${profile.id}`
      );
    } catch (error) {
      message.error(formatErrorMessage(error));
    }
  };

  return (
    <Modal
      className="academicLevel-modal"
      visible={visible}
      destroyOnClose
      width="520px"
      footer={
        <ModalButtonGroup
          onOK={() => form.submit()}
          okText={formatLabel("Confirm")}
          onCancel={() => {
            form.resetFields();
            onCancel();
          }}
          confirmLoading={updateStudentLoading}
        />
      }
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      title={formatLabel("Set Academic Level")}
    >
      <Alert
        type="info"
        description={
          <div className="break-words whitespace-pre-wrap">
            {
              "This student has no academic level set yet. Please select an academic level to continue. "
            }
          </div>
        }
      />
      <StudentCell profile={profile} className="my-6" />
      <Form form={form} onFinish={onFinish}>
        <Form.Item
          className="mb-0 academicLevel-modal-item"
          name="academicLevelId"
          label="Set Academic Level"
          labelCol={{ span: 24 }}
          rules={[{ required: true, message: "Field is required." }]}
        >
          <Select
            options={academicLevelsData?.academicLevels?.map(
              ({ name, id }) => ({
                label: name,
                value: id,
              })
            )}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SetAcademicLevelModal;
