import { CourseApi } from "@/apis/course/course.api";
import { useRequest } from "ahooks";
import { useState } from "react";
import { OptionType } from "../types";

export const useLevelOptions = (): {
  getOptions: () => Promise<OptionType[]>;
  loading: boolean;
  options: OptionType[];
} => {
  const [options, setOptions] = useState<OptionType[]>();
  const { run, loading } = useRequest(
    () => CourseApi.getAcademicLevelOptions(),
    { throwOnError: true, manual: true }
  );

  const getOptions = async (): Promise<OptionType[]> => {
    const res = await run();
    const newOptions = res.map((i) => ({ label: i.name, value: i._id }));
    setOptions(newOptions);
    return newOptions;
  };

  return {
    getOptions,
    loading,
    options,
  };
};
