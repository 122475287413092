/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import InsufficientCredentials from "@/assets/images/InsufficientCredentials.svg";
import * as Sentry from "@sentry/react";
import { ErrorScreen } from "@thepiquelab/archus-components-web";
import React from "react";

const ErrorPage: React.FC = () => (
  <ErrorScreen
    logo=""
    title="Something Went Wrong"
    description="Oops! Something went wrong. Please try again later."
    primaryButtonLabel=""
    illustration={InsufficientCredentials}
  />
);
class ErrorBoundaryWithSentry extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    try {
      Sentry.captureException(error);
    } catch (e) {
      console.error(e);
    }
  }

  render() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (this.state.hasError) {
      return <ErrorPage />;
    }

    return this.props.children;
  }
}

export default ErrorBoundaryWithSentry;
