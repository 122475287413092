import { Modal as BaseModal } from "antd";
import React from "react";
import { ModalProps, ModalSubComponents } from "./types";
import "./Modal.scss";
import classNames from "classnames";
import ModalHeader from "./ModalHeader";
import ModalFooter from "./ModalFooter";
import ModalBody from "./ModalBody";

/**
 * Modal component based on derived from Antd library.
 * Accepts similar parameters used in Antd Modal.
 *
 * @example Modal with header, body and footer
 * ```
 * <Modal
 *    header={<Modal.Header title="Sample modal" />}
 *    footer={ <Modal.Footer primaryButton={{id: 'ok', label: 'OK'}} />}
 * >
 *    <Modal.Body className="modal-body">
 *      Content here
 *    </Modal.Body>
 * </Modal>
 * ```
 */
const Modal: React.FC<ModalProps> & ModalSubComponents = ({
  className,
  children,
  footer,
  okText,
  header,
  visible = false,
  ...props
}) => {
  const classes = classNames(className, "common-modal");

  return (
    <BaseModal
      className={classes}
      destroyOnClose
      footer={footer}
      title={header}
      visible={visible}
      {...props}
    >
      {children}
    </BaseModal>
  );
};

Modal.Header = ModalHeader;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;

export default Modal;
