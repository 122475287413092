import { useAccessControl } from "@/hooks/useAccessControl";
import AccessDeniedPage from "@/pages/errors/AccessDeniedPage";
import React from "react";
import { Route, RouteProps } from "react-router-dom";
import { UserRoleDb } from "../users/types";
import { EnumPermissions } from "./Permissions";

/**
 * enum of permissionCheckType
 * @enum
 * @property {string} EnumCheckPermissionType.ALL - Pass if only all permissions are assigned
 * @property {string} EnumCheckPermissionType.SOME - Pass if some of permissions are assigned
 */
export enum EnumCheckPermissionType {
  /** Pass if only all permissions are assigned */
  "ALL" = "ALL",
  /** Pass if some of permissions are assigned */
  "SOME" = "SOME",
}
export interface AuthorizedRouteProps extends RouteProps {
  userRoles?: UserRoleDb[];
  /** Permissions to decide if the route is able to access to */
  permissions?: EnumPermissions[];
  /**
   * Indicates whether to permit a user to access if one of the permissions required is in his priviledge or only if all is present.
   */
  permissionCheckType?: EnumCheckPermissionType;

  hide?: boolean;
  disableRedirectTo?: boolean;
}

const AuthorizedRoute: React.FC<AuthorizedRouteProps> = (props) => {
  const { userRoles, permissions, permissionCheckType, ...rest } = props;
  const { hasAllPermissions, hasSomePermissions } = useAccessControl();

  /**
   * @param permissionsToCheck {EnumPermissions[]} - permissionsToCheck
   * @param type {EnumCheckPermissionType} [type=EnumCheckPermissionType.ALL] - Indicates whether to permit a user to access if one of the permissions required is in his priviledge or only if all is present.
   * @returns {boolean}
   */
  const hasPermission = (
    permissionsToCheck: EnumPermissions[],
    type: EnumCheckPermissionType = EnumCheckPermissionType.ALL
  ): boolean => {
    if (type === EnumCheckPermissionType.SOME) {
      return hasSomePermissions(permissionsToCheck);
    }
    return hasAllPermissions(permissionsToCheck);
  };

  if (permissions?.length && !hasPermission(permissions, permissionCheckType)) {
    return <AccessDeniedPage />;
  }

  return <Route {...rest} />;
};

export default AuthorizedRoute;
