import {
  GetReferences,
  GetReferencesQuery,
  GetReferencesQueryVariables,
  PostReferenceData,
  PostReferenceDataSubscription,
  PostReferenceDataSubscriptionVariables,
} from "@/graphql";
import { useQuery, useSubscription } from "@apollo/client";
import { find } from "lodash";
import { useEffect, useState } from "react";

export const usePostReferenceDataSubscription = (): {
  referenceData: GetReferencesQuery["referenceDatas"];
  setReferenceData: (data: GetReferencesQuery["referenceDatas"]) => void;
  referenceDataLoading: boolean;
} => {
  const [referenceData, setReferenceData] = useState<
    GetReferencesQuery["referenceDatas"] | null
  >(null);

  const { data: originalReferenceData, loading: getReferenceDataLoading } =
    useQuery<GetReferencesQuery, GetReferencesQueryVariables>(GetReferences, {
      fetchPolicy: "cache-first",
    });

  const { data: ReferenceDataFromSub } = useSubscription<
    PostReferenceDataSubscription,
    PostReferenceDataSubscriptionVariables
  >(PostReferenceData);

  useEffect(() => {
    if (!ReferenceDataFromSub || !referenceData) {
      return;
    }

    /**
     * creation
     */
    if (
      !find(referenceData, {
        id: ReferenceDataFromSub?.postReferenceData?.id,
      })
    ) {
      setReferenceData([
        ...referenceData,
        ReferenceDataFromSub.postReferenceData,
      ]);
    } else {
      /**
       * updating, include archive
       */
      setReferenceData(
        referenceData.map((data) => {
          if (data.id === ReferenceDataFromSub.postReferenceData.id) {
            return {
              ...data,
              ...ReferenceDataFromSub.postReferenceData,
            };
          }
          return { ...data };
        })
      );
    }
  }, [ReferenceDataFromSub]);

  useEffect(() => {
    if (originalReferenceData?.referenceDatas) {
      setReferenceData(originalReferenceData.referenceDatas);
    }
  }, [originalReferenceData]);

  return {
    referenceData,
    setReferenceData,
    referenceDataLoading: getReferenceDataLoading,
  };
};
