import { CourseServiceApiClient } from "../clients/CourseServiceApiClient";
import {
  FilterOptionAcademicLevelDTO,
  FilterOptionAcademicYearDTO,
  FilterOptionBranchDTO,
  FilterOptionClassDTO,
  FilterOptionCourseDTO,
  FilterOptionVenueDTO,
} from "./types";

export class CourseApi {
  private static client = CourseServiceApiClient;

  private static coursePathPrefix = "/v1/courses";

  private static filterOptionPathPrefix = "/v1/filter-options";

  public static async getClassOptionsByLevelId(
    levelId: string
  ): Promise<FilterOptionClassDTO[]> {
    const response = await this.client.get<null, FilterOptionClassDTO[]>(
      `${this.filterOptionPathPrefix}/classes/academic-level/${levelId}`
    );
    return response;
  }

  public static async getCourseOptionsByLevelId(
    levelId: string
  ): Promise<FilterOptionCourseDTO[]> {
    const response = await this.client.get<null, FilterOptionCourseDTO[]>(
      `${this.filterOptionPathPrefix}/courses/academic-level/${levelId}`
    );
    return response;
  }

  public static async getAcademicLevelOptions(): Promise<
    FilterOptionAcademicLevelDTO[]
  > {
    const response = await this.client.get<
      null,
      FilterOptionAcademicLevelDTO[]
    >(`${this.filterOptionPathPrefix}/academic-levels`);
    return response;
  }

  public static async getAcademicYearOptions(): Promise<
    FilterOptionAcademicYearDTO[]
  > {
    const response = await this.client.get<null, FilterOptionAcademicYearDTO[]>(
      `${this.filterOptionPathPrefix}/academic-years`
    );
    return response;
  }

  public static async getBranchOptions(): Promise<FilterOptionBranchDTO[]> {
    const response = await this.client.get<null, FilterOptionBranchDTO[]>(
      `${this.filterOptionPathPrefix}/branches`
    );
    return response;
  }

  public static async getVenueOptionsByBranchId(
    branchId: string
  ): Promise<FilterOptionVenueDTO[]> {
    const response = await this.client.get<null, FilterOptionVenueDTO[]>(
      `${this.filterOptionPathPrefix}/venues/branch/${branchId}`
    );
    return response;
  }

  public static async getCourseOptions(): Promise<FilterOptionCourseDTO[]> {
    const response = await this.client.get<null, FilterOptionCourseDTO[]>(
      `${this.filterOptionPathPrefix}/courses`
    );
    return response;
  }

  public static async getCourseClasses(
    id: string,
    params: any
  ): Promise<string[]> {
    return this.client.post(`/v1/courses/${id}/classes`, {
      ...params,
    });
  }

  public static async toggleVisibility(id: string): Promise<string[]> {
    return this.client.put(`/v1/courses/${id}/visibility`, {});
  }

  public static async toggleBulkVisibility(ids: string[]): Promise<string[]> {
    return this.client.put(`/v1/classes/bulk-visibility`, {
      ids,
    });
  }
}
