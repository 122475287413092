import { Calendar, Col, Row, Select } from "@thepiquelab/archus-components-web";
import _ from "lodash";
import moment, { Moment } from "moment";
import React, { useEffect, useMemo, useState } from "react";

export interface Props {
  availableDates: Moment[];
  registrationStartDate?: Date;
  onSelect: (v: Date) => void;
}

// Start from Monday
moment.locale("en", {
  week: {
    dow: 1,
  },
});

const StartDateCalendar: React.FC<Props> = ({
  availableDates,
  onSelect,
  registrationStartDate,
}) => {
  const dates = useMemo(() => availableDates, [availableDates]);
  const years = useMemo(
    () => [...new Set(dates.map((i) => i.year()))].sort((a, b) => a - b),
    [dates]
  );
  const months = useMemo(
    () => [...new Set(dates.map((i) => i.month()))].sort((a, b) => a - b),
    [dates]
  );

  const [calendarValue, setCalendarValue] = useState<Moment>();

  useEffect(() => {
    if (!calendarValue && dates?.length) {
      const d = dates.find((date) =>
        date.isSameOrAfter(registrationStartDate || new Date(), "day")
      );
      setCalendarValue(d || _.last(dates));
      onSelect(d ? d?.toDate() : _.last(dates)?.toDate());
    }
  }, [calendarValue, dates, registrationStartDate]);

  const handleDisabledDate = (currentDate: Moment): boolean =>
    !dates.find((date) => date.isSame(currentDate, "day"));

  return (
    <div className="w-auto mt-2">
      <Calendar
        value={calendarValue}
        disabledDate={handleDisabledDate}
        fullscreen={false}
        onSelect={(v) => {
          setCalendarValue(v);
          onSelect(v.toDate());
        }}
        headerRender={({ value, onChange }) => (
          <Row gutter={24} className="mb-6">
            <Col span={12}>
              <Select
                className="w-full"
                placeholder="Please select"
                size={"middle"}
                dropdownMatchSelectWidth={false}
                onChange={(selectedMonth: number) => {
                  const date = dates.find((d) =>
                    d.isSameOrAfter(moment(value).month(selectedMonth), "month")
                  );
                  onChange(moment(date));
                }}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                value={
                  calendarValue
                    ? moment(value).month()
                    : moment().month(_.last(months)).month()
                }
              >
                {months.map((monthNum) => (
                  <Select.Option key={monthNum} value={monthNum}>
                    {moment().month(monthNum).format("MMMM")}
                  </Select.Option>
                ))}
              </Select>
            </Col>
            <Col span={12}>
              <Select
                placeholder="Please select"
                className="w-full"
                size={"middle"}
                dropdownMatchSelectWidth={false}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                onChange={(newYear) => {
                  const date = dates.find((d) =>
                    d.isSameOrAfter(moment(value).year(newYear), "year")
                  );
                  onChange(moment(date));
                }}
                value={moment(value).year()}
              >
                {years.map((item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>
        )}
      />
    </div>
  );
};

export default StartDateCalendar;
