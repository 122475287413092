import AuthorizedByPermission, {
  EnumAuthHandleType,
} from "@/components/authorize/AuthorizedByPermission";
import { EnumPermissions } from "@/components/authorize/Permissions";
import CommonBadge from "@/components/widget/Badge/CommonBadge";
import { customizeEnumDisplay } from "@/utils/customizeEnumValue";
import { formatCurrency, formatDate } from "@thepiquelab/archus-library";
import BigNumber from "bignumber.js";
import React from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as IconCash } from "../../../assets/images/IconCashTransparent.svg";
import { ReactComponent as IconClose } from "../../../assets/images/IconCloseFill.svg";
import { ReactComponent as IconEdit } from "../../../assets/images/IconEdit.svg";
import { ReactComponent as IconSend } from "../../../assets/images/IconSend.svg";
import { PaymentRecordCardProps } from "../types";
import { formatPaymentMode } from "./util";

const PaymentRecordCard: React.FC<PaymentRecordCardProps> = (props) => {
  const {
    paymentData,
    onEdit,
    isEditing,
    onVoid,
    NameOfBankOptions,
    PaymentProcessorOptions,
  } = props;
  const { receipt, details } = paymentData || {};

  const history = useHistory();

  const handleClickReceipt = (): void => {
    const { contact, id } = receipt || {};
    if (!paymentData?.isVoid)
      history.push(`/finance/statement/${contact?.id}/receipt/${id}`);
  };

  return (
    <div
      className={`flex items-start px-6 border-b`}
      style={{ background: isEditing ? "#EFF5FF" : "" }}
    >
      <div className="flex-1 flex flex-col items-stretch justify-center font_regular py-4">
        <div>
          {new BigNumber(receipt?.amount).isGreaterThan(0) && (
            <div
              className={`flex cursor-pointer flex-row items-center justify-start mb-4`}
            >
              <div
                onClick={handleClickReceipt}
                className={`flex cursor-pointer flex-row items-center justify-start`}
                style={{ color: "#5B9DFF" }}
              >
                <IconCash fill="#5B9DFF" />
                <div
                  className={`ml-2 typography_sub_heading2  ${
                    paymentData.isVoid ? "line-through" : "underline"
                  }`}
                  style={{ color: "#5B9DFF" }}
                >
                  {`${paymentData?.receipt?.referenceNumber}`}
                </div>
              </div>
              {paymentData.isVoid ? (
                <></>
              ) : (
                <IconSend
                  className="ml-2"
                  onClick={() => {
                    history.push(
                      `/finance/receipts/sendConfirmation/${paymentData?.receipt?.id}`
                    );
                  }}
                />
              )}
            </div>
          )}
        </div>
        <div className="items-center">
          {new BigNumber(paymentData?.cashAmount).gt(0) && (
            <div className="font_semibold text-4 mb-2">
              {`${formatCurrency(
                paymentData?.cashAmount
              )} in ${customizeEnumDisplay(
                paymentData?.method,
                paymentData?.method
              )}${
                formatPaymentMode(
                  {
                    NameOfBankOptions,
                    PaymentProcessorOptions,
                    record: paymentData,
                  },
                  undefined
                )
                  ? `, ${formatPaymentMode(
                      {
                        NameOfBankOptions,
                        PaymentProcessorOptions,
                        record: paymentData,
                      },
                      undefined
                    )}`
                  : ""
              }`}
            </div>
          )}
          {new BigNumber(paymentData?.balanceAmount).gt(0) && (
            <div className="font_semibold text-4 mb-2">
              {`${formatCurrency(paymentData?.balanceAmount)} in Balance`}
            </div>
          )}

          {details?.length >= 1 &&
            !!details?.[0]?.balanceAmount &&
            details?.map((i) => (
              <div
                key={i?.invoice?.referenceNumber}
                className="flex mb-2 items-center"
              >
                <span>{`${formatCurrency(i?.amount)} for`}</span>&nbsp;
                <span
                  style={{ color: "#5B9DFF" }}
                  className={`cursor-pointer  ${
                    paymentData.isVoid ? "line-through" : "underline"
                  }`}
                  onClick={() => {
                    if (!paymentData?.isVoid)
                      history.push(
                        `/finance/statement/${receipt?.contact?.id}/invoice/${i?.invoice?.id}`
                      );
                  }}
                >
                  {i?.invoice?.referenceNumber}
                </span>
              </div>
            ))}

          {paymentData?.isVoid && (
            <CommonBadge
              label="Voided"
              textColor="#fff"
              bgColor="#F36B7F"
              className="mb-2"
            />
          )}
        </div>

        <div>
          {paymentData?.paymentDate && (
            <div className="mb-2">
              {paymentData?.referenceNumber
                ? `${paymentData?.referenceNumber} | `
                : ""}
              {`Paid on ${formatDate(paymentData?.paymentDate, {
                format: "SHORT",
              })}`}
            </div>
          )}
          {paymentData?.depositDate && (
            <div className="mb-2">
              {`Deposited on ${formatDate(paymentData?.depositDate, {
                format: "SHORT",
              })}`}
            </div>
          )}
          {paymentData?.reference && (
            <div className="mt-2 text-gray-500">{paymentData?.reference}</div>
          )}
          {paymentData?.remarks && (
            <div className="mt-2 text-gray-500">{paymentData?.remarks}</div>
          )}
          {paymentData?.lastModifiedBy?.fullName && (
            <div className="mt-2 text-gray-500">
              {`Last Modified by ${paymentData?.lastModifiedBy?.fullName}`}
            </div>
          )}
        </div>
      </div>
      {!paymentData?.isVoid && (
        <div className="flex justify-end py-4">
          <AuthorizedByPermission
            permissions={[EnumPermissions.INVOICE_UPDATE]}
            authHandleType={EnumAuthHandleType.HIDE}
          >
            <IconClose
              onClick={() => onVoid(paymentData?.id)}
              className="mr-2 cursor-pointer w-5 h-5"
            />
          </AuthorizedByPermission>
          <AuthorizedByPermission
            permissions={[EnumPermissions.INVOICE_UPDATE]}
            authHandleType={EnumAuthHandleType.HIDE}
          >
            <IconEdit
              className="cursor-pointer"
              style={{ width: 16, height: 16 }}
              onClick={() => onEdit(paymentData)}
            />
          </AuthorizedByPermission>
        </div>
      )}
    </div>
  );
};

export default PaymentRecordCard;
