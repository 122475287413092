import { ReactComponent as IconCopy } from "@/assets/images/IconCopyFull.svg";
import { formatTemplateBr } from "@/components/settings/Template/tools";
import useCopy from "@/hooks/dom/useCopy";
import { useSalutation } from "@/hooks/reference.hook";

import { Button } from "@thepiquelab/archus-components-web";
import BraftEditor from "braft-editor";
import { FC, memo } from "react";

interface EmailMessageData {
  emailMessage?: string;
  parent?: {
    fullName?: string;
    email?: string;
    salutation?: string;
  };
  students?: {
    fullName?: string;
  }[];
}

interface Props {
  item?: EmailMessageData;
}

export const formatEmailMessage = (item: EmailMessageData): string => {
  const braft = BraftEditor?.createEditorState?.(item.emailMessage);
  return braft?.toText()?.trim();
};

const EmailMessage: FC<Props> = (props) => {
  const { item } = props;
  const { formatSalutation } = useSalutation();

  const { copy } = useCopy();

  const onCopy = (): void => {
    const text = formatEmailMessage(item);
    copy(text);
  };

  return (
    <div>
      <div className="mb-6 font-normal">
        <span>&lt;&lt;{item?.parent?.email}&gt;&gt;</span>&nbsp;
        <span>
          &lt;&lt;
          {formatSalutation(item?.parent?.salutation, item?.parent?.fullName)}
          &gt;&gt;
        </span>
        &nbsp;/&nbsp;
        <span>
          &lt;&lt;
          {item?.students?.map((student) => student.fullName)?.join(", ")}
          &gt;&gt;
        </span>
      </div>
      <div
        className="mb-6 font-normal"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: formatTemplateBr(item.emailMessage),
        }}
      />
      <Button icon={<IconCopy className="mr-2" />} onClick={onCopy}>
        Copy Message
      </Button>
    </div>
  );
};

export default memo(EmailMessage);
