import { ReactComponent as IconDelete } from "@/assets/images/IconDelete.svg";
import DiscountForm, {
  discountUnit,
} from "@/components/finance/Discount/DiscountForm";
import { AppConfig } from "@/config";
import formatErrorMessage from "@/utils/formatErrorMessage";
import { useMutation, useQuery } from "@apollo/client";
import {
  Collapse,
  Divider,
  Form,
  Input,
  Select,
  Store,
  message,
} from "@thepiquelab/archus-components-web";
import { formatCurrency } from "@thepiquelab/archus-library";
import BigNumber from "bignumber.js";
import React, { useEffect, useMemo } from "react";
import {
  ApplyInvoiceDiscountCommand,
  ArchiveDiscount,
  ArchiveDiscountMutation,
  ArchiveDiscountMutationVariables,
  CreateDisCount,
  CreateDisCountMutation,
  CreateDisCountMutationVariables,
  CreateDiscountInput,
  DiscountType,
  LineItemFieldsFragment,
  QueryDiscounts,
  QueryDiscountsQuery,
  QueryDiscountsQueryVariables,
} from "../../../../graphql";
import formatLabel from "../../../../utils/formatLabel";
import ModalButtonGroup from "../../../common/ModalButtonGroup";
import "./index.scss";
import { LineItem } from "./types";

type DiscountItem = QueryDiscountsQuery["discounts"][0];

interface Props {
  lineItem: LineItem | LineItemFieldsFragment;
  loading: boolean;
  taxRatePercentage: number;
  onApply: (input: ApplyInvoiceDiscountCommand) => void;
  setVisible: (isVisible: boolean) => void;
}

export const Discount: React.FC<Props> = (props) => {
  const { onApply, lineItem, setVisible, loading, taxRatePercentage } = props;
  const {
    data,
    loading: discountLoading,
    refetch,
  } = useQuery<QueryDiscountsQuery, QueryDiscountsQueryVariables>(
    QueryDiscounts,
    {
      onError(e) {
        message.error(formatErrorMessage(e));
      },
      variables: { showArchived: false, showVisible: true },
      fetchPolicy: "cache-and-network",
    }
  );
  const [createDiscount, { loading: createLoading }] = useMutation<
    CreateDisCountMutation,
    CreateDisCountMutationVariables
  >(CreateDisCount, {
    onError(e) {
      message.error(formatErrorMessage(e));
    },
  });

  const [archiveDiscount, { loading: archiveLoading }] = useMutation<
    ArchiveDiscountMutation,
    ArchiveDiscountMutationVariables
  >(ArchiveDiscount);

  const [form] = Form.useForm();
  const discountId = Form.useWatch("discountId", form);
  const discounts = useMemo(() => data?.discounts || [], [data]);

  const itemTotal = useMemo(
    () => new BigNumber(lineItem?.unitAmount).multipliedBy(lineItem?.quantity),
    [lineItem]
  );

  const discount = useMemo(
    () => discounts.find((d) => d.id === discountId),
    [discountId]
  );

  const discountAmount = useMemo(() => {
    if (!discount) {
      return null;
    }
    return discount?.discountType === DiscountType.Amount
      ? new BigNumber(discount?.discountAmount || 0)
      : itemTotal.multipliedBy(
          new BigNumber(discount?.discountRate || 0).dividedBy(100)
        );
  }, [itemTotal, discount]);

  const total = useMemo(() => {
    if (!discountAmount) {
      return itemTotal;
    }

    return itemTotal?.minus(discountAmount);
  }, [discountAmount, itemTotal]);

  const tax = useMemo(
    () => total.minus(total?.dividedBy(1 + AppConfig.taxPercentage)),
    [total]
  );

  const subTotal = useMemo(() => total?.minus(tax), [total, tax]);

  const handleSubmit = (values: Store): void => {
    const { remarks } = values;

    onApply({
      lineItemId: lineItem?.id,
      discountId: discount?.id,
      // discountType: discount?.discountType,
      // discountAmount: discount?.discountAmount,
      // discountRate: discount?.discountRate,
      remarks: remarks || undefined,
    });
  };

  const handleCreateDiscount = async (
    input: CreateDiscountInput
  ): Promise<void> => {
    try {
      await createDiscount({
        variables: {
          input,
        },
      });
      await refetch({ showArchived: false, showVisible: true });
    } catch (error) {
      message.error(formatErrorMessage(error));
    }
  };

  const handleArchiveDiscount = async (id: string): Promise<void> => {
    try {
      await archiveDiscount({
        variables: {
          id,
        },
      });
      await refetch({ showArchived: false, showVisible: true });
    } catch (error) {
      message.error(formatErrorMessage(error));
    }
  };

  useEffect(() => {
    if (lineItem) {
      const { discountId: initDiscountId, discountRemarks } = lineItem;

      form.setFieldsValue({
        discountId: initDiscountId,
        remarks: discountRemarks || "",
      });
    }
  }, [lineItem]);

  const formatAmount = (str: string): string =>
    str?.replaceAll("$", "") < "0" ? "0" : str;

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      className="discount"
    >
      <Form.Item
        label={formatLabel("Discount")}
        name="discountId"
        className="w-full"
        rules={[
          {
            required: true,
            message: formatLabel("Field is required."),
          },
        ]}
      >
        <Select
          allowClear
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
          loading={discountLoading || archiveLoading}
          optionLabelProp="label"
        >
          {discounts?.map((item) => (
            <Select.Option
              key={item.id}
              value={item.id}
              label={
                <span>
                  {`${item.discountName}: ${
                    item.discountType === DiscountType.Amount
                      ? `${discountUnit[item.discountType]}${
                          item.discountAmount
                        }`
                      : `${item.discountRate}${discountUnit[item.discountType]}`
                  }`}
                </span>
              }
            >
              <div className="flex justify-between items-center">
                <span>
                  {`${item.discountName}: ${
                    item.discountType === DiscountType.Amount
                      ? `${discountUnit[item.discountType]}${
                          item.discountAmount
                        }`
                      : `${item.discountRate}${discountUnit[item.discountType]}`
                  }`}
                </span>
                <IconDelete
                  onClick={(e) => {
                    e.stopPropagation();
                    handleArchiveDiscount(item.id);
                    if (discountId === item.id) {
                      form.resetFields(["discountId"]);
                    }
                  }}
                />
              </div>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Collapse ghost destroyInactivePanel>
        <Collapse.Panel header={"Add New Discount"} key={1}>
          <DiscountForm loading={createLoading} onSave={handleCreateDiscount} />
        </Collapse.Panel>
      </Collapse>
      <Form.Item label={formatLabel("Remarks")} name="remarks" className="mt-2">
        <Input.TextArea maxLength={1000} />
      </Form.Item>
      <div className="flex flex-col w-full">
        {!discountAmount?.eq(0) ? (
          <div className="flex mt-2">
            <div className="w-6/12 text-primary-navyLight">
              <div>{formatLabel("Discount")}</div>
            </div>
            <div className="w-6/12 text-right">
              {discountAmount?.toFixed(2)
                ? `-${formatCurrency(discountAmount?.toFixed(2))}`
                : ""}
            </div>
          </div>
        ) : null}
        <div className="flex mt-2">
          <div className="w-6/12 text-primary-navyLight">
            <div>{`Subtotal without GST`}</div>
          </div>
          <div className="w-6/12 text-right">
            {subTotal?.toFixed(2)
              ? formatAmount(formatCurrency(subTotal?.toFixed(2)))
              : ""}
          </div>
        </div>

        <div className="flex mt-2">
          <div className="w-6/12 text-primary-navyLight">
            {`GST (${taxRatePercentage}%)`}
          </div>
          <div className="w-6/12 text-right">
            {tax?.toFixed(2)
              ? formatAmount(formatCurrency(tax?.toFixed(2)))
              : ""}
          </div>
        </div>

        <div className="flex mt-2">
          <div className="w-6/12 text-primary-navyLight">
            {formatLabel("Item Total")}
          </div>
          <div className="w-6/12 text-right">
            {total?.toFixed(2)
              ? formatAmount(formatCurrency(total?.toFixed(2)))
              : ""}
          </div>
        </div>
      </div>
      <Divider />
      <ModalButtonGroup
        onCancel={() => setVisible(false)}
        okText={"Confirm"}
        confirmLoading={loading}
        onOK={form.submit}
      />
    </Form>
  );
};
