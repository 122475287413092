import ConfirmModal from "@/components/common/ConfirmModal/ConfirmModal";
import FormSkeleton from "@/components/common/Skeleton/common/FormSkeleton";
import formatLabel from "@/utils/formatLabel";
import { Form, Input, Typography } from "@thepiquelab/archus-components-web";
import React from "react";
import "./index.scss";

interface Props {
  onConfirm: (values: any) => void;
  onCancel: () => void;
  visible: boolean;
  loading?: boolean;
}

const VoidPaymentRecordModal: React.FC<Props> = (props) => {
  const [form] = Form.useForm();
  const { onConfirm, onCancel, visible, loading } = props;
  return (
    <ConfirmModal
      title="Void Payment Record"
      visible={visible}
      onClose={() => {
        onCancel();
        form?.resetFields();
      }}
      onCancel={() => {
        onCancel();
        form?.resetFields();
      }}
      onConfirm={form.submit}
      loading={loading}
      noFooter={loading}
    >
      <div>
        <div className="mb-6">
          <Typography.Heading
            level={3}
            className="mt-2"
            data-testid="ConfirmModalContent"
          >
            Are you sure you want to proceed?
          </Typography.Heading>
        </div>
        {loading ? (
          <FormSkeleton fieldNumber={2} showButtons />
        ) : (
          <Form
            form={form}
            hideRequiredMark
            onFinish={async (value) => {
              await onConfirm(value);
              form?.resetFields();
            }}
            colon={false}
          >
            <Form.Item
              name="remarks"
              className="flex flex-col void_remarks"
              labelAlign="left"
              labelCol={{ span: 24 }}
              label={
                <Typography.Heading level={3} className="">
                  {formatLabel("remarks")}
                </Typography.Heading>
              }
              rules={[
                {
                  required: true,
                  message: "Field is required.",
                },
              ]}
            >
              <Input.TextArea allowClear rows={4} />
            </Form.Item>
          </Form>
        )}
      </div>
    </ConfirmModal>
  );
};

export default VoidPaymentRecordModal;
