import { CoreServiceApiClient } from "./CoreServiceApiClient";
import { CourseServiceApiClient } from "./CourseServiceApiClient";
import { CsdControllerServiceApiClient } from "./CsdControllerServiceApiClient";
import { EnrollmentServiceApiClient } from "./EnrollmentServiceApiClient";
import { ExportServiceApiClient } from "./ExportServiceApiClient";
import { FinanceServiceApiClient } from "./FinanceServiceApiClient";
import { UserServiceApiClient } from "./UserServiceApiClient";

export class AxiosClient {
  public static init(): void {
    CoreServiceApiClient.init();
    UserServiceApiClient.init();
    EnrollmentServiceApiClient.init();
    CourseServiceApiClient.init();
    ExportServiceApiClient.init();
    CsdControllerServiceApiClient.init();
    FinanceServiceApiClient.init();
    CourseServiceApiClient.init();
  }

  public static setAuthorization(token: string): void {
    CoreServiceApiClient.setAuthorization(token);
    UserServiceApiClient.setAuthorization(token);
    EnrollmentServiceApiClient.setAuthorization(token);
    CourseServiceApiClient.setAuthorization(token);
    ExportServiceApiClient.setAuthorization(token);
    CsdControllerServiceApiClient.setAuthorization(token);
    FinanceServiceApiClient.setAuthorization(token);
    CourseServiceApiClient.setAuthorization(token);
  }
}
