import { AppConfig } from "@/config";
import { CreateAxiosDefaults } from "axios";
import { BaseApiClient, InitProps } from "./BaseApiClient";

export class FinanceServiceApiClient extends BaseApiClient {
  public static init(config?: CreateAxiosDefaults & InitProps): void {
    super.init({
      ...config,
      baseURL: AppConfig.FinanceServiceUrl,
    });
  }
}
