import {
  BulkCreateHistory,
  BulkCreateHistoryQuery,
  BulkCreateHistoryQueryVariables,
  BulkCreateHistoryType,
  BulkCreateRegistration,
  BulkCreateRegistrationMutation,
  BulkCreateRegistrationMutationVariables,
  CompleteImportRegistrations,
  CompleteImportRegistrationsMutation,
  CompleteImportRegistrationsMutationVariables,
  ImportRegistrationHistoryCheckExport,
  ImportRegistrationHistoryCheckExportQuery,
  ImportRegistrationHistoryCheckExportQueryVariables,
} from "@/graphql";
import {
  MutationTuple,
  QueryResult,
  useMutation,
  useQuery,
} from "@apollo/client";

export const useBulkCreateHistoryHistory = (
  type: BulkCreateHistoryType
): QueryResult<BulkCreateHistoryQuery, BulkCreateHistoryQueryVariables> =>
  useQuery<BulkCreateHistoryQuery, BulkCreateHistoryQueryVariables>(
    BulkCreateHistory,
    {
      variables: {
        type,
      },
    }
  );

export const useImportRegistrationHistoryCheckExport = (): QueryResult<
  ImportRegistrationHistoryCheckExportQuery,
  ImportRegistrationHistoryCheckExportQueryVariables
> =>
  useQuery<
    ImportRegistrationHistoryCheckExportQuery,
    ImportRegistrationHistoryCheckExportQueryVariables
  >(ImportRegistrationHistoryCheckExport, {
    variables: { type: BulkCreateHistoryType.Registration },
  });

export const useCompleteImportRegistrations = (): MutationTuple<
  CompleteImportRegistrationsMutation,
  CompleteImportRegistrationsMutationVariables
> =>
  useMutation<
    CompleteImportRegistrationsMutation,
    CompleteImportRegistrationsMutationVariables
  >(CompleteImportRegistrations);

export const useBulkCreateRegistration = (): MutationTuple<
  BulkCreateRegistrationMutation,
  BulkCreateRegistrationMutationVariables
> =>
  useMutation<
    BulkCreateRegistrationMutation,
    BulkCreateRegistrationMutationVariables
  >(BulkCreateRegistration);
