// import { useAuth0 } from "@/Auth0Wrapper";
import { EnumPermissions } from "@/components/authorize/Permissions";
import { IsPermissionDebugMode } from "@/config";
import jwtDecode from "jwt-decode";
import { isBoolean, uniq } from "lodash";

interface IUseAccessControl {
  hasSomePermissions: (permissions: EnumPermissions[]) => boolean;
  hasAllPermissions: (permissions: EnumPermissions[]) => boolean;
}

const permissionsForTest: { [key in EnumPermissions]?: boolean } = {
  // [EnumPermissions.EnrollmentCreate]: true,
  // [EnumPermissions.EnrollmentView]: true,
  // [EnumPermissions.EnrollmentUpdate]: true,
  // [EnumPermissions.EnrollmentExport]: true,
  // [EnumPermissions.REGISTRATION_VIEW]: true,
  // [EnumPermissions.RegistrationCreate]: false,
  // [EnumPermissions.REGISTRATION_UPDATE]: true,
  // [EnumPermissions.RegistrationViewExport]: false,
  // [EnumPermissions.ARRANGEMENT_VIEW]: true,
  // [EnumPermissions.ARRANGEMENT_UPDATE]: false,
  // [EnumPermissions.ARRANGEMENT_UPDATE]: true,
  // [EnumPermissions.WHATSAPP_ACCOUNT_VIEW]: true,
  // [EnumPermissions.CAMOAINGN_VIEW]: true,
  // [EnumPermissions.ReportFinanceView]: true,
};

const mergePermissions = (
  permissions: EnumPermissions[]
): EnumPermissions[] => {
  if (!IsPermissionDebugMode) return permissions;
  return uniq(
    permissions
      .concat(Object.keys(permissionsForTest) as EnumPermissions[])
      .filter(
        (item) =>
          !isBoolean(permissionsForTest[item]) || permissionsForTest[item]
      )
  );
};

/**
 *
 * @returns {object} { hasSomePermissions, hasAllPermissions }
 *  hasSomePermissions - if it has one of required permissions
 *  hasSomePermissions - if it has all of required permissions
 */
export function useAccessControl(): IUseAccessControl {
  const token = sessionStorage.getItem("token") || "";
  const decodedToken: { permissions: EnumPermissions[] } = jwtDecode(token);
  const permissionsAllowed = mergePermissions(decodedToken.permissions || []);

  /**
   * to check if it has one of required permissions
   * @param {EnumPermissions[]} - permissions to check
   * @returns {boolean}
   */
  const hasSomePermissions = (permissions: EnumPermissions[]): boolean => {
    const result = permissions.some((permission) =>
      permissionsAllowed.includes(permission)
    );
    return result;
  };

  /**
   * to check if it has all of required permissions
   * @param permissions {EnumPermission[]} - permissions to check
   * @returns {boolean}
   */
  const hasAllPermissions = (permissions: EnumPermissions[]): boolean => {
    const result = permissions.every((permission) =>
      permissionsAllowed.includes(permission)
    );
    return result;
  };

  return {
    hasSomePermissions,
    hasAllPermissions,
  };
}
