import { AdvanceTableColumnType } from "@/components/common/AdvanceTable/AdvanceTable";
import {
  EnumEnhancedTableWidthType,
  enumEnhancedTableWidthType,
} from "@/components/common/EnhancedTable/EnhancedTable";
import ClassLink from "@/components/widget/CommonLink/ClassLink";
import { TaskIndexFieldsFragment, TaskType } from "@/graphql";
import formatLabel from "@/utils/formatLabel";
import { Typography } from "@thepiquelab/archus-components-web";
import { formatDate, formatTimeRange } from "@thepiquelab/archus-library";
import { Space } from "antd";
import TaskTable, { CustomCommonColumn } from "./TaskTable";
import { TaskTableProps } from "./types";

const FollowUpReplacementLessonOffer: React.FC<TaskTableProps> = (props) => {
  const { data, callback, visible, pagination, count, loading } = props;

  const columns: AdvanceTableColumnType[] = [
    CustomCommonColumn.StudentName,
    CustomCommonColumn.StudentID,
    CustomCommonColumn.PrimaryParentName,
    CustomCommonColumn.PrimaryParentMobile,
    CustomCommonColumn.PrimaryParentWALine,
    {
      title: formatLabel("Class Shorthand"),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(value: string, record: TaskIndexFieldsFragment) {
        const { attendance } = record;
        const { id: classId, shorthand } = attendance?.class || {};
        return <ClassLink classId={classId}>{shorthand}</ClassLink>;
      },
    },
    {
      title: formatLabel("Skip Lesson Date"),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.M_MEDIUM],
      render(
        value: string,
        record: TaskIndexFieldsFragment & { isTaskDetailModal: boolean }
      ) {
        const { attendance } = record;
        const lesson = attendance?.lesson;
        return (
          <Space
            direction={record?.isTaskDetailModal ? "horizontal" : "vertical"}
          >
            <Typography.Heading level={4}>
              {formatDate(lesson?.lessonStart)}
            </Typography.Heading>
            <Typography.Text size="middle" className=" text-primary-navyLight">
              {`${formatTimeRange(lesson?.lessonStart, lesson?.lessonEnd, {
                showDayOfWeek: true,
              })}`}
            </Typography.Text>
          </Space>
        );
      },
    },
    {
      title: formatLabel("Skip Lesson Number"),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(value: string, record: TaskIndexFieldsFragment) {
        const { attendance } = record;
        const lesson = attendance?.lesson;
        return <>{`Lesson ${lesson?.lessonNumber}`}</>;
      },
    },
    {
      title: formatLabel("Email"),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(value: string, record: TaskIndexFieldsFragment) {
        const emailStatus = record?.emailStatus;
        return (
          <Typography.Heading level={4}>
            {formatLabel(emailStatus)}
          </Typography.Heading>
        );
      },
    },
    {
      title: "WhatsApp",
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(value: string, record: TaskIndexFieldsFragment) {
        const emailStatus = record?.waStatus;
        return (
          <Typography.Heading level={4}>
            {formatLabel(emailStatus)}
          </Typography.Heading>
        );
      },
    },
  ];
  if (!visible) {
    return <></>;
  }

  return (
    <div>
      <TaskTable
        data={data}
        loading={loading}
        count={count}
        pagination={{
          ...pagination,
        }}
        columns={columns}
        title={formatLabel("Replacement Lesson Offer Follow Up")}
        taskType={TaskType.FollowUpReplacementLessonOffer}
        callback={callback}
      />
    </div>
  );
};

export default FollowUpReplacementLessonOffer;
