import { UserRole } from "@/apis/user/types";
import {
  GetAllBranchesQuery,
  GetAllVenuesQuery,
  GetDepartmentsQuery,
  GetFeaturesQuery,
  GetReferencesQuery,
} from "@/graphql";
import React, { createContext } from "react";
import Loading from "../Loading";
import { usePostCentreDataSubscription } from "./hooks/usePostCentreDataSubscription";
import { usePostFeatureDataSubscription } from "./hooks/usePostFeatureDataSubscription";
import { usePostReferenceDataSubscription } from "./hooks/usePostReferenceDataSubscription";

export const CommonCacheContext = createContext<{
  referenceData?: GetReferencesQuery["referenceDatas"] | null;
  referenceDataLoading: boolean;
  featureData?: GetFeaturesQuery["features"] | null;
  featureDataLoading: boolean;
  enabledFeatureData?: GetFeaturesQuery["features"] | null;
  enabledFeatureDataLoading: boolean;
  venuesData?: GetAllVenuesQuery | null;
  branchesData?: GetAllBranchesQuery | null;
  departmentsData?: GetDepartmentsQuery | null;
  centreDataLoading?: boolean;
  roleData?: UserRole[];
}>(null);

const CacheProvider: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = ({ children }) => {
  const { referenceData, referenceDataLoading } =
    usePostReferenceDataSubscription();
  const {
    featureData,
    featureDataLoading,
    enabledFeatureData,
    enabledFeatureDataLoading,
  } = usePostFeatureDataSubscription();
  const {
    venuesData,
    branchesData,
    departmentsData,
    loading: centreDataLoading,
  } = usePostCentreDataSubscription();

  const contextValue = {
    referenceData,
    referenceDataLoading,
    featureData,
    featureDataLoading,
    enabledFeatureData,
    enabledFeatureDataLoading,
    venuesData,
    branchesData,
    departmentsData,
    centreDataLoading,
  };

  return (
    <CommonCacheContext.Provider value={contextValue}>
      {featureDataLoading || enabledFeatureDataLoading ? <Loading /> : children}
    </CommonCacheContext.Provider>
  );
};

export default CacheProvider;
