import React from "react";
import {
  EnumImportRegistrationTempTitle,
  ErrorCheckDependency,
  getErrorByDataIndex,
} from "../Config";

interface RenderWrapProps {
  children: React.ReactNode;
  isEditing: boolean;
  title: EnumImportRegistrationTempTitle;
  value: string;
  setEditingTableKey: (key: string) => void;
  rowKey: string;
  data: ErrorCheckDependency;
}
export const RenderWrap: React.FC<RenderWrapProps> = ({
  children,
  value,
  isEditing,
  title,
  setEditingTableKey,
  rowKey,
  data,
}) => {
  const errorMessage = getErrorByDataIndex(value, title, data);
  const hasError = !!errorMessage;
  return (
    <>
      <div
        className="w-full h-full"
        onDoubleClick={(e) => {
          if (!isEditing) {
            setEditingTableKey(rowKey);
          }
          e.stopPropagation();
        }}
      >
        {isEditing ? children : value}
        <div
          className={`text-3 text-primary-red mt-2 ${
            !hasError ? "hidden" : ""
          }`}
        >
          {errorMessage}
        </div>
      </div>
    </>
  );
};
