import React from "react";
import { Link } from "react-router-dom";

interface ParentLinkProps {
  id: string;
  linkText?: string;
}
const ParentLink: React.FC<ParentLinkProps> = ({ id, linkText, children }) => {
  if (!id) return <>{children}</>;

  const linkUrl = `/customers/parents/profile/${id?.trim()}`;
  return (
    <Link
      to={linkUrl}
      target={"_blank"}
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <span className="font_semibold text-primary-blue">
        {linkText || children}
      </span>
    </Link>
  );
};

export default ParentLink;
