import { ReactComponent as IconDelete } from "@/assets/images/IconDelete.svg";
import { ReactComponent as IconEdit } from "@/assets/images/IconEdit.svg";
import { CreateTaskInput } from "@/graphql";
import formatErrorMessage from "@/utils/formatErrorMessage";
import formatLabel from "@/utils/formatLabel";
import { PlusCircleFilled } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Empty,
  Form,
  Input,
  Modal,
  Skeleton,
  Spin,
  message,
} from "antd";
import { omit, pick } from "lodash";
import React, { ReactNode, useEffect, useState } from "react";
import { useCreateTask, useQueryAllTasks, useUpdateTask } from "../hooks";

interface TaskCreateFromTemplateModalProps {
  listId: string;
  title?: string;
  callback?: () => Promise<void> | void;
  onClose: () => void;
  openTaskDetail?: (id: string) => void;
}

const TaskCreateFromTemplateModal: React.FC<
  TaskCreateFromTemplateModalProps
> = (props) => {
  const {
    title = "Create From Template",
    listId,
    callback,
    onClose,
    openTaskDetail,
  } = props;
  const [createTask, { loading: createTaskLoading }] = useCreateTask();
  const [templateId, setTemplateId] = useState<string>("");
  const [isAddingTemplate, setIsAddingTemplate] = useState(false);
  const [updateTask, { data, loading: updateTaskLoading }] = useUpdateTask();
  const [taskIdsToUnmakeTemplate, setTaskIdsToUnmakeTemplate] = useState<
    string[]
  >([]);

  const [
    queryAllTasks,
    {
      data: templateTasks,
      loading: queryTemplateLoading,
      refetch: refetchAllTasks,
    },
  ] = useQueryAllTasks({
    fetchPolicy: "network-only",
  });

  const templates = templateTasks?.tasks?.items?.map((task) => ({
    value: task?.id,
    label: task?.name,
  }));

  const [form] = Form.useForm();
  const addTask = async (value: any): Promise<void> => {
    const selectedTask = templateTasks?.tasks?.items?.find(
      (task) => task.id === templateId
    );
    const createInput: CreateTaskInput = {
      name: value.name,
      listId,
    };

    const keepChecklist = form?.getFieldValue("keepChecklist");
    const keepAssignee = form?.getFieldValue("keepAssignee");

    const isMarkAsTemplate = form?.getFieldValue("templateName");
    if (isMarkAsTemplate) {
      createInput.isTemplate = true;
      createInput.name = isMarkAsTemplate;
    }
    if (keepChecklist) {
      createInput.checklists = selectedTask?.checklists.map((checklist) => {
        let newChecklist = pick(checklist, ["name", "assigneeIds", "items"]);
        if (!keepAssignee) {
          newChecklist = omit(newChecklist, "assigneeIds");
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        newChecklist.items =
          newChecklist?.items?.map((item) =>
            pick(item, ["name", "assigneeIds"])
          ) || [];

        if (!keepAssignee) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          newChecklist.items =
            newChecklist?.items?.map((item) => omit(item, ["assigneeIds"])) ||
            [];
        }
        return newChecklist;
      });
    }
    if (keepAssignee) {
      createInput.assigneeIds = selectedTask?.assignees?.map(
        (assignee) => assignee?._id
      );
    }
    try {
      await createTask({
        variables: {
          input: createInput,
        },
      });

      /**
       * if is adding template, do not close modal.
       * And also need to reload the templates.
       */
      if (isMarkAsTemplate) {
        resetModal();
        queryTasksWithTemplate();
      } else {
        closeAndResetModal();
      }
      form.resetFields();
      callback?.();
    } catch (error) {
      message.error(formatErrorMessage(error));
    }
  };

  const closeAndResetModal = (): void => {
    resetModal();
    onClose();
  };
  const resetModal = (): void => {
    setTemplateId("");
    setIsAddingTemplate(false);
  };

  const renderSelectAndManageTemplate = (): ReactNode => (
    <>
      {queryTemplateLoading ? (
        <>
          <div className="flex gap-6 flex-col mb-6">
            <Skeleton.Button className="w-full" active />
            <Skeleton.Button className="w-full" active />
            <Skeleton.Button className="w-full" active />
          </div>
        </>
      ) : (
        <div className={`flex flex-col gap-6 mb-6`}>
          {templates?.map((template) => (
            <div className=" flex border-solid border rounded p-4 active-hover cursor-pointer">
              <div
                onClick={() => {
                  setTemplateId(template.value);
                  setIsAddingTemplate(false);
                  form?.setFieldValue("templateName", "");
                  form?.setFieldValue("name", template.label);
                }}
                className="flex-grow"
              >
                {template?.label}
              </div>
              <div className="flex gap-2">
                <IconEdit
                  onClick={(e) => {
                    e.stopPropagation();
                    // closeAndResetModal();
                    openTaskDetail?.(template.value);
                  }}
                />
                <Spin
                  spinning={
                    updateTaskLoading &&
                    taskIdsToUnmakeTemplate?.includes(template?.value)
                  }
                >
                  <IconDelete
                    onClick={async (e) => {
                      e.stopPropagation();
                      setTaskIdsToUnmakeTemplate([
                        ...taskIdsToUnmakeTemplate,
                        template.value,
                      ]);
                      try {
                        await updateTask({
                          variables: {
                            input: {
                              isTemplate: false,
                              id: template?.value,
                            },
                          },
                        });
                        setTaskIdsToUnmakeTemplate(
                          taskIdsToUnmakeTemplate?.filter(
                            (id) => id !== template.value
                          )
                        );
                        message.success("Template Removed Successfully.");
                        refetchAllTasks?.();
                        callback?.();
                      } catch (error) {
                        setTaskIdsToUnmakeTemplate(
                          taskIdsToUnmakeTemplate?.filter(
                            (id) => id !== template.value
                          )
                        );
                        message.error(formatErrorMessage(error));
                      }
                    }}
                  />
                </Spin>
              </div>
            </div>
          ))}
          {!templates?.length ? <Empty /> : <></>}
        </div>
      )}
      <div>
        {isAddingTemplate ? (
          <Form.Item
            label="Template Name"
            name="templateName"
            className=""
            rules={[
              {
                required: true,
                message: formatLabel("Field is required."),
              },
            ]}
            labelCol={{
              span: 24,
            }}
          >
            <Input maxLength={200} className="w-full" />
          </Form.Item>
        ) : (
          <></>
        )}
        <div className="flex gap-2 flex-row-reverse">
          {!isAddingTemplate ? (
            <Button
              icon={<PlusCircleFilled className="mr-2" />}
              type="primary"
              onClick={() => {
                setIsAddingTemplate(true);
              }}
            >
              Add Template
            </Button>
          ) : (
            <></>
          )}
          {isAddingTemplate ? (
            <>
              <Button
                type="primary"
                onClick={() => {
                  form.submit();
                }}
                loading={createTaskLoading}
              >
                Save
              </Button>
              <Button
                onClick={() => {
                  setIsAddingTemplate(false);
                  form.setFieldValue("templateName", "");
                }}
              >
                Cancel
              </Button>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );

  const renderCreateFromTemplate = (): ReactNode => (
    <>
      <Form.Item
        label="Task Name"
        name="name"
        rules={[
          {
            required: true,
            message: formatLabel("Field is required."),
          },
        ]}
        labelCol={{
          span: 24,
        }}
      >
        <Input maxLength={200} />
      </Form.Item>
      <div className="mb-6 font-semibold">Keep</div>
      <Form.Item
        name="keepChecklist"
        valuePropName="checked"
        className="no-margin"
      >
        <Checkbox>Checklists</Checkbox>
      </Form.Item>
      <Form.Item
        name="keepAssignee"
        valuePropName="checked"
        className="no-margin"
      >
        <Checkbox>Assignee</Checkbox>
      </Form.Item>
    </>
  );

  const queryTasksWithTemplate = (): void => {
    queryAllTasks({
      variables: {
        filterInput: {
          isTemplate: true,
        },
        pageInfo: {
          pageIndex: 0,
          pageSize: 0,
        },
      },
    });
  };

  useEffect(() => {
    if (listId) {
      queryTasksWithTemplate();
    }
  }, [listId]);

  return (
    <>
      <Modal
        title={title}
        onCancel={() => {
          closeAndResetModal();
        }}
        onOk={() => {
          form.submit();
        }}
        visible={!!listId}
        destroyOnClose
        footer={
          !templateId ? null : (
            <div>
              <Button
                onClick={() => {
                  if (templateId) {
                    resetModal();
                  } else {
                    closeAndResetModal();
                  }
                }}
              >
                Back
              </Button>
              <Button
                type="primary"
                loading={createTaskLoading}
                onClick={() => {
                  form.submit();
                }}
              >
                Create Task
              </Button>
            </div>
          )
        }
      >
        <Form
          onFinish={(values) => {
            addTask(values);
          }}
          colon={false}
          form={form}
        >
          {!templateId ? renderSelectAndManageTemplate() : <></>}
          {templateId ? renderCreateFromTemplate() : <></>}
        </Form>
      </Modal>
    </>
  );
};

export default TaskCreateFromTemplateModal;
