import { TableInCardSkeleton } from "@/components/common/Skeleton/TableSkeleton";
import { Empty, Row, Spin } from "@thepiquelab/archus-components-web";
import { ReactElement, ReactNode } from "react";
import InfiniteScroll from "react-infinite-scroller";
import TableItem from "./TableItem";

interface CollapseListProps<T> {
  dataSource?: T[];
  loadMore?: (page: number) => void;
  hasMore?: boolean;
  loading?: boolean;
  renderItem?: (item: T) => ReactNode;
}

const CollapseList = <T extends Record<string, any>>(
  props: CollapseListProps<T>
): ReactElement => {
  const { dataSource = [], hasMore, loading, loadMore, renderItem } = props;

  if (loading) {
    return (
      <div className="flex flex-col gap-6">
        <TableInCardSkeleton active size={5} />
      </div>
    );
  }

  if (!dataSource?.length) {
    return <Empty className="mt-20" />;
  }

  return (
    <InfiniteScroll
      initialLoad={false}
      pageStart={0}
      loadMore={loadMore}
      hasMore={hasMore}
      loader={
        <Row justify={"center"} key={0}>
          <Spin />
        </Row>
      }
      useWindow={false}
    >
      {dataSource.map((item) => renderItem?.(item))}
    </InfiniteScroll>
  );
};

CollapseList.TableItem = TableItem;

export default CollapseList;
