import createAuth0Client, {
  Auth0ClientOptions,
  AuthenticationError,
  LogoutOptions,
} from "@auth0/auth0-spa-js";
import Auth0Client from "@auth0/auth0-spa-js/dist/typings/Auth0Client";
import { message } from "antd";
import jwtDecode from "jwt-decode";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { actions } from "./MicroAppStateActions";
import { AxiosClient } from "./apis/clients";
import { UserApi } from "./apis/user/user.api";
import Loading from "./components/common/Loading";
import { AppConfig, Auth0Config } from "./config";

export interface ContextValue {
  isAuthenticated?: boolean;
  userInOrganization?: boolean;
  user?: any;
  dbUser?: any;
  logout(options?: LogoutOptions): void;
  getToken: () => Promise<string>;
}

interface Auth0ProviderProps {
  onRedirectCallback?: (appState: any) => void;
  children: React.ReactNode;
  clientOptions: Auth0ClientOptions;
}

const DEFAULT_REDIRECT_CALLBACK = (): void =>
  window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext<ContextValue>(
  {} as ContextValue
);
export const useAuth0 = (): ContextValue => useContext(Auth0Context);

export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  clientOptions,
}: Auth0ProviderProps): JSX.Element => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>();
  const [userInOrganization, setUserInOrganization] = useState<boolean>(true);
  const [user, setUser] = useState<any>();
  const [dbUser, setDbUser] = useState<any>();
  const [auth0Client, setAuth0] = useState<Auth0Client>();

  useEffect(() => {
    const initAuth0 = async (): Promise<void> => {
      const auth0FromHook = await createAuth0Client(clientOptions);
      setAuth0(auth0FromHook);
      if (
        (window.location.search.includes("code=") &&
          window.location.search.includes("state=")) ||
        window.location.search.includes("error=")
      ) {
        try {
          const { appState } = await auth0FromHook.handleRedirectCallback();
          onRedirectCallback(appState);
        } catch (error) {
          if (error instanceof AuthenticationError) {
            const errorMessage =
              error.message === "user is blocked"
                ? `Failed login: Your account has been deactivated. `
                : error.message;
            message.error(errorMessage, 5, () =>
              auth0FromHook.logout({ returnTo: window.location.origin })
            );
            return;
          }
        }
      }

      const isAuthorized = await auth0FromHook.isAuthenticated();
      setIsAuthenticated(isAuthorized);

      if (isAuthorized) {
        const accessToken = await auth0FromHook.getTokenSilently();
        sessionStorage.setItem("token", accessToken);
        actions.setGlobalState({ token: `Bearer ${accessToken}` });
        AxiosClient.setAuthorization(accessToken);

        const userClaims = await auth0FromHook.getIdTokenClaims();
        setUser(userClaims);

        try {
          await UserApi.syncRole();
          const res = await UserApi.me();
          if (!res?._id) {
            message.error("User not found.", 5, () => {
              auth0FromHook.logout({
                returnTo: Auth0Config.redirect_uri,
              });
            });
          }
          setDbUser(res);
          setUserInOrganization(true);
        } catch (error) {
          if (error.response?.status === 401) {
            message.error(error.response?.data?.message, 5, () => {
              auth0FromHook.logout({
                returnTo: Auth0Config.redirect_uri,
              });
            });
          } else if (error.response?.status === 403) {
            message.error(error.response?.data?.message, 5, () => {
              auth0FromHook.logout({
                returnTo: Auth0Config.redirect_uri,
              });
            });
          } else {
            message.error(error.response?.data?.message || error.message);
          }
        }
      } else {
        await auth0FromHook.loginWithRedirect({
          appState: {
            targetUrl: AppConfig.redirectPathname,
          },
        });
      }
    };
    initAuth0();
  }, [clientOptions, onRedirectCallback]);

  const getToken = async (): Promise<string> => {
    let token = sessionStorage.getItem("token");
    if (!token) {
      return null;
    }

    const decoded: { exp: number } = jwtDecode(token);

    if (moment(decoded.exp * 1000).isBefore()) {
      try {
        const newToken = await auth0Client.getTokenSilently();
        sessionStorage.setItem("token", newToken);
        token = newToken;
      } catch (error) {
        message.error("Refresh token failed, please refresh page.");
        window.location.reload();
      }
    }

    AxiosClient.setAuthorization(token);
    actions.setGlobalState({ token: `Bearer ${token}` });

    return token;
  };

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        userInOrganization,
        dbUser,
        logout: (options?: LogoutOptions) => {
          sessionStorage.clear();
          auth0Client.logout(options);
        },
        getToken,
      }}
    >
      {!dbUser ? <Loading /> : children}
    </Auth0Context.Provider>
  );
};
