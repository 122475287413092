import { useLazyQuery } from "@apollo/client";
import { DocumentNode } from "graphql";
import {
  LazyQueryHookOptions,
  LazyQueryResult,
  QueryLazyOptions,
} from "@apollo/client/react/types/types";
import { useEffect, useMemo, useState } from "react";
import { PageInfo } from "../graphql";

type LoadResult<TData> = {
  hasMore: boolean;
  loadMore: (pageInfo?: any) => Promise<void>;
  loadMoreLoading: boolean;
  globalData?: TData;
};

export type ReturnType<TData, TVariables> = [
  (options?: QueryLazyOptions<TVariables>) => void,
  LazyQueryResult<TData, TVariables>,
  LoadResult<TData>
];

export const useLoadMore = <
  TData extends {
    [key: string]:
      | {
          pageSize: number;
          pageIndex: number;
          total: number;
          items: [];
        }
      | any;
  },
  TVariables extends Record<string, any> & { pageInfo?: PageInfo }
>(
  query: DocumentNode,
  key: keyof TData,
  lazy = false,
  preload = false,
  enableScroll = false,
  options?: LazyQueryHookOptions<TData, TVariables>
): ReturnType<TData, TVariables> => {
  const [exec, result] = useLazyQuery<TData, TVariables>(query, {
    fetchPolicy: enableScroll ? "cache-first" : "network-only",
    nextFetchPolicy: "cache-first",
    ...options,
  });
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);

  const { data, fetchMore } = result;
  const hasMore = useMemo(() => {
    if (!data || !data[key]) return false;
    const {
      [key]: { total, pageIndex, pageSize },
    } = data;
    return (pageIndex + 1) * pageSize < total;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const loadPage = async (page: PageInfo) => {
    try {
      setLoadMoreLoading(true);
      await fetchMore<TData, { pageInfo: PageInfo }>({
        variables: { pageInfo: page },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          const { [key]: res } = fetchMoreResult;
          if (prev[key].pageIndex === res.pageIndex && enableScroll)
            return prev;

          return {
            [key]: {
              pageSize: res.pageSize,
              pageIndex: res.pageIndex,
              total: res.total,
              items: enableScroll
                ? [...prev[key].items, ...res.items]
                : [...res.items],
            },
          } as TData;
        },
      });
    } catch (e) {
      console.error(`[Load more ${key}] ${e.message}`, e);
    } finally {
      setLoadMoreLoading(false);
    }
  };

  const loadMore = async (pageInfo?: PageInfo): Promise<void> => {
    if (!data || !data[key]) return;

    const {
      [key]: { pageSize, total, pageIndex },
    } = data;

    const nextPage = {
      pageSize: pageInfo?.pageSize ?? pageSize,
      pageIndex: pageInfo?.pageIndex ?? pageIndex + 1,
    };

    await loadPage(nextPage);

    const hasNextPage = nextPage.pageSize * (nextPage.pageIndex + 1) < total;
    if (preload && hasNextPage) {
      await loadPage({ ...nextPage, pageIndex: nextPage.pageIndex + 1 });
    }
  };

  useEffect(() => {
    if (!lazy) exec();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (preload && data && fetchMore) {
      const {
        [key]: { pageSize, pageIndex, total },
      } = data;
      const hasNextPage = pageSize * (pageIndex + 1) < total;
      if (pageIndex === 0 && hasNextPage) {
        loadMore();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchMore, data, preload]);

  return [exec, result, { hasMore, loadMore, loadMoreLoading }];
};
