import CommonBadge from "@/components/widget/Badge/CommonBadge";
import { enumToArray } from "@/utils/enumUnit";
import formatLabel from "@/utils/formatLabel";
import { FC } from "react";

export enum UserRequestStatus {
  Pending = "PENDING",
  Approved = "APPROVED",
  Rejected = "REJECTED",
}

export const requestStatusMap = new Map(
  enumToArray(UserRequestStatus).map((status) => {
    switch (status.value) {
      case UserRequestStatus.Approved:
        return [
          status.value,
          { text: "Approved", color: "#64DD17", bgColor: "#64DD1733" },
        ];
      case UserRequestStatus.Pending:
        return [
          status.value,
          { text: "Pending", color: "#5B9DFF", bgColor: "#5B9DFF33" },
        ];
      case UserRequestStatus.Rejected:
        return [
          status.value,
          { text: "Rejected", color: "#F36B7F", bgColor: "#F36B7F33" },
        ];

      default:
        return [
          status.value,
          { text: status.key, color: "#5B9DFF", bgColor: "#5B9DFF33" },
        ];
    }
  })
);
export const RequestStatusTag: FC<{ status: UserRequestStatus }> = ({
  status,
}) => (
  <CommonBadge
    textColor={requestStatusMap.get(status)?.color}
    bgColor={requestStatusMap.get(status)?.bgColor}
    label={formatLabel(status)}
    className="w-maxcontent"
  />
);
