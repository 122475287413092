import ModalButtonGroup from "@/components/common/ModalButtonGroup";
import formatLabel from "@/utils/formatLabel";
import {
  Form,
  FormInstance,
  Input,
  Store,
} from "@thepiquelab/archus-components-web";
import { Modal } from "antd";
import React from "react";

interface Props {
  visible: boolean;
  form?: FormInstance;
  onCancel: () => void;
  onSubmit: (value: Store) => void;
  title: string;
  loading?: boolean;
  isEdit?: boolean;
}

const ChecklistModal: React.FC<Props> = (props) => {
  const {
    visible,
    onCancel,
    onSubmit,
    form: formInstance,
    title,
    loading,
    isEdit,
  } = props;
  const [form] = Form.useForm(formInstance);

  const handleSubmit = (value: Store): void => {
    onSubmit(value);
  };

  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={onCancel}
      footer={
        <ModalButtonGroup
          onCancel={onCancel}
          onOK={form.submit}
          confirmLoading={loading}
          okText={isEdit ? "Save" : "Add"}
        />
      }
    >
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Form.Item
          label={formatLabel("Checklist Name")}
          name="name"
          rules={[
            {
              required: true,
              message: formatLabel("Field is required."),
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ChecklistModal;
