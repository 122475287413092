import { Skeleton } from "antd";
import { ListSkeletonItem } from "./List";

const TaskDetailSkeleton = () => (
  <div className="flex-row gap-4">
    <div className="flex gap-2 justify-end">
      <Skeleton.Button active />
    </div>
    <div className="flex gap-4 justify-between mt-8 items-center">
      <Skeleton.Input active className="flex-grow w-full" />
    </div>
    <div className="flex gap-4 justify-between mt-8 items-center">
      <Skeleton.Input active className="flex-grow w-full" />
    </div>
    <div className="flex gap-4 justify-between mt-8 items-center">
      <Skeleton.Input active className="flex-grow w-full" />
    </div>
    <div className="flex gap-4 justify-between mt-8 items-center">
      <Skeleton.Input active className="flex-grow w-full" />
    </div>
    <div className="flex gap-2 mt-8 ">
      <Skeleton.Button active className="w-40" />
    </div>
    <ListSkeletonItem active />
    <ListSkeletonItem active />
    <div className="flex gap-2 py-6 justify-end">
      <Skeleton.Button active />
    </div>
  </div>
);

export default TaskDetailSkeleton;
