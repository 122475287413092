import { RemarkLevelType } from "../../../graphql";

export const RemarkLevelTypeMap = {
  [RemarkLevelType.General]: {
    text: "General",
    color: "#5B9DFF",
  },
  [RemarkLevelType.Low]: {
    text: "Low Risk",
    color: "#F3C342",
  },
  [RemarkLevelType.Medium]: {
    text: "Medium Risk",
    color: "#FF7324",
  },
  [RemarkLevelType.High]: {
    text: "High Risk",
    color: "#F36B7F",
  },
};

export interface ConsentType {
  termCondition?: boolean;
  accountCondition?: boolean;
  statements?: boolean;
  PhotographyOrVideography?: boolean;
}

export interface TelephoneType {
  countryCode?: string;
  number?: string;
}

export interface PhoneType {
  mobile: TelephoneType;
  homePhone?: TelephoneType;
  office?: TelephoneType;
}

export interface SchoolType {
  educationStage?: string;
  academicLevelName?: string;
  primarySchoolName?: string;
  secondarySchoolName?: string;
}

export interface ProfileUserDataType {
  fullName?: string;
  alias?: string;
  initialism?: string;
  roleType?: string;
  gender?: string;
  school?: SchoolType;

  departmentName?: string | string[];
  branchName?: string | string[];
  salutation?: string | string[];

  colorCode?: any;
  onColorCodeChange?(val: string): void;
  remarks?: string;
  onRemarksChange?(val: string): void;

  birthDate?: Date | string;
  emails?: string[] | string;
  phones?: PhoneType;
  address?: string[] | string;

  academicLevelId?: string;
  primarySchoolId?: string;
  primaryStream?: string;
  secondarySchoolId?: string;
  secondaryStream?: string;

  consent?: ConsentType;
  parentIds?: string[];
}

export interface ProfileProp<T> {
  UserData: T;
  handleEditButton?(): void;
}
