import { AppConfig } from "@/config";
import { AxiosError, CreateAxiosDefaults } from "axios";
import { BaseApiClient, InitProps } from "./BaseApiClient";

export class EnrollmentServiceApiClient extends BaseApiClient {
  public static init(config?: CreateAxiosDefaults & InitProps): void {
    super.init({
      ...config,
      baseURL: AppConfig.EnrollmentServiceUrl,
      response: (r) => r?.data || r,
      responseError: (e: AxiosError<{ message: string }>) => {
        throw new Error(e.response?.data?.message || e.message);
      },
    });
  }
}
