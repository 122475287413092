import { TaskIndexFieldsFragment, TaskOriginRecordType } from "@/graphql";
import { QueryTaskIndexProps } from "../index.utils";
import { TaskPagination } from "./TaskTable";
import { TaskCountType } from "./hooks";

export enum TaskTabType {
  FollowUpTasks = "Follow Up Tasks",
  ReminderTasks = "Reminder Tasks",
  OperationalTasks = "Operational Tasks",
}

export interface TaskTableProps {
  data: TaskIndexFieldsFragment[];
  loading: boolean;
  count: TaskCountType;
  completeLoading?: boolean;
  pagination: TaskPagination;
  callback?: (options?: QueryTaskIndexProps) => Promise<void> | void;
  visible: boolean;
  onInnerFilterChange?: (filter: TaskInnerFilterType) => void;
}

export interface TaskInnerFilterType {
  originRecordTypes?: TaskOriginRecordType[];
  isTransferLessonReminder?: boolean;
}
