import { AppConfig } from "@/config";
import { CreateAxiosDefaults } from "axios";
import { BaseApiClient, InitProps } from "./BaseApiClient";

export class CsdControllerServiceApiClient extends BaseApiClient {
  public static init(config?: CreateAxiosDefaults & InitProps): void {
    super.init({
      ...config,
      baseURL: AppConfig.CsdControllerServiceUrl,
      response: (r) => r?.data || r,
    });
  }
}
