import { ReactComponent as IconCopy } from "@/assets/images/IconCopy.svg";
import { ReactComponent as IconDelete } from "@/assets/images/IconDelete.svg";
import { ReactComponent as IconEdit } from "@/assets/images/IconEdit.svg";
import { ReactComponent as IconEditOutline } from "@/assets/images/IconEditOutline.svg";
import { ReactComponent as IconPinned } from "@/assets/images/IconPinned.svg";
import { useConfirmModal } from "@/components/common/ConfirmModal/NewConfirmModal";
import DropdownAction, {
  Action,
} from "@/components/common/DropdownAction/DropdownAction";
import { SortDirection, TaskIndexFieldsFragment, TaskStatus } from "@/graphql";
import { enumToLabelArray } from "@/utils/enumUnit";
import formatErrorMessage from "@/utils/formatErrorMessage";
import { WeekDay } from "@/utils/getWeeksOfYears";
import {
  CaretDownOutlined,
  EllipsisOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";
import {
  Button,
  Collapse,
  Dropdown,
  Menu,
  message,
} from "@thepiquelab/archus-components-web";
import { sortBy } from "lodash";
import { FC, memo, useEffect, useState } from "react";
import { QueryTaskIndexProps } from "../../index.utils";
import OperationalTaskDetailModal from "../TaskDetail/OperationalTaskDetailModal";
import TaskCreateFromTemplateModal from "../TaskDetail/TaskCreateFromTemplateModal";
import TaskCreateModal from "../TaskDetail/TaskCreateModal";
import {
  useDeleteTaskList,
  usePinTaskList,
  useQueryAllIndexTasks,
  useQueryTaskList,
} from "../hooks";
import OperationalTaskListModal, {
  ActionType,
} from "./OperationalTaskListModal";
import OperationalTaskTable from "./OperationalTaskTable";

interface Props {
  taskList: {
    id: string;
    name?: string;
    isPinned?: boolean;
  };
  className?: string;
  refetch?: () => void;
  refreshCount?: () => Promise<void> | void;
}

const sortWeekDay = (
  data: TaskIndexFieldsFragment[]
): TaskIndexFieldsFragment[] => {
  const weekDays = enumToLabelArray(WeekDay).map((item) => item.value);
  return sortBy(data, [(item) => weekDays.indexOf(item.name)]);
};

const ListCollapse: FC<Props> = (props) => {
  const { taskList, className = "", refetch, refreshCount } = props;
  const [visible, setVisible] = useState(false);
  const [actionType, setActionType] = useState<ActionType>();
  const [sorter, setSorter] = useState<QueryTaskIndexProps["sorter"]>();

  const [listIdToAddTask, setListIdToAddTask] = useState("");
  const [taskIdToEdit, setTaskIdToEdit] = useState("");
  const [listIdToAddTaskFromTemplate, setListIdToAddTaskFromTemplate] =
    useState("");

  const [queryAllIndexTasks, { data, loading, refetch: refetchAllIndexTasks }] =
    useQueryAllIndexTasks({
      fetchPolicy: "no-cache",
    });

  const { modal, show, close, setModalProps } = useConfirmModal();

  const [deleteTaskList, { loading: deleteLoading }] = useDeleteTaskList();
  const [pinTaskList, { loading: pinLoading }] = usePinTaskList();
  const [queryTaskList, { data: taskListData, loading: taskListLoading }] =
    useQueryTaskList({
      fetchPolicy: "network-only",
      onCompleted: () => {
        setVisible(true);
      },
    });

  useEffect(() => {
    setModalProps({ confirmLoading: deleteLoading });
  }, [deleteLoading]);

  const handleChange = (key: string[]): void => {
    if (key.length > 0) {
      queryAllIndexTasks({
        variables: {
          filterInput: {
            listIds: [taskList.id],
          },
          pageInfo: {
            pageSize: 0,
            pageIndex: 0,
          },
          sortInfo: getSortInfo(sorter),
        },
      });
    }
  };

  const getSortInfo = (
    sorter: QueryTaskIndexProps["sorter"]
  ): {
    field: string;
    direction: SortDirection;
  } => {
    const sortKey = sorter?.sortKey;
    const order = sorter?.sort;
    let sortDirection = null;
    if (order === "ascend") {
      sortDirection = SortDirection.Asc;
    } else if (order === "descend") {
      sortDirection = SortDirection.Desc;
    }
    return sortKey && sortDirection
      ? {
          field: sortKey,
          direction: sortDirection,
        }
      : null;
  };

  const editAction: Action = {
    icon: <IconEdit className="w-4" />,
    label: "Edit List",
    onClick: (id: string) => {
      setActionType(ActionType.Edit);
      queryTaskList({ variables: { id } });
    },
  };
  const copyAction: Action = {
    icon: <IconCopy className="w-4" />,
    label: "Copy List",
    onClick: (id: string) => {
      setActionType(ActionType.Copy);
      queryTaskList({ variables: { id } });
    },
  };
  const deleteAction: Action = {
    icon: <IconDelete className="w-4" />,
    label: "Delete List",
    onClick: (id: string) => {
      setModalProps({
        title: "Confirmation",
        contentDesc: "Are you sure you want to proceed?",
        onConfirm: () => {
          handleDeleteTaskList(id);
        },
        onClose: close,
      });
      show();
    },
  };

  const pinAction: Action = {
    icon: <IconPinned className="w-4" />,
    label: taskList?.isPinned ? "Unpin List" : "Pin List",
    onClick: (id: string) => {
      handlePinTaskList(id);
    },
  };

  const handleDeleteTaskList = async (id: string): Promise<void> => {
    try {
      await deleteTaskList({ variables: { id } });
      message.success("List deleted successfully!");
      close();
      refetch?.();
    } catch (error) {
      message.error(formatErrorMessage(error));
    }
  };

  const handlePinTaskList = async (id: string): Promise<void> => {
    try {
      await pinTaskList({ variables: { id } });
      refetch?.();
    } catch (error) {
      message.error(formatErrorMessage(error));
    }
  };

  if (
    data?.tasksWithIndex?.items?.length &&
    data?.tasksWithIndex?.items?.every(
      (item) => item.status === TaskStatus.Void
    )
  ) {
    return <></>;
  }
  return (
    <>
      <Collapse
        onChange={handleChange}
        bordered={false}
        className={`bg-white shadow rounded-md ${className}`}
      >
        <Collapse.Panel
          header={
            <div className="w-10/12 truncate font-semibold leading-7 flex gap-3 items-center">
              {taskList?.name}

              <div
                className="flex gap-3 items-center"
                onClick={async (e) => {
                  e.stopPropagation();
                  await handlePinTaskList(taskList?.id);
                }}
              >
                <IconPinned
                  className={`${
                    taskList?.isPinned
                      ? "text-primary-blue"
                      : "text-primary-navy"
                  } text-4`}
                />
                {taskList?.isPinned && (
                  <span className="text-primary-blue">Pinned</span>
                )}
              </div>
            </div>
          }
          key={taskList?.id}
          extra={
            <div
              className="flex items-center min-w-36 justify-end"
              onClick={(e) => {
                e?.stopPropagation();
              }}
            >
              <Dropdown.Button
                icon={<CaretDownOutlined />}
                onClick={() => {
                  setListIdToAddTask(taskList?.id);
                }}
                type="primary"
                className="mr-2"
                overlay={
                  <Menu
                    onClick={({ key }) => {
                      if (key === "template") {
                        setListIdToAddTaskFromTemplate(taskList?.id);
                      }
                    }}
                  >
                    <Menu.Item
                      icon={<IconEditOutline className="w-5 h-5" />}
                      key={"template"}
                    >
                      Create From Template
                    </Menu.Item>
                  </Menu>
                }
              >
                <div>
                  <PlusCircleFilled className="mr-2" />
                  Add Task
                </div>
              </Dropdown.Button>

              <DropdownAction
                id={taskList?.id}
                actions={[editAction, pinAction, copyAction, deleteAction]}
              >
                <Button loading={taskListLoading} icon={<EllipsisOutlined />} />
              </DropdownAction>
            </div>
          }
          className="no-padding"
        >
          <OperationalTaskTable
            data={
              data?.tasksWithIndex?.items?.length
                ? sortWeekDay(data?.tasksWithIndex?.items)
                : []
            }
            callback={(option) => {
              const sorter = option?.sorter;
              setSorter(sorter);

              refreshCount();
              queryAllIndexTasks({
                variables: {
                  filterInput: {
                    listIds: [taskList?.id],
                  },
                  pageInfo: {
                    pageSize: 0,
                    pageIndex: 0,
                  },
                  sortInfo: getSortInfo(sorter),
                },
              });
            }}
            visible
            loading={loading}
          />
        </Collapse.Panel>
      </Collapse>
      <OperationalTaskListModal
        refetch={refetch}
        actionType={actionType}
        taskListData={taskListData?.taskList}
        visible={visible}
        onCancel={() => {
          setVisible(false);
        }}
      />
      {modal}
      <TaskCreateModal
        listId={listIdToAddTask}
        onClose={() => {
          setListIdToAddTask("");
        }}
        callback={() => {
          refreshCount();
          refetchAllIndexTasks();
        }}
      />
      <TaskCreateFromTemplateModal
        listId={listIdToAddTaskFromTemplate}
        onClose={() => {
          setListIdToAddTaskFromTemplate("");
        }}
        callback={() => {
          refreshCount();
          refetchAllIndexTasks?.();
        }}
        openTaskDetail={(id: string) => {
          setTaskIdToEdit(id);
        }}
      />
      <OperationalTaskDetailModal
        callback={() => {
          refreshCount();
          refetchAllIndexTasks();
        }}
        taskId={taskIdToEdit}
        onClose={() => {
          setTaskIdToEdit("");
        }}
      />
    </>
  );
};

export default memo(ListCollapse);
