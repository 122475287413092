import { Row } from "antd";
import React, { useEffect, useState } from "react";
import Content, { ContentProps } from "./Content";
import Header, { HeaderProps } from "./Header";

interface ContainerSubComponents {
  Header: React.FC<HeaderProps>;
  Content: React.FC<ContentProps>;
}

export interface ContainerProps {
  className?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

/**
 * @example 
 * <Container {...args}>
    <Container.Header>Some contents here...</Container.Header>
    <Container.Content>
      <Container {...args}>
        <Container.Header>Some contents here...</Container.Header>
        <Container.Content>Some contents here...</Container.Content>
      </Container>
    </Container.Content>
  </Container>
  
 * @description 
  * This container component wraps content, and its height is based on the 
  content in the Header, while the Content's height adjusts accordingly. 
  Nested Container components are allowed within the Content, but 
  Header components should not be nested within Container.
 */
const Container: React.FC<ContainerProps> & ContainerSubComponents = (
  props: ContainerProps
) => {
  const { children, className = "", ...res } = props;

  const [body, setBody] = useState<React.ReactNode | null>(null);
  const [header, setHeader] = useState<React.ReactNode | null>(null);

  useEffect(() => {
    React.Children.forEach(children, (child: any) => {
      switch (child?.type) {
        case Header:
          setHeader(child);
          break;
        case Content:
          setBody(child);
          break;
        default:
          throw new Error(
            `Container doesn't support child: ${child.type.name}`
          );
      }
    });
  }, [children]);

  return (
    <Row
      {...res}
      className={`h-full w-full flex flex-col ${className}`}
      wrap={false}
    >
      {header}
      {body}
    </Row>
  );
};

Container.Header = Header;
Container.Content = Content;

export default Container;
