import { UserCustomiseColumn } from "@/graphql";
import formatLabel from "@/utils/formatLabel";
import { getDeepValue } from "@/utils/getDeepValueOfObject";
import { ColumnsType } from "@thepiquelab/archus-components-web";
import { FunctionComponent } from "react";
import { CommandType } from "../CommandCell";
import {
  DataPageCellProps,
  DataPageColumnProps,
  enumEnhancedTableWidthType,
  EnumEnhancedTableWidthType,
} from "./EnhancedTableType";
import { DefaultUserCustomiseColumns } from "./hooks/ColumnSeed";

export const transformFields = <T>(
  fields: Array<DataPageColumnProps<T>>
): ColumnsType<object> => {
  const result: ColumnsType<object> = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const field of fields) {
    const { sortable = true } = field;
    result.push({
      title: field.title,
      dataIndex: field?.field,
      fixed: field?.fixed,
      width:
        field?.width ||
        (enumEnhancedTableWidthType[field?.type] ??
          enumEnhancedTableWidthType[EnumEnhancedTableWidthType.M_MEDIUM]),
      render: (text: any, record: object) => {
        if (field?.cell) {
          return (field?.cell as FunctionComponent<DataPageCellProps<T>>)?.({
            dataItem: record,
          } as any);
        }
        if (field?.field) {
          return getDeepValue(record, field?.field);
        }
        return "";
      },
      sorter: sortable as boolean,
    });
  }

  return result;
};

export const calcTableScrollWidth = <T>(
  fields: Array<DataPageColumnProps<T>>
): number => {
  const result = transformFields(fields);

  return result.reduce(
    (width, column) => Number(width) + Number(column.width),
    0
  );
};

const fieldsFilter = (
  item: DataPageColumnProps<any>,
  tableName: string,
  consolidatedUserColumns: UserCustomiseColumn[],
  useColumnNameInFilter = false
): boolean => {
  if (!tableName || !consolidatedUserColumns) return true;

  const customiseColumnShowNameList = consolidatedUserColumns
    ?.filter((c) => c.isVisible)
    ?.map((c) =>
      useColumnNameInFilter
        ? [c.columnName, c.columnShowName, formatLabel(c.columnShowName)]
        : [c.columnShowName, formatLabel(c.columnShowName)]
    )
    .flat();

  return (
    customiseColumnShowNameList.includes(item.title) ||
    (useColumnNameInFilter && customiseColumnShowNameList.includes(item.field))
  );
};

const fieldsSorter = (
  a: DataPageColumnProps,
  b: DataPageColumnProps,
  tableName: string,
  consolidatedUserColumns: UserCustomiseColumn[],
  useColumnNameInFilter = false
): number => {
  if (!tableName || !consolidatedUserColumns) return 0;
  const customiseColumns = consolidatedUserColumns || [];
  const columnA = customiseColumns.find(
    (i) =>
      i.columnShowName === a.title ||
      (useColumnNameInFilter && i.columnName === a.field)
  );
  const columnB = customiseColumns.find(
    (i) =>
      i.columnShowName === b.title ||
      (useColumnNameInFilter && i.columnName === b.field)
  );
  if (!columnA || !columnB) return 0;
  return columnA.weight > columnB.weight ? 1 : -1;
};

export const calcAvailableFields = <T>(
  fields: DataPageColumnProps[],
  tableName: string,
  consolidatedUserColumns: UserCustomiseColumn[],
  useColumnNameInFilter = false,
  internalUserColumnsLoading: boolean,
  commands?: CommandType
): DataPageColumnProps<T>[] => {
  if (!consolidatedUserColumns?.length && internalUserColumnsLoading) {
    return new Array(10).fill({
      title: "",
      field: "show_commands",
      isShow: true,
      width: 250,
    });
  }

  const defaultUserCustomiseColumns = getDefaultUserCustomiseColumns(
    consolidatedUserColumns,
    tableName
  );

  const baseFields = fields
    .map(({ title = "", field, isShow = true, ...res }) => ({
      title,
      field,
      isShow,
      ...res,
    }))
    ?.filter((i) => i?.isShow);

  const result = baseFields
    ?.filter?.((i) =>
      fieldsFilter(
        i,
        tableName,
        defaultUserCustomiseColumns,
        useColumnNameInFilter
      )
    )
    ?.sort?.((a, b) =>
      fieldsSorter(
        a,
        b,
        tableName,
        defaultUserCustomiseColumns,
        useColumnNameInFilter
      )
    );

  if (commands) {
    result.push({
      title: "Options",
      field: "show_commands",
      isShow: true,
      sortable: false,
      fixed: "right",
      width: 90,
      cell: ({ dataItem }) => commands?.render(dataItem) as any,
    });
  }
  return result as unknown as DataPageColumnProps<T>[];
};

export const formatFieldsWidth = <T>(
  fields: Array<DataPageColumnProps<T>>
): (number | string)[] => {
  const result = transformFields(fields);

  return result?.map((i) => i?.width);
};

export const calcFieldsWidth = <T>(
  fields: Array<DataPageColumnProps<T>>
): number => {
  const result = transformFields(fields);
  const widthArray = result?.map((i) => i?.width);

  return widthArray?.reduce(
    (total, i) => Number(total) + Number(i),
    0
  ) as number;
};

const getDefaultUserCustomiseColumns = (
  consolidatedUserColumns: UserCustomiseColumn[],
  tableName: string
): UserCustomiseColumn[] => {
  const columns = consolidatedUserColumns?.length
    ? consolidatedUserColumns
    : (DefaultUserCustomiseColumns.filter(
        (i) => i?.tableName === tableName
      ) as UserCustomiseColumn[]);
  return columns;
};
