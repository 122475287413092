import { TaskOriginRecordType, TaskStatus, TaskType } from "@/graphql";

const getIsStatusCompleteOrVoid = (taskStatus: TaskStatus): boolean =>
  [TaskStatus.Complete, TaskStatus.Void].includes(taskStatus);

export const getIsNotAllowedToVoid = (
  taskType: TaskType,
  taskStatus?: TaskStatus
): boolean => {
  const isFeeBalanceFollowUp = [TaskType.FollowUpFeeBalance].includes(taskType);
  if (isFeeBalanceFollowUp) {
    return true;
  }
  const isStatusCompleteOrVoid = getIsStatusCompleteOrVoid(taskStatus);
  if (isStatusCompleteOrVoid) {
    return true;
  }
  return false;
};

export const getIsNotAllowedToReopen = (
  taskType: TaskType,
  taskStatus?: TaskStatus
): boolean => {
  if (taskStatus !== TaskStatus.Complete) return true;

  // Reminder/Follow Up tasks (Exclude Fee Balance)
  const noReopen = [TaskType.FollowUpFeeBalance].includes(taskType);
  if (noReopen) {
    return true;
  }

  return false;
};

export const getIsNotAllowedToComplete = (
  taskType: TaskType,
  taskStatus?: TaskStatus
): boolean => {
  const isFeeBalanceFollowUp = [TaskType.FollowUpFeeBalance].includes(taskType);
  if (isFeeBalanceFollowUp) {
    return true;
  }
  const isStatusCompleteOrVoid = getIsStatusCompleteOrVoid(taskStatus);
  if (isStatusCompleteOrVoid) {
    return true;
  }
  return false;
};

export const getIsNotAllowedToEdit = (
  taskType: TaskType,
  taskStatus?: TaskStatus
): boolean => {
  const isOperationalTask = [TaskType.Operational].includes(taskType);
  if (!isOperationalTask) {
    return true;
  }
  const isStatusCompleteOrVoid = getIsStatusCompleteOrVoid(taskStatus);
  if (isStatusCompleteOrVoid) {
    return true;
  }
  return false;
};

export const getIsNotAllowedToSend = (taskType: TaskType): boolean =>
  [TaskType.FollowUpFeeBalance].includes(taskType);

export const getIsNoSelection = (taskType: TaskType): boolean =>
  [TaskType.Operational, TaskType.FollowUpFeeBalance].includes(taskType);

export const CreationContextTypeMap = {
  [TaskOriginRecordType.FirstTrialFollowUpTask]: "1st Trial Follow Up Task",
  [TaskOriginRecordType.SecondaryTrialFollowUpTask]: "2nd Trial Follow Up Task",
  [TaskOriginRecordType.GapLessonReminder]: "GAP 1st Lesson Reminder",
  [TaskOriginRecordType.WorkshopWeekLessonReminder]:
    "Workshop Lesson Reminder (1 Week)",
  [TaskOriginRecordType.WorkshopDayNonContinuouslyLessonReminder]:
    "Workshop Lesson Reminder (1 Day)",
  [TaskOriginRecordType.WorkshopDayContinuouslyLessonReminder]:
    "Workshop Lesson Reminder (1 Day)",
  [TaskOriginRecordType.TermBillingDue]: "Term Billing Due",
  [TaskOriginRecordType.TermBillingOverDue]: "Term Billing Overdue",
  [TaskOriginRecordType.TermBillingPending]: "Term Billing Pending",
};
