import { useConfirmModal } from "@/components/common/ConfirmModal/NewConfirmModal";
import ModalButtonGroup from "@/components/common/ModalButtonGroup";
import FormSkeleton from "@/components/common/Skeleton/common/FormSkeleton";
import formatLabel from "@/utils/formatLabel";
import {
  Form,
  Input,
  Modal,
  Typography,
} from "@thepiquelab/archus-components-web";
import { useEffect, useState } from "react";
import { useOnHoldInvoice } from "../../FinanceHooks";

interface MarkOnHoldModalProps {
  visible: boolean;
  callback: (type?: string) => void;
  onClose: (e?: any) => void;
  id: string;
}
const MarkOnHoldModal: React.FC<MarkOnHoldModalProps> = ({
  visible,
  callback,
  onClose,
  id,
}) => {
  const [form] = Form.useForm();
  const [onHoldInvoice, { loading: onHoldInvoiceLoading }] = useOnHoldInvoice();

  return (
    <Modal
      style={{ width: 520 }}
      visible={visible}
      onClose={onClose}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      zIndex={1001}
      destroyOnClose
    >
      <Modal.Header title={"On Hold Invoice"} />
      <Modal.Content>
        {onHoldInvoiceLoading ? (
          <FormSkeleton fieldNumber={2} showButtons={false} />
        ) : (
          <Form
            className="w-full"
            form={form}
            layout="vertical"
            requiredMark={false}
            onFinish={async (value) => {
              const { onHoldRemarks } = value;
              await onHoldInvoice({
                variables: { input: { id, onHoldRemarks, isOnHold: true } },
              });
              callback?.();
              form?.resetFields();
              onClose?.();
            }}
          >
            <div className="mb-6">
              Placing this invoice on hold will disable any payments towards it
              and prevent sending notifications related to it. Do you want to
              proceed?
            </div>
            <Form.Item
              className="mb-0"
              name="onHoldRemarks"
              label={
                <Typography.Heading level={4}>
                  {formatLabel("Remarks")}
                </Typography.Heading>
              }
              rules={[
                {
                  required: true,
                  message: "Field is required.",
                },
              ]}
            >
              <Input.TextArea />
            </Form.Item>
          </Form>
        )}
      </Modal.Content>
      <Modal.Footer>
        {onHoldInvoiceLoading ? (
          <FormSkeleton fieldNumber={0} showButtons />
        ) : (
          <ModalButtonGroup
            okText="Yes"
            cancelText="No"
            onOK={() => {
              form.submit();
            }}
            onCancel={onClose}
          />
        )}
      </Modal.Footer>
    </Modal>
  );
};
export const useMarkOnHoldHandler = (
  id: string,
  callback: (type?: string) => void,
  option: {
    isOnHold: boolean;
  }
) => {
  const isOnHold = option?.isOnHold;
  const [visible, setVisible] = useState(false);
  const [onHoldInvoice, { loading: onHoldInvoiceLoading }] = useOnHoldInvoice();
  const onMarkOnHold = () => {
    if (!isOnHold) {
      setVisible(true);
    } else {
      removeOnHold();
    }
  };
  const { modal, setModalProps, show, close } = useConfirmModal();

  const removeOnHold = async () => {
    setModalProps({
      title: "Confirmation",
      contentDesc: "Are you sure you want to proceed?",
      onConfirm: async () => {
        await onHoldInvoice({
          variables: {
            input: { id, onHoldRemarks: null, isOnHold: false },
          },
        });
        close();
        callback?.();
      },
      onClose: () => {
        close();
      },
      confirmLoading: onHoldInvoiceLoading,
    });
    show();
  };
  useEffect(() => {
    if (onHoldInvoiceLoading) {
      setModalProps({
        confirmLoading: onHoldInvoiceLoading,
      });
    }
  }, [onHoldInvoiceLoading]);
  return {
    modal: (
      <>
        {modal}
        <MarkOnHoldModal
          onClose={() => {
            setVisible(false);
          }}
          visible={visible}
          id={id}
          callback={callback}
        />
      </>
    ),
    onMarkOnHold,
  };
};
