import { ErrorScreen } from "@thepiquelab/archus-components-web";
import InsufficientCredentials from "../../assets/images/InsufficientCredentials.svg";

const AccessDeniedPage: React.FC = () => (
  <ErrorScreen
    logo=""
    title="Access Denied"
    description="Sorry, you don't have access to this page."
    primaryButtonLabel=""
    illustration={InsufficientCredentials}
  />
);

export default AccessDeniedPage;
