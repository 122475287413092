/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
import {
  CellProfileProps,
  StudentCell,
} from "@/components/widget/UserCell/StudentCell";
import { GetSelectRegistrationTypeDataQuery } from "@/graphql";
import { Col, Row } from "antd";
import { Moment } from "moment";
import React, { memo } from "react";

const WaitingListItem: React.FC<{
  enrollmentsData?: GetSelectRegistrationTypeDataQuery["class"];
  classId?: string;
  // status?: EnrollmentStatus;
  profile?: CellProfileProps;
  // type?: EnrollmentType;
  date?: Moment;
  isEmpty?: boolean;
}> = (itemProps) => {
  const { profile, isEmpty = false } = itemProps;

  return (
    <Row className="px-4 py-2 min-h-16">
      <Col span={12}>{isEmpty ? "-" : <StudentCell profile={profile} />}</Col>
    </Row>
  );
};

export default memo(WaitingListItem);
