import { AdvanceTableColumnType } from "@/components/common/AdvanceTable/AdvanceTable";
import {
  EnumEnhancedTableWidthType,
  enumEnhancedTableWidthType,
} from "@/components/common/EnhancedTable/EnhancedTable";
import { GetIndexTasksQuery, TaskFieldsFragment, TaskType } from "@/graphql";
import formatLabel from "@/utils/formatLabel";
import { QueryTaskIndexProps } from "../../index.utils";
import AssigneeAvatars from "../TaskDetail/AssigneeAvatars";
import TaskTable from "../TaskTable";

interface ReminderTaskTrialLessonProps {
  completeLoading?: boolean;
  loading: boolean;
  callback?: (options?: QueryTaskIndexProps) => Promise<void> | void;
  visible: boolean;
  data: GetIndexTasksQuery["tasksWithIndex"]["items"];
}

const OperationalTaskTable: React.FC<ReminderTaskTrialLessonProps> = (
  props
) => {
  const { callback, visible, data, loading } = props;

  const columns: AdvanceTableColumnType[] = [
    {
      title: formatLabel("Task Name"),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(value, record: TaskFieldsFragment) {
        const { name } = record;
        return <div>{name}</div>;
      },
    },
    {
      title: formatLabel("Assignee"),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(value, record: TaskFieldsFragment) {
        const { assignees } = record;
        return (
          <div className="flex gap-2 flex-wrap">
            <AssigneeAvatars assignees={assignees} />
          </div>
        );
      },
    },
  ];
  if (!visible) {
    return <></>;
  }

  return (
    <div>
      <TaskTable
        data={data || []}
        loading={loading}
        columns={columns}
        title={formatLabel("Additional Lesson Reminder")}
        taskType={TaskType.Operational}
        callback={callback}
        hideHeader
      />
    </div>
  );
};

export default OperationalTaskTable;
