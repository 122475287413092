/* eslint-disable max-lines */
import { FinanceApi } from "@/apis/finance/finance.api";
import { InvoicePaymentCommand, PaymentModeMap } from "@/apis/finance/types";
import AuthorizedByPermission, {
  EnumAuthHandleType,
} from "@/components/authorize/AuthorizedByPermission";
import { EnumPermissions } from "@/components/authorize/Permissions";
import FormSkeleton from "@/components/common/Skeleton/common/FormSkeleton";
import IntegerInput from "@/components/course/CourseForms/IntegerInput";
import { useReferenceDataOptions } from "@/hooks/reference.hook";
import { useAccessControl } from "@/hooks/useAccessControl";
import useAuth from "@/hooks/useAuth";
import formatErrorMessage from "@/utils/formatErrorMessage";
import { graphqlValidator } from "@/utils/graphqlValidator";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
} from "@thepiquelab/archus-components-web";
import { formatCurrency } from "@thepiquelab/archus-library";
import { useRequest } from "ahooks";
import { Skeleton } from "antd";
import BigNumber from "bignumber.js";
import moment, { Moment } from "moment";
import {
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import {
  GetFinanceRecordOfAccountNumber,
  PaymentMode,
  ReferenceDataCategory,
} from "../../../graphql";
import formatLabel from "../../../utils/formatLabel";
import { Store } from "../../common/ProgressForm/types";
import {
  useLazyQueryFinanceRecordOfAccountNumber,
  useUpdatePaymentRecord,
  useVoidPaymentRecord,
} from "../FinanceHooks";
import { PaymentInfo, PaymentRecordModalProps } from "../types";
import BalanceAmountItem from "./BalanceAmountItem";
import {
  setFormInitialValues,
  usePaymentInfo,
} from "./PaymentRecordModalContentUtil";
import PaymentRecordList from "./SubComponent/PaymentRecordList";
import PaymentRecordStatus from "./SubComponent/PaymentRecordStatus";
import VoidPaymentRecordModal from "./VoidPaymentRecordModal";
import "./index.scss";
import { PaymentModeMaps, PaymentModeOptions } from "./util";

const PaymentRecordModalContent: ForwardRefRenderFunction<
  any,
  PaymentRecordModalProps & {
    paymentRecordStatus?: string;
    setPaymentRecordStatus?: (val: string) => void;
    verticalAlign?: boolean;
  }
> = (props, ref) => {
  const {
    invoiceId,
    onOk,
    arrangementId = null,
    paymentRecordStatus = "paid",
    setPaymentRecordStatus = (val: string) => {},
    verticalAlign = false,
    data,
    refetch,
    loading,
  } = props;
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [confirmModalVisible, setConfirmModalVisible] = useState<boolean>();
  const [paymentId, setPaymentId] = useState<string>();
  const { hasSomePermissions } = useAccessControl();

  useImperativeHandle(ref, () => ({
    onModalClose: () => {
      form?.resetFields();
    },
  }));

  const [financeRecordOfAccountNumber] =
    useLazyQueryFinanceRecordOfAccountNumber();

  const [updateRecord, { loading: updateLoading }] = useUpdatePaymentRecord();
  const [voidRecord, { loading: voidLoading }] = useVoidPaymentRecord();

  const { loading: createLoading, run } = useRequest(
    (p) => FinanceApi.bulkPayment(p),
    {
      throwOnError: true,
      manual: true,
    }
  );

  const { activePaymentInfo, setActivePaymentInfo } = usePaymentInfo(form);

  const { data: NameOfBankOptions } = useReferenceDataOptions(
    ReferenceDataCategory.NameOfBank
  );

  const { data: PaymentProcessorOptions } = useReferenceDataOptions(
    ReferenceDataCategory.PaymentProcessor
  );

  useEffect(() => {
    if (data) {
      const initValues = setFormInitialValues(data, isEditMode);
      form.setFieldsValue({ ...initValues });
    }
  }, [data, isEditMode]);

  const refetchQueries = [
    // { query: GetPaymentRecordModalData, variables: { invoiceId } },
    {
      query: GetFinanceRecordOfAccountNumber,
      variables: {
        parentId: data?.invoice?.billedTo?.financeStatement?.id,
      },
    },
  ];

  // TODO => known issue: click "Collect Fee" button will set "Paid" to 0 in ListInvoice, and AmountPayable in PaymentRecordModalContent will not update after record a payment
  // it's apollo cache problem, the void credit flow is fine.

  const handleCancelEdit = (): void => {
    form.resetFields();
    setIsEditMode(false);
    setActivePaymentInfo(null);
    const initValues = setFormInitialValues(data, false);
    form.setFieldsValue({ ...initValues });
  };

  const handleSubmit = async (values: Store): Promise<void> => {
    if (isEditMode) {
      const { amount, balanceAmount, depositDate, ...res } = values;
      try {
        await updateRecord({
          variables: {
            input: {
              id: activePaymentInfo.id,
              depositDate: depositDate
                ? moment(depositDate).toISOString()
                : undefined,
              ...res,
            },
          },
        });
        refetch({ invoiceId });
        setIsEditMode(false);
        setActivePaymentInfo(null);
        onOk?.();
        form.resetFields();
        message.success("Payment Recorded Successfully!");
      } catch (e) {
        message.error("Payment Recording Failed!");
        if (e?.graphQLErrors) {
          graphqlValidator(e?.graphQLErrors, form);
        }
      }
    } else {
      try {
        const { method, depositDate, ...res } = values;

        await run({
          ...res,
          method: PaymentModeMap[(method || PaymentMode.Credit) as PaymentMode],
          invoiceIds: [invoiceId],
          balanceAmount: values?.balanceAmount || 0,
          amount: new BigNumber(values?.amount || 0)
            .plus(values.balanceAmount || 0)
            .toNumber(),
          depositDate: depositDate
            ? moment(depositDate).toISOString()
            : undefined,
        } as InvoicePaymentCommand);
        refetch({ invoiceId });
        financeRecordOfAccountNumber({
          variables: {
            parentId: data?.invoice?.billedTo?.financeStatement?.id,
          },
        });
        form.resetFields();
        onOk?.();
        message.success("Payment Recorded Successfully!");
      } catch (e) {
        message.error("Payment Recording Failed!");
        if (e?.graphQLErrors) {
          graphqlValidator(e?.graphQLErrors, form);
        }
      }
    }
  };

  const handleCardClick = (input: PaymentInfo): void => {
    setIsEditMode(true);
    setActivePaymentInfo({
      ...input,
      method: PaymentModeMaps.get(input.method),
      amount: input?.amount || amountDue.toString(),
    });
  };

  const isFutureDate = (current: Moment): boolean =>
    current.isAfter(new Date(), "day");

  const handleVoidPaymentRecord = async (value: any): Promise<void> => {
    setConfirmModalVisible(true);
    try {
      await voidRecord({
        variables: { input: { paymentId, remarks: value?.remarks } },
        refetchQueries,
      });
      setConfirmModalVisible(false);
      refetch({ invoiceId });
      setActivePaymentInfo(null);
      onOk?.();
      message.success("Payment Voided Successfully!");
    } catch (error) {
      message.error(formatErrorMessage(error));
    }
    setConfirmModalVisible(false);
  };

  const method = Form.useWatch("method", form);
  const amount = Form.useWatch("amount", form);
  const balanceAmount = Form.useWatch("balanceAmount", form);

  // invoice outstanding amount
  const amountDue = useMemo(
    () => new BigNumber(data?.invoice?.total).minus(data?.invoice?.amountPaid),
    [data]
  );

  // invoice total amount
  const totalAmountDue = useMemo(
    () => new BigNumber(data?.invoice?.total),
    [data]
  );

  const methodRequired = useMemo(() => amount > 0, [amount]);

  const amountRequired = useMemo(
    () => method || new BigNumber(balanceAmount || 0).lte(0),
    [method, balanceAmount]
  );

  const isPayable = useMemo(() => amountDue.isGreaterThan(0), [amountDue]);

  const isFormVisible = isPayable || isEditMode;

  const FormButtons = () => (
    <div className="flex justify-end pr-5">
      {isEditMode && (
        <Button className="mr-4" onClick={handleCancelEdit}>
          {formatLabel("Cancel")}
        </Button>
      )}
      {isFormVisible && (
        <AuthorizedByPermission
          permissions={[EnumPermissions.INVOICE_UPDATE]}
          authHandleType={EnumAuthHandleType.HIDE}
        >
          <Button
            type="primary"
            onClick={form.submit}
            loading={
              (isEditMode ? updateLoading : createLoading) || voidLoading
            }
          >
            {formatLabel(isEditMode ? "Save" : "Record Payment")}
          </Button>
        </AuthorizedByPermission>
      )}
    </div>
  );

  const getFormContentWrapClass = () =>
    verticalAlign ? "" : "border-b border-solid border-gray-400";

  const getEditingColSpan = () => (verticalAlign ? 24 : 14);
  const showFormButton = (showFormButton: boolean) => {
    return (
      showFormButton && (
        <div className="pb-6">
          <FormButtons />
        </div>
      )
    );
  };

  const isEditLoading =
    (isEditMode ? updateLoading : createLoading) || voidLoading;

  const paymentUpdateAuth = useAuth([EnumPermissions.PAYMENT_UPDATE]);

  // if (hasSomePermissions([EnumPermissions.PAYMENT_VIEW]) === false) {
  //   return null;
  // }

  const validateAmountCollectNotExceedAmountDue = async () => {
    if (!balanceAmount) {
      return;
    }
    const total = new BigNumber(balanceAmount || 0).plus(
      new BigNumber(amount || 0)
    );
    if (total.gt(amountDue)) {
      throw new Error("Amount collect should not more than amount due.");
    }
  };

  return (
    <>
      <Form
        labelCol={{
          span: 10,
        }}
        wrapperCol={{
          span: 12,
          offset: 1,
        }}
        form={form}
        name="paymentRecordModalContent"
        colon={false}
        preserve={false}
        requiredMark={false}
        initialValues={{
          ...setFormInitialValues(data, isEditMode),
        }}
        className="typography_sub_heading2"
        onFinish={(values) => handleSubmit(values)}
        onFinishFailed={({ errorFields }) => {
          if (errorFields?.[0]?.name) {
            form.scrollToField(errorFields[0].name);
          }
        }}
      >
        <div className={getFormContentWrapClass()}>
          <Row>
            {paymentUpdateAuth && (
              <>
                <Title verticalAlign={verticalAlign} />
                {isFormVisible && (
                  <>
                    {isEditLoading ? (
                      <div className="w-full flex flex-col p-6 gap-6">
                        <FormSkeleton
                          showButtons={false}
                          fieldNumber={9}
                          className="flex-auto"
                        />
                        <div className="flex flex-row-reverse">
                          <Skeleton.Button className="w-32" active />
                        </div>
                      </div>
                    ) : (
                      <Col
                        span={getEditingColSpan()}
                        className="flex flex-col items-stretch pl-4"
                      >
                        <div>
                          <Row className="my-6">
                            <Col span={10} className="text-right pr-2">
                              {formatLabel("Total Amount Due")}
                            </Col>
                            <Col span={12} offset={1}>
                              {formatCurrency(totalAmountDue.toNumber())}
                            </Col>
                          </Row>
                          <Row className="my-6">
                            <Col span={10} className="text-right pr-2">
                              {formatLabel("Amount Due")}
                            </Col>
                            <Col span={12} offset={1}>
                              {formatCurrency(amountDue.toNumber())}
                            </Col>
                          </Row>
                          <BalanceAmountItem
                            creditRemain={
                              data?.invoice?.billedTo?.financeStatement
                                ?.creditRemain
                            }
                            maxBalance={amountDue.toNumber()}
                            isEditMode={isEditMode}
                            validator={validateAmountCollectNotExceedAmountDue}
                          />
                          <Form.Item
                            name={"amount"}
                            label={formatLabel("Amount Collected")}
                            dependencies={["method", "balanceAmount"]}
                            validateFirst
                            rules={[
                              {
                                required: amountRequired,
                                message: "Field is required.",
                              },
                              {
                                validator:
                                  validateAmountCollectNotExceedAmountDue,
                              },
                            ]}
                          >
                            <InputNumber
                              className="w-full"
                              prefix="$"
                              min={0.01}
                              precision={2}
                              disabled={isEditMode}
                            />
                          </Form.Item>
                          <Form.Item
                            initialValue={moment()}
                            name="paymentDate"
                            label="Payment Date"
                            rules={[
                              {
                                required: true,
                                message: "Field is required.",
                              },
                            ]}
                          >
                            <DatePicker
                              format={"D MMM YYYY"}
                              className="font_regular w-full"
                              disabledDate={isFutureDate}
                            />
                          </Form.Item>
                          <Form.Item
                            name={"method"}
                            label={formatLabel("Payment Mode")}
                            dependencies={["amount"]}
                            rules={[
                              {
                                required: methodRequired,
                                message: "Field is required.",
                              },
                            ]}
                          >
                            <Select
                              className="font_regular"
                              allowClear
                              getPopupContainer={(triggerNode) =>
                                triggerNode.parentNode
                              }
                              disabled={isEditMode}
                            >
                              {PaymentModeOptions.map((i) => (
                                <Select.Option key={i.value} value={i.value}>
                                  {i.label}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                          {method === PaymentMode.CreditCard && (
                            <>
                              <Form.Item
                                name={"paymentProcessor"}
                                label={
                                  <div>
                                    <div>
                                      {formatLabel("Payment Processor")}
                                    </div>
                                    <span className="text-sm text-primary-navyLight">
                                      (Optional)
                                    </span>
                                  </div>
                                }
                              >
                                <Select
                                  allowClear
                                  placeholder="Select Bank"
                                  options={PaymentProcessorOptions}
                                />
                              </Form.Item>
                              <Form.Item
                                name={"lastDigits"}
                                rules={[
                                  {
                                    len: 4,
                                    message: "Invalid last 4 digits.",
                                  },
                                ]}
                                label={
                                  <div>
                                    <div>{formatLabel("Last 4 Digits")}</div>
                                    <span className="text-sm text-primary-navyLight">
                                      (Optional)
                                    </span>
                                  </div>
                                }
                              >
                                <IntegerInput maxLength={4} />
                              </Form.Item>
                            </>
                          )}
                          {[PaymentMode.Cheque].includes(method) && (
                            <>
                              <Form.Item
                                name={"bankName"}
                                label={
                                  <div>
                                    <div>{formatLabel("Name of Bank")}</div>
                                    <span className="text-sm text-primary-navyLight">
                                      (Optional)
                                    </span>
                                  </div>
                                }
                              >
                                <Select
                                  allowClear
                                  placeholder="Select Bank"
                                  options={NameOfBankOptions}
                                />
                              </Form.Item>
                              <Form.Item
                                name={"chequeNumber"}
                                rules={[
                                  {
                                    len: 6,
                                    message: "Invalid cheque number.",
                                  },
                                ]}
                                label={
                                  <div>
                                    <div>{formatLabel("Cheque Number")}</div>
                                    <span className="text-sm text-primary-navyLight">
                                      (Optional)
                                    </span>
                                  </div>
                                }
                              >
                                <IntegerInput maxLength={6} />
                              </Form.Item>
                            </>
                          )}
                          {[PaymentMode.Cheque, PaymentMode.Cash].includes(
                            method
                          ) &&
                            methodRequired && (
                              <Form.Item
                                name={"depositDate"}
                                label={formatLabel("Deposit Date (Optional)")}
                              >
                                <DatePicker
                                  format={"D MMM YYYY"}
                                  className="font_regular w-full"
                                  disabled={!methodRequired}
                                  disabledDate={isFutureDate}
                                />
                              </Form.Item>
                            )}
                          <Form.Item
                            name={"reference"}
                            label={formatLabel("Reference (Optional)")}
                          >
                            <Input name={"reference"} maxLength={500} />
                          </Form.Item>
                          <Form.Item
                            name={"remarks"}
                            label={formatLabel("Remarks (Optional)")}
                          >
                            <Input name="remarks" maxLength={1000} />
                          </Form.Item>
                        </div>
                        {showFormButton(verticalAlign)}
                      </Col>
                    )}
                  </>
                )}
              </>
            )}
            {/* Payments Made , Voided Payments */}
            <PaymentRecordStatus
              loading={loading}
              isFormVisible={isFormVisible}
              verticalAlign={verticalAlign}
              permissions={[EnumPermissions.PAYMENT_VIEW]}
              paymentRecordStatus={paymentRecordStatus}
              setPaymentRecordStatus={setPaymentRecordStatus}
            >
              <PaymentRecordList
                data={data}
                NameOfBankOptions={NameOfBankOptions}
                PaymentProcessorOptions={PaymentProcessorOptions}
                paymentRecordStatus={paymentRecordStatus}
                activeItem={activePaymentInfo}
                onEdit={handleCardClick}
                onVoid={(val) => {
                  setPaymentId(val);
                  setConfirmModalVisible(true);
                }}
              />
            </PaymentRecordStatus>
          </Row>
        </div>
        {showFormButton(!verticalAlign)}
      </Form>
      <VoidPaymentRecordModal
        visible={confirmModalVisible}
        loading={voidLoading}
        onCancel={() => {
          setConfirmModalVisible(false);
        }}
        onConfirm={handleVoidPaymentRecord}
      />
    </>
  );
};

export default forwardRef(PaymentRecordModalContent);

function Title({ verticalAlign }: { verticalAlign: boolean }): JSX.Element {
  if (!verticalAlign) {
    return null;
  }
  return (
    <Col className="h-14 ant-col-24 border-1 leading-14 text-4 font-semibold pl-2 border-b border-gray-400">
      <span>Collect Fees</span>
    </Col>
  );
}
