import { GetPendingOverdueTaskCountQuery } from "@/graphql";
import { generateNotificationConfig } from "@thepiquelab/archus-components-web";
import { formatDate } from "@thepiquelab/archus-library";
import { notification } from "antd";
import moment from "moment";
import {
  checkIsFollowUpTask,
  checkIsOperationalTask,
  checkIsReminderTask,
} from "../index.utils";

export const useCheckOverdueNotification = () => {
  const checkOverdueNotification = (
    overdueCounts: GetPendingOverdueTaskCountQuery["getPendingOverdueTask"]
  ) => {
    const currentDate = moment().format("YYYY-MM-DD");
    const lastCheckDate = localStorage.getItem("lastCheckOverdueNotification");

    // check to notify only once every day even if there is no overdue task on first check
    if (currentDate === lastCheckDate) {
      return;
    }
    if (overdueCounts) {
      localStorage.setItem("lastCheckOverdueNotification", currentDate);
    }

    const followUpCounts =
      overdueCounts
        ?.filter((overdueCount) => checkIsFollowUpTask(overdueCount.type))
        ?.reduce((acc, overdueCount) => acc + overdueCount.overdue, 0) || 0;
    const reminderCounts =
      overdueCounts
        ?.filter((overdueCount) => checkIsReminderTask(overdueCount.type))
        ?.reduce((acc, overdueCount) => acc + overdueCount.overdue, 0) || 0;
    const operationalCounts =
      overdueCounts
        ?.filter((overdueCount) => checkIsOperationalTask(overdueCount.type))
        ?.reduce((acc, overdueCount) => acc + overdueCount.overdue, 0) || 0;

    const formattedDateText = formatDate(new Date(), {
      format: "SHORT",
    });

    const followUpCountsWarningText = followUpCounts
      ? `${followUpCounts} follow up task${followUpCounts > 1 ? "s" : ""}`
      : null;
    const reminderCountsWarningText = reminderCounts
      ? `${reminderCounts} reminder task${reminderCounts > 1 ? "s" : ""}`
      : null;
    const operationalCountsWarningText = operationalCounts
      ? `${operationalCounts} operational task${
          operationalCounts > 1 ? "s" : ""
        }`
      : null;

    const taskOverdueArray = [
      followUpCountsWarningText,
      reminderCountsWarningText,
      operationalCountsWarningText,
    ].filter(Boolean);

    // build text
    let taskOverdueText = taskOverdueArray.join("");
    if (taskOverdueArray.length === 2) {
      taskOverdueText = `${taskOverdueArray[0]} and ${taskOverdueArray[1]}`;
    }
    if (taskOverdueArray.length === 3) {
      taskOverdueText = `${taskOverdueArray[0]}, ${taskOverdueArray[1]} and ${taskOverdueArray[2]}`;
    }

    const allOverdueCounts =
      followUpCounts + reminderCounts + operationalCounts;
    if (followUpCounts || reminderCounts || operationalCounts) {
      notification.open(
        generateNotificationConfig({
          type: "info",
          title: "Overdue Tasks Reminder!",
          description: `As of ${formattedDateText}, there ${
            allOverdueCounts > 1 ? "are" : "is"
          } ${taskOverdueText} overdue. Your timely review of these would be appreciated. `,
        })
      );
    }
  };
  return {
    checkOverdueNotification,
  };
};
