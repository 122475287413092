import { FormInstance } from "antd";
import { GraphQLError } from "graphql";
import { FieldData } from "rc-field-form/lib/interface";

export const graphqlValidator = (
  error: readonly GraphQLError[],
  form: FormInstance<any>,
  callback?: (data: FieldData[]) => void
): void => {
  setTimeout(() => {
    const data = TypesettingData(error);
    if (callback) {
      callback(data);
    } else {
      form?.setFields(data);
      form?.scrollToField(data?.[0]?.name);
    }
  }, 0);
};

const recursionOfChildren = (data: any, name: string[] = []): FieldData[] =>
  data?.map(
    (i: {
      children: any;
      constraints: { [s: string]: unknown } | ArrayLike<unknown>;
      property: string;
    }) => {
      if (i?.children?.length) {
        return recursionOfChildren(i?.children, [...name, i.property]);
      }
      return {
        errors: [
          Object.values(i?.constraints)?.[
            Object.values(i?.constraints)?.length - 1
          ],
        ],
        name: [...name, i.property],
      };
    }
  );

export const TypesettingData = (
  error: readonly GraphQLError[]
): FieldData[] => {
  const { validationErrors = [] } = error?.[0]?.extensions;
  return recursionOfChildren(validationErrors)?.flat(3);
};
