import { InvoiceLineItemDO } from "@/components/finance/Modules/InvoiceDetail/InvoiceLineItemUtil";
import { InvoiceLineItem, LineItemFieldsFragment } from "@/graphql";
import {
  Form,
  Input,
  InputNumber,
  Store,
} from "@thepiquelab/archus-components-web";
import React from "react";
import formatLabel from "../../../../utils/formatLabel";
import ModalButtonGroup from "../../../common/ModalButtonGroup";
import { LineItem } from "./types";

export const Edit: React.FC<{
  lineItem: LineItem | LineItemFieldsFragment;
  onApply: (input: {
    description: string;
    quantity: number;
    unitAmount: number;
  }) => void;
  onCancel?: () => void;
  loading: boolean;
}> = ({ lineItem, onApply, loading, onCancel }): JSX.Element => {
  const [form] = Form.useForm();

  const handleSubmit = (values: Store) => {
    const { description, quantity, unitAmount } = values;
    onApply({ description, quantity, unitAmount });
  };

  const invoiceLineItemDO = new InvoiceLineItemDO(lineItem as InvoiceLineItem);

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      initialValues={lineItem}
      layout="vertical"
    >
      <Form.Item
        label={formatLabel("Name")}
        name="description"
        rules={[
          {
            required: true,
            message: formatLabel("Field is required."),
          },
        ]}
      >
        <Input maxLength={500} />
      </Form.Item>
      <Form.Item
        label="Qty"
        name="quantity"
        validateFirst
        rules={[
          {
            required: true,
            message: formatLabel("Field is required."),
          },
          // {
          //   type: "integer",
          //   message: "This value is invalid.",
          // },
        ]}
      >
        <InputNumber
          disabled={invoiceLineItemDO.isCourseFee()}
          className="w-full"
          min={0}
          step="1"
          precision={0}
        />
      </Form.Item>
      <Form.Item
        label="Price"
        name="unitAmount"
        validateFirst
        rules={[
          {
            required: true,
            message: formatLabel("Field is required."),
          },
        ]}
      >
        <InputNumber
          disabled={invoiceLineItemDO.isCourseFee()}
          className="w-full"
          prefix="$"
          min={0.0}
          precision={2}
          step="0.01"
        />
      </Form.Item>
      <ModalButtonGroup
        okText="Confirm"
        onOK={form.submit}
        confirmLoading={loading}
        onCancel={onCancel}
      />
    </Form>
  );
};
