import {
  ClassStatus,
  ClassType,
  GetWaitingListOverviewClassesQuery,
} from "@/graphql";

export const filterCompletedWorkshop = (
  classIndex: GetWaitingListOverviewClassesQuery["classesWithIndex"]["items"][0]
): boolean => {
  if (
    classIndex.status === ClassStatus.Completed &&
    classIndex.type === ClassType.Workshop
  ) {
    return false;
  }
  return true;
};
