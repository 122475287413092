import UserSettings from "@/components/settings/UserSettings/UserSettings";
import { SecurityScanFilled, SettingFilled } from "@ant-design/icons";
import { Menu } from "@thepiquelab/archus-components-web";
import { Modal } from "antd";
import React, { useState } from "react";
import { useAuth0 } from "../../../Auth0Wrapper";

const { Item } = Menu;

interface MenuBarDropdownProps {
  onDropdownVisible?: (visible: boolean) => void;
}

const MenuBarDropdown: React.FC<MenuBarDropdownProps> = (props) => {
  const { onDropdownVisible } = props;
  const auth0 = useAuth0();
  const [isShowUserSetting, setIsShowUserSetting] = useState<boolean>(false);

  const handleChangeModalVisible = (visible: boolean): void => {
    if (visible && onDropdownVisible) {
      onDropdownVisible(false);
    }

    setIsShowUserSetting(visible);
  };

  return (
    <Menu
      className="setting-menu shadow bg-white rounded"
      selectable={false}
      _internalDisableMenuItemTitleTooltip
    >
      <Item key="/settings/user" onClick={() => handleChangeModalVisible(true)}>
        <div className="flex items-center gap-2">
          <SettingFilled />
          <div className="flex items-center">User Settings</div>
        </div>
      </Item>
      <Item
        key="/logout"
        onClick={() => auth0.logout({ returnTo: window.location.origin })}
      >
        <div className="flex items-center gap-2">
          <SecurityScanFilled />
          <div className="flex items-center">Log Out</div>
        </div>
      </Item>
      <Modal
        visible={isShowUserSetting}
        onCancel={() => handleChangeModalVisible(false)}
        title="Profile Settings"
        width="520px"
        footer={null}
      >
        <UserSettings />
      </Modal>
    </Menu>
  );
};

export default MenuBarDropdown;
