import * as handleBars from "handlebars";

export enum ValidateHandlebarsCompileType {
  Save,
  Preview,
  Send,
}

export const generateHandleBar = (
  templateText: string,
  data: Record<string, any>,
  helpers?: { key: string; func: (data: any) => string | boolean }[]
): string => {
  helpers?.forEach((helper) =>
    handleBars?.registerHelper(helper.key, helper.func)
  );
  const template = handleBars.compile(templateText || "");
  const html = template(data);
  return html;
};

export const validateHandlebarsTemplateSyntax = (
  data: {
    subject?: string;
    whatsAppMessage?: string;
    emailMessage?: string;
    content?: string;
  },
  type: ValidateHandlebarsCompileType
): void => {
  const messageMap = {
    [ValidateHandlebarsCompileType.Save]:
      "Unable to save template due to syntax error. Please check your template details and try again.",
    [ValidateHandlebarsCompileType.Preview]:
      "Unable to preview template due to a template syntax error. Please check your template detail and try again.",
    [ValidateHandlebarsCompileType.Send]:
      "Unable to send message due to a template syntax error. Please check your template detail and try again.",
  };

  try {
    if (data.whatsAppMessage) {
      const template = handleBars.compile(data.whatsAppMessage);
      template({});
    }
    if (data.emailMessage) {
      const template = handleBars.compile(data.emailMessage);
      template({});
    }
    if (data.subject) {
      const template = handleBars.compile(data.subject);
      template({});
    }
    if (data.content) {
      const template = handleBars.compile(data.content);
      template({});
    }
  } catch (error) {
    throw new Error(messageMap[type]);
  }
};
