import {
  MutationTuple,
  QueryResult,
  QueryTuple,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import {
  CourseStatus,
  CreateRegularCourse,
  CreateRegularCourseMutation,
  CreateRegularCourseMutationVariables,
  CreateWorkShopCourse,
  CreateWorkShopCourseMutation,
  CreateWorkShopCourseMutationVariables,
  GetAllAcademicLevels,
  GetAllAcademicLevelsQuery,
  GetAllAcademicLevelsQueryVariables,
  GetCourse,
  GetCourseOfClass,
  GetCourseOfClassQuery,
  GetCourseOfClassQueryVariables,
  GetCourseQuery,
  GetCourseQueryVariables,
  GetOptionCourses,
  GetOptionCoursesQuery,
  GetOptionCoursesQueryVariables,
  UpdateCourse,
  UpdateCourseMutation,
  UpdateCourseMutationVariables,
} from "../../../graphql";

const useLazyQueryCourse = (
  id?: string
): QueryTuple<GetCourseQuery, GetCourseQueryVariables> =>
  useLazyQuery<GetCourseQuery, GetCourseQueryVariables>(GetCourse, {
    variables: { id },
  });

const useLazyQueryCourseOfClass = (
  id?: string
): QueryTuple<GetCourseOfClassQuery, GetCourseOfClassQueryVariables> =>
  useLazyQuery<GetCourseOfClassQuery, GetCourseOfClassQueryVariables>(
    GetCourseOfClass,
    {
      variables: { id },
    }
  );

const useQueryCourse = (
  id: string
): QueryResult<GetCourseQuery, GetCourseQueryVariables> =>
  useQuery<GetCourseQuery, GetCourseQueryVariables>(GetCourse, {
    variables: { id },
  });

const useQueryOptionCourses = (): QueryResult<
  GetOptionCoursesQuery,
  GetOptionCoursesQueryVariables
> =>
  useQuery<GetOptionCoursesQuery, GetOptionCoursesQueryVariables>(
    GetOptionCourses,
    {
      variables: {
        pageInfo: {
          pageIndex: 0,
          pageSize: 0,
        },
        filterInput: {
          statuses: [
            CourseStatus.Completed,
            CourseStatus.Created,
            CourseStatus.Ongoing,
          ],
        },
      },
    }
  );

const useCreateRegularCourse = (
  handleCompleted?: () => void
): MutationTuple<
  CreateRegularCourseMutation,
  CreateRegularCourseMutationVariables
> =>
  useMutation<
    CreateRegularCourseMutation,
    CreateRegularCourseMutationVariables
  >(CreateRegularCourse, {
    onCompleted() {
      handleCompleted?.();
    },
  });

const useCreateWorkShopCourse = (
  handleCompleted?: () => void
): MutationTuple<
  CreateWorkShopCourseMutation,
  CreateWorkShopCourseMutationVariables
> =>
  useMutation<
    CreateWorkShopCourseMutation,
    CreateWorkShopCourseMutationVariables
  >(CreateWorkShopCourse, {
    onCompleted() {
      handleCompleted?.();
    },
  });

const useUpdateCourse = (): MutationTuple<
  UpdateCourseMutation,
  UpdateCourseMutationVariables
> =>
  useMutation<UpdateCourseMutation, UpdateCourseMutationVariables>(
    UpdateCourse
  );

const useAcademicLevel = (): QueryResult<
  GetAllAcademicLevelsQuery,
  GetAllAcademicLevelsQueryVariables
> =>
  useQuery<GetAllAcademicLevelsQuery, GetAllAcademicLevelsQueryVariables>(
    GetAllAcademicLevels
  );

export {
  useAcademicLevel,
  useCreateRegularCourse,
  useCreateWorkShopCourse,
  useLazyQueryCourse,
  useLazyQueryCourseOfClass,
  useQueryCourse,
  useQueryOptionCourses,
  useUpdateCourse,
};
