import { formatDateTimeRange } from "@thepiquelab/archus-library";
import { find } from "lodash";
import React from "react";
import { EnrollmentType, InvoiceItemFieldsFragment } from "../../../../graphql";

// Modify type GetDetailRegistrationQuery from LineItemFieldsFragment
// if u need this type, please modify registrationDetail.graphql too
interface BillingItemCellProps {
  lineItem: InvoiceItemFieldsFragment["lineItems"][0];
  holidays?: { name: string }[];
  enrollmentType?: EnrollmentType;
  detailsType?: "class" | "lesson";
  snapLessons?: { lessonStart: string; lessonEnd: string; id: string }[];
  isInvoiceStatusPaid?: boolean;
  isAdhocAdditionalLesson?: boolean;
}

const BillingItemCell: React.FC<BillingItemCellProps> = (props) => {
  const {
    lineItem,
    enrollmentType,
    holidays,
    detailsType = "lesson",
    isInvoiceStatusPaid = false,
    snapLessons,
    isAdhocAdditionalLesson,
  } = props;
  const { description } = lineItem;

  return (
    <td className="align-top space-y-2 py-3 w-80">
      <div>
        <div className="font_semibold text-primary-navy text-lg">
          {description}
        </div>
      </div>

      {lineItem?.class && detailsType === "class" && (
        <div className="font_regular text-primary-navyLight">
          {lineItem?.class?.name}
        </div>
      )}

      {lineItem?.lessons && detailsType === "lesson" && (
        <>
          {enrollmentType === EnrollmentType.Additional &&
            !isAdhocAdditionalLesson &&
            !!holidays?.length && (
              <div className="font_regular text-primary-navyLight">
                Additional Lesson due to{" "}
                {holidays?.map((i) => i?.name)?.join(", ")}
              </div>
            )}
          {enrollmentType === EnrollmentType.Additional &&
            isAdhocAdditionalLesson && (
              <div className="font_regular text-primary-navyLight">
                Ad Hoc Additional Lesson
              </div>
            )}

          {lineItem?.lessons.map((record) => {
            const lesson =
              isInvoiceStatusPaid && snapLessons
                ? find(snapLessons, { id: record.lesson.id })
                : record.lesson;
            return (
              <div
                key={record.lesson.lessonNumber}
                className="font_regular text-primary-navyLight"
              >
                {`${formatDateTimeRange(
                  lesson?.lessonStart,
                  lesson?.lessonEnd
                )}${record.isMakeUp ? " (RL)" : ""}`}
              </div>
            );
          })}
        </>
      )}
    </td>
  );
};

export default BillingItemCell;
