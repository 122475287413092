import { prevWhatsAppMessage } from "@/components/settings/Template/tools";
import {
  BulkExportReminderDocxQueryVariables,
  BulkSendReminderInput,
  CompileTemplateMutation,
  EmailReminderInput,
  ExportReminderDocxQueryVariables,
  ReminderType,
  SendReminderInput,
  WhatsappReminderInput,
} from "@/graphql";
import { QueryLazyOptions } from "@apollo/client";
import { formatPhoneNumber } from "@thepiquelab/archus-library";
import { message } from "antd";
import { formatEmailMessage } from "./EmailMessage";
import { formatWhatsappMessage, formatWhatsappNumber } from "./WhatsappMessage";

export const handleCopyAllReminder = (props: {
  type: ReminderType;
  compiledData: CompileTemplateMutation["compileTemplate"][];
  copy: (content: string) => void;
  formatSalutation: (salutation: string, name: string) => string;
  isBulk: boolean;
}): void => {
  const { type, compiledData, copy, formatSalutation, isBulk } = props;

  let text = "";
  // eslint-disable-next-line no-restricted-syntax
  for (const compile of compiledData) {
    const parentName = formatSalutation(
      compile?.parent?.salutation,
      compile?.parent?.fullName
    );
    const studentsName = compile?.students
      ?.map((student) => student.fullName)
      ?.join(", ");

    if (isBulk) {
      if (type === ReminderType.Whatsapp) {
        text += `${formatWhatsappNumber(
          compile?.parent?.linkedWhatsappAccount
        )}\n<<${formatPhoneNumber(
          compile?.parent?.mobile
        )}>> <<${parentName}>> / <<${studentsName}>>\n\n${formatWhatsappMessage(
          compile
        )}\n\n---\n\n`;
      } else {
        text += `<<${
          compile?.parent?.email
        }>> <<${parentName}>> / <<${studentsName}>>\n\n${formatEmailMessage(
          compile
        )}\n\n---\n\n`;
      }
    } else if (type === ReminderType.Whatsapp) {
        text += `${formatWhatsappMessage(compile)}\n\n`;
      } else {
        text += `${formatEmailMessage(compile)}`;
      }
  }
  copy(text);
};

const messageKey = "tasks";
export const handleExportReminder = async (props: {
  type: ReminderType;
  whatsappInput?: WhatsappReminderInput;
  emailInput?: EmailReminderInput;
  isBulk: boolean;
  input: SendReminderInput | BulkSendReminderInput;
  bulkExportReminderDocx: (
    options?: QueryLazyOptions<BulkExportReminderDocxQueryVariables>
  ) => void;
  ExportReminderDocx: (
    options?: QueryLazyOptions<ExportReminderDocxQueryVariables>
  ) => void;
}): Promise<void> => {
  const {
    type,
    whatsappInput,
    emailInput,
    isBulk,
    input,
    bulkExportReminderDocx,
    ExportReminderDocx,
  } = props;

  message.loading({
    content: "Downloading",
    key: messageKey,
    duration: 0,
  });

  // text to html
  if (whatsappInput) {
    whatsappInput.message = `<p>${prevWhatsAppMessage(
      whatsappInput.message
    )}</p>`;
  }

  if (isBulk) {
    bulkExportReminderDocx({
      variables: {
        input: {
          ...input,
          types: [type],
          emailInput: type === ReminderType.Email ? emailInput : undefined,
          whatsappInput:
            type === ReminderType.Whatsapp ? whatsappInput : undefined,
        },
      },
    });
    return;
  }

  ExportReminderDocx({
    variables: {
      input: {
        ...input,
        types: [type],
        emailInput: type === ReminderType.Email ? emailInput : undefined,
        whatsappInput:
          type === ReminderType.Whatsapp ? whatsappInput : undefined,
      },
    },
  });
};
