import { CsdControllerServiceApiClient } from "../clients/CsdControllerServiceApiClient";
import { Page, PageQuery } from "../types";
import { Devices } from "./types";

export class CsdControllerApi {
  private static client = CsdControllerServiceApiClient;

  private static csdControllerPathPrefix = "/devices";

  public static async getDevices(params?: {
    pageQuery?: PageQuery;
  }): Promise<Page<Devices>> {
    const pageQuery = params?.pageQuery || {};

    return this.client.get(`${this.csdControllerPathPrefix}`, pageQuery);
  }

  public static async unlock(params?: { id?: string }): Promise<boolean> {
    return this.client.put(
      `${this.csdControllerPathPrefix}/${params?.id}/unlock`
    );
  }

  public static async openApp(params?: { id?: string }): Promise<boolean> {
    return this.client.put(
      `${this.csdControllerPathPrefix}/${params?.id}/open-app`
    );
  }

  public static async closeApp(params?: { id?: string }): Promise<boolean> {
    return this.client.put(
      `${this.csdControllerPathPrefix}/${params?.id}/close-app`
    );
  }

  public static async getDevice(id: string): Promise<Devices> {
    return this.client.get(`${this.csdControllerPathPrefix}/${id}`);
  }
}
