import { AdvanceTableColumnType } from "@/components/common/AdvanceTable/AdvanceTable";
import DigitalCredentialCell from "@/components/common/DigitalCredentialCell";
import {
  EnumEnhancedTableWidthType,
  enumEnhancedTableWidthType,
} from "@/components/common/EnhancedTable/EnhancedTable";
import ClassLink from "@/components/widget/CommonLink/ClassLink";
import { TeacherCell } from "@/components/widget/UserCell/TeacherCell";
import { ClassChangeLogType, TaskFieldsFragment, TaskType } from "@/graphql";
import { customizeEnumDisplay } from "@/utils/customizeEnumValue";
import formatLabel from "@/utils/formatLabel";
import { formatLocation } from "@/utils/formatLocation";
import { formatDate } from "@thepiquelab/archus-library";
import React from "react";
import TaskTable, { CustomCommonColumn } from "./TaskTable";
import { TaskTableProps } from "./types";

const ReminderTaskClassArrangementChange: React.FC<TaskTableProps> = (
  props
) => {
  const { data, callback, visible, pagination, count, loading } = props;

  const columns: AdvanceTableColumnType[] = [
    CustomCommonColumn.StudentName,
    CustomCommonColumn.StudentID,
    CustomCommonColumn.PrimaryParentName,
    CustomCommonColumn.PrimaryParentMobile,
    CustomCommonColumn.PrimaryParentWALine,
    {
      title: formatLabel("Class Shorthand"),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(value, record: TaskFieldsFragment) {
        const { attendance } = record;
        const { id: classId, shorthand } = attendance?.class || {};
        return <ClassLink classId={classId}>{shorthand}</ClassLink>;
      },
    },
    {
      title: formatLabel("Previous Value"),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(value, record: TaskFieldsFragment) {
        const { changeLog } = record || {};
        const type = changeLog?.type;

        if (
          [
            ClassChangeLogType.ClassTeacher,
            ClassChangeLogType.LessonTeacher,
          ].includes(type)
        ) {
          const previousTeacher = changeLog?.previousTeacher;
          const { fullName, salutation, gender, id } = previousTeacher || {};
          return (
            <div className="semi-bold">
              <TeacherCell
                avatarHidden
                isShowInitials={false}
                allowJump
                profile={{
                  salutation,
                  fullName,
                  gender,
                  id,
                }}
              />
            </div>
          );
        }
        if (
          [
            ClassChangeLogType.ClassVenue,
            ClassChangeLogType.LessonVenue,
          ].includes(type)
        ) {
          const venue = changeLog?.previousVenue;
          return (
            <>{venue ? formatLocation(venue?.branch, venue) : "Digital"}</>
          );
        }

        if (type === ClassChangeLogType.ClassLearningArrangement) {
          const LA = changeLog?.previousLearningArrangement;
          return <>{LA ? customizeEnumDisplay(LA, LA) : "-"}</>;
        }

        if (type === ClassChangeLogType.ClassZoomDetails) {
          const digitalCredentials = changeLog?.previousDigitalCredentials;
          return (
            <DigitalCredentialCell digitalCredentials={digitalCredentials} />
          );
        }

        return <>-</>;
      },
    },
    {
      title: formatLabel("Update Value"),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(value, record: TaskFieldsFragment) {
        const { changeLog } = record || {};
        const type = changeLog?.type;

        if (
          [
            ClassChangeLogType.ClassTeacher,
            ClassChangeLogType.LessonTeacher,
          ].includes(type)
        ) {
          const teacher = changeLog?.teacher;
          const { fullName, salutation, gender, id } = teacher || {};
          return (
            <div className="semi-bold">
              <TeacherCell
                avatarHidden
                isShowInitials={false}
                allowJump
                profile={{
                  salutation,
                  fullName,
                  gender,
                  id,
                }}
              />
            </div>
          );
        }
        if (
          [
            ClassChangeLogType.ClassVenue,
            ClassChangeLogType.LessonVenue,
          ].includes(type)
        ) {
          const venue = changeLog?.venue;
          return (
            <>{venue ? formatLocation(venue?.branch, venue) : "Digital"}</>
          );
        }

        if (type === ClassChangeLogType.ClassLearningArrangement) {
          const LA = changeLog?.learningArrangement;
          return <>{LA ? customizeEnumDisplay(LA, LA) : "-"}</>;
        }

        if (type === ClassChangeLogType.ClassZoomDetails) {
          const digitalCredentials = changeLog?.digitalCredentials;
          return (
            <DigitalCredentialCell digitalCredentials={digitalCredentials} />
          );
        }

        return <>-</>;
      },
    },

    {
      title: formatLabel("Lesson Date"),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(value, record: TaskFieldsFragment) {
        const { attendance } = record;
        return (
          <>
            {attendance?.lesson?.lessonStart
              ? formatDate(attendance?.lesson?.lessonStart, {
                  format: "SHORT",
                })
              : "-"}
          </>
        );
      },
    },
  ];

  if (!visible) {
    return <></>;
  }

  return (
    <div>
      <TaskTable
        loading={loading}
        data={data}
        count={count}
        columns={columns}
        title={formatLabel(
          "Change in Teacher/Venue/Learning Arrangement Reminder"
        )}
        taskType={TaskType.ReminderChangeClass}
        callback={callback}
        pagination={{
          ...pagination,
        }}
      />
    </div>
  );
};

export default ReminderTaskClassArrangementChange;
