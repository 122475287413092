import React, { ReactElement, ReactNode, cloneElement } from "react";

import { IsPermissionDebugMode } from "@/config";
import { useAccessControl } from "@/hooks/useAccessControl";
import { Tooltip } from "@thepiquelab/archus-components-web";
import { EnumCheckPermissionType } from "./AuthorizedRoute";
import { EnumPermissions } from "./Permissions";

/**
 * Enum for authHandleType.
 * @readonly
 * @enum {string}
 */
export enum EnumAuthHandleType {
  /** hide element if no permission */
  "HIDE" = "HIDE",
  /** disable element if no permission */
  "DISABLE" = "DISABLE",
  /** @description usually used for a button element, disable element if no permission, and show tooltip with same warning msg. */
  "DISABLE_WITH_TOOLTIP" = "DISABLE_WITH_TOOLTIP",
  /** @description usually used for a clickable text element, disable element if no permission, and show tooltip with same warning msg.  */
  "DISABLE_FOR_TEXT" = "DISABLE_FOR_TEXT",
  /** @description used for menu with items props, disable element if no permission, and show tooltip with same warning msg.  */
  "FOR_MENU" = "FOR_MENU",
}

export interface IAuthorizedPermissions {
  permissions?: EnumPermissions[];
  permissionCheckType?: EnumCheckPermissionType;
}

interface IAuthorizedByPermissionProps {
  authHandleType?: EnumAuthHandleType;
  noAccessNode?: ReactNode;
  children: ReactElement;
}
/**
 *
 * @param {EnumAuthHandleType} [param0.authHandleType=EnumAuthHandleType.HIDE] - how to handle if no permission
 * @param {EnumAuthHandleType} [param0.noAccessNode=null] - customize how to display if no permission
 * @param {EnumAuthHandleType} param0.permissions - permissions required to get element available, if no permission passed, keep it as it is.
 *
 * @returns
 */
const AuthorizedByPermission: React.FC<
  IAuthorizedByPermissionProps & Partial<IAuthorizedPermissions>
> = ({
  authHandleType = EnumAuthHandleType.DISABLE_WITH_TOOLTIP,
  noAccessNode = null,
  permissions,
  children,
}) => {
  const { hasAllPermissions } = useAccessControl();

  if (!children) return children;

  // static warning text if element is not available.
  let ACTION_DENIED_TEXT =
    "You do not have the required permission(s) to perform this action.";
  if (IsPermissionDebugMode && permissions?.length) {
    ACTION_DENIED_TEXT += `[${permissions?.join(",")}]`;
  }

  if (authHandleType === EnumAuthHandleType.FOR_MENU) {
    const newNode = cloneElement(children, {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      items: children?.props?.items.filter((item) => {
        const permissionCheckFailed =
          item?.permissions?.length && !hasAllPermissions(item?.permissions);
        return !permissionCheckFailed;
      }),
    });
    return newNode;
  }

  if (!permissions?.length) {
    return children;
  }
  if (hasAllPermissions(permissions)) {
    if (IsPermissionDebugMode) {
      return <Tooltip title={permissions?.join(" ")}>{children}</Tooltip>;
    }
    return children;
  }

  if (authHandleType === EnumAuthHandleType.HIDE) {
    return <>{noAccessNode}</>;
  }
  if (authHandleType === EnumAuthHandleType.DISABLE) {
    const newNode = cloneElement(children, {
      disabled: true,
      readonly: true,
      onClick: null,
    });
    return newNode;
  }
  if (authHandleType === EnumAuthHandleType.DISABLE_WITH_TOOLTIP) {
    const newNode = cloneElement(children, {
      disabled: true,
      onClick: null,
    });
    return <Tooltip title={ACTION_DENIED_TEXT}>{newNode}</Tooltip>;
  }
  if (authHandleType === EnumAuthHandleType.DISABLE_FOR_TEXT) {
    const newNode = cloneElement(children, {
      disabled: true,
      onClick: null,
      style: {
        ...(children?.props?.style || {}),
        cursor: "not-allowed",
      },
    });
    return <Tooltip title={ACTION_DENIED_TEXT}>{newNode}</Tooltip>;
  }

  return <></>;
};

export default AuthorizedByPermission;
