import { Space, Typography } from "@thepiquelab/archus-components-web";
import classNames from "classnames";
import React from "react";
import { ModalHeaderProps } from "./types";

/**
 * Modal Header component
 * that is rendered in the top section of Modal component
 *
 * @example Header with title and subtitle
 * ```
 * <Modal.Header title="Modal Title" subtitle="Modal subtitle" />
 * ```
 *
 * @example Header with title and children node
 * ```
 * <Modal.Header title="Modal Title">
 *  <MinimizeButton onClick={handleMinimize} />
 * </Modal>
 * ```
 */
const ModalHeader: React.FC<ModalHeaderProps> = ({
  className,
  children,
  title,
  subtitle,
  ...props
}) => {
  const classes = classNames(
    className,
    "common-modal__header flex flex-col items-start justify-center py-4"
  );

  return (
    <Space className={classes}>
      <Typography.Heading level={4}>{title}</Typography.Heading>
      {subtitle && (
        <Typography.Text size="middle" className="text-gray-500">
          {subtitle}
        </Typography.Text>
      )}
      {children}
    </Space>
  );
};

export default ModalHeader;
