import {
  CourseNotifications,
  CourseNotificationsQuery,
  CourseNotificationsQueryVariables,
  CreateCourseNotification,
  CreateCourseNotificationMutation,
  CreateCourseNotificationMutationVariables,
  DeleteCourseNotification,
  DeleteCourseNotificationMutation,
  DeleteCourseNotificationMutationVariables,
  GetStudentWithEnrollments,
  GetStudentWithEnrollmentsQuery,
  GetStudentWithEnrollmentsQueryVariables,
  GetWaitingListOverviewRegistrations,
  GetWaitingListOverviewRegistrationsQuery,
  GetWaitingListOverviewRegistrationsQueryVariables,
  PrioritizeRegistration,
  PrioritizeRegistrationMutation,
  PrioritizeRegistrationMutationVariables,
} from "@/graphql";
import {
  MutationTuple,
  QueryResult,
  QueryTuple,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client/react";

export const useLazyQueryWaitingListOverviewRegistrations = (): QueryTuple<
  GetWaitingListOverviewRegistrationsQuery,
  GetWaitingListOverviewRegistrationsQueryVariables
> =>
  useLazyQuery<
    GetWaitingListOverviewRegistrationsQuery,
    GetWaitingListOverviewRegistrationsQueryVariables
  >(GetWaitingListOverviewRegistrations, {
    fetchPolicy: "cache-and-network",
  });

export const usePrioritizeRegistration = (): MutationTuple<
  PrioritizeRegistrationMutation,
  PrioritizeRegistrationMutationVariables
> =>
  useMutation<
    PrioritizeRegistrationMutation,
    PrioritizeRegistrationMutationVariables
  >(PrioritizeRegistration);

export const useCreateCourseNotification = (): MutationTuple<
  CreateCourseNotificationMutation,
  CreateCourseNotificationMutationVariables
> =>
  useMutation<
    CreateCourseNotificationMutation,
    CreateCourseNotificationMutationVariables
  >(CreateCourseNotification);

export const useDeleteCourseNotification = (): MutationTuple<
  DeleteCourseNotificationMutation,
  DeleteCourseNotificationMutationVariables
> =>
  useMutation<
    DeleteCourseNotificationMutation,
    DeleteCourseNotificationMutationVariables
  >(DeleteCourseNotification);

export const useLazyQueryCourseNotifications = (): QueryTuple<
  CourseNotificationsQuery,
  CourseNotificationsQueryVariables
> =>
  useLazyQuery<CourseNotificationsQuery, CourseNotificationsQueryVariables>(
    CourseNotifications,
    {
      fetchPolicy: "cache-and-network",
    }
  );

export const useQueryStudentWithEnrollments = (): QueryResult<
  GetStudentWithEnrollmentsQuery,
  GetStudentWithEnrollmentsQueryVariables
> =>
  useQuery<
    GetStudentWithEnrollmentsQuery,
    GetStudentWithEnrollmentsQueryVariables
  >(GetStudentWithEnrollments, {
    fetchPolicy: "network-only",
    skip: true,
  });
