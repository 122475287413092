import { EnumPermissions } from "@/components/authorize/Permissions";
import DataPageHeader from "@/components/common/DataPage/DataPageHeader";
import { getProcessSessionInfo } from "@/components/registration/RegistrationDetail/common/SMS/utils";
import useAuth from "@/hooks/useAuth";
import formatLabel from "@/utils/formatLabel";
import {
  Breadcrumb,
  Container,
  Tabs,
} from "@thepiquelab/archus-components-web";
import { FC } from "react";
import { useHistory } from "react-router-dom";
import ClassWaitingList from "./ClassWaitingList";
import CourseNotificationList from "./CourseNotificationList";

const WaitingListOverview: FC = () => {
  const history = useHistory();

  const SessionInfo = getProcessSessionInfo({ source: "add_student" });

  const courseNotificationListViewAuth = useAuth([
    EnumPermissions.COURSE_NOTIFICATION_LIST_VIEW,
  ]);

  return (
    <Container>
      <Container.Header>
        <Breadcrumb>
          <Breadcrumb.Item
            className="cursor-pointer"
            onClick={() => {
              history.push("/dashboard");
            }}
          >
            {formatLabel("Dashboard")}
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {formatLabel("Waiting List Overview")}
          </Breadcrumb.Item>
        </Breadcrumb>
        <DataPageHeader
          className="mt-2"
          title={formatLabel("Waiting List Overview")}
        />
      </Container.Header>
      <Container.Content className="h-full overflow-auto">
        <Tabs
          destroyInactiveTabPane
          defaultActiveKey={
            SessionInfo?.sourceDetails?.tabKey || "ClassWaitingList"
          }
          className="leading-tight font_semibold text_sm text__primary h-inherit"
        >
          <Tabs.TabPane tab="Class Waiting List" key="ClassWaitingList">
            <ClassWaitingList />
          </Tabs.TabPane>
          {courseNotificationListViewAuth && (
            <Tabs.TabPane
              tab="Course Notification List"
              key="CourseNotificationList"
            >
              <CourseNotificationList />
            </Tabs.TabPane>
          )}
        </Tabs>
      </Container.Content>
    </Container>
  );
};

export default WaitingListOverview;
