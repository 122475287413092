interface StudentOfRequest {
  address: any;
  _id: string;
  fullName: string;
  alias: string;
  gender: string;
  primaryParentId: string;
  academicLevelId: string;
  userId: string;
}

interface ParentOfRequest {
  _id: string;
  salutation: string;
  fullName: string;
  alias: string;
  gender: string;
  mobile: {
    countryCode: string;
    number: string;
  };
  homePhone?: {
    countryCode: string;
    number: string;
  };
  email: string;
  address?: {
    streetAddress: string;
    gateBuilding: string;
    unit: string;
    country: string;
    postalCode: string;
  };
}

interface ClassOfRequest {
  _id: string;
  name: string;
  classCode: string;
  type: string;
  status: string;
  academicYearId: string;
  academicLevelIds: string[];
  defaultLessonDays: string[];
  defaultLessonStart: string;
  defaultLessonEnd: string;
  teacherId: string;
  courseId: string;
  learningArrangement: string;
  shorthand: string;
  firstLessonDate: string;
  course: {
    _id: string;
    name: string;
    type: string;
    academicLevelIds: string[];
  };
  teacher: {
    _id: string;
    fullName: string;
    salutation: string;
    avatarUrl?: string;
  };
}
export interface UserRequest {
  _id: string;
  referenceNumber?: string;
  type: UserRequestType;
  status: UserRequestStatus;
  studentId: string;
  classId: string;
  startDate: string;
  student: StudentOfRequest;
  parent: ParentOfRequest;
  class: ClassOfRequest;
  createDateTime?: string;
  processedUser?: {
    fullName?: string;
  };
}

export type Telephone = {
  countryCode: string;
  number: string;
};
export interface Student {
  _id: string;
  fullName: string;
  alias?: string;
  gender?: string;
  birthDate?: Date;
  avatarUrl?: string;
  primaryParentId?: string;
  academicLevelId?: string;
  primarySchoolId?: string;
  secondarySchoolId?: string;
  userId?: string;
  secondaryStream?: string;
}
export interface Parent {
  _id: string;
  fullName: string;
  alias?: string;
  gender?: string;
  salutation?: string;
  mobile: Telephone;
  homePhone?: Telephone;
  email?: string;
  avatarUrl?: string;
  userId?: string;
  stripeCustomerId?: string;
}

export enum LearningArrangementType {
  Onsite = "ONSITE",
  Digital = "DIGITAL",
  Hybrid = "HYBRID",
}

export enum CourseType {
  Regular = "REGULAR",
  Workshop = "WORKSHOP",
}
export enum CourseStatus {
  Created = "CREATED",
  Ongoing = "ONGOING",
  Completed = "COMPLETED",
  Terminated = "TERMINATED",
  Void = "VOID",
}
export interface Course {
  _id: string;
  avatarUrl?: string;
  name: string;
  nameShort: string;
  description?: string;
  learningResourceUrl?: string;
  status?: CourseStatus;
  type: CourseType;
  // courseFee: CourseFee;
  academicLevelIds: string[];
  academicStreams?: string[];
  // makeUpCredits: CourseMakeUpCredit;
  colorCode?: string;
  xeroAccountsSyncStatus?: boolean;
  termsAndConditions?: string;
  disclaimer?: string[];
  numberOfLessons?: number;
  duration?: number;
  voidRemarks?: string;
  isSkipScheduledHoliday?: boolean;
  isRequireFeedback?: boolean;
  subject?: string;
  gamePlanLink?: string;
  courseMaterial?: string;
}

export interface AddressDO {
  line1?: string;
  streetAddress?: string;
  gateBuilding?: string;
  link?: string;
  unit?: string;
  country?: string;
  postalCode?: string;
}

export interface BranchDTO {
  _id: string;
  name: string;
  alias: string;
  address: AddressDO;
  isArchived: boolean;
  systemId?: string;
}

export enum ClassType {
  Recurring = "RECURRING",
  Workshop = "WORKSHOP",
}

export enum ClassStatus {
  Incomplete = "INCOMPLETE",
  Created = "CREATED",
  Ongoing = "ONGOING",
  Completed = "COMPLETED",
  Void = "VOID",
}

export enum WeekDay {
  Monday = "MONDAY",
  Tuesday = "TUESDAY",
  Wednesday = "WEDNESDAY",
  Thursday = "THURSDAY",
  Friday = "FRIDAY",
  Saturday = "SATURDAY",
  Sunday = "SUNDAY",
}

export interface ClassDTO {
  _id: string;
  name: string;
  classCode?: string;
  type?: ClassType;
  status: ClassStatus;
  academicYearId: string;
  academicLevelIds: string[];
  defaultLessonDays?: WeekDay[];
  defaultLessonStart?: Date;
  defaultLessonEnd?: Date;
  teacherId?: string;
  venueId?: string;
  courseId: string;
  learningArrangement: LearningArrangementType;
  course?: Course;
  venue?: VenueDTO;
  teacher?: SystemUserDTO;
  capacity?: ClassCapacityDTO;
  isAdhoc?: boolean;
  isReplacementLesson?: boolean;
}

export interface ClassCapacityDTO {
  used: number;
  regular: number;
  replacement: number;
  isFull: boolean;
}

export interface SystemUserDTO {
  _id: string;
  fullName: string;
  salutation: string;
  avatarUrl?: string;
}
export interface VenueDTO {
  _id: string;
  name: string;
  capacity?: number;
  systemId?: string;
  address: AddressDO;
  isArchived: boolean;
  branchId: string;
  branch?: BranchDTO;
}
export interface UserRequestDTO {
  _id: string;
  referenceNumber?: string;
  type: UserRequestType;
  status: string;
  studentId: string;
  classId: string;
  remarks?: string;
  startDate: Date;
  student?: Student;
  parent?: Parent;
  class?: ClassDTO;
  createDateTime: Date;
  lastModifiedDateTime?: Date;
  processedUser: SystemUserDTO;
  eligibilityResults?: string[];
}

export enum UserRequestStatus {
  Pending = "PENDING",
  Approved = "APPROVED",
  Rejected = "REJECTED",
}

export enum UserRequestType {
  Trial = "TRIAL",
  WaitingList = "WAITING_LIST",
  Regular = "REGULAR",
}

export enum UserRequestSource {
  website = "Website",
  app = "App",
}

export interface GetRequestsFilterInput {
  dateRange?: string[];
  statuses?: UserRequestStatus[];
  sources?: string[];
}

export enum EnrollmentType {
  Regular = "REGULAR",
  Trial = "TRIAL",
  Additional = "ADDITIONAL",
}

export enum EnrollmentStatus {
  Holding = "HOLDING",
  Active = "ACTIVE",
  Complete = "COMPLETE",
  Cancelled = "CANCELLED",
}

export interface Enrollment {
  _id: string;
  type: EnrollmentType;

  status: EnrollmentStatus;

  startDate?: Date;

  endDate?: Date;

  classId: string;

  studentId: string;

  registrationId?: string;

  attendanceIds?: string[];

  isPartialEnrollment?: boolean;

  preferredLearningArrangement?: LearningArrangementType;

  followUpStatusLastModifiedDateTime?: Date;

  followUpStatusLastModifiedBy?: string;

  isEndRecurring?: boolean;
}

export enum RegistrationType {
  Regular = "REGULAR",
  Trial = "TRIAL",
}

export enum RegistrationStage {
  Regular = "REGULAR",
  Trial = "TRIAL",
  WaitingList = "WAITING_LIST",
  Reservation = "RESERVATION",
}

export class SelectLesson {
  public asMakeUp?: boolean;

  public lessonId: string;

  public makeUpLessonId?: string;
}

export declare enum RegistrationsStatus {
  Paid = "PAID",
  Complete = "COMPLETE",
  Expired = "EXPIRED",
  Waitlisted = "WAITLISTED",
  Cancelled = "CANCELLED",
  Reserved = "RESERVED",
  Draft = "DRAFT",
}

export interface WaitingListUpdateHistory {
  originalStatus?: RegistrationsStatus;

  status?: RegistrationsStatus;

  timestamp?: Date;
}

export interface Registration {
  _id: string;

  name?: string;

  type: RegistrationType;

  startDate?: Date;

  dueDate?: Date;

  currentStep?: number;

  invoiceId?: string;

  enrollmentId?: string;

  studentId: string;

  classId: string;

  selectedLessons?: SelectLesson[];

  status: RegistrationsStatus;

  remarks?: string;

  approvalDate?: Date;

  processedBy?: string;

  processedDateTime?: Date;

  isPrioritized?: boolean;

  updateHistories?: WaitingListUpdateHistory[];

  waitingListCreatedAt?: Date;

  linkUserRequestId?: string;
}

export interface SelectedLessonCommand {
  lessonId: string;
  byPass?: boolean;
  asMakeUp?: boolean;
  makeUpLessonId?: string;
}

export enum EnrollmentGroupType {
  A = "A",
  B = "B",
  Unassigned = "UNASSIGNED",
}

export interface RegistrationLessonModeCommand {
  lessonId: string;
  lessonMode?: LearningArrangementType;
}

export interface CreateRegistrationCommand {
  stage?: RegistrationStage;

  startDate?: Date;

  dueDate?: Date;

  studentId?: string;

  classId?: string;

  currentStep?: number;

  selectedLessons?: SelectedLessonCommand[];

  remarks?: string;

  linkRegistrationId?: string;

  preferredLearningArrangement?: LearningArrangementType;

  group?: EnrollmentGroupType;

  linkUserRequestId?: string;

  lessonModes?: RegistrationLessonModeCommand[];
}

export enum SubscriptionType {
  Registration = "REGISTRATION",
}

export interface SubscriptionData<T extends Record<string, any>> {
  type: SubscriptionType;
  data: T;
}
