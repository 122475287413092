import { UserCustomiseColumn } from "@/graphql";
import React from "react";

/**
 * Enum for Loading state types
 */
export enum LoadType {
  /** Loading state when fetch operation is in progress */
  FETCH = "FETCH",
  /** Loading state when apply operation is in progress */
  SAVE = "SAVE",
  /** Loading state when reset operation is in progress */
  RESET = "RESET",
}

/**
 * Interface for DisplayEditor's loading state
 */
export interface LoadState {
  /** Loading state boolean flag */
  isLoading: boolean;
  /** Loading state type */
  type: LoadType | null;
}

/**
 * Display Editor component props
 */
export interface DisplayEditorProps {
  /** Modal visibility flag */
  visible: boolean;
  /** List Items array */
  items: UserCustomiseColumn[];
  /** Function callback when cancel button is clicked */
  onCancel: () => void;
  /** Function callback when apply button is clicked */
  onApply: (items: UserCustomiseColumn[], savePreset?: boolean) => void;
  /** Function callback when reset button is clicked */
  onReset: () => void;
  /** Loading state */
  loadingState?: LoadState;
}

/**
 * Display Editor Footer component props
 */
export interface DisplayEditorFooterProps {
  /** Count of columns to be reset */
  count: number;
  /** Function callback when apply button is clicked */
  onApply: () => void;
  /** Function callback when reset button is clicked */
  onReset: () => void;
  /** Function callback when clear button is clicked */
  onClear: () => void;
  /** Apply operation in progress flag */
  applyLoading?: boolean;
  /** Reset operation in progress flag */
  resetLoading?: boolean;
  /**
   * Save configuration as preset boolean flag
   * This flag will be passed on to onApply callback in DisplayEditor
   */
  savePreset: boolean;
  setSavePreset: React.Dispatch<React.SetStateAction<boolean>>;
}
