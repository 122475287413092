import { FeatureAccessKeyword } from "@/graphql";
import { FC, memo, ReactElement, useMemo } from "react";
import { useQueryEnabledFeatures } from "./featureHook";
import { KEYWORDS } from "./utils";

interface Props {
  keywords: (typeof KEYWORDS)[number];
  children?: ReactElement;
}

const FeatureWrapper: FC<Props> = (props) => {
  const { keywords, children } = props;

  const { data } = useQueryEnabledFeatures();

  const features = useMemo(
    () => data?.enabledFeatures?.map((i) => i.keywords),
    [data]
  );

  return features?.includes(keywords as unknown as FeatureAccessKeyword) ? (
    children
  ) : (
    <></>
  );
};

export default memo(FeatureWrapper);
