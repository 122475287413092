import {
  CreateRemark,
  CreateRemarkMutation,
  CreateRemarkMutationVariables,
  DeleteRemark,
  DeleteRemarkMutation,
  DeleteRemarkMutationVariables,
} from "@/graphql";
import {
  MutationHookOptions,
  MutationTuple,
  useMutation,
} from "@apollo/client";

const useCreateRemark = (
  options?: MutationHookOptions<
    CreateRemarkMutation,
    CreateRemarkMutationVariables
  >
): MutationTuple<CreateRemarkMutation, CreateRemarkMutationVariables> =>
  useMutation<CreateRemarkMutation, CreateRemarkMutationVariables>(
    CreateRemark,
    options
  );

const useDeleteRemark = (
  options?: MutationHookOptions<
    DeleteRemarkMutation,
    DeleteRemarkMutationVariables
  >
): MutationTuple<DeleteRemarkMutation, DeleteRemarkMutationVariables> =>
  useMutation<DeleteRemarkMutation, DeleteRemarkMutationVariables>(
    DeleteRemark,
    options
  );

export { useCreateRemark, useDeleteRemark };
