import { AdvanceTableColumnType } from "@/components/common/AdvanceTable/AdvanceTable";
import { VariableNameArr } from "@/components/settings/Template/TemplateVariableConfig";
import { NotifyMissingInfoFieldsFragment } from "@/graphql";
import formatLabel from "@/utils/formatLabel";
import { Alert } from "@thepiquelab/archus-components-web";
import { Modal, Table } from "antd";
import { ReactElement, useMemo, useState } from "react";
import ClassLink from "../CommonLink/ClassLink";
import CourseLink from "../CommonLink/CourseLink";
import ParentLink from "../CommonLink/ParentLink";
import StudentLink from "../CommonLink/StudentLink";
import { TeacherCell } from "../UserCell/TeacherCell";

export interface MissingInformationModalProps {
  visible: boolean;
  onCancel: () => void;
  missingInfos?: NotifyMissingInfoFieldsFragment[];
}

export const MissingInformationModal: React.FC<MissingInformationModalProps> = (
  props
) => {
  const { onCancel, visible, missingInfos } = props;

  const variableNameMap = useMemo(() => {
    const map = new Map<string, string>();
    map.set("Email", "Recipient’s Email Address");
    VariableNameArr.forEach((variable) => {
      map.set(variable.val, variable.text);
    });
    return map;
  }, []);

  const columns: AdvanceTableColumnType[] = [
    {
      title: formatLabel("Student Name"),
      render: (value, record: NotifyMissingInfoFieldsFragment) =>
        record.student ? (
          <StudentLink
            id={record.student.id}
            linkText={record.student.fullName}
          />
        ) : (
          <>-</>
        ),
    },
    {
      title: formatLabel("Primary Parent Name"),
      render: (value, record: NotifyMissingInfoFieldsFragment) =>
        record.parent ? (
          <ParentLink id={record.parent.id} linkText={record.parent.fullName} />
        ) : (
          <>-</>
        ),
    },
    {
      title: formatLabel("Class"),
      render: (value, record: NotifyMissingInfoFieldsFragment) =>
        record.class ? (
          <ClassLink
            classId={record.class.id}
            linkText={record.class.shorthand}
          />
        ) : (
          <>-</>
        ),
    },
    {
      title: formatLabel("Course"),
      render: (value, record: NotifyMissingInfoFieldsFragment) =>
        record.course ? (
          <CourseLink
            courseId={record.course.id}
            linkText={record.course.name}
          />
        ) : (
          <>-</>
        ),
    },
    {
      title: formatLabel("Teacher"),
      render: (value, record: NotifyMissingInfoFieldsFragment) =>
        record.teacher ? (
          <TeacherCell
            profile={record.teacher}
            avatarHidden
            isShowInitials={false}
          />
        ) : (
          <>-</>
        ),
    },
    {
      title: formatLabel("Missing Information"),
      render: (value, record: NotifyMissingInfoFieldsFragment) => (
        <ul className="flex flex-col justify-center list-disc">
          {record.missingFields.map((field) => (
            <li key={field}>{variableNameMap.get(field)}</li>
          ))}
        </ul>
      ),
    },
  ];

  return (
    <Modal
      title={formatLabel("Missing Information")}
      visible={visible}
      onCancel={onCancel}
      footer={null}
      width={1300}
    >
      <Table
        columns={columns}
        dataSource={missingInfos}
        title={() => (
          <Alert
            type="info"
            description="Some messages contain incomplete details. Kindly review and update the missing information in the table below before proceeding."
          />
        )}
      />
    </Modal>
  );
};

export const useMissingInformationModal = (): {
  modal: ReactElement<
    Omit<MissingInformationModalProps, "visible" | "onCancel">
  >;
  setModalProps: (
    newProps: Omit<MissingInformationModalProps, "visible" | "onCancel">
  ) => void;
  close: () => void;
  show: () => void;
} => {
  const [visible, setVisible] = useState(false);

  const close = (): void => setVisible(false);
  const show = (): void => setVisible(true);

  const [props, setProps] = useState<
    Omit<MissingInformationModalProps, "visible" | "onCancel">
  >({});

  const setModalProps = (
    newProps: Omit<MissingInformationModalProps, "visible">
  ): void => {
    setProps({
      ...newProps,
    });
  };

  return {
    setModalProps,
    modal: (
      <MissingInformationModal {...props} visible={visible} onCancel={close} />
    ),
    close,
    show,
  };
};
