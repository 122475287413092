import { customizeMapConfig } from "../enumCustomizeConfig";

const customizeEnumValue =
  <T>(customizeMap: Record<keyof T | string, string>) =>
  (key: keyof T | string, defaultValue?: string): string =>
    customizeMap[key] ?? defaultValue;

const customizeEnumDisplay = customizeEnumValue(customizeMapConfig);

export default customizeEnumValue;
export { customizeEnumDisplay };
