import { UploadFilePath } from "@/graphql";
import { useUpload } from "@/hooks/upload/useUpload";
import { message, Spin, Upload } from "@thepiquelab/archus-components-web";
import { RcFile } from "antd/lib/upload/interface";
import React, { useEffect } from "react";
import UserAvatar, { UserAvatarProps } from "../UserAvatar/UserAvatar";

interface Props extends UserAvatarProps {
  path: UploadFilePath;
  // eslint-disable-next-line react/no-unused-prop-types
  onUploaded?: (url: string) => void;
}

const AvatarUpload: React.FC<Props> = (props) => {
  const { path, onUploaded, ...config } = props;

  const { uploadPublicFile, data, loading } = useUpload();

  useEffect(() => {
    if (data?.url) {
      onUploaded(data?.url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.url]);

  const upload = async (file: string | Blob | RcFile): Promise<void> => {
    try {
      const data = await uploadPublicFile({ variables: { file, path } });
      if (onUploaded) {
        onUploaded(data.data.uploadFile?.url);
      }
    } catch (error) {
      message.error("Upload avatar failed");
    }
  };

  return (
    <div
      className="w-maxcontent h-maxcontent cursor-pointer"
      data-testid="DragUpload"
    >
      <Upload
        accept=".jpg, .jpeg, .png, .svg, .webp"
        disabled={loading}
        showUploadList={false}
        multiple={false}
        customRequest={(options) => {
          upload(options.file);
        }}
      >
        <Spin spinning={loading}>
          <UserAvatar
            {...{ ...config, avatarUrl: data?.remoteUrl || config.avatarUrl }}
          />
        </Spin>
      </Upload>
    </div>
  );
};

export default AvatarUpload;
