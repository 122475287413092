import { ReactComponent as IconRegistered } from "../../../../assets/images/IconChecked.svg";
import { ReactComponent as IconPending } from "../../../../assets/images/progressIcon/IconPending.svg";
import { ReactComponent as IconComplete } from "../../../../assets/images/progressIcon/IconComplete.svg";
import { ReactComponent as IconApproved } from "../../../../assets/images/progressIcon/IconApproved.svg";
import { IconType } from "..";

interface IconPathsType {
  [key: string]: IconType;
}

export enum ProgressIconType {
  CREATE,
  AVAILABILITY,
  APPROVAL,
  PAYMENT,
  REFUND,
  DONE,
}

const IconPaths: IconPathsType = {
  [ProgressIconType.CREATE]: IconRegistered,
  [ProgressIconType.AVAILABILITY]: IconComplete,
  [ProgressIconType.APPROVAL]: IconApproved,
  [ProgressIconType.PAYMENT]: IconPending,
  [ProgressIconType.REFUND]: IconPending,
  [ProgressIconType.DONE]: IconComplete,
};

export default IconPaths;
