import { InvoiceStatus } from "@/graphql";
import { cloneDeep, find, pick } from "lodash";
import { InvoiceDetailType } from "./InvoiceDetailTypes";

/**
 * If the status of an invoice is 'paid', the information in the invoice should remain as it was at the moment of payment.
 * Even if the information is later updated, it should still reflect the version that existed at the time of payment.
 * @param invoiceData
 * @returns
 */
const handleSnapshot = (invoiceData: InvoiceDetailType): InvoiceDetailType => {
  const invoiceDetail: InvoiceDetailType = cloneDeep(invoiceData);
  if (!invoiceDetail) return invoiceDetail;

  const isInvoiceStatusPaid = [InvoiceStatus.Paid].includes(
    invoiceData?.status
  );
  const snapLessons = invoiceDetail?.snapshot?.lessons;

  invoiceDetail?.lineItems?.forEach((lineItem) => {
    lineItem?.lessons?.forEach((record) => {
      const lesson = isInvoiceStatusPaid
        ? find(snapLessons, { id: record?.lesson?.id })
        : record?.lesson;
      record.lesson = {
        ...record?.lesson,
        ...pick(lesson, ["lessonStart", "lessonEnd"]),
      };
    });
  });

  invoiceDetail.billedFor = isInvoiceStatusPaid
    ? {
        ...invoiceDetail?.billedFor,
        fullName: invoiceDetail?.snapshot?.studentFullName,
      }
    : invoiceDetail?.billedFor;

  invoiceDetail.billedTo = isInvoiceStatusPaid
    ? {
        ...invoiceDetail?.billedTo,
        fullName: invoiceDetail?.snapshot?.parentFullName,
      }
    : invoiceDetail?.billedTo;

  return invoiceDetail;
};

const useHandleInvoiceDetailData = (
  invoiceData: InvoiceDetailType
): InvoiceDetailType => {
  let result = invoiceData;

  result = handleSnapshot(result);
  return result;
};
export default useHandleInvoiceDetailData;
