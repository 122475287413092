import {
  MutationHookOptions,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import {
  ApproveRegistration,
  ApproveRegistrationMutation,
  ApproveRegistrationMutationVariables,
  CancelChangeAttendingClassForRegistration,
  CancelChangeAttendingClassForRegistrationMutation,
  CancelChangeAttendingClassForRegistrationMutationVariables,
  CancelRegistration,
  CancelRegistrationMutation,
  CancelRegistrationMutationVariables,
  ChangeAttendingClassForRegistration,
  ChangeAttendingClassForRegistrationMutation,
  ChangeAttendingClassForRegistrationMutationVariables,
  CreateBillingforRegistration,
  CreateBillingforRegistrationMutation,
  CreateBillingforRegistrationMutationVariables,
  CreateWaitingListsForMultipleClasses,
  CreateWaitingListsForMultipleClassesMutation,
  CreateWaitingListsForMultipleClassesMutationVariables,
  ExecuteEligibilityFlowWithCourseId,
  ExecuteEligibilityFlowWithCourseIdQuery,
  ExecuteEligibilityFlowWithCourseIdQueryVariables,
  ExtendsRegistrationTime,
  ExtendsRegistrationTimeMutation,
  ExtendsRegistrationTimeMutationVariables,
  GetCancelClassById,
  GetCancelClassByIdQuery,
  GetCancelClassByIdQueryVariables,
  GetClassForReservation,
  GetClassForReservationQuery,
  GetClassForReservationQueryVariables,
  GetClassesInCourse,
  GetClassesInCourseQuery,
  GetClassesInCourseQueryVariables,
  GetOptionClasses,
  GetOptionClassesQuery,
  GetOptionClassesQueryVariables,
  GetRegistrationById,
  GetRegistrationByIdQuery,
  GetRegistrationByIdQueryVariables,
  GetWaitingListMoreClasses,
  GetWaitingListMoreClassesQuery,
  GetWaitingListMoreClassesQueryVariables,
  ProcessRegistration,
  ProcessRegistrationMutation,
  ProcessRegistrationMutationVariables,
  RegenerateRegistration,
  RegenerateRegistrationMutation,
  RegenerateRegistrationMutationVariables,
  UpdateEnrollmentPreferredLearningArrangement,
  UpdateEnrollmentPreferredLearningArrangementMutation,
  UpdateEnrollmentPreferredLearningArrangementMutationVariables,
  UpdateRegistration,
  UpdateRegistrationMutation,
  UpdateRegistrationMutationVariables,
} from "../../../graphql";

export const useCancelRegistration = () =>
  useMutation<CancelRegistrationMutation, CancelRegistrationMutationVariables>(
    CancelRegistration
  );

export const useQueryRegistrationOnChecks = (id: string) =>
  useQuery<GetRegistrationByIdQuery, GetRegistrationByIdQueryVariables>(
    GetRegistrationById,
    {
      variables: { id },
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
    }
  );

export const useExecuteEligibilityFlowWithCourseId = (
  courseId: string,
  studentId: string
) =>
  useQuery<
    ExecuteEligibilityFlowWithCourseIdQuery,
    ExecuteEligibilityFlowWithCourseIdQueryVariables
  >(ExecuteEligibilityFlowWithCourseId, {
    variables: { courseId, studentId },
    fetchPolicy: "network-only",
  });

export const useExtendsRegistrationTime = (id: string) =>
  useMutation<
    ExtendsRegistrationTimeMutation,
    ExtendsRegistrationTimeMutationVariables
  >(ExtendsRegistrationTime, {
    variables: {
      id,
    },
  });

export const useApproveRegistration = () =>
  useMutation<
    ApproveRegistrationMutation,
    ApproveRegistrationMutationVariables
  >(ApproveRegistration, {
    // refetchQueries: [{ query: GetListRegistrationData }],
  });

// TODO => Change the `Ready` status to `Complete` when regular registration changed `Paid`
export const useProcessRegistration = (
  options?: MutationHookOptions<
    ProcessRegistrationMutation,
    ProcessRegistrationMutationVariables
  >
) =>
  useMutation<
    ProcessRegistrationMutation,
    ProcessRegistrationMutationVariables
  >(ProcessRegistration, options);

export const useLazyClassesInCourse = () =>
  useLazyQuery<GetClassesInCourseQuery, GetClassesInCourseQueryVariables>(
    GetClassesInCourse
  );

export const useQueryClassForReservation = (
  callback?: (data: GetClassForReservationQuery) => void
) =>
  useLazyQuery<
    GetClassForReservationQuery,
    GetClassForReservationQueryVariables
  >(GetClassForReservation, {
    fetchPolicy: "network-only",
    onCompleted(data) {
      callback?.(data);
    },
  });

export const useCreateBillingForRegistration = () =>
  useMutation<
    CreateBillingforRegistrationMutation,
    CreateBillingforRegistrationMutationVariables
  >(CreateBillingforRegistration);

export const useUpdateRegistration = () =>
  useMutation<UpdateRegistrationMutation, UpdateRegistrationMutationVariables>(
    UpdateRegistration
  );

export const useChangeAttendingClassForRegistration = () =>
  useMutation<
    ChangeAttendingClassForRegistrationMutation,
    ChangeAttendingClassForRegistrationMutationVariables
  >(ChangeAttendingClassForRegistration);

export const useCancelChangeAttendingClassForRegistration = () =>
  useMutation<
    CancelChangeAttendingClassForRegistrationMutation,
    CancelChangeAttendingClassForRegistrationMutationVariables
  >(CancelChangeAttendingClassForRegistration);

export const useRegenerateRegistration = () =>
  useMutation<
    RegenerateRegistrationMutation,
    RegenerateRegistrationMutationVariables
  >(RegenerateRegistration);

export const useUpdateEnrollmentPreferredLearningArrangement = () =>
  useMutation<
    UpdateEnrollmentPreferredLearningArrangementMutation,
    UpdateEnrollmentPreferredLearningArrangementMutationVariables
  >(UpdateEnrollmentPreferredLearningArrangement);

export const useOptionClasses = () =>
  useLazyQuery<GetOptionClassesQuery, GetOptionClassesQueryVariables>(
    GetOptionClasses
  );

export const useGetCancelClassById = () =>
  useLazyQuery<GetCancelClassByIdQuery, GetCancelClassByIdQueryVariables>(
    GetCancelClassById,
    {
      fetchPolicy: "network-only",
    }
  );

export const useWaitingListMoreClasses = () =>
  useLazyQuery<
    GetWaitingListMoreClassesQuery,
    GetWaitingListMoreClassesQueryVariables
  >(GetWaitingListMoreClasses, {
    fetchPolicy: "network-only",
  });

export const useCreateWaitingListsForMultipleClasses = () =>
  useMutation<
    CreateWaitingListsForMultipleClassesMutation,
    CreateWaitingListsForMultipleClassesMutationVariables
  >(CreateWaitingListsForMultipleClasses, {
    fetchPolicy: "network-only",
  });
