import { PaymentMode } from "@/graphql";
import { OptionType } from "@/hooks/reference.hook";

interface FormatPaymentModeProps {
  NameOfBankOptions?: OptionType[];
  PaymentProcessorOptions?: OptionType[];
  record: {
    method?: string;
    bankName?: string;
    chequeNumber?: string;
    lastDigits?: string;
    paymentProcessor?: string;
  };
}

export const formatPaymentMode = (
  {
    NameOfBankOptions,
    PaymentProcessorOptions,
    record,
  }: FormatPaymentModeProps,
  defaultReturn: keyof FormatPaymentModeProps["record"] = undefined
): string => {
  const bankNameLabel = NameOfBankOptions?.find(
    (v) => v.value === record?.bankName
  )?.label;
  const paymentProcessorLabel = PaymentProcessorOptions?.find(
    (v) => v.value === record?.paymentProcessor
  )?.label;
  switch (record?.method) {
    case PaymentMode.Cheque:
      return `${bankNameLabel || ""}${
        bankNameLabel && record?.chequeNumber ? "-" : ""
      }${record?.chequeNumber || ""}`;
    case PaymentMode.CreditCard:
      return `${paymentProcessorLabel || ""}${
        paymentProcessorLabel && record?.lastDigits ? "-" : ""
      }${record?.lastDigits || ""}`;
    default:
      return defaultReturn ? record?.[defaultReturn] : undefined;
  }
};

export const PaymentModeOptions = [
  { label: "Credit Card (Stripe)", value: PaymentMode.CreditCardStripe },
  { label: "GrabPay (Stripe)", value: PaymentMode.GrabPayStripe },
  { label: "PayNow (Stripe)", value: PaymentMode.PayNowStripe },
  { label: "PayNow (Bank)", value: PaymentMode.PayNowBank },
  {
    label: "Bank Transfer",
    value: PaymentMode.BankTransfer,
  },
  {
    label: "ATM Transfer",
    value: PaymentMode.AtmTransfer,
  },
  { label: "Cash", value: PaymentMode.Cash },
  { label: "Cheque", value: PaymentMode.Cheque },
  { label: "Credit Card", value: PaymentMode.CreditCard },
  { label: "DBS Max!", value: PaymentMode.DbsMax },
];

export const PaymentModeMaps = new Map<string, PaymentMode>([
  ["Cash", PaymentMode.Cash],
  ["Credit Card", PaymentMode.CreditCard],
  ["Bank Transfer", PaymentMode.BankTransfer],
  ["ATM Transfer", PaymentMode.AtmTransfer],
  ["Pay Now", PaymentMode.PayNowStripe],
  ["DBS MAX!", PaymentMode.DbsMax],
  ["Cheque", PaymentMode.Cheque],
  ["Credit", PaymentMode.Credit],
  ["PayNow (Stripe)", PaymentMode.PayNowStripe],
  ["PayNow (Bank)", PaymentMode.PayNowBank],
  ["Credit Card (Stripe)", PaymentMode.CreditCardStripe],
  ["GrabPay (Stripe)", PaymentMode.GrabPayStripe],
]);
