import { ReactComponent as IconCopy } from "@/assets/images/IconCopyFull.svg";
import {
  KEYWORD_ENUM,
  useFeatureHook,
} from "@/components/settings/Features/utils";
import {
  ValidateHandlebarsCompileType,
  validateHandlebarsTemplateSyntax,
} from "@/components/settings/Template/handleBarUtil";
import {
  useBulkCompileTemplate,
  useCompileTemplate,
  useLazyQueryBulkExportReminderDocx,
  useLazyQueryExportReminderDocx,
} from "@/components/widget/Reminder/hooks";
import {
  BulkSendReminderInput,
  ReminderType,
  SendReminderInput,
} from "@/graphql";
import useCopy from "@/hooks/dom/useCopy";
import { useSalutation } from "@/hooks/reference.hook";
import { download } from "@/utils/download";
import formatErrorMessage from "@/utils/formatErrorMessage";
import { ExportOutlined } from "@ant-design/icons";
import {
  Button,
  Divider,
  Empty,
  Modal,
  Spin,
  message,
} from "@thepiquelab/archus-components-web";
import { isEmpty } from "lodash";
import { FC, ReactElement, useEffect, useMemo, useState } from "react";
import EmailMessage from "./EmailMessage";
import { handleCopyAllReminder, handleExportReminder } from "./Utils";
import WhatsappMessage from "./WhatsappMessage";

type Props = {
  input: SendReminderInput | BulkSendReminderInput;
  isBulk: boolean;
  visible: boolean;
  onCancel?: () => void;
};

const messageKey = "tasks";

const PreviewMessageModal: FC<Props> = (props) => {
  const { visible, input, onCancel, isBulk } = props;
  const { emailInput, whatsappInput } = input || {};

  const isShowWhatsApp = useFeatureHook([KEYWORD_ENUM.global_whatsApp]);
  const isShowEmail = useFeatureHook([KEYWORD_ENUM.global_email]);
  const { copy } = useCopy();
  const { formatSalutation } = useSalutation();
  const [bulkCompileTemplate, { data: bulkData, loading: bulkLoading }] =
    useBulkCompileTemplate();
  const [compileTemplate, { data, loading }] = useCompileTemplate();

  const [ExportReminderDocx, { data: exportData, loading: exportLoading }] =
    useLazyQueryExportReminderDocx({
      fetchPolicy: "network-only",
      onCompleted() {
        download(exportData?.exportReminderDocx, messageKey);
      },
      onError() {
        message.error({
          content: "Export was unsuccessful",
          key: messageKey,
        });
      },
    });

  const [
    bulkExportReminderDocx,
    { data: bulkExportData, loading: bulkExportLoading },
  ] = useLazyQueryBulkExportReminderDocx({
    fetchPolicy: "network-only",
    onCompleted() {
      download(bulkExportData?.bulkExportReminderDocx, messageKey);
    },
    onError() {
      message.error({
        content: "Export was unsuccessful",
        key: messageKey,
      });
    },
  });

  const compiledData = useMemo(() => {
    if (isBulk) {
      return bulkData?.bulkCompileTemplate || [];
    }
    return data?.compileTemplate ? [data?.compileTemplate] : [];
  }, [data, bulkData]);

  const handleExport = async (type: ReminderType): Promise<void> => {
    handleExportReminder({
      type,
      input,
      emailInput,
      whatsappInput,
      isBulk,
      bulkExportReminderDocx,
      ExportReminderDocx,
    });
  };

  const handleCopyAll = (type: ReminderType): void => {
    handleCopyAllReminder({
      type,
      compiledData,
      copy,
      formatSalutation,
      isBulk,
    });
  };

  const handleCompileTemplate = async (key: ReminderType): Promise<void> => {
    if (key === ReminderType.Email && !emailInput?.templateId) {
      return;
    }
    if (key === ReminderType.Whatsapp && !whatsappInput?.templateId) {
      return;
    }

    if (!isEmpty(input)) {
      try {
        await compileTemplate({
          variables: {
            input: {
              ...input,
              types: [key],
              emailInput: key === ReminderType.Email ? emailInput : undefined,
              whatsappInput:
                key === ReminderType.Whatsapp ? whatsappInput : undefined,
            },
          },
        });
      } catch (error) {
        message.error(formatErrorMessage(error));
      }
    }
  };

  const handleBulkCompileTemplate = async (
    key: ReminderType
  ): Promise<void> => {
    if (key === ReminderType.Email && !emailInput?.templateId) {
      return;
    }
    if (key === ReminderType.Whatsapp && !whatsappInput?.templateId) {
      return;
    }

    if (!isEmpty(input)) {
      try {
        await bulkCompileTemplate({
          variables: {
            input: {
              ...input,
              types: [key],
              emailInput: key === ReminderType.Email ? emailInput : undefined,
              whatsappInput:
                key === ReminderType.Whatsapp ? whatsappInput : undefined,
            },
          },
        });
      } catch (error) {
        message.error(formatErrorMessage(error));
      }
    }
  };

  useEffect(() => {
    if (!isShowWhatsApp && !isShowEmail) {
      return;
    }

    // const tabActiveKey = isShowWhatsApp
    //   ? ReminderType.Whatsapp
    //   : ReminderType.Email;

    if (isBulk) {
      handleBulkCompileTemplate(input?.types?.[0]);
    } else {
      handleCompileTemplate(input?.types?.[0]);
    }
  }, [input, isShowWhatsApp, isShowEmail, isBulk]);

  return (
    <Modal
      visible={visible}
      onClose={() => {
        onCancel?.();
      }}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      destroyOnClose
      width={800}
    >
      <Modal.Header title="Message Preview" />
      <Modal.Content className="min-h-56 max">
        <Spin spinning={loading || bulkLoading}>
          {/* <Tabs
            defaultActiveKey={ReminderType.Whatsapp}
            onChange={(key: ReminderType) => {
              handleBulkCompileTemplate(key);
            }}
          > */}
          {input?.types?.[0] === ReminderType.Whatsapp ? (
            // <Tabs.TabPane
            //   tab={ReminderType.Whatsapp}
            //   key={ReminderType.Whatsapp}
            // >
            <div className="">
              {compiledData?.length && whatsappInput?.templateId ? (
                <div>
                  <div className="flex mb-6 gap-2 items-center">
                    {isBulk && (
                      <Button
                        icon={<IconCopy className="mr-2" />}
                        onClick={() => {
                          handleCopyAll(ReminderType.Whatsapp);
                        }}
                      >
                        Copy All Message
                      </Button>
                    )}
                    <Button
                      icon={<ExportOutlined className="mr-2" />}
                      loading={exportLoading || bulkExportLoading}
                      onClick={() => {
                        handleExport(ReminderType.Whatsapp);
                      }}
                    >
                      Export
                    </Button>
                  </div>
                  <div className="max-h-100 overflow-auto">
                    {compiledData?.map((item, index) => (
                      <div key={`whatsapp_${index.toString()}`}>
                        <WhatsappMessage item={item} />
                        {index < compiledData?.length - 1 ? <Divider /> : <></>}
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
            </div>
          ) : (
            // </Tabs.TabPane>
            <></>
          )}
          {input?.types?.[0] === ReminderType.Email ? (
            // <Tabs.TabPane tab={ReminderType.Email} key={ReminderType.Email}>
            <div className="">
              {compiledData?.length && emailInput?.templateId ? (
                <div>
                  <div className="flex mb-6 gap-2 items-center">
                    {isBulk && (
                      <Button
                        icon={<IconCopy className="mr-2" />}
                        onClick={() => {
                          handleCopyAll(ReminderType.Email);
                        }}
                      >
                        Copy All Message
                      </Button>
                    )}
                    <Button
                      icon={<ExportOutlined className="mr-2" />}
                      loading={exportLoading || bulkExportLoading}
                      onClick={() => {
                        handleExport(ReminderType.Email);
                      }}
                    >
                      Export
                    </Button>
                  </div>
                  <div className="max-h-100 overflow-auto">
                    {compiledData?.map((item, index) => (
                      <div key={`email_${index.toString()}`}>
                        <EmailMessage item={item} />
                        {index < compiledData.length - 1 ? <Divider /> : <></>}
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
            </div>
          ) : (
            // </Tabs.TabPane>
            <></>
          )}
          {/* </Tabs> */}
        </Spin>
      </Modal.Content>
    </Modal>
  );
};

export const usePreviewMessageModal = (): {
  previewMessageModal: ReactElement<Omit<Props, "visible" | "onCancel">>;
  setPreviewMessageModalProps: (
    newProps: Omit<Props, "visible" | "onCancel">
  ) => void;
} => {
  const [visible, setVisible] = useState(false);

  const close = (): void => setVisible(false);
  const show = (): void => setVisible(true);

  const [props, setProps] = useState<Omit<Props, "visible" | "onCancel">>({
    input: null,
    isBulk: true,
  });

  const setPreviewMessageModalProps = (
    newProps: Omit<Props, "visible">
  ): void => {
    const type = newProps.input?.types?.[0];

    try {
      validateHandlebarsTemplateSyntax(
        {
          subject:
            type === ReminderType.Email && newProps.input?.emailInput?.subject,
          whatsAppMessage:
            type === ReminderType.Whatsapp &&
            newProps.input?.whatsappInput?.message,
          emailMessage:
            type === ReminderType.Email && newProps.input?.emailInput?.message,
        },
        ValidateHandlebarsCompileType.Preview
      );
    } catch (error) {
      message.error(formatErrorMessage(error));
      return;
    }

    setProps({
      ...newProps,
    });
    show();
  };

  return {
    previewMessageModal: (
      <PreviewMessageModal visible={visible} {...props} onCancel={close} />
    ),
    setPreviewMessageModalProps,
  };
};
export default PreviewMessageModal;
