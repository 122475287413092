import React from "react";
import { ModalBodyProps } from "./types";
import classNames from "classnames";

/**
 * Modal Body component
 * that is rendered in the content section of Modal component
 *
 * @example Body with className and text node
 * ```
 * <Modal.Body className="modal-body">
 *    Content here
 * </Modal>
 * ```
 */
const ModalBody: React.FC<ModalBodyProps> = ({
  className,
  children,
  ...props
}) => {
  const classes = classNames(className, "common-modal__body px-6 py-8");

  return (
    <div className={classes} {...props}>
      {children}
    </div>
  );
};

export default ModalBody;
