import { UserCustomiseColumn } from "@/graphql";
import { useEffect, useReducer } from "react";
import { DefaultUserCustomiseColumns } from "./ColumnSeed";
import {
  useLazyQueryUserCustomiseColumns,
  useResetDefault,
  useUpsertUserCustomiseColumns,
} from "./customise";

/**
 * Enum for Loading state types
 */
export enum LoadType {
  /** Loading state when fetch operation is in progress */
  FETCH = "FETCH",
  /** Loading state when apply operation is in progress */
  SAVE = "SAVE",
  /** Loading state when reset operation is in progress */
  RESET = "RESET",
}

/**
 * Interface for DisplayEditor's loading state
 */
export interface LoadState {
  /** Loading state boolean flag */
  isLoading: boolean;
  /** Loading state type */
  type: LoadType;
}

interface UserCustomiseState {
  loadState: LoadState;
  list: UserCustomiseColumn[];
}

enum UserCustomiseColumnActions {
  /** Action to initialize column data in state */
  FETCH_COLUMNS_ACTION = "FETCH_COLUMNS_ACTION",
  /** Action to update column data in state */
  UPDATE_COLUMNS_ACTION = "UPDATE_COLUMNS_ACTION",
  /** Action when fetching column data */
  FETCH = "FETCH",
  /** Action when resetting column data */
  RESET = "RESET",
  /** Action when saving column data */
  SAVE = "SAVE",
}

function updateListByList(
  oldList: UserCustomiseColumn[],
  newList: UserCustomiseColumn[]
): UserCustomiseColumn[] {
  return oldList.map((l) => {
    const { tableName, columnName } = l;
    const needToUpdatedItem = newList.find(
      (n) => n.tableName === tableName && n.columnName === columnName
    );

    if (needToUpdatedItem) {
      return {
        ...l,
        ...needToUpdatedItem,
      };
    }
    return l;
  });
}

function reducer(
  state: UserCustomiseState,
  action: {
    type: UserCustomiseColumnActions;
    payload?: boolean | UserCustomiseColumn[];
  }
): UserCustomiseState {
  switch (action.type) {
    /** Action to initialize column data in state */
    case UserCustomiseColumnActions.FETCH_COLUMNS_ACTION:
      return {
        ...state,
        loadState: {
          isLoading: false,
          type: null,
        },
        list: action.payload as UserCustomiseColumn[],
      };
    /** Action when fetching column data */
    case UserCustomiseColumnActions.FETCH:
      return {
        ...state,
        loadState: {
          isLoading: true,
          type: LoadType.FETCH,
        },
      };
    /** Action when resetting column data */
    case UserCustomiseColumnActions.RESET:
      return {
        ...state,
        loadState: {
          isLoading: true,
          type: LoadType.RESET,
        },
      };
    /** Action when saving column data */
    case UserCustomiseColumnActions.SAVE:
      return {
        ...state,
        loadState: {
          isLoading: true,
          type: LoadType.SAVE,
        },
      };
    /** Action to update column data in state */
    case UserCustomiseColumnActions.UPDATE_COLUMNS_ACTION:
      return {
        ...state,
        loadState: {
          isLoading: false,
          type: null,
        },
        list: [
          ...updateListByList(
            state.list,
            action.payload as UserCustomiseColumn[]
          ),
        ],
      };

    default:
      return state;
  }
}

const initState: UserCustomiseState = {
  loadState: { isLoading: true, type: LoadType.FETCH },
  list: [],
};

/**
 *
 * @param tableName can be missing, since users can modify all of their tables' column
 * @returns
 */
export default function useUserCustomise(tableName?: string): {
  upsertCustomiseColumns: (items: UserCustomiseColumn[]) => Promise<void>;
  resetCustomiseColumns: () => Promise<void>;
} & UserCustomiseState {
  const [customiseColumns, dispatch] = useReducer(reducer, initState);
  const [queryColumns, { data: columnsData, loading: columnsDataLoading }] =
    useLazyQueryUserCustomiseColumns();

  const [
    upsertUserCustomiseColumns,
    { loading: upsertUserCustomiseColumnsLoading, data: updatedData },
  ] = useUpsertUserCustomiseColumns();

  const [resetDefault, { loading: resetDefaultLoading, data: resettledData }] =
    useResetDefault();

  useEffect(() => {
    if (tableName) {
      queryColumns({
        variables: {
          filterInput: {
            tableName,
          },
        },
      });
    }
  }, [queryColumns, tableName]);

  useEffect(() => {
    if (!columnsDataLoading && columnsData) {
      dispatch({
        type: UserCustomiseColumnActions.FETCH_COLUMNS_ACTION,
        payload: columnsData?.userCustomiseColumns
          ?.items as UserCustomiseColumn[],
      });
    }
  }, [
    columnsData,
    columnsData?.userCustomiseColumns?.items,
    columnsDataLoading,
  ]);

  useEffect(() => {
    if (!upsertUserCustomiseColumnsLoading && updatedData) {
      dispatch({
        type: UserCustomiseColumnActions.UPDATE_COLUMNS_ACTION,
        payload:
          updatedData.upsertUserCustomiseColumns as UserCustomiseColumn[],
      });
    }
  }, [updatedData, upsertUserCustomiseColumnsLoading]);

  useEffect(() => {
    if (!resetDefaultLoading && resettledData) {
      dispatch({
        type: UserCustomiseColumnActions.FETCH_COLUMNS_ACTION,
        payload: resettledData.resetDefault as UserCustomiseColumn[],
      });
    }
  }, [resetDefaultLoading, resettledData]);

  const upsertCustomiseColumns = async (
    items: UserCustomiseColumn[]
  ): Promise<void> => {
    dispatch({
      type: UserCustomiseColumnActions.SAVE,
    });
    await upsertUserCustomiseColumns({
      variables: {
        input: items.map((item) => {
          const { __typename, ...rest } = item;
          return {
            ...rest,
            tableName,
          };
        }),
      },
    });
  };

  const resetCustomiseColumns = async (): Promise<void> => {
    dispatch({
      type: UserCustomiseColumnActions.RESET,
    });
    const columns = DefaultUserCustomiseColumns?.filter(
      (i) => i?.tableName === tableName
    ).map(({ csvColumnName, ...rest }) => rest);

    await resetDefault({
      variables: {
        input: {
          tableName,
          columns: columns?.length ? columns : undefined,
        },
      },
    });
  };

  return {
    ...customiseColumns,
    upsertCustomiseColumns,
    resetCustomiseColumns,
  };
}
