import AuthorizedRoute, {
  AuthorizedRouteProps,
  EnumCheckPermissionType,
} from "@/components/authorize/AuthorizedRoute";
import { EnumPermissions } from "@/components/authorize/Permissions";
import {
  KEYWORD_ENUM,
  useFeatureHook,
} from "@/components/settings/Features/utils";
import { SentryConfig } from "@/config";
import useDeviceEventSource from "@/hooks/useDeviceEventSource";
import useRedirectPath, { useGetPaths } from "@/hooks/useRedirectPath";
import { Layout, Skeleton } from "@thepiquelab/archus-components-web";
import React, { Suspense, lazy, memo, useEffect, useState } from "react";
import { Redirect, Switch, useLocation } from "react-router-dom";
import SideBar from "../../components/navigation/SideBar";
import {
  Dashboard,
  DashboardSendFollowUp,
  DashboardWaitingListOverview,
} from "./Dashboard";
import { RefreshListClassesContext } from "./ImportClassNotification/Context";
import ImportClassNotification from "./ImportClassNotification/ImportClassNotification";
import { RefreshListRegistrationsContext } from "./ImportRegistrationNotification/Context";
import ImportRegistrationNotification from "./ImportRegistrationNotification/ImportRegistrationNotification";

const Customers = lazy(() => import("./Customers"));
const SystemUser = lazy(() => import("./SystemUser"));
const CourseManagement = lazy(() => import("./CourseManagement"));
const ClassArrangement = lazy(() => import("./ClassArrangement"));
const ClassScheduler = lazy(() => import("./ClassScheduler"));
const Finance = lazy(() => import("./Finance"));
const Settings = lazy(() => import("./settings"));
const Reports = lazy(() => import("./Reports"));

const Logistics = lazy(() => import("./Logistics"));
const ParentCentre = lazy(() => import("./ParentCentre"));

const { Content } = Layout;

const AdminIndex: React.FC = () => {
  const location = useLocation();
  const enableDashboard = useFeatureHook([KEYWORD_ENUM.global_dashboard]);

  const { initEventSource } = useDeviceEventSource();

  /** NOTE: Remove CourseViewOwn on ARCD-277 */
  const routes: AuthorizedRouteProps[] = [
    // {
    //   path: "/",
    //   component: DashboardPage,
    //   exact: true,
    //   permissions: [EnumPermissions.DashboardView],
    //   permissionCheckType: EnumCheckPermissionType.ALL,
    //   hide: !useFeatureHook([KEYWORD_ENUM.global_dashboard]),
    // },
    {
      path: "/dashboard",
      component: Dashboard,
      exact: true,
      permissions: [EnumPermissions.TASK_VIEW],
      permissionCheckType: EnumCheckPermissionType.ALL,
      hide: !enableDashboard,
    },
    {
      path: "/dashboard/operational",
      component: Dashboard,
      exact: true,
      permissions: [EnumPermissions.TASK_VIEW],
      permissionCheckType: EnumCheckPermissionType.ALL,
      hide: !enableDashboard,
    },
    {
      path: "/dashboard/send-followup",
      component: DashboardSendFollowUp,
      exact: true,
      permissions: [EnumPermissions.TASK_VIEW],
      permissionCheckType: EnumCheckPermissionType.ALL,
      hide: !enableDashboard,
    },
    {
      path: "/dashboard/waiting-list-overview",
      component: DashboardWaitingListOverview,
      exact: true,
      permissions: [EnumPermissions.TASK_VIEW],
      permissionCheckType: EnumCheckPermissionType.ALL,
      hide: !enableDashboard,
    },
    {
      path: "/class-schedule",
      component: ClassScheduler,
      exact: true,
      permissions: [EnumPermissions.CLASS_VIEW],
      permissionCheckType: EnumCheckPermissionType.SOME,
      disableRedirectTo: true,
    },
    {
      path: "/course-management",
      component: CourseManagement,
      permissions: [
        EnumPermissions.COURSE_VIEW,
        EnumPermissions.CLASS_VIEW,
        EnumPermissions.SCHOOL_VIEW,
      ],
      permissionCheckType: EnumCheckPermissionType.SOME,
      disableRedirectTo: true,
    },
    {
      path: "/class-arrangement",
      component: ClassArrangement,
      permissions: [
        EnumPermissions.REGISTRATION_VIEW,
        EnumPermissions.ARRANGEMENT_VIEW,
      ],
      permissionCheckType: EnumCheckPermissionType.SOME,
      disableRedirectTo: true,
    },
    {
      path: "/finance",
      component: Finance,
      permissions: [
        EnumPermissions.INVOICE_VIEW,
        EnumPermissions.RECEIPT_VIEW,
        EnumPermissions.REFUND_VIEW,
        EnumPermissions.STATEMENT_OF_ACCOUNT_VIEW,
      ],
      permissionCheckType: EnumCheckPermissionType.SOME,
    },
    {
      path: "/customers",
      component: Customers,
      permissions: [EnumPermissions.PARENT_VIEW, EnumPermissions.STUDENT_VIEW],
      permissionCheckType: EnumCheckPermissionType.SOME,
    },
    {
      path: "/system-user",
      component: SystemUser,
      permissions: [EnumPermissions.SYSTEM_USER_VIEW],
      permissionCheckType: EnumCheckPermissionType.SOME,
    },
    {
      path: "/settings",
      component: Settings,
      permissions: [
        EnumPermissions.DEPARTMENT_VIEW,
        EnumPermissions.BRANCH_VIEW,
        EnumPermissions.VENUE_VIEW,
        EnumPermissions.ACADEMIC_LEVEL_VIEW,
        EnumPermissions.ACADEMIC_YEAR_VIEW,
        EnumPermissions.SCHOOL_VIEW,
        EnumPermissions.GROUP_ASSIGNMENT_VIEW,
        EnumPermissions.TERM_BILLING_DUE_DATE_VIEW,
        EnumPermissions.HOLIDAY_VIEW,
        EnumPermissions.REFERENCE_DATA_VIEW,
        EnumPermissions.WHATSAPP_ACCOUNT_VIEW,
        EnumPermissions.PARENT_VIEW,
        EnumPermissions.STUDENT_VIEW,
        EnumPermissions.REWARD_VIEW,
        EnumPermissions.INTEGRATION_VIEW,
        EnumPermissions.FEATURE_VIEW,
      ],
      permissionCheckType: EnumCheckPermissionType.SOME,
    },
    {
      path: "/reports",
      component: Reports,
    },
    {
      path: "/logistics",
      component: Logistics,
      permissionCheckType: EnumCheckPermissionType.SOME,
      permissions: [
        EnumPermissions.MATERIAL_VIEW,
        EnumPermissions.DELIVERY_VIEW,
      ],
    },
    {
      path: "/parent-centre",
      component: ParentCentre,
      permissionCheckType: EnumCheckPermissionType.ALL,
      permissions: [EnumPermissions.USER_REQUEST_VIEW],
    },
  ];

  const [isRefresh, setIsRefresh] = useState(false);
  const [isImportClassRefresh, setIsImportClassRefresh] = useState(false);

  const redirectPath = useRedirectPath(routes);
  const filteredRoutes = useGetPaths(routes);

  useEffect(() => {
    if (SentryConfig.Environment !== "staging") {
      initEventSource();
    }
  }, []);

  return (
    <Layout className="layout min-w-xl">
      <SideBar />
      <Layout>
        <RefreshListRegistrationsContext.Provider
          value={{ isRefresh, setIsRefresh }}
        >
          <RefreshListClassesContext.Provider
            value={{
              isRefresh: isImportClassRefresh,
              setIsRefresh: setIsImportClassRefresh,
            }}
          >
            <ImportRegistrationNotification />
            <ImportClassNotification />

            <Content className="bg-gray-100 h-full p-6 pb-0">
              <Suspense fallback={<Skeleton />}>
                <Switch>
                  {filteredRoutes.map((route) => (
                    <AuthorizedRoute {...route} key={`${route.path}`} />
                  ))}
                  <Redirect
                    exact
                    to={redirectPath}
                    from={`${location.pathname}`}
                  />
                </Switch>
              </Suspense>
            </Content>
          </RefreshListClassesContext.Provider>
        </RefreshListRegistrationsContext.Provider>
      </Layout>
    </Layout>
  );
};

export default memo(AdminIndex);
