import React from "react";

const GuideLineStep: React.FC = () => (
  <div>
    <p>
      For a successful registration import, adhere to the following guidelines
      to ensure that your dataset aligns with the CRM’s Registration standards:
    </p>{" "}
    <p>
      Format Consistency: Your CSV file should mirror the format of the sample
      provided. Download the template by clicking the Download a CSV Template
      next to the upload field.
    </p>{" "}
    <p>
      Data Accuracy: Verify that all details, such as student names, IDs, and
      class information, are correct to avoid import errors. Do not leave
      mandatory fields empty. Essential details like Student Name, Class Name
      are necessary for a valid import. Import Limit: A maximum of 500
      registrations can be imported at once. Ensure your file does not exceed
      this limit to avoid processing issues.
    </p>{" "}
    <p>
      Single Batch Processing: Only one import can be processed at a time. The
      system will show a progress modal to all users, indicating the import
      status. If there are registrations that fail to import, a downloadable CSV
      of failed records will be available in the progress modal.
    </p>{" "}
    <p className="mb-0">
      By adhering to these detailed steps, you're set for a streamlined and
      effective import process into the CRM.
    </p>{" "}
  </div>
);
export default GuideLineStep;
