import {
  BillingFrequency,
  Class,
  CourseFeeType,
  DiscountType,
  InvoiceLineItem,
  InvoiceLineItemLesson,
} from "@/graphql";
import { formatCurrency } from "@thepiquelab/archus-library";
import BigNumber from "bignumber.js";

export class InvoiceLineItemDO {
  public discountAmount: string;

  public discountRate: string;

  public discountType: DiscountType;

  public lineAmount: string;

  public class: Class;

  public lessons: InvoiceLineItemLesson[];

  constructor(invoiceLineItem: InvoiceLineItem) {
    this.class = invoiceLineItem.class;
    this.lessons = invoiceLineItem.lessons;
    this.discountAmount = invoiceLineItem.discountAmount;
    this.discountRate = invoiceLineItem.discountRate;
    this.discountType = invoiceLineItem.discountType;
    this.lineAmount = invoiceLineItem.lineAmount;
  }

  public isCourseFee?(): boolean {
    return !!this.lessons?.length;
  }

  public isOtherFee?(): boolean {
    return !this.lessons?.length;
  }

  public getCourseFeeType(): CourseFeeType {
    return (
      this?.class?.course?.courseFee?.standardCourseFees?.[0]?.type || null
    );
  }

  public getDiscountedPrice(): string {
    if (this.discountAmount && this.discountType === DiscountType.Amount) {
      return `- ${formatCurrency(this.discountAmount)}`;
    }

    if (this.discountRate && this.discountType === DiscountType.Percentage) {
      return `- ${formatCurrency(
        new BigNumber(this.lineAmount)
          .multipliedBy(new BigNumber(this.discountRate))
          .div(100)
          .toNumber()
      )}`;
    }

    return "";
  }

  public getBillingFrequency(isOtherFee?: boolean): string {
    if (isOtherFee) return "";

    if (this.class?.isAdHoc) return "";

    const courseFeeType = this.getCourseFeeType();

    const billingFrequency = this.class?.course?.courseFee?.billingFrequency;

    switch (courseFeeType) {
      case null:
        return "";
      case CourseFeeType.Perclass:
        return "/Class";
      case CourseFeeType.Permonth:
        if (billingFrequency === BillingFrequency.Perterm) return "/Term";
        if (billingFrequency === BillingFrequency.Perclass) return "/Class";
        return "/Month";
      case CourseFeeType.Perterm:
        if (billingFrequency === BillingFrequency.Perclass) return "/Class";
        return "/Term";
      case CourseFeeType.Perlesson:
        return "/Lesson";
      default:
        return "";
    }
  }
}
