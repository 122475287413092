import gql from 'graphql-tag';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  /** Mongo object id scalar type */
  ObjectId: string,
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any,
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: string,
  /** The `Decimal` scalar type to represent currency values */
  Decimal: string,
  /** A time string at UTC, such as 10:15:30Z, compliant with the `full-time` format outlined in section 5.6 of the RFC 3339profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Time: any,
  /** The `Upload` scalar type represents a file upload. */
  Upload: any,
  /** Json object scalar type */
  Object: any,
};


export type AcademicLevel = {
   __typename?: 'AcademicLevel',
  _id: Scalars['ObjectId'],
  alias: Scalars['String'],
  classes?: Maybe<Array<Class>>,
  courses?: Maybe<Array<Course>>,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  educationStage: EducationStage,
  isArchived: Scalars['Boolean'],
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  isVisible: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  name: Scalars['String'],
  orderNo?: Maybe<Scalars['Float']>,
  seatsAnalyticsByAcademicLevel?: Maybe<SeatAnalytics>,
  systemId?: Maybe<Scalars['String']>,
};


export type AcademicLevelClassesArgs = {
  filterInput?: Maybe<ClassFilterInput>
};


export type AcademicLevelCoursesArgs = {
  statuses?: Maybe<Array<CourseStatus>>,
  types?: Maybe<Array<CourseType>>
};


export type AcademicLevelSeatsAnalyticsByAcademicLevelArgs = {
  filterInput?: Maybe<ClassFilterInput>
};

export type AcademicWeek = {
   __typename?: 'AcademicWeek',
  endDate: Scalars['DateTime'],
  startDate: Scalars['DateTime'],
  /** The week number in an academic year */
  weekNumber: Scalars['Int'],
};

export type AcademicWeekData = {
   __typename?: 'AcademicWeekData',
  selectionData?: Maybe<Array<SelectionData>>,
};

export type AcademicWeekInput = {
  weekNumber: Scalars['Int'],
};

/** A single instance of an academic week within an academic year. */
export type AcademicWeekWithId = {
   __typename?: 'AcademicWeekWithId',
  /** Unique identifier of the record. */
  _id: Scalars['ObjectId'],
  /** The end date of the academic week. */
  endDate: Scalars['DateTime'],
  /** The start date of the academic week. */
  startDate: Scalars['DateTime'],
  /** The week number within the academic year. */
  weekNumber: Scalars['Float'],
};

/** A calendar year pertaining to the academic year. */
export type AcademicYear = {
   __typename?: 'AcademicYear',
  /** Unique identifier of the academic year record. */
  _id: Scalars['ObjectId'],
  /** The day the week end date falls on. e.g. Monday. */
  academicWeekEnd: WeekDay,
  /** The day the week start date falls on. e.g. Monday. */
  academicWeekStart: WeekDay,
  /** Academic weeks within the academic year. */
  academicWeeks: Array<AcademicWeekWithId>,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  groupAssignment?: Maybe<GroupAssignment>,
  holidays?: Maybe<Array<Holiday>>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  /** The start and end date of the academic year. */
  period: DateTimeRange,
  taxRate: Scalars['Float'],
  term?: Maybe<Term>,
  /** Term billing due dates within the academic year. */
  termBillingDueDate?: Maybe<TermBillingDueDate>,
  terms?: Maybe<Array<Term>>,
  /** The calendar year that the academic year is for. There could only be one record per year. */
  year: Scalars['Int'],
};


/** A calendar year pertaining to the academic year. */
export type AcademicYearTermArgs = {
  academicLevelId: Scalars['ObjectId']
};

export type AccessDevice = {
   __typename?: 'AccessDevice',
  id?: Maybe<Scalars['String']>,
  ip?: Maybe<Scalars['String']>,
  location?: Maybe<Scalars['String']>,
};

export type AccountInput = {
  code: Scalars['String'],
  name: Scalars['String'],
};

export enum AccountType {
  Bank = 'BANK',
  Current = 'CURRENT',
  Currliab = 'CURRLIAB',
  Depreciatn = 'DEPRECIATN',
  Directcosts = 'DIRECTCOSTS',
  Equity = 'EQUITY',
  Expense = 'EXPENSE',
  Fixed = 'FIXED',
  Inventory = 'INVENTORY',
  Liability = 'LIABILITY',
  Noncurrent = 'NONCURRENT',
  Otherincome = 'OTHERINCOME',
  Overheads = 'OVERHEADS',
  Payg = 'PAYG',
  Paygliability = 'PAYGLIABILITY',
  Prepayment = 'PREPAYMENT',
  Revenue = 'REVENUE',
  Sales = 'SALES',
  Superannuationexpense = 'SUPERANNUATIONEXPENSE',
  Superannuationliability = 'SUPERANNUATIONLIABILITY',
  Termliab = 'TERMLIAB',
  Wagesexpense = 'WAGESEXPENSE'
}

export type Activities = {
  isExercise?: Maybe<Scalars['Boolean']>,
  isHomework?: Maybe<Scalars['Boolean']>,
};

/** Activity Log. */
export type ActivityLog = {
   __typename?: 'ActivityLog',
  _id: Scalars['ObjectId'],
  addIds?: Maybe<Array<Scalars['ObjectId']>>,
  adds?: Maybe<Array<ActivityLogEntity>>,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  keywords?: Maybe<Array<Scalars['String']>>,
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  previousAndUpdateValue?: Maybe<ActivityLogValue>,
  removeIds?: Maybe<Array<Scalars['ObjectId']>>,
  removes?: Maybe<Array<ActivityLogEntity>>,
  sourceId: Scalars['ObjectId'],
  subType: ActivityLogSubType,
  type: ActivityLogType,
};

export type ActivityLogEntity = SystemUser;

export type ActivityLogListQuery = {
  sourceIds: Array<Scalars['ObjectId']>,
};

export type ActivityLogPage = {
   __typename?: 'ActivityLogPage',
  items: Array<ActivityLog>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

export enum ActivityLogSubType {
  Created = 'Created',
  TaskAddedChecklist = 'TaskAddedChecklist',
  TaskAddedItem = 'TaskAddedItem',
  TaskAssigned = 'TaskAssigned',
  TaskAssignedChecklist = 'TaskAssignedChecklist',
  TaskAssignedItem = 'TaskAssignedItem',
  TaskCompleted = 'TaskCompleted',
  TaskCompletedItem = 'TaskCompletedItem',
  TaskDeletedChecklist = 'TaskDeletedChecklist',
  TaskDeletedItem = 'TaskDeletedItem',
  TaskReopen = 'TaskReopen',
  TaskUnassigned = 'TaskUnassigned',
  TaskUnassignedChecklist = 'TaskUnassignedChecklist',
  TaskUnassignedItem = 'TaskUnassignedItem',
  TaskUncompletedItem = 'TaskUncompletedItem',
  TaskUpdatedDueDate = 'TaskUpdatedDueDate',
  TaskVoided = 'TaskVoided'
}

export enum ActivityLogType {
  Task = 'Task'
}

export type ActivityLogValue = TaskActivityLogDateValue;

export type AddInvoiceLineItemCommand = {
  invoiceId: Scalars['ObjectId'],
  lineItem: AddLineItemCommand,
};

/** 
 * Inputs for the Add LessonFlow Schedule mutation.
 *   Add either a worksheet, test, break, and other to the schedule. Passing 2 or
 *   more item types at the same time will be invalid.
 */
export type AddLessonFlowScheduleInput = {
  /** Required for updating existing lesson plan item of type BREAK. */
  break?: Maybe<LessonFlowBreakItemInput>,
  /** Id of the lesson flow under the lesson plan you want the schedule to be added to. */
  lessonFlowId: Scalars['ObjectId'],
  /** Id of the lesson plan you want the schedule to be added to. */
  lessonPlanId: Scalars['ObjectId'],
  /** Required for updating existing lesson plan item of type OTHER. */
  other?: Maybe<LessonFlowOtherItemInput>,
  /** Required for updating existing lesson plan item of type TEST. */
  test?: Maybe<LessonFlowTestItemInput>,
  /** Required for updating existing lesson plan item of type WORKSHEET. */
  worksheet?: Maybe<LessonFlowWorkSheetItemInput>,
};

/** Indicates the effect of the add lesson flow schedule operation. */
export type AddLessonFlowScheduleResponse = {
   __typename?: 'AddLessonFlowScheduleResponse',
  /** The updated lesson plan - added schedule to a lesson flow. */
  updatedLessonPlan: LessonPlan,
  /** The number of lessons updated with the schedule of the lesson flow. */
  updatedLessonsCount: Scalars['Int'],
};

/** Adds a lesson plan to a course. */
export type AddLessonPlanInput = {
  /** The year to assign the Lesson Plan. */
  academicYearId: Scalars['ObjectId'],
  /** The course to assign the Lesson Plan. */
  courseId: Scalars['ObjectId'],
  /** The name to assign to the Lesson Plan. */
  name: Scalars['String'],
};

export type AddLineItemCommand = {
  description: Scalars['String'],
  quantity: Scalars['Int'],
  unitAmount: Scalars['Decimal'],
};

export type Address = {
   __typename?: 'Address',
  country?: Maybe<Scalars['String']>,
  line1: Scalars['String'],
  link?: Maybe<Scalars['String']>,
  postalCode: Scalars['String'],
  unit?: Maybe<Scalars['String']>,
};

export type AddressInput = {
  country?: Maybe<Scalars['String']>,
  line1: Scalars['String'],
  link?: Maybe<Scalars['String']>,
  postalCode: Scalars['String'],
  unit?: Maybe<Scalars['String']>,
};

export type AddRewardCatalogueInput = {
  avatar: Scalars['String'],
  category: Scalars['String'],
  description: Scalars['String'],
  galleries?: Maybe<Array<Scalars['String']>>,
  isVisible: Scalars['Boolean'],
  name: Scalars['String'],
  points: Scalars['Int'],
  stock: Scalars['Int'],
};

/** Reward points input to add for one student item */
export type AddRewardPointsInput = {
  /** Linked gradable (Exercise or Homework) ID if points are from award */
  gradableId?: Maybe<Scalars['ObjectId']>,
  /** Amount of reward points */
  points: Scalars['Float'],
  /** Other notes associated with the reward transaction */
  remarks?: Maybe<Scalars['String']>,
  /** Student ID on where reward points will be added */
  studentId: Scalars['ObjectId'],
  /** Type of reward history */
  type?: Maybe<RewardHistoryType>,
};

export type AddXeroAccountInput = {
  code: Scalars['String'],
  name: Scalars['String'],
  type: AccountType,
};

export type AdjustBalanceInput = {
  amount: Scalars['Decimal'],
  description: Scalars['String'],
  parentId: Scalars['ObjectId'],
};

export type AlternativeLessonsForChangeAttendingClassInput = {
  lessonId: Scalars['ObjectId'],
  studentId: Scalars['ObjectId'],
};

export type AmountAudit = {
   __typename?: 'AmountAudit',
  onHold: Scalars['Decimal'],
  overdue: Scalars['Decimal'],
  paid: Scalars['Decimal'],
  pending: Scalars['Decimal'],
  unpaid: Scalars['Decimal'],
};

export type Applicable = {
  isApplicable: Scalars['Boolean'],
};

export type ApplyInvoiceDiscountCommand = {
  discountId: Scalars['ObjectId'],
  lineItemId: Scalars['ObjectId'],
  remarks?: Maybe<Scalars['String']>,
};

/** Contains the lesson plan id and the class id where the lesson plan should be applied. */
export type ApplyLessonPlanToClassInput = {
  /** The class to apply the lesson plan. */
  classId: Scalars['ObjectId'],
  /** The lesson plan containing the lesson flows to apply to a class. */
  lessonPlanId: Scalars['ObjectId'],
};

/** Indicates the effect of the apply lesson plan to class operation. */
export type ApplyLessonPlanToClassResponse = {
   __typename?: 'ApplyLessonPlanToClassResponse',
  /** The number of lessons the lesson flow was applied to. */
  lessonsAppliedWithFlowsCount: Scalars['Int'],
  /** The updated class that the lesson plan was applied to. */
  updatedClass: Class,
  /** The updated lesson plan that was applied to the class. */
  updatedLessonPlan: LessonPlan,
};

export type ApproveRegistrationInput = {
  dueDate?: Maybe<Scalars['DateTime']>,
  group?: Maybe<EnrollmentGroupType>,
  id: Scalars['ObjectId'],
  isChangeToWaitingList?: Maybe<Scalars['Boolean']>,
  lessonModes?: Maybe<Array<RegistrationLessonModeCommand>>,
  preferredLearningArrangement?: Maybe<LearningArrangementType>,
  remarks?: Maybe<Scalars['String']>,
  selectedLessons?: Maybe<Array<SelectedLessonInput>>,
  startDate?: Maybe<Scalars['DateTime']>,
};

export type Archivable = {
  isArchived: Scalars['Boolean'],
};

/** Input for archiving the worksheet. */
export type ArchiveWorkSheetsInput = {
  /** The ids of the worksheets to be archived. */
  worksheetIds: Array<Scalars['ObjectId']>,
};

export type Arrangement = {
   __typename?: 'Arrangement',
  _id: Scalars['ObjectId'],
  arrangementBillingCheck?: Maybe<ArrangementBillingCheckResult>,
  cancelInfo?: Maybe<TransferCancelInfo>,
  cancellation?: Maybe<Array<Arrangement>>,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  currentStep?: Maybe<Scalars['Int']>,
  currentTermNumber?: Maybe<Scalars['Int']>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  emailStatus?: Maybe<ReminderStatus>,
  enrollment?: Maybe<Enrollment>,
  enrollmentId?: Maybe<Scalars['ObjectId']>,
  fromClass?: Maybe<Class>,
  fromLesson?: Maybe<Lesson>,
  incompleteDeadline: Scalars['DateTime'],
  invoice?: Maybe<Invoice>,
  isAdhocAdditionalLesson?: Maybe<Scalars['Boolean']>,
  isCancelHasFullLesson?: Maybe<Scalars['Boolean']>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  name?: Maybe<Scalars['String']>,
  newEnrollment?: Maybe<Enrollment>,
  newEnrollmentId?: Maybe<Scalars['ObjectId']>,
  originInvoice?: Maybe<Invoice>,
  remarks?: Maybe<Scalars['String']>,
  reminders?: Maybe<Array<Reminder>>,
  selectedLessons?: Maybe<Array<SelectedLesson>>,
  session?: Maybe<Session>,
  status?: Maybe<ArrangementStatus>,
  student: Student,
  toClass?: Maybe<Class>,
  toLesson?: Maybe<Lesson>,
  type: ArrangementType,
  useCredits?: Maybe<Scalars['Boolean']>,
  waStatus?: Maybe<ReminderStatus>,
  willAttendLessons?: Maybe<Array<Lesson>>,
};

export type ArrangementAcademicYearIndex = {
   __typename?: 'ArrangementAcademicYearIndex',
  _id: Scalars['ObjectId'],
  year: Scalars['Int'],
};

export type ArrangementBillingCheckResult = {
   __typename?: 'ArrangementBillingCheckResult',
  amountUsed: Scalars['Decimal'],
  feeDifference?: Maybe<Scalars['Decimal']>,
  lessonsNeedNotToPay: Array<Lesson>,
  lessonsNeedToPay: Array<Lesson>,
  remainingFee: Scalars['Decimal'],
  updatedLessonFee?: Maybe<Scalars['Decimal']>,
};

export type ArrangementClassIndex = {
   __typename?: 'ArrangementClassIndex',
  _id: Scalars['ObjectId'],
  name: Scalars['String'],
};

export type ArrangementCourseIndex = {
   __typename?: 'ArrangementCourseIndex',
  _id: Scalars['ObjectId'],
  nameShort: Scalars['String'],
};

export type ArrangementFilterInput = {
  academicYearIds?: Maybe<Array<Scalars['ObjectId']>>,
  courseIds?: Maybe<Array<Scalars['ObjectId']>>,
  createdBy?: Maybe<Scalars['ObjectId']>,
  dateRange?: Maybe<DateTimeRangeInput>,
  fromClassIds?: Maybe<Array<Scalars['ObjectId']>>,
  fromLessonIds?: Maybe<Array<Scalars['ObjectId']>>,
  name?: Maybe<Scalars['String']>,
  paymentStatuses?: Maybe<Array<InvoiceStatus>>,
  status?: Maybe<Array<ArrangementStatus>>,
  studentIds?: Maybe<Array<Scalars['ObjectId']>>,
  toClassIds?: Maybe<Array<Scalars['ObjectId']>>,
  toLessonIds?: Maybe<Array<Scalars['ObjectId']>>,
  types?: Maybe<Array<ArrangementType>>,
};

export type ArrangementHolidayIndex = {
   __typename?: 'ArrangementHolidayIndex',
  _id: Scalars['ObjectId'],
  name: Scalars['String'],
};

export type ArrangementIndex = {
   __typename?: 'ArrangementIndex',
  _id: Scalars['ObjectId'],
  academicYear?: Maybe<ArrangementAcademicYearIndex>,
  course?: Maybe<ArrangementCourseIndex>,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  currentStep?: Maybe<Scalars['Int']>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  effectedHolidays?: Maybe<Array<ArrangementHolidayIndex>>,
  emailStatus?: Maybe<ReminderStatus>,
  enrollmentId?: Maybe<Scalars['ObjectId']>,
  fromClass?: Maybe<ArrangementClassIndex>,
  fromClassTeacher?: Maybe<ArrangementTeacherIndex>,
  fromLesson?: Maybe<ArrangementLessonIndex>,
  invoice?: Maybe<ArrangementInvoiceIndex>,
  isAdhocAdditionalLesson?: Maybe<Scalars['Boolean']>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  lastModifiedByFullName?: Maybe<Scalars['String']>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  name?: Maybe<Scalars['String']>,
  newEnrollmentId?: Maybe<Scalars['ObjectId']>,
  remarks?: Maybe<Scalars['String']>,
  status?: Maybe<ArrangementStatus>,
  student?: Maybe<ArrangementStudentIndex>,
  toClass?: Maybe<ArrangementClassIndex>,
  toClassTeacher?: Maybe<ArrangementTeacherIndex>,
  toLesson?: Maybe<ArrangementLessonIndex>,
  type: ArrangementType,
  useCredits?: Maybe<Scalars['Boolean']>,
  waStatus?: Maybe<ReminderStatus>,
};

export type ArrangementIndexPage = {
   __typename?: 'ArrangementIndexPage',
  items: Array<ArrangementIndex>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

export type ArrangementInvoiceIndex = {
   __typename?: 'ArrangementInvoiceIndex',
  _id: Scalars['ObjectId'],
  status: InvoiceStatus,
};

export type ArrangementLessonIndex = {
   __typename?: 'ArrangementLessonIndex',
  _id: Scalars['ObjectId'],
  lessonEnd: Scalars['DateTime'],
  lessonStart: Scalars['DateTime'],
};

export type ArrangementPage = {
   __typename?: 'ArrangementPage',
  items: Array<Arrangement>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

export type ArrangementSession = {
   __typename?: 'ArrangementSession',
  arrangementId: Scalars['ObjectId'],
};

export enum ArrangementStatus {
  Cancelled = 'Cancelled',
  Complete = 'Complete',
  Draft = 'Draft'
}

export type ArrangementStudentIndex = {
   __typename?: 'ArrangementStudentIndex',
  _id: Scalars['ObjectId'],
  avatarUrl?: Maybe<Scalars['String']>,
  fullName: Scalars['String'],
  gender?: Maybe<Scalars['String']>,
  userId?: Maybe<Scalars['String']>,
};

export type ArrangementTeacherIndex = {
   __typename?: 'ArrangementTeacherIndex',
  _id: Scalars['ObjectId'],
  avatarUrl?: Maybe<Scalars['String']>,
  fullName: Scalars['String'],
  gender?: Maybe<Scalars['String']>,
  salutation?: Maybe<Scalars['String']>,
};

export enum ArrangementType {
  Additional = 'Additional',
  Makeup = 'Makeup',
  Transfer = 'Transfer',
  Withdrawals = 'Withdrawals'
}

export type Attendance = {
   __typename?: 'Attendance',
  _id: Scalars['ObjectId'],
  class: Class,
  classId: Scalars['ObjectId'],
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  endDateTime: Scalars['DateTime'],
  enrollment?: Maybe<Enrollment>,
  group?: Maybe<EnrollmentGroupType>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  learningArrangement?: Maybe<LearningArrangementType>,
  lesson: Lesson,
  lessonId: Scalars['ObjectId'],
  lessonMode?: Maybe<LearningArrangementType>,
  linkArrangement?: Maybe<Arrangement>,
  linkInvoice?: Maybe<Invoice>,
  linkedAttendance?: Maybe<Attendance>,
  /** 
 * In the CRM, when the 'Manual Delivery Option' is selected for 
   *     this attendance. This will have a value containing the student's attendance.
 */
  materialsForHandOver?: Maybe<Material>,
  previousLessonAttendance?: Maybe<Attendance>,
  /** The remarks associated with this attendance are 'Lesson Remarks'. */
  remarks?: Maybe<Array<Remark>>,
  startDateTime: Scalars['DateTime'],
  status?: Maybe<AttendanceStatus>,
  statusTag?: Maybe<AttendanceStatusTag>,
  student?: Maybe<Student>,
  type: AttendanceType,
};


export type AttendanceRemarksArgs = {
  includesStudentRemarks?: Maybe<Scalars['Boolean']>,
  showArchived?: Maybe<Scalars['Boolean']>
};

export type AttendanceAcademicLevelIndex = {
   __typename?: 'AttendanceAcademicLevelIndex',
  _id: Scalars['ObjectId'],
  name: Scalars['String'],
};

export type AttendanceArrangementIndex = {
   __typename?: 'AttendanceArrangementIndex',
  _id: Scalars['ObjectId'],
  fromClassId?: Maybe<Scalars['ObjectId']>,
  fromClassShorthand?: Maybe<Scalars['String']>,
  status?: Maybe<ArrangementStatus>,
  toClassId?: Maybe<Scalars['ObjectId']>,
  toClassShorthand?: Maybe<Scalars['String']>,
  type: ArrangementType,
};

export type AttendanceClassIndex = {
   __typename?: 'AttendanceClassIndex',
  _id: Scalars['ObjectId'],
  academicYearId: Scalars['ObjectId'],
  courseId: Scalars['ObjectId'],
  learningArrangement: LearningArrangementType,
  shorthand?: Maybe<Scalars['String']>,
  type?: Maybe<ClassType>,
};

export type AttendanceEnrollmentIndex = {
   __typename?: 'AttendanceEnrollmentIndex',
  _id: Scalars['ObjectId'],
  followUpStatus?: Maybe<FollowUpStatus>,
  followUpStatusLastModifiedByFullName?: Maybe<Scalars['String']>,
  followUpStatusLastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  group?: Maybe<EnrollmentGroupType>,
  preferredLearningArrangement?: Maybe<LearningArrangementType>,
  status: EnrollmentStatus,
  /** Enrollment suitability information that tells if student is suitable for registration */
  suitability?: Maybe<EnrollmentSuitability>,
  type: EnrollmentType,
};

export type AttendanceIndex = {
   __typename?: 'AttendanceIndex',
  _id: Scalars['ObjectId'],
  academicLevels?: Maybe<Array<AttendanceAcademicLevelIndex>>,
  class?: Maybe<AttendanceClassIndex>,
  classId: Scalars['ObjectId'],
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  endDateTime: Scalars['DateTime'],
  enrollment?: Maybe<AttendanceEnrollmentIndex>,
  flagRemark?: Maybe<Scalars['String']>,
  group?: Maybe<EnrollmentGroupType>,
  invoice?: Maybe<AttendanceInvoiceIndex>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  latestLessonRemark?: Maybe<Scalars['String']>,
  learningArrangement?: Maybe<LearningArrangementType>,
  lesson?: Maybe<AttendanceLessonIndex>,
  lessonId: Scalars['ObjectId'],
  lessonMode?: Maybe<LearningArrangementType>,
  linkArrangements?: Maybe<Array<AttendanceArrangementIndex>>,
  pinnedRemark?: Maybe<Scalars['String']>,
  previousAttendance?: Maybe<PreviousAttendanceIndex>,
  primaryParent?: Maybe<AttendanceParentIndex>,
  primaryParentWhatsappAccount?: Maybe<AttendanceWhatsappAccountIndex>,
  secondaryParentWhatsappAccounts?: Maybe<Array<AttendanceWhatsappAccountIndex>>,
  secondaryParents?: Maybe<Array<AttendanceParentIndex>>,
  startDateTime: Scalars['DateTime'],
  status?: Maybe<AttendanceStatus>,
  statusTag?: Maybe<AttendanceStatusTag>,
  student?: Maybe<AttendanceStudentIndex>,
  teacher?: Maybe<AttendanceTeacherIndex>,
  trialAttendances?: Maybe<Array<AttendanceTrialIndex>>,
  type: AttendanceType,
};

export type AttendanceIndexPage = {
   __typename?: 'AttendanceIndexPage',
  items: Array<AttendanceIndex>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

export type AttendanceInvoiceIndex = {
   __typename?: 'AttendanceInvoiceIndex',
  _id: Scalars['ObjectId'],
  dueDate?: Maybe<Scalars['DateTime']>,
  status: InvoiceStatus,
};

export type AttendanceLessonIndex = {
   __typename?: 'AttendanceLessonIndex',
  _id: Scalars['ObjectId'],
  lessonEnd: Scalars['DateTime'],
  lessonNumber: Scalars['Float'],
  lessonStart: Scalars['DateTime'],
};

export type AttendancePage = {
   __typename?: 'AttendancePage',
  items: Array<Attendance>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

export type AttendanceParentIndex = {
   __typename?: 'AttendanceParentIndex',
  _id: Scalars['ObjectId'],
  avatarUrl?: Maybe<Scalars['String']>,
  fullName: Scalars['String'],
  gender?: Maybe<Scalars['String']>,
  mobile: Telephone,
  salutation?: Maybe<Scalars['String']>,
};

export type AttendanceSnapshot = {
   __typename?: 'AttendanceSnapshot',
  _id: Scalars['ObjectId'],
  class: Class,
  classId: Scalars['ObjectId'],
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  endDateTime: Scalars['DateTime'],
  group?: Maybe<EnrollmentGroupType>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  lesson: Lesson,
  lessonId: Scalars['ObjectId'],
  lessonMode?: Maybe<LearningArrangementType>,
  /** The remarks for this attendance. This should be used over the `remarks` prop. */
  remarks?: Maybe<Array<Remark>>,
  startDateTime: Scalars['DateTime'],
  status?: Maybe<AttendanceStatus>,
  student?: Maybe<Student>,
  type: AttendanceType,
};


export type AttendanceSnapshotRemarksArgs = {
  includesStudentRemarks?: Maybe<Scalars['Boolean']>,
  showArchived?: Maybe<Scalars['Boolean']>
};

export enum AttendanceStatus {
  Absent = 'Absent',
  Cancelled = 'Cancelled',
  Holding = 'Holding',
  NotAttending = 'NotAttending',
  Present = 'Present',
  Skipped = 'Skipped',
  Unmarked = 'Unmarked'
}

export type AttendanceStatusTag = {
   __typename?: 'AttendanceStatusTag',
  description: Scalars['String'],
  tags: Array<AttendanceTag>,
};

export type AttendanceStudentIndex = {
   __typename?: 'AttendanceStudentIndex',
  _id: Scalars['ObjectId'],
  alias: Scalars['String'],
  avatarUrl?: Maybe<Scalars['String']>,
  fullName: Scalars['String'],
  gender?: Maybe<Scalars['String']>,
  userId?: Maybe<Scalars['String']>,
};

export enum AttendanceTag {
  AdditionalLesson = 'AdditionalLesson',
  Paid = 'Paid',
  ReplacementIn = 'ReplacementIn',
  TransferredOut = 'TransferredOut',
  TransferringIn = 'TransferringIn',
  TransferringOut = 'TransferringOut',
  Trial = 'Trial',
  Unpaid = 'Unpaid',
  Withdrawing = 'Withdrawing',
  Withdrawn = 'Withdrawn'
}

export type AttendanceTeacherIndex = {
   __typename?: 'AttendanceTeacherIndex',
  _id: Scalars['ObjectId'],
  avatarUrl?: Maybe<Scalars['String']>,
  fullName: Scalars['String'],
  gender?: Maybe<Scalars['String']>,
  initials?: Maybe<Scalars['String']>,
  salutation?: Maybe<Scalars['String']>,
};

export type AttendanceTrialIndex = {
   __typename?: 'AttendanceTrialIndex',
  _id: Scalars['ObjectId'],
  startDateTime: Scalars['DateTime'],
  status?: Maybe<AttendanceStatus>,
};

export enum AttendanceType {
  Additional = 'Additional',
  MakeupIn = 'MakeupIn',
  Regular = 'Regular',
  Trial = 'Trial'
}

export type AttendanceWhatsappAccountIndex = {
   __typename?: 'AttendanceWhatsappAccountIndex',
  _id: Scalars['ObjectId'],
  number?: Maybe<Telephone>,
  parentId: Scalars['ObjectId'],
};

export type AuditLog = {
   __typename?: 'AuditLog',
  _id: Scalars['ObjectId'],
  accessDevice?: Maybe<AccessDevice>,
  changes?: Maybe<Array<Changes>>,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  description?: Maybe<AuditLogDescriptionType>,
  isArchived: Scalars['Boolean'],
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  operationType: AuditLogOperationType,
  principal?: Maybe<SystemUser>,
  principalId: Scalars['ObjectId'],
  resourceId?: Maybe<Scalars['ObjectId']>,
  resourceName: Scalars['String'],
  resourceType?: Maybe<AuditLogResourceType>,
  timestamp?: Maybe<Scalars['DateTime']>,
};

export enum AuditLogDescriptionType {
  Approved = 'Approved',
  Archive = 'Archive',
  Cancelled = 'Cancelled',
  ChangeToRegistration = 'ChangeToRegistration',
  Link = 'Link',
  LinkRemove = 'LinkRemove',
  SetAttendance = 'SetAttendance',
  Unarchive = 'Unarchive',
  UpdateRecord = 'UpdateRecord',
  Void = 'Void'
}

export type AuditLogFilterInput = {
  dateTimeRange?: Maybe<DateRangeInput>,
  descriptions?: Maybe<Array<AuditLogDescriptionType>>,
  fieldNames?: Maybe<Array<Scalars['String']>>,
  operationTypes?: Maybe<Array<AuditLogOperationType>>,
  principalId?: Maybe<Scalars['ObjectId']>,
  resourceTypes?: Maybe<Array<AuditLogResourceType>>,
  roleIds?: Maybe<Array<Scalars['ObjectId']>>,
};

export enum AuditLogOperationType {
  Create = 'Create',
  Delete = 'Delete',
  Fetch = 'Fetch',
  Unknown = 'Unknown',
  Update = 'Update'
}

export type AuditLogPage = {
   __typename?: 'AuditLogPage',
  items: Array<AuditLog>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

export enum AuditLogResourceType {
  Admin = 'Admin',
  Attendance = 'Attendance',
  Classes = 'Classes',
  Course = 'Course',
  Invoice = 'Invoice',
  Lessons = 'Lessons',
  Makeup = 'Makeup',
  Parameter = 'Parameter',
  Parent = 'Parent',
  Receipt = 'Receipt',
  Refund = 'Refund',
  Registration = 'Registration',
  Student = 'Student',
  Submission = 'Submission',
  Teacher = 'Teacher',
  Template = 'Template',
  Transfer = 'Transfer',
  Withdrawal = 'Withdrawal'
}

export type AvailableSeatsNumberQuery = {
  max?: Maybe<Scalars['Int']>,
  min?: Maybe<Scalars['Int']>,
};

export type Balance = {
   __typename?: 'Balance',
  _id: Scalars['ObjectId'],
  balance: Scalars['Decimal'],
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  parent?: Maybe<Parent>,
  parentId: Scalars['ObjectId'],
};

export enum BalanceActionType {
  AddToBalance = 'AddToBalance',
  ApplyBalance = 'ApplyBalance',
  DeductBalance = 'DeductBalance',
  ManualAdjustBalance = 'ManualAdjustBalance',
  ReversalOfApplyBalance = 'ReversalOfApplyBalance',
  VoidReceipt = 'VoidReceipt'
}

export type BalanceHistory = {
   __typename?: 'BalanceHistory',
  _id: Scalars['ObjectId'],
  actionType?: Maybe<BalanceActionType>,
  amount: Scalars['Decimal'],
  amountPaid?: Maybe<Scalars['Decimal']>,
  amountPayable?: Maybe<Scalars['Decimal']>,
  balance: Scalars['Decimal'],
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  description?: Maybe<Scalars['String']>,
  invoiceIds?: Maybe<Array<Scalars['ObjectId']>>,
  invoiceNumbers?: Maybe<Array<Scalars['String']>>,
  invoices?: Maybe<Array<Invoice>>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  isMerged?: Maybe<Scalars['Boolean']>,
  isVoidedInvoice?: Maybe<Scalars['Boolean']>,
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  originalInvoiceAmount?: Maybe<Scalars['Decimal']>,
  outstandingPayable?: Maybe<Scalars['Decimal']>,
  parent: Parent,
  parentId: Scalars['ObjectId'],
  /** The date when the payment was made */
  paymentDate?: Maybe<Scalars['DateTime']>,
  paymentIds?: Maybe<Array<Scalars['ObjectId']>>,
  paymentMethod?: Maybe<Scalars['String']>,
  paymentNumbers?: Maybe<Array<Scalars['String']>>,
  payments?: Maybe<Array<Payment>>,
  receiptIds?: Maybe<Array<Scalars['ObjectId']>>,
  receiptNumbers?: Maybe<Array<Scalars['String']>>,
  refund?: Maybe<Refund>,
  refundId?: Maybe<Scalars['ObjectId']>,
  type: BalanceHistoryType,
};

export type BalanceHistoryFilterInput = {
  actionTypes?: Maybe<Array<BalanceActionType>>,
  name?: Maybe<Scalars['String']>,
  parentId?: Maybe<Scalars['ObjectId']>,
  paymentDate?: Maybe<DateTimeRangeInput>,
  paymentMethods?: Maybe<Array<PaymentMode>>,
  types?: Maybe<Array<BalanceHistoryType>>,
};

export type BalanceHistoryPage = {
   __typename?: 'BalanceHistoryPage',
  items: Array<BalanceHistory>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

export enum BalanceHistoryType {
  BalanceRefund = 'BalanceRefund',
  FeePayment = 'FeePayment',
  ManualAdjustment = 'ManualAdjustment',
  MergeAdjustment = 'MergeAdjustment',
  Receipt = 'Receipt',
  VoidBalanceRefund = 'VoidBalanceRefund',
  VoidFeePayment = 'VoidFeePayment',
  VoidReceipt = 'VoidReceipt'
}

export enum BillingFrequency {
  Perclass = 'Perclass',
  Permonth = 'Permonth',
  Perterm = 'Perterm'
}

export type Branch = {
   __typename?: 'Branch',
  _id: Scalars['ObjectId'],
  address: Address,
  alias: Scalars['String'],
  centre: Centre,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  isArchived: Scalars['Boolean'],
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  name: Scalars['String'],
  systemId?: Maybe<Scalars['String']>,
  venues?: Maybe<Array<Venue>>,
};


export type BranchVenuesArgs = {
  showArchived?: Maybe<Scalars['Boolean']>
};

/** Reward points input to add for multiple student items */
export type BulkAddRewardPointsInput = {
  /** Linked gradable (Exercise or Homework) ID if points are from award */
  gradableId?: Maybe<Scalars['ObjectId']>,
  /** Multiple student items along with points to be added */
  items: Array<RewardPointsItemInput>,
  /** Other notes associated with the reward transaction */
  remarks?: Maybe<Scalars['String']>,
  /** Type of reward history */
  type?: Maybe<RewardHistoryType>,
};

export type BulkCompleteTaskCommand = {
  ids?: Maybe<Array<Scalars['ObjectId']>>,
  type: TaskType,
};

export type BulkCreateClassCommand = {
  adhocClasses?: Maybe<Array<CreateAdhocClassCommand>>,
  regularClasses?: Maybe<Array<CreateRegularClassCommand>>,
  workshopClasses?: Maybe<Array<CreateWorkShopClassCommand>>,
};

export type BulkCreateDeliveryInput = {
  createDeliveries?: Maybe<Array<CreateDeliveryInput>>,
};

export type BulkCreateHistory = {
   __typename?: 'BulkCreateHistory',
  _id: Scalars['ObjectId'],
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  failed?: Maybe<Scalars['Int']>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  isExportFailedDocuments?: Maybe<Scalars['Boolean']>,
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  processed?: Maybe<Scalars['Int']>,
  status: BulkCreateHistoryStatus,
  total?: Maybe<Scalars['Int']>,
  type: BulkCreateHistoryType,
};

export enum BulkCreateHistoryStatus {
  Complete = 'Complete',
  Processing = 'Processing',
  ToBeConfirmed = 'ToBeConfirmed'
}

export enum BulkCreateHistoryType {
  Class = 'Class',
  Registration = 'Registration'
}

export type BulkCreateRegistrationCommand = {
  details: Array<BulkCreateRegistrationDetailCommand>,
};

export type BulkCreateRegistrationDetailCommand = {
  classId: Scalars['ObjectId'],
  lessonIds?: Maybe<Array<Scalars['ObjectId']>>,
  stage: RegistrationStage,
  startDate: Scalars['DateTime'],
  studentId: Scalars['ObjectId'],
};

/** Reward points input to deduct for multiple student items */
export type BulkDeductRewardPointsInput = {
  /** Linked gradable (Exercise or Homework) ID if points are from award */
  gradableId?: Maybe<Scalars['ObjectId']>,
  /** Multiple student items along with points to be deducted */
  items: Array<RewardPointsItemInput>,
  /** Reward order to redeem */
  orderDetail?: Maybe<RewardDetailInput>,
  /** Other notes associated with the reward transaction */
  remarks?: Maybe<Scalars['String']>,
  /** Type of reward history */
  type?: Maybe<RewardHistoryType>,
};

/** Bulk Handover Onsite Material. */
export type BulkHandoverOnsiteCommand = {
  materials?: Maybe<Array<HandoverOnsiteMaterialCommand>>,
  weekNumber?: Maybe<Scalars['Int']>,
};

export type BulkSendReminderInput = {
  attendanceIds?: Maybe<Array<Scalars['ObjectId']>>,
  emailInput?: Maybe<EmailReminderInput>,
  enrollmentIds?: Maybe<Array<Scalars['ObjectId']>>,
  generalRecipients?: Maybe<Array<CampaignRecipientInput>>,
  invoiceIds?: Maybe<Array<Scalars['ObjectId']>>,
  materialIds?: Maybe<Array<Scalars['ObjectId']>>,
  parentIds?: Maybe<Array<Scalars['ObjectId']>>,
  receiptIds?: Maybe<Array<Scalars['ObjectId']>>,
  registrationIds?: Maybe<Array<Scalars['ObjectId']>>,
  taskIds?: Maybe<Array<Scalars['ObjectId']>>,
  types: Array<ReminderType>,
  whatsappInput?: Maybe<WhatsappReminderInput>,
};

export type BulkVoidTaskCommand = {
  ids?: Maybe<Array<Scalars['ObjectId']>>,
  type: TaskType,
};

export type Campaign = {
   __typename?: 'Campaign',
  _id: Scalars['ObjectId'],
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  emailSubject?: Maybe<Scalars['String']>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  isIncludeAttachment?: Maybe<Scalars['Boolean']>,
  isRealSended: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  message?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  realSendAt?: Maybe<Scalars['DateTime']>,
  recallAt?: Maybe<Scalars['DateTime']>,
  recipientTypes?: Maybe<Array<CampaignRecipientType>>,
  recipients?: Maybe<Array<CampaignRecipient>>,
  scheduleSendAt?: Maybe<Scalars['DateTime']>,
  sendMethod: ReminderType,
  sentAt?: Maybe<Scalars['DateTime']>,
  status: CampaignStatus,
  template?: Maybe<Template>,
  templateId?: Maybe<Scalars['ObjectId']>,
  usageType?: Maybe<TemplateUsageType>,
};

export type CampaignExportHistory = {
   __typename?: 'CampaignExportHistory',
  _id: Scalars['ObjectId'],
  campaign: Campaign,
  campaignId: Scalars['ObjectId'],
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  url: Scalars['String'],
};

export type CampaignExportHistoryPage = {
   __typename?: 'CampaignExportHistoryPage',
  items: Array<CampaignExportHistory>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

export type CampaignFilterInput = {
  type?: Maybe<TemplateType>,
};

export type CampaignPage = {
   __typename?: 'CampaignPage',
  items: Array<Campaign>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

export type CampaignRecipient = {
   __typename?: 'CampaignRecipient',
  message?: Maybe<Scalars['String']>,
  parent: Parent,
  parentId: Scalars['ObjectId'],
  receiptId?: Maybe<Scalars['ObjectId']>,
  studentIds: Array<Scalars['ObjectId']>,
  students: Array<Student>,
};

export type CampaignRecipientInput = {
  message?: Maybe<Scalars['String']>,
  parentId: Scalars['ObjectId'],
  receiptId?: Maybe<Scalars['ObjectId']>,
  studentIds: Array<Scalars['ObjectId']>,
};

export enum CampaignRecipientType {
  PrimaryParent = 'PrimaryParent',
  SecondaryParents = 'SecondaryParents'
}

export enum CampaignStatus {
  Draft = 'Draft',
  Failed = 'Failed',
  Recalled = 'Recalled',
  Scheduling = 'Scheduling',
  Sent = 'Sent'
}

export type CancellationEffects = {
   __typename?: 'CancellationEffects',
  invoicesToVoid?: Maybe<Array<Invoice>>,
  isCreateNewCreditNote?: Maybe<Scalars['Boolean']>,
  receiptsToVoid?: Maybe<Array<Receipt>>,
};

export enum CapacitySeatType {
  Additional = 'Additional',
  MakeupIn = 'MakeupIn',
  Regular = 'Regular',
  Reserved = 'Reserved',
  TransferIn = 'TransferIn',
  TransferOut = 'TransferOut',
  Trial = 'Trial',
  UserRequest = 'UserRequest',
  WaitingList = 'WaitingList',
  Withdrawing = 'Withdrawing'
}

export enum CapacitySimulationType {
  Additional = 'Additional',
  Makeup = 'Makeup',
  Regular = 'Regular',
  Reservation = 'Reservation',
  Trial = 'Trial'
}

export type Centre = {
   __typename?: 'Centre',
  _id: Scalars['ObjectId'],
  branches?: Maybe<Array<Branch>>,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  departments?: Maybe<Array<Department>>,
  isArchived: Scalars['Boolean'],
  isDefault: Scalars['Boolean'],
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
};


export type CentreBranchesArgs = {
  showArchived?: Maybe<Scalars['Boolean']>
};


export type CentreDepartmentsArgs = {
  showArchived?: Maybe<Scalars['Boolean']>
};

export type CentreAssignments = {
   __typename?: 'CentreAssignments',
  classScores?: Maybe<ScoreStats>,
  gradable?: Maybe<GradableUnion>,
  levelScores?: Maybe<ScoreStats>,
  submission?: Maybe<Submission>,
};

export type ChangeAcademicLevelArchive = {
  id: Scalars['ObjectId'],
  isArchived: Scalars['Boolean'],
};

export type ChangeAcademicLevelVisibility = {
  id: Scalars['ObjectId'],
  isVisible: Scalars['Boolean'],
};

export type ChangeAttendingClassForRegistrationInput = {
  fromLessonId: Scalars['ObjectId'],
  registrationId: Scalars['ObjectId'],
  toLessonId: Scalars['ObjectId'],
};

export type ChangeAttendingClassForTransferInput = {
  arrangementId: Scalars['ObjectId'],
  fromLessonId: Scalars['ObjectId'],
  toLessonId: Scalars['ObjectId'],
};

export type ChangeBranchArchiveInput = {
  id: Scalars['ObjectId'],
  isArchived: Scalars['Boolean'],
};

/** 
 * An input that is used to update the assigned LessonFlow of one or many classes.
 * The "ids" field must contain one or more IDs.
 */
export type ChangeClassLessonFlowInput = {
  /** Class ID to update. */
  id: Scalars['ObjectId'],
  /** The ID of the new LessonFlow to assign to the classes specified. */
  lessonFlowId?: Maybe<Scalars['ObjectId']>,
};

export type ChangeCourierPickupAddressArchiveInput = {
  id: Scalars['ObjectId'],
  isArchived: Scalars['Boolean'],
};

export type ChangeDepartmentArchiveInput = {
  id: Scalars['ObjectId'],
  isArchived: Scalars['Boolean'],
};

export type Changes = {
   __typename?: 'Changes',
  fieldName?: Maybe<Scalars['String']>,
  newValue?: Maybe<Scalars['String']>,
  oldValue?: Maybe<Scalars['String']>,
};

export type ChangeSchoolArchiveInput = {
  id: Scalars['ObjectId'],
  isArchived: Scalars['Boolean'],
};

export type ChangeSchoolVisibilityInput = {
  id: Scalars['ObjectId'],
  isVisible: Scalars['Boolean'],
};

export type Class = {
   __typename?: 'Class',
  _id: Scalars['ObjectId'],
  academicLevelIds: Array<Scalars['ObjectId']>,
  academicLevels: Array<AcademicLevel>,
  academicWeeks: Array<AcademicWeek>,
  academicYear: AcademicYear,
  academicYearId: Scalars['ObjectId'],
  activeEnrollments?: Maybe<Array<Enrollment>>,
  capacity?: Maybe<ClassCapacityDto>,
  capacityUsed?: Maybe<Scalars['Int']>,
  classCode?: Maybe<Scalars['String']>,
  classFee?: Maybe<StandardCourseFee>,
  course: Course,
  courseId: Scalars['ObjectId'],
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  dailySessionTimeSlots?: Maybe<Array<TimeRange>>,
  defaultLessonDays?: Maybe<Array<WeekDay>>,
  defaultLessonEnd?: Maybe<Scalars['DateTime']>,
  defaultLessonStart?: Maybe<Scalars['DateTime']>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  digitalClassAccessCredentials?: Maybe<DigitalClassAccessCredentials>,
  eligibility?: Maybe<Array<ClassEligibility>>,
  enrollments?: Maybe<Array<Enrollment>>,
  firstLessonDate?: Maybe<Scalars['DateTime']>,
  generateUntil?: Maybe<Scalars['String']>,
  isAdHoc?: Maybe<Scalars['Boolean']>,
  isArchived?: Maybe<Scalars['Boolean']>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  isReplacementLesson?: Maybe<Scalars['Boolean']>,
  isSimulation?: Maybe<Scalars['Boolean']>,
  isSkipScheduledHoliday?: Maybe<Scalars['Boolean']>,
  isVisible?: Maybe<Scalars['Boolean']>,
  lastLessonDate?: Maybe<Scalars['DateTime']>,
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  learningArrangement: LearningArrangementType,
  lessonDates?: Maybe<Array<Scalars['DateTime']>>,
  lessonFlow?: Maybe<LessonFlow>,
  lessonNumber?: Maybe<Scalars['Int']>,
  /** The lesson plan applied to the class. */
  lessonPlan?: Maybe<ClassLessonPlan>,
  lessons?: Maybe<Array<Lesson>>,
  maximumClassCapacity?: Maybe<Scalars['Int']>,
  maximumMakeUpCapacity?: Maybe<Scalars['Int']>,
  name: Scalars['String'],
  numberOfLessons?: Maybe<Scalars['Int']>,
  registrations?: Maybe<Array<Registration>>,
  repeat?: Maybe<Scalars['Int']>,
  runNumber?: Maybe<Scalars['Int']>,
  seats?: Maybe<Array<ClassSeatDto>>,
  shorthand?: Maybe<Scalars['String']>,
  status?: Maybe<ClassStatus>,
  teacher?: Maybe<SystemUser>,
  teacherId?: Maybe<Scalars['ObjectId']>,
  term?: Maybe<Term>,
  type?: Maybe<ClassType>,
  venue?: Maybe<Venue>,
  venueId?: Maybe<Scalars['ObjectId']>,
  voidRemarks?: Maybe<Scalars['String']>,
};


export type ClassEligibilityArgs = {
  linkRegistrationId?: Maybe<Scalars['ObjectId']>,
  studentId?: Maybe<Scalars['ObjectId']>
};


export type ClassEnrollmentsArgs = {
  status?: Maybe<Array<EnrollmentStatus>>,
  types?: Maybe<Array<EnrollmentType>>
};


export type ClassLessonsArgs = {
  skipHoliday?: Maybe<Scalars['Boolean']>
};


export type ClassRegistrationsArgs = {
  status?: Maybe<Array<RegistrationsStatus>>,
  types?: Maybe<Array<RegistrationType>>
};

export type ClassAcademicLevelIndex = {
   __typename?: 'ClassAcademicLevelIndex',
  _id: Scalars['ObjectId'],
  name: Scalars['String'],
};

export type ClassAcademicYearIndex = {
   __typename?: 'ClassAcademicYearIndex',
  _id: Scalars['ObjectId'],
  year: Scalars['Int'],
};

export type ClassBranchIndex = {
   __typename?: 'ClassBranchIndex',
  _id: Scalars['ObjectId'],
  alias: Scalars['String'],
  name: Scalars['String'],
};

export type ClassCapacityDto = {
   __typename?: 'ClassCapacityDTO',
  regular?: Maybe<Scalars['Int']>,
  replacement?: Maybe<Scalars['Int']>,
  seats?: Maybe<Array<ClassSeatDto>>,
  used?: Maybe<Scalars['Int']>,
  waiting?: Maybe<Scalars['Int']>,
};

export type ClassChangeLog = {
   __typename?: 'ClassChangeLog',
  _id: Scalars['ObjectId'],
  affectAt: Scalars['DateTime'],
  classId?: Maybe<Scalars['ObjectId']>,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  digitalCredentials?: Maybe<DigitalClassAccessCredentialsDo>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  learningArrangement?: Maybe<LearningArrangementType>,
  lessonId?: Maybe<Scalars['ObjectId']>,
  previousDigitalCredentials?: Maybe<DigitalClassAccessCredentialsDo>,
  previousLearningArrangement?: Maybe<LearningArrangementType>,
  previousTeacher?: Maybe<SystemUser>,
  previousTeacherId?: Maybe<Scalars['ObjectId']>,
  previousVenue?: Maybe<Venue>,
  previousVenueId?: Maybe<Scalars['ObjectId']>,
  teacher?: Maybe<SystemUser>,
  teacherId?: Maybe<Scalars['ObjectId']>,
  type: ClassChangeLogType,
  venue?: Maybe<Venue>,
  venueId?: Maybe<Scalars['ObjectId']>,
};

export enum ClassChangeLogType {
  ClassDate = 'ClassDate',
  ClassLearningArrangement = 'ClassLearningArrangement',
  ClassTeacher = 'ClassTeacher',
  ClassVenue = 'ClassVenue',
  ClassZoomDetails = 'ClassZoomDetails',
  LessonTeacher = 'LessonTeacher',
  LessonVenue = 'LessonVenue'
}

export type ClassCourseIndex = {
   __typename?: 'ClassCourseIndex',
  _id: Scalars['ObjectId'],
  name: Scalars['String'],
  nameShort: Scalars['String'],
};

export type ClassEligibility = {
   __typename?: 'ClassEligibility',
  lessonDates?: Maybe<Array<Scalars['DateTime']>>,
  stage: RegistrationStage,
};

export type ClassEnrollmentOverview = {
   __typename?: 'ClassEnrollmentOverview',
  capacity?: Maybe<ClassCapacityDto>,
  classId: Scalars['ObjectId'],
  serializedData: Scalars['String'],
};

export type ClassFilterInput = {
  academicLevelIds?: Maybe<Array<Scalars['ObjectId']>>,
  academicYearIds?: Maybe<Array<Scalars['ObjectId']>>,
  academicYears?: Maybe<Array<Scalars['Int']>>,
  availableSeatsNumber?: Maybe<AvailableSeatsNumberQuery>,
  classCodes?: Maybe<Array<Scalars['String']>>,
  courseIds?: Maybe<Array<Scalars['ObjectId']>>,
  dateRange?: Maybe<DateTimeRangeInput>,
  isAdHoc?: Maybe<Scalars['Boolean']>,
  isFull?: Maybe<Scalars['Boolean']>,
  isReplacementLesson?: Maybe<Scalars['Boolean']>,
  isVisible?: Maybe<Scalars['Boolean']>,
  isWaitlisted?: Maybe<Scalars['Boolean']>,
  learningArrangements?: Maybe<Array<LearningArrangementType>>,
  lessonDays?: Maybe<Array<WeekDay>>,
  /** Filters classes with applied lesson plans. */
  lessonPlanIds?: Maybe<Array<Scalars['ObjectId']>>,
  name?: Maybe<Scalars['String']>,
  status?: Maybe<Array<ClassStatus>>,
  teacherIds?: Maybe<Array<Scalars['ObjectId']>>,
  types?: Maybe<Array<ClassType>>,
  venueIds?: Maybe<Array<Scalars['ObjectId']>>,
};

export type ClassIndex = {
   __typename?: 'ClassIndex',
  _id: Scalars['ObjectId'],
  academicLevels?: Maybe<Array<ClassAcademicLevelIndex>>,
  academicYear?: Maybe<ClassAcademicYearIndex>,
  academicYearId: Scalars['ObjectId'],
  branch?: Maybe<ClassBranchIndex>,
  capacity?: Maybe<ClassCapacityDto>,
  classCode?: Maybe<Scalars['String']>,
  course?: Maybe<ClassCourseIndex>,
  courseId: Scalars['ObjectId'],
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  defaultLessonDays?: Maybe<Array<WeekDay>>,
  defaultLessonEnd?: Maybe<Scalars['DateTime']>,
  defaultLessonStart?: Maybe<Scalars['DateTime']>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  digitalClassAccessCredentials?: Maybe<DigitalClassAccessCredentialsDo>,
  isAdHoc?: Maybe<Scalars['Boolean']>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  lastModifiedFullName?: Maybe<Scalars['String']>,
  learningArrangement: LearningArrangementType,
  maximumClassCapacity?: Maybe<Scalars['Int']>,
  maximumMakeUpCapacity?: Maybe<Scalars['Int']>,
  name: Scalars['String'],
  regularStudents?: Maybe<Scalars['Int']>,
  reservedStudents?: Maybe<Scalars['Int']>,
  shorthand?: Maybe<Scalars['String']>,
  status?: Maybe<ClassStatus>,
  teacher?: Maybe<ClassTeacherIndex>,
  transferredStudents?: Maybe<Scalars['Int']>,
  trialStudents?: Maybe<Scalars['Int']>,
  type?: Maybe<ClassType>,
  venue?: Maybe<ClassVenueIndex>,
  withdrawnStudents?: Maybe<Scalars['Int']>,
};

export type ClassIndexPage = {
   __typename?: 'ClassIndexPage',
  items: Array<ClassIndex>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

/** The lesson plan applied to the class. */
export type ClassLessonPlan = {
   __typename?: 'ClassLessonPlan',
  /** The timestamp when the lesson plan was applied. */
  appliedDate: Scalars['DateTime'],
  /** Reference to the lesson plan applied. */
  id: Scalars['ObjectId'],
  /** The name of the lesson plan applied. */
  name: Scalars['String'],
};

export type ClassPage = {
   __typename?: 'ClassPage',
  items: Array<Class>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

export type ClassSeatDetailDto = {
   __typename?: 'ClassSeatDetailDTO',
  /** date string */
  enrollmentEndDate?: Maybe<Scalars['String']>,
  /** date string */
  enrollmentStartDate?: Maybe<Scalars['String']>,
  enrollmentStatus?: Maybe<EnrollmentStatus>,
  hasPaid?: Maybe<Scalars['Boolean']>,
  isEnrollmentStarted?: Maybe<Scalars['Boolean']>,
  isSimulation?: Maybe<Scalars['Boolean']>,
  seatType?: Maybe<SeatType>,
  student?: Maybe<Student>,
  type?: Maybe<CapacitySeatType>,
};

export type ClassSeatDto = {
   __typename?: 'ClassSeatDTO',
  /** date string */
  end?: Maybe<Scalars['String']>,
  seatDetails?: Maybe<Array<ClassSeatDetailDto>>,
  /** date string */
  start?: Maybe<Scalars['String']>,
};

export enum ClassStatus {
  Completed = 'Completed',
  Created = 'Created',
  Incomplete = 'Incomplete',
  Ongoing = 'Ongoing',
  Void = 'Void'
}

/** Object defining submission statistics by Class */
export type ClassSubmissionStats = {
   __typename?: 'ClassSubmissionStats',
  /** Object ID of Class */
  _id: Scalars['ObjectId'],
  /** Abbreviated Class name */
  abbreviation: Scalars['String'],
  /** Class name */
  className: Scalars['String'],
  /** Score statistics object containing min, max, and avg scores of the Class */
  score: ScoreStats,
};

/** Input parameters in getting score statistics by class */
export type ClassSubmissionStatsInput = {
  /** Filter class score statistics by Academic Level ID */
  academicLevelId?: Maybe<Scalars['ObjectId']>,
  /** Filter class score statistics by Academic Year ID */
  academicYearId?: Maybe<Scalars['ObjectId']>,
  /** Only filter the class score statistisc of these teachers */
  teacherIds?: Maybe<Scalars['ObjectId']>,
  /** Filter class score statistics by Worksheet ID */
  worksheetId?: Maybe<Scalars['ObjectId']>,
};

export type ClassTeacherIndex = {
   __typename?: 'ClassTeacherIndex',
  _id: Scalars['ObjectId'],
  avatarUrl?: Maybe<Scalars['String']>,
  fullName: Scalars['String'],
  gender?: Maybe<Scalars['String']>,
  initials?: Maybe<Scalars['String']>,
  salutation?: Maybe<Scalars['String']>,
};

export enum ClassType {
  Recurring = 'Recurring',
  Workshop = 'Workshop'
}

export type ClassVenueIndex = {
   __typename?: 'ClassVenueIndex',
  _id: Scalars['ObjectId'],
  shorthand: Scalars['String'],
  unitNo?: Maybe<Scalars['String']>,
};

/** The collect object that lists the description of a collect item in the LessonFlowItem. */
export type Collect = {
   __typename?: 'Collect',
  /** The ID of the Collect. */
  _id: Scalars['ObjectId'],
  /** The description of the collect formatted in markdown. */
  description: Scalars['String'],
};

export type CompleteMailMergeInput = {
  id: Scalars['ObjectId'],
  senderId: Scalars['ObjectId'],
};

export type ConnectXeroAccountForCourseInput = {
  courseId: Scalars['ObjectId'],
  liabilityAccount: AccountInput,
  revenueAccount: AccountInput,
};

export type ConnectXeroAccountInput = {
  code: Scalars['String'],
  id: Scalars['ObjectId'],
  name: Scalars['String'],
};

export type Consent = {
   __typename?: 'Consent',
  PhotographyOrVideography?: Maybe<Scalars['Boolean']>,
  accountCondition?: Maybe<Scalars['Boolean']>,
  statements?: Maybe<Scalars['Boolean']>,
  termCondition?: Maybe<Scalars['Boolean']>,
};

export type ConsentInput = {
  PhotographyOrVideography?: Maybe<Scalars['Boolean']>,
  accountCondition?: Maybe<Scalars['Boolean']>,
  statements?: Maybe<Scalars['Boolean']>,
  termCondition?: Maybe<Scalars['Boolean']>,
};

export type ConsentOutput = {
   __typename?: 'ConsentOutput',
  PhotographyOrVideography?: Maybe<Scalars['Boolean']>,
  accountCondition?: Maybe<Scalars['Boolean']>,
  statements?: Maybe<Scalars['Boolean']>,
  termCondition?: Maybe<Scalars['Boolean']>,
};

export type Content = {
   __typename?: 'Content',
  academicWeek?: Maybe<AcademicWeek>,
  gradable?: Maybe<GradableUnion>,
  submissions: Array<Submission>,
};

export type CopyTaskListInput = {
  endAt?: Maybe<Scalars['DateTime']>,
  generateAt?: Maybe<Scalars['DateTime']>,
  isAutoGenerate: Scalars['Boolean'],
  listId: Scalars['ObjectId'],
  name: Scalars['String'],
  startAt?: Maybe<Scalars['DateTime']>,
  weekDay?: Maybe<WeekDay>,
};

export type CountryCode = {
   __typename?: 'CountryCode',
  _id: Scalars['ObjectId'],
  areaCode: Scalars['String'],
  countryCode?: Maybe<Scalars['String']>,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  order?: Maybe<Scalars['Int']>,
};

export type CourierPickupAddress = {
   __typename?: 'CourierPickupAddress',
  _id: Scalars['ObjectId'],
  address: Scalars['String'],
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  isArchived: Scalars['Boolean'],
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  postalCode: Scalars['String'],
};

export type Course = {
   __typename?: 'Course',
  _id: Scalars['ObjectId'],
  academicLevelIds: Array<Scalars['String']>,
  academicLevels: Array<AcademicLevel>,
  academicStreams?: Maybe<Array<Scalars['String']>>,
  avatarUrl?: Maybe<Scalars['String']>,
  classes?: Maybe<Array<Class>>,
  colorCode?: Maybe<Scalars['String']>,
  courseFee?: Maybe<CourseFee>,
  courseMaterial?: Maybe<Scalars['String']>,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  description?: Maybe<Scalars['String']>,
  disclaimer?: Maybe<Array<Scalars['String']>>,
  duration?: Maybe<Scalars['Float']>,
  enrollments: Array<Enrollment>,
  gamePlanLink?: Maybe<Scalars['String']>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  isRequireFeedback?: Maybe<Scalars['Boolean']>,
  isSkipScheduledHoliday?: Maybe<Scalars['Boolean']>,
  isVisible: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  learningResourceUrl?: Maybe<Scalars['String']>,
  makeUpCredits: CourseReplacementCredit,
  name: Scalars['String'],
  nameShort: Scalars['String'],
  nextRunNumber?: Maybe<Scalars['Int']>,
  numberOfLessons?: Maybe<Scalars['Int']>,
  onGoingClassNumber: Scalars['Int'],
  status?: Maybe<CourseStatus>,
  subject?: Maybe<Scalars['String']>,
  termsAndConditions?: Maybe<Scalars['String']>,
  /** List of topics for the course */
  topics?: Maybe<Array<Topic>>,
  type: CourseType,
  voidRemarks?: Maybe<Scalars['String']>,
  xeroAccountsSyncStatus?: Maybe<Scalars['Boolean']>,
};


export type CourseClassesArgs = {
  academicYearId?: Maybe<Scalars['ObjectId']>,
  status?: Maybe<Array<ClassStatus>>
};

export type CourseAcademicLevelIndex = {
   __typename?: 'CourseAcademicLevelIndex',
  _id: Scalars['ObjectId'],
  name: Scalars['String'],
};

export type CourseFee = {
   __typename?: 'CourseFee',
  billingFrequency?: Maybe<BillingFrequency>,
  isApplicable: Scalars['Boolean'],
  otherFees?: Maybe<Array<Fee>>,
  standardCourseFees: Array<StandardCourseFee>,
};

export type CourseFeeDo = {
   __typename?: 'CourseFeeDO',
  billingFrequency?: Maybe<BillingFrequency>,
  isApplicable: Scalars['Boolean'],
  otherFees?: Maybe<Array<FeeDo>>,
  standardCourseFees: Array<StandardCourseFeeDo>,
};

export type CourseFeeInput = {
  billingFrequency: BillingFrequency,
  isApplicable: Scalars['Boolean'],
  otherFees?: Maybe<Array<OtherFeeInput>>,
  standardCourseFees: Array<StandardCourseFeeInput>,
};

export type CourseFeeOutput = {
   __typename?: 'CourseFeeOutput',
  billingFrequency: BillingFrequency,
  isApplicable: Scalars['Boolean'],
  otherFees?: Maybe<Array<OtherFeeOutput>>,
  standardCourseFees: Array<StandardCourseFee>,
};

export enum CourseFeeType {
  Perclass = 'Perclass',
  Perlesson = 'Perlesson',
  Permonth = 'Permonth',
  Perterm = 'Perterm'
}

export type CourseFilterInput = {
  academicLevelIds?: Maybe<Array<Scalars['ObjectId']>>,
  academicStreams?: Maybe<Array<Scalars['String']>>,
  courseIds?: Maybe<Array<Scalars['ObjectId']>>,
  dateRange?: Maybe<DateTimeRangeInput>,
  eligibleStudentId?: Maybe<Scalars['ObjectId']>,
  isVisible?: Maybe<Scalars['Boolean']>,
  name?: Maybe<Scalars['String']>,
  statuses?: Maybe<Array<CourseStatus>>,
  teacherId?: Maybe<Scalars['ObjectId']>,
  types?: Maybe<Array<CourseType>>,
};

export type CourseIndex = {
   __typename?: 'CourseIndex',
  _id: Scalars['ObjectId'],
  academicLevels?: Maybe<Array<CourseAcademicLevelIndex>>,
  academicStreams?: Maybe<Array<Scalars['String']>>,
  additionalLessonFee?: Maybe<Scalars['Decimal']>,
  avatarUrl?: Maybe<Scalars['String']>,
  colorCode?: Maybe<Scalars['String']>,
  courseCompleted?: Maybe<Scalars['Int']>,
  courseFee?: Maybe<CourseFeeDo>,
  courseFeeWithSort?: Maybe<Scalars['Decimal']>,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  depositFee?: Maybe<Scalars['Decimal']>,
  description?: Maybe<Scalars['String']>,
  disclaimer?: Maybe<Array<Scalars['String']>>,
  duration?: Maybe<Scalars['Float']>,
  isArchived: Scalars['Boolean'],
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  isSkipScheduledHoliday?: Maybe<Scalars['Boolean']>,
  isVisible: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  lastModifiedFullName?: Maybe<Scalars['String']>,
  learningResourceUrl?: Maybe<Scalars['String']>,
  makeUpCredits: MakeUpCredits,
  materialsFee?: Maybe<Scalars['Decimal']>,
  name: Scalars['String'],
  nameShort: Scalars['String'],
  numberOfLessons?: Maybe<Scalars['Int']>,
  ongoingClasses?: Maybe<Scalars['Int']>,
  registrationFee?: Maybe<Scalars['Decimal']>,
  registrations?: Maybe<Scalars['Int']>,
  reservations?: Maybe<Scalars['Int']>,
  status?: Maybe<CourseStatus>,
  subject?: Maybe<Scalars['String']>,
  termsAndConditions?: Maybe<Scalars['String']>,
  totalRevenue?: Maybe<Scalars['Float']>,
  trialFee?: Maybe<Scalars['Decimal']>,
  trials?: Maybe<Scalars['Int']>,
  type: CourseType,
  voidRemarks?: Maybe<Scalars['String']>,
  withdrawals?: Maybe<Scalars['Int']>,
  withdrawing?: Maybe<Scalars['Int']>,
};


export type CourseIndexTotalRevenueArgs = {
  dateRange: DateTimeRangeInput
};

export type CourseIndexPage = {
   __typename?: 'CourseIndexPage',
  items: Array<CourseIndex>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

export type CourseNotification = {
   __typename?: 'CourseNotification',
  _id: Scalars['ObjectId'],
  academicYear?: Maybe<AcademicYear>,
  academicYearId: Scalars['ObjectId'],
  course?: Maybe<Course>,
  courseId: Scalars['ObjectId'],
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  enrollments?: Maybe<Array<Enrollment>>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  newClasses?: Maybe<Array<Class>>,
  student?: Maybe<Student>,
  studentId: Scalars['ObjectId'],
};

export type CourseNotificationFilterInput = {
  courseIds?: Maybe<Array<Scalars['ObjectId']>>,
};

export type CourseNotificationPage = {
   __typename?: 'CourseNotificationPage',
  items: Array<CourseNotification>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

export type CoursePage = {
   __typename?: 'CoursePage',
  items: Array<Course>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

export type CourseReplacementCredit = {
   __typename?: 'CourseReplacementCredit',
  term1?: Maybe<Scalars['Int']>,
  term2?: Maybe<Scalars['Int']>,
  term3?: Maybe<Scalars['Int']>,
  term4?: Maybe<Scalars['Int']>,
  workshop?: Maybe<Scalars['Int']>,
};

export enum CourseStatus {
  Completed = 'Completed',
  Created = 'Created',
  Ongoing = 'Ongoing',
  Void = 'Void'
}

export enum CourseType {
  Regular = 'Regular',
  Workshop = 'Workshop'
}

export type CreateAcademicLevelInput = {
  alias: Scalars['String'],
  educationStage: EducationStage,
  isVisible: Scalars['Boolean'],
  name: Scalars['String'],
};

export type CreateAcademicYearInput = {
  academicWeekEnd: WeekDay,
  academicWeekStart: WeekDay,
  period: DateTimeRangeInput,
  taxRate: Scalars['Int'],
  termBillingDueDate: TermBillingDueDateInput,
  terms: Array<TermInput>,
  year: Scalars['Int'],
};

export type CreateAdhocClassCommand = {
  academicWeekNumber: Scalars['Int'],
  academicYearId: Scalars['ObjectId'],
  classCode: Scalars['String'],
  courseId: Scalars['ObjectId'],
  /** Used to record the original CSV document index for import class creation records. */
  csvIndex?: Maybe<Scalars['Float']>,
  defaultLessonEnd: Scalars['DateTime'],
  defaultLessonStart: Scalars['DateTime'],
  digitalClassAccessCredentials?: Maybe<DigitalClassAccessCredentialsCommand>,
  firstLessonDate: Scalars['DateTime'],
  generateUntil?: Maybe<Scalars['String']>,
  isSkipScheduledHoliday?: Maybe<Scalars['Boolean']>,
  learningArrangement: LearningArrangementType,
  maximumClassCapacity?: Maybe<Scalars['Int']>,
  maximumMakeUpCapacity?: Maybe<Scalars['Int']>,
  name: Scalars['String'],
  shorthand: Scalars['String'],
  teacherId?: Maybe<Scalars['ObjectId']>,
  venueId?: Maybe<Scalars['ObjectId']>,
};

export type CreateAdHocInvoiceCommand = {
  billedForStudentId?: Maybe<Scalars['ObjectId']>,
  billedToParentId: Scalars['ObjectId'],
  classId?: Maybe<Scalars['ObjectId']>,
  lineItems: Array<CreateAdHocInvoiceItemCommand>,
};

export type CreateAdHocInvoiceItemCommand = {
  description: Scalars['String'],
  quantity: Scalars['Int'],
  remarks?: Maybe<Scalars['String']>,
  unitAmount: Scalars['Decimal'],
};

/** Create Ad Hoc Material Envelope Booklet Input */
export type CreateAdHocMaterialEnvelopeBookletInput = {
  bookletId: Scalars['ObjectId'],
  worksheetIds: Array<Scalars['ObjectId']>,
};

/** Create Ad Hoc Material Envelopes Input */
export type CreateAdHocMaterialEnvelopeInput = {
  booklets: Array<CreateAdHocMaterialEnvelopeBookletInput>,
  envelopeId: Scalars['ObjectId'],
};

/** Create Ad Hoc Material Input */
export type CreateAdHocMaterialInput = {
  adHocMaterials?: Maybe<Scalars['String']>,
  courseId?: Maybe<Scalars['ObjectId']>,
  envelope?: Maybe<CreateAdHocMaterialEnvelopeInput>,
  studentId: Scalars['ObjectId'],
};

export type CreateArrangementInput = {
  fromClassId: Scalars['ObjectId'],
  fromLessonId: Scalars['ObjectId'],
  group?: Maybe<EnrollmentGroupType>,
  isAdhocAdditionalLesson?: Maybe<Scalars['Boolean']>,
  preferredLearningArrangement?: Maybe<LearningArrangementType>,
  remarks?: Maybe<Scalars['String']>,
  studentId: Scalars['ObjectId'],
  toClassId?: Maybe<Scalars['ObjectId']>,
  toLessonId?: Maybe<Scalars['ObjectId']>,
  type: ArrangementType,
  useCredits?: Maybe<Scalars['Boolean']>,
  withdrawReasons?: Maybe<WithdrawReasonsInput>,
};

export type CreateBranchInput = {
  address: AddressInput,
  alias: Scalars['String'],
  centreId: Scalars['ObjectId'],
  name: Scalars['String'],
};

export type CreateCampaignInput = {
  emailSubject?: Maybe<Scalars['String']>,
  isIncludeAttachment: Scalars['Boolean'],
  message?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  recipientTypes?: Maybe<Array<CampaignRecipientType>>,
  recipients?: Maybe<Array<CampaignRecipientInput>>,
  scheduleSendAt?: Maybe<Scalars['DateTime']>,
  sendMethod: ReminderType,
  status: CampaignStatus,
  templateId?: Maybe<Scalars['ObjectId']>,
  usageType: TemplateUsageType,
};

export type CreateClassOutput = {
   __typename?: 'CreateClassOutput',
  academicWeekNumber?: Maybe<Scalars['Int']>,
  academicYearId: Scalars['ObjectId'],
  classCode: Scalars['String'],
  classFee?: Maybe<StandardCourseFee>,
  courseId: Scalars['ObjectId'],
  dailySessionTimeSlots?: Maybe<Array<TimeRangeOutput>>,
  defaultLessonDays?: Maybe<Array<WeekDay>>,
  defaultLessonEnd?: Maybe<Scalars['DateTime']>,
  defaultLessonStart?: Maybe<Scalars['DateTime']>,
  digitalClassAccessCredentials?: Maybe<DigitalClassAccessCredentialsOutput>,
  firstLessonDate?: Maybe<Scalars['DateTime']>,
  generateUntil?: Maybe<Scalars['String']>,
  isAdHoc?: Maybe<Scalars['Boolean']>,
  isSkipScheduledHoliday?: Maybe<Scalars['Boolean']>,
  lastLessonDate?: Maybe<Scalars['DateTime']>,
  learningArrangement: LearningArrangementType,
  maximumClassCapacity?: Maybe<Scalars['Int']>,
  maximumMakeUpCapacity?: Maybe<Scalars['Int']>,
  name: Scalars['String'],
  numberOfLessons?: Maybe<Scalars['Int']>,
  repeat?: Maybe<Scalars['Int']>,
  runNumber?: Maybe<Scalars['Int']>,
  shorthand?: Maybe<Scalars['String']>,
  teacherId?: Maybe<Scalars['ObjectId']>,
  venueId?: Maybe<Scalars['ObjectId']>,
};

/** Input used to Create collect details of a LessonFlowItem. */
export type CreateCollectInput = {
  /** The text of the collect generated from a rich text editor. */
  description: Scalars['String'],
  /** The ID of the LessonFlowItem to insert the collect to. */
  lessonFlowItemId: Scalars['ObjectId'],
};

export type CreateCourierPickupAddressInput = {
  address: Scalars['String'],
  postalCode: Scalars['String'],
};

export type CreateCourseNotificationInput = {
  academicYearId: Scalars['ObjectId'],
  courseId: Scalars['ObjectId'],
  studentId: Scalars['ObjectId'],
};

export type CreateCourseOutput = {
   __typename?: 'CreateCourseOutput',
  academicLevelIds: Array<Scalars['ObjectId']>,
  academicStreams: Array<Scalars['String']>,
  avatarUrl?: Maybe<Scalars['String']>,
  courseFee: CourseFeeOutput,
  description?: Maybe<Scalars['String']>,
  disclaimer: Array<Scalars['String']>,
  duration: Scalars['Float'],
  isRequireFeedback?: Maybe<Scalars['Boolean']>,
  isSkipScheduledHoliday?: Maybe<Scalars['Boolean']>,
  learningResourceUrl?: Maybe<Scalars['String']>,
  makeUpCredits: MakeUpCreditOutput,
  name: Scalars['String'],
  nameShort: Scalars['String'],
  numberOfLessons?: Maybe<Scalars['Int']>,
  subject?: Maybe<Scalars['String']>,
  termsAndConditions: Scalars['String'],
  type: CourseType,
};

export type CreateDeliveryInput = {
  address: Scalars['String'],
  dropOffAddress: Scalars['String'],
  dropOffMobile: TelephoneInput,
  dropOffPostalCode: Scalars['String'],
  dropOffRemarks?: Maybe<Scalars['String']>,
  materialIds: Array<Scalars['ObjectId']>,
  parcelSize: DeliveryParcelSize,
  pickupEndAt: Scalars['DateTime'],
  pickupStartAt: Scalars['DateTime'],
  remarks?: Maybe<Scalars['String']>,
  status: DeliveryStatus,
  trackingNo?: Maybe<Scalars['String']>,
  type: DeliveryType,
};

export type CreateDepartmentInput = {
  centreId: Scalars['ObjectId'],
  name: Scalars['String'],
};

export type CreateDiscountInput = {
  discountAmount?: Maybe<Scalars['Decimal']>,
  discountName: Scalars['String'],
  discountRate?: Maybe<Scalars['Decimal']>,
  discountType: DiscountType,
};

export type CreateEligibilityFlowInput = {
  courseId: Scalars['ObjectId'],
  startNode: FlowNodeInput,
};

export type CreateExerciseInput = {
  content: Scalars['String'],
  duration?: Maybe<Scalars['Int']>,
  lessonNumber: Scalars['Int'],
  needSubmit?: Maybe<Scalars['Boolean']>,
  order: Scalars['Int'],
  totalBreakdown?: Maybe<MarkBreakDownInput>,
  totalGrade?: Maybe<Scalars['Int']>,
  workSheetId: Scalars['ObjectId'],
};

export type CreateFeedbackInput = {
  lessonFlowId?: Maybe<Scalars['ObjectId']>,
  lessonFlowItemId?: Maybe<Scalars['ObjectId']>,
  quoted: Scalars['String'],
  text: Scalars['String'],
  type: FeedbackType,
};

/** Input in creating a single Feedback Seen Receipt */
export type CreateFeedbackSeenReceiptInput = {
  /** Linked feedback ID from `feedback` collection */
  feedbackId: Scalars['ObjectId'],
  /** Linked Lesson Flow ID */
  lessonFlowId?: Maybe<Scalars['ObjectId']>,
  /** Linked Lesson Flow Item ID */
  lessonFlowItemId?: Maybe<Scalars['ObjectId']>,
  /** Feedback type (e.g. NOTE or ERROR) */
  type: FeedbackType,
};

/** Input in creating multiple Feedback Seen Receipts */
export type CreateFeedbackSeenReceiptInputs = {
  /** Array of Feedback seen receipt records */
  items: Array<CreateFeedbackSeenReceiptInput>,
};

export type CreateGradingTemplateInput = {
  name: Scalars['String'],
  sections: Array<GradingTemplateSectionInput>,
};

export type CreateGroupAssignmentInput = {
  academicYearId: Scalars['ObjectId'],
  items?: Maybe<Array<GroupAssignmentItemInput>>,
};

/** Arguments to be used when creating a new handout. */
export type CreateHandoutInput = {
  /** The Academic Week this handout is for. */
  academicWeek: LessonFlowAcademicWeekInput,
  /** Used to determine where the Handout is intended to be sent. */
  channel: HandoutChannel,
  /** The ID of the course this handout is for. */
  courseId: Scalars['ObjectId'],
  /** The enrollment type this handout is for. */
  enrollmentType: EnrollmentType,
  /**  A file to be attached to the course. This is used for handouts that are of type `HandoutType.Answers` */
  file?: Maybe<HandoutFileInput>,
  /** The instructions for the handout. This is formatted in Markdown. */
  instructions?: Maybe<Scalars['String']>,
  /**  This determines who the handout is intended for. */
  type: HandoutType,
  /** The id of the worksheet linked to this handout. */
  worksheetId?: Maybe<Scalars['ObjectId']>,
};

export type CreateHolidayInput = {
  name: Scalars['String'],
  period: DateRangeInput,
  type: HolidayType,
};

export type CreateHomeworkInput = {
  content: Scalars['String'],
  /** The duration of the `homework` in minutes. */
  duration?: Maybe<Scalars['Int']>,
  lessonNumber: Scalars['Int'],
  needSubmit?: Maybe<Scalars['Boolean']>,
  order: Scalars['Int'],
  totalBreakdown?: Maybe<MarkBreakDownInput>,
  totalGrade?: Maybe<Scalars['Int']>,
  workSheetId?: Maybe<Scalars['ObjectId']>,
};

export type CreateInvoiceItemOptionInput = {
  description: Scalars['String'],
  unitAmount: Scalars['Decimal'],
};

export type CreateLessonFlowInput = {
  academicWeek?: Maybe<LessonFlowAcademicWeekInput>,
  academicYearId: Scalars['ObjectId'],
  /** List of Course IDs to apply the LessonFlow to. */
  applyToCourses?: Maybe<Array<Scalars['ObjectId']>>,
  courseId?: Maybe<Scalars['ObjectId']>,
  lessonFlowItemIds?: Maybe<Array<Scalars['ObjectId']>>,
  name: Scalars['String'],
};

export type CreateLessonFlowItemInput = {
  academicWeek: LessonFlowAcademicWeekInput,
  lessonFlowId: Scalars['ObjectId'],
  lessonNumber: Scalars['Int'],
  name?: Maybe<Scalars['String']>,
  order: Scalars['Int'],
};

export type CreateLessonInput = {
  academicWeekNumber?: Maybe<Scalars['Int']>,
  classId: Scalars['ObjectId'],
  lessonEnd: Scalars['DateTime'],
  lessonNotes?: Maybe<Scalars['String']>,
  lessonStart: Scalars['DateTime'],
  teacherId?: Maybe<Scalars['ObjectId']>,
  venueId?: Maybe<Scalars['ObjectId']>,
};

/** Input used to Create lesson sequence of a LessonFlowItem. */
export type CreateLessonSequenceInput = {
  /** Optional description of the lesson sequence item */
  description?: Maybe<Scalars['String']>,
  /** The duration (in minutes) of the lesson sequence item */
  duration: Scalars['Float'],
  /** The linked exercise ID of the lesson sequence item */
  exerciseId?: Maybe<Scalars['String']>,
  /** The ID of the LessonFlowItem to insert the lesson sequence to. */
  lessonFlowItemId: Scalars['ObjectId'],
  /** The zero-based index order of the lesson sequence item in the list */
  order?: Maybe<Scalars['Float']>,
};

export type CreateLogInput = {
  attachment?: Maybe<Scalars['String']>,
  log: Scalars['String'],
  type: LogType,
  userId: Scalars['ObjectId'],
};

export type CreateMailMergeInput = {
  templateId: Scalars['ObjectId'],
};

export type CreateMerchandiseInput = {
  dateIssued?: Maybe<Scalars['DateTime']>,
  distributionChannel?: Maybe<Scalars['String']>,
  item: Scalars['String'],
  remarks?: Maybe<Scalars['String']>,
  status: MerchandiseStatus,
  studentId: Scalars['ObjectId'],
};

export type CreateNoteInput = {
  note: Scalars['String'],
  userId: Scalars['ObjectId'],
};

export type CreateParentInput = {
  address?: Maybe<UserAddressInput>,
  alias: Scalars['String'],
  alternateUserId?: Maybe<Scalars['String']>,
  alternativeEmails?: Maybe<Array<Scalars['String']>>,
  avatarUrl?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  fullName: Scalars['String'],
  gender: Scalars['String'],
  homePhone?: Maybe<TelephoneInput>,
  linkedWhatsappAccountId: Scalars['ObjectId'],
  mobile: TelephoneInput,
  officePhone?: Maybe<TelephoneInput>,
  remarks?: Maybe<Scalars['String']>,
  salutation?: Maybe<Scalars['String']>,
  userId?: Maybe<Scalars['String']>,
  whatsAppName?: Maybe<Scalars['String']>,
};

export type CreateParentOutput = {
   __typename?: 'CreateParentOutput',
  address?: Maybe<UserAddressOutput>,
  alias: Scalars['String'],
  alternateUserId?: Maybe<Scalars['String']>,
  alternativeEmails?: Maybe<Array<Scalars['String']>>,
  avatarUrl?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  fullName: Scalars['String'],
  gender: Scalars['String'],
  homePhone?: Maybe<TelephoneOutput>,
  linkedWhatsappAccountId: Scalars['ObjectId'],
  mobile: TelephoneOutput,
  officePhone?: Maybe<TelephoneOutput>,
  remarks?: Maybe<Scalars['String']>,
  salutation?: Maybe<Scalars['String']>,
  userId?: Maybe<Scalars['String']>,
  whatsAppName?: Maybe<Scalars['String']>,
};

export type CreatePaymentRecordInput = {
  amount: Scalars['Decimal'],
  bankName?: Maybe<Scalars['String']>,
  chequeNumber?: Maybe<Scalars['String']>,
  depositDate?: Maybe<Scalars['DateTime']>,
  invoiceIds: Array<Scalars['ObjectId']>,
  isUseRemainingCredit?: Maybe<Scalars['Boolean']>,
  lastDigits?: Maybe<Scalars['String']>,
  method: PaymentMode,
  /** The date when the payment was made */
  paymentDate?: Maybe<Scalars['DateTime']>,
  paymentProcessor?: Maybe<Scalars['String']>,
  reference?: Maybe<Scalars['String']>,
  remarks?: Maybe<Scalars['String']>,
};

export type CreatePromotionInput = {
  academicLevelIds: Array<Scalars['ObjectId']>,
  promoteToLevelId: Scalars['ObjectId'],
  scheduledDate: Scalars['DateTime'],
  selectedStudentIds?: Maybe<Array<Scalars['ObjectId']>>,
};

export type CreateReferenceDataInput = {
  category: ReferenceDataCategory,
  value: Scalars['String'],
};

export type CreateRefundInput = {
  adminFee?: Maybe<Scalars['Decimal']>,
  amountRefunded: Scalars['Decimal'],
  bankAccountNumber?: Maybe<Scalars['String']>,
  bankName?: Maybe<Scalars['String']>,
  chequeNumber?: Maybe<Scalars['String']>,
  lastDigits?: Maybe<Scalars['String']>,
  parentId: Scalars['ObjectId'],
  payNowNumber?: Maybe<Scalars['String']>,
  paymentProcessor?: Maybe<Scalars['String']>,
  processedDate?: Maybe<Scalars['DateTime']>,
  reason?: Maybe<Scalars['String']>,
  reference?: Maybe<Scalars['String']>,
  refundMethod: PaymentMode,
  refundedDate: Scalars['DateTime'],
};

export type CreateRegistrationInput = {
  classId: Scalars['ObjectId'],
  currentStep: Scalars['Int'],
  dueDate?: Maybe<Scalars['DateTime']>,
  group?: Maybe<EnrollmentGroupType>,
  lessonModes?: Maybe<Array<RegistrationLessonModeCommand>>,
  linkRegistrationId?: Maybe<Scalars['ObjectId']>,
  linkUserRequestId?: Maybe<Scalars['ObjectId']>,
  preferredLearningArrangement?: Maybe<LearningArrangementType>,
  remarks?: Maybe<Scalars['String']>,
  selectedLessons?: Maybe<Array<SelectedLessonInput>>,
  stage: RegistrationStage,
  startDate: Scalars['DateTime'],
  studentId: Scalars['ObjectId'],
};

export type CreateRegularClassCommand = {
  academicYearId: Scalars['ObjectId'],
  classCode: Scalars['String'],
  classFee: StandardCourseFeeInput,
  courseId: Scalars['ObjectId'],
  /** Used to record the original CSV document index for import class creation records. */
  csvIndex?: Maybe<Scalars['Float']>,
  defaultLessonDays: Array<WeekDay>,
  defaultLessonEnd: Scalars['DateTime'],
  defaultLessonStart: Scalars['DateTime'],
  digitalClassAccessCredentials?: Maybe<DigitalClassAccessCredentialsCommand>,
  firstLessonDate: Scalars['DateTime'],
  generateUntil?: Maybe<Scalars['String']>,
  isReplacementLesson?: Maybe<Scalars['Boolean']>,
  isSkipScheduledHoliday?: Maybe<Scalars['Boolean']>,
  lastLessonDate: Scalars['DateTime'],
  learningArrangement: LearningArrangementType,
  maximumClassCapacity?: Maybe<Scalars['Int']>,
  maximumMakeUpCapacity?: Maybe<Scalars['Int']>,
  name: Scalars['String'],
  shorthand: Scalars['String'],
  teacherId?: Maybe<Scalars['ObjectId']>,
  venueId?: Maybe<Scalars['ObjectId']>,
};

export type CreateRegularCourseInput = {
  academicLevelIds: Array<Scalars['ObjectId']>,
  academicStreams?: Maybe<Array<Scalars['String']>>,
  avatarUrl?: Maybe<Scalars['String']>,
  colorCode?: Maybe<Scalars['String']>,
  courseFee: CourseFeeInput,
  courseMaterial?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  disclaimer: Array<Scalars['String']>,
  duration: Scalars['Float'],
  gamePlanLink?: Maybe<Scalars['String']>,
  isRequireFeedback?: Maybe<Scalars['Boolean']>,
  isSkipScheduledHoliday?: Maybe<Scalars['Boolean']>,
  learningResourceUrl?: Maybe<Scalars['String']>,
  makeUpCredits: RegularMakeUpCreditInput,
  name: Scalars['String'],
  nameShort: Scalars['String'],
  subject?: Maybe<Scalars['String']>,
  termsAndConditions: Scalars['String'],
};

export type CreateRemarkInput = {
  /** A string that contains a remark in a formatted string that will be formatted further in the front-end. */
  content: Scalars['String'],
  /** A boolean that signifies the pinned status of the remark. */
  isPinned?: Maybe<Scalars['Boolean']>,
  /** The level type of the remark */
  levelType?: Maybe<RemarkLevelType>,
  /** The ID of a remark this remark is created as a reply for. */
  parentId?: Maybe<Scalars['ObjectId']>,
  /** 
 * The `ObjectId` of the `Entity` to bind this remark to. For example, if this
   * remark is for an attendance, use the id of the attendance record.
 */
  subjectId: Scalars['ObjectId'],
  /** 
 * The type of the `Entity` defined from an enum to bind this remark to. For
   * example, if this remark is for an attendance, use the enum value
   * `RemarkType.Attendance`
 */
  type: RemarkType,
};

export type CreateSchoolInput = {
  alias?: Maybe<Scalars['String']>,
  educationStage: EducationStage,
  isVisible: Scalars['Boolean'],
  name: Scalars['String'],
};

export type CreateSubmissionInput = {
  breakdown?: Maybe<MarkBreakDownInput>,
  classId: Scalars['ObjectId'],
  enrollmentId: Scalars['ObjectId'],
  gradableId: Scalars['ObjectId'],
  grade?: Maybe<Scalars['Int']>,
};

export type CreateTaskChecklistInput = {
  assigneeIds?: Maybe<Array<Scalars['ObjectId']>>,
  items?: Maybe<Array<CreateTaskChecklistItemInput>>,
  name: Scalars['String'],
  taskId?: Maybe<Scalars['ObjectId']>,
};

export type CreateTaskChecklistItemInput = {
  assigneeIds?: Maybe<Array<Scalars['ObjectId']>>,
  checklistId?: Maybe<Scalars['ObjectId']>,
  name: Scalars['String'],
};

export type CreateTaskInput = {
  assigneeIds?: Maybe<Array<Scalars['ObjectId']>>,
  attendanceId?: Maybe<Scalars['ObjectId']>,
  checklists?: Maybe<Array<CreateTaskChecklistInput>>,
  description?: Maybe<Scalars['String']>,
  dueDate?: Maybe<Scalars['DateTime']>,
  isTemplate?: Maybe<Scalars['Boolean']>,
  listId?: Maybe<Scalars['ObjectId']>,
  name?: Maybe<Scalars['String']>,
};

export type CreateTaskListInput = {
  endAt?: Maybe<Scalars['DateTime']>,
  generateAt?: Maybe<Scalars['DateTime']>,
  isAutoGenerate: Scalars['Boolean'],
  name: Scalars['String'],
  startAt?: Maybe<Scalars['DateTime']>,
  weekDay?: Maybe<WeekDay>,
};

export type CreateTemplateInput = {
  content?: Maybe<Scalars['String']>,
  emailMessage?: Maybe<Scalars['String']>,
  status: TemplateStatus,
  subject?: Maybe<Scalars['String']>,
  title: Scalars['String'],
  type: TemplateType,
  usageType: TemplateUsageType,
  whatsAppMessage?: Maybe<Scalars['String']>,
};

export type CreateTopicInput = {
  courseId: Scalars['ObjectId'],
  name: Scalars['String'],
};

export type CreateUserCustomiseColumnInput = {
  columnName: Scalars['String'],
  columnShowName?: Maybe<Scalars['String']>,
  isRequired: Scalars['Boolean'],
  isVisible: Scalars['Boolean'],
  ownerId?: Maybe<Scalars['ObjectId']>,
  tableName: Scalars['String'],
  tableShowName?: Maybe<Scalars['String']>,
  weight: Scalars['Int'],
};

export type CreateVenueCommand = {
  /** Address of the Venue */
  address: AddressInput,
  /** Id of the branch where the venue is located */
  branchId: Scalars['ObjectId'],
  /** Maximum number of students that a venue can hold */
  capacity?: Maybe<Scalars['Int']>,
  googleCalendarHostEmail?: Maybe<Scalars['String']>,
  googleCalendarId?: Maybe<Scalars['String']>,
  /** Name of the Venue, this is the combination of the venue unit and classroom number e.g 02-5A CR1 */
  name: Scalars['String'],
  section: Scalars['String'],
  shorthand: Scalars['String'],
  unitNo?: Maybe<Scalars['String']>,
};

export type CreateWaitingListsForStudentInput = {
  classIds: Array<Scalars['ObjectId']>,
  startDate: Scalars['DateTime'],
  studentId: Scalars['ObjectId'],
};

export type CreateWhatsappAccountInput = {
  name: Scalars['String'],
  number: PhoneNumberInput,
};

export type CreateWorkShopClassCommand = {
  academicYearId: Scalars['ObjectId'],
  classCode: Scalars['String'],
  classFee: StandardCourseFeeInput,
  courseId: Scalars['ObjectId'],
  /** Used to record the original CSV document index for import class creation records. */
  csvIndex?: Maybe<Scalars['Float']>,
  dailySessionTimeSlots: Array<TimeRangeInput>,
  defaultLessonDays: Array<WeekDay>,
  digitalClassAccessCredentials?: Maybe<DigitalClassAccessCredentialsCommand>,
  firstLessonDate: Scalars['DateTime'],
  generateUntil?: Maybe<Scalars['String']>,
  isSkipScheduledHoliday?: Maybe<Scalars['Boolean']>,
  learningArrangement: LearningArrangementType,
  maximumClassCapacity?: Maybe<Scalars['Int']>,
  maximumMakeUpCapacity?: Maybe<Scalars['Int']>,
  name: Scalars['String'],
  numberOfLessons: Scalars['Int'],
  repeat: Scalars['Int'],
  runNumber: Scalars['Int'],
  shorthand: Scalars['String'],
  teacherId?: Maybe<Scalars['ObjectId']>,
  venueId?: Maybe<Scalars['ObjectId']>,
};

export type CreateWorkShopCourseInput = {
  academicLevelIds: Array<Scalars['ObjectId']>,
  academicStreams?: Maybe<Array<Scalars['String']>>,
  avatarUrl?: Maybe<Scalars['String']>,
  colorCode?: Maybe<Scalars['String']>,
  courseFee: CourseFeeInput,
  courseMaterial?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  disclaimer: Array<Scalars['String']>,
  duration: Scalars['Float'],
  gamePlanLink?: Maybe<Scalars['String']>,
  isRequireFeedback?: Maybe<Scalars['Boolean']>,
  isSkipScheduledHoliday?: Maybe<Scalars['Boolean']>,
  learningResourceUrl?: Maybe<Scalars['String']>,
  makeUpCredits: WorkshopMakeUpCreditInput,
  name: Scalars['String'],
  nameShort: Scalars['String'],
  numberOfLessons: Scalars['Int'],
  subject?: Maybe<Scalars['String']>,
  termsAndConditions: Scalars['String'],
};

export type Csv = {
   __typename?: 'Csv',
  url: Scalars['String'],
};


export type DateRange = {
   __typename?: 'DateRange',
  end: Scalars['Date'],
  start: Scalars['Date'],
};

export type DateRangeInput = {
  end: Scalars['Date'],
  start: Scalars['Date'],
};


export type DateTimeRange = {
   __typename?: 'DateTimeRange',
  end: Scalars['DateTime'],
  start: Scalars['DateTime'],
};

export type DateTimeRangeInput = {
  end: Scalars['DateTime'],
  start: Scalars['DateTime'],
};


/** Reward points input to deduct for onr student item */
export type DeductRewardPointsInput = {
  /** Linked gradable (Exercise or Homework) ID if points are from award */
  gradableId?: Maybe<Scalars['ObjectId']>,
  /** Reward order to redeem */
  orderDetail?: Maybe<RewardDetailInput>,
  /** Amount of reward points */
  points: Scalars['Float'],
  /** Other notes associated with the reward transaction */
  remarks: Scalars['String'],
  /** Student ID on where reward points will be added */
  studentId: Scalars['ObjectId'],
  /** Type of reward history */
  type?: Maybe<RewardHistoryType>,
};

/** Input used to Delete a collect item of a LessonFlowItem. */
export type DeleteCollectInput = {
  /** The ID of the collect to delete. */
  collectId: Scalars['ObjectId'],
  /** The ID of the LessonFlowItem containing the collect to find. */
  lessonFlowItemId: Scalars['ObjectId'],
};

/** Input used to Delete lesson sequence of a LessonFlowItem. */
export type DeleteLessonSequenceInput = {
  /** The ID of the `LessonFlowItem` to update. */
  lessonFlowItemId: Scalars['ObjectId'],
  /** The ID of the Lesson sequence ID to remove from the `LessonFlowItem` */
  sequenceId: Scalars['ObjectId'],
};

export type DeleteSubmissionInput = {
  submissionId: Scalars['ObjectId'],
};

export type DeleteTopicInput = {
  topicId: Scalars['ObjectId'],
};

export type DeleteUserCustomiseColumnInput = {
  columnName: Scalars['String'],
  id?: Maybe<Scalars['ObjectId']>,
  ownerId: Scalars['ObjectId'],
  tableName: Scalars['String'],
};

export type Delivery = {
   __typename?: 'Delivery',
  _id: Scalars['ObjectId'],
  address: Scalars['String'],
  admin?: Maybe<SystemUser>,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  dropOffAddress: Scalars['String'],
  dropOffMobile: Telephone,
  dropOffPostalCode: Scalars['String'],
  dropOffRemarks?: Maybe<Scalars['String']>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  materialIds: Array<Scalars['ObjectId']>,
  materials?: Maybe<Array<Material>>,
  parcelSize: DeliveryParcelSize,
  pickupEndAt: Scalars['DateTime'],
  pickupStartAt: Scalars['DateTime'],
  remarks?: Maybe<Scalars['String']>,
  status: DeliveryStatus,
  trackingNo?: Maybe<Scalars['String']>,
  trackingUrl?: Maybe<Scalars['String']>,
  type: DeliveryType,
  userId?: Maybe<Scalars['ObjectId']>,
  waybillUrl?: Maybe<Scalars['String']>,
};

export type DeliveryFilterInput = {
  dateRange?: Maybe<DateTimeRangeInput>,
  name?: Maybe<Scalars['String']>,
  statuses?: Maybe<Array<DeliveryStatus>>,
  types?: Maybe<Array<DeliveryType>>,
};

export type DeliveryIndex = {
   __typename?: 'DeliveryIndex',
  _id: Scalars['ObjectId'],
  address: Scalars['String'],
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  dropOffAddress: Scalars['String'],
  dropOffMobile?: Maybe<Telephone>,
  dropOffPostalCode: Scalars['String'],
  dropOffRemarks?: Maybe<Scalars['String']>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  lastModifyUser?: Maybe<DeliveryUserIndex>,
  materialIds: Array<Scalars['ObjectId']>,
  materials?: Maybe<Array<DeliveryMaterialIndex>>,
  parcelSize: DeliveryParcelSize,
  pickupEndAt: Scalars['DateTime'],
  pickupStartAt: Scalars['DateTime'],
  remarks?: Maybe<Scalars['String']>,
  status: DeliveryStatus,
  students?: Maybe<Array<DeliveryStudentIndex>>,
  trackingNo?: Maybe<Scalars['String']>,
  trackingUrl?: Maybe<Scalars['String']>,
  type: DeliveryType,
  user?: Maybe<DeliveryUserIndex>,
  userId?: Maybe<Scalars['ObjectId']>,
};

export type DeliveryIndexPage = {
   __typename?: 'DeliveryIndexPage',
  items: Array<DeliveryIndex>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

export type DeliveryMaterialIndex = {
   __typename?: 'DeliveryMaterialIndex',
  _id: Scalars['ObjectId'],
  adHocMaterials?: Maybe<Scalars['String']>,
  /** PackingListEnvelope. */
  envelope?: Maybe<MaterialEnvelopeDo>,
};

export enum DeliveryMethod {
  DeliveryByOtherCouriers = 'DeliveryByOtherCouriers',
  HandoverOnsite = 'HandoverOnsite'
}

export enum DeliveryParcelSize {
  Big = 'Big',
  Medium = 'Medium',
  Small = 'Small'
}

export enum DeliveryStatus {
  Completed = 'Completed',
  Delivered = 'Delivered',
  Delivering = 'Delivering',
  Draft = 'Draft',
  Dropped = 'Dropped',
  Exception = 'Exception',
  MissedDelivery = 'MissedDelivery',
  Pending = 'Pending',
  ProcessingHub = 'ProcessingHub'
}

export type DeliveryStudentIndex = {
   __typename?: 'DeliveryStudentIndex',
  _id: Scalars['ObjectId'],
  fullName: Scalars['String'],
};

export enum DeliveryType {
  ParkNParcel = 'ParkNParcel'
}

export type DeliveryUserIndex = {
   __typename?: 'DeliveryUserIndex',
  _id: Scalars['ObjectId'],
  email: Scalars['String'],
  fullName: Scalars['String'],
  mobile?: Maybe<Telephone>,
};

export type Department = {
   __typename?: 'Department',
  _id: Scalars['ObjectId'],
  centre: Centre,
  centreId: Scalars['ObjectId'],
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  isArchived: Scalars['Boolean'],
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  name: Scalars['String'],
  systemId?: Maybe<Scalars['String']>,
};

export type DigitalClassAccessCredentials = {
   __typename?: 'DigitalClassAccessCredentials',
  autoGenerateGoogleMeetingDetails?: Maybe<Scalars['Boolean']>,
  autoGenerateZoomMeetingDetails?: Maybe<Scalars['Boolean']>,
  googleMeetId?: Maybe<Scalars['String']>,
  googleMeetLink?: Maybe<Scalars['String']>,
  zoomMeetingId?: Maybe<Scalars['String']>,
  zoomMeetingLink?: Maybe<Scalars['String']>,
  zoomMeetingPassword?: Maybe<Scalars['String']>,
};

export type DigitalClassAccessCredentialsCommand = {
  autoGenerateGoogleMeetingDetails?: Maybe<Scalars['Boolean']>,
  autoGenerateZoomMeetingDetails?: Maybe<Scalars['Boolean']>,
  googleMeetId?: Maybe<Scalars['String']>,
  googleMeetLink?: Maybe<Scalars['String']>,
  zoomMeetingId?: Maybe<Scalars['String']>,
  zoomMeetingLink?: Maybe<Scalars['String']>,
  zoomMeetingPassword?: Maybe<Scalars['String']>,
};

export type DigitalClassAccessCredentialsDo = {
   __typename?: 'DigitalClassAccessCredentialsDO',
  autoGenerateGoogleMeetingDetails?: Maybe<Scalars['Boolean']>,
  autoGenerateZoomMeetingDetails?: Maybe<Scalars['Boolean']>,
  googleMeetId?: Maybe<Scalars['String']>,
  googleMeetLink?: Maybe<Scalars['String']>,
  zoomMeetingId?: Maybe<Scalars['String']>,
  zoomMeetingLink?: Maybe<Scalars['String']>,
  zoomMeetingPassword?: Maybe<Scalars['String']>,
};

export type DigitalClassAccessCredentialsOutput = {
   __typename?: 'DigitalClassAccessCredentialsOutput',
  autoGenerateGoogleMeetingDetails?: Maybe<Scalars['Boolean']>,
  autoGenerateZoomMeetingDetails?: Maybe<Scalars['Boolean']>,
  googleMeetId?: Maybe<Scalars['String']>,
  googleMeetLink?: Maybe<Scalars['String']>,
  zoomMeetingId?: Maybe<Scalars['String']>,
  zoomMeetingLink?: Maybe<Scalars['String']>,
  zoomMeetingPassword?: Maybe<Scalars['String']>,
};

export type Discount = {
   __typename?: 'Discount',
  _id: Scalars['ObjectId'],
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  discountAmount?: Maybe<Scalars['Decimal']>,
  discountName?: Maybe<Scalars['String']>,
  discountRate?: Maybe<Scalars['Decimal']>,
  discountType?: Maybe<DiscountType>,
  isArchived: Scalars['Boolean'],
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  isVisible: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
};

export enum DiscountType {
  Amount = 'Amount',
  Percentage = 'Percentage'
}

export enum EducationStage {
  Primary = 'Primary',
  Secondary = 'Secondary'
}

export type EligibilityFlow = {
   __typename?: 'EligibilityFlow',
  _id: Scalars['ObjectId'],
  courseId: Scalars['ObjectId'],
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  startNode: FlowNode,
  startNodeJsonString: Scalars['String'],
};

export type EligibleScore = {
   __typename?: 'EligibleScore',
  /** The maximum score. */
  max?: Maybe<Scalars['Float']>,
  /** The minimum score. */
  min?: Maybe<Scalars['Float']>,
};

/** This sets the minimum and maximum score for the student's score to determine eligibility for a reward. */
export type EligibleScoreInput = {
  /** The maximum score. */
  max: Scalars['Int'],
  /** The minimum score. */
  min: Scalars['Int'],
};

export type EmailReminderInput = {
  file?: Maybe<Scalars['Upload']>,
  fileName?: Maybe<Scalars['String']>,
  fileType?: Maybe<Scalars['String']>,
  isIncludeAttachment?: Maybe<Scalars['Boolean']>,
  message: Scalars['String'],
  receiverParentIds?: Maybe<Array<Scalars['ObjectId']>>,
  scheduledDate?: Maybe<Scalars['DateTime']>,
  subject?: Maybe<Scalars['String']>,
  templateId: Scalars['ObjectId'],
};

export type EmailValidation = {
   __typename?: 'EmailValidation',
  valid: Scalars['Boolean'],
  validationError?: Maybe<Scalars['String']>,
};

export type Employee = {
  branches?: Maybe<Array<Branch>>,
  department?: Maybe<Department>,
};

export type Enrollment = {
   __typename?: 'Enrollment',
  _id: Scalars['ObjectId'],
  arrangements?: Maybe<Array<Arrangement>>,
  attendances?: Maybe<Array<Attendance>>,
  class: Class,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  endDate?: Maybe<Scalars['DateTime']>,
  followUpStatus?: Maybe<FollowUpStatus>,
  followUpStatusLastModifiedBy?: Maybe<SystemUser>,
  followUpStatusLastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  group?: Maybe<EnrollmentGroupType>,
  invoiceByLessonId?: Maybe<Invoice>,
  invoices?: Maybe<Array<Invoice>>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  isEndRecurring?: Maybe<Scalars['Boolean']>,
  isFullEnrollment?: Maybe<Scalars['Boolean']>,
  isReserved?: Maybe<Scalars['Boolean']>,
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  preferredLearningArrangement?: Maybe<LearningArrangementType>,
  registration?: Maybe<Registration>,
  reservation?: Maybe<Registration>,
  startDate?: Maybe<Scalars['DateTime']>,
  status: EnrollmentStatus,
  student?: Maybe<Student>,
  /** Enrollment suitability information that tells if student is suitable for registration */
  suitability?: Maybe<EnrollmentSuitability>,
  trialRecord?: Maybe<Array<Registration>>,
  type: EnrollmentType,
};


export type EnrollmentInvoiceByLessonIdArgs = {
  lessonId: Scalars['ObjectId']
};

export enum EnrollmentGroupType {
  A = 'A',
  B = 'B',
  Unassigned = 'Unassigned'
}

export type EnrollmentIndex = {
   __typename?: 'EnrollmentIndex',
  _id: Scalars['ObjectId'],
  attendances?: Maybe<Array<Attendance>>,
  class?: Maybe<Class>,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  endDate?: Maybe<Scalars['DateTime']>,
  followUpStatus?: Maybe<FollowUpStatus>,
  followUpStatusLastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  group?: Maybe<EnrollmentGroupType>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  preferredLearningArrangement?: Maybe<LearningArrangementType>,
  remarks?: Maybe<Array<Remark>>,
  startDate?: Maybe<Scalars['DateTime']>,
  status: EnrollmentStatus,
  student?: Maybe<EnrollmentStudentIndex>,
  /** Enrollment suitability information that tells if student is suitable for registration */
  suitability?: Maybe<EnrollmentSuitability>,
  type: EnrollmentType,
};

export type EnrollmentIndexPage = {
   __typename?: 'EnrollmentIndexPage',
  items: Array<EnrollmentIndex>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

export enum EnrollmentStatus {
  Active = 'Active',
  Cancelled = 'Cancelled',
  Complete = 'Complete',
  Holding = 'Holding'
}

export type EnrollmentStudentIndex = {
   __typename?: 'EnrollmentStudentIndex',
  _id: Scalars['ObjectId'],
  alias: Scalars['String'],
  avatarUrl?: Maybe<Scalars['String']>,
  fullName: Scalars['String'],
  gender?: Maybe<Scalars['String']>,
  userId?: Maybe<Scalars['String']>,
};

/** Suitability class that includes suitability information of student on an `Enrollment` record */
export type EnrollmentSuitability = {
   __typename?: 'EnrollmentSuitability',
  remark?: Maybe<Remark>,
  /** Optional remark entity ID field; Applicable only for suitability status with attached student remarks */
  remarkId?: Maybe<Scalars['ObjectId']>,
  /** Status of suitability of the student's enrollment (either Suitable or pending for contact parent) */
  status?: Maybe<EnrollmentSuitabilityStatus>,
};

export enum EnrollmentSuitabilityStatus {
  Cjco = 'CJCO',
  Cjdo = 'CJDO',
  ContactParent = 'ContactParent',
  Dno = 'DNO',
  Suitable = 'Suitable'
}

export enum EnrollmentType {
  Additional = 'Additional',
  Regular = 'Regular',
  Trial = 'Trial'
}

export type ExamScore = {
   __typename?: 'ExamScore',
  _id: Scalars['ObjectId'],
  academicLevel?: Maybe<AcademicLevel>,
  academicLevelId?: Maybe<Scalars['ObjectId']>,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  examType: Scalars['String'],
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  marksPercentage: Scalars['Float'],
  mcq?: Maybe<ScoreRecord>,
  practical?: Maybe<ScoreRecord>,
  qeq?: Maybe<ScoreRecord>,
  scoreGrade: Scalars['String'],
  stream: Scalars['String'],
  student?: Maybe<Student>,
  studentId?: Maybe<Scalars['ObjectId']>,
  subject: Scalars['String'],
  totalMarks: ScoreRecord,
  year: Scalars['Float'],
};

export type Exercise = {
   __typename?: 'Exercise',
  _id: Scalars['ObjectId'],
  content: Scalars['String'],
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  duration?: Maybe<Scalars['Int']>,
  gradableType?: Maybe<GradableType>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  lessonNumber: Scalars['Int'],
  needSubmit?: Maybe<Scalars['Boolean']>,
  order: Scalars['Int'],
  /** Contains the `status` and `mechanics` of an `Activity` which this case is an `Exercise`. */
  reward?: Maybe<RewardInfo>,
  totalBreakdown?: Maybe<MarkBreakDown>,
  totalGrade?: Maybe<Scalars['Int']>,
  workSheet: WorkSheet,
  workSheetId: Scalars['ObjectId'],
};

export type ExtraReminderVariables = {
  IsPaid?: Maybe<Scalars['Boolean']>,
};

export type FeatureAccess = {
   __typename?: 'FeatureAccess',
  _id: Scalars['ObjectId'],
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  description?: Maybe<Scalars['String']>,
  enabled?: Maybe<Scalars['Boolean']>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  keywords: FeatureAccessKeyword,
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  name: Scalars['String'],
};

export type FeatureAccessExtendInput = {
  enrollmentGroup?: Maybe<EnrollmentGroupType>,
  enrollmentPreferredLearningArrangement?: Maybe<LearningArrangementType>,
};

export enum FeatureAccessKeyword {
  Campaign = 'Campaign',
  Customise = 'Customise',
  Dashboard = 'Dashboard',
  Email = 'Email',
  Exports = 'Exports',
  Filters = 'Filters',
  Gap = 'GAP',
  HybridGrouping = 'HybridGrouping',
  LearningArrangement = 'LearningArrangement',
  Logistics = 'Logistics',
  Promotion = 'Promotion',
  Replacement = 'Replacement',
  Report = 'Report',
  Rewards = 'Rewards',
  WhatsApp = 'WhatsApp'
}

export type Fee = {
   __typename?: 'Fee',
  amount?: Maybe<Scalars['Float']>,
  isApplicable: Scalars['Boolean'],
  name: OtherFeeType,
};

export type Feedback = {
   __typename?: 'Feedback',
  _id: Scalars['ObjectId'],
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  isResolved?: Maybe<Scalars['Boolean']>,
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  /** A singlular LessonFlowItem referred to by the feedback. */
  lessonFlow?: Maybe<LessonFlow>,
  /** Linked Lesson Flow ID */
  lessonFlowId?: Maybe<Scalars['ObjectId']>,
  /** Linked Lesson Flow Item resolved field */
  lessonFlowItem: LessonFlow,
  /** Linked Lesson Flow Item ID */
  lessonFlowItemId?: Maybe<Scalars['ObjectId']>,
  quoted: Scalars['String'],
  reply?: Maybe<Array<FeedbackReply>>,
  /** Derived status field of feedback record (e.g. SEEN, UNSEEN) */
  status?: Maybe<FeedbackSeenStatus>,
  text: Scalars['String'],
  type: FeedbackType,
};

export type FeedbackFilterInput = {
  isResolved?: Maybe<Scalars['Boolean']>,
  lessonFlowId?: Maybe<Scalars['ObjectId']>,
  lessonFlowItemId?: Maybe<Scalars['ObjectId']>,
  type?: Maybe<FeedbackType>,
  /** Check feedback seen receipt records by User ID  */
  userId?: Maybe<Scalars['ObjectId']>,
};

export type FeedbackPage = {
   __typename?: 'FeedbackPage',
  items: Array<Feedback>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

export type FeedbackReply = {
   __typename?: 'FeedbackReply',
  _id: Scalars['ObjectId'],
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  text: Scalars['String'],
};

export type FeedbackSeenReceipt = {
   __typename?: 'FeedbackSeenReceipt',
  _id: Scalars['ObjectId'],
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  feedbackId: Scalars['ObjectId'],
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  lessonFlowItemId: Scalars['ObjectId'],
  type: FeedbackType,
};

export type FeedbackSeenReceiptPage = {
   __typename?: 'FeedbackSeenReceiptPage',
  items: Array<FeedbackSeenReceipt>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

/** Filter input in fetching feedback seen receipt records */
export type FeedbackSeenReceiptsFilterInput = {
  /** Filter by Lesson Flow ID */
  lessonFlowItem?: Maybe<Scalars['ObjectId']>,
  /** Filter by Lesson Flow Item ID */
  lessonFlowItemId?: Maybe<Scalars['ObjectId']>,
  /** Filter by Feedback type (e.g. NOTE or ERROR) */
  type?: Maybe<FeedbackType>,
  /** Filter by User ID */
  userId?: Maybe<Scalars['ObjectId']>,
};

export enum FeedbackSeenStatus {
  Seen = 'SEEN',
  Unseen = 'UNSEEN'
}

export enum FeedbackType {
  Error = 'Error',
  Note = 'Note'
}

/** Result object for count unseen feedback query */
export type FeedbackUnseenCount = {
   __typename?: 'FeedbackUnseenCount',
  /** Total count of unseen feedback records */
  total: Scalars['Float'],
};

export type FeeDo = {
   __typename?: 'FeeDO',
  amount?: Maybe<Scalars['Decimal']>,
  isApplicable: Scalars['Boolean'],
  name: OtherFeeType,
};

export type FlowEnrollmentCheckExpression = {
   __typename?: 'FlowEnrollmentCheckExpression',
  type: FlowEnrollmentComparatorType,
};

export type FlowEnrollmentCheckExpressionInput = {
  type: FlowEnrollmentComparatorType,
};

export enum FlowEnrollmentComparatorType {
  AttendanceBefore = 'AttendanceBefore',
  CurrentlyAttending = 'CurrentlyAttending',
  NeverAttended = 'NeverAttended',
  WillAttend = 'WillAttend'
}

export type FlowLevelCheckExpression = {
   __typename?: 'FlowLevelCheckExpression',
  academicLevelId: Scalars['ObjectId'],
};

export type FlowLevelCheckExpressionInput = {
  academicLevelId: Scalars['ObjectId'],
};

export type FlowNode = {
   __typename?: 'FlowNode',
  enrollmentCheckExpressions?: Maybe<Array<FlowEnrollmentCheckExpression>>,
  failNode?: Maybe<FlowNode>,
  levelCheckExpressions?: Maybe<Array<FlowLevelCheckExpression>>,
  logicalOperator?: Maybe<LogicalOperator>,
  passNode?: Maybe<FlowNode>,
  result?: Maybe<FlowResult>,
  timePeriodExpressions?: Maybe<Array<FlowTimePeriodCheckExpression>>,
  type: FlowNodeType,
};

export type FlowNodeInput = {
  enrollmentCheckExpressions?: Maybe<Array<FlowEnrollmentCheckExpressionInput>>,
  failNode?: Maybe<FlowNodeInput>,
  levelCheckExpressions?: Maybe<Array<FlowLevelCheckExpressionInput>>,
  logicalOperator?: Maybe<LogicalOperator>,
  passNode?: Maybe<FlowNodeInput>,
  result?: Maybe<FlowResultInput>,
  timePeriodExpressions?: Maybe<Array<FlowTimePeriodCheckExpressionInput>>,
  type: FlowNodeType,
};

export enum FlowNodeType {
  Enrollment = 'Enrollment',
  Level = 'Level',
  Result = 'Result',
  TimePeriod = 'TimePeriod'
}

export type FlowResult = {
   __typename?: 'FlowResult',
  message: Scalars['String'],
  outcome: ResultOutcome,
};

export type FlowResultInput = {
  message: Scalars['String'],
  outcome: ResultOutcome,
};

export type FlowTimePeriodCheckExpression = {
   __typename?: 'FlowTimePeriodCheckExpression',
  end: Scalars['DateTime'],
  start: Scalars['DateTime'],
};

export type FlowTimePeriodCheckExpressionInput = {
  end: Scalars['DateTime'],
  start: Scalars['DateTime'],
};

export enum FollowUpStatus {
  Enrolled = 'Enrolled',
  FirstFollowUpSent = 'FirstFollowUpSent',
  FollowUpSent = 'FollowUpSent',
  Pending = 'Pending',
  ScheduleCall = 'ScheduleCall',
  SeatReleased = 'SeatReleased',
  SecondFollowUpSent = 'SecondFollowUpSent'
}

export type GenerateUserIdInput = {
  parentId?: Maybe<Scalars['ObjectId']>,
  phoneNumber?: Maybe<PhoneNumberInput>,
};

export type GetTopicsByCourseIdsInput = {
  /** The IDs of the courses to find topics by. */
  courseIds: Array<Scalars['ObjectId']>,
};

/** Input for `GetTopicsByLessonFlowId` query. */
export type GetTopicsByLessonFlowIdInput = {
  /** The IDs of the lesson flows to find topics by. */
  lessonFlowId: Scalars['ObjectId'],
};

/** Input for `GetWorksheetsByLessonFlowId` query. */
export type GetWorksheetsByLessonFlowIdInput = {
  /** The IDs of the lesson flows to find worksheets by. */
  lessonFlowId: Scalars['ObjectId'],
};

/** Object defining submission statistics by Gradable (e.g. Exercise, Homework) */
export type GradableSubmissionStats = {
   __typename?: 'GradableSubmissionStats',
  /** Object ID of Gradable */
  _id: Scalars['ObjectId'],
  /** Gradable Name */
  gradableName: Scalars['String'],
  /** Gradable Type (e.g. GradableType.EXERCISE) */
  gradableType: Scalars['String'],
  /** Score statistics object containing min, max, and avg scores of the Gradable */
  score: ScoreStats,
};

export type GradableSubmissionStatsInput = {
  /** Filter gradable score statistics by Academic Year ID */
  academicYearId?: Maybe<Scalars['ObjectId']>,
  /** Filter gradable score statistics by Class ID */
  classId?: Maybe<Scalars['ObjectId']>,
  /** Filter gradable score statistics by date range */
  dateTimeRange?: Maybe<DateRangeInput>,
  /** Filter gradable score statistics by Worksheet ID */
  worksheetId?: Maybe<Scalars['ObjectId']>,
};

export enum GradableType {
  Exercise = 'Exercise',
  Homework = 'Homework'
}

export type GradableUnion = Exercise | Homework;

/** The grading template used on the test worksheets. */
export type GradingTemplate = {
   __typename?: 'GradingTemplate',
  _id: Scalars['ObjectId'],
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  isArchived: Scalars['Boolean'],
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  /** This is the name identifier of each template */
  name: Scalars['String'],
  /** Contains the list of templates to be copied to the worksheets model. */
  sections: Array<GradingTemplateSection>,
  /** The overall grade of all the sections */
  totalScore: Scalars['Float'],
};

/** These are the sections being copied to the worksheets model. */
export type GradingTemplateSection = {
   __typename?: 'GradingTemplateSection',
  /** This is the template name of the section on each test */
  name: Scalars['String'],
  /** This is the template total of each section */
  total: Scalars['Float'],
};

export type GradingTemplateSectionInput = {
  name: Scalars['String'],
  total: Scalars['Int'],
};

export type GroupAssignment = {
   __typename?: 'GroupAssignment',
  _id: Scalars['ObjectId'],
  academicYear: AcademicYear,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  items?: Maybe<Array<GroupAssignmentItem>>,
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
};

export type GroupAssignmentItem = {
   __typename?: 'GroupAssignmentItem',
  endDate: Scalars['DateTime'],
  groupA: GroupAssignmentType,
  groupB: GroupAssignmentType,
  startDate: Scalars['DateTime'],
  weekNumber: Scalars['Int'],
};

export type GroupAssignmentItemInput = {
  endDate: Scalars['DateTime'],
  groupA: GroupAssignmentType,
  groupB: GroupAssignmentType,
  startDate: Scalars['DateTime'],
  weekNumber: Scalars['Int'],
};

export enum GroupAssignmentType {
  Digital = 'Digital',
  Onsite = 'Onsite'
}

/** A handout. This is used in Teachers -> Reminders and can be of three different types of `HandoutType`. */
export type Handout = {
   __typename?: 'Handout',
  _id: Scalars['ObjectId'],
  academicWeek: AcademicWeek,
  /** The channel the handout is sent to. */
  channel: HandoutChannel,
  course: Course,
  /** The ID of the course this handout belongs to. */
  courseId: Scalars['ObjectId'],
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  /** The enrollment type the Handout is intended for. Only Regular and Trial is allowed. */
  enrollmentType: EnrollmentType,
  /** A file to be attached to the course. This is used for handouts that are of type `HandoutType.Answers` */
  file?: Maybe<HandoutFile>,
  /** The instructions for the handout. This is formatted in Markdown. */
  instructions?: Maybe<Scalars['String']>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  /** The type of handout. This is either instructions for the handout itself, or instructions to parents. */
  type: HandoutType,
  /** The Reference to the Worksheet this handout is linked to. */
  worksheet?: Maybe<WorkSheet>,
  /** The ID of the Worksheet this handout is linked to. */
  worksheetId: Scalars['ObjectId'],
};

export enum HandoutChannel {
  Portal = 'Portal',
  WhatsApp = 'WhatsApp'
}

/** An uploaded file for a handout. This is used for handouts that are of type `HandoutType.Answers` */
export type HandoutFile = {
   __typename?: 'HandoutFile',
  /** The name of the file. */
  fileName: Scalars['String'],
  /** The URL of the file. */
  url: Scalars['String'],
};

/** An uploaded file for a handout. This is used for handouts that are of type `HandoutType.Answers` */
export type HandoutFileInput = {
  /** The name of the file. */
  fileName: Scalars['String'],
  /** The URL of the file. */
  url: Scalars['String'],
};

/** Arguments to be used when filtering handouts. */
export type HandoutFilterInput = {
  /** The Academic Week to filter the handouts by. This only needs the weekNumber as an input. */
  academicWeek?: Maybe<AcademicWeekInput>,
  channels?: Maybe<Array<HandoutChannel>>,
  /** The IDs of the courses to filter by. If not provided, handouts for all courses will be returned. */
  courseIds?: Maybe<Array<Scalars['ObjectId']>>,
  /** The enrollment types to filter by. */
  enrollmentTypes?: Maybe<Array<EnrollmentType>>,
  /** The handout types to filter by. This determines who the handout is intended for. */
  types?: Maybe<Array<HandoutType>>,
};

export type HandoutPage = {
   __typename?: 'HandoutPage',
  items: Array<Handout>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

export enum HandoutType {
  Answers = 'Answers',
  Course = 'Course',
  Parent = 'Parent'
}

/** Handover Onsite Material. */
export type HandoverOnsiteMaterialCommand = {
  additionalIds?: Maybe<Array<Scalars['ObjectId']>>,
  id: Scalars['ObjectId'],
};

export type Holiday = {
   __typename?: 'Holiday',
  _id: Scalars['ObjectId'],
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  name: Scalars['String'],
  period: DateRange,
  type: HolidayType,
};

export type HolidayFilterInput = {
  types?: Maybe<Array<HolidayType>>,
  year?: Maybe<Scalars['Int']>,
};

export enum HolidayType {
  Public = 'Public',
  School = 'School'
}

export type Homework = {
   __typename?: 'Homework',
  _id: Scalars['ObjectId'],
  content: Scalars['String'],
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  /** The duration of the `homework` in minutes. */
  duration?: Maybe<Scalars['Int']>,
  gradableType?: Maybe<GradableType>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  lessonNumber: Scalars['Int'],
  needSubmit?: Maybe<Scalars['Boolean']>,
  order: Scalars['Int'],
  /** Contains the `status` and `mechanics` of an `Activity` which this case is a `Homework`. */
  reward?: Maybe<RewardInfo>,
  totalBreakdown?: Maybe<MarkBreakDown>,
  totalGrade?: Maybe<Scalars['Int']>,
  workSheet: WorkSheet,
  workSheetId?: Maybe<Scalars['ObjectId']>,
};

export type IdInput = {
  id: Scalars['ObjectId'],
};

/** Third party integration object type */
export type Integration = {
   __typename?: 'Integration',
  _id: Scalars['ObjectId'],
  /** Integration application account id */
  accountId?: Maybe<Scalars['String']>,
  /** Integration application client id */
  clientId?: Maybe<Scalars['String']>,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  isConfigured?: Maybe<Scalars['Boolean']>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  /** Integration application type */
  type: IntegrationType,
};

export enum IntegrationType {
  GoogleCalendar = 'GoogleCalendar',
  Xero = 'Xero',
  ZoomMeeting = 'ZoomMeeting'
}

export type Invoice = {
   __typename?: 'Invoice',
  _id: Scalars['ObjectId'],
  amountPaid?: Maybe<Scalars['Decimal']>,
  arrangement?: Maybe<Arrangement>,
  arrangementBillingCheck?: Maybe<ArrangementBillingCheckResult>,
  billedFor?: Maybe<Student>,
  billedTo?: Maybe<Parent>,
  billingFrequency?: Maybe<BillingFrequency>,
  billingPeriod?: Maybe<Scalars['Int']>,
  billingYear?: Maybe<Scalars['Int']>,
  class?: Maybe<Class>,
  course?: Maybe<Course>,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  destination?: Maybe<Enrollment>,
  dueDate?: Maybe<Scalars['DateTime']>,
  hasTransferred: Scalars['Boolean'],
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  isImported?: Maybe<Scalars['Boolean']>,
  isOnHold?: Maybe<Scalars['Boolean']>,
  isOtherFee?: Maybe<Scalars['Boolean']>,
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  lessonDates?: Maybe<Array<Scalars['DateTime']>>,
  lessons?: Maybe<Array<Lesson>>,
  lineItems?: Maybe<Array<InvoiceLineItem>>,
  nextInvoice?: Maybe<Invoice>,
  nextInvoiceDate?: Maybe<Scalars['DateTime']>,
  onHoldRemarks?: Maybe<Scalars['String']>,
  payments?: Maybe<Array<Payment>>,
  receipts?: Maybe<Array<Receipt>>,
  referenceNumber: Scalars['String'],
  reminders?: Maybe<Array<Reminder>>,
  snapshot?: Maybe<InvoiceSnapshot>,
  status: InvoiceStatus,
  subtotal?: Maybe<Scalars['Decimal']>,
  taxRate?: Maybe<Scalars['Int']>,
  total?: Maybe<Scalars['Decimal']>,
  totalTax?: Maybe<Scalars['Decimal']>,
  xeroInvoices?: Maybe<Array<InvoiceXero>>,
};


export type InvoiceArrangementBillingCheckArgs = {
  lessonId: Scalars['ObjectId']
};


export type InvoiceLineItemsArgs = {
  isFormat?: Maybe<Scalars['Boolean']>
};

export type InvoiceAcademicYearIndex = {
   __typename?: 'InvoiceAcademicYearIndex',
  _id: Scalars['ObjectId'],
  year: Scalars['Int'],
};

export type InvoiceClassIndex = {
   __typename?: 'InvoiceClassIndex',
  _id: Scalars['ObjectId'],
  defaultLessonDays?: Maybe<Array<WeekDay>>,
  name: Scalars['String'],
  teacherId?: Maybe<Scalars['ObjectId']>,
};

export type InvoiceCourseIndex = {
   __typename?: 'InvoiceCourseIndex',
  _id: Scalars['ObjectId'],
  nameShort: Scalars['String'],
};

export type InvoiceFilterInput = {
  billedForStudentIds?: Maybe<Array<Scalars['ObjectId']>>,
  courseIds?: Maybe<Array<Scalars['ObjectId']>>,
  dateRange?: Maybe<DateTimeRangeInput>,
  dueDateRange?: Maybe<DateTimeRangeInput>,
  invoiceIds?: Maybe<Array<Scalars['ObjectId']>>,
  isOtherFee?: Maybe<Scalars['Boolean']>,
  lessonDays?: Maybe<Array<WeekDay>>,
  name?: Maybe<Scalars['String']>,
  parentId?: Maybe<Scalars['ObjectId']>,
  showOnHold?: Maybe<Scalars['Boolean']>,
  status?: Maybe<Array<InvoiceStatus>>,
  teacherIds?: Maybe<Array<Scalars['ObjectId']>>,
  xeroInvoiceDate?: Maybe<XeroInvoiceDateInput>,
};

export type InvoiceIndex = {
   __typename?: 'InvoiceIndex',
  _id: Scalars['ObjectId'],
  academicYear?: Maybe<InvoiceAcademicYearIndex>,
  amountPaid?: Maybe<Scalars['Decimal']>,
  billedToParent?: Maybe<InvoiceParentIndex>,
  billingFrequency?: Maybe<BillingFrequency>,
  billingPeriod?: Maybe<Scalars['Int']>,
  billingYear?: Maybe<Scalars['Int']>,
  class?: Maybe<InvoiceClassIndex>,
  course?: Maybe<InvoiceCourseIndex>,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  dueDate?: Maybe<Scalars['DateTime']>,
  emailStatus?: Maybe<ReminderStatus>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  isImported?: Maybe<Scalars['Boolean']>,
  isOnHold?: Maybe<Scalars['Boolean']>,
  isOtherFee?: Maybe<Scalars['Boolean']>,
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  lessonDates?: Maybe<Array<Scalars['DateTime']>>,
  nextInvoiceDate?: Maybe<Scalars['DateTime']>,
  onHoldRemarks?: Maybe<Scalars['String']>,
  paymentAdviceEmailStatus?: Maybe<ReminderStatus>,
  paymentAdviceWaAttachmentStatus?: Maybe<ReminderStatus>,
  paymentAdviceWaStatus?: Maybe<ReminderStatus>,
  referenceNumber: Scalars['String'],
  secondaryParents?: Maybe<Array<InvoiceParentIndex>>,
  status: InvoiceStatus,
  student?: Maybe<InvoiceStudentIndex>,
  subtotal?: Maybe<Scalars['Decimal']>,
  total?: Maybe<Scalars['Decimal']>,
  totalTax?: Maybe<Scalars['Decimal']>,
  waAttachmentStatus?: Maybe<ReminderStatus>,
  waStatus?: Maybe<ReminderStatus>,
};

export type InvoiceIndexPage = {
   __typename?: 'InvoiceIndexPage',
  items: Array<InvoiceIndex>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

export type InvoiceItemOption = {
   __typename?: 'InvoiceItemOption',
  _id: Scalars['ObjectId'],
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  description: Scalars['String'],
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  unitAmount: Scalars['Decimal'],
};

export type InvoiceLessonSnapshot = {
   __typename?: 'InvoiceLessonSnapshot',
  _id: Scalars['ObjectId'],
  lessonEnd: Scalars['DateTime'],
  lessonStart: Scalars['DateTime'],
};

export type InvoiceLineItem = {
   __typename?: 'InvoiceLineItem',
  _id: Scalars['ObjectId'],
  class?: Maybe<Class>,
  classId?: Maybe<Scalars['ObjectId']>,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  description?: Maybe<Scalars['String']>,
  discountAmount?: Maybe<Scalars['Decimal']>,
  discountId?: Maybe<Scalars['ObjectId']>,
  discountRate?: Maybe<Scalars['Decimal']>,
  discountRemarks?: Maybe<Scalars['String']>,
  discountType?: Maybe<DiscountType>,
  invoice?: Maybe<Invoice>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  isPaid?: Maybe<Scalars['Boolean']>,
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  lessons?: Maybe<Array<InvoiceLineItemLesson>>,
  lineAmount?: Maybe<Scalars['Decimal']>,
  otherFeeType?: Maybe<OtherFeeType>,
  paid?: Maybe<Scalars['Decimal']>,
  quantity?: Maybe<Scalars['Int']>,
  remarks?: Maybe<Scalars['String']>,
  taxRate?: Maybe<Scalars['Int']>,
  unitAmount?: Maybe<Scalars['Decimal']>,
};

export type InvoiceLineItemLesson = {
   __typename?: 'InvoiceLineItemLesson',
  amount?: Maybe<Scalars['Decimal']>,
  isMakeUp?: Maybe<Scalars['Boolean']>,
  isPaid?: Maybe<Scalars['Boolean']>,
  lesson?: Maybe<Lesson>,
  lessonId?: Maybe<Scalars['ObjectId']>,
  paid?: Maybe<Scalars['Decimal']>,
};

export type InvoiceLineItemPage = {
   __typename?: 'InvoiceLineItemPage',
  items: Array<InvoiceLineItem>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

export type InvoicePage = {
   __typename?: 'InvoicePage',
  items: Array<Invoice>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

export type InvoicePaidSnapshot = {
   __typename?: 'InvoicePaidSnapshot',
  beforePaid?: Maybe<Scalars['Decimal']>,
  invoiceId: Scalars['ObjectId'],
  paid?: Maybe<Scalars['Decimal']>,
  snapshot?: Maybe<InvoiceSnapshot>,
};

export type InvoiceParentIndex = {
   __typename?: 'InvoiceParentIndex',
  _id: Scalars['ObjectId'],
  avatarUrl?: Maybe<Scalars['String']>,
  fullName: Scalars['String'],
  gender?: Maybe<Scalars['String']>,
  mobile: Telephone,
  salutation?: Maybe<Scalars['String']>,
};

export type InvoiceReport = {
   __typename?: 'InvoiceReport',
  /** Unique identifier for the record */
  _id: Scalars['ObjectId'],
  classId?: Maybe<Scalars['ObjectId']>,
  courseId?: Maybe<Scalars['ObjectId']>,
  courseName?: Maybe<Scalars['String']>,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  description?: Maybe<Scalars['String']>,
  feeWithoutGST: Scalars['Decimal'],
  fullLessonDate?: Maybe<Array<Scalars['DateTime']>>,
  invoiceDate?: Maybe<Scalars['DateTime']>,
  invoiceId?: Maybe<Scalars['ObjectId']>,
  invoiceTotal: Scalars['Decimal'],
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  isOnHold?: Maybe<Scalars['Boolean']>,
  isVoid?: Maybe<Scalars['Boolean']>,
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  lessonDate?: Maybe<Scalars['DateTime']>,
  lessonId?: Maybe<Scalars['ObjectId']>,
  lineItemFee: Scalars['Decimal'],
  lineItemFeeWithoutGST: Scalars['Decimal'],
  lineItemId?: Maybe<Scalars['ObjectId']>,
  parentFullName?: Maybe<Scalars['String']>,
  parentId?: Maybe<Scalars['ObjectId']>,
  parentUserId?: Maybe<Scalars['String']>,
  referenceNumber: Scalars['String'],
  remarks?: Maybe<Array<Scalars['String']>>,
  studentFullName?: Maybe<Scalars['String']>,
  studentId?: Maybe<Scalars['ObjectId']>,
  studentUserId?: Maybe<Scalars['String']>,
  type: InvoiceReportType,
};

export type InvoiceReportFilterInput = {
  classIds?: Maybe<Array<Scalars['ObjectId']>>,
  invoiceDateRange?: Maybe<DateTimeRangeInput>,
  lessonDateRange?: Maybe<DateTimeRangeInput>,
  name?: Maybe<Scalars['String']>,
  showVoided?: Maybe<Scalars['Boolean']>,
};

export type InvoiceReportPage = {
   __typename?: 'InvoiceReportPage',
  items: Array<InvoiceReport>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

export enum InvoiceReportType {
  CourseFee = 'CourseFee',
  OtherFee = 'OtherFee'
}

export type InvoiceSnapshot = {
   __typename?: 'InvoiceSnapshot',
  lessons?: Maybe<Array<InvoiceLessonSnapshot>>,
  parentFullName: Scalars['String'],
  studentFullName?: Maybe<Scalars['String']>,
};

export enum InvoiceStatus {
  Draft = 'Draft',
  Overdue = 'Overdue',
  Paid = 'Paid',
  Pending = 'Pending',
  Void = 'Void'
}

export type InvoiceStudentIndex = {
   __typename?: 'InvoiceStudentIndex',
  _id: Scalars['ObjectId'],
  alias: Scalars['String'],
  avatarUrl?: Maybe<Scalars['String']>,
  fullName: Scalars['String'],
  gender?: Maybe<Scalars['String']>,
  userId?: Maybe<Scalars['String']>,
};

export type InvoiceXero = {
   __typename?: 'InvoiceXero',
  amount?: Maybe<Scalars['Decimal']>,
  lastSyncedDate?: Maybe<Scalars['DateTime']>,
  month: Scalars['Int'],
  url?: Maybe<Scalars['String']>,
  xeroInvoiceId?: Maybe<Scalars['String']>,
  year: Scalars['Int'],
};

export enum LearningArrangementType {
  Digital = 'Digital',
  Hybrid = 'Hybrid',
  Onsite = 'Onsite'
}

export type Lesson = {
   __typename?: 'Lesson',
  _id: Scalars['ObjectId'],
  absentStudent?: Maybe<Scalars['Int']>,
  academicWeek?: Maybe<AcademicWeek>,
  activeEnrolled?: Maybe<Scalars['Int']>,
  attendance?: Maybe<Array<Attendance>>,
  attendanceSnapshot?: Maybe<Array<AttendanceSnapshot>>,
  capacity?: Maybe<LessonCapacityDto>,
  capacityNumber?: Maybe<LessonCapacityNumberDto>,
  class?: Maybe<Class>,
  classId: Scalars['ObjectId'],
  course: Course,
  courseId: Scalars['ObjectId'],
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  enrollment?: Maybe<Array<Enrollment>>,
  holidayEffectedEnrollments?: Maybe<Array<Enrollment>>,
  holidayIds?: Maybe<Array<Scalars['ObjectId']>>,
  holidays?: Maybe<Array<Holiday>>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  isHoliday?: Maybe<Scalars['Boolean']>,
  isHolidayEffectedAdditionalLesson?: Maybe<Scalars['Boolean']>,
  isOverlappedTeacher?: Maybe<Scalars['Boolean']>,
  isOverlappedVenue?: Maybe<Scalars['Boolean']>,
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  lessonDay: WeekDay,
  lessonEnd: Scalars['DateTime'],
  /** The lesson flow copied from the lesson plan when the class this lesson is assigned to is applied to the lesson plan. */
  lessonFlow: LessonFlowV2,
  lessonNotes?: Maybe<Scalars['String']>,
  lessonNumber: Scalars['Float'],
  lessonStart: Scalars['DateTime'],
  maximumClassCapacity?: Maybe<Scalars['Int']>,
  noStatusStudent?: Maybe<Scalars['Int']>,
  remarks?: Maybe<Scalars['String']>,
  status?: Maybe<LessonStatus>,
  teacher?: Maybe<SystemUser>,
  teacherId?: Maybe<Scalars['ObjectId']>,
  totalStudent?: Maybe<Scalars['Int']>,
  venue?: Maybe<Venue>,
  venueId?: Maybe<Scalars['ObjectId']>,
};


export type LessonAttendanceArgs = {
  status?: Maybe<Array<AttendanceStatus>>,
  type?: Maybe<Array<AttendanceType>>
};


export type LessonAttendanceSnapshotArgs = {
  status?: Maybe<Array<AttendanceStatus>>,
  type?: Maybe<Array<AttendanceType>>
};

export type LessonBranchIndex = {
   __typename?: 'LessonBranchIndex',
  _id: Scalars['ObjectId'],
  alias: Scalars['String'],
  name: Scalars['String'],
};

export type LessonCapacityDto = {
   __typename?: 'LessonCapacityDTO',
  hasRegularAndReplacementSeat?: Maybe<Scalars['Boolean']>,
  regular?: Maybe<Scalars['Int']>,
  remainingRegularSeat?: Maybe<Scalars['Int']>,
  remainingReplacementSeat?: Maybe<Scalars['Int']>,
  replacement?: Maybe<Scalars['Int']>,
  replacing?: Maybe<Scalars['Int']>,
  seats?: Maybe<Array<LessonSeatDto>>,
  used?: Maybe<Scalars['Int']>,
};

export type LessonCapacityNumberDto = {
   __typename?: 'LessonCapacityNumberDTO',
  regular?: Maybe<Scalars['Int']>,
  replacement?: Maybe<Scalars['Int']>,
  used?: Maybe<Scalars['Int']>,
};

export type LessonClassIndex = {
   __typename?: 'LessonClassIndex',
  _id: Scalars['ObjectId'],
  academicYearId: Scalars['ObjectId'],
  learningArrangement?: Maybe<LearningArrangementType>,
  name?: Maybe<Scalars['String']>,
  shorthand?: Maybe<Scalars['String']>,
  teacherId?: Maybe<Scalars['ObjectId']>,
  type?: Maybe<ClassType>,
};

export type LessonCourseIndex = {
   __typename?: 'LessonCourseIndex',
  _id: Scalars['ObjectId'],
  avatarUrl?: Maybe<Scalars['String']>,
  colorCode?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  nameShort: Scalars['String'],
};

export type LessonFilterInput = {
  academicLevelIds?: Maybe<Array<Scalars['ObjectId']>>,
  academicWeek?: Maybe<AcademicWeekInput>,
  academicYearIds?: Maybe<Array<Scalars['ObjectId']>>,
  classIds?: Maybe<Array<Scalars['ObjectId']>>,
  classStatus?: Maybe<Array<ClassStatus>>,
  classTypes?: Maybe<Array<ClassType>>,
  courseIds?: Maybe<Array<Scalars['ObjectId']>>,
  dateRange?: Maybe<DateTimeRangeInput>,
  endTime?: Maybe<Scalars['Time']>,
  holidayIds?: Maybe<Array<Scalars['ObjectId']>>,
  isAdHoc?: Maybe<Scalars['Boolean']>,
  isFull?: Maybe<Scalars['Boolean']>,
  isReplacementSeatFull?: Maybe<Scalars['Boolean']>,
  isSearchClassTeacher?: Maybe<Scalars['Boolean']>,
  learningArrangements?: Maybe<Array<LearningArrangementType>>,
  lessonDays?: Maybe<Array<WeekDay>>,
  lessonNumber?: Maybe<Array<Scalars['Int']>>,
  name?: Maybe<Scalars['String']>,
  showVoided?: Maybe<Scalars['Boolean']>,
  skipHoliday?: Maybe<Scalars['Boolean']>,
  startAndEndTime?: Maybe<TimeRangeInput>,
  startTime?: Maybe<Scalars['Time']>,
  studentId?: Maybe<Scalars['ObjectId']>,
  teacherIds?: Maybe<Array<Scalars['ObjectId']>>,
  venueIds?: Maybe<Array<Scalars['ObjectId']>>,
};

export type LessonFlow = {
   __typename?: 'LessonFlow',
  _id: Scalars['ObjectId'],
  academicYear?: Maybe<AcademicYear>,
  academicYearId: Scalars['ObjectId'],
  appliedClasses?: Maybe<Array<Class>>,
  appliedCourses?: Maybe<Array<Course>>,
  course?: Maybe<Course>,
  /** 
 * The ID of the course assigned to the lesson flow. This will be used by the
   * course to determine it's assigned `lessonFlow`s. Notice: `courseId` will soon
   * be changed to required in the future.
 */
  courseId?: Maybe<Scalars['ObjectId']>,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  isArchived: Scalars['Boolean'],
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  /** Reference of Lesson Flow Items assigned to the Lesson Flow. These contain activities for the Lesson. */
  lessonFlowItemIds?: Maybe<Array<Scalars['ObjectId']>>,
  lessonFlowItems?: Maybe<Array<LessonFlowItem>>,
  name: Scalars['String'],
};

/** The academic week generated based on the Term. */
export type LessonFlowAcademicWeek = {
   __typename?: 'LessonFlowAcademicWeek',
  /** The date of the last day of the week. */
  endDate: Scalars['DateTime'],
  /** The date of the first day of the week. */
  startDate: Scalars['DateTime'],
  /** The week number in an academic year. */
  weekNumber: Scalars['Int'],
};

export type LessonFlowAcademicWeekInput = {
  endDate: Scalars['DateTime'],
  startDate: Scalars['DateTime'],
  weekNumber: Scalars['Int'],
};

/** The payload for homework type `OTHER`. */
export type LessonFlowAddOtherHomeworkInput = {
  /** The custom description which can be any text that the teacher puts. */
  description?: Maybe<Scalars['String']>,
  /** The custom title which can be any text that the teacher puts. */
  title: Scalars['String'],
};

/** The payload for homework type `WORKSHEET`. */
export type LessonFlowAddWorksheetHomeworkInput = {
  /** The description to add for the chosen worksheet to be used as a homework. */
  description?: Maybe<Scalars['String']>,
  /** When true, this worksheet will be considered a trial worksheet. Only used for REGULAR courses. */
  forTrial?: Maybe<Scalars['Boolean']>,
  /** The worksheet to be used as a homework. */
  worksheetId: Scalars['ObjectId'],
};

/** 
 * The entry on the lesson flow containing the break time details. 
 *   This is currently only being used in Schedule.
 */
export type LessonFlowBreakItem = {
   __typename?: 'LessonFlowBreakItem',
  /** The description that the teacher placed for the break time. */
  description?: Maybe<Scalars['String']>,
};

/** Inputs for adding "Break" Item in LessonFlow. */
export type LessonFlowBreakItemInput = {
  /** The description that the teacher placed for the break time. */
  description?: Maybe<Scalars['String']>,
};

/** The object containing the collectable item details by the teachers. */
export type LessonFlowCollectable = {
   __typename?: 'LessonFlowCollectable',
  /** 
 * The manually generated id that will be used 
   *     for reference when performing operations.
 */
  id: Scalars['ObjectId'],
  /** 
 * The object containing the other details. 
   *     This is required if the collectable type is `OTHER`.
 */
  other?: Maybe<LessonFlowOtherItem>,
  /** The different types of collectable items: WORKSHEET, OTHER */
  type: LessonFlowCollectableType,
  /** 
 * The object containing the worksheet details. 
   *     This is required if the collectable type is `WORKSHEET`.
 */
  worksheet?: Maybe<LessonFlowWorksheetItem>,
};

export enum LessonFlowCollectableType {
  Other = 'OTHER',
  Worksheet = 'WORKSHEET'
}

export type LessonFlowFilterInput = {
  /** Filter by one or more academic levels */
  academicLevels?: Maybe<Array<Scalars['ObjectId']>>,
  /** Filter by one or more academic year IDs */
  academicYears?: Maybe<Array<Scalars['ObjectId']>>,
  courseId?: Maybe<Scalars['ObjectId']>,
  /** Filter by one or more course types */
  courseTypes?: Maybe<Array<CourseType>>,
  /** Filter condition if archived items should be returned */
  showArchived?: Maybe<Scalars['Boolean']>,
};

/** The object containing the homework details. */
export type LessonFlowHomework = {
   __typename?: 'LessonFlowHomework',
  /** Reference to the Homework entity. */
  id: Scalars['ObjectId'],
  /** 
 * True if the related packing list envelope is already set to "Printed"/"Packed
   * for Delivery" and this item will not be used for this lesson flow.
 */
  isSkipped?: Maybe<Scalars['Boolean']>,
  /** 
 * The object containing the other details. 
   *     This is required if the homework type is `OTHER`.
 */
  other?: Maybe<LessonFlowOtherItem>,
  /** The different types of homeworks: WORKSHEET, OTHER */
  type: LessonFlowHomeworkType,
  /** 
 * The object containing the worksheet details. 
   *     This is required if the homework type is `WORKSHEET`.
 */
  worksheet?: Maybe<LessonFlowWorksheetItem>,
};

/** The payload for homework type `OTHER`. */
export type LessonFlowHomeworkOtherInput = {
  /** The custom description which can be any text that the teacher puts. */
  description?: Maybe<Scalars['String']>,
  /** The custom title which can be any text that the teacher puts. */
  title: Scalars['String'],
};

export enum LessonFlowHomeworkType {
  Other = 'OTHER',
  Worksheet = 'WORKSHEET'
}

/** The payload for homework type `WORKSHEET`. */
export type LessonFlowHomeworkWorksheetInput = {
  /** The description to set for the chosen worksheet to be used as a homework. */
  description?: Maybe<Scalars['String']>,
  /** When true, this worksheet will be considered a trial worksheet. Only used for REGULAR courses. */
  forTrial?: Maybe<Scalars['Boolean']>,
  /** The worksheet to be used as a homework. */
  worksheetId: Scalars['ObjectId'],
};

export type LessonFlowItem = {
   __typename?: 'LessonFlowItem',
  _id: Scalars['ObjectId'],
  academicWeek?: Maybe<AcademicWeek>,
  /** 
 * @deprecated Keeping collect to ensure the frontend in admin does not break.
   * Currently unsure wether this would be an issue since the split happened before
   * this has been implemented.
 */
  collect?: Maybe<Scalars['String']>,
  /** An array of strings that are received from a markdown input from a Rich Text Editor */
  collects?: Maybe<Array<Collect>>,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  exceptedClassIds: Array<Scalars['ObjectId']>,
  exceptions?: Maybe<Array<Class>>,
  exercises?: Maybe<Array<Exercise>>,
  feedbacks?: Maybe<Array<Feedback>>,
  homeworks?: Maybe<Array<Homework>>,
  isArchived: Scalars['Boolean'],
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  lessonFlowId: Scalars['ObjectId'],
  lessonNumber: Scalars['Int'],
  name: Scalars['String'],
  order: Scalars['Int'],
  /** List of lesson sequence items to represent schedule of Exercises and breaks */
  sequence?: Maybe<Array<SequenceItem>>,
  suitability?: Maybe<Suitability>,
  version: Scalars['Int'],
};

export type LessonFlowItemPage = {
   __typename?: 'LessonFlowItemPage',
  items: Array<LessonFlowItem>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

/** 
 * Used as a flexible entry on the lesson flow. 
 *   Currently being used in Schedule, Homework, and Collect.
 */
export type LessonFlowOtherItem = {
   __typename?: 'LessonFlowOtherItem',
  /** The custom description which can be any text that the teacher puts. */
  description?: Maybe<Scalars['String']>,
  /** The custom title which can be any text that the teacher puts. */
  title: Scalars['String'],
};

/** Inputs for adding "Other" Item in LessonFlow. */
export type LessonFlowOtherItemInput = {
  /** The custom description which can be any text that the teacher puts. */
  description?: Maybe<Scalars['String']>,
  /** The custom title which can be any text that the teacher puts. */
  title: Scalars['String'],
};

export type LessonFlowPage = {
   __typename?: 'LessonFlowPage',
  items: Array<LessonFlow>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

/** The schedule template of a lesson. */
export type LessonFlowSchedule = {
   __typename?: 'LessonFlowSchedule',
  /** 
 * The object containing the break time details. 
   *     This is required if the schedule type is `BREAK`.
 */
  break?: Maybe<LessonFlowBreakItem>,
  /** 
 * The manually generated id that will be used 
   *     for reference when performing operations.
 */
  id: Scalars['ObjectId'],
  /** 
 * This can be set to true if the worksheet is already included in a packing list
   * envelope that is already printed/packed for delivery to indicate that it won't
   * be used anymore in a class.
 */
  isSkipped?: Maybe<Scalars['Boolean']>,
  /** The object containing the other details. This is required if the schedule type is `OTHER`. */
  other?: Maybe<LessonFlowOtherItem>,
  /** 
 * The object containing the test details. This is required if the schedule type is `TEST`.
   *     When added to the lesson flow schedule, the corresponding worksheet must also be added to the collectable list
 */
  test?: Maybe<LessonFlowTestItem>,
  /** 
 * The lesson flow schedule type. 
   *     Used for identifying the item added to the schedule in the lesson flow.
 */
  type: LessonFlowScheduleType,
  /** The object containing the worksheet details. This is required if the schedule type is `WORKSHEET`. */
  worksheet?: Maybe<LessonFlowWorksheetItem>,
};

/** The object containing the lesson flow schedule ids and the order in which they are saved. */
export type LessonFlowSchedulePositionInput = {
  /** The lesson flow schedule to be reordered. */
  lessonFlowScheduleId: Scalars['ObjectId'],
  /** 
 * The new position to save the lesson flow schedule. 
   *     The value passed is the ordinal position rather than the index.
   *     Ex. position=2 instead of position=1
   *     to put the id as the second element of the array.
 */
  position: Scalars['Int'],
};

export enum LessonFlowScheduleType {
  Break = 'BREAK',
  Other = 'OTHER',
  Test = 'TEST',
  Worksheet = 'WORKSHEET'
}

/** 
 * The entry on the lesson flow containing the test details. 
 *   This is currently only being used in Schedule.
 */
export type LessonFlowTestItem = {
   __typename?: 'LessonFlowTestItem',
  /** 
 * The description that the teacher placed for the test. 
   *     (not copied from the WorkSheet entity).
 */
  description?: Maybe<Scalars['String']>,
  /** When true, this test (worksheet) will be considered a trial worksheet. Only used for REGULAR courses. */
  forTrial?: Maybe<Scalars['Boolean']>,
  /** The test name copied from the WorkSheet entity. */
  testName: Scalars['String'],
  /** Reference to the WorkSheet entity. */
  worksheetId: Scalars['ObjectId'],
  /** The title copied from the WorkSheet entity. */
  worksheetTitle: Scalars['String'],
};

/** Inputs for adding "Test" Item in LessonFlow. */
export type LessonFlowTestItemInput = {
  /** 
 * The description that the teacher placed for the test. 
   *     (not copied from the WorkSheet entity).
 */
  description?: Maybe<Scalars['String']>,
  /** When true, this test (worksheet) will be considered a trial worksheet. Only used for REGULAR courses. */
  forTrial?: Maybe<Scalars['Boolean']>,
  /** Reference to the WorkSheet entity. */
  worksheetId: Scalars['ObjectId'],
};

/** This describes the payload of the lesson flow update notification */
export type LessonFlowUpdate = {
   __typename?: 'LessonFlowUpdate',
  /** The date and time that the lesson flow was modified */
  dateModified: Scalars['DateTime'],
  /** The supervisor who modified the lesson flow */
  modifiedBy: SystemUser,
  /** Type of the lesson flow update */
  type: LessonFlowUpdateType,
};

export enum LessonFlowUpdateType {
  Collect = 'COLLECT',
  Homework = 'HOMEWORK',
  LessonFlowItem = 'LESSON_FLOW_ITEM',
  Sequence = 'SEQUENCE'
}

/** The lesson flow for an academic week. */
export type LessonFlowV2 = {
   __typename?: 'LessonFlowV2',
  /** 
 * The academic week when this lesson flow is to be used.
   *     This is only available when the associated course for this lesson plan is of type REGULAR.
 */
  academicWeek?: Maybe<LessonFlowAcademicWeek>,
  /** 
 * The materials that the teacher will collect 
   *     from the students for this lesson flow.
 */
  collectables: Array<LessonFlowCollectable>,
  /** 
 * The homeworks to be used within this lesson flow 
   *     for the academic week.
 */
  homeworks: Array<LessonFlowHomework>,
  /** 
 * The manually generated id that will be used 
   *     for reference by other entities such as the Lesson entity.
 */
  id: Scalars['ObjectId'],
  /** The lesson number assigned to this lesson flow. Starts at `1`. */
  lessonNo: Scalars['Int'],
  /** The sequence of lessons to be executed on the lesson flow. */
  schedules: Array<LessonFlowSchedule>,
  /** 
 * The term period number. An academic term is a portion of an academic year,
   *     the time during which an educational institution holds classes.
   *     This is only available when the associated course for this lesson plan is of type REGULAR.
   *     Starts at `1`.
   *     Ex: `1 > 2 > 3`
 */
  termNo?: Maybe<Scalars['Int']>,
  /** 
 * Variations of this lesson flow 
   *     which can be applied to a specific class for a special reason.
   *     Note that a lesson flow can have multiple variants.
 */
  variants: Array<LessonFlowVariant>,
};

/** 
 * A special kind of lesson flow that is 
 *   applied to specific classes for a special reason.
 */
export type LessonFlowVariant = {
   __typename?: 'LessonFlowVariant',
  /** 
 * The academic week when this lesson flow is to be used.
   *     This is only available when the associated course for this lesson plan is of type REGULAR.
 */
  academicWeek?: Maybe<LessonFlowAcademicWeek>,
  /** Reference to the class the variant is applied to. */
  classesApplied: Array<LessonPlanClass>,
  /** 
 * The materials that the teacher will collect 
   *     from the students for this lesson flow.
 */
  collectables: Array<LessonFlowCollectable>,
  /** 
 * The homeworks to be used within this lesson flow 
   *     for the academic week.
 */
  homeworks: Array<LessonFlowHomework>,
  /** 
 * The manually generated id that will be used 
   *     for reference by other entities such as the Lesson entity.
 */
  id: Scalars['ObjectId'],
  /** The lesson number assigned to this lesson flow. Starts at `1`. */
  lessonNo: Scalars['Int'],
  /** The sequence of lessons to be executed on the lesson flow. */
  schedules: Array<LessonFlowSchedule>,
  /** 
 * The term period number. An academic term is a portion of an academic year,
   *     the time during which an educational institution holds classes.
   *     This is only available when the associated course for this lesson plan is of type REGULAR.
   *     Starts at `1`.
   *     Ex: `1 > 2 > 3`
 */
  termNo?: Maybe<Scalars['Int']>,
};

/** The base item on the lesson flow containing the worksheet details. */
export type LessonFlowWorksheetBaseItem = {
   __typename?: 'LessonFlowWorksheetBaseItem',
  /** When true, this worksheet will be considered a trial worksheet. Only used for REGULAR courses. */
  forTrial?: Maybe<Scalars['Boolean']>,
  /** The title copied from the WorkSheet entity. */
  title: Scalars['String'],
  /** Reference to the WorkSheet entity. */
  worksheetId: Scalars['ObjectId'],
};

/** 
 * The entry on the lesson flow containing the worksheet details. 
 *   Currently used in Schedule, Homework, and Collect.
 */
export type LessonFlowWorksheetItem = {
   __typename?: 'LessonFlowWorksheetItem',
  /** 
 * The description that the teacher placed for the referenced WorkSheet. 
   *     (not copied from the WorkSheet entity).
 */
  description?: Maybe<Scalars['String']>,
  /** When true, this worksheet will be considered a trial worksheet. Only used for REGULAR courses. */
  forTrial?: Maybe<Scalars['Boolean']>,
  /** The title copied from the WorkSheet entity. */
  title: Scalars['String'],
  /** Reference to the topic of the WorkSheet. Taken from the WorkSheet entity. */
  topicId: Scalars['ObjectId'],
  /** Reference to the WorkSheet entity. */
  worksheetId: Scalars['ObjectId'],
};

/** Inputs for adding "WorkSheet" Item in LessonFlow. */
export type LessonFlowWorkSheetItemInput = {
  /** 
 * The description that the teacher placed for the referenced WorkSheet. 
   *     (not copied from the WorkSheet entity).
 */
  description?: Maybe<Scalars['String']>,
  /** When true, this worksheet will be considered a trial worksheet. Only used for REGULAR courses. */
  forTrial?: Maybe<Scalars['Boolean']>,
  /** Reference to the WorkSheet entity. */
  worksheetId: Scalars['ObjectId'],
};

export type LessonIndex = {
   __typename?: 'LessonIndex',
  _id: Scalars['ObjectId'],
  absentStudents?: Maybe<Scalars['Int']>,
  academicWeek?: Maybe<AcademicWeek>,
  activeEnrolled?: Maybe<Scalars['Int']>,
  attending?: Maybe<Scalars['Int']>,
  branch?: Maybe<LessonBranchIndex>,
  class?: Maybe<LessonClassIndex>,
  classId: Scalars['ObjectId'],
  classTeacher?: Maybe<LessonTeacherIndex>,
  course?: Maybe<LessonCourseIndex>,
  courseId: Scalars['ObjectId'],
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  isHoliday?: Maybe<Scalars['Boolean']>,
  lastModifiedBy?: Maybe<SystemUser>,
  lastModifiedByFullName?: Maybe<Scalars['String']>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  lessonDay: WeekDay,
  lessonEnd: Scalars['DateTime'],
  lessonNumber: Scalars['Float'],
  lessonStart: Scalars['DateTime'],
  noStatusStudents?: Maybe<Scalars['Int']>,
  present?: Maybe<Scalars['Int']>,
  remarks?: Maybe<Scalars['String']>,
  status?: Maybe<LessonStatus>,
  teacher?: Maybe<LessonTeacherIndex>,
  totalStudents?: Maybe<Scalars['Int']>,
  venue?: Maybe<LessonVenueIndex>,
};

export type LessonIndexPage = {
   __typename?: 'LessonIndexPage',
  items: Array<LessonIndex>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

export type LessonPage = {
   __typename?: 'LessonPage',
  items: Array<Lesson>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

/** The yearly lesson plan of the teachers for a course. */
export type LessonPlan = {
   __typename?: 'LessonPlan',
  /** Unique identifier of the record. */
  _id: Scalars['ObjectId'],
  /** References the academic year record which this lesson plan is applied to. */
  academicYear: LessonPlanAcademicYear,
  /** Reference to the classes applied to this lesson plan. */
  classesApplied: Array<LessonPlanClass>,
  /** Reference to the source lesson plan that this is duplicated from. */
  copiedFrom: ParentLessonPlan,
  /** References the course entity which this lesson plan is applied. */
  course: LessonPlanCourse,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  /** The flag that marks if the lesson plan is archived or not. */
  isArchived: Scalars['Boolean'],
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  /** The set of lesson flow to be used within the term. */
  lessonFlows: Array<LessonFlowV2>,
  /** The name to display for the lesson plan. */
  name: Scalars['String'],
  /** The number of lesson flows in the lesson plan. */
  noOfLessonFlows: Scalars['Int'],
};

/** 
 * The object containing the necessary fields used in the lesson plan 
 *   that is copied from the AcademicLevel entity.
 */
export type LessonPlanAcademicLevel = {
   __typename?: 'LessonPlanAcademicLevel',
  /** 
 * The education stage copied from the AcademicLevel entity. 
   *     This is used for filtering lesson plans to be displayed in FE.
 */
  educationStage: EducationStage,
  /** Reference to the AcademicLevel entity. */
  id: Scalars['ObjectId'],
  /** The name copied from the AcademicLevel entity. */
  name: Scalars['String'],
};

/** 
 * The object containing the necessary fields used in the lesson plan 
 *   that is copied from the AcademicYear entity.
 */
export type LessonPlanAcademicYear = {
   __typename?: 'LessonPlanAcademicYear',
  /** Reference to the AcademicYear entity. */
  id: Scalars['ObjectId'],
  /** The year copied from the AcademicYear entity. */
  year: Scalars['Int'],
};

/** Input for `lessonPlanAddLessonFlowHomework` mutation. */
export type LessonPlanAddLessonFlowHomeworkInput = {
  /** The type of homework to add to the lesson flow. */
  homeworkType: LessonFlowHomeworkType,
  /** The lesson flow to add the homework. */
  lessonFlowId: Scalars['ObjectId'],
  /** The lesson plan containing the lesson flow to add the homework. */
  lessonPlanId: Scalars['ObjectId'],
  /** 
 * The payload for homework type `OTHER`.
   *     This field is required when the `homeworkType` is `OTHER`
 */
  other?: Maybe<LessonFlowAddOtherHomeworkInput>,
  /** 
 * The payload for homework type `WORKSHEET`.
   *     This field is required when the `homeworkType` is `WORKSHEET`
 */
  worksheet?: Maybe<LessonFlowAddWorksheetHomeworkInput>,
};

/** 
 * The object containing the necessary fields used in the lesson plan 
 *   that is copied from the Class entity.
 */
export type LessonPlanClass = {
   __typename?: 'LessonPlanClass',
  /** The date this class was applied. */
  appliedDate: Scalars['DateTime'],
  /** Reference to the class entity. */
  id: Scalars['ObjectId'],
  /** The name copied from the Class entity. */
  name: Scalars['String'],
};

/** 
 * The object containing the necessary fields used in the lesson plan 
 *   that is copied from the Course entity.
 */
export type LessonPlanCourse = {
   __typename?: 'LessonPlanCourse',
  /** The academic levels copied from the Course entity. */
  academicLevels: Array<LessonPlanAcademicLevel>,
  /** Reference to the Course entity. */
  id: Scalars['ObjectId'],
  /** The link to the image of the course copied from the Course entity. */
  imageUrl?: Maybe<Scalars['String']>,
  /** The course name copied from the Course entity. */
  name: Scalars['String'],
  /** 
 * The course type copied from the Course entity. 
   *     This is used to determine if the lesson plan is `REGULAR` or `WORKSHOP`.
 */
  type: CourseType,
};

export type LessonPlanPage = {
   __typename?: 'LessonPlanPage',
  items: Array<LessonPlan>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

/** Input for `lessonPlanRemoveLessonFlowHomework` mutation. */
export type LessonPlanRemoveLessonFlowHomeworkInput = {
  /** The homework to remove. */
  homeworkId: Scalars['ObjectId'],
  /** The lesson flow containing the homework to remove. */
  lessonFlowId: Scalars['ObjectId'],
  /** The lesson plan containing the lesson flow of the homework to remove. */
  lessonPlanId: Scalars['ObjectId'],
};

/** Input for `lessonPlanReorderLessonFlowSchedules` mutation. */
export type LessonPlanReorderLessonFlowSchedulesInput = {
  /** The lesson flow containing the schedules to reorder. */
  lessonFlowId: Scalars['ObjectId'],
  /** The lesson plan containing the lesson flow schedules to reorder. */
  lessonPlanId: Scalars['ObjectId'],
  /** The new schedule positions of the lesson flow. */
  schedulePositions: Array<LessonFlowSchedulePositionInput>,
};

/** Object for filtering the list of lesson plans. */
export type LessonPlansFilterInput = {
  /** Array of academic level Ids that the lesson plans should belong to. */
  academicLevelIds?: Maybe<Array<Scalars['ObjectId']>>,
  /** Array of academic year Ids that the lesson plans should belong to. */
  academicYearIds?: Maybe<Array<Scalars['ObjectId']>>,
  /** The reference to the course entity which this lesson plan is applied. */
  courseIds?: Maybe<Array<Scalars['ObjectId']>>,
  /** Array of courses that the lesson plans should belong to. */
  courseTypes?: Maybe<Array<CourseType>>,
  /** The name pattern to filter the lesson plan */
  name?: Maybe<Scalars['String']>,
  /** Filter condition if archived items should be returned */
  showArchived?: Maybe<Scalars['Boolean']>,
};

/** Input for `LessonPlanSkipLessonFlowItem` mutation. */
export type LessonPlanSkipLessonFlowItemInput = {
  /** The homework item Id of the Lesson Flow Item to be updated. Required if scheduleId is not provided. */
  homeworkId?: Maybe<Scalars['ObjectId']>,
  /** The new `isSkipped` value of the lesson flow schedule/homework item. */
  isSkipped: Scalars['Boolean'],
  /** The lesson flow Id of the lesson flow item to update. */
  lessonFlowId: Scalars['ObjectId'],
  /** The lesson plan containing the lesson flow of the schedule or homework to update. */
  lessonPlanId: Scalars['ObjectId'],
  /** The schedule item Id of the Lesson Flow Item to be updated. Required if homeworkId is not provided. */
  scheduleId?: Maybe<Scalars['ObjectId']>,
};

/** Input for `lessonPlanUpdateLessonFlowHomework` mutation. */
export type LessonPlanUpdateLessonFlowHomeworkInput = {
  /** The homework to update. */
  homeworkId: Scalars['ObjectId'],
  /** The lesson flow containing the homework to update. */
  lessonFlowId: Scalars['ObjectId'],
  /** The lesson plan containing the lesson flow of the homework to update. */
  lessonPlanId: Scalars['ObjectId'],
  /** 
 * The payload for homework type `OTHER`.
   *     This field is required when the homework to update is `OTHER`
 */
  other?: Maybe<LessonFlowHomeworkOtherInput>,
  /** 
 * The payload for homework type `WORKSHEET`.
   *     This field is required when the homework to update is `WORKSHEET`
 */
  worksheet?: Maybe<LessonFlowHomeworkWorksheetInput>,
};

/** Input for `lessonPlanUpdateLessonFlowItemTrialFlag` mutation. */
export type LessonPlanUpdateLessonFlowItemTrialFlagInput = {
  /** The new `forTrial` value of the lesson flow item. */
  forTrial: Scalars['Boolean'],
  /** The lesson flow Id of the lesson flow item to update. */
  lessonFlowId: Scalars['ObjectId'],
  /** The lesson plan containing the lesson flow of the schedule or homework to update. */
  lessonPlanId: Scalars['ObjectId'],
  /** 
 * The worksheet Id of the Lesson Flow Item to be updated.
   *     This could be the LessonFlowScheduleItem (worksheet or test) or the LessonFlowHomeworkItem (worksheet).
 */
  worksheetId: Scalars['ObjectId'],
};

/** Input for `LessonPlanWorksheets` query. */
export type LessonPlanWorksheetsInput = {
  /** Packing List Envelope we're getting the worksheets for. */
  packingListEnvelopeId: Scalars['ObjectId'],
  /** Packing List that contains the envelope we're getting the worksheets for. */
  packingListId: Scalars['ObjectId'],
  /** True if we're retrieving worksheets that are not part of the provided packing list envelope. */
  ungrouped?: Maybe<Scalars['Boolean']>,
};

export type LessonSeatDto = {
   __typename?: 'LessonSeatDTO',
  attendanceId?: Maybe<Scalars['String']>,
  attendanceStatus?: Maybe<AttendanceStatus>,
  /** date string */
  endDate?: Maybe<Scalars['String']>,
  hasPaid?: Maybe<Scalars['Boolean']>,
  isEnrollmentStarted?: Maybe<Scalars['Boolean']>,
  isSimulation?: Maybe<Scalars['Boolean']>,
  seatType?: Maybe<SeatType>,
  /** date string */
  startDate?: Maybe<Scalars['String']>,
  student?: Maybe<Student>,
  type?: Maybe<CapacitySeatType>,
};

export enum LessonStatus {
  Created = 'Created',
  Incomplete = 'Incomplete',
  Void = 'Void'
}

export type LessonTeacherIndex = {
   __typename?: 'LessonTeacherIndex',
  _id: Scalars['ObjectId'],
  avatarUrl?: Maybe<Scalars['String']>,
  fullName: Scalars['String'],
  gender?: Maybe<Scalars['String']>,
  initials?: Maybe<Scalars['String']>,
  salutation?: Maybe<Scalars['String']>,
};

export type LessonVenueIndex = {
   __typename?: 'LessonVenueIndex',
  _id: Scalars['ObjectId'],
  capacity?: Maybe<Scalars['Int']>,
  shorthand: Scalars['String'],
  unitNo?: Maybe<Scalars['String']>,
};

export type LinkParentInput = {
  parentId1: Scalars['ObjectId'],
  parentId2: Scalars['ObjectId'],
};

/** Query input on fetching students award points details */
export type ListStudentAwardPointsInput = {
  /** Filter students by enrolled class ID */
  classId?: Maybe<Scalars['ObjectId']>,
  /** Filter students by linked gradable Exercise or Homework */
  gradableId: Scalars['ObjectId'],
  /** Filter students by name */
  search?: Maybe<Scalars['String']>,
};

export type Log = {
   __typename?: 'Log',
  _id: Scalars['ObjectId'],
  attachment?: Maybe<Scalars['String']>,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  log: Scalars['String'],
  type: LogType,
  userId: Scalars['ObjectId'],
};

export enum LogicalOperator {
  And = 'And',
  Or = 'Or'
}

export type LogPage = {
   __typename?: 'LogPage',
  items: Array<Log>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

export enum LogType {
  Other = 'Other',
  PhoneCall = 'PhoneCall',
  WalkIn = 'WalkIn'
}

export type MailMerge = {
   __typename?: 'MailMerge',
  _id: Scalars['ObjectId'],
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  mergeList?: Maybe<Array<Scalars['Object']>>,
  mergeListHeaders?: Maybe<Array<Scalars['String']>>,
  sender?: Maybe<WhatsappAccount>,
  status: MailMergeStatus,
  template: Template,
};

export enum MailMergeStatus {
  Complete = 'Complete',
  Draft = 'Draft'
}

export type MakeUpCreditInput = {
  term1?: Maybe<Scalars['Int']>,
  term2?: Maybe<Scalars['Int']>,
  term3?: Maybe<Scalars['Int']>,
  term4?: Maybe<Scalars['Int']>,
  workshop?: Maybe<Scalars['Int']>,
};

export type MakeUpCreditOutput = {
   __typename?: 'MakeUpCreditOutput',
  term1?: Maybe<Scalars['Int']>,
  term2?: Maybe<Scalars['Int']>,
  term3?: Maybe<Scalars['Int']>,
  term4?: Maybe<Scalars['Int']>,
  workshop?: Maybe<Scalars['Int']>,
};

export type MakeUpCredits = {
   __typename?: 'MakeUpCredits',
  term1?: Maybe<Scalars['Int']>,
  term2?: Maybe<Scalars['Int']>,
  term3?: Maybe<Scalars['Int']>,
  term4?: Maybe<Scalars['Int']>,
  workshop?: Maybe<Scalars['Int']>,
};

export type MarkBreakDown = {
   __typename?: 'MarkBreakDown',
  ecq?: Maybe<Scalars['Float']>,
  mcq?: Maybe<Scalars['Float']>,
};

export type MarkBreakDownInput = {
  ecq?: Maybe<Scalars['Int']>,
  mcq?: Maybe<Scalars['Int']>,
};

/** Teaching Material information for student. */
export type Material = {
   __typename?: 'Material',
  _id: Scalars['ObjectId'],
  adHocMaterials?: Maybe<Scalars['String']>,
  address?: Maybe<UserAddress>,
  attendance?: Maybe<Attendance>,
  attendanceId?: Maybe<Scalars['ObjectId']>,
  attendances?: Maybe<Array<Attendance>>,
  courseId?: Maybe<Scalars['ObjectId']>,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  enrollmentEndAt?: Maybe<Scalars['DateTime']>,
  enrollmentIds?: Maybe<Array<Scalars['ObjectId']>>,
  enrollmentStartAt?: Maybe<Scalars['DateTime']>,
  /** MaterialEnvelop. */
  envelope?: Maybe<MaterialEnvelope>,
  /** If user select materials and additional materials, will group these materials together. */
  handoverOnsiteMaterialId?: Maybe<Scalars['ObjectId']>,
  isAdHoc?: Maybe<Scalars['Boolean']>,
  isCollectFromReception?: Maybe<Scalars['Boolean']>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  remarks?: Maybe<Scalars['String']>,
  status?: Maybe<MaterialStatus>,
  student?: Maybe<Student>,
  studentId: Scalars['ObjectId'],
};

/** Academic level index for material. */
export type MaterialAcademicLevelIndex = {
   __typename?: 'MaterialAcademicLevelIndex',
  _id: Scalars['ObjectId'],
  alias: Scalars['String'],
};

export type MaterialBookletTopic = {
   __typename?: 'MaterialBookletTopic',
  _id: Scalars['ObjectId'],
  name: Scalars['String'],
};

export type MaterialBookletTopicDo = {
   __typename?: 'MaterialBookletTopicDO',
  _id: Scalars['ObjectId'],
  name: Scalars['String'],
};

/** Class index for material. */
export type MaterialClassIndex = {
   __typename?: 'MaterialClassIndex',
  _id: Scalars['ObjectId'],
  shorthand?: Maybe<Scalars['String']>,
};

/** Course index for material. */
export type MaterialCourseIndex = {
   __typename?: 'MaterialCourseIndex',
  _id: Scalars['ObjectId'],
  nameShort: Scalars['String'],
};

/** Delivery index for material. */
export type MaterialDeliveryIndex = {
   __typename?: 'MaterialDeliveryIndex',
  _id: Scalars['ObjectId'],
  pickupEndAt: Scalars['DateTime'],
  pickupStartAt: Scalars['DateTime'],
  trackingNo?: Maybe<Scalars['String']>,
  type: DeliveryType,
};

/** Enrollment index for material. */
export type MaterialEnrollmentIndex = {
   __typename?: 'MaterialEnrollmentIndex',
  _id: Scalars['ObjectId'],
  classId: Scalars['ObjectId'],
  learningArrangement?: Maybe<LearningArrangementType>,
  type: EnrollmentType,
};

export type MaterialEnvelope = {
   __typename?: 'MaterialEnvelope',
  _id: Scalars['ObjectId'],
  academicWeeks: Array<PackingListAcademicWeek>,
  booklets: Array<MaterialEnvelopeBooklet>,
  title: Scalars['String'],
};

export type MaterialEnvelopeBooklet = {
   __typename?: 'MaterialEnvelopeBooklet',
  _id: Scalars['ObjectId'],
  serialNumber: Scalars['String'],
  title: Scalars['String'],
  topic: MaterialBookletTopic,
  worksheets: Array<MaterialEnvelopeBookletWorksheet>,
};

export type MaterialEnvelopeBookletDo = {
   __typename?: 'MaterialEnvelopeBookletDO',
  _id: Scalars['ObjectId'],
  serialNumber: Scalars['String'],
  title: Scalars['String'],
  topic: MaterialBookletTopicDo,
  worksheets: Array<MaterialEnvelopeBookletWorksheetDo>,
};

export type MaterialEnvelopeBookletWorksheet = {
   __typename?: 'MaterialEnvelopeBookletWorksheet',
  _id: Scalars['ObjectId'],
  serialNumber: Scalars['String'],
  title: Scalars['String'],
};

export type MaterialEnvelopeBookletWorksheetDo = {
   __typename?: 'MaterialEnvelopeBookletWorksheetDO',
  _id: Scalars['ObjectId'],
  serialNumber: Scalars['String'],
  title: Scalars['String'],
};

export type MaterialEnvelopeDo = {
   __typename?: 'MaterialEnvelopeDO',
  _id: Scalars['ObjectId'],
  academicWeeks: Array<PackingListAcademicWeek>,
  booklets: Array<MaterialEnvelopeBookletDo>,
  title: Scalars['String'],
};

/** Material filter input for Material list */
export type MaterialFilterInput = {
  /** To get materials that belong to these attendance Ids */
  attendanceIds?: Maybe<Array<Scalars['ObjectId']>>,
  courseIds?: Maybe<Array<Scalars['ObjectId']>>,
  dateRange?: Maybe<DateTimeRangeInput>,
  enrollmentEndDate?: Maybe<Scalars['DateTime']>,
  enrollmentStartDate?: Maybe<Scalars['DateTime']>,
  enrollmentTypes?: Maybe<Array<EnrollmentType>>,
  envelopeIds?: Maybe<Array<Scalars['ObjectId']>>,
  hasAdditionalMaterial?: Maybe<Scalars['Boolean']>,
  hasUpcomingOnsiteGAPLesson?: Maybe<Scalars['Boolean']>,
  ids?: Maybe<Array<Scalars['ObjectId']>>,
  isAdHoc?: Maybe<Scalars['Boolean']>,
  isNoStatus?: Maybe<Scalars['Boolean']>,
  learningArrangementTypes?: Maybe<Array<LearningArrangementType>>,
  lessonDateRange?: Maybe<DateTimeRangeInput>,
  name?: Maybe<Scalars['String']>,
  statuses?: Maybe<Array<MaterialStatus>>,
};

/** Input for `materialHandoverUpdate` mutation. */
export type MaterialHandoverUpdateInput = {
  _ids: Array<Scalars['ObjectId']>,
  /** 
 * Flag for determining whether the materials were handed over already or not.
   *    true - means materials were already given, so the status becomes "Handed Onsite"
   *    false - means materials are not given, so the status becomes "Pending Handover"
 */
  areMaterialsGiven: Scalars['Boolean'],
};

/** Material mongo index. */
export type MaterialIndex = {
   __typename?: 'MaterialIndex',
  _id: Scalars['ObjectId'],
  academicLevel?: Maybe<MaterialAcademicLevelIndex>,
  adHocMaterials?: Maybe<Scalars['String']>,
  address?: Maybe<UserAddress>,
  attendanceId?: Maybe<Scalars['ObjectId']>,
  course?: Maybe<MaterialCourseIndex>,
  courseId?: Maybe<Scalars['ObjectId']>,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  delivery?: Maybe<MaterialDeliveryIndex>,
  enrollmentEndAt?: Maybe<Scalars['DateTime']>,
  enrollmentIds?: Maybe<Array<Scalars['ObjectId']>>,
  enrollmentStartAt?: Maybe<Scalars['DateTime']>,
  enrollments?: Maybe<Array<MaterialEnrollmentIndex>>,
  /** MaterialEnvelope. */
  envelope?: Maybe<MaterialEnvelopeDo>,
  handoverOnsiteMaterialId?: Maybe<Scalars['ObjectId']>,
  hasAdditionalMaterial?: Maybe<Scalars['Boolean']>,
  /** Workshop Has Upcoming Onsite GAP Lesson. */
  hasUpcomingOnsiteGAPLesson?: Maybe<Scalars['Boolean']>,
  isAdHoc?: Maybe<Scalars['Boolean']>,
  isAddLessonRemarks?: Maybe<Scalars['Boolean']>,
  isCollectFromReception?: Maybe<Scalars['Boolean']>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  lastModifyUser?: Maybe<MaterialUserIndex>,
  lesson?: Maybe<MaterialLessonIndex>,
  lessonClass?: Maybe<MaterialClassIndex>,
  otherMaterials?: Maybe<Array<MaterialSubIndex>>,
  primaryParent?: Maybe<MaterialParentIndex>,
  primaryParentWhatsappAccount?: Maybe<MaterialWhatsappAccountIndex>,
  remarks?: Maybe<Scalars['String']>,
  secondaryParentWhatsappAccounts?: Maybe<Array<MaterialWhatsappAccountIndex>>,
  secondaryParents?: Maybe<Array<MaterialParentIndex>>,
  siblingMaterials?: Maybe<Array<MaterialSubIndex>>,
  status?: Maybe<MaterialStatus>,
  student?: Maybe<MaterialStudentIndex>,
  studentId: Scalars['ObjectId'],
  /** Workshop Upcoming Onsite GAP Lesson LA. */
  upcomingOnsiteGAPLessonLA?: Maybe<Scalars['String']>,
};

/** Material index page type */
export type MaterialIndexPage = {
   __typename?: 'MaterialIndexPage',
  items: Array<MaterialIndex>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

/** Lesson index for material. */
export type MaterialLessonIndex = {
   __typename?: 'MaterialLessonIndex',
  _id: Scalars['ObjectId'],
  lessonEnd: Scalars['DateTime'],
  lessonNumber: Scalars['Float'],
  lessonStart: Scalars['DateTime'],
};

/** Material page type */
export type MaterialPage = {
   __typename?: 'MaterialPage',
  items: Array<Material>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

/** Parent index for material. */
export type MaterialParentIndex = {
   __typename?: 'MaterialParentIndex',
  _id: Scalars['ObjectId'],
  email?: Maybe<Scalars['String']>,
  fullName: Scalars['String'],
  mobile: Telephone,
  salutation?: Maybe<Scalars['String']>,
};

export enum MaterialStatus {
  AddressVerificationConfirmed = 'AddressVerificationConfirmed',
  AddressVerificationSent = 'AddressVerificationSent',
  Completed = 'Completed',
  Delivered = 'Delivered',
  Delivering = 'Delivering',
  DeliveryByOtherCouriers = 'DeliveryByOtherCouriers',
  Draft = 'Draft',
  Dropped = 'Dropped',
  Exception = 'Exception',
  HandOutOverdue = 'HandOutOverdue',
  HandedOnsite = 'HandedOnsite',
  MaterialsNoteSent = 'MaterialsNoteSent',
  MissedDelivery = 'MissedDelivery',
  Pending = 'Pending',
  PendingHandover = 'PendingHandover',
  ProcessingHub = 'ProcessingHub'
}

/** Student index for material. */
export type MaterialStudentIndex = {
   __typename?: 'MaterialStudentIndex',
  _id: Scalars['ObjectId'],
  address?: Maybe<UserAddress>,
  avatarUrl?: Maybe<Scalars['String']>,
  fullName: Scalars['String'],
  gender?: Maybe<Scalars['String']>,
  userId?: Maybe<Scalars['String']>,
};

/** Lesson index for material. */
export type MaterialSubIndex = {
   __typename?: 'MaterialSubIndex',
  _id: Scalars['ObjectId'],
  course?: Maybe<MaterialCourseIndex>,
  /** MaterialEnvelope. */
  envelope?: Maybe<MaterialEnvelopeDo>,
  student?: Maybe<MaterialStudentIndex>,
};

export type MaterialUserIndex = {
   __typename?: 'MaterialUserIndex',
  _id: Scalars['ObjectId'],
  fullName: Scalars['String'],
};

/** Whatsapp account index for material. */
export type MaterialWhatsappAccountIndex = {
   __typename?: 'MaterialWhatsappAccountIndex',
  _id: Scalars['ObjectId'],
  /** WhatsApp phone number. */
  number?: Maybe<Telephone>,
  parentId: Scalars['ObjectId'],
};

export type Merchandise = {
   __typename?: 'Merchandise',
  _id: Scalars['ObjectId'],
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  dateIssued?: Maybe<Scalars['DateTime']>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  distributionChannel?: Maybe<Scalars['String']>,
  isArchived: Scalars['Boolean'],
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  item: Scalars['String'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  remarks?: Maybe<Scalars['String']>,
  status: MerchandiseStatus,
  student: Student,
};

export type MerchandisePage = {
   __typename?: 'MerchandisePage',
  items: Array<Merchandise>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

export enum MerchandiseStatus {
  Issued = 'Issued',
  Pending = 'Pending'
}

export type MergeParentInput = {
  alias?: Maybe<MergeReservedType>,
  country?: Maybe<MergeReservedType>,
  email?: Maybe<MergeReservedType>,
  gateBuilding?: Maybe<MergeReservedType>,
  gender?: Maybe<MergeReservedType>,
  homePhone?: Maybe<MergeReservedType>,
  linkedWhatsappAccountId?: Maybe<MergeReservedType>,
  /** Parent to be merged */
  mergeParentId: Scalars['ObjectId'],
  officePhone?: Maybe<MergeReservedType>,
  postalCode?: Maybe<MergeReservedType>,
  /** Parent to be retained */
  retainParentId: Scalars['ObjectId'],
  salutation?: Maybe<MergeReservedType>,
  streetAddress?: Maybe<MergeReservedType>,
  unit?: Maybe<MergeReservedType>,
  whatsAppName?: Maybe<MergeReservedType>,
};

export enum MergeReservedType {
  Merge = 'Merge',
  Retain = 'Retain'
}

export type MergeStudentInput = {
  academicLevel?: Maybe<MergeReservedType>,
  alias?: Maybe<MergeReservedType>,
  birthDate?: Maybe<MergeReservedType>,
  country?: Maybe<MergeReservedType>,
  email?: Maybe<MergeReservedType>,
  gateBuilding?: Maybe<MergeReservedType>,
  gender?: Maybe<MergeReservedType>,
  homePhone?: Maybe<MergeReservedType>,
  /** Student to be merged */
  mergeStudentId: Scalars['ObjectId'],
  mobile?: Maybe<MergeReservedType>,
  postalCode?: Maybe<MergeReservedType>,
  primarySchool?: Maybe<MergeReservedType>,
  /** Student to be retained */
  retainStudentId: Scalars['ObjectId'],
  secondarySchool?: Maybe<MergeReservedType>,
  secondaryStream?: Maybe<MergeReservedType>,
  streetAddress?: Maybe<MergeReservedType>,
  unit?: Maybe<MergeReservedType>,
};

export type Mutation = {
   __typename?: 'Mutation',
  addInvoiceLineItem?: Maybe<Invoice>,
  /** Adds an item to the Lesson Flow's schedule. */
  addLessonFlowSchedule: AddLessonFlowScheduleResponse,
  /** Adds a lesson plan to a course. */
  addLessonPlan: LessonPlan,
  addReward?: Maybe<Reward>,
  /** Mutation to add reward points to student */
  addRewardPoints?: Maybe<Student>,
  addXeroAccount?: Maybe<OtherMappingAccount>,
  /** Update the material's status from Address Verification Sent to Address verification confirmed */
  addressVerificationConfirmed?: Maybe<Array<Material>>,
  adjustBalance?: Maybe<Balance>,
  applyDiscount?: Maybe<Invoice>,
  /** Applies the lesson flows of a lesson plan to the lessons of a class by id. */
  applyLessonPlanToClass: ApplyLessonPlanToClassResponse,
  approveRegistration?: Maybe<Registration>,
  archiveDiscount?: Maybe<Discount>,
  archiveHistoryReward?: Maybe<RewardHistory>,
  archiveReward?: Maybe<Reward>,
  archiveWhatsappAccount?: Maybe<WhatsappAccount>,
  /** Archives the worksheets. */
  archiveWorkSheets?: Maybe<Array<WorkSheet>>,
  arrangementEligibility?: Maybe<Scalars['Boolean']>,
  /** Mutation to add reward points to students by bulk */
  bulkAddRewardPoints?: Maybe<StudentBulkAwardsResult>,
  bulkCompileTemplate?: Maybe<Array<ReminderPreview>>,
  bulkCreateClass?: Maybe<Scalars['Boolean']>,
  bulkCreateDelivery?: Maybe<Array<Delivery>>,
  bulkCreateRegistration?: Maybe<Scalars['Boolean']>,
  /** Mutation to deduct reward points to students by bulk */
  bulkDeductRewardPoints?: Maybe<StudentBulkAwardsResult>,
  /** selected materials status will update to Pending Handout, handout lesson will be selected Academic Week. */
  bulkHandoverOnsite: Scalars['Boolean'],
  bulkSendReminder?: Maybe<Array<NotifyMissingDto>>,
  bulkUpdateDigitalClassVenue?: Maybe<Array<Class>>,
  bulkUpdateStudentAddressFromPrimaryParent?: Maybe<Array<Student>>,
  cancelArrangement?: Maybe<Arrangement>,
  cancelChangeAttendingClassForRegistration?: Maybe<Registration>,
  cancelChangeAttendingClassForTransfer?: Maybe<Arrangement>,
  cancelClassCreationFlow?: Maybe<Class>,
  cancelPromotion?: Maybe<Promotion>,
  cancelRegistration?: Maybe<Registration>,
  capacitySimulation?: Maybe<Scalars['Boolean']>,
  changeAcademicLevelArchive?: Maybe<AcademicLevel>,
  changeAcademicLevelVisibility?: Maybe<AcademicLevel>,
  changeAttendingClassForRegistration?: Maybe<Registration>,
  changeAttendingClassForTransfer?: Maybe<Arrangement>,
  changeBranchArchive?: Maybe<Branch>,
  changeCourierPickupAddressArchive?: Maybe<CourierPickupAddress>,
  changeDepartmentArchive?: Maybe<Department>,
  /** Archive a remark. */
  changeRemarkArchive?: Maybe<Remark>,
  changeSchoolArchive?: Maybe<School>,
  changeSchoolVisibility?: Maybe<School>,
  clearSimulation?: Maybe<Scalars['Boolean']>,
  compileTemplate?: Maybe<ReminderPreview>,
  completeClassCreationFlow?: Maybe<Class>,
  completeImportClasses?: Maybe<BulkCreateHistory>,
  completeImportRegistrations?: Maybe<BulkCreateHistory>,
  completeMailMerge?: Maybe<MailMerge>,
  completeTasks?: Maybe<Array<Task>>,
  connectXeroAccountForCourse?: Maybe<Course>,
  connectXeroAccountForOtherMapping?: Maybe<XeroIntegration>,
  copyTaskList?: Maybe<TaskList>,
  createAcademicLevel?: Maybe<AcademicLevel>,
  createAcademicYear: AcademicYear,
  createAdHocInvoice?: Maybe<Invoice>,
  /** Create a ad hoc material record. */
  createAdHocMaterial?: Maybe<Material>,
  createAdhocClass?: Maybe<Class>,
  createArrangement?: Maybe<Arrangement>,
  createBillingForRegistration?: Maybe<Registration>,
  createBillingForTransfer?: Maybe<Arrangement>,
  createBranch?: Maybe<Branch>,
  createCampaign?: Maybe<Campaign>,
  /** Create a collect for the current LessonFlowItem. */
  createCollect?: Maybe<LessonFlowItem>,
  createCourierPickupAddress?: Maybe<CourierPickupAddress>,
  createCourseNotification?: Maybe<CourseNotification>,
  createDepartment?: Maybe<Department>,
  createDiscount?: Maybe<Discount>,
  createEligibilityFlow?: Maybe<EligibilityFlow>,
  createExercise?: Maybe<Exercise>,
  createFeedback?: Maybe<Feedback>,
  /** Mutation to create multiple feedback seen receipt records */
  createFeedbackSeenReceipts?: Maybe<Array<FeedbackSeenReceipt>>,
  createGradingTemplate: GradingTemplate,
  createGroupAssignment?: Maybe<GroupAssignment>,
  /** Create a new handout. */
  createHandout?: Maybe<Handout>,
  createHoliday?: Maybe<Holiday>,
  createHomework?: Maybe<Homework>,
  createInvoiceItemOption?: Maybe<InvoiceItemOption>,
  createLesson?: Maybe<Lesson>,
  createLessonFlow?: Maybe<LessonFlow>,
  createLessonFlowItem?: Maybe<LessonFlowItem>,
  /** Mutation resolver that adds a lesson sequence to Lesson Flow Item. */
  createLessonSequenceItem?: Maybe<LessonFlowItem>,
  createLog?: Maybe<Log>,
  createMailMerge?: Maybe<MailMerge>,
  createMerchandise?: Maybe<Merchandise>,
  createNote?: Maybe<Note>,
  createParent?: Maybe<Parent>,
  createPaymentRecord?: Maybe<Payment>,
  createPromotion?: Maybe<Promotion>,
  createReferenceData?: Maybe<ReferenceData>,
  createRefund?: Maybe<Refund>,
  createRegistration?: Maybe<Registration>,
  createRegularClass?: Maybe<Class>,
  createRegularCourse?: Maybe<Course>,
  /** Create a remark. All remarks require a `subjectId` and `type`. */
  createRemark?: Maybe<Remark>,
  createSchool?: Maybe<School>,
  createStudentReportFilter?: Maybe<StudentReportFilter>,
  createTask?: Maybe<Task>,
  createTaskChecklist?: Maybe<Task>,
  createTaskChecklistItem?: Maybe<Task>,
  createTaskList?: Maybe<TaskList>,
  createTemplate?: Maybe<Template>,
  createTopic?: Maybe<Topic>,
  createUserCustomiseColumn?: Maybe<UserCustomiseColumn>,
  createUserCustomiseColumns?: Maybe<Array<UserCustomiseColumn>>,
  createVenue?: Maybe<Venue>,
  createWaitingListsForMultipleClasses?: Maybe<Array<Registration>>,
  createWhatsappAccount?: Maybe<WhatsappAccount>,
  createWorkShopClass?: Maybe<Class>,
  createWorkShopCourse?: Maybe<Course>,
  /** Mutation to deduct reward points to student */
  deductRewardPoints?: Maybe<Student>,
  deleteAcademicLevel?: Maybe<AcademicLevel>,
  deleteAcademicYear: AcademicYear,
  /** Delete a ad hoc material record. */
  deleteAdHocMaterial?: Maybe<Material>,
  deleteBranch?: Maybe<Branch>,
  deleteCampaign?: Maybe<Campaign>,
  /** Delete a collect for the current LessonFlowItem. */
  deleteCollect?: Maybe<LessonFlowItem>,
  deleteCourseNotification?: Maybe<CourseNotification>,
  deleteDelivery?: Maybe<Delivery>,
  deleteDepartment?: Maybe<Centre>,
  deleteGroupAssignment?: Maybe<GroupAssignment>,
  /** Delete a handout by id. */
  deleteHandout?: Maybe<Handout>,
  deleteHoliday?: Maybe<Holiday>,
  deleteInvoiceItemOption?: Maybe<InvoiceItemOption>,
  deleteLesson?: Maybe<Lesson>,
  /** A Mutation to delete a lessonFlowItem and it's exercises, and homeworks. */
  deleteLessonFlowItem?: Maybe<LessonFlowItem>,
  /** Mutation resolver that removes a lesson sequence from Lesson Flow Item. */
  deleteLessonSequenceItem?: Maybe<LessonFlowItem>,
  deleteLessons?: Maybe<Array<Lesson>>,
  deleteMerchandise?: Maybe<Merchandise>,
  deleteParent?: Maybe<Parent>,
  deletePromotion?: Maybe<Promotion>,
  deleteReferenceData?: Maybe<ReferenceData>,
  /** Delete a remark. */
  deleteRemark?: Maybe<Remark>,
  deleteSchool?: Maybe<School>,
  deleteStudent?: Maybe<Student>,
  deleteStudentReportFilter?: Maybe<StudentReportFilter>,
  deleteSubmission?: Maybe<Submission>,
  deleteTaskChecklist?: Maybe<Task>,
  deleteTaskChecklistItem?: Maybe<Task>,
  deleteTaskList?: Maybe<TaskList>,
  deleteTerm: Term,
  deleteTopic?: Maybe<Topic>,
  deleteUserCustomiseColumn?: Maybe<UserCustomiseColumn>,
  deleteUserCustomiseColumns?: Maybe<Array<UserCustomiseColumn>>,
  disconnectXeroAccount?: Maybe<XeroIntegration>,
  downloadMailMerge?: Maybe<Csv>,
  exportFailedImportClasses?: Maybe<Csv>,
  exportFailedImportRegistrations?: Maybe<Csv>,
  extendsArrangementTime?: Maybe<Arrangement>,
  extendsRegistrationTime?: Maybe<Registration>,
  generateNextInvoice?: Maybe<Invoice>,
  incrementStep?: Maybe<Arrangement>,
  /** Adds a new homework item to a lesson flow of a lesson plan. */
  lessonPlanAddLessonFlowHomework: LessonPlan,
  /** Removes a homework item from a lesson flow of a lesson plan. */
  lessonPlanRemoveLessonFlowHomework: LessonPlan,
  /** Reorders the schedules of a lesson flow of a lesson plan. */
  lessonPlanReorderLessonFlowSchedules: LessonPlan,
  /** 
 * Updates a lesson flow schedule/homework item's `isSkipped` flag. Can only be
   * updated if the lesson flow schedule item is a Schedule (worksheet or test) or
   * Homework (worksheet) and related packing list envelope is 'Packed for
   * Delivery' or 'Printed'.
 */
  lessonPlanSkipLessonFlowItem: LessonPlan,
  /** Updates a homework item of a lesson flow of a lesson plan. */
  lessonPlanUpdateLessonFlowHomework: LessonPlan,
  /** Updates a lesson flow item's trial flag. Schedule (worksheet or test) and Homework (worksheet). */
  lessonPlanUpdateLessonFlowItemTrialFlags: LessonPlan,
  linkParent?: Maybe<Relationship>,
  /** 
 * When a material has been handed out, 
   *     a teacher can mark the material on the attendance page as `Handed Onsite`.
 */
  materialHandoverUpdate?: Maybe<Array<Material>>,
  mergeParent?: Maybe<Parent>,
  mergeStudent?: Maybe<Student>,
  onHoldInvoice?: Maybe<Invoice>,
  /** Adds a booklet to an existing packing list envelope */
  packingListAddBooklets: PackingList,
  /** Add envelopes to an existing packing list record. */
  packingListAddEnvelopes: PackingList,
  /** 
 * Adds a set of worksheets to a booklet of a packing list.
   *     Duplicate worksheets will not be added to the booklet.
 */
  packingListAddWorksheets: PackingList,
  /** Changes the status of a packing list envelope. */
  packingListChangeEnvelopeStatus: PackingList,
  /** Creates a new packing list configuration. */
  packingListConfigurationCreate: PackingListConfiguration,
  /** Updates the envelopes of a packing list configuration. */
  packingListConfigurationUpdateEnvelopes: PackingListConfiguration,
  /** Creates a packing list record. */
  packingListCreate: PackingList,
  /** Moves a worksheet between booklets within the same envelope. */
  packingListEnvelopeMoveWorksheetToBooklet: PackingList,
  /** Reduces the "Available" units of a packing list envelope in a packing list. */
  packingListEnvelopeReduceAvailableUnits: PackingList,
  /** Reduces the "Available" units of a packing list partial envelope in a packing list. */
  packingListPartialEnvelopeReduceAvailableUnits: PackingList,
  /** Remove booklets from a packing list envelope. */
  packingListRemoveBooklets: PackingList,
  /** 
 * Remove envelopes from a packing list.
   *     ll of the child documents will also be removed.
 */
  packingListRemoveEnvelopes: PackingList,
  /** Removes a worksheet from a booklet of a packing list envelope. */
  packingListRemoveWorksheets: PackingList,
  /** 
 * Reorders the booklets of an envelope in a packing list based the given
   *    * ordinal position
 */
  packingListReorderBooklets: PackingList,
  /** 
 * Reorders the worksheets of an envelope in a packing list based 
   *     on a source worksheet and destination worksheet.
 */
  packingListReorderWorksheets: PackingList,
  /** Reduces the "Available" units of a packing list trial envelope in a packing list. */
  packingListTrialEnvelopeReduceAvailableUnits: PackingList,
  /** Updates the "Available" units of an envelope or partial envelope in a packing list. */
  packingListUpdatePackedUnits: PackingList,
  /** Updates the `status` of multiple trial worksheets belonging to an academic week. */
  packingListUpdateTrialWorksheetAcademicWeekStatus: PackingList,
  /** 
 * Updates the version name of the packing list when provided and
   *     updates the academic weeks for each envelope when the academic year
   *     of the version changes.
 */
  packingListUpdateVersion: PackingList,
  pauseWhatsAppMessage?: Maybe<WhatsappMessage>,
  pauseWhatsappAccount?: Maybe<WhatsappAccount>,
  pinTaskList?: Maybe<TaskList>,
  placeOrderDelivery: Delivery,
  prioritizeRegistration?: Maybe<Registration>,
  processArrangement?: Maybe<Arrangement>,
  processRegistration?: Maybe<Registration>,
  queueNext?: Maybe<WhatsappMessage>,
  readSystemNotification?: Maybe<SystemNotification>,
  recallCampaign?: Maybe<Campaign>,
  reconnectWhatsappAccount?: Maybe<WhatsappAccount>,
  recordExamScore?: Maybe<ExamScore>,
  recoverWhatsappAccount?: Maybe<WhatsappAccount>,
  refreshXeroToken?: Maybe<Array<XeroIntegration>>,
  regenerateInvoice?: Maybe<Invoice>,
  regenerateRegistration?: Maybe<Registration>,
  registerStudent?: Maybe<Student>,
  registrationIncrementStep?: Maybe<Registration>,
  /** Removes a schedule item from a lesson flow of a lesson plan. */
  removeLessonFlowSchedule: RemoveLessonFlowScheduleResponse,
  /** Removes the lesson flows of the lesson plan from the lessons of a class by id. */
  removeLessonPlanFromClass: RemoveLessonPlanFromClassResponse,
  removeLineItem?: Maybe<Invoice>,
  removeLineItemDiscount?: Maybe<Invoice>,
  reopenTask?: Maybe<Task>,
  reorderLessonFlowItems?: Maybe<Array<LessonFlowItem>>,
  replyFeedback?: Maybe<Feedback>,
  requestChangeSystemUserPassword?: Maybe<Scalars['Boolean']>,
  requeueWhatsAppMessage?: Maybe<WhatsappMessage>,
  resendReminder?: Maybe<Reminder>,
  resetDefault?: Maybe<Array<UserCustomiseColumn>>,
  /** Reset the status of the default material. */
  resetMaterialStatus?: Maybe<Material>,
  restoreDiscount?: Maybe<Discount>,
  restoreLessonFlowItemVersion?: Maybe<LessonFlowItem>,
  restoreReward?: Maybe<Reward>,
  resumeWhatsAppMessage?: Maybe<WhatsappMessage>,
  resumeWhatsappAccount?: Maybe<WhatsappAccount>,
  retryUploadInvoice?: Maybe<Invoice>,
  retryWhatsAppMessage?: Maybe<WhatsappMessage>,
  retryXeroPayment?: Maybe<Payment>,
  saveAsNewLessonFlow?: Maybe<LessonFlow>,
  saveSubmissions?: Maybe<Array<Submission>>,
  saveTopics?: Maybe<Array<Topic>>,
  sendReminder?: Maybe<Array<Reminder>>,
  sendTestReminder?: Maybe<Reminder>,
  setDefaultLessonFlow?: Maybe<Course>,
  setLessonFlowItemExceptions?: Maybe<LessonFlowItem>,
  testSubmissionCreate: TestSubmission,
  /** Make a test submission to `Draft`. */
  testSubmissionMakeDraft: TestSubmission,
  /** Saves the marked resources of a test submission and changes the status to `Returned`. */
  testSubmissionSaveMarkedResource: TestSubmission,
  /** Adds the test scores to the submission and sets its status to `Marked`. */
  testSubmissionSaveScores: TestSubmission,
  /** Updates a test submission. */
  testSubmissionUpdate: TestSubmission,
  toggleFeature?: Maybe<FeatureAccess>,
  toggleFeatures?: Maybe<Array<FeatureAccess>>,
  /** 
 * Adds a course to a topic.
   *     This would also enable this course to be associated with the worksheets of the topic.
 */
  topicAddCourse?: Maybe<Topic>,
  /** 
 * Removes a course from a topic. 
   *     This would also dissociate the worksheets of the topic from the course.
 */
  topicRemoveCourse?: Maybe<Topic>,
  /** Unarchives the worksheets. */
  unarchiveWorksheets?: Maybe<Array<WorkSheet>>,
  unlinkParent?: Maybe<Relationship>,
  updateAcademicLevel?: Maybe<AcademicLevel>,
  updateAcademicYear: AcademicYear,
  updateArchivable?: Maybe<Template>,
  updateAttendanceLessonMode?: Maybe<Attendance>,
  /** Updates one more attendance record. */
  updateAttendances?: Maybe<Array<Attendance>>,
  updateBranch?: Maybe<Branch>,
  updateCampaign?: Maybe<Campaign>,
  updateClassDigitalCredential?: Maybe<Class>,
  updateClassFee?: Maybe<Class>,
  updateClassLearningArrangement?: Maybe<Class>,
  updateClassLessonFlow?: Maybe<Class>,
  updateClassName?: Maybe<Class>,
  updateClassSchedule?: Maybe<Class>,
  updateClassTeacher?: Maybe<Class>,
  updateClassVenue?: Maybe<Class>,
  /** Update a collect for the current LessonFlowItem. */
  updateCollect?: Maybe<LessonFlowItem>,
  updateCourierPickupAddress?: Maybe<CourierPickupAddress>,
  updateCourse?: Maybe<Course>,
  updateDelivery?: Maybe<Delivery>,
  updateDepartment?: Maybe<Department>,
  updateDiscount?: Maybe<Discount>,
  updateEligibilityFlow?: Maybe<EligibilityFlow>,
  /** Update enrollment end recurring. */
  updateEnrollmentEndRecurring?: Maybe<Enrollment>,
  updateEnrollmentFollowUpStatus?: Maybe<Enrollment>,
  updateEnrollmentGroup?: Maybe<Enrollment>,
  updateEnrollmentPreferredLearningArrangement?: Maybe<Enrollment>,
  /** Mutation that upddates an enrollment's suitability information */
  updateEnrollmentSuitability?: Maybe<Enrollment>,
  /** Updates multiple enrollment records. */
  updateEnrollments?: Maybe<Array<Enrollment>>,
  updateEnrollmentsPreferredLearningArrangement?: Maybe<Array<Enrollment>>,
  updateExamScore?: Maybe<ExamScore>,
  updateExercise?: Maybe<Exercise>,
  updateFeedback?: Maybe<Feedback>,
  updateGroupAssignment?: Maybe<GroupAssignment>,
  /** Update a handout by id. */
  updateHandout?: Maybe<Handout>,
  updateHoliday?: Maybe<Holiday>,
  updateHomework?: Maybe<Homework>,
  updateIntegrationClientInfo?: Maybe<Integration>,
  updateInvoiceDueDate?: Maybe<Invoice>,
  updateInvoiceItem?: Maybe<Invoice>,
  updateInvoiceItemOption?: Maybe<InvoiceItemOption>,
  updateLesson?: Maybe<Lesson>,
  updateLessonAttendance?: Maybe<Lesson>,
  /** Updates lesson flow record with given classIds and lessonFlowItemIds. */
  updateLessonFlow?: Maybe<LessonFlow>,
  updateLessonFlowArchive?: Maybe<LessonFlow>,
  updateLessonFlowItem?: Maybe<LessonFlowItem>,
  /** Updates an existing lesson flow schedule item in a lesson flow of a lesson plan. */
  updateLessonFlowSchedule: UpdateLessonFlowScheduleResponse,
  /** Mutation resolver that updates a lesson sequence from Lesson Flow Item. */
  updateLessonSequenceItem?: Maybe<LessonFlowItem>,
  updateLog?: Maybe<Log>,
  updateMailMerge?: Maybe<MailMerge>,
  /** Update the status of the material. */
  updateMaterialDeliveryOption?: Maybe<Material>,
  updateMerchandise?: Maybe<Merchandise>,
  updateNotificationItem?: Maybe<NotificationSetting>,
  updateParent?: Maybe<Parent>,
  /** Resolver to update existing payment record in an invoice */
  updatePaymentRecord?: Maybe<Payment>,
  updatePromotion?: Maybe<Promotion>,
  updateReferenceData?: Maybe<ReferenceData>,
  updateRegistration?: Maybe<Registration>,
  updateRelationName?: Maybe<Scalars['String']>,
  /** Update a remark. Only the `content` and `isPinned` props may be updated. */
  updateRemark?: Maybe<Remark>,
  updateReward?: Maybe<Reward>,
  updateRewardsHistory?: Maybe<RewardHistory>,
  updateSchool?: Maybe<School>,
  updateStudent?: Maybe<Student>,
  updateStudentRemarks?: Maybe<Student>,
  updateStudentReportFilter?: Maybe<StudentReportFilter>,
  updateTask?: Maybe<Task>,
  updateTaskChecklist?: Maybe<Task>,
  updateTaskChecklistItem?: Maybe<Task>,
  updateTaskDueDate?: Maybe<Task>,
  updateTaskList?: Maybe<TaskList>,
  updateTemplate?: Maybe<Template>,
  updateTopic?: Maybe<Topic>,
  updateUserCustomiseColumn?: Maybe<UserCustomiseColumn>,
  updateUserCustomiseColumns?: Maybe<Array<UserCustomiseColumn>>,
  updateVenue?: Maybe<Venue>,
  updateWhatsappAccount?: Maybe<WhatsappAccount>,
  updateWorkSheet?: Maybe<WorkSheet>,
  uploadBase64File?: Maybe<UploadResult>,
  uploadFile?: Maybe<UploadResult>,
  uploadMailMergeMergeList?: Maybe<MailMerge>,
  upsertUserCustomiseColumn?: Maybe<UserCustomiseColumn>,
  upsertUserCustomiseColumns?: Maybe<Array<UserCustomiseColumn>>,
  useRewardPoints?: Maybe<Student>,
  validateIntegrationAccessToken?: Maybe<Scalars['Boolean']>,
  validateWhatsappNumber?: Maybe<Scalars['Boolean']>,
  voidClass?: Maybe<Class>,
  voidCourse?: Maybe<Course>,
  voidInvoice?: Maybe<Invoice>,
  voidParent?: Maybe<Parent>,
  voidPaymentRecord?: Maybe<Payment>,
  voidRefund?: Maybe<Refund>,
  voidStudent?: Maybe<Student>,
  voidTask?: Maybe<Task>,
  voidTasks?: Maybe<Array<Task>>,
  whatsappLogin?: Maybe<QrCode>,
  whatsappLogout?: Maybe<WhatsappAccount>,
  /** Adds a course to a worksheet. */
  worksheetAddCourse?: Maybe<WorkSheet>,
  /** Creates a worksheet on a course. */
  worksheetCreate: WorkSheet,
  /** Removes a course to a worksheet. */
  worksheetRemoveCourse?: Maybe<WorkSheet>,
  worksheetsCreate: Array<WorkSheet>,
  worksheetsParseCsv: WorksheetsParseCsvResponse,
  xeroApiCallback?: Maybe<XeroIntegration>,
};


export type MutationAddInvoiceLineItemArgs = {
  input: AddInvoiceLineItemCommand
};


export type MutationAddLessonFlowScheduleArgs = {
  input: AddLessonFlowScheduleInput
};


export type MutationAddLessonPlanArgs = {
  input: AddLessonPlanInput
};


export type MutationAddRewardArgs = {
  input: AddRewardCatalogueInput
};


export type MutationAddRewardPointsArgs = {
  input: AddRewardPointsInput
};


export type MutationAddXeroAccountArgs = {
  input: AddXeroAccountInput
};


export type MutationAddressVerificationConfirmedArgs = {
  ids: Array<Scalars['ObjectId']>
};


export type MutationAdjustBalanceArgs = {
  input: AdjustBalanceInput
};


export type MutationApplyDiscountArgs = {
  input: ApplyInvoiceDiscountCommand
};


export type MutationApplyLessonPlanToClassArgs = {
  input: ApplyLessonPlanToClassInput
};


export type MutationApproveRegistrationArgs = {
  input: ApproveRegistrationInput
};


export type MutationArchiveDiscountArgs = {
  id: Scalars['ObjectId']
};


export type MutationArchiveHistoryRewardArgs = {
  id: Scalars['ObjectId']
};


export type MutationArchiveRewardArgs = {
  id: Scalars['ObjectId']
};


export type MutationArchiveWhatsappAccountArgs = {
  id: Scalars['ObjectId']
};


export type MutationArchiveWorkSheetsArgs = {
  input: ArchiveWorkSheetsInput
};


export type MutationArrangementEligibilityArgs = {
  input: CreateArrangementInput
};


export type MutationBulkAddRewardPointsArgs = {
  input: BulkAddRewardPointsInput
};


export type MutationBulkCompileTemplateArgs = {
  input: BulkSendReminderInput
};


export type MutationBulkCreateClassArgs = {
  input: BulkCreateClassCommand
};


export type MutationBulkCreateDeliveryArgs = {
  input: BulkCreateDeliveryInput
};


export type MutationBulkCreateRegistrationArgs = {
  input: BulkCreateRegistrationCommand
};


export type MutationBulkDeductRewardPointsArgs = {
  input: BulkDeductRewardPointsInput
};


export type MutationBulkHandoverOnsiteArgs = {
  input: BulkHandoverOnsiteCommand
};


export type MutationBulkSendReminderArgs = {
  input: BulkSendReminderInput
};


export type MutationBulkUpdateDigitalClassVenueArgs = {
  input: Array<UpdateClassCommand>
};


export type MutationBulkUpdateStudentAddressFromPrimaryParentArgs = {
  ids: Array<Scalars['ObjectId']>
};


export type MutationCancelArrangementArgs = {
  id: Scalars['ObjectId']
};


export type MutationCancelChangeAttendingClassForRegistrationArgs = {
  input: ChangeAttendingClassForRegistrationInput
};


export type MutationCancelChangeAttendingClassForTransferArgs = {
  input: ChangeAttendingClassForTransferInput
};


export type MutationCancelClassCreationFlowArgs = {
  input: IdInput
};


export type MutationCancelPromotionArgs = {
  id: Scalars['ObjectId']
};


export type MutationCancelRegistrationArgs = {
  id: Scalars['ObjectId'],
  isCancelOtherRegistration?: Maybe<Scalars['Boolean']>
};


export type MutationCapacitySimulationArgs = {
  input: SimulationCapacityInput
};


export type MutationChangeAcademicLevelArchiveArgs = {
  input: ChangeAcademicLevelArchive
};


export type MutationChangeAcademicLevelVisibilityArgs = {
  input: ChangeAcademicLevelVisibility
};


export type MutationChangeAttendingClassForRegistrationArgs = {
  input: ChangeAttendingClassForRegistrationInput
};


export type MutationChangeAttendingClassForTransferArgs = {
  input: ChangeAttendingClassForTransferInput
};


export type MutationChangeBranchArchiveArgs = {
  input: ChangeBranchArchiveInput
};


export type MutationChangeCourierPickupAddressArchiveArgs = {
  input: ChangeCourierPickupAddressArchiveInput
};


export type MutationChangeDepartmentArchiveArgs = {
  input: ChangeDepartmentArchiveInput
};


export type MutationChangeRemarkArchiveArgs = {
  id: Scalars['ObjectId'],
  isArchived: Scalars['Boolean']
};


export type MutationChangeSchoolArchiveArgs = {
  input: ChangeSchoolArchiveInput
};


export type MutationChangeSchoolVisibilityArgs = {
  input: ChangeSchoolVisibilityInput
};


export type MutationClearSimulationArgs = {
  input: IdInput
};


export type MutationCompileTemplateArgs = {
  input: SendReminderInput
};


export type MutationCompleteClassCreationFlowArgs = {
  input: IdInput
};


export type MutationCompleteImportClassesArgs = {
  id: Scalars['ObjectId']
};


export type MutationCompleteImportRegistrationsArgs = {
  id: Scalars['ObjectId']
};


export type MutationCompleteMailMergeArgs = {
  input: CompleteMailMergeInput
};


export type MutationCompleteTasksArgs = {
  command: BulkCompleteTaskCommand
};


export type MutationConnectXeroAccountForCourseArgs = {
  input: ConnectXeroAccountForCourseInput
};


export type MutationConnectXeroAccountForOtherMappingArgs = {
  input: ConnectXeroAccountInput
};


export type MutationCopyTaskListArgs = {
  input: CopyTaskListInput
};


export type MutationCreateAcademicLevelArgs = {
  input: CreateAcademicLevelInput
};


export type MutationCreateAcademicYearArgs = {
  input: CreateAcademicYearInput
};


export type MutationCreateAdHocInvoiceArgs = {
  input: CreateAdHocInvoiceCommand
};


export type MutationCreateAdHocMaterialArgs = {
  input: CreateAdHocMaterialInput
};


export type MutationCreateAdhocClassArgs = {
  input: CreateAdhocClassCommand
};


export type MutationCreateArrangementArgs = {
  input: CreateArrangementInput
};


export type MutationCreateBillingForRegistrationArgs = {
  currentStep: Scalars['Int'],
  id: Scalars['ObjectId']
};


export type MutationCreateBillingForTransferArgs = {
  id: Scalars['ObjectId']
};


export type MutationCreateBranchArgs = {
  input: CreateBranchInput
};


export type MutationCreateCampaignArgs = {
  input: CreateCampaignInput
};


export type MutationCreateCollectArgs = {
  input: CreateCollectInput
};


export type MutationCreateCourierPickupAddressArgs = {
  input: CreateCourierPickupAddressInput
};


export type MutationCreateCourseNotificationArgs = {
  input: CreateCourseNotificationInput
};


export type MutationCreateDepartmentArgs = {
  input: CreateDepartmentInput
};


export type MutationCreateDiscountArgs = {
  input: CreateDiscountInput
};


export type MutationCreateEligibilityFlowArgs = {
  input: CreateEligibilityFlowInput
};


export type MutationCreateExerciseArgs = {
  input: CreateExerciseInput
};


export type MutationCreateFeedbackArgs = {
  input: CreateFeedbackInput
};


export type MutationCreateFeedbackSeenReceiptsArgs = {
  inputs: CreateFeedbackSeenReceiptInputs
};


export type MutationCreateGradingTemplateArgs = {
  input: CreateGradingTemplateInput
};


export type MutationCreateGroupAssignmentArgs = {
  input: CreateGroupAssignmentInput
};


export type MutationCreateHandoutArgs = {
  input: CreateHandoutInput
};


export type MutationCreateHolidayArgs = {
  input: CreateHolidayInput
};


export type MutationCreateHomeworkArgs = {
  input: CreateHomeworkInput
};


export type MutationCreateInvoiceItemOptionArgs = {
  input: CreateInvoiceItemOptionInput
};


export type MutationCreateLessonArgs = {
  input: CreateLessonInput
};


export type MutationCreateLessonFlowArgs = {
  input: CreateLessonFlowInput
};


export type MutationCreateLessonFlowItemArgs = {
  input: CreateLessonFlowItemInput
};


export type MutationCreateLessonSequenceItemArgs = {
  input: CreateLessonSequenceInput
};


export type MutationCreateLogArgs = {
  input: CreateLogInput
};


export type MutationCreateMailMergeArgs = {
  input: CreateMailMergeInput
};


export type MutationCreateMerchandiseArgs = {
  input: CreateMerchandiseInput
};


export type MutationCreateNoteArgs = {
  input: CreateNoteInput
};


export type MutationCreateParentArgs = {
  input: CreateParentInput
};


export type MutationCreatePaymentRecordArgs = {
  input: CreatePaymentRecordInput
};


export type MutationCreatePromotionArgs = {
  input: CreatePromotionInput
};


export type MutationCreateReferenceDataArgs = {
  input: CreateReferenceDataInput
};


export type MutationCreateRefundArgs = {
  input: CreateRefundInput
};


export type MutationCreateRegistrationArgs = {
  input: CreateRegistrationInput
};


export type MutationCreateRegularClassArgs = {
  input: CreateRegularClassCommand
};


export type MutationCreateRegularCourseArgs = {
  input: CreateRegularCourseInput
};


export type MutationCreateRemarkArgs = {
  input: CreateRemarkInput
};


export type MutationCreateSchoolArgs = {
  input: CreateSchoolInput
};


export type MutationCreateStudentReportFilterArgs = {
  input: StudentReportFilterInput
};


export type MutationCreateTaskArgs = {
  input: CreateTaskInput
};


export type MutationCreateTaskChecklistArgs = {
  input: CreateTaskChecklistInput
};


export type MutationCreateTaskChecklistItemArgs = {
  input: CreateTaskChecklistItemInput
};


export type MutationCreateTaskListArgs = {
  input: CreateTaskListInput
};


export type MutationCreateTemplateArgs = {
  input: CreateTemplateInput
};


export type MutationCreateTopicArgs = {
  input: CreateTopicInput
};


export type MutationCreateUserCustomiseColumnArgs = {
  input: CreateUserCustomiseColumnInput
};


export type MutationCreateUserCustomiseColumnsArgs = {
  inputs: Array<CreateUserCustomiseColumnInput>
};


export type MutationCreateVenueArgs = {
  command: CreateVenueCommand
};


export type MutationCreateWaitingListsForMultipleClassesArgs = {
  input: CreateWaitingListsForStudentInput
};


export type MutationCreateWhatsappAccountArgs = {
  input: CreateWhatsappAccountInput
};


export type MutationCreateWorkShopClassArgs = {
  input: CreateWorkShopClassCommand
};


export type MutationCreateWorkShopCourseArgs = {
  input: CreateWorkShopCourseInput
};


export type MutationDeductRewardPointsArgs = {
  input: DeductRewardPointsInput
};


export type MutationDeleteAcademicLevelArgs = {
  input: IdInput
};


export type MutationDeleteAcademicYearArgs = {
  input: IdInput
};


export type MutationDeleteAdHocMaterialArgs = {
  id: Scalars['ObjectId']
};


export type MutationDeleteBranchArgs = {
  input: IdInput
};


export type MutationDeleteCampaignArgs = {
  id: Scalars['ObjectId']
};


export type MutationDeleteCollectArgs = {
  input: DeleteCollectInput
};


export type MutationDeleteCourseNotificationArgs = {
  id: Scalars['ObjectId']
};


export type MutationDeleteDeliveryArgs = {
  id: Scalars['ObjectId']
};


export type MutationDeleteDepartmentArgs = {
  input: IdInput
};


export type MutationDeleteGroupAssignmentArgs = {
  id: Scalars['ObjectId']
};


export type MutationDeleteHandoutArgs = {
  id: Scalars['ObjectId']
};


export type MutationDeleteHolidayArgs = {
  input: IdInput
};


export type MutationDeleteInvoiceItemOptionArgs = {
  id: Scalars['ObjectId']
};


export type MutationDeleteLessonArgs = {
  id: Scalars['ObjectId']
};


export type MutationDeleteLessonFlowItemArgs = {
  id: Scalars['ObjectId']
};


export type MutationDeleteLessonSequenceItemArgs = {
  input: DeleteLessonSequenceInput
};


export type MutationDeleteLessonsArgs = {
  ids: Array<Scalars['ObjectId']>
};


export type MutationDeleteMerchandiseArgs = {
  input: IdInput
};


export type MutationDeleteParentArgs = {
  input: IdInput
};


export type MutationDeletePromotionArgs = {
  id: Scalars['ObjectId']
};


export type MutationDeleteReferenceDataArgs = {
  input: IdInput
};


export type MutationDeleteRemarkArgs = {
  input: IdInput
};


export type MutationDeleteSchoolArgs = {
  input: IdInput
};


export type MutationDeleteStudentArgs = {
  input: IdInput
};


export type MutationDeleteStudentReportFilterArgs = {
  id: Scalars['ObjectId']
};


export type MutationDeleteSubmissionArgs = {
  input: DeleteSubmissionInput
};


export type MutationDeleteTaskChecklistArgs = {
  id: Scalars['ObjectId']
};


export type MutationDeleteTaskChecklistItemArgs = {
  id: Scalars['ObjectId']
};


export type MutationDeleteTaskListArgs = {
  id: Scalars['ObjectId']
};


export type MutationDeleteTermArgs = {
  id: Scalars['ObjectId']
};


export type MutationDeleteTopicArgs = {
  input: DeleteTopicInput
};


export type MutationDeleteUserCustomiseColumnArgs = {
  input: DeleteUserCustomiseColumnInput
};


export type MutationDeleteUserCustomiseColumnsArgs = {
  inputs: Array<DeleteUserCustomiseColumnInput>
};


export type MutationDownloadMailMergeArgs = {
  id: Scalars['ObjectId']
};


export type MutationExportFailedImportClassesArgs = {
  id: Scalars['ObjectId']
};


export type MutationExportFailedImportRegistrationsArgs = {
  id: Scalars['ObjectId']
};


export type MutationExtendsArrangementTimeArgs = {
  id: Scalars['ObjectId']
};


export type MutationExtendsRegistrationTimeArgs = {
  id: Scalars['ObjectId']
};


export type MutationGenerateNextInvoiceArgs = {
  id: Scalars['ObjectId']
};


export type MutationIncrementStepArgs = {
  arrangementId: Scalars['ObjectId'],
  step?: Maybe<Scalars['Int']>
};


export type MutationLessonPlanAddLessonFlowHomeworkArgs = {
  input: LessonPlanAddLessonFlowHomeworkInput
};


export type MutationLessonPlanRemoveLessonFlowHomeworkArgs = {
  input: LessonPlanRemoveLessonFlowHomeworkInput
};


export type MutationLessonPlanReorderLessonFlowSchedulesArgs = {
  input: LessonPlanReorderLessonFlowSchedulesInput
};


export type MutationLessonPlanSkipLessonFlowItemArgs = {
  input: LessonPlanSkipLessonFlowItemInput
};


export type MutationLessonPlanUpdateLessonFlowHomeworkArgs = {
  input: LessonPlanUpdateLessonFlowHomeworkInput
};


export type MutationLessonPlanUpdateLessonFlowItemTrialFlagsArgs = {
  input: LessonPlanUpdateLessonFlowItemTrialFlagInput
};


export type MutationLinkParentArgs = {
  input: LinkParentInput
};


export type MutationMaterialHandoverUpdateArgs = {
  input: MaterialHandoverUpdateInput
};


export type MutationMergeParentArgs = {
  input: MergeParentInput
};


export type MutationMergeStudentArgs = {
  input: MergeStudentInput
};


export type MutationOnHoldInvoiceArgs = {
  input: OnHoldInvoiceCommand
};


export type MutationPackingListAddBookletsArgs = {
  input: PackingListEnvelopeAddBookletsInput
};


export type MutationPackingListAddEnvelopesArgs = {
  input: PackingListAddEnvelopesInput
};


export type MutationPackingListAddWorksheetsArgs = {
  input: PackingListAddWorksheetsInput
};


export type MutationPackingListChangeEnvelopeStatusArgs = {
  input: PackingListChangeEnvelopeStatusInput
};


export type MutationPackingListConfigurationCreateArgs = {
  input: PackingListConfigurationCreateInput
};


export type MutationPackingListConfigurationUpdateEnvelopesArgs = {
  input: PackingListConfigurationUpdateEnvelopesInput
};


export type MutationPackingListCreateArgs = {
  input: PackingListCreateInput
};


export type MutationPackingListEnvelopeMoveWorksheetToBookletArgs = {
  input: PackingListEnvelopeMoveWorksheetToBookletInput
};


export type MutationPackingListEnvelopeReduceAvailableUnitsArgs = {
  input: PackingListEnvelopeReduceAvailableUnitsInput
};


export type MutationPackingListPartialEnvelopeReduceAvailableUnitsArgs = {
  input: PackingListPartialEnvelopeReduceAvailableUnitsInput
};


export type MutationPackingListRemoveBookletsArgs = {
  input: PackingListRemoveBookletsInput
};


export type MutationPackingListRemoveEnvelopesArgs = {
  input: PackingListRemoveEnvelopesInput
};


export type MutationPackingListRemoveWorksheetsArgs = {
  input: PackingListRemoveWorksheetsInput
};


export type MutationPackingListReorderBookletsArgs = {
  input: PackingListReorderBookletsInput
};


export type MutationPackingListReorderWorksheetsArgs = {
  input: PackingListReorderWorksheetsInput
};


export type MutationPackingListTrialEnvelopeReduceAvailableUnitsArgs = {
  input: PackingListTrialEnvelopeReduceAvailableUnitsInput
};


export type MutationPackingListUpdatePackedUnitsArgs = {
  input: PackingListUpdatePackedUnitsInput
};


export type MutationPackingListUpdateTrialWorksheetAcademicWeekStatusArgs = {
  input: PackingListUpdateTrialWorksheetAcademicWeekStatusInput
};


export type MutationPackingListUpdateVersionArgs = {
  input: PackingListUpdateVersionInput
};


export type MutationPauseWhatsAppMessageArgs = {
  id: Scalars['ObjectId']
};


export type MutationPauseWhatsappAccountArgs = {
  id: Scalars['ObjectId']
};


export type MutationPinTaskListArgs = {
  id: Scalars['ObjectId']
};


export type MutationPlaceOrderDeliveryArgs = {
  deliveryId: Scalars['ObjectId']
};


export type MutationPrioritizeRegistrationArgs = {
  input: UpdateRegistrationInput
};


export type MutationProcessArrangementArgs = {
  id: Scalars['ObjectId']
};


export type MutationProcessRegistrationArgs = {
  id: Scalars['ObjectId']
};


export type MutationQueueNextArgs = {
  id: Scalars['ObjectId']
};


export type MutationReadSystemNotificationArgs = {
  id: Scalars['ObjectId']
};


export type MutationRecallCampaignArgs = {
  id: Scalars['ObjectId']
};


export type MutationReconnectWhatsappAccountArgs = {
  id: Scalars['ObjectId']
};


export type MutationRecordExamScoreArgs = {
  input: RecordExamScoreInput
};


export type MutationRecoverWhatsappAccountArgs = {
  id: Scalars['ObjectId']
};


export type MutationRegenerateInvoiceArgs = {
  id: Scalars['ObjectId']
};


export type MutationRegenerateRegistrationArgs = {
  input: RegenerateRegistrationInput
};


export type MutationRegisterStudentArgs = {
  input: RegisterStudentInput
};


export type MutationRegistrationIncrementStepArgs = {
  currentStep: Scalars['Int'],
  registrationId: Scalars['ObjectId']
};


export type MutationRemoveLessonFlowScheduleArgs = {
  input: RemoveLessonFlowScheduleInput
};


export type MutationRemoveLessonPlanFromClassArgs = {
  input: RemoveLessonPlanFromClassInput
};


export type MutationRemoveLineItemArgs = {
  lineItemId: Scalars['ObjectId']
};


export type MutationRemoveLineItemDiscountArgs = {
  lineItemId: Scalars['ObjectId']
};


export type MutationReopenTaskArgs = {
  id: Scalars['ObjectId']
};


export type MutationReorderLessonFlowItemsArgs = {
  input: ReorderLessonFlowItemsInput
};


export type MutationReplyFeedbackArgs = {
  input: ReplyFeedbackInput
};


export type MutationRequestChangeSystemUserPasswordArgs = {
  id: Scalars['ObjectId']
};


export type MutationRequeueWhatsAppMessageArgs = {
  id: Scalars['ObjectId']
};


export type MutationResendReminderArgs = {
  id: Scalars['ObjectId']
};


export type MutationResetDefaultArgs = {
  input: ResetDefaultInput
};


export type MutationResetMaterialStatusArgs = {
  id: Scalars['ObjectId']
};


export type MutationRestoreDiscountArgs = {
  id: Scalars['ObjectId']
};


export type MutationRestoreLessonFlowItemVersionArgs = {
  input: RestoreLessonFlowItemVersion
};


export type MutationRestoreRewardArgs = {
  id: Scalars['ObjectId']
};


export type MutationResumeWhatsAppMessageArgs = {
  id: Scalars['ObjectId']
};


export type MutationResumeWhatsappAccountArgs = {
  id: Scalars['ObjectId']
};


export type MutationRetryUploadInvoiceArgs = {
  date: Scalars['DateTime'],
  invoiceId: Scalars['ObjectId']
};


export type MutationRetryWhatsAppMessageArgs = {
  id: Scalars['ObjectId']
};


export type MutationRetryXeroPaymentArgs = {
  paymentId: Scalars['ObjectId']
};


export type MutationSaveAsNewLessonFlowArgs = {
  input: SaveAsNewLessonFlowInput
};


export type MutationSaveSubmissionsArgs = {
  input: SaveSubmissionsInput
};


export type MutationSaveTopicsArgs = {
  input: SaveTopicsInput
};


export type MutationSendReminderArgs = {
  input: SendReminderInput
};


export type MutationSendTestReminderArgs = {
  input: SendTestReminderInput
};


export type MutationSetDefaultLessonFlowArgs = {
  id: Scalars['ObjectId']
};


export type MutationSetLessonFlowItemExceptionsArgs = {
  input: SetLessonFlowItemExceptionsInput
};


export type MutationTestSubmissionCreateArgs = {
  input: TestSubmissionCreateInput
};


export type MutationTestSubmissionMakeDraftArgs = {
  input: TestSubmissionMakeDraftInput
};


export type MutationTestSubmissionSaveMarkedResourceArgs = {
  input: TestSubmissionSaveMarkedResourceInput
};


export type MutationTestSubmissionSaveScoresArgs = {
  input: TestSubmissionSaveScoresInput
};


export type MutationTestSubmissionUpdateArgs = {
  input: TestSubmissionUpdateInput
};


export type MutationToggleFeatureArgs = {
  input: UpdateFeatureAccessInput
};


export type MutationToggleFeaturesArgs = {
  input: UpdateFeatureAccessesInput
};


export type MutationTopicAddCourseArgs = {
  input: TopicAddCourseInput
};


export type MutationTopicRemoveCourseArgs = {
  input: TopicRemoveCourseInput
};


export type MutationUnarchiveWorksheetsArgs = {
  input: UnarchiveWorkSheetsInput
};


export type MutationUnlinkParentArgs = {
  input: LinkParentInput
};


export type MutationUpdateAcademicLevelArgs = {
  input: UpdateAcademicLevelInput
};


export type MutationUpdateAcademicYearArgs = {
  input: UpdateAcademicYearInput
};


export type MutationUpdateArchivableArgs = {
  id: Scalars['ObjectId'],
  isArchived: Scalars['Boolean']
};


export type MutationUpdateAttendanceLessonModeArgs = {
  input: UpdateAttendanceLessonModeInput
};


export type MutationUpdateAttendancesArgs = {
  input: UpdateAttendancesInput
};


export type MutationUpdateBranchArgs = {
  input: UpdateBranchInput
};


export type MutationUpdateCampaignArgs = {
  input: UpdateCampaignInput
};


export type MutationUpdateClassDigitalCredentialArgs = {
  command: UpdateClassDigitalCredentialCommand
};


export type MutationUpdateClassFeeArgs = {
  command: UpdateClassFeeCommand
};


export type MutationUpdateClassLearningArrangementArgs = {
  command: UpdateClassLearningArrangementCommand
};


export type MutationUpdateClassLessonFlowArgs = {
  input: ChangeClassLessonFlowInput
};


export type MutationUpdateClassNameArgs = {
  command: UpdateClassNameCommand
};


export type MutationUpdateClassScheduleArgs = {
  command: UpdateClassScheduleCommand
};


export type MutationUpdateClassTeacherArgs = {
  command: UpdateClassTeacherCommand
};


export type MutationUpdateClassVenueArgs = {
  command: UpdateClassVenueCommand
};


export type MutationUpdateCollectArgs = {
  input: UpdateCollectInput
};


export type MutationUpdateCourierPickupAddressArgs = {
  input: UpdateCourierPickupAddressInput
};


export type MutationUpdateCourseArgs = {
  input: UpdateCourseInput
};


export type MutationUpdateDeliveryArgs = {
  input: UpdateDeliveryInput
};


export type MutationUpdateDepartmentArgs = {
  input: UpdateDepartmentInput
};


export type MutationUpdateDiscountArgs = {
  input: UpdateDiscountInput
};


export type MutationUpdateEligibilityFlowArgs = {
  input: UpdateEligibilityFlowInput
};


export type MutationUpdateEnrollmentEndRecurringArgs = {
  id: Scalars['ObjectId'],
  isEndRecurring: Scalars['Boolean']
};


export type MutationUpdateEnrollmentFollowUpStatusArgs = {
  input: UpdateEnrollmentFollowUpStatusInput
};


export type MutationUpdateEnrollmentGroupArgs = {
  input: UpdateEnrollmentGroupInput
};


export type MutationUpdateEnrollmentPreferredLearningArrangementArgs = {
  input: UpdateEnrollmentPreferredLearningArrangementInput
};


export type MutationUpdateEnrollmentSuitabilityArgs = {
  input: UpdateEnrollmentSuitabilityInput
};


export type MutationUpdateEnrollmentsArgs = {
  enrollments: Array<UpdateEnrollmentsInput>
};


export type MutationUpdateEnrollmentsPreferredLearningArrangementArgs = {
  input: UpdateEnrollmentsPreferredLearningArrangementInput
};


export type MutationUpdateExamScoreArgs = {
  input: UpdateExamScoreInput
};


export type MutationUpdateExerciseArgs = {
  input: UpdateExerciseInput
};


export type MutationUpdateFeedbackArgs = {
  input: UpdateFeedbackInput
};


export type MutationUpdateGroupAssignmentArgs = {
  input: UpdateGroupAssignmentInput
};


export type MutationUpdateHandoutArgs = {
  input: UpdateHandoutInput
};


export type MutationUpdateHolidayArgs = {
  input: UpdateHolidayInput
};


export type MutationUpdateHomeworkArgs = {
  input: UpdateHomeworkInput
};


export type MutationUpdateIntegrationClientInfoArgs = {
  input: UpdateIntegrationCommand
};


export type MutationUpdateInvoiceDueDateArgs = {
  input: UpdateInvoiceDueDateCommand
};


export type MutationUpdateInvoiceItemArgs = {
  input: UpdateInvoiceLineItemCommand
};


export type MutationUpdateInvoiceItemOptionArgs = {
  input: UpdateInvoiceItemOptionInput
};


export type MutationUpdateLessonArgs = {
  input: UpdateLessonCommand
};


export type MutationUpdateLessonAttendanceArgs = {
  input: UpdateLessonAttendanceInput
};


export type MutationUpdateLessonFlowArgs = {
  input: UpdateLessonFlowInput
};


export type MutationUpdateLessonFlowArchiveArgs = {
  archive: Scalars['Boolean'],
  id: Scalars['ObjectId']
};


export type MutationUpdateLessonFlowItemArgs = {
  input: UpdateLessonFlowItemInput
};


export type MutationUpdateLessonFlowScheduleArgs = {
  input: UpdateLessonFlowScheduleInput
};


export type MutationUpdateLessonSequenceItemArgs = {
  input: UpdateLessonSequenceInput
};


export type MutationUpdateLogArgs = {
  input: UpdateLogInput
};


export type MutationUpdateMailMergeArgs = {
  input: UpdateMailMergeInput
};


export type MutationUpdateMaterialDeliveryOptionArgs = {
  input: UpdateMaterialDeliveryOptionInput
};


export type MutationUpdateMerchandiseArgs = {
  input: UpdateMerchandiseInput
};


export type MutationUpdateNotificationItemArgs = {
  input: NotificationInput
};


export type MutationUpdateParentArgs = {
  input: UpdateParentInput
};


export type MutationUpdatePaymentRecordArgs = {
  input: UpdatePaymentRecordInput
};


export type MutationUpdatePromotionArgs = {
  input: UpdatePromotionInput
};


export type MutationUpdateReferenceDataArgs = {
  input: UpdateReferenceDataInput
};


export type MutationUpdateRegistrationArgs = {
  input: UpdateRegistrationInput
};


export type MutationUpdateRelationNameArgs = {
  input: UpdateRelationshipInput
};


export type MutationUpdateRemarkArgs = {
  input: UpdateRemarkInput
};


export type MutationUpdateRewardArgs = {
  input: UpdateRewardCatalogInput
};


export type MutationUpdateRewardsHistoryArgs = {
  input: UpdateRewardsHistoryInput
};


export type MutationUpdateSchoolArgs = {
  input: UpdateSchoolInput
};


export type MutationUpdateStudentArgs = {
  input: UpdateStudentInput
};


export type MutationUpdateStudentRemarksArgs = {
  input: UpdateStudentRemarksInput
};


export type MutationUpdateStudentReportFilterArgs = {
  input: UpdateStudentReportFilterInput
};


export type MutationUpdateTaskArgs = {
  input: UpdateTaskInput
};


export type MutationUpdateTaskChecklistArgs = {
  input: UpdateTaskChecklistInput
};


export type MutationUpdateTaskChecklistItemArgs = {
  input: UpdateTaskChecklistItemInput
};


export type MutationUpdateTaskDueDateArgs = {
  dueDate?: Maybe<Scalars['DateTime']>,
  id: Scalars['ObjectId']
};


export type MutationUpdateTaskListArgs = {
  input: UpdateTaskListInput
};


export type MutationUpdateTemplateArgs = {
  input: UpdateTemplateInput
};


export type MutationUpdateTopicArgs = {
  input: UpdateTopicInput
};


export type MutationUpdateUserCustomiseColumnArgs = {
  input: UpdateUserCustomiseColumnInput
};


export type MutationUpdateUserCustomiseColumnsArgs = {
  inputs: Array<UpdateUserCustomiseColumnInput>
};


export type MutationUpdateVenueArgs = {
  command: UpdateVenueCommand
};


export type MutationUpdateWhatsappAccountArgs = {
  input: UpdateWhatsappAccountInput
};


export type MutationUpdateWorkSheetArgs = {
  input: UpdateWorksheetInput
};


export type MutationUploadBase64FileArgs = {
  body: Scalars['String'],
  path: UploadFilePath
};


export type MutationUploadFileArgs = {
  file: Scalars['Upload'],
  path: UploadFilePath
};


export type MutationUploadMailMergeMergeListArgs = {
  input: UploadMailMergeMergeListInput
};


export type MutationUpsertUserCustomiseColumnArgs = {
  input: UpsertUserCustomiseColumnInput
};


export type MutationUpsertUserCustomiseColumnsArgs = {
  inputs: Array<UpsertUserCustomiseColumnInput>
};


export type MutationUseRewardPointsArgs = {
  input: UseRewardPointsInput
};


export type MutationValidateIntegrationAccessTokenArgs = {
  input: ValidateIntegrationAccessTokenCommand
};


export type MutationValidateWhatsappNumberArgs = {
  input: ValidateWhatsappNumberInput
};


export type MutationVoidClassArgs = {
  input: VoidInput
};


export type MutationVoidCourseArgs = {
  input: VoidInput
};


export type MutationVoidInvoiceArgs = {
  id: Scalars['ObjectId']
};


export type MutationVoidParentArgs = {
  input: VoidInput
};


export type MutationVoidPaymentRecordArgs = {
  input: VoidPaymentRecordInput
};


export type MutationVoidRefundArgs = {
  id: Scalars['ObjectId']
};


export type MutationVoidStudentArgs = {
  input: VoidInput
};


export type MutationVoidTaskArgs = {
  id: Scalars['ObjectId']
};


export type MutationVoidTasksArgs = {
  command: BulkVoidTaskCommand
};


export type MutationWhatsappLoginArgs = {
  id: Scalars['ObjectId']
};


export type MutationWhatsappLogoutArgs = {
  id: Scalars['ObjectId']
};


export type MutationWorksheetAddCourseArgs = {
  input: WorksheetAddCourseInput
};


export type MutationWorksheetCreateArgs = {
  input: WorksheetCreateInput
};


export type MutationWorksheetRemoveCourseArgs = {
  input: WorksheetRemoveCourseInput
};


export type MutationWorksheetsCreateArgs = {
  input: WorksheetsCreateInput
};


export type MutationWorksheetsParseCsvArgs = {
  input: WorksheetsParseCsvInput
};


export type MutationXeroApiCallbackArgs = {
  callbackUrl: Scalars['String']
};

export type Note = {
   __typename?: 'Note',
  _id: Scalars['ObjectId'],
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  note: Scalars['String'],
  userId: Scalars['ObjectId'],
};

export type NotePage = {
   __typename?: 'NotePage',
  items: Array<Note>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

export enum NotificationChannel {
  Email = 'Email',
  Whatsapp = 'Whatsapp'
}

export type NotificationCondition = {
   __typename?: 'NotificationCondition',
  attendanceStatuses: Array<AttendanceStatus>,
  attendanceTypes: Array<AttendanceType>,
  classStatuses: Array<ClassStatus>,
  courseStatuses: Array<CourseStatus>,
  courseTypes: Array<CourseType>,
  enrollmentStatuses: Array<EnrollmentStatus>,
  enrollmentTypes: Array<EnrollmentType>,
  invoiceStatuses: Array<InvoiceStatus>,
};

export type NotificationHyperLink = {
   __typename?: 'NotificationHyperLink',
  id: Scalars['ObjectId'],
  keyword: Scalars['String'],
  type: NotificationHyperLinkType,
};

export enum NotificationHyperLinkType {
  Class = 'Class',
  Parent = 'Parent',
  SystemUser = 'SystemUser',
  TaskList = 'TaskList',
  UserRequest = 'UserRequest'
}

export type NotificationInput = {
  id: Scalars['ObjectId'],
  isEnabled?: Maybe<Scalars['Boolean']>,
  templateId?: Maybe<Scalars['ObjectId']>,
};

export type NotificationSetting = {
   __typename?: 'NotificationSetting',
  _id: Scalars['ObjectId'],
  conditions: NotificationCondition,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  daysBefore: Scalars['Int'],
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  groupName: Scalars['String'],
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  isEnabled: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  name: Scalars['String'],
  template?: Maybe<Template>,
  type: NotificationType,
};

export enum NotificationType {
  LessonReminder = 'LessonReminder'
}

export type NotifyMissingDto = {
   __typename?: 'NotifyMissingDTO',
  class?: Maybe<Class>,
  classId?: Maybe<Scalars['ObjectId']>,
  course?: Maybe<Course>,
  courseId?: Maybe<Scalars['ObjectId']>,
  missingFields: Array<Scalars['String']>,
  parent?: Maybe<Parent>,
  parentId: Scalars['ObjectId'],
  student?: Maybe<Student>,
  studentId?: Maybe<Scalars['ObjectId']>,
  teacher?: Maybe<SystemUser>,
  teacherId?: Maybe<Scalars['ObjectId']>,
};

export type NumberRangeInput = {
  end: Scalars['Int'],
  start: Scalars['Int'],
};



export type OnHoldInvoiceCommand = {
  id: Scalars['ObjectId'],
  isOnHold: Scalars['Boolean'],
  onHoldRemarks?: Maybe<Scalars['String']>,
};

export type OrderHistory = {
   __typename?: 'OrderHistory',
  /** Unique identifier for the record */
  _id: Scalars['ObjectId'],
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  status: RewardHistoryStatus,
};

export type OtherFeeInput = {
  amount?: Maybe<Scalars['Float']>,
  isApplicable: Scalars['Boolean'],
  name: OtherFeeType,
};

export type OtherFeeOutput = {
   __typename?: 'OtherFeeOutput',
  amount?: Maybe<Scalars['Float']>,
  isApplicable: Scalars['Boolean'],
  name: OtherFeeType,
};

export enum OtherFeeType {
  Additional = 'Additional',
  Deposit = 'Deposit',
  Materials = 'Materials',
  Registration = 'Registration',
  Trial = 'Trial'
}

export type OtherMappingAccount = {
   __typename?: 'OtherMappingAccount',
  _id: Scalars['ObjectId'],
  code: Scalars['String'],
  description: Scalars['String'],
  name: Scalars['String'],
  otherFeeType: OtherFeeType,
  taxType?: Maybe<Scalars['String']>,
  type: AccountType,
  xeroAccountsSyncStatus?: Maybe<Scalars['Boolean']>,
};

/** The list of the materials of a course to be packed. There could be one or more packing list under one course. */
export type PackingList = {
   __typename?: 'PackingList',
  /** Unique identifier for the record. */
  _id: Scalars['ObjectId'],
  /** The id of the packing list configuration that was applied to this packing list. */
  configurationId?: Maybe<Scalars['ObjectId']>,
  /** Associated course to the packing list. This record is referenced to an existing "course" record. */
  course: PackingListCourse,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  /** 
 * The envelopes under the packing list. These contains the items being packed.
   *      - Packing Lists on REGULAR courses should have 7 envelopes.
   *      - Packing Lists on WORKSHOP courses should only have 1 envelope.
 */
  envelopes: Array<PackingListEnvelope>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  /** Associated lesson plan to the packing list. */
  lessonPlan: PackingListLessonPlan,
  /** The name of the packing list. */
  name: Scalars['String'],
  /** The envelopes for late enrollments. */
  partialEnvelopes: Array<PackingListPartialEnvelope>,
  /** The envelopes for trial enrollments in GAP classes. This contains worksheets to be delivered to trial students. */
  trialEnvelopes: Array<PackingListTrialEnvelope>,
  /** Version of the packing list composed of month and academic year. */
  version: PackingListVersion,
  /** Display student who is currently enrolled this course, supposed to receive this materials but not yet paid. */
  willGeneratedMaterialEnrollments?: Maybe<Array<Enrollment>>,
};


/** The list of the materials of a course to be packed. There could be one or more packing list under one course. */
export type PackingListWillGeneratedMaterialEnrollmentsArgs = {
  envelopeId: Scalars['ObjectId']
};

/** 
 * This is to indicate a list of academic week each envelope is assigned to. Only
 * for REGULAR classes. Null for WORKSHOP classes.
 */
export type PackingListAcademicWeek = {
   __typename?: 'PackingListAcademicWeek',
  /** Unique identifier of the record. This is copied from the referenced academic week record. */
  _id: Scalars['ObjectId'],
  /** The date of the last day of the week. */
  endDate: Scalars['DateTime'],
  /** The date of the first day of the week. */
  startDate: Scalars['DateTime'],
  /** The week number in an academic year. */
  weekNumber: Scalars['Float'],
};

/** Academic week range for packing list add envelope input. */
export type PackingListAddEnvelopeAcademicWeekDateRangeInput = {
  /** ID of the academic week end. */
  academicWeekEndId: Scalars['ObjectId'],
  /** ID of the academic week start. */
  academicWeekStartId: Scalars['ObjectId'],
};

/** GraphQL input for adding an envelope to a packing list. */
export type PackingListAddEnvelopeInput = {
  /** Academic weeks to associated the packing list to. This is required when the packing list course type is Regular. */
  academicWeekDateRange?: Maybe<PackingListAddEnvelopeAcademicWeekDateRangeInput>,
  /** URL of the image cover of the envelope. */
  imageUrl?: Maybe<Scalars['String']>,
  /** Title of the envelope. */
  title: Scalars['String'],
};

/** Input for adding an envelope to a packing list record. */
export type PackingListAddEnvelopesInput = {
  /** Envelopes to add to a packing list. */
  envelopes: Array<PackingListAddEnvelopeInput>,
  /** ID of the packing list to add the envelope to */
  packingListId: Scalars['ObjectId'],
};

/** Input for the Packing List Add Worksheets mutation. */
export type PackingListAddWorksheetsInput = {
  /** The booklet of the packing list to add the worksheet. */
  bookletId: Scalars['ObjectId'],
  /** The envelope of the packing list to add the worksheet. */
  envelopeId: Scalars['ObjectId'],
  /** The packing list to add the worksheet. */
  packingListId: Scalars['ObjectId'],
  /** The worksheets to be added to the booklet of the packing list. */
  worksheets: Array<PackingListWorksheetInput>,
};

/** The topic of the packing list booklet. */
export type PackingListBookletTopic = {
   __typename?: 'PackingListBookletTopic',
  /** Unique identifier of the record. The value of this field will be coming from the referenced "topic" record. */
  _id: Scalars['ObjectId'],
  /** Name of the packing list booklet. The value of this field will be coming from the referenced "topic" record. */
  name: Scalars['String'],
};

/** Input for `packingListChangeEnvelopeStatus` mutation. */
export type PackingListChangeEnvelopeStatusInput = {
  /** The envelope to change the status. */
  envelopeId: Scalars['ObjectId'],
  /** The packing list containing the envelope to change status. */
  packingListId: Scalars['ObjectId'],
  /** The desired status of the envelope. */
  status: PackingListEnvelopeStatus,
};

/** The configuration for the packing list feature. */
export type PackingListConfiguration = {
   __typename?: 'PackingListConfiguration',
  /** Unique identifier for the record. */
  _id: Scalars['ObjectId'],
  /** This configuration would only apply to the packing lists under this academic year. */
  academicYear: PackingListConfigurationAcademicYear,
  /** This configuration would only apply to the packing lists under a course with this course type. */
  courseType?: Maybe<Scalars['String']>,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  /** The envelope presets for the affected packing lists. */
  envelopePresets: Array<PackingListConfigurationEnvelopePreset>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
};

/** Subset of the academic year entity. */
export type PackingListConfigurationAcademicYear = {
   __typename?: 'PackingListConfigurationAcademicYear',
  /** Unique ID referencing to the corresponding academic year record. */
  _id: Scalars['ObjectId'],
  /** The year copied from the academic year record. */
  year: Scalars['Float'],
};

/** The academic week range to set for this envelope. */
export type PackingListConfigurationCreateEnvelopeAcademicWeekRangeInput = {
  /** The ending week id to set for this envelope. */
  endingWeekId: Scalars['ObjectId'],
  /** The starting week id to set for this envelope. */
  startingWeekId: Scalars['ObjectId'],
};

/** The individual packing list configuration envelope to create. */
export type PackingListConfigurationCreateEnvelopeInput = {
  /** The acdemic week range to set for this envelope. Set this to `null` to not set anything. */
  academicWeekRange?: Maybe<PackingListConfigurationCreateEnvelopeAcademicWeekRangeInput>,
  /** The title to set for this envelope. If not specified or set, this defaults to `Envelope {n}` */
  title?: Maybe<Scalars['String']>,
};

/** Input for `packingListConfigurationCreate`. */
export type PackingListConfigurationCreateInput = {
  /** 
 * The ID of the academic year the packing list configuration is associated to. 
   *     This would limit the packing lists that this configuration applies to the packing lists under this academic year.
 */
  academicYearId: Scalars['ObjectId'],
  /** The course type to create the packing list configuration. */
  courseType: CourseType,
  /** The individual envelope configurations to assign to this packing list configuration. */
  envelopes: Array<PackingListConfigurationCreateEnvelopeInput>,
};

/** The academic week copied from the academic year entity. */
export type PackingListConfigurationEnvelopeAcademicWeek = {
   __typename?: 'PackingListConfigurationEnvelopeAcademicWeek',
  /** Unique ID referencing to the corresponding academic week in the academic year record. */
  _id: Scalars['ObjectId'],
  /** End date copied from the academic week. */
  endDate: Scalars['DateTime'],
  /** Start date copied from the academic week. */
  startDate: Scalars['DateTime'],
  /** Week number copied from the academic week. */
  weekNumber: Scalars['Float'],
};

/** This is to indicate a list of academic week each envelope is assigned to. */
export type PackingListConfigurationEnvelopeAcademicWeekRange = {
   __typename?: 'PackingListConfigurationEnvelopeAcademicWeekRange',
  /** The ending week number of the envelope. */
  endingWeek: PackingListConfigurationEnvelopeAcademicWeek,
  /** The starting week number of the envelope. */
  startingWeek: PackingListConfigurationEnvelopeAcademicWeek,
};

/** The envelope options to choose from for creating a new envelope. */
export type PackingListConfigurationEnvelopePreset = {
   __typename?: 'PackingListConfigurationEnvelopePreset',
  /** Unique identifier of the record. */
  _id: Scalars['ObjectId'],
  /** 
 * This is to indicate the academic week range each envelope is assigned to.
   *     This is only applicable to REGULAR/GAP courses.
 */
  academicWeekRange?: Maybe<PackingListConfigurationEnvelopeAcademicWeekRange>,
  /** The title to display for this envelope. When not specified during creation, this defaults to 'Envelope {n}' */
  title: Scalars['String'],
  /** This is used to track the current latest version of the envelope. */
  version?: Maybe<Scalars['Float']>,
};

/** Input for `packingListConfiguration`. */
export type PackingListConfigurationInput = {
  /** Gets the packing list configuration matching the specified year. */
  academicYearId: Scalars['ObjectId'],
  /** Gets the packing list configuration matching the specified course type. */
  courseType: CourseType,
};

/** The individual packing list configuration envelope to update. */
export type PackingListConfigurationUpdateEnvelopeInput = {
  /** The envelope to update. */
  _id: Scalars['ObjectId'],
  /** The ending week id to set for this envelope. */
  endingWeekId: Scalars['ObjectId'],
  /** The starting week id to set for this envelope. */
  startingWeekId: Scalars['ObjectId'],
};

/** Input for `packingListConfigurationUpdateEnvelopes` mutation. */
export type PackingListConfigurationUpdateEnvelopesInput = {
  /** The individual envelope configurations to update in this packing list configuration. */
  envelopes: Array<PackingListConfigurationUpdateEnvelopeInput>,
  /** The packing list configuration containing the envelopes to update. */
  packingListConfigurationId: Scalars['ObjectId'],
};

/** Associated course to the packing list. This record is referenced to an existing "course" record. */
export type PackingListCourse = {
   __typename?: 'PackingListCourse',
  /** Unique ID of the packing list course record. This value is from the ID of the referenced course record. */
  _id: Scalars['ObjectId'],
  /** The name of the course. This value is from the "name" field of the referenced course. */
  name: Scalars['String'],
  /** The course type copied from the associated course record. */
  type: CourseType,
};

/** Academic week range for packing list add envelope input. */
export type PackingListCreateEnvelopeAcademicWeekDateRangeInput = {
  /** ID of the academic week end. */
  academicWeekEndId: Scalars['ObjectId'],
  /** ID of the academic week start. */
  academicWeekStartId: Scalars['ObjectId'],
};

/** Booklets to be added to the packing list envelope being created. */
export type PackingListCreateEnvelopeBookletInput = {
  /** URL of the image to be used as the booklet's cover image. */
  imageUrl?: Maybe<Scalars['String']>,
  /** Title of the booklet. */
  title: Scalars['String'],
  /** ID of the topic to associate the booklet to. This should be associated to the packing list course. */
  topicId: Scalars['ObjectId'],
  /** Worksheets to be added to the booklet. */
  worksheets?: Maybe<Array<PackingListCreateEnvelopeBookletWorksheetInput>>,
};

/** Worksheets to add to the packing list envelope booklet. */
export type PackingListCreateEnvelopeBookletWorksheetInput = {
  /** 
 * The worksheet to add to the booklet of the packing list. Worksheet should be
   * associated to the packing list course and booklet topic.
 */
  id: Scalars['ObjectId'],
  /** URL of the cover image of the worksheet. */
  imageUrl?: Maybe<Scalars['String']>,
};

/** Input for including an envelope to the packing list record being created. */
export type PackingListCreateEnvelopeInput = {
  /** 
 * Academic weeks to associated the packing list to. This is required if the
   * course's type associated with the packing list is 'Regular'.
 */
  academicWeekDateRange?: Maybe<PackingListCreateEnvelopeAcademicWeekDateRangeInput>,
  /** Booklets to be added to the envelope. */
  booklets?: Maybe<Array<PackingListCreateEnvelopeBookletInput>>,
  /** URL of the image cover of the envelope. */
  imageUrl?: Maybe<Scalars['String']>,
  /** Title of the envelope. */
  title: Scalars['String'],
};

/** GraphQL input for creating a packing list. */
export type PackingListCreateInput = {
  /** ID of the academic year associated with the packing list. */
  academicYearId: Scalars['ObjectId'],
  /** ID of the course associated with the packing list. */
  courseId: Scalars['ObjectId'],
  /** Envelopes to be included in the packing list record being created. */
  envelopes?: Maybe<Array<PackingListCreateEnvelopeInput>>,
  /** Name of the packing list. */
  name: Scalars['String'],
};

/** This serves as a grouping mechanism for the booklets. */
export type PackingListEnvelope = {
   __typename?: 'PackingListEnvelope',
  /** Unique identifier of the record. */
  _id: Scalars['ObjectId'],
  /** 
 * This is to indicate a list of academic week each envelope is assigned to.
   *     This is only applicable to REGULAR courses.
 */
  academicWeeks: Array<PackingListAcademicWeek>,
  /** The booklet details keyed by the booklet id. */
  booklets: Array<PackingListEnvelopeBooklet>,
  /** The id of the envelope preset that was copied. */
  envelopePresetId?: Maybe<Scalars['ObjectId']>,
  /** The version of the envelope preset that was copied from the packing list configuration. */
  envelopePresetVersion?: Maybe<Scalars['Float']>,
  /** Image URL of the cover image of the envelope. */
  imageUrl?: Maybe<Scalars['String']>,
  /** Is used material selected all booklets. */
  isSelectedAll?: Maybe<Scalars['Boolean']>,
  /** Packing status of the envelope. */
  status: PackingListEnvelopeStatus,
  /** The title of the envelope when it was added. */
  title: Scalars['String'],
  /** Quantity data for the envelope. */
  totalUnits: PackingListEnvelopeUnits,
};

/** A single booklet input to add to an existing envelope. */
export type PackingListEnvelopeAddBookletInput = {
  /** URL of the cover image of the booklet. */
  imageUrl?: Maybe<Scalars['String']>,
  /** Title of the booklet to add. */
  title: Scalars['String'],
  /** ID of the topic of the booklet. */
  topicId: Scalars['ObjectId'],
};

/** Input for adding booklets to an existing packing list envelope. */
export type PackingListEnvelopeAddBookletsInput = {
  /** Booklets to add to an existing packing list envelope. */
  booklets: Array<PackingListEnvelopeAddBookletInput>,
  /** ID of the packing list envelope to add the booklets to. */
  envelopeId: Scalars['ObjectId'],
};

/** This a small unit of a book, which contains the topic, the title, and the worksheets. */
export type PackingListEnvelopeBooklet = {
   __typename?: 'PackingListEnvelopeBooklet',
  /** Unique identifier of the record. */
  _id: Scalars['ObjectId'],
  /** Image URL of the booklet cover. */
  imageUrl?: Maybe<Scalars['String']>,
  /** Is used material booklet selected all worksheets. */
  isSelectedAll?: Maybe<Scalars['Boolean']>,
  /** Serial number of a booklet in 0.0 format. */
  serialNumber: Scalars['String'],
  /** Title of the booklet. */
  title: Scalars['String'],
  /** Topic of the booklet. */
  topic: PackingListBookletTopic,
  /** Worksheets under the booklet. */
  worksheets: Array<PackingListEnvelopeBookletWorksheet>,
};

/** A worksheet under a packing list booklet. */
export type PackingListEnvelopeBookletWorksheet = {
   __typename?: 'PackingListEnvelopeBookletWorksheet',
  /** Unique identifier of the record. This value is from the referenced 'worksheet' record. */
  _id: Scalars['ObjectId'],
  /** Image URL of the worksheet cover. */
  imageUrl?: Maybe<Scalars['String']>,
  /** Name of the worksheet. This is coming from the referenced "worksheet" record. */
  name: Scalars['String'],
  /** Serial number of a worksheet in 0.0.A format. */
  serialNumber: Scalars['String'],
  /** Title of the worksheet. This is coming from the referenced "worksheet" record. */
  title: Scalars['String'],
};

/** Input for the Packing List Envelope Move Worksheet to Booklet mutation. */
export type PackingListEnvelopeMoveWorksheetToBookletInput = {
  /** The booklet where the worksheet-to-move will be moved to. */
  destinationBookletId: Scalars['ObjectId'],
  /** The envelope where the worksheet-to-move is found. */
  envelopeId: Scalars['ObjectId'],
  /** The packing list to update the envelope of. */
  packingListId: Scalars['ObjectId'],
  /** 
 * Optional: Position of the worksheet in the destination booklet. A positive
   * number from 1 to n. If not provided will move worksheet to the last position
   * in the destination booklet.
 */
  position?: Maybe<Scalars['Float']>,
  /** The booklet where the worksheet-to-move originates from. */
  sourceBookletId: Scalars['ObjectId'],
  /** The booklet where the worksheet-to-move will be moved to. */
  worksheetId: Scalars['ObjectId'],
};

/** Input for the Packing List Envelope Reduce Available Units mutation. */
export type PackingListEnvelopeReduceAvailableUnitsInput = {
  /** The envelope to reduce the available units of. */
  envelopeId?: Maybe<Scalars['ObjectId']>,
  /** The packing list to update the envelope of. */
  packingListId: Scalars['ObjectId'],
  /** Value to reduce 'available' units by. */
  unitsReduced: Scalars['Float'],
};

export enum PackingListEnvelopeStatus {
  Compiled = 'Compiled',
  Delayed = 'Delayed',
  InProgress = 'InProgress',
  Packed = 'Packed',
  Printed = 'Printed'
}

/** This serves as a grouping mechanism for the packing list envelope units. */
export type PackingListEnvelopeUnits = {
   __typename?: 'PackingListEnvelopeUnits',
  /** Number of envelopes already allocated to a student. */
  allocated: Scalars['Float'],
  /** 'Required' units of the envelope or the number of students who need to be allocated an envelope. */
  needed: Scalars['Float'],
  /** 'Available' units of the envelope or the number of envelopes ready to be allocated */
  packed: Scalars['Float'],
};

/** Input for the `packingListEnvelopeUnits` query. */
export type PackingListEnvelopeUnitsInput = {
  /** The id of the 'full' envelope to get the envelope units from. */
  envelopeId?: Maybe<Scalars['ObjectId']>,
  /** The id of the packing list to get the envelope units from. */
  packingListId: Scalars['ObjectId'],
  /** The id of the 'partial' envelope to get the envelope units from. */
  partialEnvelopeId?: Maybe<Scalars['ObjectId']>,
  /** The id of 'trial' the envelope to get the envelope units from. */
  trialEnvelopeId?: Maybe<Scalars['ObjectId']>,
};

/** 
 * Lesson flow embedded in the packing list envelopes.
 *     Used to determine which lesson flow a packing list envelope is assigned to.
 *     Can be used for both REGULAR and WORKSHOP lessons.
 */
export type PackingListLessonFlow = {
   __typename?: 'PackingListLessonFlow',
  /** Unique identifier of the record. */
  _id: Scalars['ObjectId'],
  /** 
 * This is to indicate a list of academic week each envelope is assigned to. Only
   * for REGULAR classes. Null for WORKSHOP classes.
 */
  academicWeek?: Maybe<PackingListAcademicWeek>,
  /** The lesson number assigned to this lesson flow. Starts at `1`. Can be used for both REGULAR and WORKSHOP classes. */
  lessonNumber: Scalars['Float'],
};

/** Associated lesson plan of the packing list. */
export type PackingListLessonPlan = {
   __typename?: 'PackingListLessonPlan',
  /** Unique ID of the referenced lesson plan. */
  _id: Scalars['ObjectId'],
  /** The name of the lesson plan. This value is from the "name" field of the referenced lesson plan. */
  name: Scalars['String'],
};

/** This contains the list to be used for late enrollments, specific enrollments. */
export type PackingListPartialEnvelope = {
   __typename?: 'PackingListPartialEnvelope',
  /** Unique identifier of the record. */
  _id: Scalars['ObjectId'],
  /** 
 * This is to indicate a list of academic week each envelope is assigned to.
   *     This is only applicable to REGULAR courses.
 */
  academicWeeks: Array<PackingListAcademicWeek>,
  /** The booklets included in this envelope. */
  booklets: Array<PackingListPartialEnvelopeBooklet>,
  /** Id of the envelope where this partial envelope was taken from. */
  envelopeSourceId: Scalars['ObjectId'],
  /** This is to indicate a list of lesson flows that the envelope is assigned to. */
  lessonFlows: Array<PackingListLessonFlow>,
  /** Packing status of the envelope. */
  status: PackingListPartialEnvelopeStatus,
  /** The title of the envelope when it was added. */
  title: Scalars['String'],
  /** Quantity data for the envelope. */
  totalUnits: PackingListEnvelopeUnits,
};

/** This a small unit of a book, which contains the topic, the title, and the worksheets. */
export type PackingListPartialEnvelopeBooklet = {
   __typename?: 'PackingListPartialEnvelopeBooklet',
  /** Unique identifier of the record. */
  _id: Scalars['ObjectId'],
  /** Image URL of the booklet cover. */
  imageUrl?: Maybe<Scalars['String']>,
  /** Serial number of a booklet in 0.0 format. */
  serialNumber: Scalars['String'],
  /** Title of the booklet. */
  title: Scalars['String'],
  /** Topic of the booklet. */
  topic: PackingListBookletTopic,
  /** Worksheets under the booklet. */
  worksheets: Array<PackingListPartialEnvelopeBookletWorksheet>,
};

/** A worksheet under a packing list partial envelope booklet. */
export type PackingListPartialEnvelopeBookletWorksheet = {
   __typename?: 'PackingListPartialEnvelopeBookletWorksheet',
  /** Unique identifier of the record. This value is from the referenced 'worksheet' record. */
  _id: Scalars['ObjectId'],
  /** Image URL of the worksheet cover. */
  imageUrl?: Maybe<Scalars['String']>,
  /** Name of the worksheet. This is coming from the referenced "worksheet" record. */
  name: Scalars['String'],
  /** Serial number of a worksheet in 0.0.A format. */
  serialNumber: Scalars['String'],
  /** Title of the worksheet. This is coming from the referenced "worksheet" record. */
  title: Scalars['String'],
};

/** Input for the Packing List Partial Envelope Reduce Available Units mutation. */
export type PackingListPartialEnvelopeReduceAvailableUnitsInput = {
  /** The packing list to update the envelope of. */
  packingListId: Scalars['ObjectId'],
  /** The partial envelope to reduce the available units of. */
  partialEnvelopeId?: Maybe<Scalars['ObjectId']>,
  /** Value to reduce 'available' units by. */
  unitsReduced: Scalars['Float'],
};

export enum PackingListPartialEnvelopeStatus {
  InProgress = 'InProgress',
  Packed = 'Packed'
}

/** Input for the packing list remove booklets mutation. */
export type PackingListRemoveBookletsInput = {
  /** The booklet ids to remove from the envelope of a packing list. */
  bookletIds: Array<Scalars['ObjectId']>,
  /** The envelope to remove the booklets from. */
  envelopeId: Scalars['ObjectId'],
  /** The packing list containing the booklets to remove. */
  packingListId: Scalars['ObjectId'],
};

/** Input for packing list remove envelope mutation. */
export type PackingListRemoveEnvelopesInput = {
  /** The envelope IDs of the envelopes to remove. */
  envelopeIds: Array<Scalars['ObjectId']>,
  /** The packing list containing the envelope to remove. */
  packingListId: Scalars['ObjectId'],
};

/** Input for the packing list remove worksheets mutation. */
export type PackingListRemoveWorksheetsInput = {
  /** The booklet of the packing list containing the worksheet to remove. */
  bookletId: Scalars['ObjectId'],
  /** The envelope of the packing list containing the worksheet to remove. */
  envelopeId: Scalars['ObjectId'],
  /** The packing list containing the worksheet to remove. */
  packingListId: Scalars['ObjectId'],
  /** The worksheet IDs to remove from the packing list booklet. */
  worksheetIds: Array<Scalars['ObjectId']>,
};

/** Booklets to be reordered and its target ordinal position. */
export type PackingListReorderBookletsBookletInput = {
  /** The ID of the booklet being reordered. */
  id: Scalars['ObjectId'],
  /** A positive number from 1 to n. Indicates the target ordinal position of the booklet after the reordering */
  position: Scalars['Int'],
};

/** Input for the packing list reorder booklets mutation. */
export type PackingListReorderBookletsInput = {
  /** Booklets to be reordered and its target ordinal position. */
  booklets: Array<PackingListReorderBookletsBookletInput>,
  /** The envelope of the packing list with booklets to be sorted. */
  envelopeId: Scalars['ObjectId'],
  /** The Packing list with booklets to be sorted. */
  packingListId: Scalars['ObjectId'],
};

/** Input for the packing list reorder worksheets mutation. */
export type PackingListReorderWorksheetsInput = {
  /** The booklet ID of the packing list with worksheets to be sorted. */
  bookletId: Scalars['ObjectId'],
  /** The envelope ID of the packing list with worksheets to be sorted. */
  envelopeId: Scalars['ObjectId'],
  /** The packing list ID with worksheets to be sorted. */
  packingListId: Scalars['ObjectId'],
  /** The worksheets to be reordered and its target ordinal position. */
  worksheets: Array<PackingListReorderWorksheetsWorksheetInput>,
};

/** Worksheets to be reordered and its target ordinal position. */
export type PackingListReorderWorksheetsWorksheetInput = {
  /** The ID of the worksheet that is being reordered. */
  id: Scalars['ObjectId'],
  /** A positive number from 1 to n. Indicates the target ordinal position of the worksheet after the reordering */
  position: Scalars['Int'],
};

/** Input for getting the packing lists. */
export type PackingListsFilterInput = {
  /** Filter the packing lists with the matching course IDs. */
  courseIds?: Maybe<Array<Scalars['ObjectId']>>,
  /** 
 * Filters the packing lists that contain at least 1 envelope with the
   *     given envelope statuses.
 */
  envelopeStatuses?: Maybe<Array<PackingListEnvelopeStatus>>,
  /** Filters the packing lists with the given lesson plan IDs */
  lessonPlanIds?: Maybe<Array<Scalars['ObjectId']>>,
  /** Filters the packing lists with the given packing list IDs */
  packingListIds?: Maybe<Array<Scalars['ObjectId']>>,
  /** Filters the packing lists with the given trial material IDs */
  trialMaterialIds?: Maybe<Array<Scalars['ObjectId']>>,
};

export type PackingListsPage = {
   __typename?: 'PackingListsPage',
  items: Array<PackingList>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

/** The envelopes for trial enrollments in GAP classes. This contains worksheets to be delivered to trial students. */
export type PackingListTrialEnvelope = {
   __typename?: 'PackingListTrialEnvelope',
  /** Unique identifier of the record. This is not related to an ID of original envelopes. */
  _id: Scalars['ObjectId'],
  /** 
 * The academic week this envelope is assigned to. 
   *     This also used to determine its corresponding lesson flow.
 */
  academicWeek: PackingListAcademicWeek,
  /** The booklets included in this envelope. */
  booklets: Array<PackingListEnvelopeBooklet>,
  /** 
 * This is copied from the referenced lesson flow record. This is to indicate the
   * lesson flow a trial material is assigned to.
 */
  lessonFlowId: Scalars['ObjectId'],
  /** Packing status of the trial envelope. */
  status: PackingListTrialEnvelopeStatus,
  /** Quantity data for the envelope. */
  totalUnits: PackingListEnvelopeUnits,
};

/** Input for the Packing List Trial Envelope Reduce Available Units mutation. */
export type PackingListTrialEnvelopeReduceAvailableUnitsInput = {
  /** The packing list to update the envelope of. */
  packingListId: Scalars['ObjectId'],
  /** The trial envelope to reduce the available units of. */
  trialEnvelopeId?: Maybe<Scalars['ObjectId']>,
  /** Value to reduce 'available' units by. */
  unitsReduced: Scalars['Float'],
};

export enum PackingListTrialEnvelopeStatus {
  InProgress = 'InProgress',
  Packed = 'Packed'
}

/** Input for the Packing List Update Available Units mutation. */
export type PackingListUpdatePackedUnitsInput = {
  /** The envelope to update the available units of. Required when partialEnvelopeId is not provided. */
  envelopeId?: Maybe<Scalars['ObjectId']>,
  /** The packing list to update the envelope of. */
  packingListId: Scalars['ObjectId'],
  /** The envelope to update the available units of. Required when envelopeId is not provided. */
  partialEnvelopeId?: Maybe<Scalars['ObjectId']>,
  /** New value for 'available' units. */
  unitsPacked: Scalars['Float'],
};

/** Input for `updateTrialWorksheetAcademicWeekStatus` mutation. */
export type PackingListUpdateTrialWorksheetAcademicWeekStatusInput = {
  /** The academic week where the trial worksheets are. */
  academicWeekId: Scalars['ObjectId'],
  /** The packing list Id where the trial worksheets are. */
  packingListId: Scalars['ObjectId'],
  /** The new status to be updated to. (Must only use InProgress or Packed). */
  status: PackingListTrialEnvelopeStatus,
};

/** Input for `packingListChangeAcademicYear` mutation. */
export type PackingListUpdateVersionInput = {
  /** The academic year to assign to the packing list. */
  academicYearId: Scalars['ObjectId'],
  /** The desired name to assign to the packing list. */
  name?: Maybe<Scalars['String']>,
  /** The packing list to change the academic year. */
  packingListId: Scalars['ObjectId'],
};

/** The envelopes for trial enrollments in GAP classes. This contains worksheets to be delivered to trial students. */
export type PackingListVersion = {
   __typename?: 'PackingListVersion',
  /** Unique identifier of the record. */
  _id: Scalars['ObjectId'],
  /** Academic year ID and number. */
  year: PackingListVersionYear,
};

/** Year associated with the packing list version. */
export type PackingListVersionYear = {
   __typename?: 'PackingListVersionYear',
  /** Derived from the associated academic year record. */
  _id: Scalars['ObjectId'],
  /** Calendar year. Derrived from the provided academic year ID */
  year: Scalars['Float'],
};

/** Input for the worksheet data on the Packing List Add Worksheets mutation. */
export type PackingListWorksheetInput = {
  /** The worksheet to add to the booklet of the packing list. */
  id: Scalars['ObjectId'],
  /** URL of the cover image of the worksheet. */
  imageUrl?: Maybe<Scalars['String']>,
};

/** Pagination properties. */
export type Pageable = {
   __typename?: 'Pageable',
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

export type PageInfo = {
  pageIndex: Scalars['Int'],
  /** If you want to get data without pagination, you can set pageSize: 0. */
  pageSize: Scalars['Int'],
};

export type Parent = {
   __typename?: 'Parent',
  /** Unique identifier for the record */
  _id: Scalars['ObjectId'],
  address?: Maybe<UserAddress>,
  alias?: Maybe<Scalars['String']>,
  alternateUserId?: Maybe<Scalars['String']>,
  alternativeEmails?: Maybe<Array<Scalars['String']>>,
  avatarUrl?: Maybe<Scalars['String']>,
  canRemove?: Maybe<Scalars['Boolean']>,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  directlyRelatedStudents?: Maybe<Array<Student>>,
  email?: Maybe<Scalars['String']>,
  financeStatement?: Maybe<StatementOfAccount>,
  fullName: Scalars['String'],
  gender?: Maybe<Scalars['String']>,
  homePhone?: Maybe<Telephone>,
  isArchived: Scalars['Boolean'],
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  latestStudentNumber: Scalars['Int'],
  linkedWhatsappAccount?: Maybe<WhatsappAccount>,
  linkedWhatsappAccountId?: Maybe<Scalars['ObjectId']>,
  mobile: Telephone,
  mobileLine?: Maybe<Scalars['String']>,
  officePhone?: Maybe<Telephone>,
  relatedParents?: Maybe<Array<Parent>>,
  relatedStudents?: Maybe<Array<Student>>,
  relationName?: Maybe<Scalars['String']>,
  remark?: Maybe<Remark>,
  salutation?: Maybe<Scalars['String']>,
  status?: Maybe<VoidableStatus>,
  userId?: Maybe<Scalars['String']>,
  voidRemarks?: Maybe<Scalars['String']>,
  whatsAppName?: Maybe<Scalars['String']>,
};


export type ParentCanRemoveArgs = {
  id?: Maybe<Scalars['ObjectId']>
};


export type ParentRelationNameArgs = {
  id?: Maybe<Scalars['ObjectId']>
};

export type ParentFilter = {
  alias?: Maybe<Scalars['String']>,
  fullName?: Maybe<Scalars['String']>,
  hasStatementOfAccount?: Maybe<Scalars['Boolean']>,
  invoiceStatuses?: Maybe<Array<InvoiceStatus>>,
  mobile?: Maybe<TelephoneInput>,
  mobileLine?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  statuses?: Maybe<Array<VoidableStatus>>,
};

export type ParentIndex = {
   __typename?: 'ParentIndex',
  _id: Scalars['ObjectId'],
  address?: Maybe<UserAddress>,
  alias?: Maybe<Scalars['String']>,
  alternateUserId?: Maybe<Scalars['String']>,
  alternativeEmails?: Maybe<Array<Scalars['String']>>,
  avatarUrl?: Maybe<Scalars['String']>,
  balance?: Maybe<Scalars['Decimal']>,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  email?: Maybe<Scalars['String']>,
  fullName: Scalars['String'],
  gender?: Maybe<Scalars['String']>,
  hasStatementOfAccount?: Maybe<Scalars['Boolean']>,
  homePhone?: Maybe<Telephone>,
  invoiceStatuses?: Maybe<Array<InvoiceStatus>>,
  isArchived: Scalars['Boolean'],
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  linkedWhatsappAccount?: Maybe<ParentWhatsappAccountIndex>,
  linkedWhatsappAccountId?: Maybe<Scalars['ObjectId']>,
  mobile: Telephone,
  mobileLine?: Maybe<Scalars['String']>,
  officePhone?: Maybe<Telephone>,
  onHold?: Maybe<Scalars['Decimal']>,
  overdue?: Maybe<Scalars['Decimal']>,
  paid?: Maybe<Scalars['Decimal']>,
  pending?: Maybe<Scalars['Decimal']>,
  salutation?: Maybe<Scalars['String']>,
  status?: Maybe<VoidableStatus>,
  students?: Maybe<Array<ParentStudentIndex>>,
  unpaid?: Maybe<Scalars['Decimal']>,
  userId?: Maybe<Scalars['String']>,
  whatsAppName?: Maybe<Scalars['String']>,
};

export type ParentIndexPage = {
   __typename?: 'ParentIndexPage',
  items: Array<ParentIndex>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

/** 
 * The object containing the necessary fields used in the lesson plan 
 *   that is copied from the referenced LessonPlan entity.
 */
export type ParentLessonPlan = {
   __typename?: 'ParentLessonPlan',
  /** The date when the lesson plan was copied. */
  copiedDate: Scalars['DateTime'],
  /** Reference to the lesson plan copied. */
  id: Scalars['ObjectId'],
  /** The copied name of the lesson plan copied. */
  name: Scalars['String'],
};

export type ParentPage = {
   __typename?: 'ParentPage',
  items: Array<Parent>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

export type ParentStudentIndex = {
   __typename?: 'ParentStudentIndex',
  _id: Scalars['ObjectId'],
  fullName: Scalars['String'],
};

export type ParentWhatsappAccountIndex = {
   __typename?: 'ParentWhatsappAccountIndex',
  _id: Scalars['ObjectId'],
  number?: Maybe<Telephone>,
};

export type Payment = {
   __typename?: 'Payment',
  _id: Scalars['ObjectId'],
  amount?: Maybe<Scalars['Decimal']>,
  balanceAmount?: Maybe<Scalars['Decimal']>,
  bankName?: Maybe<Scalars['String']>,
  cashAmount?: Maybe<Scalars['Decimal']>,
  chequeNumber?: Maybe<Scalars['String']>,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  depositDate?: Maybe<Scalars['DateTime']>,
  details?: Maybe<Array<PaymentDetail>>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  isVoid?: Maybe<Scalars['Boolean']>,
  lastDigits?: Maybe<Scalars['String']>,
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  method: Scalars['String'],
  payForInvoiceAmount?: Maybe<Scalars['Decimal']>,
  /** The date when the payment was made */
  paymentDate?: Maybe<Scalars['DateTime']>,
  paymentProcessor?: Maybe<Scalars['String']>,
  receipt?: Maybe<Receipt>,
  reference?: Maybe<Scalars['String']>,
  referenceNumber?: Maybe<Scalars['String']>,
  remarks?: Maybe<Scalars['String']>,
  url?: Maybe<Scalars['String']>,
  xeroPaymentSyncStatus?: Maybe<XeroSyncStatus>,
  xeroPrepayments?: Maybe<Array<XeroPrepayment>>,
};

export type PaymentDetail = {
   __typename?: 'PaymentDetail',
  amount?: Maybe<Scalars['Decimal']>,
  /** Memory calculate,not save to db. */
  balanceAmount?: Maybe<Scalars['Decimal']>,
  invoice?: Maybe<Invoice>,
  invoiceId: Scalars['ObjectId'],
  xeroPrepayments?: Maybe<Array<XeroPrepayment>>,
};

export enum PaymentMode {
  AtmTransfer = 'ATMTransfer',
  BankTransfer = 'BankTransfer',
  Cash = 'Cash',
  Cheque = 'Cheque',
  Credit = 'Credit',
  CreditCard = 'CreditCard',
  CreditCardStripe = 'CreditCardStripe',
  DbsMax = 'DBSMax',
  GrabPayStripe = 'GrabPayStripe',
  PayNow = 'PayNow',
  PayNowBank = 'PayNowBank',
  PayNowStripe = 'PayNowStripe'
}

export type Pdf = {
   __typename?: 'Pdf',
  url: Scalars['String'],
};

export type Person = {
  alias?: Maybe<Scalars['String']>,
  avatarUrl?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  fullName: Scalars['String'],
  gender?: Maybe<Scalars['String']>,
  mobile: Telephone,
};

export type PhoneNumberInput = {
  countryCode: Scalars['String'],
  number: Scalars['String'],
};

export type PointCounter = {
   __typename?: 'PointCounter',
  expired?: Maybe<Scalars['Int']>,
  expiring?: Maybe<Scalars['Int']>,
  redemptionsMade?: Maybe<Scalars['Int']>,
  remaining?: Maybe<Scalars['Int']>,
};

export type PreviousAttendanceIndex = {
   __typename?: 'PreviousAttendanceIndex',
  _id: Scalars['ObjectId'],
  classId: Scalars['ObjectId'],
  classShorthand?: Maybe<Scalars['String']>,
  status?: Maybe<AttendanceStatus>,
  type: AttendanceType,
};

export type Promotion = {
   __typename?: 'Promotion',
  _id: Scalars['ObjectId'],
  academicLevels: Array<AcademicLevel>,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  promoteToLevel: AcademicLevel,
  scheduledDate: Scalars['DateTime'],
  selectedStudents?: Maybe<Array<Student>>,
  status?: Maybe<PromotionStatus>,
  students?: Maybe<Array<Student>>,
};

export enum PromotionStatus {
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  Failed = 'Failed',
  Pending = 'Pending'
}

export type QrCode = {
   __typename?: 'QrCode',
  image: Scalars['String'],
};

export type Query = {
   __typename?: 'Query',
  academicLevel?: Maybe<AcademicLevel>,
  academicLevels?: Maybe<Array<AcademicLevel>>,
  academicYear?: Maybe<AcademicYear>,
  academicYears?: Maybe<Array<AcademicYear>>,
  activityLogs?: Maybe<ActivityLogPage>,
  alternativeLessons?: Maybe<Array<Lesson>>,
  arrangement?: Maybe<Arrangement>,
  arrangementWithIndex?: Maybe<ArrangementIndex>,
  arrangements?: Maybe<ArrangementPage>,
  arrangementsWithIndex?: Maybe<ArrangementIndexPage>,
  attachedLessonFlows?: Maybe<Array<LessonFlow>>,
  attendanceRemarks?: Maybe<Array<Remark>>,
  attendanceReports?: Maybe<EnrollmentIndexPage>,
  attendanceWithIndex?: Maybe<AttendanceIndex>,
  /** Retrieves the attendances. The results can be filtered usign the filter property. */
  attendances?: Maybe<AttendancePage>,
  attendancesWithIndex?: Maybe<AttendanceIndexPage>,
  auditLog?: Maybe<AuditLog>,
  auditLogs?: Maybe<AuditLogPage>,
  autocompleteClass: CreateClassOutput,
  autocompleteCourse: CreateCourseOutput,
  autocompleteParent: CreateParentOutput,
  autocompleteStudent: RegisterStudentOutput,
  balanceHistories?: Maybe<BalanceHistoryPage>,
  balanceHistory?: Maybe<Invoice>,
  branch?: Maybe<Branch>,
  branches?: Maybe<Array<Branch>>,
  bulkCreateHistory?: Maybe<BulkCreateHistory>,
  bulkExportReceipts?: Maybe<Pdf>,
  bulkExportReminderDocx?: Maybe<Scalars['String']>,
  campaign?: Maybe<Campaign>,
  campaignExportHistories?: Maybe<CampaignExportHistoryPage>,
  campaignExportHistory?: Maybe<CampaignExportHistory>,
  campaigns?: Maybe<CampaignPage>,
  cancellationEffects?: Maybe<CancellationEffects>,
  centre?: Maybe<Centre>,
  centreAssignments?: Maybe<Array<CentreAssignments>>,
  class?: Maybe<Class>,
  classEnrollmentOverview?: Maybe<Array<ClassEnrollmentOverview>>,
  classFromStudent?: Maybe<Class>,
  /** Query to get score submission statistics by Class */
  classSubmissionStats?: Maybe<Array<ClassSubmissionStats>>,
  classWithIndex?: Maybe<ClassIndex>,
  classes?: Maybe<ClassPage>,
  classesFromStudent?: Maybe<ClassPage>,
  classesWithIndex?: Maybe<ClassIndexPage>,
  /** Count unseen feedback records with given set of filters */
  countUnseenFeedback?: Maybe<FeedbackUnseenCount>,
  countryCodes?: Maybe<Array<CountryCode>>,
  courierPickupAddresses?: Maybe<Array<CourierPickupAddress>>,
  course?: Maybe<Course>,
  courseNotification?: Maybe<CourseNotification>,
  courseNotifications?: Maybe<CourseNotificationPage>,
  courseWithIndex?: Maybe<CourseIndex>,
  courses?: Maybe<CoursePage>,
  coursesWithIndex?: Maybe<CourseIndexPage>,
  currentSystemUser?: Maybe<SystemUser>,
  deliveriesWithIndex?: Maybe<DeliveryIndexPage>,
  delivery?: Maybe<Delivery>,
  deliveryWithIndex?: Maybe<DeliveryIndex>,
  department?: Maybe<Department>,
  departments?: Maybe<Array<Department>>,
  discount?: Maybe<Discount>,
  discounts?: Maybe<Array<Discount>>,
  eligibilityFlow?: Maybe<EligibilityFlow>,
  enabledFeatures?: Maybe<Array<FeatureAccess>>,
  enrollment?: Maybe<Enrollment>,
  examScore?: Maybe<ExamScore>,
  examScores?: Maybe<Array<ExamScore>>,
  executeEligibilityFlowWithCourseId?: Maybe<FlowResult>,
  exportArrangementCsv?: Maybe<Csv>,
  exportAttendanceCsv?: Maybe<Csv>,
  exportAttendanceReportCsv?: Maybe<Csv>,
  exportCampaignDocx?: Maybe<Scalars['String']>,
  exportClassCsv?: Maybe<Csv>,
  exportClassCsvWithIndex?: Maybe<Csv>,
  exportCourseCsv?: Maybe<Csv>,
  exportCourseCsvWithIndex?: Maybe<Csv>,
  exportInvoiceCsv?: Maybe<Csv>,
  exportInvoiceReportCsv?: Maybe<Csv>,
  exportInvoiceRevenueReportCsv?: Maybe<Csv>,
  exportLessonCsv?: Maybe<Csv>,
  exportLessonCsvWithIndex?: Maybe<Csv>,
  exportParentCsv?: Maybe<Csv>,
  exportPaymentReportCsv?: Maybe<Csv>,
  exportPromotionCsv?: Maybe<Csv>,
  exportReceiptsCsv?: Maybe<Csv>,
  exportRefundCsv?: Maybe<Csv>,
  exportRegistrationCsv?: Maybe<Csv>,
  exportReminderDocx?: Maybe<Scalars['String']>,
  exportStatementOfAccountCsv?: Maybe<Csv>,
  exportStudentCsv?: Maybe<Csv>,
  exportStudentReportCsv?: Maybe<Csv>,
  /** Query to process submission audit logs to CSV */
  exportSubmissionAuditLog?: Maybe<Csv>,
  /** Query for exporting submission tracking report. */
  exportSubmissionTracking?: Maybe<Csv>,
  exportSystemUserCsv?: Maybe<Csv>,
  /** Mutation to export list of worksheets to CSV. This will return the url of the generated CSV file that can be downloaded. */
  exportWorkSheets?: Maybe<Csv>,
  features?: Maybe<Array<FeatureAccess>>,
  feedback?: Maybe<Feedback>,
  /** Query to get a single Feedback Seen Receipt record */
  feedbackSeenReceipt?: Maybe<FeedbackSeenReceipt>,
  /** Query to fetch list of Feedback Seen Receipt records */
  feedbackSeenReceipts?: Maybe<FeedbackSeenReceiptPage>,
  feedbacks?: Maybe<FeedbackPage>,
  generateUserId?: Maybe<Scalars['String']>,
  getAcademicWeeksByCourseId?: Maybe<AcademicWeekData>,
  getEligibilityFlowWithCourseId?: Maybe<EligibilityFlow>,
  getMailMerge?: Maybe<MailMerge>,
  getNotificationConfigurations?: Maybe<Array<NotificationSetting>>,
  getPendingOverdueTask: Array<TaskCount>,
  /** Query to get remarks with suitability information (if there is) for the student */
  getStudentRemarks?: Maybe<Array<StudentRemarks>>,
  getStudentsBirthDate?: Maybe<Array<Scalars['Date']>>,
  /** Get topics by one or more courseIds. */
  getTopicsByCourseIds?: Maybe<Array<Topic>>,
  /** 
 * Get topics by lesson flow ID. Check for related packing list envelope status
   * if it is set to "Printed"/ "Packed for delivery". If so, retrieve topics that
   * are only found within the packing list. Otherwise, retrieve all topics under parent course.
 */
  getTopicsByLessonFlowId?: Maybe<Array<Topic>>,
  /** 
 * Get worksheets by lesson flow ID. Check for related packing list envelope
   * status if it is set to "Printed"/ "Packed for delivery". If so, retrieve
   * worksheets that are only found within the packing list. Otherwise, retrieve
   * all worksheets under parent course.
 */
  getWorksheetsByLessonFlowId?: Maybe<Array<WorkSheet>>,
  /** Query to get score submission statistics by Gradable (e.g. Exercise, Homework) */
  gradableSubmissionStats?: Maybe<Array<GradableSubmissionStats>>,
  gradingTemplates: Array<GradingTemplate>,
  /** Query the handouts depending on the search and filter. */
  handouts?: Maybe<HandoutPage>,
  historyPointCounterBaseDateRange?: Maybe<RewardsHistoryCounter>,
  holiday?: Maybe<Holiday>,
  holidayLessonsIncludeEnrollments?: Maybe<LessonPage>,
  holidays?: Maybe<Array<Holiday>>,
  integrations?: Maybe<Array<Integration>>,
  invoice?: Maybe<Invoice>,
  invoiceExport?: Maybe<Pdf>,
  invoiceItemOption?: Maybe<InvoiceItemOption>,
  invoiceItemOptions?: Maybe<Array<InvoiceItemOption>>,
  invoiceReports?: Maybe<InvoiceReportPage>,
  invoiceRevenueReports?: Maybe<InvoiceLineItemPage>,
  invoiceWithIndex?: Maybe<InvoiceIndex>,
  invoices?: Maybe<InvoicePage>,
  invoicesWithIndex?: Maybe<InvoiceIndexPage>,
  isCancelArrangementHasFullLesson?: Maybe<Scalars['Boolean']>,
  lesson?: Maybe<Lesson>,
  lessonFlow?: Maybe<LessonFlow>,
  /** Query Lesson flow item by ID with mapped lesson sequence information */
  lessonFlowItemWithSequence?: Maybe<LessonFlowItem>,
  lessonFlowItems?: Maybe<Array<LessonFlowItem>>,
  /** Query Lesson flow items by parent Lesson Flow ID with mapped lesson sequence information */
  lessonFlowItemsWithSequence?: Maybe<LessonFlowItemPage>,
  lessonFlows?: Maybe<LessonFlowPage>,
  /** Get a lesson plan by id. */
  lessonPlan?: Maybe<LessonPlan>,
  /** Get all the worksheets of a lesson plan that can be added to a given packing list envelope. */
  lessonPlanPackingListEnvelopeWorksheets?: Maybe<Array<LessonFlowWorksheetBaseItem>>,
  /** Gets a paginated list of lesson plans of a course. */
  lessonPlans?: Maybe<LessonPlanPage>,
  lessons?: Maybe<LessonPage>,
  lessonsWithIndex?: Maybe<LessonIndexPage>,
  /** Query to list students with award point details */
  listStudentAwardPointsDetails?: Maybe<StudentAwardDetailsPage>,
  /** Retrieves the material. Can be filtered by ID associated with the material. */
  material?: Maybe<Material>,
  /** Get material with index */
  materialWithIndex?: Maybe<MaterialIndex>,
  /** Retrieves the materials. Can be filtered by input associated with the materials. */
  materials?: Maybe<MaterialPage>,
  /** Get materials with index */
  materialsWithIndex?: Maybe<MaterialIndexPage>,
  packingList?: Maybe<PackingList>,
  /** Gets the latest packing list configuration. */
  packingListConfiguration: PackingListConfiguration,
  /** 
 * Gets the envelope unit based on the envelopeId. To get the realtime value, 
   *     enhance this query by adding the `packingListEnvelopeUnitsUpdated` subscription
   *     to Apollo client's `subscribeToMore`.
 */
  packingListEnvelopeUnits?: Maybe<PackingListEnvelopeUnits>,
  /** Retrieves the packing lists. Can be filtered by course IDs associated with the packing list. */
  packingLists?: Maybe<PackingListsPage>,
  parent?: Maybe<Parent>,
  parentWithIndex?: Maybe<ParentIndex>,
  parents?: Maybe<ParentPage>,
  parentsWithIndex?: Maybe<ParentIndexPage>,
  previewAdHocInvoice?: Maybe<Invoice>,
  promotion?: Maybe<Promotion>,
  promotions?: Maybe<Array<Promotion>>,
  receipt?: Maybe<Receipt>,
  receiptDetails?: Maybe<Scalars['String']>,
  receiptExport?: Maybe<Pdf>,
  receiptWithIndex?: Maybe<ReceiptIndex>,
  receipts?: Maybe<ReceiptPage>,
  receiptsWithIndex?: Maybe<ReceiptIndexPage>,
  referenceData?: Maybe<ReferenceData>,
  referenceDatas?: Maybe<Array<ReferenceData>>,
  refund?: Maybe<Refund>,
  refundExport?: Maybe<Pdf>,
  refunds?: Maybe<RefundPage>,
  registration?: Maybe<Registration>,
  registrationWithIndex?: Maybe<RegistrationIndex>,
  registrations?: Maybe<RegistrationPage>,
  registrationsWithIndex?: Maybe<RegistrationIndexPage>,
  /** Get a paginated list of remarks that are filtered with the filterInput. Requires `subjectId` and `type`. */
  remarks?: Maybe<RemarkPage>,
  reminder?: Maybe<Reminder>,
  reminders?: Maybe<ReminderPage>,
  replacementCreditWithCourse?: Maybe<StudentReplacementCreditDto>,
  revenueCoursesWithIndex?: Maybe<CourseIndexPage>,
  reward?: Maybe<Reward>,
  rewards?: Maybe<RewardPage>,
  /** 
 * This is a query that returns a list of `Rewards` that have been awarded to
   * students. This contains information about what the current status of the
   * reward is long with ordering and shipping information.
 */
  rewardsHistory?: Maybe<RewardHistoryPage>,
  school?: Maybe<School>,
  schools?: Maybe<Array<School>>,
  seatsAnalyticsByAcademicLevel?: Maybe<SeatAnalytics>,
  sessions?: Maybe<Array<Session>>,
  statementOfAccount?: Maybe<StatementOfAccount>,
  statementOfAccountNumber?: Maybe<StatementOfAccountNumber>,
  statementOfAccounts?: Maybe<StatementOfAccountPage>,
  student?: Maybe<Student>,
  studentMerchandise?: Maybe<MerchandisePage>,
  studentReportFilter?: Maybe<StudentReportFilter>,
  studentReportFilters?: Maybe<Array<StudentReportFilter>>,
  studentReports?: Maybe<StudentPage>,
  studentWithIndex?: Maybe<StudentIndex>,
  students?: Maybe<StudentPage>,
  studentsWithIndex?: Maybe<StudentIndexPage>,
  submissionAuditLogs?: Maybe<AuditLogPage>,
  submissionTracking?: Maybe<Array<SubmissionTracking>>,
  systemNotification?: Maybe<SystemNotification>,
  systemNotifications?: Maybe<SystemNotificationPage>,
  systemUser?: Maybe<SystemUser>,
  systemUsers?: Maybe<SystemUserPage>,
  task?: Maybe<Task>,
  taskList?: Maybe<TaskList>,
  taskLists?: Maybe<TaskListPage>,
  tasks?: Maybe<TaskPage>,
  tasksWithIndex?: Maybe<TaskIndexPage>,
  template?: Maybe<Template>,
  templates?: Maybe<TemplatePage>,
  /** Get a test submission by id. */
  testSubmission?: Maybe<TestSubmission>,
  /** Gets a paginated list of test submissions. */
  testSubmissions?: Maybe<TestSubmissionsPage>,
  topic?: Maybe<Topic>,
  topics?: Maybe<Array<Topic>>,
  upcomingLesson?: Maybe<Lesson>,
  userCustomiseColumn?: Maybe<UserCustomiseColumn>,
  userCustomiseColumns?: Maybe<UserCustomiseColumnPage>,
  userLogs?: Maybe<LogPage>,
  userNotes?: Maybe<NotePage>,
  validateEmail?: Maybe<EmailValidation>,
  validateTeacherAndVenue?: Maybe<TeacherAndVenueValidateResult>,
  venue?: Maybe<Venue>,
  venues?: Maybe<Array<Venue>>,
  whatsappAccount?: Maybe<WhatsappAccount>,
  whatsappAccounts?: Maybe<WhatsappAccountPage>,
  whatsappMessage?: Maybe<WhatsappMessage>,
  whatsappMessageCounts?: Maybe<WhatsappMessageCounts>,
  whatsappMessages?: Maybe<WhatsappMessagePage>,
  workSheet?: Maybe<WorkSheet>,
  workSheets?: Maybe<WorkSheetPage>,
  xeroAccounts?: Maybe<Array<XeroAccount>>,
  xeroConsentUrl?: Maybe<Scalars['String']>,
  xeroTokenInfo?: Maybe<XeroIntegration>,
};


export type QueryAcademicLevelArgs = {
  id: Scalars['ObjectId']
};


export type QueryAcademicLevelsArgs = {
  ids?: Maybe<Array<Scalars['ObjectId']>>,
  showArchived?: Maybe<Scalars['Boolean']>,
  showHided?: Maybe<Scalars['Boolean']>
};


export type QueryAcademicYearArgs = {
  id?: Maybe<Scalars['ObjectId']>,
  year?: Maybe<Scalars['Int']>
};


export type QueryActivityLogsArgs = {
  pageInfo?: Maybe<PageInfo>,
  query: ActivityLogListQuery,
  sortInfo?: Maybe<SortInfo>
};


export type QueryAlternativeLessonsArgs = {
  input: AlternativeLessonsForChangeAttendingClassInput
};


export type QueryArrangementArgs = {
  id: Scalars['ObjectId']
};


export type QueryArrangementWithIndexArgs = {
  id: Scalars['ObjectId']
};


export type QueryArrangementsArgs = {
  filterInput?: Maybe<ArrangementFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  search?: Maybe<Scalars['String']>,
  sortInfo?: Maybe<SortInfo>
};


export type QueryArrangementsWithIndexArgs = {
  filter?: Maybe<ArrangementFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  sortInfo?: Maybe<SortInfo>
};


export type QueryAttachedLessonFlowsArgs = {
  workSheetId: Scalars['ObjectId']
};


export type QueryAttendanceRemarksArgs = {
  id: Scalars['ObjectId'],
  includesStudentRemarks?: Maybe<Scalars['Boolean']>,
  showArchived?: Maybe<Scalars['Boolean']>
};


export type QueryAttendanceReportsArgs = {
  filter?: Maybe<StudentEnrollmentFilterInput>,
  pageInfo?: Maybe<PageInfo>
};


export type QueryAttendanceWithIndexArgs = {
  id: Scalars['ObjectId']
};


export type QueryAttendancesArgs = {
  filter?: Maybe<StudentAttendanceFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  sortInfo?: Maybe<SortInfo>
};


export type QueryAttendancesWithIndexArgs = {
  filter?: Maybe<StudentAttendanceFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  sortInfo?: Maybe<SortInfo>
};


export type QueryAuditLogArgs = {
  id: Scalars['ObjectId']
};


export type QueryAuditLogsArgs = {
  filterInput?: Maybe<AuditLogFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  search?: Maybe<Scalars['String']>,
  sortInfo?: Maybe<SortInfo>
};


export type QueryAutocompleteClassArgs = {
  courseId: Scalars['ObjectId'],
  isAdHoc: Scalars['Boolean']
};


export type QueryAutocompleteCourseArgs = {
  type: CourseType
};


export type QueryAutocompleteStudentArgs = {
  primaryParentId: Scalars['ObjectId']
};


export type QueryBalanceHistoriesArgs = {
  filterInput?: Maybe<BalanceHistoryFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  sortInfo?: Maybe<SortInfo>
};


export type QueryBalanceHistoryArgs = {
  id: Scalars['ObjectId']
};


export type QueryBranchArgs = {
  id: Scalars['ObjectId']
};


export type QueryBranchesArgs = {
  showArchived?: Maybe<Scalars['Boolean']>
};


export type QueryBulkCreateHistoryArgs = {
  type: BulkCreateHistoryType
};


export type QueryBulkExportReceiptsArgs = {
  filterInput?: Maybe<ReceiptFilterInput>,
  printBase64?: Maybe<Scalars['Boolean']>
};


export type QueryBulkExportReminderDocxArgs = {
  input: BulkSendReminderInput
};


export type QueryCampaignArgs = {
  id: Scalars['ObjectId']
};


export type QueryCampaignExportHistoriesArgs = {
  filterInput?: Maybe<CampaignFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  sortInfo?: Maybe<SortInfo>
};


export type QueryCampaignExportHistoryArgs = {
  id: Scalars['ObjectId']
};


export type QueryCampaignsArgs = {
  filterInput?: Maybe<CampaignFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  sortInfo?: Maybe<SortInfo>
};


export type QueryCancellationEffectsArgs = {
  id: Scalars['ObjectId']
};


export type QueryCentreAssignmentsArgs = {
  studentId: Scalars['ObjectId']
};


export type QueryClassArgs = {
  id: Scalars['ObjectId']
};


export type QueryClassEnrollmentOverviewArgs = {
  filterInput?: Maybe<ClassFilterInput>
};


export type QueryClassFromStudentArgs = {
  classId: Scalars['ObjectId'],
  studentId: Scalars['ObjectId']
};


export type QueryClassSubmissionStatsArgs = {
  input: ClassSubmissionStatsInput
};


export type QueryClassWithIndexArgs = {
  id: Scalars['ObjectId']
};


export type QueryClassesArgs = {
  filterInput?: Maybe<ClassFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  search?: Maybe<Scalars['String']>,
  sortInfo?: Maybe<SortInfo>
};


export type QueryClassesFromStudentArgs = {
  courseId?: Maybe<Scalars['ObjectId']>,
  pageInfo?: Maybe<PageInfo>,
  search?: Maybe<Scalars['String']>,
  sortInfo?: Maybe<SortInfo>,
  studentId: Scalars['ObjectId']
};


export type QueryClassesWithIndexArgs = {
  filter?: Maybe<ClassFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  sortInfo?: Maybe<SortInfo>
};


export type QueryCountUnseenFeedbackArgs = {
  filterInput?: Maybe<FeedbackFilterInput>
};


export type QueryCourierPickupAddressesArgs = {
  showArchived?: Maybe<Scalars['Boolean']>
};


export type QueryCourseArgs = {
  id: Scalars['ObjectId']
};


export type QueryCourseNotificationArgs = {
  id: Scalars['ObjectId']
};


export type QueryCourseNotificationsArgs = {
  filterInput?: Maybe<CourseNotificationFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  sortInfo?: Maybe<SortInfo>
};


export type QueryCourseWithIndexArgs = {
  id: Scalars['ObjectId']
};


export type QueryCoursesArgs = {
  filterInput?: Maybe<CourseFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  search?: Maybe<Scalars['String']>,
  sortInfo?: Maybe<SortInfo>
};


export type QueryCoursesWithIndexArgs = {
  filter?: Maybe<CourseFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  sortInfo?: Maybe<SortInfo>
};


export type QueryDeliveriesWithIndexArgs = {
  filter?: Maybe<DeliveryFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  sortInfo?: Maybe<SortInfo>
};


export type QueryDeliveryArgs = {
  id: Scalars['ObjectId']
};


export type QueryDeliveryWithIndexArgs = {
  id: Scalars['ObjectId']
};


export type QueryDepartmentArgs = {
  id: Scalars['ObjectId']
};


export type QueryDepartmentsArgs = {
  showArchived?: Maybe<Scalars['Boolean']>
};


export type QueryDiscountArgs = {
  id: Scalars['ObjectId']
};


export type QueryDiscountsArgs = {
  showArchived?: Maybe<Scalars['Boolean']>,
  showVisible?: Maybe<Scalars['Boolean']>
};


export type QueryEligibilityFlowArgs = {
  id: Scalars['ObjectId']
};


export type QueryEnrollmentArgs = {
  id: Scalars['ObjectId']
};


export type QueryExamScoreArgs = {
  id: Scalars['ObjectId']
};


export type QueryExamScoresArgs = {
  studentId: Scalars['ObjectId']
};


export type QueryExecuteEligibilityFlowWithCourseIdArgs = {
  courseId: Scalars['ObjectId'],
  studentId: Scalars['ObjectId']
};


export type QueryExportArrangementCsvArgs = {
  filterInput?: Maybe<ArrangementFilterInput>,
  search?: Maybe<Scalars['String']>
};


export type QueryExportAttendanceCsvArgs = {
  filter?: Maybe<StudentAttendanceFilterInput>
};


export type QueryExportAttendanceReportCsvArgs = {
  filter?: Maybe<StudentEnrollmentFilterInput>
};


export type QueryExportCampaignDocxArgs = {
  id: Scalars['ObjectId']
};


export type QueryExportClassCsvArgs = {
  filterInput?: Maybe<ClassFilterInput>,
  search?: Maybe<Scalars['String']>
};


export type QueryExportClassCsvWithIndexArgs = {
  filterInput?: Maybe<ClassFilterInput>
};


export type QueryExportCourseCsvArgs = {
  filterInput?: Maybe<CourseFilterInput>,
  search?: Maybe<Scalars['String']>
};


export type QueryExportCourseCsvWithIndexArgs = {
  filterInput?: Maybe<CourseFilterInput>
};


export type QueryExportInvoiceCsvArgs = {
  filterInput?: Maybe<InvoiceFilterInput>,
  search?: Maybe<Scalars['String']>
};


export type QueryExportInvoiceReportCsvArgs = {
  filter?: Maybe<InvoiceReportFilterInput>
};


export type QueryExportInvoiceRevenueReportCsvArgs = {
  filter: CourseFilterInput
};


export type QueryExportLessonCsvArgs = {
  filterInput?: Maybe<LessonFilterInput>,
  search?: Maybe<Scalars['String']>
};


export type QueryExportLessonCsvWithIndexArgs = {
  filterInput?: Maybe<LessonFilterInput>
};


export type QueryExportParentCsvArgs = {
  filterInput?: Maybe<ParentFilter>,
  search?: Maybe<Scalars['String']>
};


export type QueryExportPaymentReportCsvArgs = {
  filter?: Maybe<BalanceHistoryFilterInput>
};


export type QueryExportReceiptsCsvArgs = {
  filterInput?: Maybe<ReceiptFilterInput>,
  search?: Maybe<Scalars['String']>
};


export type QueryExportRefundCsvArgs = {
  filterInput?: Maybe<RefundFilterInput>
};


export type QueryExportRegistrationCsvArgs = {
  filterInput?: Maybe<RegistrationFilterInput>,
  search?: Maybe<Scalars['String']>
};


export type QueryExportReminderDocxArgs = {
  input: SendReminderInput
};


export type QueryExportStatementOfAccountCsvArgs = {
  filter?: Maybe<StatementOfAccountFilter>
};


export type QueryExportStudentCsvArgs = {
  columns?: Maybe<Array<Scalars['String']>>,
  filterInput?: Maybe<StudentFilterInput>,
  search?: Maybe<Scalars['String']>
};


export type QueryExportStudentReportCsvArgs = {
  filter: StudentReportFilterInput
};


export type QueryExportSubmissionAuditLogArgs = {
  filterInput?: Maybe<SubmissionAuditLogFilterInput>,
  search?: Maybe<Scalars['String']>
};


export type QueryExportSubmissionTrackingArgs = {
  classId: Scalars['ObjectId'],
  filter?: Maybe<SubmissionTrackingFilter>,
  view: Scalars['String']
};


export type QueryExportSystemUserCsvArgs = {
  filterInput?: Maybe<SystemUserQuery>
};


export type QueryExportWorkSheetsArgs = {
  filterInput?: Maybe<WorkSheetItemInput>,
  pageInfo?: Maybe<PageInfo>,
  search?: Maybe<Scalars['String']>,
  sortInfo?: Maybe<SortInfo>
};


export type QueryFeedbackArgs = {
  id: Scalars['ObjectId']
};


export type QueryFeedbackSeenReceiptArgs = {
  id: Scalars['ObjectId']
};


export type QueryFeedbackSeenReceiptsArgs = {
  filterInput?: Maybe<FeedbackSeenReceiptsFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  search?: Maybe<Scalars['String']>,
  sortInfo?: Maybe<SortInfo>
};


export type QueryFeedbacksArgs = {
  filterInput?: Maybe<FeedbackFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  search?: Maybe<Scalars['String']>,
  sortInfo?: Maybe<SortInfo>
};


export type QueryGenerateUserIdArgs = {
  input: GenerateUserIdInput
};


export type QueryGetAcademicWeeksByCourseIdArgs = {
  courseId: Scalars['ObjectId']
};


export type QueryGetEligibilityFlowWithCourseIdArgs = {
  courseId: Scalars['ObjectId']
};


export type QueryGetMailMergeArgs = {
  id: Scalars['ObjectId']
};


export type QueryGetNotificationConfigurationsArgs = {
  type: NotificationType
};


export type QueryGetStudentRemarksArgs = {
  enrollmentId?: Maybe<Scalars['ObjectId']>,
  studentId: Scalars['ObjectId']
};


export type QueryGetTopicsByCourseIdsArgs = {
  input: GetTopicsByCourseIdsInput
};


export type QueryGetTopicsByLessonFlowIdArgs = {
  input: GetTopicsByLessonFlowIdInput
};


export type QueryGetWorksheetsByLessonFlowIdArgs = {
  input: GetWorksheetsByLessonFlowIdInput
};


export type QueryGradableSubmissionStatsArgs = {
  input: GradableSubmissionStatsInput
};


export type QueryHandoutsArgs = {
  filterInput?: Maybe<HandoutFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  search?: Maybe<Scalars['String']>,
  sortInfo?: Maybe<SortInfo>
};


export type QueryHistoryPointCounterBaseDateRangeArgs = {
  dateRange: DateRangeInput,
  studentId: Scalars['ObjectId']
};


export type QueryHolidayArgs = {
  id: Scalars['ObjectId']
};


export type QueryHolidayLessonsIncludeEnrollmentsArgs = {
  filterInput?: Maybe<LessonFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  search?: Maybe<Scalars['String']>
};


export type QueryHolidaysArgs = {
  filterInput?: Maybe<HolidayFilterInput>
};


export type QueryInvoiceArgs = {
  id: Scalars['ObjectId']
};


export type QueryInvoiceExportArgs = {
  id: Scalars['ObjectId'],
  printBase64?: Maybe<Scalars['Boolean']>
};


export type QueryInvoiceItemOptionArgs = {
  id: Scalars['ObjectId']
};


export type QueryInvoiceReportsArgs = {
  filter?: Maybe<InvoiceReportFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  sortInfo?: Maybe<SortInfo>
};


export type QueryInvoiceRevenueReportsArgs = {
  filter: RevenueReportQuery,
  pageInfo?: Maybe<PageInfo>
};


export type QueryInvoiceWithIndexArgs = {
  id: Scalars['ObjectId']
};


export type QueryInvoicesArgs = {
  filterInput?: Maybe<InvoiceFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  search?: Maybe<Scalars['String']>,
  sortInfo?: Maybe<SortInfo>
};


export type QueryInvoicesWithIndexArgs = {
  filter?: Maybe<InvoiceFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  sortInfo?: Maybe<SortInfo>
};


export type QueryIsCancelArrangementHasFullLessonArgs = {
  id: Scalars['ObjectId']
};


export type QueryLessonArgs = {
  id: Scalars['ObjectId']
};


export type QueryLessonFlowArgs = {
  id: Scalars['ObjectId']
};


export type QueryLessonFlowItemWithSequenceArgs = {
  lessonFlowItemId: Scalars['ObjectId']
};


export type QueryLessonFlowItemsArgs = {
  lessonFlowId: Scalars['ObjectId']
};


export type QueryLessonFlowItemsWithSequenceArgs = {
  lessonFlowId: Scalars['ObjectId'],
  pageInfo?: Maybe<PageInfo>,
  sortInfo?: Maybe<SortInfo>
};


export type QueryLessonFlowsArgs = {
  filterInput?: Maybe<LessonFlowFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  search?: Maybe<Scalars['String']>,
  sortInfo?: Maybe<SortInfo>
};


export type QueryLessonPlanArgs = {
  id: Scalars['ObjectId']
};


export type QueryLessonPlanPackingListEnvelopeWorksheetsArgs = {
  input: LessonPlanWorksheetsInput
};


export type QueryLessonPlansArgs = {
  filterInput?: Maybe<LessonPlansFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  sortInfo?: Maybe<SortInfo>
};


export type QueryLessonsArgs = {
  filterInput?: Maybe<LessonFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  search?: Maybe<Scalars['String']>,
  sortInfo?: Maybe<SortInfo>
};


export type QueryLessonsWithIndexArgs = {
  filter?: Maybe<LessonFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  sortInfo?: Maybe<SortInfo>
};


export type QueryListStudentAwardPointsDetailsArgs = {
  filterInput?: Maybe<ListStudentAwardPointsInput>,
  pageInfo?: Maybe<PageInfo>,
  sortInfo?: Maybe<SortInfo>
};


export type QueryMaterialArgs = {
  id: Scalars['ObjectId']
};


export type QueryMaterialWithIndexArgs = {
  id: Scalars['ObjectId']
};


export type QueryMaterialsArgs = {
  filterInput?: Maybe<MaterialFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  sortInfo?: Maybe<SortInfo>
};


export type QueryMaterialsWithIndexArgs = {
  filter?: Maybe<MaterialFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  sortInfo?: Maybe<SortInfo>
};


export type QueryPackingListArgs = {
  id: Scalars['ObjectId']
};


export type QueryPackingListConfigurationArgs = {
  input: PackingListConfigurationInput
};


export type QueryPackingListEnvelopeUnitsArgs = {
  input: PackingListEnvelopeUnitsInput
};


export type QueryPackingListsArgs = {
  filter?: Maybe<PackingListsFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  sortInfos?: Maybe<Array<SortInfo>>
};


export type QueryParentArgs = {
  id: Scalars['ObjectId']
};


export type QueryParentWithIndexArgs = {
  id: Scalars['ObjectId']
};


export type QueryParentsArgs = {
  filterInput?: Maybe<ParentFilter>,
  pageInfo?: Maybe<PageInfo>,
  search?: Maybe<Scalars['String']>,
  sortInfo?: Maybe<SortInfo>
};


export type QueryParentsWithIndexArgs = {
  filter?: Maybe<ParentFilter>,
  pageInfo?: Maybe<PageInfo>,
  sortInfo?: Maybe<SortInfo>
};


export type QueryPreviewAdHocInvoiceArgs = {
  input: CreateAdHocInvoiceCommand
};


export type QueryPromotionArgs = {
  id: Scalars['ObjectId']
};


export type QueryReceiptArgs = {
  id: Scalars['ObjectId']
};


export type QueryReceiptDetailsArgs = {
  id: Scalars['ObjectId']
};


export type QueryReceiptExportArgs = {
  id: Scalars['ObjectId'],
  printBase64?: Maybe<Scalars['Boolean']>
};


export type QueryReceiptWithIndexArgs = {
  id: Scalars['ObjectId']
};


export type QueryReceiptsArgs = {
  filterInput?: Maybe<ReceiptFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  search?: Maybe<Scalars['String']>,
  sortInfo?: Maybe<SortInfo>
};


export type QueryReceiptsWithIndexArgs = {
  filter?: Maybe<ReceiptFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  sortInfo?: Maybe<SortInfo>
};


export type QueryReferenceDataArgs = {
  id: Scalars['ObjectId']
};


export type QueryReferenceDatasArgs = {
  category?: Maybe<ReferenceDataCategory>,
  showArchived?: Maybe<Scalars['Boolean']>
};


export type QueryRefundArgs = {
  id: Scalars['ObjectId']
};


export type QueryRefundExportArgs = {
  id: Scalars['ObjectId'],
  printBase64?: Maybe<Scalars['Boolean']>
};


export type QueryRefundsArgs = {
  filterInput?: Maybe<RefundFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  sortInfo?: Maybe<SortInfo>
};


export type QueryRegistrationArgs = {
  id: Scalars['ObjectId']
};


export type QueryRegistrationWithIndexArgs = {
  id: Scalars['ObjectId']
};


export type QueryRegistrationsArgs = {
  filterInput?: Maybe<RegistrationFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  search?: Maybe<Scalars['String']>,
  sortInfo?: Maybe<SortInfo>
};


export type QueryRegistrationsWithIndexArgs = {
  filterInput?: Maybe<RegistrationFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  search?: Maybe<Scalars['String']>,
  sortInfo?: Maybe<SortInfo>
};


export type QueryRemarksArgs = {
  filterInput?: Maybe<RemarkFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  search?: Maybe<Scalars['String']>,
  sortInfo?: Maybe<SortInfo>
};


export type QueryReminderArgs = {
  id: Scalars['ObjectId']
};


export type QueryRemindersArgs = {
  filterInput?: Maybe<ReminderFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  search?: Maybe<Scalars['String']>,
  sortInfo?: Maybe<SortInfo>
};


export type QueryReplacementCreditWithCourseArgs = {
  query: StudentReplacementCreditsQuery
};


export type QueryRevenueCoursesWithIndexArgs = {
  filter?: Maybe<CourseFilterInput>,
  pageInfo?: Maybe<PageInfo>
};


export type QueryRewardArgs = {
  id: Scalars['ObjectId']
};


export type QueryRewardsArgs = {
  filterInput?: Maybe<RewardFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  search?: Maybe<Scalars['String']>,
  sortInfo?: Maybe<SortInfo>
};


export type QueryRewardsHistoryArgs = {
  filterInput?: Maybe<RewardHistoryFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  sortInfo?: Maybe<SortInfo>
};


export type QuerySchoolArgs = {
  id: Scalars['ObjectId']
};


export type QuerySchoolsArgs = {
  showArchived?: Maybe<Scalars['Boolean']>,
  showHided?: Maybe<Scalars['Boolean']>
};


export type QuerySeatsAnalyticsByAcademicLevelArgs = {
  filterInput?: Maybe<ClassFilterInput>
};


export type QuerySessionsArgs = {
  types?: Maybe<Array<SessionType>>
};


export type QueryStatementOfAccountArgs = {
  parentId: Scalars['ObjectId']
};


export type QueryStatementOfAccountNumberArgs = {
  parentId: Scalars['ObjectId']
};


export type QueryStatementOfAccountsArgs = {
  filterInput?: Maybe<StatementOfAccountFilter>,
  pageInfo?: Maybe<PageInfo>,
  sortInfo?: Maybe<SortInfo>
};


export type QueryStudentArgs = {
  id: Scalars['ObjectId']
};


export type QueryStudentMerchandiseArgs = {
  pageInfo?: Maybe<PageInfo>,
  sortInfo?: Maybe<SortInfo>,
  studentId: Scalars['ObjectId']
};


export type QueryStudentReportFilterArgs = {
  id: Scalars['ObjectId']
};


export type QueryStudentReportFiltersArgs = {
  userId: Scalars['ObjectId']
};


export type QueryStudentReportsArgs = {
  filter?: Maybe<StudentReportFilterInput>,
  pageInfo?: Maybe<PageInfo>
};


export type QueryStudentWithIndexArgs = {
  id: Scalars['ObjectId']
};


export type QueryStudentsArgs = {
  filterInput?: Maybe<StudentFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  search?: Maybe<Scalars['String']>,
  sortInfo?: Maybe<SortInfo>
};


export type QueryStudentsWithIndexArgs = {
  filter?: Maybe<StudentFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  sortInfo?: Maybe<SortInfo>
};


export type QuerySubmissionAuditLogsArgs = {
  filterInput?: Maybe<SubmissionAuditLogFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  search?: Maybe<Scalars['String']>,
  sortInfo?: Maybe<SortInfo>
};


export type QuerySubmissionTrackingArgs = {
  classId: Scalars['ObjectId'],
  enrollmentType?: Maybe<EnrollmentType>,
  filter?: Maybe<SubmissionTrackingFilter>
};


export type QuerySystemNotificationArgs = {
  id: Scalars['ObjectId']
};


export type QuerySystemNotificationsArgs = {
  filterInput?: Maybe<SystemNotificationFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  search?: Maybe<Scalars['String']>,
  sortInfo?: Maybe<SortInfo>
};


export type QuerySystemUserArgs = {
  id: Scalars['ObjectId']
};


export type QuerySystemUsersArgs = {
  filterInput?: Maybe<SystemUserQuery>,
  pageInfo?: Maybe<PageInfo>,
  sortInfo?: Maybe<SortInfo>
};


export type QueryTaskArgs = {
  id: Scalars['ObjectId']
};


export type QueryTaskListArgs = {
  id: Scalars['ObjectId']
};


export type QueryTaskListsArgs = {
  filterInput?: Maybe<TaskListFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  sortInfo?: Maybe<SortInfo>
};


export type QueryTasksArgs = {
  filterInput?: Maybe<TaskFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  sortInfo?: Maybe<SortInfo>
};


export type QueryTasksWithIndexArgs = {
  filterInput?: Maybe<TaskFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  sortInfo?: Maybe<SortInfo>
};


export type QueryTemplateArgs = {
  id: Scalars['ObjectId']
};


export type QueryTemplatesArgs = {
  filterInput?: Maybe<TemplateFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  search?: Maybe<Scalars['String']>,
  sortInfo?: Maybe<SortInfo>
};


export type QueryTestSubmissionArgs = {
  id: Scalars['ObjectId']
};


export type QueryTestSubmissionsArgs = {
  filterInput?: Maybe<TestSubmissionFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  sortInfo?: Maybe<SortInfo>
};


export type QueryTopicArgs = {
  id: Scalars['ObjectId']
};


export type QueryTopicsArgs = {
  filterInput?: Maybe<TopicsInput>
};


export type QueryUpcomingLessonArgs = {
  input?: Maybe<UpcomingLessonInput>
};


export type QueryUserCustomiseColumnArgs = {
  id: Scalars['ObjectId']
};


export type QueryUserCustomiseColumnsArgs = {
  filterInput?: Maybe<UserCustomiseColumnFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  search?: Maybe<Scalars['String']>,
  sortInfo?: Maybe<SortInfo>
};


export type QueryUserLogsArgs = {
  pageInfo?: Maybe<PageInfo>,
  sortInfo?: Maybe<SortInfo>,
  userId: Scalars['ObjectId']
};


export type QueryUserNotesArgs = {
  pageInfo?: Maybe<PageInfo>,
  sortInfo?: Maybe<SortInfo>,
  userId: Scalars['ObjectId']
};


export type QueryValidateEmailArgs = {
  email: Scalars['String'],
  excludeUserId?: Maybe<Scalars['ObjectId']>
};


export type QueryValidateTeacherAndVenueArgs = {
  input: ValidateTeacherAndVenueInput
};


export type QueryVenueArgs = {
  id: Scalars['ObjectId']
};


export type QueryVenuesArgs = {
  showArchived?: Maybe<Scalars['Boolean']>,
  showDigital?: Maybe<Scalars['Boolean']>
};


export type QueryWhatsappAccountArgs = {
  id: Scalars['ObjectId']
};


export type QueryWhatsappAccountsArgs = {
  filterInput?: Maybe<WhatsappAccountFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  search?: Maybe<Scalars['String']>,
  sortInfo?: Maybe<SortInfo>
};


export type QueryWhatsappMessageArgs = {
  id: Scalars['ObjectId']
};


export type QueryWhatsappMessageCountsArgs = {
  createDate?: Maybe<DateTimeRangeInput>,
  id?: Maybe<Scalars['ObjectId']>
};


export type QueryWhatsappMessagesArgs = {
  filterInput?: Maybe<WhatsappMessageFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  search?: Maybe<Scalars['String']>,
  sortInfo?: Maybe<SortInfo>
};


export type QueryWorkSheetArgs = {
  id: Scalars['ObjectId']
};


export type QueryWorkSheetsArgs = {
  filterInput?: Maybe<WorkSheetItemInput>,
  pageInfo?: Maybe<PageInfo>,
  search?: Maybe<Scalars['String']>,
  sortInfo?: Maybe<SortInfo>
};

export type Receipt = {
   __typename?: 'Receipt',
  _id: Scalars['ObjectId'],
  amount?: Maybe<Scalars['Decimal']>,
  amountPaidSnapshot?: Maybe<Scalars['Decimal']>,
  contact?: Maybe<Parent>,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  emailStatus?: Maybe<ReminderStatus>,
  invoicePaidSnapshots?: Maybe<Array<InvoicePaidSnapshot>>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  isNotified?: Maybe<Array<NotificationChannel>>,
  isOtherFee?: Maybe<Scalars['Boolean']>,
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  parentId?: Maybe<Scalars['ObjectId']>,
  paymentId: Scalars['ObjectId'],
  paymentRecord?: Maybe<Payment>,
  referenceNumber: Scalars['String'],
  status: VoidableStatus,
  waStatus?: Maybe<ReminderStatus>,
};

export type ReceiptAcademicYearIndex = {
   __typename?: 'ReceiptAcademicYearIndex',
  _id: Scalars['ObjectId'],
  year: Scalars['Int'],
};

export type ReceiptClassIndex = {
   __typename?: 'ReceiptClassIndex',
  _id: Scalars['ObjectId'],
  shorthand?: Maybe<Scalars['String']>,
  type?: Maybe<ClassType>,
};

export type ReceiptCourseIndex = {
   __typename?: 'ReceiptCourseIndex',
  _id: Scalars['ObjectId'],
  nameShort: Scalars['String'],
};

export type ReceiptFilterInput = {
  dateRange?: Maybe<DateTimeRangeInput>,
  ids?: Maybe<Array<Scalars['ObjectId']>>,
  isNotified?: Maybe<Array<NotificationChannel>>,
  isOtherFee?: Maybe<Scalars['Boolean']>,
  name?: Maybe<Scalars['String']>,
  parentId?: Maybe<Scalars['ObjectId']>,
  paymentMethods?: Maybe<Array<Scalars['String']>>,
  status?: Maybe<Array<VoidableStatus>>,
};

export type ReceiptIndex = {
   __typename?: 'ReceiptIndex',
  _id: Scalars['ObjectId'],
  academicYears?: Maybe<Array<ReceiptAcademicYearIndex>>,
  amount?: Maybe<Scalars['Decimal']>,
  amountDue?: Maybe<Scalars['Decimal']>,
  amountPaidSnapshot?: Maybe<Scalars['Decimal']>,
  classes?: Maybe<Array<ReceiptClassIndex>>,
  courses?: Maybe<Array<ReceiptCourseIndex>>,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  emailStatus?: Maybe<ReminderStatus>,
  invoicePaidSnapshots?: Maybe<Array<InvoicePaidSnapshot>>,
  invoices?: Maybe<Array<ReceiptInvoiceIndex>>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  isNotified?: Maybe<Array<NotificationChannel>>,
  isOtherFee?: Maybe<Scalars['Boolean']>,
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  parent?: Maybe<ReceiptParentIndex>,
  parentId?: Maybe<Scalars['ObjectId']>,
  payment?: Maybe<ReceiptPaymentIndex>,
  paymentId: Scalars['ObjectId'],
  referenceNumber: Scalars['String'],
  secondaryParents?: Maybe<Array<ReceiptParentIndex>>,
  status: VoidableStatus,
  students?: Maybe<Array<ReceiptStudentIndex>>,
  waStatus?: Maybe<ReminderStatus>,
};

export type ReceiptIndexPage = {
   __typename?: 'ReceiptIndexPage',
  items: Array<ReceiptIndex>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

export type ReceiptInvoiceIndex = {
   __typename?: 'ReceiptInvoiceIndex',
  _id: Scalars['ObjectId'],
  dueDate?: Maybe<Scalars['DateTime']>,
  total?: Maybe<Scalars['Decimal']>,
};

export type ReceiptPage = {
   __typename?: 'ReceiptPage',
  items: Array<Receipt>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

export type ReceiptParentIndex = {
   __typename?: 'ReceiptParentIndex',
  _id: Scalars['ObjectId'],
  email?: Maybe<Scalars['String']>,
  fullName: Scalars['String'],
  mobile: Telephone,
};

export type ReceiptPaymentIndex = {
   __typename?: 'ReceiptPaymentIndex',
  _id: Scalars['ObjectId'],
  createDateTime: Scalars['DateTime'],
  method: Scalars['String'],
  /** The date when the payment was made */
  paymentDate?: Maybe<Scalars['DateTime']>,
  reference?: Maybe<Scalars['String']>,
  remarks?: Maybe<Scalars['String']>,
};

export type ReceiptStudentIndex = {
   __typename?: 'ReceiptStudentIndex',
  _id: Scalars['ObjectId'],
  fullName: Scalars['String'],
};

export type RecordExamScoreInput = {
  academicLevelId: Scalars['ObjectId'],
  examType: Scalars['String'],
  marksPercentage: Scalars['Float'],
  mcq?: Maybe<ScoreRecordInput>,
  practical?: Maybe<ScoreRecordInput>,
  qeq?: Maybe<ScoreRecordInput>,
  scoreGrade: Scalars['String'],
  stream: Scalars['String'],
  studentId: Scalars['ObjectId'],
  subject: Scalars['String'],
  totalMarks: ScoreRecordInput,
  year: Scalars['Int'],
};

export type ReferenceData = {
   __typename?: 'ReferenceData',
  _id: Scalars['ObjectId'],
  category: ReferenceDataCategory,
  countryCode?: Maybe<Scalars['String']>,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  isArchived: Scalars['Boolean'],
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  key: Scalars['String'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  order?: Maybe<Scalars['Int']>,
  value: Scalars['String'],
};

export enum ReferenceDataCategory {
  AcademicStream = 'AcademicStream',
  ExamType = 'ExamType',
  Gender = 'Gender',
  NameOfBank = 'NameOfBank',
  PaymentProcessor = 'PaymentProcessor',
  RewardCategory = 'RewardCategory',
  Salutation = 'Salutation',
  Subject = 'Subject'
}

export type Refund = {
   __typename?: 'Refund',
  _id: Scalars['ObjectId'],
  adminFee?: Maybe<Scalars['Decimal']>,
  amount?: Maybe<Scalars['Decimal']>,
  bankAccountNumber?: Maybe<Scalars['String']>,
  bankName?: Maybe<Scalars['String']>,
  chequeNumber?: Maybe<Scalars['String']>,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  emailStatus?: Maybe<ReminderStatus>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  isVoid?: Maybe<Scalars['Boolean']>,
  lastDigits?: Maybe<Scalars['String']>,
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  parent?: Maybe<Parent>,
  parentId: Scalars['ObjectId'],
  payNowNumber?: Maybe<Scalars['String']>,
  paymentProcessor?: Maybe<Scalars['String']>,
  processedDate?: Maybe<Scalars['DateTime']>,
  reason?: Maybe<Scalars['String']>,
  reference?: Maybe<Scalars['String']>,
  referenceNumber: Scalars['String'],
  refundMethod?: Maybe<PaymentMode>,
  refundedDate?: Maybe<Scalars['DateTime']>,
  snapshot?: Maybe<RefundSnapshot>,
  waStatus?: Maybe<ReminderStatus>,
};

export type RefundFilterInput = {
  dateRange?: Maybe<DateTimeRangeInput>,
  isVoid?: Maybe<Scalars['Boolean']>,
  name?: Maybe<Scalars['String']>,
  parentId?: Maybe<Scalars['ObjectId']>,
  refundMethods?: Maybe<Array<Scalars['String']>>,
};

export type RefundPage = {
   __typename?: 'RefundPage',
  items: Array<Refund>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

export type RefundSnapshot = {
   __typename?: 'RefundSnapshot',
  parentFullName: Scalars['String'],
};

export type RegenerateRegistrationInput = {
  dueDate?: Maybe<Scalars['DateTime']>,
  id: Scalars['ObjectId'],
  selectedLessons?: Maybe<Array<SelectedLessonInput>>,
  startDate: Scalars['DateTime'],
};

export type RegisterStudentInput = {
  academicLevelId: Scalars['ObjectId'],
  address?: Maybe<UserAddressInput>,
  alias: Scalars['String'],
  alternateUserId?: Maybe<Scalars['String']>,
  alternativeEmails?: Maybe<Array<Scalars['String']>>,
  avatarUrl?: Maybe<Scalars['String']>,
  birthDate?: Maybe<Scalars['DateTime']>,
  consent: ConsentInput,
  email?: Maybe<Scalars['String']>,
  fullName: Scalars['String'],
  gender: Scalars['String'],
  homePhone?: Maybe<TelephoneInput>,
  mobile?: Maybe<TelephoneInput>,
  parentIds?: Maybe<Array<Scalars['ObjectId']>>,
  primaryParentId: Scalars['ObjectId'],
  primarySchoolId?: Maybe<Scalars['ObjectId']>,
  primaryStream?: Maybe<Scalars['String']>,
  remarks?: Maybe<Scalars['String']>,
  secondarySchoolId?: Maybe<Scalars['ObjectId']>,
  secondaryStream?: Maybe<Scalars['String']>,
  userId: Scalars['String'],
};

export type RegisterStudentOutput = {
   __typename?: 'RegisterStudentOutput',
  academicLevelId: Scalars['ObjectId'],
  address?: Maybe<UserAddressOutput>,
  alias: Scalars['String'],
  alternateUserId?: Maybe<Scalars['String']>,
  alternativeEmails?: Maybe<Array<Scalars['String']>>,
  avatarUrl?: Maybe<Scalars['String']>,
  birthDate?: Maybe<Scalars['DateTime']>,
  consent: ConsentOutput,
  email?: Maybe<Scalars['String']>,
  fullName: Scalars['String'],
  gender: Scalars['String'],
  homePhone?: Maybe<TelephoneOutput>,
  mobile?: Maybe<TelephoneOutput>,
  parentIds?: Maybe<Array<Scalars['ObjectId']>>,
  primaryParentId: Scalars['ObjectId'],
  primarySchoolId?: Maybe<Scalars['ObjectId']>,
  primaryStream?: Maybe<Scalars['String']>,
  remarks?: Maybe<Scalars['String']>,
  secondarySchoolId?: Maybe<Scalars['ObjectId']>,
  secondaryStream?: Maybe<Scalars['String']>,
  userId: Scalars['String'],
};

export type Registration = {
   __typename?: 'Registration',
  _id: Scalars['ObjectId'],
  approvalDate?: Maybe<Scalars['DateTime']>,
  class?: Maybe<Class>,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  currentStep?: Maybe<Scalars['Float']>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  dueDate?: Maybe<Scalars['DateTime']>,
  effectedWaitingLists?: Maybe<Array<Registration>>,
  emailStatus?: Maybe<ReminderStatus>,
  enrollment?: Maybe<Enrollment>,
  incompleteDeadline: Scalars['DateTime'],
  invoice?: Maybe<Invoice>,
  invoices?: Maybe<Array<Invoice>>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  isPrioritized?: Maybe<Scalars['Boolean']>,
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  linkRegistration?: Maybe<Registration>,
  name?: Maybe<Scalars['String']>,
  otherRegistrations?: Maybe<Array<Registration>>,
  remarks?: Maybe<Scalars['String']>,
  reminders?: Maybe<Array<Reminder>>,
  reservationsForTrial?: Maybe<Array<Registration>>,
  selectedLessons?: Maybe<Array<SelectedLesson>>,
  session?: Maybe<Session>,
  startDate?: Maybe<Scalars['DateTime']>,
  status: RegistrationsStatus,
  student: Student,
  trialRegistration?: Maybe<Registration>,
  type: RegistrationType,
  updateHistories?: Maybe<Array<WaitingListUpdateHistory>>,
  waStatus?: Maybe<ReminderStatus>,
  waitingListCreatedAt?: Maybe<Scalars['DateTime']>,
};

export type RegistrationClassIndex = {
   __typename?: 'RegistrationClassIndex',
  _id: Scalars['ObjectId'],
  courseId: Scalars['ObjectId'],
  learningArrangement: LearningArrangementType,
  name: Scalars['String'],
};

export type RegistrationCourseIndex = {
   __typename?: 'RegistrationCourseIndex',
  _id: Scalars['ObjectId'],
  name: Scalars['String'],
};

export type RegistrationEnrollmentIndex = {
   __typename?: 'RegistrationEnrollmentIndex',
  _id: Scalars['ObjectId'],
  followUpStatus?: Maybe<FollowUpStatus>,
  followUpStatusLastModifiedByFullName?: Maybe<Scalars['String']>,
  followUpStatusLastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  group?: Maybe<EnrollmentGroupType>,
  preferredLearningArrangement?: Maybe<LearningArrangementType>,
  startDate?: Maybe<Scalars['DateTime']>,
  status: EnrollmentStatus,
};

export type RegistrationFilterInput = {
  academicLevelIds?: Maybe<Array<Scalars['ObjectId']>>,
  classCode?: Maybe<Scalars['String']>,
  classIds?: Maybe<Array<Scalars['ObjectId']>>,
  classTypes?: Maybe<Array<ClassType>>,
  courseIds?: Maybe<Array<Scalars['ObjectId']>>,
  createdBy?: Maybe<Scalars['ObjectId']>,
  dateRange?: Maybe<DateTimeRangeInput>,
  isWaitlisted?: Maybe<Scalars['Boolean']>,
  name?: Maybe<Scalars['String']>,
  status?: Maybe<Array<RegistrationsStatus>>,
  studentIds?: Maybe<Array<Scalars['ObjectId']>>,
  teacherIds?: Maybe<Array<Scalars['ObjectId']>>,
  types?: Maybe<Array<RegistrationType>>,
  venueIds?: Maybe<Array<Scalars['ObjectId']>>,
  weekDays?: Maybe<Array<WeekDay>>,
};

export type RegistrationIndex = {
   __typename?: 'RegistrationIndex',
  _id: Scalars['ObjectId'],
  class?: Maybe<RegistrationClassIndex>,
  classCapacity?: Maybe<ClassCapacityDto>,
  course?: Maybe<RegistrationCourseIndex>,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  dueDate?: Maybe<Scalars['DateTime']>,
  emailStatus?: Maybe<ReminderStatus>,
  enrollment?: Maybe<RegistrationEnrollmentIndex>,
  invoice?: Maybe<RegistrationInvoiceIndex>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  name?: Maybe<Scalars['String']>,
  startDate?: Maybe<Scalars['DateTime']>,
  status: RegistrationsStatus,
  student?: Maybe<RegistrationStudentIndex>,
  type: RegistrationType,
  waStatus?: Maybe<ReminderStatus>,
};

export type RegistrationIndexPage = {
   __typename?: 'RegistrationIndexPage',
  items: Array<RegistrationIndex>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

export type RegistrationInvoiceIndex = {
   __typename?: 'RegistrationInvoiceIndex',
  _id: Scalars['ObjectId'],
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  status: InvoiceStatus,
};

export type RegistrationLessonModeCommand = {
  lessonId: Scalars['ObjectId'],
  lessonMode?: Maybe<LearningArrangementType>,
};

export type RegistrationPage = {
   __typename?: 'RegistrationPage',
  items: Array<Registration>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

export type RegistrationSession = {
   __typename?: 'RegistrationSession',
  regenerateStartData?: Maybe<Scalars['Date']>,
  registrationId: Scalars['ObjectId'],
  registrationStage: RegistrationStage,
};

export enum RegistrationsStatus {
  Cancelled = 'Cancelled',
  Complete = 'Complete',
  Draft = 'Draft',
  Expired = 'Expired',
  Paid = 'Paid',
  Reserved = 'Reserved',
  Waitlisted = 'Waitlisted'
}

export enum RegistrationStage {
  Regular = 'Regular',
  Reservation = 'Reservation',
  Trial = 'Trial',
  WaitingList = 'WaitingList'
}

export type RegistrationStudentIndex = {
   __typename?: 'RegistrationStudentIndex',
  _id: Scalars['ObjectId'],
  avatarUrl?: Maybe<Scalars['String']>,
  fullName: Scalars['String'],
  gender?: Maybe<Scalars['String']>,
  userId?: Maybe<Scalars['String']>,
};

export enum RegistrationType {
  Regular = 'Regular',
  Trial = 'Trial'
}

export type RegularMakeUpCreditInput = {
  term1: Scalars['Int'],
  term2: Scalars['Int'],
  term3: Scalars['Int'],
  term4: Scalars['Int'],
};

export type Relationship = {
   __typename?: 'Relationship',
  _id: Scalars['ObjectId'],
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  parentId1: Scalars['ObjectId'],
  parentId2: Scalars['ObjectId'],
};

export type Remark = {
   __typename?: 'Remark',
  _id: Scalars['ObjectId'],
  attendance?: Maybe<Attendance>,
  /** A string that contains a remark in a formatted string that will be formatted further in the front-end. */
  content: Scalars['String'],
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  enrollmentSuitability?: Maybe<EnrollmentSuitability>,
  isArchived?: Maybe<Scalars['Boolean']>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  isFlagged?: Maybe<Scalars['Boolean']>,
  /** A boolean that signifies the pinned status of the remark. */
  isPinned?: Maybe<Scalars['Boolean']>,
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  /** Level type for Remark, used for parent remarks. */
  levelType?: Maybe<RemarkLevelType>,
  /** The ID of a remark this remark is created as a reply for. */
  parentId?: Maybe<Scalars['ObjectId']>,
  /** 
 * The `ObjectId` of the `Entity` to bind this remark to. For example, if this
   * remark is for an attendance, use the id of the attendance record.
 */
  subjectId: Scalars['ObjectId'],
  /** 
 * The type of the `Entity` defined from an enum to bind this remark to. For
   * example, if this remark is for an attendance, use the enum value
   * `RemarkSubject.Attendance`
 */
  type: RemarkType,
};


export type RemarkAttendanceArgs = {
  attendanceId?: Maybe<Scalars['ObjectId']>
};

export type RemarkFilterInput = {
  /** A boolean that signifies the pinned status of the remark. */
  isPinned?: Maybe<Scalars['Boolean']>,
  /** The ID of a remark this remark is created as a reply for. */
  parentId?: Maybe<Scalars['ObjectId']>,
  showArchived?: Maybe<Scalars['Boolean']>,
  /** 
 * The `ObjectId` of the `Entity` to bind this remark to. For example, if this
   * remark is for an attendance, use the id of the attendance record.
 */
  subjectId: Scalars['ObjectId'],
  /** 
 * The type of the `Entity` defined from an enum to bind this remark to. For
   * example, if this remark is for an attendance, use the enum value
   * `RemarkSubject.Attendance`
 */
  type: Scalars['String'],
};

export enum RemarkLevelType {
  General = 'General',
  High = 'High',
  Low = 'Low',
  Medium = 'Medium'
}

export type RemarkPage = {
   __typename?: 'RemarkPage',
  items: Array<Remark>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

export enum RemarkType {
  Attendance = 'Attendance',
  Parent = 'Parent',
  Student = 'Student',
  Task = 'Task'
}

/** DEPRECATED, Please don't use this. */
export type RemarkUnion = Remark | StudentRemarks;

export type Reminder = {
   __typename?: 'Reminder',
  _id: Scalars['ObjectId'],
  arrangement?: Maybe<Arrangement>,
  arrangementId?: Maybe<Scalars['ObjectId']>,
  attachmentStatus?: Maybe<ReminderStatus>,
  attendance?: Maybe<Attendance>,
  attendanceId?: Maybe<Scalars['ObjectId']>,
  campaignId?: Maybe<Scalars['ObjectId']>,
  class?: Maybe<Class>,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  destination?: Maybe<Scalars['String']>,
  file?: Maybe<ReminderFile>,
  invoiceIds?: Maybe<Array<Scalars['ObjectId']>>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  materialId?: Maybe<Scalars['ObjectId']>,
  message?: Maybe<Scalars['String']>,
  parentId?: Maybe<Scalars['ObjectId']>,
  receiptId?: Maybe<Scalars['ObjectId']>,
  receiver?: Maybe<Parent>,
  refundId?: Maybe<Scalars['ObjectId']>,
  registration?: Maybe<Registration>,
  registrationId?: Maybe<Scalars['ObjectId']>,
  scheduledDate?: Maybe<Scalars['DateTime']>,
  sendDate?: Maybe<Scalars['DateTime']>,
  sendStatus?: Maybe<Scalars['String']>,
  sender?: Maybe<Scalars['String']>,
  sendgridEmailStatus?: Maybe<Scalars['String']>,
  sendgridMessageId?: Maybe<Scalars['String']>,
  status?: Maybe<ReminderStatus>,
  student?: Maybe<Student>,
  studentIds?: Maybe<Array<Scalars['ObjectId']>>,
  subject?: Maybe<Scalars['String']>,
  taskId?: Maybe<Scalars['ObjectId']>,
  template?: Maybe<Template>,
  templateId?: Maybe<Scalars['ObjectId']>,
  type?: Maybe<ReminderType>,
  whatsappMessage?: Maybe<WhatsappMessage>,
  whatsappMessageId?: Maybe<Scalars['ObjectId']>,
};

export type ReminderFile = {
   __typename?: 'ReminderFile',
  content: Scalars['String'],
  disposition: Scalars['String'],
  fileName: Scalars['String'],
  type: Scalars['String'],
};

export type ReminderFilterInput = {
  campaignId?: Maybe<Scalars['ObjectId']>,
  dateRange?: Maybe<DateTimeRangeInput>,
  name?: Maybe<Scalars['String']>,
  parentId?: Maybe<Scalars['ObjectId']>,
  status?: Maybe<Array<ReminderStatus>>,
  templateTypes?: Maybe<Array<TemplateType>>,
  types?: Maybe<Array<ReminderType>>,
};

export type ReminderPage = {
   __typename?: 'ReminderPage',
  items: Array<Reminder>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

export type ReminderPreview = {
   __typename?: 'ReminderPreview',
  emailMessage?: Maybe<Scalars['String']>,
  parent?: Maybe<Parent>,
  students?: Maybe<Array<Student>>,
  subject?: Maybe<Scalars['String']>,
  whatsappMessage?: Maybe<Scalars['String']>,
};

export enum ReminderStatus {
  Cancelled = 'Cancelled',
  Delivered = 'Delivered',
  Failed = 'Failed',
  NotSent = 'NotSent',
  Pending = 'Pending',
  Processing = 'Processing',
  Read = 'Read',
  Sent = 'Sent'
}

export enum ReminderType {
  Email = 'Email',
  Whatsapp = 'Whatsapp'
}

/** 
 * Inputs for the Remove LessonFlow Schedule mutation.
 *   Remove a schedule item in a lesson flow.
 */
export type RemoveLessonFlowScheduleInput = {
  /** Id of the lesson flow containing the schedule to be removed. */
  lessonFlowId: Scalars['ObjectId'],
  /** Id of the lesson plan containing the schedule to be removed. */
  lessonPlanId: Scalars['ObjectId'],
  /** Id of the schedule in the lesson flow to remove. */
  scheduleId: Scalars['ObjectId'],
};

/** Indicates the effect of the remove lesson flow schedule operation. */
export type RemoveLessonFlowScheduleResponse = {
   __typename?: 'RemoveLessonFlowScheduleResponse',
  /** The updated lesson plan - removed schedule of lesson flow. */
  updatedLessonPlan: LessonPlan,
  /** The number of lessons updated with the schedule of the lesson flow. */
  updatedLessonsCount: Scalars['Int'],
};

/** Contains the lesson plan id and the class id where the lesson plan should be removed. */
export type RemoveLessonPlanFromClassInput = {
  /** The class to remove the lesson plan. */
  classId: Scalars['ObjectId'],
  /** The lesson plan containing the lesson flows to remove from a class. */
  lessonPlanId: Scalars['ObjectId'],
};

/** Indicates the effect of the remove lesson plan from class operation. */
export type RemoveLessonPlanFromClassResponse = {
   __typename?: 'RemoveLessonPlanFromClassResponse',
  /** The number of lessons removed with lesson flows. */
  lessonFlowsRemovedCount: Scalars['Int'],
  /** The updated class - removed applied lesson plan. */
  updatedClass: Class,
  /** The updated lesson plan - removed applied class. */
  updatedLessonPlan: LessonPlan,
};

export type ReorderLessonFlowItemsInput = {
  lessonFlowItemIds?: Maybe<Array<Scalars['ObjectId']>>,
};

export type ReplyFeedbackInput = {
  id: Scalars['ObjectId'],
  text: Scalars['String'],
};

export type ResetDefaultInput = {
  columns?: Maybe<Array<ResetUserCustomiseColumnInput>>,
  tableName?: Maybe<Scalars['String']>,
};

export type ResetUserCustomiseColumnInput = {
  columnName: Scalars['String'],
  columnShowName?: Maybe<Scalars['String']>,
  isRequired: Scalars['Boolean'],
  isVisible: Scalars['Boolean'],
  ownerId?: Maybe<Scalars['ObjectId']>,
  tableName: Scalars['String'],
  tableShowName?: Maybe<Scalars['String']>,
  weight: Scalars['Int'],
};

export type RestoreLessonFlowItemVersion = {
  lessonFlowId: Scalars['ObjectId'],
  lessonNumber: Scalars['Int'],
  version: Scalars['Int'],
};

export enum ResultOutcome {
  CanAttend = 'CanAttend',
  CanAttendWithConditional = 'CanAttendWithConditional',
  NotEligible = 'NotEligible'
}

export type RevenueReportQuery = {
  courseId: Scalars['ObjectId'],
  dateRange: DateTimeRangeInput,
};

export type Reward = {
   __typename?: 'Reward',
  _id: Scalars['ObjectId'],
  avatar: Scalars['String'],
  category: Scalars['String'],
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  description: Scalars['String'],
  galleries?: Maybe<Array<Scalars['String']>>,
  isArchived: Scalars['Boolean'],
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  isVisible: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  name: Scalars['String'],
  points: Scalars['Float'],
  remoteGalleries?: Maybe<Array<Scalars['String']>>,
  stock: Scalars['Float'],
};

export type RewardDetail = {
   __typename?: 'RewardDetail',
  orderUrl?: Maybe<Scalars['String']>,
  quantity: Scalars['Int'],
  reward?: Maybe<Reward>,
  rewardId: Scalars['ObjectId'],
  trackingNumber?: Maybe<Scalars['String']>,
};

/** Reward redemption order information */
export type RewardDetailInput = {
  /** Count of reward items in order */
  quantity: Scalars['Int'],
  /** Reward item id associated with the order */
  rewardId: Scalars['ObjectId'],
  /** Optional order tracking number */
  trackingNumber?: Maybe<Scalars['String']>,
};

export type RewardFilterInput = {
  categories?: Maybe<Array<Scalars['String']>>,
  isVisible?: Maybe<Scalars['Boolean']>,
  points?: Maybe<Array<NumberRangeInput>>,
  showArchived?: Maybe<Scalars['Boolean']>,
  stock?: Maybe<Array<NumberRangeInput>>,
};

export type RewardHistory = {
   __typename?: 'RewardHistory',
  _id: Scalars['ObjectId'],
  adjustment: Scalars['Int'],
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  /** Optional gradable (Exercise or Homework) linked to reward transaction */
  gradableId: Scalars['ObjectId'],
  isArchived: Scalars['Boolean'],
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  orderDetail?: Maybe<RewardDetail>,
  orderHistory?: Maybe<Array<OrderHistory>>,
  remarks?: Maybe<Scalars['String']>,
  rewardPoints: Array<RewardPoints>,
  rewardPointsIds: Array<Scalars['ObjectId']>,
  status: RewardHistoryStatus,
  student: Student,
  studentId: Scalars['ObjectId'],
  type: RewardHistoryType,
};

export type RewardHistoryFilterInput = {
  /** Filter by course ID */
  courseId?: Maybe<Scalars['ObjectId']>,
  dateRange?: Maybe<DateRangeInput>,
  /** Filter Reward History by isArchived status */
  isArchived?: Maybe<Scalars['Boolean']>,
  /** Filter Reward History to toggle show archived or show all records. */
  showArchived?: Maybe<Scalars['Boolean']>,
  /** Filter Reward History by one or more status */
  statuses?: Maybe<Array<RewardHistoryStatus>>,
  /** Filter Reward History by one or more student IDs */
  studentIds?: Maybe<Array<Scalars['ObjectId']>>,
  /** Filter Reward History by student name search */
  studentName?: Maybe<Scalars['String']>,
  type?: Maybe<RewardHistoryType>,
};

export type RewardHistoryPage = {
   __typename?: 'RewardHistoryPage',
  items: Array<RewardHistory>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

export enum RewardHistoryStatus {
  Cancelled = 'Cancelled',
  Collected = 'Collected',
  HandedToCourier = 'HandedToCourier',
  OrderPending = 'OrderPending',
  Preparing = 'Preparing',
  ReadyForCollection = 'ReadyForCollection'
}

export enum RewardHistoryType {
  ManualAdjustment = 'ManualAdjustment',
  PointRefund = 'PointRefund',
  Redemption = 'Redemption',
  Reward = 'Reward'
}

export type RewardInfo = {
   __typename?: 'RewardInfo',
  /** 
 * A List of reward mechanics containing criteria on minimum and maximum scores
   * and how many points will be awarded to each criteria.
 */
  rewardMechanics?: Maybe<Array<RewardMechanic>>,
  /** 
 * This will determine wether a `Reward` is `Active` or `Inactive` which can be
   * used for awarding points to a student based on the mechanics. It could be used
   * to determine if the mechanics in this reward will be applied or not.
 */
  status?: Maybe<RewardStatus>,
};

/** A Property of Exercise and Homework that contains information about Rewards. */
export type RewardInfoInput = {
  /** 
 * A List of reward mechanics containing criteria on minimum and maximum scores
   * and how many points will be awarded to each criteria.
 */
  rewardMechanics?: Maybe<Array<RewardMechanicInput>>,
  /** The status set from Rewards Management. */
  status?: Maybe<Scalars['String']>,
};

export type RewardMechanic = {
   __typename?: 'RewardMechanic',
  /** The points that will be awarded if the minimum and maximum conditions are met. */
  award?: Maybe<Scalars['Float']>,
  /** Number used as the current Band number for the reward mechanic. */
  band?: Maybe<Scalars['Float']>,
  /** This holds the maximum and minimum score that determines wether a score is eligible for award. */
  score?: Maybe<EligibleScore>,
};

/** This will contain the Score Eligibility and Award score for the Reward. */
export type RewardMechanicInput = {
  /** The points to award the student. */
  award: Scalars['Int'],
  /** Number used as the current Band count for the reward mechanic. */
  band: Scalars['Int'],
  /** Contains the minimum and maximum score for the mechanic to be active. */
  score: EligibleScoreInput,
};

export type RewardPage = {
   __typename?: 'RewardPage',
  items: Array<Reward>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

export type RewardPoints = {
   __typename?: 'RewardPoints',
  _id: Scalars['ObjectId'],
  /** Reward Point attributed to a gradable */
  gradableId?: Maybe<Scalars['ObjectId']>,
  points: Scalars['Int'],
  status: RewardPointsStatus,
  used: Scalars['Int'],
};

/** Reward points input to add/deduct by student */
export type RewardPointsItemInput = {
  /** Amount of reward points */
  points: Scalars['Float'],
  /** Student ID on where reward points will be added */
  studentId: Scalars['ObjectId'],
};

export enum RewardPointsStatus {
  Active = 'Active',
  Expired = 'Expired'
}

export type RewardsHistoryCounter = {
   __typename?: 'RewardsHistoryCounter',
  accumulated: Scalars['Int'],
  redeemed: Scalars['Int'],
};

export enum RewardStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type SaveAsNewLessonFlowInput = {
  academicYearId: Scalars['ObjectId'],
  lessonFlowId: Scalars['ObjectId'],
  name: Scalars['String'],
};

export type SaveExerciseInput = {
  create?: Maybe<Array<CreateExerciseInput>>,
  delete?: Maybe<Array<IdInput>>,
  update?: Maybe<Array<UpdateExerciseInput>>,
};

export type SaveHomeworkInput = {
  create?: Maybe<Array<CreateHomeworkInput>>,
  delete?: Maybe<Array<IdInput>>,
  update?: Maybe<Array<UpdateHomeworkInput>>,
};

export type SaveSubmissionsInput = {
  classId: Scalars['ObjectId'],
  create?: Maybe<Array<CreateSubmissionInput>>,
  delete?: Maybe<Array<DeleteSubmissionInput>>,
  update?: Maybe<Array<UpdateSubmissionInput>>,
};

/** Deprecated - No usage last 90 days based on Apollo graphql metrics. */
export type SaveTopicsInput = {
  create: Array<CreateTopicInput>,
  delete: Array<DeleteTopicInput>,
  update: Array<UpdateTopicInput>,
};

export type School = {
   __typename?: 'School',
  _id: Scalars['ObjectId'],
  alias?: Maybe<Scalars['String']>,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  educationStage: EducationStage,
  isArchived: Scalars['Boolean'],
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  isVisible: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  name: Scalars['String'],
  systemId?: Maybe<Scalars['String']>,
};

export type ScoreRecord = {
   __typename?: 'ScoreRecord',
  score: Scalars['Float'],
  total: Scalars['Float'],
};

export type ScoreRecordInput = {
  score: Scalars['Float'],
  total: Scalars['Float'],
};

export type ScoreStats = {
   __typename?: 'ScoreStats',
  average?: Maybe<Scalars['Float']>,
  max?: Maybe<Scalars['Float']>,
  min?: Maybe<Scalars['Float']>,
};

export type SeatAnalytics = {
   __typename?: 'SeatAnalytics',
  enrollmentRating: Scalars['Int'],
  totalAvailableSlots: Scalars['Int'],
  totalStudents: Scalars['Int'],
};

export enum SeatType {
  Bypass = 'Bypass',
  Makeup = 'Makeup',
  Regular = 'Regular'
}

export type SelectedLesson = {
   __typename?: 'SelectedLesson',
  asMakeUp?: Maybe<Scalars['Boolean']>,
  byPass?: Maybe<Scalars['Boolean']>,
  lesson: Lesson,
  makeUpLesson?: Maybe<Lesson>,
};

export type SelectedLessonInput = {
  asMakeUp?: Maybe<Scalars['Boolean']>,
  byPass?: Maybe<Scalars['Boolean']>,
  lessonId: Scalars['ObjectId'],
  makeUpLessonId?: Maybe<Scalars['ObjectId']>,
};

export type SelectedReasonInput = {
  checked?: Maybe<Scalars['Boolean']>,
  description?: Maybe<Scalars['String']>,
};

export type SelectionData = {
   __typename?: 'SelectionData',
  academicWeek?: Maybe<AcademicWeek>,
  lessonNumber?: Maybe<Scalars['Int']>,
  lessonStart?: Maybe<Scalars['DateTime']>,
};

export type SendReminderInput = {
  arrangementId?: Maybe<Scalars['ObjectId']>,
  attendanceId?: Maybe<Scalars['ObjectId']>,
  classIds?: Maybe<Array<Scalars['ObjectId']>>,
  emailInput?: Maybe<EmailReminderInput>,
  enrollmentId?: Maybe<Scalars['ObjectId']>,
  extraVariables?: Maybe<ExtraReminderVariables>,
  invoiceId?: Maybe<Scalars['ObjectId']>,
  paymentId?: Maybe<Scalars['ObjectId']>,
  receiptId?: Maybe<Scalars['ObjectId']>,
  refundId?: Maybe<Scalars['ObjectId']>,
  registrationId?: Maybe<Scalars['ObjectId']>,
  taskId?: Maybe<Scalars['ObjectId']>,
  types: Array<ReminderType>,
  whatsappInput?: Maybe<WhatsappReminderInput>,
};

export type SendTestReminderInput = {
  recipient?: Maybe<Scalars['String']>,
  senderId?: Maybe<Scalars['ObjectId']>,
  templateId: Scalars['ObjectId'],
  type: ReminderType,
  whatsappRecipient?: Maybe<TelephoneInput>,
};

/** 
 * The Lesson Sequence Item object that contains the exercise ID information (if not a schedule break),
 *     the order and duration of the item as part of the Lesson Flow Item's sequence
 */
export type SequenceItem = {
   __typename?: 'SequenceItem',
  /** The ID of the Lesson Sequence item. */
  _id: Scalars['ObjectId'],
  /** Optional description of the sequence item */
  description?: Maybe<Scalars['String']>,
  /** The duration (in minutes) of the exercise / break. */
  duration: Scalars['Float'],
  /** Populated Exercise object in reference to `exerciseId` */
  exercise?: Maybe<Exercise>,
  /** Optional exercise ID. If not set, sequence item will be considered as a break */
  exerciseId?: Maybe<Scalars['ObjectId']>,
};

export type Session = {
   __typename?: 'Session',
  _id: Scalars['ObjectId'],
  arrangement?: Maybe<Arrangement>,
  arrangementSession?: Maybe<ArrangementSession>,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  expiredAt: Scalars['DateTime'],
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  registration?: Maybe<Registration>,
  registrationSession?: Maybe<RegistrationSession>,
  status: SessionStatus,
  type: SessionType,
};

export enum SessionStatus {
  Complete = 'Complete',
  Discard = 'Discard',
  Processing = 'Processing'
}

export enum SessionType {
  Arrangement = 'Arrangement',
  Registration = 'Registration'
}

export type SetLessonFlowItemExceptionsInput = {
  exceptedClassIds?: Maybe<Array<Scalars['ObjectId']>>,
  lessonFlowItemId: Scalars['ObjectId'],
};

export type SimulationCapacityInput = {
  classId: Scalars['ObjectId'],
  endDate?: Maybe<Scalars['DateTime']>,
  quantity: Scalars['Int'],
  startDate?: Maybe<Scalars['DateTime']>,
  type: CapacitySimulationType,
};

export enum SortDirection {
  Asc = 'Asc',
  Desc = 'Desc'
}

export type SortInfo = {
  direction: SortDirection,
  field: Scalars['String'],
};

export type StandardCourseFee = {
   __typename?: 'StandardCourseFee',
  amount: Scalars['Float'],
  type: CourseFeeType,
};

export type StandardCourseFeeDo = {
   __typename?: 'StandardCourseFeeDO',
  amount: Scalars['Decimal'],
  type: CourseFeeType,
};

export type StandardCourseFeeInput = {
  amount: Scalars['Float'],
  type: CourseFeeType,
};

export type StatementOfAccount = {
   __typename?: 'StatementOfAccount',
  amountAudits?: Maybe<AmountAudit>,
  creditRemain?: Maybe<Scalars['Decimal']>,
  hasUnpaidInvoice?: Maybe<Scalars['Boolean']>,
  id: Scalars['ObjectId'],
  invoices?: Maybe<Array<Invoice>>,
  parent: Parent,
  receipts?: Maybe<Array<Receipt>>,
  refund?: Maybe<Scalars['Decimal']>,
  refunds?: Maybe<Array<Refund>>,
};

export type StatementOfAccountFilter = {
  invoiceStatuses?: Maybe<Array<InvoiceStatus>>,
  search?: Maybe<Scalars['String']>,
  statuses?: Maybe<Array<VoidableStatus>>,
};

export type StatementOfAccountNumber = {
   __typename?: 'StatementOfAccountNumber',
  balanceHistories?: Maybe<Scalars['Int']>,
  invoices?: Maybe<Scalars['Int']>,
  receipts?: Maybe<Scalars['Int']>,
  refunds?: Maybe<Scalars['Int']>,
};

export type StatementOfAccountPage = {
   __typename?: 'StatementOfAccountPage',
  items: Array<StatementOfAccount>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

export type Student = {
   __typename?: 'Student',
  _id: Scalars['ObjectId'],
  academicLevel?: Maybe<AcademicLevel>,
  academicMatters?: Maybe<Array<Parent>>,
  address?: Maybe<UserAddress>,
  alias?: Maybe<Scalars['String']>,
  alternateUserId?: Maybe<Scalars['String']>,
  alternativeEmails?: Maybe<Array<Scalars['String']>>,
  attendances?: Maybe<Array<Attendance>>,
  avatarUrl?: Maybe<Scalars['String']>,
  birthDate?: Maybe<Scalars['DateTime']>,
  consent?: Maybe<Consent>,
  courses?: Maybe<Array<Course>>,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  eligibleArrangements?: Maybe<Array<ArrangementType>>,
  email?: Maybe<Scalars['String']>,
  enrollmentMatters?: Maybe<Array<Parent>>,
  enrollments?: Maybe<Array<Enrollment>>,
  examScores?: Maybe<Array<ExamScore>>,
  flagRemark?: Maybe<Remark>,
  fullName: Scalars['String'],
  gender?: Maybe<Scalars['String']>,
  homePhone?: Maybe<Telephone>,
  isActive: Scalars['Boolean'],
  isArchived: Scalars['Boolean'],
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  mobile?: Maybe<Telephone>,
  parents?: Maybe<Array<Parent>>,
  pinnedRemark?: Maybe<Remark>,
  primaryParent?: Maybe<Parent>,
  primarySchool?: Maybe<School>,
  primaryStream?: Maybe<Scalars['String']>,
  /** Field that resolves to registrations linked to student */
  registrations?: Maybe<Array<Registration>>,
  relationName?: Maybe<Scalars['String']>,
  /** The remarks associated with this student are 'General Remarks' and 'Enrollment Suitability Remarks'. */
  remarks?: Maybe<Array<RemarkUnion>>,
  replacementCredit?: Maybe<StudentReplacementCreditDto>,
  rewardPoints?: Maybe<Array<RewardPoints>>,
  rewardPointsCounter?: Maybe<PointCounter>,
  secondarySchool?: Maybe<School>,
  secondaryStream?: Maybe<Scalars['String']>,
  siblings?: Maybe<Array<Student>>,
  status?: Maybe<VoidableStatus>,
  term?: Maybe<Term>,
  userId?: Maybe<Scalars['String']>,
  voidRemarks?: Maybe<Scalars['String']>,
};


export type StudentAttendancesArgs = {
  filterInput?: Maybe<StudentAttendanceFilterInput>
};


export type StudentEnrollmentsArgs = {
  filterInput?: Maybe<StudentEnrollmentFilterInput>
};


export type StudentRegistrationsArgs = {
  status?: Maybe<Array<RegistrationsStatus>>
};


export type StudentRelationNameArgs = {
  id?: Maybe<Scalars['ObjectId']>
};


export type StudentRemarksArgs = {
  lessonId?: Maybe<Scalars['ObjectId']>
};


export type StudentReplacementCreditArgs = {
  lessonId: Scalars['ObjectId']
};


export type StudentTermArgs = {
  year: Scalars['Int']
};

export type StudentAcademicLevelIndex = {
   __typename?: 'StudentAcademicLevelIndex',
  _id: Scalars['ObjectId'],
  name: Scalars['String'],
};

export type StudentAttendanceFilterInput = {
  attendanceTypes?: Maybe<Array<AttendanceType>>,
  classIds?: Maybe<Array<Scalars['ObjectId']>>,
  courseIds?: Maybe<Array<Scalars['ObjectId']>>,
  datetimeRange?: Maybe<DateTimeRangeInput>,
  ids?: Maybe<Array<Scalars['ObjectId']>>,
  /** Retrieve all the attendances matching the lesson IDs provided. */
  lessonIds?: Maybe<Array<Scalars['ObjectId']>>,
  name?: Maybe<Scalars['String']>,
  paymentStatuses?: Maybe<Array<InvoiceStatus>>,
  /** Retrieve all the attendances matching the status provided. */
  statuses?: Maybe<Array<AttendanceStatus>>,
  teacherIds?: Maybe<Array<Scalars['ObjectId']>>,
};

export type StudentAttendanceIndex = {
   __typename?: 'StudentAttendanceIndex',
  _id: Scalars['ObjectId'],
  startDateTime: Scalars['DateTime'],
  status?: Maybe<AttendanceStatus>,
  type: AttendanceType,
};

/** Paginated response for List Students with Award Points detail */
export type StudentAwardDetailsPage = {
   __typename?: 'StudentAwardDetailsPage',
  items: Array<StudentAwardPointsDetail>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

/** Object extending StudentWithSubmissionGrade with a gradable score and reward points applicable for awarding */
export type StudentAwardPointsDetail = {
   __typename?: 'StudentAwardPointsDetail',
  _id: Scalars['ObjectId'],
  academicLevel?: Maybe<AcademicLevel>,
  academicMatters?: Maybe<Array<Parent>>,
  address?: Maybe<UserAddress>,
  alias?: Maybe<Scalars['String']>,
  alternateUserId?: Maybe<Scalars['String']>,
  alternativeEmails?: Maybe<Array<Scalars['String']>>,
  /** Applicable points that can be applied to Student */
  applicablePoints: Scalars['Float'],
  attendances?: Maybe<Array<Attendance>>,
  avatarUrl?: Maybe<Scalars['String']>,
  /** Awarded points that is already applied to Student */
  awardedPoints: Scalars['Float'],
  birthDate?: Maybe<Scalars['DateTime']>,
  consent?: Maybe<Consent>,
  courses?: Maybe<Array<Course>>,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  eligibleArrangements?: Maybe<Array<ArrangementType>>,
  email?: Maybe<Scalars['String']>,
  enrollmentMatters?: Maybe<Array<Parent>>,
  enrollments?: Maybe<Array<Enrollment>>,
  examScores?: Maybe<Array<ExamScore>>,
  flagRemark?: Maybe<Remark>,
  fullName: Scalars['String'],
  gender?: Maybe<Scalars['String']>,
  homePhone?: Maybe<Telephone>,
  isActive: Scalars['Boolean'],
  isArchived: Scalars['Boolean'],
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  mobile?: Maybe<Telephone>,
  parents?: Maybe<Array<Parent>>,
  pinnedRemark?: Maybe<Remark>,
  primaryParent?: Maybe<Parent>,
  primarySchool?: Maybe<School>,
  primaryStream?: Maybe<Scalars['String']>,
  /** Field that resolves to registrations linked to student */
  registrations?: Maybe<Array<Registration>>,
  relationName?: Maybe<Scalars['String']>,
  /** The remarks associated with this student are 'General Remarks' and 'Enrollment Suitability Remarks'. */
  remarks?: Maybe<Array<RemarkUnion>>,
  replacementCredit?: Maybe<StudentReplacementCreditDto>,
  rewardPoints?: Maybe<Array<RewardPoints>>,
  rewardPointsCounter?: Maybe<PointCounter>,
  /** Score that is computed for awarding based on reward mechanics */
  score: Scalars['Float'],
  secondarySchool?: Maybe<School>,
  secondaryStream?: Maybe<Scalars['String']>,
  siblings?: Maybe<Array<Student>>,
  status?: Maybe<VoidableStatus>,
  /** Submission record attributed to student */
  submission?: Maybe<Submission>,
  term?: Maybe<Term>,
  /** Overall score for gradable */
  totalScore: Scalars['Float'],
  userId?: Maybe<Scalars['String']>,
  voidRemarks?: Maybe<Scalars['String']>,
};


/** Object extending StudentWithSubmissionGrade with a gradable score and reward points applicable for awarding */
export type StudentAwardPointsDetailAttendancesArgs = {
  filterInput?: Maybe<StudentAttendanceFilterInput>
};


/** Object extending StudentWithSubmissionGrade with a gradable score and reward points applicable for awarding */
export type StudentAwardPointsDetailEnrollmentsArgs = {
  filterInput?: Maybe<StudentEnrollmentFilterInput>
};


/** Object extending StudentWithSubmissionGrade with a gradable score and reward points applicable for awarding */
export type StudentAwardPointsDetailRegistrationsArgs = {
  status?: Maybe<Array<RegistrationsStatus>>
};


/** Object extending StudentWithSubmissionGrade with a gradable score and reward points applicable for awarding */
export type StudentAwardPointsDetailRelationNameArgs = {
  id?: Maybe<Scalars['ObjectId']>
};


/** Object extending StudentWithSubmissionGrade with a gradable score and reward points applicable for awarding */
export type StudentAwardPointsDetailRemarksArgs = {
  lessonId?: Maybe<Scalars['ObjectId']>
};


/** Object extending StudentWithSubmissionGrade with a gradable score and reward points applicable for awarding */
export type StudentAwardPointsDetailReplacementCreditArgs = {
  lessonId: Scalars['ObjectId']
};


/** Object extending StudentWithSubmissionGrade with a gradable score and reward points applicable for awarding */
export type StudentAwardPointsDetailTermArgs = {
  year: Scalars['Int']
};

/** Object extending Student with a submission record */
export type StudentBulkAwardsErrorResult = {
   __typename?: 'StudentBulkAwardsErrorResult',
  /** Error message on award operation */
  errorMessage: Scalars['String'],
  /** Object ID of Student */
  studentId: Scalars['ObjectId'],
};

/** Object extending Student with a submission record */
export type StudentBulkAwardsResult = {
   __typename?: 'StudentBulkAwardsResult',
  /** Errors in bulk awards operation */
  errors: Array<StudentBulkAwardsErrorResult>,
  /** Student */
  students: Array<Student>,
};

export type StudentEnrollmentFilterInput = {
  academicYearIds?: Maybe<Array<Scalars['ObjectId']>>,
  classIds?: Maybe<Array<Scalars['ObjectId']>>,
  classStatus?: Maybe<Array<ClassStatus>>,
  classTypes?: Maybe<Array<ClassType>>,
  courseIds?: Maybe<Array<Scalars['ObjectId']>>,
  dateTimeRange?: Maybe<DateTimeRangeInput>,
  enrollmentTypes?: Maybe<Array<EnrollmentType>>,
  isHolidayAffected?: Maybe<Scalars['Boolean']>,
  status?: Maybe<Array<EnrollmentStatus>>,
};

export type StudentEnrollmentIndex = {
   __typename?: 'StudentEnrollmentIndex',
  _id: Scalars['ObjectId'],
  endDate: Scalars['DateTime'],
  startDate: Scalars['DateTime'],
  status?: Maybe<EnrollmentStatus>,
  type: EnrollmentType,
};

export type StudentExamScoreIndex = {
   __typename?: 'StudentExamScoreIndex',
  _id: Scalars['ObjectId'],
  createDateTime: Scalars['DateTime'],
  marksPercentage: Scalars['Float'],
  subject: Scalars['String'],
  year: Scalars['Float'],
};

export type StudentFilterInput = {
  academicLevelIds?: Maybe<Array<Scalars['ObjectId']>>,
  academicYearIds?: Maybe<Array<Scalars['ObjectId']>>,
  attendanceStatuses?: Maybe<Array<AttendanceStatus>>,
  attendanceType?: Maybe<Array<AttendanceType>>,
  birthDateRange?: Maybe<DateTimeRangeInput>,
  classIds?: Maybe<Array<Scalars['ObjectId']>>,
  /** Optional column names that will be returned in Student data */
  columns?: Maybe<Array<Scalars['String']>>,
  courseIds?: Maybe<Array<Scalars['ObjectId']>>,
  /** Date range filter */
  dateRange?: Maybe<DateTimeRangeInput>,
  enrollmentStatuses?: Maybe<Array<EnrollmentStatus>>,
  /** Optional gradableId filter on student's rewarded points */
  gradableId?: Maybe<Scalars['ObjectId']>,
  lessonDays?: Maybe<Array<WeekDay>>,
  lessonIds?: Maybe<Array<Scalars['ObjectId']>>,
  mobile?: Maybe<TelephoneInput>,
  name?: Maybe<Scalars['String']>,
  parentId?: Maybe<Scalars['ObjectId']>,
  promotionId?: Maybe<Scalars['ObjectId']>,
  statuses?: Maybe<Array<VoidableStatus>>,
  studentIds?: Maybe<Array<Scalars['ObjectId']>>,
  studentNames?: Maybe<Array<Scalars['String']>>,
  teacherIds?: Maybe<Array<Scalars['ObjectId']>>,
  userIds?: Maybe<Array<Scalars['String']>>,
  venueIds?: Maybe<Array<Scalars['ObjectId']>>,
};

export type StudentIndex = {
   __typename?: 'StudentIndex',
  _id: Scalars['ObjectId'],
  academicLevel?: Maybe<StudentAcademicLevelIndex>,
  address?: Maybe<UserAddress>,
  alias?: Maybe<Scalars['String']>,
  alternateUserId?: Maybe<Scalars['String']>,
  alternativeEmails?: Maybe<Array<Scalars['String']>>,
  avatarUrl?: Maybe<Scalars['String']>,
  birthDate?: Maybe<Scalars['DateTime']>,
  consent?: Maybe<Consent>,
  courseIds?: Maybe<Array<Scalars['ObjectId']>>,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  email?: Maybe<Scalars['String']>,
  enrollments?: Maybe<Array<StudentEnrollmentIndex>>,
  examScores?: Maybe<Array<StudentExamScoreIndex>>,
  fullName: Scalars['String'],
  gender?: Maybe<Scalars['String']>,
  homePhone?: Maybe<Telephone>,
  isArchived: Scalars['Boolean'],
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  lessonDays?: Maybe<Array<WeekDay>>,
  mobile?: Maybe<Telephone>,
  primaryParent?: Maybe<StudentParentIndex>,
  primaryParentWhatsappAccount?: Maybe<StudentWhatsappAccountIndex>,
  primarySchool?: Maybe<StudentSchoolIndex>,
  primaryStream?: Maybe<Scalars['String']>,
  secondaryParentWhatsappAccounts?: Maybe<Array<StudentWhatsappAccountIndex>>,
  secondaryParents?: Maybe<Array<StudentParentIndex>>,
  secondarySchool?: Maybe<StudentSchoolIndex>,
  secondaryStream?: Maybe<Scalars['String']>,
  siblings?: Maybe<Array<StudentSiblingIndex>>,
  status?: Maybe<VoidableStatus>,
  teacherIds?: Maybe<Array<Scalars['ObjectId']>>,
  trialAttendances?: Maybe<Array<StudentAttendanceIndex>>,
  userId?: Maybe<Scalars['String']>,
  venueIds?: Maybe<Array<Scalars['ObjectId']>>,
  voidRemarks?: Maybe<Scalars['String']>,
};

export type StudentIndexPage = {
   __typename?: 'StudentIndexPage',
  items: Array<StudentIndex>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

export type StudentPage = {
   __typename?: 'StudentPage',
  items: Array<Student>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

export type StudentParentIndex = {
   __typename?: 'StudentParentIndex',
  _id: Scalars['ObjectId'],
  email?: Maybe<Scalars['String']>,
  fullName: Scalars['String'],
  mobile: Telephone,
  salutation?: Maybe<Scalars['String']>,
  userId?: Maybe<Scalars['String']>,
};

/** Object based on global remarks entity but extends with suitability information of student on an `Enrollment` record */
export type StudentRemarks = {
   __typename?: 'StudentRemarks',
  _id: Scalars['ObjectId'],
  attendance?: Maybe<Attendance>,
  /** A string that contains a remark in a formatted string that will be formatted further in the front-end. */
  content: Scalars['String'],
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  enrollmentSuitability?: Maybe<EnrollmentSuitability>,
  isArchived?: Maybe<Scalars['Boolean']>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  isFlagged?: Maybe<Scalars['Boolean']>,
  /** A boolean that signifies the pinned status of the remark. */
  isPinned?: Maybe<Scalars['Boolean']>,
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  /** Level type for Remark, used for parent remarks. */
  levelType?: Maybe<RemarkLevelType>,
  /** Optional class information tagged with the remark */
  linkedClass?: Maybe<Class>,
  /** Optional suitability information tagged with the remark */
  linkedSuitability?: Maybe<EnrollmentSuitability>,
  /** The ID of a remark this remark is created as a reply for. */
  parentId?: Maybe<Scalars['ObjectId']>,
  /** 
 * The `ObjectId` of the `Entity` to bind this remark to. For example, if this
   * remark is for an attendance, use the id of the attendance record.
 */
  subjectId: Scalars['ObjectId'],
  /** 
 * The type of the `Entity` defined from an enum to bind this remark to. For
   * example, if this remark is for an attendance, use the enum value
   * `RemarkSubject.Attendance`
 */
  type: RemarkType,
};


/** Object based on global remarks entity but extends with suitability information of student on an `Enrollment` record */
export type StudentRemarksAttendanceArgs = {
  attendanceId?: Maybe<Scalars['ObjectId']>
};

export type StudentReplacementCreditDetailDto = {
   __typename?: 'StudentReplacementCreditDetailDTO',
  exceptions?: Maybe<Scalars['Int']>,
  termNumber?: Maybe<Scalars['Int']>,
  total?: Maybe<Scalars['Int']>,
  used?: Maybe<Scalars['Int']>,
};

export type StudentReplacementCreditDto = {
   __typename?: 'StudentReplacementCreditDTO',
  academicYear?: Maybe<AcademicYear>,
  academicYearId?: Maybe<Scalars['ObjectId']>,
  course?: Maybe<Course>,
  courseId?: Maybe<Scalars['ObjectId']>,
  currentTerm?: Maybe<StudentReplacementCreditDetailDto>,
  term1?: Maybe<StudentReplacementCreditDetailDto>,
  term2?: Maybe<StudentReplacementCreditDetailDto>,
  term3?: Maybe<StudentReplacementCreditDetailDto>,
  term4?: Maybe<StudentReplacementCreditDetailDto>,
  workshop?: Maybe<StudentReplacementCreditDetailDto>,
};

export type StudentReplacementCreditsQuery = {
  academicYearId?: Maybe<Scalars['ObjectId']>,
  courseId?: Maybe<Scalars['ObjectId']>,
  studentId: Scalars['ObjectId'],
};

export type StudentReportFilter = {
   __typename?: 'StudentReportFilter',
  _id: Scalars['ObjectId'],
  academicLevelIds?: Maybe<Array<Scalars['ObjectId']>>,
  academicLevels?: Maybe<Array<AcademicLevel>>,
  academicYearIds?: Maybe<Array<Scalars['ObjectId']>>,
  academicYears?: Maybe<Array<AcademicYear>>,
  courseIds?: Maybe<Array<Scalars['ObjectId']>>,
  courses?: Maybe<Array<Course>>,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  dateRange: DateTimeRange,
  dateRangeType: StudentReportFilterDateRangeType,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  lessonDays?: Maybe<Array<WeekDay>>,
  name?: Maybe<Scalars['String']>,
  show?: Maybe<Array<StudentReportFilterShowGroup>>,
  teacherIds?: Maybe<Array<Scalars['ObjectId']>>,
  teachers?: Maybe<Array<SystemUser>>,
  user?: Maybe<SystemUser>,
  userId: Scalars['ObjectId'],
  venueIds?: Maybe<Array<Scalars['ObjectId']>>,
  venues?: Maybe<Array<Venue>>,
};

export enum StudentReportFilterDateRangeType {
  Day = 'Day',
  Range = 'Range',
  Week = 'Week'
}

export type StudentReportFilterInput = {
  academicLevelIds?: Maybe<Array<Scalars['ObjectId']>>,
  academicYearIds?: Maybe<Array<Scalars['ObjectId']>>,
  courseIds?: Maybe<Array<Scalars['ObjectId']>>,
  dateRange: DateTimeRangeInput,
  dateRangeType: StudentReportFilterDateRangeType,
  lessonDays?: Maybe<Array<WeekDay>>,
  name?: Maybe<Scalars['String']>,
  show?: Maybe<Array<StudentReportFilterShowGroup>>,
  teacherIds?: Maybe<Array<Scalars['ObjectId']>>,
  venueIds?: Maybe<Array<Scalars['ObjectId']>>,
};

export enum StudentReportFilterShowGroup {
  Current = 'Current',
  Future = 'Future',
  Past = 'Past'
}

export type StudentSchoolIndex = {
   __typename?: 'StudentSchoolIndex',
  _id: Scalars['ObjectId'],
  name: Scalars['String'],
};

export type StudentSiblingIndex = {
   __typename?: 'StudentSiblingIndex',
  _id: Scalars['ObjectId'],
  fullName: Scalars['String'],
};

export type StudentWhatsappAccountIndex = {
   __typename?: 'StudentWhatsappAccountIndex',
  _id: Scalars['ObjectId'],
  number?: Maybe<Telephone>,
  parentId: Scalars['ObjectId'],
};

export type Submission = {
   __typename?: 'Submission',
  _id: Scalars['ObjectId'],
  breakdown?: Maybe<MarkBreakDown>,
  class?: Maybe<Class>,
  classId: Scalars['ObjectId'],
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  enrollment?: Maybe<Enrollment>,
  enrollmentId: Scalars['ObjectId'],
  gradable?: Maybe<GradableUnion>,
  gradableId: Scalars['ObjectId'],
  grade?: Maybe<Scalars['Float']>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
};

export type SubmissionAuditLogFilterInput = {
  dateTimeRange?: Maybe<DateRangeInput>,
  descriptions?: Maybe<Array<AuditLogDescriptionType>>,
  fieldNames?: Maybe<Array<Scalars['String']>>,
  operationTypes?: Maybe<Array<AuditLogOperationType>>,
  principalId?: Maybe<Scalars['ObjectId']>,
  resourceTypes?: Maybe<Array<AuditLogResourceType>>,
  roleIds?: Maybe<Array<Scalars['ObjectId']>>,
};

export type SubmissionDateRange = {
  end?: Maybe<Scalars['DateTime']>,
  start?: Maybe<Scalars['DateTime']>,
};

export type SubmissionTracking = {
   __typename?: 'SubmissionTracking',
  content?: Maybe<Array<Content>>,
  enrollment?: Maybe<Enrollment>,
};

export type SubmissionTrackingFilter = {
  academicLevels?: Maybe<Array<Scalars['ObjectId']>>,
  /** Will be used to filter class that has this academicYearId */
  academicYearId?: Maybe<Scalars['ObjectId']>,
  activities?: Maybe<Activities>,
  /** Search by gradable's worksheet topic nand and worksheet title */
  activityName?: Maybe<Scalars['String']>,
  dateRange?: Maybe<SubmissionDateRange>,
  enrollmentType?: Maybe<Array<EnrollmentType>>,
  isGraded?: Maybe<Scalars['Boolean']>,
  lessonDays?: Maybe<Array<WeekDay>>,
  workSheetId?: Maybe<Scalars['ObjectId']>,
};

export type Subscription = {
   __typename?: 'Subscription',
  deleteDraftClass: Scalars['Boolean'],
  healthServiceChanged: Scalars['Boolean'],
  /** 
 * Gets the realtime envelope unit based on the envelopeId. It only emits based on the envelopeId argument.
   *     It is recommended to use the `packingListEnvelopeUnits` QUERY first then
   *     use this SUBSCRIPTION with `subscribeToMore` with Apollo client.
 */
  packingListEnvelopeUnitsUpdated: PackingListEnvelopeUnits,
  postBranchData: Branch,
  postDepartmentData: Department,
  postFeatureData: Array<FeatureAccess>,
  postImportClassHistory: BulkCreateHistory,
  postImportRegistrationHistory: BulkCreateHistory,
  postIncompleteArrangement: Arrangement,
  postIncompleteRegistration: Registration,
  postLessonFlowUpdate: LessonFlowUpdate,
  postReferenceData: ReferenceData,
  postVenueData: Venue,
  systemNotification: SystemNotification,
  whatsappAccountLoginFailed: WhatsappAccount,
  whatsappAccountStatusChanged: WhatsappAccount,
  whatsappMessageSendFailed: WhatsappMessage,
};


export type SubscriptionPackingListEnvelopeUnitsUpdatedArgs = {
  envelopeId: Scalars['ObjectId']
};


export type SubscriptionPostIncompleteArrangementArgs = {
  type: ArrangementType
};


export type SubscriptionPostLessonFlowUpdateArgs = {
  lessonFlowId: Scalars['ObjectId']
};

export type Suitability = {
   __typename?: 'Suitability',
  description?: Maybe<Scalars['String']>,
  isForTrial?: Maybe<Scalars['Boolean']>,
};

export type SuitabilityInput = {
  description?: Maybe<Scalars['String']>,
  isForTrial?: Maybe<Scalars['Boolean']>,
};

export type SystemNotification = {
   __typename?: 'SystemNotification',
  _id: Scalars['ObjectId'],
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  hyperLinkMessage?: Maybe<Scalars['String']>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  links?: Maybe<Array<NotificationHyperLink>>,
  message: Scalars['String'],
  read: Scalars['Boolean'],
  relatedUser?: Maybe<SystemUser>,
  relatedUserId: Scalars['ObjectId'],
  title?: Maybe<Scalars['String']>,
  type?: Maybe<SystemNotificationType>,
  whatsappMessage?: Maybe<WhatsappMessage>,
  whatsappMessageId?: Maybe<Scalars['ObjectId']>,
};

export type SystemNotificationFilterInput = {
  relatedUserId?: Maybe<Scalars['ObjectId']>,
};

export type SystemNotificationPage = {
   __typename?: 'SystemNotificationPage',
  items: Array<SystemNotification>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

export enum SystemNotificationType {
  AsyncRequestFailed = 'AsyncRequestFailed',
  ClassIsAvailableFromFull = 'ClassIsAvailableFromFull',
  ClassSyncGoogleCalendarFailed = 'ClassSyncGoogleCalendarFailed',
  ClassSyncGoogleCalendarSuccess = 'ClassSyncGoogleCalendarSuccess',
  ClassSyncZoomMeetingFailed = 'ClassSyncZoomMeetingFailed',
  ClassSyncZoomMeetingSuccess = 'ClassSyncZoomMeetingSuccess',
  EmailSendFailed = 'EmailSendFailed',
  GoogleMeetEventsConflict = 'GoogleMeetEventsConflict',
  MessagePreparedFailed = 'MessagePreparedFailed',
  NewUserRequest = 'NewUserRequest',
  TaskAssignment = 'TaskAssignment',
  WhatsappAbnormalOffline = 'WhatsappAbnormalOffline',
  WhatsappLoginFailed = 'WhatsappLoginFailed',
  WhatsappLogoutFailed = 'WhatsappLogoutFailed',
  WhatsappMessageSendFailed = 'WhatsappMessageSendFailed',
  WhatsappOffline = 'WhatsappOffline',
  WhatsappOnline = 'WhatsappOnline'
}

export type SystemUser = {
   __typename?: 'SystemUser',
  _id: Scalars['ObjectId'],
  alias?: Maybe<Scalars['String']>,
  alternateUserId?: Maybe<Scalars['String']>,
  avatarUrl?: Maybe<Scalars['String']>,
  branchIds?: Maybe<Array<Scalars['String']>>,
  branches?: Maybe<Array<Branch>>,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  department?: Maybe<Department>,
  departmentId?: Maybe<Scalars['String']>,
  email: Scalars['String'],
  fullName: Scalars['String'],
  gender?: Maybe<Scalars['String']>,
  initials?: Maybe<Scalars['String']>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  isTeacher?: Maybe<Scalars['Boolean']>,
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  linkedWhatsappNumber?: Maybe<Telephone>,
  mobile?: Maybe<Telephone>,
  officePhone?: Maybe<Telephone>,
  roleIds?: Maybe<Array<Scalars['String']>>,
  roleNames?: Maybe<Array<Scalars['String']>>,
  salutation?: Maybe<Scalars['String']>,
  sub?: Maybe<Scalars['String']>,
  userId?: Maybe<Scalars['String']>,
};

export type SystemUserPage = {
   __typename?: 'SystemUserPage',
  items: Array<SystemUser>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

export type SystemUserQuery = {
  departmentIds?: Maybe<Array<Scalars['ObjectId']>>,
  name?: Maybe<Scalars['String']>,
  roleIds?: Maybe<Array<Scalars['String']>>,
};

export type Task = {
   __typename?: 'Task',
  _id: Scalars['ObjectId'],
  /** Used for type - Operational, Storing admin ids. */
  assigneeIds?: Maybe<Array<Scalars['ObjectId']>>,
  assignees?: Maybe<Array<SystemUser>>,
  attendance?: Maybe<Attendance>,
  /** Used for type - Attendance */
  attendanceId?: Maybe<Scalars['ObjectId']>,
  balance?: Maybe<Balance>,
  /** Used for type - Fee balance */
  balanceId?: Maybe<Scalars['ObjectId']>,
  changeLog?: Maybe<ClassChangeLog>,
  /** Used for type - Reminder Change Class */
  changeLogId?: Maybe<Scalars['ObjectId']>,
  /** Used for type - Operational */
  checklists?: Maybe<Array<TaskChecklist>>,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Used for type - FollowUpTrial/ReminderLesson */
  creationContext?: Maybe<TaskCreationContext>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  /** Used for type - Operational */
  description?: Maybe<Scalars['String']>,
  dueDate?: Maybe<Scalars['DateTime']>,
  emailStatus?: Maybe<ReminderStatus>,
  enrollment?: Maybe<Enrollment>,
  /** Used for type - Trial and Reservation */
  enrollmentId?: Maybe<Scalars['ObjectId']>,
  flagRemark?: Maybe<Remark>,
  /** Used for type - Lesson Under Holiday, Storing holiday ids. */
  holidayIds?: Maybe<Array<Scalars['ObjectId']>>,
  holidays?: Maybe<Array<Holiday>>,
  invoice?: Maybe<Invoice>,
  /** Used for type - Invoice */
  invoiceId?: Maybe<Scalars['ObjectId']>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  /** Used for type - Operational */
  isTemplate?: Maybe<Scalars['Boolean']>,
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  lesson?: Maybe<Lesson>,
  /** Used for type - Additional Lesson Offer Reminder */
  lessonId?: Maybe<Scalars['ObjectId']>,
  /** Used for type - Operational */
  listId?: Maybe<Scalars['ObjectId']>,
  /** Used for type - Operational */
  name?: Maybe<Scalars['String']>,
  pinnedRemark?: Maybe<Remark>,
  registration?: Maybe<Registration>,
  /** Used for type - Reservation */
  registrationId?: Maybe<Scalars['ObjectId']>,
  remarks?: Maybe<Array<Remark>>,
  status: TaskStatus,
  type: TaskType,
  userRequest?: Maybe<UserRequestDto>,
  waStatus?: Maybe<ReminderStatus>,
};


export type TaskRemarksArgs = {
  showArchived?: Maybe<Scalars['Boolean']>
};

/** Task ActivityLog date value. */
export type TaskActivityLogDateValue = {
   __typename?: 'TaskActivityLogDateValue',
  previousValue?: Maybe<Scalars['DateTime']>,
  value?: Maybe<Scalars['DateTime']>,
};

export type TaskChecklist = {
   __typename?: 'TaskChecklist',
  _id: Scalars['ObjectId'],
  /** Storing admin ids. */
  assigneeIds?: Maybe<Array<Scalars['ObjectId']>>,
  assignees?: Maybe<Array<SystemUser>>,
  items?: Maybe<Array<TaskChecklistItem>>,
  name: Scalars['String'],
};

export type TaskChecklistItem = {
   __typename?: 'TaskChecklistItem',
  _id: Scalars['ObjectId'],
  /** Storing admin ids. */
  assigneeIds?: Maybe<Array<Scalars['ObjectId']>>,
  assignees?: Maybe<Array<SystemUser>>,
  isDone: Scalars['Boolean'],
  name: Scalars['String'],
};

export type TaskClassIndex = {
   __typename?: 'TaskClassIndex',
  _id: Scalars['ObjectId'],
  shorthand: Scalars['String'],
};

export type TaskCount = {
   __typename?: 'TaskCount',
  overdue: Scalars['Int'],
  pending: Scalars['Int'],
  type: TaskType,
};

export type TaskCreationContext = {
   __typename?: 'TaskCreationContext',
  /** Is Transfer Lesson reminder. */
  isTransferLessonReminder?: Maybe<Scalars['Boolean']>,
  /** TaskOriginRecordType */
  type?: Maybe<TaskOriginRecordType>,
};

export type TaskFilterInput = {
  dueDateRange?: Maybe<DateTimeRangeInput>,
  isTemplate?: Maybe<Scalars['Boolean']>,
  isTransferLessonReminder?: Maybe<Scalars['Boolean']>,
  isWithoutWAline?: Maybe<Scalars['Boolean']>,
  lastModifiedDateRange?: Maybe<DateTimeRangeInput>,
  listIds?: Maybe<Array<Scalars['ObjectId']>>,
  name?: Maybe<Scalars['String']>,
  originRecordTypes?: Maybe<Array<TaskOriginRecordType>>,
  statuses?: Maybe<Array<TaskStatus>>,
  taskIds?: Maybe<Array<Scalars['ObjectId']>>,
  types?: Maybe<Array<TaskType>>,
  whatsappAccountIds?: Maybe<Array<Scalars['ObjectId']>>,
};

export type TaskIndex = {
   __typename?: 'TaskIndex',
  /** Unique identifier for the record */
  _id: Scalars['ObjectId'],
  /** Used for type - Operational, Storing admin ids. */
  assigneeIds?: Maybe<Array<Scalars['ObjectId']>>,
  attendance?: Maybe<Attendance>,
  /** Used for type - Attendance */
  attendanceId?: Maybe<Scalars['ObjectId']>,
  balance?: Maybe<Balance>,
  /** Used for type - Fee balance */
  balanceId?: Maybe<Scalars['ObjectId']>,
  changeLog?: Maybe<ClassChangeLog>,
  /** Used for type - Reminder Change Class */
  changeLogId?: Maybe<Scalars['ObjectId']>,
  class?: Maybe<TaskClassIndex>,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Used for type - FollowUpTrial/ReminderLesson */
  creationContext?: Maybe<TaskCreationContext>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  /** Used for type - Operational */
  description?: Maybe<Scalars['String']>,
  dueDate?: Maybe<Scalars['DateTime']>,
  emailStatus?: Maybe<ReminderStatus>,
  enrollment?: Maybe<Enrollment>,
  /** Used for type - Trial and Reservation */
  enrollmentId?: Maybe<Scalars['ObjectId']>,
  flagRemark?: Maybe<Remark>,
  /** Used for type - Lesson Under Holiday, Storing holiday ids. */
  holidayIds?: Maybe<Array<Scalars['ObjectId']>>,
  holidays?: Maybe<Array<Holiday>>,
  invoice?: Maybe<Invoice>,
  /** Used for type - Invoice */
  invoiceId?: Maybe<Scalars['ObjectId']>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  /** Used for type - Operational */
  isTemplate?: Maybe<Scalars['Boolean']>,
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  lesson?: Maybe<Lesson>,
  /** Used for type - Additional Lesson Offer Reminder */
  lessonId?: Maybe<Scalars['ObjectId']>,
  /** Used for type - Operational */
  listId?: Maybe<Scalars['ObjectId']>,
  /** Used for type - Operational */
  name?: Maybe<Scalars['String']>,
  parent?: Maybe<TaskParentIndex>,
  parentWhatsappAccount?: Maybe<TaskWhatsappAccountIndex>,
  pinnedRemark?: Maybe<Remark>,
  registration?: Maybe<Registration>,
  /** Used for type - Reservation */
  registrationId?: Maybe<Scalars['ObjectId']>,
  remarks?: Maybe<Array<Remark>>,
  status: TaskStatus,
  statusWithSort: Scalars['String'],
  student?: Maybe<TaskStudentIndex>,
  type: TaskType,
  userRequest?: Maybe<UserRequestDto>,
  /** Used for type - Request */
  userRequestId?: Maybe<Scalars['ObjectId']>,
  waStatus?: Maybe<ReminderStatus>,
};


export type TaskIndexRemarksArgs = {
  showArchived?: Maybe<Scalars['Boolean']>
};

export type TaskIndexPage = {
   __typename?: 'TaskIndexPage',
  items: Array<TaskIndex>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

export type TaskList = {
   __typename?: 'TaskList',
  _id: Scalars['ObjectId'],
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  endAt?: Maybe<Scalars['DateTime']>,
  generateAt?: Maybe<Scalars['DateTime']>,
  isAutoGenerate: Scalars['Boolean'],
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  isPinned?: Maybe<Scalars['Boolean']>,
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  name: Scalars['String'],
  parent?: Maybe<TaskList>,
  parentId?: Maybe<Scalars['ObjectId']>,
  startAt?: Maybe<Scalars['DateTime']>,
  weekDay?: Maybe<WeekDay>,
};

export type TaskListFilterInput = {
  weekDays?: Maybe<Array<WeekDay>>,
};

export type TaskListPage = {
   __typename?: 'TaskListPage',
  items: Array<TaskList>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

export enum TaskOriginRecordType {
  FirstTrialFollowUpTask = 'FirstTrialFollowUpTask',
  GapLessonReminder = 'GapLessonReminder',
  SecondaryTrialFollowUpTask = 'SecondaryTrialFollowUpTask',
  TermBillingDue = 'TermBillingDue',
  TermBillingOverDue = 'TermBillingOverDue',
  TermBillingPending = 'TermBillingPending',
  WorkshopDayContinuouslyLessonReminder = 'WorkshopDayContinuouslyLessonReminder',
  WorkshopDayNonContinuouslyLessonReminder = 'WorkshopDayNonContinuouslyLessonReminder',
  WorkshopWeekLessonReminder = 'WorkshopWeekLessonReminder'
}

export type TaskPage = {
   __typename?: 'TaskPage',
  items: Array<Task>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

export type TaskParentIndex = {
   __typename?: 'TaskParentIndex',
  _id: Scalars['ObjectId'],
  avatarUrl?: Maybe<Scalars['String']>,
  fullName: Scalars['String'],
  gender?: Maybe<Scalars['String']>,
  mobile: Telephone,
  salutation?: Maybe<Scalars['String']>,
};

export enum TaskStatus {
  Complete = 'Complete',
  Overdue = 'Overdue',
  Pending = 'Pending',
  Void = 'Void'
}

export type TaskStudentIndex = {
   __typename?: 'TaskStudentIndex',
  _id: Scalars['ObjectId'],
  alias: Scalars['String'],
  avatarUrl?: Maybe<Scalars['String']>,
  fullName: Scalars['String'],
  gender?: Maybe<Scalars['String']>,
  userId?: Maybe<Scalars['String']>,
};

export enum TaskType {
  FollowUpAbsent = 'FollowUpAbsent',
  FollowUpFeeBalance = 'FollowUpFeeBalance',
  FollowUpPayment = 'FollowUpPayment',
  FollowUpReplacementLessonOffer = 'FollowUpReplacementLessonOffer',
  FollowUpReservation = 'FollowUpReservation',
  FollowUpTermBilling = 'FollowUpTermBilling',
  FollowUpTrial = 'FollowUpTrial',
  FollowUpTrialRequest = 'FollowUpTrialRequest',
  Operational = 'Operational',
  ReminderAdditionalLesson = 'ReminderAdditionalLesson',
  ReminderAdditionalLessonOffer = 'ReminderAdditionalLessonOffer',
  ReminderChangeClass = 'ReminderChangeClass',
  ReminderLesson = 'ReminderLesson',
  ReminderLessonUnderHoliday = 'ReminderLessonUnderHoliday',
  ReminderTrialLesson = 'ReminderTrialLesson',
  ReminderWorkshopReplacementLesson = 'ReminderWorkshopReplacementLesson'
}

export type TaskWhatsappAccountIndex = {
   __typename?: 'TaskWhatsappAccountIndex',
  _id: Scalars['ObjectId'],
  number?: Maybe<Telephone>,
  parentId: Scalars['ObjectId'],
};

export type TeacherAndVenueValidateResult = {
   __typename?: 'TeacherAndVenueValidateResult',
  teacher: Scalars['String'],
  venue: Scalars['String'],
};

export type Telephone = {
   __typename?: 'Telephone',
  countryCode: Scalars['String'],
  number: Scalars['String'],
};

export type TelephoneInput = {
  countryCode: Scalars['String'],
  number: Scalars['String'],
};

export type TelephoneOutput = {
   __typename?: 'TelephoneOutput',
  countryCode: Scalars['String'],
  number: Scalars['String'],
};

export type Template = {
   __typename?: 'Template',
  _id: Scalars['ObjectId'],
  content?: Maybe<Scalars['String']>,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  emailMessage?: Maybe<Scalars['String']>,
  isArchived: Scalars['Boolean'],
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  sendFrequency?: Maybe<Scalars['Float']>,
  status: TemplateStatus,
  subject?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  type: TemplateType,
  usageType?: Maybe<TemplateUsageType>,
  whatsAppMessage?: Maybe<Scalars['String']>,
};

export type TemplateFilterInput = {
  isArchived?: Maybe<Scalars['Boolean']>,
  name?: Maybe<Scalars['String']>,
  status?: Maybe<TemplateStatus>,
  type?: Maybe<TemplateType>,
  usageTypes?: Maybe<Array<TemplateUsageType>>,
};

export type TemplatePage = {
   __typename?: 'TemplatePage',
  items: Array<Template>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

export enum TemplateStatus {
  Complete = 'Complete',
  Draft = 'Draft'
}

export enum TemplateType {
  Confirmation = 'Confirmation',
  CopyList = 'CopyList',
  FollowUp = 'FollowUp',
  General = 'General',
  Reminder = 'Reminder',
  System = 'System'
}

export enum TemplateUsageType {
  ConfirmationAdditionalLesson = 'ConfirmationAdditionalLesson',
  ConfirmationAddressVerification = 'ConfirmationAddressVerification',
  ConfirmationInvoice = 'ConfirmationInvoice',
  ConfirmationMaterialsNote = 'ConfirmationMaterialsNote',
  ConfirmationReceipt = 'ConfirmationReceipt',
  ConfirmationRefund = 'ConfirmationRefund',
  ConfirmationRegistration = 'ConfirmationRegistration',
  ConfirmationReplacementLesson = 'ConfirmationReplacementLesson',
  ConfirmationReservation = 'ConfirmationReservation',
  ConfirmationTransfer = 'ConfirmationTransfer',
  ConfirmationTrial = 'ConfirmationTrial',
  ConfirmationWaitingList = 'ConfirmationWaitingList',
  ConfirmationWithdrawal = 'ConfirmationWithdrawal',
  CopyListAdditionalLesson = 'CopyListAdditionalLesson',
  CopyListClass = 'CopyListClass',
  CopyListGml = 'CopyListGML',
  CopyListReplacement = 'CopyListReplacement',
  CopyListSms = 'CopyListSMS',
  FollowUpAbsent = 'FollowUpAbsent',
  FollowUpPayment = 'FollowUpPayment',
  FollowUpPaymentAdvice = 'FollowUpPaymentAdvice',
  FollowUpReplacementLessonOffer = 'FollowUpReplacementLessonOffer',
  FollowUpReservation = 'FollowUpReservation',
  FollowUpTermBilling = 'FollowUpTermBilling',
  FollowUpTrial = 'FollowUpTrial',
  FollowUpTrialRequest = 'FollowUpTrialRequest',
  FollowUpWaitingList = 'FollowUpWaitingList',
  General = 'General',
  ReminderAdditionalLesson = 'ReminderAdditionalLesson',
  ReminderAdditionalLessonOffer = 'ReminderAdditionalLessonOffer',
  ReminderAddressVerification = 'ReminderAddressVerification',
  ReminderLesson = 'ReminderLesson',
  ReminderLessonUnderHoliday = 'ReminderLessonUnderHoliday',
  ReminderTeacherOrVenueUpdate = 'ReminderTeacherOrVenueUpdate',
  ReminderTrialLesson = 'ReminderTrialLesson',
  ReminderWorkshopReplacementLesson = 'ReminderWorkshopReplacementLesson',
  SystemDisclaimer = 'SystemDisclaimer',
  SystemDropOffRemarks = 'SystemDropOffRemarks',
  SystemPickupRemarks = 'SystemPickupRemarks',
  SystemTermsConditions = 'SystemTermsConditions'
}

export type Term = {
   __typename?: 'Term',
  _id: Scalars['ObjectId'],
  academicLevels?: Maybe<Array<AcademicLevel>>,
  academicYear: AcademicYear,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  term1Period: DateTimeRange,
  term2Period: DateTimeRange,
  term3Period: DateTimeRange,
  term4Period: DateTimeRange,
  year: Scalars['Int'],
};

/** A single instance of Term Billing Due Date within an academic year. */
export type TermBillingDueDate = {
   __typename?: 'TermBillingDueDate',
  term1?: Maybe<Scalars['DateTime']>,
  term2?: Maybe<Scalars['DateTime']>,
  term3?: Maybe<Scalars['DateTime']>,
  term4?: Maybe<Scalars['DateTime']>,
};

export type TermBillingDueDateInput = {
  term1?: Maybe<Scalars['DateTime']>,
  term2?: Maybe<Scalars['DateTime']>,
  term3?: Maybe<Scalars['DateTime']>,
  term4?: Maybe<Scalars['DateTime']>,
};

export type TermInput = {
  academicLevelIds: Array<Scalars['ObjectId']>,
  id?: Maybe<Scalars['ObjectId']>,
  term1Period: DateTimeRangeInput,
  term2Period: DateTimeRangeInput,
  term3Period: DateTimeRangeInput,
  term4Period: DateTimeRangeInput,
};

/** The test submission of each student. */
export type TestSubmission = {
   __typename?: 'TestSubmission',
  /** Unique identifier of the record. */
  _id: Scalars['ObjectId'],
  /** 
 * Indicates whether the test will be marked digitally or 
   *     the test has already been marked physically by the teacher.
   *     When not provided, it should default to `Digital`.
 */
  annotationType?: Maybe<TestSubmissionAnnotationType>,
  /** Date time when the test submission was archived. */
  archivedDate?: Maybe<Scalars['DateTime']>,
  /** Class of the student. */
  class: TestSubmissionClass,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  /** Student who took the exam. */
  examinee: TestSubmissionStudent,
  /** The person who exported the test. */
  exportedBy: TestSubmissionOperator,
  /** Date time when the test submission was exported. */
  exportedDate: Scalars['DateTime'],
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  /** Lesson associated with the test that was submitted. */
  lesson: TestSubmissionLesson,
  /** 
 * The marked/final version of the test file of the examinee that was submitted/uploaded.
   *     This is required when the test is marked.
 */
  markedResource?: Maybe<TestSubmissionResource>,
  /** Indicates the current state of the test submission. */
  status: TestSubmissionStatus,
  /** Date time when the test was submitted. */
  submissionDate: Scalars['DateTime'],
  /** The person who submitted/uploaded the test file. */
  submittedBy: TestSubmissionOperator,
  /** 
 * The original test file of the examinee that was submitted/uploaded.
   *     This is only available for digital test submissions.
 */
  unmarkedResource?: Maybe<TestSubmissionResource>,
  /** The submitted worksheet used as the test. */
  worksheet: TestSubmissionWorksheet,
};

/** Academic week that the lesson is to be used. */
export type TestSubmissionAcademicWeek = {
   __typename?: 'TestSubmissionAcademicWeek',
  /** Reference to the academic week of an academic year. */
  _id: Scalars['ObjectId'],
  /** The date of the last day of the week. */
  endDate: Scalars['DateTime'],
  /** The date of the first day of the week. */
  startDate: Scalars['DateTime'],
  /** The week number in an academic year. */
  weekNumber: Scalars['Int'],
};

export enum TestSubmissionAnnotationType {
  Digital = 'Digital',
  Physical = 'Physical'
}

/** Class where the student is assigned to. */
export type TestSubmissionClass = {
   __typename?: 'TestSubmissionClass',
  /** Reference to the class entity. */
  _id: Scalars['ObjectId'],
  /** Name of the class copied from the class entity. */
  name: Scalars['String'],
  /** Short hand of the class name copied from the class entity. */
  shortName: Scalars['String'],
};

/** Input for `testSubmissionCreate` mutation. */
export type TestSubmissionCreateInput = {
  /** 
 * Indicates whether the test will be marked digitally or 
   *     the test has already been marked physically by the teacher.
   *     When not provided, it should default to `Digital`.
 */
  annotationType: TestSubmissionAnnotationType,
  /** The student who took the exam. */
  examineeId: Scalars['ObjectId'],
  /** The lesson that the test was taken on. */
  lessonId: Scalars['ObjectId'],
  /** 
 * The list of scores (written physically) that the teacher placed on the test.
   * This is required for `annotationType`="Physical" test submission.
 */
  scores?: Maybe<Array<TestSubmissionScoreInput>>,
  /** Url to the test file that was submitted. */
  testFileUrl: Scalars['String'],
  /** Url to the thumbnail of the test file that was submitted. */
  testThumbnailFileUrl: Scalars['String'],
  /** The worksheet that was used as a test. */
  worksheetId: Scalars['ObjectId'],
};

/** Input for getting a list of TestSubmissions */
export type TestSubmissionFilterInput = {
  /** Filter the TestSubmission with matching annotationTypes. */
  annotationTypes?: Maybe<Array<TestSubmissionAnnotationType>>,
  /** Filter the TestSubmission with matching classIds. */
  classIds?: Maybe<Array<Scalars['ObjectId']>>,
  /** Filter the TestSubmissions from end date. */
  endDate?: Maybe<Scalars['DateTime']>,
  /** Filter the TestSubmission with matching examineeIds */
  examineeIds?: Maybe<Array<Scalars['ObjectId']>>,
  /** Filter the TestSubmission with matching lessonIds. */
  lessonIds?: Maybe<Array<Scalars['ObjectId']>>,
  /** Filter the TestSubmissions from start date. */
  startDate?: Maybe<Scalars['DateTime']>,
  /** Filter the TestSubmission with matching statuses. */
  statuses?: Maybe<Array<TestSubmissionStatus>>,
};

/** Lesson associated with the test that was submitted. */
export type TestSubmissionLesson = {
   __typename?: 'TestSubmissionLesson',
  /** Reference to the lesson entity. */
  _id: Scalars['ObjectId'],
  /** Academic week that the lesson is to be used on. */
  academicWeek: TestSubmissionAcademicWeek,
  /** Lesson number of the class. */
  lessonNo: Scalars['Int'],
};

/** Input for `testSubmissionMakeDraft` mutation. */
export type TestSubmissionMakeDraftInput = {
  /** The test submission to make into `Draft` */
  testSubmissionId: Scalars['ObjectId'],
};

/** The person who performed the operation. */
export type TestSubmissionOperator = {
   __typename?: 'TestSubmissionOperator',
  /** Reference to the user entity. */
  _id: Scalars['ObjectId'],
  /** The email of the user who submitted the test. */
  email: Scalars['String'],
  /** The token sub (subject) of the user who submitted the test. */
  sub: Scalars['String'],
};

/** Contains the resources submitted for the test. */
export type TestSubmissionResource = {
   __typename?: 'TestSubmissionResource',
  /** The url to the test file that was submitted. */
  testFileUrl: Scalars['String'],
  /** The Url to the thumbnail of the test file that was submitted. */
  testThumbnailFileUrl: Scalars['String'],
};

/** Input for `testSubmissionSaveMarkedResource` mutation. */
export type TestSubmissionSaveMarkedResourceInput = {
  /** The annotated test file. */
  testFileUrl: Scalars['String'],
  /** The entity to perform the operation on. */
  testSubmissionId: Scalars['ObjectId'],
  /** The thumbnail of the annotated test file. */
  testThumbnailFileUrl: Scalars['String'],
};

/** Input for `testSubmissionSaveScores` mutation. */
export type TestSubmissionSaveScoresInput = {
  /** 
 * The list of scores (written physically) that the teacher placed on the test.
   * This is required for `annotationType`="Physical" test submission.
 */
  scores: Array<TestSubmissionScoreInput>,
  /** The entity to perform the operation on. */
  testSubmissionId: Scalars['ObjectId'],
};

/** 
 * Contains scores of a physical/marked test. 
 *   This is required for `annotationType`="Physical" test submission.
 */
export type TestSubmissionScoreInput = {
  /** The section name to provide the score. */
  name: Scalars['String'],
  /** The score to provide the test section. */
  score: Scalars['Float'],
};

/** A paginated response containing the packing lists being retrieved. */
export type TestSubmissionsPage = {
   __typename?: 'TestSubmissionsPage',
  items: Array<TestSubmission>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

export enum TestSubmissionStatus {
  Draft = 'Draft',
  ForReview = 'ForReview',
  Marked = 'Marked',
  Returned = 'Returned',
  Reviewed = 'Reviewed',
  Unmarked = 'Unmarked'
}

/** Student who took the exam. */
export type TestSubmissionStudent = {
   __typename?: 'TestSubmissionStudent',
  /** Reference to the student entity. */
  _id: Scalars['ObjectId'],
  /** The email of the student who took the test (examinee). */
  email: Scalars['String'],
  /** The full name of the student who took the test (examinee). */
  fullName: Scalars['String'],
};

/** The teacher who scored the exam. */
export type TestSubmissionTeacher = {
   __typename?: 'TestSubmissionTeacher',
  /** Reference to the teacher entity. */
  _id: Scalars['ObjectId'],
  /** The email of the teacher. */
  email: Scalars['String'],
  /** The full name of the teacher. */
  fullName: Scalars['String'],
};

/** Contains the test information. */
export type TestSubmissionTest = {
   __typename?: 'TestSubmissionTest',
  /** Unique identifier for the test. */
  _id: Scalars['ObjectId'],
  /** The total number of points that can be acquired in the test. */
  maxTotalScore?: Maybe<Scalars['Int']>,
  /** The name of the test. */
  name: Scalars['String'],
  /** The teacher who scored the exam. */
  scoredBy?: Maybe<TestSubmissionTeacher>,
  /** Date time when the test was scored. */
  scoredDate?: Maybe<Scalars['DateTime']>,
  /** The different section groups in the test. */
  sections: Array<TestSubmissionTestSection>,
  /** The total score of the examinee, which is the sum of all the section scores in the test. */
  totalScore: Scalars['Int'],
};

/** The different section groups in the test. */
export type TestSubmissionTestSection = {
   __typename?: 'TestSubmissionTestSection',
  /** The total number of points that can be acquired in the section. */
  maxScore: Scalars['Int'],
  /** The name of each section in the test. */
  name: Scalars['String'],
  /** The score of the examinee in the section. */
  score: Scalars['Int'],
};

/** Input for `testSubmissionUpdate` mutation. */
export type TestSubmissionUpdateInput = {
  /** The new status to set. */
  status: TestSubmissionStatus,
  /** The test submission to update. */
  testSubmissionId: Scalars['ObjectId'],
};

/** The submitted worksheet used as the test. */
export type TestSubmissionWorksheet = {
   __typename?: 'TestSubmissionWorksheet',
  /** Reference to the worksheet entity. */
  _id: Scalars['ObjectId'],
  /** Contains the test information: name, scores, scoredBy. */
  test: TestSubmissionTest,
  /** Title of the worksheet copied from the worksheet entity. */
  title: Scalars['String'],
  /** The topic assigned to the worksheet. */
  topic: TestSubmissionWorksheetTopic,
};

/** The topic assigned to the worksheet. */
export type TestSubmissionWorksheetTopic = {
   __typename?: 'TestSubmissionWorksheetTopic',
  /** Reference to the topic entity. */
  _id: Scalars['ObjectId'],
  /** Name of the topic copied from the topic entity. */
  name: Scalars['String'],
};


export type TimeRange = {
   __typename?: 'TimeRange',
  end: Scalars['Time'],
  start: Scalars['Time'],
};

export type TimeRangeInput = {
  end: Scalars['Time'],
  start: Scalars['Time'],
};

export type TimeRangeOutput = {
   __typename?: 'TimeRangeOutput',
  end: Scalars['Time'],
  start: Scalars['Time'],
};

export type Topic = {
   __typename?: 'Topic',
  _id: Scalars['ObjectId'],
  /** List of associated courses */
  courseIds: Array<Scalars['ObjectId']>,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  name: Scalars['String'],
  /** The worksheets assigned under the current topic. */
  worksheets?: Maybe<Array<WorkSheet>>,
};

/** Input for `topicAddCourse` mutation. */
export type TopicAddCourseInput = {
  /** The course to associate to the topic. */
  courseId: Scalars['ObjectId'],
  /** The topic to add the course. */
  topicId: Scalars['ObjectId'],
};

/** Input for `topicRemoveCourse` mutation. */
export type TopicRemoveCourseInput = {
  /** The course to dissociate from the topic. */
  courseId: Scalars['ObjectId'],
  /** The topic to remove the course. */
  topicId: Scalars['ObjectId'],
};

export type TopicsInput = {
  /** Course Ids that the topic should belong to. Leave null to get all. */
  courseIds?: Maybe<Array<Scalars['ObjectId']>>,
};

export type TransferCancelInfo = {
   __typename?: 'TransferCancelInfo',
  firstFullLesson?: Maybe<Lesson>,
  isFullFromClass: Scalars['Boolean'],
  isFullFromLesson: Scalars['Boolean'],
};

/** Input for unarchiving the worksheet. */
export type UnarchiveWorkSheetsInput = {
  /** The ids of the worksheets to be unarchived. */
  worksheetIds: Array<Scalars['ObjectId']>,
};

export type UpcomingLessonInput = {
  classId?: Maybe<Scalars['ObjectId']>,
  date?: Maybe<Scalars['DateTime']>,
  teacherId?: Maybe<Scalars['ObjectId']>,
};

export type UpdateAcademicLevelInput = {
  alias?: Maybe<Scalars['String']>,
  educationStage?: Maybe<EducationStage>,
  id: Scalars['ObjectId'],
  isArchived?: Maybe<Scalars['Boolean']>,
  isVisible?: Maybe<Scalars['Boolean']>,
  name?: Maybe<Scalars['String']>,
};

export type UpdateAcademicYearInput = {
  academicWeekEnd: WeekDay,
  academicWeekStart: WeekDay,
  id: Scalars['ObjectId'],
  period: DateTimeRangeInput,
  taxRate: Scalars['Int'],
  termBillingDueDate?: Maybe<TermBillingDueDateInput>,
  terms: Array<TermInput>,
};

export type UpdateAttendanceLessonModeInput = {
  attendanceId: Scalars['ObjectId'],
  lessonMode?: Maybe<LearningArrangementType>,
};

/** Input for updating one or more attendance records. */
export type UpdateAttendancesInput = {
  /** Attendance IDs. */
  ids: Array<Scalars['ObjectId']>,
  /** The status to set the attendance to. */
  status: AttendanceStatus,
};

export type UpdateBranchInput = {
  address?: Maybe<AddressInput>,
  alias?: Maybe<Scalars['String']>,
  id: Scalars['ObjectId'],
  isArchived?: Maybe<Scalars['Boolean']>,
  name?: Maybe<Scalars['String']>,
};

export type UpdateCampaignInput = {
  emailSubject?: Maybe<Scalars['String']>,
  id: Scalars['ObjectId'],
  isIncludeAttachment?: Maybe<Scalars['Boolean']>,
  message?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  recipientTypes?: Maybe<Array<CampaignRecipientType>>,
  recipients?: Maybe<Array<CampaignRecipientInput>>,
  scheduleSendAt?: Maybe<Scalars['DateTime']>,
  sendMethod?: Maybe<ReminderType>,
  status?: Maybe<CampaignStatus>,
  templateId?: Maybe<Scalars['ObjectId']>,
};

export type UpdateClassCommand = {
  academicWeekNumber?: Maybe<Scalars['Int']>,
  classCode?: Maybe<Scalars['String']>,
  classFee?: Maybe<StandardCourseFeeInput>,
  defaultLessonDays?: Maybe<Array<WeekDay>>,
  defaultLessonEnd?: Maybe<Scalars['DateTime']>,
  defaultLessonStart?: Maybe<Scalars['DateTime']>,
  digitalClassAccessCredentials?: Maybe<DigitalClassAccessCredentialsCommand>,
  id: Scalars['ObjectId'],
  learningArrangement?: Maybe<LearningArrangementType>,
  /** ID of the Lesson Flow to be assigned to the class. */
  lessonFlowId?: Maybe<Scalars['ObjectId']>,
  maximumClassCapacity?: Maybe<Scalars['Int']>,
  maximumMakeUpCapacity?: Maybe<Scalars['Int']>,
  name?: Maybe<Scalars['String']>,
  runNumber?: Maybe<Scalars['Int']>,
  shorthand?: Maybe<Scalars['String']>,
  startUpdateLessonId?: Maybe<Scalars['ObjectId']>,
  teacherId?: Maybe<Scalars['ObjectId']>,
  venueId?: Maybe<Scalars['ObjectId']>,
};

export type UpdateClassDigitalCredentialCommand = {
  digitalClassAccessCredentials: DigitalClassAccessCredentialsCommand,
  id: Scalars['ObjectId'],
};

export type UpdateClassFeeCommand = {
  classFee: StandardCourseFeeInput,
  id: Scalars['ObjectId'],
};

export type UpdateClassLearningArrangementCommand = {
  id: Scalars['ObjectId'],
  learningArrangement: LearningArrangementType,
};

export type UpdateClassNameCommand = {
  classCode?: Maybe<Scalars['String']>,
  id: Scalars['ObjectId'],
  name?: Maybe<Scalars['String']>,
  runNumber?: Maybe<Scalars['Int']>,
  shorthand?: Maybe<Scalars['String']>,
};

export type UpdateClassScheduleCommand = {
  academicWeekNumber?: Maybe<Scalars['Int']>,
  defaultLessonDays?: Maybe<Array<WeekDay>>,
  defaultLessonEnd?: Maybe<Scalars['DateTime']>,
  defaultLessonStart?: Maybe<Scalars['DateTime']>,
  id: Scalars['ObjectId'],
  startUpdateLessonId?: Maybe<Scalars['ObjectId']>,
};

export type UpdateClassTeacherCommand = {
  id: Scalars['ObjectId'],
  startUpdateLessonId: Scalars['ObjectId'],
  teacherId?: Maybe<Scalars['ObjectId']>,
};

export type UpdateClassVenueCommand = {
  id: Scalars['ObjectId'],
  maximumClassCapacity?: Maybe<Scalars['Int']>,
  maximumMakeUpCapacity?: Maybe<Scalars['Int']>,
  startUpdateLessonId?: Maybe<Scalars['ObjectId']>,
  venueId?: Maybe<Scalars['ObjectId']>,
};

/** Input used to Update collect details of a LessonFlowItem. */
export type UpdateCollectInput = {
  /** The ID of the collect to update. */
  collectId: Scalars['ObjectId'],
  /** The text of the collect generated from a rich text editor. */
  description: Scalars['String'],
  /** The ID of the LessonFlowItem containing the collect to find. */
  lessonFlowItemId: Scalars['ObjectId'],
};

export type UpdateCourierPickupAddressInput = {
  address?: Maybe<Scalars['String']>,
  id: Scalars['ObjectId'],
  isArchived?: Maybe<Scalars['Boolean']>,
  postalCode?: Maybe<Scalars['String']>,
};

export type UpdateCourseInput = {
  academicLevelIds?: Maybe<Array<Scalars['ObjectId']>>,
  academicStreams?: Maybe<Array<Scalars['String']>>,
  avatarUrl?: Maybe<Scalars['String']>,
  colorCode?: Maybe<Scalars['String']>,
  courseFee?: Maybe<CourseFeeInput>,
  courseMaterial?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  disclaimer?: Maybe<Array<Scalars['String']>>,
  duration?: Maybe<Scalars['Float']>,
  gamePlanLink?: Maybe<Scalars['String']>,
  id: Scalars['ObjectId'],
  isRequireFeedback?: Maybe<Scalars['Boolean']>,
  isSkipScheduledHoliday?: Maybe<Scalars['Boolean']>,
  learningResourceUrl?: Maybe<Scalars['String']>,
  /** ID of the Lesson Flow to be assigned to the class. */
  lessonFlowId?: Maybe<Scalars['ObjectId']>,
  makeUpCredits?: Maybe<MakeUpCreditInput>,
  name?: Maybe<Scalars['String']>,
  nameShort?: Maybe<Scalars['String']>,
  numberOfLessons?: Maybe<Scalars['Int']>,
  subject?: Maybe<Scalars['String']>,
  termsAndConditions?: Maybe<Scalars['String']>,
  type?: Maybe<CourseType>,
};

export type UpdateDeliveryInput = {
  address?: Maybe<Scalars['String']>,
  dropOffAddress?: Maybe<Scalars['String']>,
  dropOffMobile?: Maybe<TelephoneInput>,
  dropOffPostalCode?: Maybe<Scalars['String']>,
  dropOffRemarks?: Maybe<Scalars['String']>,
  id: Scalars['ObjectId'],
  parcelSize?: Maybe<DeliveryParcelSize>,
  pickupEndAt?: Maybe<Scalars['DateTime']>,
  pickupStartAt?: Maybe<Scalars['DateTime']>,
  remarks?: Maybe<Scalars['String']>,
};

export type UpdateDepartmentInput = {
  centreId?: Maybe<Scalars['ObjectId']>,
  id: Scalars['ObjectId'],
  isArchived?: Maybe<Scalars['Boolean']>,
  name?: Maybe<Scalars['String']>,
};

export type UpdateDiscountInput = {
  discountAmount?: Maybe<Scalars['Decimal']>,
  discountName?: Maybe<Scalars['String']>,
  discountRate?: Maybe<Scalars['Decimal']>,
  discountType?: Maybe<DiscountType>,
  id: Scalars['ObjectId'],
  isVisible?: Maybe<Scalars['Boolean']>,
};

export type UpdateEligibilityFlowInput = {
  id: Scalars['ObjectId'],
  startNode: FlowNodeInput,
};

export type UpdateEnrollmentFollowUpStatusInput = {
  followUpStatus: FollowUpStatus,
  id: Scalars['ObjectId'],
};

export type UpdateEnrollmentGroupInput = {
  group?: Maybe<EnrollmentGroupType>,
  id: Scalars['ObjectId'],
};

export type UpdateEnrollmentPreferredLearningArrangementInput = {
  id: Scalars['ObjectId'],
  preferredLearningArrangement?: Maybe<LearningArrangementType>,
};

/** Input for updating multiple enrollment records. */
export type UpdateEnrollmentsInput = {
  /** Input in updating an enrollment record's suitability information */
  enrollmentId: Scalars['ObjectId'],
  /** Remarks on the suitability of the student's enrollment */
  remarks?: Maybe<Scalars['String']>,
  /** Input in updating an enrollment record's suitability information */
  suitabilityStatus?: Maybe<EnrollmentSuitabilityStatus>,
};

export type UpdateEnrollmentsPreferredLearningArrangementInput = {
  classId: Scalars['ObjectId'],
  preferredLearningArrangement?: Maybe<LearningArrangementType>,
};

/** Input in updating an enrollment record's suitability information */
export type UpdateEnrollmentSuitabilityInput = {
  /** Input in updating an enrollment record's suitability information */
  enrollmentId: Scalars['ObjectId'],
  /** Remarks on the suitability of the student's enrollment */
  remarks?: Maybe<Scalars['String']>,
  /** Input in updating an enrollment record's suitability information. Set to `null` to give 'Unassigned' status. */
  status?: Maybe<EnrollmentSuitabilityStatus>,
};

export type UpdateExamScoreInput = {
  academicLevelId: Scalars['ObjectId'],
  examType?: Maybe<Scalars['String']>,
  id: Scalars['ObjectId'],
  marksPercentage?: Maybe<Scalars['Float']>,
  mcq?: Maybe<ScoreRecordInput>,
  practical?: Maybe<ScoreRecordInput>,
  qeq?: Maybe<ScoreRecordInput>,
  scoreGrade?: Maybe<Scalars['String']>,
  stream?: Maybe<Scalars['String']>,
  studentId: Scalars['ObjectId'],
  subject?: Maybe<Scalars['String']>,
  totalMarks?: Maybe<ScoreRecordInput>,
  year: Scalars['Int'],
};

export type UpdateExerciseInput = {
  /** An HTML string that is taken from Braft-Editor. */
  content?: Maybe<Scalars['String']>,
  /** The duration of the `exercise` in minutes. */
  duration?: Maybe<Scalars['Int']>,
  id: Scalars['ObjectId'],
  /** The index of a `lesson`. This is used to link similar lessons for applied `lessonFlow`s to the `lessonflowItem`s. */
  lessonNumber?: Maybe<Scalars['Int']>,
  /** This determines wether the `exercise` requires to be submitted. */
  needSubmit?: Maybe<Scalars['Boolean']>,
  /** The a number to set what position in a list the `exercise` is located. */
  order?: Maybe<Scalars['Int']>,
  /** Contains the `status` and `mechanics` of an `Activity` which in this case is an `Exercise`. */
  reward?: Maybe<RewardInfoInput>,
  /** 
 * This is the breakdown of the achievable scores for the `exercise`. The sum of
   * the breakdown must be equal to the `totalGrade`
 */
  totalBreakdown?: Maybe<MarkBreakDownInput>,
  /** 
 * This is the total grade that can be achieved in the `exercise`. If
   * `totalBreakdown` exists, it must be equal to the sum of the breakdown.
 */
  totalGrade?: Maybe<Scalars['Int']>,
  /** The ID of the `worksheet` to referrence for the `exercise`. */
  workSheetId?: Maybe<Scalars['ObjectId']>,
};

export type UpdateFeatureAccessesInput = {
  enabled?: Maybe<Scalars['Boolean']>,
  extendParams?: Maybe<FeatureAccessExtendInput>,
  keywords?: Maybe<Array<FeatureAccessKeyword>>,
};

export type UpdateFeatureAccessInput = {
  enabled?: Maybe<Scalars['Boolean']>,
  extendParams?: Maybe<FeatureAccessExtendInput>,
  keywords: FeatureAccessKeyword,
};

export type UpdateFeedbackInput = {
  id: Scalars['ObjectId'],
  isResolved?: Maybe<Scalars['Boolean']>,
  quoted?: Maybe<Scalars['String']>,
  text?: Maybe<Scalars['String']>,
};

export type UpdateGroupAssignmentInput = {
  id: Scalars['ObjectId'],
  items?: Maybe<Array<GroupAssignmentItemInput>>,
};

/** Arguments to be used when updating a handout. */
export type UpdateHandoutInput = {
  /** The URL of the file, if the handout is a file. */
  file?: Maybe<HandoutFileInput>,
  /** The ID of the handout to update. */
  id: Scalars['ObjectId'],
  /** The instructions for the handout. This is formatted in Markdown */
  instructions?: Maybe<Scalars['String']>,
};

export type UpdateHolidayInput = {
  id: Scalars['ObjectId'],
  name?: Maybe<Scalars['String']>,
  period?: Maybe<DateRangeInput>,
};

export type UpdateHomeworkInput = {
  /** An HTML string that is taken from Braft-Editor. */
  content?: Maybe<Scalars['String']>,
  /** The duration of the `homework` in minutes. */
  duration?: Maybe<Scalars['Int']>,
  id: Scalars['ObjectId'],
  /** The index of a `lesson`. This is used to link similar lessons for applied `lessonFlow`s to the `lessonflowItem`s. */
  lessonNumber?: Maybe<Scalars['Int']>,
  /** This determines wether the `exercise` requires to be submitted. */
  needSubmit?: Maybe<Scalars['Boolean']>,
  /** The a number to set what position in a list the `exercise` is located. */
  order?: Maybe<Scalars['Int']>,
  /** Contains the `status` and `mechanics` of an `Ac tivity` which in this case is a `Homework`. */
  reward?: Maybe<RewardInfoInput>,
  /** 
 * This is the breakdown of the achievable scores for the `exercise`. The sum of
   * the breakdown must be equal to the `totalGrade`
 */
  totalBreakdown?: Maybe<MarkBreakDownInput>,
  /** 
 * This is the total grade that can be achieved in the `exercise`. If
   * `totalBreakdown` exists, it must be equal to the sum of the breakdown.
 */
  totalGrade?: Maybe<Scalars['Int']>,
  /** The ID of the `worksheet` to referrence for the `exercise`. */
  workSheetId?: Maybe<Scalars['ObjectId']>,
};

/** Update integration command. */
export type UpdateIntegrationCommand = {
  /** Integration application account id */
  accountId?: Maybe<Scalars['String']>,
  /** Integration application client id */
  clientId?: Maybe<Scalars['String']>,
  /** Integration application client secret */
  clientSecret?: Maybe<Scalars['String']>,
  /** Google credentials */
  googleCredentials?: Maybe<Scalars['Upload']>,
  id: Scalars['ObjectId'],
};

export type UpdateInvoiceDueDateCommand = {
  dueDate?: Maybe<Scalars['DateTime']>,
  id: Scalars['ObjectId'],
};

export type UpdateInvoiceItemOptionInput = {
  description: Scalars['String'],
  id: Scalars['ObjectId'],
  unitAmount: Scalars['Decimal'],
};

export type UpdateInvoiceLineItemCommand = {
  description: Scalars['String'],
  lineItemId: Scalars['ObjectId'],
  quantity: Scalars['Int'],
  unitAmount: Scalars['Decimal'],
};

export type UpdateLessonAttendanceInput = {
  lessonId: Scalars['ObjectId'],
  status: AttendanceStatus,
  studentIds: Array<Scalars['ObjectId']>,
};

export type UpdateLessonCommand = {
  academicWeekNumber?: Maybe<Scalars['Int']>,
  id: Scalars['ObjectId'],
  lessonEnd: Scalars['DateTime'],
  lessonNotes?: Maybe<Scalars['String']>,
  lessonStart: Scalars['DateTime'],
  teacherId?: Maybe<Scalars['ObjectId']>,
  updateForSubsequentLessons?: Maybe<Scalars['Boolean']>,
  venueId?: Maybe<Scalars['ObjectId']>,
};

/** An input used to provide values for updating a lesson flow. Currently being used for setting Lesson Flow Items. */
export type UpdateLessonFlowInput = {
  /** Current Lesson Flow ID to update */
  lessonFlowId: Scalars['ObjectId'],
  /** An array of Lesson Flow Item Ids */
  lessonFlowItemIds?: Maybe<Array<Scalars['ObjectId']>>,
};

export type UpdateLessonFlowItemInput = {
  /** The academic week object to assign to the lessonFlowItem. */
  academicWeek?: Maybe<LessonFlowAcademicWeekInput>,
  exceptedClassIds?: Maybe<Array<Scalars['ObjectId']>>,
  exerciseInputs?: Maybe<SaveExerciseInput>,
  homeworkInputs?: Maybe<SaveHomeworkInput>,
  lessonFlowItemId: Scalars['ObjectId'],
  name?: Maybe<Scalars['String']>,
  suitability?: Maybe<SuitabilityInput>,
};

/** 
 * Inputs for the Update LessonFlow Schedule mutation.
 *   Updates an existing lesson flow schedule item.
 *   Passing a different item payload would result in an error.
 */
export type UpdateLessonFlowScheduleInput = {
  /** Required for updating existing lesson plan item of type BREAK. */
  break?: Maybe<LessonFlowBreakItemInput>,
  /** Id of the lesson flow containing the schedule item to be edited. */
  lessonFlowId: Scalars['ObjectId'],
  /** Id of the lesson plan containing the schedule item to be edited. */
  lessonPlanId: Scalars['ObjectId'],
  /** Required for updating existing lesson plan item of type OTHER. */
  other?: Maybe<LessonFlowOtherItemInput>,
  /** Id of the schedule in the lesson flow to edit. */
  scheduleId: Scalars['ObjectId'],
  /** Required for updating existing lesson plan item of type TEST. */
  test?: Maybe<LessonFlowTestItemInput>,
  /** Required for updating existing lesson plan item of type WORKSHEET. */
  worksheet?: Maybe<LessonFlowWorkSheetItemInput>,
};

/** Indicates the effect of the update lesson flow schedule operation. */
export type UpdateLessonFlowScheduleResponse = {
   __typename?: 'UpdateLessonFlowScheduleResponse',
  /** The updated lesson plan - edited schedule of lesson flow. */
  updatedLessonPlan: LessonPlan,
  /** The number of lessons updated with the schedule of the lesson flow. */
  updatedLessonsCount: Scalars['Int'],
};

/** Input used to update lesson sequence of a LessonFlowItem. */
export type UpdateLessonSequenceInput = {
  /** Optional description of the lesson sequence item */
  description?: Maybe<Scalars['String']>,
  /** The updated duration (in minutes) of the sequence item */
  duration: Scalars['Float'],
  /** The updated linked exercise ID of the sequence item in the list. */
  exerciseId?: Maybe<Scalars['ObjectId']>,
  /** The ID of the LessonFlowItem to be updated. */
  lessonFlowItemId: Scalars['ObjectId'],
  /** The updated zero-based index order of the sequence item in the list */
  order?: Maybe<Scalars['Float']>,
  /** The ID of the LessonFlowItem sequence item to update. */
  sequenceId: Scalars['ObjectId'],
};

export type UpdateLogInput = {
  id: Scalars['ObjectId'],
  log: Scalars['String'],
};

export type UpdateMailMergeInput = {
  id: Scalars['ObjectId'],
  templateId: Scalars['ObjectId'],
};

/** Update Ad Hoc Material Input */
export type UpdateMaterialDeliveryOptionInput = {
  attendanceId?: Maybe<Scalars['ObjectId']>,
  id: Scalars['ObjectId'],
  isCollectFromReception?: Maybe<Scalars['Boolean']>,
  method: DeliveryMethod,
  remarks?: Maybe<Scalars['String']>,
};

export type UpdateMerchandiseInput = {
  dateIssued?: Maybe<Scalars['DateTime']>,
  distributionChannel?: Maybe<Scalars['String']>,
  id: Scalars['ObjectId'],
  item?: Maybe<Scalars['String']>,
  remarks?: Maybe<Scalars['String']>,
  status?: Maybe<MerchandiseStatus>,
};

export type UpdateParentInput = {
  address?: Maybe<UserAddressInput>,
  alias?: Maybe<Scalars['String']>,
  alternateUserId?: Maybe<Scalars['String']>,
  alternativeEmails?: Maybe<Array<Scalars['String']>>,
  avatarUrl?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  fullName?: Maybe<Scalars['String']>,
  gender?: Maybe<Scalars['String']>,
  homePhone?: Maybe<TelephoneInput>,
  id: Scalars['ObjectId'],
  linkedWhatsappAccountId?: Maybe<Scalars['ObjectId']>,
  mobile?: Maybe<TelephoneInput>,
  officePhone?: Maybe<TelephoneInput>,
  remarks?: Maybe<Scalars['String']>,
  salutation?: Maybe<Scalars['String']>,
  userId?: Maybe<Scalars['String']>,
  whatsAppName?: Maybe<Scalars['String']>,
};

export type UpdatePaymentRecordInput = {
  bankName?: Maybe<Scalars['String']>,
  chequeNumber?: Maybe<Scalars['String']>,
  depositDate?: Maybe<Scalars['DateTime']>,
  id: Scalars['ObjectId'],
  lastDigits?: Maybe<Scalars['String']>,
  /** Updated Payment method to overwrite in record */
  method?: Maybe<PaymentMode>,
  /** The date when the payment was made */
  paymentDate?: Maybe<Scalars['DateTime']>,
  paymentProcessor?: Maybe<Scalars['String']>,
  reference?: Maybe<Scalars['String']>,
  remarks?: Maybe<Scalars['String']>,
};

export type UpdatePromotionInput = {
  academicLevelIds?: Maybe<Array<Scalars['ObjectId']>>,
  id: Scalars['ObjectId'],
  promoteToLevelId: Scalars['ObjectId'],
  scheduledDate?: Maybe<Scalars['DateTime']>,
  selectedStudentIds?: Maybe<Array<Scalars['ObjectId']>>,
};

export type UpdateReferenceDataInput = {
  id: Scalars['ObjectId'],
  isArchived?: Maybe<Scalars['Boolean']>,
  order?: Maybe<Scalars['Int']>,
  value?: Maybe<Scalars['String']>,
};

export type UpdateRegistrationInput = {
  classIds?: Maybe<Scalars['ObjectId']>,
  currentStep?: Maybe<Scalars['Int']>,
  dueDate?: Maybe<Scalars['DateTime']>,
  id: Scalars['ObjectId'],
  invoiceId?: Maybe<Scalars['ObjectId']>,
  isPrioritized?: Maybe<Scalars['Boolean']>,
  preferredLearningArrangement?: Maybe<LearningArrangementType>,
  remarks?: Maybe<Scalars['String']>,
  selectedLessons?: Maybe<Array<SelectedLessonInput>>,
  startDate?: Maybe<Scalars['DateTime']>,
};

export type UpdateRelationshipInput = {
  destId: Scalars['ObjectId'],
  relationName?: Maybe<Scalars['String']>,
  sourceId: Scalars['ObjectId'],
};

export type UpdateRemarkInput = {
  /** A string that contains a remark in a formatted string that will be formatted further in the front-end. */
  content?: Maybe<Scalars['String']>,
  id: Scalars['ObjectId'],
  /** A boolean that signifies the pinned status of the remark. */
  isPinned?: Maybe<Scalars['Boolean']>,
  /** The type of the remark */
  levelType?: Maybe<RemarkLevelType>,
};

export type UpdateRewardCatalogInput = {
  avatar?: Maybe<Scalars['String']>,
  category?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  galleries?: Maybe<Array<Scalars['String']>>,
  id: Scalars['ObjectId'],
  isVisible?: Maybe<Scalars['Boolean']>,
  name?: Maybe<Scalars['String']>,
  points?: Maybe<Scalars['Int']>,
  stock?: Maybe<Scalars['Int']>,
};

export type UpdateRewardsHistoryInput = {
  id: Scalars['ObjectId'],
  orderUrl?: Maybe<Scalars['String']>,
  remarks?: Maybe<Scalars['String']>,
  status?: Maybe<RewardHistoryStatus>,
  trackingNumber?: Maybe<Scalars['String']>,
};

export type UpdateSchoolInput = {
  alias?: Maybe<Scalars['String']>,
  educationStage?: Maybe<EducationStage>,
  id: Scalars['ObjectId'],
  isArchived?: Maybe<Scalars['Boolean']>,
  isVisible?: Maybe<Scalars['Boolean']>,
  name?: Maybe<Scalars['String']>,
};

export type UpdateStudentInput = {
  academicLevelId?: Maybe<Scalars['ObjectId']>,
  academicMatterIds?: Maybe<Array<Scalars['ObjectId']>>,
  address?: Maybe<UserAddressInput>,
  alias?: Maybe<Scalars['String']>,
  alternateUserId?: Maybe<Scalars['String']>,
  alternativeEmails?: Maybe<Array<Scalars['String']>>,
  avatarUrl?: Maybe<Scalars['String']>,
  birthDate?: Maybe<Scalars['DateTime']>,
  consent?: Maybe<ConsentInput>,
  email?: Maybe<Scalars['String']>,
  enrollmentMatterIds?: Maybe<Array<Scalars['ObjectId']>>,
  fullName?: Maybe<Scalars['String']>,
  gender?: Maybe<Scalars['String']>,
  homePhone?: Maybe<TelephoneInput>,
  id: Scalars['ObjectId'],
  mobile?: Maybe<TelephoneInput>,
  parentIds?: Maybe<Array<Scalars['ObjectId']>>,
  primaryParentId?: Maybe<Scalars['ObjectId']>,
  primarySchoolId?: Maybe<Scalars['ObjectId']>,
  primaryStream?: Maybe<Scalars['String']>,
  remarks?: Maybe<Scalars['String']>,
  secondarySchoolId?: Maybe<Scalars['ObjectId']>,
  secondaryStream?: Maybe<Scalars['String']>,
  userId?: Maybe<Scalars['String']>,
};

export type UpdateStudentRemarksInput = {
  id: Scalars['ObjectId'],
  remarks: Scalars['String'],
};

export type UpdateStudentReportFilterInput = {
  id: Scalars['ObjectId'],
  name: Scalars['String'],
};

export type UpdateSubmissionInput = {
  breakdown?: Maybe<MarkBreakDownInput>,
  /** Flag to set if update operation involves removing/ nulling a breakdown value. */
  deleteFlag?: Maybe<Scalars['Boolean']>,
  grade?: Maybe<Scalars['Int']>,
  submissionId: Scalars['ObjectId'],
};

export type UpdateTaskChecklistInput = {
  assigneeIds?: Maybe<Array<Scalars['ObjectId']>>,
  id: Scalars['ObjectId'],
  name?: Maybe<Scalars['String']>,
};

export type UpdateTaskChecklistItemInput = {
  assigneeIds?: Maybe<Array<Scalars['ObjectId']>>,
  id: Scalars['ObjectId'],
  isDone?: Maybe<Scalars['Boolean']>,
  name?: Maybe<Scalars['String']>,
};

export type UpdateTaskInput = {
  assigneeIds?: Maybe<Array<Scalars['ObjectId']>>,
  description?: Maybe<Scalars['String']>,
  id: Scalars['ObjectId'],
  isTemplate?: Maybe<Scalars['Boolean']>,
  name?: Maybe<Scalars['String']>,
};

export type UpdateTaskListInput = {
  endAt?: Maybe<Scalars['DateTime']>,
  generateAt?: Maybe<Scalars['DateTime']>,
  id: Scalars['ObjectId'],
  isAutoGenerate?: Maybe<Scalars['Boolean']>,
  name?: Maybe<Scalars['String']>,
  startAt?: Maybe<Scalars['DateTime']>,
  weekDay?: Maybe<WeekDay>,
};

export type UpdateTemplateInput = {
  content?: Maybe<Scalars['String']>,
  emailMessage?: Maybe<Scalars['String']>,
  id: Scalars['ObjectId'],
  status: TemplateStatus,
  subject?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  type?: Maybe<TemplateType>,
  usageType?: Maybe<TemplateUsageType>,
  whatsAppMessage?: Maybe<Scalars['String']>,
};

export type UpdateTopicInput = {
  courseId: Scalars['ObjectId'],
  name: Scalars['String'],
  topicId: Scalars['ObjectId'],
};

export type UpdateUserCustomiseColumnInput = {
  columnName: Scalars['String'],
  columnShowName?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['ObjectId']>,
  isRequired: Scalars['Boolean'],
  isVisible: Scalars['Boolean'],
  ownerId?: Maybe<Scalars['ObjectId']>,
  tableName: Scalars['String'],
  tableShowName?: Maybe<Scalars['String']>,
  weight: Scalars['Int'],
};

export type UpdateVenueCommand = {
  /** Address of the Venue */
  address?: Maybe<AddressInput>,
  /** Id of the branch where the venue is located */
  branchId?: Maybe<Scalars['ObjectId']>,
  /** The maximum number of students that a venue can hold */
  capacity?: Maybe<Scalars['Int']>,
  googleCalendarHostEmail?: Maybe<Scalars['String']>,
  googleCalendarId?: Maybe<Scalars['String']>,
  /** Id of the venue */
  id: Scalars['ObjectId'],
  /** Indicator that the venue is archived, archiving the venue means it cannot be associated to a class */
  isArchived?: Maybe<Scalars['Boolean']>,
  /** Name of the Venue, this is the combination of the venue unit and classroom number e.g 02-5A CR1 */
  name?: Maybe<Scalars['String']>,
  section?: Maybe<Scalars['String']>,
  shorthand?: Maybe<Scalars['String']>,
  unitNo?: Maybe<Scalars['String']>,
};

export type UpdateWhatsappAccountInput = {
  id: Scalars['ObjectId'],
  name?: Maybe<Scalars['String']>,
};

/** 
 * Inputs for the UpdateWorksheet mutation. Fields are optionally
 *   set so only defined fields will be updated.
 */
export type UpdateWorksheetInput = {
  id: Scalars['ObjectId'],
  test?: Maybe<WorkSheetTestInput>,
  title?: Maybe<Scalars['String']>,
  topicId?: Maybe<Scalars['ObjectId']>,
};


export enum UploadFilePath {
  ImagesCourse = 'ImagesCourse',
  ImagesLog = 'ImagesLog',
  ImagesReward = 'ImagesReward',
  ImagesUser = 'ImagesUser',
  ReminderFiles = 'ReminderFiles'
}

export type UploadMailMergeMergeListInput = {
  file: Scalars['Upload'],
  id: Scalars['ObjectId'],
};

export type UploadResult = {
   __typename?: 'UploadResult',
  remoteUrl: Scalars['String'],
  url: Scalars['String'],
};

export type UpsertUserCustomiseColumnInput = {
  columnName: Scalars['String'],
  columnShowName?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['ObjectId']>,
  isRequired: Scalars['Boolean'],
  isVisible: Scalars['Boolean'],
  ownerId?: Maybe<Scalars['ObjectId']>,
  tableName: Scalars['String'],
  tableShowName?: Maybe<Scalars['String']>,
  weight: Scalars['Int'],
};

export type UserAddress = {
   __typename?: 'UserAddress',
  country?: Maybe<Scalars['String']>,
  gateBuilding?: Maybe<Scalars['String']>,
  link?: Maybe<Scalars['String']>,
  postalCode?: Maybe<Scalars['String']>,
  streetAddress?: Maybe<Scalars['String']>,
  unit?: Maybe<Scalars['String']>,
};

export type UserAddressInput = {
  country?: Maybe<Scalars['String']>,
  gateBuilding?: Maybe<Scalars['String']>,
  link?: Maybe<Scalars['String']>,
  postalCode?: Maybe<Scalars['String']>,
  streetAddress?: Maybe<Scalars['String']>,
  unit?: Maybe<Scalars['String']>,
};

export type UserAddressOutput = {
   __typename?: 'UserAddressOutput',
  country?: Maybe<Scalars['String']>,
  gateBuilding?: Maybe<Scalars['String']>,
  link?: Maybe<Scalars['String']>,
  postalCode?: Maybe<Scalars['String']>,
  streetAddress?: Maybe<Scalars['String']>,
  unit?: Maybe<Scalars['String']>,
};

export type UserCustomiseColumn = {
   __typename?: 'UserCustomiseColumn',
  _id: Scalars['ObjectId'],
  columnName: Scalars['String'],
  columnShowName?: Maybe<Scalars['String']>,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  isRequired: Scalars['Boolean'],
  isVisible: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  ownerId?: Maybe<Scalars['ObjectId']>,
  tableName: Scalars['String'],
  tableShowName?: Maybe<Scalars['String']>,
  weight: Scalars['Float'],
};

export type UserCustomiseColumnFilterInput = {
  columnName?: Maybe<Scalars['String']>,
  columnShowName?: Maybe<Scalars['String']>,
  isRequired?: Maybe<Scalars['Boolean']>,
  isVisible?: Maybe<Scalars['Boolean']>,
  ownerId?: Maybe<Scalars['ObjectId']>,
  tableName?: Maybe<Scalars['String']>,
  tableShowName?: Maybe<Scalars['String']>,
  weight?: Maybe<Scalars['Int']>,
};

export type UserCustomiseColumnPage = {
   __typename?: 'UserCustomiseColumnPage',
  items: Array<UserCustomiseColumn>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

export type UseRewardPointsInput = {
  orderDetail: RewardDetailInput,
  points: Scalars['Int'],
  remarks?: Maybe<Scalars['String']>,
  studentId: Scalars['ObjectId'],
};

export type UserRequestDto = {
   __typename?: 'UserRequestDTO',
  _id: Scalars['ObjectId'],
  classId?: Maybe<Scalars['ObjectId']>,
  eligibilityResults?: Maybe<Array<Scalars['String']>>,
  referenceNumber?: Maybe<Scalars['String']>,
  remarks?: Maybe<Scalars['String']>,
  source?: Maybe<Scalars['String']>,
  startDate?: Maybe<Scalars['String']>,
  status?: Maybe<UserRequestStatus>,
  studentId?: Maybe<Scalars['ObjectId']>,
  type?: Maybe<UserRequestType>,
};

export enum UserRequestStatus {
  Approved = 'Approved',
  Draft = 'Draft',
  Pending = 'Pending',
  Rejected = 'Rejected'
}

export enum UserRequestType {
  Regular = 'Regular',
  Trial = 'Trial',
  WaitingList = 'WaitingList'
}

/** Validate integration command. */
export type ValidateIntegrationAccessTokenCommand = {
  /** Integration application client type */
  type: IntegrationType,
};

export type ValidateTeacherAndVenueInput = {
  dateTimeRanges: Array<DateTimeRangeInput>,
  excludeClassId?: Maybe<Scalars['ObjectId']>,
  excludeLessonId?: Maybe<Scalars['ObjectId']>,
  teacherId?: Maybe<Scalars['ObjectId']>,
  venueId?: Maybe<Scalars['ObjectId']>,
};

export type ValidateWhatsappNumberInput = {
  accountId?: Maybe<Scalars['ObjectId']>,
  number: TelephoneInput,
};

export type Venue = {
   __typename?: 'Venue',
  _id: Scalars['ObjectId'],
  address: Address,
  branch?: Maybe<Branch>,
  branchId?: Maybe<Scalars['ObjectId']>,
  capacity?: Maybe<Scalars['Float']>,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  googleCalendarHostEmail?: Maybe<Scalars['String']>,
  googleCalendarId?: Maybe<Scalars['String']>,
  isArchived: Scalars['Boolean'],
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  isDigital?: Maybe<Scalars['Boolean']>,
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  name?: Maybe<Scalars['String']>,
  section?: Maybe<Scalars['String']>,
  shorthand: Scalars['String'],
  systemId?: Maybe<Scalars['String']>,
  unitNo?: Maybe<Scalars['String']>,
};

export type Visible = {
  isVisible: Scalars['Boolean'],
};

export enum VoidableStatus {
  Normal = 'Normal',
  Void = 'Void'
}

export type VoidInput = {
  id: Scalars['ObjectId'],
  voidRemarks?: Maybe<Scalars['String']>,
};

export type VoidPaymentRecordInput = {
  paymentId: Scalars['ObjectId'],
  remarks?: Maybe<Scalars['String']>,
};

export type WaitingListUpdateHistory = {
   __typename?: 'WaitingListUpdateHistory',
  originalStatus?: Maybe<RegistrationsStatus>,
  status?: Maybe<RegistrationsStatus>,
  timestamp?: Maybe<Scalars['DateTime']>,
};

export enum WeekDay {
  Friday = 'Friday',
  Monday = 'Monday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
  Thursday = 'Thursday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday'
}

export type WhatsappAccount = {
   __typename?: 'WhatsappAccount',
  _id: Scalars['ObjectId'],
  contacts?: Maybe<Array<WhatsappContact>>,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  currentUserId?: Maybe<Scalars['ObjectId']>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  hasFailedMessages?: Maybe<Scalars['Boolean']>,
  isArchived: Scalars['Boolean'],
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  lastSentDateTime?: Maybe<Scalars['DateTime']>,
  lastSentOffset?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  number?: Maybe<Telephone>,
  platform?: Maybe<ReminderType>,
  processingStatus: WhatsappProcessingStatus,
  status: WhatsappAccountStatus,
  statusDate?: Maybe<Scalars['DateTime']>,
  statusDetail?: Maybe<Scalars['String']>,
};

export type WhatsappAccountFilterInput = {
  accountIds?: Maybe<Array<Scalars['ObjectId']>>,
  isArchived?: Maybe<Scalars['Boolean']>,
  processingStatus?: Maybe<Array<WhatsappProcessingStatus>>,
  status?: Maybe<Array<WhatsappAccountStatus>>,
};

export type WhatsappAccountPage = {
   __typename?: 'WhatsappAccountPage',
  items: Array<WhatsappAccount>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

export enum WhatsappAccountStatus {
  Handoff = 'Handoff',
  Offline = 'Offline',
  Online = 'Online',
  Warning = 'Warning'
}

export type WhatsappContact = {
   __typename?: 'WhatsappContact',
  name: Scalars['String'],
  number: Scalars['String'],
};

export type WhatsappMessage = {
   __typename?: 'WhatsappMessage',
  _id: Scalars['ObjectId'],
  arrangement?: Maybe<Arrangement>,
  attachmentStatus?: Maybe<WhatsappMessageStatus>,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  failedCount?: Maybe<Scalars['Int']>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  isQueueNext?: Maybe<Scalars['Boolean']>,
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  message: Scalars['String'],
  parent?: Maybe<Parent>,
  platform: ReminderType,
  queueOrder?: Maybe<Scalars['Int']>,
  recipient: Scalars['String'],
  registration?: Maybe<Registration>,
  sender: WhatsappAccount,
  sentDate?: Maybe<Scalars['DateTime']>,
  status?: Maybe<WhatsappMessageStatus>,
  statusDate?: Maybe<Scalars['DateTime']>,
  template?: Maybe<Template>,
};

export type WhatsappMessageCounts = {
   __typename?: 'WhatsappMessageCounts',
  cancelled: Scalars['Int'],
  delivered: Scalars['Int'],
  failed: Scalars['Int'],
  paused: Scalars['Int'],
  processing: Scalars['Int'],
  queued: Scalars['Int'],
  read: Scalars['Int'],
  sent: Scalars['Int'],
};

export type WhatsappMessageFilterInput = {
  createDate?: Maybe<DateTimeRangeInput>,
  senderId?: Maybe<Scalars['ObjectId']>,
  status?: Maybe<Array<WhatsappMessageStatus>>,
};

export type WhatsappMessagePage = {
   __typename?: 'WhatsappMessagePage',
  items: Array<WhatsappMessage>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

export enum WhatsappMessageStatus {
  Cancelled = 'Cancelled',
  Delivered = 'Delivered',
  Failed = 'Failed',
  Paused = 'Paused',
  Processing = 'Processing',
  Queued = 'Queued',
  Read = 'Read',
  Sent = 'Sent'
}

export enum WhatsappProcessingStatus {
  Paused = 'Paused',
  Ready = 'Ready'
}

export type WhatsappReminderInput = {
  isIncludeAttachment?: Maybe<Scalars['Boolean']>,
  message: Scalars['String'],
  receiverParentIds?: Maybe<Array<Scalars['ObjectId']>>,
  senderId?: Maybe<Scalars['ObjectId']>,
  templateId: Scalars['ObjectId'],
};

export type WithdrawReasonsInput = {
  otherReason?: Maybe<Scalars['String']>,
  reasons?: Maybe<Array<SelectedReasonInput>>,
  remarks?: Maybe<Scalars['String']>,
  suggestion?: Maybe<Scalars['String']>,
};

/** 
 * This refers to a sheet paper given by teachers to students to do a particular
 * task. There are two types of worksheets: Regular worksheet and Test worksheet
 */
export type WorkSheet = {
   __typename?: 'WorkSheet',
  _id: Scalars['ObjectId'],
  /** Optional Worksheet abbreviation */
  abbreviation?: Maybe<Scalars['String']>,
  academicYear?: Maybe<AcademicYear>,
  /** Academic Year ID assigned to Worksheet */
  academicYearId: Scalars['ObjectId'],
  /** List of associated courses */
  courseIds: Array<Scalars['ObjectId']>,
  courses?: Maybe<Array<Course>>,
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  isArchived: Scalars['Boolean'],
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  /** When this is not null, the worksheet is considered a test. */
  test?: Maybe<WorkSheetTest>,
  title: Scalars['String'],
  topic?: Maybe<Topic>,
  topicId: Scalars['ObjectId'],
};

/** Input for the `worksheetAddCourse` mutation. */
export type WorksheetAddCourseInput = {
  /** The course to associate to the worksheet. */
  courseId: Scalars['ObjectId'],
  /** The worksheet to add the course. */
  worksheetId: Scalars['ObjectId'],
};

/** Input for `worksheetCreate` mutation. */
export type WorksheetCreateInput = {
  /** The course to add the worksheet. */
  courseId: Scalars['ObjectId'],
  /** When provided, it categorizes the worksheet as a test. */
  test?: Maybe<WorksheetTestCreateInput>,
  /** The provided title of the worksheet. */
  title: Scalars['String'],
  /** The topic of the course to assign to the worksheet. */
  topicId: Scalars['ObjectId'],
};

export type WorkSheetItemInput = {
  academicLevelId?: Maybe<Scalars['ObjectId']>,
  /** Filter by Academic Year ID */
  academicYearId?: Maybe<Scalars['ObjectId']>,
  /** Course Ids that the worksheet should belong to. Leave null to get all. */
  courseIds?: Maybe<Array<Scalars['ObjectId']>>,
  isArchived?: Maybe<Scalars['Boolean']>,
  title?: Maybe<Scalars['String']>,
  topicId?: Maybe<Scalars['ObjectId']>,
  workSheetId?: Maybe<Scalars['ObjectId']>,
};

export type WorkSheetPage = {
   __typename?: 'WorkSheetPage',
  items: Array<WorkSheet>,
  pageIndex: Scalars['Int'],
  pageSize: Scalars['Int'],
  total: Scalars['Int'],
};

/** The data that was parsed on each row on the csv file. */
export type WorksheetParsedData = {
   __typename?: 'WorksheetParsedData',
  /** The grading template to assign to the test. */
  gradingTemplate: WorksheetParsedGradingTemplate,
  /** The name of the test of the worksheet. */
  testName?: Maybe<Scalars['String']>,
  /** The title of the worksheet. */
  title?: Maybe<Scalars['String']>,
  /** The topic of the worksheet. */
  topic: WorksheetParsedTopic,
};

/** The errors encountered while parsing the data of a row on the csv file. */
export type WorksheetParsedError = {
   __typename?: 'WorksheetParsedError',
  /** 
 * The type of error that occurred while parsing the csv file.
   *     This is to help FE determine what message to display.
 */
  errorCode: WorksheetParsedErrorCode,
  /** The incorrect field of the worksheet. */
  field: Scalars['String'],
  /** A human readable error message indicating the problem. */
  reason: Scalars['String'],
};

export enum WorksheetParsedErrorCode {
  Duplicate = 'DUPLICATE',
  IncorrectField = 'INCORRECT_FIELD',
  MissingField = 'MISSING_FIELD'
}

/** Contains the parsed grading template data provided on a row of the csv file. */
export type WorksheetParsedGradingTemplate = {
   __typename?: 'WorksheetParsedGradingTemplate',
  /** Reference to the grading template entity. */
  _id?: Maybe<Scalars['ObjectId']>,
  /** Name of the referenced grading template. */
  name?: Maybe<Scalars['String']>,
};

/** The result of parsing a row on the csv file. */
export type WorksheetParsedResult = {
   __typename?: 'WorksheetParsedResult',
  /** Contains the data of a parsed row of the csv file. */
  data: WorksheetParsedData,
  /** Contains the errors encountered while parsing the data of a row on the csv file. */
  errors: Array<WorksheetParsedError>,
};

/** Contains the parsed topic data provided on a row of the csv file. */
export type WorksheetParsedTopic = {
   __typename?: 'WorksheetParsedTopic',
  /** Reference to the topic entity. */
  _id?: Maybe<Scalars['ObjectId']>,
  /** Name of the referenced topic. */
  name?: Maybe<Scalars['String']>,
};

/** Input for the `worksheetRemoveCourse` mutation. */
export type WorksheetRemoveCourseInput = {
  /** The course to dissociate from the worksheet. */
  courseId: Scalars['ObjectId'],
  /** The worksheet to remove the course from. */
  worksheetId: Scalars['ObjectId'],
};

/** Input for  the `worksheets` field of the `worksheetsCreate` mutation. */
export type WorksheetsCreateDataInput = {
  /** When provided, it categorizes the worksheet as a test. */
  test?: Maybe<WorksheetTestCreateInput>,
  /** The provided title of the worksheet. */
  title: Scalars['String'],
  /** 
 * Name of the topic of the course to assign to the worksheet.
   *     If the topic name is not existing, it will be created.
 */
  topicName: Scalars['String'],
};

/** Input for `worksheetsCreate` mutation. */
export type WorksheetsCreateInput = {
  /** The course to add the worksheet. */
  courseId: Scalars['ObjectId'],
  /** List of worksheets to create. */
  worksheets: Array<WorksheetsCreateDataInput>,
};

/** Input for the `worksheetsParseCsv` mutation. This contains the file to upload as a FileUpload object. */
export type WorksheetsParseCsvInput = {
  /** Course ID where worksheets will be created */
  courseId: Scalars['ObjectId'],
  /** The file to upload. This is a FileUpload object that is created by the `<Upload/>` component of Antd. */
  file: Scalars['Upload'],
};

/** Response object of the `workshetsParseCsv` mutation */
export type WorksheetsParseCsvResponse = {
   __typename?: 'WorksheetsParseCsvResponse',
  /** A list containing the CSV parsed results */
  results: Array<WorksheetParsedResult>,
  /** The total number of rows parsed on the csv file, excluding the empty rows. */
  total: Scalars['Int'],
};

/** A worksheet that is used as a test for the students. */
export type WorkSheetTest = {
   __typename?: 'WorkSheetTest',
  /** Auto-generated identifier of the test. */
  _id: Scalars['ObjectId'],
  /** Reference to the grading template used. */
  gradingTemplateId: Scalars['ObjectId'],
  /** Reference to the name of the grading template used. */
  gradingTemplateName: Scalars['String'],
  /** The name of the test used by the teachers */
  name: Scalars['String'],
  /** The different sections of a Test worksheet */
  sections: Array<WorkSheetTestSection>,
  /** The overall score of the test. It is the sum of all the section total. */
  totalScore: Scalars['Float'],
};

/** Input for the `test` field of the `worksheetCreate` mutation. */
export type WorksheetTestCreateInput = {
  /** The grading template to be used for the test. */
  gradingTemplateId: Scalars['ObjectId'],
  /** 
 * The name of the test of the worksheet. 
   *     When specified, it categorizes the worksheet as a test.
 */
  name: Scalars['String'],
  /** Customized section totals based on the provided grading template. */
  sections?: Maybe<Array<WorkSheetTestSectionInput>>,
};

/** 
 * When this is not null, the worksheet is considered a Test. 
 *     If a test object exists, set this to null to remove it when updating.
 */
export type WorkSheetTestInput = {
  /** 
 * Reference to the name of the grading template most recently 
   *     used for this test. When used in the update mutation, it does not update the
   *     `sections` field since the grade totals can be different from the what
   *     the template originally had.
 */
  gradingTemplateId?: Maybe<Scalars['ObjectId']>,
  /** The name of the test used by the teachers */
  name?: Maybe<Scalars['String']>,
  sections?: Maybe<Array<WorkSheetTestSectionInput>>,
};

/** These are the sections copied from the grading template. */
export type WorkSheetTestSection = {
   __typename?: 'WorkSheetTestSection',
  /** The test section name, copied from the grading template section. */
  name: Scalars['String'],
  /** The section total, initially copied from the grading template, then can be updated afterwards. */
  total: Scalars['Float'],
};

/** The different sections of a Test worksheet indicating grade totals. */
export type WorkSheetTestSectionInput = {
  /** The test section name, copied from the grading template section. */
  name: Scalars['String'],
  /** The section total, initially copied from the grading template, then can be updated afterwards. */
  total: Scalars['Float'],
};

export type WorkshopMakeUpCreditInput = {
  workshop: Scalars['Int'],
};

export type XeroAccount = {
   __typename?: 'XeroAccount',
  code?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  type?: Maybe<AccountType>,
};

export type XeroIntegration = {
   __typename?: 'XeroIntegration',
  _id: Scalars['ObjectId'],
  /** The date and the time record was created. */
  createDateTime: Scalars['DateTime'],
  createdBy?: Maybe<SystemUser>,
  /** Indicates when the record was soft-deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>,
  /** Indicates that the record is soft-deleted. */
  isDeleted: Scalars['Boolean'],
  lastModifiedBy?: Maybe<SystemUser>,
  /** The date and time the record was last modified. */
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>,
  name: Scalars['String'],
  otherAccounts: Array<OtherMappingAccount>,
  tenant?: Maybe<XeroTenant>,
  token?: Maybe<XeroTokenSet>,
};

export type XeroInvoiceDateInput = {
  month?: Maybe<Scalars['Int']>,
  year?: Maybe<Scalars['Int']>,
};

export type XeroPrepayment = {
   __typename?: 'XeroPrepayment',
  allocatedAmount?: Maybe<Scalars['Decimal']>,
  amount: Scalars['Decimal'],
  id?: Maybe<Scalars['String']>,
  lastSyncedDate?: Maybe<Scalars['DateTime']>,
  lineItemId: Scalars['ObjectId'],
};

export enum XeroSyncStatus {
  Completed = 'Completed',
  Failed = 'Failed',
  Pending = 'Pending'
}

export type XeroTenant = {
   __typename?: 'XeroTenant',
  authEventId?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  tenantId?: Maybe<Scalars['String']>,
  tenantName?: Maybe<Scalars['String']>,
  tenantType?: Maybe<Scalars['Float']>,
};

export type XeroTokenSet = {
   __typename?: 'XeroTokenSet',
  access_token?: Maybe<Scalars['String']>,
  expires_at?: Maybe<Scalars['Float']>,
  expires_in?: Maybe<Scalars['Float']>,
  id_token?: Maybe<Scalars['String']>,
  refresh_token?: Maybe<Scalars['String']>,
  scope?: Maybe<Scalars['String']>,
  session_state?: Maybe<Scalars['String']>,
  token_type?: Maybe<Scalars['String']>,
};

export type GetHolidayAffectedLessonsQueryVariables = {
  search?: Maybe<Scalars['String']>,
  filterInput?: Maybe<LessonFilterInput>,
  pageInfo?: Maybe<PageInfo>
};


export type GetHolidayAffectedLessonsQuery = (
  { __typename?: 'Query' }
  & { holidayLessonsIncludeEnrollments: Maybe<(
    { __typename?: 'LessonPage' }
    & Pick<LessonPage, 'pageSize' | 'pageIndex' | 'total'>
    & { items: Array<(
      { __typename?: 'Lesson' }
      & Pick<Lesson, 'lessonNumber' | 'lessonStart'>
      & { id: Lesson['_id'] }
      & { holidayEffectedEnrollments: Maybe<Array<(
        { __typename?: 'Enrollment' }
        & Pick<Enrollment, 'status' | 'type'>
        & { id: Enrollment['_id'] }
        & { student: Maybe<(
          { __typename?: 'Student' }
          & StudentCellFieldsFragment
        )>, class: (
          { __typename?: 'Class' }
          & Pick<Class, 'name'>
          & { id: Class['_id'] }
          & { course: (
            { __typename?: 'Course' }
            & Pick<Course, 'nameShort'>
          ), academicYear: (
            { __typename?: 'AcademicYear' }
            & Pick<AcademicYear, 'year'>
          ) }
        ), arrangements: Maybe<Array<(
          { __typename?: 'Arrangement' }
          & Pick<Arrangement, 'type'>
          & { id: Arrangement['_id'] }
          & { toClass: Maybe<(
            { __typename?: 'Class' }
            & Pick<Class, 'name' | 'shorthand'>
            & { id: Class['_id'] }
          )>, toLesson: Maybe<(
            { __typename?: 'Lesson' }
            & Pick<Lesson, 'lessonDay'>
            & { id: Lesson['_id'] }
          )>, fromLesson: Maybe<(
            { __typename?: 'Lesson' }
            & { id: Lesson['_id'] }
          )> }
        )>> }
      )>>, class: Maybe<(
        { __typename?: 'Class' }
        & Pick<Class, 'name'>
        & { id: Class['_id'] }
      )>, holidays: Maybe<Array<(
        { __typename?: 'Holiday' }
        & Pick<Holiday, 'name'>
        & { id: Holiday['_id'] }
        & { period: (
          { __typename?: 'DateRange' }
          & Pick<DateRange, 'start' | 'end'>
        ) }
      )>>, course: (
        { __typename?: 'Course' }
        & Pick<Course, 'colorCode'>
        & { id: Course['_id'] }
      ) }
    )> }
  )> }
);

export type GetHolidayAffectedEnrollmentsQueryVariables = {
  pageInfo?: Maybe<PageInfo>
};


export type GetHolidayAffectedEnrollmentsQuery = (
  { __typename?: 'Query' }
  & { holidayLessonsIncludeEnrollments: Maybe<(
    { __typename?: 'LessonPage' }
    & Pick<LessonPage, 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'Lesson' }
      & { id: Lesson['_id'] }
      & { holidayEffectedEnrollments: Maybe<Array<(
        { __typename?: 'Enrollment' }
        & { id: Enrollment['_id'] }
        & { student: Maybe<(
          { __typename?: 'Student' }
          & Pick<Student, 'avatarUrl' | 'fullName' | 'gender'>
          & { id: Student['_id'] }
          & { primarySchool: Maybe<(
            { __typename?: 'School' }
            & Pick<School, 'name'>
          )> }
        )>, class: (
          { __typename?: 'Class' }
          & Pick<Class, 'name'>
          & { id: Class['_id'] }
          & { course: (
            { __typename?: 'Course' }
            & Pick<Course, 'nameShort' | 'colorCode'>
          ), academicYear: (
            { __typename?: 'AcademicYear' }
            & Pick<AcademicYear, 'year'>
          ), teacher: Maybe<(
            { __typename?: 'SystemUser' }
            & Pick<SystemUser, 'fullName'>
            & { id: SystemUser['_id'] }
          )> }
        ) }
      )>>, holidays: Maybe<Array<(
        { __typename?: 'Holiday' }
        & Pick<Holiday, 'name'>
        & { id: Holiday['_id'] }
        & { period: (
          { __typename?: 'DateRange' }
          & Pick<DateRange, 'start' | 'end'>
        ) }
      )>> }
    )> }
  )> }
);

export type GetAllHolidaysQueryVariables = {
  filterInput?: Maybe<HolidayFilterInput>
};


export type GetAllHolidaysQuery = (
  { __typename?: 'Query' }
  & { holidays: Maybe<Array<(
    { __typename?: 'Holiday' }
    & Pick<Holiday, 'name'>
    & { id: Holiday['_id'] }
  )>> }
);

export type GetAdditionalLessonDetailQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetAdditionalLessonDetailQuery = (
  { __typename?: 'Query' }
  & { arrangement: Maybe<(
    { __typename?: 'Arrangement' }
    & Pick<Arrangement, 'name' | 'createDateTime' | 'type' | 'status' | 'emailStatus' | 'waStatus' | 'isCancelHasFullLesson' | 'lastModifiedDateTime'>
    & { id: Arrangement['_id'] }
    & { student: (
      { __typename?: 'Student' }
      & StudentCellFieldsFragment
    ), invoice: Maybe<(
      { __typename?: 'Invoice' }
      & InvoiceItemFieldsFragment
    )>, reminders: Maybe<Array<(
      { __typename?: 'Reminder' }
      & Pick<Reminder, 'subject' | 'message' | 'type' | 'sendDate' | 'sendgridEmailStatus'>
      & { id: Reminder['_id'] }
      & { whatsappMessage: Maybe<(
        { __typename?: 'WhatsappMessage' }
        & Pick<WhatsappMessage, 'status'>
      )> }
    )>>, lastModifiedBy: Maybe<(
      { __typename?: 'SystemUser' }
      & LastModifiedByFieldsFragment
    )> }
    & MakeUpCardFieldsFragment
  )> }
);

export type GetAdditionalLessonBillingQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetAdditionalLessonBillingQuery = (
  { __typename?: 'Query' }
  & { arrangement: Maybe<(
    { __typename?: 'Arrangement' }
    & Pick<Arrangement, 'type' | 'createDateTime' | 'isAdhocAdditionalLesson'>
    & { id: Arrangement['_id'] }
    & { fromClass: Maybe<(
      { __typename?: 'Class' }
      & { id: Class['_id'] }
      & { course: (
        { __typename?: 'Course' }
        & { id: Course['_id'] }
      ) }
    )>, fromLesson: Maybe<(
      { __typename?: 'Lesson' }
      & Pick<Lesson, 'lessonNumber'>
      & { id: Lesson['_id'] }
    )>, student: (
      { __typename?: 'Student' }
      & { id: Student['_id'] }
    ), invoice: Maybe<(
      { __typename?: 'Invoice' }
      & InvoiceItemFieldsFragment
    )> }
  )> }
);

export type GetAdditionalLessonWithClassQueryVariables = {
  classId: Scalars['ObjectId']
};


export type GetAdditionalLessonWithClassQuery = (
  { __typename?: 'Query' }
  & { class: Maybe<(
    { __typename?: 'Class' }
    & { academicYear: (
      { __typename?: 'AcademicYear' }
      & Pick<AcademicYear, 'year'>
      & { id: AcademicYear['_id'] }
    ) }
    & ArrangementClassCardFieldsFragment
  )> }
);

export type GetAdHocAdditionalLessonWithStudentQueryVariables = {
  id: Scalars['ObjectId'],
  filterInput?: Maybe<StudentEnrollmentFilterInput>
};


export type GetAdHocAdditionalLessonWithStudentQuery = (
  { __typename?: 'Query' }
  & { student: Maybe<(
    { __typename?: 'Student' }
    & { enrollments: Maybe<Array<(
      { __typename?: 'Enrollment' }
      & Pick<Enrollment, 'type' | 'startDate'>
      & { id: Enrollment['_id'] }
      & { class: (
        { __typename?: 'Class' }
        & Pick<Class, 'classCode'>
        & { id: Class['_id'] }
      ) }
    )>> }
    & StudentCellFieldsFragment
  )> }
);

export type GetAdHocAdditionalLessonWithClassesQueryVariables = {
  search?: Maybe<Scalars['String']>,
  filterInput?: Maybe<ClassFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetAdHocAdditionalLessonWithClassesQuery = (
  { __typename?: 'Query' }
  & { classes: Maybe<(
    { __typename?: 'ClassPage' }
    & Pick<ClassPage, 'total' | 'pageSize' | 'pageIndex'>
    & { items: Array<(
      { __typename?: 'Class' }
      & Pick<Class, 'classCode'>
      & { id: Class['_id'] }
    )> }
  )> }
);

export type GetAdhocAdditionalLessonWithClassLessonsQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetAdhocAdditionalLessonWithClassLessonsQuery = (
  { __typename?: 'Query' }
  & { class: Maybe<(
    { __typename?: 'Class' }
    & Pick<Class, 'courseId'>
    & { id: Class['_id'] }
    & { lessons: Maybe<Array<(
      { __typename?: 'Lesson' }
      & Pick<Lesson, 'lessonNumber' | 'lessonStart' | 'lessonEnd'>
      & { id: Lesson['_id'] }
    )>> }
  )> }
);

export type GetArrangementFilterOptionsQueryVariables = {};


export type GetArrangementFilterOptionsQuery = (
  { __typename?: 'Query' }
  & { academicYears: Maybe<Array<(
    { __typename?: 'AcademicYear' }
    & Pick<AcademicYear, 'year'>
    & { id: AcademicYear['_id'] }
  )>> }
);

export type GetArrangementModalEnrollmentDataQueryVariables = {
  studentId: Scalars['ObjectId'],
  enrollmentFilterInput?: Maybe<StudentEnrollmentFilterInput>
};


export type GetArrangementModalEnrollmentDataQuery = (
  { __typename?: 'Query' }
  & { student: Maybe<(
    { __typename?: 'Student' }
    & Pick<Student, 'gender' | 'fullName' | 'avatarUrl' | 'userId' | 'alternateUserId'>
    & { id: Student['_id'] }
    & { enrollments: Maybe<Array<(
      { __typename?: 'Enrollment' }
      & Pick<Enrollment, '_id' | 'type' | 'status' | 'startDate' | 'endDate' | 'isFullEnrollment' | 'preferredLearningArrangement' | 'group' | 'isReserved'>
      & { id: Enrollment['_id'] }
      & { arrangements: Maybe<Array<(
        { __typename?: 'Arrangement' }
        & Pick<Arrangement, 'type' | 'status' | 'enrollmentId'>
      )>>, class: (
        { __typename?: 'Class' }
        & Pick<Class, 'name' | 'status' | 'type' | 'learningArrangement'>
        & { id: Class['_id'] }
        & { academicYear: (
          { __typename?: 'AcademicYear' }
          & Pick<AcademicYear, 'year'>
          & { id: AcademicYear['_id'] }
        ), course: (
          { __typename?: 'Course' }
          & Pick<Course, 'nameShort'>
          & { id: Course['_id'] }
        ), term: Maybe<(
          { __typename?: 'Term' }
          & BasicTermFieldsFragment
        )> }
      ) }
    )>> }
  )> }
);

export type GetArrangementModalAttendanceDataQueryVariables = {
  studentId: Scalars['ObjectId'],
  filterInput: StudentAttendanceFilterInput
};


export type GetArrangementModalAttendanceDataQuery = (
  { __typename?: 'Query' }
  & { student: Maybe<(
    { __typename?: 'Student' }
    & { id: Student['_id'] }
    & { attendances: Maybe<Array<(
      { __typename?: 'Attendance' }
      & Pick<Attendance, 'status'>
      & { id: Attendance['_id'] }
      & { linkArrangement: Maybe<(
        { __typename?: 'Arrangement' }
        & Pick<Arrangement, 'type'>
        & { toClass: Maybe<(
          { __typename?: 'Class' }
          & Pick<Class, 'name' | 'shorthand'>
        )> }
      )>, lesson: (
        { __typename?: 'Lesson' }
        & Pick<Lesson, 'lessonStart' | 'lessonEnd' | 'lessonNumber'>
        & { id: Lesson['_id'] }
        & { academicWeek: Maybe<(
          { __typename?: 'AcademicWeek' }
          & Pick<AcademicWeek, 'weekNumber'>
        )> }
      ) }
    )>> }
  )> }
);

export type GetTransferModalAttendanceDataQueryVariables = {
  studentId: Scalars['ObjectId'],
  filterInput: StudentAttendanceFilterInput
};


export type GetTransferModalAttendanceDataQuery = (
  { __typename?: 'Query' }
  & { student: Maybe<(
    { __typename?: 'Student' }
    & { id: Student['_id'] }
    & { attendances: Maybe<Array<(
      { __typename?: 'Attendance' }
      & Pick<Attendance, 'status'>
      & { id: Attendance['_id'] }
      & { lesson: (
        { __typename?: 'Lesson' }
        & Pick<Lesson, 'lessonStart' | 'lessonEnd' | 'lessonNumber'>
        & { id: Lesson['_id'] }
        & { academicWeek: Maybe<(
          { __typename?: 'AcademicWeek' }
          & Pick<AcademicWeek, 'weekNumber'>
        )> }
      ) }
    )>> }
  )> }
);

export type ArrangementEligibilityMutationVariables = {
  input: CreateArrangementInput
};


export type ArrangementEligibilityMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'arrangementEligibility'>
);

export type GetTransferModalEnrollmentDataQueryVariables = {
  studentId: Scalars['ObjectId'],
  enrollmentFilterInput?: Maybe<StudentEnrollmentFilterInput>
};


export type GetTransferModalEnrollmentDataQuery = (
  { __typename?: 'Query' }
  & { student: Maybe<(
    { __typename?: 'Student' }
    & Pick<Student, 'gender' | 'fullName' | 'avatarUrl' | 'userId' | 'alternateUserId'>
    & { id: Student['_id'] }
    & { enrollments: Maybe<Array<(
      { __typename?: 'Enrollment' }
      & Pick<Enrollment, 'type' | 'startDate' | 'endDate' | 'status' | 'preferredLearningArrangement' | 'group' | 'isReserved'>
      & { id: Enrollment['_id'] }
      & { class: (
        { __typename?: 'Class' }
        & Pick<Class, 'name' | 'status' | 'type' | 'learningArrangement'>
        & { id: Class['_id'] }
        & { academicYear: (
          { __typename?: 'AcademicYear' }
          & Pick<AcademicYear, 'year'>
          & { id: AcademicYear['_id'] }
        ), course: (
          { __typename?: 'Course' }
          & Pick<Course, 'nameShort'>
          & { id: Course['_id'] }
        ), lessons: Maybe<Array<(
          { __typename?: 'Lesson' }
          & Pick<Lesson, 'lessonNumber' | 'lessonStart'>
          & { id: Lesson['_id'] }
          & { academicWeek: Maybe<(
            { __typename?: 'AcademicWeek' }
            & Pick<AcademicWeek, 'weekNumber'>
          )> }
        )>> }
      ), arrangements: Maybe<Array<(
        { __typename?: 'Arrangement' }
        & Pick<Arrangement, 'status' | 'type' | 'enrollmentId'>
      )>> }
    )>> }
  )> }
);

export type GetTimerArrangementQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetTimerArrangementQuery = (
  { __typename?: 'Query' }
  & { arrangement: Maybe<(
    { __typename?: 'Arrangement' }
    & Pick<Arrangement, 'createDateTime' | 'type' | 'status'>
    & { id: Arrangement['_id'] }
    & { session: Maybe<(
      { __typename?: 'Session' }
      & Pick<Session, 'expiredAt'>
    )> }
  )> }
);

export type PostIncompleteArrangementSubscriptionVariables = {
  type: ArrangementType
};


export type PostIncompleteArrangementSubscription = (
  { __typename?: 'Subscription' }
  & { postIncompleteArrangement: (
    { __typename?: 'Arrangement' }
    & Pick<Arrangement, 'createDateTime' | 'type' | 'status'>
    & { id: Arrangement['_id'] }
    & { session: Maybe<(
      { __typename?: 'Session' }
      & Pick<Session, 'expiredAt'>
    )> }
  ) }
);

export type GetLessonForArrangementActionQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetLessonForArrangementActionQuery = (
  { __typename?: 'Query' }
  & { lesson: Maybe<(
    { __typename?: 'Lesson' }
    & { attendance: Maybe<Array<(
      { __typename?: 'Attendance' }
      & Pick<Attendance, 'status' | 'type'>
      & { id: Attendance['_id'] }
      & { student: Maybe<(
        { __typename?: 'Student' }
        & { id: Student['_id'] }
      )> }
    )>>, capacity: Maybe<(
      { __typename?: 'LessonCapacityDTO' }
      & Pick<LessonCapacityDto, 'remainingRegularSeat' | 'remainingReplacementSeat'>
    )> }
  )> }
);

export type IsCancelArrangementHasFullLessonQueryVariables = {
  id: Scalars['ObjectId']
};


export type IsCancelArrangementHasFullLessonQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'isCancelArrangementHasFullLesson'>
);

export type ArrangementClassFieldsFragment = (
  { __typename?: 'Class' }
  & Pick<Class, 'name' | 'defaultLessonDays' | 'defaultLessonStart' | 'defaultLessonEnd' | 'learningArrangement'>
  & { id: Class['_id'] }
  & { academicWeeks: Array<(
    { __typename?: 'AcademicWeek' }
    & Pick<AcademicWeek, 'startDate' | 'endDate' | 'weekNumber'>
  )>, lessons: Maybe<Array<(
    { __typename?: 'Lesson' }
    & ArrangementLessonFieldsFragment
  )>>, teacher: Maybe<(
    { __typename?: 'SystemUser' }
    & Pick<SystemUser, 'fullName' | 'salutation' | 'gender' | 'initials'>
    & { id: SystemUser['_id'] }
  )>, course: (
    { __typename?: 'Course' }
    & Pick<Course, 'colorCode'>
    & { courseFee: Maybe<(
      { __typename?: 'CourseFee' }
      & Pick<CourseFee, 'isApplicable'>
      & { standardCourseFees: Array<(
        { __typename?: 'StandardCourseFee' }
        & Pick<StandardCourseFee, 'type' | 'amount'>
      )>, otherFees: Maybe<Array<(
        { __typename?: 'Fee' }
        & Pick<Fee, 'name' | 'amount' | 'isApplicable'>
      )>> }
    )>, makeUpCredits: (
      { __typename?: 'CourseReplacementCredit' }
      & Pick<CourseReplacementCredit, 'term1' | 'term2' | 'term3' | 'term4'>
    ) }
    & CourseShortInfoFragment
  ), academicYear: (
    { __typename?: 'AcademicYear' }
    & BasicAcademicYearFieldsFragment
  ), term: Maybe<(
    { __typename?: 'Term' }
    & { term1Period: (
      { __typename?: 'DateTimeRange' }
      & Pick<DateTimeRange, 'start' | 'end'>
    ), term2Period: (
      { __typename?: 'DateTimeRange' }
      & Pick<DateTimeRange, 'start' | 'end'>
    ), term3Period: (
      { __typename?: 'DateTimeRange' }
      & Pick<DateTimeRange, 'start' | 'end'>
    ), term4Period: (
      { __typename?: 'DateTimeRange' }
      & Pick<DateTimeRange, 'start' | 'end'>
    ) }
  )>, venue: Maybe<(
    { __typename?: 'Venue' }
    & BasicVenueFieldsFragment
  )>, capacity: Maybe<(
    { __typename?: 'ClassCapacityDTO' }
    & CapacityFieldsFragment
  )> }
);

export type ArrangementLessonFieldsFragment = (
  { __typename?: 'Lesson' }
  & Pick<Lesson, 'lessonDay' | 'lessonStart' | 'lessonEnd' | 'lessonNumber' | 'maximumClassCapacity'>
  & { id: Lesson['_id'] }
  & { academicWeek: Maybe<(
    { __typename?: 'AcademicWeek' }
    & Pick<AcademicWeek, 'weekNumber' | 'startDate' | 'endDate'>
  )>, attendance: Maybe<Array<(
    { __typename?: 'Attendance' }
    & Pick<Attendance, 'status' | 'type'>
    & { student: Maybe<(
      { __typename?: 'Student' }
      & Pick<Student, 'fullName'>
      & { id: Student['_id'] }
    )> }
  )>>, holidays: Maybe<Array<(
    { __typename?: 'Holiday' }
    & Pick<Holiday, 'name'>
    & { period: (
      { __typename?: 'DateRange' }
      & Pick<DateRange, 'start'>
    ) }
  )>>, class: Maybe<(
    { __typename?: 'Class' }
    & Pick<Class, 'name'>
    & { id: Class['_id'] }
    & { academicYear: (
      { __typename?: 'AcademicYear' }
      & Pick<AcademicYear, 'year'>
    ), course: (
      { __typename?: 'Course' }
      & Pick<Course, 'colorCode'>
    ), teacher: Maybe<(
      { __typename?: 'SystemUser' }
      & Pick<SystemUser, 'fullName'>
      & { id: SystemUser['_id'] }
    )> }
  )> }
);

export type ListArrangementFieldsFragment = (
  { __typename?: 'Arrangement' }
  & Pick<Arrangement, 'type' | 'status' | 'remarks' | 'useCredits' | 'createDateTime' | 'isCancelHasFullLesson' | 'lastModifiedDateTime'>
  & { id: Arrangement['_id'] }
  & { student: (
    { __typename?: 'Student' }
    & Pick<Student, 'gender' | 'fullName' | 'avatarUrl' | 'userId' | 'alternateUserId'>
    & { id: Student['_id'] }
    & { primarySchool: Maybe<(
      { __typename?: 'School' }
      & Pick<School, 'name'>
      & { id: School['_id'] }
    )>, primaryParent: Maybe<(
      { __typename?: 'Parent' }
      & { mobile: (
        { __typename?: 'Telephone' }
        & TelephoneFieldsFragment
      ) }
      & BasicParentFieldsFragment
    )> }
  ), fromClass: Maybe<(
    { __typename?: 'Class' }
    & ArrangementClassFieldsFragment
  )>, fromLesson: Maybe<(
    { __typename?: 'Lesson' }
    & ArrangementLessonFieldsFragment
  )>, toClass: Maybe<(
    { __typename?: 'Class' }
    & ArrangementClassFieldsFragment
  )>, toLesson: Maybe<(
    { __typename?: 'Lesson' }
    & ArrangementLessonFieldsFragment
  )>, invoice: Maybe<(
    { __typename?: 'Invoice' }
    & InvoiceFieldsFragment
  )>, originInvoice: Maybe<(
    { __typename?: 'Invoice' }
    & InvoiceFieldsFragment
  )>, createdBy: Maybe<(
    { __typename?: 'SystemUser' }
    & CreateByFieldsFragment
  )>, lastModifiedBy: Maybe<(
    { __typename?: 'SystemUser' }
    & CreateByFieldsFragment
  )>, reminders: Maybe<Array<(
    { __typename?: 'Reminder' }
    & Pick<Reminder, 'sendStatus' | 'sendDate' | 'type'>
    & { whatsappMessage: Maybe<(
      { __typename?: 'WhatsappMessage' }
      & Pick<WhatsappMessage, 'status' | 'sentDate'>
    )> }
  )>> }
);

export type ArrangementCsvQueryVariables = {
  search?: Maybe<Scalars['String']>,
  filterInput?: Maybe<ArrangementFilterInput>
};


export type ArrangementCsvQuery = (
  { __typename?: 'Query' }
  & { csv: Maybe<(
    { __typename?: 'Csv' }
    & Pick<Csv, 'url'>
  )> }
);

export type CreateArrangementMutationVariables = {
  input: CreateArrangementInput
};


export type CreateArrangementMutation = (
  { __typename?: 'Mutation' }
  & { createArrangement: Maybe<(
    { __typename?: 'Arrangement' }
    & { id: Arrangement['_id'] }
  )> }
);

export type IncrementStepMutationVariables = {
  arrangementId: Scalars['ObjectId'],
  step?: Maybe<Scalars['Int']>
};


export type IncrementStepMutation = (
  { __typename?: 'Mutation' }
  & { incrementStep: Maybe<(
    { __typename?: 'Arrangement' }
    & { id: Arrangement['_id'] }
  )> }
);

export type CancelArrangementMutationVariables = {
  id: Scalars['ObjectId']
};


export type CancelArrangementMutation = (
  { __typename?: 'Mutation' }
  & { cancelArrangement: Maybe<(
    { __typename?: 'Arrangement' }
    & Pick<Arrangement, 'status'>
    & { id: Arrangement['_id'] }
  )> }
);

export type ProcessArrangementMutationVariables = {
  id: Scalars['ObjectId']
};


export type ProcessArrangementMutation = (
  { __typename?: 'Mutation' }
  & { processArrangement: Maybe<(
    { __typename?: 'Arrangement' }
    & { id: Arrangement['_id'] }
  )> }
);

export type CreateBillingForTransferMutationVariables = {
  id: Scalars['ObjectId']
};


export type CreateBillingForTransferMutation = (
  { __typename?: 'Mutation' }
  & { createBillingForTransfer: Maybe<(
    { __typename?: 'Arrangement' }
    & { id: Arrangement['_id'] }
  )> }
);

export type ExtendsArrangementTimeMutationVariables = {
  id: Scalars['ObjectId']
};


export type ExtendsArrangementTimeMutation = (
  { __typename?: 'Mutation' }
  & { extendsArrangementTime: Maybe<(
    { __typename?: 'Arrangement' }
    & Pick<Arrangement, 'createDateTime'>
    & { id: Arrangement['_id'] }
  )> }
);

export type ChangeAttendingClassForTransferMutationVariables = {
  input: ChangeAttendingClassForTransferInput
};


export type ChangeAttendingClassForTransferMutation = (
  { __typename?: 'Mutation' }
  & { changeAttendingClassForTransfer: Maybe<(
    { __typename?: 'Arrangement' }
    & CheckTransferFieldsFragment
  )> }
);

export type CancelChangeAttendingClassForTransferMutationVariables = {
  input: ChangeAttendingClassForTransferInput
};


export type CancelChangeAttendingClassForTransferMutation = (
  { __typename?: 'Mutation' }
  & { cancelChangeAttendingClassForTransfer: Maybe<(
    { __typename?: 'Arrangement' }
    & CheckTransferFieldsFragment
  )> }
);

export type ListArrangementIndexFieldsFragment = (
  { __typename?: 'ArrangementIndex' }
  & Pick<ArrangementIndex, 'name' | 'type' | 'status' | 'waStatus' | 'emailStatus' | 'lastModifiedByFullName' | 'lastModifiedDateTime' | 'isAdhocAdditionalLesson'>
  & { id: ArrangementIndex['_id'] }
  & { student: Maybe<(
    { __typename?: 'ArrangementStudentIndex' }
    & Pick<ArrangementStudentIndex, 'fullName' | 'gender' | 'avatarUrl' | 'userId'>
    & { id: ArrangementStudentIndex['_id'] }
  )>, fromClass: Maybe<(
    { __typename?: 'ArrangementClassIndex' }
    & Pick<ArrangementClassIndex, 'name'>
    & { id: ArrangementClassIndex['_id'] }
  )>, fromClassTeacher: Maybe<(
    { __typename?: 'ArrangementTeacherIndex' }
    & Pick<ArrangementTeacherIndex, 'fullName' | 'salutation' | 'gender' | 'avatarUrl'>
    & { id: ArrangementTeacherIndex['_id'] }
  )>, fromLesson: Maybe<(
    { __typename?: 'ArrangementLessonIndex' }
    & Pick<ArrangementLessonIndex, 'lessonStart' | 'lessonEnd'>
    & { id: ArrangementLessonIndex['_id'] }
  )>, toClass: Maybe<(
    { __typename?: 'ArrangementClassIndex' }
    & Pick<ArrangementClassIndex, 'name'>
    & { id: ArrangementClassIndex['_id'] }
  )>, toClassTeacher: Maybe<(
    { __typename?: 'ArrangementTeacherIndex' }
    & Pick<ArrangementTeacherIndex, 'fullName' | 'salutation' | 'gender' | 'avatarUrl'>
    & { id: ArrangementTeacherIndex['_id'] }
  )>, toLesson: Maybe<(
    { __typename?: 'ArrangementLessonIndex' }
    & Pick<ArrangementLessonIndex, 'lessonStart' | 'lessonEnd'>
    & { id: ArrangementLessonIndex['_id'] }
  )>, academicYear: Maybe<(
    { __typename?: 'ArrangementAcademicYearIndex' }
    & Pick<ArrangementAcademicYearIndex, 'year'>
    & { id: ArrangementAcademicYearIndex['_id'] }
  )>, course: Maybe<(
    { __typename?: 'ArrangementCourseIndex' }
    & Pick<ArrangementCourseIndex, 'nameShort'>
    & { id: ArrangementCourseIndex['_id'] }
  )>, effectedHolidays: Maybe<Array<(
    { __typename?: 'ArrangementHolidayIndex' }
    & Pick<ArrangementHolidayIndex, 'name'>
    & { id: ArrangementHolidayIndex['_id'] }
  )>>, invoice: Maybe<(
    { __typename?: 'ArrangementInvoiceIndex' }
    & Pick<ArrangementInvoiceIndex, 'status'>
    & { id: ArrangementInvoiceIndex['_id'] }
  )> }
);

export type GetListArrangementDataQueryVariables = {
  filterInput?: Maybe<ArrangementFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetListArrangementDataQuery = (
  { __typename?: 'Query' }
  & { arrangementsWithIndex: Maybe<(
    { __typename?: 'ArrangementIndexPage' }
    & Pick<ArrangementIndexPage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'ArrangementIndex' }
      & ListArrangementIndexFieldsFragment
    )> }
  )> }
);

export type GetArrangementIndexQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetArrangementIndexQuery = (
  { __typename?: 'Query' }
  & { arrangementWithIndex: Maybe<(
    { __typename?: 'ArrangementIndex' }
    & ListArrangementIndexFieldsFragment
  )> }
);

export type GetIncompleteArrangementQueryVariables = {};


export type GetIncompleteArrangementQuery = (
  { __typename?: 'Query' }
  & { sessions: Maybe<Array<(
    { __typename?: 'Session' }
    & Pick<Session, 'expiredAt'>
    & { arrangement: Maybe<(
      { __typename?: 'Arrangement' }
      & Pick<Arrangement, 'type' | 'name' | 'currentStep'>
      & { id: Arrangement['_id'] }
      & { student: (
        { __typename?: 'Student' }
        & Pick<Student, 'fullName'>
        & { id: Student['_id'] }
      ) }
    )> }
  )>> }
);

export type GetWithdrawDetailQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetWithdrawDetailQuery = (
  { __typename?: 'Query' }
  & { arrangement: Maybe<(
    { __typename?: 'Arrangement' }
    & Pick<Arrangement, 'name' | 'type' | 'status' | 'waStatus' | 'emailStatus' | 'lastModifiedDateTime' | 'isCancelHasFullLesson'>
    & { id: Arrangement['_id'] }
    & { student: (
      { __typename?: 'Student' }
      & Pick<Student, 'fullName' | 'userId'>
    ), reminders: Maybe<Array<(
      { __typename?: 'Reminder' }
      & Pick<Reminder, 'type' | 'sendDate' | 'sendgridEmailStatus' | 'message' | 'subject'>
      & { id: Reminder['_id'] }
      & { whatsappMessage: Maybe<(
        { __typename?: 'WhatsappMessage' }
        & Pick<WhatsappMessage, 'status'>
      )> }
    )>>, enrollment: Maybe<(
      { __typename?: 'Enrollment' }
      & Pick<Enrollment, 'preferredLearningArrangement' | 'group'>
    )>, createdBy: Maybe<(
      { __typename?: 'SystemUser' }
      & { id: SystemUser['_id'] }
    )>, lastModifiedBy: Maybe<(
      { __typename?: 'SystemUser' }
      & LastModifiedByFieldsFragment
    )> }
    & ArrangementBillingFieldsFragment
  )> }
);

export type TermFieldsFragment = (
  { __typename?: 'Term' }
  & { term1Period: (
    { __typename?: 'DateTimeRange' }
    & Pick<DateTimeRange, 'start' | 'end'>
  ), term2Period: (
    { __typename?: 'DateTimeRange' }
    & Pick<DateTimeRange, 'start' | 'end'>
  ), term3Period: (
    { __typename?: 'DateTimeRange' }
    & Pick<DateTimeRange, 'start' | 'end'>
  ), term4Period: (
    { __typename?: 'DateTimeRange' }
    & Pick<DateTimeRange, 'start' | 'end'>
  ) }
);

export type GetWithdrawBillingQueryVariables = {
  enrollmentId: Scalars['ObjectId'],
  lessonId: Scalars['ObjectId']
};


export type GetWithdrawBillingQuery = (
  { __typename?: 'Query' }
  & { enrollment: Maybe<(
    { __typename?: 'Enrollment' }
    & Pick<Enrollment, 'preferredLearningArrangement' | 'group'>
    & { id: Enrollment['_id'] }
    & { class: (
      { __typename?: 'Class' }
      & { term: Maybe<(
        { __typename?: 'Term' }
        & TermFieldsFragment
      )>, course: (
        { __typename?: 'Course' }
        & Pick<Course, 'name' | 'avatarUrl'>
      ), teacher: Maybe<(
        { __typename?: 'SystemUser' }
        & TeacherCellFieldsFragment
      )>, venue: Maybe<(
        { __typename?: 'Venue' }
        & BasicVenueFieldsFragment
      )> }
      & ArrangementClassCardFieldsFragment
    ), invoice: Maybe<(
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'total' | 'referenceNumber' | 'amountPaid'>
      & { id: Invoice['_id'] }
      & { arrangementBillingCheck: Maybe<(
        { __typename?: 'ArrangementBillingCheckResult' }
        & Pick<ArrangementBillingCheckResult, 'amountUsed' | 'remainingFee' | 'updatedLessonFee' | 'feeDifference'>
        & { lessonsNeedToPay: Array<(
          { __typename?: 'Lesson' }
          & Pick<Lesson, 'lessonNumber' | 'lessonStart'>
          & { id: Lesson['_id'] }
        )>, lessonsNeedNotToPay: Array<(
          { __typename?: 'Lesson' }
          & Pick<Lesson, 'lessonNumber' | 'lessonStart'>
          & { id: Lesson['_id'] }
        )> }
      )>, lessons: Maybe<Array<(
        { __typename?: 'Lesson' }
        & Pick<Lesson, 'lessonNumber' | 'lessonStart' | 'lessonEnd'>
        & { id: Lesson['_id'] }
      )>> }
    )>, student: Maybe<(
      { __typename?: 'Student' }
      & Pick<Student, 'fullName' | 'userId' | 'alternateUserId'>
      & { id: Student['_id'] }
      & { primaryParent: Maybe<(
        { __typename?: 'Parent' }
        & Pick<Parent, 'fullName' | 'gender' | 'email'>
        & { id: Parent['_id'] }
        & { mobile: (
          { __typename?: 'Telephone' }
          & Pick<Telephone, 'number' | 'countryCode'>
        ) }
      )>, primarySchool: Maybe<(
        { __typename?: 'School' }
        & Pick<School, 'name'>
      )> }
    )>, attendances: Maybe<Array<(
      { __typename?: 'Attendance' }
      & { lesson: (
        { __typename?: 'Lesson' }
        & Pick<Lesson, 'lessonStart' | 'lessonEnd' | 'lessonNumber'>
        & { id: Lesson['_id'] }
      ) }
    )>>, arrangements: Maybe<Array<(
      { __typename?: 'Arrangement' }
      & Pick<Arrangement, 'name' | 'type' | 'status'>
      & { id: Arrangement['_id'] }
      & { fromLesson: Maybe<(
        { __typename?: 'Lesson' }
        & Pick<Lesson, 'lessonStart' | 'lessonNumber'>
      )>, toLesson: Maybe<(
        { __typename?: 'Lesson' }
        & Pick<Lesson, 'lessonStart' | 'lessonNumber'>
      )> }
    )>> }
  )> }
);

export type GetWithdrawConfirmQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetWithdrawConfirmQuery = (
  { __typename?: 'Query' }
  & { arrangement: Maybe<(
    { __typename?: 'Arrangement' }
    & { id: Arrangement['_id'] }
    & { fromClass: Maybe<(
      { __typename?: 'Class' }
      & ArrangementClassCardFieldsFragment
    )>, fromLesson: Maybe<(
      { __typename?: 'Lesson' }
      & Pick<Lesson, 'lessonStart' | 'lessonEnd'>
    )>, student: (
      { __typename?: 'Student' }
      & { primaryParent: Maybe<(
        { __typename?: 'Parent' }
        & { id: Parent['_id'] }
      )> }
      & StudentCellFieldsFragment
    ), originInvoice: Maybe<(
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'amountPaid'>
    )>, cancellation: Maybe<Array<(
      { __typename?: 'Arrangement' }
      & Pick<Arrangement, 'type' | 'name'>
      & { id: Arrangement['_id'] }
      & { fromLesson: Maybe<(
        { __typename?: 'Lesson' }
        & Pick<Lesson, 'lessonStart' | 'lessonNumber'>
      )>, toLesson: Maybe<(
        { __typename?: 'Lesson' }
        & Pick<Lesson, 'lessonStart' | 'lessonNumber'>
      )> }
    )>> }
  )> }
);

export type UpdateEnrollmentEndRecurringMutationVariables = {
  id: Scalars['ObjectId'],
  isEndRecurring: Scalars['Boolean']
};


export type UpdateEnrollmentEndRecurringMutation = (
  { __typename?: 'Mutation' }
  & { updateEnrollmentEndRecurring: Maybe<(
    { __typename?: 'Enrollment' }
    & Pick<Enrollment, '_id'>
  )> }
);

export type GetFindMakeUpLessonsQueryVariables = {
  filterInput?: Maybe<LessonFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetFindMakeUpLessonsQuery = (
  { __typename?: 'Query' }
  & { lessons: Maybe<(
    { __typename?: 'LessonPage' }
    & Pick<LessonPage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'Lesson' }
      & Pick<Lesson, 'lessonDay' | 'lessonNumber' | 'lessonStart' | 'lessonEnd' | 'maximumClassCapacity'>
      & { id: Lesson['_id'] }
      & { academicWeek: Maybe<(
        { __typename?: 'AcademicWeek' }
        & Pick<AcademicWeek, 'weekNumber'>
      )>, capacity: Maybe<(
        { __typename?: 'LessonCapacityDTO' }
        & Pick<LessonCapacityDto, 'remainingRegularSeat' | 'remainingReplacementSeat'>
      )>, capacityNumber: Maybe<(
        { __typename?: 'LessonCapacityNumberDTO' }
        & Pick<LessonCapacityNumberDto, 'regular' | 'replacement' | 'used'>
      )>, class: Maybe<(
        { __typename?: 'Class' }
        & Pick<Class, 'name' | 'type' | 'runNumber' | 'learningArrangement' | 'maximumClassCapacity' | 'maximumMakeUpCapacity' | 'defaultLessonDays' | 'isAdHoc'>
        & { id: Class['_id'] }
        & { academicYear: (
          { __typename?: 'AcademicYear' }
          & Pick<AcademicYear, 'year'>
          & { holidays: Maybe<Array<(
            { __typename?: 'Holiday' }
            & Pick<Holiday, 'name' | 'type'>
            & { id: Holiday['_id'] }
            & { period: (
              { __typename?: 'DateRange' }
              & Pick<DateRange, 'start' | 'end'>
            ) }
          )>> }
        ), course: (
          { __typename?: 'Course' }
          & Pick<Course, 'nameShort' | 'colorCode'>
          & { id: Course['_id'] }
        ) }
      )>, teacher: Maybe<(
        { __typename?: 'SystemUser' }
        & TeacherCellFieldsFragment
      )>, venue: Maybe<(
        { __typename?: 'Venue' }
        & BasicVenueFieldsFragment
      )> }
    )> }
  )> }
);

export type GetArrangementClassCardQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetArrangementClassCardQuery = (
  { __typename?: 'Query' }
  & { class: Maybe<(
    { __typename?: 'Class' }
    & ArrangementClassCardFieldsFragment
  )> }
);

export type GetFromLessonOnFindMakeupLessonsQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetFromLessonOnFindMakeupLessonsQuery = (
  { __typename?: 'Query' }
  & { lesson: Maybe<(
    { __typename?: 'Lesson' }
    & Pick<Lesson, 'lessonStart' | 'lessonNumber' | 'lessonEnd'>
    & { id: Lesson['_id'] }
    & { course: (
      { __typename?: 'Course' }
      & Pick<Course, 'nameShort' | 'subject'>
    ), attendance: Maybe<Array<(
      { __typename?: 'Attendance' }
      & Pick<Attendance, 'status'>
      & { id: Attendance['_id'] }
      & { student: Maybe<(
        { __typename?: 'Student' }
        & { id: Student['_id'] }
      )> }
    )>> }
  )> }
);

export type MakeUpClassFieldFragment = (
  { __typename?: 'Class' }
  & { course: (
    { __typename?: 'Course' }
    & Pick<Course, 'name' | 'type'>
    & { id: Course['_id'] }
    & { makeUpCredits: (
      { __typename?: 'CourseReplacementCredit' }
      & Pick<CourseReplacementCredit, 'term1' | 'term2' | 'term3' | 'term4'>
    ) }
  ), academicYear: (
    { __typename?: 'AcademicYear' }
    & Pick<AcademicYear, 'year'>
  ) }
  & ArrangementClassCardFieldsFragment
);

export type MakeUpCardFieldsFragment = (
  { __typename?: 'Arrangement' }
  & { fromClass: Maybe<(
    { __typename?: 'Class' }
    & ArrangementClassCardFieldsFragment
  )>, fromLesson: Maybe<(
    { __typename?: 'Lesson' }
    & Pick<Lesson, 'lessonStart' | 'lessonEnd' | 'lessonNumber'>
    & { id: Lesson['_id'] }
  )>, toClass: Maybe<(
    { __typename?: 'Class' }
    & ArrangementClassCardFieldsFragment
  )>, toLesson: Maybe<(
    { __typename?: 'Lesson' }
    & Pick<Lesson, 'lessonStart' | 'lessonEnd' | 'lessonNumber'>
    & { id: Lesson['_id'] }
  )> }
);

export type GetMakeUpCardArrangementQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetMakeUpCardArrangementQuery = (
  { __typename?: 'Query' }
  & { arrangement: Maybe<(
    { __typename?: 'Arrangement' }
    & Pick<Arrangement, 'type'>
    & { id: Arrangement['_id'] }
    & { student: (
      { __typename?: 'Student' }
      & { primaryParent: Maybe<(
        { __typename?: 'Parent' }
        & { id: Parent['_id'] }
      )> }
      & StudentCellFieldsFragment
    ) }
    & MakeUpCardFieldsFragment
  )> }
);

export type MakeUpCreditInfoFragmentFragment = (
  { __typename?: 'StudentReplacementCreditDTO' }
  & { currentTerm: Maybe<(
    { __typename?: 'StudentReplacementCreditDetailDTO' }
    & Pick<StudentReplacementCreditDetailDto, 'termNumber' | 'used' | 'exceptions' | 'total'>
  )>, term1: Maybe<(
    { __typename?: 'StudentReplacementCreditDetailDTO' }
    & Pick<StudentReplacementCreditDetailDto, 'used' | 'exceptions' | 'total'>
  )>, term2: Maybe<(
    { __typename?: 'StudentReplacementCreditDetailDTO' }
    & Pick<StudentReplacementCreditDetailDto, 'used' | 'exceptions' | 'total'>
  )>, term3: Maybe<(
    { __typename?: 'StudentReplacementCreditDetailDTO' }
    & Pick<StudentReplacementCreditDetailDto, 'used' | 'exceptions' | 'total'>
  )>, term4: Maybe<(
    { __typename?: 'StudentReplacementCreditDetailDTO' }
    & Pick<StudentReplacementCreditDetailDto, 'used' | 'exceptions' | 'total'>
  )>, workshop: Maybe<(
    { __typename?: 'StudentReplacementCreditDetailDTO' }
    & Pick<StudentReplacementCreditDetailDto, 'used' | 'exceptions' | 'total'>
  )> }
);

export type MakeUpCheckLessonFieldFragment = (
  { __typename?: 'Lesson' }
  & Pick<Lesson, 'lessonDay' | 'lessonStart' | 'lessonEnd' | 'lessonNumber' | 'maximumClassCapacity'>
  & { id: Lesson['_id'] }
);

export type MakeUpCheckClassFieldFragment = (
  { __typename?: 'Class' }
  & Pick<Class, 'shorthand' | 'learningArrangement'>
  & { course: (
    { __typename?: 'Course' }
    & Pick<Course, 'nameShort' | 'colorCode'>
    & { makeUpCredits: (
      { __typename?: 'CourseReplacementCredit' }
      & Pick<CourseReplacementCredit, 'term1' | 'term2' | 'term3' | 'term4'>
    ) }
  ) }
  & ArrangementClassCardFieldsFragment
);

export type GetMakeUpCheckStudentAndClassQueryVariables = {
  id: Scalars['ObjectId'],
  classId: Scalars['ObjectId'],
  lessonId: Scalars['ObjectId']
};


export type GetMakeUpCheckStudentAndClassQuery = (
  { __typename?: 'Query' }
  & { student: Maybe<(
    { __typename?: 'Student' }
    & Pick<Student, 'fullName' | 'gender' | 'avatarUrl' | 'userId' | 'alternateUserId'>
    & { id: Student['_id'] }
    & { primaryParent: Maybe<(
      { __typename?: 'Parent' }
      & { id: Parent['_id'] }
    )>, primarySchool: Maybe<(
      { __typename?: 'School' }
      & Pick<School, 'name'>
      & { id: School['_id'] }
    )>, secondarySchool: Maybe<(
      { __typename?: 'School' }
      & Pick<School, 'name'>
      & { id: School['_id'] }
    )>, academicLevel: Maybe<(
      { __typename?: 'AcademicLevel' }
      & Pick<AcademicLevel, 'educationStage'>
      & { id: AcademicLevel['_id'] }
    )>, replacementCredit: Maybe<(
      { __typename?: 'StudentReplacementCreditDTO' }
      & MakeUpCreditInfoFragmentFragment
    )> }
  )>, class: Maybe<(
    { __typename?: 'Class' }
    & { lessons: Maybe<Array<(
      { __typename?: 'Lesson' }
      & MakeUpCheckLessonFieldFragment
    )>> }
    & ArrangementClassCardFieldsFragment
  )> }
);

export type GetMakeUpCheckArrangementsQueryVariables = {
  search?: Maybe<Scalars['String']>,
  filterInput?: Maybe<ArrangementFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>,
  lessonId: Scalars['ObjectId']
};


export type GetMakeUpCheckArrangementsQuery = (
  { __typename?: 'Query' }
  & { arrangements: Maybe<(
    { __typename?: 'ArrangementPage' }
    & Pick<ArrangementPage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'Arrangement' }
      & Pick<Arrangement, 'type' | 'status' | 'currentTermNumber' | 'remarks' | 'useCredits'>
      & { id: Arrangement['_id'] }
      & { student: (
        { __typename?: 'Student' }
        & Pick<Student, 'fullName' | 'avatarUrl' | 'gender' | 'userId' | 'alternateUserId'>
        & { id: Student['_id'] }
        & { replacementCredit: Maybe<(
          { __typename?: 'StudentReplacementCreditDTO' }
          & MakeUpCreditInfoFragmentFragment
        )> }
      ), fromClass: Maybe<(
        { __typename?: 'Class' }
        & Pick<Class, 'learningArrangement'>
        & MakeUpCheckClassFieldFragment
      )>, fromLesson: Maybe<(
        { __typename?: 'Lesson' }
        & MakeUpCheckLessonFieldFragment
      )>, toClass: Maybe<(
        { __typename?: 'Class' }
        & Pick<Class, 'learningArrangement'>
        & MakeUpCheckClassFieldFragment
      )>, toLesson: Maybe<(
        { __typename?: 'Lesson' }
        & MakeUpCheckLessonFieldFragment
      )> }
    )> }
  )> }
);

export type GetMakeUpDetailQueryVariables = {
  id: Scalars['ObjectId'],
  lessonId: Scalars['ObjectId']
};


export type GetMakeUpDetailQuery = (
  { __typename?: 'Query' }
  & { arrangement: Maybe<(
    { __typename?: 'Arrangement' }
    & Pick<Arrangement, 'name' | 'type' | 'status' | 'emailStatus' | 'waStatus' | 'remarks' | 'isCancelHasFullLesson' | 'lastModifiedDateTime' | 'createDateTime'>
    & { id: Arrangement['_id'] }
    & { fromClass: Maybe<(
      { __typename?: 'Class' }
      & ArrangementClassCardFieldsFragment
    )>, fromLesson: Maybe<(
      { __typename?: 'Lesson' }
      & Pick<Lesson, 'lessonStart' | 'lessonEnd' | 'lessonNumber'>
      & { id: Lesson['_id'] }
      & { capacity: Maybe<(
        { __typename?: 'LessonCapacityDTO' }
        & Pick<LessonCapacityDto, 'remainingRegularSeat' | 'remainingReplacementSeat'>
      )> }
    )>, toClass: Maybe<(
      { __typename?: 'Class' }
      & ArrangementClassCardFieldsFragment
    )>, toLesson: Maybe<(
      { __typename?: 'Lesson' }
      & Pick<Lesson, 'lessonStart' | 'lessonEnd' | 'lessonNumber'>
      & { id: Lesson['_id'] }
    )>, student: (
      { __typename?: 'Student' }
      & Pick<Student, 'fullName' | 'alternateUserId' | 'userId'>
      & { replacementCredit: Maybe<(
        { __typename?: 'StudentReplacementCreditDTO' }
        & MakeUpCreditInfoFragmentFragment
      )> }
    ), reminders: Maybe<Array<(
      { __typename?: 'Reminder' }
      & Pick<Reminder, 'type' | 'sendDate' | 'sendgridEmailStatus' | 'message' | 'subject'>
      & { id: Reminder['_id'] }
      & { whatsappMessage: Maybe<(
        { __typename?: 'WhatsappMessage' }
        & Pick<WhatsappMessage, 'status'>
      )> }
    )>>, lastModifiedBy: Maybe<(
      { __typename?: 'SystemUser' }
      & LastModifiedByFieldsFragment
    )> }
  )> }
);

export type GetStudentMakeupCreditsQueryVariables = {
  studentId: Scalars['ObjectId'],
  lessonId: Scalars['ObjectId']
};


export type GetStudentMakeupCreditsQuery = (
  { __typename?: 'Query' }
  & { student: Maybe<(
    { __typename?: 'Student' }
    & Pick<Student, '_id'>
    & { replacementCredit: Maybe<(
      { __typename?: 'StudentReplacementCreditDTO' }
      & { currentTerm: Maybe<(
        { __typename?: 'StudentReplacementCreditDetailDTO' }
        & Pick<StudentReplacementCreditDetailDto, 'used' | 'exceptions' | 'total' | 'termNumber'>
      )>, workshop: Maybe<(
        { __typename?: 'StudentReplacementCreditDetailDTO' }
        & Pick<StudentReplacementCreditDetailDto, 'used' | 'exceptions' | 'total'>
      )> }
    )> }
  )> }
);

export type CheckTransferFieldsFragment = (
  { __typename?: 'Arrangement' }
  & Pick<Arrangement, 'currentStep' | 'type' | 'status'>
  & { id: Arrangement['_id'] }
  & { fromClass: Maybe<(
    { __typename?: 'Class' }
    & ArrangementClassCardFieldsFragment
  )>, fromLesson: Maybe<(
    { __typename?: 'Lesson' }
    & Pick<Lesson, 'lessonNumber' | 'lessonStart' | 'lessonEnd'>
    & { id: Lesson['_id'] }
  )>, toClass: Maybe<(
    { __typename?: 'Class' }
    & Pick<Class, 'name' | 'type' | 'maximumMakeUpCapacity' | 'maximumClassCapacity' | 'learningArrangement'>
    & { id: Class['_id'] }
    & { term: Maybe<(
      { __typename?: 'Term' }
      & BasicTermFieldsFragment
    )> }
    & ArrangementClassCardFieldsFragment
  )>, selectedLessons: Maybe<Array<(
    { __typename?: 'SelectedLesson' }
    & { lesson: (
      { __typename?: 'Lesson' }
      & Pick<Lesson, 'lessonStart' | 'lessonEnd' | 'lessonNumber' | 'lessonDay'>
      & { id: Lesson['_id'] }
      & { academicWeek: Maybe<(
        { __typename?: 'AcademicWeek' }
        & Pick<AcademicWeek, 'weekNumber' | 'startDate' | 'endDate'>
      )>, capacity: Maybe<(
        { __typename?: 'LessonCapacityDTO' }
        & LessonCapacityFieldsFragment
      )>, class: Maybe<(
        { __typename?: 'Class' }
        & Pick<Class, 'shorthand'>
      )> }
    ), makeUpLesson: Maybe<(
      { __typename?: 'Lesson' }
      & Pick<Lesson, 'lessonStart' | 'lessonEnd' | 'lessonNumber' | 'lessonDay'>
      & { id: Lesson['_id'] }
      & { academicWeek: Maybe<(
        { __typename?: 'AcademicWeek' }
        & Pick<AcademicWeek, 'weekNumber' | 'startDate' | 'endDate'>
      )>, capacity: Maybe<(
        { __typename?: 'LessonCapacityDTO' }
        & LessonCapacityFieldsFragment
      )>, class: Maybe<(
        { __typename?: 'Class' }
        & Pick<Class, 'shorthand'>
      )> }
    )> }
  )>>, enrollment: Maybe<(
    { __typename?: 'Enrollment' }
    & Pick<Enrollment, 'preferredLearningArrangement' | 'group'>
  )>, newEnrollment: Maybe<(
    { __typename?: 'Enrollment' }
    & Pick<Enrollment, 'preferredLearningArrangement' | 'group'>
  )>, toLesson: Maybe<(
    { __typename?: 'Lesson' }
    & Pick<Lesson, 'lessonNumber' | 'lessonStart' | 'lessonEnd'>
    & { id: Lesson['_id'] }
  )>, student: (
    { __typename?: 'Student' }
    & StudentCellFieldsFragment
  ) }
);

export type GetCheckTransferQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetCheckTransferQuery = (
  { __typename?: 'Query' }
  & { arrangement: Maybe<(
    { __typename?: 'Arrangement' }
    & CheckTransferFieldsFragment
  )> }
);

export type GetFindTransferClassesQueryVariables = {
  search?: Maybe<Scalars['String']>,
  filterInput?: Maybe<ClassFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>,
  skipHoliday?: Maybe<Scalars['Boolean']>
};


export type GetFindTransferClassesQuery = (
  { __typename?: 'Query' }
  & { classes: Maybe<(
    { __typename?: 'ClassPage' }
    & Pick<ClassPage, 'total' | 'pageSize' | 'pageIndex'>
    & { items: Array<(
      { __typename?: 'Class' }
      & Pick<Class, 'name' | 'type' | 'learningArrangement' | 'defaultLessonStart' | 'defaultLessonEnd' | 'defaultLessonDays'>
      & { id: Class['_id'] }
      & { dailySessionTimeSlots: Maybe<Array<(
        { __typename?: 'TimeRange' }
        & Pick<TimeRange, 'start' | 'end'>
      )>>, course: (
        { __typename?: 'Course' }
        & Pick<Course, 'name' | 'colorCode'>
        & { id: Course['_id'] }
      ), academicYear: (
        { __typename?: 'AcademicYear' }
        & Pick<AcademicYear, 'year'>
      ), capacity: Maybe<(
        { __typename?: 'ClassCapacityDTO' }
        & CapacityFieldsFragment
      )>, teacher: Maybe<(
        { __typename?: 'SystemUser' }
        & Pick<SystemUser, 'fullName' | 'avatarUrl'>
        & { id: SystemUser['_id'] }
      )>, venue: Maybe<(
        { __typename?: 'Venue' }
        & Pick<Venue, 'shorthand' | 'unitNo'>
        & { branch: Maybe<(
          { __typename?: 'Branch' }
          & Pick<Branch, 'name' | 'alias'>
        )> }
      )>, enrollments: Maybe<Array<(
        { __typename?: 'Enrollment' }
        & Pick<Enrollment, '_id' | 'type' | 'isReserved'>
      )>>, lessons: Maybe<Array<(
        { __typename?: 'Lesson' }
        & Pick<Lesson, 'lessonNumber' | 'lessonStart'>
        & { id: Lesson['_id'] }
      )>> }
    )> }
  )> }
);

export type GetTransferFromClassQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetTransferFromClassQuery = (
  { __typename?: 'Query' }
  & { class: Maybe<(
    { __typename?: 'Class' }
    & Pick<Class, 'courseId' | 'academicYearId' | 'type'>
    & { id: Class['_id'] }
  )> }
);

export type GetClassOfLessonsQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetClassOfLessonsQuery = (
  { __typename?: 'Query' }
  & { class: Maybe<(
    { __typename?: 'Class' }
    & Pick<Class, 'learningArrangement'>
    & { id: Class['_id'] }
    & { lessons: Maybe<Array<(
      { __typename?: 'Lesson' }
      & Pick<Lesson, 'lessonNumber' | 'lessonStart'>
      & { id: Lesson['_id'] }
    )>> }
  )> }
);

export type GetTransferNotifyArrangementQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetTransferNotifyArrangementQuery = (
  { __typename?: 'Query' }
  & { arrangement: Maybe<(
    { __typename?: 'Arrangement' }
    & Pick<Arrangement, 'type'>
    & { id: Arrangement['_id'] }
    & { student: (
      { __typename?: 'Student' }
      & Pick<Student, 'gender' | 'avatarUrl' | 'fullName' | 'alternateUserId' | 'userId'>
      & { id: Student['_id'] }
      & { primaryParent: Maybe<(
        { __typename?: 'Parent' }
        & Pick<Parent, 'email' | 'fullName' | 'alias' | 'gender'>
        & { id: Parent['_id'] }
        & { mobile: (
          { __typename?: 'Telephone' }
          & TelephoneFieldsFragment
        ) }
      )>, primarySchool: Maybe<(
        { __typename?: 'School' }
        & Pick<School, 'createDateTime' | 'name' | 'isArchived' | 'alias' | 'isVisible' | 'educationStage'>
        & { id: School['_id'] }
      )> }
    ), cancellation: Maybe<Array<(
      { __typename?: 'Arrangement' }
      & Pick<Arrangement, 'name' | 'type'>
      & { id: Arrangement['_id'] }
      & { fromLesson: Maybe<(
        { __typename?: 'Lesson' }
        & Pick<Lesson, 'lessonNumber' | 'lessonStart'>
      )>, toLesson: Maybe<(
        { __typename?: 'Lesson' }
        & Pick<Lesson, 'lessonNumber' | 'lessonStart'>
      )> }
    )>> }
    & TransferDetailCardFieldsFragment
  )> }
);

export type TransferBillingArrangementLessonFieldsFragment = (
  { __typename?: 'Lesson' }
  & Pick<Lesson, 'status' | 'lessonStart' | 'lessonEnd' | 'lessonNumber' | 'lessonDay'>
  & { id: Lesson['_id'] }
);

export type ArrangementBillingFieldsFragment = (
  { __typename?: 'Arrangement' }
  & { willAttendLessons: Maybe<Array<(
    { __typename?: 'Lesson' }
    & Pick<Lesson, 'lessonNumber' | 'lessonStart'>
    & { id: Lesson['_id'] }
  )>>, arrangementBillingCheck: Maybe<(
    { __typename?: 'ArrangementBillingCheckResult' }
    & Pick<ArrangementBillingCheckResult, 'amountUsed' | 'remainingFee' | 'updatedLessonFee' | 'feeDifference'>
    & { lessonsNeedToPay: Array<(
      { __typename?: 'Lesson' }
      & Pick<Lesson, 'lessonNumber' | 'lessonStart'>
      & { id: Lesson['_id'] }
    )>, lessonsNeedNotToPay: Array<(
      { __typename?: 'Lesson' }
      & Pick<Lesson, 'lessonNumber' | 'lessonStart'>
      & { id: Lesson['_id'] }
    )> }
  )>, toClass: Maybe<(
    { __typename?: 'Class' }
    & Pick<Class, 'learningArrangement'>
    & { lessons: Maybe<Array<(
      { __typename?: 'Lesson' }
      & TransferBillingArrangementLessonFieldsFragment
    )>> }
    & ArrangementClassCardFieldsFragment
  )>, fromClass: Maybe<(
    { __typename?: 'Class' }
    & { lessons: Maybe<Array<(
      { __typename?: 'Lesson' }
      & TransferBillingArrangementLessonFieldsFragment
    )>> }
    & ArrangementClassCardFieldsFragment
  )>, toLesson: Maybe<(
    { __typename?: 'Lesson' }
    & Pick<Lesson, 'lessonStart' | 'lessonEnd' | 'lessonNumber'>
    & { id: Lesson['_id'] }
  )>, fromLesson: Maybe<(
    { __typename?: 'Lesson' }
    & Pick<Lesson, 'lessonStart' | 'lessonEnd' | 'lessonNumber'>
    & { id: Lesson['_id'] }
  )>, invoice: Maybe<(
    { __typename?: 'Invoice' }
    & InvoiceItemFieldsFragment
  )>, originInvoice: Maybe<(
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'total' | 'referenceNumber' | 'amountPaid'>
    & { id: Invoice['_id'] }
    & { lessons: Maybe<Array<(
      { __typename?: 'Lesson' }
      & Pick<Lesson, 'status' | 'lessonStart' | 'lessonEnd' | 'lessonNumber' | 'lessonDay'>
      & { id: Lesson['_id'] }
    )>> }
  )> }
);

export type GetTransferBillingArrangementQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetTransferBillingArrangementQuery = (
  { __typename?: 'Query' }
  & { arrangement: Maybe<(
    { __typename?: 'Arrangement' }
    & Pick<Arrangement, 'createDateTime' | 'type' | 'status'>
    & { id: Arrangement['_id'] }
    & { student: (
      { __typename?: 'Student' }
      & StudentCellFieldsFragment
    ), enrollment: Maybe<(
      { __typename?: 'Enrollment' }
      & Pick<Enrollment, 'preferredLearningArrangement' | 'group'>
    )>, newEnrollment: Maybe<(
      { __typename?: 'Enrollment' }
      & Pick<Enrollment, 'preferredLearningArrangement' | 'group'>
    )> }
    & ArrangementBillingFieldsFragment
  )> }
);

export type GetTransferDetailQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetTransferDetailQuery = (
  { __typename?: 'Query' }
  & { arrangement: Maybe<(
    { __typename?: 'Arrangement' }
    & Pick<Arrangement, 'name' | 'createDateTime' | 'lastModifiedDateTime' | 'type' | 'status' | 'emailStatus' | 'waStatus' | 'isCancelHasFullLesson'>
    & { id: Arrangement['_id'] }
    & { student: (
      { __typename?: 'Student' }
      & StudentCellFieldsFragment
    ), enrollment: Maybe<(
      { __typename?: 'Enrollment' }
      & Pick<Enrollment, 'preferredLearningArrangement' | 'group'>
    )>, newEnrollment: Maybe<(
      { __typename?: 'Enrollment' }
      & Pick<Enrollment, 'preferredLearningArrangement' | 'group'>
    )>, reminders: Maybe<Array<(
      { __typename?: 'Reminder' }
      & Pick<Reminder, 'subject' | 'message' | 'type' | 'sendDate' | 'sendgridEmailStatus'>
      & { id: Reminder['_id'] }
      & { whatsappMessage: Maybe<(
        { __typename?: 'WhatsappMessage' }
        & Pick<WhatsappMessage, 'status'>
      )> }
    )>>, lastModifiedBy: Maybe<(
      { __typename?: 'SystemUser' }
      & LastModifiedByFieldsFragment
    )> }
    & ArrangementBillingFieldsFragment
  )> }
);

export type GetClassFilterOptionsQueryVariables = {
  showArchived?: Maybe<Scalars['Boolean']>
};


export type GetClassFilterOptionsQuery = (
  { __typename?: 'Query' }
  & { branches: Maybe<Array<(
    { __typename?: 'Branch' }
    & { venues: Maybe<Array<(
      { __typename?: 'Venue' }
      & BasicVenueFieldsFragment
    )>> }
    & BasicBranchFieldsFragment
  )>>, academicYears: Maybe<Array<(
    { __typename?: 'AcademicYear' }
    & Pick<AcademicYear, 'year'>
    & { id: AcademicYear['_id'] }
    & { academicWeeks: Array<(
      { __typename?: 'AcademicWeekWithId' }
      & Pick<AcademicWeekWithId, 'startDate' | 'endDate' | 'weekNumber'>
    )> }
  )>> }
);

export type GetFindClassFilterOptionsQueryVariables = {
  showArchived?: Maybe<Scalars['Boolean']>
};


export type GetFindClassFilterOptionsQuery = (
  { __typename?: 'Query' }
  & { branches: Maybe<Array<(
    { __typename?: 'Branch' }
    & Pick<Branch, 'name' | 'alias'>
    & { id: Branch['_id'] }
    & { venues: Maybe<Array<(
      { __typename?: 'Venue' }
      & BasicVenueFieldsFragment
    )>> }
  )>>, academicYears: Maybe<Array<(
    { __typename?: 'AcademicYear' }
    & Pick<AcademicYear, 'year'>
    & { id: AcademicYear['_id'] }
  )>> }
);

export type GetSelectCoursesQueryVariables = {
  search?: Maybe<Scalars['String']>,
  filterInput?: Maybe<CourseFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetSelectCoursesQuery = (
  { __typename?: 'Query' }
  & { courses: Maybe<(
    { __typename?: 'CoursePage' }
    & Pick<CoursePage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'Course' }
      & Pick<Course, 'avatarUrl' | 'name' | 'nameShort' | 'type' | 'onGoingClassNumber'>
      & { id: Course['_id'] }
      & { academicLevels: Array<(
        { __typename?: 'AcademicLevel' }
        & Pick<AcademicLevel, 'name'>
        & { id: AcademicLevel['_id'] }
      )> }
    )> }
  )> }
);

export type GetClassWithAcademicYearsQueryVariables = {};


export type GetClassWithAcademicYearsQuery = (
  { __typename?: 'Query' }
  & { academicYears: Maybe<Array<(
    { __typename?: 'AcademicYear' }
    & Pick<AcademicYear, 'year' | 'academicWeekStart' | 'academicWeekEnd'>
    & { id: AcademicYear['_id'] }
    & { period: (
      { __typename?: 'DateTimeRange' }
      & Pick<DateTimeRange, 'start' | 'end'>
    ), terms: Maybe<Array<(
      { __typename?: 'Term' }
      & { id: Term['_id'] }
      & { academicLevels: Maybe<Array<(
        { __typename?: 'AcademicLevel' }
        & BasicAcademicLevelFieldsFragment
      )>> }
      & BasicTermFieldsFragment
    )>> }
  )>> }
);

export type GetSetupClassAcademicYearQueryVariables = {
  academicYearId: Scalars['ObjectId']
};


export type GetSetupClassAcademicYearQuery = (
  { __typename?: 'Query' }
  & { academicYear: Maybe<(
    { __typename?: 'AcademicYear' }
    & { id: AcademicYear['_id'] }
    & { holidays: Maybe<Array<(
      { __typename?: 'Holiday' }
      & BasicHolidayFieldsFragment
    )>> }
  )> }
);

export type ValidateTeacherAndVenueQueryVariables = {
  input: ValidateTeacherAndVenueInput
};


export type ValidateTeacherAndVenueQuery = (
  { __typename?: 'Query' }
  & { validateTeacherAndVenue: Maybe<(
    { __typename?: 'TeacherAndVenueValidateResult' }
    & Pick<TeacherAndVenueValidateResult, 'teacher' | 'venue'>
  )> }
);

export type AutocompleteClassInfoQueryVariables = {
  courseId: Scalars['ObjectId'],
  isAdHoc: Scalars['Boolean']
};


export type AutocompleteClassInfoQuery = (
  { __typename?: 'Query' }
  & { autocompleteClass: (
    { __typename?: 'CreateClassOutput' }
    & Pick<CreateClassOutput, 'courseId' | 'name' | 'defaultLessonDays' | 'defaultLessonStart' | 'defaultLessonEnd' | 'academicYearId' | 'teacherId' | 'classCode' | 'learningArrangement' | 'venueId' | 'maximumClassCapacity' | 'maximumMakeUpCapacity' | 'isSkipScheduledHoliday' | 'firstLessonDate' | 'isAdHoc' | 'runNumber' | 'numberOfLessons' | 'academicWeekNumber'>
  ) }
);

export type GetClassFormDataQueryVariables = {};


export type GetClassFormDataQuery = (
  { __typename?: 'Query' }
  & { venues: Maybe<Array<(
    { __typename?: 'Venue' }
    & BasicVenueFieldsFragment
  )>>, academicYears: Maybe<Array<(
    { __typename?: 'AcademicYear' }
    & Pick<AcademicYear, 'year' | 'academicWeekStart' | 'academicWeekEnd'>
    & { id: AcademicYear['_id'] }
    & { period: (
      { __typename?: 'DateTimeRange' }
      & Pick<DateTimeRange, 'start' | 'end'>
    ), academicWeeks: Array<(
      { __typename?: 'AcademicWeekWithId' }
      & Pick<AcademicWeekWithId, 'startDate' | 'endDate' | 'weekNumber'>
    )>, terms: Maybe<Array<(
      { __typename?: 'Term' }
      & { id: Term['_id'] }
      & { academicLevels: Maybe<Array<(
        { __typename?: 'AcademicLevel' }
        & BasicAcademicLevelFieldsFragment
      )>> }
      & BasicTermFieldsFragment
    )>> }
  )>> }
);

export type GetCurrentYearHolidaysQueryVariables = {
  year?: Maybe<Scalars['Int']>
};


export type GetCurrentYearHolidaysQuery = (
  { __typename?: 'Query' }
  & { holidays: Maybe<Array<(
    { __typename?: 'Holiday' }
    & { id: Holiday['_id'] }
    & { period: (
      { __typename?: 'DateRange' }
      & Pick<DateRange, 'start' | 'end'>
    ) }
  )>> }
);

export type GetEditClassFormDataQueryVariables = {
  classId: Scalars['ObjectId'],
  skipHoliday?: Maybe<Scalars['Boolean']>
};


export type GetEditClassFormDataQuery = (
  { __typename?: 'Query' }
  & { class: Maybe<(
    { __typename?: 'Class' }
    & Pick<Class, 'name' | 'shorthand' | 'isAdHoc' | 'classCode' | 'type' | 'repeat' | 'firstLessonDate' | 'lastLessonDate' | 'generateUntil' | 'numberOfLessons' | 'isReplacementLesson' | 'isSkipScheduledHoliday' | 'learningArrangement' | 'runNumber' | 'defaultLessonDays' | 'defaultLessonStart' | 'defaultLessonEnd' | 'maximumClassCapacity' | 'maximumMakeUpCapacity' | 'isArchived' | 'isVisible' | 'status' | 'lessonNumber' | 'createDateTime'>
    & { id: Class['_id'] }
    & { dailySessionTimeSlots: Maybe<Array<(
      { __typename?: 'TimeRange' }
      & Pick<TimeRange, 'start' | 'end'>
    )>>, classFee: Maybe<(
      { __typename?: 'StandardCourseFee' }
      & Pick<StandardCourseFee, 'amount' | 'type'>
    )>, academicYear: (
      { __typename?: 'AcademicYear' }
      & Pick<AcademicYear, 'year'>
      & { id: AcademicYear['_id'] }
    ), teacher: Maybe<(
      { __typename?: 'SystemUser' }
      & Pick<SystemUser, 'alias' | 'avatarUrl' | 'fullName' | 'gender' | 'salutation' | 'initials'>
      & { id: SystemUser['_id'] }
    )>, venue: Maybe<(
      { __typename?: 'Venue' }
      & BasicVenueFieldsFragment
    )>, digitalClassAccessCredentials: Maybe<(
      { __typename?: 'DigitalClassAccessCredentials' }
      & Pick<DigitalClassAccessCredentials, 'autoGenerateZoomMeetingDetails' | 'autoGenerateGoogleMeetingDetails' | 'zoomMeetingId' | 'zoomMeetingPassword' | 'zoomMeetingLink' | 'googleMeetId' | 'googleMeetLink'>
    )>, course: (
      { __typename?: 'Course' }
      & Pick<Course, 'name' | 'colorCode' | 'nameShort' | 'type' | 'avatarUrl' | 'description' | 'numberOfLessons' | 'learningResourceUrl' | 'gamePlanLink' | 'subject' | 'isSkipScheduledHoliday' | 'isRequireFeedback' | 'academicStreams' | 'duration'>
      & { id: Course['_id'] }
      & { academicLevels: Array<(
        { __typename?: 'AcademicLevel' }
        & Pick<AcademicLevel, 'name'>
        & { id: AcademicLevel['_id'] }
      )>, courseFee: Maybe<(
        { __typename?: 'CourseFee' }
        & Pick<CourseFee, 'isApplicable' | 'billingFrequency'>
        & { standardCourseFees: Array<(
          { __typename?: 'StandardCourseFee' }
          & Pick<StandardCourseFee, 'type' | 'amount'>
        )>, otherFees: Maybe<Array<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'isApplicable' | 'name' | 'amount'>
        )>> }
      )> }
    ), lessons: Maybe<Array<(
      { __typename?: 'Lesson' }
      & Pick<Lesson, 'lessonNumber' | 'lessonStart'>
      & { id: Lesson['_id'] }
      & { academicWeek: Maybe<(
        { __typename?: 'AcademicWeek' }
        & Pick<AcademicWeek, 'weekNumber' | 'endDate' | 'startDate'>
      )> }
    )>> }
  )> }
);

export type UpdateEnrollmentsPreferredLearningArrangementMutationVariables = {
  input: UpdateEnrollmentsPreferredLearningArrangementInput
};


export type UpdateEnrollmentsPreferredLearningArrangementMutation = (
  { __typename?: 'Mutation' }
  & { updateEnrollmentsPreferredLearningArrangement: Maybe<Array<(
    { __typename?: 'Enrollment' }
    & { id: Enrollment['_id'] }
  )>> }
);

export type GetClassListQueryVariables = {
  search?: Maybe<Scalars['String']>,
  filterInput?: Maybe<ClassFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetClassListQuery = (
  { __typename?: 'Query' }
  & { classes: Maybe<(
    { __typename?: 'ClassPage' }
    & Pick<ClassPage, 'total' | 'pageSize' | 'pageIndex'>
    & { items: Array<(
      { __typename?: 'Class' }
      & Pick<Class, 'name' | 'shorthand' | 'status' | 'type' | 'classCode' | 'isVisible' | 'isAdHoc' | 'learningArrangement' | 'defaultLessonDays' | 'defaultLessonStart' | 'defaultLessonEnd'>
      & { id: Class['_id'] }
      & { academicYear: (
        { __typename?: 'AcademicYear' }
        & Pick<AcademicYear, 'year'>
        & { id: AcademicYear['_id'] }
      ), academicLevels: Array<(
        { __typename?: 'AcademicLevel' }
        & Pick<AcademicLevel, 'name'>
      )>, teacher: Maybe<(
        { __typename?: 'SystemUser' }
        & Pick<SystemUser, 'fullName' | 'avatarUrl' | 'gender' | 'salutation' | 'initials'>
        & { id: SystemUser['_id'] }
      )>, venue: Maybe<(
        { __typename?: 'Venue' }
        & Pick<Venue, 'shorthand' | 'unitNo'>
        & { branch: Maybe<(
          { __typename?: 'Branch' }
          & Pick<Branch, 'name' | 'alias'>
        )> }
      )>, course: (
        { __typename?: 'Course' }
        & Pick<Course, 'name' | 'nameShort' | 'type' | 'avatarUrl' | 'colorCode'>
        & { id: Course['_id'] }
      ), digitalClassAccessCredentials: Maybe<(
        { __typename?: 'DigitalClassAccessCredentials' }
        & Pick<DigitalClassAccessCredentials, 'zoomMeetingId' | 'zoomMeetingPassword' | 'zoomMeetingLink' | 'googleMeetId' | 'googleMeetLink'>
      )>, capacity: Maybe<(
        { __typename?: 'ClassCapacityDTO' }
        & CapacityFieldsFragment
      )>, enrollments: Maybe<Array<(
        { __typename?: 'Enrollment' }
        & Pick<Enrollment, '_id' | 'type' | 'isReserved'>
      )>>, dailySessionTimeSlots: Maybe<Array<(
        { __typename?: 'TimeRange' }
        & Pick<TimeRange, 'start' | 'end'>
      )>> }
    )> }
  )> }
);

export type GetClassWithIndexListQueryVariables = {
  filterInput?: Maybe<ClassFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetClassWithIndexListQuery = (
  { __typename?: 'Query' }
  & { classesWithIndex: Maybe<(
    { __typename?: 'ClassIndexPage' }
    & Pick<ClassIndexPage, 'total' | 'pageSize' | 'pageIndex'>
    & { items: Array<(
      { __typename?: 'ClassIndex' }
      & Pick<ClassIndex, 'name' | 'shorthand' | 'status' | 'type' | 'classCode' | 'isAdHoc' | 'createDateTime' | 'lastModifiedDateTime' | 'lastModifiedFullName' | 'maximumClassCapacity' | 'maximumMakeUpCapacity' | 'regularStudents' | 'trialStudents' | 'reservedStudents' | 'transferredStudents' | 'withdrawnStudents' | 'defaultLessonStart' | 'defaultLessonEnd' | 'learningArrangement' | 'defaultLessonDays'>
      & { id: ClassIndex['_id'] }
      & { createdBy: Maybe<(
        { __typename?: 'SystemUser' }
        & Pick<SystemUser, 'fullName'>
      )>, academicYear: Maybe<(
        { __typename?: 'ClassAcademicYearIndex' }
        & Pick<ClassAcademicYearIndex, 'year'>
        & { id: ClassAcademicYearIndex['_id'] }
      )>, academicLevels: Maybe<Array<(
        { __typename?: 'ClassAcademicLevelIndex' }
        & Pick<ClassAcademicLevelIndex, 'name'>
      )>>, teacher: Maybe<(
        { __typename?: 'ClassTeacherIndex' }
        & Pick<ClassTeacherIndex, 'fullName' | 'avatarUrl' | 'gender' | 'salutation' | 'initials'>
        & { id: ClassTeacherIndex['_id'] }
      )>, venue: Maybe<(
        { __typename?: 'ClassVenueIndex' }
        & Pick<ClassVenueIndex, 'unitNo' | 'shorthand'>
      )>, branch: Maybe<(
        { __typename?: 'ClassBranchIndex' }
        & Pick<ClassBranchIndex, 'name' | 'alias'>
      )>, course: Maybe<(
        { __typename?: 'ClassCourseIndex' }
        & Pick<ClassCourseIndex, 'name' | 'nameShort'>
        & { id: ClassCourseIndex['_id'] }
      )>, digitalClassAccessCredentials: Maybe<(
        { __typename?: 'DigitalClassAccessCredentialsDO' }
        & Pick<DigitalClassAccessCredentialsDo, 'zoomMeetingId' | 'zoomMeetingPassword' | 'zoomMeetingLink' | 'googleMeetId' | 'googleMeetLink'>
      )>, capacity: Maybe<(
        { __typename?: 'ClassCapacityDTO' }
        & CapacityFieldsFragment
      )> }
    )> }
  )> }
);

export type GetClassIdsWithIndexListQueryVariables = {
  filterInput?: Maybe<ClassFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetClassIdsWithIndexListQuery = (
  { __typename?: 'Query' }
  & { classesWithIndex: Maybe<(
    { __typename?: 'ClassIndexPage' }
    & Pick<ClassIndexPage, 'total' | 'pageSize' | 'pageIndex'>
    & { items: Array<(
      { __typename?: 'ClassIndex' }
      & { id: ClassIndex['_id'] }
    )> }
  )> }
);

export type GetClassesQueryVariables = {
  search?: Maybe<Scalars['String']>,
  filterInput?: Maybe<ClassFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>,
  skipHoliday?: Maybe<Scalars['Boolean']>
};


export type GetClassesQuery = (
  { __typename?: 'Query' }
  & { classes: Maybe<(
    { __typename?: 'ClassPage' }
    & Pick<ClassPage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'Class' }
      & Pick<Class, 'name' | 'status' | 'type' | 'classCode' | 'learningArrangement' | 'runNumber' | 'defaultLessonDays' | 'defaultLessonStart' | 'defaultLessonEnd' | 'maximumClassCapacity' | 'maximumMakeUpCapacity' | 'isArchived' | 'isVisible' | 'capacityUsed' | 'isAdHoc'>
      & { id: Class['_id'] }
      & { academicYear: (
        { __typename?: 'AcademicYear' }
        & Pick<AcademicYear, 'year'>
        & { id: AcademicYear['_id'] }
      ), academicLevels: Array<(
        { __typename?: 'AcademicLevel' }
        & Pick<AcademicLevel, 'alias' | 'name'>
        & { id: AcademicLevel['_id'] }
      )>, teacher: Maybe<(
        { __typename?: 'SystemUser' }
        & Pick<SystemUser, 'fullName' | 'avatarUrl' | 'gender' | 'salutation' | 'initials'>
        & { id: SystemUser['_id'] }
      )>, venue: Maybe<(
        { __typename?: 'Venue' }
        & BasicVenueFieldsFragment
      )>, digitalClassAccessCredentials: Maybe<(
        { __typename?: 'DigitalClassAccessCredentials' }
        & Pick<DigitalClassAccessCredentials, 'zoomMeetingId' | 'zoomMeetingPassword' | 'zoomMeetingLink' | 'googleMeetId' | 'googleMeetLink'>
      )>, course: (
        { __typename?: 'Course' }
        & Pick<Course, 'name' | 'colorCode' | 'nameShort' | 'type' | 'avatarUrl'>
        & { id: Course['_id'] }
        & { academicLevels: Array<(
          { __typename?: 'AcademicLevel' }
          & Pick<AcademicLevel, 'name'>
        )>, classes: Maybe<Array<(
          { __typename?: 'Class' }
          & Pick<Class, 'status'>
          & { id: Class['_id'] }
        )>> }
      ), lessons: Maybe<Array<(
        { __typename?: 'Lesson' }
        & Pick<Lesson, 'lessonDay' | 'lessonStart' | 'lessonEnd' | 'lessonNumber'>
        & { id: Lesson['_id'] }
        & { academicWeek: Maybe<(
          { __typename?: 'AcademicWeek' }
          & Pick<AcademicWeek, 'weekNumber' | 'startDate' | 'endDate'>
        )> }
      )>>, enrollments: Maybe<Array<(
        { __typename?: 'Enrollment' }
        & Pick<Enrollment, 'type' | 'startDate' | 'endDate' | 'isReserved'>
        & { student: Maybe<(
          { __typename?: 'Student' }
          & Pick<Student, 'fullName' | 'alias' | 'gender' | 'email'>
          & { id: Student['_id'] }
          & { primarySchool: Maybe<(
            { __typename?: 'School' }
            & Pick<School, 'name'>
            & { id: School['_id'] }
          )>, secondarySchool: Maybe<(
            { __typename?: 'School' }
            & Pick<School, 'name'>
            & { id: School['_id'] }
          )>, academicLevel: Maybe<(
            { __typename?: 'AcademicLevel' }
            & Pick<AcademicLevel, 'educationStage'>
            & { id: AcademicLevel['_id'] }
          )>, examScores: Maybe<Array<(
            { __typename?: 'ExamScore' }
            & Pick<ExamScore, 'year' | 'subject' | 'marksPercentage'>
            & { id: ExamScore['_id'] }
          )>> }
        )> }
      )>>, term: Maybe<(
        { __typename?: 'Term' }
        & { term1Period: (
          { __typename?: 'DateTimeRange' }
          & Pick<DateTimeRange, 'start' | 'end'>
        ), term2Period: (
          { __typename?: 'DateTimeRange' }
          & Pick<DateTimeRange, 'start' | 'end'>
        ), term3Period: (
          { __typename?: 'DateTimeRange' }
          & Pick<DateTimeRange, 'start' | 'end'>
        ), term4Period: (
          { __typename?: 'DateTimeRange' }
          & Pick<DateTimeRange, 'start' | 'end'>
        ) }
      )>, capacity: Maybe<(
        { __typename?: 'ClassCapacityDTO' }
        & CapacityFieldsFragment
      )>, activeEnrollments: Maybe<Array<(
        { __typename?: 'Enrollment' }
        & Pick<Enrollment, '_id'>
      )>> }
    )> }
  )> }
);

export type GetClassesOfTeacherQueryVariables = {
  filterInput?: Maybe<ClassFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetClassesOfTeacherQuery = (
  { __typename?: 'Query' }
  & { classes: Maybe<(
    { __typename?: 'ClassPage' }
    & Pick<ClassPage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'Class' }
      & Pick<Class, 'name' | 'classCode' | 'type' | 'defaultLessonDays' | 'defaultLessonStart' | 'defaultLessonEnd'>
      & { id: Class['_id'] }
      & { academicYear: (
        { __typename?: 'AcademicYear' }
        & Pick<AcademicYear, 'year'>
        & { id: AcademicYear['_id'] }
      ), venue: Maybe<(
        { __typename?: 'Venue' }
        & BasicVenueFieldsFragment
      )>, dailySessionTimeSlots: Maybe<Array<(
        { __typename?: 'TimeRange' }
        & Pick<TimeRange, 'start' | 'end'>
      )>>, course: (
        { __typename?: 'Course' }
        & Pick<Course, 'nameShort'>
        & { id: Course['_id'] }
      ), enrollments: Maybe<Array<(
        { __typename?: 'Enrollment' }
        & Pick<Enrollment, 'type' | 'startDate' | 'endDate' | 'isReserved'>
        & { id: Enrollment['_id'] }
      )>>, capacity: Maybe<(
        { __typename?: 'ClassCapacityDTO' }
        & CapacityFieldsFragment
      )> }
    )> }
  )> }
);

export type GetClassEnrollmentsQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetClassEnrollmentsQuery = (
  { __typename?: 'Query' }
  & { class: Maybe<(
    { __typename?: 'Class' }
    & Pick<Class, 'name' | 'maximumClassCapacity' | 'maximumMakeUpCapacity' | 'capacityUsed'>
    & { id: Class['_id'] }
    & { lessons: Maybe<Array<(
      { __typename?: 'Lesson' }
      & Pick<Lesson, '_id' | 'lessonNumber' | 'lessonStart'>
    )>>, capacity: Maybe<(
      { __typename?: 'ClassCapacityDTO' }
      & CapacityFieldsFragment
    )>, enrollments: Maybe<Array<(
      { __typename?: 'Enrollment' }
      & Pick<Enrollment, 'isReserved'>
      & { class: (
        { __typename?: 'Class' }
        & Pick<Class, 'name'>
      ), registration: Maybe<(
        { __typename?: 'Registration' }
        & { linkRegistration: Maybe<(
          { __typename?: 'Registration' }
          & { enrollment: Maybe<(
            { __typename?: 'Enrollment' }
            & Pick<Enrollment, 'status' | 'type' | 'startDate' | 'endDate'>
            & { class: (
              { __typename?: 'Class' }
              & Pick<Class, 'name'>
            ) }
          )> }
        )> }
      )>, invoices: Maybe<Array<(
        { __typename?: 'Invoice' }
        & Pick<Invoice, '_id' | 'dueDate' | 'status'>
      )>> }
      & ClassEnrollmentFieldsFragment
    )>> }
  )> }
);

export type GetClassDetailByIdQueryVariables = {
  classId: Scalars['ObjectId']
};


export type GetClassDetailByIdQuery = (
  { __typename?: 'Query' }
  & { class: Maybe<(
    { __typename?: 'Class' }
    & Pick<Class, 'name' | 'type' | 'isSimulation' | 'classCode' | 'isReplacementLesson' | 'learningArrangement' | 'isAdHoc' | 'runNumber' | 'defaultLessonDays' | 'defaultLessonStart' | 'defaultLessonEnd' | 'voidRemarks' | 'maximumClassCapacity' | 'maximumMakeUpCapacity' | 'capacityUsed' | 'status' | 'createDateTime'>
    & { id: Class['_id'] }
    & { course: (
      { __typename?: 'Course' }
      & Pick<Course, 'type' | 'colorCode' | 'name' | 'nameShort' | 'avatarUrl'>
      & { id: Course['_id'] }
    ), academicYear: (
      { __typename?: 'AcademicYear' }
      & Pick<AcademicYear, 'year'>
      & { id: AcademicYear['_id'] }
    ), teacher: Maybe<(
      { __typename?: 'SystemUser' }
      & Pick<SystemUser, 'alias' | 'avatarUrl' | 'fullName' | 'gender' | 'salutation' | 'initials'>
      & { id: SystemUser['_id'] }
    )>, lessons: Maybe<Array<(
      { __typename?: 'Lesson' }
      & Pick<Lesson, 'lessonStart'>
    )>>, venue: Maybe<(
      { __typename?: 'Venue' }
      & BasicVenueFieldsFragment
    )>, capacity: Maybe<(
      { __typename?: 'ClassCapacityDTO' }
      & CapacityFieldsFragment
    )>, dailySessionTimeSlots: Maybe<Array<(
      { __typename?: 'TimeRange' }
      & Pick<TimeRange, 'start' | 'end'>
    )>>, digitalClassAccessCredentials: Maybe<(
      { __typename?: 'DigitalClassAccessCredentials' }
      & Pick<DigitalClassAccessCredentials, 'zoomMeetingId' | 'zoomMeetingPassword' | 'zoomMeetingLink' | 'googleMeetId' | 'googleMeetLink'>
    )>, enrollments: Maybe<Array<(
      { __typename?: 'Enrollment' }
      & Pick<Enrollment, 'isReserved'>
      & { id: Enrollment['_id'] }
    )>>, createdBy: Maybe<(
      { __typename?: 'SystemUser' }
      & CreateByFieldsFragment
    )> }
  )> }
);

export type GetClassByAttendanceIdQueryVariables = {
  classId: Scalars['ObjectId'],
  skipHoliday?: Maybe<Scalars['Boolean']>
};


export type GetClassByAttendanceIdQuery = (
  { __typename?: 'Query' }
  & { class: Maybe<(
    { __typename?: 'Class' }
    & Pick<Class, 'name' | 'isAdHoc' | 'learningArrangement' | 'isReplacementLesson'>
    & { id: Class['_id'] }
    & { academicYear: (
      { __typename?: 'AcademicYear' }
      & Pick<AcademicYear, 'year'>
      & { id: AcademicYear['_id'] }
      & { groupAssignment: Maybe<(
        { __typename?: 'GroupAssignment' }
        & { items: Maybe<Array<(
          { __typename?: 'GroupAssignmentItem' }
          & Pick<GroupAssignmentItem, 'weekNumber' | 'startDate' | 'endDate' | 'groupA' | 'groupB'>
        )>> }
      )> }
    ), lessons: Maybe<Array<(
      { __typename?: 'Lesson' }
      & Pick<Lesson, 'lessonNumber' | 'lessonStart' | 'lessonEnd' | 'lessonDay' | 'maximumClassCapacity' | 'isHoliday'>
      & { id: Lesson['_id'] }
      & { academicWeek: Maybe<(
        { __typename?: 'AcademicWeek' }
        & Pick<AcademicWeek, 'startDate' | 'endDate' | 'weekNumber'>
      )> }
    )>> }
  )> }
);

export type GetSetDateModalClassByIdQueryVariables = {
  classId: Scalars['ObjectId'],
  skipHoliday?: Maybe<Scalars['Boolean']>
};


export type GetSetDateModalClassByIdQuery = (
  { __typename?: 'Query' }
  & { class: Maybe<(
    { __typename?: 'Class' }
    & { term: Maybe<(
      { __typename?: 'Term' }
      & BasicTermFieldsFragment
    )>, lessons: Maybe<Array<(
      { __typename?: 'Lesson' }
      & Pick<Lesson, 'lessonNumber' | 'lessonStart' | 'lessonEnd' | 'maximumClassCapacity'>
      & { id: Lesson['_id'] }
      & { attendance: Maybe<Array<(
        { __typename?: 'Attendance' }
        & { id: Attendance['_id'] }
      )>> }
    )>> }
  )> }
);

export type GetVenuesQueryVariables = {};


export type GetVenuesQuery = (
  { __typename?: 'Query' }
  & { venues: Maybe<Array<(
    { __typename?: 'Venue' }
    & BasicVenueFieldsFragment
  )>> }
);

export type GetClassAndLessonsQueryVariables = {
  classId: Scalars['ObjectId']
};


export type GetClassAndLessonsQuery = (
  { __typename?: 'Query' }
  & { class: Maybe<(
    { __typename?: 'Class' }
    & Pick<Class, 'name' | 'runNumber' | 'defaultLessonDays' | 'defaultLessonStart' | 'defaultLessonEnd' | 'maximumClassCapacity' | 'maximumMakeUpCapacity'>
    & { id: Class['_id'] }
    & { academicYear: (
      { __typename?: 'AcademicYear' }
      & Pick<AcademicYear, 'year'>
      & { id: AcademicYear['_id'] }
    ), teacher: Maybe<(
      { __typename?: 'SystemUser' }
      & Pick<SystemUser, 'fullName' | 'gender'>
      & { id: SystemUser['_id'] }
    )>, venue: Maybe<(
      { __typename?: 'Venue' }
      & BasicVenueFieldsFragment
    )>, course: (
      { __typename?: 'Course' }
      & Pick<Course, 'name' | 'nameShort' | 'type'>
      & { id: Course['_id'] }
    ), lessons: Maybe<Array<(
      { __typename?: 'Lesson' }
      & Pick<Lesson, 'lessonStart' | 'lessonEnd' | 'maximumClassCapacity' | 'lessonDay' | 'lessonNumber'>
      & { id: Lesson['_id'] }
      & { academicWeek: Maybe<(
        { __typename?: 'AcademicWeek' }
        & Pick<AcademicWeek, 'startDate' | 'endDate' | 'weekNumber'>
      )>, teacher: Maybe<(
        { __typename?: 'SystemUser' }
        & Pick<SystemUser, 'fullName' | 'gender'>
        & { id: SystemUser['_id'] }
      )>, venue: Maybe<(
        { __typename?: 'Venue' }
        & BasicVenueFieldsFragment
      )> }
    )>> }
  )> }
);

export type AcademicYearFieldsFragment = (
  { __typename?: 'AcademicYear' }
  & { holidays: Maybe<Array<(
    { __typename?: 'Holiday' }
    & BasicHolidayFieldsFragment
  )>> }
  & BasicAcademicYearFieldsFragment
);

export type LessonFieldsFragment = (
  { __typename?: 'Lesson' }
  & Pick<Lesson, 'lessonNumber' | 'lessonStart' | 'lessonEnd' | 'lessonDay' | 'maximumClassCapacity' | 'isHoliday'>
  & { id: Lesson['_id'] }
  & { teacher: Maybe<(
    { __typename?: 'SystemUser' }
    & Pick<SystemUser, 'fullName' | 'salutation' | 'gender' | 'avatarUrl' | 'initials'>
    & { id: SystemUser['_id'] }
  )>, venue: Maybe<(
    { __typename?: 'Venue' }
    & BasicVenueFieldsFragment
  )>, academicWeek: Maybe<(
    { __typename?: 'AcademicWeek' }
    & Pick<AcademicWeek, 'startDate' | 'endDate' | 'weekNumber'>
  )>, attendance: Maybe<Array<(
    { __typename?: 'Attendance' }
    & AttendanceFieldsFragment
  )>>, capacity: Maybe<(
    { __typename?: 'LessonCapacityDTO' }
    & LessonCapacityFieldsFragment
  )> }
);

export type CreateRegularClassMutationVariables = {
  input: CreateRegularClassCommand
};


export type CreateRegularClassMutation = (
  { __typename?: 'Mutation' }
  & { createRegularClass: Maybe<(
    { __typename?: 'Class' }
    & { id: Class['_id'] }
  )> }
);

export type CreateWorkShopClassMutationVariables = {
  input: CreateWorkShopClassCommand
};


export type CreateWorkShopClassMutation = (
  { __typename?: 'Mutation' }
  & { createWorkShopClass: Maybe<(
    { __typename?: 'Class' }
    & { id: Class['_id'] }
  )> }
);

export type CreateAdhocClassMutationVariables = {
  input: CreateAdhocClassCommand
};


export type CreateAdhocClassMutation = (
  { __typename?: 'Mutation' }
  & { createAdhocClass: Maybe<(
    { __typename?: 'Class' }
    & { id: Class['_id'] }
  )> }
);

export type UpdateClassScheduleMutationVariables = {
  input: UpdateClassScheduleCommand
};


export type UpdateClassScheduleMutation = (
  { __typename?: 'Mutation' }
  & { updateClassSchedule: Maybe<(
    { __typename?: 'Class' }
    & { id: Class['_id'] }
  )> }
);

export type UpdateClassNameMutationVariables = {
  input: UpdateClassNameCommand
};


export type UpdateClassNameMutation = (
  { __typename?: 'Mutation' }
  & { updateClassName: Maybe<(
    { __typename?: 'Class' }
    & { id: Class['_id'] }
  )> }
);

export type UpdateClassTeacherMutationVariables = {
  input: UpdateClassTeacherCommand
};


export type UpdateClassTeacherMutation = (
  { __typename?: 'Mutation' }
  & { updateClassTeacher: Maybe<(
    { __typename?: 'Class' }
    & { id: Class['_id'] }
  )> }
);

export type UpdateClassLearningArrangementMutationVariables = {
  input: UpdateClassLearningArrangementCommand
};


export type UpdateClassLearningArrangementMutation = (
  { __typename?: 'Mutation' }
  & { updateClassLearningArrangement: Maybe<(
    { __typename?: 'Class' }
    & { id: Class['_id'] }
  )> }
);

export type UpdateClassFeeMutationVariables = {
  input: UpdateClassFeeCommand
};


export type UpdateClassFeeMutation = (
  { __typename?: 'Mutation' }
  & { updateClassFee: Maybe<(
    { __typename?: 'Class' }
    & { id: Class['_id'] }
  )> }
);

export type UpdateClassVenueMutationVariables = {
  input: UpdateClassVenueCommand
};


export type UpdateClassVenueMutation = (
  { __typename?: 'Mutation' }
  & { updateClassVenue: Maybe<(
    { __typename?: 'Class' }
    & { id: Class['_id'] }
  )> }
);

export type UpdateClassDigitalCredentialMutationVariables = {
  input: UpdateClassDigitalCredentialCommand
};


export type UpdateClassDigitalCredentialMutation = (
  { __typename?: 'Mutation' }
  & { updateClassDigitalCredential: Maybe<(
    { __typename?: 'Class' }
    & { id: Class['_id'] }
  )> }
);

export type VoidClassMutationVariables = {
  input: VoidInput
};


export type VoidClassMutation = (
  { __typename?: 'Mutation' }
  & { voidClass: Maybe<(
    { __typename?: 'Class' }
    & { id: Class['_id'] }
  )> }
);

export type CancelClassCreationFlowMutationVariables = {
  input: IdInput
};


export type CancelClassCreationFlowMutation = (
  { __typename?: 'Mutation' }
  & { cancelClassCreationFlow: Maybe<(
    { __typename?: 'Class' }
    & Pick<Class, 'name' | 'runNumber' | 'defaultLessonDays' | 'defaultLessonStart' | 'defaultLessonEnd' | 'maximumClassCapacity' | 'maximumMakeUpCapacity'>
    & { id: Class['_id'] }
    & { academicYear: (
      { __typename?: 'AcademicYear' }
      & Pick<AcademicYear, 'year'>
      & { id: AcademicYear['_id'] }
    ), teacher: Maybe<(
      { __typename?: 'SystemUser' }
      & Pick<SystemUser, 'fullName' | 'gender'>
      & { id: SystemUser['_id'] }
    )>, venue: Maybe<(
      { __typename?: 'Venue' }
      & BasicVenueFieldsFragment
    )>, course: (
      { __typename?: 'Course' }
      & Pick<Course, 'name' | 'nameShort' | 'type'>
      & { id: Course['_id'] }
    ), lessons: Maybe<Array<(
      { __typename?: 'Lesson' }
      & Pick<Lesson, 'lessonStart' | 'lessonEnd' | 'maximumClassCapacity' | 'lessonDay' | 'lessonNumber'>
      & { id: Lesson['_id'] }
      & { academicWeek: Maybe<(
        { __typename?: 'AcademicWeek' }
        & Pick<AcademicWeek, 'startDate' | 'endDate' | 'weekNumber'>
      )>, teacher: Maybe<(
        { __typename?: 'SystemUser' }
        & Pick<SystemUser, 'fullName' | 'gender'>
        & { id: SystemUser['_id'] }
      )>, venue: Maybe<(
        { __typename?: 'Venue' }
        & BasicVenueFieldsFragment
      )> }
    )>> }
  )> }
);

export type CompleteClassCreationFlowMutationVariables = {
  input: IdInput
};


export type CompleteClassCreationFlowMutation = (
  { __typename?: 'Mutation' }
  & { completeClassCreationFlow: Maybe<(
    { __typename?: 'Class' }
    & Pick<Class, 'name' | 'runNumber' | 'defaultLessonDays' | 'defaultLessonStart' | 'defaultLessonEnd' | 'maximumClassCapacity' | 'maximumMakeUpCapacity'>
    & { id: Class['_id'] }
    & { academicYear: (
      { __typename?: 'AcademicYear' }
      & Pick<AcademicYear, 'year'>
      & { id: AcademicYear['_id'] }
    ), teacher: Maybe<(
      { __typename?: 'SystemUser' }
      & Pick<SystemUser, 'fullName' | 'gender'>
      & { id: SystemUser['_id'] }
    )>, venue: Maybe<(
      { __typename?: 'Venue' }
      & BasicVenueFieldsFragment
    )>, course: (
      { __typename?: 'Course' }
      & Pick<Course, 'name' | 'nameShort' | 'type'>
      & { id: Course['_id'] }
    ), lessons: Maybe<Array<(
      { __typename?: 'Lesson' }
      & Pick<Lesson, 'lessonStart' | 'lessonEnd' | 'maximumClassCapacity' | 'lessonDay' | 'lessonNumber'>
      & { id: Lesson['_id'] }
      & { academicWeek: Maybe<(
        { __typename?: 'AcademicWeek' }
        & Pick<AcademicWeek, 'startDate' | 'endDate' | 'weekNumber'>
      )>, teacher: Maybe<(
        { __typename?: 'SystemUser' }
        & Pick<SystemUser, 'fullName' | 'gender'>
        & { id: SystemUser['_id'] }
      )>, venue: Maybe<(
        { __typename?: 'Venue' }
        & BasicVenueFieldsFragment
      )> }
    )>> }
  )> }
);

export type ClassCsvQueryVariables = {
  search?: Maybe<Scalars['String']>,
  filterInput?: Maybe<ClassFilterInput>
};


export type ClassCsvQuery = (
  { __typename?: 'Query' }
  & { csv: Maybe<(
    { __typename?: 'Csv' }
    & Pick<Csv, 'url'>
  )> }
);

export type SeatsAnalyticsByAcademicLevelQueryVariables = {
  filterInput?: Maybe<ClassFilterInput>
};


export type SeatsAnalyticsByAcademicLevelQuery = (
  { __typename?: 'Query' }
  & { seatsAnalyticsByAcademicLevel: Maybe<(
    { __typename?: 'SeatAnalytics' }
    & Pick<SeatAnalytics, 'totalAvailableSlots' | 'totalStudents' | 'enrollmentRating'>
  )> }
);

export type CapacitySimulationMutationVariables = {
  input: SimulationCapacityInput
};


export type CapacitySimulationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'capacitySimulation'>
);

export type ClearSimulationMutationVariables = {
  input: IdInput
};


export type ClearSimulationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'clearSimulation'>
);

export type GetClassWithRegistrationModalQueryVariables = {
  classId: Scalars['ObjectId'],
  skipHoliday?: Maybe<Scalars['Boolean']>,
  studentId: Scalars['ObjectId']
};


export type GetClassWithRegistrationModalQuery = (
  { __typename?: 'Query' }
  & { class: Maybe<(
    { __typename?: 'Class' }
    & { id: Class['_id'] }
    & { course: (
      { __typename?: 'Course' }
      & Pick<Course, 'type'>
    ), lessons: Maybe<Array<(
      { __typename?: 'Lesson' }
      & Pick<Lesson, 'lessonNumber' | 'lessonStart' | 'lessonEnd' | 'maximumClassCapacity'>
      & { id: Lesson['_id'] }
      & { attendance: Maybe<Array<(
        { __typename?: 'Attendance' }
        & { id: Attendance['_id'] }
      )>> }
    )>>, eligibility: Maybe<Array<(
      { __typename?: 'ClassEligibility' }
      & Pick<ClassEligibility, 'stage' | 'lessonDates'>
    )>> }
  )> }
);

export type ExportClassCsvWithIndexQueryVariables = {
  filterInput?: Maybe<ClassFilterInput>
};


export type ExportClassCsvWithIndexQuery = (
  { __typename?: 'Query' }
  & { csv: Maybe<(
    { __typename?: 'Csv' }
    & Pick<Csv, 'url'>
  )> }
);

export type ValidateIntegrationAccessTokenMutationVariables = {
  input: ValidateIntegrationAccessTokenCommand
};


export type ValidateIntegrationAccessTokenMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'validateIntegrationAccessToken'>
);

export type DeleteDraftClassSubscriptionVariables = {};


export type DeleteDraftClassSubscription = (
  { __typename?: 'Subscription' }
  & Pick<Subscription, 'deleteDraftClass'>
);

export type AttendanceFieldsFragment = (
  { __typename?: 'Attendance' }
  & Pick<Attendance, 'status' | 'type' | 'startDateTime'>
  & { id: Attendance['_id'] }
  & { linkInvoice: Maybe<(
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'status'>
    & { id: Invoice['_id'] }
  )>, remarks: Maybe<Array<(
    { __typename?: 'Remark' }
    & RemarkFieldsFragment
  )>>, enrollment: Maybe<(
    { __typename?: 'Enrollment' }
    & Pick<Enrollment, 'type' | 'status' | 'startDate' | 'endDate' | 'preferredLearningArrangement' | 'group'>
  )>, linkedAttendance: Maybe<(
    { __typename?: 'Attendance' }
    & Pick<Attendance, 'startDateTime'>
    & { class: (
      { __typename?: 'Class' }
      & Pick<Class, 'name'>
    ) }
  )>, student: Maybe<(
    { __typename?: 'Student' }
    & Pick<Student, 'fullName' | 'gender' | 'avatarUrl' | 'userId' | 'alternateUserId'>
    & { id: Student['_id'] }
    & { primarySchool: Maybe<(
      { __typename?: 'School' }
      & Pick<School, 'name'>
      & { id: School['_id'] }
    )>, secondarySchool: Maybe<(
      { __typename?: 'School' }
      & Pick<School, 'name'>
      & { id: School['_id'] }
    )>, academicLevel: Maybe<(
      { __typename?: 'AcademicLevel' }
      & Pick<AcademicLevel, 'educationStage'>
      & { id: AcademicLevel['_id'] }
    )>, enrollments: Maybe<Array<(
      { __typename?: 'Enrollment' }
      & Pick<Enrollment, 'isReserved' | 'group'>
      & { class: (
        { __typename?: 'Class' }
        & Pick<Class, '_id'>
      ) }
    )>> }
  )> }
);

export type GetAttendanceByLessonIdQueryVariables = {
  id: Scalars['ObjectId'],
  type?: Maybe<Array<AttendanceType>>,
  status?: Maybe<Array<AttendanceStatus>>
};


export type GetAttendanceByLessonIdQuery = (
  { __typename?: 'Query' }
  & { lesson: Maybe<(
    { __typename?: 'Lesson' }
    & Pick<Lesson, 'status' | 'lessonStart' | 'lessonEnd' | 'lessonDay' | 'lessonNumber' | 'lessonNotes'>
    & { id: Lesson['_id'] }
    & { teacher: Maybe<(
      { __typename?: 'SystemUser' }
      & Pick<SystemUser, 'fullName' | 'salutation'>
      & { id: SystemUser['_id'] }
    )>, venue: Maybe<(
      { __typename?: 'Venue' }
      & BasicVenueFieldsFragment
    )>, class: Maybe<(
      { __typename?: 'Class' }
      & Pick<Class, 'learningArrangement' | 'type'>
      & { id: Class['_id'] }
      & { lessons: Maybe<Array<(
        { __typename?: 'Lesson' }
        & Pick<Lesson, 'lessonNumber' | 'lessonStart'>
      )>> }
    )>, attendance: Maybe<Array<(
      { __typename?: 'Attendance' }
      & Pick<Attendance, 'status' | 'type' | 'startDateTime' | 'learningArrangement' | 'lessonMode' | 'group'>
      & { id: Attendance['_id'] }
      & { linkInvoice: Maybe<(
        { __typename?: 'Invoice' }
        & Pick<Invoice, 'status' | 'dueDate'>
        & { id: Invoice['_id'] }
      )>, remarks: Maybe<Array<(
        { __typename?: 'Remark' }
        & RemarkFieldsFragment
      )>>, enrollment: Maybe<(
        { __typename?: 'Enrollment' }
        & Pick<Enrollment, 'type' | 'status' | 'startDate' | 'endDate' | 'isFullEnrollment' | 'preferredLearningArrangement' | 'group' | 'followUpStatus' | 'followUpStatusLastModifiedDateTime'>
        & { id: Enrollment['_id'] }
        & { suitability: Maybe<(
          { __typename?: 'EnrollmentSuitability' }
          & Pick<EnrollmentSuitability, 'status'>
        )>, followUpStatusLastModifiedBy: Maybe<(
          { __typename?: 'SystemUser' }
          & CreateByFieldsFragment
        )> }
      )>, linkedAttendance: Maybe<(
        { __typename?: 'Attendance' }
        & Pick<Attendance, 'startDateTime'>
        & { class: (
          { __typename?: 'Class' }
          & Pick<Class, 'name'>
        ) }
      )>, previousLessonAttendance: Maybe<(
        { __typename?: 'Attendance' }
        & Pick<Attendance, 'type' | 'status'>
        & { class: (
          { __typename?: 'Class' }
          & Pick<Class, 'shorthand' | 'name'>
        ) }
      )>, student: Maybe<(
        { __typename?: 'Student' }
        & Pick<Student, 'fullName' | 'gender' | 'avatarUrl' | 'userId' | 'alternateUserId'>
        & { id: Student['_id'] }
        & { primarySchool: Maybe<(
          { __typename?: 'School' }
          & Pick<School, 'name'>
          & { id: School['_id'] }
        )>, secondarySchool: Maybe<(
          { __typename?: 'School' }
          & Pick<School, 'name'>
          & { id: School['_id'] }
        )>, academicLevel: Maybe<(
          { __typename?: 'AcademicLevel' }
          & Pick<AcademicLevel, 'educationStage'>
          & { id: AcademicLevel['_id'] }
        )>, enrollments: Maybe<Array<(
          { __typename?: 'Enrollment' }
          & Pick<Enrollment, 'group' | 'isReserved'>
          & { class: (
            { __typename?: 'Class' }
            & Pick<Class, '_id'>
          ) }
        )>> }
      )>, linkArrangement: Maybe<(
        { __typename?: 'Arrangement' }
        & Pick<Arrangement, 'remarks'>
        & { fromClass: Maybe<(
          { __typename?: 'Class' }
          & Pick<Class, 'shorthand'>
        )> }
      )>, statusTag: Maybe<(
        { __typename?: 'AttendanceStatusTag' }
        & Pick<AttendanceStatusTag, 'tags' | 'description'>
      )> }
    )>>, attendanceSnapshot: Maybe<Array<(
      { __typename?: 'AttendanceSnapshot' }
      & Pick<AttendanceSnapshot, 'status' | 'type' | 'startDateTime' | 'lessonMode' | 'group'>
      & { id: AttendanceSnapshot['_id'] }
      & { remarks: Maybe<Array<(
        { __typename?: 'Remark' }
        & RemarkFieldsFragment
      )>>, student: Maybe<(
        { __typename?: 'Student' }
        & Pick<Student, 'fullName' | 'gender' | 'avatarUrl' | 'userId' | 'alternateUserId'>
        & { id: Student['_id'] }
        & { primarySchool: Maybe<(
          { __typename?: 'School' }
          & Pick<School, 'name'>
          & { id: School['_id'] }
        )>, secondarySchool: Maybe<(
          { __typename?: 'School' }
          & Pick<School, 'name'>
          & { id: School['_id'] }
        )>, academicLevel: Maybe<(
          { __typename?: 'AcademicLevel' }
          & Pick<AcademicLevel, 'educationStage'>
          & { id: AcademicLevel['_id'] }
        )>, enrollments: Maybe<Array<(
          { __typename?: 'Enrollment' }
          & Pick<Enrollment, 'group' | 'isReserved'>
          & { class: (
            { __typename?: 'Class' }
            & Pick<Class, '_id'>
          ) }
        )>> }
      )> }
    )>> }
  )> }
);

export type GetEnrollmentSuitabilityWithAttendancesQueryVariables = {
  filter: StudentAttendanceFilterInput
};


export type GetEnrollmentSuitabilityWithAttendancesQuery = (
  { __typename?: 'Query' }
  & { attendances: Maybe<(
    { __typename?: 'AttendancePage' }
    & { items: Array<(
      { __typename?: 'Attendance' }
      & { id: Attendance['_id'] }
      & { class: (
        { __typename?: 'Class' }
        & Pick<Class, 'type'>
      ), student: Maybe<(
        { __typename?: 'Student' }
        & Pick<Student, 'fullName' | 'userId' | 'avatarUrl' | 'gender'>
        & { id: Student['_id'] }
      )>, enrollment: Maybe<(
        { __typename?: 'Enrollment' }
        & { id: Enrollment['_id'] }
        & { suitability: Maybe<(
          { __typename?: 'EnrollmentSuitability' }
          & Pick<EnrollmentSuitability, 'status'>
          & { remark: Maybe<(
            { __typename?: 'Remark' }
            & Pick<Remark, 'content'>
            & { id: Remark['_id'] }
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type UpdateEnrollmentSuitabilityMutationVariables = {
  input: UpdateEnrollmentSuitabilityInput
};


export type UpdateEnrollmentSuitabilityMutation = (
  { __typename?: 'Mutation' }
  & { updateEnrollmentSuitability: Maybe<(
    { __typename?: 'Enrollment' }
    & { id: Enrollment['_id'] }
  )> }
);

export type GetClassSeatViewDataQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetClassSeatViewDataQuery = (
  { __typename?: 'Query' }
  & { class: Maybe<(
    { __typename?: 'Class' }
    & Pick<Class, 'name' | 'maximumClassCapacity' | 'maximumMakeUpCapacity' | 'capacityUsed'>
    & { id: Class['_id'] }
    & { capacity: Maybe<(
      { __typename?: 'ClassCapacityDTO' }
      & CapacityFieldsFragment
    )> }
  )>, registrations: Maybe<(
    { __typename?: 'RegistrationPage' }
    & Pick<RegistrationPage, 'total'>
  )> }
);

export type LessonInClassFieldsFragment = (
  { __typename?: 'Lesson' }
  & Pick<Lesson, 'status' | 'lessonNumber' | 'lessonStart' | 'lessonEnd' | 'lessonNotes' | 'isHoliday'>
  & { id: Lesson['_id'] }
  & { academicWeek: Maybe<(
    { __typename?: 'AcademicWeek' }
    & Pick<AcademicWeek, 'weekNumber'>
  )>, teacher: Maybe<(
    { __typename?: 'SystemUser' }
    & BasicTeacherFieldsFragment
  )>, venue: Maybe<(
    { __typename?: 'Venue' }
    & Pick<Venue, 'shorthand' | 'unitNo'>
    & { branch: Maybe<(
      { __typename?: 'Branch' }
      & Pick<Branch, 'name' | 'alias'>
    )> }
  )> }
);

export type GetLessonsInClassByIdQueryVariables = {
  classId: Scalars['ObjectId']
};


export type GetLessonsInClassByIdQuery = (
  { __typename?: 'Query' }
  & { class: Maybe<(
    { __typename?: 'Class' }
    & Pick<Class, 'type' | 'status' | 'isAdHoc' | 'learningArrangement' | 'name'>
    & { id: Class['_id'] }
    & { academicYear: (
      { __typename?: 'AcademicYear' }
      & Pick<AcademicYear, 'year'>
      & { id: AcademicYear['_id'] }
      & { period: (
        { __typename?: 'DateTimeRange' }
        & Pick<DateTimeRange, 'end' | 'start'>
      ) }
    ), term: Maybe<(
      { __typename?: 'Term' }
      & BasicTermFieldsFragment
    )>, course: (
      { __typename?: 'Course' }
      & Pick<Course, 'name' | 'nameShort' | 'type'>
      & { id: Course['_id'] }
    ), lessons: Maybe<Array<(
      { __typename?: 'Lesson' }
      & Pick<Lesson, 'isHoliday' | 'isHolidayEffectedAdditionalLesson'>
      & { holidays: Maybe<Array<(
        { __typename?: 'Holiday' }
        & Pick<Holiday, 'name'>
      )>> }
      & LessonInClassFieldsFragment
    )>> }
  )> }
);

export type GetAcademicYearsForImportClassesQueryVariables = {};


export type GetAcademicYearsForImportClassesQuery = (
  { __typename?: 'Query' }
  & { academicYears: Maybe<Array<(
    { __typename?: 'AcademicYear' }
    & Pick<AcademicYear, 'year'>
    & { id: AcademicYear['_id'] }
    & { academicWeeks: Array<(
      { __typename?: 'AcademicWeekWithId' }
      & Pick<AcademicWeekWithId, 'weekNumber' | 'startDate' | 'endDate'>
    )> }
  )>> }
);

export type GetCoursesForImportClassesQueryVariables = {
  filterInput?: Maybe<CourseFilterInput>
};


export type GetCoursesForImportClassesQuery = (
  { __typename?: 'Query' }
  & { courses: Maybe<(
    { __typename?: 'CoursePage' }
    & Pick<CoursePage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'Course' }
      & Pick<Course, 'name' | 'nameShort' | 'type'>
      & { id: Course['_id'] }
      & { courseFee: Maybe<(
        { __typename?: 'CourseFee' }
        & { standardCourseFees: Array<(
          { __typename?: 'StandardCourseFee' }
          & Pick<StandardCourseFee, 'amount' | 'type'>
        )> }
      )> }
    )> }
  )> }
);

export type ImportClassesMutationVariables = {
  input: BulkCreateClassCommand
};


export type ImportClassesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'bulkCreateClass'>
);

export type BulkCreateHistoryFieldsFragment = (
  { __typename?: 'BulkCreateHistory' }
  & Pick<BulkCreateHistory, 'status' | 'total' | 'failed' | 'processed' | 'type' | 'isExportFailedDocuments'>
  & { id: BulkCreateHistory['_id'] }
  & { createdBy: Maybe<(
    { __typename?: 'SystemUser' }
    & { id: SystemUser['_id'] }
    & UserFieldsFragment
  )> }
);

export type PostImportClassHistorySubscriptionVariables = {};


export type PostImportClassHistorySubscription = (
  { __typename?: 'Subscription' }
  & { postImportClassHistory: (
    { __typename?: 'BulkCreateHistory' }
    & BulkCreateHistoryFieldsFragment
  ) }
);

export type ExportFailedImportClassesMutationVariables = {
  id: Scalars['ObjectId']
};


export type ExportFailedImportClassesMutation = (
  { __typename?: 'Mutation' }
  & { exportFailedImportClasses: Maybe<(
    { __typename?: 'Csv' }
    & Pick<Csv, 'url'>
  )> }
);

export type GetVenuesForImportClassesQueryVariables = {
  showArchive?: Maybe<Scalars['Boolean']>
};


export type GetVenuesForImportClassesQuery = (
  { __typename?: 'Query' }
  & { venues: Maybe<Array<(
    { __typename?: 'Venue' }
    & Pick<Venue, 'shorthand' | 'unitNo' | 'capacity'>
    & { id: Venue['_id'] }
    & { address: (
      { __typename?: 'Address' }
      & Pick<Address, 'line1' | 'link' | 'postalCode'>
    ), branch: Maybe<(
      { __typename?: 'Branch' }
      & Pick<Branch, 'name' | 'alias'>
    )> }
  )>> }
);

export type GetUserCustomiseColumnsQueryVariables = {
  search?: Maybe<Scalars['String']>,
  filterInput?: Maybe<UserCustomiseColumnFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetUserCustomiseColumnsQuery = (
  { __typename?: 'Query' }
  & { userCustomiseColumns: Maybe<(
    { __typename?: 'UserCustomiseColumnPage' }
    & { items: Array<(
      { __typename?: 'UserCustomiseColumn' }
      & Pick<UserCustomiseColumn, 'tableName' | 'tableShowName' | 'columnName' | 'columnShowName' | 'isVisible' | 'isRequired' | 'weight'>
    )> }
  )> }
);

export type UpsertUserCustomiseColumnsMutationVariables = {
  input: Array<UpsertUserCustomiseColumnInput>
};


export type UpsertUserCustomiseColumnsMutation = (
  { __typename?: 'Mutation' }
  & { upsertUserCustomiseColumns: Maybe<Array<(
    { __typename?: 'UserCustomiseColumn' }
    & Pick<UserCustomiseColumn, 'tableName' | 'tableShowName' | 'columnName' | 'columnShowName' | 'isVisible' | 'isRequired' | 'weight'>
  )>> }
);

export type ResetDefaultMutationVariables = {
  input: ResetDefaultInput
};


export type ResetDefaultMutation = (
  { __typename?: 'Mutation' }
  & { resetDefault: Maybe<Array<(
    { __typename?: 'UserCustomiseColumn' }
    & Pick<UserCustomiseColumn, 'tableName' | 'tableShowName' | 'columnName' | 'columnShowName' | 'isVisible' | 'isRequired' | 'weight'>
  )>> }
);

export type GetCourseFilterOptionsQueryVariables = {};


export type GetCourseFilterOptionsQuery = (
  { __typename?: 'Query' }
  & { academicLevels: Maybe<Array<(
    { __typename?: 'AcademicLevel' }
    & BasicAcademicLevelFieldsFragment
  )>>, academicStreams: Maybe<Array<(
    { __typename?: 'ReferenceData' }
    & Pick<ReferenceData, 'key' | 'value'>
    & { id: ReferenceData['_id'] }
  )>> }
);

export type VoidCourseMutationVariables = {
  input: VoidInput
};


export type VoidCourseMutation = (
  { __typename?: 'Mutation' }
  & { voidCourse: Maybe<(
    { __typename?: 'Course' }
    & { id: Course['_id'] }
  )> }
);

export type CreateRegularCourseMutationVariables = {
  input: CreateRegularCourseInput
};


export type CreateRegularCourseMutation = (
  { __typename?: 'Mutation' }
  & { createRegularCourse: Maybe<(
    { __typename?: 'Course' }
    & Pick<Course, 'type'>
    & { id: Course['_id'] }
  )> }
);

export type CreateWorkShopCourseMutationVariables = {
  input: CreateWorkShopCourseInput
};


export type CreateWorkShopCourseMutation = (
  { __typename?: 'Mutation' }
  & { createWorkShopCourse: Maybe<(
    { __typename?: 'Course' }
    & Pick<Course, 'type'>
    & { id: Course['_id'] }
  )> }
);

export type UpdateCourseMutationVariables = {
  input: UpdateCourseInput
};


export type UpdateCourseMutation = (
  { __typename?: 'Mutation' }
  & { updateCourse: Maybe<(
    { __typename?: 'Course' }
    & Pick<Course, 'name' | 'nameShort' | 'description' | 'status' | 'colorCode' | 'createDateTime' | 'learningResourceUrl' | 'gamePlanLink' | 'academicStreams' | 'type' | 'termsAndConditions' | 'disclaimer' | 'duration'>
    & { id: Course['_id'] }
    & { createdBy: Maybe<(
      { __typename?: 'SystemUser' }
      & Pick<SystemUser, 'email'>
      & { id: SystemUser['_id'] }
    )>, academicLevels: Array<(
      { __typename?: 'AcademicLevel' }
      & Pick<AcademicLevel, 'name'>
      & { id: AcademicLevel['_id'] }
    )>, courseFee: Maybe<(
      { __typename?: 'CourseFee' }
      & Pick<CourseFee, 'isApplicable' | 'billingFrequency'>
      & { standardCourseFees: Array<(
        { __typename?: 'StandardCourseFee' }
        & Pick<StandardCourseFee, 'type' | 'amount'>
      )>, otherFees: Maybe<Array<(
        { __typename?: 'Fee' }
        & Pick<Fee, 'name' | 'amount' | 'isApplicable'>
      )>> }
    )>, makeUpCredits: (
      { __typename?: 'CourseReplacementCredit' }
      & Pick<CourseReplacementCredit, 'term1' | 'term2' | 'term3' | 'term4'>
    ) }
  )> }
);

export type AcademicFieldsFragment = (
  { __typename?: 'AcademicLevel' }
  & Pick<AcademicLevel, 'name' | 'alias' | 'educationStage'>
  & { id: AcademicLevel['_id'] }
);

export type CourseShortInfoFragment = (
  { __typename?: 'Course' }
  & Pick<Course, 'name' | 'nameShort' | 'avatarUrl' | 'status' | 'type' | 'createDateTime'>
  & { id: Course['_id'] }
  & { createdBy: Maybe<(
    { __typename?: 'SystemUser' }
    & CreateByFieldsFragment
  )> }
);

export type GetCourseOfClassQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetCourseOfClassQuery = (
  { __typename?: 'Query' }
  & { course: Maybe<(
    { __typename?: 'Course' }
    & Pick<Course, 'name' | 'nameShort' | 'description' | 'subject' | 'status' | 'nextRunNumber' | 'numberOfLessons' | 'avatarUrl' | 'isRequireFeedback' | 'learningResourceUrl' | 'gamePlanLink' | 'termsAndConditions' | 'disclaimer' | 'academicStreams' | 'type' | 'isSkipScheduledHoliday' | 'duration'>
    & { id: Course['_id'] }
    & { academicLevels: Array<(
      { __typename?: 'AcademicLevel' }
      & Pick<AcademicLevel, 'name'>
      & { id: AcademicLevel['_id'] }
    )>, courseFee: Maybe<(
      { __typename?: 'CourseFee' }
      & Pick<CourseFee, 'isApplicable' | 'billingFrequency'>
      & { standardCourseFees: Array<(
        { __typename?: 'StandardCourseFee' }
        & Pick<StandardCourseFee, 'type' | 'amount'>
      )>, otherFees: Maybe<Array<(
        { __typename?: 'Fee' }
        & Pick<Fee, 'name' | 'amount' | 'isApplicable'>
      )>> }
    )>, makeUpCredits: (
      { __typename?: 'CourseReplacementCredit' }
      & Pick<CourseReplacementCredit, 'term1' | 'term2' | 'term3' | 'term4' | 'workshop'>
    ) }
  )> }
);

export type GetCourseQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetCourseQuery = (
  { __typename?: 'Query' }
  & { course: Maybe<(
    { __typename?: 'Course' }
    & Pick<Course, 'name' | 'nameShort' | 'description' | 'subject' | 'status' | 'voidRemarks' | 'numberOfLessons' | 'nextRunNumber' | 'colorCode' | 'avatarUrl' | 'courseMaterial' | 'createDateTime' | 'learningResourceUrl' | 'gamePlanLink' | 'termsAndConditions' | 'disclaimer' | 'academicStreams' | 'type' | 'onGoingClassNumber' | 'isSkipScheduledHoliday' | 'duration' | 'isRequireFeedback'>
    & { id: Course['_id'] }
    & { createdBy: Maybe<(
      { __typename?: 'SystemUser' }
      & CreateByFieldsFragment
    )>, academicLevels: Array<(
      { __typename?: 'AcademicLevel' }
      & AcademicLevelFieldsFragment
    )>, courseFee: Maybe<(
      { __typename?: 'CourseFee' }
      & Pick<CourseFee, 'isApplicable' | 'billingFrequency'>
      & { standardCourseFees: Array<(
        { __typename?: 'StandardCourseFee' }
        & Pick<StandardCourseFee, 'type' | 'amount'>
      )>, otherFees: Maybe<Array<(
        { __typename?: 'Fee' }
        & Pick<Fee, 'name' | 'amount' | 'isApplicable'>
      )>> }
    )>, makeUpCredits: (
      { __typename?: 'CourseReplacementCredit' }
      & Pick<CourseReplacementCredit, 'term1' | 'term2' | 'term3' | 'term4' | 'workshop'>
    ) }
  )> }
);

export type GetOptionCoursesQueryVariables = {
  search?: Maybe<Scalars['String']>,
  filterInput?: Maybe<CourseFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetOptionCoursesQuery = (
  { __typename?: 'Query' }
  & { courses: Maybe<(
    { __typename?: 'CoursePage' }
    & Pick<CoursePage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'Course' }
      & Pick<Course, 'name'>
      & { id: Course['_id'] }
    )> }
  )> }
);

export type GetCoursesQueryVariables = {
  search?: Maybe<Scalars['String']>,
  filterInput?: Maybe<CourseFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetCoursesQuery = (
  { __typename?: 'Query' }
  & { courses: Maybe<(
    { __typename?: 'CoursePage' }
    & Pick<CoursePage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'Course' }
      & CourseFieldsFragment
    )> }
  )> }
);

export type CourseFieldsFragment = (
  { __typename?: 'Course' }
  & Pick<Course, 'name' | 'nameShort' | 'onGoingClassNumber' | 'avatarUrl'>
  & { id: Course['_id'] }
);

export type CourseCsvQueryVariables = {
  search?: Maybe<Scalars['String']>,
  filterInput?: Maybe<CourseFilterInput>
};


export type CourseCsvQuery = (
  { __typename?: 'Query' }
  & { csv: Maybe<(
    { __typename?: 'Csv' }
    & Pick<Csv, 'url'>
  )> }
);

export type AutocompleteCourseInfoQueryVariables = {
  type: CourseType
};


export type AutocompleteCourseInfoQuery = (
  { __typename?: 'Query' }
  & { autocompleteCourse: (
    { __typename?: 'CreateCourseOutput' }
    & Pick<CreateCourseOutput, 'name' | 'nameShort' | 'type' | 'description' | 'academicLevelIds' | 'academicStreams' | 'avatarUrl' | 'termsAndConditions' | 'disclaimer'>
    & { makeUpCredits: (
      { __typename?: 'MakeUpCreditOutput' }
      & Pick<MakeUpCreditOutput, 'term1' | 'term2' | 'term3' | 'term4'>
    ), courseFee: (
      { __typename?: 'CourseFeeOutput' }
      & Pick<CourseFeeOutput, 'isApplicable' | 'billingFrequency'>
      & { standardCourseFees: Array<(
        { __typename?: 'StandardCourseFee' }
        & Pick<StandardCourseFee, 'type' | 'amount'>
      )>, otherFees: Maybe<Array<(
        { __typename?: 'OtherFeeOutput' }
        & Pick<OtherFeeOutput, 'name' | 'amount' | 'isApplicable'>
      )>> }
    ) }
  ) }
);

export type EnrollmentHistoryQueryVariables = {
  id: Scalars['ObjectId']
};


export type EnrollmentHistoryQuery = (
  { __typename?: 'Query' }
  & { course: Maybe<(
    { __typename?: 'Course' }
    & Pick<Course, 'name' | 'nameShort' | 'description' | 'status'>
    & { id: Course['_id'] }
  )> }
);

export type CourseAcademicYearQueryVariables = {
  year: Scalars['Int']
};


export type CourseAcademicYearQuery = (
  { __typename?: 'Query' }
  & { academicYear: Maybe<(
    { __typename?: 'AcademicYear' }
    & Pick<AcademicYear, 'year'>
    & { id: AcademicYear['_id'] }
    & { period: (
      { __typename?: 'DateTimeRange' }
      & Pick<DateTimeRange, 'start' | 'end'>
    ) }
  )> }
);

export type GetCourseInfoQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetCourseInfoQuery = (
  { __typename?: 'Query' }
  & { course: Maybe<(
    { __typename?: 'Course' }
    & Pick<Course, 'name' | 'nameShort' | 'type' | 'status' | 'description' | 'avatarUrl' | 'onGoingClassNumber' | 'voidRemarks' | 'createDateTime'>
    & { id: Course['_id'] }
    & { academicLevels: Array<(
      { __typename?: 'AcademicLevel' }
      & Pick<AcademicLevel, 'name'>
      & { id: AcademicLevel['_id'] }
    )>, createdBy: Maybe<(
      { __typename?: 'SystemUser' }
      & CreateByFieldsFragment
    )> }
  )> }
);

export type GetListCourseWithIndexDataQueryVariables = {
  filterInput?: Maybe<CourseFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetListCourseWithIndexDataQuery = (
  { __typename?: 'Query' }
  & { coursesWithIndex: Maybe<(
    { __typename?: 'CourseIndexPage' }
    & Pick<CourseIndexPage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'CourseIndex' }
      & Pick<CourseIndex, 'avatarUrl' | 'name' | 'academicStreams' | 'courseFeeWithSort' | 'nameShort' | 'status' | 'type' | 'ongoingClasses' | 'trialFee' | 'additionalLessonFee' | 'depositFee' | 'isVisible' | 'materialsFee' | 'registrationFee' | 'registrations' | 'trials' | 'reservations' | 'withdrawing' | 'withdrawals' | 'courseCompleted' | 'createDateTime' | 'lastModifiedFullName' | 'lastModifiedDateTime' | 'description'>
      & { id: CourseIndex['_id'] }
      & { academicLevels: Maybe<Array<(
        { __typename?: 'CourseAcademicLevelIndex' }
        & Pick<CourseAcademicLevelIndex, 'name'>
      )>>, courseFee: Maybe<(
        { __typename?: 'CourseFeeDO' }
        & Pick<CourseFeeDo, 'billingFrequency'>
      )>, createdBy: Maybe<(
        { __typename?: 'SystemUser' }
        & Pick<SystemUser, 'fullName'>
      )>, makeUpCredits: (
        { __typename?: 'MakeUpCredits' }
        & Pick<MakeUpCredits, 'term1' | 'term2' | 'term3' | 'term4' | 'workshop'>
      ) }
    )> }
  )> }
);

export type ExportCourseCsvWithIndexQueryVariables = {
  filterInput?: Maybe<CourseFilterInput>
};


export type ExportCourseCsvWithIndexQuery = (
  { __typename?: 'Query' }
  & { csv: Maybe<(
    { __typename?: 'Csv' }
    & Pick<Csv, 'url'>
  )> }
);

export type UpdateEnrollmentFollowUpStatusMutationVariables = {
  input: UpdateEnrollmentFollowUpStatusInput
};


export type UpdateEnrollmentFollowUpStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateEnrollmentFollowUpStatus: Maybe<(
    { __typename?: 'Enrollment' }
    & Pick<Enrollment, 'startDate' | 'followUpStatus' | 'followUpStatusLastModifiedDateTime'>
    & { id: Enrollment['_id'] }
    & { followUpStatusLastModifiedBy: Maybe<(
      { __typename?: 'SystemUser' }
      & CreateByFieldsFragment
    )> }
  )> }
);

export type GetEnrollmentWithFollowUpStatusQueryVariables = {
  enrollmentId: Scalars['ObjectId']
};


export type GetEnrollmentWithFollowUpStatusQuery = (
  { __typename?: 'Query' }
  & { enrollment: Maybe<(
    { __typename?: 'Enrollment' }
    & Pick<Enrollment, 'followUpStatus' | 'followUpStatusLastModifiedDateTime'>
    & { id: Enrollment['_id'] }
    & { followUpStatusLastModifiedBy: Maybe<(
      { __typename?: 'SystemUser' }
      & CreateByFieldsFragment
    )> }
  )> }
);

export type GetActivitiesBySourceIdsQueryVariables = {
  query: ActivityLogListQuery,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetActivitiesBySourceIdsQuery = (
  { __typename?: 'Query' }
  & { activityLogs: Maybe<(
    { __typename?: 'ActivityLogPage' }
    & Pick<ActivityLogPage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'ActivityLog' }
      & Pick<ActivityLog, 'createDateTime' | 'lastModifiedDateTime' | 'type' | 'subType' | 'keywords'>
      & { id: ActivityLog['_id'] }
      & { createdBy: Maybe<(
        { __typename?: 'SystemUser' }
        & UserFieldsFragment
      )>, lastModifiedBy: Maybe<(
        { __typename?: 'SystemUser' }
        & UserFieldsFragment
      )>, adds: Maybe<Array<(
        { __typename?: 'SystemUser' }
        & Pick<SystemUser, 'fullName'>
        & { id: SystemUser['_id'] }
      )>>, removes: Maybe<Array<(
        { __typename?: 'SystemUser' }
        & Pick<SystemUser, 'fullName'>
        & { id: SystemUser['_id'] }
      )>>, previousAndUpdateValue: Maybe<(
        { __typename?: 'TaskActivityLogDateValue' }
        & Pick<TaskActivityLogDateValue, 'previousValue' | 'value'>
      )> }
    )> }
  )> }
);

export type TaskChecklistItemFieldsFragment = (
  { __typename?: 'TaskChecklistItem' }
  & Pick<TaskChecklistItem, 'name' | 'isDone' | 'assigneeIds'>
  & { id: TaskChecklistItem['_id'] }
  & { assignees: Maybe<Array<(
    { __typename?: 'SystemUser' }
    & Pick<SystemUser, '_id' | 'initials' | 'fullName' | 'avatarUrl'>
  )>> }
);

export type TaskChecklistFieldsFragment = (
  { __typename?: 'TaskChecklist' }
  & Pick<TaskChecklist, 'name' | 'assigneeIds'>
  & { id: TaskChecklist['_id'] }
  & { assignees: Maybe<Array<(
    { __typename?: 'SystemUser' }
    & Pick<SystemUser, '_id' | 'initials' | 'fullName' | 'avatarUrl'>
  )>>, items: Maybe<Array<(
    { __typename?: 'TaskChecklistItem' }
    & TaskChecklistItemFieldsFragment
  )>> }
);

export type TaskFieldsFragment = (
  { __typename?: 'Task' }
  & Pick<Task, 'listId' | 'type' | 'status' | 'waStatus' | 'emailStatus' | 'name' | 'isTemplate' | 'assigneeIds' | 'lastModifiedDateTime' | 'dueDate' | 'createDateTime' | 'description'>
  & { id: Task['_id'] }
  & { creationContext: Maybe<(
    { __typename?: 'TaskCreationContext' }
    & Pick<TaskCreationContext, 'type' | 'isTransferLessonReminder'>
  )>, assignees: Maybe<Array<(
    { __typename?: 'SystemUser' }
    & Pick<SystemUser, '_id' | 'initials' | 'fullName' | 'avatarUrl'>
  )>>, balance: Maybe<(
    { __typename?: 'Balance' }
    & Pick<Balance, 'balance'>
    & { id: Balance['_id'] }
    & { parent: Maybe<(
      { __typename?: 'Parent' }
      & Pick<Parent, 'fullName'>
      & { id: Parent['_id'] }
      & { mobile: (
        { __typename?: 'Telephone' }
        & Pick<Telephone, 'countryCode' | 'number'>
      ), linkedWhatsappAccount: Maybe<(
        { __typename?: 'WhatsappAccount' }
        & { id: WhatsappAccount['_id'] }
        & { number: Maybe<(
          { __typename?: 'Telephone' }
          & Pick<Telephone, 'countryCode' | 'number'>
        )> }
      )> }
    )> }
  )>, remarks: Maybe<Array<(
    { __typename?: 'Remark' }
    & Pick<Remark, 'content' | 'createDateTime'>
    & { id: Remark['_id'] }
    & RemarkFieldsFragment
  )>>, holidays: Maybe<Array<(
    { __typename?: 'Holiday' }
    & Pick<Holiday, 'name'>
    & { period: (
      { __typename?: 'DateRange' }
      & Pick<DateRange, 'start' | 'end'>
    ) }
  )>>, lesson: Maybe<(
    { __typename?: 'Lesson' }
    & Pick<Lesson, 'lessonNumber' | 'lessonStart'>
    & { id: Lesson['_id'] }
    & { holidays: Maybe<Array<(
      { __typename?: 'Holiday' }
      & Pick<Holiday, 'name'>
      & { period: (
        { __typename?: 'DateRange' }
        & Pick<DateRange, 'start' | 'end'>
      ) }
    )>> }
  )>, enrollment: Maybe<(
    { __typename?: 'Enrollment' }
    & Pick<Enrollment, 'status' | 'startDate' | 'followUpStatus' | 'followUpStatusLastModifiedDateTime'>
    & { id: Enrollment['_id'] }
    & { followUpStatusLastModifiedBy: Maybe<(
      { __typename?: 'SystemUser' }
      & UserFieldsFragment
    )>, suitability: Maybe<(
      { __typename?: 'EnrollmentSuitability' }
      & Pick<EnrollmentSuitability, 'status'>
    )>, student: Maybe<(
      { __typename?: 'Student' }
      & Pick<Student, 'fullName' | 'userId'>
      & { id: Student['_id'] }
      & { primaryParent: Maybe<(
        { __typename?: 'Parent' }
        & Pick<Parent, 'fullName'>
        & { id: Parent['_id'] }
        & { mobile: (
          { __typename?: 'Telephone' }
          & Pick<Telephone, 'countryCode' | 'number'>
        ), linkedWhatsappAccount: Maybe<(
          { __typename?: 'WhatsappAccount' }
          & { id: WhatsappAccount['_id'] }
          & { number: Maybe<(
            { __typename?: 'Telephone' }
            & Pick<Telephone, 'countryCode' | 'number'>
          )> }
        )> }
      )> }
    )>, class: (
      { __typename?: 'Class' }
      & Pick<Class, 'shorthand'>
      & { id: Class['_id'] }
    ), registration: Maybe<(
      { __typename?: 'Registration' }
      & Pick<Registration, 'dueDate' | 'name' | 'status'>
      & { id: Registration['_id'] }
      & { reservationsForTrial: Maybe<Array<(
        { __typename?: 'Registration' }
        & Pick<Registration, 'status' | 'name'>
        & { id: Registration['_id'] }
        & { class: Maybe<(
          { __typename?: 'Class' }
          & Pick<Class, 'shorthand'>
          & { id: Class['_id'] }
        )> }
      )>> }
    )>, attendances: Maybe<Array<(
      { __typename?: 'Attendance' }
      & Pick<Attendance, 'startDateTime' | 'status'>
      & { id: Attendance['_id'] }
    )>>, arrangements: Maybe<Array<(
      { __typename?: 'Arrangement' }
      & Pick<Arrangement, 'name' | 'type' | 'newEnrollmentId'>
      & { id: Arrangement['_id'] }
      & { invoice: Maybe<(
        { __typename?: 'Invoice' }
        & Pick<Invoice, 'status'>
      )> }
    )>>, invoices: Maybe<Array<(
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'status' | 'billingPeriod'>
      & { id: Invoice['_id'] }
    )>> }
  )>, registration: Maybe<(
    { __typename?: 'Registration' }
    & Pick<Registration, 'status' | 'name' | 'dueDate'>
    & { id: Registration['_id'] }
    & { student: (
      { __typename?: 'Student' }
      & Pick<Student, 'fullName' | 'userId'>
      & { id: Student['_id'] }
      & { primaryParent: Maybe<(
        { __typename?: 'Parent' }
        & Pick<Parent, 'fullName'>
        & { id: Parent['_id'] }
        & { mobile: (
          { __typename?: 'Telephone' }
          & Pick<Telephone, 'countryCode' | 'number'>
        ), linkedWhatsappAccount: Maybe<(
          { __typename?: 'WhatsappAccount' }
          & { id: WhatsappAccount['_id'] }
          & { number: Maybe<(
            { __typename?: 'Telephone' }
            & Pick<Telephone, 'countryCode' | 'number'>
          )> }
        )> }
      )> }
    ), class: Maybe<(
      { __typename?: 'Class' }
      & Pick<Class, 'shorthand'>
      & { id: Class['_id'] }
    )>, enrollment: Maybe<(
      { __typename?: 'Enrollment' }
      & Pick<Enrollment, 'status' | 'followUpStatus' | 'followUpStatusLastModifiedDateTime'>
      & { id: Enrollment['_id'] }
      & { followUpStatusLastModifiedBy: Maybe<(
        { __typename?: 'SystemUser' }
        & UserFieldsFragment
      )> }
    )> }
  )>, invoice: Maybe<(
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'referenceNumber' | 'dueDate' | 'status' | 'isOnHold' | 'onHoldRemarks'>
    & { id: Invoice['_id'] }
    & { billedFor: Maybe<(
      { __typename?: 'Student' }
      & Pick<Student, 'fullName' | 'userId'>
      & { id: Student['_id'] }
      & { primaryParent: Maybe<(
        { __typename?: 'Parent' }
        & Pick<Parent, 'fullName'>
        & { id: Parent['_id'] }
        & { mobile: (
          { __typename?: 'Telephone' }
          & Pick<Telephone, 'countryCode' | 'number'>
        ), linkedWhatsappAccount: Maybe<(
          { __typename?: 'WhatsappAccount' }
          & { id: WhatsappAccount['_id'] }
          & { number: Maybe<(
            { __typename?: 'Telephone' }
            & Pick<Telephone, 'countryCode' | 'number'>
          )> }
        )> }
      )> }
    )> }
  )>, attendance: Maybe<(
    { __typename?: 'Attendance' }
    & Pick<Attendance, 'status'>
    & { id: Attendance['_id'] }
    & { student: Maybe<(
      { __typename?: 'Student' }
      & Pick<Student, 'fullName' | 'userId'>
      & { id: Student['_id'] }
      & { primaryParent: Maybe<(
        { __typename?: 'Parent' }
        & Pick<Parent, 'fullName'>
        & { id: Parent['_id'] }
        & { mobile: (
          { __typename?: 'Telephone' }
          & Pick<Telephone, 'countryCode' | 'number'>
        ), linkedWhatsappAccount: Maybe<(
          { __typename?: 'WhatsappAccount' }
          & { id: WhatsappAccount['_id'] }
          & { number: Maybe<(
            { __typename?: 'Telephone' }
            & Pick<Telephone, 'countryCode' | 'number'>
          )> }
        )> }
      )> }
    )>, class: (
      { __typename?: 'Class' }
      & Pick<Class, 'shorthand' | 'learningArrangement'>
      & { id: Class['_id'] }
    ), lesson: (
      { __typename?: 'Lesson' }
      & Pick<Lesson, 'lessonStart' | 'lessonEnd' | 'lessonNumber'>
    ), linkArrangement: Maybe<(
      { __typename?: 'Arrangement' }
      & { fromClass: Maybe<(
        { __typename?: 'Class' }
        & Pick<Class, 'shorthand'>
        & { id: Class['_id'] }
      )>, fromLesson: Maybe<(
        { __typename?: 'Lesson' }
        & Pick<Lesson, 'lessonStart' | 'lessonEnd' | 'lessonDay'>
        & { id: Lesson['_id'] }
      )>, toClass: Maybe<(
        { __typename?: 'Class' }
        & Pick<Class, 'shorthand'>
        & { id: Class['_id'] }
      )>, toLesson: Maybe<(
        { __typename?: 'Lesson' }
        & Pick<Lesson, 'lessonStart' | 'lessonEnd' | 'lessonDay'>
        & { id: Lesson['_id'] }
      )> }
    )>, enrollment: Maybe<(
      { __typename?: 'Enrollment' }
      & Pick<Enrollment, 'preferredLearningArrangement'>
    )> }
  )>, pinnedRemark: Maybe<(
    { __typename?: 'Remark' }
    & Pick<Remark, 'content'>
    & { id: Remark['_id'] }
  )>, flagRemark: Maybe<(
    { __typename?: 'Remark' }
    & Pick<Remark, 'content'>
    & { id: Remark['_id'] }
  )>, changeLog: Maybe<(
    { __typename?: 'ClassChangeLog' }
    & Pick<ClassChangeLog, 'type' | 'affectAt' | 'classId' | 'lessonId' | 'previousLearningArrangement' | 'learningArrangement'>
    & { id: ClassChangeLog['_id'] }
    & { previousTeacher: Maybe<(
      { __typename?: 'SystemUser' }
      & Pick<SystemUser, 'userId' | 'fullName' | 'salutation' | 'gender'>
      & { id: SystemUser['_id'] }
    )>, teacher: Maybe<(
      { __typename?: 'SystemUser' }
      & Pick<SystemUser, 'userId' | 'fullName' | 'salutation' | 'gender'>
      & { id: SystemUser['_id'] }
    )>, previousDigitalCredentials: Maybe<(
      { __typename?: 'DigitalClassAccessCredentialsDO' }
      & Pick<DigitalClassAccessCredentialsDo, 'zoomMeetingId' | 'zoomMeetingLink' | 'zoomMeetingPassword' | 'googleMeetId' | 'googleMeetLink'>
    )>, digitalCredentials: Maybe<(
      { __typename?: 'DigitalClassAccessCredentialsDO' }
      & Pick<DigitalClassAccessCredentialsDo, 'zoomMeetingId' | 'zoomMeetingLink' | 'zoomMeetingPassword' | 'googleMeetId' | 'googleMeetLink'>
    )>, previousVenue: Maybe<(
      { __typename?: 'Venue' }
      & Pick<Venue, 'unitNo' | 'shorthand'>
      & { branch: Maybe<(
        { __typename?: 'Branch' }
        & Pick<Branch, 'alias' | 'name'>
      )> }
    )>, venue: Maybe<(
      { __typename?: 'Venue' }
      & Pick<Venue, 'unitNo' | 'shorthand'>
      & { branch: Maybe<(
        { __typename?: 'Branch' }
        & Pick<Branch, 'alias' | 'name'>
      )> }
    )> }
  )>, checklists: Maybe<Array<(
    { __typename?: 'TaskChecklist' }
    & TaskChecklistFieldsFragment
  )>>, userRequest: Maybe<(
    { __typename?: 'UserRequestDTO' }
    & Pick<UserRequestDto, '_id' | 'referenceNumber' | 'startDate' | 'status'>
  )> }
);

export type GetTasksQueryVariables = {
  filterInput?: Maybe<TaskFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  sortInfo?: Maybe<SortInfo>
};


export type GetTasksQuery = (
  { __typename?: 'Query' }
  & { tasks: Maybe<(
    { __typename?: 'TaskPage' }
    & Pick<TaskPage, 'pageSize' | 'pageIndex' | 'total'>
    & { items: Array<(
      { __typename?: 'Task' }
      & TaskFieldsFragment
    )> }
  )> }
);

export type TaskIndexFieldsFragment = (
  { __typename?: 'TaskIndex' }
  & Pick<TaskIndex, 'listId' | 'type' | 'status' | 'waStatus' | 'emailStatus' | 'name' | 'isTemplate' | 'assigneeIds' | 'dueDate' | 'createDateTime' | 'lastModifiedDateTime' | 'description'>
  & { id: TaskIndex['_id'] }
  & { creationContext: Maybe<(
    { __typename?: 'TaskCreationContext' }
    & Pick<TaskCreationContext, 'type' | 'isTransferLessonReminder'>
  )>, student: Maybe<(
    { __typename?: 'TaskStudentIndex' }
    & Pick<TaskStudentIndex, 'fullName' | 'alias' | 'gender' | 'avatarUrl' | 'userId'>
    & { id: TaskStudentIndex['_id'] }
  )>, parent: Maybe<(
    { __typename?: 'TaskParentIndex' }
    & Pick<TaskParentIndex, 'fullName' | 'gender' | 'salutation' | 'avatarUrl'>
    & { id: TaskParentIndex['_id'] }
    & { mobile: (
      { __typename?: 'Telephone' }
      & Pick<Telephone, 'countryCode' | 'number'>
    ) }
  )>, parentWhatsappAccount: Maybe<(
    { __typename?: 'TaskWhatsappAccountIndex' }
    & Pick<TaskWhatsappAccountIndex, 'parentId'>
    & { id: TaskWhatsappAccountIndex['_id'] }
    & { number: Maybe<(
      { __typename?: 'Telephone' }
      & Pick<Telephone, 'countryCode' | 'number'>
    )> }
  )>, balance: Maybe<(
    { __typename?: 'Balance' }
    & Pick<Balance, 'balance'>
    & { id: Balance['_id'] }
  )>, remarks: Maybe<Array<(
    { __typename?: 'Remark' }
    & Pick<Remark, 'content' | 'createDateTime'>
    & { id: Remark['_id'] }
    & RemarkFieldsFragment
  )>>, holidays: Maybe<Array<(
    { __typename?: 'Holiday' }
    & Pick<Holiday, 'name'>
    & { period: (
      { __typename?: 'DateRange' }
      & Pick<DateRange, 'start' | 'end'>
    ) }
  )>>, lesson: Maybe<(
    { __typename?: 'Lesson' }
    & Pick<Lesson, 'lessonNumber' | 'lessonStart'>
    & { id: Lesson['_id'] }
    & { holidays: Maybe<Array<(
      { __typename?: 'Holiday' }
      & Pick<Holiday, 'name'>
      & { period: (
        { __typename?: 'DateRange' }
        & Pick<DateRange, 'start' | 'end'>
      ) }
    )>> }
  )>, enrollment: Maybe<(
    { __typename?: 'Enrollment' }
    & Pick<Enrollment, 'status' | 'startDate' | 'followUpStatus' | 'followUpStatusLastModifiedDateTime'>
    & { id: Enrollment['_id'] }
    & { followUpStatusLastModifiedBy: Maybe<(
      { __typename?: 'SystemUser' }
      & UserFieldsFragment
    )>, suitability: Maybe<(
      { __typename?: 'EnrollmentSuitability' }
      & Pick<EnrollmentSuitability, 'status'>
    )>, class: (
      { __typename?: 'Class' }
      & Pick<Class, 'shorthand'>
      & { id: Class['_id'] }
    ), registration: Maybe<(
      { __typename?: 'Registration' }
      & Pick<Registration, 'dueDate' | 'name' | 'status'>
      & { id: Registration['_id'] }
      & { reservationsForTrial: Maybe<Array<(
        { __typename?: 'Registration' }
        & Pick<Registration, 'status' | 'name'>
        & { id: Registration['_id'] }
        & { class: Maybe<(
          { __typename?: 'Class' }
          & Pick<Class, 'shorthand'>
          & { id: Class['_id'] }
        )> }
      )>> }
    )>, attendances: Maybe<Array<(
      { __typename?: 'Attendance' }
      & Pick<Attendance, 'startDateTime' | 'status'>
      & { id: Attendance['_id'] }
    )>>, arrangements: Maybe<Array<(
      { __typename?: 'Arrangement' }
      & Pick<Arrangement, 'name' | 'type' | 'newEnrollmentId'>
      & { id: Arrangement['_id'] }
      & { invoice: Maybe<(
        { __typename?: 'Invoice' }
        & Pick<Invoice, 'status'>
      )> }
    )>>, invoices: Maybe<Array<(
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'status' | 'billingPeriod'>
      & { id: Invoice['_id'] }
    )>> }
  )>, registration: Maybe<(
    { __typename?: 'Registration' }
    & Pick<Registration, 'status' | 'name' | 'dueDate'>
    & { id: Registration['_id'] }
    & { class: Maybe<(
      { __typename?: 'Class' }
      & Pick<Class, 'shorthand'>
      & { id: Class['_id'] }
    )>, enrollment: Maybe<(
      { __typename?: 'Enrollment' }
      & Pick<Enrollment, 'status' | 'followUpStatus' | 'followUpStatusLastModifiedDateTime'>
      & { id: Enrollment['_id'] }
      & { followUpStatusLastModifiedBy: Maybe<(
        { __typename?: 'SystemUser' }
        & UserFieldsFragment
      )> }
    )> }
  )>, invoice: Maybe<(
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'referenceNumber' | 'dueDate' | 'status' | 'isOnHold' | 'onHoldRemarks'>
    & { id: Invoice['_id'] }
    & { billedFor: Maybe<(
      { __typename?: 'Student' }
      & Pick<Student, 'fullName' | 'userId'>
      & { id: Student['_id'] }
      & { primaryParent: Maybe<(
        { __typename?: 'Parent' }
        & Pick<Parent, 'fullName'>
        & { id: Parent['_id'] }
        & { mobile: (
          { __typename?: 'Telephone' }
          & Pick<Telephone, 'countryCode' | 'number'>
        ), linkedWhatsappAccount: Maybe<(
          { __typename?: 'WhatsappAccount' }
          & { id: WhatsappAccount['_id'] }
          & { number: Maybe<(
            { __typename?: 'Telephone' }
            & Pick<Telephone, 'countryCode' | 'number'>
          )> }
        )> }
      )> }
    )> }
  )>, attendance: Maybe<(
    { __typename?: 'Attendance' }
    & Pick<Attendance, 'status'>
    & { id: Attendance['_id'] }
    & { class: (
      { __typename?: 'Class' }
      & Pick<Class, 'shorthand' | 'learningArrangement'>
      & { id: Class['_id'] }
    ), lesson: (
      { __typename?: 'Lesson' }
      & Pick<Lesson, 'lessonStart' | 'lessonEnd' | 'lessonNumber'>
    ), linkArrangement: Maybe<(
      { __typename?: 'Arrangement' }
      & { fromClass: Maybe<(
        { __typename?: 'Class' }
        & Pick<Class, 'shorthand'>
        & { id: Class['_id'] }
      )>, fromLesson: Maybe<(
        { __typename?: 'Lesson' }
        & Pick<Lesson, 'lessonStart' | 'lessonEnd' | 'lessonDay'>
        & { id: Lesson['_id'] }
      )>, toClass: Maybe<(
        { __typename?: 'Class' }
        & Pick<Class, 'shorthand'>
        & { id: Class['_id'] }
      )>, toLesson: Maybe<(
        { __typename?: 'Lesson' }
        & Pick<Lesson, 'lessonStart' | 'lessonEnd' | 'lessonDay'>
        & { id: Lesson['_id'] }
      )> }
    )>, enrollment: Maybe<(
      { __typename?: 'Enrollment' }
      & Pick<Enrollment, 'preferredLearningArrangement'>
    )> }
  )>, pinnedRemark: Maybe<(
    { __typename?: 'Remark' }
    & Pick<Remark, 'content'>
    & { id: Remark['_id'] }
  )>, flagRemark: Maybe<(
    { __typename?: 'Remark' }
    & Pick<Remark, 'content'>
    & { id: Remark['_id'] }
  )>, userRequest: Maybe<(
    { __typename?: 'UserRequestDTO' }
    & Pick<UserRequestDto, '_id' | 'referenceNumber' | 'startDate' | 'status'>
  )>, class: Maybe<(
    { __typename?: 'TaskClassIndex' }
    & Pick<TaskClassIndex, 'shorthand'>
    & { id: TaskClassIndex['_id'] }
  )>, changeLog: Maybe<(
    { __typename?: 'ClassChangeLog' }
    & Pick<ClassChangeLog, 'type' | 'affectAt' | 'classId' | 'lessonId' | 'previousLearningArrangement' | 'learningArrangement'>
    & { id: ClassChangeLog['_id'] }
    & { previousTeacher: Maybe<(
      { __typename?: 'SystemUser' }
      & Pick<SystemUser, 'userId' | 'fullName' | 'salutation' | 'gender'>
      & { id: SystemUser['_id'] }
    )>, teacher: Maybe<(
      { __typename?: 'SystemUser' }
      & Pick<SystemUser, 'userId' | 'fullName' | 'salutation' | 'gender'>
      & { id: SystemUser['_id'] }
    )>, previousDigitalCredentials: Maybe<(
      { __typename?: 'DigitalClassAccessCredentialsDO' }
      & Pick<DigitalClassAccessCredentialsDo, 'zoomMeetingId' | 'zoomMeetingLink' | 'zoomMeetingPassword' | 'googleMeetId' | 'googleMeetLink'>
    )>, digitalCredentials: Maybe<(
      { __typename?: 'DigitalClassAccessCredentialsDO' }
      & Pick<DigitalClassAccessCredentialsDo, 'zoomMeetingId' | 'zoomMeetingLink' | 'zoomMeetingPassword' | 'googleMeetId' | 'googleMeetLink'>
    )>, previousVenue: Maybe<(
      { __typename?: 'Venue' }
      & Pick<Venue, 'unitNo' | 'shorthand'>
      & { branch: Maybe<(
        { __typename?: 'Branch' }
        & Pick<Branch, 'alias' | 'name'>
      )> }
    )>, venue: Maybe<(
      { __typename?: 'Venue' }
      & Pick<Venue, 'unitNo' | 'shorthand'>
      & { branch: Maybe<(
        { __typename?: 'Branch' }
        & Pick<Branch, 'alias' | 'name'>
      )> }
    )> }
  )> }
);

export type GetIndexTasksQueryVariables = {
  filterInput?: Maybe<TaskFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  sortInfo?: Maybe<SortInfo>
};


export type GetIndexTasksQuery = (
  { __typename?: 'Query' }
  & { tasksWithIndex: Maybe<(
    { __typename?: 'TaskIndexPage' }
    & Pick<TaskIndexPage, 'pageSize' | 'pageIndex' | 'total'>
    & { items: Array<(
      { __typename?: 'TaskIndex' }
      & TaskIndexFieldsFragment
    )> }
  )> }
);

export type GetPendingOverdueTaskCountQueryVariables = {};


export type GetPendingOverdueTaskCountQuery = (
  { __typename?: 'Query' }
  & { getPendingOverdueTask: Array<(
    { __typename?: 'TaskCount' }
    & Pick<TaskCount, 'type' | 'pending' | 'overdue'>
  )> }
);

export type GetTaskQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetTaskQuery = (
  { __typename?: 'Query' }
  & { task: Maybe<(
    { __typename?: 'Task' }
    & TaskFieldsFragment
  )> }
);

export type GetTaskListQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetTaskListQuery = (
  { __typename?: 'Query' }
  & { taskList: Maybe<(
    { __typename?: 'TaskList' }
    & Pick<TaskList, 'name' | 'isAutoGenerate' | 'startAt' | 'endAt' | 'weekDay' | 'generateAt' | 'isPinned'>
    & { id: TaskList['_id'] }
    & { parent: Maybe<(
      { __typename?: 'TaskList' }
      & Pick<TaskList, 'name' | 'isAutoGenerate' | 'startAt' | 'endAt' | 'weekDay' | 'generateAt'>
      & { id: TaskList['_id'] }
    )> }
  )> }
);

export type GetTaskListsQueryVariables = {
  filterInput: TaskListFilterInput,
  pageInfo?: Maybe<PageInfo>,
  sortInfo?: Maybe<SortInfo>
};


export type GetTaskListsQuery = (
  { __typename?: 'Query' }
  & { taskLists: Maybe<(
    { __typename?: 'TaskListPage' }
    & Pick<TaskListPage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'TaskList' }
      & Pick<TaskList, 'name' | 'isPinned'>
      & { id: TaskList['_id'] }
    )> }
  )> }
);

export type UpdateTaskDueDateMutationVariables = {
  id: Scalars['ObjectId'],
  dueDate?: Maybe<Scalars['DateTime']>
};


export type UpdateTaskDueDateMutation = (
  { __typename?: 'Mutation' }
  & { updateTaskDueDate: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'dueDate' | 'status'>
    & { id: Task['_id'] }
  )> }
);

export type CompleteTasksMutationVariables = {
  command: BulkCompleteTaskCommand
};


export type CompleteTasksMutation = (
  { __typename?: 'Mutation' }
  & { completeTasks: Maybe<Array<(
    { __typename?: 'Task' }
    & Pick<Task, 'status'>
    & { id: Task['_id'] }
  )>> }
);

export type VoidTasksMutationVariables = {
  command: BulkVoidTaskCommand
};


export type VoidTasksMutation = (
  { __typename?: 'Mutation' }
  & { voidTasks: Maybe<Array<(
    { __typename?: 'Task' }
    & Pick<Task, 'status'>
    & { id: Task['_id'] }
  )>> }
);

export type VoidTaskMutationVariables = {
  id: Scalars['ObjectId']
};


export type VoidTaskMutation = (
  { __typename?: 'Mutation' }
  & { voidTask: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'status'>
    & { id: Task['_id'] }
  )> }
);

export type ReopenTaskMutationVariables = {
  id: Scalars['ObjectId']
};


export type ReopenTaskMutation = (
  { __typename?: 'Mutation' }
  & { reopenTask: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'status'>
    & { id: Task['_id'] }
  )> }
);

export type CreateTaskListMutationVariables = {
  input: CreateTaskListInput
};


export type CreateTaskListMutation = (
  { __typename?: 'Mutation' }
  & { createTaskList: Maybe<(
    { __typename?: 'TaskList' }
    & { id: TaskList['_id'] }
  )> }
);

export type UpdateTaskListMutationVariables = {
  input: UpdateTaskListInput
};


export type UpdateTaskListMutation = (
  { __typename?: 'Mutation' }
  & { updateTaskList: Maybe<(
    { __typename?: 'TaskList' }
    & { id: TaskList['_id'] }
  )> }
);

export type DeleteTaskListMutationVariables = {
  id: Scalars['ObjectId']
};


export type DeleteTaskListMutation = (
  { __typename?: 'Mutation' }
  & { deleteTaskList: Maybe<(
    { __typename?: 'TaskList' }
    & { id: TaskList['_id'] }
  )> }
);

export type CreateTaskMutationVariables = {
  input: CreateTaskInput
};


export type CreateTaskMutation = (
  { __typename?: 'Mutation' }
  & { createTask: Maybe<(
    { __typename?: 'Task' }
    & TaskFieldsFragment
  )> }
);

export type CreateTaskChecklistMutationVariables = {
  input: CreateTaskChecklistInput
};


export type CreateTaskChecklistMutation = (
  { __typename?: 'Mutation' }
  & { createTaskChecklist: Maybe<(
    { __typename?: 'Task' }
    & TaskFieldsFragment
  )> }
);

export type UpdateTaskChecklistMutationVariables = {
  input: UpdateTaskChecklistInput
};


export type UpdateTaskChecklistMutation = (
  { __typename?: 'Mutation' }
  & { updateTaskChecklist: Maybe<(
    { __typename?: 'Task' }
    & TaskFieldsFragment
  )> }
);

export type DeleteTaskChecklistMutationVariables = {
  id: Scalars['ObjectId']
};


export type DeleteTaskChecklistMutation = (
  { __typename?: 'Mutation' }
  & { deleteTaskChecklist: Maybe<(
    { __typename?: 'Task' }
    & TaskFieldsFragment
  )> }
);

export type CreateTaskChecklistItemMutationVariables = {
  input: CreateTaskChecklistItemInput
};


export type CreateTaskChecklistItemMutation = (
  { __typename?: 'Mutation' }
  & { createTaskChecklistItem: Maybe<(
    { __typename?: 'Task' }
    & TaskFieldsFragment
  )> }
);

export type UpdateTaskChecklistItemMutationVariables = {
  input: UpdateTaskChecklistItemInput
};


export type UpdateTaskChecklistItemMutation = (
  { __typename?: 'Mutation' }
  & { updateTaskChecklistItem: Maybe<(
    { __typename?: 'Task' }
    & TaskFieldsFragment
  )> }
);

export type DeleteTaskChecklistItemMutationVariables = {
  id: Scalars['ObjectId']
};


export type DeleteTaskChecklistItemMutation = (
  { __typename?: 'Mutation' }
  & { deleteTaskChecklistItem: Maybe<(
    { __typename?: 'Task' }
    & TaskFieldsFragment
  )> }
);

export type UpdateTaskMutationVariables = {
  input: UpdateTaskInput
};


export type UpdateTaskMutation = (
  { __typename?: 'Mutation' }
  & { updateTask: Maybe<(
    { __typename?: 'Task' }
    & TaskFieldsFragment
  )> }
);

export type CopyTaskListMutationVariables = {
  input: CopyTaskListInput
};


export type CopyTaskListMutation = (
  { __typename?: 'Mutation' }
  & { copyTaskList: Maybe<(
    { __typename?: 'TaskList' }
    & { id: TaskList['_id'] }
  )> }
);

export type PinTaskListMutationVariables = {
  id: Scalars['ObjectId']
};


export type PinTaskListMutation = (
  { __typename?: 'Mutation' }
  & { pinTaskList: Maybe<(
    { __typename?: 'TaskList' }
    & { id: TaskList['_id'] }
  )> }
);

export type GetWaitingListOverviewClassesQueryVariables = {
  filterInput?: Maybe<ClassFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetWaitingListOverviewClassesQuery = (
  { __typename?: 'Query' }
  & { classesWithIndex: Maybe<(
    { __typename?: 'ClassIndexPage' }
    & Pick<ClassIndexPage, 'total' | 'pageSize' | 'pageIndex'>
    & { items: Array<(
      { __typename?: 'ClassIndex' }
      & Pick<ClassIndex, 'name' | 'status' | 'type'>
      & { id: ClassIndex['_id'] }
      & { capacity: Maybe<(
        { __typename?: 'ClassCapacityDTO' }
        & Pick<ClassCapacityDto, 'regular' | 'used'>
      )> }
    )> }
  )> }
);

export type GetWaitingListOverviewCoursesQueryVariables = {
  filterInput?: Maybe<CourseFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetWaitingListOverviewCoursesQuery = (
  { __typename?: 'Query' }
  & { coursesWithIndex: Maybe<(
    { __typename?: 'CourseIndexPage' }
    & Pick<CourseIndexPage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'CourseIndex' }
      & Pick<CourseIndex, 'name' | 'nameShort' | 'type'>
      & { id: CourseIndex['_id'] }
    )> }
  )> }
);

export type GetWaitingListOverviewRegistrationsQueryVariables = {
  filterInput?: Maybe<RegistrationFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetWaitingListOverviewRegistrationsQuery = (
  { __typename?: 'Query' }
  & { registrations: Maybe<(
    { __typename?: 'RegistrationPage' }
    & Pick<RegistrationPage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'Registration' }
      & Pick<Registration, 'name' | 'status' | 'startDate' | 'isPrioritized' | 'createDateTime' | 'waitingListCreatedAt' | 'lastModifiedDateTime'>
      & { id: Registration['_id'] }
      & { student: (
        { __typename?: 'Student' }
        & Pick<Student, 'fullName' | 'userId' | 'avatarUrl'>
        & { id: Student['_id'] }
      ), updateHistories: Maybe<Array<(
        { __typename?: 'WaitingListUpdateHistory' }
        & Pick<WaitingListUpdateHistory, 'originalStatus' | 'status' | 'timestamp'>
      )>> }
    )> }
  )> }
);

export type PrioritizeRegistrationMutationVariables = {
  input: UpdateRegistrationInput
};


export type PrioritizeRegistrationMutation = (
  { __typename?: 'Mutation' }
  & { prioritizeRegistration: Maybe<(
    { __typename?: 'Registration' }
    & Pick<Registration, 'isPrioritized'>
    & { id: Registration['_id'] }
  )> }
);

export type CreateCourseNotificationMutationVariables = {
  input: CreateCourseNotificationInput
};


export type CreateCourseNotificationMutation = (
  { __typename?: 'Mutation' }
  & { createCourseNotification: Maybe<(
    { __typename?: 'CourseNotification' }
    & { id: CourseNotification['_id'] }
  )> }
);

export type DeleteCourseNotificationMutationVariables = {
  id: Scalars['ObjectId']
};


export type DeleteCourseNotificationMutation = (
  { __typename?: 'Mutation' }
  & { deleteCourseNotification: Maybe<(
    { __typename?: 'CourseNotification' }
    & { id: CourseNotification['_id'] }
  )> }
);

export type CourseNotificationsQueryVariables = {
  filterInput?: Maybe<CourseNotificationFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type CourseNotificationsQuery = (
  { __typename?: 'Query' }
  & { courseNotifications: Maybe<(
    { __typename?: 'CourseNotificationPage' }
    & { items: Array<(
      { __typename?: 'CourseNotification' }
      & Pick<CourseNotification, 'createDateTime'>
      & { id: CourseNotification['_id'] }
      & { student: Maybe<(
        { __typename?: 'Student' }
        & Pick<Student, 'fullName' | 'userId' | 'avatarUrl'>
        & { id: Student['_id'] }
        & { primaryParent: Maybe<(
          { __typename?: 'Parent' }
          & Pick<Parent, 'fullName'>
          & { id: Parent['_id'] }
          & { mobile: (
            { __typename?: 'Telephone' }
            & Pick<Telephone, 'countryCode' | 'number'>
          ), linkedWhatsappAccount: Maybe<(
            { __typename?: 'WhatsappAccount' }
            & { number: Maybe<(
              { __typename?: 'Telephone' }
              & Pick<Telephone, 'countryCode' | 'number'>
            )> }
          )> }
        )>, parents: Maybe<Array<(
          { __typename?: 'Parent' }
          & Pick<Parent, 'fullName'>
          & { id: Parent['_id'] }
          & { mobile: (
            { __typename?: 'Telephone' }
            & Pick<Telephone, 'countryCode' | 'number'>
          ), linkedWhatsappAccount: Maybe<(
            { __typename?: 'WhatsappAccount' }
            & { id: WhatsappAccount['_id'] }
            & { number: Maybe<(
              { __typename?: 'Telephone' }
              & Pick<Telephone, 'countryCode' | 'number'>
            )> }
          )> }
        )>> }
      )>, academicYear: Maybe<(
        { __typename?: 'AcademicYear' }
        & Pick<AcademicYear, 'year'>
      )>, newClasses: Maybe<Array<(
        { __typename?: 'Class' }
        & Pick<Class, 'shorthand'>
        & { id: Class['_id'] }
      )>>, enrollments: Maybe<Array<(
        { __typename?: 'Enrollment' }
        & Pick<Enrollment, 'type' | 'status' | 'startDate' | 'endDate'>
        & { registration: Maybe<(
          { __typename?: 'Registration' }
          & Pick<Registration, 'type' | 'status'>
        )>, class: (
          { __typename?: 'Class' }
          & Pick<Class, 'shorthand'>
          & { id: Class['_id'] }
        ) }
      )>> }
    )> }
  )> }
);

export type GetStudentWithEnrollmentsQueryVariables = {
  id: Scalars['ObjectId'],
  filterInput?: Maybe<StudentEnrollmentFilterInput>
};


export type GetStudentWithEnrollmentsQuery = (
  { __typename?: 'Query' }
  & { student: Maybe<(
    { __typename?: 'Student' }
    & { id: Student['_id'] }
    & { enrollments: Maybe<Array<(
      { __typename?: 'Enrollment' }
      & { id: Enrollment['_id'] }
    )>> }
  )> }
);

export type GetParentsOnAddInvoiceQueryVariables = {
  search?: Maybe<Scalars['String']>,
  filterInput?: Maybe<ParentFilter>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetParentsOnAddInvoiceQuery = (
  { __typename?: 'Query' }
  & { parents: Maybe<(
    { __typename?: 'ParentPage' }
    & Pick<ParentPage, 'total' | 'pageIndex' | 'pageSize'>
    & { items: Array<(
      { __typename?: 'Parent' }
      & Pick<Parent, 'fullName' | 'salutation' | 'userId'>
      & { id: Parent['_id'] }
    )> }
  )> }
);

export type GetStudentsOnAddInvoiceQueryVariables = {
  search?: Maybe<Scalars['String']>,
  filterInput?: Maybe<StudentFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetStudentsOnAddInvoiceQuery = (
  { __typename?: 'Query' }
  & { students: Maybe<(
    { __typename?: 'StudentPage' }
    & Pick<StudentPage, 'total' | 'pageIndex' | 'pageSize'>
    & { items: Array<(
      { __typename?: 'Student' }
      & Pick<Student, 'fullName' | 'userId'>
      & { id: Student['_id'] }
      & { primaryParent: Maybe<(
        { __typename?: 'Parent' }
        & { id: Parent['_id'] }
      )> }
    )> }
  )> }
);

export type GetStudentEnrollmentsOnAddInvoiceQueryVariables = {
  id: Scalars['ObjectId'],
  filterInput?: Maybe<StudentEnrollmentFilterInput>
};


export type GetStudentEnrollmentsOnAddInvoiceQuery = (
  { __typename?: 'Query' }
  & { student: Maybe<(
    { __typename?: 'Student' }
    & { enrollments: Maybe<Array<(
      { __typename?: 'Enrollment' }
      & Pick<Enrollment, 'type' | 'status'>
      & { id: Enrollment['_id'] }
      & { class: (
        { __typename?: 'Class' }
        & Pick<Class, 'name'>
        & { id: Class['_id'] }
      ) }
    )>> }
  )> }
);

export type PreviewAdHocInvoiceQueryVariables = {
  input: CreateAdHocInvoiceCommand
};


export type PreviewAdHocInvoiceQuery = (
  { __typename?: 'Query' }
  & { previewAdHocInvoice: Maybe<(
    { __typename?: 'Invoice' }
    & InvoiceItemFieldsFragment
  )> }
);

export type InvoiceItemOptionFieldsFragment = (
  { __typename?: 'InvoiceItemOption' }
  & Pick<InvoiceItemOption, 'description' | 'unitAmount'>
  & { id: InvoiceItemOption['_id'] }
);

export type GetInvoiceItemOptionsQueryVariables = {};


export type GetInvoiceItemOptionsQuery = (
  { __typename?: 'Query' }
  & { invoiceItemOptions: Maybe<Array<(
    { __typename?: 'InvoiceItemOption' }
    & InvoiceItemOptionFieldsFragment
  )>> }
);

export type CreateInvoiceItemOptionMutationVariables = {
  input: CreateInvoiceItemOptionInput
};


export type CreateInvoiceItemOptionMutation = (
  { __typename?: 'Mutation' }
  & { createInvoiceItemOption: Maybe<(
    { __typename?: 'InvoiceItemOption' }
    & InvoiceItemOptionFieldsFragment
  )> }
);

export type UpdateInvoiceItemOptionMutationVariables = {
  input: UpdateInvoiceItemOptionInput
};


export type UpdateInvoiceItemOptionMutation = (
  { __typename?: 'Mutation' }
  & { updateInvoiceItemOption: Maybe<(
    { __typename?: 'InvoiceItemOption' }
    & InvoiceItemOptionFieldsFragment
  )> }
);

export type DeleteInvoiceItemOptionMutationVariables = {
  id: Scalars['ObjectId']
};


export type DeleteInvoiceItemOptionMutation = (
  { __typename?: 'Mutation' }
  & { deleteInvoiceItemOption: Maybe<(
    { __typename?: 'InvoiceItemOption' }
    & InvoiceItemOptionFieldsFragment
  )> }
);

export type CreateAdHocInvoiceMutationVariables = {
  input: CreateAdHocInvoiceCommand
};


export type CreateAdHocInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { createAdHocInvoice: Maybe<(
    { __typename?: 'Invoice' }
    & { id: Invoice['_id'] }
  )> }
);

export type QueryDiscountsQueryVariables = {
  showArchived?: Maybe<Scalars['Boolean']>,
  showVisible?: Maybe<Scalars['Boolean']>
};


export type QueryDiscountsQuery = (
  { __typename?: 'Query' }
  & { discounts: Maybe<Array<(
    { __typename?: 'Discount' }
    & DiscountFieldsFragment
  )>> }
);

export type CreateDisCountMutationVariables = {
  input: CreateDiscountInput
};


export type CreateDisCountMutation = (
  { __typename?: 'Mutation' }
  & { createDiscount: Maybe<(
    { __typename?: 'Discount' }
    & DiscountFieldsFragment
  )> }
);

export type UpdateDiscountMutationVariables = {
  input: UpdateDiscountInput
};


export type UpdateDiscountMutation = (
  { __typename?: 'Mutation' }
  & { updateDiscount: Maybe<(
    { __typename?: 'Discount' }
    & DiscountFieldsFragment
  )> }
);

export type ArchiveDiscountMutationVariables = {
  id: Scalars['ObjectId']
};


export type ArchiveDiscountMutation = (
  { __typename?: 'Mutation' }
  & { archiveDiscount: Maybe<(
    { __typename?: 'Discount' }
    & DiscountFieldsFragment
  )> }
);

export type RestoreDiscountMutationVariables = {
  id: Scalars['ObjectId']
};


export type RestoreDiscountMutation = (
  { __typename?: 'Mutation' }
  & { restoreDiscount: Maybe<(
    { __typename?: 'Discount' }
    & DiscountFieldsFragment
  )> }
);

export type DiscountFieldsFragment = (
  { __typename?: 'Discount' }
  & Pick<Discount, 'discountName' | 'discountType' | 'discountAmount' | 'discountRate' | 'isArchived' | 'isVisible'>
  & { id: Discount['_id'] }
);

export type CreatePaymentRecordMutationVariables = {
  input: CreatePaymentRecordInput
};


export type CreatePaymentRecordMutation = (
  { __typename?: 'Mutation' }
  & { createPaymentRecord: Maybe<(
    { __typename?: 'Payment' }
    & { id: Payment['_id'] }
    & { details: Maybe<Array<(
      { __typename?: 'PaymentDetail' }
      & Pick<PaymentDetail, 'amount'>
      & { invoice: Maybe<(
        { __typename?: 'Invoice' }
        & InvoiceItemFieldsFragment
      )> }
    )>>, receipt: Maybe<(
      { __typename?: 'Receipt' }
      & BasicReceiptFieldsFragment
    )>, lastModifiedBy: Maybe<(
      { __typename?: 'SystemUser' }
      & UserFieldsFragment
    )> }
    & BasicPaymentRecordFieldsFragment
  )> }
);

export type UpdatePaymentRecordMutationVariables = {
  input: UpdatePaymentRecordInput
};


export type UpdatePaymentRecordMutation = (
  { __typename?: 'Mutation' }
  & { updatePaymentRecord: Maybe<(
    { __typename?: 'Payment' }
    & { id: Payment['_id'] }
  )> }
);

export type CreateRefundMutationVariables = {
  input: CreateRefundInput
};


export type CreateRefundMutation = (
  { __typename?: 'Mutation' }
  & { createRefund: Maybe<(
    { __typename?: 'Refund' }
    & { id: Refund['_id'] }
  )> }
);

export type VoidRefundMutationVariables = {
  id: Scalars['ObjectId']
};


export type VoidRefundMutation = (
  { __typename?: 'Mutation' }
  & { voidRefund: Maybe<(
    { __typename?: 'Refund' }
    & { id: Refund['_id'] }
  )> }
);

export type RegenerateInvoiceMutationVariables = {
  id: Scalars['ObjectId']
};


export type RegenerateInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { regenerateInvoice: Maybe<(
    { __typename?: 'Invoice' }
    & { id: Invoice['_id'] }
  )> }
);

export type GenerateNextInvoiceMutationVariables = {
  id: Scalars['ObjectId']
};


export type GenerateNextInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { generateNextInvoice: Maybe<(
    { __typename?: 'Invoice' }
    & { id: Invoice['_id'] }
  )> }
);

export type VoidPaymentRecordMutationVariables = {
  input: VoidPaymentRecordInput
};


export type VoidPaymentRecordMutation = (
  { __typename?: 'Mutation' }
  & { voidPaymentRecord: Maybe<(
    { __typename?: 'Payment' }
    & { id: Payment['_id'] }
  )> }
);

export type VoidInvoiceMutationVariables = {
  id: Scalars['ObjectId']
};


export type VoidInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { voidInvoice: Maybe<(
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'status'>
    & { id: Invoice['_id'] }
  )> }
);

export type AdjustBalanceMutationVariables = {
  input: AdjustBalanceInput
};


export type AdjustBalanceMutation = (
  { __typename?: 'Mutation' }
  & { adjustBalance: Maybe<(
    { __typename?: 'Balance' }
    & { id: Balance['_id'] }
  )> }
);

export type GetReceiptsQueryVariables = {
  search?: Maybe<Scalars['String']>,
  filterInput?: Maybe<ReceiptFilterInput>,
  sort?: Maybe<SortInfo>,
  page?: Maybe<PageInfo>
};


export type GetReceiptsQuery = (
  { __typename?: 'Query' }
  & { receipts: Maybe<(
    { __typename?: 'ReceiptPage' }
    & Pick<ReceiptPage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'Receipt' }
      & Pick<Receipt, 'referenceNumber' | 'status' | 'isNotified' | 'createDateTime'>
      & { id: Receipt['_id'] }
      & { paymentRecord: Maybe<(
        { __typename?: 'Payment' }
        & PaymentFieldsFragment
      )>, contact: Maybe<(
        { __typename?: 'Parent' }
        & Pick<Parent, 'fullName'>
        & { id: Parent['_id'] }
      )>, createdBy: Maybe<(
        { __typename?: 'SystemUser' }
        & CreateByFieldsFragment
      )> }
    )> }
  )> }
);

export type GetReceiptQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetReceiptQuery = (
  { __typename?: 'Query' }
  & { receipt: Maybe<(
    { __typename?: 'Receipt' }
    & ListReceiptFieldsFragment
  )> }
);

export type GetRefundQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetRefundQuery = (
  { __typename?: 'Query' }
  & { refund: Maybe<(
    { __typename?: 'Refund' }
    & ListRefundFieldsFragment
  )> }
);

export type GetRefundsQueryVariables = {
  filterInput?: Maybe<RefundFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetRefundsQuery = (
  { __typename?: 'Query' }
  & { refunds: Maybe<(
    { __typename?: 'RefundPage' }
    & Pick<RefundPage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'Refund' }
      & Pick<Refund, 'waStatus' | 'emailStatus'>
      & ListRefundFieldsFragment
    )> }
  )> }
);

export type GetInVoicePaymentRecordQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetInVoicePaymentRecordQuery = (
  { __typename?: 'Query' }
  & { invoice: Maybe<(
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'total' | 'totalTax' | 'subtotal' | 'dueDate' | 'amountPaid' | 'referenceNumber'>
    & { id: Invoice['_id'] }
    & { billedTo: Maybe<(
      { __typename?: 'Parent' }
      & { id: Parent['_id'] }
    )>, payments: Maybe<Array<(
      { __typename?: 'Payment' }
      & PaymentFieldsFragment
    )>> }
  )> }
);

export type GetInvoiceQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetInvoiceQuery = (
  { __typename?: 'Query' }
  & { invoice: Maybe<(
    { __typename?: 'Invoice' }
    & InvoiceItemFieldsFragment
  )> }
);

export type GetInvoiceExportQueryVariables = {
  id: Scalars['ObjectId'],
  printBase64?: Maybe<Scalars['Boolean']>
};


export type GetInvoiceExportQuery = (
  { __typename?: 'Query' }
  & { invoiceExport: Maybe<(
    { __typename?: 'Pdf' }
    & Pick<Pdf, 'url'>
  )> }
);

export type GetReceiptExportQueryVariables = {
  id: Scalars['ObjectId'],
  printBase64?: Maybe<Scalars['Boolean']>
};


export type GetReceiptExportQuery = (
  { __typename?: 'Query' }
  & { receiptExport: Maybe<(
    { __typename?: 'Pdf' }
    & Pick<Pdf, 'url'>
  )> }
);

export type GetRefundExportQueryVariables = {
  id: Scalars['ObjectId'],
  printBase64?: Maybe<Scalars['Boolean']>
};


export type GetRefundExportQuery = (
  { __typename?: 'Query' }
  & { refundExport: Maybe<(
    { __typename?: 'Pdf' }
    & Pick<Pdf, 'url'>
  )> }
);

export type GetStatementOfAccountInfoQueryVariables = {
  parentId: Scalars['ObjectId']
};


export type GetStatementOfAccountInfoQuery = (
  { __typename?: 'Query' }
  & { statementOfAccount: Maybe<(
    { __typename?: 'StatementOfAccount' }
    & Pick<StatementOfAccount, 'id' | 'creditRemain' | 'refund'>
    & { amountAudits: Maybe<(
      { __typename?: 'AmountAudit' }
      & Pick<AmountAudit, 'pending' | 'unpaid' | 'overdue' | 'onHold' | 'paid'>
    )>, parent: (
      { __typename?: 'Parent' }
      & Pick<Parent, 'fullName' | 'salutation' | 'alias' | 'gender' | 'email' | 'avatarUrl' | 'alternateUserId' | 'userId'>
      & { id: Parent['_id'] }
      & { mobile: (
        { __typename?: 'Telephone' }
        & Pick<Telephone, 'countryCode' | 'number'>
      ), linkedWhatsappAccount: Maybe<(
        { __typename?: 'WhatsappAccount' }
        & { number: Maybe<(
          { __typename?: 'Telephone' }
          & Pick<Telephone, 'countryCode' | 'number'>
        )> }
      )>, relatedStudents: Maybe<Array<(
        { __typename?: 'Student' }
        & Pick<Student, 'relationName'>
        & RelationshipStudentFieldsFragment
      )>> }
    ) }
  )> }
);

export type GetFinanceRecordOfAccountNumberQueryVariables = {
  parentId: Scalars['ObjectId']
};


export type GetFinanceRecordOfAccountNumberQuery = (
  { __typename?: 'Query' }
  & { statementOfAccountNumber: Maybe<(
    { __typename?: 'StatementOfAccountNumber' }
    & Pick<StatementOfAccountNumber, 'invoices' | 'receipts' | 'balanceHistories' | 'refunds'>
  )> }
);

export type GetFinanceRecordOfRefundQueryVariables = {
  filterInput?: Maybe<RefundFilterInput>,
  pageInfo?: Maybe<PageInfo>
};


export type GetFinanceRecordOfRefundQuery = (
  { __typename?: 'Query' }
  & { refunds: Maybe<(
    { __typename?: 'RefundPage' }
    & Pick<RefundPage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'Refund' }
      & Pick<Refund, 'waStatus' | 'emailStatus'>
      & ListRefundFieldsFragment
    )> }
  )> }
);

export type GetFinanceRecordOfReceiptQueryVariables = {
  search?: Maybe<Scalars['String']>,
  filterInput?: Maybe<ReceiptFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetFinanceRecordOfReceiptQuery = (
  { __typename?: 'Query' }
  & { receipts: Maybe<(
    { __typename?: 'ReceiptPage' }
    & Pick<ReceiptPage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'Receipt' }
      & Pick<Receipt, 'waStatus' | 'emailStatus'>
      & ListReceiptFieldsFragment
    )> }
  )> }
);

export type GetFinanceRecordOfInvoiceQueryVariables = {
  search?: Maybe<Scalars['String']>,
  filterInput?: Maybe<InvoiceFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetFinanceRecordOfInvoiceQuery = (
  { __typename?: 'Query' }
  & { invoices: Maybe<(
    { __typename?: 'InvoicePage' }
    & Pick<InvoicePage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'Invoice' }
      & { reminders: Maybe<Array<(
        { __typename?: 'Reminder' }
        & Pick<Reminder, 'type' | 'status' | 'attachmentStatus'>
        & { template: Maybe<(
          { __typename?: 'Template' }
          & Pick<Template, 'type'>
        )> }
      )>> }
      & InvoiceItemFieldsFragment
    )> }
  )> }
);

export type GetFinanceRecordOfBalanceHistoryQueryVariables = {
  filterInput?: Maybe<BalanceHistoryFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetFinanceRecordOfBalanceHistoryQuery = (
  { __typename?: 'Query' }
  & { balanceHistories: Maybe<(
    { __typename?: 'BalanceHistoryPage' }
    & Pick<BalanceHistoryPage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'BalanceHistory' }
      & ListBalanceHistoryFieldsFragment
    )> }
  )> }
);

export type ListBalanceHistoryFieldsFragment = (
  { __typename?: 'BalanceHistory' }
  & Pick<BalanceHistory, 'createDateTime' | 'lastModifiedDateTime' | 'type' | 'amount' | 'balance' | 'description'>
  & { id: BalanceHistory['_id'] }
  & { lastModifiedBy: Maybe<(
    { __typename?: 'SystemUser' }
    & Pick<SystemUser, 'fullName'>
  )>, payments: Maybe<Array<(
    { __typename?: 'Payment' }
    & { id: Payment['_id'] }
    & { details: Maybe<Array<(
      { __typename?: 'PaymentDetail' }
      & { invoice: Maybe<(
        { __typename?: 'Invoice' }
        & Pick<Invoice, 'referenceNumber'>
        & { id: Invoice['_id'] }
        & { billedFor: Maybe<(
          { __typename?: 'Student' }
          & Pick<Student, 'fullName'>
          & { id: Student['_id'] }
        )>, billedTo: Maybe<(
          { __typename?: 'Parent' }
          & { id: Parent['_id'] }
        )>, class: Maybe<(
          { __typename?: 'Class' }
          & Pick<Class, 'shorthand'>
          & { id: Class['_id'] }
          & { course: (
            { __typename?: 'Course' }
            & Pick<Course, 'nameShort'>
            & { id: Course['_id'] }
          ) }
        )> }
      )> }
    )>> }
  )>>, invoices: Maybe<Array<(
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'referenceNumber'>
    & { id: Invoice['_id'] }
    & { billedFor: Maybe<(
      { __typename?: 'Student' }
      & Pick<Student, 'fullName'>
      & { id: Student['_id'] }
    )>, billedTo: Maybe<(
      { __typename?: 'Parent' }
      & { id: Parent['_id'] }
    )>, class: Maybe<(
      { __typename?: 'Class' }
      & Pick<Class, 'shorthand'>
      & { id: Class['_id'] }
    )>, course: Maybe<(
      { __typename?: 'Course' }
      & Pick<Course, 'nameShort'>
      & { id: Course['_id'] }
    )> }
  )>>, refund: Maybe<(
    { __typename?: 'Refund' }
    & Pick<Refund, 'referenceNumber'>
    & { id: Refund['_id'] }
  )>, createdBy: Maybe<(
    { __typename?: 'SystemUser' }
    & UserFieldsFragment
  )> }
);

export type ListReceiptFieldsFragment = (
  { __typename?: 'Receipt' }
  & Pick<Receipt, 'status' | 'referenceNumber' | 'createDateTime' | 'amount' | 'amountPaidSnapshot'>
  & { id: Receipt['_id'] }
  & { invoicePaidSnapshots: Maybe<Array<(
    { __typename?: 'InvoicePaidSnapshot' }
    & Pick<InvoicePaidSnapshot, 'invoiceId' | 'paid' | 'beforePaid'>
    & { snapshot: Maybe<(
      { __typename?: 'InvoiceSnapshot' }
      & Pick<InvoiceSnapshot, 'studentFullName' | 'parentFullName'>
      & { lessons: Maybe<Array<(
        { __typename?: 'InvoiceLessonSnapshot' }
        & Pick<InvoiceLessonSnapshot, 'lessonStart' | 'lessonEnd'>
        & { id: InvoiceLessonSnapshot['_id'] }
      )>> }
    )> }
  )>>, paymentRecord: Maybe<(
    { __typename?: 'Payment' }
    & Pick<Payment, 'method' | 'amount' | 'cashAmount' | 'createDateTime' | 'paymentDate' | 'balanceAmount'>
    & { details: Maybe<Array<(
      { __typename?: 'PaymentDetail' }
      & Pick<PaymentDetail, 'balanceAmount' | 'invoiceId'>
      & { invoice: Maybe<(
        { __typename?: 'Invoice' }
        & Pick<Invoice, 'createDateTime' | 'referenceNumber' | 'status' | 'isImported' | 'isOtherFee' | 'dueDate' | 'subtotal' | 'totalTax' | 'total' | 'amountPaid' | 'lastModifiedDateTime'>
        & { id: Invoice['_id'] }
        & { arrangement: Maybe<(
          { __typename?: 'Arrangement' }
          & { fromLesson: Maybe<(
            { __typename?: 'Lesson' }
            & { holidays: Maybe<Array<(
              { __typename?: 'Holiday' }
              & Pick<Holiday, 'name'>
            )>> }
          )> }
        )>, class: Maybe<(
          { __typename?: 'Class' }
          & Pick<Class, 'name' | 'type' | 'defaultLessonDays' | 'defaultLessonStart' | 'defaultLessonEnd' | 'isAdHoc' | 'shorthand' | 'runNumber'>
          & { id: Class['_id'] }
          & { course: (
            { __typename?: 'Course' }
            & Pick<Course, 'nameShort' | 'colorCode'>
            & { id: Course['_id'] }
          ), academicYear: (
            { __typename?: 'AcademicYear' }
            & Pick<AcademicYear, 'year'>
            & { id: AcademicYear['_id'] }
          ), teacher: Maybe<(
            { __typename?: 'SystemUser' }
            & Pick<SystemUser, 'fullName' | 'gender' | 'avatarUrl'>
            & { id: SystemUser['_id'] }
          )>, venue: Maybe<(
            { __typename?: 'Venue' }
            & Pick<Venue, 'shorthand' | 'unitNo'>
            & { branch: Maybe<(
              { __typename?: 'Branch' }
              & Pick<Branch, 'name' | 'alias'>
            )> }
          )> }
        )>, lineItems: Maybe<Array<(
          { __typename?: 'InvoiceLineItem' }
          & LineItemFieldsFragment
        )>>, billedFor: Maybe<(
          { __typename?: 'Student' }
          & Pick<Student, 'gender' | 'fullName' | 'alternateUserId' | 'userId'>
          & { id: Student['_id'] }
          & { primarySchool: Maybe<(
            { __typename?: 'School' }
            & Pick<School, 'name'>
            & { id: School['_id'] }
          )> }
        )>, billedTo: Maybe<(
          { __typename?: 'Parent' }
          & Pick<Parent, 'fullName' | 'email'>
          & { id: Parent['_id'] }
          & { mobile: (
            { __typename?: 'Telephone' }
            & Pick<Telephone, 'countryCode' | 'number'>
          ), financeStatement: Maybe<(
            { __typename?: 'StatementOfAccount' }
            & Pick<StatementOfAccount, 'id' | 'creditRemain'>
          )> }
        )>, destination: Maybe<(
          { __typename?: 'Enrollment' }
          & Pick<Enrollment, 'type' | 'isFullEnrollment'>
          & { id: Enrollment['_id'] }
        )>, nextInvoice: Maybe<(
          { __typename?: 'Invoice' }
          & { id: Invoice['_id'] }
        )>, receipts: Maybe<Array<(
          { __typename?: 'Receipt' }
          & Pick<Receipt, 'amount' | 'createDateTime' | 'status'>
          & { paymentRecord: Maybe<(
            { __typename?: 'Payment' }
            & Pick<Payment, 'amount' | 'method' | 'bankName' | 'lastDigits' | 'chequeNumber' | 'paymentDate' | 'paymentProcessor'>
            & { details: Maybe<Array<(
              { __typename?: 'PaymentDetail' }
              & { invoice: Maybe<(
                { __typename?: 'Invoice' }
                & { id: Invoice['_id'] }
              )> }
            )>> }
          )> }
        )>>, payments: Maybe<Array<(
          { __typename?: 'Payment' }
          & Pick<Payment, '_id' | 'amount' | 'balanceAmount' | 'createDateTime' | 'isVoid'>
          & { details: Maybe<Array<(
            { __typename?: 'PaymentDetail' }
            & Pick<PaymentDetail, 'invoiceId' | 'amount' | 'balanceAmount'>
          )>> }
        )>> }
      )> }
    )>>, receipt: Maybe<(
      { __typename?: 'Receipt' }
      & Pick<Receipt, 'amount' | 'createDateTime'>
      & { paymentRecord: Maybe<(
        { __typename?: 'Payment' }
        & Pick<Payment, 'amount' | 'method' | 'bankName' | 'lastDigits' | 'chequeNumber' | 'paymentProcessor'>
      )> }
    )> }
  )> }
);

export type ListRefundFieldsFragment = (
  { __typename?: 'Refund' }
  & Pick<Refund, 'referenceNumber' | 'amount' | 'isVoid' | 'adminFee' | 'refundMethod' | 'processedDate' | 'refundedDate' | 'reference' | 'reason' | 'bankName' | 'bankAccountNumber' | 'chequeNumber' | 'payNowNumber' | 'paymentProcessor' | 'lastDigits' | 'lastModifiedDateTime'>
  & { id: Refund['_id'] }
  & { lastModifiedBy: Maybe<(
    { __typename?: 'SystemUser' }
    & CreateByFieldsFragment
  )>, parent: Maybe<(
    { __typename?: 'Parent' }
    & ParentCellFieldsFragment
  )>, snapshot: Maybe<(
    { __typename?: 'RefundSnapshot' }
    & Pick<RefundSnapshot, 'parentFullName'>
  )> }
);

export type PaymentFieldsFragment = (
  { __typename?: 'Payment' }
  & Pick<Payment, 'method' | 'amount' | 'createDateTime' | 'depositDate' | 'reference' | 'remarks' | 'bankName' | 'lastDigits' | 'chequeNumber' | 'paymentProcessor' | 'isVoid'>
  & { id: Payment['_id'] }
  & { receipt: Maybe<(
    { __typename?: 'Receipt' }
    & ReceiptFieldsFragment
  )> }
);

export type InvoiceItemFieldsFragment = (
  { __typename?: 'Invoice' }
  & Pick<Invoice, 'createDateTime' | 'referenceNumber' | 'status' | 'isOnHold' | 'taxRate' | 'onHoldRemarks' | 'isImported' | 'isOtherFee' | 'dueDate' | 'subtotal' | 'totalTax' | 'total' | 'amountPaid' | 'lastModifiedDateTime'>
  & { id: Invoice['_id'] }
  & { snapshot: Maybe<(
    { __typename?: 'InvoiceSnapshot' }
    & Pick<InvoiceSnapshot, 'studentFullName' | 'parentFullName'>
    & { lessons: Maybe<Array<(
      { __typename?: 'InvoiceLessonSnapshot' }
      & Pick<InvoiceLessonSnapshot, 'lessonStart' | 'lessonEnd'>
      & { id: InvoiceLessonSnapshot['_id'] }
    )>> }
  )>, arrangement: Maybe<(
    { __typename?: 'Arrangement' }
    & Pick<Arrangement, 'isAdhocAdditionalLesson'>
    & { fromLesson: Maybe<(
      { __typename?: 'Lesson' }
      & { holidays: Maybe<Array<(
        { __typename?: 'Holiday' }
        & Pick<Holiday, 'name'>
      )>> }
    )> }
  )>, class: Maybe<(
    { __typename?: 'Class' }
    & Pick<Class, 'name' | 'type' | 'defaultLessonDays' | 'defaultLessonStart' | 'defaultLessonEnd' | 'isAdHoc' | 'shorthand' | 'runNumber'>
    & { id: Class['_id'] }
    & { course: (
      { __typename?: 'Course' }
      & Pick<Course, 'nameShort' | 'colorCode'>
      & { id: Course['_id'] }
    ), academicYear: (
      { __typename?: 'AcademicYear' }
      & Pick<AcademicYear, 'year'>
      & { id: AcademicYear['_id'] }
    ), teacher: Maybe<(
      { __typename?: 'SystemUser' }
      & Pick<SystemUser, 'fullName' | 'gender' | 'avatarUrl'>
      & { id: SystemUser['_id'] }
    )>, venue: Maybe<(
      { __typename?: 'Venue' }
      & Pick<Venue, 'shorthand' | 'unitNo'>
      & { branch: Maybe<(
        { __typename?: 'Branch' }
        & Pick<Branch, 'name' | 'alias'>
      )> }
    )> }
  )>, lineItems: Maybe<Array<(
    { __typename?: 'InvoiceLineItem' }
    & LineItemFieldsFragment
  )>>, billedFor: Maybe<(
    { __typename?: 'Student' }
    & Pick<Student, 'gender' | 'fullName' | 'alternateUserId' | 'userId'>
    & { id: Student['_id'] }
    & { primarySchool: Maybe<(
      { __typename?: 'School' }
      & Pick<School, 'name'>
      & { id: School['_id'] }
    )>, primaryParent: Maybe<(
      { __typename?: 'Parent' }
      & { id: Parent['_id'] }
    )> }
  )>, billedTo: Maybe<(
    { __typename?: 'Parent' }
    & Pick<Parent, 'fullName' | 'email'>
    & { id: Parent['_id'] }
    & { mobile: (
      { __typename?: 'Telephone' }
      & Pick<Telephone, 'countryCode' | 'number'>
    ), financeStatement: Maybe<(
      { __typename?: 'StatementOfAccount' }
      & Pick<StatementOfAccount, 'id' | 'creditRemain'>
    )> }
  )>, destination: Maybe<(
    { __typename?: 'Enrollment' }
    & Pick<Enrollment, 'type' | 'isFullEnrollment'>
    & { id: Enrollment['_id'] }
  )>, nextInvoice: Maybe<(
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'referenceNumber'>
    & { id: Invoice['_id'] }
    & { billedTo: Maybe<(
      { __typename?: 'Parent' }
      & Pick<Parent, 'fullName' | 'email'>
      & { id: Parent['_id'] }
      & { mobile: (
        { __typename?: 'Telephone' }
        & Pick<Telephone, 'countryCode' | 'number'>
      ), financeStatement: Maybe<(
        { __typename?: 'StatementOfAccount' }
        & Pick<StatementOfAccount, 'id' | 'creditRemain'>
      )> }
    )> }
  )> }
);

export type ListInvoiceFieldsFragment = (
  { __typename?: 'Invoice' }
  & Pick<Invoice, 'createDateTime' | 'referenceNumber' | 'status' | 'dueDate' | 'subtotal' | 'totalTax' | 'total' | 'amountPaid' | 'lastModifiedDateTime'>
  & { id: Invoice['_id'] }
  & { createdBy: Maybe<(
    { __typename?: 'SystemUser' }
    & { id: SystemUser['_id'] }
  )>, class: Maybe<(
    { __typename?: 'Class' }
    & Pick<Class, 'name' | 'defaultLessonDays' | 'defaultLessonStart' | 'defaultLessonEnd' | 'shorthand' | 'runNumber' | 'isAdHoc'>
    & { id: Class['_id'] }
    & { academicYear: (
      { __typename?: 'AcademicYear' }
      & Pick<AcademicYear, 'year'>
      & { id: AcademicYear['_id'] }
    ), teacher: Maybe<(
      { __typename?: 'SystemUser' }
      & Pick<SystemUser, 'fullName' | 'gender' | 'avatarUrl'>
      & { id: SystemUser['_id'] }
    )> }
  )>, billedFor: Maybe<(
    { __typename?: 'Student' }
    & Pick<Student, 'gender' | 'fullName' | 'alternateUserId' | 'userId'>
    & { id: Student['_id'] }
    & { primarySchool: Maybe<(
      { __typename?: 'School' }
      & Pick<School, 'name'>
      & { id: School['_id'] }
    )> }
  )>, billedTo: Maybe<(
    { __typename?: 'Parent' }
    & Pick<Parent, 'fullName' | 'email'>
    & { id: Parent['_id'] }
    & { mobile: (
      { __typename?: 'Telephone' }
      & Pick<Telephone, 'countryCode' | 'number'>
    ), financeStatement: Maybe<(
      { __typename?: 'StatementOfAccount' }
      & Pick<StatementOfAccount, 'id' | 'creditRemain'>
    )> }
  )>, lineItems: Maybe<Array<(
    { __typename?: 'InvoiceLineItem' }
    & LineItemFieldsFragment
  )>>, destination: Maybe<(
    { __typename?: 'Enrollment' }
    & Pick<Enrollment, 'createDateTime' | 'type'>
    & { id: Enrollment['_id'] }
    & { createdBy: Maybe<(
      { __typename?: 'SystemUser' }
      & CreateByFieldsFragment
    )> }
  )>, nextInvoice: Maybe<(
    { __typename?: 'Invoice' }
    & { id: Invoice['_id'] }
  )> }
);

export type LinkedInvoiceFieldsFragment = (
  { __typename?: 'Invoice' }
  & Pick<Invoice, 'status' | 'referenceNumber' | 'total' | 'dueDate' | 'amountPaid' | 'createDateTime'>
  & { id: Invoice['_id'] }
  & { billedFor: Maybe<(
    { __typename?: 'Student' }
    & Pick<Student, 'gender' | 'fullName' | 'alternateUserId' | 'userId'>
    & { id: Student['_id'] }
    & { primarySchool: Maybe<(
      { __typename?: 'School' }
      & Pick<School, 'name'>
      & { id: School['_id'] }
    )> }
  )>, billedTo: Maybe<(
    { __typename?: 'Parent' }
    & ParentFieldsFragment
  )>, course: Maybe<(
    { __typename?: 'Course' }
    & Pick<Course, 'name' | 'nameShort' | 'colorCode'>
    & { id: Course['_id'] }
  )>, lessons: Maybe<Array<(
    { __typename?: 'Lesson' }
    & Pick<Lesson, '_id'>
  )>>, class: Maybe<(
    { __typename?: 'Class' }
    & Pick<Class, 'name'>
    & { id: Class['_id'] }
    & { academicYear: (
      { __typename?: 'AcademicYear' }
      & Pick<AcademicYear, 'year'>
      & { id: AcademicYear['_id'] }
    ), teacher: Maybe<(
      { __typename?: 'SystemUser' }
      & Pick<SystemUser, 'fullName' | 'gender' | 'avatarUrl'>
      & { id: SystemUser['_id'] }
    )> }
  )>, createdBy: Maybe<(
    { __typename?: 'SystemUser' }
    & CreateByFieldsFragment
  )> }
);

export type InvoiceCsvQueryVariables = {
  search?: Maybe<Scalars['String']>,
  filterInput?: Maybe<InvoiceFilterInput>
};


export type InvoiceCsvQuery = (
  { __typename?: 'Query' }
  & { csv: Maybe<(
    { __typename?: 'Csv' }
    & Pick<Csv, 'url'>
  )> }
);

export type ReceiptsCsvQueryVariables = {
  search?: Maybe<Scalars['String']>,
  filterInput?: Maybe<ReceiptFilterInput>
};


export type ReceiptsCsvQuery = (
  { __typename?: 'Query' }
  & { csv: Maybe<(
    { __typename?: 'Csv' }
    & Pick<Csv, 'url'>
  )> }
);

export type RefundCsvQueryVariables = {
  filterInput?: Maybe<RefundFilterInput>
};


export type RefundCsvQuery = (
  { __typename?: 'Query' }
  & { csv: Maybe<(
    { __typename?: 'Csv' }
    & Pick<Csv, 'url'>
  )> }
);

export type StatementOfAccountCsvQueryVariables = {
  filter?: Maybe<StatementOfAccountFilter>
};


export type StatementOfAccountCsvQuery = (
  { __typename?: 'Query' }
  & { csv: Maybe<(
    { __typename?: 'Csv' }
    & Pick<Csv, 'url'>
  )> }
);

export type UpdateInvoiceDueDateMutationVariables = {
  updateInvoiceInput: UpdateInvoiceDueDateCommand
};


export type UpdateInvoiceDueDateMutation = (
  { __typename?: 'Mutation' }
  & { updateInvoiceDueDate: Maybe<(
    { __typename?: 'Invoice' }
    & InvoiceFieldsFragment
  )> }
);

export type InvoiceFieldsFragment = (
  { __typename?: 'Invoice' }
  & Pick<Invoice, 'createDateTime' | 'referenceNumber' | 'status' | 'dueDate' | 'subtotal' | 'totalTax' | 'total' | 'amountPaid'>
  & { id: Invoice['_id'] }
  & { destination: Maybe<(
    { __typename?: 'Enrollment' }
    & Pick<Enrollment, 'type' | 'status'>
    & { id: Enrollment['_id'] }
    & { registration: Maybe<(
      { __typename?: 'Registration' }
      & Pick<Registration, 'type'>
      & { id: Registration['_id'] }
    )>, arrangements: Maybe<Array<(
      { __typename?: 'Arrangement' }
      & Pick<Arrangement, 'type' | 'status'>
      & { id: Arrangement['_id'] }
    )>> }
  )>, createdBy: Maybe<(
    { __typename?: 'SystemUser' }
    & { id: SystemUser['_id'] }
  )>, class: Maybe<(
    { __typename?: 'Class' }
    & Pick<Class, 'name' | 'defaultLessonDays' | 'defaultLessonStart' | 'defaultLessonEnd' | 'isAdHoc'>
    & { id: Class['_id'] }
    & { academicYear: (
      { __typename?: 'AcademicYear' }
      & Pick<AcademicYear, 'year'>
      & { id: AcademicYear['_id'] }
    ), teacher: Maybe<(
      { __typename?: 'SystemUser' }
      & Pick<SystemUser, 'fullName' | 'gender' | 'avatarUrl'>
      & { id: SystemUser['_id'] }
    )>, venue: Maybe<(
      { __typename?: 'Venue' }
      & BasicVenueFieldsFragment
    )> }
  )>, billedTo: Maybe<(
    { __typename?: 'Parent' }
    & ParentFieldsFragment
  )>, billedFor: Maybe<(
    { __typename?: 'Student' }
    & StudentFieldsFragment
  )>, lineItems: Maybe<Array<(
    { __typename?: 'InvoiceLineItem' }
    & LineItemFieldsFragment
  )>> }
);

export type ReceiptFieldsFragment = (
  { __typename?: 'Receipt' }
  & Pick<Receipt, 'referenceNumber' | 'status' | 'createDateTime'>
  & { id: Receipt['_id'] }
  & { contact: Maybe<(
    { __typename?: 'Parent' }
    & ParentFieldsFragment
  )>, paymentRecord: Maybe<(
    { __typename?: 'Payment' }
    & { id: Payment['_id'] }
  )>, createdBy: Maybe<(
    { __typename?: 'SystemUser' }
    & CreateByFieldsFragment
  )> }
);

export type GetReceiptDetailsQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetReceiptDetailsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'receiptDetails'>
);

export type NotifyMissingInfoFieldsFragment = (
  { __typename?: 'NotifyMissingDTO' }
  & Pick<NotifyMissingDto, 'missingFields'>
  & { parent: Maybe<(
    { __typename?: 'Parent' }
    & Pick<Parent, 'fullName'>
    & { id: Parent['_id'] }
  )>, student: Maybe<(
    { __typename?: 'Student' }
    & Pick<Student, 'fullName'>
    & { id: Student['_id'] }
  )>, class: Maybe<(
    { __typename?: 'Class' }
    & Pick<Class, 'shorthand'>
    & { id: Class['_id'] }
  )>, course: Maybe<(
    { __typename?: 'Course' }
    & Pick<Course, 'name'>
    & { id: Course['_id'] }
  )>, teacher: Maybe<(
    { __typename?: 'SystemUser' }
    & Pick<SystemUser, 'fullName' | 'salutation'>
    & { id: SystemUser['_id'] }
  )> }
);

export type BulkSendReminderMutationVariables = {
  input: BulkSendReminderInput
};


export type BulkSendReminderMutation = (
  { __typename?: 'Mutation' }
  & { bulkSendReminder: Maybe<Array<(
    { __typename?: 'NotifyMissingDTO' }
    & NotifyMissingInfoFieldsFragment
  )>> }
);

export type OnHoldInvoiceMutationVariables = {
  input: OnHoldInvoiceCommand
};


export type OnHoldInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { onHoldInvoice: Maybe<(
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'isOnHold' | 'onHoldRemarks'>
    & { id: Invoice['_id'] }
  )> }
);

export type InvoiceIndexFieldsFragment = (
  { __typename?: 'InvoiceIndex' }
  & Pick<InvoiceIndex, 'referenceNumber' | 'createDateTime' | 'total' | 'amountPaid' | 'isOtherFee' | 'status' | 'dueDate' | 'isOnHold' | 'onHoldRemarks' | 'waStatus' | 'emailStatus' | 'paymentAdviceWaStatus' | 'paymentAdviceEmailStatus' | 'waAttachmentStatus' | 'paymentAdviceWaAttachmentStatus'>
  & { id: InvoiceIndex['_id'] }
  & { academicYear: Maybe<(
    { __typename?: 'InvoiceAcademicYearIndex' }
    & Pick<InvoiceAcademicYearIndex, 'year'>
    & { id: InvoiceAcademicYearIndex['_id'] }
  )>, class: Maybe<(
    { __typename?: 'InvoiceClassIndex' }
    & Pick<InvoiceClassIndex, 'name'>
    & { id: InvoiceClassIndex['_id'] }
  )>, course: Maybe<(
    { __typename?: 'InvoiceCourseIndex' }
    & Pick<InvoiceCourseIndex, 'nameShort'>
    & { id: InvoiceCourseIndex['_id'] }
  )>, student: Maybe<(
    { __typename?: 'InvoiceStudentIndex' }
    & Pick<InvoiceStudentIndex, 'fullName' | 'alias' | 'gender' | 'avatarUrl' | 'userId'>
    & { id: InvoiceStudentIndex['_id'] }
  )>, billedToParent: Maybe<(
    { __typename?: 'InvoiceParentIndex' }
    & Pick<InvoiceParentIndex, 'fullName' | 'gender' | 'salutation' | 'avatarUrl'>
    & { id: InvoiceParentIndex['_id'] }
    & { mobile: (
      { __typename?: 'Telephone' }
      & Pick<Telephone, 'countryCode' | 'number'>
    ) }
  )>, secondaryParents: Maybe<Array<(
    { __typename?: 'InvoiceParentIndex' }
    & Pick<InvoiceParentIndex, 'fullName' | 'gender' | 'salutation' | 'avatarUrl'>
    & { id: InvoiceParentIndex['_id'] }
    & { mobile: (
      { __typename?: 'Telephone' }
      & Pick<Telephone, 'countryCode' | 'number'>
    ) }
  )>> }
);

export type GetListInvoiceDataQueryVariables = {
  search?: Maybe<Scalars['String']>,
  filterInput?: Maybe<InvoiceFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetListInvoiceDataQuery = (
  { __typename?: 'Query' }
  & { invoices: Maybe<(
    { __typename?: 'InvoicePage' }
    & Pick<InvoicePage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'referenceNumber' | 'createDateTime' | 'total' | 'amountPaid' | 'hasTransferred' | 'isOtherFee' | 'status' | 'dueDate' | 'isOnHold' | 'onHoldRemarks'>
      & { id: Invoice['_id'] }
      & { billedTo: Maybe<(
        { __typename?: 'Parent' }
        & Pick<Parent, 'fullName' | 'userId'>
        & { id: Parent['_id'] }
        & { mobile: (
          { __typename?: 'Telephone' }
          & Pick<Telephone, 'countryCode' | 'number'>
        ), relatedParents: Maybe<Array<(
          { __typename?: 'Parent' }
          & Pick<Parent, 'fullName'>
          & { id: Parent['_id'] }
          & { mobile: (
            { __typename?: 'Telephone' }
            & Pick<Telephone, 'countryCode' | 'number'>
          ) }
        )>> }
      )>, billedFor: Maybe<(
        { __typename?: 'Student' }
        & StudentCellFieldsFragment
      )>, class: Maybe<(
        { __typename?: 'Class' }
        & Pick<Class, 'name'>
        & { id: Class['_id'] }
        & { academicYear: (
          { __typename?: 'AcademicYear' }
          & Pick<AcademicYear, 'year'>
          & { id: AcademicYear['_id'] }
        ), teacher: Maybe<(
          { __typename?: 'SystemUser' }
          & { id: SystemUser['_id'] }
        )> }
      )>, xeroInvoices: Maybe<Array<(
        { __typename?: 'InvoiceXero' }
        & Pick<InvoiceXero, 'xeroInvoiceId' | 'year' | 'month' | 'amount' | 'lastSyncedDate' | 'url'>
      )>>, course: Maybe<(
        { __typename?: 'Course' }
        & Pick<Course, 'name' | 'nameShort' | 'type' | 'colorCode'>
        & { id: Course['_id'] }
      )>, payments: Maybe<Array<(
        { __typename?: 'Payment' }
        & Pick<Payment, 'method' | 'amount' | 'createDateTime' | 'depositDate' | 'remarks' | 'reference' | 'isVoid' | 'xeroPaymentSyncStatus' | 'url'>
        & { paymentId: Payment['_id'] }
        & { xeroPrepayments: Maybe<Array<(
          { __typename?: 'XeroPrepayment' }
          & Pick<XeroPrepayment, 'amount' | 'lineItemId' | 'lastSyncedDate'>
          & { prepaymentId: XeroPrepayment['id'] }
        )>> }
      )>>, reminders: Maybe<Array<(
        { __typename?: 'Reminder' }
        & Pick<Reminder, 'type' | 'status' | 'attachmentStatus'>
        & { id: Reminder['_id'] }
        & { template: Maybe<(
          { __typename?: 'Template' }
          & Pick<Template, 'type'>
        )> }
      )>> }
    )> }
  )> }
);

export type GetListInvoiceIndexDataQueryVariables = {
  filterInput?: Maybe<InvoiceFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetListInvoiceIndexDataQuery = (
  { __typename?: 'Query' }
  & { invoicesWithIndex: Maybe<(
    { __typename?: 'InvoiceIndexPage' }
    & Pick<InvoiceIndexPage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'InvoiceIndex' }
      & InvoiceIndexFieldsFragment
    )> }
  )> }
);

export type GetInvoiceIndexDataQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetInvoiceIndexDataQuery = (
  { __typename?: 'Query' }
  & { invoiceWithIndex: Maybe<(
    { __typename?: 'InvoiceIndex' }
    & InvoiceIndexFieldsFragment
  )> }
);

export type ReceiptIndexFieldsFragment = (
  { __typename?: 'ReceiptIndex' }
  & Pick<ReceiptIndex, 'referenceNumber' | 'amountPaidSnapshot' | 'status' | 'amount' | 'amountDue' | 'waStatus' | 'emailStatus'>
  & { id: ReceiptIndex['_id'] }
  & { payment: Maybe<(
    { __typename?: 'ReceiptPaymentIndex' }
    & Pick<ReceiptPaymentIndex, 'method' | 'paymentDate' | 'createDateTime' | 'reference' | 'remarks'>
  )>, invoices: Maybe<Array<(
    { __typename?: 'ReceiptInvoiceIndex' }
    & Pick<ReceiptInvoiceIndex, 'dueDate' | 'total'>
    & { id: ReceiptInvoiceIndex['_id'] }
  )>>, parent: Maybe<(
    { __typename?: 'ReceiptParentIndex' }
    & Pick<ReceiptParentIndex, 'fullName' | 'email'>
    & { id: ReceiptParentIndex['_id'] }
    & { mobile: (
      { __typename?: 'Telephone' }
      & Pick<Telephone, 'countryCode' | 'number'>
    ) }
  )>, secondaryParents: Maybe<Array<(
    { __typename?: 'ReceiptParentIndex' }
    & Pick<ReceiptParentIndex, 'fullName' | 'email'>
    & { id: ReceiptParentIndex['_id'] }
    & { mobile: (
      { __typename?: 'Telephone' }
      & Pick<Telephone, 'countryCode' | 'number'>
    ) }
  )>>, students: Maybe<Array<(
    { __typename?: 'ReceiptStudentIndex' }
    & Pick<ReceiptStudentIndex, 'fullName'>
    & { id: ReceiptStudentIndex['_id'] }
  )>>, classes: Maybe<Array<(
    { __typename?: 'ReceiptClassIndex' }
    & Pick<ReceiptClassIndex, 'shorthand' | 'type'>
    & { id: ReceiptClassIndex['_id'] }
  )>>, academicYears: Maybe<Array<(
    { __typename?: 'ReceiptAcademicYearIndex' }
    & Pick<ReceiptAcademicYearIndex, 'year'>
    & { id: ReceiptAcademicYearIndex['_id'] }
  )>>, courses: Maybe<Array<(
    { __typename?: 'ReceiptCourseIndex' }
    & Pick<ReceiptCourseIndex, 'nameShort'>
    & { id: ReceiptCourseIndex['_id'] }
  )>> }
);

export type GetListReceiptDataQueryVariables = {
  filterInput?: Maybe<ReceiptFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetListReceiptDataQuery = (
  { __typename?: 'Query' }
  & { receiptsWithIndex: Maybe<(
    { __typename?: 'ReceiptIndexPage' }
    & Pick<ReceiptIndexPage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'ReceiptIndex' }
      & ReceiptIndexFieldsFragment
    )> }
  )> }
);

export type BulkExportReceiptsQueryVariables = {
  filterInput: ReceiptFilterInput,
  printBase64?: Maybe<Scalars['Boolean']>
};


export type BulkExportReceiptsQuery = (
  { __typename?: 'Query' }
  & { bulkExportReceipts: Maybe<(
    { __typename?: 'Pdf' }
    & Pick<Pdf, 'url'>
  )> }
);

export type GetListStatementOfAccountDataQueryVariables = {
  filterInput?: Maybe<ParentFilter>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetListStatementOfAccountDataQuery = (
  { __typename?: 'Query' }
  & { parentsWithIndex: Maybe<(
    { __typename?: 'ParentIndexPage' }
    & Pick<ParentIndexPage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'ParentIndex' }
      & Pick<ParentIndex, 'userId' | 'fullName' | 'salutation' | 'alias' | 'gender' | 'email' | 'pending' | 'unpaid' | 'overdue' | 'onHold' | 'balance' | 'invoiceStatuses'>
      & { id: ParentIndex['_id'] }
      & { mobile: (
        { __typename?: 'Telephone' }
        & Pick<Telephone, 'countryCode' | 'number'>
      ) }
    )> }
  )> }
);

export type GetPaymentRecordModalDataQueryVariables = {
  invoiceId: Scalars['ObjectId']
};


export type GetPaymentRecordModalDataQuery = (
  { __typename?: 'Query' }
  & { invoice: Maybe<(
    { __typename?: 'Invoice' }
    & { payments: Maybe<Array<(
      { __typename?: 'Payment' }
      & { details: Maybe<Array<(
        { __typename?: 'PaymentDetail' }
        & Pick<PaymentDetail, 'amount' | 'balanceAmount'>
        & { invoice: Maybe<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'referenceNumber'>
          & { id: Invoice['_id'] }
        )> }
      )>>, receipt: Maybe<(
        { __typename?: 'Receipt' }
        & BasicReceiptFieldsFragment
      )>, lastModifiedBy: Maybe<(
        { __typename?: 'SystemUser' }
        & UserFieldsFragment
      )> }
      & BasicPaymentRecordFieldsFragment
    )>>, billedTo: Maybe<(
      { __typename?: 'Parent' }
      & { financeStatement: Maybe<(
        { __typename?: 'StatementOfAccount' }
        & Pick<StatementOfAccount, 'id' | 'creditRemain'>
      )> }
    )> }
    & BasicInvoiceFieldsFragment
  )> }
);

export type GetPaymentInvoicesQueryVariables = {
  filterInput?: Maybe<InvoiceFilterInput>
};


export type GetPaymentInvoicesQuery = (
  { __typename?: 'Query' }
  & { invoices: Maybe<(
    { __typename?: 'InvoicePage' }
    & { items: Array<(
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'referenceNumber' | 'subtotal' | 'totalTax' | 'total' | 'amountPaid'>
      & { id: Invoice['_id'] }
      & { billedTo: Maybe<(
        { __typename?: 'Parent' }
        & { financeStatement: Maybe<(
          { __typename?: 'StatementOfAccount' }
          & Pick<StatementOfAccount, 'id' | 'creditRemain'>
        )> }
      )> }
    )> }
  )> }
);

export type GetAcademicLevelCheckBoxDataQueryVariables = {};


export type GetAcademicLevelCheckBoxDataQuery = (
  { __typename?: 'Query' }
  & { academicLevels: Maybe<Array<(
    { __typename?: 'AcademicLevel' }
    & Pick<AcademicLevel, 'name' | 'educationStage'>
    & { id: AcademicLevel['_id'] }
  )>> }
);

export type GetAcademicYearSelectOptionsQueryVariables = {};


export type GetAcademicYearSelectOptionsQuery = (
  { __typename?: 'Query' }
  & { academicYears: Maybe<Array<(
    { __typename?: 'AcademicYear' }
    & Pick<AcademicYear, 'year'>
    & { id: AcademicYear['_id'] }
    & { period: (
      { __typename?: 'DateTimeRange' }
      & Pick<DateTimeRange, 'start' | 'end'>
    ) }
  )>> }
);

export type GetLessonFilterOptionsQueryVariables = {};


export type GetLessonFilterOptionsQuery = (
  { __typename?: 'Query' }
  & { academicYears: Maybe<Array<(
    { __typename?: 'AcademicYear' }
    & Pick<AcademicYear, 'year'>
    & { id: AcademicYear['_id'] }
    & { academicWeeks: Array<(
      { __typename?: 'AcademicWeekWithId' }
      & Pick<AcademicWeekWithId, 'startDate' | 'endDate' | 'weekNumber'>
    )> }
  )>>, branches: Maybe<Array<(
    { __typename?: 'Branch' }
    & Pick<Branch, 'name' | 'alias'>
    & { id: Branch['_id'] }
    & { venues: Maybe<Array<(
      { __typename?: 'Venue' }
      & BasicVenueFieldsFragment
    )>> }
  )>> }
);

export type GetGmlAndSmsFilterOptionsQueryVariables = {
  filterInput: SystemUserQuery,
  CourseType?: Maybe<Array<CourseType>>
};


export type GetGmlAndSmsFilterOptionsQuery = (
  { __typename?: 'Query' }
  & { academicYears: Maybe<Array<(
    { __typename?: 'AcademicYear' }
    & Pick<AcademicYear, 'year'>
    & { id: AcademicYear['_id'] }
  )>>, branches: Maybe<Array<(
    { __typename?: 'Branch' }
    & Pick<Branch, 'name' | 'alias'>
    & { id: Branch['_id'] }
    & { venues: Maybe<Array<(
      { __typename?: 'Venue' }
      & BasicVenueFieldsFragment
    )>> }
  )>> }
);

export type UpdateLessonMutationVariables = {
  updateLessonInput: UpdateLessonCommand
};


export type UpdateLessonMutation = (
  { __typename?: 'Mutation' }
  & { updateLesson: Maybe<(
    { __typename?: 'Lesson' }
    & Pick<Lesson, 'lessonStart' | 'lessonEnd' | 'maximumClassCapacity' | 'lessonDay' | 'lessonNumber'>
    & { id: Lesson['_id'] }
    & { teacher: Maybe<(
      { __typename?: 'SystemUser' }
      & Pick<SystemUser, 'fullName'>
      & { id: SystemUser['_id'] }
    )>, venue: Maybe<(
      { __typename?: 'Venue' }
      & Pick<Venue, 'shorthand' | 'unitNo' | 'capacity'>
      & { id: Venue['_id'] }
    )>, class: Maybe<(
      { __typename?: 'Class' }
      & Pick<Class, 'name'>
      & { id: Class['_id'] }
      & { academicYear: (
        { __typename?: 'AcademicYear' }
        & Pick<AcademicYear, 'year'>
        & { id: AcademicYear['_id'] }
      ) }
    )> }
  )> }
);

export type CreateLessonMutationVariables = {
  CreateLessonInput: CreateLessonInput
};


export type CreateLessonMutation = (
  { __typename?: 'Mutation' }
  & { createLesson: Maybe<(
    { __typename?: 'Lesson' }
    & Pick<Lesson, 'lessonStart' | 'lessonEnd' | 'maximumClassCapacity' | 'lessonDay' | 'lessonNumber'>
    & { id: Lesson['_id'] }
    & { teacher: Maybe<(
      { __typename?: 'SystemUser' }
      & Pick<SystemUser, 'fullName'>
      & { id: SystemUser['_id'] }
    )>, venue: Maybe<(
      { __typename?: 'Venue' }
      & Pick<Venue, 'shorthand' | 'unitNo' | 'capacity'>
      & { id: Venue['_id'] }
    )>, class: Maybe<(
      { __typename?: 'Class' }
      & Pick<Class, 'name'>
      & { id: Class['_id'] }
      & { academicYear: (
        { __typename?: 'AcademicYear' }
        & Pick<AcademicYear, 'year'>
        & { id: AcademicYear['_id'] }
      ) }
    )> }
  )> }
);

export type DeleteLessonMutationVariables = {
  id: Scalars['ObjectId']
};


export type DeleteLessonMutation = (
  { __typename?: 'Mutation' }
  & { deleteLesson: Maybe<(
    { __typename?: 'Lesson' }
    & { id: Lesson['_id'] }
  )> }
);

export type DeleteLessonsMutationVariables = {
  ids: Array<Scalars['ObjectId']>
};


export type DeleteLessonsMutation = (
  { __typename?: 'Mutation' }
  & { deleteLessons: Maybe<Array<(
    { __typename?: 'Lesson' }
    & { id: Lesson['_id'] }
  )>> }
);

export type QueryClassroomsQueryVariables = {};


export type QueryClassroomsQuery = (
  { __typename?: 'Query' }
  & { venues: Maybe<Array<(
    { __typename?: 'Venue' }
    & BasicVenueFieldsFragment
  )>> }
);

export type GetLessonsQueryVariables = {
  search?: Maybe<Scalars['String']>,
  filterInput?: Maybe<LessonFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>,
  AttendanceStatus?: Maybe<Array<AttendanceStatus>>,
  AttendanceType?: Maybe<Array<AttendanceType>>
};


export type GetLessonsQuery = (
  { __typename?: 'Query' }
  & { lessons: Maybe<(
    { __typename?: 'LessonPage' }
    & Pick<LessonPage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'Lesson' }
      & Pick<Lesson, 'lessonStart' | 'lessonEnd' | 'maximumClassCapacity' | 'lessonDay' | 'lessonNumber' | 'totalStudent' | 'absentStudent' | 'noStatusStudent'>
      & { id: Lesson['_id'] }
      & { teacher: Maybe<(
        { __typename?: 'SystemUser' }
        & Pick<SystemUser, 'fullName' | 'salutation' | 'avatarUrl' | 'gender' | 'initials'>
        & { id: SystemUser['_id'] }
      )>, venue: Maybe<(
        { __typename?: 'Venue' }
        & Pick<Venue, 'shorthand' | 'unitNo' | 'capacity'>
        & { id: Venue['_id'] }
        & { branch: Maybe<(
          { __typename?: 'Branch' }
          & Pick<Branch, 'name' | 'alias'>
          & { id: Branch['_id'] }
        )> }
      )>, academicWeek: Maybe<(
        { __typename?: 'AcademicWeek' }
        & Pick<AcademicWeek, 'startDate' | 'endDate' | 'weekNumber'>
      )>, attendance: Maybe<Array<(
        { __typename?: 'Attendance' }
        & Pick<Attendance, 'status' | 'type'>
        & { id: Attendance['_id'] }
        & { student: Maybe<(
          { __typename?: 'Student' }
          & StudentFieldsFragment
        )>, linkedAttendance: Maybe<(
          { __typename?: 'Attendance' }
          & Pick<Attendance, 'startDateTime' | 'endDateTime' | 'status' | 'type'>
          & { class: (
            { __typename?: 'Class' }
            & { teacher: Maybe<(
              { __typename?: 'SystemUser' }
              & BasicTeacherFieldsFragment
            )> }
          ) }
        )>, remarks: Maybe<Array<(
          { __typename?: 'Remark' }
          & RemarkFieldsFragment
        )>> }
      )>>, capacity: Maybe<(
        { __typename?: 'LessonCapacityDTO' }
        & LessonCapacityFieldsFragment
      )>, class: Maybe<(
        { __typename?: 'Class' }
        & Pick<Class, 'name' | 'maximumClassCapacity' | 'maximumMakeUpCapacity'>
        & { id: Class['_id'] }
        & { academicYear: (
          { __typename?: 'AcademicYear' }
          & Pick<AcademicYear, 'year'>
          & { id: AcademicYear['_id'] }
        ), course: (
          { __typename?: 'Course' }
          & Pick<Course, 'name' | 'nameShort' | 'avatarUrl' | 'colorCode'>
          & { id: Course['_id'] }
        ) }
      )>, course: (
        { __typename?: 'Course' }
        & Pick<Course, 'name' | 'nameShort' | 'avatarUrl' | 'type'>
        & { id: Course['_id'] }
      ) }
    )> }
  )> }
);

export type GetLessonQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetLessonQuery = (
  { __typename?: 'Query' }
  & { lesson: Maybe<(
    { __typename?: 'Lesson' }
    & Pick<Lesson, 'status' | 'lessonStart' | 'lessonEnd' | 'lessonNotes'>
    & { id: Lesson['_id'] }
    & { teacher: Maybe<(
      { __typename?: 'SystemUser' }
      & { id: SystemUser['_id'] }
    )>, venue: Maybe<(
      { __typename?: 'Venue' }
      & Pick<Venue, 'unitNo' | 'shorthand' | 'capacity' | 'isArchived'>
      & { id: Venue['_id'] }
      & { branch: Maybe<(
        { __typename?: 'Branch' }
        & Pick<Branch, 'name' | 'alias'>
      )> }
    )>, academicWeek: Maybe<(
      { __typename?: 'AcademicWeek' }
      & Pick<AcademicWeek, 'weekNumber'>
    )>, class: Maybe<(
      { __typename?: 'Class' }
      & Pick<Class, 'type' | 'isAdHoc' | 'isSkipScheduledHoliday'>
      & { id: Class['_id'] }
    )> }
  )> }
);

export type GetLessonDetailQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetLessonDetailQuery = (
  { __typename?: 'Query' }
  & { lesson: Maybe<(
    { __typename?: 'Lesson' }
    & Pick<Lesson, 'status' | 'lessonStart' | 'lessonEnd' | 'lessonNumber' | 'lessonNotes'>
    & { id: Lesson['_id'] }
    & { class: Maybe<(
      { __typename?: 'Class' }
      & Pick<Class, 'name' | 'learningArrangement'>
      & { id: Class['_id'] }
      & { lessons: Maybe<Array<(
        { __typename?: 'Lesson' }
        & Pick<Lesson, 'isHoliday' | 'lessonStart' | 'lessonEnd'>
        & { id: Lesson['_id'] }
        & { attendance: Maybe<Array<(
          { __typename?: 'Attendance' }
          & Pick<Attendance, '_id' | 'status' | 'type'>
          & { student: Maybe<(
            { __typename?: 'Student' }
            & Pick<Student, '_id'>
          )> }
        )>> }
      )>> }
    )> }
  )> }
);

export type GetFindAdditionalLessonQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetFindAdditionalLessonQuery = (
  { __typename?: 'Query' }
  & { lesson: Maybe<(
    { __typename?: 'Lesson' }
    & Pick<Lesson, 'status' | 'lessonStart' | 'lessonEnd' | 'lessonNumber'>
    & { id: Lesson['_id'] }
    & { holidays: Maybe<Array<(
      { __typename?: 'Holiday' }
      & Pick<Holiday, 'name'>
    )>> }
  )> }
);

export type GetLessonsStartDateQueryVariables = {};


export type GetLessonsStartDateQuery = (
  { __typename?: 'Query' }
  & { lessons: Maybe<(
    { __typename?: 'LessonPage' }
    & { items: Array<(
      { __typename?: 'Lesson' }
      & Pick<Lesson, 'lessonStart'>
      & { id: Lesson['_id'] }
    )> }
  )> }
);

export type LessonCsvQueryVariables = {
  search?: Maybe<Scalars['String']>,
  filterInput?: Maybe<LessonFilterInput>
};


export type LessonCsvQuery = (
  { __typename?: 'Query' }
  & { csv: Maybe<(
    { __typename?: 'Csv' }
    & Pick<Csv, 'url'>
  )> }
);

export type LessonCsvWithIndexQueryVariables = {
  filterInput?: Maybe<LessonFilterInput>
};


export type LessonCsvWithIndexQuery = (
  { __typename?: 'Query' }
  & { csv: Maybe<(
    { __typename?: 'Csv' }
    & Pick<Csv, 'url'>
  )> }
);

export type LessonCapacityFieldsFragment = (
  { __typename?: 'LessonCapacityDTO' }
  & Pick<LessonCapacityDto, 'regular' | 'replacement' | 'used' | 'replacing' | 'remainingRegularSeat' | 'remainingReplacementSeat'>
  & { seats: Maybe<Array<(
    { __typename?: 'LessonSeatDTO' }
    & Pick<LessonSeatDto, 'startDate' | 'endDate' | 'seatType' | 'type' | 'attendanceStatus' | 'hasPaid' | 'isEnrollmentStarted' | 'attendanceId'>
    & { student: Maybe<(
      { __typename?: 'Student' }
      & Pick<Student, '_id' | 'fullName'>
    )> }
  )>> }
);

export type GetListLessonWithIndexDataQueryVariables = {
  filterInput?: Maybe<LessonFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetListLessonWithIndexDataQuery = (
  { __typename?: 'Query' }
  & { lessonsWithIndex: Maybe<(
    { __typename?: 'LessonIndexPage' }
    & Pick<LessonIndexPage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'LessonIndex' }
      & Pick<LessonIndex, 'status' | 'lessonStart' | 'lessonEnd' | 'lessonNumber' | 'isHoliday' | 'totalStudents' | 'absentStudents' | 'noStatusStudents' | 'createDateTime' | 'lastModifiedDateTime' | 'lastModifiedByFullName'>
      & { id: LessonIndex['_id'] }
      & { academicWeek: Maybe<(
        { __typename?: 'AcademicWeek' }
        & Pick<AcademicWeek, 'weekNumber'>
      )>, teacher: Maybe<(
        { __typename?: 'LessonTeacherIndex' }
        & Pick<LessonTeacherIndex, 'fullName' | 'initials' | 'salutation' | 'avatarUrl' | 'gender'>
        & { id: LessonTeacherIndex['_id'] }
      )>, course: Maybe<(
        { __typename?: 'LessonCourseIndex' }
        & Pick<LessonCourseIndex, 'name' | 'avatarUrl' | 'nameShort'>
        & { id: LessonCourseIndex['_id'] }
      )>, class: Maybe<(
        { __typename?: 'LessonClassIndex' }
        & Pick<LessonClassIndex, 'name' | 'shorthand' | 'type'>
        & { id: LessonClassIndex['_id'] }
      )>, venue: Maybe<(
        { __typename?: 'LessonVenueIndex' }
        & Pick<LessonVenueIndex, 'shorthand' | 'unitNo'>
        & { id: LessonVenueIndex['_id'] }
      )>, branch: Maybe<(
        { __typename?: 'LessonBranchIndex' }
        & Pick<LessonBranchIndex, 'name' | 'alias'>
        & { id: LessonBranchIndex['_id'] }
      )>, createdBy: Maybe<(
        { __typename?: 'SystemUser' }
        & Pick<SystemUser, 'fullName'>
      )> }
    )> }
  )> }
);

export type GetListLessonInCreateClassFlowDataQueryVariables = {
  classId: Scalars['ObjectId']
};


export type GetListLessonInCreateClassFlowDataQuery = (
  { __typename?: 'Query' }
  & { class: Maybe<(
    { __typename?: 'Class' }
    & Pick<Class, 'isAdHoc' | 'type' | 'learningArrangement'>
    & { id: Class['_id'] }
    & { academicYear: (
      { __typename?: 'AcademicYear' }
      & Pick<AcademicYear, 'year'>
      & { groupAssignment: Maybe<(
        { __typename?: 'GroupAssignment' }
        & { items: Maybe<Array<(
          { __typename?: 'GroupAssignmentItem' }
          & Pick<GroupAssignmentItem, 'weekNumber' | 'groupA' | 'groupB'>
        )>> }
      )> }
    ), term: Maybe<(
      { __typename?: 'Term' }
      & BasicTermFieldsFragment
    )>, course: (
      { __typename?: 'Course' }
      & Pick<Course, 'type'>
      & { id: Course['_id'] }
    ), lessons: Maybe<Array<(
      { __typename?: 'Lesson' }
      & Pick<Lesson, 'isHoliday'>
      & { academicWeek: Maybe<(
        { __typename?: 'AcademicWeek' }
        & Pick<AcademicWeek, 'weekNumber'>
      )>, holidays: Maybe<Array<(
        { __typename?: 'Holiday' }
        & Pick<Holiday, 'name'>
      )>>, teacher: Maybe<(
        { __typename?: 'SystemUser' }
        & BasicTeacherFieldsFragment
      )>, venue: Maybe<(
        { __typename?: 'Venue' }
        & BasicVenueFieldsFragment
      )> }
      & BasicLessonFieldsFragment
    )>> }
  )> }
);

export type DeliveryDetailFieldsFragment = (
  { __typename?: 'Delivery' }
  & Pick<Delivery, 'type' | 'status' | 'address' | 'remarks' | 'dropOffAddress' | 'dropOffRemarks' | 'dropOffPostalCode' | 'pickupStartAt' | 'pickupEndAt' | 'trackingNo' | 'trackingUrl' | 'parcelSize'>
  & { id: Delivery['_id'] }
  & { dropOffMobile: (
    { __typename?: 'Telephone' }
    & Pick<Telephone, 'countryCode' | 'number'>
  ), admin: Maybe<(
    { __typename?: 'SystemUser' }
    & Pick<SystemUser, 'fullName'>
    & { id: SystemUser['_id'] }
  )>, materials: Maybe<Array<(
    { __typename?: 'Material' }
    & { id: Material['_id'] }
    & { student: Maybe<(
      { __typename?: 'Student' }
      & Pick<Student, 'fullName' | 'email'>
      & { id: Student['_id'] }
    )>, envelope: Maybe<(
      { __typename?: 'MaterialEnvelope' }
      & MaterialEnvelopeFieldsFragment
    )> }
  )>> }
);

export type GetDeliveryQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetDeliveryQuery = (
  { __typename?: 'Query' }
  & { delivery: Maybe<(
    { __typename?: 'Delivery' }
    & DeliveryDetailFieldsFragment
  )> }
);

export type UpdateDeliveryMutationVariables = {
  input: UpdateDeliveryInput
};


export type UpdateDeliveryMutation = (
  { __typename?: 'Mutation' }
  & { updateDelivery: Maybe<(
    { __typename?: 'Delivery' }
    & DeliveryDetailFieldsFragment
  )> }
);

export type GetDeliveriesWithIndexQueryVariables = {
  filterInput?: Maybe<DeliveryFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  sortInfo?: Maybe<SortInfo>
};


export type GetDeliveriesWithIndexQuery = (
  { __typename?: 'Query' }
  & { deliveriesWithIndex: Maybe<(
    { __typename?: 'DeliveryIndexPage' }
    & Pick<DeliveryIndexPage, 'total' | 'pageIndex' | 'pageSize'>
    & { items: Array<(
      { __typename?: 'DeliveryIndex' }
      & Pick<DeliveryIndex, 'type' | 'dropOffPostalCode' | 'remarks' | 'address' | 'dropOffAddress' | 'dropOffRemarks' | 'pickupStartAt' | 'pickupEndAt' | 'trackingNo' | 'trackingUrl' | 'status' | 'parcelSize' | 'createDateTime' | 'lastModifiedDateTime'>
      & { id: DeliveryIndex['_id'] }
      & { user: Maybe<(
        { __typename?: 'DeliveryUserIndex' }
        & Pick<DeliveryUserIndex, 'fullName' | 'email'>
        & { mobile: Maybe<(
          { __typename?: 'Telephone' }
          & Pick<Telephone, 'countryCode' | 'number'>
        )> }
      )>, lastModifiedBy: Maybe<(
        { __typename?: 'SystemUser' }
        & Pick<SystemUser, 'fullName'>
      )>, createdBy: Maybe<(
        { __typename?: 'SystemUser' }
        & Pick<SystemUser, 'fullName'>
      )>, students: Maybe<Array<(
        { __typename?: 'DeliveryStudentIndex' }
        & Pick<DeliveryStudentIndex, 'fullName'>
        & { id: DeliveryStudentIndex['_id'] }
      )>>, materials: Maybe<Array<(
        { __typename?: 'DeliveryMaterialIndex' }
        & Pick<DeliveryMaterialIndex, 'adHocMaterials'>
        & { envelope: Maybe<(
          { __typename?: 'MaterialEnvelopeDO' }
          & MaterialEnvelopeDoFieldsFragment
        )> }
      )>>, dropOffMobile: Maybe<(
        { __typename?: 'Telephone' }
        & Pick<Telephone, 'countryCode' | 'number'>
      )> }
    )> }
  )> }
);

export type PlaceOrderDeliveryMutationVariables = {
  id: Scalars['ObjectId']
};


export type PlaceOrderDeliveryMutation = (
  { __typename?: 'Mutation' }
  & { placeOrderDelivery: (
    { __typename?: 'Delivery' }
    & { id: Delivery['_id'] }
  ) }
);

export type DeleteDeliveryMutationVariables = {
  id: Scalars['ObjectId']
};


export type DeleteDeliveryMutation = (
  { __typename?: 'Mutation' }
  & { deleteDelivery: Maybe<(
    { __typename?: 'Delivery' }
    & { id: Delivery['_id'] }
  )> }
);

export type DistributionWithMaterialsFieldsFragment = (
  { __typename?: 'MaterialIndex' }
  & Pick<MaterialIndex, 'isAdHoc' | 'adHocMaterials' | 'createDateTime' | 'enrollmentStartAt' | 'enrollmentEndAt' | 'hasUpcomingOnsiteGAPLesson' | 'upcomingOnsiteGAPLessonLA' | 'status' | 'remarks' | 'handoverOnsiteMaterialId'>
  & { id: MaterialIndex['_id'] }
  & { student: Maybe<(
    { __typename?: 'MaterialStudentIndex' }
    & Pick<MaterialStudentIndex, 'fullName' | 'userId' | 'gender' | 'avatarUrl'>
    & { id: MaterialStudentIndex['_id'] }
  )>, enrollments: Maybe<Array<(
    { __typename?: 'MaterialEnrollmentIndex' }
    & Pick<MaterialEnrollmentIndex, 'type' | 'learningArrangement'>
  )>>, lesson: Maybe<(
    { __typename?: 'MaterialLessonIndex' }
    & Pick<MaterialLessonIndex, 'lessonStart' | 'lessonEnd' | 'lessonNumber'>
  )>, envelope: Maybe<(
    { __typename?: 'MaterialEnvelopeDO' }
    & MaterialEnvelopeDoFieldsFragment
  )>, lessonClass: Maybe<(
    { __typename?: 'MaterialClassIndex' }
    & Pick<MaterialClassIndex, 'shorthand'>
    & { id: MaterialClassIndex['_id'] }
  )>, otherMaterials: Maybe<Array<(
    { __typename?: 'MaterialSubIndex' }
    & { id: MaterialSubIndex['_id'] }
    & { course: Maybe<(
      { __typename?: 'MaterialCourseIndex' }
      & Pick<MaterialCourseIndex, 'nameShort'>
      & { id: MaterialCourseIndex['_id'] }
    )>, envelope: Maybe<(
      { __typename?: 'MaterialEnvelopeDO' }
      & MaterialEnvelopeDoFieldsFragment
    )> }
  )>>, siblingMaterials: Maybe<Array<(
    { __typename?: 'MaterialSubIndex' }
    & { id: MaterialSubIndex['_id'] }
    & { course: Maybe<(
      { __typename?: 'MaterialCourseIndex' }
      & Pick<MaterialCourseIndex, 'nameShort'>
      & { id: MaterialCourseIndex['_id'] }
    )>, student: Maybe<(
      { __typename?: 'MaterialStudentIndex' }
      & Pick<MaterialStudentIndex, 'fullName'>
      & { id: MaterialStudentIndex['_id'] }
    )>, envelope: Maybe<(
      { __typename?: 'MaterialEnvelopeDO' }
      & MaterialEnvelopeDoFieldsFragment
    )> }
  )>> }
);

export type GetDistributionCourseListQueryVariables = {
  filterInput?: Maybe<CourseFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetDistributionCourseListQuery = (
  { __typename?: 'Query' }
  & { coursesWithIndex: Maybe<(
    { __typename?: 'CourseIndexPage' }
    & Pick<CourseIndexPage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'CourseIndex' }
      & Pick<CourseIndex, 'avatarUrl' | 'name' | 'status' | 'type' | 'ongoingClasses'>
      & { id: CourseIndex['_id'] }
      & { academicLevels: Maybe<Array<(
        { __typename?: 'CourseAcademicLevelIndex' }
        & Pick<CourseAcademicLevelIndex, 'name'>
      )>> }
    )> }
  )> }
);

export type GetDistributionWithMaterialsIndexQueryVariables = {
  filterInput?: Maybe<MaterialFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  sortInfo?: Maybe<SortInfo>
};


export type GetDistributionWithMaterialsIndexQuery = (
  { __typename?: 'Query' }
  & { materialsWithIndex: Maybe<(
    { __typename?: 'MaterialIndexPage' }
    & Pick<MaterialIndexPage, 'total' | 'pageIndex' | 'pageSize'>
    & { items: Array<(
      { __typename?: 'MaterialIndex' }
      & DistributionWithMaterialsFieldsFragment
    )> }
  )> }
);

export type GetPackingListsInDistributionQueryVariables = {
  courseIds?: Maybe<Array<Scalars['ObjectId']>>
};


export type GetPackingListsInDistributionQuery = (
  { __typename?: 'Query' }
  & { packingLists: Maybe<(
    { __typename?: 'PackingListsPage' }
    & { items: Array<(
      { __typename?: 'PackingList' }
      & Pick<PackingList, 'name' | 'createDateTime'>
      & { id: PackingList['_id'] }
      & { version: (
        { __typename?: 'PackingListVersion' }
        & { year: (
          { __typename?: 'PackingListVersionYear' }
          & Pick<PackingListVersionYear, 'year'>
          & { id: PackingListVersionYear['_id'] }
        ) }
      ), course: (
        { __typename?: 'PackingListCourse' }
        & Pick<PackingListCourse, 'name' | 'type'>
      ), envelopes: Array<(
        { __typename?: 'PackingListEnvelope' }
        & Pick<PackingListEnvelope, 'title' | 'status' | 'imageUrl'>
        & { id: PackingListEnvelope['_id'] }
        & { academicWeeks: Array<(
          { __typename?: 'PackingListAcademicWeek' }
          & Pick<PackingListAcademicWeek, 'weekNumber' | 'startDate' | 'endDate'>
        )>, booklets: Array<(
          { __typename?: 'PackingListEnvelopeBooklet' }
          & Pick<PackingListEnvelopeBooklet, 'title' | 'serialNumber' | 'imageUrl'>
          & { topic: (
            { __typename?: 'PackingListBookletTopic' }
            & Pick<PackingListBookletTopic, 'name'>
          ), worksheets: Array<(
            { __typename?: 'PackingListEnvelopeBookletWorksheet' }
            & Pick<PackingListEnvelopeBookletWorksheet, 'title' | 'serialNumber' | 'imageUrl'>
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type CourseAcademicYearWithAcademicWeeksQueryVariables = {
  year: Scalars['Int']
};


export type CourseAcademicYearWithAcademicWeeksQuery = (
  { __typename?: 'Query' }
  & { academicYear: Maybe<(
    { __typename?: 'AcademicYear' }
    & Pick<AcademicYear, 'year'>
    & { id: AcademicYear['_id'] }
    & { period: (
      { __typename?: 'DateTimeRange' }
      & Pick<DateTimeRange, 'start' | 'end'>
    ), academicWeeks: Array<(
      { __typename?: 'AcademicWeekWithId' }
      & Pick<AcademicWeekWithId, 'weekNumber' | 'startDate' | 'endDate'>
      & { id: AcademicWeekWithId['_id'] }
    )> }
  )> }
);

export type GetWillGeneratedMaterialEnrollmentsQueryVariables = {
  id: Scalars['ObjectId'],
  envelopeId: Scalars['ObjectId']
};


export type GetWillGeneratedMaterialEnrollmentsQuery = (
  { __typename?: 'Query' }
  & { packingList: Maybe<(
    { __typename?: 'PackingList' }
    & { id: PackingList['_id'] }
    & { willGeneratedMaterialEnrollments: Maybe<Array<(
      { __typename?: 'Enrollment' }
      & Pick<Enrollment, 'startDate' | 'endDate'>
      & { id: Enrollment['_id'] }
      & { student: Maybe<(
        { __typename?: 'Student' }
        & Pick<Student, 'fullName' | 'userId' | 'gender' | 'avatarUrl'>
        & { id: Student['_id'] }
      )> }
    )>> }
  )> }
);

export type BookletFieldsFragment = (
  { __typename?: 'MaterialEnvelopeBooklet' }
  & Pick<MaterialEnvelopeBooklet, 'title' | 'serialNumber'>
  & { id: MaterialEnvelopeBooklet['_id'] }
  & { topic: (
    { __typename?: 'MaterialBookletTopic' }
    & Pick<MaterialBookletTopic, '_id' | 'name'>
  ), worksheets: Array<(
    { __typename?: 'MaterialEnvelopeBookletWorksheet' }
    & Pick<MaterialEnvelopeBookletWorksheet, 'serialNumber' | 'title'>
    & { id: MaterialEnvelopeBookletWorksheet['_id'] }
  )> }
);

export type PackingListBookletFieldsFragment = (
  { __typename?: 'PackingListEnvelopeBooklet' }
  & Pick<PackingListEnvelopeBooklet, 'title' | 'serialNumber'>
  & { id: PackingListEnvelopeBooklet['_id'] }
  & { topic: (
    { __typename?: 'PackingListBookletTopic' }
    & Pick<PackingListBookletTopic, '_id' | 'name'>
  ), worksheets: Array<(
    { __typename?: 'PackingListEnvelopeBookletWorksheet' }
    & Pick<PackingListEnvelopeBookletWorksheet, 'serialNumber' | 'title'>
    & { id: PackingListEnvelopeBookletWorksheet['_id'] }
  )> }
);

export type BookletDoFieldsFragment = (
  { __typename?: 'MaterialEnvelopeBookletDO' }
  & Pick<MaterialEnvelopeBookletDo, 'title' | 'serialNumber'>
  & { id: MaterialEnvelopeBookletDo['_id'] }
  & { topic: (
    { __typename?: 'MaterialBookletTopicDO' }
    & Pick<MaterialBookletTopicDo, '_id' | 'name'>
  ), worksheets: Array<(
    { __typename?: 'MaterialEnvelopeBookletWorksheetDO' }
    & Pick<MaterialEnvelopeBookletWorksheetDo, 'serialNumber' | 'title'>
    & { id: MaterialEnvelopeBookletWorksheetDo['_id'] }
  )> }
);

export type MaterialEnvelopeFieldsFragment = (
  { __typename?: 'MaterialEnvelope' }
  & Pick<MaterialEnvelope, 'title'>
  & { id: MaterialEnvelope['_id'] }
  & { academicWeeks: Array<(
    { __typename?: 'PackingListAcademicWeek' }
    & Pick<PackingListAcademicWeek, 'startDate' | 'endDate' | 'weekNumber'>
  )>, booklets: Array<(
    { __typename?: 'MaterialEnvelopeBooklet' }
    & BookletFieldsFragment
  )> }
);

export type MaterialEnvelopeDoFieldsFragment = (
  { __typename?: 'MaterialEnvelopeDO' }
  & Pick<MaterialEnvelopeDo, 'title'>
  & { id: MaterialEnvelopeDo['_id'] }
  & { academicWeeks: Array<(
    { __typename?: 'PackingListAcademicWeek' }
    & Pick<PackingListAcademicWeek, 'startDate' | 'endDate' | 'weekNumber'>
  )>, booklets: Array<(
    { __typename?: 'MaterialEnvelopeBookletDO' }
    & BookletDoFieldsFragment
  )> }
);

export type PackingListEnvelopeFieldsFragment = (
  { __typename?: 'PackingListEnvelope' }
  & Pick<PackingListEnvelope, 'title' | 'status'>
  & { id: PackingListEnvelope['_id'] }
  & { academicWeeks: Array<(
    { __typename?: 'PackingListAcademicWeek' }
    & Pick<PackingListAcademicWeek, 'startDate' | 'endDate' | 'weekNumber'>
  )>, booklets: Array<(
    { __typename?: 'PackingListEnvelopeBooklet' }
    & PackingListBookletFieldsFragment
  )> }
);

export type GetMaterialsWithIndexQueryVariables = {
  filterInput?: Maybe<MaterialFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  sortInfo?: Maybe<SortInfo>
};


export type GetMaterialsWithIndexQuery = (
  { __typename?: 'Query' }
  & { materialsWithIndex: Maybe<(
    { __typename?: 'MaterialIndexPage' }
    & Pick<MaterialIndexPage, 'total' | 'pageIndex' | 'pageSize'>
    & { items: Array<(
      { __typename?: 'MaterialIndex' }
      & Pick<MaterialIndex, 'courseId' | 'isAdHoc' | 'adHocMaterials' | 'createDateTime' | 'remarks' | 'enrollmentStartAt' | 'enrollmentEndAt' | 'status' | 'handoverOnsiteMaterialId' | 'lastModifiedDateTime' | 'isCollectFromReception'>
      & { id: MaterialIndex['_id'] }
      & { createdBy: Maybe<(
        { __typename?: 'SystemUser' }
        & Pick<SystemUser, 'fullName'>
      )>, lesson: Maybe<(
        { __typename?: 'MaterialLessonIndex' }
        & Pick<MaterialLessonIndex, 'lessonStart' | 'lessonEnd' | 'lessonNumber'>
      )>, student: Maybe<(
        { __typename?: 'MaterialStudentIndex' }
        & Pick<MaterialStudentIndex, 'fullName' | 'userId' | 'gender' | 'avatarUrl'>
        & { id: MaterialStudentIndex['_id'] }
        & { address: Maybe<(
          { __typename?: 'UserAddress' }
          & Pick<UserAddress, 'streetAddress' | 'unit' | 'postalCode' | 'gateBuilding'>
        )> }
      )>, academicLevel: Maybe<(
        { __typename?: 'MaterialAcademicLevelIndex' }
        & Pick<MaterialAcademicLevelIndex, 'alias'>
        & { id: MaterialAcademicLevelIndex['_id'] }
      )>, address: Maybe<(
        { __typename?: 'UserAddress' }
        & Pick<UserAddress, 'streetAddress' | 'unit' | 'postalCode' | 'gateBuilding'>
      )>, delivery: Maybe<(
        { __typename?: 'MaterialDeliveryIndex' }
        & Pick<MaterialDeliveryIndex, 'trackingNo' | 'pickupStartAt' | 'pickupEndAt' | 'type'>
      )>, primaryParent: Maybe<(
        { __typename?: 'MaterialParentIndex' }
        & Pick<MaterialParentIndex, 'fullName' | 'salutation'>
        & { id: MaterialParentIndex['_id'] }
        & { mobile: (
          { __typename?: 'Telephone' }
          & Pick<Telephone, 'countryCode' | 'number'>
        ) }
      )>, primaryParentWhatsappAccount: Maybe<(
        { __typename?: 'MaterialWhatsappAccountIndex' }
        & { number: Maybe<(
          { __typename?: 'Telephone' }
          & Pick<Telephone, 'countryCode' | 'number'>
        )> }
      )>, secondaryParents: Maybe<Array<(
        { __typename?: 'MaterialParentIndex' }
        & Pick<MaterialParentIndex, 'fullName' | 'salutation'>
        & { id: MaterialParentIndex['_id'] }
        & { mobile: (
          { __typename?: 'Telephone' }
          & Pick<Telephone, 'countryCode' | 'number'>
        ) }
      )>>, secondaryParentWhatsappAccounts: Maybe<Array<(
        { __typename?: 'MaterialWhatsappAccountIndex' }
        & { number: Maybe<(
          { __typename?: 'Telephone' }
          & Pick<Telephone, 'countryCode' | 'number'>
        )> }
      )>>, enrollments: Maybe<Array<(
        { __typename?: 'MaterialEnrollmentIndex' }
        & Pick<MaterialEnrollmentIndex, 'type' | 'learningArrangement'>
      )>>, course: Maybe<(
        { __typename?: 'MaterialCourseIndex' }
        & Pick<MaterialCourseIndex, 'nameShort'>
      )>, envelope: Maybe<(
        { __typename?: 'MaterialEnvelopeDO' }
        & MaterialEnvelopeDoFieldsFragment
      )>, lessonClass: Maybe<(
        { __typename?: 'MaterialClassIndex' }
        & Pick<MaterialClassIndex, 'shorthand'>
        & { id: MaterialClassIndex['_id'] }
      )>, lastModifyUser: Maybe<(
        { __typename?: 'MaterialUserIndex' }
        & Pick<MaterialUserIndex, 'fullName'>
        & { id: MaterialUserIndex['_id'] }
      )> }
    )> }
  )> }
);

export type GetMaterialStudentsQueryVariables = {
  filterInput?: Maybe<StudentFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  sortInfo?: Maybe<SortInfo>
};


export type GetMaterialStudentsQuery = (
  { __typename?: 'Query' }
  & { students: Maybe<(
    { __typename?: 'StudentPage' }
    & Pick<StudentPage, 'total' | 'pageIndex' | 'pageSize'>
    & { items: Array<(
      { __typename?: 'Student' }
      & Pick<Student, 'fullName' | 'avatarUrl' | 'userId'>
      & { id: Student['_id'] }
    )> }
  )> }
);

export type GetMaterialStudentQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetMaterialStudentQuery = (
  { __typename?: 'Query' }
  & { student: Maybe<(
    { __typename?: 'Student' }
    & { id: Student['_id'] }
    & { enrollments: Maybe<Array<(
      { __typename?: 'Enrollment' }
      & { id: Enrollment['_id'] }
      & { class: (
        { __typename?: 'Class' }
        & { id: Class['_id'] }
        & { course: (
          { __typename?: 'Course' }
          & Pick<Course, 'nameShort'>
          & { id: Course['_id'] }
        ) }
      ) }
    )>> }
  )> }
);

export type GetPackingListsQueryVariables = {
  filter?: Maybe<PackingListsFilterInput>
};


export type GetPackingListsQuery = (
  { __typename?: 'Query' }
  & { packingLists: Maybe<(
    { __typename?: 'PackingListsPage' }
    & Pick<PackingListsPage, 'total' | 'pageIndex' | 'pageSize'>
    & { items: Array<(
      { __typename?: 'PackingList' }
      & { id: PackingList['_id'] }
      & { envelopes: Array<(
        { __typename?: 'PackingListEnvelope' }
        & PackingListEnvelopeFieldsFragment
      )> }
    )> }
  )> }
);

export type CreateAdHocMaterialMutationVariables = {
  input: CreateAdHocMaterialInput
};


export type CreateAdHocMaterialMutation = (
  { __typename?: 'Mutation' }
  & { createAdHocMaterial: Maybe<(
    { __typename?: 'Material' }
    & { id: Material['_id'] }
  )> }
);

export type DeleteAdHocMaterialMutationVariables = {
  id: Scalars['ObjectId']
};


export type DeleteAdHocMaterialMutation = (
  { __typename?: 'Mutation' }
  & { deleteAdHocMaterial: Maybe<(
    { __typename?: 'Material' }
    & { id: Material['_id'] }
  )> }
);

export type UpdateMaterialDeliveryOptionMutationVariables = {
  input: UpdateMaterialDeliveryOptionInput
};


export type UpdateMaterialDeliveryOptionMutation = (
  { __typename?: 'Mutation' }
  & { updateMaterialDeliveryOption: Maybe<(
    { __typename?: 'Material' }
    & { id: Material['_id'] }
  )> }
);

export type ResetMaterialStatusMutationVariables = {
  id: Scalars['ObjectId']
};


export type ResetMaterialStatusMutation = (
  { __typename?: 'Mutation' }
  & { resetMaterialStatus: Maybe<(
    { __typename?: 'Material' }
    & { id: Material['_id'] }
  )> }
);

export type GetManualDeliveryOptionFormDataQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetManualDeliveryOptionFormDataQuery = (
  { __typename?: 'Query' }
  & { material: Maybe<(
    { __typename?: 'Material' }
    & Pick<Material, 'status' | 'remarks'>
    & { attendances: Maybe<Array<(
      { __typename?: 'Attendance' }
      & { id: Attendance['_id'] }
      & { lesson: (
        { __typename?: 'Lesson' }
        & Pick<Lesson, 'lessonNumber' | 'lessonDay' | 'lessonStart' | 'lessonEnd' | 'remarks'>
        & { id: Lesson['_id'] }
      ) }
    )>> }
  )> }
);

export type GetMaterialAddressQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetMaterialAddressQuery = (
  { __typename?: 'Query' }
  & { material: Maybe<(
    { __typename?: 'Material' }
    & { id: Material['_id'] }
    & { student: Maybe<(
      { __typename?: 'Student' }
      & { id: Student['_id'] }
      & { address: Maybe<(
        { __typename?: 'UserAddress' }
        & Pick<UserAddress, 'country' | 'gateBuilding' | 'postalCode' | 'streetAddress' | 'unit'>
      )> }
    )> }
  )> }
);

export type UpdateStudentAddressMutationVariables = {
  input: UpdateStudentInput
};


export type UpdateStudentAddressMutation = (
  { __typename?: 'Mutation' }
  & { updateStudent: Maybe<(
    { __typename?: 'Student' }
    & { id: Student['_id'] }
  )> }
);

export type BulkUpdateStudentAddressFromPrimaryParentMutationVariables = {
  ids: Array<Scalars['ObjectId']>
};


export type BulkUpdateStudentAddressFromPrimaryParentMutation = (
  { __typename?: 'Mutation' }
  & { bulkUpdateStudentAddressFromPrimaryParent: Maybe<Array<(
    { __typename?: 'Student' }
    & { id: Student['_id'] }
  )>> }
);

export type BulkUpdateStatusToAddressVerificationConfirmedMutationVariables = {
  ids: Array<Scalars['ObjectId']>
};


export type BulkUpdateStatusToAddressVerificationConfirmedMutation = (
  { __typename?: 'Mutation' }
  & { addressVerificationConfirmed: Maybe<Array<(
    { __typename?: 'Material' }
    & { id: Material['_id'] }
  )>> }
);

export type BulkHandoverOnsiteMutationVariables = {
  input: BulkHandoverOnsiteCommand
};


export type BulkHandoverOnsiteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'bulkHandoverOnsite'>
);

export type GetMaterialsQueryVariables = {
  filterInput?: Maybe<MaterialFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  sortInfo?: Maybe<SortInfo>
};


export type GetMaterialsQuery = (
  { __typename?: 'Query' }
  & { materials: Maybe<(
    { __typename?: 'MaterialPage' }
    & { items: Array<(
      { __typename?: 'Material' }
      & { id: Material['_id'] }
      & { student: Maybe<(
        { __typename?: 'Student' }
        & Pick<Student, 'fullName' | 'userId'>
        & { id: Student['_id'] }
        & { address: Maybe<(
          { __typename?: 'UserAddress' }
          & Pick<UserAddress, 'country' | 'gateBuilding' | 'postalCode' | 'streetAddress' | 'unit'>
        )>, mobile: Maybe<(
          { __typename?: 'Telephone' }
          & Pick<Telephone, 'countryCode' | 'number'>
        )>, primaryParent: Maybe<(
          { __typename?: 'Parent' }
          & { id: Parent['_id'] }
          & { mobile: (
            { __typename?: 'Telephone' }
            & Pick<Telephone, 'countryCode' | 'number'>
          ) }
        )> }
      )> }
    )> }
  )> }
);

export type BulkCreateDeliveryMutationVariables = {
  input: BulkCreateDeliveryInput
};


export type BulkCreateDeliveryMutation = (
  { __typename?: 'Mutation' }
  & { bulkCreateDelivery: Maybe<Array<(
    { __typename?: 'Delivery' }
    & { id: Delivery['_id'] }
  )>> }
);

export type GetSystemNotificationsQueryVariables = {
  search?: Maybe<Scalars['String']>,
  filterInput?: Maybe<SystemNotificationFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetSystemNotificationsQuery = (
  { __typename?: 'Query' }
  & { systemNotifications: Maybe<(
    { __typename?: 'SystemNotificationPage' }
    & Pick<SystemNotificationPage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'SystemNotification' }
      & SystemNotificationFragmentFragment
    )> }
  )> }
);

export type SystemNotificationSubscriptionVariables = {};


export type SystemNotificationSubscription = (
  { __typename?: 'Subscription' }
  & { systemNotification: (
    { __typename?: 'SystemNotification' }
    & SystemNotificationFragmentFragment
  ) }
);

export type ReadSystemNotificationMutationVariables = {
  id: Scalars['ObjectId']
};


export type ReadSystemNotificationMutation = (
  { __typename?: 'Mutation' }
  & { readSystemNotification: Maybe<(
    { __typename?: 'SystemNotification' }
    & SystemNotificationFragmentFragment
  )> }
);

export type HealthServiceChangedSubscriptionVariables = {};


export type HealthServiceChangedSubscription = (
  { __typename?: 'Subscription' }
  & Pick<Subscription, 'healthServiceChanged'>
);

export type SystemNotificationFragmentFragment = (
  { __typename?: 'SystemNotification' }
  & Pick<SystemNotification, '_id' | 'type' | 'title' | 'message' | 'hyperLinkMessage' | 'read' | 'createDateTime' | 'lastModifiedDateTime'>
  & { links: Maybe<Array<(
    { __typename?: 'NotificationHyperLink' }
    & Pick<NotificationHyperLink, 'id' | 'type' | 'keyword'>
  )>>, whatsappMessage: Maybe<(
    { __typename?: 'WhatsappMessage' }
    & WhatsappMessageInfoFragment
  )>, relatedUser: Maybe<(
    { __typename?: 'SystemUser' }
    & UserFieldsFragment
  )>, createdBy: Maybe<(
    { __typename?: 'SystemUser' }
    & UserFieldsFragment
  )>, lastModifiedBy: Maybe<(
    { __typename?: 'SystemUser' }
    & UserFieldsFragment
  )> }
);

export type GetLessonWithClassIdQueryVariables = {
  classId: Scalars['ObjectId']
};


export type GetLessonWithClassIdQuery = (
  { __typename?: 'Query' }
  & { class: Maybe<(
    { __typename?: 'Class' }
    & { id: Class['_id'] }
    & { lessons: Maybe<Array<(
      { __typename?: 'Lesson' }
      & Pick<Lesson, '_id' | 'lessonStart'>
    )>> }
  )> }
);

export type GetAnalyticsClassesQueryVariables = {
  search?: Maybe<Scalars['String']>,
  filterInput?: Maybe<ClassFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetAnalyticsClassesQuery = (
  { __typename?: 'Query' }
  & { classes: Maybe<(
    { __typename?: 'ClassPage' }
    & Pick<ClassPage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'Class' }
      & Pick<Class, 'name' | 'learningArrangement' | 'capacityUsed' | 'defaultLessonStart' | 'defaultLessonEnd' | 'defaultLessonDays' | 'isAdHoc' | 'firstLessonDate'>
      & { id: Class['_id'] }
      & { capacity: Maybe<(
        { __typename?: 'ClassCapacityDTO' }
        & CapacityFieldsFragment
      )>, teacher: Maybe<(
        { __typename?: 'SystemUser' }
        & Pick<SystemUser, 'fullName' | 'avatarUrl' | 'gender' | 'salutation' | 'initials'>
        & { id: SystemUser['_id'] }
      )>, course: (
        { __typename?: 'Course' }
        & Pick<Course, 'colorCode'>
      ), enrollments: Maybe<Array<(
        { __typename?: 'Enrollment' }
        & Pick<Enrollment, 'type' | 'status'>
        & { id: Enrollment['_id'] }
      )>> }
    )> }
  )> }
);

export type GetAcademicLevelAnalyticsClassesQueryVariables = {
  ids?: Maybe<Array<Scalars['ObjectId']>>,
  filterInput?: Maybe<ClassFilterInput>
};


export type GetAcademicLevelAnalyticsClassesQuery = (
  { __typename?: 'Query' }
  & { academicLevels: Maybe<Array<(
    { __typename?: 'AcademicLevel' }
    & Pick<AcademicLevel, 'name' | 'alias'>
    & { id: AcademicLevel['_id'] }
    & { classes: Maybe<Array<(
      { __typename?: 'Class' }
      & Pick<Class, 'name' | 'learningArrangement' | 'capacityUsed' | 'type' | 'defaultLessonStart' | 'defaultLessonDays' | 'defaultLessonEnd' | 'firstLessonDate' | 'isAdHoc'>
      & { id: Class['_id'] }
      & { lessons: Maybe<Array<(
        { __typename?: 'Lesson' }
        & Pick<Lesson, 'lessonStart'>
      )>>, capacity: Maybe<(
        { __typename?: 'ClassCapacityDTO' }
        & CapacityFieldsFragment
      )>, teacher: Maybe<(
        { __typename?: 'SystemUser' }
        & Pick<SystemUser, 'fullName' | 'avatarUrl' | 'gender' | 'salutation' | 'initials'>
        & { id: SystemUser['_id'] }
      )>, course: (
        { __typename?: 'Course' }
        & Pick<Course, 'colorCode'>
      ), dailySessionTimeSlots: Maybe<Array<(
        { __typename?: 'TimeRange' }
        & Pick<TimeRange, 'start' | 'end'>
      )>>, enrollments: Maybe<Array<(
        { __typename?: 'Enrollment' }
        & Pick<Enrollment, 'type' | 'status'>
        & { id: Enrollment['_id'] }
      )>> }
    )>>, seatsAnalyticsByAcademicLevel: Maybe<(
      { __typename?: 'SeatAnalytics' }
      & Pick<SeatAnalytics, 'totalAvailableSlots' | 'totalStudents' | 'enrollmentRating'>
    )> }
  )>> }
);

export type GetClassWithRegistrationsQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetClassWithRegistrationsQuery = (
  { __typename?: 'Query' }
  & { class: Maybe<(
    { __typename?: 'Class' }
    & Pick<Class, 'name' | 'shorthand' | 'type'>
    & { id: Class['_id'] }
    & { registrations: Maybe<Array<(
      { __typename?: 'Registration' }
      & Pick<Registration, 'status' | 'isPrioritized' | 'lastModifiedDateTime' | 'startDate' | 'waitingListCreatedAt'>
      & { id: Registration['_id'] }
      & { student: (
        { __typename?: 'Student' }
        & Pick<Student, 'fullName' | 'userId'>
        & { id: Student['_id'] }
      ) }
    )>>, capacity: Maybe<(
      { __typename?: 'ClassCapacityDTO' }
      & Pick<ClassCapacityDto, 'regular' | 'used'>
    )>, academicWeeks: Array<(
      { __typename?: 'AcademicWeek' }
      & Pick<AcademicWeek, 'startDate' | 'endDate' | 'weekNumber'>
    )> }
  )> }
);

export type CompleteImportRegistrationsMutationVariables = {
  id: Scalars['ObjectId']
};


export type CompleteImportRegistrationsMutation = (
  { __typename?: 'Mutation' }
  & { completeImportRegistrations: Maybe<(
    { __typename?: 'BulkCreateHistory' }
    & BulkCreateHistoryFieldsFragment
  )> }
);

export type ExportFailedImportRegistrationsMutationVariables = {
  id: Scalars['ObjectId']
};


export type ExportFailedImportRegistrationsMutation = (
  { __typename?: 'Mutation' }
  & { exportFailedImportRegistrations: Maybe<(
    { __typename?: 'Csv' }
    & Pick<Csv, 'url'>
  )> }
);

export type PostImportRegistrationHistorySubscriptionVariables = {};


export type PostImportRegistrationHistorySubscription = (
  { __typename?: 'Subscription' }
  & { postImportRegistrationHistory: (
    { __typename?: 'BulkCreateHistory' }
    & BulkCreateHistoryFieldsFragment
  ) }
);

export type BulkCreateHistoryQueryVariables = {
  type: BulkCreateHistoryType
};


export type BulkCreateHistoryQuery = (
  { __typename?: 'Query' }
  & { bulkCreateHistory: Maybe<(
    { __typename?: 'BulkCreateHistory' }
    & BulkCreateHistoryFieldsFragment
  )> }
);

export type ImportRegistrationHistoryCheckExportQueryVariables = {
  type: BulkCreateHistoryType
};


export type ImportRegistrationHistoryCheckExportQuery = (
  { __typename?: 'Query' }
  & { bulkCreateHistory: Maybe<(
    { __typename?: 'BulkCreateHistory' }
    & Pick<BulkCreateHistory, 'isExportFailedDocuments' | 'type'>
    & { id: BulkCreateHistory['_id'] }
  )> }
);

export type GetStudentsForImportRegistrationQueryVariables = {
  search?: Maybe<Scalars['String']>,
  filterInput?: Maybe<StudentFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetStudentsForImportRegistrationQuery = (
  { __typename?: 'Query' }
  & { students: Maybe<(
    { __typename?: 'StudentPage' }
    & Pick<StudentPage, 'total' | 'pageIndex' | 'pageSize'>
    & { items: Array<(
      { __typename?: 'Student' }
      & StudentBaseFieldsFragment
    )> }
  )> }
);

export type GetClassesForImportRegistrationQueryVariables = {
  search?: Maybe<Scalars['String']>,
  filterInput?: Maybe<ClassFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetClassesForImportRegistrationQuery = (
  { __typename?: 'Query' }
  & { classes: Maybe<(
    { __typename?: 'ClassPage' }
    & Pick<ClassPage, 'total' | 'pageIndex' | 'pageSize'>
    & { items: Array<(
      { __typename?: 'Class' }
      & Pick<Class, 'classCode' | 'type' | 'isAdHoc'>
      & { id: Class['_id'] }
      & { lessons: Maybe<Array<(
        { __typename?: 'Lesson' }
        & Pick<Lesson, 'lessonStart' | 'lessonNumber'>
        & { id: Lesson['_id'] }
      )>> }
    )> }
  )> }
);

export type BulkCreateRegistrationMutationVariables = {
  input: BulkCreateRegistrationCommand
};


export type BulkCreateRegistrationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'bulkCreateRegistration'>
);

export type RegistrationCacheFieldsFragment = (
  { __typename?: 'RegistrationIndex' }
  & Pick<RegistrationIndex, 'lastModifiedDateTime' | 'name' | 'type' | 'status' | 'waStatus' | 'emailStatus' | 'startDate' | 'createDateTime'>
  & { id: RegistrationIndex['_id'] }
  & { class: Maybe<(
    { __typename?: 'RegistrationClassIndex' }
    & Pick<RegistrationClassIndex, 'learningArrangement' | 'name'>
    & { id: RegistrationClassIndex['_id'] }
  )>, course: Maybe<(
    { __typename?: 'RegistrationCourseIndex' }
    & Pick<RegistrationCourseIndex, 'name'>
    & { id: RegistrationCourseIndex['_id'] }
  )>, classCapacity: Maybe<(
    { __typename?: 'ClassCapacityDTO' }
    & Pick<ClassCapacityDto, 'used' | 'regular' | 'replacement'>
  )>, invoice: Maybe<(
    { __typename?: 'RegistrationInvoiceIndex' }
    & Pick<RegistrationInvoiceIndex, 'status'>
    & { id: RegistrationInvoiceIndex['_id'] }
  )>, student: Maybe<(
    { __typename?: 'RegistrationStudentIndex' }
    & Pick<RegistrationStudentIndex, 'userId' | 'fullName' | 'gender' | 'avatarUrl'>
    & { id: RegistrationStudentIndex['_id'] }
  )>, lastModifiedBy: Maybe<(
    { __typename?: 'SystemUser' }
    & LastModifiedByFieldsFragment
  )>, enrollment: Maybe<(
    { __typename?: 'RegistrationEnrollmentIndex' }
    & Pick<RegistrationEnrollmentIndex, 'startDate' | 'preferredLearningArrangement' | 'group' | 'status' | 'followUpStatus' | 'followUpStatusLastModifiedDateTime' | 'followUpStatusLastModifiedByFullName' | '_id'>
  )> }
);

export type GetRegistrationFilterOptionsQueryVariables = {
  year?: Maybe<Scalars['Int']>
};


export type GetRegistrationFilterOptionsQuery = (
  { __typename?: 'Query' }
  & { academicYear: Maybe<(
    { __typename?: 'AcademicYear' }
    & Pick<AcademicYear, 'academicWeekStart' | 'academicWeekEnd'>
    & { terms: Maybe<Array<(
      { __typename?: 'Term' }
      & BasicTermFieldsFragment
    )>>, period: (
      { __typename?: 'DateTimeRange' }
      & Pick<DateTimeRange, 'start' | 'end'>
    ), academicWeeks: Array<(
      { __typename?: 'AcademicWeekWithId' }
      & Pick<AcademicWeekWithId, 'startDate' | 'endDate' | 'weekNumber'>
    )> }
  )> }
);

export type GetListRegistrationDataQueryVariables = {
  search?: Maybe<Scalars['String']>,
  filterInput?: Maybe<RegistrationFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetListRegistrationDataQuery = (
  { __typename?: 'Query' }
  & { registrationsWithIndex: Maybe<(
    { __typename?: 'RegistrationIndexPage' }
    & Pick<RegistrationIndexPage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'RegistrationIndex' }
      & RegistrationCacheFieldsFragment
    )> }
  )> }
);

export type GetRegistrationCacheQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetRegistrationCacheQuery = (
  { __typename?: 'Query' }
  & { registrationWithIndex: Maybe<(
    { __typename?: 'RegistrationIndex' }
    & RegistrationCacheFieldsFragment
  )> }
);

export type GetRegistrationOfAcademicTermQueryVariables = {
  year: Scalars['Int']
};


export type GetRegistrationOfAcademicTermQuery = (
  { __typename?: 'Query' }
  & { academicYear: Maybe<(
    { __typename?: 'AcademicYear' }
    & { id: AcademicYear['_id'] }
    & { terms: Maybe<Array<(
      { __typename?: 'Term' }
      & { id: Term['_id'] }
      & BasicTermFieldsFragment
    )>> }
  )> }
);

export type GetStudentOfActiveEnrollmentsQueryVariables = {
  id: Scalars['ObjectId'],
  input?: Maybe<StudentEnrollmentFilterInput>
};


export type GetStudentOfActiveEnrollmentsQuery = (
  { __typename?: 'Query' }
  & { student: Maybe<(
    { __typename?: 'Student' }
    & { id: Student['_id'] }
    & { enrollments: Maybe<Array<(
      { __typename?: 'Enrollment' }
      & { id: Enrollment['_id'] }
      & { class: (
        { __typename?: 'Class' }
        & { id: Class['_id'] }
      ) }
    )>> }
  )> }
);

export type GetReservationDataQueryVariables = {
  filterInput?: Maybe<RegistrationFilterInput>
};


export type GetReservationDataQuery = (
  { __typename?: 'Query' }
  & { registrations: Maybe<(
    { __typename?: 'RegistrationPage' }
    & { items: Array<(
      { __typename?: 'Registration' }
      & { id: Registration['_id'] }
    )> }
  )> }
);

export type GetSelectRegistrationTypeDataQueryVariables = {
  classId: Scalars['ObjectId']
};


export type GetSelectRegistrationTypeDataQuery = (
  { __typename?: 'Query' }
  & { class: Maybe<(
    { __typename?: 'Class' }
    & Pick<Class, 'name' | 'type' | 'defaultLessonStart' | 'defaultLessonEnd' | 'learningArrangement' | 'defaultLessonDays'>
    & { id: Class['_id'] }
    & { dailySessionTimeSlots: Maybe<Array<(
      { __typename?: 'TimeRange' }
      & Pick<TimeRange, 'start' | 'end'>
    )>>, academicYear: (
      { __typename?: 'AcademicYear' }
      & Pick<AcademicYear, 'year'>
      & { id: AcademicYear['_id'] }
    ), teacher: Maybe<(
      { __typename?: 'SystemUser' }
      & TeacherCellFieldsFragment
    )>, course: (
      { __typename?: 'Course' }
      & Pick<Course, 'name' | 'type' | 'avatarUrl'>
      & { id: Course['_id'] }
    ), venue: Maybe<(
      { __typename?: 'Venue' }
      & Pick<Venue, 'shorthand' | 'unitNo'>
      & { branch: Maybe<(
        { __typename?: 'Branch' }
        & Pick<Branch, 'name' | 'alias'>
      )> }
    )>, capacity: Maybe<(
      { __typename?: 'ClassCapacityDTO' }
      & CapacityFieldsFragment
    )>, lessons: Maybe<Array<(
      { __typename?: 'Lesson' }
      & Pick<Lesson, '_id' | 'lessonNumber' | 'lessonStart' | 'lessonEnd' | 'lessonDay'>
    )>>, enrollments: Maybe<Array<(
      { __typename?: 'Enrollment' }
      & Pick<Enrollment, '_id'>
      & { class: (
        { __typename?: 'Class' }
        & Pick<Class, 'name'>
      ), registration: Maybe<(
        { __typename?: 'Registration' }
        & { trialRegistration: Maybe<(
          { __typename?: 'Registration' }
          & Pick<Registration, 'startDate'>
          & { class: Maybe<(
            { __typename?: 'Class' }
            & Pick<Class, 'shorthand'>
          )> }
        )> }
      )>, invoices: Maybe<Array<(
        { __typename?: 'Invoice' }
        & Pick<Invoice, '_id' | 'dueDate' | 'status'>
      )>> }
      & ClassEnrollmentFieldsFragment
    )>>, registrations: Maybe<Array<(
      { __typename?: 'Registration' }
      & Pick<Registration, 'type' | 'status'>
      & { id: Registration['_id'] }
      & { student: (
        { __typename?: 'Student' }
        & StudentCellFieldsFragment
      ) }
    )>> }
  )> }
);

export type CancelRegistrationButtonActionMutationVariables = {
  id: Scalars['ObjectId']
};


export type CancelRegistrationButtonActionMutation = (
  { __typename?: 'Mutation' }
  & { cancelRegistration: Maybe<(
    { __typename?: 'Registration' }
    & { id: Registration['_id'] }
  )> }
);

export type GetBillingRegistrationQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetBillingRegistrationQuery = (
  { __typename?: 'Query' }
  & { registration: Maybe<(
    { __typename?: 'Registration' }
    & Pick<Registration, 'createDateTime' | 'currentStep' | 'type'>
    & { id: Registration['_id'] }
    & { enrollment: Maybe<(
      { __typename?: 'Enrollment' }
      & Pick<Enrollment, 'isFullEnrollment'>
    )>, invoices: Maybe<Array<(
      { __typename?: 'Invoice' }
      & InvoiceItemFieldsFragment
    )>>, student: (
      { __typename?: 'Student' }
      & { enrollments: Maybe<Array<(
        { __typename?: 'Enrollment' }
        & Pick<Enrollment, 'type' | 'status' | 'startDate' | 'endDate'>
        & { id: Enrollment['_id'] }
        & { class: (
          { __typename?: 'Class' }
          & { course: (
            { __typename?: 'Course' }
            & Pick<Course, 'name'>
          ) }
        ), attendances: Maybe<Array<(
          { __typename?: 'Attendance' }
          & Pick<Attendance, 'startDateTime'>
        )>> }
      )>> }
    ), session: Maybe<(
      { __typename?: 'Session' }
      & { registrationSession: Maybe<(
        { __typename?: 'RegistrationSession' }
        & Pick<RegistrationSession, 'registrationStage'>
      )> }
    )> }
  )> }
);

export type GetCancelClassByIdQueryVariables = {
  classId: Scalars['ObjectId']
};


export type GetCancelClassByIdQuery = (
  { __typename?: 'Query' }
  & { class: Maybe<(
    { __typename?: 'Class' }
    & Pick<Class, 'name' | 'shorthand' | 'learningArrangement'>
    & { id: Class['_id'] }
    & { course: (
      { __typename?: 'Course' }
      & Pick<Course, 'name' | 'nameShort' | 'colorCode'>
    ), lessons: Maybe<Array<(
      { __typename?: 'Lesson' }
      & Pick<Lesson, 'lessonNumber' | 'lessonStart' | 'lessonEnd'>
      & { id: Lesson['_id'] }
      & { attendance: Maybe<Array<(
        { __typename?: 'Attendance' }
        & { id: Attendance['_id'] }
      )>>, academicWeek: Maybe<(
        { __typename?: 'AcademicWeek' }
        & Pick<AcademicWeek, 'weekNumber'>
      )>, capacity: Maybe<(
        { __typename?: 'LessonCapacityDTO' }
        & LessonCapacityFieldsFragment
      )> }
    )>> }
  )> }
);

export type EnrollmentOverviewLessonsQueryVariables = {
  search?: Maybe<Scalars['String']>,
  filterInput?: Maybe<LessonFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type EnrollmentOverviewLessonsQuery = (
  { __typename?: 'Query' }
  & { lessons: Maybe<(
    { __typename?: 'LessonPage' }
    & Pick<LessonPage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'Lesson' }
      & Pick<Lesson, 'lessonDay' | 'lessonNumber' | 'lessonStart' | 'lessonEnd' | 'maximumClassCapacity'>
      & { id: Lesson['_id'] }
      & { teacher: Maybe<(
        { __typename?: 'SystemUser' }
        & Pick<SystemUser, 'fullName' | 'avatarUrl' | 'gender' | 'salutation'>
        & { id: SystemUser['_id'] }
      )>, venue: Maybe<(
        { __typename?: 'Venue' }
        & Pick<Venue, 'shorthand' | 'unitNo' | 'capacity'>
        & { id: Venue['_id'] }
        & { branch: Maybe<(
          { __typename?: 'Branch' }
          & Pick<Branch, 'name' | 'alias'>
          & { id: Branch['_id'] }
        )> }
      )>, academicWeek: Maybe<(
        { __typename?: 'AcademicWeek' }
        & Pick<AcademicWeek, 'startDate' | 'endDate'>
      )>, attendance: Maybe<Array<(
        { __typename?: 'Attendance' }
        & Pick<Attendance, 'status' | 'type'>
        & { id: Attendance['_id'] }
        & { student: Maybe<(
          { __typename?: 'Student' }
          & Pick<Student, 'fullName'>
          & { id: Student['_id'] }
        )>, linkInvoice: Maybe<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'status' | 'dueDate'>
        )>, linkArrangement: Maybe<(
          { __typename?: 'Arrangement' }
          & Pick<Arrangement, '_id'>
          & { fromClass: Maybe<(
            { __typename?: 'Class' }
            & Pick<Class, 'shorthand'>
          )> }
        )> }
      )>>, class: Maybe<(
        { __typename?: 'Class' }
        & Pick<Class, 'name' | 'type' | 'shorthand' | 'runNumber' | 'maximumClassCapacity' | 'maximumMakeUpCapacity' | 'capacityUsed' | 'learningArrangement'>
        & { id: Class['_id'] }
        & { academicLevels: Array<(
          { __typename?: 'AcademicLevel' }
          & Pick<AcademicLevel, '_id'>
        )>, lessons: Maybe<Array<(
          { __typename?: 'Lesson' }
          & Pick<Lesson, 'lessonStart' | 'lessonEnd' | 'lessonNumber'>
          & { id: Lesson['_id'] }
        )>>, digitalClassAccessCredentials: Maybe<(
          { __typename?: 'DigitalClassAccessCredentials' }
          & Pick<DigitalClassAccessCredentials, 'zoomMeetingLink' | 'zoomMeetingPassword' | 'zoomMeetingId' | 'googleMeetLink' | 'googleMeetId'>
        )> }
      )>, course: (
        { __typename?: 'Course' }
        & Pick<Course, 'name' | 'nameShort' | 'avatarUrl' | 'type' | 'colorCode'>
        & { id: Course['_id'] }
      ), capacity: Maybe<(
        { __typename?: 'LessonCapacityDTO' }
        & { seats: Maybe<Array<(
          { __typename?: 'LessonSeatDTO' }
          & { student: Maybe<(
            { __typename?: 'Student' }
            & StudentCellFieldsFragment
          )> }
        )>> }
        & LessonCapacityFieldsFragment
      )> }
    )> }
  )> }
);

export type EnrollmentOverviewClassQueryVariables = {
  filterInput?: Maybe<ClassFilterInput>
};


export type EnrollmentOverviewClassQuery = (
  { __typename?: 'Query' }
  & { classEnrollmentOverview: Maybe<Array<(
    { __typename?: 'ClassEnrollmentOverview' }
    & Pick<ClassEnrollmentOverview, 'classId' | 'serializedData'>
    & { capacity: Maybe<(
      { __typename?: 'ClassCapacityDTO' }
      & CapacityFieldsFragment
    )> }
  )>> }
);

export type SmsFieldsFragment = (
  { __typename?: 'Class' }
  & Pick<Class, 'name' | 'runNumber' | 'shorthand' | 'type' | 'defaultLessonDays' | 'defaultLessonEnd' | 'defaultLessonStart' | 'lessonNumber' | 'maximumClassCapacity' | 'learningArrangement'>
  & { id: Class['_id'] }
  & { academicLevels: Array<(
    { __typename?: 'AcademicLevel' }
    & Pick<AcademicLevel, '_id'>
  )>, teacher: Maybe<(
    { __typename?: 'SystemUser' }
    & Pick<SystemUser, 'fullName' | 'avatarUrl' | 'gender' | 'salutation'>
    & { id: SystemUser['_id'] }
  )>, venue: Maybe<(
    { __typename?: 'Venue' }
    & Pick<Venue, 'unitNo' | 'shorthand'>
    & { id: Venue['_id'] }
    & { branch: Maybe<(
      { __typename?: 'Branch' }
      & Pick<Branch, 'name' | 'alias'>
      & { id: Branch['_id'] }
    )> }
  )>, enrollments: Maybe<Array<(
    { __typename?: 'Enrollment' }
    & { student: Maybe<(
      { __typename?: 'Student' }
      & Pick<Student, '_id' | 'fullName'>
    )>, invoices: Maybe<Array<(
      { __typename?: 'Invoice' }
      & Pick<Invoice, '_id' | 'dueDate'>
    )>>, attendances: Maybe<Array<(
      { __typename?: 'Attendance' }
      & Pick<Attendance, '_id' | 'endDateTime'>
      & { lesson: (
        { __typename?: 'Lesson' }
        & Pick<Lesson, '_id'>
      ) }
    )>>, registration: Maybe<(
      { __typename?: 'Registration' }
      & { trialRegistration: Maybe<(
        { __typename?: 'Registration' }
        & Pick<Registration, 'startDate'>
        & { class: Maybe<(
          { __typename?: 'Class' }
          & Pick<Class, 'shorthand'>
        )> }
      )> }
    )> }
    & BasicEnrollmentFieldFragment
  )>>, lessons: Maybe<Array<(
    { __typename?: 'Lesson' }
    & Pick<Lesson, '_id' | 'lessonStart' | 'lessonNumber'>
  )>>, capacity: Maybe<(
    { __typename?: 'ClassCapacityDTO' }
    & CapacityFieldsFragment
  )>, digitalClassAccessCredentials: Maybe<(
    { __typename?: 'DigitalClassAccessCredentials' }
    & Pick<DigitalClassAccessCredentials, 'zoomMeetingLink' | 'zoomMeetingPassword' | 'zoomMeetingId' | 'googleMeetLink' | 'googleMeetId'>
  )>, course: (
    { __typename?: 'Course' }
    & Pick<Course, 'nameShort' | 'colorCode'>
    & { id: Course['_id'] }
  ) }
);

export type StudentRelationQueryVariables = {
  studentId: Scalars['ObjectId']
};


export type StudentRelationQuery = (
  { __typename?: 'Query' }
  & { student: Maybe<(
    { __typename?: 'Student' }
    & { siblings: Maybe<Array<(
      { __typename?: 'Student' }
      & { enrollments: Maybe<Array<(
        { __typename?: 'Enrollment' }
        & Pick<Enrollment, 'type'>
        & { id: Enrollment['_id'] }
      )>> }
      & StudentCellFieldsFragment
    )>> }
    & StudentCellFieldsFragment
  )> }
);

export type GetMakeupListAnalyticsLessonsQueryVariables = {
  search?: Maybe<Scalars['String']>,
  filterInput?: Maybe<LessonFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetMakeupListAnalyticsLessonsQuery = (
  { __typename?: 'Query' }
  & { lessons: Maybe<(
    { __typename?: 'LessonPage' }
    & Pick<LessonPage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'Lesson' }
      & Pick<Lesson, 'lessonDay' | 'lessonNumber' | 'lessonStart' | 'lessonEnd' | 'maximumClassCapacity'>
      & { id: Lesson['_id'] }
      & { teacher: Maybe<(
        { __typename?: 'SystemUser' }
        & TeacherCellFieldsFragment
      )>, class: Maybe<(
        { __typename?: 'Class' }
        & Pick<Class, 'name' | 'type' | 'shorthand' | 'runNumber' | 'maximumClassCapacity' | 'maximumMakeUpCapacity' | 'capacityUsed' | 'learningArrangement'>
        & { id: Class['_id'] }
        & { academicLevels: Array<(
          { __typename?: 'AcademicLevel' }
          & Pick<AcademicLevel, '_id'>
        )> }
      )>, capacity: Maybe<(
        { __typename?: 'LessonCapacityDTO' }
        & LessonCapacityFieldsFragment
      )> }
    )> }
  )> }
);

export type GetFindClassDataQueryVariables = {
  search?: Maybe<Scalars['String']>,
  filterInput?: Maybe<ClassFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  sortInfo?: Maybe<SortInfo>
};


export type GetFindClassDataQuery = (
  { __typename?: 'Query' }
  & { classes: Maybe<(
    { __typename?: 'ClassPage' }
    & Pick<ClassPage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'Class' }
      & Pick<Class, 'name' | 'status' | 'defaultLessonStart' | 'defaultLessonEnd' | 'defaultLessonDays' | 'type' | 'isAdHoc' | 'learningArrangement'>
      & { id: Class['_id'] }
      & { dailySessionTimeSlots: Maybe<Array<(
        { __typename?: 'TimeRange' }
        & Pick<TimeRange, 'start' | 'end'>
      )>>, course: (
        { __typename?: 'Course' }
        & Pick<Course, 'name' | 'colorCode'>
        & { id: Course['_id'] }
      ), teacher: Maybe<(
        { __typename?: 'SystemUser' }
        & Pick<SystemUser, 'fullName' | 'gender' | 'avatarUrl' | 'salutation' | 'initials'>
        & { id: SystemUser['_id'] }
      )>, venue: Maybe<(
        { __typename?: 'Venue' }
        & Pick<Venue, 'unitNo' | 'shorthand'>
        & { branch: Maybe<(
          { __typename?: 'Branch' }
          & Pick<Branch, 'name' | 'alias'>
        )> }
      )>, capacity: Maybe<(
        { __typename?: 'ClassCapacityDTO' }
        & CapacityFieldsFragment
      )>, enrollments: Maybe<Array<(
        { __typename?: 'Enrollment' }
        & Pick<Enrollment, '_id' | 'type'>
      )>>, digitalClassAccessCredentials: Maybe<(
        { __typename?: 'DigitalClassAccessCredentials' }
        & Pick<DigitalClassAccessCredentials, 'zoomMeetingId' | 'zoomMeetingPassword' | 'zoomMeetingLink' | 'googleMeetId' | 'googleMeetLink'>
      )> }
    )> }
  )> }
);

export type GetOptionClassesQueryVariables = {
  search?: Maybe<Scalars['String']>,
  filterInput?: Maybe<ClassFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetOptionClassesQuery = (
  { __typename?: 'Query' }
  & { classes: Maybe<(
    { __typename?: 'ClassPage' }
    & { items: Array<(
      { __typename?: 'Class' }
      & Pick<Class, 'name'>
      & { id: Class['_id'] }
    )> }
  )> }
);

export type GetFindClassStudentQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetFindClassStudentQuery = (
  { __typename?: 'Query' }
  & { student: Maybe<(
    { __typename?: 'Student' }
    & { academicLevel: Maybe<(
      { __typename?: 'AcademicLevel' }
      & { id: AcademicLevel['_id'] }
    )> }
  )> }
);

export type GetFindClassByIdOnRegistrationModalQueryVariables = {
  classId: Scalars['ObjectId'],
  studentId: Scalars['ObjectId'],
  skipHoliday?: Maybe<Scalars['Boolean']>
};


export type GetFindClassByIdOnRegistrationModalQuery = (
  { __typename?: 'Query' }
  & { classFromStudent: Maybe<(
    { __typename?: 'Class' }
    & Pick<Class, 'type' | 'learningArrangement' | 'isAdHoc'>
    & { id: Class['_id'] }
    & { course: (
      { __typename?: 'Course' }
      & Pick<Course, 'name'>
      & { courseFee: Maybe<(
        { __typename?: 'CourseFee' }
        & { otherFees: Maybe<Array<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'name' | 'amount'>
        )>> }
      )> }
    ), lessons: Maybe<Array<(
      { __typename?: 'Lesson' }
      & Pick<Lesson, 'lessonStart'>
      & { id: Lesson['_id'] }
    )>>, eligibility: Maybe<Array<(
      { __typename?: 'ClassEligibility' }
      & Pick<ClassEligibility, 'stage' | 'lessonDates'>
    )>> }
  )> }
);

export type GetFindClassByIdQueryVariables = {
  classId: Scalars['ObjectId'],
  studentId: Scalars['ObjectId'],
  skipHoliday?: Maybe<Scalars['Boolean']>
};


export type GetFindClassByIdQuery = (
  { __typename?: 'Query' }
  & { classFromStudent: Maybe<(
    { __typename?: 'Class' }
    & Pick<Class, 'name' | 'type' | 'capacityUsed' | 'maximumClassCapacity' | 'defaultLessonStart' | 'defaultLessonEnd' | 'learningArrangement' | 'isAdHoc'>
    & { id: Class['_id'] }
    & { course: (
      { __typename?: 'Course' }
      & Pick<Course, 'name' | 'type'>
      & { courseFee: Maybe<(
        { __typename?: 'CourseFee' }
        & { otherFees: Maybe<Array<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'name' | 'amount'>
        )>> }
      )> }
    ), teacher: Maybe<(
      { __typename?: 'SystemUser' }
      & BasicTeacherFieldsFragment
    )>, academicYear: (
      { __typename?: 'AcademicYear' }
      & Pick<AcademicYear, 'year'>
    ), venue: Maybe<(
      { __typename?: 'Venue' }
      & Pick<Venue, 'name'>
      & { branch: Maybe<(
        { __typename?: 'Branch' }
        & Pick<Branch, 'name' | 'alias'>
      )> }
    )>, activeEnrollments: Maybe<Array<(
      { __typename?: 'Enrollment' }
      & Pick<Enrollment, 'status' | 'preferredLearningArrangement' | 'group'>
      & { id: Enrollment['_id'] }
      & { class: (
        { __typename?: 'Class' }
        & Pick<Class, 'isAdHoc'>
        & { course: (
          { __typename?: 'Course' }
          & Pick<Course, 'type'>
        ) }
      ), student: Maybe<(
        { __typename?: 'Student' }
        & Pick<Student, 'avatarUrl' | 'fullName' | 'gender'>
        & { id: Student['_id'] }
        & { primarySchool: Maybe<(
          { __typename?: 'School' }
          & Pick<School, 'name'>
        )>, secondarySchool: Maybe<(
          { __typename?: 'School' }
          & Pick<School, 'name'>
        )>, academicLevel: Maybe<(
          { __typename?: 'AcademicLevel' }
          & Pick<AcademicLevel, 'educationStage'>
        )> }
      )> }
    )>>, lessons: Maybe<Array<(
      { __typename?: 'Lesson' }
      & Pick<Lesson, 'lessonStart'>
      & { id: Lesson['_id'] }
      & { capacity: Maybe<(
        { __typename?: 'LessonCapacityDTO' }
        & LessonCapacityFieldsFragment
      )> }
    )>> }
  )> }
);

export type GetActiveWaitingListsQueryVariables = {
  studentId: Scalars['ObjectId'],
  classId: Scalars['ObjectId']
};


export type GetActiveWaitingListsQuery = (
  { __typename?: 'Query' }
  & { registrations: Maybe<(
    { __typename?: 'RegistrationPage' }
    & { items: Array<(
      { __typename?: 'Registration' }
      & Pick<Registration, 'name' | 'status'>
      & { id: Registration['_id'] }
      & { class: Maybe<(
        { __typename?: 'Class' }
        & Pick<Class, 'name'>
        & { id: Class['_id'] }
        & { capacity: Maybe<(
          { __typename?: 'ClassCapacityDTO' }
          & CapacityFieldsFragment
        )> }
      )> }
    )> }
  )> }
);

export type NotifyClassFragment = (
  { __typename?: 'Class' }
  & Pick<Class, 'name' | 'defaultLessonStart' | 'defaultLessonEnd' | 'defaultLessonDays' | 'learningArrangement'>
  & { id: Class['_id'] }
  & { course: (
    { __typename?: 'Course' }
    & Pick<Course, 'name'>
    & { id: Course['_id'] }
  ), teacher: Maybe<(
    { __typename?: 'SystemUser' }
    & BasicTeacherFieldsFragment
  )>, venue: Maybe<(
    { __typename?: 'Venue' }
    & Pick<Venue, 'shorthand' | 'unitNo'>
    & { branch: Maybe<(
      { __typename?: 'Branch' }
      & Pick<Branch, 'name' | 'alias'>
    )> }
  )>, enrollments: Maybe<Array<(
    { __typename?: 'Enrollment' }
    & Pick<Enrollment, '_id' | 'type'>
  )>>, capacity: Maybe<(
    { __typename?: 'ClassCapacityDTO' }
    & CapacityFieldsFragment
  )> }
);

export type GetNotifyRegistrationQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetNotifyRegistrationQuery = (
  { __typename?: 'Query' }
  & { registration: Maybe<(
    { __typename?: 'Registration' }
    & Pick<Registration, 'name' | 'type' | 'currentStep' | 'startDate'>
    & { id: Registration['_id'] }
    & { invoices: Maybe<Array<(
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'total'>
    )>>, enrollment: Maybe<(
      { __typename?: 'Enrollment' }
      & Pick<Enrollment, 'type' | 'isFullEnrollment'>
    )>, linkRegistration: Maybe<(
      { __typename?: 'Registration' }
      & Pick<Registration, 'name' | 'type' | 'startDate'>
      & { id: Registration['_id'] }
      & { selectedLessons: Maybe<Array<(
        { __typename?: 'SelectedLesson' }
        & { lesson: (
          { __typename?: 'Lesson' }
          & Pick<Lesson, 'lessonNumber' | 'lessonStart'>
        ) }
      )>>, class: Maybe<(
        { __typename?: 'Class' }
        & ArrangementClassCardFieldsFragment
      )>, enrollment: Maybe<(
        { __typename?: 'Enrollment' }
        & Pick<Enrollment, 'type' | 'isFullEnrollment'>
      )> }
    )>, selectedLessons: Maybe<Array<(
      { __typename?: 'SelectedLesson' }
      & { lesson: (
        { __typename?: 'Lesson' }
        & Pick<Lesson, 'lessonNumber' | 'lessonStart'>
      ) }
    )>>, class: Maybe<(
      { __typename?: 'Class' }
      & Pick<Class, 'type' | 'academicYearId'>
      & ArrangementClassCardFieldsFragment
    )>, student: (
      { __typename?: 'Student' }
      & Pick<Student, 'alternateUserId' | 'fullName' | 'userId' | 'gender'>
      & { id: Student['_id'] }
      & { primaryParent: Maybe<(
        { __typename?: 'Parent' }
        & Pick<Parent, 'fullName' | 'alias' | 'gender' | 'email'>
        & { id: Parent['_id'] }
        & { mobile: (
          { __typename?: 'Telephone' }
          & TelephoneFieldsFragment
        ) }
      )>, primarySchool: Maybe<(
        { __typename?: 'School' }
        & BasicSchoolFieldsFragment
      )> }
    ), session: Maybe<(
      { __typename?: 'Session' }
      & { registrationSession: Maybe<(
        { __typename?: 'RegistrationSession' }
        & Pick<RegistrationSession, 'registrationStage'>
      )> }
    )> }
  )> }
);

export type GetWaitingListMoreClassesQueryVariables = {
  courseId: Scalars['ObjectId'],
  studentId: Scalars['ObjectId'],
  status?: Maybe<Array<ClassStatus>>,
  academicYearId?: Maybe<Scalars['ObjectId']>
};


export type GetWaitingListMoreClassesQuery = (
  { __typename?: 'Query' }
  & { course: Maybe<(
    { __typename?: 'Course' }
    & { classes: Maybe<Array<(
      { __typename?: 'Class' }
      & { eligibility: Maybe<Array<(
        { __typename?: 'ClassEligibility' }
        & Pick<ClassEligibility, 'stage'>
      )>> }
      & ArrangementClassCardFieldsFragment
    )>> }
  )> }
);

export type CreateWaitingListsForMultipleClassesMutationVariables = {
  input: CreateWaitingListsForStudentInput
};


export type CreateWaitingListsForMultipleClassesMutation = (
  { __typename?: 'Mutation' }
  & { createWaitingListsForMultipleClasses: Maybe<Array<(
    { __typename?: 'Registration' }
    & { id: Registration['_id'] }
    & { class: Maybe<(
      { __typename?: 'Class' }
      & ArrangementClassCardFieldsFragment
    )> }
  )>> }
);

export type GetDetailRegistrationQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetDetailRegistrationQuery = (
  { __typename?: 'Query' }
  & { registration: Maybe<(
    { __typename?: 'Registration' }
    & Pick<Registration, 'name' | 'type' | 'startDate' | 'status' | 'remarks' | 'dueDate' | 'createDateTime' | 'approvalDate' | 'waStatus' | 'emailStatus' | 'lastModifiedDateTime'>
    & { id: Registration['_id'] }
    & { invoice: Maybe<(
      { __typename?: 'Invoice' }
      & InvoiceItemFieldsFragment
    )>, createdBy: Maybe<(
      { __typename?: 'SystemUser' }
      & CreateByFieldsFragment
    )>, class: Maybe<(
      { __typename?: 'Class' }
      & ArrangementClassCardFieldsFragment
    )>, student: (
      { __typename?: 'Student' }
      & Pick<Student, 'fullName' | 'alternateUserId' | 'userId'>
      & { id: Student['_id'] }
    ), enrollment: Maybe<(
      { __typename?: 'Enrollment' }
      & Pick<Enrollment, 'type' | 'isFullEnrollment' | 'preferredLearningArrangement' | 'group' | 'followUpStatus'>
      & { id: Enrollment['_id'] }
    )>, reminders: Maybe<Array<(
      { __typename?: 'Reminder' }
      & Pick<Reminder, 'subject' | 'message' | 'type' | 'sendDate' | 'sendgridEmailStatus'>
      & { id: Reminder['_id'] }
      & { whatsappMessage: Maybe<(
        { __typename?: 'WhatsappMessage' }
        & Pick<WhatsappMessage, 'status'>
      )> }
    )>>, lastModifiedBy: Maybe<(
      { __typename?: 'SystemUser' }
      & LastModifiedByFieldsFragment
    )>, effectedWaitingLists: Maybe<Array<(
      { __typename?: 'Registration' }
      & Pick<Registration, 'startDate' | 'createDateTime'>
      & { id: Registration['_id'] }
      & { student: (
        { __typename?: 'Student' }
        & StudentCellFieldsFragment
      ) }
    )>>, otherRegistrations: Maybe<Array<(
      { __typename?: 'Registration' }
      & Pick<Registration, 'name' | 'status'>
      & { class: Maybe<(
        { __typename?: 'Class' }
        & Pick<Class, 'name'>
        & { course: (
          { __typename?: 'Course' }
          & Pick<Course, 'colorCode'>
        ) }
      )> }
    )>> }
  )> }
);

export type GetTimerRegistrationQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetTimerRegistrationQuery = (
  { __typename?: 'Query' }
  & { registration: Maybe<(
    { __typename?: 'Registration' }
    & Pick<Registration, 'createDateTime' | 'type' | 'incompleteDeadline' | 'status'>
    & { id: Registration['_id'] }
    & { session: Maybe<(
      { __typename?: 'Session' }
      & Pick<Session, 'expiredAt'>
    )> }
  )> }
);

export type PostIncompleteRegistrationSubscriptionVariables = {};


export type PostIncompleteRegistrationSubscription = (
  { __typename?: 'Subscription' }
  & { postIncompleteRegistration: (
    { __typename?: 'Registration' }
    & Pick<Registration, 'type' | 'status' | 'isDeleted'>
    & { id: Registration['_id'] }
    & { session: Maybe<(
      { __typename?: 'Session' }
      & Pick<Session, 'expiredAt'>
    )> }
  ) }
);

export type GetRegistrationByIdQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetRegistrationByIdQuery = (
  { __typename?: 'Query' }
  & { registration: Maybe<(
    { __typename?: 'Registration' }
    & RegistrationOnChecksFieldsFragment
  )> }
);

export type GetHybridGroupsClassQueryVariables = {
  id: Scalars['ObjectId'],
  status?: Maybe<Array<EnrollmentStatus>>,
  types?: Maybe<Array<EnrollmentType>>
};


export type GetHybridGroupsClassQuery = (
  { __typename?: 'Query' }
  & { class: Maybe<(
    { __typename?: 'Class' }
    & Pick<Class, 'type' | 'isAdHoc'>
    & { id: Class['_id'] }
    & { lessons: Maybe<Array<(
      { __typename?: 'Lesson' }
      & Pick<Lesson, 'lessonNumber' | 'lessonStart' | 'lessonEnd'>
      & { id: Lesson['_id'] }
    )>>, enrollments: Maybe<Array<(
      { __typename?: 'Enrollment' }
      & Pick<Enrollment, 'group' | 'preferredLearningArrangement'>
      & { id: Enrollment['_id'] }
      & { student: Maybe<(
        { __typename?: 'Student' }
        & StudentCellFieldsFragment
      )> }
    )>> }
  )> }
);

export type GetClassForReservationQueryVariables = {
  id: Scalars['ObjectId'],
  studentId: Scalars['ObjectId'],
  linkRegistrationId: Scalars['ObjectId']
};


export type GetClassForReservationQuery = (
  { __typename?: 'Query' }
  & { class: Maybe<(
    { __typename?: 'Class' }
    & Pick<Class, 'name' | 'type' | 'defaultLessonDays' | 'defaultLessonStart' | 'defaultLessonEnd' | 'learningArrangement'>
    & { id: Class['_id'] }
    & { dailySessionTimeSlots: Maybe<Array<(
      { __typename?: 'TimeRange' }
      & Pick<TimeRange, 'start' | 'end'>
    )>>, venue: Maybe<(
      { __typename?: 'Venue' }
      & Pick<Venue, 'shorthand' | 'unitNo'>
      & { id: Venue['_id'] }
      & { branch: Maybe<(
        { __typename?: 'Branch' }
        & Pick<Branch, 'name' | 'alias'>
        & { id: Branch['_id'] }
      )> }
    )>, eligibility: Maybe<Array<(
      { __typename?: 'ClassEligibility' }
      & Pick<ClassEligibility, 'lessonDates' | 'stage'>
    )>>, course: (
      { __typename?: 'Course' }
      & BasicCourseFieldsFragment
    ), teacher: Maybe<(
      { __typename?: 'SystemUser' }
      & BasicTeacherFieldsFragment
    )>, lessons: Maybe<Array<(
      { __typename?: 'Lesson' }
      & SelectedLessonFieldsFragment
    )>>, academicWeeks: Array<(
      { __typename?: 'AcademicWeek' }
      & Pick<AcademicWeek, 'weekNumber' | 'startDate' | 'endDate'>
    )>, academicYear: (
      { __typename?: 'AcademicYear' }
      & BasicAcademicYearFieldsFragment
    ), enrollments: Maybe<Array<(
      { __typename?: 'Enrollment' }
      & Pick<Enrollment, '_id' | 'type'>
    )>>, capacity: Maybe<(
      { __typename?: 'ClassCapacityDTO' }
      & CapacityFieldsFragment
    )> }
  )> }
);

export type GetClassesInCourseQueryVariables = {
  id: Scalars['ObjectId'],
  studentId?: Maybe<Scalars['ObjectId']>,
  linkRegistrationId?: Maybe<Scalars['ObjectId']>,
  status?: Maybe<Array<ClassStatus>>,
  academicYearId?: Maybe<Scalars['ObjectId']>
};


export type GetClassesInCourseQuery = (
  { __typename?: 'Query' }
  & { course: Maybe<(
    { __typename?: 'Course' }
    & Pick<Course, 'colorCode'>
    & { classes: Maybe<Array<(
      { __typename?: 'Class' }
      & Pick<Class, 'name' | 'shorthand' | 'defaultLessonDays' | 'defaultLessonStart' | 'defaultLessonEnd'>
      & { id: Class['_id'] }
      & { term: Maybe<(
        { __typename?: 'Term' }
        & BasicTermFieldsFragment
      )>, lessons: Maybe<Array<(
        { __typename?: 'Lesson' }
        & Pick<Lesson, 'lessonStart' | 'lessonEnd' | 'lessonNumber'>
        & { id: Lesson['_id'] }
      )>>, teacher: Maybe<(
        { __typename?: 'SystemUser' }
        & Pick<SystemUser, 'salutation' | 'fullName'>
      )>, eligibility: Maybe<Array<(
        { __typename?: 'ClassEligibility' }
        & Pick<ClassEligibility, 'lessonDates' | 'stage'>
      )>> }
    )>> }
  )> }
);

export type GetClassEligibilityQueryVariables = {
  id: Scalars['ObjectId'],
  studentId: Scalars['ObjectId'],
  linkRegistrationId?: Maybe<Scalars['ObjectId']>
};


export type GetClassEligibilityQuery = (
  { __typename?: 'Query' }
  & { class: Maybe<(
    { __typename?: 'Class' }
    & Pick<Class, 'type'>
    & { eligibility: Maybe<Array<(
      { __typename?: 'ClassEligibility' }
      & Pick<ClassEligibility, 'stage' | 'lessonDates'>
    )>> }
  )> }
);

export type ExecuteEligibilityFlowWithCourseIdQueryVariables = {
  courseId: Scalars['ObjectId'],
  studentId: Scalars['ObjectId']
};


export type ExecuteEligibilityFlowWithCourseIdQuery = (
  { __typename?: 'Query' }
  & { executeEligibilityFlowWithCourseId: Maybe<(
    { __typename?: 'FlowResult' }
    & Pick<FlowResult, 'message' | 'outcome'>
  )> }
);

export type GetEnrollmentQueryVariables = {
  enrollmentId: Scalars['ObjectId']
};


export type GetEnrollmentQuery = (
  { __typename?: 'Query' }
  & { enrollment: Maybe<(
    { __typename?: 'Enrollment' }
    & Pick<Enrollment, 'group' | 'preferredLearningArrangement'>
    & { id: Enrollment['_id'] }
  )> }
);

export type RegistrationCsvQueryVariables = {
  search?: Maybe<Scalars['String']>,
  filterInput?: Maybe<RegistrationFilterInput>
};


export type RegistrationCsvQuery = (
  { __typename?: 'Query' }
  & { csv: Maybe<(
    { __typename?: 'Csv' }
    & Pick<Csv, 'url'>
  )> }
);

export type GetRegistrationForActionQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetRegistrationForActionQuery = (
  { __typename?: 'Query' }
  & { registration: Maybe<(
    { __typename?: 'Registration' }
    & { id: Registration['_id'] }
    & { effectedWaitingLists: Maybe<Array<(
      { __typename?: 'Registration' }
      & Pick<Registration, 'startDate' | 'createDateTime'>
      & { id: Registration['_id'] }
      & { student: (
        { __typename?: 'Student' }
        & StudentCellFieldsFragment
      ) }
    )>>, otherRegistrations: Maybe<Array<(
      { __typename?: 'Registration' }
      & Pick<Registration, 'name' | 'status'>
      & { id: Registration['_id'] }
      & { class: Maybe<(
        { __typename?: 'Class' }
        & Pick<Class, 'name'>
        & { course: (
          { __typename?: 'Course' }
          & Pick<Course, 'colorCode'>
        ) }
      )>, enrollment: Maybe<(
        { __typename?: 'Enrollment' }
        & Pick<Enrollment, 'preferredLearningArrangement' | 'group'>
      )> }
    )>> }
  )> }
);

export type CancelRegistrationMutationVariables = {
  id: Scalars['ObjectId'],
  isCancelOtherRegistration?: Maybe<Scalars['Boolean']>
};


export type CancelRegistrationMutation = (
  { __typename?: 'Mutation' }
  & { cancelRegistration: Maybe<(
    { __typename?: 'Registration' }
    & Pick<Registration, 'status'>
    & { id: Registration['_id'] }
  )> }
);

export type CreateRegistrationMutationVariables = {
  input: CreateRegistrationInput
};


export type CreateRegistrationMutation = (
  { __typename?: 'Mutation' }
  & { createRegistration: Maybe<(
    { __typename?: 'Registration' }
    & { id: Registration['_id'] }
    & { enrollment: Maybe<(
      { __typename?: 'Enrollment' }
      & Pick<Enrollment, 'group'>
      & { id: Enrollment['_id'] }
    )>, session: Maybe<(
      { __typename?: 'Session' }
      & { id: Session['_id'] }
    )> }
  )> }
);

export type RegenerateRegistrationMutationVariables = {
  input: RegenerateRegistrationInput
};


export type RegenerateRegistrationMutation = (
  { __typename?: 'Mutation' }
  & { regenerateRegistration: Maybe<(
    { __typename?: 'Registration' }
    & Pick<Registration, 'startDate' | 'dueDate'>
    & { id: Registration['_id'] }
    & { selectedLessons: Maybe<Array<(
      { __typename?: 'SelectedLesson' }
      & { lesson: (
        { __typename?: 'Lesson' }
        & SelectedLessonFieldsFragment
      ), makeUpLesson: Maybe<(
        { __typename?: 'Lesson' }
        & SelectedLessonFieldsFragment
      )> }
    )>>, session: Maybe<(
      { __typename?: 'Session' }
      & { registrationSession: Maybe<(
        { __typename?: 'RegistrationSession' }
        & Pick<RegistrationSession, 'registrationStage' | 'regenerateStartData'>
      )> }
    )> }
  )> }
);

export type ExtendsRegistrationTimeMutationVariables = {
  id: Scalars['ObjectId']
};


export type ExtendsRegistrationTimeMutation = (
  { __typename?: 'Mutation' }
  & { extendsRegistrationTime: Maybe<(
    { __typename?: 'Registration' }
    & Pick<Registration, 'name' | 'createDateTime'>
    & { id: Registration['_id'] }
  )> }
);

export type ApproveRegistrationMutationVariables = {
  input: ApproveRegistrationInput
};


export type ApproveRegistrationMutation = (
  { __typename?: 'Mutation' }
  & { approveRegistration: Maybe<(
    { __typename?: 'Registration' }
    & { id: Registration['_id'] }
  )> }
);

export type ProcessRegistrationMutationVariables = {
  id: Scalars['ObjectId']
};


export type ProcessRegistrationMutation = (
  { __typename?: 'Mutation' }
  & { processRegistration: Maybe<(
    { __typename?: 'Registration' }
    & { id: Registration['_id'] }
  )> }
);

export type CreateBillingforRegistrationMutationVariables = {
  id: Scalars['ObjectId'],
  currentStep: Scalars['Int']
};


export type CreateBillingforRegistrationMutation = (
  { __typename?: 'Mutation' }
  & { createBillingForRegistration: Maybe<(
    { __typename?: 'Registration' }
    & Pick<Registration, 'currentStep'>
    & { id: Registration['_id'] }
  )> }
);

export type RegistrationIncrementStepMutationVariables = {
  registrationId: Scalars['ObjectId'],
  currentStep: Scalars['Int']
};


export type RegistrationIncrementStepMutation = (
  { __typename?: 'Mutation' }
  & { registrationIncrementStep: Maybe<(
    { __typename?: 'Registration' }
    & Pick<Registration, 'currentStep'>
    & { id: Registration['_id'] }
  )> }
);

export type UpdateRegistrationMutationVariables = {
  input: UpdateRegistrationInput
};


export type UpdateRegistrationMutation = (
  { __typename?: 'Mutation' }
  & { updateRegistration: Maybe<(
    { __typename?: 'Registration' }
    & Pick<Registration, 'currentStep'>
    & { id: Registration['_id'] }
  )> }
);

export type ChangeAttendingClassForRegistrationMutationVariables = {
  input: ChangeAttendingClassForRegistrationInput
};


export type ChangeAttendingClassForRegistrationMutation = (
  { __typename?: 'Mutation' }
  & { changeAttendingClassForRegistration: Maybe<(
    { __typename?: 'Registration' }
    & RegistrationOnChecksFieldsFragment
  )> }
);

export type CancelChangeAttendingClassForRegistrationMutationVariables = {
  input: ChangeAttendingClassForRegistrationInput
};


export type CancelChangeAttendingClassForRegistrationMutation = (
  { __typename?: 'Mutation' }
  & { cancelChangeAttendingClassForRegistration: Maybe<(
    { __typename?: 'Registration' }
    & RegistrationOnChecksFieldsFragment
  )> }
);

export type UpdateEnrollmentPreferredLearningArrangementMutationVariables = {
  input: UpdateEnrollmentPreferredLearningArrangementInput
};


export type UpdateEnrollmentPreferredLearningArrangementMutation = (
  { __typename?: 'Mutation' }
  & { updateEnrollmentPreferredLearningArrangement: Maybe<(
    { __typename?: 'Enrollment' }
    & Pick<Enrollment, 'group' | 'preferredLearningArrangement'>
    & { id: Enrollment['_id'] }
    & { student: Maybe<(
      { __typename?: 'Student' }
      & StudentCellFieldsFragment
    )> }
  )> }
);

export type GetInCompleteRegistrationQueryVariables = {};


export type GetInCompleteRegistrationQuery = (
  { __typename?: 'Query' }
  & { sessions: Maybe<Array<(
    { __typename?: 'Session' }
    & Pick<Session, 'expiredAt'>
    & { registration: Maybe<(
      { __typename?: 'Registration' }
      & Pick<Registration, 'name' | 'type' | 'currentStep' | 'incompleteDeadline' | 'createDateTime'>
      & { id: Registration['_id'] }
      & { invoices: Maybe<Array<(
        { __typename?: 'Invoice' }
        & { id: Invoice['_id'] }
      )>>, class: Maybe<(
        { __typename?: 'Class' }
        & { id: Class['_id'] }
      )>, enrollment: Maybe<(
        { __typename?: 'Enrollment' }
        & { id: Enrollment['_id'] }
      )>, student: (
        { __typename?: 'Student' }
        & { id: Student['_id'] }
      ) }
    )> }
  )>> }
);

export type GetRegistrationForWaitingListActionQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetRegistrationForWaitingListActionQuery = (
  { __typename?: 'Query' }
  & { registration: Maybe<(
    { __typename?: 'Registration' }
    & Pick<Registration, 'status' | 'startDate'>
    & { id: Registration['_id'] }
    & { class: Maybe<(
      { __typename?: 'Class' }
      & Pick<Class, 'learningArrangement'>
      & { id: Class['_id'] }
    )>, student: (
      { __typename?: 'Student' }
      & { id: Student['_id'] }
    ), enrollment: Maybe<(
      { __typename?: 'Enrollment' }
      & Pick<Enrollment, 'preferredLearningArrangement' | 'group'>
    )>, effectedWaitingLists: Maybe<Array<(
      { __typename?: 'Registration' }
      & Pick<Registration, 'startDate' | 'createDateTime'>
      & { id: Registration['_id'] }
      & { student: (
        { __typename?: 'Student' }
        & StudentCellFieldsFragment
      ) }
    )>> }
  )> }
);

export type GetStudentsTrialEnrollmentQueryVariables = {
  studentId: Scalars['ObjectId'],
  StudentEnrollmentFilterInput?: Maybe<StudentEnrollmentFilterInput>
};


export type GetStudentsTrialEnrollmentQuery = (
  { __typename?: 'Query' }
  & { student: Maybe<(
    { __typename?: 'Student' }
    & { enrollments: Maybe<Array<(
      { __typename?: 'Enrollment' }
      & Pick<Enrollment, 'startDate'>
      & { id: Enrollment['_id'] }
      & { class: (
        { __typename?: 'Class' }
        & Pick<Class, 'shorthand'>
      ) }
    )>> }
  )> }
);

export type AttendanceIndexFieldsFragment = (
  { __typename?: 'AttendanceIndex' }
  & Pick<AttendanceIndex, 'type' | 'status' | 'group' | 'lessonMode' | 'classId' | 'pinnedRemark' | 'latestLessonRemark' | 'flagRemark' | 'learningArrangement'>
  & { id: AttendanceIndex['_id'] }
  & { class: Maybe<(
    { __typename?: 'AttendanceClassIndex' }
    & Pick<AttendanceClassIndex, 'shorthand' | 'type' | 'learningArrangement' | 'academicYearId'>
    & { id: AttendanceClassIndex['_id'] }
  )>, academicLevels: Maybe<Array<(
    { __typename?: 'AttendanceAcademicLevelIndex' }
    & Pick<AttendanceAcademicLevelIndex, 'name'>
  )>>, linkArrangements: Maybe<Array<(
    { __typename?: 'AttendanceArrangementIndex' }
    & Pick<AttendanceArrangementIndex, 'type' | 'fromClassId' | 'toClassId' | 'fromClassShorthand' | 'toClassShorthand'>
  )>>, lesson: Maybe<(
    { __typename?: 'AttendanceLessonIndex' }
    & Pick<AttendanceLessonIndex, 'lessonNumber' | 'lessonStart' | 'lessonEnd'>
    & { id: AttendanceLessonIndex['_id'] }
  )>, teacher: Maybe<(
    { __typename?: 'AttendanceTeacherIndex' }
    & Pick<AttendanceTeacherIndex, 'fullName' | 'salutation' | 'initials' | 'avatarUrl' | 'gender'>
    & { id: AttendanceTeacherIndex['_id'] }
  )>, statusTag: Maybe<(
    { __typename?: 'AttendanceStatusTag' }
    & Pick<AttendanceStatusTag, 'tags' | 'description'>
  )>, previousAttendance: Maybe<(
    { __typename?: 'PreviousAttendanceIndex' }
    & Pick<PreviousAttendanceIndex, 'type' | 'status' | 'classShorthand'>
    & { id: PreviousAttendanceIndex['_id'] }
  )>, trialAttendances: Maybe<Array<(
    { __typename?: 'AttendanceTrialIndex' }
    & Pick<AttendanceTrialIndex, 'startDateTime'>
    & { id: AttendanceTrialIndex['_id'] }
  )>>, enrollment: Maybe<(
    { __typename?: 'AttendanceEnrollmentIndex' }
    & Pick<AttendanceEnrollmentIndex, 'type' | 'preferredLearningArrangement' | 'group' | 'followUpStatus' | 'followUpStatusLastModifiedDateTime' | 'followUpStatusLastModifiedByFullName'>
    & { id: AttendanceEnrollmentIndex['_id'] }
    & { suitability: Maybe<(
      { __typename?: 'EnrollmentSuitability' }
      & Pick<EnrollmentSuitability, 'status'>
    )> }
  )>, invoice: Maybe<(
    { __typename?: 'AttendanceInvoiceIndex' }
    & Pick<AttendanceInvoiceIndex, 'status' | 'dueDate'>
    & { id: AttendanceInvoiceIndex['_id'] }
  )>, student: Maybe<(
    { __typename?: 'AttendanceStudentIndex' }
    & Pick<AttendanceStudentIndex, 'fullName' | 'alias' | 'avatarUrl' | 'userId'>
    & { id: AttendanceStudentIndex['_id'] }
  )>, primaryParent: Maybe<(
    { __typename?: 'AttendanceParentIndex' }
    & Pick<AttendanceParentIndex, 'fullName' | 'salutation' | 'avatarUrl' | 'gender'>
    & { id: AttendanceParentIndex['_id'] }
    & { mobile: (
      { __typename?: 'Telephone' }
      & Pick<Telephone, 'number' | 'countryCode'>
    ) }
  )>, primaryParentWhatsappAccount: Maybe<(
    { __typename?: 'AttendanceWhatsappAccountIndex' }
    & { id: AttendanceWhatsappAccountIndex['_id'] }
    & { number: Maybe<(
      { __typename?: 'Telephone' }
      & Pick<Telephone, 'number' | 'countryCode'>
    )> }
  )>, secondaryParents: Maybe<Array<(
    { __typename?: 'AttendanceParentIndex' }
    & Pick<AttendanceParentIndex, 'fullName' | 'salutation' | 'avatarUrl' | 'gender'>
    & { id: AttendanceParentIndex['_id'] }
    & { mobile: (
      { __typename?: 'Telephone' }
      & Pick<Telephone, 'number' | 'countryCode'>
    ) }
  )>>, secondaryParentWhatsappAccounts: Maybe<Array<(
    { __typename?: 'AttendanceWhatsappAccountIndex' }
    & Pick<AttendanceWhatsappAccountIndex, 'parentId'>
    & { id: AttendanceWhatsappAccountIndex['_id'] }
    & { number: Maybe<(
      { __typename?: 'Telephone' }
      & Pick<Telephone, 'number' | 'countryCode'>
    )> }
  )>> }
);

export type GetAttendancesQueryVariables = {
  filterInput?: Maybe<StudentAttendanceFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  sortInfo?: Maybe<SortInfo>
};


export type GetAttendancesQuery = (
  { __typename?: 'Query' }
  & { attendances: Maybe<(
    { __typename?: 'AttendancePage' }
    & Pick<AttendancePage, 'total' | 'pageIndex' | 'pageSize'>
    & { items: Array<(
      { __typename?: 'Attendance' }
      & Pick<Attendance, 'status' | 'type' | 'group' | 'lessonMode' | 'classId'>
      & { id: Attendance['_id'] }
      & { student: Maybe<(
        { __typename?: 'Student' }
        & { primaryParent: Maybe<(
          { __typename?: 'Parent' }
          & { linkedWhatsappAccount: Maybe<(
            { __typename?: 'WhatsappAccount' }
            & { number: Maybe<(
              { __typename?: 'Telephone' }
              & Pick<Telephone, 'countryCode' | 'number'>
            )> }
          )>, relatedParents: Maybe<Array<(
            { __typename?: 'Parent' }
            & { linkedWhatsappAccount: Maybe<(
              { __typename?: 'WhatsappAccount' }
              & { number: Maybe<(
                { __typename?: 'Telephone' }
                & Pick<Telephone, 'countryCode' | 'number'>
              )> }
            )> }
            & ParentCellFieldsFragment
          )>> }
          & ParentCellFieldsFragment
        )> }
        & StudentCellFieldsFragment
      )>, linkedAttendance: Maybe<(
        { __typename?: 'Attendance' }
        & Pick<Attendance, 'startDateTime'>
        & { class: (
          { __typename?: 'Class' }
          & Pick<Class, 'name'>
        ) }
      )>, linkArrangement: Maybe<(
        { __typename?: 'Arrangement' }
        & Pick<Arrangement, 'type' | 'remarks'>
        & { fromClass: Maybe<(
          { __typename?: 'Class' }
          & Pick<Class, 'shorthand'>
          & { id: Class['_id'] }
        )>, toClass: Maybe<(
          { __typename?: 'Class' }
          & Pick<Class, 'shorthand'>
          & { id: Class['_id'] }
        )> }
      )>, previousLessonAttendance: Maybe<(
        { __typename?: 'Attendance' }
        & Pick<Attendance, 'type' | 'status'>
        & { class: (
          { __typename?: 'Class' }
          & Pick<Class, 'shorthand' | 'name'>
        ) }
      )>, class: (
        { __typename?: 'Class' }
        & Pick<Class, 'shorthand' | 'type' | 'academicYearId' | 'learningArrangement'>
        & { id: Class['_id'] }
        & { academicLevels: Array<(
          { __typename?: 'AcademicLevel' }
          & Pick<AcademicLevel, 'name'>
          & { id: AcademicLevel['_id'] }
        )> }
      ), lesson: (
        { __typename?: 'Lesson' }
        & Pick<Lesson, 'lessonStart' | 'lessonEnd' | 'lessonNumber'>
        & { id: Lesson['_id'] }
      ), enrollment: Maybe<(
        { __typename?: 'Enrollment' }
        & Pick<Enrollment, 'type' | 'preferredLearningArrangement' | 'group' | 'followUpStatus' | 'followUpStatusLastModifiedDateTime'>
        & { id: Enrollment['_id'] }
        & { attendances: Maybe<Array<(
          { __typename?: 'Attendance' }
          & Pick<Attendance, 'startDateTime'>
          & { id: Attendance['_id'] }
        )>>, suitability: Maybe<(
          { __typename?: 'EnrollmentSuitability' }
          & Pick<EnrollmentSuitability, 'status'>
        )>, followUpStatusLastModifiedBy: Maybe<(
          { __typename?: 'SystemUser' }
          & LastModifiedByFieldsFragment
        )> }
      )>, remarks: Maybe<Array<(
        { __typename?: 'Remark' }
        & RemarkFieldsFragment
      )>>, linkInvoice: Maybe<(
        { __typename?: 'Invoice' }
        & Pick<Invoice, 'status' | 'dueDate'>
        & { id: Invoice['_id'] }
      )>, statusTag: Maybe<(
        { __typename?: 'AttendanceStatusTag' }
        & Pick<AttendanceStatusTag, 'tags' | 'description'>
      )> }
    )> }
  )> }
);

export type GetAttendanceIndexesQueryVariables = {
  filterInput?: Maybe<StudentAttendanceFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  sortInfo?: Maybe<SortInfo>
};


export type GetAttendanceIndexesQuery = (
  { __typename?: 'Query' }
  & { attendancesWithIndex: Maybe<(
    { __typename?: 'AttendanceIndexPage' }
    & Pick<AttendanceIndexPage, 'total' | 'pageIndex' | 'pageSize'>
    & { items: Array<(
      { __typename?: 'AttendanceIndex' }
      & AttendanceIndexFieldsFragment
    )> }
  )> }
);

export type GetAttendanceIndexQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetAttendanceIndexQuery = (
  { __typename?: 'Query' }
  & { attendanceWithIndex: Maybe<(
    { __typename?: 'AttendanceIndex' }
    & AttendanceIndexFieldsFragment
  )> }
);

export type GetAttendanceReportFilterOptionsQueryVariables = {
  classFilterInput?: Maybe<ClassFilterInput>
};


export type GetAttendanceReportFilterOptionsQuery = (
  { __typename?: 'Query' }
  & { academicLevels: Maybe<Array<(
    { __typename?: 'AcademicLevel' }
    & Pick<AcademicLevel, 'name'>
    & { id: AcademicLevel['_id'] }
    & { courses: Maybe<Array<(
      { __typename?: 'Course' }
      & Pick<Course, 'nameShort'>
      & { id: Course['_id'] }
    )>>, classes: Maybe<Array<(
      { __typename?: 'Class' }
      & Pick<Class, 'shorthand'>
      & { id: Class['_id'] }
    )>> }
  )>>, academicYears: Maybe<Array<(
    { __typename?: 'AcademicYear' }
    & Pick<AcademicYear, 'year'>
    & { id: AcademicYear['_id'] }
    & { academicWeeks: Array<(
      { __typename?: 'AcademicWeekWithId' }
      & Pick<AcademicWeekWithId, 'startDate' | 'endDate' | 'weekNumber'>
    )> }
  )>> }
);

export type ExportAttendanceCsvQueryVariables = {
  filterInput?: Maybe<StudentAttendanceFilterInput>
};


export type ExportAttendanceCsvQuery = (
  { __typename?: 'Query' }
  & { exportAttendanceCsv: Maybe<(
    { __typename?: 'Csv' }
    & Pick<Csv, 'url'>
  )> }
);

export type ExportAttendanceReportCsvQueryVariables = {
  filterInput?: Maybe<StudentEnrollmentFilterInput>
};


export type ExportAttendanceReportCsvQuery = (
  { __typename?: 'Query' }
  & { exportAttendanceReportCsv: Maybe<(
    { __typename?: 'Csv' }
    & Pick<Csv, 'url'>
  )> }
);

export type AttendanceReportsQueryVariables = {
  filterInput?: Maybe<StudentEnrollmentFilterInput>,
  pageInfo?: Maybe<PageInfo>
};


export type AttendanceReportsQuery = (
  { __typename?: 'Query' }
  & { attendanceReports: Maybe<(
    { __typename?: 'EnrollmentIndexPage' }
    & Pick<EnrollmentIndexPage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'EnrollmentIndex' }
      & Pick<EnrollmentIndex, 'type' | 'status' | 'preferredLearningArrangement' | 'startDate'>
      & { id: EnrollmentIndex['_id'] }
      & { attendances: Maybe<Array<(
        { __typename?: 'Attendance' }
        & Pick<Attendance, 'type' | 'status' | 'startDateTime' | 'lessonId'>
        & { id: Attendance['_id'] }
        & { linkArrangement: Maybe<(
          { __typename?: 'Arrangement' }
          & { fromClass: Maybe<(
            { __typename?: 'Class' }
            & Pick<Class, 'shorthand'>
            & { id: Class['_id'] }
          )>, toClass: Maybe<(
            { __typename?: 'Class' }
            & Pick<Class, 'shorthand'>
            & { id: Class['_id'] }
          )> }
        )> }
      )>>, class: Maybe<(
        { __typename?: 'Class' }
        & Pick<Class, 'name' | 'classCode' | 'type' | 'learningArrangement' | 'isAdHoc' | 'firstLessonDate'>
        & { id: Class['_id'] }
        & { lessons: Maybe<Array<(
          { __typename?: 'Lesson' }
          & Pick<Lesson, 'lessonStart' | 'lessonEnd' | 'lessonNumber'>
          & { id: Lesson['_id'] }
        )>> }
      )>, suitability: Maybe<(
        { __typename?: 'EnrollmentSuitability' }
        & Pick<EnrollmentSuitability, 'status'>
      )>, student: Maybe<(
        { __typename?: 'EnrollmentStudentIndex' }
        & Pick<EnrollmentStudentIndex, 'fullName' | 'gender' | 'alias' | 'avatarUrl' | 'userId'>
        & { id: EnrollmentStudentIndex['_id'] }
      )>, remarks: Maybe<Array<(
        { __typename?: 'Remark' }
        & RemarkFieldsFragment
      )>> }
    )> }
  )> }
);

export type AcademicYearsOnAttendanceQueryVariables = {};


export type AcademicYearsOnAttendanceQuery = (
  { __typename?: 'Query' }
  & { academicYears: Maybe<Array<(
    { __typename?: 'AcademicYear' }
    & { id: AcademicYear['_id'] }
    & { groupAssignment: Maybe<(
      { __typename?: 'GroupAssignment' }
      & { items: Maybe<Array<(
        { __typename?: 'GroupAssignmentItem' }
        & Pick<GroupAssignmentItem, 'startDate' | 'endDate' | 'groupA' | 'groupB'>
      )>> }
    )> }
  )>> }
);

export type UpdateAttendancesMutationVariables = {
  input: UpdateAttendancesInput
};


export type UpdateAttendancesMutation = (
  { __typename?: 'Mutation' }
  & { updateAttendances: Maybe<Array<(
    { __typename?: 'Attendance' }
    & Pick<Attendance, 'status' | 'type'>
    & { id: Attendance['_id'] }
  )>> }
);

export type GetInvoiceReportQueryVariables = {
  filterInput?: Maybe<InvoiceReportFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  sortInfo?: Maybe<SortInfo>
};


export type GetInvoiceReportQuery = (
  { __typename?: 'Query' }
  & { invoiceReports: Maybe<(
    { __typename?: 'InvoiceReportPage' }
    & Pick<InvoiceReportPage, 'total' | 'pageIndex' | 'pageSize'>
    & { items: Array<(
      { __typename?: 'InvoiceReport' }
      & Pick<InvoiceReport, 'type' | 'referenceNumber' | 'description' | 'studentId' | 'studentFullName' | 'studentUserId' | 'lineItemId' | 'invoiceId' | 'lessonId' | 'classId' | 'lessonDate' | 'fullLessonDate' | 'invoiceDate' | 'invoiceTotal' | 'lineItemFee' | 'lineItemFeeWithoutGST' | 'feeWithoutGST' | 'remarks' | 'isVoid' | 'isOnHold' | 'parentUserId' | 'parentFullName' | 'courseName'>
      & { id: InvoiceReport['_id'] }
    )> }
  )> }
);

export type ExportInvoiceReportCsvQueryVariables = {
  filterInput?: Maybe<InvoiceReportFilterInput>
};


export type ExportInvoiceReportCsvQuery = (
  { __typename?: 'Query' }
  & { exportInvoiceReportCsv: Maybe<(
    { __typename?: 'Csv' }
    & Pick<Csv, 'url'>
  )> }
);

export type GetPaymentReportQueryVariables = {
  filterInput?: Maybe<BalanceHistoryFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  sortInfo?: Maybe<SortInfo>
};


export type GetPaymentReportQuery = (
  { __typename?: 'Query' }
  & { balanceHistories: Maybe<(
    { __typename?: 'BalanceHistoryPage' }
    & Pick<BalanceHistoryPage, 'total' | 'pageIndex' | 'pageSize'>
    & { items: Array<(
      { __typename?: 'BalanceHistory' }
      & Pick<BalanceHistory, 'invoiceNumbers' | 'paymentNumbers' | 'receiptNumbers' | 'originalInvoiceAmount' | 'amountPayable' | 'paymentMethod' | 'paymentDate' | 'amountPaid' | 'outstandingPayable' | 'actionType' | 'balance' | 'amount' | 'type' | 'isVoidedInvoice'>
      & { id: BalanceHistory['_id'] }
      & { parent: (
        { __typename?: 'Parent' }
        & Pick<Parent, 'fullName' | 'userId'>
      ), invoices: Maybe<Array<(
        { __typename?: 'Invoice' }
        & Pick<Invoice, 'referenceNumber' | 'status'>
        & { id: Invoice['_id'] }
      )>>, payments: Maybe<Array<(
        { __typename?: 'Payment' }
        & Pick<Payment, 'isVoid' | 'referenceNumber'>
        & { id: Payment['_id'] }
        & { receipt: Maybe<(
          { __typename?: 'Receipt' }
          & Pick<Receipt, 'referenceNumber' | 'status'>
          & { id: Receipt['_id'] }
        )> }
      )>> }
    )> }
  )> }
);

export type ExportPaymentReportCsvQueryVariables = {
  filterInput?: Maybe<BalanceHistoryFilterInput>
};


export type ExportPaymentReportCsvQuery = (
  { __typename?: 'Query' }
  & { exportPaymentReportCsv: Maybe<(
    { __typename?: 'Csv' }
    & Pick<Csv, 'url'>
  )> }
);

export type GetRevenueCourseWithIndexDataQueryVariables = {
  filterInput?: Maybe<CourseFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  dateRange: DateTimeRangeInput
};


export type GetRevenueCourseWithIndexDataQuery = (
  { __typename?: 'Query' }
  & { revenueCoursesWithIndex: Maybe<(
    { __typename?: 'CourseIndexPage' }
    & Pick<CourseIndexPage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'CourseIndex' }
      & Pick<CourseIndex, 'nameShort' | 'status' | 'type' | 'totalRevenue'>
      & { id: CourseIndex['_id'] }
    )> }
  )> }
);

export type GetRevenueCourseDataQueryVariables = {
  courseIds?: Maybe<Array<Scalars['ObjectId']>>
};


export type GetRevenueCourseDataQuery = (
  { __typename?: 'Query' }
  & { courses: Maybe<(
    { __typename?: 'CoursePage' }
    & { items: Array<(
      { __typename?: 'Course' }
      & Pick<Course, 'nameShort'>
      & { id: Course['_id'] }
    )> }
  )> }
);

export type GetInvoiceRevenueReportsQueryVariables = {
  filter: RevenueReportQuery,
  pageInfo?: Maybe<PageInfo>
};


export type GetInvoiceRevenueReportsQuery = (
  { __typename?: 'Query' }
  & { invoiceRevenueReports: Maybe<(
    { __typename?: 'InvoiceLineItemPage' }
    & Pick<InvoiceLineItemPage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'InvoiceLineItem' }
      & Pick<InvoiceLineItem, 'unitAmount' | 'description' | 'isPaid' | 'taxRate' | 'paid'>
      & { id: InvoiceLineItem['_id'] }
      & { invoice: Maybe<(
        { __typename?: 'Invoice' }
        & Pick<Invoice, 'referenceNumber' | 'createDateTime'>
        & { id: Invoice['_id'] }
        & { course: Maybe<(
          { __typename?: 'Course' }
          & Pick<Course, 'type'>
        )>, billedTo: Maybe<(
          { __typename?: 'Parent' }
          & Pick<Parent, 'fullName' | 'userId'>
          & { id: Parent['_id'] }
        )>, billedFor: Maybe<(
          { __typename?: 'Student' }
          & Pick<Student, 'fullName' | 'userId'>
          & { id: Student['_id'] }
        )> }
      )>, lessons: Maybe<Array<(
        { __typename?: 'InvoiceLineItemLesson' }
        & Pick<InvoiceLineItemLesson, 'lessonId' | 'isPaid' | 'paid'>
        & { lesson: Maybe<(
          { __typename?: 'Lesson' }
          & Pick<Lesson, 'lessonStart'>
          & { id: Lesson['_id'] }
        )> }
      )>> }
    )> }
  )> }
);

export type ExportInvoiceRevenueReportCsvQueryVariables = {
  filterInput: CourseFilterInput
};


export type ExportInvoiceRevenueReportCsvQuery = (
  { __typename?: 'Query' }
  & { exportInvoiceRevenueReportCsv: Maybe<(
    { __typename?: 'Csv' }
    & Pick<Csv, 'url'>
  )> }
);

export type LessonScheduleFieldsFragment = (
  { __typename?: 'LessonIndex' }
  & Pick<LessonIndex, 'lessonDay' | 'lessonNumber' | 'lessonStart' | 'lessonEnd' | 'activeEnrolled' | 'present' | 'attending'>
  & { id: LessonIndex['_id'] }
  & { class: Maybe<(
    { __typename?: 'LessonClassIndex' }
    & Pick<LessonClassIndex, '_id' | 'name' | 'type' | 'shorthand' | 'learningArrangement'>
  )>, course: Maybe<(
    { __typename?: 'LessonCourseIndex' }
    & Pick<LessonCourseIndex, 'name' | 'nameShort' | 'avatarUrl' | 'colorCode'>
    & { id: LessonCourseIndex['_id'] }
  )>, teacher: Maybe<(
    { __typename?: 'LessonTeacherIndex' }
    & Pick<LessonTeacherIndex, 'fullName' | 'avatarUrl' | 'gender' | 'salutation'>
    & { id: LessonTeacherIndex['_id'] }
  )>, classTeacher: Maybe<(
    { __typename?: 'LessonTeacherIndex' }
    & Pick<LessonTeacherIndex, 'fullName' | 'avatarUrl' | 'gender' | 'salutation'>
    & { id: LessonTeacherIndex['_id'] }
  )>, venue: Maybe<(
    { __typename?: 'LessonVenueIndex' }
    & Pick<LessonVenueIndex, 'shorthand' | 'unitNo' | 'capacity'>
    & { id: LessonVenueIndex['_id'] }
  )>, branch: Maybe<(
    { __typename?: 'LessonBranchIndex' }
    & Pick<LessonBranchIndex, 'alias' | 'name'>
    & { id: LessonBranchIndex['_id'] }
  )> }
);

export type GetLessonsInScheduleQueryVariables = {
  filterInput?: Maybe<LessonFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetLessonsInScheduleQuery = (
  { __typename?: 'Query' }
  & { lessonsWithIndex: Maybe<(
    { __typename?: 'LessonIndexPage' }
    & Pick<LessonIndexPage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'LessonIndex' }
      & LessonScheduleFieldsFragment
    )> }
  )> }
);

export type GetVenuesInScheduleQueryVariables = {};


export type GetVenuesInScheduleQuery = (
  { __typename?: 'Query' }
  & { venues: Maybe<Array<(
    { __typename?: 'Venue' }
    & BasicVenueFieldsFragment
  )>> }
);

export type GetScheduleFilterDataQueryVariables = {
  filterInput: SystemUserQuery
};


export type GetScheduleFilterDataQuery = (
  { __typename?: 'Query' }
  & { branches: Maybe<Array<(
    { __typename?: 'Branch' }
    & Pick<Branch, 'name' | 'alias'>
    & { id: Branch['_id'] }
    & { venues: Maybe<Array<(
      { __typename?: 'Venue' }
      & BasicVenueFieldsFragment
    )>> }
  )>> }
);

export type GetArrangementActionModalLessonQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetArrangementActionModalLessonQuery = (
  { __typename?: 'Query' }
  & { lesson: Maybe<(
    { __typename?: 'Lesson' }
    & { id: Lesson['_id'] }
    & { class: Maybe<(
      { __typename?: 'Class' }
      & { id: Class['_id'] }
      & { course: (
        { __typename?: 'Course' }
        & { id: Course['_id'] }
      ) }
    )> }
  )> }
);

export type UpdateAcademicYearFormMutationVariables = {
  input: UpdateAcademicYearInput
};


export type UpdateAcademicYearFormMutation = (
  { __typename?: 'Mutation' }
  & { updateAcademicYear: (
    { __typename?: 'AcademicYear' }
    & BasicAcademicYearFieldsFragment
  ) }
);

export type DeleteTermMutationVariables = {
  id: Scalars['ObjectId']
};


export type DeleteTermMutation = (
  { __typename?: 'Mutation' }
  & { deleteTerm: (
    { __typename?: 'Term' }
    & { id: Term['_id'] }
    & { academicYear: (
      { __typename?: 'AcademicYear' }
      & BasicAcademicYearFieldsFragment
    ) }
  ) }
);

export type GetAcademicYearFormDataQueryVariables = {};


export type GetAcademicYearFormDataQuery = (
  { __typename?: 'Query' }
  & { academicLevels: Maybe<Array<(
    { __typename?: 'AcademicLevel' }
    & BasicAcademicLevelFieldsFragment
  )>> }
);

export type CreateGroupAssignmentMutationVariables = {
  input: CreateGroupAssignmentInput
};


export type CreateGroupAssignmentMutation = (
  { __typename?: 'Mutation' }
  & { createGroupAssignment: Maybe<(
    { __typename?: 'GroupAssignment' }
    & Pick<GroupAssignment, '_id'>
  )> }
);

export type UpdateGroupAssignmentMutationVariables = {
  input: UpdateGroupAssignmentInput
};


export type UpdateGroupAssignmentMutation = (
  { __typename?: 'Mutation' }
  & { updateGroupAssignment: Maybe<(
    { __typename?: 'GroupAssignment' }
    & Pick<GroupAssignment, '_id'>
  )> }
);

export type DeleteGroupAssignmentMutationVariables = {
  id: Scalars['ObjectId']
};


export type DeleteGroupAssignmentMutation = (
  { __typename?: 'Mutation' }
  & { deleteGroupAssignment: Maybe<(
    { __typename?: 'GroupAssignment' }
    & Pick<GroupAssignment, '_id'>
  )> }
);

export type GetAcademicYearPageDataQueryVariables = {
  year: Scalars['Int']
};


export type GetAcademicYearPageDataQuery = (
  { __typename?: 'Query' }
  & { academicYear: Maybe<(
    { __typename?: 'AcademicYear' }
    & BasicAcademicYearFieldsFragment
  )> }
);

export type GetHolidaysQueryVariables = {
  year: Scalars['Int']
};


export type GetHolidaysQuery = (
  { __typename?: 'Query' }
  & { holidays: Maybe<Array<(
    { __typename?: 'Holiday' }
    & BasicHolidayFieldsFragment
  )>> }
);

export type DeleteHolidayMutationVariables = {
  id: Scalars['ObjectId']
};


export type DeleteHolidayMutation = (
  { __typename?: 'Mutation' }
  & { deleteHoliday: Maybe<(
    { __typename?: 'Holiday' }
    & BasicHolidayFieldsFragment
  )> }
);

export type UpdateHolidayMutationVariables = {
  input: UpdateHolidayInput
};


export type UpdateHolidayMutation = (
  { __typename?: 'Mutation' }
  & { updateHoliday: Maybe<(
    { __typename?: 'Holiday' }
    & BasicHolidayFieldsFragment
  )> }
);

export type AddHolidayMutationVariables = {
  input: CreateHolidayInput
};


export type AddHolidayMutation = (
  { __typename?: 'Mutation' }
  & { createHoliday: Maybe<(
    { __typename?: 'Holiday' }
    & BasicHolidayFieldsFragment
  )> }
);

export type GetHolidayLessonsQueryVariables = {
  filterInput?: Maybe<LessonFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetHolidayLessonsQuery = (
  { __typename?: 'Query' }
  & { lessons: Maybe<(
    { __typename?: 'LessonPage' }
    & { items: Array<(
      { __typename?: 'Lesson' }
      & Pick<Lesson, 'lessonNumber' | 'lessonDay' | 'lessonStart' | 'lessonEnd'>
      & { id: Lesson['_id'] }
      & { class: Maybe<(
        { __typename?: 'Class' }
        & Pick<Class, 'name'>
        & { course: (
          { __typename?: 'Course' }
          & Pick<Course, 'colorCode'>
        ) }
      )> }
    )> }
  )> }
);

export type CampaignFieldsFragment = (
  { __typename?: 'Campaign' }
  & Pick<Campaign, 'emailSubject' | 'message' | 'name' | 'recipientTypes' | 'sendMethod' | 'sentAt' | 'status' | 'usageType' | 'templateId' | 'scheduleSendAt' | 'realSendAt' | 'isRealSended' | 'recallAt' | 'isIncludeAttachment'>
  & { id: Campaign['_id'] }
  & { recipients: Maybe<Array<(
    { __typename?: 'CampaignRecipient' }
    & Pick<CampaignRecipient, 'studentIds' | 'message' | 'parentId' | 'receiptId'>
  )>>, createdBy: Maybe<(
    { __typename?: 'SystemUser' }
    & CreateByFieldsFragment
  )> }
);

export type CreateCampaignMutationVariables = {
  input: CreateCampaignInput
};


export type CreateCampaignMutation = (
  { __typename?: 'Mutation' }
  & { createCampaign: Maybe<(
    { __typename?: 'Campaign' }
    & CampaignFieldsFragment
  )> }
);

export type UpdateCampaignMutationVariables = {
  input: UpdateCampaignInput
};


export type UpdateCampaignMutation = (
  { __typename?: 'Mutation' }
  & { updateCampaign: Maybe<(
    { __typename?: 'Campaign' }
    & CampaignFieldsFragment
  )> }
);

export type DeleteCampaignMutationVariables = {
  id: Scalars['ObjectId']
};


export type DeleteCampaignMutation = (
  { __typename?: 'Mutation' }
  & { deleteCampaign: Maybe<(
    { __typename?: 'Campaign' }
    & CampaignFieldsFragment
  )> }
);

export type RecallCampaignMutationVariables = {
  id: Scalars['ObjectId']
};


export type RecallCampaignMutation = (
  { __typename?: 'Mutation' }
  & { recallCampaign: Maybe<(
    { __typename?: 'Campaign' }
    & CampaignFieldsFragment
  )> }
);

export type CampaignsQueryVariables = {
  filterInput?: Maybe<CampaignFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  sortInfo?: Maybe<SortInfo>
};


export type CampaignsQuery = (
  { __typename?: 'Query' }
  & { campaigns: Maybe<(
    { __typename?: 'CampaignPage' }
    & Pick<CampaignPage, 'total' | 'pageSize' | 'pageIndex'>
    & { items: Array<(
      { __typename?: 'Campaign' }
      & CampaignFieldsFragment
    )> }
  )> }
);

export type GetGeneralStudentsDataQueryVariables = {
  search?: Maybe<Scalars['String']>,
  filterInput?: Maybe<StudentFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetGeneralStudentsDataQuery = (
  { __typename?: 'Query' }
  & { students: Maybe<(
    { __typename?: 'StudentPage' }
    & Pick<StudentPage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'Student' }
      & Pick<Student, 'fullName' | 'userId'>
      & { id: Student['_id'] }
      & { primaryParent: Maybe<(
        { __typename?: 'Parent' }
        & Pick<Parent, 'fullName' | 'salutation' | 'email'>
        & { id: Parent['_id'] }
        & { mobile: (
          { __typename?: 'Telephone' }
          & Pick<Telephone, 'countryCode' | 'number'>
        ), relatedParents: Maybe<Array<(
          { __typename?: 'Parent' }
          & Pick<Parent, 'fullName' | 'salutation' | 'email'>
          & { id: Parent['_id'] }
          & { mobile: (
            { __typename?: 'Telephone' }
            & Pick<Telephone, 'countryCode' | 'number'>
          ) }
        )>> }
      )> }
    )> }
  )> }
);

export type GetCampaignQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetCampaignQuery = (
  { __typename?: 'Query' }
  & { campaign: Maybe<(
    { __typename?: 'Campaign' }
    & { template: Maybe<(
      { __typename?: 'Template' }
      & Pick<Template, 'title' | 'usageType'>
      & { id: Template['_id'] }
    )> }
    & CampaignFieldsFragment
  )> }
);

export type ExportCampaignDocxQueryVariables = {
  id: Scalars['ObjectId']
};


export type ExportCampaignDocxQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'exportCampaignDocx'>
);

export type CampaignExportHistoriesQueryVariables = {
  filterInput?: Maybe<CampaignFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type CampaignExportHistoriesQuery = (
  { __typename?: 'Query' }
  & { campaignExportHistories: Maybe<(
    { __typename?: 'CampaignExportHistoryPage' }
    & Pick<CampaignExportHistoryPage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'CampaignExportHistory' }
      & Pick<CampaignExportHistory, 'url'>
      & { id: CampaignExportHistory['_id'] }
      & { campaign: (
        { __typename?: 'Campaign' }
        & Pick<Campaign, 'sendMethod' | 'name'>
        & { id: Campaign['_id'] }
        & { createdBy: Maybe<(
          { __typename?: 'SystemUser' }
          & CreateByFieldsFragment
        )> }
      ) }
    )> }
  )> }
);

export type GetReceiptStudentsDataQueryVariables = {
  search?: Maybe<Scalars['String']>,
  filterInput?: Maybe<ReceiptFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetReceiptStudentsDataQuery = (
  { __typename?: 'Query' }
  & { receipts: Maybe<(
    { __typename?: 'ReceiptPage' }
    & Pick<ReceiptPage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'Receipt' }
      & Pick<Receipt, 'referenceNumber'>
      & { id: Receipt['_id'] }
      & { paymentRecord: Maybe<(
        { __typename?: 'Payment' }
        & { details: Maybe<Array<(
          { __typename?: 'PaymentDetail' }
          & { invoice: Maybe<(
            { __typename?: 'Invoice' }
            & { id: Invoice['_id'] }
            & { billedFor: Maybe<(
              { __typename?: 'Student' }
              & Pick<Student, 'fullName' | 'userId'>
              & { id: Student['_id'] }
              & { primaryParent: Maybe<(
                { __typename?: 'Parent' }
                & Pick<Parent, 'fullName' | 'salutation' | 'email'>
                & { id: Parent['_id'] }
                & { mobile: (
                  { __typename?: 'Telephone' }
                  & Pick<Telephone, 'countryCode' | 'number'>
                ), relatedParents: Maybe<Array<(
                  { __typename?: 'Parent' }
                  & Pick<Parent, 'fullName' | 'salutation' | 'email'>
                  & { id: Parent['_id'] }
                  & { mobile: (
                    { __typename?: 'Telephone' }
                    & Pick<Telephone, 'countryCode' | 'number'>
                  ) }
                )>> }
              )> }
            )> }
          )> }
        )>> }
      )> }
    )> }
  )> }
);

export type PostBranchDataSubscriptionVariables = {};


export type PostBranchDataSubscription = (
  { __typename?: 'Subscription' }
  & { postBranchData: (
    { __typename?: 'Branch' }
    & { venues: Maybe<Array<(
      { __typename?: 'Venue' }
      & Pick<Venue, 'isArchived'>
      & { id: Venue['_id'] }
    )>> }
    & BasicBranchFieldsFragment
  ) }
);

export type PostDepartmentDataSubscriptionVariables = {};


export type PostDepartmentDataSubscription = (
  { __typename?: 'Subscription' }
  & { postDepartmentData: (
    { __typename?: 'Department' }
    & DepartmentFieldsFragment
  ) }
);

export type GetVenueQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetVenueQuery = (
  { __typename?: 'Query' }
  & { venue: Maybe<(
    { __typename?: 'Venue' }
    & BasicVenueFieldsFragment
  )> }
);

export type PostVenueDataSubscriptionVariables = {};


export type PostVenueDataSubscription = (
  { __typename?: 'Subscription' }
  & { postVenueData: (
    { __typename?: 'Venue' }
    & { createdBy: Maybe<(
      { __typename?: 'SystemUser' }
      & CreateByFieldsFragment
    )>, branch: Maybe<(
      { __typename?: 'Branch' }
      & BasicBranchFieldsFragment
    )> }
    & BasicVenueFieldsFragment
  ) }
);

export type FeatureFieldsFragment = (
  { __typename?: 'FeatureAccess' }
  & Pick<FeatureAccess, 'description' | 'enabled' | 'keywords' | 'name'>
  & { id: FeatureAccess['_id'] }
);

export type GetFeaturesQueryVariables = {};


export type GetFeaturesQuery = (
  { __typename?: 'Query' }
  & { features: Maybe<Array<(
    { __typename?: 'FeatureAccess' }
    & FeatureFieldsFragment
  )>> }
);

export type GetEnabledFeaturesQueryVariables = {};


export type GetEnabledFeaturesQuery = (
  { __typename?: 'Query' }
  & { enabledFeatures: Maybe<Array<(
    { __typename?: 'FeatureAccess' }
    & FeatureFieldsFragment
  )>> }
);

export type ToggleFeatureMutationVariables = {
  input: UpdateFeatureAccessInput
};


export type ToggleFeatureMutation = (
  { __typename?: 'Mutation' }
  & { toggleFeature: Maybe<(
    { __typename?: 'FeatureAccess' }
    & FeatureFieldsFragment
  )> }
);

export type ToggleFeaturesMutationVariables = {
  input: UpdateFeatureAccessesInput
};


export type ToggleFeaturesMutation = (
  { __typename?: 'Mutation' }
  & { toggleFeatures: Maybe<Array<(
    { __typename?: 'FeatureAccess' }
    & FeatureFieldsFragment
  )>> }
);

export type PostFeatureDataSubscriptionVariables = {};


export type PostFeatureDataSubscription = (
  { __typename?: 'Subscription' }
  & { postFeatureData: Array<(
    { __typename?: 'FeatureAccess' }
    & FeatureFieldsFragment
  )> }
);

export type BulkUpdateDigitalClassVenueMutationVariables = {
  input: Array<UpdateClassCommand>
};


export type BulkUpdateDigitalClassVenueMutation = (
  { __typename?: 'Mutation' }
  & { bulkUpdateDigitalClassVenue: Maybe<Array<(
    { __typename?: 'Class' }
    & { id: Class['_id'] }
  )>> }
);

export type GetAssignVenueClassesQueryVariables = {
  search?: Maybe<Scalars['String']>,
  filterInput?: Maybe<ClassFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetAssignVenueClassesQuery = (
  { __typename?: 'Query' }
  & { classes: Maybe<(
    { __typename?: 'ClassPage' }
    & Pick<ClassPage, 'total' | 'pageSize' | 'pageIndex'>
    & { items: Array<(
      { __typename?: 'Class' }
      & Pick<Class, 'name' | 'defaultLessonDays' | 'defaultLessonStart' | 'defaultLessonEnd'>
      & { id: Class['_id'] }
      & { lessons: Maybe<Array<(
        { __typename?: 'Lesson' }
        & Pick<Lesson, 'lessonStart' | 'lessonEnd'>
      )>>, course: (
        { __typename?: 'Course' }
        & Pick<Course, 'colorCode'>
      ), teacher: Maybe<(
        { __typename?: 'SystemUser' }
        & { id: SystemUser['_id'] }
      )>, venue: Maybe<(
        { __typename?: 'Venue' }
        & Pick<Venue, 'shorthand' | 'unitNo'>
        & { id: Venue['_id'] }
        & { branch: Maybe<(
          { __typename?: 'Branch' }
          & Pick<Branch, 'name' | 'alias'>
        )> }
      )>, capacity: Maybe<(
        { __typename?: 'ClassCapacityDTO' }
        & Pick<ClassCapacityDto, 'used'>
      )> }
    )> }
  )>, venues: Maybe<Array<(
    { __typename?: 'Venue' }
    & BasicVenueFieldsFragment
  )>> }
);

export type IntegrationFieldsFragment = (
  { __typename?: 'Integration' }
  & Pick<Integration, 'type' | 'clientId' | 'accountId' | 'isConfigured'>
  & { id: Integration['_id'] }
);

export type GetIntegrationsQueryVariables = {};


export type GetIntegrationsQuery = (
  { __typename?: 'Query' }
  & { integrations: Maybe<Array<(
    { __typename?: 'Integration' }
    & IntegrationFieldsFragment
  )>> }
);

export type UpdateIntegrationClientInfoMutationVariables = {
  input: UpdateIntegrationCommand
};


export type UpdateIntegrationClientInfoMutation = (
  { __typename?: 'Mutation' }
  & { updateIntegrationClientInfo: Maybe<(
    { __typename?: 'Integration' }
    & IntegrationFieldsFragment
  )> }
);

export type XeroTokenInfoQueryVariables = {};


export type XeroTokenInfoQuery = (
  { __typename?: 'Query' }
  & { xeroTokenInfo: Maybe<(
    { __typename?: 'XeroIntegration' }
    & { id: XeroIntegration['_id'] }
  )> }
);

export type XeroConsentUrlQueryVariables = {};


export type XeroConsentUrlQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'xeroConsentUrl'>
);

export type XeroAccountsQueryVariables = {};


export type XeroAccountsQuery = (
  { __typename?: 'Query' }
  & { xeroAccounts: Maybe<Array<(
    { __typename?: 'XeroAccount' }
    & Pick<XeroAccount, 'code' | 'name' | 'type'>
  )>> }
);

export type RefreshXeroTokenMutationVariables = {};


export type RefreshXeroTokenMutation = (
  { __typename?: 'Mutation' }
  & { refreshXeroToken: Maybe<Array<(
    { __typename?: 'XeroIntegration' }
    & { id: XeroIntegration['_id'] }
  )>> }
);

export type AddXeroAccountMutationVariables = {
  input: AddXeroAccountInput
};


export type AddXeroAccountMutation = (
  { __typename?: 'Mutation' }
  & { addXeroAccount: Maybe<(
    { __typename?: 'OtherMappingAccount' }
    & Pick<OtherMappingAccount, 'code' | 'name' | 'type'>
  )> }
);

export type ConnectXeroAccountForCourseMutationVariables = {
  input: ConnectXeroAccountForCourseInput
};


export type ConnectXeroAccountForCourseMutation = (
  { __typename?: 'Mutation' }
  & { connectXeroAccountForCourse: Maybe<(
    { __typename?: 'Course' }
    & { id: Course['_id'] }
  )> }
);

export type XeroApiCallbackMutationVariables = {
  callbackUrl: Scalars['String']
};


export type XeroApiCallbackMutation = (
  { __typename?: 'Mutation' }
  & { xeroApiCallback: Maybe<(
    { __typename?: 'XeroIntegration' }
    & Pick<XeroIntegration, '_id' | 'name'>
  )> }
);

export type DisconnectXeroAccountMutationVariables = {};


export type DisconnectXeroAccountMutation = (
  { __typename?: 'Mutation' }
  & { disconnectXeroAccount: Maybe<(
    { __typename?: 'XeroIntegration' }
    & Pick<XeroIntegration, '_id' | 'name'>
  )> }
);

export type RetryXeroPaymentMutationVariables = {
  paymentId: Scalars['ObjectId']
};


export type RetryXeroPaymentMutation = (
  { __typename?: 'Mutation' }
  & { retryXeroPayment: Maybe<(
    { __typename?: 'Payment' }
    & Pick<Payment, '_id'>
  )> }
);

export type ConnectXeroAccountForOtherMappingMutationVariables = {
  input: ConnectXeroAccountInput
};


export type ConnectXeroAccountForOtherMappingMutation = (
  { __typename?: 'Mutation' }
  & { connectXeroAccountForOtherMapping: Maybe<(
    { __typename?: 'XeroIntegration' }
    & Pick<XeroIntegration, '_id'>
  )> }
);

export type RetryUploadInvoiceMutationVariables = {
  invoiceId: Scalars['ObjectId'],
  date: Scalars['DateTime']
};


export type RetryUploadInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { retryUploadInvoice: Maybe<(
    { __typename?: 'Invoice' }
    & Pick<Invoice, '_id'>
  )> }
);

export type GetListCourseMappingDataQueryVariables = {
  search?: Maybe<Scalars['String']>,
  filterInput?: Maybe<CourseFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetListCourseMappingDataQuery = (
  { __typename?: 'Query' }
  & { courses: Maybe<(
    { __typename?: 'CoursePage' }
    & Pick<CoursePage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'Course' }
      & Pick<Course, 'name' | 'nameShort' | 'status' | 'type' | 'xeroAccountsSyncStatus' | 'onGoingClassNumber'>
      & { id: Course['_id'] }
      & { academicLevels: Array<(
        { __typename?: 'AcademicLevel' }
        & Pick<AcademicLevel, 'name'>
      )> }
    )> }
  )> }
);

export type NotificationLogFragmentFragment = (
  { __typename?: 'Reminder' }
  & Pick<Reminder, 'type' | 'sender' | 'subject' | 'message' | 'destination' | 'status' | 'attachmentStatus' | 'sendDate'>
  & { id: Reminder['_id'] }
  & { template: Maybe<(
    { __typename?: 'Template' }
    & Pick<Template, 'title' | 'type' | 'usageType'>
  )>, receiver: Maybe<(
    { __typename?: 'Parent' }
    & Pick<Parent, 'email' | 'fullName' | 'avatarUrl' | 'gender' | 'salutation' | 'alias'>
    & { id: Parent['_id'] }
    & { mobile: (
      { __typename?: 'Telephone' }
      & Pick<Telephone, 'countryCode' | 'number'>
    ) }
  )>, student: Maybe<(
    { __typename?: 'Student' }
    & Pick<Student, 'fullName' | 'avatarUrl' | 'gender' | 'alias' | 'userId'>
    & { id: Student['_id'] }
  )>, class: Maybe<(
    { __typename?: 'Class' }
    & Pick<Class, 'name' | 'learningArrangement'>
    & { id: Class['_id'] }
  )>, attendance: Maybe<(
    { __typename?: 'Attendance' }
    & Pick<Attendance, 'type'>
    & { enrollment: Maybe<(
      { __typename?: 'Enrollment' }
      & Pick<Enrollment, 'preferredLearningArrangement'>
    )> }
  )>, registration: Maybe<(
    { __typename?: 'Registration' }
    & Pick<Registration, 'type'>
  )>, arrangement: Maybe<(
    { __typename?: 'Arrangement' }
    & Pick<Arrangement, 'type'>
  )> }
);

export type GetNotificationLogsQueryVariables = {
  search?: Maybe<Scalars['String']>,
  filterInput?: Maybe<ReminderFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetNotificationLogsQuery = (
  { __typename?: 'Query' }
  & { reminders: Maybe<(
    { __typename?: 'ReminderPage' }
    & Pick<ReminderPage, 'total' | 'pageSize' | 'pageIndex'>
    & { items: Array<(
      { __typename?: 'Reminder' }
      & NotificationLogFragmentFragment
    )> }
  )> }
);

export type ResendReminderOnNotificationLogMutationVariables = {
  id: Scalars['ObjectId']
};


export type ResendReminderOnNotificationLogMutation = (
  { __typename?: 'Mutation' }
  & { resendReminder: Maybe<(
    { __typename?: 'Reminder' }
    & NotificationLogFragmentFragment
  )> }
);

export type WhatsappAccountInfoFragment = (
  { __typename?: 'WhatsappAccount' }
  & Pick<WhatsappAccount, 'name' | 'status' | 'statusDate' | 'createDateTime' | 'platform' | 'lastSentDateTime' | 'processingStatus' | 'statusDetail' | 'isArchived' | 'currentUserId' | 'hasFailedMessages'>
  & { id: WhatsappAccount['_id'] }
  & { number: Maybe<(
    { __typename?: 'Telephone' }
    & Pick<Telephone, 'countryCode' | 'number'>
  )> }
);

export type WhatsappMessageInfoFragment = (
  { __typename?: 'WhatsappMessage' }
  & Pick<WhatsappMessage, 'message' | 'recipient' | 'status' | 'queueOrder' | 'failedCount' | 'statusDate' | 'platform' | 'createDateTime'>
  & { id: WhatsappMessage['_id'] }
  & { sender: (
    { __typename?: 'WhatsappAccount' }
    & { id: WhatsappAccount['_id'] }
    & { number: Maybe<(
      { __typename?: 'Telephone' }
      & Pick<Telephone, 'countryCode' | 'number'>
    )> }
  ), parent: Maybe<(
    { __typename?: 'Parent' }
    & Pick<Parent, 'fullName' | 'avatarUrl' | 'salutation' | 'alias'>
  )>, template: Maybe<(
    { __typename?: 'Template' }
    & Pick<Template, 'title'>
  )>, registration: Maybe<(
    { __typename?: 'Registration' }
    & Pick<Registration, 'type'>
    & { student: (
      { __typename?: 'Student' }
      & Pick<Student, 'fullName' | 'gender' | 'alias' | 'avatarUrl'>
      & { primarySchool: Maybe<(
        { __typename?: 'School' }
        & Pick<School, 'name'>
      )> }
    ), class: Maybe<(
      { __typename?: 'Class' }
      & Pick<Class, 'name' | 'defaultLessonStart' | 'defaultLessonEnd'>
      & { teacher: Maybe<(
        { __typename?: 'SystemUser' }
        & Pick<SystemUser, 'fullName' | 'avatarUrl' | 'salutation'>
        & { id: SystemUser['_id'] }
      )>, course: (
        { __typename?: 'Course' }
        & Pick<Course, 'name' | 'type' | 'avatarUrl' | 'colorCode'>
      ) }
    )> }
  )>, arrangement: Maybe<(
    { __typename?: 'Arrangement' }
    & Pick<Arrangement, 'type'>
    & { student: (
      { __typename?: 'Student' }
      & Pick<Student, 'fullName' | 'gender' | 'alias' | 'avatarUrl'>
      & { primarySchool: Maybe<(
        { __typename?: 'School' }
        & Pick<School, 'name'>
      )> }
    ), fromClass: Maybe<(
      { __typename?: 'Class' }
      & Pick<Class, 'name' | 'defaultLessonStart' | 'defaultLessonEnd'>
      & { teacher: Maybe<(
        { __typename?: 'SystemUser' }
        & Pick<SystemUser, 'fullName' | 'avatarUrl' | 'salutation'>
        & { id: SystemUser['_id'] }
      )>, course: (
        { __typename?: 'Course' }
        & Pick<Course, 'name' | 'type' | 'avatarUrl' | 'colorCode'>
      ) }
    )> }
  )> }
);

export type GetWhatsappAccountsQueryVariables = {
  search?: Maybe<Scalars['String']>,
  filterInput?: Maybe<WhatsappAccountFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetWhatsappAccountsQuery = (
  { __typename?: 'Query' }
  & { whatsappAccounts: Maybe<(
    { __typename?: 'WhatsappAccountPage' }
    & Pick<WhatsappAccountPage, 'total' | 'pageSize' | 'pageIndex'>
    & { items: Array<(
      { __typename?: 'WhatsappAccount' }
      & WhatsappAccountInfoFragment
    )> }
  )> }
);

export type GetWhatsappAccountQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetWhatsappAccountQuery = (
  { __typename?: 'Query' }
  & { whatsappAccount: Maybe<(
    { __typename?: 'WhatsappAccount' }
    & WhatsappAccountInfoFragment
  )> }
);

export type LoginWhatsappAccountMutationVariables = {
  id: Scalars['ObjectId']
};


export type LoginWhatsappAccountMutation = (
  { __typename?: 'Mutation' }
  & { whatsappLogin: Maybe<(
    { __typename?: 'QrCode' }
    & Pick<QrCode, 'image'>
  )> }
);

export type LogoutWhatsappAccountMutationVariables = {
  id: Scalars['ObjectId']
};


export type LogoutWhatsappAccountMutation = (
  { __typename?: 'Mutation' }
  & { whatsappLogout: Maybe<(
    { __typename?: 'WhatsappAccount' }
    & WhatsappAccountInfoFragment
  )> }
);

export type CreateWhatsappAccountMutationVariables = {
  input: CreateWhatsappAccountInput
};


export type CreateWhatsappAccountMutation = (
  { __typename?: 'Mutation' }
  & { createWhatsappAccount: Maybe<(
    { __typename?: 'WhatsappAccount' }
    & WhatsappAccountInfoFragment
  )> }
);

export type UpdateWhatsappAccountMutationVariables = {
  input: UpdateWhatsappAccountInput
};


export type UpdateWhatsappAccountMutation = (
  { __typename?: 'Mutation' }
  & { updateWhatsappAccount: Maybe<(
    { __typename?: 'WhatsappAccount' }
    & WhatsappAccountInfoFragment
  )> }
);

export type ArchiveWhatsappAccountMutationVariables = {
  id: Scalars['ObjectId']
};


export type ArchiveWhatsappAccountMutation = (
  { __typename?: 'Mutation' }
  & { archiveWhatsappAccount: Maybe<(
    { __typename?: 'WhatsappAccount' }
    & WhatsappAccountInfoFragment
  )> }
);

export type RecoverWhatsappAccountMutationVariables = {
  id: Scalars['ObjectId']
};


export type RecoverWhatsappAccountMutation = (
  { __typename?: 'Mutation' }
  & { recoverWhatsappAccount: Maybe<(
    { __typename?: 'WhatsappAccount' }
    & WhatsappAccountInfoFragment
  )> }
);

export type PauseWhatsappAccountMutationVariables = {
  id: Scalars['ObjectId']
};


export type PauseWhatsappAccountMutation = (
  { __typename?: 'Mutation' }
  & { pauseWhatsappAccount: Maybe<(
    { __typename?: 'WhatsappAccount' }
    & WhatsappAccountInfoFragment
  )> }
);

export type ResumeWhatsappAccountMutationVariables = {
  id: Scalars['ObjectId']
};


export type ResumeWhatsappAccountMutation = (
  { __typename?: 'Mutation' }
  & { resumeWhatsappAccount: Maybe<(
    { __typename?: 'WhatsappAccount' }
    & WhatsappAccountInfoFragment
  )> }
);

export type ReconnectWhatsappAccountMutationVariables = {
  id: Scalars['ObjectId']
};


export type ReconnectWhatsappAccountMutation = (
  { __typename?: 'Mutation' }
  & { reconnectWhatsappAccount: Maybe<(
    { __typename?: 'WhatsappAccount' }
    & WhatsappAccountInfoFragment
  )> }
);

export type RequeueWhatsAppMessageMutationVariables = {
  id: Scalars['ObjectId']
};


export type RequeueWhatsAppMessageMutation = (
  { __typename?: 'Mutation' }
  & { requeueWhatsAppMessage: Maybe<(
    { __typename?: 'WhatsappMessage' }
    & WhatsappMessageInfoFragment
  )> }
);

export type PauseWhatsAppMessageMutationVariables = {
  id: Scalars['ObjectId']
};


export type PauseWhatsAppMessageMutation = (
  { __typename?: 'Mutation' }
  & { pauseWhatsAppMessage: Maybe<(
    { __typename?: 'WhatsappMessage' }
    & WhatsappMessageInfoFragment
  )> }
);

export type RetryWhatsAppMessageMutationVariables = {
  id: Scalars['ObjectId']
};


export type RetryWhatsAppMessageMutation = (
  { __typename?: 'Mutation' }
  & { retryWhatsAppMessage: Maybe<(
    { __typename?: 'WhatsappMessage' }
    & WhatsappMessageInfoFragment
  )> }
);

export type ResumeWhatsAppMessageMutationVariables = {
  id: Scalars['ObjectId']
};


export type ResumeWhatsAppMessageMutation = (
  { __typename?: 'Mutation' }
  & { resumeWhatsAppMessage: Maybe<(
    { __typename?: 'WhatsappMessage' }
    & WhatsappMessageInfoFragment
  )> }
);

export type ValidateWhatsappNumberMutationVariables = {
  input: ValidateWhatsappNumberInput
};


export type ValidateWhatsappNumberMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'validateWhatsappNumber'>
);

export type GetReferencesQueryVariables = {
  category?: Maybe<ReferenceDataCategory>,
  showArchived?: Maybe<Scalars['Boolean']>
};


export type GetReferencesQuery = (
  { __typename?: 'Query' }
  & { referenceDatas: Maybe<Array<(
    { __typename?: 'ReferenceData' }
    & Pick<ReferenceData, 'category' | 'order' | 'key' | 'value' | 'isArchived'>
    & { id: ReferenceData['_id'] }
  )>> }
);

export type CreateReferenceDataMutationVariables = {
  input: CreateReferenceDataInput
};


export type CreateReferenceDataMutation = (
  { __typename?: 'Mutation' }
  & { createReferenceData: Maybe<(
    { __typename?: 'ReferenceData' }
    & Pick<ReferenceData, 'category' | 'order' | 'key' | 'value' | 'isArchived'>
    & { id: ReferenceData['_id'] }
  )> }
);

export type UpdateReferenceDataMutationVariables = {
  input: UpdateReferenceDataInput
};


export type UpdateReferenceDataMutation = (
  { __typename?: 'Mutation' }
  & { updateReferenceData: Maybe<(
    { __typename?: 'ReferenceData' }
    & Pick<ReferenceData, 'category' | 'order' | 'key' | 'value' | 'isArchived'>
    & { id: ReferenceData['_id'] }
  )> }
);

export type PostReferenceDataSubscriptionVariables = {};


export type PostReferenceDataSubscription = (
  { __typename?: 'Subscription' }
  & { postReferenceData: (
    { __typename?: 'ReferenceData' }
    & Pick<ReferenceData, 'category' | 'order' | 'key' | 'value' | 'isArchived'>
    & { id: ReferenceData['_id'] }
  ) }
);

export type GetLevelTableDataQueryVariables = {};


export type GetLevelTableDataQuery = (
  { __typename?: 'Query' }
  & { academicLevels: Maybe<Array<(
    { __typename?: 'AcademicLevel' }
    & BasicAcademicLevelFieldsFragment
  )>> }
);

export type GetSchoolTableDataQueryVariables = {};


export type GetSchoolTableDataQuery = (
  { __typename?: 'Query' }
  & { schools: Maybe<Array<(
    { __typename?: 'School' }
    & Pick<School, 'name' | 'alias' | 'educationStage' | 'isVisible' | 'isArchived' | 'createDateTime'>
    & { id: School['_id'] }
  )>> }
);

export type CreateLevelMutationVariables = {
  CreateAcademicLevelInput: CreateAcademicLevelInput
};


export type CreateLevelMutation = (
  { __typename?: 'Mutation' }
  & { createAcademicLevel: Maybe<(
    { __typename?: 'AcademicLevel' }
    & AcademicLevelFieldsFragment
  )> }
);

export type UpdateLevelMutationVariables = {
  UpdateAcademicLevelInput: UpdateAcademicLevelInput
};


export type UpdateLevelMutation = (
  { __typename?: 'Mutation' }
  & { updateAcademicLevel: Maybe<(
    { __typename?: 'AcademicLevel' }
    & AcademicLevelFieldsFragment
  )> }
);

export type DeleteLevelMutationVariables = {
  DeleteAcademicLevelInput: IdInput
};


export type DeleteLevelMutation = (
  { __typename?: 'Mutation' }
  & { deleteAcademicLevel: Maybe<(
    { __typename?: 'AcademicLevel' }
    & { id: AcademicLevel['_id'] }
  )> }
);

export type ChangeAcademicLevelArchiveMutationVariables = {
  input: ChangeAcademicLevelArchive
};


export type ChangeAcademicLevelArchiveMutation = (
  { __typename?: 'Mutation' }
  & { changeAcademicLevelArchive: Maybe<(
    { __typename?: 'AcademicLevel' }
    & AcademicLevelFieldsFragment
  )> }
);

export type ChangeAcademicLevelVisibilityMutationVariables = {
  input: ChangeAcademicLevelVisibility
};


export type ChangeAcademicLevelVisibilityMutation = (
  { __typename?: 'Mutation' }
  & { changeAcademicLevelVisibility: Maybe<(
    { __typename?: 'AcademicLevel' }
    & AcademicLevelFieldsFragment
  )> }
);

export type GetLevelQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetLevelQuery = (
  { __typename?: 'Query' }
  & { academicLevel: Maybe<(
    { __typename?: 'AcademicLevel' }
    & AcademicLevelFieldsFragment
  )> }
);

export type GetLevelsQueryVariables = {
  ids?: Maybe<Array<Scalars['ObjectId']>>
};


export type GetLevelsQuery = (
  { __typename?: 'Query' }
  & { academicLevels: Maybe<Array<(
    { __typename?: 'AcademicLevel' }
    & AcademicLevelFieldsFragment
  )>> }
);

export type GetAcademicLevelsWithCoursesQueryVariables = {
  ids?: Maybe<Array<Scalars['ObjectId']>>
};


export type GetAcademicLevelsWithCoursesQuery = (
  { __typename?: 'Query' }
  & { academicLevels: Maybe<Array<(
    { __typename?: 'AcademicLevel' }
    & Pick<AcademicLevel, 'alias' | 'createDateTime' | 'educationStage' | 'isArchived' | 'isVisible' | 'name' | 'orderNo'>
    & { id: AcademicLevel['_id'] }
    & { courses: Maybe<Array<(
      { __typename?: 'Course' }
      & Pick<Course, 'name' | 'nameShort'>
      & { id: Course['_id'] }
    )>> }
  )>> }
);

export type GetAllAcademicLevelsQueryVariables = {};


export type GetAllAcademicLevelsQuery = (
  { __typename?: 'Query' }
  & { academicLevels: Maybe<Array<(
    { __typename?: 'AcademicLevel' }
    & Pick<AcademicLevel, 'alias' | 'name'>
    & { id: AcademicLevel['_id'] }
  )>> }
);

export type UpdateAcademicYearMutationVariables = {
  input: UpdateAcademicYearInput
};


export type UpdateAcademicYearMutation = (
  { __typename?: 'Mutation' }
  & { updateAcademicYear: (
    { __typename?: 'AcademicYear' }
    & { id: AcademicYear['_id'] }
  ) }
);

export type CreateAcademicYearMutationVariables = {
  input: CreateAcademicYearInput
};


export type CreateAcademicYearMutation = (
  { __typename?: 'Mutation' }
  & { createAcademicYear: (
    { __typename?: 'AcademicYear' }
    & { id: AcademicYear['_id'] }
  ) }
);

export type AcademicYearQueryVariables = {
  id: Scalars['ObjectId']
};


export type AcademicYearQuery = (
  { __typename?: 'Query' }
  & { academicYear: Maybe<(
    { __typename?: 'AcademicYear' }
    & Pick<AcademicYear, 'year' | 'academicWeekStart' | 'academicWeekEnd'>
    & { id: AcademicYear['_id'] }
    & { period: (
      { __typename?: 'DateTimeRange' }
      & Pick<DateTimeRange, 'start' | 'end'>
    ), terms: Maybe<Array<(
      { __typename?: 'Term' }
      & { id: Term['_id'] }
      & { academicLevels: Maybe<Array<(
        { __typename?: 'AcademicLevel' }
        & { id: AcademicLevel['_id'] }
      )>> }
      & BasicTermFieldsFragment
    )>>, holidays: Maybe<Array<(
      { __typename?: 'Holiday' }
      & BasicHolidayFieldsFragment
    )>> }
  )> }
);

export type AcademicYearsQueryVariables = {};


export type AcademicYearsQuery = (
  { __typename?: 'Query' }
  & { academicYears: Maybe<Array<(
    { __typename?: 'AcademicYear' }
    & Pick<AcademicYear, 'year' | 'taxRate' | 'academicWeekStart' | 'academicWeekEnd'>
    & { id: AcademicYear['_id'] }
    & { period: (
      { __typename?: 'DateTimeRange' }
      & Pick<DateTimeRange, 'start' | 'end'>
    ), academicWeeks: Array<(
      { __typename?: 'AcademicWeekWithId' }
      & Pick<AcademicWeekWithId, 'startDate' | 'endDate' | 'weekNumber'>
    )> }
  )>> }
);

export type GetAcademicYearsOptionsQueryVariables = {};


export type GetAcademicYearsOptionsQuery = (
  { __typename?: 'Query' }
  & { academicYears: Maybe<Array<(
    { __typename?: 'AcademicYear' }
    & Pick<AcademicYear, 'year'>
    & { id: AcademicYear['_id'] }
  )>> }
);

export type GetAcademicYearTaxRateQueryVariables = {
  year: Scalars['Int']
};


export type GetAcademicYearTaxRateQuery = (
  { __typename?: 'Query' }
  & { academicYear: Maybe<(
    { __typename?: 'AcademicYear' }
    & Pick<AcademicYear, 'year' | 'taxRate'>
    & { id: AcademicYear['_id'] }
  )> }
);

export type CreateDepartmentMutationVariables = {
  CreateDepartmentInput: CreateDepartmentInput
};


export type CreateDepartmentMutation = (
  { __typename?: 'Mutation' }
  & { createDepartment: Maybe<(
    { __typename?: 'Department' }
    & DepartmentFieldsFragment
  )> }
);

export type UpdateDepartmentMutationVariables = {
  input: UpdateDepartmentInput
};


export type UpdateDepartmentMutation = (
  { __typename?: 'Mutation' }
  & { updateDepartment: Maybe<(
    { __typename?: 'Department' }
    & DepartmentFieldsFragment
  )> }
);

export type DeleteDepartmentMutationVariables = {
  DeleteDepartmentInput: IdInput
};


export type DeleteDepartmentMutation = (
  { __typename?: 'Mutation' }
  & { deleteDepartment: Maybe<(
    { __typename?: 'Centre' }
    & { id: Centre['_id'] }
    & { departments: Maybe<Array<(
      { __typename?: 'Department' }
      & { id: Department['_id'] }
    )>> }
  )> }
);

export type ChangeDepartmentArchiveMutationVariables = {
  input: ChangeDepartmentArchiveInput
};


export type ChangeDepartmentArchiveMutation = (
  { __typename?: 'Mutation' }
  & { changeDepartmentArchive: Maybe<(
    { __typename?: 'Department' }
    & DepartmentFieldsFragment
  )> }
);

export type CreateBranchMutationVariables = {
  CreateBranchInput: CreateBranchInput
};


export type CreateBranchMutation = (
  { __typename?: 'Mutation' }
  & { createBranch: Maybe<(
    { __typename?: 'Branch' }
    & { id: Branch['_id'] }
  )> }
);

export type UpdateBranchMutationVariables = {
  UpdateBranchInput: UpdateBranchInput
};


export type UpdateBranchMutation = (
  { __typename?: 'Mutation' }
  & { updateBranch: Maybe<(
    { __typename?: 'Branch' }
    & { venues: Maybe<Array<(
      { __typename?: 'Venue' }
      & BasicVenueFieldsFragment
    )>> }
    & BasicBranchFieldsFragment
  )> }
);

export type DeleteBranchMutationVariables = {
  DeleteBranchInput: IdInput
};


export type DeleteBranchMutation = (
  { __typename?: 'Mutation' }
  & { deleteBranch: Maybe<(
    { __typename?: 'Branch' }
    & Pick<Branch, 'name'>
    & { id: Branch['_id'] }
  )> }
);

export type ChangeBranchArchiveMutationVariables = {
  input: ChangeBranchArchiveInput
};


export type ChangeBranchArchiveMutation = (
  { __typename?: 'Mutation' }
  & { changeBranchArchive: Maybe<(
    { __typename?: 'Branch' }
    & { id: Branch['_id'] }
  )> }
);

export type CreateVenueMutationVariables = {
  command: CreateVenueCommand
};


export type CreateVenueMutation = (
  { __typename?: 'Mutation' }
  & { createVenue: Maybe<(
    { __typename?: 'Venue' }
    & { id: Venue['_id'] }
    & { branch: Maybe<(
      { __typename?: 'Branch' }
      & { venues: Maybe<Array<(
        { __typename?: 'Venue' }
        & BasicVenueFieldsFragment
      )>> }
      & BasicBranchFieldsFragment
    )> }
  )> }
);

export type UpdateVenueMutationVariables = {
  command: UpdateVenueCommand
};


export type UpdateVenueMutation = (
  { __typename?: 'Mutation' }
  & { updateVenue: Maybe<(
    { __typename?: 'Venue' }
    & BasicVenueFieldsFragment
  )> }
);

export type CreateCourierPickupAddressMutationVariables = {
  input: CreateCourierPickupAddressInput
};


export type CreateCourierPickupAddressMutation = (
  { __typename?: 'Mutation' }
  & { createCourierPickupAddress: Maybe<(
    { __typename?: 'CourierPickupAddress' }
    & BasicCourierPickupAddressFieldsFragment
  )> }
);

export type UpdateCourierPickupAddressMutationVariables = {
  input: UpdateCourierPickupAddressInput
};


export type UpdateCourierPickupAddressMutation = (
  { __typename?: 'Mutation' }
  & { updateCourierPickupAddress: Maybe<(
    { __typename?: 'CourierPickupAddress' }
    & BasicCourierPickupAddressFieldsFragment
  )> }
);

export type UpdateCourierPickupAddressArchiveMutationVariables = {
  input: ChangeCourierPickupAddressArchiveInput
};


export type UpdateCourierPickupAddressArchiveMutation = (
  { __typename?: 'Mutation' }
  & { changeCourierPickupAddressArchive: Maybe<(
    { __typename?: 'CourierPickupAddress' }
    & BasicCourierPickupAddressFieldsFragment
  )> }
);

export type GetDepartmentsQueryVariables = {
  showArchive?: Maybe<Scalars['Boolean']>
};


export type GetDepartmentsQuery = (
  { __typename?: 'Query' }
  & { departments: Maybe<Array<(
    { __typename?: 'Department' }
    & DepartmentFieldsFragment
  )>> }
);

export type GetDepartmentQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetDepartmentQuery = (
  { __typename?: 'Query' }
  & { department: Maybe<(
    { __typename?: 'Department' }
    & DepartmentFieldsFragment
  )> }
);

export type GetAllBranchesQueryVariables = {
  showArchive?: Maybe<Scalars['Boolean']>
};


export type GetAllBranchesQuery = (
  { __typename?: 'Query' }
  & { branches: Maybe<Array<(
    { __typename?: 'Branch' }
    & { venues: Maybe<Array<(
      { __typename?: 'Venue' }
      & Pick<Venue, 'isArchived'>
      & { id: Venue['_id'] }
    )>> }
    & BasicBranchFieldsFragment
  )>> }
);

export type GetAllVenuesQueryVariables = {
  showArchive?: Maybe<Scalars['Boolean']>,
  showDigital?: Maybe<Scalars['Boolean']>
};


export type GetAllVenuesQuery = (
  { __typename?: 'Query' }
  & { venues: Maybe<Array<(
    { __typename?: 'Venue' }
    & { createdBy: Maybe<(
      { __typename?: 'SystemUser' }
      & CreateByFieldsFragment
    )>, branch: Maybe<(
      { __typename?: 'Branch' }
      & BasicBranchFieldsFragment
    )> }
    & BasicVenueFieldsFragment
  )>> }
);

export type GetAllVenueOptionsQueryVariables = {
  showArchive?: Maybe<Scalars['Boolean']>
};


export type GetAllVenueOptionsQuery = (
  { __typename?: 'Query' }
  & { venues: Maybe<Array<(
    { __typename?: 'Venue' }
    & Pick<Venue, 'name'>
    & { id: Venue['_id'] }
    & { branch: Maybe<(
      { __typename?: 'Branch' }
      & Pick<Branch, 'name' | 'alias'>
    )> }
  )>> }
);

export type GetCentreIdQueryVariables = {};


export type GetCentreIdQuery = (
  { __typename?: 'Query' }
  & { centre: Maybe<(
    { __typename?: 'Centre' }
    & { id: Centre['_id'] }
  )> }
);

export type AcademicLevelFieldsFragment = (
  { __typename?: 'AcademicLevel' }
  & Pick<AcademicLevel, 'orderNo' | 'name' | 'alias' | 'educationStage' | 'isVisible' | 'isArchived' | 'createDateTime'>
  & { id: AcademicLevel['_id'] }
  & { createdBy: Maybe<(
    { __typename?: 'SystemUser' }
    & CreateByFieldsFragment
  )> }
);

export type DepartmentFieldsFragment = (
  { __typename?: 'Department' }
  & Pick<Department, 'name' | 'isArchived' | 'createDateTime'>
  & { id: Department['_id'] }
);

export type GetBranchesQueryVariables = {};


export type GetBranchesQuery = (
  { __typename?: 'Query' }
  & { branches: Maybe<Array<(
    { __typename?: 'Branch' }
    & Pick<Branch, 'name'>
    & { id: Branch['_id'] }
    & { venues: Maybe<Array<(
      { __typename?: 'Venue' }
      & BasicVenueFieldsFragment
    )>> }
  )>> }
);

export type GetAllVenueQueryVariables = {
  showArchived?: Maybe<Scalars['Boolean']>
};


export type GetAllVenueQuery = (
  { __typename?: 'Query' }
  & { venues: Maybe<Array<(
    { __typename?: 'Venue' }
    & Pick<Venue, 'name' | 'shorthand' | 'unitNo' | 'capacity' | 'isArchived' | 'createDateTime'>
    & { id: Venue['_id'] }
    & { branch: Maybe<(
      { __typename?: 'Branch' }
      & Pick<Branch, 'name' | 'alias'>
      & { id: Branch['_id'] }
    )>, createdBy: Maybe<(
      { __typename?: 'SystemUser' }
      & CreateByFieldsFragment
    )> }
  )>> }
);

export type GetAllCourierPickupAddressQueryVariables = {
  showArchived?: Maybe<Scalars['Boolean']>
};


export type GetAllCourierPickupAddressQuery = (
  { __typename?: 'Query' }
  & { courierPickupAddresses: Maybe<Array<(
    { __typename?: 'CourierPickupAddress' }
    & BasicCourierPickupAddressFieldsFragment
  )>> }
);

export type MailMergeFieldsFragment = (
  { __typename?: 'MailMerge' }
  & Pick<MailMerge, '_id' | 'mergeList' | 'mergeListHeaders'>
  & { template: (
    { __typename?: 'Template' }
    & Pick<Template, 'whatsAppMessage'>
  ) }
);

export type CreateMailMergeMutationVariables = {
  input: CreateMailMergeInput
};


export type CreateMailMergeMutation = (
  { __typename?: 'Mutation' }
  & { createMailMerge: Maybe<(
    { __typename?: 'MailMerge' }
    & MailMergeFieldsFragment
  )> }
);

export type UpdateMailMergeMutationVariables = {
  input: UpdateMailMergeInput
};


export type UpdateMailMergeMutation = (
  { __typename?: 'Mutation' }
  & { updateMailMerge: Maybe<(
    { __typename?: 'MailMerge' }
    & MailMergeFieldsFragment
  )> }
);

export type UploadMailMergeMergeListMutationVariables = {
  input: UploadMailMergeMergeListInput
};


export type UploadMailMergeMergeListMutation = (
  { __typename?: 'Mutation' }
  & { uploadMailMergeMergeList: Maybe<(
    { __typename?: 'MailMerge' }
    & MailMergeFieldsFragment
  )> }
);

export type GetMailMergeQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetMailMergeQuery = (
  { __typename?: 'Query' }
  & { getMailMerge: Maybe<(
    { __typename?: 'MailMerge' }
    & MailMergeFieldsFragment
  )> }
);

export type CompleteMailMergeMutationVariables = {
  input: CompleteMailMergeInput
};


export type CompleteMailMergeMutation = (
  { __typename?: 'Mutation' }
  & { completeMailMerge: Maybe<(
    { __typename?: 'MailMerge' }
    & MailMergeFieldsFragment
  )> }
);

export type DownloadMailMergeMutationVariables = {
  id: Scalars['ObjectId']
};


export type DownloadMailMergeMutation = (
  { __typename?: 'Mutation' }
  & { downloadMailMerge: Maybe<(
    { __typename?: 'Csv' }
    & Pick<Csv, 'url'>
  )> }
);

export type NotificationSettingFieldsFragment = (
  { __typename?: 'NotificationSetting' }
  & Pick<NotificationSetting, '_id' | 'type' | 'groupName' | 'name' | 'isEnabled'>
  & { template: Maybe<(
    { __typename?: 'Template' }
    & Pick<Template, '_id' | 'title'>
  )>, conditions: (
    { __typename?: 'NotificationCondition' }
    & Pick<NotificationCondition, 'courseTypes'>
  ) }
);

export type GetNotificationConfigurationsQueryVariables = {
  type: NotificationType
};


export type GetNotificationConfigurationsQuery = (
  { __typename?: 'Query' }
  & { getNotificationConfigurations: Maybe<Array<(
    { __typename?: 'NotificationSetting' }
    & NotificationSettingFieldsFragment
  )>> }
);

export type UpdateNotificationItemMutationVariables = {
  input: NotificationInput
};


export type UpdateNotificationItemMutation = (
  { __typename?: 'Mutation' }
  & { updateNotificationItem: Maybe<(
    { __typename?: 'NotificationSetting' }
    & NotificationSettingFieldsFragment
  )> }
);

export type GetNotificationsOfTemplatesQueryVariables = {
  search?: Maybe<Scalars['String']>,
  filterInput?: Maybe<TemplateFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetNotificationsOfTemplatesQuery = (
  { __typename?: 'Query' }
  & { templates: Maybe<(
    { __typename?: 'TemplatePage' }
    & Pick<TemplatePage, 'total' | 'pageSize' | 'pageIndex'>
    & { items: Array<(
      { __typename?: 'Template' }
      & Pick<Template, 'title' | 'type'>
      & { id: Template['_id'] }
    )> }
  )> }
);

export type CreatePromotionMutationVariables = {
  input: CreatePromotionInput
};


export type CreatePromotionMutation = (
  { __typename?: 'Mutation' }
  & { createPromotion: Maybe<(
    { __typename?: 'Promotion' }
    & PromotionFieldsFragment
  )> }
);

export type UpdatePromotionMutationVariables = {
  input: UpdatePromotionInput
};


export type UpdatePromotionMutation = (
  { __typename?: 'Mutation' }
  & { updatePromotion: Maybe<(
    { __typename?: 'Promotion' }
    & PromotionFieldsFragment
  )> }
);

export type DeletePromotionMutationVariables = {
  id: Scalars['ObjectId']
};


export type DeletePromotionMutation = (
  { __typename?: 'Mutation' }
  & { deletePromotion: Maybe<(
    { __typename?: 'Promotion' }
    & PromotionFieldsFragment
  )> }
);

export type CancelPromotionMutationVariables = {
  id: Scalars['ObjectId']
};


export type CancelPromotionMutation = (
  { __typename?: 'Mutation' }
  & { cancelPromotion: Maybe<(
    { __typename?: 'Promotion' }
    & PromotionFieldsFragment
  )> }
);

export type GetPromotionsQueryVariables = {};


export type GetPromotionsQuery = (
  { __typename?: 'Query' }
  & { promotions: Maybe<Array<(
    { __typename?: 'Promotion' }
    & PromotionFieldsFragment
  )>> }
);

export type GetPromotionQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetPromotionQuery = (
  { __typename?: 'Query' }
  & { promotion: Maybe<(
    { __typename?: 'Promotion' }
    & { students: Maybe<Array<(
      { __typename?: 'Student' }
      & Pick<Student, 'fullName' | 'gender' | 'birthDate'>
      & { id: Student['_id'] }
      & { academicLevel: Maybe<(
        { __typename?: 'AcademicLevel' }
        & Pick<AcademicLevel, 'name'>
        & { id: AcademicLevel['_id'] }
      )> }
    )>>, promoteToLevel: (
      { __typename?: 'AcademicLevel' }
      & Pick<AcademicLevel, 'name'>
      & { id: AcademicLevel['_id'] }
    ), selectedStudents: Maybe<Array<(
      { __typename?: 'Student' }
      & StudentFieldsInPromotionFragment
    )>> }
    & PromotionFieldsFragment
  )> }
);

export type PromotionFieldsFragment = (
  { __typename?: 'Promotion' }
  & Pick<Promotion, 'scheduledDate' | 'status'>
  & { id: Promotion['_id'] }
  & { selectedStudents: Maybe<Array<(
    { __typename?: 'Student' }
    & { id: Student['_id'] }
  )>>, students: Maybe<Array<(
    { __typename?: 'Student' }
    & { id: Student['_id'] }
  )>>, academicLevels: Array<(
    { __typename?: 'AcademicLevel' }
    & Pick<AcademicLevel, 'name' | 'orderNo'>
    & { id: AcademicLevel['_id'] }
  )> }
);

export type PromotionCsvQueryVariables = {};


export type PromotionCsvQuery = (
  { __typename?: 'Query' }
  & { csv: Maybe<(
    { __typename?: 'Csv' }
    & Pick<Csv, 'url'>
  )> }
);

export type BasicRewardHistoryFieldsFragment = (
  { __typename?: 'RewardHistory' }
  & Pick<RewardHistory, 'createDateTime' | 'isArchived' | 'type' | 'status' | 'adjustment' | 'remarks'>
  & { id: RewardHistory['_id'] }
  & { createdBy: Maybe<(
    { __typename?: 'SystemUser' }
    & CreateByFieldsFragment
  )>, student: (
    { __typename?: 'Student' }
    & Pick<Student, 'fullName' | 'avatarUrl' | 'alias' | 'gender'>
    & { academicLevel: Maybe<(
      { __typename?: 'AcademicLevel' }
      & Pick<AcademicLevel, 'name' | 'educationStage'>
    )>, primarySchool: Maybe<(
      { __typename?: 'School' }
      & Pick<School, 'name'>
    )> }
  ), rewardPoints: Array<(
    { __typename?: 'RewardPoints' }
    & Pick<RewardPoints, 'points'>
  )>, orderDetail: Maybe<(
    { __typename?: 'RewardDetail' }
    & Pick<RewardDetail, 'quantity' | 'trackingNumber' | 'orderUrl'>
    & { reward: Maybe<(
      { __typename?: 'Reward' }
      & Pick<Reward, 'avatar' | 'name' | 'description' | 'points'>
    )> }
  )>, orderHistory: Maybe<Array<(
    { __typename?: 'OrderHistory' }
    & Pick<OrderHistory, 'status' | 'createDateTime'>
    & { createdBy: Maybe<(
      { __typename?: 'SystemUser' }
      & CreateByFieldsFragment
    )> }
  )>> }
);

export type BasicStudentBaseRewardsFragment = (
  { __typename?: 'Student' }
  & Pick<Student, 'gender' | 'fullName' | 'avatarUrl' | 'isActive'>
  & { id: Student['_id'] }
  & { academicLevel: Maybe<(
    { __typename?: 'AcademicLevel' }
    & Pick<AcademicLevel, 'name' | 'educationStage'>
  )>, primarySchool: Maybe<(
    { __typename?: 'School' }
    & Pick<School, 'name'>
  )>, secondarySchool: Maybe<(
    { __typename?: 'School' }
    & Pick<School, 'name'>
  )>, rewardPointsCounter: Maybe<(
    { __typename?: 'PointCounter' }
    & Pick<PointCounter, 'remaining' | 'expiring'>
  )>, rewardPoints: Maybe<Array<(
    { __typename?: 'RewardPoints' }
    & Pick<RewardPoints, 'status' | 'points' | 'used'>
    & { id: RewardPoints['_id'] }
  )>> }
);

export type GetRewardsHistoryQueryVariables = {
  filterInput?: Maybe<RewardHistoryFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetRewardsHistoryQuery = (
  { __typename?: 'Query' }
  & { rewardsHistory: Maybe<(
    { __typename?: 'RewardHistoryPage' }
    & Pick<RewardHistoryPage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'RewardHistory' }
      & BasicRewardHistoryFieldsFragment
    )> }
  )> }
);

export type QueryHistoryPointBaseDateRangeQueryVariables = {
  studentId: Scalars['ObjectId'],
  dateRange: DateRangeInput
};


export type QueryHistoryPointBaseDateRangeQuery = (
  { __typename?: 'Query' }
  & { historyPointCounterBaseDateRange: Maybe<(
    { __typename?: 'RewardsHistoryCounter' }
    & Pick<RewardsHistoryCounter, 'redeemed' | 'accumulated'>
  )> }
);

export type GetRewardsQueryVariables = {
  search?: Maybe<Scalars['String']>,
  filterInput?: Maybe<RewardFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetRewardsQuery = (
  { __typename?: 'Query' }
  & { rewards: Maybe<(
    { __typename?: 'RewardPage' }
    & Pick<RewardPage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'Reward' }
      & BasicRewardFieldsFragment
    )> }
  )> }
);

export type AddRewardPointsMutationVariables = {
  input: AddRewardPointsInput
};


export type AddRewardPointsMutation = (
  { __typename?: 'Mutation' }
  & { addRewardPoints: Maybe<(
    { __typename?: 'Student' }
    & { id: Student['_id'] }
  )> }
);

export type DeductRewardPointsMutationVariables = {
  input: DeductRewardPointsInput
};


export type DeductRewardPointsMutation = (
  { __typename?: 'Mutation' }
  & { deductRewardPoints: Maybe<(
    { __typename?: 'Student' }
    & { id: Student['_id'] }
  )> }
);

export type AddRewardMutationVariables = {
  input: AddRewardCatalogueInput
};


export type AddRewardMutation = (
  { __typename?: 'Mutation' }
  & { addReward: Maybe<(
    { __typename?: 'Reward' }
    & { id: Reward['_id'] }
  )> }
);

export type UpdateRewardMutationVariables = {
  input: UpdateRewardCatalogInput
};


export type UpdateRewardMutation = (
  { __typename?: 'Mutation' }
  & { updateReward: Maybe<(
    { __typename?: 'Reward' }
    & BasicRewardFieldsFragment
  )> }
);

export type ArchiveRewardMutationVariables = {
  id: Scalars['ObjectId']
};


export type ArchiveRewardMutation = (
  { __typename?: 'Mutation' }
  & { archiveReward: Maybe<(
    { __typename?: 'Reward' }
    & BasicRewardFieldsFragment
  )> }
);

export type RestoreRewardMutationVariables = {
  id: Scalars['ObjectId']
};


export type RestoreRewardMutation = (
  { __typename?: 'Mutation' }
  & { restoreReward: Maybe<(
    { __typename?: 'Reward' }
    & BasicRewardFieldsFragment
  )> }
);

export type UseRewardPointsMutationVariables = {
  input: UseRewardPointsInput
};


export type UseRewardPointsMutation = (
  { __typename?: 'Mutation' }
  & { useRewardPoints: Maybe<(
    { __typename?: 'Student' }
    & BasicStudentBaseRewardsFragment
  )> }
);

export type UpdateRewardHistoryMutationVariables = {
  input: UpdateRewardsHistoryInput
};


export type UpdateRewardHistoryMutation = (
  { __typename?: 'Mutation' }
  & { updateRewardsHistory: Maybe<(
    { __typename?: 'RewardHistory' }
    & BasicRewardHistoryFieldsFragment
  )> }
);

export type ArchiveHistoryRewardMutationVariables = {
  id: Scalars['ObjectId']
};


export type ArchiveHistoryRewardMutation = (
  { __typename?: 'Mutation' }
  & { archiveHistoryReward: Maybe<(
    { __typename?: 'RewardHistory' }
    & BasicRewardHistoryFieldsFragment
  )> }
);

export type CreateSchoolMutationVariables = {
  CreateSchoolInput: CreateSchoolInput
};


export type CreateSchoolMutation = (
  { __typename?: 'Mutation' }
  & { createSchool: Maybe<(
    { __typename?: 'School' }
    & SchoolFieldsFragment
  )> }
);

export type UpdateSchoolMutationVariables = {
  UpdateSchoolInput: UpdateSchoolInput
};


export type UpdateSchoolMutation = (
  { __typename?: 'Mutation' }
  & { updateSchool: Maybe<(
    { __typename?: 'School' }
    & SchoolFieldsFragment
  )> }
);

export type DeleteSchoolMutationVariables = {
  DeleteSchoolInput: IdInput
};


export type DeleteSchoolMutation = (
  { __typename?: 'Mutation' }
  & { deleteSchool: Maybe<(
    { __typename?: 'School' }
    & { id: School['_id'] }
  )> }
);

export type ChangeSchoolArchiveMutationVariables = {
  input: ChangeSchoolArchiveInput
};


export type ChangeSchoolArchiveMutation = (
  { __typename?: 'Mutation' }
  & { changeSchoolArchive: Maybe<(
    { __typename?: 'School' }
    & SchoolFieldsFragment
  )> }
);

export type ChangeSchoolVisibilityMutationVariables = {
  input: ChangeSchoolVisibilityInput
};


export type ChangeSchoolVisibilityMutation = (
  { __typename?: 'Mutation' }
  & { changeSchoolVisibility: Maybe<(
    { __typename?: 'School' }
    & SchoolFieldsFragment
  )> }
);

export type GetSchoolQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetSchoolQuery = (
  { __typename?: 'Query' }
  & { school: Maybe<(
    { __typename?: 'School' }
    & SchoolFieldsFragment
  )> }
);

export type GetSchoolsQueryVariables = {};


export type GetSchoolsQuery = (
  { __typename?: 'Query' }
  & { schools: Maybe<Array<(
    { __typename?: 'School' }
    & SchoolFieldsFragment
  )>> }
);

export type SchoolFieldsFragment = (
  { __typename?: 'School' }
  & Pick<School, 'name' | 'alias' | 'educationStage' | 'isVisible' | 'isArchived' | 'createDateTime'>
  & { id: School['_id'] }
  & { createdBy: Maybe<(
    { __typename?: 'SystemUser' }
    & CreateByFieldsFragment
  )> }
);

export type CreateTemplateMutationVariables = {
  input: CreateTemplateInput
};


export type CreateTemplateMutation = (
  { __typename?: 'Mutation' }
  & { createTemplate: Maybe<(
    { __typename?: 'Template' }
    & BasicTemplateFieldsFragment
  )> }
);

export type UpdateTemplateMutationVariables = {
  input: UpdateTemplateInput
};


export type UpdateTemplateMutation = (
  { __typename?: 'Mutation' }
  & { updateTemplate: Maybe<(
    { __typename?: 'Template' }
    & BasicTemplateFieldsFragment
  )> }
);

export type UpdateTemplateArchiveMutationVariables = {
  id: Scalars['ObjectId'],
  isArchived: Scalars['Boolean']
};


export type UpdateTemplateArchiveMutation = (
  { __typename?: 'Mutation' }
  & { updateArchivable: Maybe<(
    { __typename?: 'Template' }
    & BasicTemplateFieldsFragment
  )> }
);

export type GetTemplatesQueryVariables = {
  search?: Maybe<Scalars['String']>,
  filterInput?: Maybe<TemplateFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetTemplatesQuery = (
  { __typename?: 'Query' }
  & { templates: Maybe<(
    { __typename?: 'TemplatePage' }
    & Pick<TemplatePage, 'total' | 'pageSize' | 'pageIndex'>
    & { items: Array<(
      { __typename?: 'Template' }
      & Pick<Template, 'isArchived'>
      & BasicTemplateFieldsFragment
    )> }
  )> }
);

export type GetTemplateQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetTemplateQuery = (
  { __typename?: 'Query' }
  & { template: Maybe<(
    { __typename?: 'Template' }
    & BasicTemplateFieldsFragment
  )> }
);

export type CreateLogMutationVariables = {
  input: CreateLogInput
};


export type CreateLogMutation = (
  { __typename?: 'Mutation' }
  & { createLog: Maybe<(
    { __typename?: 'Log' }
    & LogFieldsFragment
  )> }
);

export type UpdateLogMutationVariables = {
  input: UpdateLogInput
};


export type UpdateLogMutation = (
  { __typename?: 'Mutation' }
  & { updateLog: Maybe<(
    { __typename?: 'Log' }
    & LogFieldsFragment
  )> }
);

export type CreateNoteMutationVariables = {
  input: CreateNoteInput
};


export type CreateNoteMutation = (
  { __typename?: 'Mutation' }
  & { createNote: Maybe<(
    { __typename?: 'Note' }
    & NoteFieldsFragment
  )> }
);

export type LogFieldsFragment = (
  { __typename?: 'Log' }
  & Pick<Log, 'userId' | 'log' | 'type' | 'attachment' | 'createDateTime' | 'isDeleted'>
  & { id: Log['_id'] }
  & { createdBy: Maybe<(
    { __typename?: 'SystemUser' }
    & Pick<SystemUser, 'avatarUrl'>
    & CreateByFieldsFragment
  )> }
);

export type NoteFieldsFragment = (
  { __typename?: 'Note' }
  & Pick<Note, 'userId' | 'note' | 'createDateTime' | 'isDeleted'>
  & { id: Note['_id'] }
  & { createdBy: Maybe<(
    { __typename?: 'SystemUser' }
    & UserFieldsFragment
  )> }
);

export type GetUserLogsQueryVariables = {
  userId: Scalars['ObjectId'],
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetUserLogsQuery = (
  { __typename?: 'Query' }
  & { userLogs: Maybe<(
    { __typename?: 'LogPage' }
    & Pick<LogPage, 'total'>
    & { items: Array<(
      { __typename?: 'Log' }
      & LogFieldsFragment
    )> }
  )> }
);

export type GetUserNotesQueryVariables = {
  userId: Scalars['ObjectId'],
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetUserNotesQuery = (
  { __typename?: 'Query' }
  & { userNotes: Maybe<(
    { __typename?: 'NotePage' }
    & Pick<NotePage, 'total'>
    & { items: Array<(
      { __typename?: 'Note' }
      & NoteFieldsFragment
    )> }
  )> }
);

export type GetStudentClassesQueryVariables = {
  search?: Maybe<Scalars['String']>,
  studentId: Scalars['ObjectId'],
  pageInfo?: Maybe<PageInfo>,
  sortInfo?: Maybe<SortInfo>
};


export type GetStudentClassesQuery = (
  { __typename?: 'Query' }
  & { classesFromStudent: Maybe<(
    { __typename?: 'ClassPage' }
    & { items: Array<(
      { __typename?: 'Class' }
      & { id: Class['_id'] }
    )> }
  )> }
);

export type GetStudentForLinkPeopleQueryVariables = {
  id: Scalars['ObjectId'],
  parentId?: Maybe<Scalars['ObjectId']>
};


export type GetStudentForLinkPeopleQuery = (
  { __typename?: 'Query' }
  & { student: Maybe<(
    { __typename?: 'Student' }
    & Pick<Student, 'fullName'>
    & { id: Student['_id'] }
    & { primaryParent: Maybe<(
      { __typename?: 'Parent' }
      & { id: Parent['_id'] }
    )>, parents: Maybe<Array<(
      { __typename?: 'Parent' }
      & Pick<Parent, 'fullName' | 'avatarUrl' | 'salutation' | 'gender' | 'alias' | 'userId' | 'canRemove' | 'relationName'>
      & { id: Parent['_id'] }
    )>>, siblings: Maybe<Array<(
      { __typename?: 'Student' }
      & Pick<Student, 'relationName' | 'fullName' | 'avatarUrl' | 'gender' | 'alias' | 'userId'>
      & { id: Student['_id'] }
    )>> }
  )> }
);

export type GetParentForLinkPeopleQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetParentForLinkPeopleQuery = (
  { __typename?: 'Query' }
  & { parent: Maybe<(
    { __typename?: 'Parent' }
    & { id: Parent['_id'] }
    & { relatedParents: Maybe<Array<(
      { __typename?: 'Parent' }
      & Pick<Parent, 'fullName' | 'avatarUrl' | 'gender' | 'userId' | 'canRemove' | 'relationName'>
      & { id: Parent['_id'] }
    )>>, relatedStudents: Maybe<Array<(
      { __typename?: 'Student' }
      & Pick<Student, 'fullName' | 'avatarUrl' | 'gender' | 'userId' | 'relationName'>
      & { id: Student['_id'] }
      & { primaryParent: Maybe<(
        { __typename?: 'Parent' }
        & { id: Parent['_id'] }
      )> }
    )>> }
  )> }
);

export type LinkParentMutationVariables = {
  input: LinkParentInput
};


export type LinkParentMutation = (
  { __typename?: 'Mutation' }
  & { linkParent: Maybe<(
    { __typename?: 'Relationship' }
    & Pick<Relationship, 'parentId1' | 'parentId2'>
  )> }
);

export type UnlinkParentMutationVariables = {
  input: LinkParentInput
};


export type UnlinkParentMutation = (
  { __typename?: 'Mutation' }
  & { unlinkParent: Maybe<(
    { __typename?: 'Relationship' }
    & Pick<Relationship, 'parentId1' | 'parentId2'>
  )> }
);

export type GetStudentsForLinkPeopleQueryVariables = {
  search?: Maybe<Scalars['String']>,
  filterInput?: Maybe<StudentFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetStudentsForLinkPeopleQuery = (
  { __typename?: 'Query' }
  & { students: Maybe<(
    { __typename?: 'StudentPage' }
    & Pick<StudentPage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'Student' }
      & Pick<Student, 'fullName' | 'alias' | 'avatarUrl' | 'gender'>
      & { id: Student['_id'] }
      & { primaryParent: Maybe<(
        { __typename?: 'Parent' }
        & { id: Parent['_id'] }
      )> }
    )> }
  )> }
);

export type ParentIndexFieldsFragment = (
  { __typename?: 'ParentIndex' }
  & Pick<ParentIndex, 'fullName' | 'gender' | 'salutation' | 'alias' | 'alternateUserId' | 'alternativeEmails' | 'whatsAppName' | 'email'>
  & { id: ParentIndex['_id'] }
  & { address: Maybe<(
    { __typename?: 'UserAddress' }
    & Pick<UserAddress, 'gateBuilding' | 'streetAddress' | 'unit' | 'country' | 'postalCode'>
  )>, mobile: (
    { __typename?: 'Telephone' }
    & TelephoneFieldsFragment
  ), homePhone: Maybe<(
    { __typename?: 'Telephone' }
    & TelephoneFieldsFragment
  )>, officePhone: Maybe<(
    { __typename?: 'Telephone' }
    & TelephoneFieldsFragment
  )>, students: Maybe<Array<(
    { __typename?: 'ParentStudentIndex' }
    & Pick<ParentStudentIndex, 'fullName'>
    & { id: ParentStudentIndex['_id'] }
  )>>, linkedWhatsappAccount: Maybe<(
    { __typename?: 'ParentWhatsappAccountIndex' }
    & { number: Maybe<(
      { __typename?: 'Telephone' }
      & Pick<Telephone, 'number' | 'countryCode'>
    )> }
  )> }
);

export type GetListParentsDataQueryVariables = {
  filterInput?: Maybe<ParentFilter>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetListParentsDataQuery = (
  { __typename?: 'Query' }
  & { parentsWithIndex: Maybe<(
    { __typename?: 'ParentIndexPage' }
    & Pick<ParentIndexPage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'ParentIndex' }
      & ParentIndexFieldsFragment
    )> }
  )> }
);

export type GetParentIndexQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetParentIndexQuery = (
  { __typename?: 'Query' }
  & { parentWithIndex: Maybe<(
    { __typename?: 'ParentIndex' }
    & ParentIndexFieldsFragment
  )> }
);

export type VoidParentMutationVariables = {
  input: VoidInput
};


export type VoidParentMutation = (
  { __typename?: 'Mutation' }
  & { voidParent: Maybe<(
    { __typename?: 'Parent' }
    & { id: Parent['_id'] }
  )> }
);

export type GetMergeAccountParentQueryVariables = {
  parent: Scalars['ObjectId']
};


export type GetMergeAccountParentQuery = (
  { __typename?: 'Query' }
  & { parent: Maybe<(
    { __typename?: 'Parent' }
    & Pick<Parent, 'fullName' | 'gender' | 'alias' | 'salutation' | 'userId' | 'email' | 'whatsAppName'>
    & { id: Parent['_id'] }
    & { address: Maybe<(
      { __typename?: 'UserAddress' }
      & Pick<UserAddress, 'gateBuilding' | 'streetAddress' | 'unit' | 'country' | 'postalCode'>
    )>, mobile: (
      { __typename?: 'Telephone' }
      & TelephoneFieldsFragment
    ), homePhone: Maybe<(
      { __typename?: 'Telephone' }
      & TelephoneFieldsFragment
    )>, officePhone: Maybe<(
      { __typename?: 'Telephone' }
      & TelephoneFieldsFragment
    )>, linkedWhatsappAccount: Maybe<(
      { __typename?: 'WhatsappAccount' }
      & { number: Maybe<(
        { __typename?: 'Telephone' }
        & Pick<Telephone, 'number' | 'countryCode'>
      )> }
    )>, relatedStudents: Maybe<Array<(
      { __typename?: 'Student' }
      & Pick<Student, 'alias' | 'userId' | 'avatarUrl' | 'fullName'>
      & { id: Student['_id'] }
    )>>, directlyRelatedStudents: Maybe<Array<(
      { __typename?: 'Student' }
      & { id: Student['_id'] }
    )>> }
  )> }
);

export type GetMergeOptionParentsQueryVariables = {
  filterInput?: Maybe<ParentFilter>
};


export type GetMergeOptionParentsQuery = (
  { __typename?: 'Query' }
  & { parents: Maybe<(
    { __typename?: 'ParentPage' }
    & { items: Array<(
      { __typename?: 'Parent' }
      & Pick<Parent, 'fullName' | 'userId'>
      & { id: Parent['_id'] }
    )> }
  )> }
);

export type MergeParentMutationVariables = {
  input: MergeParentInput
};


export type MergeParentMutation = (
  { __typename?: 'Mutation' }
  & { mergeParent: Maybe<(
    { __typename?: 'Parent' }
    & { id: Parent['_id'] }
  )> }
);

export type StudentIndexFieldsFragment = (
  { __typename?: 'StudentIndex' }
  & Pick<StudentIndex, 'fullName' | 'alias' | 'status' | 'avatarUrl' | 'email' | 'gender' | 'alternativeEmails' | 'birthDate' | 'userId'>
  & { id: StudentIndex['_id'] }
  & { mobile: Maybe<(
    { __typename?: 'Telephone' }
    & Pick<Telephone, 'countryCode' | 'number'>
  )>, primarySchool: Maybe<(
    { __typename?: 'StudentSchoolIndex' }
    & Pick<StudentSchoolIndex, 'name'>
  )>, secondarySchool: Maybe<(
    { __typename?: 'StudentSchoolIndex' }
    & Pick<StudentSchoolIndex, 'name'>
  )>, academicLevel: Maybe<(
    { __typename?: 'StudentAcademicLevelIndex' }
    & Pick<StudentAcademicLevelIndex, 'name'>
  )>, address: Maybe<(
    { __typename?: 'UserAddress' }
    & Pick<UserAddress, 'country' | 'gateBuilding' | 'link' | 'postalCode' | 'streetAddress' | 'unit'>
  )>, primaryParent: Maybe<(
    { __typename?: 'StudentParentIndex' }
    & Pick<StudentParentIndex, 'fullName' | 'email' | 'userId'>
    & { id: StudentParentIndex['_id'] }
    & { mobile: (
      { __typename?: 'Telephone' }
      & Pick<Telephone, 'countryCode' | 'number'>
    ) }
  )>, primaryParentWhatsappAccount: Maybe<(
    { __typename?: 'StudentWhatsappAccountIndex' }
    & { id: StudentWhatsappAccountIndex['_id'] }
    & { number: Maybe<(
      { __typename?: 'Telephone' }
      & Pick<Telephone, 'number' | 'countryCode'>
    )> }
  )>, secondaryParents: Maybe<Array<(
    { __typename?: 'StudentParentIndex' }
    & Pick<StudentParentIndex, 'fullName' | 'email' | 'userId'>
    & { id: StudentParentIndex['_id'] }
    & { mobile: (
      { __typename?: 'Telephone' }
      & Pick<Telephone, 'countryCode' | 'number'>
    ) }
  )>>, secondaryParentWhatsappAccounts: Maybe<Array<(
    { __typename?: 'StudentWhatsappAccountIndex' }
    & { id: StudentWhatsappAccountIndex['_id'] }
    & { number: Maybe<(
      { __typename?: 'Telephone' }
      & Pick<Telephone, 'number' | 'countryCode'>
    )> }
  )>>, siblings: Maybe<Array<(
    { __typename?: 'StudentSiblingIndex' }
    & Pick<StudentSiblingIndex, 'fullName'>
  )>>, enrollments: Maybe<Array<(
    { __typename?: 'StudentEnrollmentIndex' }
    & Pick<StudentEnrollmentIndex, 'type'>
  )>>, trialAttendances: Maybe<Array<(
    { __typename?: 'StudentAttendanceIndex' }
    & Pick<StudentAttendanceIndex, 'startDateTime'>
    & { id: StudentAttendanceIndex['_id'] }
  )>>, examScores: Maybe<Array<(
    { __typename?: 'StudentExamScoreIndex' }
    & Pick<StudentExamScoreIndex, 'marksPercentage' | 'year' | 'subject' | 'createDateTime'>
    & { id: StudentExamScoreIndex['_id'] }
  )>> }
);

export type GetListStudentsWithIndexDataQueryVariables = {
  filterInput?: Maybe<StudentFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetListStudentsWithIndexDataQuery = (
  { __typename?: 'Query' }
  & { studentsWithIndex: Maybe<(
    { __typename?: 'StudentIndexPage' }
    & Pick<StudentIndexPage, 'total' | 'pageIndex' | 'pageSize'>
    & { items: Array<(
      { __typename?: 'StudentIndex' }
      & StudentIndexFieldsFragment
    )> }
  )> }
);

export type GetListStudentsDataQueryVariables = {
  search?: Maybe<Scalars['String']>,
  filterInput?: Maybe<StudentFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetListStudentsDataQuery = (
  { __typename?: 'Query' }
  & { students: Maybe<(
    { __typename?: 'StudentPage' }
    & Pick<StudentPage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'Student' }
      & Pick<Student, 'fullName' | 'alias' | 'status' | 'avatarUrl' | 'email' | 'gender' | 'alternativeEmails' | 'birthDate' | 'userId'>
      & { id: Student['_id'] }
      & { mobile: Maybe<(
        { __typename?: 'Telephone' }
        & Pick<Telephone, 'countryCode' | 'number'>
      )>, primarySchool: Maybe<(
        { __typename?: 'School' }
        & Pick<School, 'name'>
      )>, secondarySchool: Maybe<(
        { __typename?: 'School' }
        & Pick<School, 'name'>
      )>, academicLevel: Maybe<(
        { __typename?: 'AcademicLevel' }
        & Pick<AcademicLevel, 'name' | 'educationStage'>
      )>, address: Maybe<(
        { __typename?: 'UserAddress' }
        & Pick<UserAddress, 'country' | 'gateBuilding' | 'link' | 'postalCode' | 'streetAddress' | 'unit'>
      )>, primaryParent: Maybe<(
        { __typename?: 'Parent' }
        & Pick<Parent, 'fullName' | 'email' | 'userId'>
        & { id: Parent['_id'] }
        & { mobile: (
          { __typename?: 'Telephone' }
          & Pick<Telephone, 'countryCode' | 'number'>
        ), linkedWhatsappAccount: Maybe<(
          { __typename?: 'WhatsappAccount' }
          & { number: Maybe<(
            { __typename?: 'Telephone' }
            & Pick<Telephone, 'number' | 'countryCode'>
          )> }
        )>, relatedParents: Maybe<Array<(
          { __typename?: 'Parent' }
          & Pick<Parent, 'fullName' | 'email'>
          & { id: Parent['_id'] }
          & { mobile: (
            { __typename?: 'Telephone' }
            & Pick<Telephone, 'countryCode' | 'number'>
          ), linkedWhatsappAccount: Maybe<(
            { __typename?: 'WhatsappAccount' }
            & { number: Maybe<(
              { __typename?: 'Telephone' }
              & Pick<Telephone, 'number' | 'countryCode'>
            )> }
          )> }
        )>> }
      )>, siblings: Maybe<Array<(
        { __typename?: 'Student' }
        & Pick<Student, 'fullName'>
        & { id: Student['_id'] }
      )>>, parents: Maybe<Array<(
        { __typename?: 'Parent' }
        & Pick<Parent, 'fullName' | 'email'>
        & { id: Parent['_id'] }
        & { mobile: (
          { __typename?: 'Telephone' }
          & Pick<Telephone, 'countryCode' | 'number'>
        ) }
      )>>, enrollments: Maybe<Array<(
        { __typename?: 'Enrollment' }
        & Pick<Enrollment, 'type' | 'startDate'>
        & { class: (
          { __typename?: 'Class' }
          & Pick<Class, '_id' | 'name'>
        ) }
      )>>, registrations: Maybe<Array<(
        { __typename?: 'Registration' }
        & Pick<Registration, 'type' | 'startDate'>
      )>>, examScores: Maybe<Array<(
        { __typename?: 'ExamScore' }
        & Pick<ExamScore, 'year' | 'subject' | 'marksPercentage' | 'createDateTime'>
        & { id: ExamScore['_id'] }
      )>>, remarks: Maybe<Array<(
        { __typename?: 'Remark' }
        & RemarkFieldsFragment
      ) | { __typename?: 'StudentRemarks' }>> }
    )> }
  )> }
);

export type GetListStudentFilterOptionsQueryVariables = {};


export type GetListStudentFilterOptionsQuery = (
  { __typename?: 'Query' }
  & { academicYears: Maybe<Array<(
    { __typename?: 'AcademicYear' }
    & Pick<AcademicYear, 'year'>
    & { id: AcademicYear['_id'] }
    & { academicWeeks: Array<(
      { __typename?: 'AcademicWeekWithId' }
      & Pick<AcademicWeekWithId, 'startDate' | 'endDate' | 'weekNumber'>
    )> }
  )>>, branches: Maybe<Array<(
    { __typename?: 'Branch' }
    & Pick<Branch, 'name' | 'alias'>
    & { id: Branch['_id'] }
    & { venues: Maybe<Array<(
      { __typename?: 'Venue' }
      & BasicVenueFieldsFragment
    )>> }
  )>> }
);

export type VoidStudentMutationVariables = {
  input: VoidInput
};


export type VoidStudentMutation = (
  { __typename?: 'Mutation' }
  & { voidStudent: Maybe<(
    { __typename?: 'Student' }
    & { id: Student['_id'] }
  )> }
);

export type GetMergeAccountStudentQueryVariables = {
  studentId: Scalars['ObjectId'],
  input?: Maybe<StudentEnrollmentFilterInput>
};


export type GetMergeAccountStudentQuery = (
  { __typename?: 'Query' }
  & { student: Maybe<(
    { __typename?: 'Student' }
    & Pick<Student, 'alias' | 'gender' | 'fullName' | 'email' | 'birthDate' | 'userId' | 'secondaryStream'>
    & { id: Student['_id'] }
    & { mobile: Maybe<(
      { __typename?: 'Telephone' }
      & Pick<Telephone, 'countryCode' | 'number'>
    )>, homePhone: Maybe<(
      { __typename?: 'Telephone' }
      & Pick<Telephone, 'countryCode' | 'number'>
    )>, address: Maybe<(
      { __typename?: 'UserAddress' }
      & Pick<UserAddress, 'gateBuilding' | 'streetAddress' | 'unit' | 'country' | 'postalCode'>
    )>, primaryParent: Maybe<(
      { __typename?: 'Parent' }
      & Pick<Parent, 'avatarUrl' | 'userId' | 'salutation' | 'fullName'>
      & { id: Parent['_id'] }
      & { relatedParents: Maybe<Array<(
        { __typename?: 'Parent' }
        & Pick<Parent, 'avatarUrl' | 'userId' | 'salutation' | 'fullName'>
        & { id: Parent['_id'] }
      )>> }
    )>, academicLevel: Maybe<(
      { __typename?: 'AcademicLevel' }
      & Pick<AcademicLevel, 'name' | 'educationStage'>
    )>, primarySchool: Maybe<(
      { __typename?: 'School' }
      & Pick<School, 'name'>
    )>, secondarySchool: Maybe<(
      { __typename?: 'School' }
      & Pick<School, 'name'>
    )>, enrollments: Maybe<Array<(
      { __typename?: 'Enrollment' }
      & Pick<Enrollment, 'startDate' | 'endDate'>
      & { id: Enrollment['_id'] }
      & { class: (
        { __typename?: 'Class' }
        & Pick<Class, 'name' | 'shorthand' | 'type' | 'runNumber'>
        & { id: Class['_id'] }
        & { teacher: Maybe<(
          { __typename?: 'SystemUser' }
          & Pick<SystemUser, 'avatarUrl'>
        )>, course: (
          { __typename?: 'Course' }
          & Pick<Course, 'colorCode' | 'nameShort'>
        ) }
      ) }
    )>> }
  )> }
);

export type GetOptionStudentsQueryVariables = {
  filterInput?: Maybe<StudentFilterInput>
};


export type GetOptionStudentsQuery = (
  { __typename?: 'Query' }
  & { students: Maybe<(
    { __typename?: 'StudentPage' }
    & { items: Array<(
      { __typename?: 'Student' }
      & Pick<Student, 'fullName' | 'userId'>
      & { id: Student['_id'] }
    )> }
  )> }
);

export type MergeStudentMutationVariables = {
  input: MergeStudentInput
};


export type MergeStudentMutation = (
  { __typename?: 'Mutation' }
  & { mergeStudent: Maybe<(
    { __typename?: 'Student' }
    & { id: Student['_id'] }
  )> }
);

export type CreateStudentReportFilterMutationVariables = {
  input: StudentReportFilterInput
};


export type CreateStudentReportFilterMutation = (
  { __typename?: 'Mutation' }
  & { createStudentReportFilter: Maybe<(
    { __typename?: 'StudentReportFilter' }
    & StudentReportFilterFragmentFragment
  )> }
);

export type GetAcademicYearQueryVariables = {
  year: Scalars['Int']
};


export type GetAcademicYearQuery = (
  { __typename?: 'Query' }
  & { academicYear: Maybe<(
    { __typename?: 'AcademicYear' }
    & Pick<AcademicYear, 'year' | 'academicWeekStart' | 'academicWeekEnd'>
    & { id: AcademicYear['_id'] }
    & { period: (
      { __typename?: 'DateTimeRange' }
      & Pick<DateTimeRange, 'start' | 'end'>
    ), academicWeeks: Array<(
      { __typename?: 'AcademicWeekWithId' }
      & Pick<AcademicWeekWithId, 'startDate' | 'endDate' | 'weekNumber'>
    )> }
  )> }
);

export type StudentReportFiltersQueryVariables = {
  userId: Scalars['ObjectId']
};


export type StudentReportFiltersQuery = (
  { __typename?: 'Query' }
  & { studentReportFilters: Maybe<Array<(
    { __typename?: 'StudentReportFilter' }
    & StudentReportFilterFragmentFragment
  )>> }
);

export type ExportStudentReportCsvQueryVariables = {
  filter: StudentReportFilterInput
};


export type ExportStudentReportCsvQuery = (
  { __typename?: 'Query' }
  & { exportStudentReportCsv: Maybe<(
    { __typename?: 'Csv' }
    & Pick<Csv, 'url'>
  )> }
);

export type DeleteStudentReportFilterMutationVariables = {
  id: Scalars['ObjectId']
};


export type DeleteStudentReportFilterMutation = (
  { __typename?: 'Mutation' }
  & { deleteStudentReportFilter: Maybe<(
    { __typename?: 'StudentReportFilter' }
    & StudentReportFilterFragmentFragment
  )> }
);

export type UpdateStudentReportFilterMutationVariables = {
  input: UpdateStudentReportFilterInput
};


export type UpdateStudentReportFilterMutation = (
  { __typename?: 'Mutation' }
  & { updateStudentReportFilter: Maybe<(
    { __typename?: 'StudentReportFilter' }
    & StudentReportFilterFragmentFragment
  )> }
);

export type StudentReportFilterFragmentFragment = (
  { __typename?: 'StudentReportFilter' }
  & Pick<StudentReportFilter, '_id' | 'name' | 'show' | 'dateRangeType' | 'lessonDays' | 'createDateTime' | 'lastModifiedDateTime' | 'isDeleted' | 'deletedAt'>
  & { dateRange: (
    { __typename?: 'DateTimeRange' }
    & Pick<DateTimeRange, 'start' | 'end'>
  ), user: Maybe<(
    { __typename?: 'SystemUser' }
    & UserFieldsFragment
  )>, courses: Maybe<Array<(
    { __typename?: 'Course' }
    & Pick<Course, 'name'>
    & { id: Course['_id'] }
  )>>, academicYears: Maybe<Array<(
    { __typename?: 'AcademicYear' }
    & Pick<AcademicYear, 'year'>
    & { id: AcademicYear['_id'] }
  )>>, teachers: Maybe<Array<(
    { __typename?: 'SystemUser' }
    & Pick<SystemUser, 'fullName'>
    & { id: SystemUser['_id'] }
  )>>, venues: Maybe<Array<(
    { __typename?: 'Venue' }
    & BasicVenueFieldsFragment
  )>>, createdBy: Maybe<(
    { __typename?: 'SystemUser' }
    & UserFieldsFragment
  )>, lastModifiedBy: Maybe<(
    { __typename?: 'SystemUser' }
    & UserFieldsFragment
  )> }
);

export type GetStudentReportsQueryVariables = {
  pageInfo?: Maybe<PageInfo>,
  filter: StudentReportFilterInput
};


export type GetStudentReportsQuery = (
  { __typename?: 'Query' }
  & { studentReports: Maybe<(
    { __typename?: 'StudentPage' }
    & Pick<StudentPage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'Student' }
      & Pick<Student, 'fullName' | 'avatarUrl' | 'birthDate' | 'email' | 'gender' | 'alias' | 'userId' | 'primaryStream' | 'secondaryStream'>
      & { id: Student['_id'] }
      & { address: Maybe<(
        { __typename?: 'UserAddress' }
        & Pick<UserAddress, 'country' | 'streetAddress' | 'gateBuilding' | 'postalCode' | 'link' | 'unit'>
      )>, primarySchool: Maybe<(
        { __typename?: 'School' }
        & Pick<School, 'name'>
      )>, secondarySchool: Maybe<(
        { __typename?: 'School' }
        & Pick<School, 'name'>
      )>, academicLevel: Maybe<(
        { __typename?: 'AcademicLevel' }
        & Pick<AcademicLevel, 'alias'>
      )>, primaryParent: Maybe<(
        { __typename?: 'Parent' }
        & Pick<Parent, 'fullName' | 'salutation' | 'alias' | 'userId' | 'email'>
        & { id: Parent['_id'] }
        & { mobile: (
          { __typename?: 'Telephone' }
          & Pick<Telephone, 'countryCode' | 'number'>
        ), homePhone: Maybe<(
          { __typename?: 'Telephone' }
          & Pick<Telephone, 'countryCode' | 'number'>
        )>, linkedWhatsappAccount: Maybe<(
          { __typename?: 'WhatsappAccount' }
          & { number: Maybe<(
            { __typename?: 'Telephone' }
            & Pick<Telephone, 'number' | 'countryCode'>
          )> }
        )> }
      )>, parents: Maybe<Array<(
        { __typename?: 'Parent' }
        & Pick<Parent, 'fullName' | 'salutation' | 'alias' | 'email' | 'userId'>
        & { id: Parent['_id'] }
        & { mobile: (
          { __typename?: 'Telephone' }
          & Pick<Telephone, 'countryCode' | 'number'>
        ), linkedWhatsappAccount: Maybe<(
          { __typename?: 'WhatsappAccount' }
          & { number: Maybe<(
            { __typename?: 'Telephone' }
            & Pick<Telephone, 'number' | 'countryCode'>
          )> }
        )> }
      )>>, siblings: Maybe<Array<(
        { __typename?: 'Student' }
        & Pick<Student, 'fullName'>
      )>>, enrollments: Maybe<Array<(
        { __typename?: 'Enrollment' }
        & Pick<Enrollment, 'group'>
        & { class: (
          { __typename?: 'Class' }
          & Pick<Class, 'name' | 'learningArrangement'>
          & { teacher: Maybe<(
            { __typename?: 'SystemUser' }
            & Pick<SystemUser, 'fullName' | 'salutation'>
            & { id: SystemUser['_id'] }
          )> }
        ) }
        & BasicEnrollmentFieldFragment
      )>> }
    )> }
  )> }
);

export type CentreAssignmentsQueryVariables = {
  studentId: Scalars['ObjectId']
};


export type CentreAssignmentsQuery = (
  { __typename?: 'Query' }
  & { centreAssignments: Maybe<Array<(
    { __typename?: 'CentreAssignments' }
    & { gradable: Maybe<(
      { __typename?: 'Exercise' }
      & Pick<Exercise, '_id' | 'lessonNumber' | 'totalGrade'>
      & { workSheet: (
        { __typename?: 'WorkSheet' }
        & Pick<WorkSheet, 'title' | 'isArchived' | 'createDateTime'>
        & { topic: Maybe<(
          { __typename?: 'Topic' }
          & Pick<Topic, 'name'>
        )> }
      ), totalBreakdown: Maybe<(
        { __typename?: 'MarkBreakDown' }
        & Pick<MarkBreakDown, 'ecq' | 'mcq'>
      )> }
    ) | (
      { __typename?: 'Homework' }
      & Pick<Homework, '_id' | 'lessonNumber' | 'totalGrade'>
      & { workSheet: (
        { __typename?: 'WorkSheet' }
        & Pick<WorkSheet, 'title' | 'isArchived' | 'createDateTime'>
        & { topic: Maybe<(
          { __typename?: 'Topic' }
          & Pick<Topic, 'name'>
        )> }
      ), totalBreakdown: Maybe<(
        { __typename?: 'MarkBreakDown' }
        & Pick<MarkBreakDown, 'ecq' | 'mcq'>
      )> }
    )>, submission: Maybe<(
      { __typename?: 'Submission' }
      & Pick<Submission, 'grade'>
      & { id: Submission['_id'] }
      & { enrollment: Maybe<(
        { __typename?: 'Enrollment' }
        & { id: Enrollment['_id'] }
      )>, class: Maybe<(
        { __typename?: 'Class' }
        & { id: Class['_id'] }
      )>, breakdown: Maybe<(
        { __typename?: 'MarkBreakDown' }
        & Pick<MarkBreakDown, 'ecq' | 'mcq'>
      )>, gradable: Maybe<(
        { __typename?: 'Exercise' }
        & { id: Exercise['_id'] }
      ) | (
        { __typename?: 'Homework' }
        & { id: Homework['_id'] }
      )> }
    )>, classScores: Maybe<(
      { __typename?: 'ScoreStats' }
      & Pick<ScoreStats, 'max' | 'min' | 'average'>
    )>, levelScores: Maybe<(
      { __typename?: 'ScoreStats' }
      & Pick<ScoreStats, 'max' | 'min' | 'average'>
    )> }
  )>> }
);

export type GetStudentEnrollmentTabDataQueryVariables = {
  id: Scalars['ObjectId'],
  filterInput?: Maybe<StudentEnrollmentFilterInput>
};


export type GetStudentEnrollmentTabDataQuery = (
  { __typename?: 'Query' }
  & { student: Maybe<(
    { __typename?: 'Student' }
    & { academicLevel: Maybe<(
      { __typename?: 'AcademicLevel' }
      & { id: AcademicLevel['_id'] }
    )>, enrollments: Maybe<Array<(
      { __typename?: 'Enrollment' }
      & Pick<Enrollment, '_id' | 'group' | 'type' | 'status' | 'startDate' | 'endDate' | 'preferredLearningArrangement' | 'isFullEnrollment' | 'isEndRecurring'>
      & { attendances: Maybe<Array<(
        { __typename?: 'Attendance' }
        & Pick<Attendance, 'type' | 'status'>
        & { lesson: (
          { __typename?: 'Lesson' }
          & Pick<Lesson, 'lessonStart' | 'lessonEnd' | 'lessonNumber'>
          & { id: Lesson['_id'] }
        ) }
      )>>, class: (
        { __typename?: 'Class' }
        & Pick<Class, 'name' | 'type' | 'defaultLessonDays' | 'defaultLessonStart' | 'defaultLessonEnd' | 'learningArrangement'>
        & { id: Class['_id'] }
        & { dailySessionTimeSlots: Maybe<Array<(
          { __typename?: 'TimeRange' }
          & Pick<TimeRange, 'start' | 'end'>
        )>>, term: Maybe<(
          { __typename?: 'Term' }
          & { term1Period: (
            { __typename?: 'DateTimeRange' }
            & Pick<DateTimeRange, 'start' | 'end'>
          ), term2Period: (
            { __typename?: 'DateTimeRange' }
            & Pick<DateTimeRange, 'start' | 'end'>
          ), term3Period: (
            { __typename?: 'DateTimeRange' }
            & Pick<DateTimeRange, 'start' | 'end'>
          ), term4Period: (
            { __typename?: 'DateTimeRange' }
            & Pick<DateTimeRange, 'start' | 'end'>
          ) }
        )>, academicYear: (
          { __typename?: 'AcademicYear' }
          & Pick<AcademicYear, 'year'>
          & { id: AcademicYear['_id'] }
          & { holidays: Maybe<Array<(
            { __typename?: 'Holiday' }
            & { id: Holiday['_id'] }
          )>> }
        ), digitalClassAccessCredentials: Maybe<(
          { __typename?: 'DigitalClassAccessCredentials' }
          & Pick<DigitalClassAccessCredentials, 'zoomMeetingLink' | 'zoomMeetingPassword' | 'zoomMeetingId' | 'googleMeetLink' | 'googleMeetId'>
        )>, course: (
          { __typename?: 'Course' }
          & Pick<Course, 'name' | 'avatarUrl' | 'type' | 'colorCode'>
          & { id: Course['_id'] }
        ), teacher: Maybe<(
          { __typename?: 'SystemUser' }
          & Pick<SystemUser, 'fullName' | 'salutation' | 'gender' | 'avatarUrl' | 'initials'>
          & { id: SystemUser['_id'] }
        )>, venue: Maybe<(
          { __typename?: 'Venue' }
          & Pick<Venue, 'shorthand' | 'unitNo'>
          & { branch: Maybe<(
            { __typename?: 'Branch' }
            & Pick<Branch, 'name' | 'alias'>
          )> }
        )>, capacity: Maybe<(
          { __typename?: 'ClassCapacityDTO' }
          & CapacityFieldsFragment
        )>, enrollments: Maybe<Array<(
          { __typename?: 'Enrollment' }
          & Pick<Enrollment, '_id' | 'type'>
        )>> }
      ), invoices: Maybe<Array<(
        { __typename?: 'Invoice' }
        & Pick<Invoice, 'referenceNumber' | 'status'>
        & { id: Invoice['_id'] }
        & { billedTo: Maybe<(
          { __typename?: 'Parent' }
          & { id: Parent['_id'] }
        )> }
      )>>, registration: Maybe<(
        { __typename?: 'Registration' }
        & Pick<Registration, 'dueDate'>
      )>, arrangements: Maybe<Array<(
        { __typename?: 'Arrangement' }
        & Pick<Arrangement, 'type' | 'status' | 'enrollmentId'>
        & { id: Arrangement['_id'] }
      )>> }
    )>> }
  )> }
);

export type GetStudentOnEligibleCourseTabQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetStudentOnEligibleCourseTabQuery = (
  { __typename?: 'Query' }
  & { student: Maybe<(
    { __typename?: 'Student' }
    & { academicLevel: Maybe<(
      { __typename?: 'AcademicLevel' }
      & { id: AcademicLevel['_id'] }
    )> }
  )> }
);

export type GetCoursesOnStudentTabQueryVariables = {
  search?: Maybe<Scalars['String']>,
  filterInput?: Maybe<CourseFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>,
  classStatus?: Maybe<Array<ClassStatus>>,
  academicYearId?: Maybe<Scalars['ObjectId']>
};


export type GetCoursesOnStudentTabQuery = (
  { __typename?: 'Query' }
  & { courses: Maybe<(
    { __typename?: 'CoursePage' }
    & Pick<CoursePage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'Course' }
      & Pick<Course, 'name' | 'type' | 'status' | 'avatarUrl'>
      & { id: Course['_id'] }
      & { academicLevels: Array<(
        { __typename?: 'AcademicLevel' }
        & { id: AcademicLevel['_id'] }
      )>, classes: Maybe<Array<(
        { __typename?: 'Class' }
        & Pick<Class, 'name'>
        & { id: Class['_id'] }
        & { teacher: Maybe<(
          { __typename?: 'SystemUser' }
          & Pick<SystemUser, 'avatarUrl' | 'fullName' | 'salutation'>
        )> }
      )>> }
    )> }
  )> }
);

export type GetStudentReservedOnWaitRegistrationDataQueryVariables = {
  id: Scalars['ObjectId'],
  filterInput?: Maybe<StudentEnrollmentFilterInput>
};


export type GetStudentReservedOnWaitRegistrationDataQuery = (
  { __typename?: 'Query' }
  & { student: Maybe<(
    { __typename?: 'Student' }
    & { waitingLists: Maybe<Array<(
      { __typename?: 'Registration' }
      & Pick<Registration, 'startDate'>
      & { class: Maybe<(
        { __typename?: 'Class' }
        & Pick<Class, 'name' | 'defaultLessonDays' | 'defaultLessonStart' | 'defaultLessonEnd' | 'learningArrangement'>
        & { id: Class['_id'] }
        & { term: Maybe<(
          { __typename?: 'Term' }
          & { term1Period: (
            { __typename?: 'DateTimeRange' }
            & Pick<DateTimeRange, 'start' | 'end'>
          ), term2Period: (
            { __typename?: 'DateTimeRange' }
            & Pick<DateTimeRange, 'start' | 'end'>
          ), term3Period: (
            { __typename?: 'DateTimeRange' }
            & Pick<DateTimeRange, 'start' | 'end'>
          ), term4Period: (
            { __typename?: 'DateTimeRange' }
            & Pick<DateTimeRange, 'start' | 'end'>
          ) }
        )>, academicYear: (
          { __typename?: 'AcademicYear' }
          & Pick<AcademicYear, 'year'>
          & { id: AcademicYear['_id'] }
          & { holidays: Maybe<Array<(
            { __typename?: 'Holiday' }
            & { id: Holiday['_id'] }
          )>> }
        ), digitalClassAccessCredentials: Maybe<(
          { __typename?: 'DigitalClassAccessCredentials' }
          & Pick<DigitalClassAccessCredentials, 'zoomMeetingLink' | 'zoomMeetingPassword' | 'zoomMeetingId' | 'googleMeetLink' | 'googleMeetId'>
        )>, course: (
          { __typename?: 'Course' }
          & Pick<Course, 'name' | 'avatarUrl' | 'type'>
          & { id: Course['_id'] }
        ), lessons: Maybe<Array<(
          { __typename?: 'Lesson' }
          & Pick<Lesson, 'lessonStart' | 'lessonEnd' | 'lessonNumber'>
          & { id: Lesson['_id'] }
        )>>, teacher: Maybe<(
          { __typename?: 'SystemUser' }
          & Pick<SystemUser, 'fullName' | 'salutation' | 'gender' | 'avatarUrl' | 'initials'>
          & { id: SystemUser['_id'] }
        )>, venue: Maybe<(
          { __typename?: 'Venue' }
          & Pick<Venue, 'shorthand' | 'unitNo'>
          & { branch: Maybe<(
            { __typename?: 'Branch' }
            & Pick<Branch, 'name' | 'alias'>
          )> }
        )>, capacity: Maybe<(
          { __typename?: 'ClassCapacityDTO' }
          & CapacityFieldsFragment
        )>, enrollments: Maybe<Array<(
          { __typename?: 'Enrollment' }
          & Pick<Enrollment, '_id' | 'type'>
        )>> }
      )> }
    )>>, enrollments: Maybe<Array<(
      { __typename?: 'Enrollment' }
      & Pick<Enrollment, '_id' | 'group' | 'type' | 'status' | 'startDate' | 'endDate' | 'preferredLearningArrangement'>
      & { class: (
        { __typename?: 'Class' }
        & Pick<Class, 'name' | 'defaultLessonDays' | 'defaultLessonStart' | 'defaultLessonEnd' | 'learningArrangement'>
        & { id: Class['_id'] }
        & { term: Maybe<(
          { __typename?: 'Term' }
          & { term1Period: (
            { __typename?: 'DateTimeRange' }
            & Pick<DateTimeRange, 'start' | 'end'>
          ), term2Period: (
            { __typename?: 'DateTimeRange' }
            & Pick<DateTimeRange, 'start' | 'end'>
          ), term3Period: (
            { __typename?: 'DateTimeRange' }
            & Pick<DateTimeRange, 'start' | 'end'>
          ), term4Period: (
            { __typename?: 'DateTimeRange' }
            & Pick<DateTimeRange, 'start' | 'end'>
          ) }
        )>, academicYear: (
          { __typename?: 'AcademicYear' }
          & Pick<AcademicYear, 'year'>
          & { id: AcademicYear['_id'] }
          & { holidays: Maybe<Array<(
            { __typename?: 'Holiday' }
            & { id: Holiday['_id'] }
          )>> }
        ), digitalClassAccessCredentials: Maybe<(
          { __typename?: 'DigitalClassAccessCredentials' }
          & Pick<DigitalClassAccessCredentials, 'zoomMeetingLink' | 'zoomMeetingPassword' | 'zoomMeetingId' | 'googleMeetLink' | 'googleMeetId'>
        )>, course: (
          { __typename?: 'Course' }
          & Pick<Course, 'name' | 'avatarUrl' | 'type' | 'colorCode'>
          & { id: Course['_id'] }
        ), lessons: Maybe<Array<(
          { __typename?: 'Lesson' }
          & Pick<Lesson, 'lessonStart' | 'lessonEnd' | 'lessonNumber'>
          & { id: Lesson['_id'] }
        )>>, teacher: Maybe<(
          { __typename?: 'SystemUser' }
          & Pick<SystemUser, 'fullName' | 'salutation' | 'gender' | 'avatarUrl' | 'initials'>
          & { id: SystemUser['_id'] }
        )>, venue: Maybe<(
          { __typename?: 'Venue' }
          & Pick<Venue, 'shorthand' | 'unitNo'>
          & { branch: Maybe<(
            { __typename?: 'Branch' }
            & Pick<Branch, 'name' | 'alias'>
          )> }
        )>, capacity: Maybe<(
          { __typename?: 'ClassCapacityDTO' }
          & CapacityFieldsFragment
        )>, enrollments: Maybe<Array<(
          { __typename?: 'Enrollment' }
          & Pick<Enrollment, '_id' | 'type'>
        )>> }
      ), registration: Maybe<(
        { __typename?: 'Registration' }
        & Pick<Registration, 'startDate' | 'dueDate'>
      )> }
    )>> }
  )> }
);

export type GetStudentWaitingEnrollmentQueryVariables = {
  id: Scalars['ObjectId'],
  filterInput?: Maybe<StudentEnrollmentFilterInput>
};


export type GetStudentWaitingEnrollmentQuery = (
  { __typename?: 'Query' }
  & { student: Maybe<(
    { __typename?: 'Student' }
    & { enrollments: Maybe<Array<(
      { __typename?: 'Enrollment' }
      & Pick<Enrollment, 'preferredLearningArrangement' | 'group'>
      & { class: (
        { __typename?: 'Class' }
        & ArrangementClassCardFieldsFragment
      ), registration: Maybe<(
        { __typename?: 'Registration' }
        & Pick<Registration, 'status' | 'dueDate'>
      )> }
    )>> }
  )> }
);

export type MerchandiseFieldsFragment = (
  { __typename?: 'Merchandise' }
  & Pick<Merchandise, 'item' | 'distributionChannel' | 'dateIssued' | 'status' | 'remarks'>
  & { id: Merchandise['_id'] }
);

export type CreateMerchandiseMutationVariables = {
  input: CreateMerchandiseInput
};


export type CreateMerchandiseMutation = (
  { __typename?: 'Mutation' }
  & { createMerchandise: Maybe<(
    { __typename?: 'Merchandise' }
    & MerchandiseFieldsFragment
  )> }
);

export type UpdateMerchandiseMutationVariables = {
  input: UpdateMerchandiseInput
};


export type UpdateMerchandiseMutation = (
  { __typename?: 'Mutation' }
  & { updateMerchandise: Maybe<(
    { __typename?: 'Merchandise' }
    & MerchandiseFieldsFragment
  )> }
);

export type DeleteMerchandiseMutationVariables = {
  input: IdInput
};


export type DeleteMerchandiseMutation = (
  { __typename?: 'Mutation' }
  & { deleteMerchandise: Maybe<(
    { __typename?: 'Merchandise' }
    & MerchandiseFieldsFragment
  )> }
);

export type GetStudentMerchandiseQueryVariables = {
  studentId: Scalars['ObjectId'],
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetStudentMerchandiseQuery = (
  { __typename?: 'Query' }
  & { studentMerchandise: Maybe<(
    { __typename?: 'MerchandisePage' }
    & Pick<MerchandisePage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'Merchandise' }
      & MerchandiseFieldsFragment
    )> }
  )> }
);

export type GetStudentProfileQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetStudentProfileQuery = (
  { __typename?: 'Query' }
  & { student: Maybe<(
    { __typename?: 'Student' }
    & { primaryParent: Maybe<(
      { __typename?: 'Parent' }
      & Pick<Parent, 'fullName' | 'salutation' | 'alias' | 'email' | 'whatsAppName' | 'latestStudentNumber'>
      & { id: Parent['_id'] }
      & { mobile: (
        { __typename?: 'Telephone' }
        & Pick<Telephone, 'countryCode' | 'number'>
      ) }
    )> }
    & StudentFieldsFragment
  )> }
);

export type RegisterStudentMutationVariables = {
  student: RegisterStudentInput
};


export type RegisterStudentMutation = (
  { __typename?: 'Mutation' }
  & { registerStudent: Maybe<(
    { __typename?: 'Student' }
    & Pick<Student, 'fullName' | 'gender'>
    & { id: Student['_id'] }
    & { primarySchool: Maybe<(
      { __typename?: 'School' }
      & Pick<School, 'name'>
      & { id: School['_id'] }
    )> }
  )> }
);

export type UpdateAttendanceLessonModeMutationVariables = {
  input: UpdateAttendanceLessonModeInput
};


export type UpdateAttendanceLessonModeMutation = (
  { __typename?: 'Mutation' }
  & { updateAttendanceLessonMode: Maybe<(
    { __typename?: 'Attendance' }
    & Pick<Attendance, 'lessonMode'>
    & { id: Attendance['_id'] }
  )> }
);

export type UpdateEnrollmentGroupMutationVariables = {
  input: UpdateEnrollmentGroupInput
};


export type UpdateEnrollmentGroupMutation = (
  { __typename?: 'Mutation' }
  & { updateEnrollmentGroup: Maybe<(
    { __typename?: 'Enrollment' }
    & Pick<Enrollment, 'group'>
    & { id: Enrollment['_id'] }
  )> }
);

export type QueryStudentsQueryVariables = {
  search?: Maybe<Scalars['String']>,
  filterInput?: Maybe<StudentFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type QueryStudentsQuery = (
  { __typename?: 'Query' }
  & { students: Maybe<(
    { __typename?: 'StudentPage' }
    & Pick<StudentPage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'Student' }
      & StudentFieldsFragment
    )> }
  )> }
);

export type QueryStudentsOfClassQueryVariables = {
  classId: Scalars['ObjectId']
};


export type QueryStudentsOfClassQuery = (
  { __typename?: 'Query' }
  & { class: Maybe<(
    { __typename?: 'Class' }
    & Pick<Class, 'name'>
    & { id: Class['_id'] }
    & { enrollments: Maybe<Array<(
      { __typename?: 'Enrollment' }
      & Pick<Enrollment, 'type'>
      & { student: Maybe<(
        { __typename?: 'Student' }
        & StudentFieldsFragment
      )> }
    )>>, course: (
      { __typename?: 'Course' }
      & Pick<Course, 'name' | 'description'>
      & { id: Course['_id'] }
    ), lessons: Maybe<Array<(
      { __typename?: 'Lesson' }
      & { id: Lesson['_id'] }
      & { attendance: Maybe<Array<(
        { __typename?: 'Attendance' }
        & Pick<Attendance, 'type'>
        & { student: Maybe<(
          { __typename?: 'Student' }
          & Pick<Student, 'fullName' | 'gender'>
          & { id: Student['_id'] }
        )> }
      )>> }
    )>> }
  )> }
);

export type StudentOfCourseFragment = (
  { __typename?: 'Student' }
  & Pick<Student, 'fullName' | 'gender' | 'alias' | 'userId'>
  & { id: Student['_id'] }
  & { academicLevel: Maybe<(
    { __typename?: 'AcademicLevel' }
    & Pick<AcademicLevel, 'name' | 'educationStage'>
    & { id: AcademicLevel['_id'] }
  )>, primarySchool: Maybe<(
    { __typename?: 'School' }
    & Pick<School, 'name'>
  )>, secondarySchool: Maybe<(
    { __typename?: 'School' }
    & Pick<School, 'name'>
  )> }
);

export type GetEnrollmentsInClassQueryVariables = {
  id: Scalars['ObjectId'],
  types?: Maybe<Array<EnrollmentType>>,
  status?: Maybe<Array<EnrollmentStatus>>
};


export type GetEnrollmentsInClassQuery = (
  { __typename?: 'Query' }
  & { class: Maybe<(
    { __typename?: 'Class' }
    & Pick<Class, 'learningArrangement' | 'type' | 'isAdHoc' | 'name' | 'maximumClassCapacity' | 'maximumMakeUpCapacity' | 'capacityUsed' | 'lastLessonDate' | 'isReplacementLesson'>
    & { id: Class['_id'] }
    & { enrollments: Maybe<Array<(
      { __typename?: 'Enrollment' }
      & Pick<Enrollment, 'startDate' | 'endDate' | 'type' | 'status' | 'group' | 'preferredLearningArrangement' | 'followUpStatus' | 'followUpStatusLastModifiedDateTime' | 'isReserved'>
      & { id: Enrollment['_id'] }
      & { attendances: Maybe<Array<(
        { __typename?: 'Attendance' }
        & Pick<Attendance, 'startDateTime'>
        & { remarks: Maybe<Array<(
          { __typename?: 'Remark' }
          & RemarkFieldsFragment
        )>> }
      )>>, followUpStatusLastModifiedBy: Maybe<(
        { __typename?: 'SystemUser' }
        & CreateByFieldsFragment
      )>, suitability: Maybe<(
        { __typename?: 'EnrollmentSuitability' }
        & Pick<EnrollmentSuitability, 'status'>
      )>, reservation: Maybe<(
        { __typename?: 'Registration' }
        & Pick<Registration, 'startDate'>
        & { class: Maybe<(
          { __typename?: 'Class' }
          & Pick<Class, 'name' | 'shorthand'>
          & { course: (
            { __typename?: 'Course' }
            & Pick<Course, 'colorCode'>
          ), teacher: Maybe<(
            { __typename?: 'SystemUser' }
            & { id: SystemUser['_id'] }
          )> }
        )> }
      )>, arrangements: Maybe<Array<(
        { __typename?: 'Arrangement' }
        & Pick<Arrangement, 'type' | 'status' | 'enrollmentId'>
      )>>, class: (
        { __typename?: 'Class' }
        & Pick<Class, 'name' | 'learningArrangement'>
        & { id: Class['_id'] }
        & { course: (
          { __typename?: 'Course' }
          & Pick<Course, 'colorCode'>
        ), teacher: Maybe<(
          { __typename?: 'SystemUser' }
          & { id: SystemUser['_id'] }
        )> }
      ), student: Maybe<(
        { __typename?: 'Student' }
        & StudentCellFieldsFragment
      )> }
    )>> }
  )> }
);

export type GetStudentsBirthDateQueryVariables = {};


export type GetStudentsBirthDateQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getStudentsBirthDate'>
);

export type QueryStudentPointsQueryVariables = {
  id: Scalars['ObjectId']
};


export type QueryStudentPointsQuery = (
  { __typename?: 'Query' }
  & { student: Maybe<(
    { __typename?: 'Student' }
    & BasicStudentBaseRewardsFragment
  )> }
);

export type QueryStudentListBaseRewardsQueryVariables = {
  search?: Maybe<Scalars['String']>,
  filterInput?: Maybe<StudentFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type QueryStudentListBaseRewardsQuery = (
  { __typename?: 'Query' }
  & { students: Maybe<(
    { __typename?: 'StudentPage' }
    & Pick<StudentPage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'Student' }
      & Pick<Student, 'gender' | 'fullName' | 'avatarUrl'>
      & { id: Student['_id'] }
      & { enrollments: Maybe<Array<(
        { __typename?: 'Enrollment' }
        & Pick<Enrollment, 'type'>
        & { class: (
          { __typename?: 'Class' }
          & { course: (
            { __typename?: 'Course' }
            & Pick<Course, '_id'>
          ) }
        ) }
      )>>, rewardPointsCounter: Maybe<(
        { __typename?: 'PointCounter' }
        & Pick<PointCounter, 'remaining' | 'expiring' | 'redemptionsMade'>
      )>, academicLevel: Maybe<(
        { __typename?: 'AcademicLevel' }
        & Pick<AcademicLevel, 'name' | 'educationStage'>
      )>, primarySchool: Maybe<(
        { __typename?: 'School' }
        & Pick<School, 'name'>
      )>, secondarySchool: Maybe<(
        { __typename?: 'School' }
        & Pick<School, 'name'>
      )> }
    )> }
  )> }
);

export type QueryClassListBaseRewardsQueryVariables = {
  search?: Maybe<Scalars['String']>,
  filterInput?: Maybe<ClassFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type QueryClassListBaseRewardsQuery = (
  { __typename?: 'Query' }
  & { classes: Maybe<(
    { __typename?: 'ClassPage' }
    & Pick<ClassPage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'Class' }
      & Pick<Class, 'name'>
      & { enrollments: Maybe<Array<(
        { __typename?: 'Enrollment' }
        & Pick<Enrollment, 'type' | 'startDate' | 'endDate'>
        & { student: Maybe<(
          { __typename?: 'Student' }
          & Pick<Student, 'gender' | 'fullName' | 'avatarUrl'>
          & { id: Student['_id'] }
          & { rewardPointsCounter: Maybe<(
            { __typename?: 'PointCounter' }
            & Pick<PointCounter, 'remaining' | 'expiring' | 'redemptionsMade'>
          )>, academicLevel: Maybe<(
            { __typename?: 'AcademicLevel' }
            & Pick<AcademicLevel, 'name' | 'educationStage'>
          )>, primarySchool: Maybe<(
            { __typename?: 'School' }
            & Pick<School, 'name'>
          )>, secondarySchool: Maybe<(
            { __typename?: 'School' }
            & Pick<School, 'name'>
          )> }
        )> }
      )>> }
    )> }
  )> }
);

export type GetCourseRegistrationDataQueryVariables = {
  search?: Maybe<Scalars['String']>,
  filterInput?: Maybe<RegistrationFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetCourseRegistrationDataQuery = (
  { __typename?: 'Query' }
  & { registrations: Maybe<(
    { __typename?: 'RegistrationPage' }
    & Pick<RegistrationPage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'Registration' }
      & Pick<Registration, 'startDate' | 'status' | 'isDeleted' | 'type'>
      & { id: Registration['_id'] }
      & { class: Maybe<(
        { __typename?: 'Class' }
        & Pick<Class, 'learningArrangement' | 'name' | 'shorthand' | 'classCode' | 'defaultLessonDays' | 'defaultLessonStart' | 'defaultLessonEnd'>
        & { id: Class['_id'] }
        & { venue: Maybe<(
          { __typename?: 'Venue' }
          & Pick<Venue, 'shorthand' | 'unitNo'>
          & { branch: Maybe<(
            { __typename?: 'Branch' }
            & Pick<Branch, 'name' | 'alias'>
          )> }
        )>, teacher: Maybe<(
          { __typename?: 'SystemUser' }
          & Pick<SystemUser, 'fullName' | 'salutation' | 'gender' | 'avatarUrl' | 'initials'>
          & { id: SystemUser['_id'] }
        )>, course: (
          { __typename?: 'Course' }
          & Pick<Course, 'name' | 'colorCode' | 'type'>
        ) }
      )>, enrollment: Maybe<(
        { __typename?: 'Enrollment' }
        & Pick<Enrollment, 'type' | 'startDate' | 'endDate' | 'lastModifiedDateTime'>
        & { id: Enrollment['_id'] }
        & { lastModifiedBy: Maybe<(
          { __typename?: 'SystemUser' }
          & Pick<SystemUser, 'fullName'>
        )>, attendances: Maybe<Array<(
          { __typename?: 'Attendance' }
          & Pick<Attendance, 'startDateTime'>
        )>> }
      )>, student: (
        { __typename?: 'Student' }
        & Pick<Student, 'userId' | 'fullName' | 'gender' | 'avatarUrl'>
        & { id: Student['_id'] }
        & { academicLevel: Maybe<(
          { __typename?: 'AcademicLevel' }
          & Pick<AcademicLevel, 'educationStage'>
        )>, primarySchool: Maybe<(
          { __typename?: 'School' }
          & Pick<School, 'name'>
        )>, secondarySchool: Maybe<(
          { __typename?: 'School' }
          & Pick<School, 'name'>
        )> }
      ), lastModifiedBy: Maybe<(
        { __typename?: 'SystemUser' }
        & LastModifiedByFieldsFragment
      )> }
    )> }
  )> }
);

export type GetReplacementCreditsQueryVariables = {
  query: StudentReplacementCreditsQuery
};


export type GetReplacementCreditsQuery = (
  { __typename?: 'Query' }
  & { replacementCreditWithCourse: Maybe<(
    { __typename?: 'StudentReplacementCreditDTO' }
    & { course: Maybe<(
      { __typename?: 'Course' }
      & Pick<Course, 'name' | 'avatarUrl' | 'type'>
      & { id: Course['_id'] }
    )>, academicYear: Maybe<(
      { __typename?: 'AcademicYear' }
      & Pick<AcademicYear, 'year'>
      & { id: AcademicYear['_id'] }
    )> }
    & MakeUpCreditInfoFragmentFragment
  )> }
);

export type GetCourseWithReplacementLessonQueryVariables = {
  filterInput?: Maybe<ArrangementFilterInput>
};


export type GetCourseWithReplacementLessonQuery = (
  { __typename?: 'Query' }
  & { arrangements: Maybe<(
    { __typename?: 'ArrangementPage' }
    & Pick<ArrangementPage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'Arrangement' }
      & { id: Arrangement['_id'] }
      & { fromClass: Maybe<(
        { __typename?: 'Class' }
        & { course: (
          { __typename?: 'Course' }
          & Pick<Course, 'name'>
          & { id: Course['_id'] }
        ), academicYear: (
          { __typename?: 'AcademicYear' }
          & Pick<AcademicYear, 'year'>
          & { id: AcademicYear['_id'] }
        ) }
      )> }
    )> }
  )> }
);

export type GetStudentWithReplacementLessonRecordsQueryVariables = {
  filterInput?: Maybe<ArrangementFilterInput>
};


export type GetStudentWithReplacementLessonRecordsQuery = (
  { __typename?: 'Query' }
  & { arrangements: Maybe<(
    { __typename?: 'ArrangementPage' }
    & Pick<ArrangementPage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'Arrangement' }
      & Pick<Arrangement, 'type' | 'status' | 'currentTermNumber'>
      & { id: Arrangement['_id'] }
      & { fromClass: Maybe<(
        { __typename?: 'Class' }
        & Pick<Class, 'name'>
        & { id: Class['_id'] }
        & { teacher: Maybe<(
          { __typename?: 'SystemUser' }
          & TeacherCellFieldsFragment
        )> }
      )>, fromLesson: Maybe<(
        { __typename?: 'Lesson' }
        & MakeUpCheckLessonFieldFragment
      )>, toClass: Maybe<(
        { __typename?: 'Class' }
        & Pick<Class, 'name'>
        & { id: Class['_id'] }
        & { teacher: Maybe<(
          { __typename?: 'SystemUser' }
          & TeacherCellFieldsFragment
        )> }
      )>, toLesson: Maybe<(
        { __typename?: 'Lesson' }
        & MakeUpCheckLessonFieldFragment
      )> }
    )> }
  )> }
);

export type GetStudentLessonDateQueryVariables = {
  id: Scalars['ObjectId'],
  year: Scalars['Int'],
  filterInput?: Maybe<StudentEnrollmentFilterInput>
};


export type GetStudentLessonDateQuery = (
  { __typename?: 'Query' }
  & { student: Maybe<(
    { __typename?: 'Student' }
    & { examScores: Maybe<Array<(
      { __typename?: 'ExamScore' }
      & ExamScoreFieldFragment
    )>>, enrollments: Maybe<Array<(
      { __typename?: 'Enrollment' }
      & { class: (
        { __typename?: 'Class' }
        & { id: Class['_id'] }
        & BasicClassFieldsFragment
      ) }
      & BasicEnrollmentFieldFragment
    )>>, term: Maybe<(
      { __typename?: 'Term' }
      & BasicTermFieldsFragment
    )> }
    & BasicStudentFieldsFragment
  )>, academicYears: Maybe<Array<(
    { __typename?: 'AcademicYear' }
    & Pick<AcademicYear, 'year' | 'academicWeekStart' | 'academicWeekEnd'>
    & { id: AcademicYear['_id'] }
    & { period: (
      { __typename?: 'DateTimeRange' }
      & Pick<DateTimeRange, 'start' | 'end'>
    ), terms: Maybe<Array<(
      { __typename?: 'Term' }
      & BasicTermFieldsFragment
    )>> }
  )>> }
);

export type GetStudentAttendancesQueryVariables = {
  id: Scalars['ObjectId'],
  filterInput?: Maybe<StudentAttendanceFilterInput>
};


export type GetStudentAttendancesQuery = (
  { __typename?: 'Query' }
  & { student: Maybe<(
    { __typename?: 'Student' }
    & { attendances: Maybe<Array<(
      { __typename?: 'Attendance' }
      & Pick<Attendance, 'status' | 'type'>
      & { id: Attendance['_id'] }
      & { linkArrangement: Maybe<(
        { __typename?: 'Arrangement' }
        & Pick<Arrangement, 'type'>
        & { toClass: Maybe<(
          { __typename?: 'Class' }
          & Pick<Class, 'shorthand'>
          & { id: Class['_id'] }
        )> }
      )>, linkedAttendance: Maybe<(
        { __typename?: 'Attendance' }
        & Pick<Attendance, 'startDateTime' | 'endDateTime' | 'status' | 'type'>
        & { class: (
          { __typename?: 'Class' }
          & { teacher: Maybe<(
            { __typename?: 'SystemUser' }
            & BasicTeacherFieldsFragment
          )> }
        ) }
      )>, lesson: (
        { __typename?: 'Lesson' }
        & Pick<Lesson, 'lessonStart' | 'lessonEnd'>
      ), remarks: Maybe<Array<(
        { __typename?: 'Remark' }
        & RemarkFieldsFragment
      )>> }
    )>> }
    & StudentBaseFieldsFragment
  )> }
);

export type GetClassAcademicYearWithTermQueryVariables = {
  classId: Scalars['ObjectId'],
  academicLevelId: Scalars['ObjectId']
};


export type GetClassAcademicYearWithTermQuery = (
  { __typename?: 'Query' }
  & { class: Maybe<(
    { __typename?: 'Class' }
    & { academicYear: (
      { __typename?: 'AcademicYear' }
      & Pick<AcademicYear, 'year'>
      & { id: AcademicYear['_id'] }
      & { term: Maybe<(
        { __typename?: 'Term' }
        & BasicTermFieldsFragment
      )> }
    ) }
  )> }
);

export type GetStudentRemarksQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetStudentRemarksQuery = (
  { __typename?: 'Query' }
  & { student: Maybe<(
    { __typename?: 'Student' }
    & { remarks: Maybe<Array<(
      { __typename?: 'Remark' }
      & RemarkFieldsFragment
    ) | { __typename?: 'StudentRemarks' }>>, pinnedRemark: Maybe<(
      { __typename?: 'Remark' }
      & Pick<Remark, 'content'>
      & { id: Remark['_id'] }
    )>, flagRemark: Maybe<(
      { __typename?: 'Remark' }
      & Pick<Remark, 'content'>
      & { id: Remark['_id'] }
    )> }
    & BasicStudentFieldsFragment
  )> }
);

export type CreateParentMutationVariables = {
  input: CreateParentInput
};


export type CreateParentMutation = (
  { __typename?: 'Mutation' }
  & { createParent: Maybe<(
    { __typename?: 'Parent' }
    & Pick<Parent, 'fullName' | 'email'>
    & { id: Parent['_id'] }
    & { mobile: (
      { __typename?: 'Telephone' }
      & Pick<Telephone, 'countryCode' | 'number'>
    ) }
  )> }
);

export type UpdateParentMutationVariables = {
  input: UpdateParentInput
};


export type UpdateParentMutation = (
  { __typename?: 'Mutation' }
  & { updateParent: Maybe<(
    { __typename?: 'Parent' }
    & ParentFieldsFragment
  )> }
);

export type StudentLinkParentMutationVariables = {
  input: LinkParentInput
};


export type StudentLinkParentMutation = (
  { __typename?: 'Mutation' }
  & { linkParent: Maybe<(
    { __typename?: 'Relationship' }
    & Pick<Relationship, 'parentId1' | 'parentId2'>
  )> }
);

export type UpdateRelationNameMutationVariables = {
  input: UpdateRelationshipInput
};


export type UpdateRelationNameMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateRelationName'>
);

export type UpdateStudentMutationVariables = {
  input: UpdateStudentInput
};


export type UpdateStudentMutation = (
  { __typename?: 'Mutation' }
  & { updateStudent: Maybe<(
    { __typename?: 'Student' }
    & StudentFieldsFragment
  )> }
);

export type RecordExamScoreMutationVariables = {
  input: RecordExamScoreInput
};


export type RecordExamScoreMutation = (
  { __typename?: 'Mutation' }
  & { recordExamScore: Maybe<(
    { __typename?: 'ExamScore' }
    & ExamScoreFieldFragment
  )> }
);

export type UpdateExamScoreMutationVariables = {
  input: UpdateExamScoreInput
};


export type UpdateExamScoreMutation = (
  { __typename?: 'Mutation' }
  & { updateExamScore: Maybe<(
    { __typename?: 'ExamScore' }
    & ExamScoreFieldFragment
  )> }
);

export type UpdateStudentRemarksMutationVariables = {
  input: UpdateStudentRemarksInput
};


export type UpdateStudentRemarksMutation = (
  { __typename?: 'Mutation' }
  & { updateStudentRemarks: Maybe<(
    { __typename?: 'Student' }
    & { id: Student['_id'] }
    & { remarks: Maybe<Array<(
      { __typename?: 'Remark' }
      & RemarkFieldsFragment
    ) | { __typename?: 'StudentRemarks' }>> }
  )> }
);

export type ChangeAdminPasswordMutationVariables = {
  id: Scalars['ObjectId']
};


export type ChangeAdminPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'requestChangeSystemUserPassword'>
);

export type RequestChangeSystemUserPasswordMutationVariables = {
  id: Scalars['ObjectId']
};


export type RequestChangeSystemUserPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'requestChangeSystemUserPassword'>
);

export type ParentFieldsFragment = (
  { __typename?: 'Parent' }
  & Pick<Parent, 'alias' | 'email' | 'avatarUrl' | 'createDateTime' | 'gender' | 'fullName' | 'alternativeEmails' | 'userId' | 'alternateUserId' | 'salutation' | 'status' | 'voidRemarks' | 'whatsAppName'>
  & { id: Parent['_id'] }
  & { remark: Maybe<(
    { __typename?: 'Remark' }
    & RemarkFieldsFragment
  )>, relatedParents: Maybe<Array<(
    { __typename?: 'Parent' }
    & ParentFieldsWithoutRelatedUserFragment
  )>>, relatedStudents: Maybe<Array<(
    { __typename?: 'Student' }
    & StudentFieldsWithoutRelatedUserFragment
  )>>, linkedWhatsappAccount: Maybe<(
    { __typename?: 'WhatsappAccount' }
    & { number: Maybe<(
      { __typename?: 'Telephone' }
      & Pick<Telephone, 'number' | 'countryCode'>
    )> }
  )>, address: Maybe<(
    { __typename?: 'UserAddress' }
    & Pick<UserAddress, 'country' | 'streetAddress' | 'gateBuilding' | 'postalCode' | 'link' | 'unit'>
  )>, homePhone: Maybe<(
    { __typename?: 'Telephone' }
    & Pick<Telephone, 'countryCode' | 'number'>
  )>, mobile: (
    { __typename?: 'Telephone' }
    & Pick<Telephone, 'countryCode' | 'number'>
  ), officePhone: Maybe<(
    { __typename?: 'Telephone' }
    & Pick<Telephone, 'countryCode' | 'number'>
  )> }
);

export type ParentFieldsWithoutRelatedUserFragment = (
  { __typename?: 'Parent' }
  & Pick<Parent, 'alias' | 'email' | 'avatarUrl' | 'createDateTime' | 'gender' | 'fullName' | 'salutation' | 'alternativeEmails' | 'userId' | 'alternateUserId' | 'whatsAppName'>
  & { id: Parent['_id'] }
  & { linkedWhatsappAccount: Maybe<(
    { __typename?: 'WhatsappAccount' }
    & { number: Maybe<(
      { __typename?: 'Telephone' }
      & Pick<Telephone, 'countryCode' | 'number'>
    )> }
  )>, address: Maybe<(
    { __typename?: 'UserAddress' }
    & Pick<UserAddress, 'country' | 'streetAddress' | 'gateBuilding' | 'postalCode' | 'unit'>
  )>, homePhone: Maybe<(
    { __typename?: 'Telephone' }
    & Pick<Telephone, 'countryCode' | 'number'>
  )>, mobile: (
    { __typename?: 'Telephone' }
    & Pick<Telephone, 'countryCode' | 'number'>
  ), officePhone: Maybe<(
    { __typename?: 'Telephone' }
    & Pick<Telephone, 'countryCode' | 'number'>
  )> }
);

export type StudentBaseFieldsFragment = (
  { __typename?: 'Student' }
  & Pick<Student, 'fullName' | 'alias' | 'status' | 'voidRemarks' | 'gender' | 'avatarUrl' | 'birthDate' | 'email' | 'alternativeEmails' | 'userId' | 'alternateUserId' | 'primaryStream' | 'secondaryStream'>
  & { id: Student['_id'] }
  & { remarks: Maybe<Array<(
    { __typename?: 'Remark' }
    & RemarkFieldsFragment
  ) | { __typename?: 'StudentRemarks' }>>, address: Maybe<(
    { __typename?: 'UserAddress' }
    & Pick<UserAddress, 'country' | 'streetAddress' | 'gateBuilding' | 'postalCode' | 'link' | 'unit'>
  )>, mobile: Maybe<(
    { __typename?: 'Telephone' }
    & Pick<Telephone, 'countryCode' | 'number'>
  )>, homePhone: Maybe<(
    { __typename?: 'Telephone' }
    & Pick<Telephone, 'countryCode' | 'number'>
  )>, primarySchool: Maybe<(
    { __typename?: 'School' }
    & Pick<School, 'name' | 'alias' | 'educationStage'>
    & { id: School['_id'] }
  )>, secondarySchool: Maybe<(
    { __typename?: 'School' }
    & Pick<School, 'alias' | 'educationStage' | 'name'>
    & { id: School['_id'] }
  )>, academicLevel: Maybe<(
    { __typename?: 'AcademicLevel' }
    & Pick<AcademicLevel, 'alias' | 'name' | 'educationStage'>
    & { id: AcademicLevel['_id'] }
  )> }
);

export type StudentFieldsFragment = (
  { __typename?: 'Student' }
  & { enrollments: Maybe<Array<(
    { __typename?: 'Enrollment' }
    & Pick<Enrollment, 'type'>
    & { class: (
      { __typename?: 'Class' }
      & Pick<Class, 'name'>
      & { id: Class['_id'] }
    ) }
  )>>, consent: Maybe<(
    { __typename?: 'Consent' }
    & Pick<Consent, 'termCondition' | 'accountCondition' | 'statements' | 'PhotographyOrVideography'>
  )>, primaryParent: Maybe<(
    { __typename?: 'Parent' }
    & Pick<Parent, 'fullName' | 'salutation' | 'alias' | 'email' | 'whatsAppName'>
    & { id: Parent['_id'] }
    & { mobile: (
      { __typename?: 'Telephone' }
      & Pick<Telephone, 'countryCode' | 'number'>
    ), linkedWhatsappAccount: Maybe<(
      { __typename?: 'WhatsappAccount' }
      & { number: Maybe<(
        { __typename?: 'Telephone' }
        & Pick<Telephone, 'number' | 'countryCode'>
      )> }
    )> }
  )>, enrollmentMatters: Maybe<Array<(
    { __typename?: 'Parent' }
    & Pick<Parent, 'fullName' | 'salutation' | 'alias' | 'email'>
    & { id: Parent['_id'] }
  )>>, academicMatters: Maybe<Array<(
    { __typename?: 'Parent' }
    & Pick<Parent, 'fullName' | 'salutation' | 'alias' | 'email'>
    & { id: Parent['_id'] }
  )>>, siblings: Maybe<Array<(
    { __typename?: 'Student' }
    & StudentFieldsWithoutRelatedUserFragment
  )>>, parents: Maybe<Array<(
    { __typename?: 'Parent' }
    & ParentFieldsWithoutRelatedUserFragment
  )>> }
  & StudentBaseFieldsFragment
);

export type ExamScoreFieldFragment = (
  { __typename?: 'ExamScore' }
  & Pick<ExamScore, 'year' | 'subject' | 'stream' | 'examType' | 'marksPercentage' | 'scoreGrade' | 'createDateTime'>
  & { id: ExamScore['_id'] }
  & { academicLevel: Maybe<(
    { __typename?: 'AcademicLevel' }
    & AcademicLevelFieldsFragment
  )>, mcq: Maybe<(
    { __typename?: 'ScoreRecord' }
    & ScoreRecordFieldFragment
  )>, qeq: Maybe<(
    { __typename?: 'ScoreRecord' }
    & ScoreRecordFieldFragment
  )>, practical: Maybe<(
    { __typename?: 'ScoreRecord' }
    & ScoreRecordFieldFragment
  )>, totalMarks: (
    { __typename?: 'ScoreRecord' }
    & ScoreRecordFieldFragment
  ), createdBy: Maybe<(
    { __typename?: 'SystemUser' }
    & CreateByFieldsFragment
  )> }
);

export type ScoreRecordFieldFragment = (
  { __typename?: 'ScoreRecord' }
  & Pick<ScoreRecord, 'total' | 'score'>
);

export type StudentFieldsWithoutRelatedUserFragment = (
  { __typename?: 'Student' }
  & Pick<Student, 'fullName' | 'alias' | 'gender' | 'avatarUrl' | 'birthDate' | 'email' | 'alternativeEmails' | 'userId' | 'alternateUserId' | 'primaryStream' | 'secondaryStream'>
  & { id: Student['_id'] }
  & { remarks: Maybe<Array<(
    { __typename?: 'Remark' }
    & RemarkFieldsFragment
  ) | { __typename?: 'StudentRemarks' }>>, mobile: Maybe<(
    { __typename?: 'Telephone' }
    & Pick<Telephone, 'countryCode' | 'number'>
  )>, homePhone: Maybe<(
    { __typename?: 'Telephone' }
    & Pick<Telephone, 'countryCode' | 'number'>
  )>, primarySchool: Maybe<(
    { __typename?: 'School' }
    & Pick<School, 'name' | 'alias' | 'educationStage'>
    & { id: School['_id'] }
  )>, secondarySchool: Maybe<(
    { __typename?: 'School' }
    & Pick<School, 'alias' | 'educationStage' | 'name'>
    & { id: School['_id'] }
  )>, academicLevel: Maybe<(
    { __typename?: 'AcademicLevel' }
    & Pick<AcademicLevel, 'alias' | 'name' | 'educationStage'>
    & { id: AcademicLevel['_id'] }
  )>, enrollments: Maybe<Array<(
    { __typename?: 'Enrollment' }
    & Pick<Enrollment, 'type'>
    & { class: (
      { __typename?: 'Class' }
      & Pick<Class, 'name'>
      & { id: Class['_id'] }
      & { course: (
        { __typename?: 'Course' }
        & Pick<Course, 'name'>
      ) }
    ) }
  )>>, consent: Maybe<(
    { __typename?: 'Consent' }
    & Pick<Consent, 'termCondition' | 'accountCondition' | 'statements' | 'PhotographyOrVideography'>
  )>, primaryParent: Maybe<(
    { __typename?: 'Parent' }
    & Pick<Parent, 'fullName' | 'salutation' | 'alias'>
    & { id: Parent['_id'] }
  )>, enrollmentMatters: Maybe<Array<(
    { __typename?: 'Parent' }
    & Pick<Parent, 'fullName' | 'salutation' | 'alias'>
    & { id: Parent['_id'] }
  )>>, academicMatters: Maybe<Array<(
    { __typename?: 'Parent' }
    & Pick<Parent, 'fullName' | 'salutation' | 'alias'>
    & { id: Parent['_id'] }
  )>> }
);

export type SystemUserFieldsFragment = (
  { __typename?: 'SystemUser' }
  & Pick<SystemUser, 'fullName' | 'alias' | 'avatarUrl' | 'email' | 'gender' | 'initials' | 'salutation' | 'userId' | 'alternateUserId'>
  & { id: SystemUser['_id'] }
  & { mobile: Maybe<(
    { __typename?: 'Telephone' }
    & Pick<Telephone, 'countryCode' | 'number'>
  )>, officePhone: Maybe<(
    { __typename?: 'Telephone' }
    & Pick<Telephone, 'countryCode' | 'number'>
  )>, branches: Maybe<Array<(
    { __typename?: 'Branch' }
    & Pick<Branch, 'name'>
    & { id: Branch['_id'] }
  )>>, department: Maybe<(
    { __typename?: 'Department' }
    & Pick<Department, 'name'>
    & { id: Department['_id'] }
  )>, linkedWhatsappNumber: Maybe<(
    { __typename?: 'Telephone' }
    & Pick<Telephone, 'countryCode' | 'number'>
  )> }
);

export type GetParentQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetParentQuery = (
  { __typename?: 'Query' }
  & { parent: Maybe<(
    { __typename?: 'Parent' }
    & Pick<Parent, 'alias' | 'email' | 'avatarUrl' | 'createDateTime' | 'gender' | 'fullName' | 'salutation' | 'status' | 'voidRemarks' | 'alternativeEmails' | 'userId' | 'alternateUserId' | 'latestStudentNumber' | 'whatsAppName' | 'linkedWhatsappAccountId'>
    & { id: Parent['_id'] }
    & { remark: Maybe<(
      { __typename?: 'Remark' }
      & RemarkFieldsFragment
    )>, address: Maybe<(
      { __typename?: 'UserAddress' }
      & Pick<UserAddress, 'country' | 'streetAddress' | 'gateBuilding' | 'postalCode' | 'unit'>
    )>, homePhone: Maybe<(
      { __typename?: 'Telephone' }
      & Pick<Telephone, 'countryCode' | 'number'>
    )>, mobile: (
      { __typename?: 'Telephone' }
      & Pick<Telephone, 'countryCode' | 'number'>
    ), officePhone: Maybe<(
      { __typename?: 'Telephone' }
      & Pick<Telephone, 'countryCode' | 'number'>
    )>, linkedWhatsappAccount: Maybe<(
      { __typename?: 'WhatsappAccount' }
      & { number: Maybe<(
        { __typename?: 'Telephone' }
        & Pick<Telephone, 'number' | 'countryCode'>
      )> }
    )>, relatedParents: Maybe<Array<(
      { __typename?: 'Parent' }
      & Pick<Parent, 'relationName' | 'fullName' | 'avatarUrl' | 'gender' | 'email'>
      & { id: Parent['_id'] }
      & { mobile: (
        { __typename?: 'Telephone' }
        & Pick<Telephone, 'countryCode' | 'number'>
      ), linkedWhatsappAccount: Maybe<(
        { __typename?: 'WhatsappAccount' }
        & { number: Maybe<(
          { __typename?: 'Telephone' }
          & Pick<Telephone, 'countryCode' | 'number'>
        )> }
      )> }
    )>>, relatedStudents: Maybe<Array<(
      { __typename?: 'Student' }
      & Pick<Student, 'relationName' | 'fullName' | 'avatarUrl' | 'gender' | 'userId'>
      & { id: Student['_id'] }
      & { primaryParent: Maybe<(
        { __typename?: 'Parent' }
        & { id: Parent['_id'] }
      )>, academicLevel: Maybe<(
        { __typename?: 'AcademicLevel' }
        & Pick<AcademicLevel, 'name'>
      )> }
    )>> }
  )> }
);

export type GetParentsQueryVariables = {
  search?: Maybe<Scalars['String']>,
  filterInput?: Maybe<ParentFilter>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetParentsQuery = (
  { __typename?: 'Query' }
  & { parents: Maybe<(
    { __typename?: 'ParentPage' }
    & Pick<ParentPage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'Parent' }
      & Pick<Parent, 'latestStudentNumber'>
      & ParentFieldsFragment
    )> }
  )> }
);

export type GetSearchParentsQueryVariables = {
  search?: Maybe<Scalars['String']>,
  filterInput?: Maybe<ParentFilter>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetSearchParentsQuery = (
  { __typename?: 'Query' }
  & { parents: Maybe<(
    { __typename?: 'ParentPage' }
    & Pick<ParentPage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'Parent' }
      & Pick<Parent, 'fullName' | 'alias' | 'salutation' | 'gender' | 'avatarUrl' | 'canRemove' | 'userId'>
      & { id: Parent['_id'] }
    )> }
  )> }
);

export type GetStudentQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetStudentQuery = (
  { __typename?: 'Query' }
  & { student: Maybe<(
    { __typename?: 'Student' }
    & { primaryParent: Maybe<(
      { __typename?: 'Parent' }
      & Pick<Parent, 'fullName' | 'salutation' | 'alias' | 'email' | 'whatsAppName'>
      & { id: Parent['_id'] }
      & { mobile: (
        { __typename?: 'Telephone' }
        & Pick<Telephone, 'countryCode' | 'number'>
      ), linkedWhatsappAccount: Maybe<(
        { __typename?: 'WhatsappAccount' }
        & { number: Maybe<(
          { __typename?: 'Telephone' }
          & Pick<Telephone, 'number' | 'countryCode'>
        )> }
      )> }
    )>, siblings: Maybe<Array<(
      { __typename?: 'Student' }
      & Pick<Student, 'relationName' | 'fullName' | 'avatarUrl' | 'gender' | 'userId'>
      & { id: Student['_id'] }
      & { primaryParent: Maybe<(
        { __typename?: 'Parent' }
        & { id: Parent['_id'] }
      )>, academicLevel: Maybe<(
        { __typename?: 'AcademicLevel' }
        & Pick<AcademicLevel, 'name'>
      )> }
    )>>, parents: Maybe<Array<(
      { __typename?: 'Parent' }
      & Pick<Parent, 'relationName' | 'fullName' | 'avatarUrl' | 'gender' | 'email'>
      & { id: Parent['_id'] }
      & { mobile: (
        { __typename?: 'Telephone' }
        & Pick<Telephone, 'countryCode' | 'number'>
      ), linkedWhatsappAccount: Maybe<(
        { __typename?: 'WhatsappAccount' }
        & { number: Maybe<(
          { __typename?: 'Telephone' }
          & Pick<Telephone, 'countryCode' | 'number'>
        )> }
      )> }
    )>>, examScores: Maybe<Array<(
      { __typename?: 'ExamScore' }
      & ExamScoreFieldFragment
    )>> }
    & StudentBaseFieldsFragment
  )> }
);

export type GetStudentCellQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetStudentCellQuery = (
  { __typename?: 'Query' }
  & { student: Maybe<(
    { __typename?: 'Student' }
    & { primarySchool: Maybe<(
      { __typename?: 'School' }
      & Pick<School, 'name'>
      & { id: School['_id'] }
    )>, secondarySchool: Maybe<(
      { __typename?: 'School' }
      & Pick<School, 'name'>
      & { id: School['_id'] }
    )>, academicLevel: Maybe<(
      { __typename?: 'AcademicLevel' }
      & Pick<AcademicLevel, 'educationStage' | 'name'>
      & { id: AcademicLevel['_id'] }
    )> }
    & StudentCellFieldsFragment
  )> }
);

export type GetStudentsQueryVariables = {
  search?: Maybe<Scalars['String']>,
  filterInput?: Maybe<StudentFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetStudentsQuery = (
  { __typename?: 'Query' }
  & { students: Maybe<(
    { __typename?: 'StudentPage' }
    & Pick<StudentPage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'Student' }
      & StudentFieldsFragment
    )> }
  )> }
);

export type GetTeacherQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetTeacherQuery = (
  { __typename?: 'Query' }
  & { teacher: Maybe<(
    { __typename?: 'SystemUser' }
    & SystemUserFieldsFragment
  )> }
);

export type GetAdminQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetAdminQuery = (
  { __typename?: 'Query' }
  & { admin: Maybe<(
    { __typename?: 'SystemUser' }
    & SystemUserFieldsFragment
  )> }
);

export type GetCurrentSystemUserQueryVariables = {};


export type GetCurrentSystemUserQuery = (
  { __typename?: 'Query' }
  & { currentSystemUser: Maybe<(
    { __typename?: 'SystemUser' }
    & SystemUserFieldsFragment
  )> }
);

export type GenerateUserIdQueryVariables = {
  input: GenerateUserIdInput
};


export type GenerateUserIdQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'generateUserId'>
);

export type CreateByFieldsFragment = (
  { __typename?: 'SystemUser' }
  & Pick<SystemUser, 'email' | 'fullName'>
  & { id: SystemUser['_id'] }
);

export type LastModifiedByFieldsFragment = (
  { __typename?: 'SystemUser' }
  & Pick<SystemUser, 'fullName'>
  & { id: SystemUser['_id'] }
);

export type GetExamScoreQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetExamScoreQuery = (
  { __typename?: 'Query' }
  & { examScores: Maybe<Array<(
    { __typename?: 'ExamScore' }
    & ExamScoreFieldFragment
  )>> }
);

export type AuditLogFieldsFragment = (
  { __typename?: 'AuditLog' }
  & Pick<AuditLog, 'operationType' | 'resourceType' | 'resourceName' | 'description' | 'timestamp'>
  & { id: AuditLog['_id'] }
  & { principal: Maybe<(
    { __typename?: 'SystemUser' }
    & Pick<SystemUser, 'fullName' | 'avatarUrl' | 'initials'>
    & { id: SystemUser['_id'] }
  )>, changes: Maybe<Array<(
    { __typename?: 'Changes' }
    & Pick<Changes, 'fieldName' | 'newValue' | 'oldValue'>
  )>> }
);

export type GetAuditLogsQueryVariables = {
  search?: Maybe<Scalars['String']>,
  filterInput?: Maybe<AuditLogFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetAuditLogsQuery = (
  { __typename?: 'Query' }
  & { auditLogs: Maybe<(
    { __typename?: 'AuditLogPage' }
    & Pick<AuditLogPage, 'total' | 'pageSize' | 'pageIndex'>
    & { items: Array<(
      { __typename?: 'AuditLog' }
      & AuditLogFieldsFragment
    )> }
  )> }
);

export type GetAuditLogQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetAuditLogQuery = (
  { __typename?: 'Query' }
  & { auditLog: Maybe<(
    { __typename?: 'AuditLog' }
    & AuditLogFieldsFragment
  )> }
);

export type ParentCsvQueryVariables = {
  search?: Maybe<Scalars['String']>,
  filterInput?: Maybe<ParentFilter>
};


export type ParentCsvQuery = (
  { __typename?: 'Query' }
  & { csv: Maybe<(
    { __typename?: 'Csv' }
    & Pick<Csv, 'url'>
  )> }
);

export type StudentCsvQueryVariables = {
  search?: Maybe<Scalars['String']>,
  columns?: Maybe<Array<Scalars['String']>>,
  filterInput?: Maybe<StudentFilterInput>
};


export type StudentCsvQuery = (
  { __typename?: 'Query' }
  & { csv: Maybe<(
    { __typename?: 'Csv' }
    & Pick<Csv, 'url'>
  )> }
);

export type ExportSystemUserCsvQueryVariables = {
  filterInput?: Maybe<SystemUserQuery>
};


export type ExportSystemUserCsvQuery = (
  { __typename?: 'Query' }
  & { csv: Maybe<(
    { __typename?: 'Csv' }
    & Pick<Csv, 'url'>
  )> }
);

export type GetEmailValidationQueryVariables = {
  email: Scalars['String'],
  excludeUserId?: Maybe<Scalars['ObjectId']>
};


export type GetEmailValidationQuery = (
  { __typename?: 'Query' }
  & { validateEmail: Maybe<(
    { __typename?: 'EmailValidation' }
    & Pick<EmailValidation, 'valid' | 'validationError'>
  )> }
);

export type AutocompleteParentInfoQueryVariables = {};


export type AutocompleteParentInfoQuery = (
  { __typename?: 'Query' }
  & { autocompleteParent: (
    { __typename?: 'CreateParentOutput' }
    & Pick<CreateParentOutput, 'salutation' | 'fullName' | 'alias' | 'gender' | 'email' | 'alternativeEmails' | 'whatsAppName' | 'userId' | 'alternateUserId' | 'avatarUrl'>
    & { mobile: (
      { __typename?: 'TelephoneOutput' }
      & Pick<TelephoneOutput, 'countryCode' | 'number'>
    ), homePhone: Maybe<(
      { __typename?: 'TelephoneOutput' }
      & Pick<TelephoneOutput, 'countryCode' | 'number'>
    )>, officePhone: Maybe<(
      { __typename?: 'TelephoneOutput' }
      & Pick<TelephoneOutput, 'countryCode' | 'number'>
    )>, address: Maybe<(
      { __typename?: 'UserAddressOutput' }
      & Pick<UserAddressOutput, 'country' | 'gateBuilding' | 'link' | 'postalCode' | 'streetAddress' | 'unit'>
    )> }
  ) }
);

export type AutocompleteStudentInfoQueryVariables = {
  primaryParentId: Scalars['ObjectId']
};


export type AutocompleteStudentInfoQuery = (
  { __typename?: 'Query' }
  & { autocompleteStudent: (
    { __typename?: 'RegisterStudentOutput' }
    & Pick<RegisterStudentOutput, 'academicLevelId' | 'alias' | 'alternateUserId' | 'alternativeEmails' | 'avatarUrl' | 'birthDate' | 'email' | 'fullName' | 'gender' | 'primaryParentId' | 'primarySchoolId' | 'primaryStream' | 'remarks' | 'secondarySchoolId' | 'secondaryStream' | 'userId'>
    & { consent: (
      { __typename?: 'ConsentOutput' }
      & Pick<ConsentOutput, 'PhotographyOrVideography' | 'accountCondition' | 'statements' | 'termCondition'>
    ), homePhone: Maybe<(
      { __typename?: 'TelephoneOutput' }
      & Pick<TelephoneOutput, 'countryCode' | 'number'>
    )>, mobile: Maybe<(
      { __typename?: 'TelephoneOutput' }
      & Pick<TelephoneOutput, 'countryCode' | 'number'>
    )> }
  ) }
);

export type GetCountryCodesQueryVariables = {};


export type GetCountryCodesQuery = (
  { __typename?: 'Query' }
  & { countryCodes: Maybe<Array<(
    { __typename?: 'CountryCode' }
    & Pick<CountryCode, 'order' | 'areaCode' | 'countryCode'>
  )>> }
);

export type GetOptionParentsQueryVariables = {
  search?: Maybe<Scalars['String']>,
  filterInput?: Maybe<ParentFilter>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type GetOptionParentsQuery = (
  { __typename?: 'Query' }
  & { parents: Maybe<(
    { __typename?: 'ParentPage' }
    & Pick<ParentPage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'Parent' }
      & Pick<Parent, 'avatarUrl' | 'salutation' | 'fullName' | 'userId' | 'latestStudentNumber'>
      & { id: Parent['_id'] }
      & { mobile: (
        { __typename?: 'Telephone' }
        & Pick<Telephone, 'countryCode' | 'number'>
      ) }
    )> }
  )> }
);

export type GetSystemUserQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetSystemUserQuery = (
  { __typename?: 'Query' }
  & { systemUser: Maybe<(
    { __typename?: 'SystemUser' }
    & SystemUserFieldsFragment
  )> }
);

export type GetAlternativeLessonsForChangeAttendingClassQueryVariables = {
  input: AlternativeLessonsForChangeAttendingClassInput
};


export type GetAlternativeLessonsForChangeAttendingClassQuery = (
  { __typename?: 'Query' }
  & { alternativeLessons: Maybe<Array<(
    { __typename?: 'Lesson' }
    & Pick<Lesson, 'lessonNumber' | 'lessonStart' | 'lessonEnd' | 'lessonDay' | 'maximumClassCapacity'>
    & { id: Lesson['_id'] }
    & { teacher: Maybe<(
      { __typename?: 'SystemUser' }
      & Pick<SystemUser, 'fullName' | 'salutation' | 'gender' | 'avatarUrl' | 'initials'>
      & { id: SystemUser['_id'] }
    )>, venue: Maybe<(
      { __typename?: 'Venue' }
      & Pick<Venue, 'shorthand' | 'unitNo'>
      & { id: Venue['_id'] }
    )>, academicWeek: Maybe<(
      { __typename?: 'AcademicWeek' }
      & Pick<AcademicWeek, 'startDate' | 'endDate' | 'weekNumber'>
    )>, class: Maybe<(
      { __typename?: 'Class' }
      & ArrangementClassCardFieldsFragment
    )>, capacity: Maybe<(
      { __typename?: 'LessonCapacityDTO' }
      & LessonCapacityFieldsFragment
    )>, attendance: Maybe<Array<(
      { __typename?: 'Attendance' }
      & { id: Attendance['_id'] }
    )>> }
  )>> }
);

export type AddFeesForInvoiceMutationVariables = {
  input: AddInvoiceLineItemCommand
};


export type AddFeesForInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { addInvoiceLineItem: Maybe<(
    { __typename?: 'Invoice' }
    & InvoiceItemFieldsFragment
  )> }
);

export type UpdateLineItemMutationVariables = {
  input: UpdateInvoiceLineItemCommand
};


export type UpdateLineItemMutation = (
  { __typename?: 'Mutation' }
  & { updateInvoiceItem: Maybe<(
    { __typename?: 'Invoice' }
    & InvoiceItemFieldsFragment
  )> }
);

export type ApplyDiscountMutationVariables = {
  input: ApplyInvoiceDiscountCommand
};


export type ApplyDiscountMutation = (
  { __typename?: 'Mutation' }
  & { applyDiscount: Maybe<(
    { __typename?: 'Invoice' }
    & InvoiceItemFieldsFragment
  )> }
);

export type RemoveLineItemMutationVariables = {
  lineItemId: Scalars['ObjectId']
};


export type RemoveLineItemMutation = (
  { __typename?: 'Mutation' }
  & { removeLineItem: Maybe<(
    { __typename?: 'Invoice' }
    & InvoiceItemFieldsFragment
  )> }
);

export type RemoveLineItemDiscountMutationVariables = {
  lineItemId: Scalars['ObjectId']
};


export type RemoveLineItemDiscountMutation = (
  { __typename?: 'Mutation' }
  & { removeLineItemDiscount: Maybe<(
    { __typename?: 'Invoice' }
    & InvoiceItemFieldsFragment
  )> }
);

export type ResendReminderMutationVariables = {
  id: Scalars['ObjectId']
};


export type ResendReminderMutation = (
  { __typename?: 'Mutation' }
  & { resendReminder: Maybe<(
    { __typename?: 'Reminder' }
    & Pick<Reminder, 'sender'>
    & { receiver: Maybe<(
      { __typename?: 'Parent' }
      & BasicParentFieldsFragment
    )>, template: Maybe<(
      { __typename?: 'Template' }
      & BasicTemplateFieldsFragment
    )> }
    & BasicReminderFieldsFragment
  )> }
);

export type CompileTemplateMutationVariables = {
  input: SendReminderInput
};


export type CompileTemplateMutation = (
  { __typename?: 'Mutation' }
  & { compileTemplate: Maybe<(
    { __typename?: 'ReminderPreview' }
    & Pick<ReminderPreview, 'subject' | 'emailMessage' | 'whatsappMessage'>
    & { students: Maybe<Array<(
      { __typename?: 'Student' }
      & Pick<Student, 'fullName'>
    )>>, parent: Maybe<(
      { __typename?: 'Parent' }
      & Pick<Parent, 'fullName' | 'email' | 'salutation'>
      & { mobile: (
        { __typename?: 'Telephone' }
        & Pick<Telephone, 'countryCode' | 'number'>
      ), linkedWhatsappAccount: Maybe<(
        { __typename?: 'WhatsappAccount' }
        & { number: Maybe<(
          { __typename?: 'Telephone' }
          & Pick<Telephone, 'countryCode' | 'number'>
        )> }
      )> }
    )> }
  )> }
);

export type SendReminderMutationVariables = {
  input: SendReminderInput
};


export type SendReminderMutation = (
  { __typename?: 'Mutation' }
  & { sendReminder: Maybe<Array<(
    { __typename?: 'Reminder' }
    & { id: Reminder['_id'] }
  )>> }
);

export type SendTestReminderMutationVariables = {
  input: SendTestReminderInput
};


export type SendTestReminderMutation = (
  { __typename?: 'Mutation' }
  & { sendTestReminder: Maybe<(
    { __typename?: 'Reminder' }
    & { id: Reminder['_id'] }
  )> }
);

export type BulkCompileTemplateMutationVariables = {
  input: BulkSendReminderInput
};


export type BulkCompileTemplateMutation = (
  { __typename?: 'Mutation' }
  & { bulkCompileTemplate: Maybe<Array<(
    { __typename?: 'ReminderPreview' }
    & Pick<ReminderPreview, 'subject' | 'emailMessage' | 'whatsappMessage'>
    & { students: Maybe<Array<(
      { __typename?: 'Student' }
      & Pick<Student, 'fullName'>
    )>>, parent: Maybe<(
      { __typename?: 'Parent' }
      & Pick<Parent, 'fullName' | 'email' | 'salutation'>
      & { mobile: (
        { __typename?: 'Telephone' }
        & Pick<Telephone, 'countryCode' | 'number'>
      ), linkedWhatsappAccount: Maybe<(
        { __typename?: 'WhatsappAccount' }
        & { number: Maybe<(
          { __typename?: 'Telephone' }
          & Pick<Telephone, 'countryCode' | 'number'>
        )> }
      )> }
    )> }
  )>> }
);

export type GetReminderQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetReminderQuery = (
  { __typename?: 'Query' }
  & { reminder: Maybe<(
    { __typename?: 'Reminder' }
    & Pick<Reminder, 'sender'>
    & { receiver: Maybe<(
      { __typename?: 'Parent' }
      & BasicParentFieldsFragment
    )>, template: Maybe<(
      { __typename?: 'Template' }
      & BasicTemplateFieldsFragment
    )> }
    & BasicReminderFieldsFragment
  )> }
);

export type GetRegistrationForReminderQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetRegistrationForReminderQuery = (
  { __typename?: 'Query' }
  & { registration: Maybe<(
    { __typename?: 'Registration' }
    & Pick<Registration, 'type' | 'status'>
    & { id: Registration['_id'] }
    & { invoices: Maybe<Array<(
      { __typename?: 'Invoice' }
      & InvoiceItemFieldsFragment
    )>>, student: (
      { __typename?: 'Student' }
      & { primaryParent: Maybe<(
        { __typename?: 'Parent' }
        & { id: Parent['_id'] }
      )> }
    ), class: Maybe<(
      { __typename?: 'Class' }
      & Pick<Class, 'type'>
    )> }
  )> }
);

export type GetReminderArrangementQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetReminderArrangementQuery = (
  { __typename?: 'Query' }
  & { arrangement: Maybe<(
    { __typename?: 'Arrangement' }
    & Pick<Arrangement, 'type'>
    & { invoice: Maybe<(
      { __typename?: 'Invoice' }
      & InvoiceItemFieldsFragment
    )>, student: (
      { __typename?: 'Student' }
      & { primaryParent: Maybe<(
        { __typename?: 'Parent' }
        & { id: Parent['_id'] }
      )> }
    ) }
  )> }
);

export type GetSendReminderInvoiceQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetSendReminderInvoiceQuery = (
  { __typename?: 'Query' }
  & { invoice: Maybe<(
    { __typename?: 'Invoice' }
    & { destination: Maybe<(
      { __typename?: 'Enrollment' }
      & Pick<Enrollment, 'type' | 'status'>
      & { id: Enrollment['_id'] }
      & { registration: Maybe<(
        { __typename?: 'Registration' }
        & Pick<Registration, 'type'>
        & { id: Registration['_id'] }
      )>, arrangements: Maybe<Array<(
        { __typename?: 'Arrangement' }
        & Pick<Arrangement, 'type' | 'status'>
        & { id: Arrangement['_id'] }
      )>> }
    )> }
    & InvoiceItemFieldsFragment
  )> }
);

export type GetSendPaymentAdviceInvoicesQueryVariables = {
  filterInput?: Maybe<InvoiceFilterInput>,
  pageInfo?: Maybe<PageInfo>
};


export type GetSendPaymentAdviceInvoicesQuery = (
  { __typename?: 'Query' }
  & { invoices: Maybe<(
    { __typename?: 'InvoicePage' }
    & { items: Array<(
      { __typename?: 'Invoice' }
      & { destination: Maybe<(
        { __typename?: 'Enrollment' }
        & Pick<Enrollment, 'type' | 'status'>
        & { id: Enrollment['_id'] }
        & { registration: Maybe<(
          { __typename?: 'Registration' }
          & Pick<Registration, 'type'>
          & { id: Registration['_id'] }
        )>, arrangements: Maybe<Array<(
          { __typename?: 'Arrangement' }
          & Pick<Arrangement, 'type' | 'status'>
          & { id: Arrangement['_id'] }
        )>> }
      )> }
      & InvoiceItemFieldsFragment
    )> }
  )> }
);

export type ExportReminderDocxQueryVariables = {
  input: SendReminderInput
};


export type ExportReminderDocxQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'exportReminderDocx'>
);

export type BulkExportReminderDocxQueryVariables = {
  input: BulkSendReminderInput
};


export type BulkExportReminderDocxQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'bulkExportReminderDocx'>
);

export type GetInvoiceDetailDataQueryVariables = {
  invoiceId: Scalars['ObjectId']
};


export type GetInvoiceDetailDataQuery = (
  { __typename?: 'Query' }
  & { invoice: Maybe<(
    { __typename?: 'Invoice' }
    & { billedTo: Maybe<(
      { __typename?: 'Parent' }
      & Pick<Parent, 'fullName' | 'email'>
      & { id: Parent['_id'] }
      & { mobile: (
        { __typename?: 'Telephone' }
        & Pick<Telephone, 'countryCode' | 'number'>
      ) }
    )>, billedFor: Maybe<(
      { __typename?: 'Student' }
      & Pick<Student, 'fullName' | 'userId' | 'alternateUserId'>
      & { id: Student['_id'] }
      & { primarySchool: Maybe<(
        { __typename?: 'School' }
        & Pick<School, 'name'>
      )> }
    )>, destination: Maybe<(
      { __typename?: 'Enrollment' }
      & Pick<Enrollment, 'type'>
    )> }
    & BasicInvoiceFieldsFragment
  )> }
);

export type GetInvoiceDueDateDataQueryVariables = {
  invoiceId: Scalars['ObjectId']
};


export type GetInvoiceDueDateDataQuery = (
  { __typename?: 'Query' }
  & { invoice: Maybe<(
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'dueDate'>
    & { id: Invoice['_id'] }
  )> }
);

export type ReminderParentFieldsFragment = (
  { __typename?: 'Parent' }
  & Pick<Parent, 'fullName' | 'salutation' | 'alias' | 'email' | 'whatsAppName' | 'linkedWhatsappAccountId'>
  & { id: Parent['_id'] }
  & { mobile: (
    { __typename?: 'Telephone' }
    & Pick<Telephone, 'countryCode' | 'number'>
  ), linkedWhatsappAccount: Maybe<(
    { __typename?: 'WhatsappAccount' }
    & { number: Maybe<(
      { __typename?: 'Telephone' }
      & Pick<Telephone, 'number' | 'countryCode'>
    )> }
  )> }
);

export type GetReminderFormStudentQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetReminderFormStudentQuery = (
  { __typename?: 'Query' }
  & { student: Maybe<(
    { __typename?: 'Student' }
    & { id: Student['_id'] }
    & { primaryParent: Maybe<(
      { __typename?: 'Parent' }
      & ReminderParentFieldsFragment
    )>, parents: Maybe<Array<(
      { __typename?: 'Parent' }
      & ReminderParentFieldsFragment
    )>>, enrollmentMatters: Maybe<Array<(
      { __typename?: 'Parent' }
      & Pick<Parent, 'fullName' | 'salutation' | 'alias' | 'email'>
      & { id: Parent['_id'] }
    )>> }
  )> }
);

export type GetReminderFormParentQueryVariables = {
  id: Scalars['ObjectId']
};


export type GetReminderFormParentQuery = (
  { __typename?: 'Query' }
  & { parent: Maybe<(
    { __typename?: 'Parent' }
    & { relatedParents: Maybe<Array<(
      { __typename?: 'Parent' }
      & ReminderParentFieldsFragment
    )>> }
    & ReminderParentFieldsFragment
  )> }
);

export type StudentCellProfileFragment = (
  { __typename?: 'Student' }
  & Pick<Student, 'fullName' | 'gender' | 'avatarUrl' | 'userId' | 'alternateUserId' | 'eligibleArrangements'>
  & { id: Student['_id'] }
  & { primaryParent: Maybe<(
    { __typename?: 'Parent' }
    & { id: Parent['_id'] }
    & { remark: Maybe<(
      { __typename?: 'Remark' }
      & Pick<Remark, 'levelType' | 'content'>
    )> }
  )>, primarySchool: Maybe<(
    { __typename?: 'School' }
    & Pick<School, 'name'>
    & { id: School['_id'] }
  )>, secondarySchool: Maybe<(
    { __typename?: 'School' }
    & Pick<School, 'name'>
    & { id: School['_id'] }
  )>, academicLevel: Maybe<(
    { __typename?: 'AcademicLevel' }
    & Pick<AcademicLevel, 'educationStage'>
    & { id: AcademicLevel['_id'] }
  )> }
);

export type GetSearchStudentModalDataQueryVariables = {
  search?: Maybe<Scalars['String']>,
  pageInfo?: Maybe<PageInfo>,
  filterInput?: Maybe<StudentFilterInput>
};


export type GetSearchStudentModalDataQuery = (
  { __typename?: 'Query' }
  & { students: Maybe<(
    { __typename?: 'StudentPage' }
    & Pick<StudentPage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'Student' }
      & StudentCellProfileFragment
    )> }
  )> }
);

export type GetSearchScheduleStudentModalDataQueryVariables = {
  search?: Maybe<Scalars['String']>,
  pageInfo?: Maybe<PageInfo>,
  filterInput?: Maybe<StudentFilterInput>,
  attendanceFilterInput?: Maybe<StudentAttendanceFilterInput>
};


export type GetSearchScheduleStudentModalDataQuery = (
  { __typename?: 'Query' }
  & { students: Maybe<(
    { __typename?: 'StudentPage' }
    & Pick<StudentPage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'Student' }
      & { attendances: Maybe<Array<(
        { __typename?: 'Attendance' }
        & Pick<Attendance, 'type' | 'status'>
        & { id: Attendance['_id'] }
        & { linkArrangement: Maybe<(
          { __typename?: 'Arrangement' }
          & { toClass: Maybe<(
            { __typename?: 'Class' }
            & Pick<Class, 'name'>
          )> }
        )>, lesson: (
          { __typename?: 'Lesson' }
          & { id: Lesson['_id'] }
        ) }
      )>> }
      & StudentCellProfileFragment
    )> }
  )> }
);

export type BasicAcademicLevelFieldsFragment = (
  { __typename?: 'AcademicLevel' }
  & Pick<AcademicLevel, 'orderNo' | 'name' | 'alias' | 'educationStage' | 'isVisible' | 'isArchived' | 'createDateTime'>
  & { id: AcademicLevel['_id'] }
  & { createdBy: Maybe<(
    { __typename?: 'SystemUser' }
    & CreateByFieldsFragment
  )> }
);

export type BasicAcademicYearFieldsFragment = (
  { __typename?: 'AcademicYear' }
  & Pick<AcademicYear, 'year' | 'taxRate' | 'academicWeekStart' | 'academicWeekEnd'>
  & { id: AcademicYear['_id'] }
  & { period: (
    { __typename?: 'DateTimeRange' }
    & Pick<DateTimeRange, 'start' | 'end'>
  ), academicWeeks: Array<(
    { __typename?: 'AcademicWeekWithId' }
    & Pick<AcademicWeekWithId, 'startDate' | 'endDate' | 'weekNumber'>
  )>, terms: Maybe<Array<(
    { __typename?: 'Term' }
    & { id: Term['_id'] }
    & { academicLevels: Maybe<Array<(
      { __typename?: 'AcademicLevel' }
      & BasicAcademicLevelFieldsFragment
    )>> }
    & BasicTermFieldsFragment
  )>>, termBillingDueDate: Maybe<(
    { __typename?: 'TermBillingDueDate' }
    & Pick<TermBillingDueDate, 'term1' | 'term2' | 'term3' | 'term4'>
  )>, groupAssignment: Maybe<(
    { __typename?: 'GroupAssignment' }
    & Pick<GroupAssignment, 'isDeleted' | 'createDateTime'>
    & { id: GroupAssignment['_id'] }
    & { academicYear: (
      { __typename?: 'AcademicYear' }
      & { id: AcademicYear['_id'] }
    ), items: Maybe<Array<(
      { __typename?: 'GroupAssignmentItem' }
      & Pick<GroupAssignmentItem, 'weekNumber' | 'startDate' | 'endDate' | 'groupA' | 'groupB'>
    )>> }
  )> }
);

export type BasicHolidayFieldsFragment = (
  { __typename?: 'Holiday' }
  & Pick<Holiday, 'name' | 'type'>
  & { id: Holiday['_id'] }
  & { period: (
    { __typename?: 'DateRange' }
    & Pick<DateRange, 'start' | 'end'>
  ) }
);

export type BasicTermFieldsFragment = (
  { __typename?: 'Term' }
  & { term1Period: (
    { __typename?: 'DateTimeRange' }
    & Pick<DateTimeRange, 'start' | 'end'>
  ), term2Period: (
    { __typename?: 'DateTimeRange' }
    & Pick<DateTimeRange, 'start' | 'end'>
  ), term3Period: (
    { __typename?: 'DateTimeRange' }
    & Pick<DateTimeRange, 'start' | 'end'>
  ), term4Period: (
    { __typename?: 'DateTimeRange' }
    & Pick<DateTimeRange, 'start' | 'end'>
  ) }
);

export type BasicArrangementFieldsFragment = (
  { __typename?: 'Arrangement' }
  & Pick<Arrangement, 'type' | 'status' | 'name' | 'remarks' | 'useCredits' | 'createDateTime' | 'lastModifiedDateTime' | 'currentStep'>
  & { id: Arrangement['_id'] }
);

export type TransferBillingArrangementClassFieldsFragment = (
  { __typename?: 'Class' }
  & Pick<Class, 'name' | 'defaultLessonDays' | 'defaultLessonStart' | 'defaultLessonEnd' | 'learningArrangement'>
  & { id: Class['_id'] }
  & { course: (
    { __typename?: 'Course' }
    & Pick<Course, 'avatarUrl' | 'name' | 'colorCode'>
  ), teacher: Maybe<(
    { __typename?: 'SystemUser' }
    & Pick<SystemUser, 'fullName' | 'initials'>
    & { id: SystemUser['_id'] }
  )>, venue: Maybe<(
    { __typename?: 'Venue' }
    & Pick<Venue, 'shorthand' | 'unitNo'>
    & { branch: Maybe<(
      { __typename?: 'Branch' }
      & Pick<Branch, 'name' | 'alias'>
    )> }
  )>, enrollments: Maybe<Array<(
    { __typename?: 'Enrollment' }
    & Pick<Enrollment, '_id' | 'type' | 'isReserved'>
  )>>, capacity: Maybe<(
    { __typename?: 'ClassCapacityDTO' }
    & CapacityFieldsFragment
  )> }
);

export type TransferDetailCardFieldsFragment = (
  { __typename?: 'Arrangement' }
  & Pick<Arrangement, 'status'>
  & { id: Arrangement['_id'] }
  & { toClass: Maybe<(
    { __typename?: 'Class' }
    & Pick<Class, 'learningArrangement'>
    & ArrangementClassCardFieldsFragment
  )>, fromClass: Maybe<(
    { __typename?: 'Class' }
    & ArrangementClassCardFieldsFragment
  )>, enrollment: Maybe<(
    { __typename?: 'Enrollment' }
    & Pick<Enrollment, 'group' | 'preferredLearningArrangement'>
  )>, newEnrollment: Maybe<(
    { __typename?: 'Enrollment' }
    & Pick<Enrollment, 'group' | 'preferredLearningArrangement'>
  )>, toLesson: Maybe<(
    { __typename?: 'Lesson' }
    & Pick<Lesson, 'lessonStart' | 'lessonEnd' | 'lessonNumber'>
    & { id: Lesson['_id'] }
  )>, fromLesson: Maybe<(
    { __typename?: 'Lesson' }
    & Pick<Lesson, 'lessonStart' | 'lessonEnd' | 'lessonNumber'>
    & { id: Lesson['_id'] }
  )>, student: (
    { __typename?: 'Student' }
    & { id: Student['_id'] }
  ) }
);

export type TelephoneFieldsFragment = (
  { __typename?: 'Telephone' }
  & Pick<Telephone, 'countryCode' | 'number'>
);

export type AddressFieldsFragment = (
  { __typename?: 'Address' }
  & Pick<Address, 'line1' | 'unit' | 'country' | 'postalCode'>
);

export type BasicBranchFieldsFragment = (
  { __typename?: 'Branch' }
  & Pick<Branch, 'name' | 'alias' | 'isArchived' | 'createDateTime'>
  & { id: Branch['_id'] }
  & { address: (
    { __typename?: 'Address' }
    & AddressFieldsFragment
  ) }
);

export type BasicCentreFieldsFragment = (
  { __typename?: 'Centre' }
  & Pick<Centre, 'createDateTime' | 'isArchived'>
  & { id: Centre['_id'] }
);

export type BasicClassFieldsFragment = (
  { __typename?: 'Class' }
  & Pick<Class, 'name' | 'defaultLessonDays' | 'defaultLessonStart' | 'defaultLessonEnd' | 'maximumClassCapacity' | 'maximumMakeUpCapacity' | 'runNumber' | 'createDateTime' | 'status' | 'isArchived' | 'learningArrangement'>
  & { id: Class['_id'] }
  & { academicYear: (
    { __typename?: 'AcademicYear' }
    & Pick<AcademicYear, 'year'>
  ), capacity: Maybe<(
    { __typename?: 'ClassCapacityDTO' }
    & CapacityFieldsFragment
  )> }
);

export type BasicEnrollmentFieldFragment = (
  { __typename?: 'Enrollment' }
  & Pick<Enrollment, 'type' | 'status' | 'startDate' | 'endDate'>
  & { id: Enrollment['_id'] }
);

export type BasicAcademicWeekFieldFragment = (
  { __typename?: 'AcademicWeek' }
  & Pick<AcademicWeek, 'startDate' | 'endDate' | 'weekNumber'>
);

export type ClassEnrollmentFieldsFragment = (
  { __typename?: 'Enrollment' }
  & Pick<Enrollment, 'startDate' | 'endDate' | 'type' | 'status' | 'isReserved'>
  & { id: Enrollment['_id'] }
  & { class: (
    { __typename?: 'Class' }
    & { id: Class['_id'] }
  ), attendances: Maybe<Array<(
    { __typename?: 'Attendance' }
    & Pick<Attendance, '_id' | 'endDateTime'>
    & { lesson: (
      { __typename?: 'Lesson' }
      & Pick<Lesson, '_id'>
    ) }
  )>>, student: Maybe<(
    { __typename?: 'Student' }
    & Pick<Student, 'fullName' | 'avatarUrl' | 'gender'>
    & { id: Student['_id'] }
    & { primarySchool: Maybe<(
      { __typename?: 'School' }
      & Pick<School, 'name'>
      & { id: School['_id'] }
    )>, secondarySchool: Maybe<(
      { __typename?: 'School' }
      & Pick<School, 'name'>
      & { id: School['_id'] }
    )>, academicLevel: Maybe<(
      { __typename?: 'AcademicLevel' }
      & Pick<AcademicLevel, 'educationStage'>
      & { id: AcademicLevel['_id'] }
    )> }
  )>, invoices: Maybe<Array<(
    { __typename?: 'Invoice' }
    & Pick<Invoice, '_id' | 'dueDate'>
  )>> }
);

export type CapacityFieldsFragment = (
  { __typename?: 'ClassCapacityDTO' }
  & Pick<ClassCapacityDto, 'regular' | 'replacement' | 'used' | 'waiting'>
  & { seats: Maybe<Array<(
    { __typename?: 'ClassSeatDTO' }
    & { seatDetails: Maybe<Array<(
      { __typename?: 'ClassSeatDetailDTO' }
      & Pick<ClassSeatDetailDto, 'enrollmentStartDate' | 'enrollmentEndDate' | 'enrollmentStatus' | 'type' | 'seatType' | 'hasPaid' | 'isEnrollmentStarted'>
      & { student: Maybe<(
        { __typename?: 'Student' }
        & Pick<Student, 'fullName' | 'gender' | 'avatarUrl' | 'userId' | 'alternateUserId'>
        & { id: Student['_id'] }
        & { primarySchool: Maybe<(
          { __typename?: 'School' }
          & Pick<School, 'name'>
          & { id: School['_id'] }
        )>, secondarySchool: Maybe<(
          { __typename?: 'School' }
          & Pick<School, 'name'>
          & { id: School['_id'] }
        )>, academicLevel: Maybe<(
          { __typename?: 'AcademicLevel' }
          & Pick<AcademicLevel, 'educationStage'>
          & { id: AcademicLevel['_id'] }
        )> }
      )> }
    )>> }
  )>> }
);

export type ArrangementClassCardFieldsFragment = (
  { __typename?: 'Class' }
  & Pick<Class, 'name' | 'type' | 'defaultLessonStart' | 'defaultLessonEnd' | 'learningArrangement' | 'defaultLessonDays'>
  & { id: Class['_id'] }
  & { dailySessionTimeSlots: Maybe<Array<(
    { __typename?: 'TimeRange' }
    & Pick<TimeRange, 'start' | 'end'>
  )>>, academicYear: (
    { __typename?: 'AcademicYear' }
    & Pick<AcademicYear, 'year'>
    & { id: AcademicYear['_id'] }
  ), teacher: Maybe<(
    { __typename?: 'SystemUser' }
    & TeacherCellFieldsFragment
  )>, course: (
    { __typename?: 'Course' }
    & Pick<Course, 'name' | 'type' | 'avatarUrl' | 'colorCode'>
    & { id: Course['_id'] }
  ), venue: Maybe<(
    { __typename?: 'Venue' }
    & Pick<Venue, 'shorthand' | 'unitNo'>
    & { branch: Maybe<(
      { __typename?: 'Branch' }
      & Pick<Branch, 'name' | 'alias'>
    )> }
  )>, enrollments: Maybe<Array<(
    { __typename?: 'Enrollment' }
    & Pick<Enrollment, '_id' | 'type' | 'isReserved'>
  )>>, capacity: Maybe<(
    { __typename?: 'ClassCapacityDTO' }
    & CapacityFieldsFragment
  )> }
);

export type BasicCourierPickupAddressFieldsFragment = (
  { __typename?: 'CourierPickupAddress' }
  & Pick<CourierPickupAddress, 'address' | 'postalCode' | 'isArchived'>
  & { id: CourierPickupAddress['_id'] }
);

export type BasicCourseFieldsFragment = (
  { __typename?: 'Course' }
  & Pick<Course, 'avatarUrl' | 'name' | 'nameShort' | 'description' | 'status' | 'academicStreams' | 'type' | 'createDateTime' | 'xeroAccountsSyncStatus'>
  & { id: Course['_id'] }
  & { courseFee: Maybe<(
    { __typename?: 'CourseFee' }
    & CourseFeeFieldFragment
  )> }
);

export type CourseFeeFieldFragment = (
  { __typename?: 'CourseFee' }
  & Pick<CourseFee, 'isApplicable' | 'billingFrequency'>
  & { standardCourseFees: Array<(
    { __typename?: 'StandardCourseFee' }
    & Pick<StandardCourseFee, 'type' | 'amount'>
  )>, otherFees: Maybe<Array<(
    { __typename?: 'Fee' }
    & Pick<Fee, 'name' | 'isApplicable' | 'amount'>
  )>> }
);

export type BasicPaymentRecordFieldsFragment = (
  { __typename?: 'Payment' }
  & Pick<Payment, 'method' | 'amount' | 'createDateTime' | 'paymentDate' | 'depositDate' | 'remarks' | 'reference' | 'bankName' | 'lastDigits' | 'chequeNumber' | 'paymentProcessor' | 'balanceAmount' | 'cashAmount' | 'isVoid' | 'referenceNumber'>
  & { id: Payment['_id'] }
);

export type BasicExamScoreFieldsFragment = (
  { __typename?: 'ExamScore' }
  & Pick<ExamScore, 'year' | 'subject' | 'stream' | 'examType' | 'marksPercentage' | 'scoreGrade' | 'createDateTime'>
  & { id: ExamScore['_id'] }
  & { mcq: Maybe<(
    { __typename?: 'ScoreRecord' }
    & ScoreRecordFieldsFragment
  )>, qeq: Maybe<(
    { __typename?: 'ScoreRecord' }
    & ScoreRecordFieldsFragment
  )>, practical: Maybe<(
    { __typename?: 'ScoreRecord' }
    & ScoreRecordFieldsFragment
  )>, totalMarks: (
    { __typename?: 'ScoreRecord' }
    & ScoreRecordFieldsFragment
  ) }
);

export type ScoreRecordFieldsFragment = (
  { __typename?: 'ScoreRecord' }
  & Pick<ScoreRecord, 'score' | 'total'>
);

export type BasicInvoiceFieldsFragment = (
  { __typename?: 'Invoice' }
  & Pick<Invoice, 'createDateTime' | 'referenceNumber' | 'status' | 'taxRate' | 'lessonDates' | 'dueDate' | 'subtotal' | 'totalTax' | 'total' | 'amountPaid'>
  & { id: Invoice['_id'] }
  & { lineItems: Maybe<Array<(
    { __typename?: 'InvoiceLineItem' }
    & LineItemFieldsFragment
  )>>, class: Maybe<(
    { __typename?: 'Class' }
    & Pick<Class, 'isAdHoc' | 'type'>
  )> }
);

export type LineItemFieldsFragment = (
  { __typename?: 'InvoiceLineItem' }
  & Pick<InvoiceLineItem, 'description' | 'discountId' | 'discountType' | 'discountAmount' | 'discountRate' | 'discountRemarks' | 'unitAmount' | 'quantity' | 'lineAmount' | 'paid' | 'remarks'>
  & { id: InvoiceLineItem['_id'] }
  & { lessons: Maybe<Array<(
    { __typename?: 'InvoiceLineItemLesson' }
    & Pick<InvoiceLineItemLesson, 'isMakeUp'>
    & { lesson: Maybe<(
      { __typename?: 'Lesson' }
      & Pick<Lesson, 'lessonNumber' | 'lessonStart' | 'lessonEnd'>
      & { id: Lesson['_id'] }
    )> }
  )>>, class: Maybe<(
    { __typename?: 'Class' }
    & Pick<Class, 'name' | 'defaultLessonDays' | 'defaultLessonStart' | 'defaultLessonEnd' | 'isAdHoc' | 'type' | 'learningArrangement'>
    & { teacher: Maybe<(
      { __typename?: 'SystemUser' }
      & TeacherCellFieldsFragment
    )>, venue: Maybe<(
      { __typename?: 'Venue' }
      & Pick<Venue, 'shorthand' | 'unitNo'>
      & { branch: Maybe<(
        { __typename?: 'Branch' }
        & Pick<Branch, 'name' | 'alias'>
      )> }
    )>, course: (
      { __typename?: 'Course' }
      & Pick<Course, 'type'>
      & { courseFee: Maybe<(
        { __typename?: 'CourseFee' }
        & Pick<CourseFee, 'billingFrequency'>
        & { standardCourseFees: Array<(
          { __typename?: 'StandardCourseFee' }
          & Pick<StandardCourseFee, 'type'>
        )> }
      )> }
    ) }
  )> }
);

export type BasicLessonFieldsFragment = (
  { __typename?: 'Lesson' }
  & Pick<Lesson, 'status' | 'lessonStart' | 'lessonEnd' | 'lessonNumber' | 'lessonDay' | 'isOverlappedTeacher' | 'isOverlappedVenue' | 'maximumClassCapacity' | 'createDateTime'>
  & { id: Lesson['_id'] }
  & { academicWeek: Maybe<(
    { __typename?: 'AcademicWeek' }
    & Pick<AcademicWeek, 'startDate' | 'endDate' | 'weekNumber'>
  )> }
);

export type BasicAttendanceFieldsFragment = (
  { __typename?: 'Attendance' }
  & Pick<Attendance, 'status' | 'type'>
);

export type SelectedLessonFieldsFragment = (
  { __typename?: 'Lesson' }
  & Pick<Lesson, 'lessonStart' | 'lessonEnd' | 'lessonNumber' | 'lessonDay'>
  & { id: Lesson['_id'] }
  & { class: Maybe<(
    { __typename?: 'Class' }
    & Pick<Class, 'name' | 'shorthand'>
    & { id: Class['_id'] }
  )>, academicWeek: Maybe<(
    { __typename?: 'AcademicWeek' }
    & Pick<AcademicWeek, 'weekNumber' | 'startDate' | 'endDate'>
  )>, attendance: Maybe<Array<(
    { __typename?: 'Attendance' }
    & { id: Attendance['_id'] }
  )>>, capacity: Maybe<(
    { __typename?: 'LessonCapacityDTO' }
    & LessonCapacityFieldsFragment
  )> }
);

export type BasicParentFieldsFragment = (
  { __typename?: 'Parent' }
  & Pick<Parent, 'fullName' | 'alias' | 'status' | 'gender' | 'salutation' | 'email' | 'alternativeEmails' | 'avatarUrl' | 'whatsAppName' | 'userId' | 'alternateUserId' | 'isArchived' | 'createDateTime'>
  & { id: Parent['_id'] }
  & { address: Maybe<(
    { __typename?: 'UserAddress' }
    & Pick<UserAddress, 'streetAddress' | 'gateBuilding' | 'link' | 'unit' | 'country' | 'postalCode'>
  )>, remark: Maybe<(
    { __typename?: 'Remark' }
    & RemarkFieldsFragment
  )> }
);

export type ParentCellFieldsFragment = (
  { __typename?: 'Parent' }
  & Pick<Parent, 'fullName' | 'alias' | 'salutation' | 'gender' | 'avatarUrl' | 'email'>
  & { id: Parent['_id'] }
  & { mobile: (
    { __typename?: 'Telephone' }
    & Pick<Telephone, 'countryCode' | 'number'>
  ) }
);

export type BasicPromotionFieldsFragment = (
  { __typename?: 'Promotion' }
  & Pick<Promotion, 'createDateTime' | 'scheduledDate' | 'status'>
  & { id: Promotion['_id'] }
);

export type BasicReceiptFieldsFragment = (
  { __typename?: 'Receipt' }
  & Pick<Receipt, 'amount' | 'createDateTime' | 'referenceNumber' | 'status' | 'isNotified'>
  & { id: Receipt['_id'] }
  & { contact: Maybe<(
    { __typename?: 'Parent' }
    & { id: Parent['_id'] }
  )> }
);

export type BasicReferenceDataFieldsFragment = (
  { __typename?: 'ReferenceData' }
  & Pick<ReferenceData, 'category' | 'order' | 'key' | 'value' | 'createDateTime'>
  & { id: ReferenceData['_id'] }
);

export type BasicRefundFieldsFragment = (
  { __typename?: 'Refund' }
  & Pick<Refund, 'createDateTime' | 'amount' | 'refundMethod'>
  & { id: Refund['_id'] }
);

export type BasicRegistrationFieldsFragment = (
  { __typename?: 'Registration' }
  & Pick<Registration, 'name' | 'type' | 'startDate' | 'dueDate' | 'currentStep' | 'status' | 'remarks' | 'lastModifiedDateTime' | 'createDateTime'>
  & { id: Registration['_id'] }
  & { selectedLessons: Maybe<Array<(
    { __typename?: 'SelectedLesson' }
    & SelectedLessonsFiledsFragment
  )>> }
);

export type SelectedLessonsFiledsFragment = (
  { __typename?: 'SelectedLesson' }
  & Pick<SelectedLesson, 'byPass'>
);

export type RegistrationOnChecksFieldsFragment = (
  { __typename?: 'Registration' }
  & Pick<Registration, 'type' | 'name' | 'startDate' | 'remarks' | 'dueDate'>
  & { id: Registration['_id'] }
  & { class: Maybe<(
    { __typename?: 'Class' }
    & Pick<Class, 'shorthand' | 'type' | 'academicYearId' | 'isAdHoc' | 'isReplacementLesson'>
    & { course: (
      { __typename?: 'Course' }
      & Pick<Course, 'name' | 'colorCode'>
      & { id: Course['_id'] }
    ), term: Maybe<(
      { __typename?: 'Term' }
      & BasicTermFieldsFragment
    )> }
    & ArrangementClassCardFieldsFragment
  )>, student: (
    { __typename?: 'Student' }
    & StudentCellFieldsFragment
  ), selectedLessons: Maybe<Array<(
    { __typename?: 'SelectedLesson' }
    & { lesson: (
      { __typename?: 'Lesson' }
      & SelectedLessonFieldsFragment
    ), makeUpLesson: Maybe<(
      { __typename?: 'Lesson' }
      & SelectedLessonFieldsFragment
    )> }
  )>>, enrollment: Maybe<(
    { __typename?: 'Enrollment' }
    & Pick<Enrollment, 'type' | 'isFullEnrollment'>
    & { id: Enrollment['_id'] }
  )>, linkRegistration: Maybe<(
    { __typename?: 'Registration' }
    & { id: Registration['_id'] }
  )>, session: Maybe<(
    { __typename?: 'Session' }
    & { registrationSession: Maybe<(
      { __typename?: 'RegistrationSession' }
      & Pick<RegistrationSession, 'registrationStage' | 'regenerateStartData'>
    )> }
  )> }
);

export type RelationshipStudentFieldsFragment = (
  { __typename?: 'Student' }
  & { academicLevel: Maybe<(
    { __typename?: 'AcademicLevel' }
    & BasicAcademicLevelFieldsFragment
  )>, primaryParent: Maybe<(
    { __typename?: 'Parent' }
    & BasicParentFieldsFragment
  )>, primarySchool: Maybe<(
    { __typename?: 'School' }
    & BasicSchoolFieldsFragment
  )> }
  & BasicStudentFieldsFragment
);

export type BasicReminderFieldsFragment = (
  { __typename?: 'Reminder' }
  & Pick<Reminder, 'type' | 'subject' | 'message' | 'sendDate' | 'sendStatus' | 'scheduledDate'>
  & { id: Reminder['_id'] }
);

export type BasicRewardFieldsFragment = (
  { __typename?: 'Reward' }
  & Pick<Reward, 'name' | 'description' | 'category' | 'stock' | 'avatar' | 'points' | 'galleries' | 'remoteGalleries' | 'isVisible' | 'isArchived'>
  & { id: Reward['_id'] }
);

export type BasicSchoolFieldsFragment = (
  { __typename?: 'School' }
  & Pick<School, 'name' | 'alias' | 'educationStage' | 'isVisible' | 'isArchived' | 'createDateTime'>
  & { id: School['_id'] }
);

export type BasicStatementOfAccountFragment = (
  { __typename?: 'StatementOfAccount' }
  & Pick<StatementOfAccount, 'id' | 'creditRemain' | 'refund'>
  & { parent: (
    { __typename?: 'Parent' }
    & BasicParentFieldsFragment
  ) }
);

export type BasicStudentFieldsFragment = (
  { __typename?: 'Student' }
  & Pick<Student, 'fullName' | 'alias' | 'gender' | 'alternativeEmails' | 'birthDate' | 'avatarUrl' | 'primaryStream' | 'secondaryStream' | 'userId' | 'alternateUserId' | 'isArchived' | 'createDateTime'>
  & { id: Student['_id'] }
  & { primarySchool: Maybe<(
    { __typename?: 'School' }
    & Pick<School, 'name'>
    & { id: School['_id'] }
  )>, primaryParent: Maybe<(
    { __typename?: 'Parent' }
    & Pick<Parent, 'fullName'>
    & { id: Parent['_id'] }
  )>, secondarySchool: Maybe<(
    { __typename?: 'School' }
    & Pick<School, 'name'>
    & { id: School['_id'] }
  )>, academicLevel: Maybe<(
    { __typename?: 'AcademicLevel' }
    & Pick<AcademicLevel, 'educationStage' | 'name'>
    & { id: AcademicLevel['_id'] }
  )>, consent: Maybe<(
    { __typename?: 'Consent' }
    & Pick<Consent, 'termCondition' | 'accountCondition' | 'statements' | 'PhotographyOrVideography'>
  )> }
);

export type StudentCellFieldsFragment = (
  { __typename?: 'Student' }
  & Pick<Student, 'fullName' | 'alias' | 'gender' | 'avatarUrl' | 'userId' | 'alternateUserId'>
  & { id: Student['_id'] }
);

export type BasicTeacherFieldsFragment = (
  { __typename?: 'SystemUser' }
  & Pick<SystemUser, 'fullName' | 'alias' | 'gender' | 'initials' | 'salutation' | 'avatarUrl' | 'userId' | 'alternateUserId' | 'createDateTime'>
  & { id: SystemUser['_id'] }
);

export type TeacherCellFieldsFragment = (
  { __typename?: 'SystemUser' }
  & Pick<SystemUser, 'fullName' | 'gender' | 'avatarUrl' | 'salutation' | 'initials'>
  & { id: SystemUser['_id'] }
);

export type SystemUserCellFieldsFragment = (
  { __typename?: 'SystemUser' }
  & Pick<SystemUser, 'fullName' | 'gender' | 'avatarUrl' | 'salutation' | 'initials'>
  & { id: SystemUser['_id'] }
);

export type BasicTemplateFieldsFragment = (
  { __typename?: 'Template' }
  & Pick<Template, 'type' | 'status' | 'whatsAppMessage' | 'title' | 'subject' | 'usageType' | 'emailMessage' | 'isArchived' | 'content'>
  & { id: Template['_id'] }
);

export type UserFieldsFragment = (
  { __typename?: 'SystemUser' }
  & Pick<SystemUser, 'fullName' | 'avatarUrl'>
  & { id: SystemUser['_id'] }
);

export type BasicVenueFieldsFragment = (
  { __typename?: 'Venue' }
  & Pick<Venue, 'name' | 'unitNo' | 'shorthand' | 'capacity' | 'isArchived' | 'isDigital' | 'createDateTime' | 'section' | 'googleCalendarId' | 'googleCalendarHostEmail'>
  & { id: Venue['_id'] }
  & { address: (
    { __typename?: 'Address' }
    & Pick<Address, 'line1' | 'link' | 'postalCode'>
  ), branch: Maybe<(
    { __typename?: 'Branch' }
    & BasicBranchFieldsFragment
  )> }
);

export type WhatsappAccountStatusChangedSubscriptionVariables = {};


export type WhatsappAccountStatusChangedSubscription = (
  { __typename?: 'Subscription' }
  & { whatsappAccountStatusChanged: (
    { __typename?: 'WhatsappAccount' }
    & WhatsappAccountInfoFragment
  ) }
);

export type WhatsappMessageSendFailedSubscriptionVariables = {};


export type WhatsappMessageSendFailedSubscription = (
  { __typename?: 'Subscription' }
  & { whatsappMessageSendFailed: (
    { __typename?: 'WhatsappMessage' }
    & WhatsappMessageInfoFragment
  ) }
);

export type WhatsappAccountLoginFailedSubscriptionVariables = {};


export type WhatsappAccountLoginFailedSubscription = (
  { __typename?: 'Subscription' }
  & { whatsappAccountLoginFailed: (
    { __typename?: 'WhatsappAccount' }
    & WhatsappAccountInfoFragment
  ) }
);

export type RemarkUserFieldsFragment = (
  { __typename?: 'SystemUser' }
  & Pick<SystemUser, 'fullName' | 'alias' | 'avatarUrl'>
  & { id: SystemUser['_id'] }
);

export type RemarkFieldsFragment = (
  { __typename?: 'Remark' }
  & Pick<Remark, 'content' | 'levelType' | 'type' | 'isPinned' | 'isFlagged' | 'subjectId' | 'createDateTime' | 'lastModifiedDateTime' | 'isArchived'>
  & { id: Remark['_id'] }
  & { enrollmentSuitability: Maybe<(
    { __typename?: 'EnrollmentSuitability' }
    & Pick<EnrollmentSuitability, 'status'>
  )>, createdBy: Maybe<(
    { __typename?: 'SystemUser' }
    & RemarkUserFieldsFragment
  )>, lastModifiedBy: Maybe<(
    { __typename?: 'SystemUser' }
    & RemarkUserFieldsFragment
  )> }
);

export type GetRemarksQueryVariables = {
  filterInput?: Maybe<RemarkFilterInput>,
  pageInfo?: Maybe<PageInfo>,
  search?: Maybe<Scalars['String']>,
  sortInfo?: Maybe<SortInfo>
};


export type GetRemarksQuery = (
  { __typename?: 'Query' }
  & { remarks: Maybe<(
    { __typename?: 'RemarkPage' }
    & { items: Array<(
      { __typename?: 'Remark' }
      & RemarkFieldsFragment
    )> }
  )> }
);

export type GetAttendanceRemarksQueryVariables = {
  id: Scalars['ObjectId'],
  includesStudentRemarks?: Maybe<Scalars['Boolean']>,
  showArchived?: Maybe<Scalars['Boolean']>
};


export type GetAttendanceRemarksQuery = (
  { __typename?: 'Query' }
  & { attendanceRemarks: Maybe<Array<(
    { __typename?: 'Remark' }
    & RemarkFieldsFragment
  )>> }
);

export type CreateRemarkMutationVariables = {
  input: CreateRemarkInput,
  attendanceId?: Maybe<Scalars['ObjectId']>
};


export type CreateRemarkMutation = (
  { __typename?: 'Mutation' }
  & { createRemark: Maybe<(
    { __typename?: 'Remark' }
    & { attendance: Maybe<(
      { __typename?: 'Attendance' }
      & { id: Attendance['_id'] }
      & { remarks: Maybe<Array<(
        { __typename?: 'Remark' }
        & RemarkFieldsFragment
      )>> }
    )> }
    & RemarkFieldsFragment
  )> }
);

export type UpdateRemarkMutationVariables = {
  input: UpdateRemarkInput,
  attendanceId?: Maybe<Scalars['ObjectId']>
};


export type UpdateRemarkMutation = (
  { __typename?: 'Mutation' }
  & { updateRemark: Maybe<(
    { __typename?: 'Remark' }
    & { attendance: Maybe<(
      { __typename?: 'Attendance' }
      & { id: Attendance['_id'] }
      & { remarks: Maybe<Array<(
        { __typename?: 'Remark' }
        & RemarkFieldsFragment
      )>> }
    )> }
    & RemarkFieldsFragment
  )> }
);

export type DeleteRemarkMutationVariables = {
  input: IdInput,
  attendanceId?: Maybe<Scalars['ObjectId']>
};


export type DeleteRemarkMutation = (
  { __typename?: 'Mutation' }
  & { deleteRemark: Maybe<(
    { __typename?: 'Remark' }
    & { attendance: Maybe<(
      { __typename?: 'Attendance' }
      & { id: Attendance['_id'] }
      & { remarks: Maybe<Array<(
        { __typename?: 'Remark' }
        & RemarkFieldsFragment
      )>> }
    )> }
    & RemarkFieldsFragment
  )> }
);

export type ChangeRemarkArchiveMutationVariables = {
  id: Scalars['ObjectId'],
  isArchived: Scalars['Boolean'],
  attendanceId?: Maybe<Scalars['ObjectId']>
};


export type ChangeRemarkArchiveMutation = (
  { __typename?: 'Mutation' }
  & { changeRemarkArchive: Maybe<(
    { __typename?: 'Remark' }
    & { attendance: Maybe<(
      { __typename?: 'Attendance' }
      & { id: Attendance['_id'] }
      & { remarks: Maybe<Array<(
        { __typename?: 'Remark' }
        & RemarkFieldsFragment
      )>> }
    )> }
    & RemarkFieldsFragment
  )> }
);

export type UploadFileMutationVariables = {
  file: Scalars['Upload'],
  path: UploadFilePath
};


export type UploadFileMutation = (
  { __typename?: 'Mutation' }
  & { uploadFile: Maybe<(
    { __typename?: 'UploadResult' }
    & Pick<UploadResult, 'remoteUrl' | 'url'>
  )> }
);

export type StudentFieldsInPromotionFragment = (
  { __typename?: 'Student' }
  & Pick<Student, 'fullName' | 'alias' | 'gender' | 'avatarUrl' | 'birthDate' | 'userId' | 'primaryStream' | 'secondaryStream'>
  & { id: Student['_id'] }
  & { primarySchool: Maybe<(
    { __typename?: 'School' }
    & Pick<School, 'name' | 'alias' | 'educationStage'>
    & { id: School['_id'] }
  )>, secondarySchool: Maybe<(
    { __typename?: 'School' }
    & Pick<School, 'alias' | 'educationStage' | 'name'>
    & { id: School['_id'] }
  )>, academicLevel: Maybe<(
    { __typename?: 'AcademicLevel' }
    & Pick<AcademicLevel, 'alias' | 'name' | 'educationStage'>
    & { id: AcademicLevel['_id'] }
  )> }
);

export type PromotionsQueryVariables = {};


export type PromotionsQuery = (
  { __typename?: 'Query' }
  & { promotions: Maybe<Array<(
    { __typename?: 'Promotion' }
    & PromotionFieldsFragment
  )>> }
);

export type QueryStudentsInPromotionQueryVariables = {
  search?: Maybe<Scalars['String']>,
  filterInput?: Maybe<StudentFilterInput>,
  sortInfo?: Maybe<SortInfo>,
  pageInfo?: Maybe<PageInfo>
};


export type QueryStudentsInPromotionQuery = (
  { __typename?: 'Query' }
  & { students: Maybe<(
    { __typename?: 'StudentPage' }
    & Pick<StudentPage, 'pageIndex' | 'pageSize' | 'total'>
    & { items: Array<(
      { __typename?: 'Student' }
      & StudentFieldsInPromotionFragment
    )> }
  )> }
);

export const RemarkUserFields = gql`
    fragment RemarkUserFields on SystemUser {
  id: _id
  fullName
  alias
  avatarUrl
}
    `;
export const RemarkFields = gql`
    fragment RemarkFields on Remark {
  id: _id
  content
  levelType
  type
  isPinned
  isFlagged
  enrollmentSuitability {
    status
  }
  createdBy {
    ...RemarkUserFields
  }
  subjectId
  createDateTime
  lastModifiedDateTime
  lastModifiedBy {
    ...RemarkUserFields
  }
  isArchived
}
    ${RemarkUserFields}`;
export const BasicParentFields = gql`
    fragment BasicParentFields on Parent {
  id: _id
  fullName
  alias
  status
  gender
  salutation
  email
  alternativeEmails
  avatarUrl
  address {
    streetAddress
    gateBuilding
    link
    unit
    country
    postalCode
  }
  remark {
    ...RemarkFields
  }
  whatsAppName
  userId
  alternateUserId
  isArchived
  createDateTime
}
    ${RemarkFields}`;
export const TelephoneFields = gql`
    fragment TelephoneFields on Telephone {
  countryCode
  number
}
    `;
export const ArrangementLessonFields = gql`
    fragment ArrangementLessonFields on Lesson {
  id: _id
  lessonDay
  lessonStart
  lessonEnd
  lessonNumber
  maximumClassCapacity
  academicWeek {
    weekNumber
    startDate
    endDate
  }
  attendance {
    status
    type
    student {
      id: _id
      fullName
    }
  }
  holidays {
    name
    period {
      start
    }
  }
  class {
    id: _id
    academicYear {
      year
    }
    course {
      colorCode
    }
    name
    teacher {
      id: _id
      fullName
    }
  }
}
    `;
export const CreateByFields = gql`
    fragment CreateByFields on SystemUser {
  id: _id
  email
  fullName
}
    `;
export const CourseShortInfo = gql`
    fragment CourseShortInfo on Course {
  id: _id
  name
  nameShort
  avatarUrl
  status
  type
  createdBy {
    ...CreateByFields
  }
  createDateTime
}
    ${CreateByFields}`;
export const BasicAcademicLevelFields = gql`
    fragment BasicAcademicLevelFields on AcademicLevel {
  id: _id
  orderNo
  name
  alias
  educationStage
  isVisible
  isArchived
  createDateTime
  createdBy {
    ...CreateByFields
  }
}
    ${CreateByFields}`;
export const BasicTermFields = gql`
    fragment BasicTermFields on Term {
  term1Period {
    start
    end
  }
  term2Period {
    start
    end
  }
  term3Period {
    start
    end
  }
  term4Period {
    start
    end
  }
}
    `;
export const BasicAcademicYearFields = gql`
    fragment BasicAcademicYearFields on AcademicYear {
  id: _id
  year
  taxRate
  period {
    start
    end
  }
  academicWeekStart
  academicWeekEnd
  academicWeeks {
    startDate
    endDate
    weekNumber
  }
  terms {
    id: _id
    academicLevels {
      ...BasicAcademicLevelFields
    }
    ...BasicTermFields
  }
  termBillingDueDate {
    term1
    term2
    term3
    term4
  }
  groupAssignment {
    id: _id
    academicYear {
      id: _id
    }
    items {
      weekNumber
      startDate
      endDate
      groupA
      groupB
    }
    isDeleted
    createDateTime
  }
}
    ${BasicAcademicLevelFields}
${BasicTermFields}`;
export const AddressFields = gql`
    fragment AddressFields on Address {
  line1
  unit
  country
  postalCode
}
    `;
export const BasicBranchFields = gql`
    fragment BasicBranchFields on Branch {
  id: _id
  name
  alias
  address {
    ...AddressFields
  }
  isArchived
  createDateTime
}
    ${AddressFields}`;
export const BasicVenueFields = gql`
    fragment BasicVenueFields on Venue {
  id: _id
  name
  unitNo
  shorthand
  capacity
  isArchived
  isDigital
  createDateTime
  section
  address {
    line1
    link
    postalCode
  }
  branch {
    ...BasicBranchFields
  }
  googleCalendarId
  googleCalendarHostEmail
}
    ${BasicBranchFields}`;
export const CapacityFields = gql`
    fragment CapacityFields on ClassCapacityDTO {
  regular
  replacement
  used
  waiting
  seats {
    seatDetails {
      enrollmentStartDate
      enrollmentEndDate
      student {
        id: _id
        fullName
        gender
        avatarUrl
        userId
        alternateUserId
        primarySchool {
          id: _id
          name
        }
        secondarySchool {
          id: _id
          name
        }
        academicLevel {
          id: _id
          educationStage
        }
      }
      enrollmentStatus
      type
      seatType
      hasPaid
      isEnrollmentStarted
    }
  }
}
    `;
export const ArrangementClassFields = gql`
    fragment ArrangementClassFields on Class {
  id: _id
  name
  academicWeeks {
    startDate
    endDate
    weekNumber
  }
  lessons {
    ...ArrangementLessonFields
  }
  defaultLessonDays
  defaultLessonStart
  defaultLessonEnd
  teacher {
    id: _id
    fullName
    salutation
    gender
    initials
  }
  course {
    colorCode
    ...CourseShortInfo
    courseFee {
      isApplicable
      standardCourseFees {
        type
        amount
      }
      otherFees {
        name
        amount
        isApplicable
      }
    }
    makeUpCredits {
      term1
      term2
      term3
      term4
    }
  }
  academicYear {
    ...BasicAcademicYearFields
  }
  term {
    term1Period {
      start
      end
    }
    term2Period {
      start
      end
    }
    term3Period {
      start
      end
    }
    term4Period {
      start
      end
    }
  }
  venue {
    ...BasicVenueFields
  }
  capacity {
    ...CapacityFields
  }
  learningArrangement
}
    ${ArrangementLessonFields}
${CourseShortInfo}
${BasicAcademicYearFields}
${BasicVenueFields}
${CapacityFields}`;
export const ParentFieldsWithoutRelatedUser = gql`
    fragment ParentFieldsWithoutRelatedUser on Parent {
  id: _id
  alias
  email
  avatarUrl
  createDateTime
  gender
  fullName
  salutation
  alternativeEmails
  userId
  alternateUserId
  linkedWhatsappAccount {
    number {
      countryCode
      number
    }
  }
  whatsAppName
  address {
    country
    streetAddress
    gateBuilding
    postalCode
    unit
  }
  homePhone {
    countryCode
    number
  }
  mobile {
    countryCode
    number
  }
  officePhone {
    countryCode
    number
  }
}
    `;
export const StudentFieldsWithoutRelatedUser = gql`
    fragment StudentFieldsWithoutRelatedUser on Student {
  id: _id
  fullName
  alias
  gender
  avatarUrl
  birthDate
  email
  alternativeEmails
  remarks {
    ...RemarkFields
  }
  userId
  alternateUserId
  mobile {
    countryCode
    number
  }
  homePhone {
    countryCode
    number
  }
  primarySchool {
    id: _id
    name
    alias
    educationStage
  }
  primaryStream
  secondarySchool {
    id: _id
    alias
    educationStage
    name
  }
  secondaryStream
  academicLevel {
    id: _id
    alias
    name
    educationStage
  }
  enrollments {
    type
    class {
      id: _id
      name
      course {
        name
      }
    }
  }
  consent {
    termCondition
    accountCondition
    statements
    PhotographyOrVideography
  }
  primaryParent {
    id: _id
    fullName
    salutation
    alias
  }
  enrollmentMatters {
    id: _id
    fullName
    salutation
    alias
  }
  academicMatters {
    id: _id
    fullName
    salutation
    alias
  }
}
    ${RemarkFields}`;
export const ParentFields = gql`
    fragment ParentFields on Parent {
  id: _id
  alias
  email
  avatarUrl
  createDateTime
  gender
  fullName
  remark {
    ...RemarkFields
  }
  alternativeEmails
  userId
  alternateUserId
  salutation
  status
  voidRemarks
  relatedParents {
    ...ParentFieldsWithoutRelatedUser
  }
  relatedStudents {
    ...StudentFieldsWithoutRelatedUser
  }
  linkedWhatsappAccount {
    number {
      number
      countryCode
    }
  }
  whatsAppName
  address {
    country
    streetAddress
    gateBuilding
    postalCode
    link
    unit
  }
  homePhone {
    countryCode
    number
  }
  mobile {
    countryCode
    number
  }
  officePhone {
    countryCode
    number
  }
}
    ${RemarkFields}
${ParentFieldsWithoutRelatedUser}
${StudentFieldsWithoutRelatedUser}`;
export const StudentBaseFields = gql`
    fragment StudentBaseFields on Student {
  id: _id
  fullName
  alias
  status
  voidRemarks
  gender
  avatarUrl
  birthDate
  email
  alternativeEmails
  remarks {
    ...RemarkFields
  }
  address {
    country
    streetAddress
    gateBuilding
    postalCode
    link
    unit
  }
  userId
  alternateUserId
  mobile {
    countryCode
    number
  }
  homePhone {
    countryCode
    number
  }
  primarySchool {
    id: _id
    name
    alias
    educationStage
  }
  primaryStream
  secondarySchool {
    id: _id
    alias
    educationStage
    name
  }
  secondaryStream
  academicLevel {
    id: _id
    alias
    name
    educationStage
  }
}
    ${RemarkFields}`;
export const StudentFields = gql`
    fragment StudentFields on Student {
  ...StudentBaseFields
  enrollments {
    type
    class {
      id: _id
      name
    }
  }
  consent {
    termCondition
    accountCondition
    statements
    PhotographyOrVideography
  }
  primaryParent {
    id: _id
    fullName
    salutation
    alias
    email
    mobile {
      countryCode
      number
    }
    whatsAppName
    linkedWhatsappAccount {
      number {
        number
        countryCode
      }
    }
  }
  enrollmentMatters {
    id: _id
    fullName
    salutation
    alias
    email
  }
  academicMatters {
    id: _id
    fullName
    salutation
    alias
    email
  }
  siblings {
    ...StudentFieldsWithoutRelatedUser
  }
  parents {
    ...ParentFieldsWithoutRelatedUser
  }
}
    ${StudentBaseFields}
${StudentFieldsWithoutRelatedUser}
${ParentFieldsWithoutRelatedUser}`;
export const TeacherCellFields = gql`
    fragment TeacherCellFields on SystemUser {
  id: _id
  fullName
  gender
  avatarUrl
  salutation
  initials
}
    `;
export const LineItemFields = gql`
    fragment LineItemFields on InvoiceLineItem {
  id: _id
  description
  discountId
  discountType
  discountAmount
  discountRate
  discountRemarks
  unitAmount
  quantity
  lineAmount
  paid
  remarks
  lessons {
    lesson {
      lessonNumber
      lessonStart
      lessonEnd
      id: _id
    }
    isMakeUp
  }
  class {
    name
    defaultLessonDays
    defaultLessonStart
    defaultLessonEnd
    isAdHoc
    type
    teacher {
      ...TeacherCellFields
    }
    venue {
      shorthand
      unitNo
      branch {
        name
        alias
      }
    }
    course {
      type
      courseFee {
        billingFrequency
        standardCourseFees {
          type
        }
      }
    }
    learningArrangement
  }
}
    ${TeacherCellFields}`;
export const InvoiceFields = gql`
    fragment InvoiceFields on Invoice {
  id: _id
  destination {
    id: _id
    type
    status
    registration {
      id: _id
      type
    }
    arrangements {
      id: _id
      type
      status
    }
  }
  createDateTime
  createdBy {
    id: _id
  }
  class {
    id: _id
    name
    defaultLessonDays
    defaultLessonStart
    defaultLessonEnd
    isAdHoc
    academicYear {
      id: _id
      year
    }
    teacher {
      id: _id
      fullName
      gender
      avatarUrl
    }
    venue {
      ...BasicVenueFields
    }
  }
  referenceNumber
  status
  billedTo {
    ...ParentFields
  }
  billedFor {
    ...StudentFields
  }
  lineItems {
    ...LineItemFields
  }
  dueDate
  subtotal
  totalTax
  total
  amountPaid
}
    ${BasicVenueFields}
${ParentFields}
${StudentFields}
${LineItemFields}`;
export const ListArrangementFields = gql`
    fragment ListArrangementFields on Arrangement {
  id: _id
  type
  status
  student {
    id: _id
    gender
    fullName
    avatarUrl
    userId
    alternateUserId
    primarySchool {
      id: _id
      name
    }
    primaryParent {
      ...BasicParentFields
      mobile {
        ...TelephoneFields
      }
    }
  }
  fromClass {
    ...ArrangementClassFields
  }
  fromLesson {
    ...ArrangementLessonFields
  }
  toClass {
    ...ArrangementClassFields
  }
  toLesson {
    ...ArrangementLessonFields
  }
  invoice {
    ...InvoiceFields
  }
  originInvoice {
    ...InvoiceFields
  }
  remarks
  useCredits
  createdBy {
    ...CreateByFields
  }
  createDateTime
  lastModifiedBy {
    ...CreateByFields
  }
  isCancelHasFullLesson
  lastModifiedDateTime
  reminders {
    sendStatus
    sendDate
    type
    whatsappMessage {
      status
      sentDate
    }
  }
}
    ${BasicParentFields}
${TelephoneFields}
${ArrangementClassFields}
${ArrangementLessonFields}
${InvoiceFields}
${CreateByFields}`;
export const ListArrangementIndexFields = gql`
    fragment ListArrangementIndexFields on ArrangementIndex {
  id: _id
  name
  type
  status
  waStatus
  emailStatus
  student {
    id: _id
    fullName
    gender
    avatarUrl
    userId
  }
  fromClass {
    id: _id
    name
  }
  fromClassTeacher {
    id: _id
    fullName
    salutation
    gender
    avatarUrl
  }
  fromLesson {
    id: _id
    lessonStart
    lessonEnd
  }
  toClass {
    id: _id
    name
  }
  toClassTeacher {
    id: _id
    fullName
    salutation
    gender
    avatarUrl
  }
  toLesson {
    id: _id
    lessonStart
    lessonEnd
  }
  lastModifiedByFullName
  lastModifiedDateTime
  academicYear {
    id: _id
    year
  }
  course {
    id: _id
    nameShort
  }
  effectedHolidays {
    id: _id
    name
  }
  invoice {
    id: _id
    status
  }
  isAdhocAdditionalLesson
}
    `;
export const TermFields = gql`
    fragment TermFields on Term {
  term1Period {
    start
    end
  }
  term2Period {
    start
    end
  }
  term3Period {
    start
    end
  }
  term4Period {
    start
    end
  }
}
    `;
export const ArrangementClassCardFields = gql`
    fragment ArrangementClassCardFields on Class {
  id: _id
  name
  type
  defaultLessonStart
  defaultLessonEnd
  learningArrangement
  defaultLessonDays
  dailySessionTimeSlots {
    start
    end
  }
  academicYear {
    id: _id
    year
  }
  teacher {
    ...TeacherCellFields
  }
  course {
    id: _id
    name
    type
    avatarUrl
    colorCode
  }
  venue {
    shorthand
    unitNo
    branch {
      name
      alias
    }
  }
  enrollments {
    _id
    type
    isReserved
  }
  capacity {
    ...CapacityFields
  }
}
    ${TeacherCellFields}
${CapacityFields}`;
export const MakeUpClassField = gql`
    fragment MakeUpClassField on Class {
  ...ArrangementClassCardFields
  course {
    id: _id
    name
    makeUpCredits {
      term1
      term2
      term3
      term4
    }
    type
  }
  academicYear {
    year
  }
}
    ${ArrangementClassCardFields}`;
export const MakeUpCardFields = gql`
    fragment MakeUpCardFields on Arrangement {
  fromClass {
    ...ArrangementClassCardFields
  }
  fromLesson {
    id: _id
    lessonStart
    lessonEnd
    lessonNumber
  }
  toClass {
    ...ArrangementClassCardFields
  }
  toLesson {
    id: _id
    lessonStart
    lessonEnd
    lessonNumber
  }
}
    ${ArrangementClassCardFields}`;
export const MakeUpCreditInfoFragment = gql`
    fragment makeUpCreditInfoFragment on StudentReplacementCreditDTO {
  currentTerm {
    termNumber
    used
    exceptions
    total
  }
  term1 {
    used
    exceptions
    total
  }
  term2 {
    used
    exceptions
    total
  }
  term3 {
    used
    exceptions
    total
  }
  term4 {
    used
    exceptions
    total
  }
  workshop {
    used
    exceptions
    total
  }
}
    `;
export const MakeUpCheckLessonField = gql`
    fragment MakeUpCheckLessonField on Lesson {
  id: _id
  lessonDay
  lessonStart
  lessonEnd
  lessonNumber
  maximumClassCapacity
}
    `;
export const MakeUpCheckClassField = gql`
    fragment MakeUpCheckClassField on Class {
  ...ArrangementClassCardFields
  shorthand
  course {
    nameShort
    colorCode
    makeUpCredits {
      term1
      term2
      term3
      term4
    }
  }
  learningArrangement
}
    ${ArrangementClassCardFields}`;
export const LessonCapacityFields = gql`
    fragment LessonCapacityFields on LessonCapacityDTO {
  regular
  replacement
  used
  replacing
  remainingRegularSeat
  remainingReplacementSeat
  seats {
    startDate
    endDate
    student {
      _id
      fullName
    }
    seatType
    type
    attendanceStatus
    hasPaid
    startDate
    isEnrollmentStarted
    attendanceId
  }
}
    `;
export const StudentCellFields = gql`
    fragment StudentCellFields on Student {
  id: _id
  fullName
  alias
  gender
  avatarUrl
  userId
  alternateUserId
}
    `;
export const CheckTransferFields = gql`
    fragment CheckTransferFields on Arrangement {
  id: _id
  currentStep
  type
  status
  fromClass {
    ...ArrangementClassCardFields
  }
  fromLesson {
    id: _id
    lessonNumber
    lessonStart
    lessonEnd
  }
  toClass {
    id: _id
    name
    type
    maximumMakeUpCapacity
    maximumClassCapacity
    learningArrangement
    term {
      ...BasicTermFields
    }
    ...ArrangementClassCardFields
  }
  selectedLessons {
    lesson {
      id: _id
      lessonStart
      lessonEnd
      lessonNumber
      lessonDay
      academicWeek {
        weekNumber
        startDate
        endDate
      }
      capacity {
        ...LessonCapacityFields
      }
      class {
        shorthand
      }
    }
    makeUpLesson {
      id: _id
      lessonStart
      lessonEnd
      lessonNumber
      lessonDay
      academicWeek {
        weekNumber
        startDate
        endDate
      }
      capacity {
        ...LessonCapacityFields
      }
      class {
        shorthand
      }
    }
  }
  enrollment {
    preferredLearningArrangement
    group
  }
  newEnrollment {
    preferredLearningArrangement
    group
  }
  toLesson {
    id: _id
    lessonNumber
    lessonStart
    lessonEnd
  }
  student {
    ...StudentCellFields
  }
}
    ${ArrangementClassCardFields}
${BasicTermFields}
${LessonCapacityFields}
${StudentCellFields}`;
export const TransferBillingArrangementLessonFields = gql`
    fragment TransferBillingArrangementLessonFields on Lesson {
  id: _id
  status
  lessonStart
  lessonEnd
  lessonNumber
  lessonDay
}
    `;
export const InvoiceItemFields = gql`
    fragment InvoiceItemFields on Invoice {
  id: _id
  createDateTime
  referenceNumber
  status
  snapshot {
    studentFullName
    parentFullName
    lessons {
      lessonStart
      lessonEnd
      id: _id
    }
  }
  isOnHold
  taxRate
  onHoldRemarks
  isImported
  isOtherFee
  arrangement {
    fromLesson {
      holidays {
        name
      }
    }
    isAdhocAdditionalLesson
  }
  class {
    id: _id
    name
    type
    defaultLessonDays
    defaultLessonStart
    defaultLessonEnd
    isAdHoc
    shorthand
    runNumber
    course {
      id: _id
      nameShort
      colorCode
    }
    academicYear {
      id: _id
      year
    }
    teacher {
      id: _id
      fullName
      gender
      avatarUrl
    }
    venue {
      shorthand
      unitNo
      branch {
        name
        alias
      }
    }
  }
  lineItems {
    ...LineItemFields
  }
  billedFor {
    id: _id
    gender
    fullName
    alternateUserId
    userId
    primarySchool {
      id: _id
      name
    }
    primaryParent {
      id: _id
    }
  }
  billedTo {
    id: _id
    fullName
    email
    mobile {
      countryCode
      number
    }
    financeStatement {
      id
      creditRemain
    }
  }
  destination {
    id: _id
    type
    isFullEnrollment
  }
  dueDate
  subtotal
  totalTax
  total
  amountPaid
  lastModifiedDateTime
  createDateTime
  nextInvoice {
    id: _id
    referenceNumber
    billedTo {
      id: _id
      fullName
      email
      mobile {
        countryCode
        number
      }
      financeStatement {
        id
        creditRemain
      }
    }
  }
}
    ${LineItemFields}`;
export const ArrangementBillingFields = gql`
    fragment ArrangementBillingFields on Arrangement {
  willAttendLessons {
    id: _id
    lessonNumber
    lessonStart
  }
  arrangementBillingCheck {
    amountUsed
    lessonsNeedToPay {
      id: _id
      lessonNumber
      lessonStart
    }
    lessonsNeedNotToPay {
      id: _id
      lessonNumber
      lessonStart
    }
    remainingFee
    updatedLessonFee
    feeDifference
  }
  toClass {
    ...ArrangementClassCardFields
    lessons {
      ...TransferBillingArrangementLessonFields
    }
    learningArrangement
  }
  fromClass {
    ...ArrangementClassCardFields
    lessons {
      ...TransferBillingArrangementLessonFields
    }
  }
  toLesson {
    id: _id
    lessonStart
    lessonEnd
    lessonNumber
  }
  fromLesson {
    id: _id
    lessonStart
    lessonEnd
    lessonNumber
  }
  invoice {
    ...InvoiceItemFields
  }
  originInvoice {
    id: _id
    total
    referenceNumber
    amountPaid
    lessons {
      id: _id
      status
      lessonStart
      lessonEnd
      lessonNumber
      lessonDay
    }
  }
}
    ${ArrangementClassCardFields}
${TransferBillingArrangementLessonFields}
${InvoiceItemFields}`;
export const BasicHolidayFields = gql`
    fragment BasicHolidayFields on Holiday {
  id: _id
  name
  period {
    start
    end
  }
  type
}
    `;
export const AcademicYearFields = gql`
    fragment AcademicYearFields on AcademicYear {
  ...BasicAcademicYearFields
  holidays {
    ...BasicHolidayFields
  }
}
    ${BasicAcademicYearFields}
${BasicHolidayFields}`;
export const AttendanceFields = gql`
    fragment AttendanceFields on Attendance {
  id: _id
  status
  type
  startDateTime
  linkInvoice {
    id: _id
    status
  }
  remarks {
    ...RemarkFields
  }
  enrollment {
    type
    status
    startDate
    endDate
    preferredLearningArrangement
    group
  }
  linkedAttendance {
    startDateTime
    class {
      name
    }
  }
  student {
    id: _id
    fullName
    gender
    avatarUrl
    userId
    alternateUserId
    primarySchool {
      id: _id
      name
    }
    secondarySchool {
      id: _id
      name
    }
    academicLevel {
      id: _id
      educationStage
    }
    enrollments {
      isReserved
      class {
        _id
      }
      group
    }
  }
}
    ${RemarkFields}`;
export const LessonFields = gql`
    fragment LessonFields on Lesson {
  id: _id
  lessonNumber
  lessonStart
  lessonEnd
  lessonDay
  maximumClassCapacity
  isHoliday
  teacher {
    id: _id
    fullName
    salutation
    gender
    avatarUrl
    initials
  }
  venue {
    ...BasicVenueFields
  }
  academicWeek {
    startDate
    endDate
    weekNumber
  }
  attendance {
    ...AttendanceFields
  }
  capacity {
    ...LessonCapacityFields
  }
}
    ${BasicVenueFields}
${AttendanceFields}
${LessonCapacityFields}`;
export const BasicTeacherFields = gql`
    fragment BasicTeacherFields on SystemUser {
  id: _id
  fullName
  alias
  gender
  initials
  salutation
  avatarUrl
  userId
  alternateUserId
  createDateTime
}
    `;
export const LessonInClassFields = gql`
    fragment LessonInClassFields on Lesson {
  id: _id
  status
  lessonNumber
  lessonStart
  lessonEnd
  lessonNotes
  academicWeek {
    weekNumber
  }
  teacher {
    ...BasicTeacherFields
  }
  venue {
    shorthand
    unitNo
    branch {
      name
      alias
    }
  }
  isHoliday
}
    ${BasicTeacherFields}`;
export const UserFields = gql`
    fragment UserFields on SystemUser {
  id: _id
  fullName
  avatarUrl
}
    `;
export const BulkCreateHistoryFields = gql`
    fragment BulkCreateHistoryFields on BulkCreateHistory {
  id: _id
  status
  total
  failed
  processed
  type
  isExportFailedDocuments
  createdBy {
    id: _id
    ...UserFields
  }
}
    ${UserFields}`;
export const AcademicFields = gql`
    fragment AcademicFields on AcademicLevel {
  id: _id
  name
  alias
  educationStage
}
    `;
export const CourseFields = gql`
    fragment CourseFields on Course {
  id: _id
  name
  nameShort
  onGoingClassNumber
  avatarUrl
}
    `;
export const TaskChecklistItemFields = gql`
    fragment TaskChecklistItemFields on TaskChecklistItem {
  id: _id
  name
  isDone
  assigneeIds
  assignees {
    _id
    initials
    fullName
    avatarUrl
  }
}
    `;
export const TaskChecklistFields = gql`
    fragment TaskChecklistFields on TaskChecklist {
  id: _id
  name
  assigneeIds
  assignees {
    _id
    initials
    fullName
    avatarUrl
  }
  items {
    ...TaskChecklistItemFields
  }
}
    ${TaskChecklistItemFields}`;
export const TaskFields = gql`
    fragment TaskFields on Task {
  id: _id
  listId
  type
  status
  waStatus
  emailStatus
  name
  isTemplate
  assigneeIds
  lastModifiedDateTime
  creationContext {
    type
    isTransferLessonReminder
  }
  assignees {
    _id
    initials
    fullName
    avatarUrl
  }
  balance {
    id: _id
    balance
    parent {
      id: _id
      fullName
      mobile {
        countryCode
        number
      }
      linkedWhatsappAccount {
        id: _id
        number {
          countryCode
          number
        }
      }
    }
  }
  remarks {
    ...RemarkFields
    id: _id
    content
    createDateTime
  }
  holidays {
    name
    period {
      start
      end
    }
  }
  dueDate
  createDateTime
  description
  lesson {
    id: _id
    lessonNumber
    lessonStart
    holidays {
      name
      period {
        start
        end
      }
    }
  }
  enrollment {
    id: _id
    status
    startDate
    followUpStatus
    followUpStatusLastModifiedDateTime
    followUpStatusLastModifiedBy {
      ...UserFields
    }
    suitability {
      status
    }
    student {
      id: _id
      fullName
      userId
      primaryParent {
        id: _id
        fullName
        mobile {
          countryCode
          number
        }
        linkedWhatsappAccount {
          id: _id
          number {
            countryCode
            number
          }
        }
      }
    }
    class {
      id: _id
      shorthand
    }
    registration {
      id: _id
      dueDate
      name
      status
      reservationsForTrial {
        id: _id
        status
        name
        class {
          id: _id
          shorthand
        }
      }
    }
    attendances {
      id: _id
      startDateTime
      status
    }
    arrangements {
      id: _id
      name
      type
      newEnrollmentId
      invoice {
        status
      }
    }
    invoices {
      status
      id: _id
      billingPeriod
    }
  }
  registration {
    id: _id
    status
    student {
      id: _id
      fullName
      userId
      primaryParent {
        id: _id
        fullName
        mobile {
          countryCode
          number
        }
        linkedWhatsappAccount {
          id: _id
          number {
            countryCode
            number
          }
        }
      }
    }
    class {
      id: _id
      shorthand
    }
    name
    dueDate
    enrollment {
      id: _id
      status
      followUpStatus
      followUpStatusLastModifiedDateTime
      followUpStatusLastModifiedBy {
        ...UserFields
      }
    }
  }
  invoice {
    id: _id
    referenceNumber
    dueDate
    status
    isOnHold
    onHoldRemarks
    billedFor {
      id: _id
      fullName
      userId
      primaryParent {
        id: _id
        fullName
        mobile {
          countryCode
          number
        }
        linkedWhatsappAccount {
          id: _id
          number {
            countryCode
            number
          }
        }
      }
    }
  }
  attendance {
    id: _id
    status
    student {
      id: _id
      fullName
      userId
      primaryParent {
        id: _id
        fullName
        mobile {
          countryCode
          number
        }
        linkedWhatsappAccount {
          id: _id
          number {
            countryCode
            number
          }
        }
      }
    }
    class {
      id: _id
      shorthand
      learningArrangement
    }
    lesson {
      lessonStart
      lessonEnd
      lessonNumber
    }
    linkArrangement {
      fromClass {
        id: _id
        shorthand
      }
      fromLesson {
        lessonStart
        lessonEnd
        lessonDay
        id: _id
      }
      toClass {
        id: _id
        shorthand
      }
      toLesson {
        lessonStart
        lessonEnd
        lessonDay
        id: _id
      }
    }
    enrollment {
      preferredLearningArrangement
    }
  }
  pinnedRemark {
    id: _id
    content
  }
  flagRemark {
    id: _id
    content
  }
  remarks {
    id: _id
    content
    createDateTime
  }
  changeLog {
    type
    id: _id
    affectAt
    classId
    lessonId
    previousTeacher {
      id: _id
      userId
      fullName
      salutation
      gender
    }
    teacher {
      id: _id
      userId
      fullName
      salutation
      gender
    }
    previousDigitalCredentials {
      zoomMeetingId
      zoomMeetingLink
      zoomMeetingPassword
      googleMeetId
      googleMeetLink
    }
    digitalCredentials {
      zoomMeetingId
      zoomMeetingLink
      zoomMeetingPassword
      googleMeetId
      googleMeetLink
    }
    previousLearningArrangement
    learningArrangement
    previousVenue {
      unitNo
      shorthand
      branch {
        alias
        name
      }
    }
    venue {
      unitNo
      shorthand
      branch {
        alias
        name
      }
    }
  }
  checklists {
    ...TaskChecklistFields
  }
  userRequest {
    _id
    referenceNumber
    startDate
    status
  }
}
    ${RemarkFields}
${UserFields}
${TaskChecklistFields}`;
export const TaskIndexFields = gql`
    fragment TaskIndexFields on TaskIndex {
  id: _id
  listId
  type
  status
  waStatus
  emailStatus
  name
  isTemplate
  assigneeIds
  creationContext {
    type
    isTransferLessonReminder
  }
  student {
    id: _id
    fullName
    alias
    gender
    avatarUrl
    userId
  }
  parent {
    id: _id
    fullName
    gender
    salutation
    mobile {
      countryCode
      number
    }
    avatarUrl
  }
  parentWhatsappAccount {
    id: _id
    number {
      countryCode
      number
    }
    parentId
  }
  balance {
    id: _id
    balance
  }
  remarks {
    ...RemarkFields
    id: _id
    content
    createDateTime
  }
  holidays {
    name
    period {
      start
      end
    }
  }
  dueDate
  createDateTime
  lastModifiedDateTime
  description
  lesson {
    id: _id
    lessonNumber
    lessonStart
    holidays {
      name
      period {
        start
        end
      }
    }
  }
  enrollment {
    id: _id
    status
    startDate
    followUpStatus
    followUpStatusLastModifiedDateTime
    followUpStatusLastModifiedBy {
      ...UserFields
    }
    suitability {
      status
    }
    class {
      id: _id
      shorthand
    }
    registration {
      id: _id
      dueDate
      name
      status
      reservationsForTrial {
        id: _id
        status
        name
        class {
          id: _id
          shorthand
        }
      }
    }
    attendances {
      id: _id
      startDateTime
      status
    }
    arrangements {
      id: _id
      name
      type
      newEnrollmentId
      invoice {
        status
      }
    }
    invoices {
      status
      id: _id
      billingPeriod
    }
  }
  registration {
    id: _id
    status
    class {
      id: _id
      shorthand
    }
    name
    dueDate
    enrollment {
      id: _id
      status
      followUpStatus
      followUpStatusLastModifiedDateTime
      followUpStatusLastModifiedBy {
        ...UserFields
      }
    }
  }
  invoice {
    id: _id
    referenceNumber
    dueDate
    status
    isOnHold
    onHoldRemarks
    billedFor {
      id: _id
      fullName
      userId
      primaryParent {
        id: _id
        fullName
        mobile {
          countryCode
          number
        }
        linkedWhatsappAccount {
          id: _id
          number {
            countryCode
            number
          }
        }
      }
    }
  }
  attendance {
    id: _id
    status
    class {
      id: _id
      shorthand
      learningArrangement
    }
    lesson {
      lessonStart
      lessonEnd
      lessonNumber
    }
    linkArrangement {
      fromClass {
        id: _id
        shorthand
      }
      fromLesson {
        lessonStart
        lessonEnd
        lessonDay
        id: _id
      }
      toClass {
        id: _id
        shorthand
      }
      toLesson {
        lessonStart
        lessonEnd
        lessonDay
        id: _id
      }
    }
    enrollment {
      preferredLearningArrangement
    }
  }
  pinnedRemark {
    id: _id
    content
  }
  flagRemark {
    id: _id
    content
  }
  remarks {
    id: _id
    content
    createDateTime
  }
  userRequest {
    _id
    referenceNumber
    startDate
    status
  }
  class {
    id: _id
    shorthand
  }
  changeLog {
    type
    id: _id
    affectAt
    classId
    lessonId
    previousTeacher {
      id: _id
      userId
      fullName
      salutation
      gender
    }
    teacher {
      id: _id
      userId
      fullName
      salutation
      gender
    }
    previousDigitalCredentials {
      zoomMeetingId
      zoomMeetingLink
      zoomMeetingPassword
      googleMeetId
      googleMeetLink
    }
    digitalCredentials {
      zoomMeetingId
      zoomMeetingLink
      zoomMeetingPassword
      googleMeetId
      googleMeetLink
    }
    previousLearningArrangement
    learningArrangement
    previousVenue {
      unitNo
      shorthand
      branch {
        alias
        name
      }
    }
    venue {
      unitNo
      shorthand
      branch {
        alias
        name
      }
    }
  }
}
    ${RemarkFields}
${UserFields}`;
export const InvoiceItemOptionFields = gql`
    fragment InvoiceItemOptionFields on InvoiceItemOption {
  id: _id
  description
  unitAmount
}
    `;
export const DiscountFields = gql`
    fragment discountFields on Discount {
  id: _id
  discountName
  discountType
  discountAmount
  discountRate
  isArchived
  isVisible
}
    `;
export const ListBalanceHistoryFields = gql`
    fragment ListBalanceHistoryFields on BalanceHistory {
  id: _id
  createDateTime
  lastModifiedBy {
    fullName
  }
  lastModifiedDateTime
  type
  amount
  balance
  description
  payments {
    id: _id
    details {
      invoice {
        id: _id
        referenceNumber
        billedFor {
          id: _id
          fullName
        }
        billedTo {
          id: _id
        }
        class {
          id: _id
          shorthand
          course {
            id: _id
            nameShort
          }
        }
      }
    }
  }
  invoices {
    id: _id
    referenceNumber
    billedFor {
      id: _id
      fullName
    }
    billedTo {
      id: _id
    }
    class {
      id: _id
      shorthand
    }
    course {
      id: _id
      nameShort
    }
  }
  refund {
    id: _id
    referenceNumber
  }
  createdBy {
    ...UserFields
  }
}
    ${UserFields}`;
export const ListReceiptFields = gql`
    fragment ListReceiptFields on Receipt {
  id: _id
  status
  referenceNumber
  createDateTime
  amount
  invoicePaidSnapshots {
    invoiceId
    paid
    beforePaid
    snapshot {
      studentFullName
      parentFullName
      lessons {
        id: _id
        lessonStart
        lessonEnd
      }
    }
  }
  paymentRecord {
    method
    amount
    cashAmount
    createDateTime
    paymentDate
    balanceAmount
    details {
      balanceAmount
      invoiceId
      invoice {
        id: _id
        createDateTime
        referenceNumber
        status
        isImported
        isOtherFee
        arrangement {
          fromLesson {
            holidays {
              name
            }
          }
        }
        class {
          id: _id
          name
          type
          defaultLessonDays
          defaultLessonStart
          defaultLessonEnd
          isAdHoc
          shorthand
          runNumber
          course {
            id: _id
            nameShort
            colorCode
          }
          academicYear {
            id: _id
            year
          }
          teacher {
            id: _id
            fullName
            gender
            avatarUrl
          }
          venue {
            shorthand
            unitNo
            branch {
              name
              alias
            }
          }
        }
        lineItems(isFormat: false) {
          ...LineItemFields
        }
        billedFor {
          id: _id
          gender
          fullName
          alternateUserId
          userId
          primarySchool {
            id: _id
            name
          }
        }
        billedTo {
          id: _id
          fullName
          email
          mobile {
            countryCode
            number
          }
          financeStatement {
            id
            creditRemain
          }
        }
        destination {
          id: _id
          type
          isFullEnrollment
        }
        dueDate
        subtotal
        totalTax
        total
        amountPaid
        lastModifiedDateTime
        createDateTime
        nextInvoice {
          id: _id
        }
        receipts {
          amount
          createDateTime
          status
          paymentRecord {
            amount
            method
            bankName
            lastDigits
            chequeNumber
            paymentDate
            paymentProcessor
            details {
              invoice {
                id: _id
              }
            }
          }
        }
        payments {
          _id
          amount
          balanceAmount
          createDateTime
          isVoid
          details {
            invoiceId
            amount
            balanceAmount
          }
        }
      }
    }
    receipt {
      amount
      createDateTime
      paymentRecord {
        amount
        method
        bankName
        lastDigits
        chequeNumber
        paymentProcessor
      }
    }
  }
  amountPaidSnapshot
}
    ${LineItemFields}`;
export const ParentCellFields = gql`
    fragment ParentCellFields on Parent {
  id: _id
  fullName
  alias
  salutation
  gender
  avatarUrl
  email
  mobile {
    countryCode
    number
  }
}
    `;
export const ListRefundFields = gql`
    fragment ListRefundFields on Refund {
  id: _id
  referenceNumber
  amount
  isVoid
  adminFee
  refundMethod
  processedDate
  refundedDate
  reference
  reason
  bankName
  bankAccountNumber
  chequeNumber
  payNowNumber
  paymentProcessor
  lastDigits
  lastModifiedBy {
    ...CreateByFields
  }
  lastModifiedDateTime
  parent {
    ...ParentCellFields
  }
  snapshot {
    parentFullName
  }
}
    ${CreateByFields}
${ParentCellFields}`;
export const ReceiptFields = gql`
    fragment ReceiptFields on Receipt {
  id: _id
  contact {
    ...ParentFields
  }
  referenceNumber
  status
  paymentRecord {
    id: _id
  }
  createDateTime
  createdBy {
    ...CreateByFields
  }
}
    ${ParentFields}
${CreateByFields}`;
export const PaymentFields = gql`
    fragment PaymentFields on Payment {
  id: _id
  method
  amount
  createDateTime
  depositDate
  reference
  remarks
  bankName
  lastDigits
  chequeNumber
  paymentProcessor
  isVoid
  receipt {
    ...ReceiptFields
  }
}
    ${ReceiptFields}`;
export const ListInvoiceFields = gql`
    fragment ListInvoiceFields on Invoice {
  id: _id
  createDateTime
  createdBy {
    id: _id
  }
  class {
    id: _id
    name
    defaultLessonDays
    defaultLessonStart
    defaultLessonEnd
    academicYear {
      id: _id
      year
    }
    teacher {
      id: _id
      fullName
      gender
      avatarUrl
    }
    shorthand
    runNumber
    isAdHoc
  }
  referenceNumber
  status
  billedFor {
    id: _id
    gender
    fullName
    alternateUserId
    userId
    primarySchool {
      id: _id
      name
    }
  }
  billedTo {
    id: _id
    fullName
    email
    mobile {
      countryCode
      number
    }
    financeStatement {
      id
      creditRemain
    }
  }
  lineItems {
    ...LineItemFields
  }
  destination {
    id: _id
    createDateTime
    createdBy {
      ...CreateByFields
    }
    type
  }
  dueDate
  subtotal
  totalTax
  total
  amountPaid
  lastModifiedDateTime
  createDateTime
  nextInvoice {
    id: _id
  }
}
    ${LineItemFields}
${CreateByFields}`;
export const LinkedInvoiceFields = gql`
    fragment LinkedInvoiceFields on Invoice {
  id: _id
  status
  referenceNumber
  total
  dueDate
  amountPaid
  billedFor {
    id: _id
    gender
    fullName
    alternateUserId
    userId
    primarySchool {
      id: _id
      name
    }
  }
  billedTo {
    ...ParentFields
  }
  course {
    id: _id
    name
    nameShort
    colorCode
  }
  lessons {
    _id
  }
  class {
    id: _id
    name
    academicYear {
      id: _id
      year
    }
    teacher {
      id: _id
      fullName
      gender
      avatarUrl
    }
  }
  createDateTime
  createdBy {
    ...CreateByFields
  }
}
    ${ParentFields}
${CreateByFields}`;
export const NotifyMissingInfoFields = gql`
    fragment NotifyMissingInfoFields on NotifyMissingDTO {
  parent {
    id: _id
    fullName
  }
  student {
    id: _id
    fullName
  }
  class {
    id: _id
    shorthand
  }
  course {
    id: _id
    name
  }
  teacher {
    id: _id
    fullName
    salutation
  }
  missingFields
}
    `;
export const InvoiceIndexFields = gql`
    fragment InvoiceIndexFields on InvoiceIndex {
  id: _id
  referenceNumber
  createDateTime
  total
  amountPaid
  isOtherFee
  status
  dueDate
  isOnHold
  onHoldRemarks
  academicYear {
    id: _id
    year
  }
  class {
    id: _id
    name
  }
  course {
    id: _id
    nameShort
  }
  student {
    id: _id
    fullName
    alias
    gender
    avatarUrl
    userId
  }
  billedToParent {
    id: _id
    fullName
    gender
    salutation
    avatarUrl
    mobile {
      countryCode
      number
    }
  }
  secondaryParents {
    id: _id
    fullName
    gender
    salutation
    avatarUrl
    mobile {
      countryCode
      number
    }
  }
  waStatus
  emailStatus
  paymentAdviceWaStatus
  paymentAdviceEmailStatus
  waAttachmentStatus
  paymentAdviceWaAttachmentStatus
}
    `;
export const ReceiptIndexFields = gql`
    fragment ReceiptIndexFields on ReceiptIndex {
  id: _id
  referenceNumber
  amountPaidSnapshot
  status
  amount
  amountDue
  waStatus
  emailStatus
  payment {
    method
    paymentDate
    createDateTime
    reference
    remarks
  }
  invoices {
    id: _id
    dueDate
    total
  }
  parent {
    id: _id
    fullName
    email
    mobile {
      countryCode
      number
    }
  }
  secondaryParents {
    id: _id
    fullName
    email
    mobile {
      countryCode
      number
    }
  }
  students {
    id: _id
    fullName
  }
  classes {
    id: _id
    shorthand
    type
  }
  academicYears {
    id: _id
    year
  }
  courses {
    id: _id
    nameShort
  }
}
    `;
export const BookletFields = gql`
    fragment BookletFields on MaterialEnvelopeBooklet {
  id: _id
  title
  serialNumber
  topic {
    _id
    name
  }
  worksheets {
    id: _id
    serialNumber
    title
  }
}
    `;
export const MaterialEnvelopeFields = gql`
    fragment MaterialEnvelopeFields on MaterialEnvelope {
  id: _id
  title
  academicWeeks {
    startDate
    endDate
    weekNumber
  }
  booklets {
    ...BookletFields
  }
}
    ${BookletFields}`;
export const DeliveryDetailFields = gql`
    fragment DeliveryDetailFields on Delivery {
  id: _id
  type
  status
  address
  remarks
  dropOffAddress
  dropOffMobile {
    countryCode
    number
  }
  dropOffRemarks
  dropOffPostalCode
  pickupStartAt
  pickupEndAt
  trackingNo
  trackingUrl
  status
  parcelSize
  admin {
    id: _id
    fullName
  }
  materials {
    id: _id
    student {
      id: _id
      fullName
      email
    }
    envelope {
      ...MaterialEnvelopeFields
    }
  }
}
    ${MaterialEnvelopeFields}`;
export const BookletDoFields = gql`
    fragment BookletDOFields on MaterialEnvelopeBookletDO {
  id: _id
  title
  serialNumber
  topic {
    _id
    name
  }
  worksheets {
    id: _id
    serialNumber
    title
  }
}
    `;
export const MaterialEnvelopeDoFields = gql`
    fragment MaterialEnvelopeDOFields on MaterialEnvelopeDO {
  id: _id
  title
  academicWeeks {
    startDate
    endDate
    weekNumber
  }
  booklets {
    ...BookletDOFields
  }
}
    ${BookletDoFields}`;
export const DistributionWithMaterialsFields = gql`
    fragment DistributionWithMaterialsFields on MaterialIndex {
  id: _id
  isAdHoc
  adHocMaterials
  createDateTime
  student {
    id: _id
    fullName
    userId
    gender
    avatarUrl
  }
  enrollmentStartAt
  enrollmentEndAt
  enrollments {
    type
    learningArrangement
  }
  hasUpcomingOnsiteGAPLesson
  upcomingOnsiteGAPLessonLA
  status
  lesson {
    lessonStart
    lessonEnd
    lessonNumber
  }
  remarks
  envelope {
    ...MaterialEnvelopeDOFields
  }
  lessonClass {
    id: _id
    shorthand
  }
  otherMaterials {
    id: _id
    course {
      id: _id
      nameShort
    }
    envelope {
      ...MaterialEnvelopeDOFields
    }
  }
  siblingMaterials {
    id: _id
    course {
      id: _id
      nameShort
    }
    student {
      id: _id
      fullName
    }
    envelope {
      ...MaterialEnvelopeDOFields
    }
  }
  handoverOnsiteMaterialId
}
    ${MaterialEnvelopeDoFields}`;
export const PackingListBookletFields = gql`
    fragment PackingListBookletFields on PackingListEnvelopeBooklet {
  id: _id
  title
  serialNumber
  topic {
    _id
    name
  }
  worksheets {
    id: _id
    serialNumber
    title
  }
}
    `;
export const PackingListEnvelopeFields = gql`
    fragment PackingListEnvelopeFields on PackingListEnvelope {
  id: _id
  title
  status
  academicWeeks {
    startDate
    endDate
    weekNumber
  }
  booklets {
    ...PackingListBookletFields
  }
}
    ${PackingListBookletFields}`;
export const WhatsappMessageInfo = gql`
    fragment WhatsappMessageInfo on WhatsappMessage {
  id: _id
  message
  recipient
  status
  queueOrder
  sender {
    id: _id
    number {
      countryCode
      number
    }
  }
  parent {
    fullName
    avatarUrl
    salutation
    alias
  }
  template {
    title
  }
  registration {
    type
    student {
      fullName
      gender
      alias
      primarySchool {
        name
      }
      avatarUrl
    }
    class {
      name
      defaultLessonStart
      defaultLessonEnd
      teacher {
        id: _id
        fullName
        avatarUrl
        salutation
      }
      course {
        name
        type
        avatarUrl
        colorCode
      }
    }
  }
  arrangement {
    type
    student {
      fullName
      gender
      alias
      primarySchool {
        name
      }
      avatarUrl
    }
    fromClass {
      name
      defaultLessonStart
      defaultLessonEnd
      teacher {
        id: _id
        fullName
        avatarUrl
        salutation
      }
      course {
        name
        type
        avatarUrl
        colorCode
      }
    }
  }
  failedCount
  statusDate
  platform
  createDateTime
}
    `;
export const SystemNotificationFragment = gql`
    fragment SystemNotificationFragment on SystemNotification {
  _id
  type
  title
  message
  hyperLinkMessage
  read
  links {
    id
    type
    keyword
  }
  whatsappMessage {
    ...WhatsappMessageInfo
  }
  relatedUser {
    ...UserFields
  }
  createdBy {
    ...UserFields
  }
  createDateTime
  lastModifiedBy {
    ...UserFields
  }
  lastModifiedDateTime
}
    ${WhatsappMessageInfo}
${UserFields}`;
export const LastModifiedByFields = gql`
    fragment LastModifiedByFields on SystemUser {
  id: _id
  fullName
}
    `;
export const RegistrationCacheFields = gql`
    fragment RegistrationCacheFields on RegistrationIndex {
  id: _id
  class {
    id: _id
    learningArrangement
    name
  }
  course {
    id: _id
    name
  }
  classCapacity {
    used
    regular
    replacement
  }
  invoice {
    id: _id
    status
  }
  student {
    id: _id
    userId
    fullName
    gender
    avatarUrl
  }
  lastModifiedBy {
    ...LastModifiedByFields
  }
  lastModifiedDateTime
  name
  type
  status
  waStatus
  emailStatus
  enrollment {
    startDate
    preferredLearningArrangement
    group
    status
    followUpStatus
    followUpStatusLastModifiedDateTime
    followUpStatusLastModifiedByFullName
    _id
  }
  startDate
  createDateTime
}
    ${LastModifiedByFields}`;
export const BasicEnrollmentField = gql`
    fragment BasicEnrollmentField on Enrollment {
  id: _id
  type
  status
  startDate
  endDate
}
    `;
export const SmsFields = gql`
    fragment SMSFields on Class {
  id: _id
  name
  runNumber
  shorthand
  type
  defaultLessonDays
  defaultLessonEnd
  defaultLessonStart
  lessonNumber
  academicLevels {
    _id
  }
  teacher {
    id: _id
    fullName
    avatarUrl
    gender
    salutation
  }
  venue {
    id: _id
    unitNo
    shorthand
    branch {
      id: _id
      name
      alias
    }
  }
  enrollments(status: [Active, Holding]) {
    ...BasicEnrollmentField
    student {
      _id
      fullName
    }
    invoices {
      _id
      dueDate
    }
    attendances {
      _id
      endDateTime
      lesson {
        _id
      }
    }
    registration {
      trialRegistration {
        startDate
        class {
          shorthand
        }
      }
    }
  }
  lessons {
    _id
    lessonStart
    lessonNumber
  }
  maximumClassCapacity
  capacity {
    ...CapacityFields
  }
  learningArrangement
  digitalClassAccessCredentials {
    zoomMeetingLink
    zoomMeetingPassword
    zoomMeetingId
    googleMeetLink
    googleMeetId
  }
  course {
    id: _id
    nameShort
    colorCode
  }
}
    ${BasicEnrollmentField}
${CapacityFields}`;
export const NotifyClass = gql`
    fragment NotifyClass on Class {
  id: _id
  name
  defaultLessonStart
  defaultLessonEnd
  defaultLessonDays
  course {
    name
    id: _id
  }
  teacher {
    ...BasicTeacherFields
  }
  venue {
    shorthand
    unitNo
    branch {
      name
      alias
    }
  }
  learningArrangement
  enrollments {
    _id
    type
  }
  capacity {
    ...CapacityFields
  }
}
    ${BasicTeacherFields}
${CapacityFields}`;
export const AttendanceIndexFields = gql`
    fragment AttendanceIndexFields on AttendanceIndex {
  id: _id
  type
  status
  group
  lessonMode
  classId
  pinnedRemark
  latestLessonRemark
  flagRemark
  learningArrangement
  class {
    id: _id
    shorthand
    type
    learningArrangement
    academicYearId
  }
  academicLevels {
    name
  }
  linkArrangements {
    type
    fromClassId
    toClassId
    fromClassShorthand
    toClassShorthand
  }
  lesson {
    id: _id
    lessonNumber
    lessonStart
    lessonEnd
  }
  teacher {
    id: _id
    fullName
    salutation
    initials
    avatarUrl
    gender
  }
  statusTag {
    tags
    description
  }
  previousAttendance {
    id: _id
    type
    status
    classShorthand
  }
  trialAttendances {
    id: _id
    startDateTime
  }
  enrollment {
    id: _id
    type
    preferredLearningArrangement
    group
    suitability {
      status
    }
    followUpStatus
    followUpStatusLastModifiedDateTime
    followUpStatusLastModifiedByFullName
  }
  invoice {
    id: _id
    status
    dueDate
  }
  student {
    id: _id
    fullName
    alias
    avatarUrl
    userId
  }
  primaryParent {
    id: _id
    fullName
    salutation
    avatarUrl
    gender
    mobile {
      number
      countryCode
    }
  }
  primaryParentWhatsappAccount {
    id: _id
    number {
      number
      countryCode
    }
  }
  secondaryParents {
    id: _id
    fullName
    salutation
    avatarUrl
    gender
    mobile {
      number
      countryCode
    }
  }
  secondaryParentWhatsappAccounts {
    id: _id
    parentId
    number {
      number
      countryCode
    }
  }
}
    `;
export const LessonScheduleFields = gql`
    fragment LessonScheduleFields on LessonIndex {
  id: _id
  lessonDay
  lessonNumber
  lessonStart
  lessonEnd
  activeEnrolled
  present
  attending
  class {
    _id
    name
    type
    shorthand
    learningArrangement
  }
  course {
    id: _id
    name
    nameShort
    avatarUrl
    colorCode
  }
  teacher {
    id: _id
    fullName
    avatarUrl
    gender
    salutation
  }
  classTeacher {
    id: _id
    fullName
    avatarUrl
    gender
    salutation
  }
  venue {
    id: _id
    shorthand
    unitNo
    capacity
  }
  branch {
    id: _id
    alias
    name
  }
}
    `;
export const CampaignFields = gql`
    fragment CampaignFields on Campaign {
  id: _id
  emailSubject
  message
  name
  recipientTypes
  recipients {
    studentIds
    message
    parentId
    receiptId
  }
  sendMethod
  sentAt
  status
  usageType
  templateId
  scheduleSendAt
  realSendAt
  isRealSended
  recallAt
  isIncludeAttachment
  createdBy {
    ...CreateByFields
  }
}
    ${CreateByFields}`;
export const FeatureFields = gql`
    fragment FeatureFields on FeatureAccess {
  id: _id
  description
  enabled
  keywords
  name
}
    `;
export const IntegrationFields = gql`
    fragment IntegrationFields on Integration {
  id: _id
  type
  clientId
  accountId
  isConfigured
}
    `;
export const NotificationLogFragment = gql`
    fragment NotificationLogFragment on Reminder {
  id: _id
  type
  template {
    title
    type
    usageType
  }
  sender
  subject
  message
  destination
  receiver {
    id: _id
    email
    fullName
    avatarUrl
    gender
    salutation
    alias
    mobile {
      countryCode
      number
    }
  }
  student {
    id: _id
    fullName
    avatarUrl
    gender
    alias
    userId
  }
  class {
    id: _id
    name
    learningArrangement
  }
  attendance {
    type
    enrollment {
      preferredLearningArrangement
    }
  }
  registration {
    type
  }
  arrangement {
    type
  }
  status
  attachmentStatus
  sendDate
}
    `;
export const WhatsappAccountInfo = gql`
    fragment WhatsappAccountInfo on WhatsappAccount {
  id: _id
  name
  number {
    countryCode
    number
  }
  status
  statusDate
  createDateTime
  platform
  lastSentDateTime
  processingStatus
  statusDetail
  isArchived
  currentUserId
  hasFailedMessages
}
    `;
export const DepartmentFields = gql`
    fragment DepartmentFields on Department {
  id: _id
  name
  isArchived
  createDateTime
}
    `;
export const MailMergeFields = gql`
    fragment MailMergeFields on MailMerge {
  _id
  mergeList
  mergeListHeaders
  template {
    whatsAppMessage
  }
}
    `;
export const NotificationSettingFields = gql`
    fragment NotificationSettingFields on NotificationSetting {
  _id
  type
  groupName
  name
  isEnabled
  template {
    _id
    title
  }
  conditions {
    courseTypes
  }
}
    `;
export const PromotionFields = gql`
    fragment PromotionFields on Promotion {
  id: _id
  selectedStudents {
    id: _id
  }
  students {
    id: _id
  }
  scheduledDate
  status
  academicLevels {
    id: _id
    name
    orderNo
  }
}
    `;
export const BasicRewardHistoryFields = gql`
    fragment BasicRewardHistoryFields on RewardHistory {
  id: _id
  createDateTime
  isArchived
  createdBy {
    ...CreateByFields
  }
  type
  status
  adjustment
  student {
    fullName
    avatarUrl
    alias
    gender
    academicLevel {
      name
      educationStage
    }
    primarySchool {
      name
    }
  }
  remarks
  rewardPoints {
    points
  }
  orderDetail {
    reward {
      avatar
      name
      description
      points
    }
    quantity
    trackingNumber
    orderUrl
  }
  orderHistory {
    status
    createdBy {
      ...CreateByFields
    }
    createDateTime
  }
}
    ${CreateByFields}`;
export const BasicStudentBaseRewards = gql`
    fragment BasicStudentBaseRewards on Student {
  id: _id
  gender
  fullName
  avatarUrl
  isActive
  academicLevel {
    name
    educationStage
  }
  primarySchool {
    name
  }
  secondarySchool {
    name
  }
  rewardPointsCounter {
    remaining
    expiring
  }
  rewardPoints {
    id: _id
    status
    points
    used
  }
}
    `;
export const SchoolFields = gql`
    fragment SchoolFields on School {
  id: _id
  name
  alias
  educationStage
  isVisible
  isArchived
  createdBy {
    ...CreateByFields
  }
  createDateTime
}
    ${CreateByFields}`;
export const LogFields = gql`
    fragment LogFields on Log {
  id: _id
  userId
  log
  type
  attachment
  createDateTime
  isDeleted
  createdBy {
    ...CreateByFields
    avatarUrl
  }
}
    ${CreateByFields}`;
export const NoteFields = gql`
    fragment NoteFields on Note {
  id: _id
  userId
  note
  createDateTime
  isDeleted
  createdBy {
    ...UserFields
  }
}
    ${UserFields}`;
export const ParentIndexFields = gql`
    fragment ParentIndexFields on ParentIndex {
  id: _id
  fullName
  gender
  salutation
  alias
  alternateUserId
  alternativeEmails
  whatsAppName
  address {
    gateBuilding
    streetAddress
    unit
    country
    postalCode
  }
  email
  mobile {
    ...TelephoneFields
  }
  homePhone {
    ...TelephoneFields
  }
  officePhone {
    ...TelephoneFields
  }
  students {
    id: _id
    fullName
  }
  linkedWhatsappAccount {
    number {
      number
      countryCode
    }
  }
}
    ${TelephoneFields}`;
export const StudentIndexFields = gql`
    fragment StudentIndexFields on StudentIndex {
  id: _id
  fullName
  alias
  status
  avatarUrl
  email
  gender
  alternativeEmails
  birthDate
  userId
  mobile {
    countryCode
    number
  }
  primarySchool {
    name
  }
  secondarySchool {
    name
  }
  academicLevel {
    name
  }
  address {
    country
    gateBuilding
    link
    postalCode
    streetAddress
    unit
  }
  primaryParent {
    id: _id
    fullName
    email
    mobile {
      countryCode
      number
    }
    userId
  }
  primaryParentWhatsappAccount {
    id: _id
    number {
      number
      countryCode
    }
  }
  secondaryParents {
    id: _id
    fullName
    email
    mobile {
      countryCode
      number
    }
    userId
  }
  secondaryParentWhatsappAccounts {
    id: _id
    number {
      number
      countryCode
    }
  }
  siblings {
    fullName
  }
  enrollments {
    type
  }
  trialAttendances {
    id: _id
    startDateTime
  }
  examScores {
    id: _id
    marksPercentage
    year
    subject
    createDateTime
  }
}
    `;
export const StudentReportFilterFragment = gql`
    fragment StudentReportFilterFragment on StudentReportFilter {
  _id
  name
  dateRange {
    start
    end
  }
  show
  dateRangeType
  user {
    ...UserFields
  }
  courses {
    id: _id
    name
  }
  academicYears {
    id: _id
    year
  }
  teachers {
    id: _id
    fullName
  }
  lessonDays
  venues {
    ...BasicVenueFields
  }
  createdBy {
    ...UserFields
  }
  createDateTime
  lastModifiedBy {
    ...UserFields
  }
  lastModifiedDateTime
  isDeleted
  deletedAt
}
    ${UserFields}
${BasicVenueFields}`;
export const MerchandiseFields = gql`
    fragment MerchandiseFields on Merchandise {
  id: _id
  item
  distributionChannel
  dateIssued
  status
  remarks
}
    `;
export const StudentOfCourse = gql`
    fragment studentOfCourse on Student {
  id: _id
  fullName
  gender
  alias
  userId
  academicLevel {
    id: _id
    name
    educationStage
  }
  primarySchool {
    name
  }
  secondarySchool {
    name
  }
}
    `;
export const AcademicLevelFields = gql`
    fragment AcademicLevelFields on AcademicLevel {
  id: _id
  orderNo
  name
  alias
  educationStage
  isVisible
  isArchived
  createdBy {
    ...CreateByFields
  }
  createDateTime
}
    ${CreateByFields}`;
export const ScoreRecordField = gql`
    fragment ScoreRecordField on ScoreRecord {
  total
  score
}
    `;
export const ExamScoreField = gql`
    fragment ExamScoreField on ExamScore {
  id: _id
  year
  academicLevel {
    ...AcademicLevelFields
  }
  subject
  stream
  examType
  mcq {
    ...ScoreRecordField
  }
  qeq {
    ...ScoreRecordField
  }
  practical {
    ...ScoreRecordField
  }
  totalMarks {
    ...ScoreRecordField
  }
  marksPercentage
  scoreGrade
  createDateTime
  createdBy {
    ...CreateByFields
  }
}
    ${AcademicLevelFields}
${ScoreRecordField}
${CreateByFields}`;
export const SystemUserFields = gql`
    fragment SystemUserFields on SystemUser {
  id: _id
  fullName
  alias
  avatarUrl
  email
  gender
  initials
  salutation
  userId
  alternateUserId
  mobile {
    countryCode
    number
  }
  officePhone {
    countryCode
    number
  }
  branches {
    id: _id
    name
  }
  department {
    id: _id
    name
  }
  linkedWhatsappNumber {
    countryCode
    number
  }
}
    `;
export const AuditLogFields = gql`
    fragment AuditLogFields on AuditLog {
  id: _id
  principal {
    id: _id
    fullName
    avatarUrl
    initials
  }
  operationType
  resourceType
  resourceName
  description
  timestamp
  changes {
    fieldName
    newValue
    oldValue
  }
}
    `;
export const ReminderParentFields = gql`
    fragment ReminderParentFields on Parent {
  id: _id
  fullName
  salutation
  alias
  email
  mobile {
    countryCode
    number
  }
  whatsAppName
  linkedWhatsappAccountId
  linkedWhatsappAccount {
    number {
      number
      countryCode
    }
  }
}
    `;
export const StudentCellProfile = gql`
    fragment StudentCellProfile on Student {
  id: _id
  fullName
  gender
  avatarUrl
  userId
  alternateUserId
  eligibleArrangements
  primaryParent {
    id: _id
    remark {
      levelType
      content
    }
  }
  primarySchool {
    id: _id
    name
  }
  secondarySchool {
    id: _id
    name
  }
  academicLevel {
    id: _id
    educationStage
  }
}
    `;
export const BasicArrangementFields = gql`
    fragment BasicArrangementFields on Arrangement {
  id: _id
  type
  status
  name
  remarks
  useCredits
  createDateTime
  lastModifiedDateTime
  currentStep
}
    `;
export const TransferBillingArrangementClassFields = gql`
    fragment TransferBillingArrangementClassFields on Class {
  id: _id
  name
  defaultLessonDays
  defaultLessonStart
  defaultLessonEnd
  course {
    avatarUrl
    name
    colorCode
  }
  teacher {
    id: _id
    fullName
    initials
  }
  venue {
    shorthand
    unitNo
    branch {
      name
      alias
    }
  }
  enrollments {
    _id
    type
    isReserved
  }
  capacity {
    ...CapacityFields
  }
  learningArrangement
}
    ${CapacityFields}`;
export const TransferDetailCardFields = gql`
    fragment TransferDetailCardFields on Arrangement {
  id: _id
  status
  toClass {
    ...ArrangementClassCardFields
    learningArrangement
  }
  fromClass {
    ...ArrangementClassCardFields
  }
  enrollment {
    group
    preferredLearningArrangement
  }
  newEnrollment {
    group
    preferredLearningArrangement
  }
  toLesson {
    id: _id
    lessonStart
    lessonEnd
    lessonNumber
  }
  fromLesson {
    id: _id
    lessonStart
    lessonEnd
    lessonNumber
  }
  student {
    id: _id
  }
}
    ${ArrangementClassCardFields}`;
export const BasicCentreFields = gql`
    fragment BasicCentreFields on Centre {
  id: _id
  createDateTime
  isArchived
}
    `;
export const BasicClassFields = gql`
    fragment BasicClassFields on Class {
  id: _id
  name
  defaultLessonDays
  defaultLessonStart
  defaultLessonEnd
  maximumClassCapacity
  maximumMakeUpCapacity
  runNumber
  createDateTime
  status
  academicYear {
    year
  }
  isArchived
  capacity {
    ...CapacityFields
  }
  learningArrangement
}
    ${CapacityFields}`;
export const BasicAcademicWeekField = gql`
    fragment BasicAcademicWeekField on AcademicWeek {
  startDate
  endDate
  weekNumber
}
    `;
export const ClassEnrollmentFields = gql`
    fragment ClassEnrollmentFields on Enrollment {
  id: _id
  startDate
  endDate
  type
  status
  isReserved
  class {
    id: _id
  }
  attendances {
    _id
    lesson {
      _id
    }
    endDateTime
  }
  student {
    id: _id
    fullName
    avatarUrl
    gender
    primarySchool {
      id: _id
      name
    }
    secondarySchool {
      id: _id
      name
    }
    academicLevel {
      id: _id
      educationStage
    }
  }
  invoices {
    _id
    dueDate
  }
}
    `;
export const BasicCourierPickupAddressFields = gql`
    fragment BasicCourierPickupAddressFields on CourierPickupAddress {
  id: _id
  address
  postalCode
  isArchived
}
    `;
export const CourseFeeField = gql`
    fragment CourseFeeField on CourseFee {
  isApplicable
  standardCourseFees {
    type
    amount
  }
  otherFees {
    name
    isApplicable
    amount
  }
  billingFrequency
}
    `;
export const BasicCourseFields = gql`
    fragment BasicCourseFields on Course {
  id: _id
  avatarUrl
  name
  nameShort
  description
  status
  academicStreams
  type
  courseFee {
    ...CourseFeeField
  }
  createDateTime
  xeroAccountsSyncStatus
}
    ${CourseFeeField}`;
export const BasicPaymentRecordFields = gql`
    fragment BasicPaymentRecordFields on Payment {
  id: _id
  method
  amount
  createDateTime
  paymentDate
  depositDate
  remarks
  reference
  bankName
  lastDigits
  chequeNumber
  paymentProcessor
  balanceAmount
  cashAmount
  isVoid
  referenceNumber
}
    `;
export const ScoreRecordFields = gql`
    fragment ScoreRecordFields on ScoreRecord {
  score
  total
}
    `;
export const BasicExamScoreFields = gql`
    fragment BasicExamScoreFields on ExamScore {
  id: _id
  year
  subject
  stream
  examType
  mcq {
    ...ScoreRecordFields
  }
  qeq {
    ...ScoreRecordFields
  }
  practical {
    ...ScoreRecordFields
  }
  totalMarks {
    ...ScoreRecordFields
  }
  marksPercentage
  scoreGrade
  createDateTime
}
    ${ScoreRecordFields}`;
export const BasicInvoiceFields = gql`
    fragment BasicInvoiceFields on Invoice {
  id: _id
  createDateTime
  referenceNumber
  status
  taxRate
  lessonDates
  lineItems {
    ...LineItemFields
  }
  dueDate
  subtotal
  totalTax
  total
  amountPaid
  class {
    isAdHoc
    type
  }
}
    ${LineItemFields}`;
export const BasicLessonFields = gql`
    fragment BasicLessonFields on Lesson {
  id: _id
  status
  lessonStart
  lessonEnd
  lessonNumber
  lessonDay
  isOverlappedTeacher
  isOverlappedVenue
  academicWeek {
    startDate
    endDate
    weekNumber
  }
  maximumClassCapacity
  createDateTime
}
    `;
export const BasicAttendanceFields = gql`
    fragment BasicAttendanceFields on Attendance {
  status
  type
}
    `;
export const BasicPromotionFields = gql`
    fragment BasicPromotionFields on Promotion {
  id: _id
  createDateTime
  scheduledDate
  status
}
    `;
export const BasicReceiptFields = gql`
    fragment BasicReceiptFields on Receipt {
  id: _id
  amount
  createDateTime
  contact {
    id: _id
  }
  referenceNumber
  status
  isNotified
}
    `;
export const BasicReferenceDataFields = gql`
    fragment BasicReferenceDataFields on ReferenceData {
  id: _id
  category
  order
  key
  value
  createDateTime
}
    `;
export const BasicRefundFields = gql`
    fragment BasicRefundFields on Refund {
  id: _id
  createDateTime
  amount
  refundMethod
}
    `;
export const SelectedLessonsFileds = gql`
    fragment SelectedLessonsFileds on SelectedLesson {
  byPass
}
    `;
export const BasicRegistrationFields = gql`
    fragment BasicRegistrationFields on Registration {
  id: _id
  name
  type
  startDate
  dueDate
  currentStep
  selectedLessons {
    ...SelectedLessonsFileds
  }
  status
  remarks
  lastModifiedDateTime
  createDateTime
}
    ${SelectedLessonsFileds}`;
export const SelectedLessonFields = gql`
    fragment SelectedLessonFields on Lesson {
  id: _id
  lessonStart
  lessonEnd
  lessonNumber
  lessonDay
  class {
    id: _id
    name
    shorthand
  }
  academicWeek {
    weekNumber
    startDate
    endDate
  }
  attendance {
    id: _id
  }
  capacity {
    ...LessonCapacityFields
  }
}
    ${LessonCapacityFields}`;
export const RegistrationOnChecksFields = gql`
    fragment RegistrationOnChecksFields on Registration {
  id: _id
  type
  name
  startDate
  remarks
  dueDate
  class {
    ...ArrangementClassCardFields
    shorthand
    type
    academicYearId
    isAdHoc
    course {
      id: _id
      name
      colorCode
    }
    isReplacementLesson
    term {
      ...BasicTermFields
    }
  }
  student {
    ...StudentCellFields
  }
  selectedLessons {
    lesson {
      ...SelectedLessonFields
    }
    makeUpLesson {
      ...SelectedLessonFields
    }
  }
  enrollment {
    id: _id
    type
    isFullEnrollment
  }
  linkRegistration {
    id: _id
  }
  session {
    registrationSession {
      registrationStage
      regenerateStartData
    }
  }
}
    ${ArrangementClassCardFields}
${BasicTermFields}
${StudentCellFields}
${SelectedLessonFields}`;
export const BasicStudentFields = gql`
    fragment BasicStudentFields on Student {
  id: _id
  fullName
  alias
  gender
  primarySchool {
    id: _id
    name
  }
  primaryParent {
    id: _id
    fullName
  }
  secondarySchool {
    id: _id
    name
  }
  academicLevel {
    id: _id
    educationStage
    name
  }
  alternativeEmails
  birthDate
  avatarUrl
  consent {
    termCondition
    accountCondition
    statements
    PhotographyOrVideography
  }
  primaryStream
  secondaryStream
  userId
  alternateUserId
  isArchived
  createDateTime
}
    `;
export const BasicSchoolFields = gql`
    fragment BasicSchoolFields on School {
  id: _id
  name
  alias
  educationStage
  isVisible
  isArchived
  createDateTime
}
    `;
export const RelationshipStudentFields = gql`
    fragment RelationshipStudentFields on Student {
  ...BasicStudentFields
  academicLevel {
    ...BasicAcademicLevelFields
  }
  primaryParent {
    ...BasicParentFields
  }
  primarySchool {
    ...BasicSchoolFields
  }
}
    ${BasicStudentFields}
${BasicAcademicLevelFields}
${BasicParentFields}
${BasicSchoolFields}`;
export const BasicReminderFields = gql`
    fragment BasicReminderFields on Reminder {
  id: _id
  type
  subject
  message
  sendDate
  sendStatus
  scheduledDate
}
    `;
export const BasicRewardFields = gql`
    fragment BasicRewardFields on Reward {
  id: _id
  name
  description
  category
  stock
  avatar
  points
  galleries
  remoteGalleries
  isVisible
  isArchived
}
    `;
export const BasicStatementOfAccount = gql`
    fragment BasicStatementOfAccount on StatementOfAccount {
  id
  parent {
    ...BasicParentFields
  }
  creditRemain
  refund
}
    ${BasicParentFields}`;
export const SystemUserCellFields = gql`
    fragment SystemUserCellFields on SystemUser {
  id: _id
  fullName
  gender
  avatarUrl
  salutation
  initials
}
    `;
export const BasicTemplateFields = gql`
    fragment BasicTemplateFields on Template {
  id: _id
  type
  status
  whatsAppMessage
  title
  subject
  usageType
  emailMessage
  isArchived
  content
}
    `;
export const StudentFieldsInPromotion = gql`
    fragment StudentFieldsInPromotion on Student {
  id: _id
  fullName
  alias
  gender
  avatarUrl
  birthDate
  userId
  primarySchool {
    id: _id
    name
    alias
    educationStage
  }
  primaryStream
  secondarySchool {
    id: _id
    alias
    educationStage
    name
  }
  secondaryStream
  academicLevel {
    id: _id
    alias
    name
    educationStage
  }
}
    `;
export const GetHolidayAffectedLessons = gql`
    query GetHolidayAffectedLessons($search: String, $filterInput: LessonFilterInput, $pageInfo: PageInfo) {
  holidayLessonsIncludeEnrollments(search: $search, filterInput: $filterInput, pageInfo: $pageInfo) {
    items {
      id: _id
      lessonNumber
      lessonStart
      holidayEffectedEnrollments {
        id: _id
        status
        type
        student {
          ...StudentCellFields
        }
        class {
          id: _id
          name
          course {
            nameShort
          }
          academicYear {
            year
          }
        }
        arrangements {
          id: _id
          type
          toClass {
            id: _id
            name
            shorthand
          }
          toLesson {
            id: _id
            lessonDay
          }
          fromLesson {
            id: _id
          }
        }
      }
      class {
        id: _id
        name
      }
      holidays {
        id: _id
        name
        period {
          start
          end
        }
      }
      course {
        id: _id
        colorCode
      }
    }
    pageSize
    pageIndex
    total
  }
}
    ${StudentCellFields}`;
export const GetHolidayAffectedEnrollments = gql`
    query GetHolidayAffectedEnrollments($pageInfo: PageInfo) {
  holidayLessonsIncludeEnrollments(pageInfo: $pageInfo) {
    items {
      id: _id
      holidayEffectedEnrollments {
        id: _id
        student {
          id: _id
          avatarUrl
          fullName
          gender
          primarySchool {
            name
          }
        }
        class {
          id: _id
          name
          course {
            nameShort
            colorCode
          }
          academicYear {
            year
          }
          teacher {
            id: _id
            fullName
          }
        }
      }
      holidays {
        id: _id
        name
        period {
          start
          end
        }
      }
    }
    pageSize
    total
  }
}
    `;
export const GetAllHolidays = gql`
    query GetAllHolidays($filterInput: HolidayFilterInput) {
  holidays(filterInput: $filterInput) {
    id: _id
    name
  }
}
    `;
export const GetAdditionalLessonDetail = gql`
    query GetAdditionalLessonDetail($id: ObjectId!) {
  arrangement(id: $id) {
    id: _id
    name
    createDateTime
    type
    status
    emailStatus
    waStatus
    isCancelHasFullLesson
    ...MakeUpCardFields
    student {
      ...StudentCellFields
    }
    invoice {
      ...InvoiceItemFields
    }
    reminders {
      id: _id
      subject
      message
      type
      sendDate
      sendgridEmailStatus
      whatsappMessage {
        status
      }
    }
    lastModifiedDateTime
    lastModifiedBy {
      ...LastModifiedByFields
    }
  }
}
    ${MakeUpCardFields}
${StudentCellFields}
${InvoiceItemFields}
${LastModifiedByFields}`;
export const GetAdditionalLessonBilling = gql`
    query GetAdditionalLessonBilling($id: ObjectId!) {
  arrangement(id: $id) {
    id: _id
    type
    createDateTime
    isAdhocAdditionalLesson
    fromClass {
      id: _id
      course {
        id: _id
      }
    }
    fromLesson {
      id: _id
      lessonNumber
    }
    student {
      id: _id
    }
    invoice {
      ...InvoiceItemFields
    }
  }
}
    ${InvoiceItemFields}`;
export const GetAdditionalLessonWithClass = gql`
    query GetAdditionalLessonWithClass($classId: ObjectId!) {
  class(id: $classId) {
    ...ArrangementClassCardFields
    academicYear {
      id: _id
      year
    }
  }
}
    ${ArrangementClassCardFields}`;
export const GetAdHocAdditionalLessonWithStudent = gql`
    query GetAdHocAdditionalLessonWithStudent($id: ObjectId!, $filterInput: StudentEnrollmentFilterInput) {
  student(id: $id) {
    ...StudentCellFields
    enrollments(filterInput: $filterInput) {
      id: _id
      type
      startDate
      class {
        id: _id
        classCode
      }
    }
  }
}
    ${StudentCellFields}`;
export const GetAdHocAdditionalLessonWithClasses = gql`
    query GetAdHocAdditionalLessonWithClasses($search: String, $filterInput: ClassFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  classes(search: $search, filterInput: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) {
    items {
      id: _id
      classCode
    }
    total
    pageSize
    pageIndex
  }
}
    `;
export const GetAdhocAdditionalLessonWithClassLessons = gql`
    query GetAdhocAdditionalLessonWithClassLessons($id: ObjectId!) {
  class(id: $id) {
    id: _id
    courseId
    lessons {
      id: _id
      lessonNumber
      lessonStart
      lessonEnd
    }
  }
}
    `;
export const GetArrangementFilterOptions = gql`
    query GetArrangementFilterOptions {
  academicYears {
    id: _id
    year
  }
}
    `;
export const GetArrangementModalEnrollmentData = gql`
    query GetArrangementModalEnrollmentData($studentId: ObjectId!, $enrollmentFilterInput: StudentEnrollmentFilterInput) {
  student(id: $studentId) {
    id: _id
    gender
    fullName
    avatarUrl
    userId
    alternateUserId
    enrollments(filterInput: $enrollmentFilterInput) {
      id: _id
      _id
      type
      status
      arrangements {
        type
        status
        enrollmentId
      }
      startDate
      endDate
      isFullEnrollment
      class {
        id: _id
        name
        status
        type
        academicYear {
          id: _id
          year
        }
        course {
          id: _id
          nameShort
        }
        learningArrangement
        term {
          ...BasicTermFields
        }
      }
      preferredLearningArrangement
      group
      isReserved
    }
  }
}
    ${BasicTermFields}`;
export const GetArrangementModalAttendanceData = gql`
    query GetArrangementModalAttendanceData($studentId: ObjectId!, $filterInput: StudentAttendanceFilterInput!) {
  student(id: $studentId) {
    id: _id
    attendances(filterInput: $filterInput) {
      id: _id
      status
      linkArrangement {
        type
        toClass {
          name
          shorthand
        }
      }
      lesson {
        id: _id
        lessonStart
        lessonEnd
        lessonNumber
        academicWeek {
          weekNumber
        }
      }
    }
  }
}
    `;
export const GetTransferModalAttendanceData = gql`
    query GetTransferModalAttendanceData($studentId: ObjectId!, $filterInput: StudentAttendanceFilterInput!) {
  student(id: $studentId) {
    id: _id
    attendances(filterInput: $filterInput) {
      id: _id
      status
      lesson {
        id: _id
        lessonStart
        lessonEnd
        lessonNumber
        academicWeek {
          weekNumber
        }
      }
    }
  }
}
    `;
export const ArrangementEligibility = gql`
    mutation arrangementEligibility($input: CreateArrangementInput!) {
  arrangementEligibility(input: $input)
}
    `;
export const GetTransferModalEnrollmentData = gql`
    query GetTransferModalEnrollmentData($studentId: ObjectId!, $enrollmentFilterInput: StudentEnrollmentFilterInput) {
  student(id: $studentId) {
    id: _id
    gender
    fullName
    avatarUrl
    userId
    alternateUserId
    enrollments(filterInput: $enrollmentFilterInput) {
      id: _id
      type
      startDate
      endDate
      class {
        id: _id
        name
        status
        type
        academicYear {
          id: _id
          year
        }
        course {
          id: _id
          nameShort
        }
        learningArrangement
        lessons {
          lessonNumber
          lessonStart
          id: _id
          academicWeek {
            weekNumber
          }
        }
      }
      status
      arrangements {
        status
        type
        enrollmentId
      }
      preferredLearningArrangement
      group
      isReserved
    }
  }
}
    `;
export const GetTimerArrangement = gql`
    query GetTimerArrangement($id: ObjectId!) {
  arrangement(id: $id) {
    id: _id
    createDateTime
    type
    status
    session {
      expiredAt
    }
  }
}
    `;
export const PostIncompleteArrangement = gql`
    subscription PostIncompleteArrangement($type: ArrangementType!) {
  postIncompleteArrangement(type: $type) {
    id: _id
    createDateTime
    type
    status
    session {
      expiredAt
    }
  }
}
    `;
export const GetLessonForArrangementAction = gql`
    query getLessonForArrangementAction($id: ObjectId!) {
  lesson(id: $id) {
    attendance {
      id: _id
      status
      type
      student {
        id: _id
      }
    }
    capacity {
      remainingRegularSeat
      remainingReplacementSeat
    }
  }
}
    `;
export const IsCancelArrangementHasFullLesson = gql`
    query isCancelArrangementHasFullLesson($id: ObjectId!) {
  isCancelArrangementHasFullLesson(id: $id)
}
    `;
export const ArrangementCsv = gql`
    query arrangementCsv($search: String, $filterInput: ArrangementFilterInput) {
  csv: exportArrangementCsv(search: $search, filterInput: $filterInput) {
    url
  }
}
    `;
export const CreateArrangement = gql`
    mutation createArrangement($input: CreateArrangementInput!) {
  createArrangement(input: $input) {
    id: _id
  }
}
    `;
export const IncrementStep = gql`
    mutation incrementStep($arrangementId: ObjectId!, $step: Int) {
  incrementStep(arrangementId: $arrangementId, step: $step) {
    id: _id
  }
}
    `;
export const CancelArrangement = gql`
    mutation cancelArrangement($id: ObjectId!) {
  cancelArrangement(id: $id) {
    id: _id
    status
  }
}
    `;
export const ProcessArrangement = gql`
    mutation processArrangement($id: ObjectId!) {
  processArrangement(id: $id) {
    id: _id
  }
}
    `;
export const CreateBillingForTransfer = gql`
    mutation createBillingForTransfer($id: ObjectId!) {
  createBillingForTransfer(id: $id) {
    id: _id
  }
}
    `;
export const ExtendsArrangementTime = gql`
    mutation extendsArrangementTime($id: ObjectId!) {
  extendsArrangementTime(id: $id) {
    id: _id
    createDateTime
  }
}
    `;
export const ChangeAttendingClassForTransfer = gql`
    mutation changeAttendingClassForTransfer($input: ChangeAttendingClassForTransferInput!) {
  changeAttendingClassForTransfer(input: $input) {
    ...CheckTransferFields
  }
}
    ${CheckTransferFields}`;
export const CancelChangeAttendingClassForTransfer = gql`
    mutation cancelChangeAttendingClassForTransfer($input: ChangeAttendingClassForTransferInput!) {
  cancelChangeAttendingClassForTransfer(input: $input) {
    ...CheckTransferFields
  }
}
    ${CheckTransferFields}`;
export const GetListArrangementData = gql`
    query GetListArrangementData($filterInput: ArrangementFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  arrangementsWithIndex(filter: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) {
    items {
      ...ListArrangementIndexFields
    }
    pageIndex
    pageSize
    total
  }
}
    ${ListArrangementIndexFields}`;
export const GetArrangementIndex = gql`
    query GetArrangementIndex($id: ObjectId!) {
  arrangementWithIndex(id: $id) {
    ...ListArrangementIndexFields
  }
}
    ${ListArrangementIndexFields}`;
export const GetIncompleteArrangement = gql`
    query GetIncompleteArrangement {
  sessions(types: [Arrangement]) {
    expiredAt
    arrangement {
      id: _id
      type
      name
      student {
        id: _id
        fullName
      }
      currentStep
    }
  }
}
    `;
export const GetWithdrawDetail = gql`
    query getWithdrawDetail($id: ObjectId!) {
  arrangement(id: $id) {
    id: _id
    name
    type
    status
    waStatus
    emailStatus
    lastModifiedDateTime
    isCancelHasFullLesson
    ...ArrangementBillingFields
    student {
      fullName
      userId
    }
    reminders {
      id: _id
      type
      sendDate
      sendgridEmailStatus
      message
      subject
      whatsappMessage {
        status
      }
    }
    enrollment {
      preferredLearningArrangement
      group
    }
    createdBy {
      id: _id
    }
    lastModifiedBy {
      ...LastModifiedByFields
    }
  }
}
    ${ArrangementBillingFields}
${LastModifiedByFields}`;
export const GetWithdrawBilling = gql`
    query getWithdrawBilling($enrollmentId: ObjectId!, $lessonId: ObjectId!) {
  enrollment(id: $enrollmentId) {
    id: _id
    preferredLearningArrangement
    group
    class {
      ...ArrangementClassCardFields
      term {
        ...TermFields
      }
      course {
        name
        avatarUrl
      }
      teacher {
        ...TeacherCellFields
      }
      venue {
        ...BasicVenueFields
      }
    }
    invoice: invoiceByLessonId(lessonId: $lessonId) {
      id: _id
      total
      referenceNumber
      amountPaid
      arrangementBillingCheck(lessonId: $lessonId) {
        amountUsed
        lessonsNeedToPay {
          id: _id
          lessonNumber
          lessonStart
        }
        lessonsNeedNotToPay {
          id: _id
          lessonNumber
          lessonStart
        }
        remainingFee
        updatedLessonFee
        feeDifference
      }
      lessons {
        id: _id
        lessonNumber
        lessonStart
        lessonEnd
      }
    }
    student {
      id: _id
      fullName
      userId
      alternateUserId
      primaryParent {
        id: _id
        fullName
        gender
        email
        mobile {
          number
          countryCode
        }
      }
      primarySchool {
        name
      }
    }
    attendances {
      lesson {
        id: _id
        lessonStart
        lessonEnd
        lessonNumber
      }
    }
    arrangements {
      id: _id
      name
      type
      status
      fromLesson {
        lessonStart
        lessonNumber
      }
      toLesson {
        lessonStart
        lessonNumber
      }
    }
  }
}
    ${ArrangementClassCardFields}
${TermFields}
${TeacherCellFields}
${BasicVenueFields}`;
export const GetWithdrawConfirm = gql`
    query getWithdrawConfirm($id: ObjectId!) {
  arrangement(id: $id) {
    id: _id
    fromClass {
      ...ArrangementClassCardFields
    }
    fromLesson {
      lessonStart
      lessonEnd
    }
    student {
      ...StudentCellFields
      primaryParent {
        id: _id
      }
    }
    originInvoice {
      amountPaid
    }
    cancellation {
      id: _id
      type
      name
      fromLesson {
        lessonStart
        lessonNumber
      }
      toLesson {
        lessonStart
        lessonNumber
      }
    }
  }
}
    ${ArrangementClassCardFields}
${StudentCellFields}`;
export const UpdateEnrollmentEndRecurring = gql`
    mutation updateEnrollmentEndRecurring($id: ObjectId!, $isEndRecurring: Boolean!) {
  updateEnrollmentEndRecurring(id: $id, isEndRecurring: $isEndRecurring) {
    _id
  }
}
    `;
export const GetFindMakeUpLessons = gql`
    query GetFindMakeUpLessons($filterInput: LessonFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  lessons(filterInput: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) @connection(key: "lessonschedules", filter: ["filterInput", "sortInfo", "pageInfo"]) {
    pageIndex
    pageSize
    total
    items {
      id: _id
      lessonDay
      lessonNumber
      lessonStart
      lessonEnd
      maximumClassCapacity
      academicWeek {
        weekNumber
      }
      capacity {
        remainingRegularSeat
        remainingReplacementSeat
      }
      capacityNumber {
        regular
        replacement
        used
      }
      class {
        id: _id
        name
        type
        runNumber
        learningArrangement
        maximumClassCapacity
        maximumMakeUpCapacity
        defaultLessonDays
        isAdHoc
        academicYear {
          year
          holidays {
            id: _id
            name
            type
            period {
              start
              end
            }
          }
        }
        course {
          id: _id
          nameShort
          colorCode
        }
      }
      teacher {
        ...TeacherCellFields
      }
      venue {
        ...BasicVenueFields
      }
    }
  }
}
    ${TeacherCellFields}
${BasicVenueFields}`;
export const GetArrangementClassCard = gql`
    query GetArrangementClassCard($id: ObjectId!) {
  class(id: $id) {
    ...ArrangementClassCardFields
  }
}
    ${ArrangementClassCardFields}`;
export const GetFromLessonOnFindMakeupLessons = gql`
    query GetFromLessonOnFindMakeupLessons($id: ObjectId!) {
  lesson(id: $id) {
    id: _id
    lessonStart
    lessonNumber
    lessonEnd
    course {
      nameShort
      subject
    }
    attendance {
      id: _id
      status
      student {
        id: _id
      }
    }
  }
}
    `;
export const GetMakeUpCardArrangement = gql`
    query GetMakeUpCardArrangement($id: ObjectId!) {
  arrangement(id: $id) {
    id: _id
    type
    student {
      ...StudentCellFields
      primaryParent {
        id: _id
      }
    }
    ...MakeUpCardFields
  }
}
    ${StudentCellFields}
${MakeUpCardFields}`;
export const GetMakeUpCheckStudentAndClass = gql`
    query GetMakeUpCheckStudentAndClass($id: ObjectId!, $classId: ObjectId!, $lessonId: ObjectId!) {
  student(id: $id) {
    id: _id
    fullName
    gender
    avatarUrl
    userId
    alternateUserId
    primaryParent {
      id: _id
    }
    primarySchool {
      id: _id
      name
    }
    secondarySchool {
      id: _id
      name
    }
    academicLevel {
      id: _id
      educationStage
    }
    replacementCredit(lessonId: $lessonId) {
      ...makeUpCreditInfoFragment
    }
  }
  class(id: $classId) {
    ...ArrangementClassCardFields
    lessons {
      ...MakeUpCheckLessonField
    }
  }
}
    ${MakeUpCreditInfoFragment}
${ArrangementClassCardFields}
${MakeUpCheckLessonField}`;
export const GetMakeUpCheckArrangements = gql`
    query GetMakeUpCheckArrangements($search: String, $filterInput: ArrangementFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo, $lessonId: ObjectId!) {
  arrangements(search: $search, filterInput: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) @connection(key: "arrangements", filter: ["search", "filterInput", "sortInfo"]) {
    items {
      id: _id
      type
      status
      currentTermNumber
      student {
        id: _id
        fullName
        avatarUrl
        gender
        userId
        alternateUserId
        replacementCredit(lessonId: $lessonId) {
          ...makeUpCreditInfoFragment
        }
      }
      fromClass {
        ...MakeUpCheckClassField
        learningArrangement
      }
      fromLesson {
        ...MakeUpCheckLessonField
      }
      toClass {
        ...MakeUpCheckClassField
        learningArrangement
      }
      toLesson {
        ...MakeUpCheckLessonField
      }
      remarks
      useCredits
    }
    pageIndex
    pageSize
    total
  }
}
    ${MakeUpCreditInfoFragment}
${MakeUpCheckClassField}
${MakeUpCheckLessonField}`;
export const GetMakeUpDetail = gql`
    query GetMakeUpDetail($id: ObjectId!, $lessonId: ObjectId!) {
  arrangement(id: $id) {
    id: _id
    name
    type
    status
    emailStatus
    waStatus
    remarks
    isCancelHasFullLesson
    fromClass {
      ...ArrangementClassCardFields
    }
    fromLesson {
      id: _id
      lessonStart
      lessonEnd
      lessonNumber
      capacity {
        remainingRegularSeat
        remainingReplacementSeat
      }
    }
    toClass {
      ...ArrangementClassCardFields
    }
    toLesson {
      id: _id
      lessonStart
      lessonEnd
      lessonNumber
    }
    student {
      fullName
      alternateUserId
      userId
      replacementCredit(lessonId: $lessonId) {
        ...makeUpCreditInfoFragment
      }
    }
    reminders {
      id: _id
      type
      sendDate
      sendgridEmailStatus
      message
      subject
      whatsappMessage {
        status
      }
    }
    lastModifiedDateTime
    lastModifiedBy {
      ...LastModifiedByFields
    }
    createDateTime
  }
}
    ${ArrangementClassCardFields}
${MakeUpCreditInfoFragment}
${LastModifiedByFields}`;
export const GetStudentMakeupCredits = gql`
    query getStudentMakeupCredits($studentId: ObjectId!, $lessonId: ObjectId!) {
  student(id: $studentId) {
    _id
    replacementCredit(lessonId: $lessonId) {
      currentTerm {
        used
        exceptions
        total
        termNumber
      }
      workshop {
        used
        exceptions
        total
      }
    }
  }
}
    `;
export const GetCheckTransfer = gql`
    query GetCheckTransfer($id: ObjectId!) {
  arrangement(id: $id) {
    ...CheckTransferFields
  }
}
    ${CheckTransferFields}`;
export const GetFindTransferClasses = gql`
    query GetFindTransferClasses($search: String, $filterInput: ClassFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo, $skipHoliday: Boolean) {
  classes(search: $search, filterInput: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) @connection(key: "classes", filter: ["search", "filterInput", "sortInfo"]) {
    items {
      id: _id
      name
      type
      learningArrangement
      defaultLessonStart
      defaultLessonEnd
      defaultLessonDays
      dailySessionTimeSlots {
        start
        end
      }
      course {
        id: _id
        name
        colorCode
      }
      academicYear {
        year
      }
      capacity {
        ...CapacityFields
      }
      teacher {
        id: _id
        fullName
        avatarUrl
      }
      venue {
        shorthand
        unitNo
        branch {
          name
          alias
        }
      }
      enrollments {
        _id
        type
        isReserved
      }
      lessons(skipHoliday: $skipHoliday) {
        id: _id
        lessonNumber
        lessonStart
      }
    }
    total
    pageSize
    pageIndex
  }
}
    ${CapacityFields}`;
export const GetTransferFromClass = gql`
    query GetTransferFromClass($id: ObjectId!) {
  class(id: $id) {
    id: _id
    courseId
    academicYearId
    type
  }
}
    `;
export const GetClassOfLessons = gql`
    query getClassOfLessons($id: ObjectId!) {
  class(id: $id) {
    id: _id
    learningArrangement
    lessons(skipHoliday: true) {
      id: _id
      lessonNumber
      lessonStart
    }
  }
}
    `;
export const GetTransferNotifyArrangement = gql`
    query GetTransferNotifyArrangement($id: ObjectId!) {
  arrangement(id: $id) {
    id: _id
    type
    ...TransferDetailCardFields
    student {
      id: _id
      gender
      avatarUrl
      fullName
      alternateUserId
      userId
      primaryParent {
        id: _id
        email
        fullName
        alias
        gender
        mobile {
          ...TelephoneFields
        }
      }
      primarySchool {
        id: _id
        createDateTime
        name
        isArchived
        alias
        isVisible
        educationStage
      }
    }
    cancellation {
      id: _id
      name
      type
      fromLesson {
        lessonNumber
        lessonStart
      }
      toLesson {
        lessonNumber
        lessonStart
      }
    }
  }
}
    ${TransferDetailCardFields}
${TelephoneFields}`;
export const GetTransferBillingArrangement = gql`
    query GetTransferBillingArrangement($id: ObjectId!) {
  arrangement(id: $id) {
    id: _id
    createDateTime
    type
    status
    student {
      ...StudentCellFields
    }
    ...ArrangementBillingFields
    enrollment {
      preferredLearningArrangement
      group
    }
    newEnrollment {
      preferredLearningArrangement
      group
    }
  }
}
    ${StudentCellFields}
${ArrangementBillingFields}`;
export const GetTransferDetail = gql`
    query GetTransferDetail($id: ObjectId!) {
  arrangement(id: $id) {
    id: _id
    name
    createDateTime
    lastModifiedDateTime
    type
    status
    emailStatus
    waStatus
    isCancelHasFullLesson
    ...ArrangementBillingFields
    student {
      ...StudentCellFields
    }
    enrollment {
      preferredLearningArrangement
      group
    }
    newEnrollment {
      preferredLearningArrangement
      group
    }
    reminders {
      id: _id
      subject
      message
      type
      sendDate
      sendgridEmailStatus
      whatsappMessage {
        status
      }
    }
    lastModifiedBy {
      ...LastModifiedByFields
    }
  }
}
    ${ArrangementBillingFields}
${StudentCellFields}
${LastModifiedByFields}`;
export const GetClassFilterOptions = gql`
    query GetClassFilterOptions($showArchived: Boolean) {
  branches(showArchived: $showArchived) {
    ...BasicBranchFields
    venues {
      ...BasicVenueFields
    }
  }
  academicYears {
    id: _id
    year
    academicWeeks {
      startDate
      endDate
      weekNumber
    }
  }
}
    ${BasicBranchFields}
${BasicVenueFields}`;
export const GetFindClassFilterOptions = gql`
    query GetFindClassFilterOptions($showArchived: Boolean) {
  branches(showArchived: $showArchived) {
    id: _id
    name
    alias
    venues {
      ...BasicVenueFields
    }
  }
  academicYears {
    id: _id
    year
  }
}
    ${BasicVenueFields}`;
export const GetSelectCourses = gql`
    query getSelectCourses($search: String, $filterInput: CourseFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  courses(search: $search, filterInput: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) @connection(key: "courses", filter: ["search", "filterInput", "sortInfo"]) {
    items {
      id: _id
      avatarUrl
      name
      nameShort
      type
      academicLevels {
        id: _id
        name
      }
      onGoingClassNumber
    }
    pageIndex
    pageSize
    total
  }
}
    `;
export const GetClassWithAcademicYears = gql`
    query getClassWithAcademicYears {
  academicYears {
    id: _id
    year
    period {
      start
      end
    }
    academicWeekStart
    academicWeekEnd
    terms {
      id: _id
      academicLevels {
        ...BasicAcademicLevelFields
      }
      ...BasicTermFields
    }
  }
}
    ${BasicAcademicLevelFields}
${BasicTermFields}`;
export const GetSetupClassAcademicYear = gql`
    query GetSetupClassAcademicYear($academicYearId: ObjectId!) {
  academicYear(id: $academicYearId) {
    id: _id
    holidays {
      ...BasicHolidayFields
    }
  }
}
    ${BasicHolidayFields}`;
export const ValidateTeacherAndVenue = gql`
    query ValidateTeacherAndVenue($input: ValidateTeacherAndVenueInput!) {
  validateTeacherAndVenue(input: $input) {
    teacher
    venue
  }
}
    `;
export const AutocompleteClassInfo = gql`
    query autocompleteClassInfo($courseId: ObjectId!, $isAdHoc: Boolean!) {
  autocompleteClass(courseId: $courseId, isAdHoc: $isAdHoc) {
    courseId
    name
    defaultLessonDays
    defaultLessonStart
    defaultLessonEnd
    academicYearId
    teacherId
    classCode
    learningArrangement
    venueId
    maximumClassCapacity
    maximumMakeUpCapacity
    isSkipScheduledHoliday
    firstLessonDate
    isAdHoc
    runNumber
    numberOfLessons
    academicWeekNumber
  }
}
    `;
export const GetClassFormData = gql`
    query GetClassFormData {
  venues {
    ...BasicVenueFields
  }
  academicYears {
    id: _id
    year
    id: _id
    year
    period {
      start
      end
    }
    academicWeekStart
    academicWeekEnd
    academicWeeks {
      startDate
      endDate
      weekNumber
    }
    terms {
      id: _id
      academicLevels {
        ...BasicAcademicLevelFields
      }
      ...BasicTermFields
    }
  }
}
    ${BasicVenueFields}
${BasicAcademicLevelFields}
${BasicTermFields}`;
export const GetCurrentYearHolidays = gql`
    query GetCurrentYearHolidays($year: Int) {
  holidays(filterInput: {year: $year}) {
    period {
      start
      end
    }
    id: _id
  }
}
    `;
export const GetEditClassFormData = gql`
    query GetEditClassFormData($classId: ObjectId!, $skipHoliday: Boolean) {
  class(id: $classId) {
    id: _id
    name
    shorthand
    isAdHoc
    classCode
    type
    repeat
    dailySessionTimeSlots {
      start
      end
    }
    classFee {
      amount
      type
    }
    firstLessonDate
    lastLessonDate
    generateUntil
    numberOfLessons
    isReplacementLesson
    academicYear {
      id: _id
      year
    }
    isSkipScheduledHoliday
    teacher {
      id: _id
      alias
      avatarUrl
      fullName
      gender
      salutation
      initials
    }
    venue {
      ...BasicVenueFields
    }
    learningArrangement
    digitalClassAccessCredentials {
      autoGenerateZoomMeetingDetails
      autoGenerateGoogleMeetingDetails
      zoomMeetingId
      zoomMeetingPassword
      zoomMeetingLink
      googleMeetId
      googleMeetLink
    }
    runNumber
    defaultLessonDays
    defaultLessonStart
    defaultLessonEnd
    maximumClassCapacity
    maximumMakeUpCapacity
    course {
      id: _id
      name
      colorCode
      nameShort
      type
      avatarUrl
      description
      numberOfLessons
      learningResourceUrl
      gamePlanLink
      academicLevels {
        id: _id
        name
      }
      subject
      isSkipScheduledHoliday
      isRequireFeedback
      academicStreams
      courseFee {
        isApplicable
        billingFrequency
        standardCourseFees {
          type
          amount
        }
        otherFees {
          isApplicable
          name
          amount
        }
      }
      duration
    }
    lessons(skipHoliday: $skipHoliday) {
      id: _id
      academicWeek {
        weekNumber
        endDate
        startDate
      }
      lessonNumber
      lessonStart
    }
    isArchived
    isVisible
    status
    lessonNumber
    createDateTime
  }
}
    ${BasicVenueFields}`;
export const UpdateEnrollmentsPreferredLearningArrangement = gql`
    mutation updateEnrollmentsPreferredLearningArrangement($input: UpdateEnrollmentsPreferredLearningArrangementInput!) {
  updateEnrollmentsPreferredLearningArrangement(input: $input) {
    id: _id
  }
}
    `;
export const GetClassList = gql`
    query GetClassList($search: String, $filterInput: ClassFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  classes(search: $search, filterInput: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) @connection(key: "classes", filter: ["search", "filterInput", "sortInfo"]) {
    items {
      id: _id
      name
      shorthand
      status
      type
      classCode
      isVisible
      isAdHoc
      academicYear {
        id: _id
        year
      }
      academicLevels {
        name
      }
      teacher {
        id: _id
        fullName
        avatarUrl
        gender
        salutation
        initials
      }
      venue {
        shorthand
        unitNo
        branch {
          name
          alias
        }
      }
      course {
        id: _id
        name
        nameShort
        type
        avatarUrl
        colorCode
      }
      learningArrangement
      digitalClassAccessCredentials {
        zoomMeetingId
        zoomMeetingPassword
        zoomMeetingLink
        googleMeetId
        googleMeetLink
      }
      capacity {
        ...CapacityFields
      }
      enrollments {
        _id
        type
        isReserved
      }
      defaultLessonDays
      defaultLessonStart
      defaultLessonEnd
      dailySessionTimeSlots {
        start
        end
      }
    }
    total
    pageSize
    pageIndex
  }
}
    ${CapacityFields}`;
export const GetClassWithIndexList = gql`
    query GetClassWithIndexList($filterInput: ClassFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  classesWithIndex(filter: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) @connection(key: "classes", filter: ["filter", "sortInfo"]) {
    items {
      id: _id
      name
      shorthand
      status
      type
      classCode
      isAdHoc
      createdBy {
        fullName
      }
      createDateTime
      lastModifiedDateTime
      lastModifiedFullName
      maximumClassCapacity
      maximumMakeUpCapacity
      regularStudents
      trialStudents
      reservedStudents
      transferredStudents
      withdrawnStudents
      defaultLessonStart
      defaultLessonEnd
      academicYear {
        id: _id
        year
      }
      academicLevels {
        name
      }
      teacher {
        id: _id
        fullName
        avatarUrl
        gender
        salutation
        initials
      }
      venue {
        unitNo
        shorthand
      }
      branch {
        name
        alias
      }
      course {
        id: _id
        name
        nameShort
      }
      learningArrangement
      digitalClassAccessCredentials {
        zoomMeetingId
        zoomMeetingPassword
        zoomMeetingLink
        googleMeetId
        googleMeetLink
      }
      capacity {
        ...CapacityFields
      }
      defaultLessonDays
      defaultLessonStart
      defaultLessonEnd
    }
    total
    pageSize
    pageIndex
  }
}
    ${CapacityFields}`;
export const GetClassIdsWithIndexList = gql`
    query GetClassIdsWithIndexList($filterInput: ClassFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  classesWithIndex(filter: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) @connection(key: "classes", filter: ["filter", "sortInfo"]) {
    items {
      id: _id
    }
    total
    pageSize
    pageIndex
  }
}
    `;
export const GetClasses = gql`
    query GetClasses($search: String, $filterInput: ClassFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo, $skipHoliday: Boolean) {
  classes(search: $search, filterInput: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) @connection(key: "classes", filter: ["search", "filterInput", "sortInfo"]) {
    items {
      id: _id
      name
      status
      type
      classCode
      academicYear {
        id: _id
        year
      }
      academicLevels {
        id: _id
        alias
        name
      }
      teacher {
        id: _id
        fullName
        avatarUrl
        gender
        salutation
        initials
      }
      venue {
        ...BasicVenueFields
      }
      learningArrangement
      digitalClassAccessCredentials {
        zoomMeetingId
        zoomMeetingPassword
        zoomMeetingLink
        googleMeetId
        googleMeetLink
      }
      runNumber
      defaultLessonDays
      defaultLessonStart
      defaultLessonEnd
      maximumClassCapacity
      maximumMakeUpCapacity
      course {
        id: _id
        name
        colorCode
        nameShort
        type
        avatarUrl
        academicLevels {
          name
        }
        classes {
          id: _id
          status
        }
      }
      lessons(skipHoliday: $skipHoliday) {
        id: _id
        lessonDay
        lessonStart
        lessonEnd
        lessonNumber
        academicWeek {
          weekNumber
          startDate
          endDate
        }
      }
      enrollments {
        type
        startDate
        endDate
        isReserved
        student {
          id: _id
          fullName
          alias
          gender
          email
          primarySchool {
            id: _id
            name
          }
          secondarySchool {
            id: _id
            name
          }
          academicLevel {
            id: _id
            educationStage
          }
          examScores {
            id: _id
            year
            subject
            marksPercentage
          }
        }
      }
      term {
        term1Period {
          start
          end
        }
        term2Period {
          start
          end
        }
        term3Period {
          start
          end
        }
        term4Period {
          start
          end
        }
      }
      capacity {
        ...CapacityFields
      }
      isArchived
      isVisible
      capacityUsed
      activeEnrollments {
        _id
      }
      isAdHoc
    }
    pageIndex
    pageSize
    total
  }
}
    ${BasicVenueFields}
${CapacityFields}`;
export const GetClassesOfTeacher = gql`
    query GetClassesOfTeacher($filterInput: ClassFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  classes(filterInput: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) {
    items {
      id: _id
      name
      classCode
      academicYear {
        id: _id
        year
      }
      venue {
        ...BasicVenueFields
      }
      type
      dailySessionTimeSlots {
        start
        end
      }
      defaultLessonDays
      defaultLessonStart
      defaultLessonEnd
      course {
        id: _id
        nameShort
      }
      enrollments {
        id: _id
        type
        startDate
        endDate
        isReserved
      }
      capacity {
        ...CapacityFields
      }
    }
    pageIndex
    pageSize
    total
  }
}
    ${BasicVenueFields}
${CapacityFields}`;
export const GetClassEnrollments = gql`
    query GetClassEnrollments($id: ObjectId!) {
  class(id: $id) {
    id: _id
    name
    maximumClassCapacity
    maximumMakeUpCapacity
    capacityUsed
    lessons {
      _id
      lessonNumber
      lessonStart
    }
    capacity {
      ...CapacityFields
    }
    enrollments {
      ...ClassEnrollmentFields
      isReserved
      class {
        name
      }
      registration {
        linkRegistration {
          enrollment {
            status
            type
            startDate
            endDate
            class {
              name
            }
          }
        }
      }
      invoices {
        _id
        dueDate
        status
      }
    }
  }
}
    ${CapacityFields}
${ClassEnrollmentFields}`;
export const GetClassDetailById = gql`
    query GetClassDetailById($classId: ObjectId!) {
  class(id: $classId) {
    id: _id
    name
    type
    course {
      id: _id
      type
      colorCode
      name
      nameShort
      avatarUrl
    }
    academicYear {
      id: _id
      year
    }
    isSimulation
    teacher {
      id: _id
      alias
      avatarUrl
      fullName
      gender
      salutation
      initials
    }
    lessons {
      lessonStart
    }
    classCode
    venue {
      ...BasicVenueFields
    }
    isReplacementLesson
    capacity {
      ...CapacityFields
    }
    dailySessionTimeSlots {
      start
      end
    }
    learningArrangement
    digitalClassAccessCredentials {
      zoomMeetingId
      zoomMeetingPassword
      zoomMeetingLink
      googleMeetId
      googleMeetLink
    }
    isAdHoc
    runNumber
    defaultLessonDays
    defaultLessonStart
    defaultLessonEnd
    voidRemarks
    academicYear {
      year
    }
    maximumClassCapacity
    maximumMakeUpCapacity
    capacityUsed
    enrollments {
      id: _id
      isReserved
    }
    course {
      id: _id
      name
      nameShort
      avatarUrl
    }
    status
    createDateTime
    createdBy {
      ...CreateByFields
    }
  }
}
    ${BasicVenueFields}
${CapacityFields}
${CreateByFields}`;
export const GetClassByAttendanceId = gql`
    query GetClassByAttendanceId($classId: ObjectId!, $skipHoliday: Boolean) {
  class(id: $classId) {
    id: _id
    name
    isAdHoc
    learningArrangement
    isReplacementLesson
    academicYear {
      id: _id
      year
      groupAssignment {
        items {
          weekNumber
          startDate
          endDate
          groupA
          groupB
        }
      }
    }
    lessons(skipHoliday: $skipHoliday) {
      id: _id
      lessonNumber
      lessonStart
      lessonEnd
      lessonDay
      maximumClassCapacity
      isHoliday
      academicWeek {
        startDate
        endDate
        weekNumber
      }
    }
  }
}
    `;
export const GetSetDateModalClassById = gql`
    query GetSetDateModalClassById($classId: ObjectId!, $skipHoliday: Boolean) {
  class(id: $classId) {
    term {
      ...BasicTermFields
    }
    lessons(skipHoliday: $skipHoliday) {
      id: _id
      lessonNumber
      lessonStart
      lessonEnd
      maximumClassCapacity
      attendance {
        id: _id
      }
    }
  }
}
    ${BasicTermFields}`;
export const GetVenues = gql`
    query GetVenues {
  venues {
    ...BasicVenueFields
  }
}
    ${BasicVenueFields}`;
export const GetClassAndLessons = gql`
    query GetClassAndLessons($classId: ObjectId!) {
  class(id: $classId) {
    id: _id
    name
    academicYear {
      id: _id
      year
    }
    teacher {
      id: _id
      fullName
      gender
    }
    venue {
      ...BasicVenueFields
    }
    runNumber
    defaultLessonDays
    defaultLessonStart
    defaultLessonEnd
    maximumClassCapacity
    maximumMakeUpCapacity
    course {
      id: _id
      name
      nameShort
      type
    }
    lessons {
      academicWeek {
        startDate
        endDate
        weekNumber
      }
      id: _id
      teacher {
        id: _id
        fullName
        gender
      }
      venue {
        ...BasicVenueFields
      }
      lessonStart
      lessonEnd
      maximumClassCapacity
      lessonDay
      lessonNumber
    }
  }
}
    ${BasicVenueFields}`;
export const CreateRegularClass = gql`
    mutation createRegularClass($input: CreateRegularClassCommand!) {
  createRegularClass(input: $input) {
    id: _id
  }
}
    `;
export const CreateWorkShopClass = gql`
    mutation createWorkShopClass($input: CreateWorkShopClassCommand!) {
  createWorkShopClass(input: $input) {
    id: _id
  }
}
    `;
export const CreateAdhocClass = gql`
    mutation createAdhocClass($input: CreateAdhocClassCommand!) {
  createAdhocClass(input: $input) {
    id: _id
  }
}
    `;
export const UpdateClassSchedule = gql`
    mutation UpdateClassSchedule($input: UpdateClassScheduleCommand!) {
  updateClassSchedule(command: $input) {
    id: _id
  }
}
    `;
export const UpdateClassName = gql`
    mutation UpdateClassName($input: UpdateClassNameCommand!) {
  updateClassName(command: $input) {
    id: _id
  }
}
    `;
export const UpdateClassTeacher = gql`
    mutation UpdateClassTeacher($input: UpdateClassTeacherCommand!) {
  updateClassTeacher(command: $input) {
    id: _id
  }
}
    `;
export const UpdateClassLearningArrangement = gql`
    mutation UpdateClassLearningArrangement($input: UpdateClassLearningArrangementCommand!) {
  updateClassLearningArrangement(command: $input) {
    id: _id
  }
}
    `;
export const UpdateClassFee = gql`
    mutation UpdateClassFee($input: UpdateClassFeeCommand!) {
  updateClassFee(command: $input) {
    id: _id
  }
}
    `;
export const UpdateClassVenue = gql`
    mutation UpdateClassVenue($input: UpdateClassVenueCommand!) {
  updateClassVenue(command: $input) {
    id: _id
  }
}
    `;
export const UpdateClassDigitalCredential = gql`
    mutation UpdateClassDigitalCredential($input: UpdateClassDigitalCredentialCommand!) {
  updateClassDigitalCredential(command: $input) {
    id: _id
  }
}
    `;
export const VoidClass = gql`
    mutation voidClass($input: VoidInput!) {
  voidClass(input: $input) {
    id: _id
  }
}
    `;
export const CancelClassCreationFlow = gql`
    mutation cancelClassCreationFlow($input: IdInput!) {
  cancelClassCreationFlow(input: $input) {
    id: _id
    name
    academicYear {
      id: _id
      year
    }
    teacher {
      id: _id
      fullName
      gender
    }
    venue {
      ...BasicVenueFields
    }
    runNumber
    defaultLessonDays
    defaultLessonStart
    defaultLessonEnd
    maximumClassCapacity
    maximumMakeUpCapacity
    course {
      id: _id
      name
      nameShort
      type
    }
    lessons {
      academicWeek {
        startDate
        endDate
        weekNumber
      }
      id: _id
      teacher {
        id: _id
        fullName
        gender
      }
      venue {
        ...BasicVenueFields
      }
      lessonStart
      lessonEnd
      maximumClassCapacity
      lessonDay
      lessonNumber
    }
  }
}
    ${BasicVenueFields}`;
export const CompleteClassCreationFlow = gql`
    mutation completeClassCreationFlow($input: IdInput!) {
  completeClassCreationFlow(input: $input) {
    id: _id
    name
    academicYear {
      id: _id
      year
    }
    teacher {
      id: _id
      fullName
      gender
    }
    venue {
      ...BasicVenueFields
    }
    runNumber
    defaultLessonDays
    defaultLessonStart
    defaultLessonEnd
    maximumClassCapacity
    maximumMakeUpCapacity
    course {
      id: _id
      name
      nameShort
      type
    }
    lessons {
      academicWeek {
        startDate
        endDate
        weekNumber
      }
      id: _id
      teacher {
        id: _id
        fullName
        gender
      }
      venue {
        ...BasicVenueFields
      }
      lessonStart
      lessonEnd
      maximumClassCapacity
      lessonDay
      lessonNumber
    }
  }
}
    ${BasicVenueFields}`;
export const ClassCsv = gql`
    query classCsv($search: String, $filterInput: ClassFilterInput) {
  csv: exportClassCsv(search: $search, filterInput: $filterInput) {
    url
  }
}
    `;
export const SeatsAnalyticsByAcademicLevel = gql`
    query seatsAnalyticsByAcademicLevel($filterInput: ClassFilterInput) {
  seatsAnalyticsByAcademicLevel(filterInput: $filterInput) {
    totalAvailableSlots
    totalStudents
    enrollmentRating
  }
}
    `;
export const CapacitySimulation = gql`
    mutation capacitySimulation($input: SimulationCapacityInput!) {
  capacitySimulation(input: $input)
}
    `;
export const ClearSimulation = gql`
    mutation clearSimulation($input: IdInput!) {
  clearSimulation(input: $input)
}
    `;
export const GetClassWithRegistrationModal = gql`
    query getClassWithRegistrationModal($classId: ObjectId!, $skipHoliday: Boolean, $studentId: ObjectId!) {
  class(id: $classId) {
    id: _id
    course {
      type
    }
    lessons(skipHoliday: $skipHoliday) {
      id: _id
      lessonNumber
      lessonStart
      lessonEnd
      maximumClassCapacity
      attendance {
        id: _id
      }
    }
    eligibility(studentId: $studentId) {
      stage
      lessonDates
    }
  }
}
    `;
export const ExportClassCsvWithIndex = gql`
    query ExportClassCsvWithIndex($filterInput: ClassFilterInput) {
  csv: exportClassCsvWithIndex(filterInput: $filterInput) {
    url
  }
}
    `;
export const ValidateIntegrationAccessToken = gql`
    mutation validateIntegrationAccessToken($input: ValidateIntegrationAccessTokenCommand!) {
  validateIntegrationAccessToken(input: $input)
}
    `;
export const DeleteDraftClass = gql`
    subscription DeleteDraftClass {
  deleteDraftClass
}
    `;
export const GetAttendanceByLessonId = gql`
    query GetAttendanceByLessonId($id: ObjectId!, $type: [AttendanceType!], $status: [AttendanceStatus!]) {
  lesson(id: $id) {
    id: _id
    status
    teacher {
      id: _id
      fullName
      salutation
    }
    venue {
      ...BasicVenueFields
    }
    class {
      id: _id
      learningArrangement
      type
      lessons {
        lessonNumber
        lessonStart
      }
    }
    lessonStart
    lessonEnd
    lessonDay
    lessonNumber
    lessonNotes
    attendance(type: $type, status: $status) {
      id: _id
      status
      type
      startDateTime
      linkInvoice {
        id: _id
        status
        dueDate
      }
      learningArrangement
      remarks(includesStudentRemarks: true) {
        ...RemarkFields
      }
      enrollment {
        id: _id
        type
        status
        startDate
        endDate
        isFullEnrollment
        preferredLearningArrangement
        group
        suitability {
          status
        }
        followUpStatus
        followUpStatusLastModifiedDateTime
        followUpStatusLastModifiedBy {
          ...CreateByFields
        }
      }
      linkedAttendance {
        startDateTime
        class {
          name
        }
      }
      previousLessonAttendance {
        type
        status
        class {
          shorthand
          name
        }
      }
      student {
        id: _id
        fullName
        gender
        avatarUrl
        userId
        alternateUserId
        primarySchool {
          id: _id
          name
        }
        secondarySchool {
          id: _id
          name
        }
        academicLevel {
          id: _id
          educationStage
        }
        enrollments {
          class {
            _id
          }
          group
          isReserved
        }
      }
      lessonMode
      group
      linkArrangement {
        fromClass {
          shorthand
        }
        remarks
      }
      statusTag {
        tags
        description
      }
    }
    attendanceSnapshot(type: $type, status: $status) {
      id: _id
      status
      type
      startDateTime
      remarks(includesStudentRemarks: true) {
        ...RemarkFields
      }
      student {
        id: _id
        fullName
        gender
        avatarUrl
        userId
        alternateUserId
        primarySchool {
          id: _id
          name
        }
        secondarySchool {
          id: _id
          name
        }
        academicLevel {
          id: _id
          educationStage
        }
        enrollments {
          class {
            _id
          }
          group
          isReserved
        }
      }
      lessonMode
      group
    }
  }
}
    ${BasicVenueFields}
${RemarkFields}
${CreateByFields}`;
export const GetEnrollmentSuitabilityWithAttendances = gql`
    query GetEnrollmentSuitabilityWithAttendances($filter: StudentAttendanceFilterInput!) {
  attendances(filter: $filter, sortInfo: {field: "createDateTime", direction: Asc}, pageInfo: {pageIndex: 0, pageSize: 0}) {
    items {
      id: _id
      class {
        type
      }
      student {
        id: _id
        fullName
        userId
        avatarUrl
        gender
      }
      enrollment {
        id: _id
        suitability {
          status
          remark {
            id: _id
            content
          }
        }
      }
    }
  }
}
    `;
export const UpdateEnrollmentSuitability = gql`
    mutation UpdateEnrollmentSuitability($input: UpdateEnrollmentSuitabilityInput!) {
  updateEnrollmentSuitability(input: $input) {
    id: _id
  }
}
    `;
export const GetClassSeatViewData = gql`
    query GetClassSeatViewData($id: ObjectId!) {
  class(id: $id) {
    id: _id
    name
    maximumClassCapacity
    maximumMakeUpCapacity
    capacityUsed
    capacity {
      ...CapacityFields
    }
  }
  registrations(filterInput: {status: [Waitlisted], classIds: [$id]}) {
    total
  }
}
    ${CapacityFields}`;
export const GetLessonsInClassById = gql`
    query GetLessonsInClassById($classId: ObjectId!) {
  class(id: $classId) {
    id: _id
    type
    status
    isAdHoc
    learningArrangement
    name
    learningArrangement
    academicYear {
      id: _id
      year
      period {
        end
        start
      }
    }
    term {
      ...BasicTermFields
    }
    course {
      id: _id
      name
      nameShort
      type
    }
    lessons {
      ...LessonInClassFields
      isHoliday
      holidays {
        name
      }
      isHolidayEffectedAdditionalLesson
    }
  }
}
    ${BasicTermFields}
${LessonInClassFields}`;
export const GetAcademicYearsForImportClasses = gql`
    query GetAcademicYearsForImportClasses {
  academicYears {
    id: _id
    year
    academicWeeks {
      weekNumber
      startDate
      endDate
    }
  }
}
    `;
export const GetCoursesForImportClasses = gql`
    query GetCoursesForImportClasses($filterInput: CourseFilterInput) {
  courses(pageInfo: {pageSize: 0, pageIndex: 0}, filterInput: $filterInput) {
    items {
      id: _id
      name
      nameShort
      type
      courseFee {
        standardCourseFees {
          amount
          type
        }
      }
    }
    pageIndex
    pageSize
    total
  }
}
    `;
export const ImportClasses = gql`
    mutation ImportClasses($input: BulkCreateClassCommand!) {
  bulkCreateClass(input: $input)
}
    `;
export const PostImportClassHistory = gql`
    subscription PostImportClassHistory {
  postImportClassHistory {
    ...BulkCreateHistoryFields
  }
}
    ${BulkCreateHistoryFields}`;
export const ExportFailedImportClasses = gql`
    mutation ExportFailedImportClasses($id: ObjectId!) {
  exportFailedImportClasses(id: $id) {
    url
  }
}
    `;
export const GetVenuesForImportClasses = gql`
    query GetVenuesForImportClasses($showArchive: Boolean = false) {
  venues(showArchived: $showArchive) {
    id: _id
    shorthand
    unitNo
    capacity
    address {
      line1
      link
      postalCode
    }
    branch {
      name
      alias
    }
  }
}
    `;
export const GetUserCustomiseColumns = gql`
    query getUserCustomiseColumns($search: String, $filterInput: UserCustomiseColumnFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  userCustomiseColumns(search: $search, filterInput: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) {
    items {
      tableName
      tableShowName
      columnName
      columnShowName
      isVisible
      isRequired
      weight
    }
  }
}
    `;
export const UpsertUserCustomiseColumns = gql`
    mutation upsertUserCustomiseColumns($input: [UpsertUserCustomiseColumnInput!]!) {
  upsertUserCustomiseColumns(inputs: $input) {
    tableName
    tableShowName
    columnName
    columnShowName
    isVisible
    isRequired
    weight
  }
}
    `;
export const ResetDefault = gql`
    mutation resetDefault($input: ResetDefaultInput!) {
  resetDefault(input: $input) {
    tableName
    tableShowName
    columnName
    columnShowName
    isVisible
    isRequired
    weight
  }
}
    `;
export const GetCourseFilterOptions = gql`
    query GetCourseFilterOptions {
  academicLevels {
    ...BasicAcademicLevelFields
  }
  academicStreams: referenceDatas(category: AcademicStream) {
    id: _id
    key
    value
  }
}
    ${BasicAcademicLevelFields}`;
export const VoidCourse = gql`
    mutation VoidCourse($input: VoidInput!) {
  voidCourse(input: $input) {
    id: _id
  }
}
    `;
export const CreateRegularCourse = gql`
    mutation createRegularCourse($input: CreateRegularCourseInput!) {
  createRegularCourse(input: $input) {
    id: _id
    type
  }
}
    `;
export const CreateWorkShopCourse = gql`
    mutation createWorkShopCourse($input: CreateWorkShopCourseInput!) {
  createWorkShopCourse(input: $input) {
    id: _id
    type
  }
}
    `;
export const UpdateCourse = gql`
    mutation updateCourse($input: UpdateCourseInput!) {
  updateCourse(input: $input) {
    id: _id
    name
    nameShort
    description
    status
    createdBy {
      id: _id
      email
    }
    colorCode
    createDateTime
    academicLevels {
      id: _id
      name
    }
    learningResourceUrl
    gamePlanLink
    academicStreams
    type
    courseFee {
      standardCourseFees {
        type
        amount
      }
      isApplicable
      billingFrequency
      otherFees {
        name
        amount
        isApplicable
      }
    }
    makeUpCredits {
      term1
      term2
      term3
      term4
    }
    termsAndConditions
    disclaimer
    duration
  }
}
    `;
export const GetCourseOfClass = gql`
    query getCourseOfClass($id: ObjectId!) {
  course(id: $id) {
    id: _id
    name
    nameShort
    description
    subject
    status
    nextRunNumber
    numberOfLessons
    avatarUrl
    academicLevels {
      id: _id
      name
    }
    isRequireFeedback
    learningResourceUrl
    gamePlanLink
    termsAndConditions
    disclaimer
    academicStreams
    type
    courseFee {
      standardCourseFees {
        type
        amount
      }
      isApplicable
      billingFrequency
      otherFees {
        name
        amount
        isApplicable
      }
    }
    makeUpCredits {
      term1
      term2
      term3
      term4
      workshop
    }
    isSkipScheduledHoliday
    duration
  }
}
    `;
export const GetCourse = gql`
    query getCourse($id: ObjectId!) {
  course(id: $id) {
    id: _id
    name
    nameShort
    description
    subject
    status
    voidRemarks
    numberOfLessons
    nextRunNumber
    colorCode
    avatarUrl
    courseMaterial
    createdBy {
      ...CreateByFields
    }
    createDateTime
    academicLevels {
      ...AcademicLevelFields
    }
    learningResourceUrl
    gamePlanLink
    termsAndConditions
    disclaimer
    academicStreams
    type
    courseFee {
      standardCourseFees {
        type
        amount
      }
      isApplicable
      billingFrequency
      otherFees {
        name
        amount
        isApplicable
      }
    }
    onGoingClassNumber
    makeUpCredits {
      term1
      term2
      term3
      term4
      workshop
    }
    isSkipScheduledHoliday
    duration
    isRequireFeedback
  }
}
    ${CreateByFields}
${AcademicLevelFields}`;
export const GetOptionCourses = gql`
    query getOptionCourses($search: String, $filterInput: CourseFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  courses(search: $search, filterInput: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) @connection(key: "courses", filter: ["search", "filterInput", "sortInfo"]) {
    items {
      id: _id
      name
    }
    pageIndex
    pageSize
    total
  }
}
    `;
export const GetCourses = gql`
    query getCourses($search: String, $filterInput: CourseFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  courses(search: $search, filterInput: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) @connection(key: "courses", filter: ["search", "filterInput", "sortInfo"]) {
    items {
      ...CourseFields
    }
    pageIndex
    pageSize
    total
  }
}
    ${CourseFields}`;
export const CourseCsv = gql`
    query courseCsv($search: String, $filterInput: CourseFilterInput) {
  csv: exportCourseCsv(search: $search, filterInput: $filterInput) {
    url
  }
}
    `;
export const AutocompleteCourseInfo = gql`
    query autocompleteCourseInfo($type: CourseType!) {
  autocompleteCourse(type: $type) {
    name
    nameShort
    type
    description
    academicLevelIds
    academicStreams
    makeUpCredits {
      term1
      term2
      term3
      term4
    }
    courseFee {
      standardCourseFees {
        type
        amount
      }
      isApplicable
      billingFrequency
      otherFees {
        name
        amount
        isApplicable
      }
    }
    avatarUrl
    termsAndConditions
    disclaimer
  }
}
    `;
export const EnrollmentHistory = gql`
    query EnrollmentHistory($id: ObjectId!) {
  course(id: $id) {
    id: _id
    name
    nameShort
    description
    status
  }
}
    `;
export const CourseAcademicYear = gql`
    query CourseAcademicYear($year: Int!) {
  academicYear(year: $year) {
    id: _id
    year
    period {
      start
      end
    }
  }
}
    `;
export const GetCourseInfo = gql`
    query getCourseInfo($id: ObjectId!) {
  course(id: $id) {
    id: _id
    name
    nameShort
    type
    status
    description
    avatarUrl
    academicLevels {
      id: _id
      name
    }
    onGoingClassNumber
    voidRemarks
    createDateTime
    createdBy {
      ...CreateByFields
    }
  }
}
    ${CreateByFields}`;
export const GetListCourseWithIndexData = gql`
    query GetListCourseWithIndexData($filterInput: CourseFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  coursesWithIndex(filter: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) {
    items {
      id: _id
      avatarUrl
      name
      academicStreams
      courseFeeWithSort
      nameShort
      status
      type
      ongoingClasses
      academicLevels {
        name
      }
      courseFee {
        billingFrequency
      }
      trialFee
      additionalLessonFee
      depositFee
      isVisible
      materialsFee
      registrationFee
      registrations
      trials
      reservations
      withdrawing
      withdrawals
      courseCompleted
      createDateTime
      createdBy {
        fullName
      }
      lastModifiedFullName
      lastModifiedDateTime
      makeUpCredits {
        term1
        term2
        term3
        term4
        workshop
      }
      description
    }
    pageIndex
    pageSize
    total
  }
}
    `;
export const ExportCourseCsvWithIndex = gql`
    query ExportCourseCsvWithIndex($filterInput: CourseFilterInput) {
  csv: exportCourseCsvWithIndex(filterInput: $filterInput) {
    url
  }
}
    `;
export const UpdateEnrollmentFollowUpStatus = gql`
    mutation updateEnrollmentFollowUpStatus($input: UpdateEnrollmentFollowUpStatusInput!) {
  updateEnrollmentFollowUpStatus(input: $input) {
    startDate
    id: _id
    followUpStatus
    followUpStatusLastModifiedDateTime
    followUpStatusLastModifiedBy {
      ...CreateByFields
    }
  }
}
    ${CreateByFields}`;
export const GetEnrollmentWithFollowUpStatus = gql`
    query getEnrollmentWithFollowUpStatus($enrollmentId: ObjectId!) {
  enrollment(id: $enrollmentId) {
    id: _id
    followUpStatus
    followUpStatusLastModifiedDateTime
    followUpStatusLastModifiedBy {
      ...CreateByFields
    }
  }
}
    ${CreateByFields}`;
export const GetActivitiesBySourceIds = gql`
    query GetActivitiesBySourceIds($query: ActivityLogListQuery!, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  activityLogs(query: $query, sortInfo: $sortInfo, pageInfo: $pageInfo) {
    items {
      id: _id
      createdBy {
        ...UserFields
      }
      createDateTime
      lastModifiedBy {
        ...UserFields
      }
      lastModifiedDateTime
      adds {
        ... on SystemUser {
          id: _id
          fullName
        }
      }
      removes {
        ... on SystemUser {
          id: _id
          fullName
        }
      }
      previousAndUpdateValue {
        ... on TaskActivityLogDateValue {
          previousValue
          value
        }
      }
      type
      subType
      keywords
    }
    pageIndex
    pageSize
    total
  }
}
    ${UserFields}`;
export const GetTasks = gql`
    query GetTasks($filterInput: TaskFilterInput, $pageInfo: PageInfo, $sortInfo: SortInfo) {
  tasks(filterInput: $filterInput, pageInfo: $pageInfo, sortInfo: $sortInfo) {
    items {
      ...TaskFields
    }
    pageSize
    pageIndex
    total
  }
}
    ${TaskFields}`;
export const GetIndexTasks = gql`
    query GetIndexTasks($filterInput: TaskFilterInput, $pageInfo: PageInfo, $sortInfo: SortInfo) {
  tasksWithIndex(filterInput: $filterInput, pageInfo: $pageInfo, sortInfo: $sortInfo) {
    items {
      ...TaskIndexFields
    }
    pageSize
    pageIndex
    total
  }
}
    ${TaskIndexFields}`;
export const GetPendingOverdueTaskCount = gql`
    query GetPendingOverdueTaskCount {
  getPendingOverdueTask {
    type
    pending
    overdue
  }
}
    `;
export const GetTask = gql`
    query GetTask($id: ObjectId!) {
  task(id: $id) {
    ...TaskFields
  }
}
    ${TaskFields}`;
export const GetTaskList = gql`
    query GetTaskList($id: ObjectId!) {
  taskList(id: $id) {
    id: _id
    name
    isAutoGenerate
    startAt
    endAt
    weekDay
    generateAt
    isPinned
    parent {
      id: _id
      name
      isAutoGenerate
      startAt
      endAt
      weekDay
      generateAt
    }
  }
}
    `;
export const GetTaskLists = gql`
    query GetTaskLists($filterInput: TaskListFilterInput!, $pageInfo: PageInfo, $sortInfo: SortInfo) {
  taskLists(filterInput: $filterInput, pageInfo: $pageInfo, sortInfo: $sortInfo) {
    items {
      id: _id
      name
      isPinned
    }
    pageIndex
    pageSize
    total
  }
}
    `;
export const UpdateTaskDueDate = gql`
    mutation UpdateTaskDueDate($id: ObjectId!, $dueDate: DateTime) {
  updateTaskDueDate(id: $id, dueDate: $dueDate) {
    id: _id
    dueDate
    status
  }
}
    `;
export const CompleteTasks = gql`
    mutation CompleteTasks($command: BulkCompleteTaskCommand!) {
  completeTasks(command: $command) {
    id: _id
    status
  }
}
    `;
export const VoidTasks = gql`
    mutation VoidTasks($command: BulkVoidTaskCommand!) {
  voidTasks(command: $command) {
    id: _id
    status
  }
}
    `;
export const VoidTask = gql`
    mutation VoidTask($id: ObjectId!) {
  voidTask(id: $id) {
    id: _id
    status
  }
}
    `;
export const ReopenTask = gql`
    mutation ReopenTask($id: ObjectId!) {
  reopenTask(id: $id) {
    id: _id
    status
  }
}
    `;
export const CreateTaskList = gql`
    mutation CreateTaskList($input: CreateTaskListInput!) {
  createTaskList(input: $input) {
    id: _id
  }
}
    `;
export const UpdateTaskList = gql`
    mutation UpdateTaskList($input: UpdateTaskListInput!) {
  updateTaskList(input: $input) {
    id: _id
  }
}
    `;
export const DeleteTaskList = gql`
    mutation DeleteTaskList($id: ObjectId!) {
  deleteTaskList(id: $id) {
    id: _id
  }
}
    `;
export const CreateTask = gql`
    mutation CreateTask($input: CreateTaskInput!) {
  createTask(input: $input) {
    ...TaskFields
  }
}
    ${TaskFields}`;
export const CreateTaskChecklist = gql`
    mutation CreateTaskChecklist($input: CreateTaskChecklistInput!) {
  createTaskChecklist(input: $input) {
    ...TaskFields
  }
}
    ${TaskFields}`;
export const UpdateTaskChecklist = gql`
    mutation UpdateTaskChecklist($input: UpdateTaskChecklistInput!) {
  updateTaskChecklist(input: $input) {
    ...TaskFields
  }
}
    ${TaskFields}`;
export const DeleteTaskChecklist = gql`
    mutation DeleteTaskChecklist($id: ObjectId!) {
  deleteTaskChecklist(id: $id) {
    ...TaskFields
  }
}
    ${TaskFields}`;
export const CreateTaskChecklistItem = gql`
    mutation CreateTaskChecklistItem($input: CreateTaskChecklistItemInput!) {
  createTaskChecklistItem(input: $input) {
    ...TaskFields
  }
}
    ${TaskFields}`;
export const UpdateTaskChecklistItem = gql`
    mutation UpdateTaskChecklistItem($input: UpdateTaskChecklistItemInput!) {
  updateTaskChecklistItem(input: $input) {
    ...TaskFields
  }
}
    ${TaskFields}`;
export const DeleteTaskChecklistItem = gql`
    mutation DeleteTaskChecklistItem($id: ObjectId!) {
  deleteTaskChecklistItem(id: $id) {
    ...TaskFields
  }
}
    ${TaskFields}`;
export const UpdateTask = gql`
    mutation UpdateTask($input: UpdateTaskInput!) {
  updateTask(input: $input) {
    ...TaskFields
  }
}
    ${TaskFields}`;
export const CopyTaskList = gql`
    mutation CopyTaskList($input: CopyTaskListInput!) {
  copyTaskList(input: $input) {
    id: _id
  }
}
    `;
export const PinTaskList = gql`
    mutation PinTaskList($id: ObjectId!) {
  pinTaskList(id: $id) {
    id: _id
  }
}
    `;
export const GetWaitingListOverviewClasses = gql`
    query GetWaitingListOverviewClasses($filterInput: ClassFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  classesWithIndex(filter: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) @connection(key: "classes", filter: ["filter", "sortInfo"]) {
    items {
      id: _id
      name
      status
      type
      capacity {
        regular
        used
      }
    }
    total
    pageSize
    pageIndex
  }
}
    `;
export const GetWaitingListOverviewCourses = gql`
    query GetWaitingListOverviewCourses($filterInput: CourseFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  coursesWithIndex(filter: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) {
    items {
      id: _id
      name
      nameShort
      type
    }
    pageIndex
    pageSize
    total
  }
}
    `;
export const GetWaitingListOverviewRegistrations = gql`
    query GetWaitingListOverviewRegistrations($filterInput: RegistrationFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  registrations(filterInput: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) @connection(key: "registrations", filter: ["filterInput", "sortInfo"]) {
    items {
      id: _id
      student {
        id: _id
        fullName
        userId
        avatarUrl
      }
      name
      status
      startDate
      updateHistories {
        originalStatus
        status
        timestamp
      }
      isPrioritized
      createDateTime
      waitingListCreatedAt
      lastModifiedDateTime
    }
    pageIndex
    pageSize
    total
  }
}
    `;
export const PrioritizeRegistration = gql`
    mutation PrioritizeRegistration($input: UpdateRegistrationInput!) {
  prioritizeRegistration(input: $input) {
    id: _id
    isPrioritized
  }
}
    `;
export const CreateCourseNotification = gql`
    mutation CreateCourseNotification($input: CreateCourseNotificationInput!) {
  createCourseNotification(input: $input) {
    id: _id
  }
}
    `;
export const DeleteCourseNotification = gql`
    mutation DeleteCourseNotification($id: ObjectId!) {
  deleteCourseNotification(id: $id) {
    id: _id
  }
}
    `;
export const CourseNotifications = gql`
    query CourseNotifications($filterInput: CourseNotificationFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  courseNotifications(filterInput: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) {
    items {
      id: _id
      student {
        id: _id
        fullName
        userId
        avatarUrl
        primaryParent {
          id: _id
          fullName
          mobile {
            countryCode
            number
          }
          linkedWhatsappAccount {
            number {
              countryCode
              number
            }
          }
        }
        parents {
          id: _id
          fullName
          mobile {
            countryCode
            number
          }
          linkedWhatsappAccount {
            id: _id
            number {
              countryCode
              number
            }
          }
        }
      }
      academicYear {
        year
      }
      newClasses {
        id: _id
        shorthand
      }
      enrollments {
        type
        status
        startDate
        endDate
        registration {
          type
          status
        }
        class {
          shorthand
          id: _id
        }
      }
      createDateTime
    }
  }
}
    `;
export const GetStudentWithEnrollments = gql`
    query GetStudentWithEnrollments($id: ObjectId!, $filterInput: StudentEnrollmentFilterInput) {
  student(id: $id) {
    id: _id
    enrollments(filterInput: $filterInput) {
      id: _id
    }
  }
}
    `;
export const GetParentsOnAddInvoice = gql`
    query GetParentsOnAddInvoice($search: String, $filterInput: ParentFilter, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  parents(search: $search, filterInput: $filterInput, pageInfo: $pageInfo, sortInfo: $sortInfo) {
    items {
      id: _id
      fullName
      salutation
      userId
    }
    total
    pageIndex
    pageSize
  }
}
    `;
export const GetStudentsOnAddInvoice = gql`
    query GetStudentsOnAddInvoice($search: String, $filterInput: StudentFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  students(search: $search, filterInput: $filterInput, pageInfo: $pageInfo, sortInfo: $sortInfo) {
    items {
      id: _id
      fullName
      userId
      primaryParent {
        id: _id
      }
    }
    total
    pageIndex
    pageSize
  }
}
    `;
export const GetStudentEnrollmentsOnAddInvoice = gql`
    query GetStudentEnrollmentsOnAddInvoice($id: ObjectId!, $filterInput: StudentEnrollmentFilterInput) {
  student(id: $id) {
    enrollments(filterInput: $filterInput) {
      id: _id
      class {
        id: _id
        name
      }
      type
      status
    }
  }
}
    `;
export const PreviewAdHocInvoice = gql`
    query PreviewAdHocInvoice($input: CreateAdHocInvoiceCommand!) {
  previewAdHocInvoice(input: $input) {
    ...InvoiceItemFields
  }
}
    ${InvoiceItemFields}`;
export const GetInvoiceItemOptions = gql`
    query GetInvoiceItemOptions {
  invoiceItemOptions {
    ...InvoiceItemOptionFields
  }
}
    ${InvoiceItemOptionFields}`;
export const CreateInvoiceItemOption = gql`
    mutation CreateInvoiceItemOption($input: CreateInvoiceItemOptionInput!) {
  createInvoiceItemOption(input: $input) {
    ...InvoiceItemOptionFields
  }
}
    ${InvoiceItemOptionFields}`;
export const UpdateInvoiceItemOption = gql`
    mutation UpdateInvoiceItemOption($input: UpdateInvoiceItemOptionInput!) {
  updateInvoiceItemOption(input: $input) {
    ...InvoiceItemOptionFields
  }
}
    ${InvoiceItemOptionFields}`;
export const DeleteInvoiceItemOption = gql`
    mutation DeleteInvoiceItemOption($id: ObjectId!) {
  deleteInvoiceItemOption(id: $id) {
    ...InvoiceItemOptionFields
  }
}
    ${InvoiceItemOptionFields}`;
export const CreateAdHocInvoice = gql`
    mutation CreateAdHocInvoice($input: CreateAdHocInvoiceCommand!) {
  createAdHocInvoice(input: $input) {
    id: _id
  }
}
    `;
export const QueryDiscounts = gql`
    query queryDiscounts($showArchived: Boolean, $showVisible: Boolean) {
  discounts(showArchived: $showArchived, showVisible: $showVisible) {
    ...discountFields
  }
}
    ${DiscountFields}`;
export const CreateDisCount = gql`
    mutation createDisCount($input: CreateDiscountInput!) {
  createDiscount(input: $input) {
    ...discountFields
  }
}
    ${DiscountFields}`;
export const UpdateDiscount = gql`
    mutation updateDiscount($input: UpdateDiscountInput!) {
  updateDiscount(input: $input) {
    ...discountFields
  }
}
    ${DiscountFields}`;
export const ArchiveDiscount = gql`
    mutation archiveDiscount($id: ObjectId!) {
  archiveDiscount(id: $id) {
    ...discountFields
  }
}
    ${DiscountFields}`;
export const RestoreDiscount = gql`
    mutation restoreDiscount($id: ObjectId!) {
  restoreDiscount(id: $id) {
    ...discountFields
  }
}
    ${DiscountFields}`;
export const CreatePaymentRecord = gql`
    mutation CreatePaymentRecord($input: CreatePaymentRecordInput!) {
  createPaymentRecord(input: $input) {
    id: _id
    details {
      amount
      invoice {
        ...InvoiceItemFields
      }
    }
    receipt {
      ...BasicReceiptFields
    }
    ...BasicPaymentRecordFields
    lastModifiedBy {
      ...UserFields
    }
  }
}
    ${InvoiceItemFields}
${BasicReceiptFields}
${BasicPaymentRecordFields}
${UserFields}`;
export const UpdatePaymentRecord = gql`
    mutation updatePaymentRecord($input: UpdatePaymentRecordInput!) {
  updatePaymentRecord(input: $input) {
    id: _id
  }
}
    `;
export const CreateRefund = gql`
    mutation createRefund($input: CreateRefundInput!) {
  createRefund(input: $input) {
    id: _id
  }
}
    `;
export const VoidRefund = gql`
    mutation voidRefund($id: ObjectId!) {
  voidRefund(id: $id) {
    id: _id
  }
}
    `;
export const RegenerateInvoice = gql`
    mutation regenerateInvoice($id: ObjectId!) {
  regenerateInvoice(id: $id) {
    id: _id
  }
}
    `;
export const GenerateNextInvoice = gql`
    mutation generateNextInvoice($id: ObjectId!) {
  generateNextInvoice(id: $id) {
    id: _id
  }
}
    `;
export const VoidPaymentRecord = gql`
    mutation voidPaymentRecord($input: VoidPaymentRecordInput!) {
  voidPaymentRecord(input: $input) {
    id: _id
  }
}
    `;
export const VoidInvoice = gql`
    mutation voidInvoice($id: ObjectId!) {
  voidInvoice(id: $id) {
    id: _id
    status
  }
}
    `;
export const AdjustBalance = gql`
    mutation adjustBalance($input: AdjustBalanceInput!) {
  adjustBalance(input: $input) {
    id: _id
  }
}
    `;
export const GetReceipts = gql`
    query GetReceipts($search: String, $filterInput: ReceiptFilterInput, $sort: SortInfo, $page: PageInfo) {
  receipts(search: $search, filterInput: $filterInput, sortInfo: $sort, pageInfo: $page) @connection(key: "receipts", filter: ["search", "filterInput", "sortInfo"]) {
    items {
      id: _id
      referenceNumber
      status
      isNotified
      paymentRecord {
        ...PaymentFields
      }
      contact {
        id: _id
        fullName
      }
      createDateTime
      createdBy {
        ...CreateByFields
      }
    }
    pageIndex
    pageSize
    total
  }
}
    ${PaymentFields}
${CreateByFields}`;
export const GetReceipt = gql`
    query GetReceipt($id: ObjectId!) {
  receipt(id: $id) {
    ...ListReceiptFields
  }
}
    ${ListReceiptFields}`;
export const GetRefund = gql`
    query GetRefund($id: ObjectId!) {
  refund(id: $id) {
    ...ListRefundFields
  }
}
    ${ListRefundFields}`;
export const GetRefunds = gql`
    query GetRefunds($filterInput: RefundFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  refunds(filterInput: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) @connection(key: "refunds", filter: ["filterInput"]) {
    items {
      ...ListRefundFields
      waStatus
      emailStatus
    }
    pageIndex
    pageSize
    total
  }
}
    ${ListRefundFields}`;
export const GetInVoicePaymentRecord = gql`
    query getInVoicePaymentRecord($id: ObjectId!) {
  invoice(id: $id) {
    id: _id
    total
    totalTax
    subtotal
    dueDate
    amountPaid
    dueDate
    referenceNumber
    billedTo {
      id: _id
    }
    payments {
      ...PaymentFields
    }
  }
}
    ${PaymentFields}`;
export const GetInvoice = gql`
    query getInvoice($id: ObjectId!) {
  invoice(id: $id) {
    ...InvoiceItemFields
  }
}
    ${InvoiceItemFields}`;
export const GetInvoiceExport = gql`
    query getInvoiceExport($id: ObjectId!, $printBase64: Boolean) {
  invoiceExport(id: $id, printBase64: $printBase64) {
    url
  }
}
    `;
export const GetReceiptExport = gql`
    query getReceiptExport($id: ObjectId!, $printBase64: Boolean) {
  receiptExport(id: $id, printBase64: $printBase64) {
    url
  }
}
    `;
export const GetRefundExport = gql`
    query getRefundExport($id: ObjectId!, $printBase64: Boolean) {
  refundExport(id: $id, printBase64: $printBase64) {
    url
  }
}
    `;
export const GetStatementOfAccountInfo = gql`
    query GetStatementOfAccountInfo($parentId: ObjectId!) {
  statementOfAccount(parentId: $parentId) {
    id
    amountAudits {
      pending
      unpaid
      overdue
      onHold
      paid
    }
    parent {
      id: _id
      fullName
      salutation
      alias
      gender
      email
      avatarUrl
      alternateUserId
      userId
      mobile {
        countryCode
        number
      }
      linkedWhatsappAccount {
        number {
          countryCode
          number
        }
      }
      relatedStudents {
        relationName(id: $parentId)
        ...RelationshipStudentFields
      }
    }
    creditRemain
    refund
  }
}
    ${RelationshipStudentFields}`;
export const GetFinanceRecordOfAccountNumber = gql`
    query GetFinanceRecordOfAccountNumber($parentId: ObjectId!) {
  statementOfAccountNumber(parentId: $parentId) {
    invoices
    receipts
    balanceHistories
    refunds
  }
}
    `;
export const GetFinanceRecordOfRefund = gql`
    query GetFinanceRecordOfRefund($filterInput: RefundFilterInput, $pageInfo: PageInfo) {
  refunds(filterInput: $filterInput, pageInfo: $pageInfo) @connection(key: "GetFinanceRecordOfRefund", filter: ["filterInput", "sortInfo"]) {
    items {
      ...ListRefundFields
      waStatus
      emailStatus
    }
    pageIndex
    pageSize
    total
  }
}
    ${ListRefundFields}`;
export const GetFinanceRecordOfReceipt = gql`
    query GetFinanceRecordOfReceipt($search: String, $filterInput: ReceiptFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  receipts(search: $search, filterInput: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) @connection(key: "GetFinanceRecordOfReceipt", filter: ["search", "filterInput", "sortInfo", "pageInfo"]) {
    items {
      ...ListReceiptFields
      waStatus
      emailStatus
    }
    pageIndex
    pageSize
    total
  }
}
    ${ListReceiptFields}`;
export const GetFinanceRecordOfInvoice = gql`
    query GetFinanceRecordOfInvoice($search: String, $filterInput: InvoiceFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  invoices(search: $search, filterInput: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) @connection(key: "GetFinanceRecordOfInvoice", filter: ["search", "filterInput", "sortInfo", "pageInfo"]) {
    items {
      ...InvoiceItemFields
      reminders {
        type
        status
        attachmentStatus
        template {
          type
        }
      }
    }
    pageIndex
    pageSize
    total
  }
}
    ${InvoiceItemFields}`;
export const GetFinanceRecordOfBalanceHistory = gql`
    query GetFinanceRecordOfBalanceHistory($filterInput: BalanceHistoryFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  balanceHistories(filterInput: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) @connection(key: "GetFinanceRecordOfBalanceHistory", filter: ["filterInput", "sortInfo", "pageInfo"]) {
    items {
      ...ListBalanceHistoryFields
    }
    pageIndex
    pageSize
    total
  }
}
    ${ListBalanceHistoryFields}`;
export const InvoiceCsv = gql`
    query invoiceCsv($search: String, $filterInput: InvoiceFilterInput) {
  csv: exportInvoiceCsv(search: $search, filterInput: $filterInput) {
    url
  }
}
    `;
export const ReceiptsCsv = gql`
    query receiptsCsv($search: String, $filterInput: ReceiptFilterInput) {
  csv: exportReceiptsCsv(search: $search, filterInput: $filterInput) {
    url
  }
}
    `;
export const RefundCsv = gql`
    query refundCsv($filterInput: RefundFilterInput) {
  csv: exportRefundCsv(filterInput: $filterInput) {
    url
  }
}
    `;
export const StatementOfAccountCsv = gql`
    query statementOfAccountCsv($filter: StatementOfAccountFilter) {
  csv: exportStatementOfAccountCsv(filter: $filter) {
    url
  }
}
    `;
export const UpdateInvoiceDueDate = gql`
    mutation updateInvoiceDueDate($updateInvoiceInput: UpdateInvoiceDueDateCommand!) {
  updateInvoiceDueDate(input: $updateInvoiceInput) {
    ...InvoiceFields
  }
}
    ${InvoiceFields}`;
export const GetReceiptDetails = gql`
    query GetReceiptDetails($id: ObjectId!) {
  receiptDetails(id: $id)
}
    `;
export const BulkSendReminder = gql`
    mutation bulkSendReminder($input: BulkSendReminderInput!) {
  bulkSendReminder(input: $input) {
    ...NotifyMissingInfoFields
  }
}
    ${NotifyMissingInfoFields}`;
export const OnHoldInvoice = gql`
    mutation onHoldInvoice($input: OnHoldInvoiceCommand!) {
  onHoldInvoice(input: $input) {
    id: _id
    isOnHold
    onHoldRemarks
  }
}
    `;
export const GetListInvoiceData = gql`
    query GetListInvoiceData($search: String, $filterInput: InvoiceFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  invoices(search: $search, filterInput: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) @connection(key: "invoices", filter: ["search", "filterInput", "sortInfo"]) {
    items {
      id: _id
      referenceNumber
      createDateTime
      total
      amountPaid
      hasTransferred
      isOtherFee
      status
      dueDate
      isOnHold
      onHoldRemarks
      billedTo {
        id: _id
        fullName
        userId
        mobile {
          countryCode
          number
        }
        relatedParents {
          id: _id
          fullName
          mobile {
            countryCode
            number
          }
        }
      }
      billedFor {
        ...StudentCellFields
      }
      class {
        id: _id
        name
        academicYear {
          id: _id
          year
        }
        teacher {
          id: _id
        }
      }
      xeroInvoices {
        xeroInvoiceId
        year
        month
        amount
        lastSyncedDate
        url
      }
      course {
        id: _id
        name
        nameShort
        type
        colorCode
      }
      payments {
        paymentId: _id
        xeroPrepayments {
          prepaymentId: id
          amount
          lineItemId
          lastSyncedDate
        }
        method
        amount
        createDateTime
        depositDate
        remarks
        reference
        isVoid
        xeroPaymentSyncStatus
        url
      }
      reminders {
        id: _id
        type
        status
        attachmentStatus
        template {
          type
        }
      }
    }
    pageIndex
    pageSize
    total
  }
}
    ${StudentCellFields}`;
export const GetListInvoiceIndexData = gql`
    query GetListInvoiceIndexData($filterInput: InvoiceFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  invoicesWithIndex(filter: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) {
    items {
      ...InvoiceIndexFields
    }
    pageIndex
    pageSize
    total
  }
}
    ${InvoiceIndexFields}`;
export const GetInvoiceIndexData = gql`
    query GetInvoiceIndexData($id: ObjectId!) {
  invoiceWithIndex(id: $id) {
    ...InvoiceIndexFields
  }
}
    ${InvoiceIndexFields}`;
export const GetListReceiptData = gql`
    query GetListReceiptData($filterInput: ReceiptFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  receiptsWithIndex(filter: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) {
    items {
      ...ReceiptIndexFields
    }
    pageIndex
    pageSize
    total
  }
}
    ${ReceiptIndexFields}`;
export const BulkExportReceipts = gql`
    query BulkExportReceipts($filterInput: ReceiptFilterInput!, $printBase64: Boolean) {
  bulkExportReceipts(filterInput: $filterInput, printBase64: $printBase64) {
    url
  }
}
    `;
export const GetListStatementOfAccountData = gql`
    query GetListStatementOfAccountData($filterInput: ParentFilter, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  parentsWithIndex(filter: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) {
    items {
      id: _id
      userId
      fullName
      salutation
      alias
      gender
      email
      mobile {
        countryCode
        number
      }
      pending
      unpaid
      overdue
      onHold
      balance
      invoiceStatuses
    }
    pageIndex
    pageSize
    total
  }
}
    `;
export const GetPaymentRecordModalData = gql`
    query GetPaymentRecordModalData($invoiceId: ObjectId!) {
  invoice(id: $invoiceId) {
    ...BasicInvoiceFields
    payments {
      details {
        amount
        balanceAmount
        invoice {
          id: _id
          referenceNumber
        }
      }
      ...BasicPaymentRecordFields
      receipt {
        ...BasicReceiptFields
      }
      lastModifiedBy {
        ...UserFields
      }
    }
    billedTo {
      financeStatement {
        id
        creditRemain
      }
    }
  }
}
    ${BasicInvoiceFields}
${BasicPaymentRecordFields}
${BasicReceiptFields}
${UserFields}`;
export const GetPaymentInvoices = gql`
    query GetPaymentInvoices($filterInput: InvoiceFilterInput) {
  invoices(filterInput: $filterInput, pageInfo: {pageIndex: 0, pageSize: 0}) {
    items {
      id: _id
      referenceNumber
      subtotal
      totalTax
      total
      amountPaid
      billedTo {
        financeStatement {
          id
          creditRemain
        }
      }
    }
  }
}
    `;
export const GetAcademicLevelCheckBoxData = gql`
    query GetAcademicLevelCheckBoxData {
  academicLevels {
    id: _id
    name
    educationStage
  }
}
    `;
export const GetAcademicYearSelectOptions = gql`
    query GetAcademicYearSelectOptions {
  academicYears {
    id: _id
    year
    period {
      start
      end
    }
  }
}
    `;
export const GetLessonFilterOptions = gql`
    query GetLessonFilterOptions {
  academicYears {
    id: _id
    year
    academicWeeks {
      startDate
      endDate
      weekNumber
    }
  }
  branches {
    id: _id
    name
    alias
    venues {
      ...BasicVenueFields
    }
  }
}
    ${BasicVenueFields}`;
export const GetGmlAndSmsFilterOptions = gql`
    query GetGMLAndSMSFilterOptions($filterInput: SystemUserQuery!, $CourseType: [CourseType!]) {
  academicYears {
    id: _id
    year
  }
  branches {
    id: _id
    name
    alias
    venues {
      ...BasicVenueFields
    }
  }
}
    ${BasicVenueFields}`;
export const UpdateLesson = gql`
    mutation UpdateLesson($updateLessonInput: UpdateLessonCommand!) {
  updateLesson(input: $updateLessonInput) {
    id: _id
    teacher {
      id: _id
      fullName
    }
    venue {
      id: _id
      shorthand
      unitNo
      capacity
    }
    lessonStart
    lessonEnd
    maximumClassCapacity
    lessonDay
    lessonNumber
    class {
      id: _id
      name
      academicYear {
        id: _id
        year
      }
    }
  }
}
    `;
export const CreateLesson = gql`
    mutation CreateLesson($CreateLessonInput: CreateLessonInput!) {
  createLesson(input: $CreateLessonInput) {
    id: _id
    teacher {
      id: _id
      fullName
    }
    venue {
      id: _id
      shorthand
      unitNo
      capacity
    }
    lessonStart
    lessonEnd
    maximumClassCapacity
    lessonDay
    lessonNumber
    class {
      id: _id
      name
      academicYear {
        id: _id
        year
      }
    }
  }
}
    `;
export const DeleteLesson = gql`
    mutation DeleteLesson($id: ObjectId!) {
  deleteLesson(id: $id) {
    id: _id
  }
}
    `;
export const DeleteLessons = gql`
    mutation DeleteLessons($ids: [ObjectId!]!) {
  deleteLessons(ids: $ids) {
    id: _id
  }
}
    `;
export const QueryClassrooms = gql`
    query queryClassrooms {
  venues {
    ...BasicVenueFields
  }
}
    ${BasicVenueFields}`;
export const GetLessons = gql`
    query getLessons($search: String, $filterInput: LessonFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo, $AttendanceStatus: [AttendanceStatus!], $AttendanceType: [AttendanceType!]) {
  lessons(search: $search, filterInput: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) @connection(key: "lessons", filter: ["filterInput", "sortInfo"]) {
    items {
      id: _id
      teacher {
        id: _id
        fullName
        salutation
        avatarUrl
        gender
        initials
      }
      venue {
        id: _id
        shorthand
        unitNo
        capacity
        branch {
          id: _id
          name
          alias
        }
      }
      lessonStart
      lessonEnd
      maximumClassCapacity
      lessonDay
      lessonNumber
      academicWeek {
        startDate
        endDate
        weekNumber
      }
      attendance(status: $AttendanceStatus, type: $AttendanceType) {
        id: _id
        status
        type
        student {
          ...StudentFields
        }
        linkedAttendance {
          startDateTime
          endDateTime
          status
          type
          class {
            teacher {
              ...BasicTeacherFields
            }
          }
        }
        remarks(includesStudentRemarks: true, showArchived: false) {
          ...RemarkFields
        }
      }
      capacity {
        ...LessonCapacityFields
      }
      class {
        id: _id
        name
        academicYear {
          id: _id
          year
        }
        course {
          id: _id
          name
          nameShort
          avatarUrl
          colorCode
        }
        maximumClassCapacity
        maximumMakeUpCapacity
      }
      course {
        id: _id
        name
        nameShort
        avatarUrl
        type
      }
      totalStudent
      absentStudent
      noStatusStudent
    }
    pageIndex
    pageSize
    total
  }
}
    ${StudentFields}
${BasicTeacherFields}
${RemarkFields}
${LessonCapacityFields}`;
export const GetLesson = gql`
    query getLesson($id: ObjectId!) {
  lesson(id: $id) {
    id: _id
    status
    teacher {
      id: _id
    }
    venue {
      id: _id
      unitNo
      shorthand
      capacity
      branch {
        name
        alias
      }
      isArchived
    }
    academicWeek {
      weekNumber
    }
    lessonStart
    lessonEnd
    lessonNotes
    class {
      id: _id
      type
      isAdHoc
      isSkipScheduledHoliday
    }
  }
}
    `;
export const GetLessonDetail = gql`
    query getLessonDetail($id: ObjectId!) {
  lesson(id: $id) {
    id: _id
    status
    lessonStart
    lessonEnd
    lessonNumber
    lessonNotes
    class {
      id: _id
      name
      learningArrangement
      lessons {
        id: _id
        attendance {
          _id
          status
          student {
            _id
          }
          type
        }
        isHoliday
        lessonStart
        lessonEnd
      }
    }
  }
}
    `;
export const GetFindAdditionalLesson = gql`
    query getFindAdditionalLesson($id: ObjectId!) {
  lesson(id: $id) {
    id: _id
    status
    lessonStart
    lessonEnd
    lessonNumber
    holidays {
      name
    }
  }
}
    `;
export const GetLessonsStartDate = gql`
    query getLessonsStartDate {
  lessons {
    items {
      id: _id
      lessonStart
    }
  }
}
    `;
export const LessonCsv = gql`
    query lessonCsv($search: String, $filterInput: LessonFilterInput) {
  csv: exportLessonCsv(search: $search, filterInput: $filterInput) {
    url
  }
}
    `;
export const LessonCsvWithIndex = gql`
    query LessonCsvWithIndex($filterInput: LessonFilterInput) {
  csv: exportLessonCsvWithIndex(filterInput: $filterInput) {
    url
  }
}
    `;
export const GetListLessonWithIndexData = gql`
    query GetListLessonWithIndexData($filterInput: LessonFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  lessonsWithIndex(filter: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) {
    items {
      id: _id
      status
      lessonStart
      lessonEnd
      lessonNumber
      academicWeek {
        weekNumber
      }
      isHoliday
      teacher {
        id: _id
        fullName
        initials
        salutation
        avatarUrl
        gender
      }
      course {
        id: _id
        name
        avatarUrl
        nameShort
      }
      class {
        id: _id
        name
        shorthand
        type
      }
      venue {
        id: _id
        shorthand
        unitNo
      }
      branch {
        id: _id
        name
        alias
      }
      totalStudents
      absentStudents
      noStatusStudents
      createDateTime
      createdBy {
        fullName
      }
      lastModifiedDateTime
      lastModifiedByFullName
    }
    pageIndex
    pageSize
    total
  }
}
    `;
export const GetListLessonInCreateClassFlowData = gql`
    query GetListLessonInCreateClassFlowData($classId: ObjectId!) {
  class(id: $classId) {
    isAdHoc
    type
    learningArrangement
    academicYear {
      year
      groupAssignment {
        items {
          weekNumber
          groupA
          groupB
        }
      }
    }
    id: _id
    learningArrangement
    term {
      ...BasicTermFields
    }
    course {
      id: _id
      type
    }
    lessons {
      isHoliday
      academicWeek {
        weekNumber
      }
      holidays {
        name
      }
      ...BasicLessonFields
      teacher {
        ...BasicTeacherFields
      }
      venue {
        ...BasicVenueFields
      }
    }
  }
}
    ${BasicTermFields}
${BasicLessonFields}
${BasicTeacherFields}
${BasicVenueFields}`;
export const GetDelivery = gql`
    query GetDelivery($id: ObjectId!) {
  delivery(id: $id) {
    ...DeliveryDetailFields
  }
}
    ${DeliveryDetailFields}`;
export const UpdateDelivery = gql`
    mutation UpdateDelivery($input: UpdateDeliveryInput!) {
  updateDelivery(input: $input) {
    ...DeliveryDetailFields
  }
}
    ${DeliveryDetailFields}`;
export const GetDeliveriesWithIndex = gql`
    query GetDeliveriesWithIndex($filterInput: DeliveryFilterInput, $pageInfo: PageInfo, $sortInfo: SortInfo) {
  deliveriesWithIndex(filter: $filterInput, pageInfo: $pageInfo, sortInfo: $sortInfo) {
    items {
      id: _id
      type
      dropOffPostalCode
      user {
        fullName
        email
        mobile {
          countryCode
          number
        }
      }
      lastModifiedBy {
        fullName
      }
      createdBy {
        fullName
      }
      students {
        id: _id
        fullName
      }
      materials {
        envelope {
          ...MaterialEnvelopeDOFields
        }
        adHocMaterials
      }
      remarks
      address
      dropOffAddress
      dropOffMobile {
        countryCode
        number
      }
      dropOffRemarks
      pickupStartAt
      pickupEndAt
      trackingNo
      trackingUrl
      status
      parcelSize
      createDateTime
      lastModifiedDateTime
    }
    total
    pageIndex
    pageSize
  }
}
    ${MaterialEnvelopeDoFields}`;
export const PlaceOrderDelivery = gql`
    mutation PlaceOrderDelivery($id: ObjectId!) {
  placeOrderDelivery(deliveryId: $id) {
    id: _id
  }
}
    `;
export const DeleteDelivery = gql`
    mutation DeleteDelivery($id: ObjectId!) {
  deleteDelivery(id: $id) {
    id: _id
  }
}
    `;
export const GetDistributionCourseList = gql`
    query GetDistributionCourseList($filterInput: CourseFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  coursesWithIndex(filter: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) {
    items {
      id: _id
      avatarUrl
      name
      status
      type
      ongoingClasses
      academicLevels {
        name
      }
    }
    pageIndex
    pageSize
    total
  }
}
    `;
export const GetDistributionWithMaterialsIndex = gql`
    query GetDistributionWithMaterialsIndex($filterInput: MaterialFilterInput, $pageInfo: PageInfo, $sortInfo: SortInfo) {
  materialsWithIndex(filter: $filterInput, pageInfo: $pageInfo, sortInfo: $sortInfo) {
    items {
      ...DistributionWithMaterialsFields
    }
    total
    pageIndex
    pageSize
  }
}
    ${DistributionWithMaterialsFields}`;
export const GetPackingListsInDistribution = gql`
    query GetPackingListsInDistribution($courseIds: [ObjectId!]) {
  packingLists(filter: {courseIds: $courseIds}) {
    items {
      id: _id
      name
      createDateTime
      version {
        year {
          id: _id
          year
        }
      }
      course {
        name
        type
      }
      envelopes {
        id: _id
        title
        status
        imageUrl
        academicWeeks {
          weekNumber
          startDate
          endDate
        }
        booklets {
          title
          serialNumber
          imageUrl
          topic {
            name
          }
          worksheets {
            title
            serialNumber
            imageUrl
          }
        }
      }
    }
  }
}
    `;
export const CourseAcademicYearWithAcademicWeeks = gql`
    query CourseAcademicYearWithAcademicWeeks($year: Int!) {
  academicYear(year: $year) {
    id: _id
    year
    period {
      start
      end
    }
    academicWeeks {
      id: _id
      weekNumber
      startDate
      endDate
    }
  }
}
    `;
export const GetWillGeneratedMaterialEnrollments = gql`
    query GetWillGeneratedMaterialEnrollments($id: ObjectId!, $envelopeId: ObjectId!) {
  packingList(id: $id) {
    id: _id
    willGeneratedMaterialEnrollments(envelopeId: $envelopeId) {
      id: _id
      student {
        id: _id
        fullName
        userId
        gender
        avatarUrl
      }
      startDate
      endDate
    }
  }
}
    `;
export const GetMaterialsWithIndex = gql`
    query GetMaterialsWithIndex($filterInput: MaterialFilterInput, $pageInfo: PageInfo, $sortInfo: SortInfo) {
  materialsWithIndex(filter: $filterInput, pageInfo: $pageInfo, sortInfo: $sortInfo) {
    items {
      id: _id
      courseId
      isAdHoc
      adHocMaterials
      createdBy {
        fullName
      }
      createDateTime
      lesson {
        lessonStart
        lessonEnd
        lessonNumber
      }
      remarks
      student {
        id: _id
        fullName
        userId
        gender
        avatarUrl
        address {
          streetAddress
          unit
          postalCode
          gateBuilding
        }
      }
      academicLevel {
        id: _id
        alias
      }
      address {
        streetAddress
        unit
        postalCode
        gateBuilding
      }
      delivery {
        trackingNo
        pickupStartAt
        pickupEndAt
        type
      }
      primaryParent {
        id: _id
        fullName
        salutation
        mobile {
          countryCode
          number
        }
      }
      primaryParentWhatsappAccount {
        number {
          countryCode
          number
        }
      }
      secondaryParents {
        id: _id
        fullName
        salutation
        mobile {
          countryCode
          number
        }
      }
      secondaryParentWhatsappAccounts {
        number {
          countryCode
          number
        }
      }
      enrollments {
        type
        learningArrangement
      }
      enrollmentStartAt
      enrollmentEndAt
      course {
        nameShort
      }
      status
      envelope {
        ...MaterialEnvelopeDOFields
      }
      lessonClass {
        id: _id
        shorthand
      }
      handoverOnsiteMaterialId
      lastModifyUser {
        id: _id
        fullName
      }
      lastModifiedDateTime
      isCollectFromReception
    }
    total
    pageIndex
    pageSize
  }
}
    ${MaterialEnvelopeDoFields}`;
export const GetMaterialStudents = gql`
    query GetMaterialStudents($filterInput: StudentFilterInput, $pageInfo: PageInfo, $sortInfo: SortInfo) {
  students(filterInput: $filterInput, pageInfo: $pageInfo, sortInfo: $sortInfo) {
    items {
      id: _id
      fullName
      avatarUrl
      userId
    }
    total
    pageIndex
    pageSize
  }
}
    `;
export const GetMaterialStudent = gql`
    query GetMaterialStudent($id: ObjectId!) {
  student(id: $id) {
    id: _id
    enrollments {
      id: _id
      class {
        id: _id
        course {
          id: _id
          nameShort
        }
      }
    }
  }
}
    `;
export const GetPackingLists = gql`
    query GetPackingLists($filter: PackingListsFilterInput) {
  packingLists(filter: $filter, pageInfo: {pageSize: 0, pageIndex: 0}) {
    items {
      id: _id
      envelopes {
        ...PackingListEnvelopeFields
      }
    }
    total
    pageIndex
    pageSize
  }
}
    ${PackingListEnvelopeFields}`;
export const CreateAdHocMaterial = gql`
    mutation CreateAdHocMaterial($input: CreateAdHocMaterialInput!) {
  createAdHocMaterial(input: $input) {
    id: _id
  }
}
    `;
export const DeleteAdHocMaterial = gql`
    mutation DeleteAdHocMaterial($id: ObjectId!) {
  deleteAdHocMaterial(id: $id) {
    id: _id
  }
}
    `;
export const UpdateMaterialDeliveryOption = gql`
    mutation UpdateMaterialDeliveryOption($input: UpdateMaterialDeliveryOptionInput!) {
  updateMaterialDeliveryOption(input: $input) {
    id: _id
  }
}
    `;
export const ResetMaterialStatus = gql`
    mutation ResetMaterialStatus($id: ObjectId!) {
  resetMaterialStatus(id: $id) {
    id: _id
  }
}
    `;
export const GetManualDeliveryOptionFormData = gql`
    query GetManualDeliveryOptionFormData($id: ObjectId!) {
  material(id: $id) {
    attendances {
      id: _id
      lesson {
        id: _id
        lessonNumber
        lessonDay
        lessonStart
        lessonEnd
        remarks
      }
    }
    status
    remarks
  }
}
    `;
export const GetMaterialAddress = gql`
    query GetMaterialAddress($id: ObjectId!) {
  material(id: $id) {
    id: _id
    student {
      id: _id
      address {
        country
        gateBuilding
        postalCode
        streetAddress
        unit
      }
    }
  }
}
    `;
export const UpdateStudentAddress = gql`
    mutation UpdateStudentAddress($input: UpdateStudentInput!) {
  updateStudent(input: $input) {
    id: _id
  }
}
    `;
export const BulkUpdateStudentAddressFromPrimaryParent = gql`
    mutation BulkUpdateStudentAddressFromPrimaryParent($ids: [ObjectId!]!) {
  bulkUpdateStudentAddressFromPrimaryParent(ids: $ids) {
    id: _id
  }
}
    `;
export const BulkUpdateStatusToAddressVerificationConfirmed = gql`
    mutation BulkUpdateStatusToAddressVerificationConfirmed($ids: [ObjectId!]!) {
  addressVerificationConfirmed(ids: $ids) {
    id: _id
  }
}
    `;
export const BulkHandoverOnsite = gql`
    mutation BulkHandoverOnsite($input: BulkHandoverOnsiteCommand!) {
  bulkHandoverOnsite(input: $input)
}
    `;
export const GetMaterials = gql`
    query GetMaterials($filterInput: MaterialFilterInput, $pageInfo: PageInfo, $sortInfo: SortInfo) {
  materials(filterInput: $filterInput, pageInfo: $pageInfo, sortInfo: $sortInfo) {
    items {
      id: _id
      student {
        id: _id
        fullName
        userId
        address {
          country
          gateBuilding
          postalCode
          streetAddress
          unit
        }
        mobile {
          countryCode
          number
        }
        primaryParent {
          id: _id
          mobile {
            countryCode
            number
          }
        }
      }
    }
  }
}
    `;
export const BulkCreateDelivery = gql`
    mutation BulkCreateDelivery($input: BulkCreateDeliveryInput!) {
  bulkCreateDelivery(input: $input) {
    id: _id
  }
}
    `;
export const GetSystemNotifications = gql`
    query getSystemNotifications($search: String, $filterInput: SystemNotificationFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  systemNotifications(search: $search, filterInput: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) {
    items {
      ...SystemNotificationFragment
    }
    pageIndex
    pageSize
    total
  }
}
    ${SystemNotificationFragment}`;
export const SystemNotification = gql`
    subscription systemNotification {
  systemNotification {
    ...SystemNotificationFragment
  }
}
    ${SystemNotificationFragment}`;
export const ReadSystemNotification = gql`
    mutation readSystemNotification($id: ObjectId!) {
  readSystemNotification(id: $id) {
    ...SystemNotificationFragment
  }
}
    ${SystemNotificationFragment}`;
export const HealthServiceChanged = gql`
    subscription healthServiceChanged {
  healthServiceChanged
}
    `;
export const GetLessonWithClassId = gql`
    query GetLessonWithClassId($classId: ObjectId!) {
  class(id: $classId) {
    id: _id
    lessons {
      _id
      lessonStart
    }
  }
}
    `;
export const GetAnalyticsClasses = gql`
    query GetAnalyticsClasses($search: String, $filterInput: ClassFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  classes(search: $search, filterInput: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) @connection(key: "classes", filter: ["search", "filterInput", "sortInfo"]) {
    items {
      id: _id
      name
      learningArrangement
      capacity {
        ...CapacityFields
      }
      capacityUsed
      teacher {
        id: _id
        fullName
        avatarUrl
        gender
        salutation
        initials
      }
      course {
        colorCode
      }
      defaultLessonStart
      defaultLessonEnd
      defaultLessonDays
      enrollments {
        id: _id
        type
        status
      }
      isAdHoc
      firstLessonDate
    }
    pageIndex
    pageSize
    total
  }
}
    ${CapacityFields}`;
export const GetAcademicLevelAnalyticsClasses = gql`
    query GetAcademicLevelAnalyticsClasses($ids: [ObjectId!], $filterInput: ClassFilterInput) {
  academicLevels(ids: $ids) {
    id: _id
    name
    alias
    classes(filterInput: $filterInput) {
      id: _id
      name
      learningArrangement
      lessons {
        lessonStart
      }
      capacity {
        ...CapacityFields
      }
      capacityUsed
      teacher {
        id: _id
        fullName
        avatarUrl
        gender
        salutation
        initials
      }
      course {
        colorCode
      }
      type
      dailySessionTimeSlots {
        start
        end
      }
      defaultLessonStart
      defaultLessonDays
      defaultLessonEnd
      firstLessonDate
      enrollments {
        id: _id
        type
        status
      }
      isAdHoc
    }
    seatsAnalyticsByAcademicLevel(filterInput: $filterInput) {
      totalAvailableSlots
      totalStudents
      enrollmentRating
    }
  }
}
    ${CapacityFields}`;
export const GetClassWithRegistrations = gql`
    query GetClassWithRegistrations($id: ObjectId!) {
  class(id: $id) {
    id: _id
    name
    shorthand
    type
    registrations {
      id: _id
      status
      isPrioritized
      student {
        id: _id
        fullName
        userId
      }
      lastModifiedDateTime
      startDate
      waitingListCreatedAt
    }
    capacity {
      regular
      used
    }
    academicWeeks {
      startDate
      endDate
      weekNumber
    }
  }
}
    `;
export const CompleteImportRegistrations = gql`
    mutation CompleteImportRegistrations($id: ObjectId!) {
  completeImportRegistrations(id: $id) {
    ...BulkCreateHistoryFields
  }
}
    ${BulkCreateHistoryFields}`;
export const ExportFailedImportRegistrations = gql`
    mutation ExportFailedImportRegistrations($id: ObjectId!) {
  exportFailedImportRegistrations(id: $id) {
    url
  }
}
    `;
export const PostImportRegistrationHistory = gql`
    subscription PostImportRegistrationHistory {
  postImportRegistrationHistory {
    ...BulkCreateHistoryFields
  }
}
    ${BulkCreateHistoryFields}`;
export const BulkCreateHistory = gql`
    query BulkCreateHistory($type: BulkCreateHistoryType!) {
  bulkCreateHistory(type: $type) {
    ...BulkCreateHistoryFields
  }
}
    ${BulkCreateHistoryFields}`;
export const ImportRegistrationHistoryCheckExport = gql`
    query ImportRegistrationHistoryCheckExport($type: BulkCreateHistoryType!) {
  bulkCreateHistory(type: $type) {
    id: _id
    isExportFailedDocuments
    type
  }
}
    `;
export const GetStudentsForImportRegistration = gql`
    query GetStudentsForImportRegistration($search: String, $filterInput: StudentFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  students(search: $search, filterInput: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) {
    items {
      ...StudentBaseFields
    }
    total
    pageIndex
    pageSize
  }
}
    ${StudentBaseFields}`;
export const GetClassesForImportRegistration = gql`
    query GetClassesForImportRegistration($search: String, $filterInput: ClassFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  classes(search: $search, filterInput: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) {
    items {
      id: _id
      classCode
      type
      isAdHoc
      lessons {
        id: _id
        lessonStart
        lessonNumber
      }
    }
    total
    pageIndex
    pageSize
  }
}
    `;
export const BulkCreateRegistration = gql`
    mutation BulkCreateRegistration($input: BulkCreateRegistrationCommand!) {
  bulkCreateRegistration(input: $input)
}
    `;
export const GetRegistrationFilterOptions = gql`
    query GetRegistrationFilterOptions($year: Int) {
  academicYear(year: $year) {
    terms {
      ...BasicTermFields
    }
    period {
      start
      end
    }
    academicWeekStart
    academicWeekEnd
    academicWeeks {
      startDate
      endDate
      weekNumber
    }
  }
}
    ${BasicTermFields}`;
export const GetListRegistrationData = gql`
    query GetListRegistrationData($search: String, $filterInput: RegistrationFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  registrationsWithIndex(search: $search, filterInput: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) {
    items {
      ...RegistrationCacheFields
    }
    pageIndex
    pageSize
    total
  }
}
    ${RegistrationCacheFields}`;
export const GetRegistrationCache = gql`
    query GetRegistrationCache($id: ObjectId!) {
  registrationWithIndex(id: $id) {
    ...RegistrationCacheFields
  }
}
    ${RegistrationCacheFields}`;
export const GetRegistrationOfAcademicTerm = gql`
    query getRegistrationOfAcademicTerm($year: Int!) {
  academicYear(year: $year) {
    id: _id
    terms {
      id: _id
      ...BasicTermFields
    }
  }
}
    ${BasicTermFields}`;
export const GetStudentOfActiveEnrollments = gql`
    query getStudentOfActiveEnrollments($id: ObjectId!, $input: StudentEnrollmentFilterInput) {
  student(id: $id) {
    id: _id
    enrollments(filterInput: $input) {
      id: _id
      class {
        id: _id
      }
    }
  }
}
    `;
export const GetReservationData = gql`
    query GetReservationData($filterInput: RegistrationFilterInput) {
  registrations(filterInput: $filterInput, pageInfo: {pageIndex: 0, pageSize: 0}) @connection(key: "registrations", filter: ["filterInput"]) {
    items {
      id: _id
    }
  }
}
    `;
export const GetSelectRegistrationTypeData = gql`
    query GetSelectRegistrationTypeData($classId: ObjectId!) {
  class(id: $classId) {
    id: _id
    name
    type
    defaultLessonStart
    defaultLessonEnd
    learningArrangement
    defaultLessonDays
    dailySessionTimeSlots {
      start
      end
    }
    academicYear {
      id: _id
      year
    }
    teacher {
      ...TeacherCellFields
    }
    course {
      id: _id
      name
      type
      avatarUrl
    }
    venue {
      shorthand
      unitNo
      branch {
        name
        alias
      }
    }
    capacity {
      ...CapacityFields
    }
    lessons {
      _id
      lessonNumber
      lessonStart
      lessonEnd
      lessonDay
    }
    enrollments(status: [Active, Holding, Complete]) {
      ...ClassEnrollmentFields
      _id
      class {
        name
      }
      registration {
        trialRegistration {
          startDate
          class {
            shorthand
          }
        }
      }
      invoices {
        _id
        dueDate
        status
      }
    }
    registrations(status: [Waitlisted]) {
      id: _id
      type
      status
      student {
        ...StudentCellFields
      }
    }
  }
}
    ${TeacherCellFields}
${CapacityFields}
${ClassEnrollmentFields}
${StudentCellFields}`;
export const CancelRegistrationButtonAction = gql`
    mutation CancelRegistrationButtonAction($id: ObjectId!) {
  cancelRegistration(id: $id) {
    id: _id
  }
}
    `;
export const GetBillingRegistration = gql`
    query GetBillingRegistration($id: ObjectId!) {
  registration(id: $id) {
    id: _id
    createDateTime
    currentStep
    enrollment {
      isFullEnrollment
    }
    invoices {
      ...InvoiceItemFields
    }
    student {
      enrollments(filterInput: {enrollmentTypes: [Regular, Trial], status: [Active, Complete]}) {
        id: _id
        type
        status
        startDate
        endDate
        class {
          course {
            name
          }
        }
        attendances {
          startDateTime
        }
      }
    }
    type
    session {
      registrationSession {
        registrationStage
      }
    }
  }
}
    ${InvoiceItemFields}`;
export const GetCancelClassById = gql`
    query GetCancelClassById($classId: ObjectId!) {
  class(id: $classId) {
    id: _id
    name
    shorthand
    learningArrangement
    course {
      name
      nameShort
      colorCode
    }
    lessons {
      id: _id
      lessonNumber
      lessonStart
      lessonEnd
      attendance {
        id: _id
      }
      academicWeek {
        weekNumber
      }
      capacity {
        ...LessonCapacityFields
      }
    }
  }
}
    ${LessonCapacityFields}`;
export const EnrollmentOverviewLessons = gql`
    query EnrollmentOverviewLessons($search: String, $filterInput: LessonFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  lessons(search: $search, filterInput: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) {
    items {
      id: _id
      lessonDay
      lessonNumber
      teacher {
        id: _id
        fullName
        avatarUrl
        gender
        salutation
      }
      venue {
        id: _id
        shorthand
        unitNo
        capacity
        branch {
          id: _id
          name
          alias
        }
      }
      lessonStart
      lessonEnd
      maximumClassCapacity
      academicWeek {
        startDate
        endDate
      }
      attendance {
        status
        type
        id: _id
        student {
          id: _id
          fullName
        }
        linkInvoice {
          status
          dueDate
        }
        linkArrangement {
          _id
          fromClass {
            shorthand
          }
        }
      }
      class {
        id: _id
        name
        type
        shorthand
        runNumber
        academicLevels {
          _id
        }
        lessons {
          id: _id
          lessonStart
          lessonEnd
          lessonNumber
        }
        maximumClassCapacity
        maximumMakeUpCapacity
        capacityUsed
        learningArrangement
        digitalClassAccessCredentials {
          zoomMeetingLink
          zoomMeetingPassword
          zoomMeetingId
          googleMeetLink
          googleMeetId
        }
      }
      course {
        id: _id
        name
        nameShort
        avatarUrl
        type
        colorCode
      }
      capacity {
        ...LessonCapacityFields
        seats {
          student {
            ...StudentCellFields
          }
        }
      }
    }
    pageIndex
    pageSize
    total
  }
}
    ${LessonCapacityFields}
${StudentCellFields}`;
export const EnrollmentOverviewClass = gql`
    query EnrollmentOverviewClass($filterInput: ClassFilterInput) {
  classEnrollmentOverview(filterInput: $filterInput) {
    classId
    capacity {
      ...CapacityFields
    }
    serializedData
  }
}
    ${CapacityFields}`;
export const StudentRelation = gql`
    query StudentRelation($studentId: ObjectId!) {
  student(id: $studentId) {
    ...StudentCellFields
    siblings {
      ...StudentCellFields
      enrollments {
        id: _id
        type
      }
    }
  }
}
    ${StudentCellFields}`;
export const GetMakeupListAnalyticsLessons = gql`
    query GetMakeupListAnalyticsLessons($search: String, $filterInput: LessonFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  lessons(search: $search, filterInput: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) {
    items {
      id: _id
      lessonDay
      lessonNumber
      teacher {
        ...TeacherCellFields
      }
      lessonStart
      lessonEnd
      maximumClassCapacity
      class {
        id: _id
        name
        type
        shorthand
        runNumber
        academicLevels {
          _id
        }
        maximumClassCapacity
        maximumMakeUpCapacity
        capacityUsed
        learningArrangement
      }
      capacity {
        ...LessonCapacityFields
      }
    }
    pageIndex
    pageSize
    total
  }
}
    ${TeacherCellFields}
${LessonCapacityFields}`;
export const GetFindClassData = gql`
    query GetFindClassData($search: String, $filterInput: ClassFilterInput, $pageInfo: PageInfo, $sortInfo: SortInfo) {
  classes(search: $search, filterInput: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) @connection(key: "classesFromStudent", filter: ["search", "studentId", "sortInfo"]) {
    items {
      id: _id
      name
      status
      defaultLessonStart
      defaultLessonEnd
      defaultLessonDays
      dailySessionTimeSlots {
        start
        end
      }
      type
      course {
        id: _id
        name
        colorCode
      }
      isAdHoc
      teacher {
        id: _id
        fullName
        gender
        avatarUrl
        salutation
        initials
      }
      venue {
        unitNo
        shorthand
        branch {
          name
          alias
        }
      }
      capacity {
        ...CapacityFields
      }
      enrollments {
        _id
        type
      }
      learningArrangement
      digitalClassAccessCredentials {
        zoomMeetingId
        zoomMeetingPassword
        zoomMeetingLink
        googleMeetId
        googleMeetLink
      }
    }
    pageIndex
    pageSize
    total
  }
}
    ${CapacityFields}`;
export const GetOptionClasses = gql`
    query getOptionClasses($search: String, $filterInput: ClassFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  classes(search: $search, filterInput: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) @connection(key: "classes", filter: ["search", "filterInput", "sortInfo"]) {
    items {
      id: _id
      name
    }
  }
}
    `;
export const GetFindClassStudent = gql`
    query getFindClassStudent($id: ObjectId!) {
  student(id: $id) {
    academicLevel {
      id: _id
    }
  }
}
    `;
export const GetFindClassByIdOnRegistrationModal = gql`
    query GetFindClassByIdOnRegistrationModal($classId: ObjectId!, $studentId: ObjectId!, $skipHoliday: Boolean) {
  classFromStudent(classId: $classId, studentId: $studentId) {
    id: _id
    type
    course {
      name
      courseFee {
        otherFees {
          name
          amount
        }
      }
    }
    lessons(skipHoliday: $skipHoliday) {
      id: _id
      lessonStart
    }
    eligibility(studentId: $studentId) {
      stage
      lessonDates
    }
    learningArrangement
    isAdHoc
  }
}
    `;
export const GetFindClassById = gql`
    query GetFindClassById($classId: ObjectId!, $studentId: ObjectId!, $skipHoliday: Boolean) {
  classFromStudent(classId: $classId, studentId: $studentId) {
    id: _id
    name
    type
    capacityUsed
    maximumClassCapacity
    course {
      name
      courseFee {
        otherFees {
          name
          amount
        }
      }
      type
    }
    teacher {
      ...BasicTeacherFields
    }
    academicYear {
      year
    }
    venue {
      name
      branch {
        name
        alias
      }
    }
    defaultLessonStart
    defaultLessonEnd
    learningArrangement
    activeEnrollments {
      id: _id
      status
      preferredLearningArrangement
      class {
        course {
          type
        }
        isAdHoc
      }
      student {
        id: _id
        avatarUrl
        fullName
        gender
        primarySchool {
          name
        }
        secondarySchool {
          name
        }
        academicLevel {
          educationStage
        }
      }
      group
    }
    lessons(skipHoliday: $skipHoliday) {
      id: _id
      lessonStart
      capacity {
        ...LessonCapacityFields
      }
    }
    isAdHoc
  }
}
    ${BasicTeacherFields}
${LessonCapacityFields}`;
export const GetActiveWaitingLists = gql`
    query getActiveWaitingLists($studentId: ObjectId!, $classId: ObjectId!) {
  registrations(filterInput: {studentIds: [$studentId], classIds: [$classId], status: Waitlisted}) {
    items {
      id: _id
      name
      status
      class {
        id: _id
        name
        capacity {
          ...CapacityFields
        }
      }
    }
  }
}
    ${CapacityFields}`;
export const GetNotifyRegistration = gql`
    query GetNotifyRegistration($id: ObjectId!) {
  registration(id: $id) {
    id: _id
    name
    type
    currentStep
    invoices {
      total
    }
    startDate
    enrollment {
      type
      isFullEnrollment
    }
    linkRegistration {
      id: _id
      name
      type
      startDate
      selectedLessons {
        lesson {
          lessonNumber
          lessonStart
        }
      }
      class {
        ...ArrangementClassCardFields
      }
      enrollment {
        type
        isFullEnrollment
      }
    }
    selectedLessons {
      lesson {
        lessonNumber
        lessonStart
      }
    }
    class {
      ...ArrangementClassCardFields
      type
      academicYearId
    }
    student {
      id: _id
      alternateUserId
      fullName
      userId
      gender
      primaryParent {
        id: _id
        fullName
        alias
        gender
        email
        mobile {
          ...TelephoneFields
        }
      }
      primarySchool {
        ...BasicSchoolFields
      }
    }
    session {
      registrationSession {
        registrationStage
      }
    }
  }
}
    ${ArrangementClassCardFields}
${TelephoneFields}
${BasicSchoolFields}`;
export const GetWaitingListMoreClasses = gql`
    query getWaitingListMoreClasses($courseId: ObjectId!, $studentId: ObjectId!, $status: [ClassStatus!], $academicYearId: ObjectId) {
  course(id: $courseId) {
    classes(status: $status, academicYearId: $academicYearId) {
      ...ArrangementClassCardFields
      eligibility(studentId: $studentId) {
        stage
      }
    }
  }
}
    ${ArrangementClassCardFields}`;
export const CreateWaitingListsForMultipleClasses = gql`
    mutation createWaitingListsForMultipleClasses($input: CreateWaitingListsForStudentInput!) {
  createWaitingListsForMultipleClasses(input: $input) {
    id: _id
    class {
      ...ArrangementClassCardFields
    }
  }
}
    ${ArrangementClassCardFields}`;
export const GetDetailRegistration = gql`
    query GetDetailRegistration($id: ObjectId!) {
  registration(id: $id) {
    id: _id
    name
    type
    startDate
    status
    remarks
    dueDate
    createDateTime
    approvalDate
    waStatus
    emailStatus
    lastModifiedDateTime
    invoice {
      ...InvoiceItemFields
    }
    createdBy {
      ...CreateByFields
    }
    class {
      ...ArrangementClassCardFields
    }
    student {
      id: _id
      fullName
      alternateUserId
      userId
    }
    enrollment {
      type
      isFullEnrollment
      preferredLearningArrangement
      group
      followUpStatus
      id: _id
    }
    reminders {
      id: _id
      subject
      message
      type
      sendDate
      sendgridEmailStatus
      whatsappMessage {
        status
      }
    }
    lastModifiedDateTime
    lastModifiedBy {
      ...LastModifiedByFields
    }
    effectedWaitingLists {
      id: _id
      startDate
      student {
        ...StudentCellFields
      }
      createDateTime
    }
    otherRegistrations {
      name
      status
      class {
        name
        course {
          colorCode
        }
      }
    }
  }
}
    ${InvoiceItemFields}
${CreateByFields}
${ArrangementClassCardFields}
${LastModifiedByFields}
${StudentCellFields}`;
export const GetTimerRegistration = gql`
    query GetTimerRegistration($id: ObjectId!) {
  registration(id: $id) {
    id: _id
    createDateTime
    type
    incompleteDeadline
    status
    session {
      expiredAt
    }
  }
}
    `;
export const PostIncompleteRegistration = gql`
    subscription PostIncompleteRegistration {
  postIncompleteRegistration {
    id: _id
    type
    status
    isDeleted
    session {
      expiredAt
    }
  }
}
    `;
export const GetRegistrationById = gql`
    query getRegistrationById($id: ObjectId!) {
  registration(id: $id) {
    ...RegistrationOnChecksFields
  }
}
    ${RegistrationOnChecksFields}`;
export const GetHybridGroupsClass = gql`
    query GetHybridGroupsClass($id: ObjectId!, $status: [EnrollmentStatus!], $types: [EnrollmentType!]) {
  class(id: $id) {
    id: _id
    type
    isAdHoc
    lessons {
      id: _id
      lessonNumber
      lessonStart
      lessonEnd
    }
    enrollments(status: $status, types: $types) {
      id: _id
      group
      preferredLearningArrangement
      student {
        ...StudentCellFields
      }
    }
  }
}
    ${StudentCellFields}`;
export const GetClassForReservation = gql`
    query getClassForReservation($id: ObjectId!, $studentId: ObjectId!, $linkRegistrationId: ObjectId!) {
  class(id: $id) {
    id: _id
    name
    type
    defaultLessonDays
    defaultLessonStart
    defaultLessonEnd
    dailySessionTimeSlots {
      start
      end
    }
    venue {
      branch {
        id: _id
        name
        alias
      }
      id: _id
      shorthand
      unitNo
    }
    eligibility(studentId: $studentId, linkRegistrationId: $linkRegistrationId) {
      lessonDates
      stage
    }
    course {
      ...BasicCourseFields
    }
    teacher {
      ...BasicTeacherFields
    }
    lessons {
      ...SelectedLessonFields
    }
    academicWeeks {
      weekNumber
      startDate
      endDate
    }
    academicYear {
      ...BasicAcademicYearFields
    }
    learningArrangement
    enrollments {
      _id
      type
    }
    capacity {
      ...CapacityFields
    }
  }
}
    ${BasicCourseFields}
${BasicTeacherFields}
${SelectedLessonFields}
${BasicAcademicYearFields}
${CapacityFields}`;
export const GetClassesInCourse = gql`
    query getClassesInCourse($id: ObjectId!, $studentId: ObjectId, $linkRegistrationId: ObjectId, $status: [ClassStatus!], $academicYearId: ObjectId) {
  course(id: $id) {
    colorCode
    classes(status: $status, academicYearId: $academicYearId) {
      id: _id
      name
      term {
        ...BasicTermFields
      }
      lessons {
        id: _id
        lessonStart
        lessonEnd
        lessonNumber
      }
      shorthand
      defaultLessonDays
      defaultLessonStart
      defaultLessonEnd
      teacher {
        salutation
        fullName
      }
      eligibility(studentId: $studentId, linkRegistrationId: $linkRegistrationId) {
        lessonDates
        stage
      }
    }
  }
}
    ${BasicTermFields}`;
export const GetClassEligibility = gql`
    query getClassEligibility($id: ObjectId!, $studentId: ObjectId!, $linkRegistrationId: ObjectId) {
  class(id: $id) {
    type
    eligibility(studentId: $studentId, linkRegistrationId: $linkRegistrationId) {
      stage
      lessonDates
    }
  }
}
    `;
export const ExecuteEligibilityFlowWithCourseId = gql`
    query executeEligibilityFlowWithCourseId($courseId: ObjectId!, $studentId: ObjectId!) {
  executeEligibilityFlowWithCourseId(courseId: $courseId, studentId: $studentId) {
    message
    outcome
  }
}
    `;
export const GetEnrollment = gql`
    query getEnrollment($enrollmentId: ObjectId!) {
  enrollment(id: $enrollmentId) {
    id: _id
    group
    preferredLearningArrangement
  }
}
    `;
export const RegistrationCsv = gql`
    query registrationCsv($search: String, $filterInput: RegistrationFilterInput) {
  csv: exportRegistrationCsv(search: $search, filterInput: $filterInput) {
    url
  }
}
    `;
export const GetRegistrationForAction = gql`
    query getRegistrationForAction($id: ObjectId!) {
  registration(id: $id) {
    id: _id
    effectedWaitingLists {
      id: _id
      startDate
      student {
        ...StudentCellFields
      }
      createDateTime
    }
    otherRegistrations {
      id: _id
      name
      status
      class {
        name
        course {
          colorCode
        }
      }
      enrollment {
        preferredLearningArrangement
        group
      }
    }
  }
}
    ${StudentCellFields}`;
export const CancelRegistration = gql`
    mutation cancelRegistration($id: ObjectId!, $isCancelOtherRegistration: Boolean) {
  cancelRegistration(id: $id, isCancelOtherRegistration: $isCancelOtherRegistration) {
    id: _id
    status
  }
}
    `;
export const CreateRegistration = gql`
    mutation createRegistration($input: CreateRegistrationInput!) {
  createRegistration(input: $input) {
    id: _id
    enrollment {
      id: _id
      group
    }
    session {
      id: _id
    }
  }
}
    `;
export const RegenerateRegistration = gql`
    mutation regenerateRegistration($input: RegenerateRegistrationInput!) {
  regenerateRegistration(input: $input) {
    id: _id
    selectedLessons {
      lesson {
        ...SelectedLessonFields
      }
      makeUpLesson {
        ...SelectedLessonFields
      }
    }
    startDate
    dueDate
    session {
      registrationSession {
        registrationStage
        regenerateStartData
      }
    }
  }
}
    ${SelectedLessonFields}`;
export const ExtendsRegistrationTime = gql`
    mutation extendsRegistrationTime($id: ObjectId!) {
  extendsRegistrationTime(id: $id) {
    id: _id
    name
    createDateTime
  }
}
    `;
export const ApproveRegistration = gql`
    mutation approveRegistration($input: ApproveRegistrationInput!) {
  approveRegistration(input: $input) {
    id: _id
  }
}
    `;
export const ProcessRegistration = gql`
    mutation processRegistration($id: ObjectId!) {
  processRegistration(id: $id) {
    id: _id
  }
}
    `;
export const CreateBillingforRegistration = gql`
    mutation createBillingforRegistration($id: ObjectId!, $currentStep: Int!) {
  createBillingForRegistration(id: $id, currentStep: $currentStep) {
    id: _id
    currentStep
  }
}
    `;
export const RegistrationIncrementStep = gql`
    mutation registrationIncrementStep($registrationId: ObjectId!, $currentStep: Int!) {
  registrationIncrementStep(registrationId: $registrationId, currentStep: $currentStep) {
    id: _id
    currentStep
  }
}
    `;
export const UpdateRegistration = gql`
    mutation updateRegistration($input: UpdateRegistrationInput!) {
  updateRegistration(input: $input) {
    id: _id
    currentStep
  }
}
    `;
export const ChangeAttendingClassForRegistration = gql`
    mutation changeAttendingClassForRegistration($input: ChangeAttendingClassForRegistrationInput!) {
  changeAttendingClassForRegistration(input: $input) {
    ...RegistrationOnChecksFields
  }
}
    ${RegistrationOnChecksFields}`;
export const CancelChangeAttendingClassForRegistration = gql`
    mutation cancelChangeAttendingClassForRegistration($input: ChangeAttendingClassForRegistrationInput!) {
  cancelChangeAttendingClassForRegistration(input: $input) {
    ...RegistrationOnChecksFields
  }
}
    ${RegistrationOnChecksFields}`;
export const UpdateEnrollmentPreferredLearningArrangement = gql`
    mutation updateEnrollmentPreferredLearningArrangement($input: UpdateEnrollmentPreferredLearningArrangementInput!) {
  updateEnrollmentPreferredLearningArrangement(input: $input) {
    id: _id
    group
    preferredLearningArrangement
    student {
      ...StudentCellFields
    }
  }
}
    ${StudentCellFields}`;
export const GetInCompleteRegistration = gql`
    query GetInCompleteRegistration {
  sessions(types: [Registration]) {
    expiredAt
    registration {
      id: _id
      name
      type
      currentStep
      incompleteDeadline
      invoices {
        id: _id
      }
      class {
        id: _id
      }
      enrollment {
        id: _id
      }
      student {
        id: _id
      }
      createDateTime
    }
  }
}
    `;
export const GetRegistrationForWaitingListAction = gql`
    query GetRegistrationForWaitingListAction($id: ObjectId!) {
  registration(id: $id) {
    id: _id
    class {
      id: _id
      learningArrangement
    }
    student {
      id: _id
    }
    enrollment {
      preferredLearningArrangement
      group
    }
    status
    startDate
    effectedWaitingLists {
      id: _id
      startDate
      student {
        ...StudentCellFields
      }
      createDateTime
    }
  }
}
    ${StudentCellFields}`;
export const GetStudentsTrialEnrollment = gql`
    query GetStudentsTrialEnrollment($studentId: ObjectId!, $StudentEnrollmentFilterInput: StudentEnrollmentFilterInput) {
  student(id: $studentId) {
    enrollments(filterInput: $StudentEnrollmentFilterInput) {
      id: _id
      startDate
      class {
        shorthand
      }
    }
  }
}
    `;
export const GetAttendances = gql`
    query getAttendances($filterInput: StudentAttendanceFilterInput, $pageInfo: PageInfo, $sortInfo: SortInfo) {
  attendances(filter: $filterInput, pageInfo: $pageInfo, sortInfo: $sortInfo) {
    items {
      id: _id
      student {
        ...StudentCellFields
        primaryParent {
          ...ParentCellFields
          linkedWhatsappAccount {
            number {
              countryCode
              number
            }
          }
          relatedParents {
            ...ParentCellFields
            linkedWhatsappAccount {
              number {
                countryCode
                number
              }
            }
          }
        }
      }
      status
      type
      group
      lessonMode
      linkedAttendance {
        startDateTime
        class {
          name
        }
      }
      linkArrangement {
        type
        fromClass {
          id: _id
          shorthand
        }
        toClass {
          id: _id
          shorthand
        }
        remarks
      }
      previousLessonAttendance {
        type
        status
        class {
          shorthand
          name
        }
      }
      classId
      class {
        id: _id
        shorthand
        type
        academicLevels {
          id: _id
          name
        }
        academicYearId
        learningArrangement
      }
      lesson {
        id: _id
        lessonStart
        lessonEnd
        lessonNumber
      }
      enrollment {
        id: _id
        type
        preferredLearningArrangement
        group
        attendances {
          id: _id
          startDateTime
        }
        suitability {
          status
        }
        followUpStatus
        followUpStatusLastModifiedBy {
          ...LastModifiedByFields
        }
        followUpStatusLastModifiedDateTime
      }
      remarks(includesStudentRemarks: true) {
        ...RemarkFields
      }
      linkInvoice {
        id: _id
        status
        dueDate
      }
      statusTag {
        tags
        description
      }
    }
    total
    pageIndex
    pageSize
  }
}
    ${StudentCellFields}
${ParentCellFields}
${LastModifiedByFields}
${RemarkFields}`;
export const GetAttendanceIndexes = gql`
    query getAttendanceIndexes($filterInput: StudentAttendanceFilterInput, $pageInfo: PageInfo, $sortInfo: SortInfo) {
  attendancesWithIndex(filter: $filterInput, pageInfo: $pageInfo, sortInfo: $sortInfo) {
    items {
      ...AttendanceIndexFields
    }
    total
    pageIndex
    pageSize
  }
}
    ${AttendanceIndexFields}`;
export const GetAttendanceIndex = gql`
    query getAttendanceIndex($id: ObjectId!) {
  attendanceWithIndex(id: $id) {
    ...AttendanceIndexFields
  }
}
    ${AttendanceIndexFields}`;
export const GetAttendanceReportFilterOptions = gql`
    query getAttendanceReportFilterOptions($classFilterInput: ClassFilterInput) {
  academicLevels {
    id: _id
    name
    courses(statuses: [Completed, Created, Ongoing]) {
      id: _id
      nameShort
    }
    classes(filterInput: $classFilterInput) {
      id: _id
      shorthand
    }
  }
  academicYears {
    id: _id
    year
    academicWeeks {
      startDate
      endDate
      weekNumber
    }
  }
}
    `;
export const ExportAttendanceCsv = gql`
    query exportAttendanceCsv($filterInput: StudentAttendanceFilterInput) {
  exportAttendanceCsv(filter: $filterInput) {
    url
  }
}
    `;
export const ExportAttendanceReportCsv = gql`
    query exportAttendanceReportCsv($filterInput: StudentEnrollmentFilterInput) {
  exportAttendanceReportCsv(filter: $filterInput) {
    url
  }
}
    `;
export const AttendanceReports = gql`
    query attendanceReports($filterInput: StudentEnrollmentFilterInput, $pageInfo: PageInfo) {
  attendanceReports(filter: $filterInput, pageInfo: $pageInfo) {
    items {
      id: _id
      type
      status
      preferredLearningArrangement
      startDate
      attendances {
        id: _id
        type
        status
        startDateTime
        lessonId
        linkArrangement {
          fromClass {
            id: _id
            shorthand
          }
          toClass {
            id: _id
            shorthand
          }
        }
      }
      class {
        id: _id
        name
        classCode
        type
        learningArrangement
        isAdHoc
        lessons {
          id: _id
          lessonStart
          lessonEnd
          lessonNumber
        }
        firstLessonDate
      }
      suitability {
        status
      }
      student {
        id: _id
        fullName
        gender
        alias
        avatarUrl
        userId
      }
      remarks {
        ...RemarkFields
      }
    }
    pageIndex
    pageSize
    total
  }
}
    ${RemarkFields}`;
export const AcademicYearsOnAttendance = gql`
    query academicYearsOnAttendance {
  academicYears {
    id: _id
    groupAssignment {
      items {
        startDate
        endDate
        groupA
        groupB
      }
    }
  }
}
    `;
export const UpdateAttendances = gql`
    mutation updateAttendances($input: UpdateAttendancesInput!) {
  updateAttendances(input: $input) {
    id: _id
    status
    type
  }
}
    `;
export const GetInvoiceReport = gql`
    query GetInvoiceReport($filterInput: InvoiceReportFilterInput, $pageInfo: PageInfo, $sortInfo: SortInfo) {
  invoiceReports(filter: $filterInput, pageInfo: $pageInfo, sortInfo: $sortInfo) {
    items {
      id: _id
      type
      referenceNumber
      description
      studentId
      studentFullName
      studentUserId
      lineItemId
      invoiceId
      lessonId
      classId
      lessonDate
      fullLessonDate
      invoiceDate
      invoiceTotal
      lineItemFee
      lineItemFeeWithoutGST
      feeWithoutGST
      remarks
      isVoid
      isOnHold
      parentUserId
      parentFullName
      courseName
    }
    total
    pageIndex
    pageSize
  }
}
    `;
export const ExportInvoiceReportCsv = gql`
    query exportInvoiceReportCsv($filterInput: InvoiceReportFilterInput) {
  exportInvoiceReportCsv(filter: $filterInput) {
    url
  }
}
    `;
export const GetPaymentReport = gql`
    query GetPaymentReport($filterInput: BalanceHistoryFilterInput, $pageInfo: PageInfo, $sortInfo: SortInfo) {
  balanceHistories(filterInput: $filterInput, pageInfo: $pageInfo, sortInfo: $sortInfo) {
    items {
      id: _id
      invoiceNumbers
      paymentNumbers
      receiptNumbers
      originalInvoiceAmount
      amountPayable
      paymentMethod
      paymentDate
      amountPaid
      outstandingPayable
      actionType
      balance
      amount
      type
      isVoidedInvoice
      parent {
        fullName
        userId
      }
      invoices {
        id: _id
        referenceNumber
        status
      }
      payments {
        id: _id
        isVoid
        referenceNumber
        receipt {
          id: _id
          referenceNumber
          status
        }
      }
    }
    total
    pageIndex
    pageSize
  }
}
    `;
export const ExportPaymentReportCsv = gql`
    query exportPaymentReportCsv($filterInput: BalanceHistoryFilterInput) {
  exportPaymentReportCsv(filter: $filterInput) {
    url
  }
}
    `;
export const GetRevenueCourseWithIndexData = gql`
    query GetRevenueCourseWithIndexData($filterInput: CourseFilterInput, $pageInfo: PageInfo, $dateRange: DateTimeRangeInput!) {
  revenueCoursesWithIndex(filter: $filterInput, pageInfo: $pageInfo) {
    items {
      id: _id
      nameShort
      status
      type
      totalRevenue(dateRange: $dateRange)
    }
    pageIndex
    pageSize
    total
  }
}
    `;
export const GetRevenueCourseData = gql`
    query GetRevenueCourseData($courseIds: [ObjectId!]) {
  courses(filterInput: {courseIds: $courseIds}, pageInfo: {pageIndex: 0, pageSize: 0}) {
    items {
      id: _id
      nameShort
    }
  }
}
    `;
export const GetInvoiceRevenueReports = gql`
    query GetInvoiceRevenueReports($filter: RevenueReportQuery!, $pageInfo: PageInfo) {
  invoiceRevenueReports(filter: $filter, pageInfo: $pageInfo) {
    items {
      id: _id
      unitAmount
      description
      isPaid
      taxRate
      paid
      invoice {
        course {
          type
        }
        id: _id
        referenceNumber
        createDateTime
        billedTo {
          id: _id
          fullName
          userId
        }
        billedFor {
          id: _id
          fullName
          userId
        }
      }
      lessons {
        lessonId
        isPaid
        paid
        lesson {
          id: _id
          lessonStart
        }
      }
    }
    pageIndex
    pageSize
    total
  }
}
    `;
export const ExportInvoiceRevenueReportCsv = gql`
    query ExportInvoiceRevenueReportCsv($filterInput: CourseFilterInput!) {
  exportInvoiceRevenueReportCsv(filter: $filterInput) {
    url
  }
}
    `;
export const GetLessonsInSchedule = gql`
    query getLessonsInSchedule($filterInput: LessonFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  lessonsWithIndex(filter: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) {
    pageIndex
    pageSize
    total
    items {
      ...LessonScheduleFields
    }
  }
}
    ${LessonScheduleFields}`;
export const GetVenuesInSchedule = gql`
    query getVenuesInSchedule {
  venues {
    ...BasicVenueFields
  }
}
    ${BasicVenueFields}`;
export const GetScheduleFilterData = gql`
    query GetScheduleFilterData($filterInput: SystemUserQuery!) {
  branches {
    id: _id
    name
    alias
    venues {
      ...BasicVenueFields
    }
  }
}
    ${BasicVenueFields}`;
export const GetArrangementActionModalLesson = gql`
    query GetArrangementActionModalLesson($id: ObjectId!) {
  lesson(id: $id) {
    id: _id
    class {
      id: _id
      course {
        id: _id
      }
    }
  }
}
    `;
export const UpdateAcademicYearForm = gql`
    mutation UpdateAcademicYearForm($input: UpdateAcademicYearInput!) {
  updateAcademicYear(input: $input) {
    ...BasicAcademicYearFields
  }
}
    ${BasicAcademicYearFields}`;
export const DeleteTerm = gql`
    mutation DeleteTerm($id: ObjectId!) {
  deleteTerm(id: $id) {
    id: _id
    academicYear {
      ...BasicAcademicYearFields
    }
  }
}
    ${BasicAcademicYearFields}`;
export const GetAcademicYearFormData = gql`
    query GetAcademicYearFormData {
  academicLevels {
    ...BasicAcademicLevelFields
  }
}
    ${BasicAcademicLevelFields}`;
export const CreateGroupAssignment = gql`
    mutation CreateGroupAssignment($input: CreateGroupAssignmentInput!) {
  createGroupAssignment(input: $input) {
    _id
  }
}
    `;
export const UpdateGroupAssignment = gql`
    mutation UpdateGroupAssignment($input: UpdateGroupAssignmentInput!) {
  updateGroupAssignment(input: $input) {
    _id
  }
}
    `;
export const DeleteGroupAssignment = gql`
    mutation DeleteGroupAssignment($id: ObjectId!) {
  deleteGroupAssignment(id: $id) {
    _id
  }
}
    `;
export const GetAcademicYearPageData = gql`
    query GetAcademicYearPageData($year: Int!) {
  academicYear(year: $year) {
    ...BasicAcademicYearFields
  }
}
    ${BasicAcademicYearFields}`;
export const GetHolidays = gql`
    query GetHolidays($year: Int!) {
  holidays(filterInput: {year: $year}) {
    ...BasicHolidayFields
  }
}
    ${BasicHolidayFields}`;
export const DeleteHoliday = gql`
    mutation DeleteHoliday($id: ObjectId!) {
  deleteHoliday(input: {id: $id}) {
    ...BasicHolidayFields
  }
}
    ${BasicHolidayFields}`;
export const UpdateHoliday = gql`
    mutation UpdateHoliday($input: UpdateHolidayInput!) {
  updateHoliday(input: $input) {
    ...BasicHolidayFields
  }
}
    ${BasicHolidayFields}`;
export const AddHoliday = gql`
    mutation AddHoliday($input: CreateHolidayInput!) {
  createHoliday(input: $input) {
    ...BasicHolidayFields
  }
}
    ${BasicHolidayFields}`;
export const GetHolidayLessons = gql`
    query GetHolidayLessons($filterInput: LessonFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  lessons(filterInput: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) @connection(key: "lessons", filter: ["filterInput", "sortInfo", "search"]) {
    items {
      id: _id
      lessonNumber
      lessonDay
      lessonStart
      lessonEnd
      class {
        name
        course {
          colorCode
        }
      }
    }
  }
}
    `;
export const CreateCampaign = gql`
    mutation createCampaign($input: CreateCampaignInput!) {
  createCampaign(input: $input) {
    ...CampaignFields
  }
}
    ${CampaignFields}`;
export const UpdateCampaign = gql`
    mutation updateCampaign($input: UpdateCampaignInput!) {
  updateCampaign(input: $input) {
    ...CampaignFields
  }
}
    ${CampaignFields}`;
export const DeleteCampaign = gql`
    mutation deleteCampaign($id: ObjectId!) {
  deleteCampaign(id: $id) {
    ...CampaignFields
  }
}
    ${CampaignFields}`;
export const RecallCampaign = gql`
    mutation recallCampaign($id: ObjectId!) {
  recallCampaign(id: $id) {
    ...CampaignFields
  }
}
    ${CampaignFields}`;
export const Campaigns = gql`
    query campaigns($filterInput: CampaignFilterInput, $pageInfo: PageInfo, $sortInfo: SortInfo) {
  campaigns(filterInput: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) @connection(key: "campaigns", filter: ["filterInput", "sortInfo"]) {
    items {
      ...CampaignFields
    }
    total
    pageSize
    pageIndex
  }
}
    ${CampaignFields}`;
export const GetGeneralStudentsData = gql`
    query GetGeneralStudentsData($search: String, $filterInput: StudentFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  students(search: $search, filterInput: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) @connection(key: "students", filter: ["search", "filterInput", "sortInfo"]) {
    items {
      id: _id
      fullName
      userId
      primaryParent {
        id: _id
        fullName
        salutation
        email
        mobile {
          countryCode
          number
        }
        relatedParents {
          id: _id
          fullName
          salutation
          email
          mobile {
            countryCode
            number
          }
        }
      }
    }
    pageIndex
    pageSize
    total
  }
}
    `;
export const GetCampaign = gql`
    query GetCampaign($id: ObjectId!) {
  campaign(id: $id) {
    ...CampaignFields
    template {
      id: _id
      title
      usageType
    }
  }
}
    ${CampaignFields}`;
export const ExportCampaignDocx = gql`
    query exportCampaignDocx($id: ObjectId!) {
  exportCampaignDocx(id: $id)
}
    `;
export const CampaignExportHistories = gql`
    query campaignExportHistories($filterInput: CampaignFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  campaignExportHistories(filterInput: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) {
    items {
      id: _id
      url
      campaign {
        id: _id
        sendMethod
        name
        createdBy {
          ...CreateByFields
        }
      }
    }
    pageIndex
    pageSize
    total
  }
}
    ${CreateByFields}`;
export const GetReceiptStudentsData = gql`
    query GetReceiptStudentsData($search: String, $filterInput: ReceiptFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  receipts(search: $search, filterInput: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) @connection(key: "listReceipts", filter: ["search", "filterInput", "sortInfo"]) {
    items {
      id: _id
      referenceNumber
      paymentRecord {
        details {
          invoice {
            id: _id
            billedFor {
              id: _id
              fullName
              userId
              primaryParent {
                id: _id
                fullName
                salutation
                email
                mobile {
                  countryCode
                  number
                }
                relatedParents {
                  id: _id
                  fullName
                  salutation
                  email
                  mobile {
                    countryCode
                    number
                  }
                }
              }
            }
          }
        }
      }
    }
    pageIndex
    pageSize
    total
  }
}
    `;
export const PostBranchData = gql`
    subscription PostBranchData {
  postBranchData {
    ...BasicBranchFields
    venues {
      id: _id
      isArchived
    }
  }
}
    ${BasicBranchFields}`;
export const PostDepartmentData = gql`
    subscription PostDepartmentData {
  postDepartmentData {
    ...DepartmentFields
  }
}
    ${DepartmentFields}`;
export const GetVenue = gql`
    query GetVenue($id: ObjectId!) {
  venue(id: $id) {
    ...BasicVenueFields
  }
}
    ${BasicVenueFields}`;
export const PostVenueData = gql`
    subscription PostVenueData {
  postVenueData {
    ...BasicVenueFields
    createdBy {
      ...CreateByFields
    }
    branch {
      ...BasicBranchFields
    }
  }
}
    ${BasicVenueFields}
${CreateByFields}
${BasicBranchFields}`;
export const GetFeatures = gql`
    query getFeatures {
  features {
    ...FeatureFields
  }
}
    ${FeatureFields}`;
export const GetEnabledFeatures = gql`
    query getEnabledFeatures {
  enabledFeatures {
    ...FeatureFields
  }
}
    ${FeatureFields}`;
export const ToggleFeature = gql`
    mutation toggleFeature($input: UpdateFeatureAccessInput!) {
  toggleFeature(input: $input) {
    ...FeatureFields
  }
}
    ${FeatureFields}`;
export const ToggleFeatures = gql`
    mutation toggleFeatures($input: UpdateFeatureAccessesInput!) {
  toggleFeatures(input: $input) {
    ...FeatureFields
  }
}
    ${FeatureFields}`;
export const PostFeatureData = gql`
    subscription postFeatureData {
  postFeatureData {
    ...FeatureFields
  }
}
    ${FeatureFields}`;
export const BulkUpdateDigitalClassVenue = gql`
    mutation bulkUpdateDigitalClassVenue($input: [UpdateClassCommand!]!) {
  bulkUpdateDigitalClassVenue(input: $input) {
    id: _id
  }
}
    `;
export const GetAssignVenueClasses = gql`
    query getAssignVenueClasses($search: String, $filterInput: ClassFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  classes(search: $search, filterInput: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) @connection(key: "classes", filter: ["search", "filterInput", "sortInfo"]) {
    items {
      id: _id
      name
      lessons {
        lessonStart
        lessonEnd
      }
      course {
        colorCode
      }
      teacher {
        id: _id
      }
      venue {
        id: _id
        shorthand
        unitNo
        branch {
          name
          alias
        }
      }
      capacity {
        used
      }
      defaultLessonDays
      defaultLessonStart
      defaultLessonEnd
    }
    total
    pageSize
    pageIndex
  }
  venues {
    ...BasicVenueFields
  }
}
    ${BasicVenueFields}`;
export const GetIntegrations = gql`
    query GetIntegrations {
  integrations {
    ...IntegrationFields
  }
}
    ${IntegrationFields}`;
export const UpdateIntegrationClientInfo = gql`
    mutation UpdateIntegrationClientInfo($input: UpdateIntegrationCommand!) {
  updateIntegrationClientInfo(input: $input) {
    ...IntegrationFields
  }
}
    ${IntegrationFields}`;
export const XeroTokenInfo = gql`
    query xeroTokenInfo {
  xeroTokenInfo {
    id: _id
  }
}
    `;
export const XeroConsentUrl = gql`
    query xeroConsentUrl {
  xeroConsentUrl
}
    `;
export const XeroAccounts = gql`
    query xeroAccounts {
  xeroAccounts {
    code
    name
    type
  }
}
    `;
export const RefreshXeroToken = gql`
    mutation refreshXeroToken {
  refreshXeroToken {
    id: _id
  }
}
    `;
export const AddXeroAccount = gql`
    mutation addXeroAccount($input: AddXeroAccountInput!) {
  addXeroAccount(input: $input) {
    code
    name
    type
  }
}
    `;
export const ConnectXeroAccountForCourse = gql`
    mutation connectXeroAccountForCourse($input: ConnectXeroAccountForCourseInput!) {
  connectXeroAccountForCourse(input: $input) {
    id: _id
  }
}
    `;
export const XeroApiCallback = gql`
    mutation xeroApiCallback($callbackUrl: String!) {
  xeroApiCallback(callbackUrl: $callbackUrl) {
    _id
    name
  }
}
    `;
export const DisconnectXeroAccount = gql`
    mutation disconnectXeroAccount {
  disconnectXeroAccount {
    _id
    name
  }
}
    `;
export const RetryXeroPayment = gql`
    mutation retryXeroPayment($paymentId: ObjectId!) {
  retryXeroPayment(paymentId: $paymentId) {
    _id
  }
}
    `;
export const ConnectXeroAccountForOtherMapping = gql`
    mutation connectXeroAccountForOtherMapping($input: ConnectXeroAccountInput!) {
  connectXeroAccountForOtherMapping(input: $input) {
    _id
  }
}
    `;
export const RetryUploadInvoice = gql`
    mutation retryUploadInvoice($invoiceId: ObjectId!, $date: DateTime!) {
  retryUploadInvoice(invoiceId: $invoiceId, date: $date) {
    _id
  }
}
    `;
export const GetListCourseMappingData = gql`
    query getListCourseMappingData($search: String, $filterInput: CourseFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  courses(search: $search, filterInput: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) @connection(key: "courses", filter: ["search", "filterInput", "sortInfo"]) {
    items {
      id: _id
      name
      nameShort
      status
      type
      xeroAccountsSyncStatus
      onGoingClassNumber
      academicLevels {
        name
      }
    }
    pageIndex
    pageSize
    total
  }
}
    `;
export const GetNotificationLogs = gql`
    query GetNotificationLogs($search: String, $filterInput: ReminderFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  reminders(search: $search, filterInput: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) @connection(key: "reminders", filter: ["search", "filterInput", "sortInfo"]) {
    items {
      ...NotificationLogFragment
    }
    total
    pageSize
    pageIndex
  }
}
    ${NotificationLogFragment}`;
export const ResendReminderOnNotificationLog = gql`
    mutation ResendReminderOnNotificationLog($id: ObjectId!) {
  resendReminder(id: $id) {
    ...NotificationLogFragment
  }
}
    ${NotificationLogFragment}`;
export const GetWhatsappAccounts = gql`
    query GetWhatsappAccounts($search: String, $filterInput: WhatsappAccountFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  whatsappAccounts(search: $search, filterInput: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) @connection(key: "whatsappAccounts", filter: ["search", "filterInput", "sortInfo"]) {
    items {
      ...WhatsappAccountInfo
    }
    total
    pageSize
    pageIndex
  }
}
    ${WhatsappAccountInfo}`;
export const GetWhatsappAccount = gql`
    query GetWhatsappAccount($id: ObjectId!) {
  whatsappAccount(id: $id) {
    ...WhatsappAccountInfo
  }
}
    ${WhatsappAccountInfo}`;
export const LoginWhatsappAccount = gql`
    mutation LoginWhatsappAccount($id: ObjectId!) {
  whatsappLogin(id: $id) {
    image
  }
}
    `;
export const LogoutWhatsappAccount = gql`
    mutation LogoutWhatsappAccount($id: ObjectId!) {
  whatsappLogout(id: $id) {
    ...WhatsappAccountInfo
  }
}
    ${WhatsappAccountInfo}`;
export const CreateWhatsappAccount = gql`
    mutation CreateWhatsappAccount($input: CreateWhatsappAccountInput!) {
  createWhatsappAccount(input: $input) {
    ...WhatsappAccountInfo
  }
}
    ${WhatsappAccountInfo}`;
export const UpdateWhatsappAccount = gql`
    mutation UpdateWhatsappAccount($input: UpdateWhatsappAccountInput!) {
  updateWhatsappAccount(input: $input) {
    ...WhatsappAccountInfo
  }
}
    ${WhatsappAccountInfo}`;
export const ArchiveWhatsappAccount = gql`
    mutation ArchiveWhatsappAccount($id: ObjectId!) {
  archiveWhatsappAccount(id: $id) {
    ...WhatsappAccountInfo
  }
}
    ${WhatsappAccountInfo}`;
export const RecoverWhatsappAccount = gql`
    mutation RecoverWhatsappAccount($id: ObjectId!) {
  recoverWhatsappAccount(id: $id) {
    ...WhatsappAccountInfo
  }
}
    ${WhatsappAccountInfo}`;
export const PauseWhatsappAccount = gql`
    mutation PauseWhatsappAccount($id: ObjectId!) {
  pauseWhatsappAccount(id: $id) {
    ...WhatsappAccountInfo
  }
}
    ${WhatsappAccountInfo}`;
export const ResumeWhatsappAccount = gql`
    mutation ResumeWhatsappAccount($id: ObjectId!) {
  resumeWhatsappAccount(id: $id) {
    ...WhatsappAccountInfo
  }
}
    ${WhatsappAccountInfo}`;
export const ReconnectWhatsappAccount = gql`
    mutation ReconnectWhatsappAccount($id: ObjectId!) {
  reconnectWhatsappAccount(id: $id) {
    ...WhatsappAccountInfo
  }
}
    ${WhatsappAccountInfo}`;
export const RequeueWhatsAppMessage = gql`
    mutation RequeueWhatsAppMessage($id: ObjectId!) {
  requeueWhatsAppMessage(id: $id) {
    ...WhatsappMessageInfo
  }
}
    ${WhatsappMessageInfo}`;
export const PauseWhatsAppMessage = gql`
    mutation PauseWhatsAppMessage($id: ObjectId!) {
  pauseWhatsAppMessage(id: $id) {
    ...WhatsappMessageInfo
  }
}
    ${WhatsappMessageInfo}`;
export const RetryWhatsAppMessage = gql`
    mutation RetryWhatsAppMessage($id: ObjectId!) {
  retryWhatsAppMessage(id: $id) {
    ...WhatsappMessageInfo
  }
}
    ${WhatsappMessageInfo}`;
export const ResumeWhatsAppMessage = gql`
    mutation ResumeWhatsAppMessage($id: ObjectId!) {
  resumeWhatsAppMessage(id: $id) {
    ...WhatsappMessageInfo
  }
}
    ${WhatsappMessageInfo}`;
export const ValidateWhatsappNumber = gql`
    mutation ValidateWhatsappNumber($input: ValidateWhatsappNumberInput!) {
  validateWhatsappNumber(input: $input)
}
    `;
export const GetReferences = gql`
    query getReferences($category: ReferenceDataCategory, $showArchived: Boolean) {
  referenceDatas(category: $category, showArchived: $showArchived) {
    id: _id
    category
    order
    key
    value
    isArchived
  }
}
    `;
export const CreateReferenceData = gql`
    mutation createReferenceData($input: CreateReferenceDataInput!) {
  createReferenceData(input: $input) {
    id: _id
    category
    order
    key
    value
    isArchived
  }
}
    `;
export const UpdateReferenceData = gql`
    mutation updateReferenceData($input: UpdateReferenceDataInput!) {
  updateReferenceData(input: $input) {
    id: _id
    category
    order
    key
    value
    isArchived
  }
}
    `;
export const PostReferenceData = gql`
    subscription PostReferenceData {
  postReferenceData {
    id: _id
    category
    order
    key
    value
    isArchived
  }
}
    `;
export const GetLevelTableData = gql`
    query GetLevelTableData {
  academicLevels(showArchived: true, showHided: true) {
    ...BasicAcademicLevelFields
  }
}
    ${BasicAcademicLevelFields}`;
export const GetSchoolTableData = gql`
    query GetSchoolTableData {
  schools(showArchived: true, showHided: true) {
    id: _id
    name
    alias
    educationStage
    isVisible
    isArchived
    createDateTime
  }
}
    `;
export const CreateLevel = gql`
    mutation CreateLevel($CreateAcademicLevelInput: CreateAcademicLevelInput!) {
  createAcademicLevel(input: $CreateAcademicLevelInput) {
    ...AcademicLevelFields
  }
}
    ${AcademicLevelFields}`;
export const UpdateLevel = gql`
    mutation UpdateLevel($UpdateAcademicLevelInput: UpdateAcademicLevelInput!) {
  updateAcademicLevel(input: $UpdateAcademicLevelInput) {
    ...AcademicLevelFields
  }
}
    ${AcademicLevelFields}`;
export const DeleteLevel = gql`
    mutation DeleteLevel($DeleteAcademicLevelInput: IdInput!) {
  deleteAcademicLevel(input: $DeleteAcademicLevelInput) {
    id: _id
  }
}
    `;
export const ChangeAcademicLevelArchive = gql`
    mutation changeAcademicLevelArchive($input: ChangeAcademicLevelArchive!) {
  changeAcademicLevelArchive(input: $input) {
    ...AcademicLevelFields
  }
}
    ${AcademicLevelFields}`;
export const ChangeAcademicLevelVisibility = gql`
    mutation changeAcademicLevelVisibility($input: ChangeAcademicLevelVisibility!) {
  changeAcademicLevelVisibility(input: $input) {
    ...AcademicLevelFields
  }
}
    ${AcademicLevelFields}`;
export const GetLevel = gql`
    query getLevel($id: ObjectId!) {
  academicLevel(id: $id) {
    ...AcademicLevelFields
  }
}
    ${AcademicLevelFields}`;
export const GetLevels = gql`
    query getLevels($ids: [ObjectId!]) {
  academicLevels(ids: $ids) {
    ...AcademicLevelFields
  }
}
    ${AcademicLevelFields}`;
export const GetAcademicLevelsWithCourses = gql`
    query getAcademicLevelsWithCourses($ids: [ObjectId!]) {
  academicLevels(ids: $ids) {
    id: _id
    alias
    createDateTime
    educationStage
    isArchived
    isVisible
    name
    orderNo
    courses(statuses: [Completed, Created, Ongoing]) {
      id: _id
      name
      nameShort
    }
  }
}
    `;
export const GetAllAcademicLevels = gql`
    query getAllAcademicLevels {
  academicLevels {
    id: _id
    alias
    name
  }
}
    `;
export const UpdateAcademicYear = gql`
    mutation updateAcademicYear($input: UpdateAcademicYearInput!) {
  updateAcademicYear(input: $input) {
    id: _id
  }
}
    `;
export const CreateAcademicYear = gql`
    mutation createAcademicYear($input: CreateAcademicYearInput!) {
  createAcademicYear(input: $input) {
    id: _id
  }
}
    `;
export const AcademicYear = gql`
    query academicYear($id: ObjectId!) {
  academicYear(id: $id) {
    id: _id
    year
    period {
      start
      end
    }
    academicWeekStart
    academicWeekEnd
    terms {
      id: _id
      academicLevels {
        id: _id
      }
      ...BasicTermFields
    }
    holidays {
      ...BasicHolidayFields
    }
  }
}
    ${BasicTermFields}
${BasicHolidayFields}`;
export const AcademicYears = gql`
    query academicYears {
  academicYears {
    id: _id
    year
    taxRate
    period {
      start
      end
    }
    academicWeekStart
    academicWeekEnd
    academicWeeks {
      startDate
      endDate
      weekNumber
    }
  }
}
    `;
export const GetAcademicYearsOptions = gql`
    query getAcademicYearsOptions {
  academicYears {
    id: _id
    year
  }
}
    `;
export const GetAcademicYearTaxRate = gql`
    query GetAcademicYearTaxRate($year: Int!) {
  academicYear(year: $year) {
    id: _id
    year
    taxRate
  }
}
    `;
export const CreateDepartment = gql`
    mutation CreateDepartment($CreateDepartmentInput: CreateDepartmentInput!) {
  createDepartment(input: $CreateDepartmentInput) {
    ...DepartmentFields
  }
}
    ${DepartmentFields}`;
export const UpdateDepartment = gql`
    mutation UpdateDepartment($input: UpdateDepartmentInput!) {
  updateDepartment(input: $input) {
    ...DepartmentFields
  }
}
    ${DepartmentFields}`;
export const DeleteDepartment = gql`
    mutation DeleteDepartment($DeleteDepartmentInput: IdInput!) {
  deleteDepartment(input: $DeleteDepartmentInput) {
    id: _id
    departments {
      id: _id
    }
  }
}
    `;
export const ChangeDepartmentArchive = gql`
    mutation changeDepartmentArchive($input: ChangeDepartmentArchiveInput!) {
  changeDepartmentArchive(input: $input) {
    ...DepartmentFields
  }
}
    ${DepartmentFields}`;
export const CreateBranch = gql`
    mutation CreateBranch($CreateBranchInput: CreateBranchInput!) {
  createBranch(input: $CreateBranchInput) {
    id: _id
  }
}
    `;
export const UpdateBranch = gql`
    mutation UpdateBranch($UpdateBranchInput: UpdateBranchInput!) {
  updateBranch(input: $UpdateBranchInput) {
    ...BasicBranchFields
    venues {
      ...BasicVenueFields
    }
  }
}
    ${BasicBranchFields}
${BasicVenueFields}`;
export const DeleteBranch = gql`
    mutation DeleteBranch($DeleteBranchInput: IdInput!) {
  deleteBranch(input: $DeleteBranchInput) {
    id: _id
    name
  }
}
    `;
export const ChangeBranchArchive = gql`
    mutation changeBranchArchive($input: ChangeBranchArchiveInput!) {
  changeBranchArchive(input: $input) {
    id: _id
  }
}
    `;
export const CreateVenue = gql`
    mutation CreateVenue($command: CreateVenueCommand!) {
  createVenue(command: $command) {
    id: _id
    branch {
      ...BasicBranchFields
      venues {
        ...BasicVenueFields
      }
    }
  }
}
    ${BasicBranchFields}
${BasicVenueFields}`;
export const UpdateVenue = gql`
    mutation UpdateVenue($command: UpdateVenueCommand!) {
  updateVenue(command: $command) {
    ...BasicVenueFields
  }
}
    ${BasicVenueFields}`;
export const CreateCourierPickupAddress = gql`
    mutation CreateCourierPickupAddress($input: CreateCourierPickupAddressInput!) {
  createCourierPickupAddress(input: $input) {
    ...BasicCourierPickupAddressFields
  }
}
    ${BasicCourierPickupAddressFields}`;
export const UpdateCourierPickupAddress = gql`
    mutation UpdateCourierPickupAddress($input: UpdateCourierPickupAddressInput!) {
  updateCourierPickupAddress(input: $input) {
    ...BasicCourierPickupAddressFields
  }
}
    ${BasicCourierPickupAddressFields}`;
export const UpdateCourierPickupAddressArchive = gql`
    mutation UpdateCourierPickupAddressArchive($input: ChangeCourierPickupAddressArchiveInput!) {
  changeCourierPickupAddressArchive(input: $input) {
    ...BasicCourierPickupAddressFields
  }
}
    ${BasicCourierPickupAddressFields}`;
export const GetDepartments = gql`
    query getDepartments($showArchive: Boolean = false) {
  departments(showArchived: $showArchive) {
    ...DepartmentFields
  }
}
    ${DepartmentFields}`;
export const GetDepartment = gql`
    query getDepartment($id: ObjectId!) {
  department(id: $id) {
    ...DepartmentFields
  }
}
    ${DepartmentFields}`;
export const GetAllBranches = gql`
    query getAllBranches($showArchive: Boolean = false) {
  branches(showArchived: $showArchive) {
    ...BasicBranchFields
    venues(showArchived: $showArchive) {
      id: _id
      isArchived
    }
  }
}
    ${BasicBranchFields}`;
export const GetAllVenues = gql`
    query getAllVenues($showArchive: Boolean = false, $showDigital: Boolean = false) {
  venues(showArchived: $showArchive, showDigital: $showDigital) {
    ...BasicVenueFields
    createdBy {
      ...CreateByFields
    }
    branch {
      ...BasicBranchFields
    }
  }
}
    ${BasicVenueFields}
${CreateByFields}
${BasicBranchFields}`;
export const GetAllVenueOptions = gql`
    query getAllVenueOptions($showArchive: Boolean = false) {
  venues(showArchived: $showArchive) {
    id: _id
    name
    branch {
      name
      alias
    }
  }
}
    `;
export const GetCentreId = gql`
    query getCentreId {
  centre {
    id: _id
  }
}
    `;
export const GetBranches = gql`
    query getBranches {
  branches {
    id: _id
    name
    venues {
      ...BasicVenueFields
    }
  }
}
    ${BasicVenueFields}`;
export const GetAllVenue = gql`
    query getAllVenue($showArchived: Boolean) {
  venues(showArchived: $showArchived) {
    id: _id
    name
    shorthand
    unitNo
    capacity
    isArchived
    branch {
      id: _id
      name
      alias
    }
    createDateTime
    createdBy {
      ...CreateByFields
    }
  }
}
    ${CreateByFields}`;
export const GetAllCourierPickupAddress = gql`
    query GetAllCourierPickupAddress($showArchived: Boolean) {
  courierPickupAddresses(showArchived: $showArchived) {
    ...BasicCourierPickupAddressFields
  }
}
    ${BasicCourierPickupAddressFields}`;
export const CreateMailMerge = gql`
    mutation createMailMerge($input: CreateMailMergeInput!) {
  createMailMerge(input: $input) {
    ...MailMergeFields
  }
}
    ${MailMergeFields}`;
export const UpdateMailMerge = gql`
    mutation updateMailMerge($input: UpdateMailMergeInput!) {
  updateMailMerge(input: $input) {
    ...MailMergeFields
  }
}
    ${MailMergeFields}`;
export const UploadMailMergeMergeList = gql`
    mutation uploadMailMergeMergeList($input: UploadMailMergeMergeListInput!) {
  uploadMailMergeMergeList(input: $input) {
    ...MailMergeFields
  }
}
    ${MailMergeFields}`;
export const GetMailMerge = gql`
    query getMailMerge($id: ObjectId!) {
  getMailMerge(id: $id) {
    ...MailMergeFields
  }
}
    ${MailMergeFields}`;
export const CompleteMailMerge = gql`
    mutation completeMailMerge($input: CompleteMailMergeInput!) {
  completeMailMerge(input: $input) {
    ...MailMergeFields
  }
}
    ${MailMergeFields}`;
export const DownloadMailMerge = gql`
    mutation downloadMailMerge($id: ObjectId!) {
  downloadMailMerge(id: $id) {
    url
  }
}
    `;
export const GetNotificationConfigurations = gql`
    query getNotificationConfigurations($type: NotificationType!) {
  getNotificationConfigurations(type: $type) {
    ...NotificationSettingFields
  }
}
    ${NotificationSettingFields}`;
export const UpdateNotificationItem = gql`
    mutation updateNotificationItem($input: NotificationInput!) {
  updateNotificationItem(input: $input) {
    ...NotificationSettingFields
  }
}
    ${NotificationSettingFields}`;
export const GetNotificationsOfTemplates = gql`
    query getNotificationsOfTemplates($search: String, $filterInput: TemplateFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  templates(search: $search, filterInput: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) @connection(key: "templates", filter: ["search", "filterInput", "sortInfo"]) {
    items {
      id: _id
      title
      type
    }
    total
    pageSize
    pageIndex
  }
}
    `;
export const CreatePromotion = gql`
    mutation createPromotion($input: CreatePromotionInput!) {
  createPromotion(input: $input) {
    ...PromotionFields
  }
}
    ${PromotionFields}`;
export const UpdatePromotion = gql`
    mutation updatePromotion($input: UpdatePromotionInput!) {
  updatePromotion(input: $input) {
    ...PromotionFields
  }
}
    ${PromotionFields}`;
export const DeletePromotion = gql`
    mutation deletePromotion($id: ObjectId!) {
  deletePromotion(id: $id) {
    ...PromotionFields
  }
}
    ${PromotionFields}`;
export const CancelPromotion = gql`
    mutation cancelPromotion($id: ObjectId!) {
  cancelPromotion(id: $id) {
    ...PromotionFields
  }
}
    ${PromotionFields}`;
export const GetPromotions = gql`
    query getPromotions {
  promotions {
    ...PromotionFields
  }
}
    ${PromotionFields}`;
export const GetPromotion = gql`
    query getPromotion($id: ObjectId!) {
  promotion(id: $id) {
    ...PromotionFields
    students {
      id: _id
      fullName
      gender
      birthDate
      academicLevel {
        id: _id
        name
      }
    }
    promoteToLevel {
      id: _id
      name
    }
    selectedStudents {
      ...StudentFieldsInPromotion
    }
  }
}
    ${PromotionFields}
${StudentFieldsInPromotion}`;
export const PromotionCsv = gql`
    query promotionCsv {
  csv: exportPromotionCsv {
    url
  }
}
    `;
export const GetRewardsHistory = gql`
    query GetRewardsHistory($filterInput: RewardHistoryFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  rewardsHistory(filterInput: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) {
    items {
      ...BasicRewardHistoryFields
    }
    pageIndex
    pageSize
    total
  }
}
    ${BasicRewardHistoryFields}`;
export const QueryHistoryPointBaseDateRange = gql`
    query QueryHistoryPointBaseDateRange($studentId: ObjectId!, $dateRange: DateRangeInput!) {
  historyPointCounterBaseDateRange(studentId: $studentId, dateRange: $dateRange) {
    redeemed
    accumulated
  }
}
    `;
export const GetRewards = gql`
    query GetRewards($search: String, $filterInput: RewardFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  rewards(search: $search, filterInput: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) {
    items {
      ...BasicRewardFields
    }
    pageIndex
    pageSize
    total
  }
}
    ${BasicRewardFields}`;
export const AddRewardPoints = gql`
    mutation AddRewardPoints($input: AddRewardPointsInput!) {
  addRewardPoints(input: $input) {
    id: _id
  }
}
    `;
export const DeductRewardPoints = gql`
    mutation DeductRewardPoints($input: DeductRewardPointsInput!) {
  deductRewardPoints(input: $input) {
    id: _id
  }
}
    `;
export const AddReward = gql`
    mutation addReward($input: AddRewardCatalogueInput!) {
  addReward(input: $input) {
    id: _id
  }
}
    `;
export const UpdateReward = gql`
    mutation updateReward($input: UpdateRewardCatalogInput!) {
  updateReward(input: $input) {
    ...BasicRewardFields
  }
}
    ${BasicRewardFields}`;
export const ArchiveReward = gql`
    mutation archiveReward($id: ObjectId!) {
  archiveReward(id: $id) {
    ...BasicRewardFields
  }
}
    ${BasicRewardFields}`;
export const RestoreReward = gql`
    mutation restoreReward($id: ObjectId!) {
  restoreReward(id: $id) {
    ...BasicRewardFields
  }
}
    ${BasicRewardFields}`;
export const UseRewardPoints = gql`
    mutation useRewardPoints($input: UseRewardPointsInput!) {
  useRewardPoints(input: $input) {
    ...BasicStudentBaseRewards
  }
}
    ${BasicStudentBaseRewards}`;
export const UpdateRewardHistory = gql`
    mutation updateRewardHistory($input: UpdateRewardsHistoryInput!) {
  updateRewardsHistory(input: $input) {
    ...BasicRewardHistoryFields
  }
}
    ${BasicRewardHistoryFields}`;
export const ArchiveHistoryReward = gql`
    mutation archiveHistoryReward($id: ObjectId!) {
  archiveHistoryReward(id: $id) {
    ...BasicRewardHistoryFields
  }
}
    ${BasicRewardHistoryFields}`;
export const CreateSchool = gql`
    mutation CreateSchool($CreateSchoolInput: CreateSchoolInput!) {
  createSchool(input: $CreateSchoolInput) {
    ...SchoolFields
  }
}
    ${SchoolFields}`;
export const UpdateSchool = gql`
    mutation UpdateSchool($UpdateSchoolInput: UpdateSchoolInput!) {
  updateSchool(input: $UpdateSchoolInput) {
    ...SchoolFields
  }
}
    ${SchoolFields}`;
export const DeleteSchool = gql`
    mutation DeleteSchool($DeleteSchoolInput: IdInput!) {
  deleteSchool(input: $DeleteSchoolInput) {
    id: _id
  }
}
    `;
export const ChangeSchoolArchive = gql`
    mutation changeSchoolArchive($input: ChangeSchoolArchiveInput!) {
  changeSchoolArchive(input: $input) {
    ...SchoolFields
  }
}
    ${SchoolFields}`;
export const ChangeSchoolVisibility = gql`
    mutation changeSchoolVisibility($input: ChangeSchoolVisibilityInput!) {
  changeSchoolVisibility(input: $input) {
    ...SchoolFields
  }
}
    ${SchoolFields}`;
export const GetSchool = gql`
    query getSchool($id: ObjectId!) {
  school(id: $id) {
    ...SchoolFields
  }
}
    ${SchoolFields}`;
export const GetSchools = gql`
    query getSchools {
  schools {
    ...SchoolFields
  }
}
    ${SchoolFields}`;
export const CreateTemplate = gql`
    mutation CreateTemplate($input: CreateTemplateInput!) {
  createTemplate(input: $input) {
    ...BasicTemplateFields
  }
}
    ${BasicTemplateFields}`;
export const UpdateTemplate = gql`
    mutation UpdateTemplate($input: UpdateTemplateInput!) {
  updateTemplate(input: $input) {
    ...BasicTemplateFields
  }
}
    ${BasicTemplateFields}`;
export const UpdateTemplateArchive = gql`
    mutation updateTemplateArchive($id: ObjectId!, $isArchived: Boolean!) {
  updateArchivable(id: $id, isArchived: $isArchived) {
    ...BasicTemplateFields
  }
}
    ${BasicTemplateFields}`;
export const GetTemplates = gql`
    query getTemplates($search: String, $filterInput: TemplateFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  templates(search: $search, filterInput: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) @connection(key: "templates", filter: ["search", "filterInput", "sortInfo"]) {
    items {
      ...BasicTemplateFields
      isArchived
    }
    total
    pageSize
    pageIndex
  }
}
    ${BasicTemplateFields}`;
export const GetTemplate = gql`
    query getTemplate($id: ObjectId!) {
  template(id: $id) {
    ...BasicTemplateFields
  }
}
    ${BasicTemplateFields}`;
export const CreateLog = gql`
    mutation CreateLog($input: CreateLogInput!) {
  createLog(input: $input) {
    ...LogFields
  }
}
    ${LogFields}`;
export const UpdateLog = gql`
    mutation UpdateLog($input: UpdateLogInput!) {
  updateLog(input: $input) {
    ...LogFields
  }
}
    ${LogFields}`;
export const CreateNote = gql`
    mutation CreateNote($input: CreateNoteInput!) {
  createNote(input: $input) {
    ...NoteFields
  }
}
    ${NoteFields}`;
export const GetUserLogs = gql`
    query GetUserLogs($userId: ObjectId!, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  userLogs(userId: $userId, sortInfo: $sortInfo, pageInfo: $pageInfo) {
    items {
      ...LogFields
    }
    total
  }
}
    ${LogFields}`;
export const GetUserNotes = gql`
    query GetUserNotes($userId: ObjectId!, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  userNotes(userId: $userId, sortInfo: $sortInfo, pageInfo: $pageInfo) {
    items {
      ...NoteFields
    }
    total
  }
}
    ${NoteFields}`;
export const GetStudentClasses = gql`
    query GetStudentClasses($search: String, $studentId: ObjectId!, $pageInfo: PageInfo, $sortInfo: SortInfo) {
  classesFromStudent(studentId: $studentId, search: $search, sortInfo: $sortInfo, pageInfo: $pageInfo) {
    items {
      id: _id
    }
  }
}
    `;
export const GetStudentForLinkPeople = gql`
    query GetStudentForLinkPeople($id: ObjectId!, $parentId: ObjectId) {
  student(id: $id) {
    id: _id
    fullName
    primaryParent {
      id: _id
    }
    parents {
      id: _id
      fullName
      avatarUrl
      salutation
      gender
      alias
      userId
      canRemove(id: $parentId)
      relationName(id: $id)
    }
    siblings {
      id: _id
      relationName(id: $id)
      fullName
      avatarUrl
      gender
      alias
      userId
    }
  }
}
    `;
export const GetParentForLinkPeople = gql`
    query GetParentForLinkPeople($id: ObjectId!) {
  parent(id: $id) {
    id: _id
    relatedParents {
      id: _id
      fullName
      avatarUrl
      gender
      userId
      canRemove(id: $id)
      relationName(id: $id)
    }
    relatedStudents {
      id: _id
      fullName
      avatarUrl
      gender
      userId
      relationName(id: $id)
      primaryParent {
        id: _id
      }
    }
  }
}
    `;
export const LinkParent = gql`
    mutation LinkParent($input: LinkParentInput!) {
  linkParent(input: $input) {
    parentId1
    parentId2
  }
}
    `;
export const UnlinkParent = gql`
    mutation UnlinkParent($input: LinkParentInput!) {
  unlinkParent(input: $input) {
    parentId1
    parentId2
  }
}
    `;
export const GetStudentsForLinkPeople = gql`
    query getStudentsForLinkPeople($search: String, $filterInput: StudentFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  students(search: $search, filterInput: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) {
    items {
      id: _id
      fullName
      alias
      avatarUrl
      gender
      primaryParent {
        id: _id
      }
    }
    pageIndex
    pageSize
    total
  }
}
    `;
export const GetListParentsData = gql`
    query GetListParentsData($filterInput: ParentFilter, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  parentsWithIndex(filter: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) {
    pageIndex
    pageSize
    total
    items {
      ...ParentIndexFields
    }
  }
}
    ${ParentIndexFields}`;
export const GetParentIndex = gql`
    query GetParentIndex($id: ObjectId!) {
  parentWithIndex(id: $id) {
    ...ParentIndexFields
  }
}
    ${ParentIndexFields}`;
export const VoidParent = gql`
    mutation VoidParent($input: VoidInput!) {
  voidParent(input: $input) {
    id: _id
  }
}
    `;
export const GetMergeAccountParent = gql`
    query getMergeAccountParent($parent: ObjectId!) {
  parent(id: $parent) {
    id: _id
    fullName
    gender
    alias
    salutation
    userId
    address {
      gateBuilding
      streetAddress
      unit
      country
      postalCode
    }
    email
    mobile {
      ...TelephoneFields
    }
    homePhone {
      ...TelephoneFields
    }
    officePhone {
      ...TelephoneFields
    }
    whatsAppName
    linkedWhatsappAccount {
      number {
        number
        countryCode
      }
    }
    relatedStudents {
      id: _id
      alias
      userId
      avatarUrl
      fullName
    }
    directlyRelatedStudents {
      id: _id
    }
  }
}
    ${TelephoneFields}`;
export const GetMergeOptionParents = gql`
    query getMergeOptionParents($filterInput: ParentFilter) {
  parents(filterInput: $filterInput, pageInfo: {pageSize: 0, pageIndex: 0}) {
    items {
      id: _id
      fullName
      userId
    }
  }
}
    `;
export const MergeParent = gql`
    mutation mergeParent($input: MergeParentInput!) {
  mergeParent(input: $input) {
    id: _id
  }
}
    `;
export const GetListStudentsWithIndexData = gql`
    query GetListStudentsWithIndexData($filterInput: StudentFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  studentsWithIndex(filter: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) {
    items {
      ...StudentIndexFields
    }
    total
    pageIndex
    pageSize
  }
}
    ${StudentIndexFields}`;
export const GetListStudentsData = gql`
    query GetListStudentsData($search: String, $filterInput: StudentFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  students(search: $search, filterInput: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) @connection(key: "students", filter: ["search", "filterInput", "sortInfo"]) {
    items {
      id: _id
      fullName
      alias
      status
      avatarUrl
      email
      gender
      alternativeEmails
      birthDate
      userId
      mobile {
        countryCode
        number
      }
      primarySchool {
        name
      }
      secondarySchool {
        name
      }
      academicLevel {
        name
        educationStage
      }
      address {
        country
        gateBuilding
        link
        postalCode
        streetAddress
        unit
      }
      primaryParent {
        id: _id
        fullName
        email
        mobile {
          countryCode
          number
        }
        userId
        linkedWhatsappAccount {
          number {
            number
            countryCode
          }
        }
        relatedParents {
          id: _id
          fullName
          email
          mobile {
            countryCode
            number
          }
          linkedWhatsappAccount {
            number {
              number
              countryCode
            }
          }
        }
      }
      siblings {
        id: _id
        fullName
      }
      parents {
        id: _id
        fullName
        email
        mobile {
          countryCode
          number
        }
      }
      enrollments {
        type
        startDate
        class {
          _id
          name
        }
      }
      registrations {
        type
        startDate
      }
      examScores {
        id: _id
        year
        subject
        marksPercentage
        createDateTime
      }
      remarks {
        ...RemarkFields
      }
    }
    pageIndex
    pageSize
    total
  }
}
    ${RemarkFields}`;
export const GetListStudentFilterOptions = gql`
    query GetListStudentFilterOptions {
  academicYears {
    id: _id
    year
    academicWeeks {
      startDate
      endDate
      weekNumber
    }
  }
  branches {
    id: _id
    name
    alias
    venues {
      ...BasicVenueFields
    }
  }
}
    ${BasicVenueFields}`;
export const VoidStudent = gql`
    mutation VoidStudent($input: VoidInput!) {
  voidStudent(input: $input) {
    id: _id
  }
}
    `;
export const GetMergeAccountStudent = gql`
    query getMergeAccountStudent($studentId: ObjectId!, $input: StudentEnrollmentFilterInput) {
  student(id: $studentId) {
    id: _id
    alias
    gender
    fullName
    mobile {
      countryCode
      number
    }
    homePhone {
      countryCode
      number
    }
    email
    birthDate
    address {
      gateBuilding
      streetAddress
      unit
      country
      postalCode
    }
    userId
    primaryParent {
      id: _id
      avatarUrl
      userId
      salutation
      fullName
      relatedParents {
        id: _id
        avatarUrl
        userId
        salutation
        fullName
      }
    }
    academicLevel {
      name
      educationStage
    }
    primarySchool {
      name
    }
    secondarySchool {
      name
    }
    secondaryStream
    enrollments(filterInput: $input) {
      id: _id
      startDate
      endDate
      class {
        id: _id
        name
        shorthand
        type
        runNumber
        teacher {
          avatarUrl
        }
        course {
          colorCode
          nameShort
        }
      }
    }
  }
}
    `;
export const GetOptionStudents = gql`
    query getOptionStudents($filterInput: StudentFilterInput) {
  students(filterInput: $filterInput, pageInfo: {pageSize: 0, pageIndex: 0}) {
    items {
      id: _id
      fullName
      userId
    }
  }
}
    `;
export const MergeStudent = gql`
    mutation mergeStudent($input: MergeStudentInput!) {
  mergeStudent(input: $input) {
    id: _id
  }
}
    `;
export const CreateStudentReportFilter = gql`
    mutation CreateStudentReportFilter($input: StudentReportFilterInput!) {
  createStudentReportFilter(input: $input) {
    ...StudentReportFilterFragment
  }
}
    ${StudentReportFilterFragment}`;
export const GetAcademicYear = gql`
    query getAcademicYear($year: Int!) {
  academicYear(year: $year) {
    id: _id
    year
    period {
      start
      end
    }
    academicWeekStart
    academicWeekEnd
    academicWeeks {
      startDate
      endDate
      weekNumber
    }
  }
}
    `;
export const StudentReportFilters = gql`
    query StudentReportFilters($userId: ObjectId!) {
  studentReportFilters(userId: $userId) {
    ...StudentReportFilterFragment
  }
}
    ${StudentReportFilterFragment}`;
export const ExportStudentReportCsv = gql`
    query exportStudentReportCsv($filter: StudentReportFilterInput!) {
  exportStudentReportCsv(filter: $filter) {
    url
  }
}
    `;
export const DeleteStudentReportFilter = gql`
    mutation DeleteStudentReportFilter($id: ObjectId!) {
  deleteStudentReportFilter(id: $id) {
    ...StudentReportFilterFragment
  }
}
    ${StudentReportFilterFragment}`;
export const UpdateStudentReportFilter = gql`
    mutation UpdateStudentReportFilter($input: UpdateStudentReportFilterInput!) {
  updateStudentReportFilter(input: $input) {
    ...StudentReportFilterFragment
  }
}
    ${StudentReportFilterFragment}`;
export const GetStudentReports = gql`
    query getStudentReports($pageInfo: PageInfo, $filter: StudentReportFilterInput!) {
  studentReports(pageInfo: $pageInfo, filter: $filter) {
    items {
      id: _id
      fullName
      avatarUrl
      birthDate
      email
      gender
      alias
      address {
        country
        streetAddress
        gateBuilding
        postalCode
        link
        unit
      }
      userId
      primarySchool {
        name
      }
      primaryStream
      secondarySchool {
        name
      }
      secondaryStream
      academicLevel {
        alias
      }
      primaryParent {
        id: _id
        fullName
        salutation
        alias
        userId
        email
        mobile {
          countryCode
          number
        }
        homePhone {
          countryCode
          number
        }
        linkedWhatsappAccount {
          number {
            number
            countryCode
          }
        }
      }
      parents {
        id: _id
        fullName
        salutation
        alias
        email
        userId
        mobile {
          countryCode
          number
          number
        }
        linkedWhatsappAccount {
          number {
            number
            countryCode
          }
        }
      }
      siblings {
        fullName
      }
      enrollments {
        ...BasicEnrollmentField
        class {
          name
          teacher {
            id: _id
            fullName
            salutation
          }
          learningArrangement
        }
        group
      }
    }
    pageIndex
    pageSize
    total
  }
}
    ${BasicEnrollmentField}`;
export const CentreAssignments = gql`
    query centreAssignments($studentId: ObjectId!) {
  centreAssignments(studentId: $studentId) {
    gradable {
      ... on Exercise {
        _id
        workSheet {
          topic {
            name
          }
          title
          isArchived
          createDateTime
        }
        lessonNumber
        totalGrade
        totalBreakdown {
          ecq
          mcq
        }
      }
      ... on Homework {
        _id
        workSheet {
          topic {
            name
          }
          title
          isArchived
          createDateTime
        }
        lessonNumber
        totalGrade
        totalBreakdown {
          ecq
          mcq
        }
      }
    }
    submission {
      id: _id
      enrollment {
        id: _id
      }
      class {
        id: _id
      }
      grade
      breakdown {
        ecq
        mcq
      }
      gradable {
        ... on Exercise {
          id: _id
        }
        ... on Homework {
          id: _id
        }
      }
    }
    classScores {
      max
      min
      average
    }
    levelScores {
      max
      min
      average
    }
  }
}
    `;
export const GetStudentEnrollmentTabData = gql`
    query GetStudentEnrollmentTabData($id: ObjectId!, $filterInput: StudentEnrollmentFilterInput) {
  student(id: $id) {
    academicLevel {
      id: _id
    }
    enrollments(filterInput: $filterInput) {
      _id
      group
      type
      status
      startDate
      endDate
      preferredLearningArrangement
      isFullEnrollment
      isEndRecurring
      attendances {
        type
        status
        lesson {
          id: _id
          lessonStart
          lessonEnd
          lessonNumber
        }
      }
      class {
        id: _id
        name
        type
        defaultLessonDays
        defaultLessonStart
        defaultLessonEnd
        dailySessionTimeSlots {
          start
          end
        }
        term {
          term1Period {
            start
            end
          }
          term2Period {
            start
            end
          }
          term3Period {
            start
            end
          }
          term4Period {
            start
            end
          }
        }
        academicYear {
          id: _id
          year
          holidays {
            id: _id
          }
        }
        digitalClassAccessCredentials {
          zoomMeetingLink
          zoomMeetingPassword
          zoomMeetingId
          googleMeetLink
          googleMeetId
        }
        course {
          id: _id
          name
          avatarUrl
          type
          colorCode
        }
        learningArrangement
        teacher {
          id: _id
          fullName
          salutation
          gender
          avatarUrl
          initials
        }
        venue {
          shorthand
          unitNo
          branch {
            name
            alias
          }
        }
        capacity {
          ...CapacityFields
        }
        enrollments {
          _id
          type
        }
      }
      invoices {
        id: _id
        referenceNumber
        status
        billedTo {
          id: _id
        }
      }
      registration {
        dueDate
      }
      arrangements {
        id: _id
        type
        status
        enrollmentId
      }
    }
  }
}
    ${CapacityFields}`;
export const GetStudentOnEligibleCourseTab = gql`
    query GetStudentOnEligibleCourseTab($id: ObjectId!) {
  student(id: $id) {
    academicLevel {
      id: _id
    }
  }
}
    `;
export const GetCoursesOnStudentTab = gql`
    query GetCoursesOnStudentTab($search: String, $filterInput: CourseFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo, $classStatus: [ClassStatus!], $academicYearId: ObjectId) {
  courses(search: $search, filterInput: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) @connection(key: "courses", filter: ["search", "filterInput", "sortInfo"]) {
    items {
      id: _id
      name
      type
      status
      avatarUrl
      academicLevels {
        id: _id
      }
      classes(status: $classStatus, academicYearId: $academicYearId) {
        id: _id
        name
        teacher {
          avatarUrl
          fullName
          salutation
        }
      }
    }
    pageIndex
    pageSize
    total
  }
}
    `;
export const GetStudentReservedOnWaitRegistrationData = gql`
    query GetStudentReservedOnWaitRegistrationData($id: ObjectId!, $filterInput: StudentEnrollmentFilterInput) {
  student(id: $id) {
    waitingLists: registrations(status: [Waitlisted]) {
      startDate
      class {
        id: _id
        name
        defaultLessonDays
        defaultLessonStart
        defaultLessonEnd
        term {
          term1Period {
            start
            end
          }
          term2Period {
            start
            end
          }
          term3Period {
            start
            end
          }
          term4Period {
            start
            end
          }
        }
        academicYear {
          id: _id
          year
          holidays {
            id: _id
          }
        }
        digitalClassAccessCredentials {
          zoomMeetingLink
          zoomMeetingPassword
          zoomMeetingId
          googleMeetLink
          googleMeetId
        }
        course {
          id: _id
          name
          avatarUrl
          type
        }
        lessons {
          id: _id
          lessonStart
          lessonEnd
          lessonNumber
        }
        learningArrangement
        teacher {
          id: _id
          fullName
          salutation
          gender
          avatarUrl
          initials
        }
        venue {
          shorthand
          unitNo
          branch {
            name
            alias
          }
        }
        capacity {
          ...CapacityFields
        }
        enrollments {
          _id
          type
        }
      }
    }
    enrollments(filterInput: $filterInput) {
      _id
      group
      type
      status
      startDate
      endDate
      preferredLearningArrangement
      class {
        id: _id
        name
        defaultLessonDays
        defaultLessonStart
        defaultLessonEnd
        term {
          term1Period {
            start
            end
          }
          term2Period {
            start
            end
          }
          term3Period {
            start
            end
          }
          term4Period {
            start
            end
          }
        }
        academicYear {
          id: _id
          year
          holidays {
            id: _id
          }
        }
        digitalClassAccessCredentials {
          zoomMeetingLink
          zoomMeetingPassword
          zoomMeetingId
          googleMeetLink
          googleMeetId
        }
        course {
          id: _id
          name
          avatarUrl
          type
          colorCode
        }
        lessons {
          id: _id
          lessonStart
          lessonEnd
          lessonNumber
        }
        learningArrangement
        teacher {
          id: _id
          fullName
          salutation
          gender
          avatarUrl
          initials
        }
        venue {
          shorthand
          unitNo
          branch {
            name
            alias
          }
        }
        capacity {
          ...CapacityFields
        }
        enrollments {
          _id
          type
        }
      }
      registration {
        startDate
        dueDate
      }
    }
  }
}
    ${CapacityFields}`;
export const GetStudentWaitingEnrollment = gql`
    query GetStudentWaitingEnrollment($id: ObjectId!, $filterInput: StudentEnrollmentFilterInput) {
  student(id: $id) {
    enrollments(filterInput: $filterInput) {
      class {
        ...ArrangementClassCardFields
      }
      registration {
        status
        dueDate
      }
      preferredLearningArrangement
      group
    }
  }
}
    ${ArrangementClassCardFields}`;
export const CreateMerchandise = gql`
    mutation CreateMerchandise($input: CreateMerchandiseInput!) {
  createMerchandise(input: $input) {
    ...MerchandiseFields
  }
}
    ${MerchandiseFields}`;
export const UpdateMerchandise = gql`
    mutation UpdateMerchandise($input: UpdateMerchandiseInput!) {
  updateMerchandise(input: $input) {
    ...MerchandiseFields
  }
}
    ${MerchandiseFields}`;
export const DeleteMerchandise = gql`
    mutation DeleteMerchandise($input: IdInput!) {
  deleteMerchandise(input: $input) {
    ...MerchandiseFields
  }
}
    ${MerchandiseFields}`;
export const GetStudentMerchandise = gql`
    query GetStudentMerchandise($studentId: ObjectId!, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  studentMerchandise(studentId: $studentId, sortInfo: $sortInfo, pageInfo: $pageInfo) {
    items {
      ...MerchandiseFields
    }
    pageIndex
    pageSize
    total
  }
}
    ${MerchandiseFields}`;
export const GetStudentProfile = gql`
    query getStudentProfile($id: ObjectId!) {
  student(id: $id) {
    ...StudentFields
    primaryParent {
      id: _id
      fullName
      salutation
      alias
      email
      mobile {
        countryCode
        number
      }
      whatsAppName
      latestStudentNumber
    }
  }
}
    ${StudentFields}`;
export const RegisterStudent = gql`
    mutation registerStudent($student: RegisterStudentInput!) {
  registerStudent(input: $student) {
    id: _id
    fullName
    gender
    primarySchool {
      id: _id
      name
    }
  }
}
    `;
export const UpdateAttendanceLessonMode = gql`
    mutation updateAttendanceLessonMode($input: UpdateAttendanceLessonModeInput!) {
  updateAttendanceLessonMode(input: $input) {
    id: _id
    lessonMode
  }
}
    `;
export const UpdateEnrollmentGroup = gql`
    mutation updateEnrollmentGroup($input: UpdateEnrollmentGroupInput!) {
  updateEnrollmentGroup(input: $input) {
    id: _id
    group
  }
}
    `;
export const QueryStudents = gql`
    query queryStudents($search: String, $filterInput: StudentFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  students(search: $search, filterInput: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) @connection(key: "students", filter: ["search", "filterInput", "sortInfo"]) {
    items {
      ...StudentFields
    }
    pageIndex
    pageSize
    total
  }
}
    ${StudentFields}`;
export const QueryStudentsOfClass = gql`
    query queryStudentsOfClass($classId: ObjectId!) {
  class(id: $classId) {
    id: _id
    name
    enrollments {
      type
      student {
        ...StudentFields
      }
    }
    course {
      id: _id
      name
      description
    }
    lessons {
      id: _id
      attendance {
        student {
          id: _id
          fullName
          gender
        }
        type
      }
    }
  }
}
    ${StudentFields}`;
export const GetEnrollmentsInClass = gql`
    query GetEnrollmentsInClass($id: ObjectId!, $types: [EnrollmentType!], $status: [EnrollmentStatus!]) {
  class(id: $id) {
    learningArrangement
    type
    isAdHoc
    id: _id
    name
    maximumClassCapacity
    maximumMakeUpCapacity
    capacityUsed
    lastLessonDate
    isReplacementLesson
    enrollments(types: $types, status: $status) {
      id: _id
      startDate
      endDate
      type
      status
      startDate
      group
      preferredLearningArrangement
      attendances {
        startDateTime
        remarks {
          ...RemarkFields
        }
      }
      followUpStatus
      followUpStatusLastModifiedDateTime
      followUpStatusLastModifiedBy {
        ...CreateByFields
      }
      suitability {
        status
      }
      reservation {
        startDate
        class {
          name
          shorthand
          course {
            colorCode
          }
          teacher {
            id: _id
          }
        }
      }
      arrangements {
        type
        status
        enrollmentId
      }
      class {
        id: _id
        name
        course {
          colorCode
        }
        teacher {
          id: _id
        }
        learningArrangement
      }
      student {
        ...StudentCellFields
      }
      isReserved
    }
  }
}
    ${RemarkFields}
${CreateByFields}
${StudentCellFields}`;
export const GetStudentsBirthDate = gql`
    query getStudentsBirthDate {
  getStudentsBirthDate
}
    `;
export const QueryStudentPoints = gql`
    query queryStudentPoints($id: ObjectId!) {
  student(id: $id) {
    ...BasicStudentBaseRewards
  }
}
    ${BasicStudentBaseRewards}`;
export const QueryStudentListBaseRewards = gql`
    query queryStudentListBaseRewards($search: String, $filterInput: StudentFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  students(search: $search, filterInput: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) @connection(key: "students", filter: ["search", "filterInput", "sortInfo"]) {
    items {
      id: _id
      gender
      fullName
      avatarUrl
      enrollments {
        type
        class {
          course {
            _id
          }
        }
      }
      rewardPointsCounter {
        remaining
        expiring
        redemptionsMade
      }
      academicLevel {
        name
        educationStage
      }
      primarySchool {
        name
      }
      secondarySchool {
        name
      }
    }
    pageIndex
    pageSize
    total
  }
}
    `;
export const QueryClassListBaseRewards = gql`
    query queryClassListBaseRewards($search: String, $filterInput: ClassFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  classes(search: $search, filterInput: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) @connection(key: "classes", filter: ["search", "filterInput", "sortInfo"]) {
    items {
      name
      enrollments {
        type
        startDate
        endDate
        student {
          id: _id
          gender
          fullName
          avatarUrl
          rewardPointsCounter {
            remaining
            expiring
            redemptionsMade
          }
          academicLevel {
            name
            educationStage
          }
          primarySchool {
            name
          }
          secondarySchool {
            name
          }
        }
      }
    }
    pageIndex
    pageSize
    total
  }
}
    `;
export const GetCourseRegistrationData = gql`
    query GetCourseRegistrationData($search: String, $filterInput: RegistrationFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  registrations(search: $search, filterInput: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) @connection(key: "registrations", filter: ["search", "filterInput", "sortInfo"]) {
    items {
      id: _id
      class {
        id: _id
        learningArrangement
        name
        shorthand
        classCode
        defaultLessonDays
        defaultLessonStart
        defaultLessonEnd
        venue {
          shorthand
          unitNo
          branch {
            name
            alias
          }
        }
        teacher {
          fullName
          salutation
          gender
          avatarUrl
          initials
          id: _id
        }
        course {
          name
          colorCode
          type
        }
      }
      enrollment {
        id: _id
        type
        startDate
        endDate
        lastModifiedBy {
          fullName
        }
        lastModifiedDateTime
        attendances {
          startDateTime
        }
      }
      startDate
      status
      isDeleted
      type
      student {
        id: _id
        userId
        fullName
        gender
        avatarUrl
        academicLevel {
          educationStage
        }
        primarySchool {
          name
        }
        secondarySchool {
          name
        }
      }
      lastModifiedBy {
        ...LastModifiedByFields
      }
    }
    pageIndex
    pageSize
    total
  }
}
    ${LastModifiedByFields}`;
export const GetReplacementCredits = gql`
    query GetReplacementCredits($query: StudentReplacementCreditsQuery!) {
  replacementCreditWithCourse(query: $query) {
    course {
      name
      id: _id
      avatarUrl
      type
    }
    academicYear {
      year
      id: _id
    }
    ...makeUpCreditInfoFragment
  }
}
    ${MakeUpCreditInfoFragment}`;
export const GetCourseWithReplacementLesson = gql`
    query GetCourseWithReplacementLesson($filterInput: ArrangementFilterInput) {
  arrangements(filterInput: $filterInput, pageInfo: {pageIndex: 0, pageSize: 0}) @connection(key: "arrangements", filter: ["filterInput"]) {
    items {
      id: _id
      fromClass {
        course {
          id: _id
          name
        }
        academicYear {
          id: _id
          year
        }
      }
    }
    pageIndex
    pageSize
    total
  }
}
    `;
export const GetStudentWithReplacementLessonRecords = gql`
    query GetStudentWithReplacementLessonRecords($filterInput: ArrangementFilterInput) {
  arrangements(filterInput: $filterInput, pageInfo: {pageIndex: 0, pageSize: 0}) @connection(key: "arrangements", filter: ["filterInput"]) {
    items {
      id: _id
      type
      status
      currentTermNumber
      fromClass {
        name
        id: _id
        teacher {
          ...TeacherCellFields
        }
      }
      fromLesson {
        ...MakeUpCheckLessonField
      }
      toClass {
        name
        id: _id
        teacher {
          ...TeacherCellFields
        }
      }
      toLesson {
        ...MakeUpCheckLessonField
      }
    }
    pageIndex
    pageSize
    total
  }
}
    ${TeacherCellFields}
${MakeUpCheckLessonField}`;
export const GetStudentLessonDate = gql`
    query GetStudentLessonDate($id: ObjectId!, $year: Int!, $filterInput: StudentEnrollmentFilterInput) {
  student(id: $id) {
    ...BasicStudentFields
    examScores {
      ...ExamScoreField
    }
    enrollments(filterInput: $filterInput) {
      ...BasicEnrollmentField
      class {
        id: _id
        ...BasicClassFields
      }
    }
    term(year: $year) {
      ...BasicTermFields
    }
  }
  academicYears {
    id: _id
    year
    period {
      start
      end
    }
    academicWeekStart
    academicWeekEnd
    terms {
      ...BasicTermFields
    }
  }
}
    ${BasicStudentFields}
${ExamScoreField}
${BasicEnrollmentField}
${BasicClassFields}
${BasicTermFields}`;
export const GetStudentAttendances = gql`
    query GetStudentAttendances($id: ObjectId!, $filterInput: StudentAttendanceFilterInput) {
  student(id: $id) {
    ...StudentBaseFields
    attendances(filterInput: $filterInput) {
      id: _id
      status
      type
      linkArrangement {
        type
        toClass {
          id: _id
          shorthand
        }
      }
      linkedAttendance {
        startDateTime
        endDateTime
        status
        type
        class {
          teacher {
            ...BasicTeacherFields
          }
        }
      }
      lesson {
        lessonStart
        lessonEnd
      }
      remarks(includesStudentRemarks: true, showArchived: false) {
        ...RemarkFields
      }
    }
  }
}
    ${StudentBaseFields}
${BasicTeacherFields}
${RemarkFields}`;
export const GetClassAcademicYearWithTerm = gql`
    query GetClassAcademicYearWithTerm($classId: ObjectId!, $academicLevelId: ObjectId!) {
  class(id: $classId) {
    academicYear {
      id: _id
      year
      term(academicLevelId: $academicLevelId) {
        ...BasicTermFields
      }
    }
  }
}
    ${BasicTermFields}`;
export const GetStudentRemarks = gql`
    query getStudentRemarks($id: ObjectId!) {
  student(id: $id) {
    ...BasicStudentFields
    remarks {
      ...RemarkFields
    }
    pinnedRemark {
      id: _id
      content
    }
    flagRemark {
      id: _id
      content
    }
  }
}
    ${BasicStudentFields}
${RemarkFields}`;
export const CreateParent = gql`
    mutation createParent($input: CreateParentInput!) {
  createParent(input: $input) {
    id: _id
    fullName
    email
    mobile {
      countryCode
      number
    }
  }
}
    `;
export const UpdateParent = gql`
    mutation updateParent($input: UpdateParentInput!) {
  updateParent(input: $input) {
    ...ParentFields
  }
}
    ${ParentFields}`;
export const StudentLinkParent = gql`
    mutation studentLinkParent($input: LinkParentInput!) {
  linkParent(input: $input) {
    parentId1
    parentId2
  }
}
    `;
export const UpdateRelationName = gql`
    mutation updateRelationName($input: UpdateRelationshipInput!) {
  updateRelationName(input: $input)
}
    `;
export const UpdateStudent = gql`
    mutation updateStudent($input: UpdateStudentInput!) {
  updateStudent(input: $input) {
    ...StudentFields
  }
}
    ${StudentFields}`;
export const RecordExamScore = gql`
    mutation recordExamScore($input: RecordExamScoreInput!) {
  recordExamScore(input: $input) {
    ...ExamScoreField
  }
}
    ${ExamScoreField}`;
export const UpdateExamScore = gql`
    mutation updateExamScore($input: UpdateExamScoreInput!) {
  updateExamScore(input: $input) {
    ...ExamScoreField
  }
}
    ${ExamScoreField}`;
export const UpdateStudentRemarks = gql`
    mutation updateStudentRemarks($input: UpdateStudentRemarksInput!) {
  updateStudentRemarks(input: $input) {
    id: _id
    remarks {
      ...RemarkFields
    }
  }
}
    ${RemarkFields}`;
export const ChangeAdminPassword = gql`
    mutation ChangeAdminPassword($id: ObjectId!) {
  requestChangeSystemUserPassword(id: $id)
}
    `;
export const RequestChangeSystemUserPassword = gql`
    mutation RequestChangeSystemUserPassword($id: ObjectId!) {
  requestChangeSystemUserPassword(id: $id)
}
    `;
export const GetParent = gql`
    query getParent($id: ObjectId!) {
  parent(id: $id) {
    id: _id
    alias
    email
    avatarUrl
    createDateTime
    gender
    fullName
    salutation
    status
    voidRemarks
    remark {
      ...RemarkFields
    }
    alternativeEmails
    userId
    alternateUserId
    latestStudentNumber
    whatsAppName
    address {
      country
      streetAddress
      gateBuilding
      postalCode
      unit
    }
    homePhone {
      countryCode
      number
    }
    mobile {
      countryCode
      number
    }
    officePhone {
      countryCode
      number
    }
    linkedWhatsappAccountId
    linkedWhatsappAccount {
      number {
        number
        countryCode
      }
    }
    relatedParents {
      relationName(id: $id)
      id: _id
      fullName
      avatarUrl
      gender
      email
      mobile {
        countryCode
        number
      }
      linkedWhatsappAccount {
        number {
          countryCode
          number
        }
      }
    }
    relatedStudents {
      relationName(id: $id)
      id: _id
      fullName
      avatarUrl
      gender
      userId
      primaryParent {
        id: _id
      }
      academicLevel {
        name
      }
    }
  }
}
    ${RemarkFields}`;
export const GetParents = gql`
    query getParents($search: String, $filterInput: ParentFilter, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  parents(search: $search, filterInput: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) @connection(key: "parents", filter: ["search", "filterInput", "sortInfo"]) {
    pageIndex
    pageSize
    total
    items {
      ...ParentFields
      latestStudentNumber
    }
  }
}
    ${ParentFields}`;
export const GetSearchParents = gql`
    query getSearchParents($search: String, $filterInput: ParentFilter, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  parents(search: $search, filterInput: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) @connection(key: "parents", filter: ["search", "filterInput", "sortInfo"]) {
    pageIndex
    pageSize
    total
    items {
      id: _id
      fullName
      alias
      salutation
      gender
      avatarUrl
      canRemove
      userId
    }
  }
}
    `;
export const GetStudent = gql`
    query getStudent($id: ObjectId!) {
  student(id: $id) {
    ...StudentBaseFields
    primaryParent {
      id: _id
      fullName
      salutation
      alias
      email
      mobile {
        countryCode
        number
      }
      whatsAppName
      linkedWhatsappAccount {
        number {
          number
          countryCode
        }
      }
    }
    siblings {
      relationName(id: $id)
      id: _id
      fullName
      avatarUrl
      gender
      userId
      primaryParent {
        id: _id
      }
      academicLevel {
        name
      }
    }
    parents {
      relationName(id: $id)
      id: _id
      fullName
      avatarUrl
      gender
      email
      mobile {
        countryCode
        number
      }
      linkedWhatsappAccount {
        number {
          countryCode
          number
        }
      }
    }
    examScores {
      ...ExamScoreField
    }
  }
}
    ${StudentBaseFields}
${ExamScoreField}`;
export const GetStudentCell = gql`
    query getStudentCell($id: ObjectId!) {
  student(id: $id) {
    ...StudentCellFields
    primarySchool {
      id: _id
      name
    }
    secondarySchool {
      id: _id
      name
    }
    academicLevel {
      id: _id
      educationStage
      name
    }
  }
}
    ${StudentCellFields}`;
export const GetStudents = gql`
    query getStudents($search: String, $filterInput: StudentFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  students(search: $search, filterInput: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) {
    items {
      ...StudentFields
    }
    pageIndex
    pageSize
    total
  }
}
    ${StudentFields}`;
export const GetTeacher = gql`
    query getTeacher($id: ObjectId!) {
  teacher: systemUser(id: $id) {
    ...SystemUserFields
  }
}
    ${SystemUserFields}`;
export const GetAdmin = gql`
    query getAdmin($id: ObjectId!) {
  admin: systemUser(id: $id) {
    ...SystemUserFields
  }
}
    ${SystemUserFields}`;
export const GetCurrentSystemUser = gql`
    query GetCurrentSystemUser {
  currentSystemUser {
    ...SystemUserFields
  }
}
    ${SystemUserFields}`;
export const GenerateUserId = gql`
    query generateUserId($input: GenerateUserIdInput!) {
  generateUserId(input: $input)
}
    `;
export const GetExamScore = gql`
    query getExamScore($id: ObjectId!) {
  examScores(studentId: $id) {
    ...ExamScoreField
  }
}
    ${ExamScoreField}`;
export const GetAuditLogs = gql`
    query getAuditLogs($search: String, $filterInput: AuditLogFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  auditLogs(search: $search, filterInput: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) @connection(key: "auditLogs", filter: ["search", "filterInput", "sortInfo"]) {
    total
    pageSize
    pageIndex
    items {
      ...AuditLogFields
    }
  }
}
    ${AuditLogFields}`;
export const GetAuditLog = gql`
    query getAuditLog($id: ObjectId!) {
  auditLog(id: $id) {
    ...AuditLogFields
  }
}
    ${AuditLogFields}`;
export const ParentCsv = gql`
    query parentCsv($search: String, $filterInput: ParentFilter) {
  csv: exportParentCsv(search: $search, filterInput: $filterInput) {
    url
  }
}
    `;
export const StudentCsv = gql`
    query studentCsv($search: String, $columns: [String!], $filterInput: StudentFilterInput) {
  csv: exportStudentCsv(search: $search, columns: $columns, filterInput: $filterInput) {
    url
  }
}
    `;
export const ExportSystemUserCsv = gql`
    query ExportSystemUserCsv($filterInput: SystemUserQuery) {
  csv: exportSystemUserCsv(filterInput: $filterInput) {
    url
  }
}
    `;
export const GetEmailValidation = gql`
    query getEmailValidation($email: String!, $excludeUserId: ObjectId) {
  validateEmail(email: $email, excludeUserId: $excludeUserId) {
    valid
    validationError
  }
}
    `;
export const AutocompleteParentInfo = gql`
    query autocompleteParentInfo {
  autocompleteParent {
    salutation
    fullName
    alias
    gender
    mobile {
      countryCode
      number
    }
    homePhone {
      countryCode
      number
    }
    officePhone {
      countryCode
      number
    }
    email
    alternativeEmails
    address {
      country
      gateBuilding
      link
      postalCode
      streetAddress
      unit
    }
    whatsAppName
    userId
    alternateUserId
    avatarUrl
  }
}
    `;
export const AutocompleteStudentInfo = gql`
    query autocompleteStudentInfo($primaryParentId: ObjectId!) {
  autocompleteStudent(primaryParentId: $primaryParentId) {
    academicLevelId
    alias
    alternateUserId
    alternativeEmails
    avatarUrl
    birthDate
    consent {
      PhotographyOrVideography
      accountCondition
      statements
      termCondition
    }
    email
    fullName
    gender
    homePhone {
      countryCode
      number
    }
    mobile {
      countryCode
      number
    }
    primaryParentId
    primarySchoolId
    primaryStream
    remarks
    secondarySchoolId
    secondaryStream
    userId
  }
}
    `;
export const GetCountryCodes = gql`
    query getCountryCodes {
  countryCodes {
    order
    areaCode
    countryCode
  }
}
    `;
export const GetOptionParents = gql`
    query getOptionParents($search: String, $filterInput: ParentFilter, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  parents(search: $search, filterInput: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) @connection(key: "parents", filter: ["search", "filterInput", "sortInfo"]) {
    pageIndex
    pageSize
    total
    items {
      id: _id
      avatarUrl
      salutation
      fullName
      userId
      latestStudentNumber
      mobile {
        countryCode
        number
      }
    }
  }
}
    `;
export const GetSystemUser = gql`
    query GetSystemUser($id: ObjectId!) {
  systemUser(id: $id) {
    ...SystemUserFields
  }
}
    ${SystemUserFields}`;
export const GetAlternativeLessonsForChangeAttendingClass = gql`
    query getAlternativeLessonsForChangeAttendingClass($input: AlternativeLessonsForChangeAttendingClassInput!) {
  alternativeLessons(input: $input) {
    id: _id
    lessonNumber
    lessonStart
    lessonEnd
    lessonDay
    maximumClassCapacity
    teacher {
      id: _id
      fullName
      salutation
      gender
      avatarUrl
      initials
    }
    venue {
      id: _id
      shorthand
      unitNo
    }
    academicWeek {
      startDate
      endDate
      weekNumber
    }
    class {
      ...ArrangementClassCardFields
    }
    capacity {
      ...LessonCapacityFields
    }
    attendance {
      id: _id
    }
  }
}
    ${ArrangementClassCardFields}
${LessonCapacityFields}`;
export const AddFeesForInvoice = gql`
    mutation AddFeesForInvoice($input: AddInvoiceLineItemCommand!) {
  addInvoiceLineItem(input: $input) {
    ...InvoiceItemFields
  }
}
    ${InvoiceItemFields}`;
export const UpdateLineItem = gql`
    mutation UpdateLineItem($input: UpdateInvoiceLineItemCommand!) {
  updateInvoiceItem(input: $input) {
    ...InvoiceItemFields
  }
}
    ${InvoiceItemFields}`;
export const ApplyDiscount = gql`
    mutation ApplyDiscount($input: ApplyInvoiceDiscountCommand!) {
  applyDiscount(input: $input) {
    ...InvoiceItemFields
  }
}
    ${InvoiceItemFields}`;
export const RemoveLineItem = gql`
    mutation RemoveLineItem($lineItemId: ObjectId!) {
  removeLineItem(lineItemId: $lineItemId) {
    ...InvoiceItemFields
  }
}
    ${InvoiceItemFields}`;
export const RemoveLineItemDiscount = gql`
    mutation RemoveLineItemDiscount($lineItemId: ObjectId!) {
  removeLineItemDiscount(lineItemId: $lineItemId) {
    ...InvoiceItemFields
  }
}
    ${InvoiceItemFields}`;
export const ResendReminder = gql`
    mutation ResendReminder($id: ObjectId!) {
  resendReminder(id: $id) {
    ...BasicReminderFields
    receiver {
      ...BasicParentFields
    }
    sender
    template {
      ...BasicTemplateFields
    }
  }
}
    ${BasicReminderFields}
${BasicParentFields}
${BasicTemplateFields}`;
export const CompileTemplate = gql`
    mutation CompileTemplate($input: SendReminderInput!) {
  compileTemplate(input: $input) {
    subject
    emailMessage
    whatsappMessage
    students {
      fullName
    }
    parent {
      fullName
      mobile {
        countryCode
        number
      }
      email
      salutation
      linkedWhatsappAccount {
        number {
          countryCode
          number
        }
      }
    }
  }
}
    `;
export const SendReminder = gql`
    mutation sendReminder($input: SendReminderInput!) {
  sendReminder(input: $input) {
    id: _id
  }
}
    `;
export const SendTestReminder = gql`
    mutation sendTestReminder($input: SendTestReminderInput!) {
  sendTestReminder(input: $input) {
    id: _id
  }
}
    `;
export const BulkCompileTemplate = gql`
    mutation BulkCompileTemplate($input: BulkSendReminderInput!) {
  bulkCompileTemplate(input: $input) {
    subject
    emailMessage
    whatsappMessage
    students {
      fullName
    }
    parent {
      fullName
      mobile {
        countryCode
        number
      }
      email
      salutation
      linkedWhatsappAccount {
        number {
          countryCode
          number
        }
      }
    }
  }
}
    `;
export const GetReminder = gql`
    query GetReminder($id: ObjectId!) {
  reminder(id: $id) {
    ...BasicReminderFields
    receiver {
      ...BasicParentFields
    }
    sender
    template {
      ...BasicTemplateFields
    }
  }
}
    ${BasicReminderFields}
${BasicParentFields}
${BasicTemplateFields}`;
export const GetRegistrationForReminder = gql`
    query getRegistrationForReminder($id: ObjectId!) {
  registration(id: $id) {
    id: _id
    type
    status
    invoices {
      ...InvoiceItemFields
    }
    student {
      primaryParent {
        id: _id
      }
    }
    class {
      type
    }
  }
}
    ${InvoiceItemFields}`;
export const GetReminderArrangement = gql`
    query getReminderArrangement($id: ObjectId!) {
  arrangement(id: $id) {
    type
    invoice {
      ...InvoiceItemFields
    }
    student {
      primaryParent {
        id: _id
      }
    }
  }
}
    ${InvoiceItemFields}`;
export const GetSendReminderInvoice = gql`
    query getSendReminderInvoice($id: ObjectId!) {
  invoice(id: $id) {
    ...InvoiceItemFields
    destination {
      id: _id
      type
      status
      registration {
        id: _id
        type
      }
      arrangements {
        id: _id
        type
        status
      }
    }
  }
}
    ${InvoiceItemFields}`;
export const GetSendPaymentAdviceInvoices = gql`
    query getSendPaymentAdviceInvoices($filterInput: InvoiceFilterInput, $pageInfo: PageInfo) {
  invoices(filterInput: $filterInput, pageInfo: $pageInfo) {
    items {
      ...InvoiceItemFields
      destination {
        id: _id
        type
        status
        registration {
          id: _id
          type
        }
        arrangements {
          id: _id
          type
          status
        }
      }
    }
  }
}
    ${InvoiceItemFields}`;
export const ExportReminderDocx = gql`
    query ExportReminderDocx($input: SendReminderInput!) {
  exportReminderDocx(input: $input)
}
    `;
export const BulkExportReminderDocx = gql`
    query BulkExportReminderDocx($input: BulkSendReminderInput!) {
  bulkExportReminderDocx(input: $input)
}
    `;
export const GetInvoiceDetailData = gql`
    query GetInvoiceDetailData($invoiceId: ObjectId!) {
  invoice(id: $invoiceId) {
    ...BasicInvoiceFields
    billedTo {
      id: _id
      fullName
      email
      mobile {
        countryCode
        number
      }
    }
    billedFor {
      id: _id
      fullName
      userId
      alternateUserId
      primarySchool {
        name
      }
    }
    destination {
      type
    }
  }
}
    ${BasicInvoiceFields}`;
export const GetInvoiceDueDateData = gql`
    query GetInvoiceDueDateData($invoiceId: ObjectId!) {
  invoice(id: $invoiceId) {
    id: _id
    dueDate
  }
}
    `;
export const GetReminderFormStudent = gql`
    query GetReminderFormStudent($id: ObjectId!) {
  student(id: $id) {
    id: _id
    primaryParent {
      ...ReminderParentFields
    }
    parents {
      ...ReminderParentFields
    }
    enrollmentMatters {
      id: _id
      fullName
      salutation
      alias
      email
    }
  }
}
    ${ReminderParentFields}`;
export const GetReminderFormParent = gql`
    query GetReminderFormParent($id: ObjectId!) {
  parent(id: $id) {
    ...ReminderParentFields
    relatedParents {
      ...ReminderParentFields
    }
  }
}
    ${ReminderParentFields}`;
export const GetSearchStudentModalData = gql`
    query GetSearchStudentModalData($search: String, $pageInfo: PageInfo, $filterInput: StudentFilterInput) {
  students(search: $search, filterInput: $filterInput, pageInfo: $pageInfo, sortInfo: {field: "fullName", direction: Asc}) @connection(key: "GetSearchStudentModalData", filter: ["search", "sortInfo"]) {
    items {
      ...StudentCellProfile
    }
    pageIndex
    pageSize
    total
  }
}
    ${StudentCellProfile}`;
export const GetSearchScheduleStudentModalData = gql`
    query GetSearchScheduleStudentModalData($search: String, $pageInfo: PageInfo, $filterInput: StudentFilterInput, $attendanceFilterInput: StudentAttendanceFilterInput) {
  students(search: $search, filterInput: $filterInput, pageInfo: $pageInfo, sortInfo: {field: "fullName", direction: Asc}) @connection(key: "GetSearchStudentModalData", filter: ["search", "sortInfo"]) {
    items {
      ...StudentCellProfile
      attendances(filterInput: $attendanceFilterInput) {
        id: _id
        type
        status
        linkArrangement {
          toClass {
            name
          }
        }
        lesson {
          id: _id
        }
      }
    }
    pageIndex
    pageSize
    total
  }
}
    ${StudentCellProfile}`;
export const WhatsappAccountStatusChanged = gql`
    subscription WhatsappAccountStatusChanged {
  whatsappAccountStatusChanged {
    ...WhatsappAccountInfo
  }
}
    ${WhatsappAccountInfo}`;
export const WhatsappMessageSendFailed = gql`
    subscription WhatsappMessageSendFailed {
  whatsappMessageSendFailed {
    ...WhatsappMessageInfo
  }
}
    ${WhatsappMessageInfo}`;
export const WhatsappAccountLoginFailed = gql`
    subscription WhatsappAccountLoginFailed {
  whatsappAccountLoginFailed {
    ...WhatsappAccountInfo
  }
}
    ${WhatsappAccountInfo}`;
export const GetRemarks = gql`
    query getRemarks($filterInput: RemarkFilterInput, $pageInfo: PageInfo, $search: String, $sortInfo: SortInfo) {
  remarks(filterInput: $filterInput, pageInfo: $pageInfo, search: $search, sortInfo: $sortInfo) {
    items {
      ...RemarkFields
    }
  }
}
    ${RemarkFields}`;
export const GetAttendanceRemarks = gql`
    query getAttendanceRemarks($id: ObjectId!, $includesStudentRemarks: Boolean, $showArchived: Boolean) {
  attendanceRemarks(id: $id, includesStudentRemarks: $includesStudentRemarks, showArchived: $showArchived) {
    ...RemarkFields
  }
}
    ${RemarkFields}`;
export const CreateRemark = gql`
    mutation createRemark($input: CreateRemarkInput!, $attendanceId: ObjectId) {
  createRemark(input: $input) {
    ...RemarkFields
    attendance(attendanceId: $attendanceId) {
      id: _id
      remarks(includesStudentRemarks: true) {
        ...RemarkFields
      }
    }
  }
}
    ${RemarkFields}`;
export const UpdateRemark = gql`
    mutation updateRemark($input: UpdateRemarkInput!, $attendanceId: ObjectId) {
  updateRemark(input: $input) {
    ...RemarkFields
    attendance(attendanceId: $attendanceId) {
      id: _id
      remarks(includesStudentRemarks: true) {
        ...RemarkFields
      }
    }
  }
}
    ${RemarkFields}`;
export const DeleteRemark = gql`
    mutation deleteRemark($input: IdInput!, $attendanceId: ObjectId) {
  deleteRemark(input: $input) {
    ...RemarkFields
    attendance(attendanceId: $attendanceId) {
      id: _id
      remarks(includesStudentRemarks: true) {
        ...RemarkFields
      }
    }
  }
}
    ${RemarkFields}`;
export const ChangeRemarkArchive = gql`
    mutation changeRemarkArchive($id: ObjectId!, $isArchived: Boolean!, $attendanceId: ObjectId) {
  changeRemarkArchive(id: $id, isArchived: $isArchived) {
    ...RemarkFields
    attendance(attendanceId: $attendanceId) {
      id: _id
      remarks(includesStudentRemarks: true) {
        ...RemarkFields
      }
    }
  }
}
    ${RemarkFields}`;
export const UploadFile = gql`
    mutation UploadFile($file: Upload!, $path: UploadFilePath!) {
  uploadFile(file: $file, path: $path) {
    remoteUrl
    url
  }
}
    `;
export const Promotions = gql`
    query promotions {
  promotions {
    ...PromotionFields
  }
}
    ${PromotionFields}`;
export const QueryStudentsInPromotion = gql`
    query queryStudentsInPromotion($search: String, $filterInput: StudentFilterInput, $sortInfo: SortInfo, $pageInfo: PageInfo) {
  students(search: $search, filterInput: $filterInput, sortInfo: $sortInfo, pageInfo: $pageInfo) @connection(key: "students", filter: ["search", "filterInput", "sortInfo"]) {
    items {
      ...StudentFieldsInPromotion
    }
    pageIndex
    pageSize
    total
  }
}
    ${StudentFieldsInPromotion}`;