import { useConfirmModal } from "@/components/common/ConfirmModal/NewConfirmModal";
import { useCheckTaxRateForCurrentYear } from "@/hooks/useCheckTaxRateForCurrentYear";
import formatErrorMessage from "@/utils/formatErrorMessage";
import { message } from "antd";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useGenerateNextInvoice, useQueryInvoice } from "../FinanceHooks";
import { InvoiceDetailType } from "../Modules/InvoiceDetail/InvoiceDetailTypes";

export const useGenerateNextInvoiceHandler = ({
  callback,
  invoiceId,
}: {
  callback?: (label?: string) => void;
  invoiceId: string;
}) => {
  const { modal, setModalProps, show, close } = useConfirmModal();
  const [generateNextInvoice, { loading: generateNextInvoiceLoading }] =
    useGenerateNextInvoice();

  const { refetch: refetchInvoice } = useQueryInvoice(invoiceId);

  const { handleCheckTaxRate } = useCheckTaxRateForCurrentYear();

  const history = useHistory();

  const checkIfGenerateNextInvoiceForOtherParent = (
    invoice?: InvoiceDetailType
  ) => {
    const currentPrimaryParentId = invoice?.billedTo?.id;
    const nextBilledToParentId = invoice?.nextInvoice?.billedTo?.id;

    return (
      currentPrimaryParentId &&
      nextBilledToParentId &&
      currentPrimaryParentId !== nextBilledToParentId
    );
  };
  const handleGenerateNextInvoice = async (id: string): Promise<void> => {
    try {
      await generateNextInvoice({
        variables: { id },
      });
      callback?.();
      close();
      message.success("Invoice Generated Successfully!");
    } catch (e) {
      message.error(formatErrorMessage(e));
    }
  };

  const handleGenerateNextConfirm = async (
    invoice?: InvoiceDetailType
  ): Promise<void> => {
    if (!handleCheckTaxRate()) {
      return;
    }
    if (!invoice) {
      const data = await refetchInvoice();
      invoice = data?.data?.invoice;
    }
    const isGenerateNextForOtherParent =
      checkIfGenerateNextInvoiceForOtherParent(invoice);

    if (isGenerateNextForOtherParent) {
      setModalProps({
        onConfirm: () => {
          history.push(
            `/finance/statement/${invoice?.nextInvoice?.billedTo?.id}/invoice/${invoice.id}`
          );
          close();
        },
        title: "Generate Next Billing Period Invoice",
        contentDesc: `The next billing period invoice ${invoice?.nextInvoice?.referenceNumber} for this student has already been generated and is available under the update primary parent’s statement of account. `,
        onClose: close,
        okText: "View",
        showCancel: false,
      });
      show();
      return;
    }
    setModalProps({
      onConfirm: () => handleGenerateNextInvoice(invoice.id),
      title: "Confirmation",
      contentDesc: "Are you sure you want to proceed?",
      onClose: close,
      confirmLoading: generateNextInvoiceLoading,
      okText: "Yes",
      showCancel: true,
    });
    show();
  };

  useEffect(() => {
    setModalProps({
      confirmLoading: generateNextInvoiceLoading,
    });
  }, [generateNextInvoiceLoading]);

  return {
    modal,
    generateNextInvoice: handleGenerateNextConfirm,
  };
};
