import {
  BulkSendReminderInput,
  SendReminderInput,
  TaskType,
  TemplateUsageType,
} from "@/graphql";
import formatErrorMessage from "@/utils/formatErrorMessage";
import { Form, message } from "@thepiquelab/archus-components-web";
import { cloneDeep } from "lodash";
import { useMemo } from "react";
import { useBulkSendReminder } from ".";
import { usePreviewMessageModal } from "../PreviewMessage/PreviewMessageModal";
import TaskReminder from "../SendReminder/Reminder/TaskReminder";
import { ReminderHookType } from "../types";

export const useSendTaskReminder = (args: {
  registrationIds: string[];
  taskIds: string[];
  attendanceIds: string[];
  invoiceIds: string[];
  taskType: TaskType;
  enrollmentIds: string[];
  callback?: () => Promise<void> | void;
}): ReminderHookType => {
  const {
    callback,
    registrationIds,
    taskIds,
    attendanceIds,
    invoiceIds,
    taskType,
    enrollmentIds,
  } = args;
  const [form] = Form.useForm();
  const {
    bulkSendReminder,
    loading: bulkSendLoading,
    modal,
  } = useBulkSendReminder(callback);

  const { setPreviewMessageModalProps, previewMessageModal } =
    usePreviewMessageModal();

  const getInputParams = (
    input: BulkSendReminderInput
  ): BulkSendReminderInput => {
    const result = cloneDeep(input);
    if (registrationIds) {
      result.registrationIds = registrationIds;
    }
    if (taskIds) {
      result.taskIds = taskIds;
    }
    if (attendanceIds) {
      result.attendanceIds = attendanceIds;
    }
    if (invoiceIds) {
      result.invoiceIds = invoiceIds;
    }
    if (enrollmentIds) {
      result.enrollmentIds = enrollmentIds;
    }

    return result;
  };

  const handleSendReminder = async (
    input: BulkSendReminderInput
  ): Promise<void> => {
    try {
      if (!input.types.length) {
        return;
      }
      const inputWithIds = getInputParams(input);
      await bulkSendReminder({
        input: {
          ...inputWithIds,
        },
      });
    } catch (error) {
      message.error(formatErrorMessage(error));
    }
  };

  const handleSubmit = async (input: BulkSendReminderInput): Promise<void> => {
    try {
      await handleSendReminder(input);
    } catch (error) {
      message.error(formatErrorMessage(error));
    }
  };

  const usageType = useMemo(() => {
    if (taskType === TaskType.FollowUpReservation) {
      return TemplateUsageType.FollowUpReservation;
    }
    if (taskType === TaskType.FollowUpTrial) {
      return TemplateUsageType.FollowUpTrial;
    }
    if (taskType === TaskType.FollowUpAbsent) {
      return TemplateUsageType.FollowUpAbsent;
    }
    if (taskType === TaskType.FollowUpPayment) {
      return TemplateUsageType.FollowUpPayment;
    }
    if (taskType === TaskType.FollowUpReplacementLessonOffer) {
      return TemplateUsageType.FollowUpReplacementLessonOffer;
    }
    if (taskType === TaskType.ReminderAdditionalLesson) {
      return TemplateUsageType.ReminderAdditionalLesson;
    }
    if (taskType === TaskType.ReminderTrialLesson) {
      return TemplateUsageType.ReminderTrialLesson;
    }
    if (taskType === TaskType.ReminderLesson) {
      return TemplateUsageType.ReminderLesson;
    }
    if (taskType === TaskType.ReminderChangeClass) {
      return TemplateUsageType.ReminderTeacherOrVenueUpdate;
    }
    if (taskType === TaskType.ReminderWorkshopReplacementLesson) {
      return TemplateUsageType.ReminderWorkshopReplacementLesson;
    }
    if (taskType === TaskType.ReminderAdditionalLessonOffer) {
      return TemplateUsageType.ReminderAdditionalLessonOffer;
    }
    if (taskType === TaskType.ReminderLessonUnderHoliday) {
      return TemplateUsageType.ReminderLessonUnderHoliday;
    }

    if (taskType === TaskType.FollowUpTermBilling) {
      return TemplateUsageType.FollowUpTermBilling;
    }

    if (taskType === TaskType.FollowUpTrialRequest) {
      return TemplateUsageType.FollowUpTrialRequest;
    }

    return TemplateUsageType.FollowUpPayment;
  }, [taskType]);

  const handlePreview = async (
    input: SendReminderInput | BulkSendReminderInput
  ): Promise<void> => {
    const inputWithIds = getInputParams(input);

    setPreviewMessageModalProps({
      input: inputWithIds,
      isBulk: true,
    });
  };

  return {
    reminder: (
      <>
        <TaskReminder
          form={form}
          onSubmit={handleSubmit}
          usageType={usageType}
          onPreview={handlePreview}
          isBulk
          partialSendReminderInput={{}}
        />
        {previewMessageModal}
        {modal}
      </>
    ),
    loading: bulkSendLoading,
    submit: form.submit,
  };
};
