import { message } from "@thepiquelab/archus-components-web";
import { useEffect, useState } from "react";
import {
  useLazyQueryInvoiceExport,
  useLazyQueryReceiptExport,
  useLazyQueryRefundExport,
} from ".";
// eslint-disable-next-line import/no-unresolved
import formatErrorMessage from "@/utils/formatErrorMessage";
import printJS from "print-js";

const downloadPdf = (url: string) => {
  window.open(url, "about:blank");
};

const base64ToBuffer = (base64String: string) => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    // eslint-disable-next-line no-useless-escape
    ?.replace(/\-/g, "+")
    ?.replace(/_/g, "/");
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

const base64toBlob = (base64: string, type = "") => {
  const u8arr = base64ToBuffer(base64);
  return new Blob([u8arr], { type });
};
const windowPrintPdf = (url: string) => {
  const blob = base64toBlob(url);
  const newUrl = URL.createObjectURL(blob);
  printJS(newUrl);
};

const downloadOrPrintPdf = ({
  isPrint,
  url,
  key,
}: {
  isPrint: boolean;
  url: string;
  key: string;
}) => {
  if (isPrint && !url.startsWith("https")) {
    windowPrintPdf(url);
  } else if (url.startsWith("https")) {
    downloadPdf(url);
  }
  message.destroy(key);
};

const checkIsLoading = (loading: boolean) => {
  if (loading) {
    message.error("Export or Print has been interrupted");
  }
};

const useExportInvoicePdf = () => {
  const key = "invoice";
  const [queryInvoiceExport, { data, loading, error }] =
    useLazyQueryInvoiceExport();

  const [isPrint, setIsPrint] = useState(false);

  useEffect(() => {
    if (error) {
      message.error({ content: formatErrorMessage(error), key });
    }
    if (data?.invoiceExport?.url) {
      downloadOrPrintPdf({
        url: data?.invoiceExport?.url,
        key,
        isPrint,
      });
    }
  }, [data, error, isPrint]);

  const printPdf = (id: string) => {
    checkIsLoading(loading);
    setIsPrint(true);

    queryInvoiceExport({ variables: { id, printBase64: true } });
  };
  const exportPdf = (id: string) => {
    checkIsLoading(loading);
    setIsPrint(false);

    queryInvoiceExport({ variables: { id } });
  };

  useEffect(() => {
    if (loading) {
      message.loading({
        content: isPrint ? "Preparing to print" : "Exporting",
        key,
        duration: 0,
      });
    }
  }, [loading, isPrint]);

  return { printPdf, exportPdf, loading };
};

const useExportReceiptPdf = () => {
  const key = "receipt";
  const [queryReceiptExport, { data, loading, error }] =
    useLazyQueryReceiptExport();

  const [isPrint, setIsPrint] = useState(false);

  useEffect(() => {
    if (error) {
      message.error({ content: formatErrorMessage(error), key });
    }
    if (data?.receiptExport?.url) {
      downloadOrPrintPdf({
        url: data?.receiptExport?.url,
        key,
        isPrint,
      });
    }
  }, [data, error, isPrint]);

  const printPdf = (id: string) => {
    checkIsLoading(loading);
    setIsPrint(true);

    queryReceiptExport({ variables: { id, printBase64: true } });
  };
  const exportPdf = (id: string) => {
    checkIsLoading(loading);
    setIsPrint(false);

    queryReceiptExport({ variables: { id } });
  };

  useEffect(() => {
    if (loading) {
      message.loading({
        content: isPrint ? "Preparing to print" : "Exporting",
        key,
        duration: 0,
      });
    }
  }, [loading, isPrint]);

  return { printPdf, exportPdf, loading };
};

const useExportRefundPdf = () => {
  const key = "refund";
  const [queryRefundExport, { data, loading, error }] =
    useLazyQueryRefundExport();
  const [isPrint, setIsPrint] = useState(false);

  useEffect(() => {
    if (error) {
      message.error({ content: formatErrorMessage(error), key });
    }
    if (data?.refundExport?.url) {
      downloadOrPrintPdf({
        url: data?.refundExport?.url,
        key,
        isPrint,
      });
    }
  }, [data, error, isPrint]);

  const printPdf = (id: string) => {
    checkIsLoading(loading);
    setIsPrint(true);

    queryRefundExport({ variables: { id, printBase64: true } });
  };
  const exportPdf = (id: string) => {
    checkIsLoading(loading);
    setIsPrint(false);

    queryRefundExport({ variables: { id } });
  };

  useEffect(() => {
    if (loading) {
      message.loading({
        content: isPrint ? "Preparing to print" : "Exporting",
        key,
        duration: 0,
      });
    }
  }, [loading, isPrint]);

  return { printPdf, exportPdf, loading };
};

export { useExportInvoicePdf, useExportReceiptPdf, useExportRefundPdf };
