import OperationalTaskDetailSkeleton from "@/components/common/Skeleton/OperationalTaskDetailSkeleton";
import { Modal } from "antd";
import { useEffect } from "react";
import Activity from "../Activity/Activity";
import ChecklistDetail from "../Checklist/ChecklistDetail";
import TaskRemarks from "../Remarks/TaskRemarks";
import { useQueryTask } from "../hooks";
import TaskDetail from "./TaskDetail";

interface OperationalTaskDetailModalProps {
  taskId: string;
  title?: string;
  callback?: () => Promise<void> | void;
  onClose: () => void;
}

const OperationalTaskDetailModal: React.FC<OperationalTaskDetailModalProps> = (
  props
) => {
  const { title = "Task Detail", taskId, callback, onClose } = props;
  const [
    queryTask,
    { refetch: refetchTask, data: taskData, loading: refetchTaskLoading },
  ] = useQueryTask({
    skip: true,
  });

  useEffect(() => {
    if (taskId) {
      queryTask({ variables: { id: taskId } });
    }
  }, [taskId]);
  return (
    <>
      <Modal
        title={title}
        visible={!!taskId}
        width={1000}
        onCancel={() => {
          onClose?.();
        }}
        footer={null}
        destroyOnClose
      >
        {refetchTaskLoading ? (
          <OperationalTaskDetailSkeleton />
        ) : (
          <>
            <TaskDetail
              taskDetail={taskData?.task}
              refetchTaskLoading={refetchTaskLoading}
              refetchTask={refetchTask}
              callback={callback}
              onClose={onClose}
            />
            <div className="mb-6">
              <ChecklistDetail task={taskData?.task} />
            </div>
            <TaskRemarks
              task={taskData?.task}
              callback={() => {
                callback();
                refetchTask();
              }}
            />
            <Activity
              sourceId={taskData?.task?.id}
              cacheKey={taskData?.task?.lastModifiedDateTime}
            />
          </>
        )}
      </Modal>
    </>
  );
};

export default OperationalTaskDetailModal;
