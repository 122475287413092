import { useCurrentSystemUser } from "@/components/users/UserHooks";
import { UserOutlined } from "@ant-design/icons";
import { Avatar, Col, Dropdown, Row } from "@thepiquelab/archus-components-web";
import { formatPhoneNumber } from "@thepiquelab/archus-library";
import React, { createContext, useMemo, useState } from "react";
import packageJson from "../../../../package.json";
import { ReactComponent as IconSetting } from "../../../assets/images/IconSetting.svg";
import { useAuth0 } from "../../../Auth0Wrapper";
import Loading from "../../common/Loading";
import MenuBar from "../MenuBar/MenuBar";
import MenuBarDropdown from "../MenuBar/MenuBarDropdown";

interface Props {
  collapse: boolean;
}

export const CurrentAdminContext = createContext<{
  user: {
    fullName?: string;
    initials?: string;
    email?: string;
    userID?: string;
    emailAlerts?: boolean;
    avatar?: string;
    adminId?: string;
    mobile?: string;
    department?: string;
  };
}>(null);

const UserContent: React.FC<Props> = (props) => {
  const { dbUser, logout } = useAuth0();
  const { data, loading } = useCurrentSystemUser();
  const { collapse } = props;
  const [dropdownVisible, setDropdownVisible] = useState(false);

  useMemo(() => {
    if (dbUser) {
      const { sub } = dbUser;
      if (!sub) {
        logout();
      }
    }
  }, [dbUser]);

  useMemo(() => {
    if (data && !data.currentSystemUser) {
      logout();
    }
  }, [data]);

  const handleDropdown = (visible: boolean): void => {
    setDropdownVisible(visible);
  };

  if (loading) {
    return <Loading height="10rem" />;
  }

  const admin = data?.currentSystemUser;
  return (
    <CurrentAdminContext.Provider
      value={{
        user: {
          fullName: admin?.fullName || admin?.alias,
          initials: admin?.initials,
          email: admin?.email,
          userID: admin?.userId,
          avatar: admin?.avatarUrl,
          adminId: admin?.id,
          mobile: formatPhoneNumber(admin?.mobile),
          department: admin?.department?.name,
        },
      }}
    >
      {!collapse && (
        <div className="px-5 mt-6 flex justify-between">
          <span>Version {packageJson.version}</span>
          <MenuBar collapse={collapse}>
            {loading ? (
              <Loading />
            ) : (
              <Dropdown
                onVisibleChange={handleDropdown}
                visible={dropdownVisible}
                overlay={
                  <MenuBarDropdown onDropdownVisible={setDropdownVisible} />
                }
                arrow={{ pointAtCenter: true }}
              >
                <IconSetting className="cursor-pointer w-4 h-4" />
              </Dropdown>
            )}
          </MenuBar>
        </div>
      )}

      {collapse && (
        <div className="mt-4">
          <MenuBar collapse={collapse}>
            {loading ? (
              <Loading />
            ) : (
              <Dropdown
                onVisibleChange={handleDropdown}
                visible={dropdownVisible}
                overlay={
                  <MenuBarDropdown onDropdownVisible={setDropdownVisible} />
                }
                arrow={{ pointAtCenter: true }}
              >
                <IconSetting className="cursor-pointer w-6 h-6" />
              </Dropdown>
            )}
          </MenuBar>
        </div>
      )}
      <div
        className={`${
          collapse ? "flex flex-row items-center justify-center" : "px-5"
        } mt-4`}
      >
        {data?.currentSystemUser?.avatarUrl ? (
          <Avatar
            size={60}
            shape={"circle"}
            style={{ boxShadow: "0px 4px 14px rgba(79, 83, 123, 0.21)" }}
            src={data?.currentSystemUser?.avatarUrl}
          />
        ) : (
          <Avatar
            size={60}
            shape={"circle"}
            style={{ boxShadow: "0px 4px 14px rgba(79, 83, 123, 0.21)" }}
            className="flex flex-row items-center justify-center"
            icon={<UserOutlined />}
          />
        )}

        {!collapse && (
          <Row
            justify="space-between"
            className="items-center justify-start mt-3 mb-4"
          >
            <Col span={20} className="font_semibold text-lg leading-tight">
              {`Hello, ${admin?.fullName || admin?.alias || ""}`}
            </Col>
          </Row>
        )}
      </div>
    </CurrentAdminContext.Provider>
  );
};

export default UserContent;
