import { ReminderType, TemplateUsageType } from "@/graphql";
import formatLabel from "@/utils/formatLabel";
import { Col, Row } from "@thepiquelab/archus-components-web";
import { ControlType } from "braft-editor";
import { intersection } from "lodash";
import { FC } from "react";
import { TemplateVariableConfig } from "./TemplateVariableConfig";
import "./index.scss";
import { controls, removeBraftEditorClassName } from "./tools";

interface Props {
  usageType?: TemplateUsageType;
  onClick?: (item: string) => void;
}

const VariableName: FC<Props> = ({ onClick, usageType }) => (
  <div className="max-h-64">
    <Row className="w-80 bg-white flex-col text-sm variable_name_row">
      {TemplateVariableConfig?.[usageType]?.map((item, i) => (
        <Col
          key={String(i)}
          className="px-4 py-3 variable_name_col"
          onClick={(e) => {
            e.preventDefault();
            if (item?.isHelper) {
              onClick?.(String(item.val));
            } else {
              onClick?.(`{{${String(item.text)}}}`);
            }
          }}
        >
          {item?.isHelper ? item?.text : formatLabel(item.text)}
        </Col>
      ))}
    </Row>
  </div>
);

export const BraftEditorControls = ({
  usageType,
  type,
  key,
  callback,
}: {
  type: ReminderType;
  key: string;
  usageType?: TemplateUsageType;
  callback?: (name: string) => void;
}): ControlType[] => [
  ...(type === ReminderType.Email
    ? controls
    : intersection(controls, ["undo", "redo", "emoji"])),
  {
    key,
    type: "dropdown",
    text: "Add variables",
    autoHide: true,
    className: key,
    component: (
      <VariableName
        usageType={usageType}
        onClick={(name) => {
          callback?.(name);
          removeBraftEditorClassName(key);
        }}
      />
    ),
  },
];

export default VariableName;
