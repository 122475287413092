import { Modal } from "@thepiquelab/archus-components-web";
import { Modal as AntdModal } from "antd";
import React, { useState } from "react";
import ModalButtonGroup from "../ModalButtonGroup";

type CustomModalArgsTypes = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
};
export interface ConfirmModalProps {
  visible: boolean;
  confirmLoading?: boolean;
  destroyOnClose?: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
  setCustomModal?: (modalProps: CustomModalArgsTypes) => React.ReactNode;
  title?: string;
  contentDesc?: React.ReactNode;
  okText?: string;
  noText?: string;
  showCancel?: boolean;
}

const NewConfirmModal: React.FC<ConfirmModalProps> = ({
  visible,
  onConfirm,
  onClose,
  title = "Confirmation",
  contentDesc = "Are you sure you want to proceed?",
  confirmLoading,
  destroyOnClose = false,
  okText = "Yes",
  noText = "No",
  showCancel = true,
}) => (
  <Modal
    style={{ width: 520 }}
    visible={visible}
    onClose={onClose}
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    zIndex={1001}
    destroyOnClose={destroyOnClose}
  >
    <Modal.Header title={title} />
    <Modal.Content>{contentDesc}</Modal.Content>
    <Modal.Footer>
      <ModalButtonGroup
        okText={okText}
        cancelText={noText}
        onOK={onConfirm}
        onCancel={onClose}
        showCancel={showCancel}
        confirmLoading={confirmLoading}
      />
    </Modal.Footer>
  </Modal>
);
/**
 * @return {
 *   modal: ReactNode, // - this must place in you view
 *   setModalProps(props: Omit<ConfirmModalProps, "visible" | "onClose">):void,
 *   show():void,
 *   close():void
 * }
 */
export const useConfirmModal = (
  modalProps: Omit<ConfirmModalProps, "visible"> = {}
): {
  modal: any;
  setModalProps: (newProps: Omit<ConfirmModalProps, "visible">) => void;
  close: () => void;
  show: () => void;
  visible: boolean;
} => {
  const [visible, setVisible] = useState(false);

  const close = (): void => setVisible(false);
  const show = (): void => setVisible(true);

  const [props, setProps] = useState(modalProps);

  const setModalProps = (
    newProps: Omit<ConfirmModalProps, "visible">
  ): void => {
    setProps({ ...props, ...newProps });
  };

  return {
    modal: props.setCustomModal ? (
      props.setCustomModal({
        visible,
        setVisible,
      })
    ) : (
      <NewConfirmModal
        visible={visible}
        onClose={modalProps?.onClose || close}
        {...props}
      />
    ),

    setModalProps,
    close,
    show,
    visible,
  };
};

export const onOpenConfirmModal = (props: {
  title?: string;
  contentDesc?: React.ReactNode;
  onClose?: () => void;
  onConfirm?: () => void;
}): void => {
  const {
    title = "Confirmation",
    contentDesc = "Are you sure you want to proceed?",
    onClose,
    onConfirm,
  } = props;

  const confirmModal = AntdModal.confirm({
    title: (
      <div className="check-trial-in-course-modal-header c-text-sm p-6 border rounded-lg font-semibold">
        {title}
      </div>
    ),
    width: 520,
    icon: <></>,
    // closeIcon: <IconClose />,
    closable: true,
    wrapClassName: "check-trial-in-course-modal",
    content: <div className="flex flex-col gap-6 px-6 pt-6">{contentDesc}</div>,
    onCancel: () => {
      onClose?.();
      confirmModal.destroy();
    },
    onOk: () => {
      onConfirm?.();
      confirmModal.destroy();
    },
  });
};

export default NewConfirmModal;
