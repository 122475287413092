import { UserCustomiseColumn } from "@/graphql";
import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  CheckboxChangeEvent,
} from "@thepiquelab/archus-components-web";
import { Empty, Input } from "antd";
import React from "react";
import { DropResult } from "react-beautiful-dnd";
import { DraggableList } from "../DraggableList";
import { Modal } from "../Modal";
import "./DisplayEditor.scss";
import { DisplayEditorProps, LoadType } from "./types";

/**
 * Component used to select and order set of column names
 * that is rendered on a Modal component
 * 
 * @example Display Editor component with an array of items
 * ```
 * <DisplayEditor
    visible={isShowDisplayEditor}
    items={[
      {
        _id: 'ObjectID-1',
        columnName: 'name',
        columnShowName: 'Name',
        weight: 1
      },
      {
        _id: 'ObjectID-2',
        columnName: 'color',
        columnShowName: 'Color',
        weight: 2
      }
    ]}
    onCancel={hideDisplayEditorModal}
    onApply={handleDisplayEditorApply}
    onReset={handleDisplayEditorReset}
    loadingState={{
      isLoading: false,
      type: null,
    }}
  />
 * ```
 *
 * @example Display Editor component with load state
 * ```
 * <DisplayEditor
    visible={isShowDisplayEditor}
    items={[
      {
        _id: 'ObjectID-1',
        columnName: 'name',
        columnShowName: 'Name',
        weight: 1
      },
      {
        _id: 'ObjectID-2',
        columnName: 'color',
        columnShowName: 'Color',
        weight: 2
      }
    ]}
    onCancel={hideDisplayEditorModal}
    onApply={handleDisplayEditorApply}
    onReset={handleDisplayEditorReset}
    loadingState={{
      isLoading: true,
      type: LoadType.SAVE, // can be "FETCH" and "RESET"
    }}
  />
 * ``` 
 */
const DisplayEditor: React.FC<DisplayEditorProps> = (props) => {
  const {
    items = [],
    visible,
    onCancel,
    onApply,
    onReset,
    loadingState,
  } = props;

  const [keyword, setKeyword] = React.useState("");
  const [checkedItems, setCheckedItems] = React.useState<UserCustomiseColumn[]>(
    []
  );
  const [savePreset, setSavePreset] = React.useState(true);

  React.useEffect(() => {
    if (!visible) {
      setKeyword("");
      setCheckedItems(
        items
          .filter((item) => item.isVisible)
          .sort((itemA, itemB) => itemA.weight - itemB.weight)
      );
    }
  }, [items, visible]);

  React.useEffect(() => {
    if (items) {
      setCheckedItems(
        items
          .filter((item) => item.isVisible)
          .sort((itemA, itemB) => itemA.weight - itemB.weight)
      );
    }
  }, [items]);

  const filteredItems = React.useMemo(
    () =>
      items.filter((item) =>
        keyword
          ? item.columnShowName
              .toLowerCase()
              .replace(/\s/g, "")
              .includes(keyword.toLowerCase())
          : true
      ),
    [items, keyword]
  );

  /** Function that sets order of items after move event */
  const moveCard = React.useCallback(
    (sourceIndex: number, destinationIndex: number) => {
      const visibleItems = checkedItems.filter(
        (checkedItem) => checkedItem.isVisible
      );
      const realSourceIndex = checkedItems.findIndex(
        (checkedItem) =>
          checkedItem.columnName === visibleItems[sourceIndex].columnName
      );
      const realDestinationIndex = checkedItems.findIndex(
        (checkedItem) =>
          checkedItem.columnName === visibleItems[destinationIndex].columnName
      );
      const item = checkedItems[realSourceIndex];

      const newCheckedItems = [...checkedItems];
      newCheckedItems.splice(realSourceIndex, 1);
      newCheckedItems.splice(realDestinationIndex, 0, item);

      setCheckedItems(newCheckedItems);
    },
    [checkedItems]
  );

  /** Function callback to be invoked on dragend of cards */
  const onDragEnd = (result: DropResult): void => {
    const { source, destination } = result;
    if (!destination) return;
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }
    moveCard(source.index, destination.index);
  };

  /** Function that adds item to the last order, and sets to isVisible to true */
  const handleCheck = React.useCallback(
    (event: CheckboxChangeEvent) => {
      const { value, checked } = event.target;
      const index = checkedItems.findIndex((i) => i.columnName === value);
      if (index >= 0) {
        const newCheckedItems = [...checkedItems];
        newCheckedItems.splice(index, 1);
        newCheckedItems.push({
          ...checkedItems[index],
          isVisible: checked,
        });
        setCheckedItems(newCheckedItems);
        return;
      }
      const itemToCheck = items.find((item) => item.columnName === value);
      if (itemToCheck) {
        const newCheckedItems = [...checkedItems];
        newCheckedItems.push({
          ...itemToCheck,
          isVisible: checked,
        });
        setCheckedItems(newCheckedItems);
      }
    },
    [items, checkedItems]
  );

  /** Function that removes item to list of visible items */
  const handleRemove = React.useCallback(
    (columnName: string) => {
      setCheckedItems(
        checkedItems.map((checkedItem) => {
          if (checkedItem.columnName === columnName) {
            return { ...checkedItem, isVisible: false };
          }
          return checkedItem;
        })
      );
    },
    [checkedItems]
  );

  /** Function that sets items to not be visible if they are not required */
  const handleClear = React.useCallback(() => {
    setCheckedItems(
      items.map((item) => ({
        ...item,
        isVisible: item.isRequired,
      }))
    );
  }, [items]);

  /** Function that regenerates weight and calls onApply prop callback */
  const handleApply = React.useCallback(() => {
    /** Regenerate weight */
    const result = checkedItems.map((checkedItem, weight) => ({
      ...checkedItem,
      weight,
    }));

    onApply(result, savePreset);
  }, [onApply, checkedItems, savePreset]);

  const onSavePresetChange = React.useCallback(
    (event: CheckboxChangeEvent) => {
      setSavePreset(event.target.checked);
    },
    [setSavePreset]
  );

  const count = React.useMemo(
    () =>
      checkedItems.filter(
        (checkedItem) => checkedItem.columnName && checkedItem.isVisible
      ).length,
    [checkedItems]
  );

  const modalFooter = React.useMemo(
    () => (
      <Modal.Footer
        className="flex-row-reverse justify-between"
        customButtons={
          <>
            <Button
              loading={
                loadingState.isLoading && loadingState.type === LoadType.RESET
              }
              className="text-base px-8"
              onClick={onReset}
              size="large"
            >
              Reset Default
            </Button>
            {count > 0 && (
              <Button
                className="text-base px-8"
                onClick={handleClear}
                size="large"
              >
                <span>Clear</span>
                <span className="ml-1 text-primary-blue">{count}</span>
              </Button>
            )}
            <Button
              loading={
                loadingState.isLoading && loadingState.type === LoadType.SAVE
              }
              className="text-base px-8"
              type="primary"
              onClick={handleApply}
              size="large"
            >
              Apply
            </Button>
          </>
        }
      >
        <Checkbox
          checked={savePreset}
          disabled={loadingState.isLoading}
          onChange={onSavePresetChange}
        >
          <span className="font-semibold">Save as preset</span>
        </Checkbox>
      </Modal.Footer>
    ),
    [
      count,
      loadingState,
      handleApply,
      handleClear,
      onReset,
      savePreset,
      onSavePresetChange,
    ]
  );

  return (
    <Modal
      className="column-customizer-modal"
      visible={visible}
      onCancel={onCancel}
      width="829px"
      title="Customise Columns"
      maskClosable={false}
      footer={modalFooter}
      bodyStyle={{ padding: "0" }}
    >
      <div className="flex items-stretch h-full">
        {/* left side */}
        <div className="px-6 py-4 w-1/2 flex-1 overflow-auto">
          {items.length > 0 && (
            <Input
              className="w-72"
              prefix={<SearchOutlined />}
              placeholder="Search"
              value={keyword}
              onChange={(event) => setKeyword(event.target.value)}
              allowClear
            />
          )}
          <div className="mt-8 flex flex-col">
            {filteredItems.length > 0 ? (
              filteredItems.map((item) => (
                <Checkbox
                  className="mb-4 truncate max-w-80"
                  key={item.columnName}
                  checked={checkedItems.some(
                    (checkedItem) =>
                      checkedItem.columnName === item.columnName &&
                      checkedItem.isVisible
                  )}
                  onChange={handleCheck}
                  value={item.columnName}
                  disabled={item.isRequired || loadingState.isLoading}
                >
                  {item.columnShowName}
                </Checkbox>
              ))
            ) : (
              <Empty description="No data" />
            )}
          </div>
        </div>
        {/* right side */}
        <div className="flex-1 bg-gray-200">
          <DraggableList
            render={(provided, _snapshot) => (
              <div
                className="overflow-auto px-5 py-6 h-full"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                <>
                  {checkedItems.length > 0 ? (
                    checkedItems
                      .filter((item) => item.isVisible)
                      .map((item, i) => (
                        <DraggableList.Item
                          key={item.columnName}
                          id={item.columnName}
                          content={item.columnShowName || item.columnName}
                          index={i}
                          onRemove={!item.isRequired ? handleRemove : undefined}
                        />
                      ))
                  ) : (
                    <Empty className="self-center" description="No data" />
                  )}
                  {provided.placeholder}
                </>
              </div>
            )}
            onDragEnd={onDragEnd}
          />
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(DisplayEditor);
