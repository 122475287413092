import { TableInCardSkeleton } from "@/components/common/Skeleton/TableSkeleton";
import {
  GetTaskLists,
  GetTaskListsQuery,
  GetTaskListsQueryVariables,
} from "@/graphql";
import { useLoadMore } from "@/hooks/useLoadMore";
import { PlusCircleFilled } from "@ant-design/icons";
import { Container } from "@thepiquelab/archus-components-web";
import { Button, Empty, Row, Spin } from "antd";
import { FC, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import ListCollapse from "./ListCollapse";
import OperationalTaskListModal, {
  ActionType,
} from "./OperationalTaskListModal";

interface OperationalTaskListProps {
  refreshCount?: () => Promise<void> | void;
}
const OperationalTaskList: FC<OperationalTaskListProps> = (props) => {
  const { refreshCount } = props;
  const [
    getTaskLists,
    { data: TaskLists, loading, refetch },
    { loadMore, hasMore },
  ] = useLoadMore<GetTaskListsQuery, GetTaskListsQueryVariables>(
    GetTaskLists,
    "taskLists",
    true,
    false,
    true,
    {
      fetchPolicy: "network-only",
    }
  );

  const [open, setOpen] = useState(false);

  useEffect(() => {
    getTaskLists({ variables: { filterInput: {} } });
  }, []);

  return (
    <Container className="h-full">
      <Container.Header>
        <Button
          type="primary"
          data-testid="data-header-add-button"
          onClick={() => {
            setOpen(true);
          }}
        >
          {<PlusCircleFilled className="mr-2" />}
          <span>Add List</span>
        </Button>
      </Container.Header>
      <Container.Content className="overflow-auto mt-6">
        {loading ? (
          <div className="flex flex-col gap-6">
            <TableInCardSkeleton active size={5} />
          </div>
        ) : (
          <>
            {TaskLists?.taskLists?.items?.length ? (
              <InfiniteScroll
                initialLoad={false}
                pageStart={0}
                loadMore={loadMore}
                hasMore={hasMore}
                loader={
                  <Row justify={"center"} key={0}>
                    <Spin />
                  </Row>
                }
                useWindow={false}
              >
                {TaskLists?.taskLists?.items?.map((item, index) => (
                  <ListCollapse
                    refetch={refetch}
                    key={`collapse-table-${item?.id}`}
                    taskList={item}
                    className={index > 0 ? "mt-6" : ""}
                    refreshCount={refreshCount}
                  />
                ))}
              </InfiniteScroll>
            ) : (
              <Empty className="mt-20" />
            )}
          </>
        )}
        <OperationalTaskListModal
          visible={open}
          actionType={ActionType.Add}
          refetch={refetch}
          onCancel={() => {
            setOpen(false);
          }}
        />
      </Container.Content>
    </Container>
  );
};

export default OperationalTaskList;
