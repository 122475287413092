import { RemarkLevelTypeMap } from "@/components/common/Profile/types";
import { SearchOutlined, WarningOutlined } from "@ant-design/icons";
import {
  Input,
  Row,
  Spin,
  Switch,
  Tooltip,
} from "@thepiquelab/archus-components-web";
import { debounce } from "lodash";
import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import {
  EnrollmentStatus,
  GetSearchStudentModalData,
  GetSearchStudentModalDataQuery,
  GetSearchStudentModalDataQueryVariables,
} from "../../../graphql";
import { useLoadMore } from "../../../hooks/useLoadMore";
import { StudentCell } from "../UserCell/StudentCell";
import SearchSkeleton from "./SearchSkeleton";

type DataItemType = GetSearchStudentModalDataQuery["students"]["items"][0];

interface SearchStudentListProps {
  action(student: DataItemType): React.ReactNode;
  showSwitch?: boolean;
}

const SearchStudentList: React.FC<SearchStudentListProps> = (props) => {
  const { action, showSwitch = false } = props;

  const [queryStudents, { data, loading }, { loadMore, hasMore }] = useLoadMore<
    GetSearchStudentModalDataQuery,
    GetSearchStudentModalDataQueryVariables
  >(GetSearchStudentModalData, "students", true, false, true, {
    fetchPolicy: "network-only",
  });

  const [filterInput, setFilterInput] = useState<{
    name?: string;
    enrollmentStatuses?: EnrollmentStatus[];
  }>(
    showSwitch
      ? { name: "", enrollmentStatuses: [EnrollmentStatus.Active] }
      : {}
  );

  const students = data?.students?.items || [];

  const searchStudents = (res: {
    name?: string;
    enrollmentStatuses?: EnrollmentStatus[];
  }): void => {
    setFilterInput({ ...filterInput, ...res });
    queryStudents({
      variables: { filterInput: { ...filterInput, ...res } },
    });
  };

  return (
    <div>
      <Input
        allowClear
        className="search-input bg-gray-400 rounded text-primary-navy hover:bg-white"
        onChange={debounce((e) => {
          searchStudents({ name: e.target.value });
        }, 300)}
        placeholder="Search"
        onPressEnter={debounce((e) => {
          searchStudents({ name: e.target.value });
        }, 100)}
        size={"large"}
        prefix={<SearchOutlined className="text-primary-navyLight text-5" />}
      />
      {showSwitch && (
        <div className="mt-6 mb-2">
          <Switch
            label="Active Student Only"
            onChange={(checked: boolean) => {
              searchStudents({
                enrollmentStatuses: checked
                  ? [EnrollmentStatus.Active]
                  : undefined,
              });
            }}
            defaultChecked={showSwitch}
          />
        </div>
      )}

      {loading ? (
        <div className="py-5">
          <SearchSkeleton row={8} />
        </div>
      ) : (
        <div
          className="mt-4 max-h-half overflow-y-auto"
          style={{
            display: !students?.length ? "none" : "block",
          }}
        >
          <InfiniteScroll
            hasMore={hasMore}
            loadMore={loadMore}
            useWindow={false}
            loader={
              <Row justify={"center"} key={0}>
                <Spin />
              </Row>
            }
          >
            <div className="space-y-2">
              {students.map((s) => (
                <div key={s.id} className="rounded flex justify-between">
                  <StudentCell
                    profile={s}
                    tag={
                      s.primaryParent?.remark ? (
                        <Tooltip title={s.primaryParent?.remark?.content}>
                          <WarningOutlined
                            className="w-4 h-4"
                            style={{
                              color:
                                RemarkLevelTypeMap[
                                  s.primaryParent?.remark?.levelType
                                ].color,
                            }}
                          />
                        </Tooltip>
                      ) : null
                    }
                  />
                  {action(s)}
                </div>
              ))}
            </div>
          </InfiniteScroll>
        </div>
      )}
    </div>
  );
};

export default SearchStudentList;
