import DashboardPage from "@/components/dashboard";
import SendTaskReminder from "@/components/dashboard/SendFollowUp";
import WaitingListOverview from "@/components/dashboard/WaitingListOverview";
import React from "react";
import ErrorBoundaryWithSentry from "../errors/ErrorBoundaryWithSentry";

export const Dashboard = (): React.ReactElement => (
  <ErrorBoundaryWithSentry>
    <DashboardPage />
  </ErrorBoundaryWithSentry>
);

export const DashboardSendFollowUp = (): React.ReactElement => (
  <ErrorBoundaryWithSentry>
    <SendTaskReminder />
  </ErrorBoundaryWithSentry>
);

export const DashboardWaitingListOverview = (): React.ReactElement => (
  <ErrorBoundaryWithSentry>
    <WaitingListOverview />
  </ErrorBoundaryWithSentry>
);
