import { Row, Skeleton } from "antd";
import FormSkeleton from "../common/FormSkeleton";

const PaymentRecordModalContentSkeleton = () => (
  <div className="flex-col gap-6 pl-6 pr-1 pt-6">
    <Row className="flex flex-row items-stretch justify-between mb-6">
      <div className=" flex flex-col items-center gap-6 h-full w-full">
        <FormSkeleton fieldNumber={10} showButtons={false} className="w-full" />
      </div>
    </Row>
    <div className="flex justify-end mb-8">
      <Skeleton.Button active />
    </div>

    <div className="flex gap-4 justify-between">
      <Skeleton.Input className="w-1/2 overflow-hidden" active />
      <Skeleton.Input className="w-1/2 overflow-hidden" active />
    </div>
  </div>
);

export default PaymentRecordModalContentSkeleton;
