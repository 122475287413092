import { TemplateUsageType } from "@/graphql";
import formatLabel from "@/utils/formatLabel";
import { FormInstance } from "@thepiquelab/archus-components-web";

export enum RichTextEditorType {
  "whatsappMessage" = "whatsappMessage",
  "emailMessage" = "emailMessage",
}
export const richTextEditorValidator = (
  name: RichTextEditorType,
  form: FormInstance
): Promise<void> => {
  if (form.getFieldValue(name)?.toHTML() !== `<p></p>`) {
    return Promise.resolve();
  }
  if (name === RichTextEditorType.whatsappMessage) {
    return Promise.reject(
      new Error(formatLabel("Please Input WhatsApp Message!"))
    );
  }
  return Promise.reject(new Error(formatLabel("Please Input Email Message!")));
};

export const getReminderTitle = (i: TemplateUsageType): string => {
  switch (i) {
    case TemplateUsageType.ConfirmationAdditionalLesson:
    case TemplateUsageType.ConfirmationRegistration:
    case TemplateUsageType.ConfirmationReplacementLesson:
    case TemplateUsageType.ConfirmationReservation:
    case TemplateUsageType.ConfirmationTransfer:
    case TemplateUsageType.ConfirmationTrial:
    case TemplateUsageType.ConfirmationWaitingList:
    case TemplateUsageType.ConfirmationWithdrawal:
      return "Confirmation";
    case TemplateUsageType.ReminderAdditionalLesson:
    case TemplateUsageType.ReminderLesson:
    case TemplateUsageType.ReminderTrialLesson:
      return "Reminder";
    case TemplateUsageType.FollowUpPayment:
    case TemplateUsageType.FollowUpAbsent:
    case TemplateUsageType.FollowUpReservation:
    case TemplateUsageType.FollowUpTrial:
      return "Follow Up";
    case TemplateUsageType.ConfirmationReceipt:
      return "Receipt";
    case TemplateUsageType.ConfirmationRefund:
      return "Refund";
    case TemplateUsageType.ConfirmationInvoice:
      return "Invoice";
    default:
      return "Confirmation";
  }
};
