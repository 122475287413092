import { Space, Typography } from "@thepiquelab/archus-components-web";
import React from "react";
import { Link } from "react-router-dom";
import UserAvatar from "../../common/UserAvatar/UserAvatar";

interface FullNameProps {
  name?: string;
}
const NameComponent: React.FC<FullNameProps> = ({ name = "" }) => (
  <Typography.Heading level={4} className="text-primary-blue">
    {name}
  </Typography.Heading>
);

export interface CellProfileProps {
  fullName: string;
  id?: string;
  gender?: string;
  avatarUrl?: string;
  userId?: string;
  alternateUserId?: string;
}

export interface Student {
  id?: string;
  fullName?: string;
  gender?: string;
  userId?: string;
  avatarUrl?: string;
}

export enum ShowStudentTypes {
  NameOnly = "NameOnly",
  All = "All",
  UserIdOnly = "UserIdOnly",
}

interface StudentCellProps {
  profile: Student;
  avatarSize?: number;
  className?: string;
  allowJump?: boolean;
  showType?: ShowStudentTypes;
  tag?: React.ReactNode;
}

export const StudentCell: React.FC<StudentCellProps> = (props) => {
  const { profile } = props;
  if (!profile) return null;

  const {
    avatarSize = 48,
    profile: { fullName, avatarUrl, gender, id, userId },
    className,
    allowJump = true,
    showType = ShowStudentTypes.All,
    tag,
  } = props;

  if (showType === ShowStudentTypes.NameOnly) {
    return (
      <Space className={className}>
        <Link
          to={id && allowJump ? `/customers/students/profile/${id}` : null}
          target="_blank"
        >
          <UserAvatar
            isStudent
            name={fullName}
            gender={gender}
            avatarUrl={avatarUrl}
            size={avatarSize}
          />
        </Link>
        <Space direction="vertical">
          <span className="inline-flex gap-x-2">
            {id && allowJump ? (
              <Link to={`/customers/students/profile/${id}`} target="_blank">
                <NameComponent name={fullName} />
              </Link>
            ) : (
              <NameComponent name={fullName} />
            )}
            {tag}
          </span>
        </Space>
      </Space>
    );
  }
  if (showType === ShowStudentTypes.UserIdOnly) {
    return (
      <Space className={className}>
        <Link
          to={id && allowJump ? `/customers/students/profile/${id}` : null}
          target="_blank"
        >
          <UserAvatar
            isStudent
            name={fullName}
            gender={gender}
            avatarUrl={avatarUrl}
            size={avatarSize}
          />
        </Link>
        <Space direction="vertical">
          <span className="inline-flex gap-x-2">
            {id && allowJump ? (
              <Link to={`/customers/students/profile/${id}`} target="_blank">
                <NameComponent name={userId} />
              </Link>
            ) : (
              <>{userId}</>
            )}
            {tag}
          </span>
        </Space>
      </Space>
    );
  }
  return (
    <Space className={className}>
      <Link
        to={id && allowJump ? `/customers/students/profile/${id}` : null}
        target="_blank"
      >
        <UserAvatar
          isStudent
          name={fullName}
          gender={gender}
          avatarUrl={avatarUrl}
          size={avatarSize}
        />
      </Link>
      <Space direction="vertical">
        <span className="inline-flex gap-x-2">
          {id && allowJump ? (
            <Link to={`/customers/students/profile/${id}`} target="_blank">
              <NameComponent name={fullName} />
            </Link>
          ) : (
            <NameComponent name={fullName} />
          )}
          {tag}
        </span>
        <Typography.Text className="text-primary-navyLight" size="middle">
          {userId}
        </Typography.Text>
      </Space>
    </Space>
  );
};
