import { ReactComponent as IconEmail } from "@/assets/images/IconEmail.svg";
import { ReactComponent as IconWhatsApp } from "@/assets/images/IconWhatsApp.svg";
import {
  GetSystemNotificationsQuery,
  ReadSystemNotification,
  ReadSystemNotificationMutation,
  ReadSystemNotificationMutationVariables,
  SystemNotificationType,
} from "@/graphql";
import formatLabel from "@/utils/formatLabel";
// import { ReactComponent as IconSent } from "@/components/settings/ProcessAutomation/assets/IconSent.svg";
// import { ReactComponent as IconMenuUser } from "@/assets/images/IconMenuUser.svg";
import { ReactComponent as IconCamera } from "@/assets/images/IconCamera.svg";
import { ReactComponent as IconGoogleMeeting } from "@/assets/images/IconGoogleMeeting.svg";
import { useMutation } from "@apollo/client";
import { Badge, Col, Row } from "@thepiquelab/archus-components-web";
import { formatDateTime } from "@thepiquelab/archus-library";
import { Typography } from "antd";
import { FC, ReactNode, memo } from "react";
import { useHistory } from "react-router-dom";
import { getMessageWithLink } from "./Notification";

export interface Props {
  item?: GetSystemNotificationsQuery["systemNotifications"]["items"][0];
}

const NotificationMap = new Map<
  SystemNotificationType,
  { title: string; icon: ReactNode }
>();
NotificationMap.set(SystemNotificationType.WhatsappAbnormalOffline, {
  title: formatLabel(SystemNotificationType.WhatsappAbnormalOffline),
  icon: <IconWhatsApp />,
});
NotificationMap.set(SystemNotificationType.WhatsappLoginFailed, {
  title: formatLabel(SystemNotificationType.WhatsappLoginFailed),
  icon: <IconWhatsApp />,
});
NotificationMap.set(SystemNotificationType.WhatsappLogoutFailed, {
  title: formatLabel(SystemNotificationType.WhatsappLogoutFailed),
  icon: <IconWhatsApp />,
});
NotificationMap.set(SystemNotificationType.WhatsappMessageSendFailed, {
  title: formatLabel(SystemNotificationType.WhatsappMessageSendFailed),
  icon: <IconWhatsApp />,
});
NotificationMap.set(SystemNotificationType.WhatsappOffline, {
  title: formatLabel(SystemNotificationType.WhatsappOffline),
  icon: <IconWhatsApp />,
});
NotificationMap.set(SystemNotificationType.WhatsappOnline, {
  title: formatLabel(SystemNotificationType.WhatsappOnline),
  icon: <IconWhatsApp />,
});
NotificationMap.set(SystemNotificationType.ClassSyncGoogleCalendarFailed, {
  title: formatLabel(SystemNotificationType.ClassSyncGoogleCalendarFailed),
  icon: <IconGoogleMeeting />,
});
NotificationMap.set(SystemNotificationType.ClassSyncGoogleCalendarSuccess, {
  title: formatLabel(SystemNotificationType.ClassSyncGoogleCalendarSuccess),
  icon: <IconGoogleMeeting />,
});
NotificationMap.set(SystemNotificationType.ClassSyncZoomMeetingFailed, {
  title: formatLabel(SystemNotificationType.ClassSyncZoomMeetingFailed),
  icon: <IconCamera />,
});
NotificationMap.set(SystemNotificationType.ClassSyncZoomMeetingSuccess, {
  title: formatLabel(SystemNotificationType.ClassSyncZoomMeetingSuccess),
  icon: <IconCamera />,
});
NotificationMap.set(SystemNotificationType.EmailSendFailed, {
  title: formatLabel(SystemNotificationType.EmailSendFailed),
  icon: <IconEmail />,
});
NotificationMap.set(SystemNotificationType.GoogleMeetEventsConflict, {
  title: formatLabel(SystemNotificationType.GoogleMeetEventsConflict),
  icon: <IconGoogleMeeting />,
});

const { Paragraph } = Typography;
const Notification: FC<Props> = ({ item }) => {
  const [readSystemNotification] = useMutation<
    ReadSystemNotificationMutation,
    ReadSystemNotificationMutationVariables
  >(ReadSystemNotification);

  const history = useHistory();

  const onClick = (): void => {
    switch (item.type) {
      case SystemNotificationType.WhatsappAbnormalOffline:
      case SystemNotificationType.WhatsappLoginFailed:
      case SystemNotificationType.WhatsappLogoutFailed:
      case SystemNotificationType.WhatsappMessageSendFailed:
      case SystemNotificationType.WhatsappOffline:
      case SystemNotificationType.WhatsappOnline:
        if (item?.whatsappMessage?.sender?.id) {
          history.push(
            `/settings/automations/whatsapp-accounts/${item.whatsappMessage.sender.id}`
          );
        } else {
          history.push(`/settings/automations/whatsapp-accounts`);
        }

        break;
      default:
        break;
    }

    if (!item.read)
      readSystemNotification({
        variables: {
          id: item._id,
        },
      });
  };
  return (
    <Row
      className="w-full justify-between mt-6 pb-6 px-4 border-b cursor-pointer"
      onClick={onClick}
    >
      <div className="w-6 mr-4 font_bold pt-8 text-2xl">
        {NotificationMap.get(item.type)?.icon}
      </div>
      <Col className="flex flex-1 overflow-hidden flex-col text-sm">
        <div className="truncate text-gray-600 mb-4">
          {item?.title || NotificationMap.get(item.type)?.title}
        </div>
        <Paragraph>
          <div dangerouslySetInnerHTML={{ __html: getMessageWithLink(item) }} />
        </Paragraph>
        <div className="text-gray-500">
          {formatDateTime(item.createDateTime)}
        </div>
      </Col>
      <Col className="ml-4 w-2">
        {!item.read && <Badge dot color="#F36B7F" text="" />}
      </Col>
    </Row>
  );
};
export default memo(Notification);
