import React from "react";

interface DigitalCredentialCellProps {
  digitalCredentials: {
    zoomMeetingId?: string;
    zoomMeetingPassword?: string;
    zoomMeetingLink?: string;
    googleMeetId?: string;
    googleMeetLink?: string;
  };
}

const DigitalCredentialCell: React.FC<DigitalCredentialCellProps> = (props) => {
  const digitalCredentials = props?.digitalCredentials || {};

  return (
    <>
      <div>Zoom Meeting ID: {digitalCredentials?.zoomMeetingId}</div>
      <div>
        Zoom Meeting Password: {digitalCredentials?.zoomMeetingPassword}
      </div>
      <div>Zoom Meeting Link: {digitalCredentials?.zoomMeetingLink}</div>
    </>
  );
};

export default DigitalCredentialCell;
