import { useSubscription } from "@apollo/client";
import { Button, Statistic } from "@thepiquelab/archus-components-web";
import moment from "moment";
import React, { useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import {
  GetClassEnrollments,
  GetClassSeatViewData,
  GetInCompleteRegistration,
  GetListRegistrationData,
  PostIncompleteRegistration,
  PostIncompleteRegistrationSubscription,
  PostIncompleteRegistrationSubscriptionVariables,
} from "../../../graphql";
import { useConfirmModal } from "../../common/ConfirmModal/NewConfirmModal";
import { removeProcessSessionInfo } from "../RegistrationDetail/common/SMS/utils";
import { useCancelRegistration } from "../RegistrationHooks";
import { useHaveInCompleteRegistration } from "../RegistrationHooks/useHaveInCompleteRegistration";
import useRegistrationEventSource from "../RegistrationHooks/useRegistrationEventSource";

const { Countdown } = Statistic;

const IncompleteRegistrationBar: React.FC = (props) => {
  const history = useHistory();
  const { modal, setModalProps, show, close } = useConfirmModal();

  const { incompleteData, refresh } = useHaveInCompleteRegistration();

  const session = useMemo(
    () =>
      incompleteData?.sessions?.filter((s) =>
        moment(s.expiredAt).isSameOrAfter()
      )?.[0],
    [incompleteData]
  );
  const incompleteItem = useMemo(() => session?.registration, [session]);

  // TODO: Remove graphql subscription
  const { data } = useSubscription<
    PostIncompleteRegistrationSubscription,
    PostIncompleteRegistrationSubscriptionVariables
  >(PostIncompleteRegistration);
  const { initEventSource, data: restfulData } = useRegistrationEventSource();

  const deadline = useMemo(() => session?.expiredAt, [session]);

  const [cancelRegistration, { loading: cancelLoading }] =
    useCancelRegistration();

  const cancel = async (id: string) => {
    try {
      await cancelRegistration({
        variables: { id },
        refetchQueries: [
          {
            query: GetListRegistrationData,
          },
          {
            query: GetInCompleteRegistration,
          },
          {
            query: GetClassSeatViewData,
            variables: { id: incompleteItem.class.id },
          },
          {
            query: GetClassEnrollments,
            variables: { id: incompleteItem.class.id },
          },
        ],
      });
      removeProcessSessionInfo();
      close();
    } catch (e) {
      await refresh();
      console.error(`[Cancel Registration]: ${e.message}`, e);
    }
  };

  useEffect(() => {
    if (deadline) {
      setTimeout(() => refresh(), moment().diff(deadline, "millisecond"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deadline]);

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, restfulData]);

  const handleContinue = (): void => {
    const stepNumber = incompleteItem.currentStep;

    if (stepNumber === 2) {
      history.push(
        `/class-arrangement/registration/create/step2/${incompleteItem?.id}`
      );
      return;
    }
    history.push(
      `/class-arrangement/registration/create/step${stepNumber}/${incompleteItem.id}`
    );
  };

  const handleCancel = (id: string): void => {
    setModalProps({
      onConfirm: () => cancel(id),
      onClose: () => close(),
    });
    show();
  };

  useEffect(() => {
    initEventSource();
  }, []);

  if (!incompleteItem) return null;

  return (
    <div className="bg-gray-400 rounded-lg py-2 mb-6">
      <div className="flex justify-around items-center">
        <div className="typography_sub_heading2">{incompleteItem?.name}</div>
        <Countdown
          valueStyle={{
            color: "#5B9DFF",
            fontSize: "16px",
            padding: ".5rem 0",
            fontWeight: "bold",
          }}
          onFinish={() => cancel(incompleteItem.id)}
          value={moment(deadline).valueOf()}
          format="mm:ss"
        />
        <div className="flex space-x-4">
          <Button type="primary" onClick={handleContinue}>
            Continue
          </Button>
          <Button
            onClick={() => handleCancel(incompleteItem.id)}
            loading={cancelLoading}
          >
            Cancel
          </Button>
        </div>
      </div>
      {modal}
    </div>
  );
};

export default IncompleteRegistrationBar;
