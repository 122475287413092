import {
  GetAcademicYearTaxRate,
  GetAcademicYearTaxRateQuery,
  GetAcademicYearTaxRateQueryVariables,
} from "@/graphql";
import { useQuery } from "@apollo/client";
import { message } from "antd";
import moment from "moment";

const useCheckTaxRateForCurrentYear = () => {
  const { data: yearData, loading } = useQuery<
    GetAcademicYearTaxRateQuery,
    GetAcademicYearTaxRateQueryVariables
  >(GetAcademicYearTaxRate, { variables: { year: moment().year() } });

  const handleCheckTaxRate = (): boolean => {
    const taxRate = yearData?.academicYear?.taxRate;

    if (!taxRate) {
      message.error(
        "Tax rate not set for this calendar year. Please update the tax rate to proceed with this action."
      );
      return false;
    }

    return true;
  };

  return {
    handleCheckTaxRate,
  };
};

export { useCheckTaxRateForCurrentYear };
