type Key = string | number;
type NamePath = Key[];

const getValue = (obj: Record<Key, any>) => (namePath: NamePath) =>
  namePath?.reduce((o, i) => o?.[i], obj);

export const getDeepValue = (
  obj: Record<Key, any>,
  namePath: NamePath | string
) => {
  const getValueOf = getValue(obj);
  if (typeof namePath === "string") {
    return getValueOf(namePath.split("."));
  }
  return getValueOf(namePath);
};
