import Loading from "@/components/common/Loading";
import {
  BulkSendReminderInput,
  SendReminderInput,
  TemplateUsageType,
} from "@/graphql";
import { Form, FormInstance } from "@thepiquelab/archus-components-web";
import React from "react";
import ReminderForm from "../../Form/ReminderForm";

interface TaskReminderProps {
  parentId?: string;
  form?: FormInstance;
  onSubmit?: (input: SendReminderInput) => Promise<void>;
  usageType: TemplateUsageType;

  onPreview?: (input: SendReminderInput | BulkSendReminderInput) => void;
  isBulk: boolean;
  partialSendReminderInput: Partial<SendReminderInput>;
}

const TaskReminder: React.FC<TaskReminderProps> = (props) => {
  const {
    parentId,
    form: formInstance,
    onSubmit,
    usageType,
    onPreview,
    isBulk,
    partialSendReminderInput,
  } = props;
  const [form] = Form.useForm(formInstance);

  return parentId ? (
    <Loading />
  ) : (
    <div className="pb-6">
      <ReminderForm
        templateUsageType={usageType}
        form={form}
        parentId={parentId}
        onSubmit={onSubmit}
        onPreview={onPreview}
        isShowSendInvoice={usageType === TemplateUsageType.FollowUpPayment}
        isBulk={isBulk}
        partialSendReminderInput={partialSendReminderInput}
      />
    </div>
  );
};

export default TaskReminder;
