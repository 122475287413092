import { InvoiceLineItemDO } from "@/components/finance/Modules/InvoiceDetail/InvoiceLineItemUtil";
import { School, Student } from "@/graphql";
import { Col, Row } from "@thepiquelab/archus-components-web";
import { formatCurrency } from "@thepiquelab/archus-library";
import React from "react";
import formatLabel from "../../../../utils/formatLabel";
import { Contact } from "./BillContact";

export type StudentCellType = Pick<
  Student,
  "fullName" | "userId" | "alternateUserId" | "__typename"
> & {
  primarySchool: Pick<School, "name">;
};

export interface BillLineItemsProps<TLineItem> {
  fields: {
    title: string;
    className?: string;
    titleClassName?: string;
    textAlign: "text-left" | "text-right" | "text-center";
    render: (lineItem: TLineItem | StudentCellType | any) => React.ReactNode;
  }[];
  subFields?: {
    render: (lineItem: TLineItem | StudentCellType | any) => React.ReactNode;
  }[];
  lineItems: Array<TLineItem & StudentCellType & any>;
  totalItems: React.ReactNode[];
  actionItems?: React.ReactNode[];
  student?: StudentCellType;
  fieldsAction?: (lineItem: TLineItem) => JSX.Element;
  subFieldsAction?: (lineItem: TLineItem) => JSX.Element;
}

export const TotalItem = ({
  text,
  total,
  bold,
}: {
  text: string;
  total: string | number;
  bold?: boolean;
}): React.ReactElement => (
  <Row justify="end" align="middle">
    <Col span={16} className="text-primary-navyLight leading-tight">
      {text}
    </Col>
    <Col span={8} className={`text-right ${bold && "font_bold text-lg"}`}>
      {total && formatCurrency(total)}
    </Col>
  </Row>
);

const OtherFeeNamesMap: { [key: string]: string } = {
  ADDITIONAL: "Additional Fee",
  DEPOSIT: "Deposit Fee",
  MATERIALS: "Materials Fee",
  REGISTRATION: "Registration Fee",
  TRIAL: "Trial Fee",
};

const formatLineItems = (res: any[]): any[] => {
  const fees = ["Deposit Fee", "Materials Fee", "Registration Fee"];

  const lineItems = res?.map((i) => ({
    ...i,
    description: OtherFeeNamesMap[i?.description] || i?.description,
  }));

  return (
    lineItems?.filter((i) => {
      const invoiceLineItemDO = new InvoiceLineItemDO(i);
      if (invoiceLineItemDO.isOtherFee() && fees.includes(i?.description)) {
        return i?.lineAmount?.toString() !== "0";
      }
      return true;
    }) || []
  );
};

const BillLineItems = <T extends {} = Record<string, any>>(
  props: BillLineItemsProps<T>
): React.ReactElement => {
  const {
    lineItems,
    totalItems,
    fields,
    actionItems,
    student,
    subFields = [],
    fieldsAction,
    subFieldsAction,
  } = props;

  return (
    <div>
      <table className="w-full my-6">
        <thead className="border-b">
          <tr>
            {fields.map((field, index) => (
              <th className={`${field.textAlign} ${field.className || "w-40"}`}>
                <div
                  className={`text-base text-gray-500 ${
                    field.titleClassName || ""
                  }`}
                >
                  {formatLabel(field.title)}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="border-b py-6">
          {student && (
            <div className="my-6">
              <Contact title={"FOR"} fullName={student?.fullName} />
            </div>
          )}
          {lineItems &&
            formatLineItems(lineItems).map((line) => (
              <>
                <tr>
                  {fields.map((field) => (
                    <>{field.render(line)}</>
                  ))}
                  {fieldsAction ? (
                    <td className="text-right align-top py-3">
                      {fieldsAction(line)}
                    </td>
                  ) : null}
                </tr>
                {subFields.every((field) => !!field.render(line)) ? (
                  <tr>
                    {subFields.map((field) => (
                      <>{field.render(line)}</>
                    ))}
                    {subFieldsAction ? (
                      <td className="text-right align-top py-1">
                        {subFieldsAction(line)}
                      </td>
                    ) : null}
                  </tr>
                ) : null}
              </>
            ))}
        </tbody>
      </table>
      <div className="w-full flex flex-no-wrap justify-between mb-6">
        <div className="w-1/2 flex flex-col">
          <div className="text-primary-navyLight font_regular mb-1">
            *Fees quoted are inclusive of GST
          </div>
          {actionItems?.map((item, index) => (
            <div key={`action-${index.toString()}`}>{item}</div>
          ))}
        </div>
        <div className="w-1/2">
          {totalItems?.map((item, index) => (
            <div key={`total-${index.toString()}`}>{item}</div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BillLineItems;
