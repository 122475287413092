import {
  generateNotificationConfig,
  message,
  notification,
} from "@thepiquelab/archus-components-web";
import { useEffect, useState } from "react";

const useCheckVersion = () => {
  const [versionCheckerWorker, setVersionCheckerWorker] =
    useState<Worker | null>(null);

  const key = new Date().getTime().toString();

  const [versionKeys, setVersionKeys] = useState<Array<string>>([]);

  const fetchAndStoreInitialVersion = async (worker: Worker) => {
    try {
      const response = await fetch(`${process.env.PUBLIC_URL}/meta.json`);
      if (!response.ok) {
        throw new Error("Failed to fetch version from meta.json");
      }
      const data = await response.json();
      const remoteVersion = data.version;

      // Store the fetched version in localStorage
      localStorage.setItem("APP_VERSION", JSON.stringify(remoteVersion));
    } catch (error) {
      console.error("Error fetching initial version:", error);
    }
  };

  const startWorker = () => {
    if (!versionCheckerWorker) {
      const worker = new Worker(`${process.env.PUBLIC_URL}/versionChecker.js`);
      setVersionCheckerWorker(worker);

      const initialVersion = localStorage.getItem("APP_VERSION");

      if (!initialVersion || initialVersion?.length < 10) {
        // If there's no APP_VERSION in localStorage, fetch and store it
        fetchAndStoreInitialVersion(worker);
      } else {
        // If APP_VERSION exists, send it to the worker
        worker.postMessage({
          localVersion: JSON.parse(initialVersion),
        });
      }

      worker.onmessage = (event) => {
        if (event.data && event.data.requestLatestVersion) {
          const latestVersion = localStorage.getItem("APP_VERSION");
          worker.postMessage({
            localVersion: JSON.parse(latestVersion),
          });
        } else {
          const { updated, error, remoteVersion } = event.data;

          if (error) {
            console.error("Version check error:", event.data.message);
          } else if (updated) {
            if (versionKeys.length > 0) {
              versionKeys.forEach((i) => notification?.close(i));
            }
            setVersionKeys([...versionKeys, key]);

            notification.open({
              key,
              ...generateNotificationConfig({
                title: "Update Available!",
                isDismissable: false,
                description:
                  "You need to update this app. The version you are using does not include the latest security features.",
                type: "info",
                primaryButtonLabel: "Get Latest Update",
                onPressPrimaryButton: () => {
                  notification.close(key);
                  message.success("Update successful!");
                  localStorage.setItem(
                    "APP_VERSION",
                    JSON.stringify(remoteVersion)
                  );
                  window.location.reload();
                },
              }),
              duration: null,
            });
          }
        }
      };
    }
  };

  const stopWorker = () => {
    if (versionCheckerWorker) {
      versionCheckerWorker.terminate();
      setVersionCheckerWorker(null);
    }
  };

  const removeWorker = () => {
    stopWorker();
    window.removeEventListener("online", startWorker);
    window.removeEventListener("offline", stopWorker);
  };

  useEffect(() => {
    if (navigator.onLine) {
      startWorker();
    }

    window.addEventListener("online", startWorker);
    window.addEventListener("offline", stopWorker);

    return () => {
      stopWorker();
      window.removeEventListener("online", startWorker);
      window.removeEventListener("offline", stopWorker);
    };
  }, [versionCheckerWorker]);

  return {
    removeWorker,
  };
};

export default useCheckVersion;
