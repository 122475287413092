import { CaretRightOutlined } from "@ant-design/icons/lib";
import {
  Checkbox,
  Divider,
  Space,
  Typography,
} from "@thepiquelab/archus-components-web";
import { useToggle } from "ahooks";
import React, { ReactNode } from "react";

interface ExpandableListItemProps {
  id?: string;
  key?: string | number;
  isExpandable?: boolean;
  defaultExpand?: boolean;
  enableDivide?: boolean;
  title: ReactNode;
  content?: ReactNode;
  right?: ReactNode;
  className?: string;
  bodyClassName?: string;
  headerClassName?: string;
  onExpand?: () => void;
  showSelect?: boolean;
  checked?: boolean;
  onSelected?: (id: string, checked: boolean) => void;
  disabled?: boolean;
  destroyOnCollapse?: boolean;
}

const ExpandableCard: React.FC<ExpandableListItemProps> = ({
  id,
  key,
  isExpandable = true,
  defaultExpand = true,
  enableDivide = true,
  title,
  content,
  right,
  className,
  bodyClassName = "px-6",
  headerClassName,
  children,
  onExpand,
  showSelect,
  checked,
  disabled,
  onSelected,
  destroyOnCollapse = false,
}) => {
  const [isExpand, { toggle: toggleExpand }] = useToggle(defaultExpand);

  const noExpand = isExpandable && !isExpand;

  const childrenNode = destroyOnCollapse && !isExpand ? <></> : children;

  return (
    <div
      className={`flex bg-white flex-col w-auto shadow-base hover:shadow-hover rounded mb-6 ${className} overflow-hidden`}
      data-testid={"ExpandableCard"}
      {...{ id, key: key ?? id }}
    >
      <div className={bodyClassName}>
        <div
          className={`expandableCard__header flex min-h-20 items-center justify-between ${headerClassName}`}
          onClick={() => {
            toggleExpand();
            if (onExpand && !isExpand) onExpand();
          }}
        >
          <Space align="center" size="middle">
            {showSelect && (
              <>
                <Checkbox
                  className="items-center"
                  checked={checked}
                  disabled={disabled}
                  onClick={(e) => {
                    e.stopPropagation();
                    onSelected?.(id, !checked);
                  }}
                />
                <Divider type="vertical" className="h-6" />
              </>
            )}
            {isExpandable && (
              <div
                className={`text-2xl text-gray-500`}
                style={{ marginLeft: "-4px" }}
              >
                <CaretRightOutlined
                  className={`transition duration-200 transform ${
                    isExpand ? "rotate-90" : "rotate-0"
                  }`}
                />
              </div>
            )}
            <Typography.Heading level={2}>{title as string}</Typography.Heading>
          </Space>
          {content}
          <div className="flex justify-between items-center">{right}</div>
        </div>
        <div
          className={`${
            noExpand ? "max-h-0" : ""
          }  transition-all duration-500 ease-in-out  ${
            enableDivide ? "divide-y" : "divide-y-0"
          } divide-gray-400 border-t justify-center`}
        >
          {childrenNode}
        </div>
      </div>
    </div>
  );
};

export default ExpandableCard;
