import NewConfirmModal from "@/components/common/ConfirmModal/NewConfirmModal";
import formatErrorMessage from "@/utils/formatErrorMessage";
import {
  PureQueryOptions,
  RefetchQueriesFunction,
  useMutation,
} from "@apollo/client";
import { message } from "@thepiquelab/archus-components-web";
import { Modal } from "antd";
import React from "react";
import {
  ApplyDiscount,
  ApplyDiscountMutation,
  ApplyDiscountMutationVariables,
  ApplyInvoiceDiscountCommand,
  LineItemFieldsFragment,
  RemoveLineItem,
  RemoveLineItemMutation,
  RemoveLineItemMutationVariables,
  UpdateLineItem,
  UpdateLineItemMutation,
  UpdateLineItemMutationVariables,
} from "../../../../graphql";
import formatLabel from "../../../../utils/formatLabel";
import { Discount } from "./Discount";
import { Edit } from "./Edit";
import { Remove } from "./Remove";
import { Config, LineItem, LineItemsModalType } from "./types";

const LineItemsModal: React.FC<{
  lineItem: LineItem | LineItemFieldsFragment;
  type: LineItemsModalType;
  refetchQueries?: Array<string | PureQueryOptions> | RefetchQueriesFunction;
  visible: boolean;
  setVisible: (isVisible: boolean) => void;
  callback?: () => void;
  taxRatePercentage: number;
}> = (props) => {
  const {
    type,
    visible,
    setVisible,
    lineItem,
    refetchQueries = [],
    callback,
    taxRatePercentage,
  } = props;

  const [updateLineItem, { loading: editLoading }] = useMutation<
    UpdateLineItemMutation,
    UpdateLineItemMutationVariables
  >(UpdateLineItem);

  const [applyLineItem, { loading: applyLoading }] = useMutation<
    ApplyDiscountMutation,
    ApplyDiscountMutationVariables
  >(ApplyDiscount);

  const [removeLineItem, { loading: removeLoading }] = useMutation<
    RemoveLineItemMutation,
    RemoveLineItemMutationVariables
  >(RemoveLineItem);

  const handleApplyDiscount = async (
    input: ApplyInvoiceDiscountCommand
  ): Promise<void> => {
    try {
      await applyLineItem({
        variables: {
          input: {
            lineItemId: lineItem.id,
            ...input,
          },
        },
        refetchQueries,
      });
      setVisible(false);
      callback?.();
    } catch (e) {
      message.error(formatErrorMessage(e));
    }
  };

  const handleApplyEdit = async (input: {
    description: string;
    quantity: number;
    unitAmount: number;
  }): Promise<void> => {
    try {
      await updateLineItem({
        variables: {
          input: {
            ...input,
            unitAmount: input.unitAmount.toString(),
            lineItemId: lineItem.id,
          },
        },
        refetchQueries,
      });
      callback?.();
    } catch (e) {
      message.error(formatErrorMessage(e));
    }

    setVisible(false);
  };

  const handleRemove = async (): Promise<void> => {
    try {
      await removeLineItem({
        variables: {
          lineItemId: lineItem.id,
        },
        refetchQueries,
      });
      setVisible(false);
      callback?.();
      message.success("Item Remove Successfully!");
    } catch (e) {
      message.error(formatErrorMessage(e));
    }
  };

  const config: Config = {
    discount: {
      title: formatLabel(`Apply Discount`),
      component: (
        <Discount
          setVisible={setVisible}
          lineItem={lineItem}
          loading={applyLoading}
          onApply={handleApplyDiscount}
          taxRatePercentage={taxRatePercentage}
        />
      ),
      modalWidth: 500,
    },
    edit: {
      title: formatLabel("Edit Item"),
      component: (
        <Edit
          lineItem={lineItem}
          onApply={handleApplyEdit}
          loading={editLoading}
          onCancel={() => setVisible(false)}
        />
      ),
      modalWidth: 500,
    },
    remove: {
      title: formatLabel("Remove"),
      component: (
        <Remove
          onRemove={handleRemove}
          onCancel={() => setVisible(false)}
          loading={removeLoading}
        />
      ),
      modalWidth: 500,
    },
  };

  if (!type || !lineItem.id) return null;

  const currentInstance = config[type];

  if (type === "remove") {
    return (
      <NewConfirmModal
        visible={visible}
        onClose={() => setVisible(false)}
        onConfirm={handleRemove}
        destroyOnClose
        confirmLoading={removeLoading}
      />
    );
  }
  return (
    <Modal
      title={currentInstance.title}
      visible={visible}
      width={currentInstance.modalWidth}
      onCancel={() => setVisible(false)}
      destroyOnClose
      footer={null}
      bodyStyle={{ paddingBottom: 10 }}
    >
      {currentInstance.component}
    </Modal>
  );
};

export default LineItemsModal;
