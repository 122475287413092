import { TaskStatus } from "@/graphql";
import formatErrorMessage from "@/utils/formatErrorMessage";
import {
  DatePicker,
  Skeleton,
  message,
} from "@thepiquelab/archus-components-web";
import moment, { Moment } from "moment";
import React from "react";
import { useUpdateFollowTaskDueDate } from "./hooks";

interface TaskDueDatePickerProps {
  task: {
    id: string;
    dueDate?: Date;
    status: TaskStatus;
  };
  callback?: () => void;
}

const TaskDueDatePicker: React.FC<TaskDueDatePickerProps> = (props) => {
  const { task, callback } = props;
  const [updateDueDate, { loading }] = useUpdateFollowTaskDueDate();

  const handleChange = async (date: Moment): Promise<void> => {
    try {
      await updateDueDate({
        variables: {
          id: task.id,
          dueDate: date ? date.startOf("h").toDate() : null,
        },
      });
      callback?.();
    } catch (error) {
      message.error(formatErrorMessage(error));
    }
  };

  return !loading ? (
    <DatePicker
      className="w-full"
      value={task?.dueDate ? moment(task?.dueDate) : null}
      onChange={handleChange}
      allowClear
      format={"DD/MM/YYYY"}
      disabled={[TaskStatus.Complete, TaskStatus.Void].includes(task?.status)}
    />
  ) : (
    <Skeleton.Input />
  );
};

export default TaskDueDatePicker;
