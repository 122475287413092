import { AdvanceTableColumnType } from "@/components/common/AdvanceTable/AdvanceTable";
import {
  EnumEnhancedTableWidthType,
  enumEnhancedTableWidthType,
} from "@/components/common/EnhancedTable/EnhancedTable";
import ClassLink from "@/components/widget/CommonLink/ClassLink";
import RegistrationLink from "@/components/widget/CommonLink/RegistrationLink";
import { TaskIndexFieldsFragment, TaskStatus, TaskType } from "@/graphql";
import { customizeEnumDisplay } from "@/utils/customizeEnumValue";
import formatLabel from "@/utils/formatLabel";
import { formatDate } from "@thepiquelab/archus-library";
import { uniqBy } from "lodash";
import FollowUpStatusSelect, {
  FollowUpType,
} from "../Modules/FollowUpStatusSelect";
import TaskTable, { CustomCommonColumn } from "./TaskTable";
import { CreationContextTypeMap } from "./TaskTableUtil";
import { TaskTableProps } from "./types";

const TrialFollowUpTask: React.FC<TaskTableProps> = (props) => {
  const { data, callback, visible, pagination, count, loading } = props;

  const columns: AdvanceTableColumnType[] = [
    {
      title: formatLabel("Task Name"),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.M_MEDIUM],
      render(value, record: TaskIndexFieldsFragment) {
        const { creationContext } = record;
        const creationContextType = creationContext?.type;
        const text = CreationContextTypeMap[creationContextType] || "-";
        return <>{text}</>;
      },
    },
    CustomCommonColumn.StudentName,
    CustomCommonColumn.StudentID,
    CustomCommonColumn.PrimaryParentName,
    CustomCommonColumn.PrimaryParentMobile,
    CustomCommonColumn.PrimaryParentWALine,
    {
      title: formatLabel("Class Shorthand"),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(value, record: TaskIndexFieldsFragment) {
        const { enrollment } = record;
        const { id: classId, shorthand } = enrollment?.class || {};

        return <ClassLink classId={classId}>{shorthand}</ClassLink>;
      },
    },
    {
      title: formatLabel("Reserved For"),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(value, record: TaskIndexFieldsFragment) {
        const { enrollment } = record;
        const { reservationsForTrial } = enrollment?.registration || {};
        if (!reservationsForTrial?.length) {
          return <>-</>;
        }
        return reservationsForTrial.map((r) => (
          <div>
            <ClassLink classId={r.class?.id}>{r.class?.shorthand}</ClassLink>
          </div>
        ));
      },
    },
    {
      title: formatLabel("Status"),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(value, record: TaskIndexFieldsFragment) {
        const { enrollment } = record;
        const { reservationsForTrial } = enrollment?.registration || {};
        if (!reservationsForTrial?.length) {
          return <>-</>;
        }
        return reservationsForTrial.map((r) => <div>{r.status}</div>);
      },
    },
    {
      title: formatLabel("Enrollment No."),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(value, record: TaskIndexFieldsFragment) {
        const { enrollment } = record;
        const { reservationsForTrial } = enrollment?.registration || {};
        if (!reservationsForTrial?.length) {
          return <>-</>;
        }
        return reservationsForTrial.map((r) => (
          <div>
            <RegistrationLink id={r.id}>{r.name}</RegistrationLink>
          </div>
        ));
      },
    },
    {
      title: formatLabel("Trial Dates"),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(value, record: TaskIndexFieldsFragment) {
        const { enrollment } = record;

        return enrollment?.attendances?.map((a) => (
          <div>{formatDate(a.startDateTime, { format: "SHORT" })}</div>
        ));
      },
    },
    {
      title: formatLabel("Attendance Status"),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(value, record: TaskIndexFieldsFragment) {
        const { enrollment } = record;

        return uniqBy(enrollment?.attendances, (a) => a.status).map((a) => (
          <div>{a.status}</div>
        ));
      },
    },
    {
      title: formatLabel("Trial Suitability"),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(value, record: TaskIndexFieldsFragment) {
        const { enrollment } = record;

        return (
          <div>
            {enrollment?.suitability?.status
              ? customizeEnumDisplay(
                  enrollment?.suitability?.status,
                  enrollment?.suitability?.status
                )
              : "-"}
          </div>
        );
      },
    },
    {
      title: formatLabel("Trial Follow Up Status"),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.M_MEDIUM],
      render(value, record: TaskIndexFieldsFragment) {
        return (
          <FollowUpStatusSelect
            enrollment={{
              id: record?.enrollment?.id,
              followUpStatus: record?.enrollment?.followUpStatus,
              followUpStatusLastModifiedBy: {
                fullName:
                  record?.enrollment?.followUpStatusLastModifiedBy?.fullName,
              },
              followUpStatusLastModifiedDateTime:
                record?.enrollment?.followUpStatusLastModifiedDateTime,
            }}
            disabled={record?.status === TaskStatus.Void}
            followUpType={FollowUpType.Trial}
            className="w-full"
            callback={callback}
          />
        );
      },
    },
  ];
  if (!visible) {
    return <></>;
  }

  return (
    <div>
      <TaskTable
        data={data}
        loading={loading}
        count={count}
        columns={columns}
        title={formatLabel("Trial Follow Up")}
        taskType={TaskType.FollowUpTrial}
        callback={callback}
        pagination={{
          ...pagination,
        }}
      />
    </div>
  );
};

export default TrialFollowUpTask;
