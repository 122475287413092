import React from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import DraggableItem from "./DraggableItem";
import { DraggableListProps, DraggableListSubComponents } from "./types";

/**
 * Draggable list component
 * Implementing react-beautiful-dnd functionalities
 * 
 * @example Draggable list with an array of items
 * ```
 * <DraggableList
    render={(provided, _snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          {checkedItems
            .filter((item) => item.isVisible)
            .map((item, i) => (
              <DraggableList.Item
                key={item.columnName}
                id={item.columnName}
                content={item.columnShowName || item.columnName}
                index={i}
                onRemove={!item.isRequired ? handleRemove : undefined}
              />
            ))
          }
          {provided.placeholder}
        </div>
      )}
    onDragEnd={(dropResult) => console.log('Drop source and destination:', dropResult)}
  />
 * ```
 */
const DraggableList: React.FC<DraggableListProps> & DraggableListSubComponents =
  ({ onDragEnd, render }) => (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">{render}</Droppable>
    </DragDropContext>
  );

DraggableList.Item = DraggableItem;

export default DraggableList;
