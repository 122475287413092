import copyTextToClipboard from "@/utils/copyToClipboard";
import { useEffect, useState } from "react";

const useCopy = (): { copy: (content: string) => void } => {
  // Avoiding shallow equals
  const [state, setState] = useState<{ content: string }>({
    content: "",
  });

  useEffect(() => {
    // create element
    const textArea = document.createElement("textarea");
    textArea.style.position = "absolute";
    textArea.style.opacity = "0";
    textArea.style.top = "0";
    textArea.style.zIndex = "-1";
    // add text
    textArea.value = state.content;
    document.querySelector("body").appendChild(textArea);
    textArea.value = state.content;
    textArea.select();
    document.execCommand("copy");
    textArea.remove();
  });

  const copy = (content: string): void => {
    if (content) {
      if (navigator?.clipboard?.writeText) {
        copyTextToClipboard(content);
      } else {
        setState({
          content,
        });
      }
    }
  };

  return { copy };
};

export default useCopy;
