import {
  GetAllBranches,
  GetAllBranchesQuery,
  GetAllBranchesQueryVariables,
  GetAllVenues,
  GetAllVenuesQuery,
  GetAllVenuesQueryVariables,
  GetDepartments,
  GetDepartmentsQuery,
  GetDepartmentsQueryVariables,
  PostBranchData,
  PostBranchDataSubscription,
  PostBranchDataSubscriptionVariables,
  PostDepartmentData,
  PostDepartmentDataSubscription,
  PostDepartmentDataSubscriptionVariables,
  PostVenueData,
  PostVenueDataSubscription,
  PostVenueDataSubscriptionVariables,
} from "@/graphql";
import { useQuery, useSubscription } from "@apollo/client";
import { find } from "lodash";
import { useEffect, useState } from "react";

export const usePostCentreDataSubscription = (): {
  venuesData: GetAllVenuesQuery;
  departmentsData: GetDepartmentsQuery;
  branchesData: GetAllBranchesQuery;
  loading: boolean;
} => {
  const [branchesData, setBranchesData] = useState<GetAllBranchesQuery>(null);
  const [venuesData, setVenuesData] = useState<GetAllVenuesQuery>(null);
  const [departmentsData, setDepartmentsData] =
    useState<GetDepartmentsQuery>(null);
  const { data: originalBranchesData, loading: getBranchDataLoading } =
    useQuery<GetAllBranchesQuery, GetAllBranchesQueryVariables>(
      GetAllBranches,
      {
        variables: {
          showArchive: true,
        },
        fetchPolicy: "cache-first",
      }
    );
  const { data: originalVenuesData, loading: getVenueDataLoading } = useQuery<
    GetAllVenuesQuery,
    GetAllVenuesQueryVariables
  >(GetAllVenues, {
    variables: {
      showArchive: true,
      showDigital: true,
    },
    fetchPolicy: "cache-first",
  });
  const { data: originalDepartmentData, loading: getDepartmentDataLoading } =
    useQuery<GetDepartmentsQuery, GetDepartmentsQueryVariables>(
      GetDepartments,
      {
        variables: { showArchive: true },
        fetchPolicy: "cache-and-network",
      }
    );

  const { data: branchesDataFromSub } = useSubscription<
    PostBranchDataSubscription,
    PostBranchDataSubscriptionVariables
  >(PostBranchData);

  const { data: venuesDataFromSub } = useSubscription<
    PostVenueDataSubscription,
    PostVenueDataSubscriptionVariables
  >(PostVenueData);

  const { data: departmentsDataFromSub } = useSubscription<
    PostDepartmentDataSubscription,
    PostDepartmentDataSubscriptionVariables
  >(PostDepartmentData);

  useEffect(() => {
    if (originalBranchesData) {
      setBranchesData(originalBranchesData);
    }
  }, [originalBranchesData]);

  useEffect(() => {
    if (originalDepartmentData) {
      setDepartmentsData(originalDepartmentData);
    }
  }, [originalDepartmentData]);

  useEffect(() => {
    if (originalVenuesData) {
      setVenuesData(originalVenuesData);
    }
  }, [originalVenuesData]);

  useEffect(() => {
    if (!branchesDataFromSub || !branchesData) {
      return;
    }
    const newBranchesData = { ...branchesData };
    const index = newBranchesData?.branches?.findIndex(
      (branch) => branch.id === branchesDataFromSub.postBranchData.id
    );
    if (index > -1) {
      newBranchesData.branches = newBranchesData?.branches?.map(
        (currentData) => {
          const updatedData = find(branchesDataFromSub.postBranchData, {
            id: currentData.id,
          });
          if (updatedData) {
            return {
              ...currentData,
              ...updatedData,
            };
          }
          return currentData;
        }
      );
    } else {
      newBranchesData.branches = [
        ...newBranchesData?.branches,
        branchesDataFromSub.postBranchData,
      ];
    }
    setBranchesData(newBranchesData);
  }, [branchesDataFromSub]);

  useEffect(() => {
    if (!venuesDataFromSub || !venuesData) {
      return;
    }
    const newVenuesData = { ...venuesData };
    const index = newVenuesData?.venues?.findIndex(
      (venue) => venue.id === venuesDataFromSub.postVenueData.id
    );
    if (index > -1) {
      newVenuesData.venues = newVenuesData?.venues?.map((currentData) => {
        const updatedData = find(venuesDataFromSub.postVenueData, {
          id: currentData.id,
        });
        if (updatedData) {
          return {
            ...currentData,
            ...updatedData,
          };
        }
        return currentData;
      });
    } else {
      newVenuesData.venues = [
        ...newVenuesData?.venues,
        venuesDataFromSub.postVenueData,
      ];
    }

    setVenuesData(newVenuesData);
  }, [venuesDataFromSub]);

  useEffect(() => {
    if (!departmentsDataFromSub || !departmentsData) {
      return;
    }

    const newDepartmentsData = { ...departmentsData };
    const index = newDepartmentsData?.departments?.findIndex(
      (department) =>
        department.id === departmentsDataFromSub.postDepartmentData.id
    );
    if (index > -1) {
      newDepartmentsData.departments = newDepartmentsData?.departments.map(
        (currentData) => {
          const updatedData = find(departmentsDataFromSub.postDepartmentData, {
            id: currentData.id,
          });
          if (updatedData) {
            return {
              ...currentData,
              ...updatedData,
            };
          }
          return currentData;
        }
      );
    } else {
      newDepartmentsData.departments = [
        ...newDepartmentsData?.departments,
        departmentsDataFromSub.postDepartmentData,
      ];
    }

    setDepartmentsData(newDepartmentsData);
  }, [departmentsDataFromSub]);

  return {
    branchesData,
    departmentsData,
    venuesData,
    loading:
      getVenueDataLoading || getBranchDataLoading || getDepartmentDataLoading,
  };
};
