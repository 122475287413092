import { RegistrationsStatus } from "@/graphql";
import { sortBy } from "lodash";

export const addRanking = (
  records: {
    [key: string]: any;
    status: RegistrationsStatus;
    isPrioritized?: boolean;
    lastModifiedDateTime?: string;
  }[]
): { [key: string]: any; ranking?: number }[] => {
  records = records?.map((item) => ({
    ...item,
    lastModifiedDateTime: item?.lastModifiedDateTime || item?.createdDateTime,
  }));
  const sortedRecords = sortBy(records, "lastModifiedDateTime");
  let rankingIndex = 1;
  const prioritizedRecords = sortedRecords.filter((r) => r.isPrioritized);
  const otherRecords = sortedRecords
    .filter((r) => !r.isPrioritized)
    .map((record) => {
      let ranking: number;
      if (record.status === RegistrationsStatus.Waitlisted) {
        ranking = rankingIndex;
        rankingIndex += 1;
      }
      return { ...record, ranking };
    });

  const result = [...prioritizedRecords, ...sortBy(otherRecords, "ranking")];
  const resultWithOrder = result.map((item, index) => ({
    ...item,
    order: index + 1,
  }));
  return resultWithOrder;
};
