import { ReactComponent as IconDelete } from "@/assets/images/IconDelete.svg";
import { ReactComponent as IconPlus } from "@/assets/images/IconPlus.svg";
import { AdvanceTableColumnType } from "@/components/common/AdvanceTable/AdvanceTable";
import { useConfirmModal } from "@/components/common/ConfirmModal/NewConfirmModal";
import DropdownAction, {
  Action,
} from "@/components/common/DropdownAction/DropdownAction";
import UserAvatar from "@/components/common/UserAvatar/UserAvatar";
import { TaskChecklistItemFieldsFragment } from "@/graphql";
import formatErrorMessage from "@/utils/formatErrorMessage";
import {
  Button,
  Form,
  Input,
  Store,
  Table,
  message,
} from "@thepiquelab/archus-components-web";
import { isEqual } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import AssigneeAvatars from "../TaskDetail/AssigneeAvatars";
import {
  useCreateTaskChecklistItem,
  useDeleteTaskChecklistItem,
  useUpdateTaskChecklistItem,
} from "../hooks";
import AssigneeSelect from "./AssigneeSelect";

interface Props {
  checklistId: string;
  items: TaskChecklistItemFieldsFragment[];
  callback?: () => void;
  isReadonly: boolean;
}

const ChecklistItemTable: React.FC<Props> = (props) => {
  const { items: itemsFromProps, checklistId, callback, isReadonly } = props;
  const [form] = Form.useForm();
  const [createItem, { loading: createLoading }] = useCreateTaskChecklistItem();
  const [updateItem, { loading: updateLoading }] = useUpdateTaskChecklistItem();
  const [deleteItem, { loading: deleteLoading }] = useDeleteTaskChecklistItem();
  const { modal, show, close, setModalProps } = useConfirmModal();
  const [editingRecord, setEditingRecord] =
    useState<TaskChecklistItemFieldsFragment>();
  const [editingKeys, setEditingKeys] = useState<string[]>();

  const items = useMemo(() => {
    if (editingRecord && !editingRecord.id) {
      return [...itemsFromProps, editingRecord];
    }

    return itemsFromProps;
  }, [itemsFromProps, editingRecord]);

  const selectedRowKeys = useMemo(
    () => items.filter((item) => item.isDone).map((item) => item.id),
    [items]
  );

  const isEditing = (item: TaskChecklistItemFieldsFragment): boolean =>
    item.id === editingRecord?.id;

  const edit = (item: TaskChecklistItemFieldsFragment, key: string): void => {
    if (editingKeys) {
      return;
    }
    form.setFieldsValue({ ...item });
    setEditingKeys([key]);
    setEditingRecord(item);
  };

  const handleCancel = (): void => {
    setEditingRecord(null);
    setEditingKeys(null);
    form.resetFields();
  };

  const handleSubmit = async (value: Store): Promise<void> => {
    try {
      const { name, assigneeIds } = value;
      if (editingRecord?.id) {
        if (assigneeIds && isEqual(assigneeIds, editingRecord.assigneeIds)) {
          setEditingRecord(null);
          setEditingKeys(null);
          return;
        }
        if (name && isEqual(name, editingRecord.name)) {
          setEditingRecord(null);
          setEditingKeys(null);
          return;
        }
        await updateItem({
          variables: {
            input: {
              id: editingRecord.id,
              name,
              assigneeIds,
            },
          },
        });
      } else {
        await createItem({
          variables: {
            input: {
              checklistId,
              name,
              assigneeIds,
            },
          },
        });
        handleCancel();
      }
      setEditingRecord(null);
      setEditingKeys(null);
    } catch (error) {
      message.error(formatErrorMessage(error));
    }
  };

  const handleDeleteItem = async (id: string): Promise<void> => {
    try {
      await deleteItem({
        variables: {
          id,
        },
      });
      close();
      callback?.();
    } catch (error) {
      message.error(formatErrorMessage(error));
    }
  };

  const handleCompleteItem = async (
    id: string,
    isDone: boolean
  ): Promise<void> => {
    try {
      await updateItem({
        variables: {
          input: {
            id,
            isDone,
          },
        },
      });
    } catch (error) {
      message.error(formatErrorMessage(error));
    }
  };

  const deleteAction: Action = {
    icon: <IconDelete className="w-4" />,
    label: "Delete Item",
    onClick: (id: string) => {
      setModalProps({
        onConfirm: () => {
          handleDeleteItem(id);
        },
        onClose: close,
      });
      show();
    },
  };

  const columns: AdvanceTableColumnType[] = [
    {
      title: "Item Name",
      dataIndex: "name",
      key: "name",
      width: 500,
      editable: true,
      onCell: (record) => ({
        onClick: () => {
          edit(record, "name");
        },
      }),
      render: (text, record) => {
        const editable = isEditing(record) && editingKeys.includes("name");
        return editable && !isReadonly ? (
          <Form.Item
            style={{ marginBottom: 0 }}
            name="name"
            rules={[{ required: true, message: "Field is required." }]}
          >
            <Input
              autoFocus
              onBlur={record.id && form.submit}
              onPressEnter={record.id && form.submit}
            />
          </Form.Item>
        ) : (
          <div className={record.isDone ? "line-through" : ""}>{text}</div>
        );
      },
    },
    {
      title: "Assignee",
      dataIndex: "assignee",
      key: "assignee",
      editable: true,
      onCell: (record) => ({
        onClick: () => {
          edit(record, "assigneeIds");
        },
      }),
      render: (text, record: TaskChecklistItemFieldsFragment) => {
        const editable =
          isEditing(record) && editingKeys.includes("assigneeIds");
        return editable && !isReadonly ? (
          <Form.Item name="assigneeIds" style={{ marginBottom: 0 }}>
            <AssigneeSelect
              disabled={createLoading || updateLoading}
              onBlur={record.id && form.submit}
              autoFocus={!!record.id}
            />
          </Form.Item>
        ) : (
          <div className="flex gap-2 flex-wrap">
            {record?.assignees?.length ? (
              <AssigneeAvatars assignees={record.assignees} />
            ) : (
              <UserAvatar size={32} />
            )}
          </div>
        );
      },
    },
    {
      title: "Options",
      dataIndex: "actions",
      width: 100,
      key: "actions",
      render: (_, record) =>
        record.id && !isReadonly ? (
          <DropdownAction id={record.id} actions={[deleteAction]} />
        ) : null,
    },
  ];

  useEffect(() => {
    setModalProps({ confirmLoading: deleteLoading });
  }, [deleteLoading]);

  return (
    <Form form={form} onFinish={handleSubmit}>
      <Table
        dataSource={items}
        columns={columns}
        rowKey="id"
        className={`${isReadonly ? "checklist-readonly" : ""}`}
        pagination={false}
        footer={() => {
          if (isReadonly) {
            return <></>;
          }
          return editingRecord && !editingRecord.id ? (
            <div className="flex gap-2">
              <Button onClick={handleCancel}>Cancel</Button>
              <Button
                type="primary"
                loading={createLoading}
                onClick={form.submit}
              >
                Save
              </Button>
            </div>
          ) : (
            <div>
              <Button
                icon={<IconPlus className="mr-2" />}
                onClick={() => {
                  form.resetFields();
                  setEditingRecord({} as TaskChecklistItemFieldsFragment);
                  setEditingKeys(["name", "assigneeIds"]);
                }}
              >
                Add Item
              </Button>
            </div>
          );
        }}
        rowSelection={{
          hideSelectAll: true,
          selectedRowKeys,
          onSelect: (record, selected) => {
            handleCompleteItem(record.id, selected);
          },
          getCheckboxProps: (record) => ({
            disabled: !record.id || isReadonly,
          }),
          renderCell: (_, record, _index, originNode) => {
            if (record.id && !isReadonly) {
              return originNode;
            }
            return <></>;
          },
        }}
      />
      {modal}
    </Form>
  );
};

export default ChecklistItemTable;
