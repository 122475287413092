import React from "react";
import ModalButtonGroup from "../../../common/ModalButtonGroup";

export const Remove: React.FC<{
  onRemove: () => void;
  onCancel: () => void;
  loading: boolean;
}> = (props): JSX.Element => {
  const { onRemove, onCancel, loading } = props;

  return (
    <>
      <div className="mb-6">Are you sure you want to remove this item?</div>

      <ModalButtonGroup
        onCancel={onCancel}
        cancelText={"Cancel"}
        onOK={onRemove}
        confirmLoading={loading}
        okText={"Confirm"}
      />
    </>
  );
};
