import UserAvatar from "@/components/common/UserAvatar/UserAvatar";
import React, { useEffect, useState } from "react";
import AssigneeAvatars from "../TaskDetail/AssigneeAvatars";
import AssigneeSelect from "./AssigneeSelect";

interface EditableAssigneeProps {
  assignees?: {
    _id: string;
    initials?: string;
    fullName?: string;
  }[];
  onSave: (assigneeIds: string[]) => void;
  onEdit?: () => void;
  loading?: boolean;
  isReadonly?: boolean;
}

const EditableAssignee: React.FC<EditableAssigneeProps> = ({
  assignees,
  onSave,
  onEdit,
  loading,
  isReadonly,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [assigneeIds, setAssigneeIds] = useState<string[]>(
    assignees?.map((a) => a._id) || []
  );

  const handleAssigneeChange = (ids: string[]): void => {
    setAssigneeIds(ids);
  };

  const handleSave = (): void => {
    onSave(assigneeIds || []);
    setIsEditing(false);
  };

  useEffect(() => {
    setAssigneeIds(assignees?.map((a) => a._id) || []);
  }, [assignees]);

  return (
    <div>
      {isEditing && !isReadonly ? (
        <AssigneeSelect
          value={assigneeIds}
          onChange={handleAssigneeChange}
          onBlur={handleSave}
          disabled={loading}
        />
      ) : (
        <div
          className="flex gap-2 flex-wrap"
          onClick={() => {
            if (isReadonly) return;
            setIsEditing(true);
            onEdit?.();
          }}
        >
          {assignees?.length ? (
            <AssigneeAvatars assignees={assignees} />
          ) : isReadonly ? (
            <> </>
          ) : (
            <UserAvatar size={32} />
          )}
        </div>
      )}
    </div>
  );
};

export default EditableAssignee;
