import { FinanceServiceApiClient } from "../clients/FinanceServiceApiClient";
import { InvoicePaymentCommand } from "./types";

export class FinanceApi {
  private static client = FinanceServiceApiClient;

  private static paymentPathPrefix = "/v1/payments";

  public static async bulkPayment(
    command: InvoicePaymentCommand
  ): Promise<void> {
    return this.client.post(`${this.paymentPathPrefix}/bulk-payment`, command);
  }
}
