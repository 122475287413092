import React from "react";

const OverlappedIcon = (): JSX.Element => (
  <svg
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 0.664062H1.66667C0.75 0.664062 0.00833333 1.41406 0.00833333 2.33073L0 17.3307L3.33333 13.9974H15C15.9167 13.9974 16.6667 13.2474 16.6667 12.3307V2.33073C16.6667 1.41406 15.9167 0.664062 15 0.664062ZM9.16667 8.16406H7.5V3.16406H9.16667V8.16406ZM9.16667 11.4974H7.5V9.83073H9.16667V11.4974Z"
      fill="#F36B7F"
    />
  </svg>
);

export default OverlappedIcon;
