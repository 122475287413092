import {
  AttendanceType,
  BalanceActionType,
  BalanceHistoryType,
  CourseStatus,
  DeliveryMethod,
  EnrollmentSuitabilityStatus,
  MaterialStatus,
  PaymentMode,
  ReminderType,
  TemplateUsageType,
  WhatsappMessageStatus,
} from "./graphql";

export const customizeMapConfig = {
  [CourseStatus.Ongoing]: "Ongoing",
  [AttendanceType.MakeupIn]: "Replacement In",
  [CourseStatus.Created]: "Created",
  [CourseStatus.Completed]: "Completed",
  [WhatsappMessageStatus.Queued]: "Pending",
  [TemplateUsageType.ConfirmationReceipt]: "Receipt",
  [ReminderType.Whatsapp]: "WhatsApp",
  [EnrollmentSuitabilityStatus.ContactParent]: "Call Parent",
  [PaymentMode.Cash]: "Cash",
  [PaymentMode.CreditCard]: "Credit Card",
  [PaymentMode.BankTransfer]: "Bank Transfer",
  [PaymentMode.AtmTransfer]: "ATM Transfer",
  [PaymentMode.PayNow]: "PayNow",
  [PaymentMode.PayNowStripe]: "PayNow (Stripe)",
  [PaymentMode.PayNowBank]: "PayNow (Bank)",
  "Pay Now": "PayNow",
  [PaymentMode.DbsMax]: "DBS MAX!",
  [PaymentMode.Cheque]: "Cheque",
  [PaymentMode.Credit]: "Balance",
  [PaymentMode.CreditCardStripe]: "Credit Card (Stripe)",
  [PaymentMode.GrabPayStripe]: "GrabPay (Stripe)",
  [BalanceActionType.AddToBalance]: "Add To Balance",
  [BalanceActionType.ApplyBalance]: "Apply Balance",
  [BalanceActionType.DeductBalance]: "Deduct Balance",
  [BalanceActionType.ManualAdjustBalance]: "Manual Adjust Balance",
  [BalanceActionType.ReversalOfApplyBalance]: "Reversal Of Apply Balance",
  [BalanceActionType.VoidReceipt]: "Void Receipt",
  [BalanceHistoryType.BalanceRefund]: "Balance Refund",
  [BalanceHistoryType.FeePayment]: "Fee Payment",
  [BalanceHistoryType.ManualAdjustment]: "Manual Adjustment",
  [BalanceHistoryType.MergeAdjustment]: "Merge Adjustment",
  [BalanceHistoryType.Receipt]: "Receipt",
  [BalanceHistoryType.VoidBalanceRefund]: "Void Balance Refund",
  [BalanceHistoryType.VoidFeePayment]: "Void Fee Payment",
  [DeliveryMethod.DeliveryByOtherCouriers]: "Deliver By Other Couriers",
  [DeliveryMethod.HandoverOnsite]: "Handover Onsite",
  [MaterialStatus.HandOutOverdue]: "Hand Out Overdue",
  [MaterialStatus.PendingHandover]: "Pending Handover",
  [MaterialStatus.Delivered]: "Delivered",
  [MaterialStatus.Delivering]: "Delivering",
  [MaterialStatus.Draft]: "Draft",
  [MaterialStatus.Exception]: "Exception",
  [MaterialStatus.Pending]: "Pending",
};
