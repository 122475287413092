import {
  MutationTuple,
  QueryResult,
  QueryTuple,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";

import {
  ArchiveWhatsappAccount,
  ArchiveWhatsappAccountMutation,
  ArchiveWhatsappAccountMutationVariables,
  CreateWhatsappAccount,
  CreateWhatsappAccountMutation,
  CreateWhatsappAccountMutationVariables,
  GetWhatsappAccount,
  GetWhatsappAccountQuery,
  GetWhatsappAccountQueryVariables,
  LoginWhatsappAccount,
  LoginWhatsappAccountMutation,
  LoginWhatsappAccountMutationVariables,
  LogoutWhatsappAccount,
  LogoutWhatsappAccountMutation,
  LogoutWhatsappAccountMutationVariables,
  PauseWhatsappAccount,
  PauseWhatsappAccountMutation,
  PauseWhatsappAccountMutationVariables,
  PauseWhatsAppMessage,
  PauseWhatsAppMessageMutation,
  PauseWhatsAppMessageMutationVariables,
  ReconnectWhatsappAccount,
  ReconnectWhatsappAccountMutation,
  ReconnectWhatsappAccountMutationVariables,
  RecoverWhatsappAccount,
  RecoverWhatsappAccountMutation,
  RecoverWhatsappAccountMutationVariables,
  RequeueWhatsAppMessage,
  RequeueWhatsAppMessageMutation,
  RequeueWhatsAppMessageMutationVariables,
  ResumeWhatsappAccount,
  ResumeWhatsappAccountMutation,
  ResumeWhatsappAccountMutationVariables,
  ResumeWhatsAppMessage,
  ResumeWhatsAppMessageMutation,
  ResumeWhatsAppMessageMutationVariables,
  RetryWhatsAppMessage,
  RetryWhatsAppMessageMutation,
  RetryWhatsAppMessageMutationVariables,
  UpdateWhatsappAccount,
  UpdateWhatsappAccountMutation,
  UpdateWhatsappAccountMutationVariables,
} from "../../../../../graphql";

export const useQueryWhatsappAccount = (
  id: string
): QueryResult<GetWhatsappAccountQuery, GetWhatsappAccountQueryVariables> =>
  useQuery<GetWhatsappAccountQuery, GetWhatsappAccountQueryVariables>(
    GetWhatsappAccount,
    { variables: { id } }
  );

export const useLazyQueryWhatsappAccount = (): QueryTuple<
  GetWhatsappAccountQuery,
  GetWhatsappAccountQueryVariables
> =>
  useLazyQuery<GetWhatsappAccountQuery, GetWhatsappAccountQueryVariables>(
    GetWhatsappAccount,
    { fetchPolicy: "network-only" }
  );

export const useCreateWhatsappAccount = (): MutationTuple<
  CreateWhatsappAccountMutation,
  CreateWhatsappAccountMutationVariables
> =>
  useMutation<
    CreateWhatsappAccountMutation,
    CreateWhatsappAccountMutationVariables
  >(CreateWhatsappAccount);

export const useUpdateWhatsappAccount = (): MutationTuple<
  UpdateWhatsappAccountMutation,
  UpdateWhatsappAccountMutationVariables
> =>
  useMutation<
    UpdateWhatsappAccountMutation,
    UpdateWhatsappAccountMutationVariables
  >(UpdateWhatsappAccount);

export const useArchiveWhatsappAccount = (): MutationTuple<
  ArchiveWhatsappAccountMutation,
  ArchiveWhatsappAccountMutationVariables
> =>
  useMutation<
    ArchiveWhatsappAccountMutation,
    ArchiveWhatsappAccountMutationVariables
  >(ArchiveWhatsappAccount);

export const usePauseWhatsappAccount = (): MutationTuple<
  PauseWhatsappAccountMutation,
  PauseWhatsappAccountMutationVariables
> =>
  useMutation<
    PauseWhatsappAccountMutation,
    PauseWhatsappAccountMutationVariables
  >(PauseWhatsappAccount);

export const useResumeWhatsappAccount = (): MutationTuple<
  ResumeWhatsappAccountMutation,
  ResumeWhatsappAccountMutationVariables
> =>
  useMutation<
    ResumeWhatsappAccountMutation,
    ResumeWhatsappAccountMutationVariables
  >(ResumeWhatsappAccount);

export const useLoginWhatsappAccount = (): MutationTuple<
  LoginWhatsappAccountMutation,
  LoginWhatsappAccountMutationVariables
> =>
  useMutation<
    LoginWhatsappAccountMutation,
    LoginWhatsappAccountMutationVariables
  >(LoginWhatsappAccount, { fetchPolicy: "no-cache" });

export const useLogoutWhatsappAccount = (): MutationTuple<
  LogoutWhatsappAccountMutation,
  LogoutWhatsappAccountMutationVariables
> =>
  useMutation<
    LogoutWhatsappAccountMutation,
    LogoutWhatsappAccountMutationVariables
  >(LogoutWhatsappAccount);

export const useReconnectWhatsappAccount = (): MutationTuple<
  ReconnectWhatsappAccountMutation,
  ReconnectWhatsappAccountMutationVariables
> =>
  useMutation<
    ReconnectWhatsappAccountMutation,
    ReconnectWhatsappAccountMutationVariables
  >(ReconnectWhatsappAccount);

export const useRecoverWhatsappAccount = (): MutationTuple<
  RecoverWhatsappAccountMutation,
  RecoverWhatsappAccountMutationVariables
> =>
  useMutation<
    RecoverWhatsappAccountMutation,
    RecoverWhatsappAccountMutationVariables
  >(RecoverWhatsappAccount);

export const useRequeueWhatsAppMessage = (): MutationTuple<
  RequeueWhatsAppMessageMutation,
  RequeueWhatsAppMessageMutationVariables
> =>
  useMutation<
    RequeueWhatsAppMessageMutation,
    RequeueWhatsAppMessageMutationVariables
  >(RequeueWhatsAppMessage);

export const usePauseWhatsAppMessage = (): MutationTuple<
  PauseWhatsAppMessageMutation,
  PauseWhatsAppMessageMutationVariables
> =>
  useMutation<
    PauseWhatsAppMessageMutation,
    PauseWhatsAppMessageMutationVariables
  >(PauseWhatsAppMessage);

export const useRetryWhatsAppMessage = (): MutationTuple<
  RetryWhatsAppMessageMutation,
  RetryWhatsAppMessageMutationVariables
> =>
  useMutation<
    RetryWhatsAppMessageMutation,
    RetryWhatsAppMessageMutationVariables
  >(RetryWhatsAppMessage);

export const useResumeWhatsAppMessage = (): MutationTuple<
  ResumeWhatsAppMessageMutation,
  ResumeWhatsAppMessageMutationVariables
> =>
  useMutation<
    ResumeWhatsAppMessageMutation,
    ResumeWhatsAppMessageMutationVariables
  >(ResumeWhatsAppMessage);
