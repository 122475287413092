type ValueType = string;

export interface ResultArrayItemType {
  key: string;
  value: ValueType;
}

interface ResultMapType {
  [key: string]: ValueType;
}

/**
 * map enum to a Array [{key, value}, ...]
 * @param data
 */
export function enumToArray(data: any): ResultArrayItemType[] {
  const keys = Object.keys(data);
  const values = Object.values(data) as ValueType[];
  return keys.map((key, index) => ({ key, value: values[index] }));
}

/**
 * map enum to a Map [key => value, ...]
 * @param data
 */
export function enumToMap(data: any): Map<string, ValueType> {
  const keys = Object.keys(data);
  const values = Object.values(data) as ValueType[];
  const mapArr = keys.map(
    (key, index) => [values[index], key] as [string, ValueType]
  );
  return new Map(mapArr);
}

/**
 * convert enum to Record ({value: key})
 * @param data
 */
export function enumToRecord(data: any): ResultMapType {
  const keys = Object.keys(data);
  const values = Object.values(data) as ValueType[];
  const result: ResultMapType = {};
  keys.forEach((key, index) => {
    result[values[index]] = key;
  });
  return result;
}

export function enumToLabelArray(
  data: any
): { label: string; value: ValueType }[] {
  const keys = Object.keys(data);
  const values = Object.values(data) as string[];
  return keys.map((label, index) => ({ label, value: values[index] }));
}
