import { Row, Skeleton } from "@thepiquelab/archus-components-web";
import React from "react";

interface SearchSkeletonProps {
  row?: number;
  inputClassName?: string;
}

const SearchSkeleton: React.FC<SearchSkeletonProps> = (props) => {
  const { row = 1, inputClassName = "ml-2 w-72" } = props;
  return (
    <div className="flex flex-col w-full">
      {Array(row)
        ?.fill("1")
        ?.map((i, index) => (
          <Row
            key={index?.toString()}
            className="flex py-3 flex-no-wrap w-full"
          >
            <Skeleton.Avatar active size="large" shape="circle" />
            <Skeleton.Input active size="large" className={inputClassName} />
          </Row>
        ))}
    </div>
  );
};

export default SearchSkeleton;
