import {
  Collapse,
  ColumnsType,
  Table,
} from "@thepiquelab/archus-components-web";
import { TablePaginationConfig } from "antd";
import { ReactElement, useMemo } from "react";
import { TableItemSkeleton } from "../Skeleton/TableSkeleton";

export interface CollapseTableItemProps<T> {
  className?: string;
  extra?: React.ReactNode;
  header: string | React.ReactNode;
  onChange?: (panelKey: string[]) => void;
  columns: ColumnsType<T>;
  dataSource: T[];
  loading?: boolean;
  panelKey?: string;
  pagination?: TablePaginationConfig;
  onTableChange?: (pagination: TablePaginationConfig) => void;
}

const TableItem = <T extends Record<string, any>>(
  props: CollapseTableItemProps<T>
): ReactElement => {
  const {
    className = "",
    extra,
    header,
    columns = [],
    loading,
    dataSource,
    panelKey,
    pagination = false,
    onChange,
    onTableChange,
  } = props;

  const width = useMemo(
    () =>
      columns
        .map((c) => c.width || 100)
        .reduce((w1: number, w2: number) => w1 + w2, 0),
    [columns]
  );

  return (
    <Collapse
      onChange={onChange}
      bordered={false}
      className={`bg-white shadow rounded-md ${className}`}
    >
      <Collapse.Panel
        header={header}
        key={panelKey}
        extra={extra}
        className="no-padding"
      >
        <Table
          rowKey={"id"}
          scroll={{ x: width }}
          columns={columns}
          dataSource={dataSource}
          pagination={pagination}
          loading={{
            indicator: (
              <div className="w-full contents opacity-0">
                <TableItemSkeleton key={1} header active />
                <TableItemSkeleton key={2} active />
                <TableItemSkeleton key={3} active />
              </div>
            ),
            wrapperClassName: "spin-opaque-mask",
            spinning: loading,
          }}
          onChange={(paginationConfig) => {
            if (paginationConfig) {
              onTableChange?.(paginationConfig);
            }
          }}
        />
      </Collapse.Panel>
    </Collapse>
  );
};

export default TableItem;
