import { ReactComponent as GenerateNext } from "@/assets/images/GenerateNext.svg";
import { ReactComponent as IconClose } from "@/assets/images/IconCircleClose.svg";
import { ReactComponent as IconSend } from "@/assets/images/IconSend.svg";
import { ReactComponent as IconStop } from "@/assets/images/IconStop.svg";
import { ReactComponent as IconWhatsApp } from "@/assets/images/IconWhatsApp.svg";
import AuthorizedByPermission, {
  EnumAuthHandleType,
} from "@/components/authorize/AuthorizedByPermission";
import { EnumPermissions } from "@/components/authorize/Permissions";
import { useConfirmModal } from "@/components/common/ConfirmModal/NewConfirmModal";
import { Action } from "@/components/common/DropdownAction/DropdownAction";
import FeatureWrapper from "@/components/settings/Features/FeatureWrapper";
import {
  KEYWORD_ENUM,
  useFeatureHook,
} from "@/components/settings/Features/utils";
import { InvoiceStatus } from "@/graphql";
import { useAccessControl } from "@/hooks/useAccessControl";
import useAuth from "@/hooks/useAuth";
import { useCheckTaxRateForCurrentYear } from "@/hooks/useCheckTaxRateForCurrentYear";
import formatErrorMessage from "@/utils/formatErrorMessage";
import {
  CaretDownOutlined,
  ExportOutlined,
  PrinterFilled,
  SyncOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Menu, message } from "antd";
import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useRegenerateInvoice, useVoidInvoice } from "../../FinanceHooks";
import { useExportInvoicePdf } from "../../FinanceHooks/useExportPdfHook";
import { useGenerateNextInvoiceHandler } from "../../hooks/useGenerateNextInvoice";
import { InvoiceActionType, InvoiceDetailType } from "./InvoiceDetailTypes";
import { useMarkOnHoldHandler } from "./useMarkOnHoldModal";

interface Props {
  invoiceId: string;
  status: InvoiceStatus;
  isOtherFee?: boolean;
  isOnHold?: boolean;
  callback?: (type?: string) => void;
  invoice?: InvoiceDetailType;
}

const onHoldErrorMessage =
  "This invoice is currently on hold. Please remove the 'On Hold' tag before proceeding.";
const InvoiceButtons: React.FC<Props> = (props) => {
  const { invoiceId, isOnHold, isOtherFee, status, callback, invoice } = props;

  const { modal, setModalProps, show, close } = useConfirmModal();
  const { exportPdf, printPdf } = useExportInvoicePdf();
  const [regenerateInvoice, { loading: regenerateLoading }] =
    useRegenerateInvoice();
  const [voidInvoice, { loading: voidLoading }] = useVoidInvoice();
  const { modal: GenerateNextInvoiceModal, generateNextInvoice } =
    useGenerateNextInvoiceHandler({ callback, invoiceId });

  const { handleCheckTaxRate } = useCheckTaxRateForCurrentYear();

  const history = useHistory();
  const isShowEmail = useFeatureHook([KEYWORD_ENUM.global_email]);
  const isShowWhatsApp = useFeatureHook([KEYWORD_ENUM.global_whatsApp]);
  const isShowProcessAutomation = useMemo(
    () => isShowEmail || isShowWhatsApp,
    [isShowWhatsApp, isShowEmail]
  );
  const { modal: MarkOnHoldModal, onMarkOnHold } = useMarkOnHoldHandler(
    invoiceId,
    callback,
    {
      isOnHold,
    }
  );
  const isVoid = status === InvoiceStatus.Void;

  const handleRegenerateInvoice = async (id: string): Promise<void> => {
    try {
      await regenerateInvoice({ variables: { id } });
      callback?.(InvoiceActionType.REGENERATE);
      close();
      message.success("Invoice Regenerated Successfully!");
    } catch (e) {
      message.error(formatErrorMessage(e));
    }
  };

  const handleVoidInvoice = async (id: string): Promise<void> => {
    try {
      await voidInvoice({ variables: { id } });
      callback?.("void");
      close();
    } catch (error) {
      message.error(formatErrorMessage(error));
    }
  };

  const handleShowConfirm = (id: string): void => {
    setModalProps({
      // title: "Regenerate Invoice",
      // contentTitle: "Are you sure you want to regenerate invoice?",
      onConfirm: () => handleRegenerateInvoice(id),
      // onCancel: () => close(),
      title: "Confirmation",
      contentDesc: "Are you sure you want to proceed?",
      onClose: close,
      confirmLoading: regenerateLoading,
    });
    show();
  };

  const handleVoidInvoiceConfirm = (id: string): void => {
    setModalProps({
      onConfirm: () => handleVoidInvoice(id),
      title: "Confirmation",
      contentDesc: "Are you sure you want to proceed?",
      onClose: close,
      confirmLoading: voidLoading,
    });
    show();
  };

  const handleOnHold = (id: string): void => {
    onMarkOnHold();
  };

  const sendPaymentAdviceAction: Action = {
    icon: <IconWhatsApp className="w-5 h-5" />,
    label: "Send Payment Advice",
    key: "send-payment-advice",
    onClick() {
      if (isOnHold) {
        message.error(onHoldErrorMessage);
        return;
      }
      history.push(`/finance/invoices/sendPaymentAdvice`, {
        invoiceIds: [invoiceId],
        isBulkSend: false,
      });
    },
    hide: !isShowProcessAutomation,
    permissions: [EnumPermissions.NOTIFICATION_LOG_UPDATE],
    disabled: ![InvoiceStatus.Pending, InvoiceStatus.Overdue].includes(status),
  };

  const markAsOnHoldAction: Action = {
    icon: <IconStop className="w-5 h-4" />,
    label: isOnHold ? "Remove On Hold" : "Mark as On Hold",
    key: "on-hold",
    onClick: () => handleOnHold(invoiceId),
    permissions: [EnumPermissions.INVOICE_UPDATE],
    disabled: ![InvoiceStatus.Pending, InvoiceStatus.Overdue].includes(status),
  };

  const regenerateInvoiceAction: Action = {
    icon: <SyncOutlined className="w-5 h-5" />,
    label: "Regenerate",
    key: "regenerate",
    onClick: () => {
      if (!handleCheckTaxRate()) {
        return;
      }
      if (isOnHold) {
        message.error(onHoldErrorMessage);
        return;
      }
      handleShowConfirm(invoiceId);
    },
    hide: isOtherFee,
    disabled: ![
      InvoiceStatus.Paid,
      InvoiceStatus.Pending,
      InvoiceStatus.Overdue,
    ].includes(status),
    permissions: [EnumPermissions.INVOICE_UPDATE],
  };

  const generateNextInvoiceAction: Action = {
    icon: (
      <span className="anticon anticon-printer">
        <GenerateNext className="w-5 h-5" />
      </span>
    ),
    label: "Generate Next Billing Period Invoice",
    onClick: () => generateNextInvoice(invoice),
    hide: isOtherFee,
    disabled: ![
      InvoiceStatus.Paid,
      InvoiceStatus.Pending,
      InvoiceStatus.Overdue,
    ].includes(status),
    permissions: [EnumPermissions.INVOICE_UPDATE],
  };

  const voidInvoiceAction: Action = {
    icon: (
      <span className="anticon anticon-printer">
        <IconClose className="w-5 h-4" />
      </span>
    ),
    label: "Void",
    onClick: () => {
      if (isOnHold) {
        message.error(onHoldErrorMessage);
        return;
      }
      handleVoidInvoiceConfirm(invoiceId);
    },
    hide: ![
      InvoiceStatus.Paid,
      InvoiceStatus.Pending,
      InvoiceStatus.Overdue,
    ].includes(status),
    permissions: [EnumPermissions.INVOICE_UPDATE],
  };

  const { hasAllPermissions } = useAccessControl();

  const actions = [
    sendPaymentAdviceAction,
    // collectFeesAction,
    markAsOnHoldAction,
    // printInvoiceAction,
    regenerateInvoiceAction,
    generateNextInvoiceAction,
    voidInvoiceAction,
  ].filter((action) => {
    if (action.hide) {
      return false;
    }
    if (!action.permissions || action.permissions.length === 0) {
      return true;
    }
    return hasAllPermissions(action.permissions);
  });

  const hasNotifyUpdateAuth = useAuth([
    EnumPermissions.NOTIFICATION_LOG_UPDATE,
  ]);

  return (
    <div className="flex justify-end mt-3">
      <FeatureWrapper keywords={KEYWORD_ENUM.global_exports}>
        <AuthorizedByPermission
          authHandleType={EnumAuthHandleType.HIDE}
          permissions={[EnumPermissions.INVOICE_VIEW_EXPORT]}
        >
          <Button
            icon={<ExportOutlined />}
            className="mr-2"
            onClick={() => {
              exportPdf(invoiceId);
            }}
            disabled={
              ![
                InvoiceStatus.Paid,
                InvoiceStatus.Pending,
                InvoiceStatus.Overdue,
              ].includes(status) || isVoid
            }
          />
        </AuthorizedByPermission>
      </FeatureWrapper>
      <AuthorizedByPermission
        authHandleType={EnumAuthHandleType.HIDE}
        permissions={[EnumPermissions.INVOICE_VIEW_EXPORT]}
      >
        <Button
          icon={<PrinterFilled />}
          className="mr-2"
          onClick={() => printPdf(invoiceId)}
          disabled={
            ![
              InvoiceStatus.Paid,
              InvoiceStatus.Pending,
              InvoiceStatus.Overdue,
            ].includes(status) || isVoid
          }
        />
      </AuthorizedByPermission>

      {isShowProcessAutomation && hasNotifyUpdateAuth ? (
        <Button
          icon={<IconSend className="mr-2" />}
          className={`${actions.length ? "rounded-r-none" : ""}`}
          onClick={() => {
            history.push(`/finance/invoices/sendFollowUp/${invoiceId}`);
          }}
          key="Send Reminder"
          disabled={
            ![InvoiceStatus.Pending, InvoiceStatus.Overdue].includes(status) ||
            isVoid
          }
        >
          Send Reminder
        </Button>
      ) : (
        <></>
      )}
      {actions.length ? (
        <Dropdown
          overlay={
            <Menu onClick={() => {}}>
              {actions.map((action) => (
                <Menu.Item
                  icon={action.icon}
                  key={action.key}
                  onClick={() => {
                    action.onClick(invoiceId);
                  }}
                  disabled={action.disabled}
                >
                  {action.label}
                </Menu.Item>
              ))}
            </Menu>
          }
        >
          <Button className="rounded-l-none" icon={<CaretDownOutlined />} />
        </Dropdown>
      ) : (
        <></>
      )}
      {modal}
      {MarkOnHoldModal}
      {GenerateNextInvoiceModal}
    </div>
  );
};

export default InvoiceButtons;
