import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import Loading from "../../components/common/Loading";

const InsufficientCredentialsPage = lazy(
  () => import("./InsufficientCredentialsPage")
);
const ErrorsIndex: React.FC = () => (
  <Suspense fallback={<Loading />}>
    <Switch>
      <Route path="/errors" component={InsufficientCredentialsPage} />
      <Redirect to="/errors" />
    </Switch>
  </Suspense>
);

export default ErrorsIndex;
