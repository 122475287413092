import CollapseList from "@/components/common/CollapseList/CollapseList";
import { QueryInputs } from "@/components/common/EnhancedTable/EnhancedTable";
import {
  CourseFilterInput,
  CourseStatus,
  CourseType,
  GetWaitingListOverviewCourses,
  GetWaitingListOverviewCoursesQuery,
  GetWaitingListOverviewCoursesQueryVariables,
} from "@/graphql";
import { useLoadMore } from "@/hooks/useLoadMore";
import { enumToLabelArray } from "@/utils/enumUnit";
import { SearchOutlined } from "@ant-design/icons";
import { Container, Input, Select } from "@thepiquelab/archus-components-web";
import { debounce } from "lodash";
import { FC, useEffect, useState } from "react";
import CollapseTable from "./CollapseTable";

const CourseNotificationList: FC = () => {
  const [queryCourses, { data: coursesData, loading }, { loadMore, hasMore }] =
    useLoadMore<
      GetWaitingListOverviewCoursesQuery,
      GetWaitingListOverviewCoursesQueryVariables
    >(GetWaitingListOverviewCourses, "coursesWithIndex", true, false, true, {
      fetchPolicy: "network-only",
    });

  const [query, setQuery] = useState<QueryInputs<CourseFilterInput>>({
    filterInput: {
      statuses: [
        CourseStatus.Created,
        CourseStatus.Completed,
        CourseStatus.Ongoing,
      ],
      types: [CourseType.Regular],
    },
  });

  useEffect(() => {
    queryCourses({ variables: { ...query } });
  }, [query]);

  const handleSearch = (text: string): void => {
    setQuery({
      ...query,
      filterInput: {
        ...(query?.filterInput || {}),
        name: text,
      },
    });
  };

  return (
    <Container>
      <Container.Header className="my-6">
        <Input
          allowClear
          size="large"
          className="search-input max-w-72"
          placeholder="Search"
          prefix={<SearchOutlined className="text-primary-navyLight text-5" />}
          onChange={debounce((e) => {
            handleSearch(e.target.value);
          }, 300)}
          onPressEnter={debounce((e) => {
            handleSearch(e.target.value);
          }, 100)}
          data-testid="grid-search-input"
        />

        <Select
          className="w-40 ml-2"
          size="small"
          options={enumToLabelArray(CourseType)}
          value={query?.filterInput?.types?.[0]}
          onChange={(value: CourseType) => {
            setQuery({
              ...query,
              filterInput: {
                ...(query?.filterInput || {}),
                types: [value],
              },
            });
          }}
        />
      </Container.Header>
      <Container.Content className="h-full overflow-y-auto">
        <CollapseList
          dataSource={coursesData?.coursesWithIndex?.items}
          loadMore={loadMore}
          hasMore={hasMore}
          loading={loading}
          renderItem={(item) => (
            <CollapseTable key={`item-${item?.id}`} courseData={item} />
          )}
        />
      </Container.Content>
    </Container>
  );
};

export default CourseNotificationList;
