import { SeatDisplayInfo } from "@/components/common/SeatView/types";
import {
  CapacitySeatType,
  EnrollmentStatus,
  EnrollmentType,
  LessonCapacityDto,
} from "@/graphql";
import { ClassCapacityDTO } from "./classUtils";

export type SeatsType = [SeatDisplayInfo, SeatDisplayInfo?][];

export interface SeatListProps {
  type: "Regular" | "Replacements" | "Bypass";
  dataSource: SeatsType;
  useSeatNumber?: number;
  waitingList?: string;
}
export interface SeatCardProps {
  type: "Regular" | "Replacements" | "Bypass";
  dataSource: SeatsType;
  useSeatNumber?: number;
  className?: string;
}

export interface SeatListTabProps {
  capacity: ClassCapacityDTO;
  reverse?: boolean;
  dueDate?: Date;
  isDisplayEnrollNumber?: boolean;
  enrollments?: { _id: string; type: EnrollmentType }[];
  excludeHolding?: boolean;
  excludeReservation?: boolean;
  studentId?: string;
}
export interface SeatLessonListViewProps {
  capacity: LessonCapacityDto;
  reverse?: boolean;
  studentId?: string;
  dueDate?: Date;
  isDisplayEnrollNumber?: boolean;
  excludeHolding?: boolean;
  excludeReservation?: boolean;
  UseWaitingListNumber?: number;
}

export const SeatTextColor = {
  Empty: "#DCDEEA",
  Regular: "#5B9DFF",
  Holding: "#4F537B",

  Trial: "#FFC300",
  Reservation: "#FFD60A",

  TransferIn: "#64DD17",

  TransferOut: "#F36B7F",
  Withdraw: "#F36B7F",

  Paid: "#64DD17",
  Unpaid: "#F3C342",
  MakeUpIn: "#948BFF",
  Additional: "#F342D7",
  WaitingList: "#4F537B",
};

export const SeatColor = {
  Empty: "#DCDEEA",
  Regular: "#5B9DFF",
  Holding: "#B8BBC1",
  Trial: "#F3C342",
  Reservation: "#F3C342",
  TransferIn: "#64DD17",
  TransferOut: "#F36B7F",
  Withdraw: "#F36B7F",

  Paid: "#64DD17",
  Unpaid: "#FFE8AE",
  MakeUpIn: "#948BFF",
  Additional: "#F342D7",

  WaitingList: "#ECBE9F",

  Request: "#FF7324",
};

export const SeatBgColor = {
  Holding: "#F4F6FC",
  Trial: "#FFF3CC",
  Reservation: "#FFF7CE",
  TransferIn: "#EBF8E8",
  TransferOut: "#F9EDF2",
  Withdraw: "#F9EDF2",
  Paid: "#EBF8E8",
  Unpaid: "#F9F5EC",
  MakeUpIn: "#EAE8FF",
  Additional: "#EAE8FF",
  WaitingList: "#ECBE9F",
};

export const enrollmentHoldingCapacity = {
  status: EnrollmentStatus.Holding,
  label: "Holding",
  color: SeatColor.Holding,
  bgColor: SeatBgColor.Holding,
  textColor: SeatTextColor.Holding,
  text: "Holding",
};

export const enrollmentReservedCapacity = {
  status: EnrollmentStatus.Active,
  template: "Reserved on {{date}}",
  label: "Reservation",
  color: SeatColor.Reservation,
  bgColor: SeatBgColor.Reservation,
  textColor: SeatTextColor.Reservation,
  text: "Reserved",
};

export const enrollmentRequestCapacity = {
  status: EnrollmentStatus.Active,
  template: "Request",
  label: "Request",
  color: SeatColor.Request,
  bgColor: SeatColor.Request,
  textColor: SeatColor.Request,
  text: "Request",
};

export const enrollmentUnpaidCapacity = {
  status: EnrollmentStatus.Active,
  label: "Incoming",
  color: SeatColor.Unpaid,
  bgColor: SeatBgColor.Unpaid,
  textColor: SeatTextColor.Unpaid,
  template: "Start on {{date}}",
  hasPaid: false,
  text: "Unpaid",
};

export const enrollmentTransferInCapacity = {
  status: EnrollmentStatus.Active,
  label: "Transfer In",
  template: "Transferring In on {{date}}",
  color: SeatColor.TransferIn,
  bgColor: SeatBgColor.TransferIn,
  textColor: SeatTextColor.TransferIn,
  text: "Transferring In",
};

export const enrollmentRegularCapacity = {
  [`${CapacitySeatType.Regular}`]: [
    {
      // status: EnrollmentStatus.Pending,
      status: EnrollmentStatus.Active,
      label: "Incoming",
      template: "Start on {{date}}",
      color: SeatColor.Paid,
      bgColor: SeatBgColor.Paid,
      textColor: SeatTextColor.Paid,
      text: "Paid",
      checkFirstLesson: false,
    },
    {
      status: EnrollmentStatus.Active,
      label: "Active",
      color: SeatColor.Regular,
      template: "Regular",
      checkFirstLesson: true,
    },
  ],
};

export const enrollmentTransferOutCapacity = {
  status: EnrollmentStatus.Complete,
  label: "Transfer Out",
  template: "Transferred Out on {{date}}",
  color: SeatColor.TransferOut,
  bgColor: SeatBgColor.TransferOut,
  textColor: SeatTextColor.TransferOut,
  text: "Transferred Out",
  hasPaid: true,
};
export const enrollmentWithdrawingCapacity = {
  status: EnrollmentStatus.Complete,
  label: "Withdrawn",
  template: "Withdrawing on {{date}}",
  color: SeatColor.Withdraw,
  bgColor: SeatBgColor.Withdraw,
  textColor: SeatTextColor.Withdraw,
  text: "Withdrawing",
  hasPaid: true,
};

export const enrollmentWaitingListCapacity = {
  status: EnrollmentStatus.Complete,
  label: "Waiting List",
  template: "Waiting List",
  color: SeatColor.WaitingList,
  bgColor: SeatBgColor.WaitingList,
  textColor: SeatTextColor.WaitingList,
  text: "Waiting List",
  hasPaid: true,
};
