import { ReactComponent as IconAlert } from "@/assets/images/IconAlert.svg";
import { ReactComponent as IconCheck } from "@/assets/images/IconCheckCircleFill.svg";
import { ReactComponent as IconWarning } from "@/assets/images/IconWarning.svg";
import { EnumPermissions } from "@/components/authorize/Permissions";
import CollapseList from "@/components/common/CollapseList/CollapseList";
import { useConfirmModal } from "@/components/common/ConfirmModal/NewConfirmModal";
import DropdownAction, {
  Action,
} from "@/components/common/DropdownAction/DropdownAction";
import {
  EnumEnhancedTableWidthType,
  enumEnhancedTableWidthType,
} from "@/components/common/EnhancedTable/EnhancedTable";
import { addRanking } from "@/components/registration/CheckWaitingList/utils";
import { registrationStatusMap } from "@/components/registration/ListRegistration/ListRegistration";
import { useCancelRegistration } from "@/components/registration/RegistrationHooks";
import { useSetWaitingListRegistration } from "@/components/registration/SetWaitingListRegistration/useSetWaitingListRegistration.hook";
import CommonBadge from "@/components/widget/Badge/CommonBadge";
import StudentLink from "@/components/widget/CommonLink/StudentLink";
import {
  GetWaitingListOverviewRegistrationsQuery,
  RegistrationsStatus,
  UpdateRegistrationInput,
  WaitingListUpdateHistory,
} from "@/graphql";
import { useCheckTaxRateForCurrentYear } from "@/hooks/useCheckTaxRateForCurrentYear";
import formatErrorMessage from "@/utils/formatErrorMessage";
import formatLabel from "@/utils/formatLabel";
import {
  CheckCircleOutlined,
  CloseCircleFilled,
  CloseCircleOutlined,
  LikeFilled,
} from "@ant-design/icons";
import {
  ColumnsType,
  Spin,
  Tooltip,
  Typography,
  message,
} from "@thepiquelab/archus-components-web";
import { formatDate, formatTime } from "@thepiquelab/archus-library";
import cx from "classnames";
import { FC, memo, useEffect, useMemo, useState } from "react";
import {
  useLazyQueryWaitingListOverviewRegistrations,
  usePrioritizeRegistration,
} from "../hooks";

interface Props {
  seatsLeft?: number;
  classData?: {
    id: string;
    name?: string;
  };
  className?: string;
}

const formatUpdateHistory = (item: WaitingListUpdateHistory): string => {
  if (
    !item?.originalStatus &&
    item?.status === RegistrationsStatus.Waitlisted
  ) {
    return `Waiting list created at ${formatDate(item?.timestamp, {
      format: "SHORT",
    })}, ${formatTime(item?.timestamp)}`;
  }
  if (
    item?.originalStatus === RegistrationsStatus.Waitlisted &&
    item?.status === RegistrationsStatus.Cancelled
  ) {
    return `Waiting list cancelled at ${formatDate(item?.timestamp, {
      format: "SHORT",
    })}, ${formatTime(item?.timestamp)}`;
  }
  if (
    item?.originalStatus === RegistrationsStatus.Waitlisted &&
    item?.status === RegistrationsStatus.Reserved
  ) {
    return `Waiting list updated to Reserved at ${formatDate(item?.timestamp, {
      format: "SHORT",
    })}, ${formatTime(item?.timestamp)}`;
  }
  if (
    item?.originalStatus === RegistrationsStatus.Reserved &&
    item?.status === RegistrationsStatus.Complete
  ) {
    return `Reservation updated to Complete at ${formatDate(item?.timestamp, {
      format: "SHORT",
    })}, ${formatTime(item?.timestamp)}`;
  }
  if (
    item?.originalStatus === RegistrationsStatus.Reserved &&
    item?.status === RegistrationsStatus.Waitlisted
  ) {
    return `Reservation updated to Waitlisted at ${formatDate(item?.timestamp, {
      format: "SHORT",
    })}, ${formatTime(item?.timestamp)}`;
  }
  if (
    item?.originalStatus === RegistrationsStatus.Reserved &&
    item?.status === RegistrationsStatus.Expired
  ) {
    return `Reservation updated to Expired at ${formatDate(item?.timestamp, {
      format: "SHORT",
    })}, ${formatTime(item?.timestamp)}`;
  }
  if (
    item?.originalStatus === RegistrationsStatus.Reserved &&
    item?.status === RegistrationsStatus.Cancelled
  ) {
    return `Reservation cancelled at ${formatDate(item?.timestamp, {
      format: "SHORT",
    })}, ${formatTime(item?.timestamp)}`;
  }
  if (
    item?.originalStatus === RegistrationsStatus.Complete &&
    item?.status === RegistrationsStatus.Cancelled
  ) {
    return `Registration cancelled at ${formatDate(item?.timestamp, {
      format: "SHORT",
    })}, ${formatTime(item?.timestamp)}`;
  }

  return "";
};

const CollapseTable: FC<Props> = (props) => {
  const { seatsLeft, classData, className = "" } = props;

  const [queryRegistrations, { data: registrationsData, loading, refetch }] =
    useLazyQueryWaitingListOverviewRegistrations();

  const [cancelRegistration, { loading: cancelLoading }] =
    useCancelRegistration();

  const [prioritizeRegistration, { loading: prioritizeLoading }] =
    usePrioritizeRegistration();

  const { modal, show, close, setModalProps } = useConfirmModal();

  const { handleCheckTaxRate } = useCheckTaxRateForCurrentYear();

  const {
    modal: waitingListModal,
    loading: waitingListLoading,
    setModalProps: waitingListSetModalProps,
  } = useSetWaitingListRegistration();

  const [actionId, setActionId] = useState<string>();

  const columns: ColumnsType<
    GetWaitingListOverviewRegistrationsQuery["registrations"]["items"][0]
  > = [
    {
      title: "Student Name",
      dataIndex: "student.name",
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.M_MEDIUM],
      render: (text, record) => (
        <div className="flex flex-col">
          <StudentLink id={record?.student?.id}>
            {record?.student?.fullName}
          </StudentLink>
          {record?.isPrioritized ? (
            <CommonBadge
              textColor={"#F36B7F"}
              bgColor={"#F36B7F33"}
              label={"Prioritized"}
              className="w-maxcontent mt-1"
            />
          ) : (
            <></>
          )}
        </div>
      ),
    },
    {
      title: "Student ID",
      dataIndex: "student.userId",
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render: (text, record) => <>{record?.student?.userId}</>,
    },
    {
      title: "Enrollment No.",
      dataIndex: "name",
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
    },
    {
      title: "Ranking",
      dataIndex: "ranking",
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render: (text) => <>{text ?? "-"}</>,
    },
    {
      title: "Status",
      dataIndex: "status",
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render: (text, record) => {
        const status = registrationStatusMap.get(record.status ?? "");
        const seatAvailable = Number(seatsLeft) > 0;
        return (
          <div className="inline-flex items-center">
            <Typography.Heading
              className="mr-2"
              level={4}
              style={{ color: status?.color }}
            >
              {status?.text}
            </Typography.Heading>
            {record?.status === RegistrationsStatus.Waitlisted && (
              <Tooltip
                title={formatLabel(
                  seatAvailable ? "Seat Available" : "Seat Unavailable"
                )}
              >
                {seatAvailable ? (
                  <IconCheck className="text-semantics-green" />
                ) : (
                  <IconAlert />
                )}
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      title: "Enrollment Start Date",
      dataIndex: "startDate",
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.M_MEDIUM],
      render: (text) => <>{text ? formatDate(text) : "-"}</>,
    },
    {
      title: "Waiting List Created at",
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.M_MEDIUM],
      dataIndex: "waitingListCreatedAt",
      render: (text) => (
        <>
          {text
            ? `${formatDate(text, { format: "SHORT" })}, ${formatTime(text)}`
            : "-"}
        </>
      ),
    },
    {
      title: "Update History",
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.L_LARGE],
      dataIndex: "updateHistories",
      render: (text: WaitingListUpdateHistory[]) => (
        <>
          {text?.length
            ? text?.map((i) => <div>{formatUpdateHistory(i)}</div>)
            : "-"}
        </>
      ),
    },
    {
      title: "Options",
      align: "center",
      render: (text, record) => {
        const { Waitlisted, Cancelled, Expired } = RegistrationsStatus;
        let actions: Action[] = [cancelAction(record.status)];

        if (record?.status === Waitlisted) {
          actions = [approveAction, ...actions];

          if (!record?.isPrioritized) {
            actions = [...actions, markAsPrioritizedAction];
          } else {
            actions = [...actions, unmarkAsPrioritizedAction];
          }
        }

        if ([Cancelled, Expired]?.includes(record?.status)) {
          actions = [];
        }

        return (
          <Spin spinning={waitingListLoading && actionId === record?.id}>
            <DropdownAction actions={actions} id={record?.id} />
          </Spin>
        );
      },
    },
  ];

  const markAsPrioritizedAction: Action = {
    label: "Mark as Prioritized",
    icon: <CheckCircleOutlined />,
    onClick: (id: string) => {
      handleShowConfirmationModal(() => {
        onPrioritize({ id, isPrioritized: true });
      });
    },
  };
  const unmarkAsPrioritizedAction: Action = {
    label: "Unmark as Prioritized",
    icon: <CloseCircleOutlined />,
    onClick: (id: string) => {
      handleShowConfirmationModal(() => {
        onPrioritize({ id, isPrioritized: false });
      });
    },
  };
  const approveAction: Action = {
    icon: <LikeFilled />,
    label: "Change Waiting List to Reservation",
    permissions: [EnumPermissions.REGISTRATION_UPDATE],
    onClick: (id: string) => {
      setActionId(id);
      waitingListSetModalProps({ registrationId: id });
    },
  };
  const cancelAction: (status: RegistrationsStatus) => Action = (status) => {
    let label = "Cancel Registration";
    if (status === RegistrationsStatus.Reserved) {
      label = "Cancel Reservation";
    }
    if (status === RegistrationsStatus.Waitlisted) {
      label = "Remove From Waiting List";
    }
    return {
      icon: <CloseCircleFilled />,
      label,
      permissions: [EnumPermissions.REGISTRATION_UPDATE],
      onClick: (id: string) => {
        if (
          [RegistrationsStatus.Complete, RegistrationsStatus.Paid].includes(
            status
          )
        ) {
          if (!handleCheckTaxRate()) return;
        }
        handleShowConfirmationModal(() => {
          onCancel(id);
        });
      },
    };
  };

  const handleShowConfirmationModal = (onConfirm?: () => void): void => {
    setModalProps({
      title: "Confirmation",
      contentDesc: "Are you sure you want to proceed?",
      onConfirm,
      onClose: close,
    });
    show();
  };

  useEffect(() => {
    setModalProps({ confirmLoading: cancelLoading || prioritizeLoading });
  }, [cancelLoading, prioritizeLoading]);

  const dataSource = useMemo(() => {
    if (!registrationsData?.registrations?.items?.length) {
      return [];
    }

    const registrations = addRanking(registrationsData?.registrations?.items);

    return registrations;
  }, [registrationsData]);

  const onChange = (key: string[]): void => {
    if (key.length > 0) {
      queryRegistrations({
        variables: {
          filterInput: { classIds: key, isWaitlisted: true },
        },
      });
    }
  };

  const onCancel = async (id: string): Promise<void> => {
    try {
      await cancelRegistration({ variables: { id } });
      refetch();
      close();
      message.success("Registration Cancelled Successfully!");
    } catch (e) {
      message.error(formatErrorMessage(e));
    }
  };

  const onPrioritize = async (
    input: UpdateRegistrationInput
  ): Promise<void> => {
    try {
      await prioritizeRegistration({ variables: { input } });
      refetch();
      close();
    } catch (e) {
      message.error(formatErrorMessage(e));
    }
  };

  return (
    <>
      <CollapseList.TableItem
        header={
          <div className="w-10/12 truncate leading-7">{classData?.name}</div>
        }
        columns={columns}
        dataSource={dataSource as any}
        loading={loading}
        onChange={onChange}
        className={className}
        extra={
          <div className="flex items-center min-w-36 justify-end">
            Seats Left:&nbsp;
            <div
              className={cx("font-semibold flex justify-start items-center", {
                "text-red-400": Number(seatsLeft) === 0,
              })}
            >
              {seatsLeft}
              {Number(seatsLeft) < 0 && (
                <Tooltip
                  placement="bottom"
                  title={formatLabel("over capacity")}
                >
                  <IconAlert className="ml-2 w-6" />
                </Tooltip>
              )}
              {Number(seatsLeft) > 0 && Number(seatsLeft) <= 2 && (
                <Tooltip placement="bottom" title={formatLabel("almost full")}>
                  <IconWarning className="ml-2 w-6" />
                </Tooltip>
              )}
            </div>
          </div>
        }
        panelKey={classData?.id}
      />
      {modal}
      {waitingListModal}
    </>
  );
};

export default memo(CollapseTable);
