import { ReactComponent as IconCompleteTask } from "@/assets/images/IconCheck.svg";
import { ReactComponent as IconReopen } from "@/assets/images/IconRefresh.svg";
import { useConfirmModal } from "@/components/common/ConfirmModal/NewConfirmModal";
import { TaskFieldsFragment, TaskStatus, UpdateTaskInput } from "@/graphql";
import formatErrorMessage from "@/utils/formatErrorMessage";
import formatLabel from "@/utils/formatLabel";
import { Button, Checkbox, DatePicker, Form, Input, Spin, message } from "antd";
import moment from "moment";
import { ReactNode, useEffect, useState } from "react";
import AssigneeSelect from "../Checklist/AssigneeSelect";
import {
  useCompleteTasks,
  useReopenTask,
  useUpdateFollowTaskDueDate,
  useUpdateTask,
} from "../hooks";
import AssigneeAvatars from "./AssigneeAvatars";

interface TaskDetailProps {
  taskDetail: TaskFieldsFragment;
  callback?: () => Promise<void> | void;
  refetchTask: () => void;
  refetchTaskLoading: boolean;
  onClose?: () => void;
}

const TaskDetail: React.FC<TaskDetailProps> = (props) => {
  const { taskDetail, callback, refetchTask, refetchTaskLoading, onClose } =
    props;

  const isReadonly = [TaskStatus.Complete, TaskStatus.Void].includes(
    taskDetail?.status
  );

  const [attributeEditMap, setAttributeEditMap] = useState<{
    [propName: string]: boolean;
  }>({});
  const [updateTask, { data, loading: updateTaskLoading }] = useUpdateTask();
  const [updateDueDate, { loading: updateDueDateLoading }] =
    useUpdateFollowTaskDueDate();

  const { modal, show, close, setModalProps } = useConfirmModal();

  const [reopenTask, { loading: reopenLoading }] = useReopenTask();

  const [completeTasks, { loading: completeLoading }] = useCompleteTasks();
  const [form] = Form.useForm();

  const loading =
    updateTaskLoading || refetchTaskLoading || updateDueDateLoading;

  const formCol = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };
  const onButtonGroupClicked = async (
    buttonKey: string,
    attributeName: string
  ): Promise<void> => {
    if (buttonKey === "edit") {
      setAttributeEditMap({
        ...attributeEditMap,
        [attributeName]: true,
      });
    } else if (buttonKey === "cancel") {
      setAttributeEditMap({
        ...attributeEditMap,
        [attributeName]: false,
      });
      form.setFieldsValue({
        name: taskDetail?.name,
        dueDate: taskDetail?.dueDate ? moment(taskDetail?.dueDate) : null,
        assignee: taskDetail?.assignees?.map((assignee) => assignee?._id),
        description: taskDetail?.description,
      });
    } else if (buttonKey === "save") {
      const variables: Partial<UpdateTaskInput> = {};
      if (attributeName === "name") {
        variables.name = form.getFieldValue("name");
      }
      if (attributeName === "dueDate") {
        try {
          await updateDueDate({
            variables: {
              id: taskDetail?.id,
              dueDate: moment(form.getFieldValue("dueDate"))
                ? moment(form.getFieldValue("dueDate")).toISOString()
                : null,
            },
          });
          setAttributeEditMap({
            ...attributeEditMap,
            [attributeName]: false,
          });
          await refetchTask();
          callback?.();
        } catch (error) {
          message.error(formatErrorMessage(error));
        }
        return;
      }
      if (attributeName === "assignee") {
        variables.assigneeIds = form.getFieldValue("assignee");
      }
      if (attributeName === "description") {
        variables.description = form.getFieldValue("description") || null;
      }
      try {
        await updateTask({
          variables: {
            input: {
              ...variables,
              id: taskDetail?.id,
            },
          },
        });
        setAttributeEditMap({
          ...attributeEditMap,
          [attributeName]: false,
        });
        await refetchTask();
        callback?.();
      } catch (error) {
        message.error(formatErrorMessage(error));
      }
    }
  };
  const renderButtons = (attributeName: string): ReactNode => {
    if (isReadonly) return <></>;
    const isEditing = attributeEditMap[attributeName];
    const isLoading = loading && isEditing;

    return (
      <div className="flex gap-2 mb-6 justify-between">
        {isEditing && (
          <>
            <Button
              key="cancel"
              onClick={() => {
                onButtonGroupClicked("cancel", attributeName);
              }}
            >
              Cancel
            </Button>
            <Button
              key="save"
              onClick={() => {
                onButtonGroupClicked("save", attributeName);
              }}
              type="primary"
              loading={isLoading}
            >
              Save
            </Button>
          </>
        )}
        {!isEditing && (
          <Button
            key="edit"
            onClick={() => {
              onButtonGroupClicked("edit", attributeName);
            }}
            loading={isLoading}
          >
            Edit
          </Button>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (taskDetail) {
      form.setFieldsValue({
        name: taskDetail?.name,
        dueDate: taskDetail?.dueDate ? moment(taskDetail?.dueDate) : null,
        assignee: taskDetail?.assignees?.map(
          (assigneeItem) => assigneeItem?._id
        ),
        description: taskDetail?.description,
        isTemplate: taskDetail?.isTemplate,
      });
    }
  }, [taskDetail]);

  const handleReopenTask = async (): Promise<void> => {
    try {
      setModalProps({ confirmLoading: true });
      await reopenTask({ variables: { id: taskDetail?.id } });
    } catch (error) {
      message.error(formatErrorMessage(error));
    } finally {
      close();
      await refetchTask();
      setModalProps({ confirmLoading: false });
      callback?.();
      onClose?.();
    }
  };

  return (
    <>
      <Form
        form={form}
        name="task-detail-form"
        onValuesChange={(changedValues) => {
          if (Object.keys(changedValues).includes("isTemplate")) {
            const markAsTemplate = async () => {
              setAttributeEditMap({
                ...attributeEditMap,
                isTemplate: true,
              });
              try {
                await updateTask({
                  variables: {
                    input: {
                      isTemplate: changedValues.isTemplate,
                      id: taskDetail?.id,
                    },
                  },
                });
                setAttributeEditMap({
                  ...attributeEditMap,
                  isTemplate: false,
                });
              } catch (error) {
                message.error(formatErrorMessage(error));
              }
            };
            try {
              markAsTemplate();
            } catch (e) {
              console.error(e);
            } finally {
              refetchTask();
              setAttributeEditMap({
                ...attributeEditMap,
                isTemplate: false,
              });
              callback?.();
            }
          }
        }}
      >
        <div className="flex justify-between mb-6">
          <div className="flex gap-2">
            <Spin spinning={updateTaskLoading && attributeEditMap.isTemplate}>
              <Form.Item
                name="isTemplate"
                valuePropName="checked"
                className="flex items-center justify-items-center no-margin"
              >
                <Checkbox>Make Template</Checkbox>
              </Form.Item>
            </Spin>
          </div>
          <div className="">
            {taskDetail?.status === TaskStatus.Complete ? (
              <Button
                loading={reopenLoading}
                icon={<IconReopen className="w-5 h-4 mr-1" />}
                onClick={() => {
                  setModalProps({
                    onConfirm: handleReopenTask,
                    onClose: close,
                  });
                  show();
                }}
              >
                Reopen Task
              </Button>
            ) : (
              <Button
                icon={
                  <span className="anticon anticon-printer">
                    <IconCompleteTask className="w-5 h-4" />
                  </span>
                }
                disabled={isReadonly}
                loading={completeLoading}
                onClick={() => {
                  setModalProps({
                    onConfirm: async () => {
                      try {
                        setModalProps({ confirmLoading: true });
                        await completeTasks({
                          variables: {
                            command: {
                              type: taskDetail.type,
                              ids: [taskDetail?.id],
                            },
                          },
                        });
                      } catch (e) {
                        message.error(formatErrorMessage(e));
                      } finally {
                        close();
                        await refetchTask();
                        setModalProps({ confirmLoading: false });
                        callback?.();
                        onClose?.();
                      }
                    },
                    onClose: close,
                  });
                  show();
                }}
              >
                Mark As Complete
              </Button>
            )}
          </div>
        </div>
        <div className=" font-semibold ant-form-item-required">Task Name</div>
        <div className="flex content-between items-center justify-items-center justify-between gap-6">
          <Form.Item
            name="name"
            rules={[
              { required: true, message: formatLabel("Field is required.") },
            ]}
            {...formCol}
            className="flex-grow"
          >
            {attributeEditMap.name && !isReadonly ? (
              <Input maxLength={200} />
            ) : (
              <div>{taskDetail?.name}</div>
            )}
          </Form.Item>
          {renderButtons("name")}
        </div>
        <div className="font-semibold">Task Due At</div>
        <div className="flex content-between items-center justify-items-center justify-between gap-6">
          <Form.Item name="dueDate" {...formCol} className="flex-grow">
            {attributeEditMap.dueDate && !isReadonly ? (
              <DatePicker className="w-full" allowClear format={"DD/MM/YYYY"} />
            ) : (
              <div>
                {taskDetail?.dueDate ? (
                  moment(taskDetail?.dueDate).format("DD/MM/YYYY")
                ) : (
                  <></>
                )}
              </div>
            )}
          </Form.Item>
          {renderButtons("dueDate")}
        </div>
        <div className="font-semibold">Assignee</div>
        <div className="flex content-between items-center justify-items-center justify-between gap-6">
          <Form.Item name="assignee" {...formCol} className="flex-grow">
            {attributeEditMap.assignee && !isReadonly ? (
              <AssigneeSelect />
            ) : (
              <div className="flex gap-2 flex-wrap">
                {taskDetail?.assignees?.length > 0 ? (
                  <AssigneeAvatars assignees={taskDetail?.assignees} />
                ) : (
                  <></>
                )}
              </div>
            )}
          </Form.Item>
          {renderButtons("assignee")}
        </div>
        <div className="font-semibold">Description</div>
        <div className="flex content-between items-center justify-items-center justify-between gap-6">
          <Form.Item
            name="description"
            {...formCol}
            className="flex-grow"
            rules={[
              {
                min: 1,
                message: formatLabel("Field is required."),
              },
            ]}
          >
            {attributeEditMap.description && !isReadonly ? (
              <Input.TextArea className="w-full" />
            ) : (
              <div>{taskDetail?.description}</div>
            )}
          </Form.Item>
          {renderButtons("description")}
        </div>
      </Form>
      {modal}
    </>
  );
};

export default TaskDetail;
