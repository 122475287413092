import { FilterOptionSystemUserDTO } from "@/apis/user/types";
import { UserApi } from "@/apis/user/user.api";
import { useSalutation } from "@/hooks/reference.hook";
import { useRequest } from "ahooks";
import { useMemo } from "react";
import { OptionType } from "../types";

export const useWithoutTeacherOptions = (): {
  options: OptionType[];
  loading: boolean;
  users: FilterOptionSystemUserDTO[];
} => {
  const { formatSalutation } = useSalutation();
  const { loading, data: withoutTeacherOptions = [] } = useRequest(
    () => UserApi.getWithoutTeacherOptions(),
    {
      throwOnError: true,
      cacheKey: "without-teacher-options",
      staleTime: 5000,
    }
  );

  const options = useMemo(
    () =>
      withoutTeacherOptions.map((i) => ({
        label: formatSalutation(i.salutation, i.fullName),
        value: i._id,
      })),
    [withoutTeacherOptions]
  );

  return {
    options,
    loading,
    users: withoutTeacherOptions,
  };
};
