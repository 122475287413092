import { ReactComponent as IconInfo } from "@/assets/images/IconInfo.svg";
import { AdvanceTableColumnType } from "@/components/common/AdvanceTable/AdvanceTable";
import {
  EnumEnhancedTableWidthType,
  enumEnhancedTableWidthType,
} from "@/components/common/EnhancedTable/EnhancedTable";
import { InvoiceStatusMap } from "@/components/finance/Modules/InvoiceDetail/InvoiceDetailCard";
import { useInvoiceDetailModal } from "@/components/finance/Modules/InvoiceDetail/InvoiceDetailModal";
import { TaskFieldsFragment, TaskType } from "@/graphql";
import formatLabel from "@/utils/formatLabel";
import { Space, Tooltip, Typography } from "@thepiquelab/archus-components-web";
import { formatDateTime } from "@thepiquelab/archus-library";
import TaskTable, { CustomCommonColumn } from "./TaskTable";
import { CreationContextTypeMap } from "./TaskTableUtil";
import { TaskTableProps } from "./types";

const TermBillingFollowUpTask: React.FC<TaskTableProps> = (props) => {
  const { data, callback, visible, pagination, count, loading } = props;
  const { invoiceDetailModal, setInvoiceDetailModalProps, show } =
    useInvoiceDetailModal();

  const columns: AdvanceTableColumnType[] = [
    {
      title: formatLabel("Task Name"),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.M_MEDIUM],
      render(value, record: TaskFieldsFragment) {
        const { creationContext } = record;
        const creationContextType = creationContext?.type;
        const text = CreationContextTypeMap[creationContextType] || "-";
        return <>{text}</>;
      },
    },
    CustomCommonColumn.StudentName,
    CustomCommonColumn.StudentID,
    CustomCommonColumn.PrimaryParentName,
    CustomCommonColumn.PrimaryParentMobile,
    CustomCommonColumn.PrimaryParentWALine,
    {
      title: formatLabel("Invoice No."),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(value, record: TaskFieldsFragment) {
        const { invoice } = record;
        const { referenceNumber, id } = invoice || {};
        return referenceNumber && id ? (
          <div
            className="text-primary-blue cursor-pointer"
            onClick={() => {
              setInvoiceDetailModalProps({
                invoiceId: id,
                showBillTitle: true,
                callback: () => {
                  callback();
                },
              });
              show();
            }}
          >
            {referenceNumber}
          </div>
        ) : (
          <>-</>
        );
      },
    },
    {
      title: formatLabel("Due On"),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.M_MEDIUM],
      render(value, record: TaskFieldsFragment) {
        const { invoice } = record;
        const { dueDate, status, isOnHold, onHoldRemarks } = invoice || {};
        return (
          <Space direction="vertical">
            {dueDate && (
              <Typography.Heading level={4}>
                {formatDateTime(dueDate, { format: "SHORT" })}
              </Typography.Heading>
            )}
            {isOnHold ? (
              <Space>
                <Typography.Text
                  size="middle"
                  style={{ color: InvoiceStatusMap[status]?.color }}
                >
                  {`${status}`}
                </Typography.Text>
                <div className="w-0 border h-4 border-primary-navyLight" />
                <Typography.Text
                  size="middle"
                  style={{ color: InvoiceStatusMap.OnHold?.color }}
                >
                  {InvoiceStatusMap.OnHold?.text}
                </Typography.Text>
                <Tooltip title={onHoldRemarks}>
                  <IconInfo className="w-4 h-4 cursor-pointer" />
                </Tooltip>
              </Space>
            ) : (
              <Typography.Text
                size="middle"
                style={{ color: InvoiceStatusMap[status]?.color }}
              >
                {`${status}`}
              </Typography.Text>
            )}
          </Space>
        );
      },
    },
  ];
  if (!visible) {
    return <></>;
  }

  return (
    <div>
      <TaskTable
        data={data}
        count={count}
        loading={loading}
        columns={columns}
        title={formatLabel("GAP Term Billing Follow Up")}
        taskType={TaskType.FollowUpTermBilling}
        callback={callback}
        pagination={{
          ...pagination,
        }}
      />

      {invoiceDetailModal}
    </div>
  );
};

export default TermBillingFollowUpTask;
