import {
  ValidateHandlebarsCompileType,
  validateHandlebarsTemplateSyntax,
} from "@/components/settings/Template/handleBarUtil";
import {
  ApolloError,
  LazyQueryHookOptions,
  MutationTuple,
  QueryTuple,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import { ReactElement } from "react";
import {
  BulkCompileTemplate,
  BulkCompileTemplateMutation,
  BulkCompileTemplateMutationVariables,
  BulkExportReminderDocx,
  BulkExportReminderDocxQuery,
  BulkExportReminderDocxQueryVariables,
  BulkSendReminder,
  BulkSendReminderMutation,
  BulkSendReminderMutationVariables,
  CompileTemplate,
  CompileTemplateMutation,
  CompileTemplateMutationVariables,
  ExportReminderDocx,
  ExportReminderDocxQuery,
  ExportReminderDocxQueryVariables,
  GetRegistrationForReminder,
  GetRegistrationForReminderQuery,
  GetRegistrationForReminderQueryVariables,
  GetReminder,
  GetReminderArrangement,
  GetReminderArrangementQuery,
  GetReminderArrangementQueryVariables,
  GetReminderQuery,
  GetReminderQueryVariables,
  GetSendPaymentAdviceInvoices,
  GetSendPaymentAdviceInvoicesQuery,
  GetSendPaymentAdviceInvoicesQueryVariables,
  GetSendReminderInvoice,
  GetSendReminderInvoiceQuery,
  GetSendReminderInvoiceQueryVariables,
  ResendReminder,
  ResendReminderMutation,
  ResendReminderMutationVariables,
  SendReminder,
  SendReminderMutation,
  SendReminderMutationVariables,
  SendTestReminder,
  SendTestReminderMutation,
  SendTestReminderMutationVariables,
} from "../../../../graphql";
import {
  MissingInformationModalProps,
  useMissingInformationModal,
} from "../MissingInformationModal";

export const useQueryReminder = () =>
  useQuery<GetReminderQuery, GetReminderQueryVariables>(GetReminder);

export const useLazyQueryReminder = () =>
  useLazyQuery<GetReminderQuery, GetReminderQueryVariables>(GetReminder);

export const useLazyQueryReminderRegistration = (): QueryTuple<
  GetRegistrationForReminderQuery,
  GetRegistrationForReminderQueryVariables
> =>
  useLazyQuery<
    GetRegistrationForReminderQuery,
    GetRegistrationForReminderQueryVariables
  >(GetRegistrationForReminder);

export const useLazyQueryReminderArrangement = (): QueryTuple<
  GetReminderArrangementQuery,
  GetReminderArrangementQueryVariables
> =>
  useLazyQuery<
    GetReminderArrangementQuery,
    GetReminderArrangementQueryVariables
  >(GetReminderArrangement);

export const useLazyQueryReminderInvoice = (): QueryTuple<
  GetSendReminderInvoiceQuery,
  GetSendReminderInvoiceQueryVariables
> =>
  useLazyQuery<
    GetSendReminderInvoiceQuery,
    GetSendReminderInvoiceQueryVariables
  >(GetSendReminderInvoice);

export const useLazyQueryPaymentAdviceInvoices = (): QueryTuple<
  GetSendPaymentAdviceInvoicesQuery,
  GetSendPaymentAdviceInvoicesQueryVariables
> =>
  useLazyQuery<
    GetSendPaymentAdviceInvoicesQuery,
    GetSendPaymentAdviceInvoicesQueryVariables
  >(GetSendPaymentAdviceInvoices);

export const useSendReminder = (
  onError?: (error: ApolloError) => void
): MutationTuple<SendReminderMutation, SendReminderMutationVariables> =>
  useMutation<SendReminderMutation, SendReminderMutationVariables>(
    SendReminder,
    {
      onError,
    }
  );

export function useBulkSendReminder(callback?: () => void | Promise<void>): {
  bulkSendReminder: (
    variables: BulkSendReminderMutationVariables
  ) => Promise<void>;
  loading: boolean;
  modal: ReactElement<
    Omit<MissingInformationModalProps, "visible" | "onCancel">
  >;
} {
  const [bulkSendReminder, { loading }] = useMutation<
    BulkSendReminderMutation,
    BulkSendReminderMutationVariables
  >(BulkSendReminder);
  const { modal, show, setModalProps } = useMissingInformationModal();

  const handleSend = async (
    variables: BulkSendReminderMutationVariables
  ): Promise<void> => {
    validateHandlebarsTemplateSyntax(
      {
        subject: variables?.input?.emailInput?.subject,
        whatsAppMessage: variables?.input?.whatsappInput?.message,
        emailMessage: variables?.input?.emailInput?.message,
      },
      ValidateHandlebarsCompileType.Send
    );

    const result = await bulkSendReminder({ variables });
    if (result.data?.bulkSendReminder?.length > 0) {
      setModalProps({
        missingInfos: result.data.bulkSendReminder,
      });
      show();
    } else {
      await callback?.();
    }
  };

  return {
    bulkSendReminder: handleSend,
    modal,
    loading,
  };
}

export const useSendTestReminder = (
  onError?: (error: ApolloError) => void
): MutationTuple<SendTestReminderMutation, SendTestReminderMutationVariables> =>
  useMutation<SendTestReminderMutation, SendTestReminderMutationVariables>(
    SendTestReminder,
    {
      onError,
    }
  );

export const useResendReminder = () =>
  useMutation<ResendReminderMutation, ResendReminderMutationVariables>(
    ResendReminder
  );

export const useCompileTemplate = (onError?: (error: ApolloError) => void) =>
  useMutation<CompileTemplateMutation, CompileTemplateMutationVariables>(
    CompileTemplate,
    {
      onError,
    }
  );

export const useBulkCompileTemplate = () =>
  useMutation<
    BulkCompileTemplateMutation,
    BulkCompileTemplateMutationVariables
  >(BulkCompileTemplate);

export const useLazyQueryExportReminderDocx = (
  options?: LazyQueryHookOptions<
    ExportReminderDocxQuery,
    ExportReminderDocxQueryVariables
  >
): QueryTuple<ExportReminderDocxQuery, ExportReminderDocxQueryVariables> =>
  useLazyQuery<ExportReminderDocxQuery, ExportReminderDocxQueryVariables>(
    ExportReminderDocx,
    options
  );

export const useLazyQueryBulkExportReminderDocx = (
  options?: LazyQueryHookOptions<
    BulkExportReminderDocxQuery,
    BulkExportReminderDocxQueryVariables
  >
): QueryTuple<
  BulkExportReminderDocxQuery,
  BulkExportReminderDocxQueryVariables
> =>
  useLazyQuery<
    BulkExportReminderDocxQuery,
    BulkExportReminderDocxQueryVariables
  >(BulkExportReminderDocx, options);
