import {
  Checkbox,
  CheckboxChangeEvent,
  Typography,
} from "@thepiquelab/archus-components-web";
import { ReactNode } from "react";

interface ReminderCollapseProps {
  checked: boolean;
  onCheckboxChange: (e: CheckboxChangeEvent) => void;
  icon: ReactNode;
  title: string;
  children: ReactNode;
}

const ReminderCollapse: React.FC<ReminderCollapseProps> = ({
  checked,
  onCheckboxChange,
  icon,
  title,
  children,
}) => (
  <div className="financeNotification__collapse_container">
    <div className="border-b border-gray-400">
      <div className="flex items-center justify-between">
        <Checkbox
          checked={checked}
          onChange={onCheckboxChange}
          className="h-full p-4 border-r border-gray-400"
        />
        <div className="flex items-center">
          {icon}
          <Typography.Heading level={2} className="ml-2">
            {title}
          </Typography.Heading>
        </div>
        <Checkbox className="invisible h-full p-4" />
      </div>
    </div>
    {children}
  </div>
);

export default ReminderCollapse;
