import { CsdControllerApi } from "@/apis/csdController/csdController";
import { DeviceStatus } from "@/apis/csdController/types";
import { AppConfig } from "@/config";
import {
  generateNotificationConfig,
  notification,
} from "@thepiquelab/archus-components-web";
import { Button } from "antd";
import { useRef } from "react";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { EventSourcePolyfill } from "event-source-polyfill";
import { useHistory } from "react-router-dom";
import { useCurrentUser } from "./useCurrentUser";

const useDeviceEventSource = () => {
  const { getToken } = useCurrentUser();
  const evtSource = useRef<EventSource>();
  const history = useHistory();

  const initEventSource = async () => {
    if (!evtSource.current) {
      const authorization = await getToken();
      const url = `${AppConfig.CsdControllerServiceUrl}/devices/listener`;

      evtSource.current = new EventSourcePolyfill(url, {
        headers: {
          Authorization: `Bearer ${authorization}`,
        },
        heartbeatTimeout: 1000 * 60 * 60 * 24 * 30 * 6,
      });
      evtSource.current.onmessage = (e) => {
        const data: string = e?.data || "";

        if (data?.includes(`"status":"DISCONNECTED"`)) {
          const timeoutId = setTimeout(async () => {
            const room = JSON.parse(data);

            const device = await CsdControllerApi.getDevice(room._id);

            if (device.status === DeviceStatus.Disconnected) {
              const csdNodes = document.getElementsByClassName(
                "csd-controller-notification"
              );
              if (csdNodes?.length > 0) return;

              const key = `open${Date.now()}`;

              notification.open({
                ...generateNotificationConfig({
                  type: "warning",
                  title: "Class Schedule Display App Disconnected!",
                  description: "Class Schedule Display App has disconnected!",
                }),
                key,
                duration: null,
                className: "csd-controller-notification",
                btn: (
                  <Button
                    type="primary"
                    onClick={() => {
                      notification.close(key);
                      history.push(`/settings/devices`);
                    }}
                  >
                    Go Check
                  </Button>
                ),
              });
            }

            clearTimeout(timeoutId);
          }, 300000);
        }
      };
    }
  };

  return {
    initEventSource,
  };
};

export default useDeviceEventSource;
