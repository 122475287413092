import { CommonCacheContext } from "@/components/common/CacheProvider";
import {
  BulkUpdateDigitalClassVenue,
  BulkUpdateDigitalClassVenueMutation,
  BulkUpdateDigitalClassVenueMutationVariables,
  GetAssignVenueClasses,
  GetAssignVenueClassesQuery,
  GetAssignVenueClassesQueryVariables,
  GetEnabledFeatures,
  GetEnabledFeaturesQuery,
  GetFeatures,
  GetFeaturesQuery,
  ToggleFeature,
  ToggleFeatureMutation,
  ToggleFeatureMutationVariables,
  ToggleFeatures,
  ToggleFeaturesMutation,
  ToggleFeaturesMutationVariables,
} from "@/graphql";
import {
  LazyQueryHookOptions,
  MutationHookOptions,
  MutationTuple,
  useLazyQuery,
  useMutation,
} from "@apollo/client";
import { useContext } from "react";

export const useQueryFeatures = (): {
  data: {
    features: GetFeaturesQuery["features"];
  };
  loading: boolean;
} => {
  const { featureData, featureDataLoading } = useContext(CommonCacheContext);
  return {
    data: {
      features: featureData,
    },
    loading: featureDataLoading,
  };
};

export const useQueryEnabledFeatures = (): {
  data: {
    enabledFeatures: GetEnabledFeaturesQuery["enabledFeatures"];
  };
  loading: boolean;
} => {
  const { enabledFeatureData, enabledFeatureDataLoading } =
    useContext(CommonCacheContext);
  return {
    data: {
      enabledFeatures: enabledFeatureData,
    },
    loading: enabledFeatureDataLoading,
  };
};

export const useLazyQueryAssignVenueClasses = (
  options?: LazyQueryHookOptions<
    GetAssignVenueClassesQuery,
    GetAssignVenueClassesQueryVariables
  >
) =>
  useLazyQuery<GetAssignVenueClassesQuery, GetAssignVenueClassesQueryVariables>(
    GetAssignVenueClasses,
    { fetchPolicy: "network-only", ...options }
  );

export const useToggleFeature = (
  options?: MutationHookOptions<
    ToggleFeatureMutation,
    ToggleFeatureMutationVariables
  >
): MutationTuple<ToggleFeatureMutation, ToggleFeatureMutationVariables> =>
  useMutation<ToggleFeatureMutation, ToggleFeatureMutationVariables>(
    ToggleFeature,
    {
      ...options,
      refetchQueries: [GetFeatures, GetEnabledFeatures],
    }
  );
export const useToggleFeatures = (
  options?: MutationHookOptions<
    ToggleFeaturesMutation,
    ToggleFeaturesMutationVariables
  >
): MutationTuple<ToggleFeaturesMutation, ToggleFeaturesMutationVariables> =>
  useMutation<ToggleFeaturesMutation, ToggleFeaturesMutationVariables>(
    ToggleFeatures,
    {
      ...options,
      refetchQueries: [GetFeatures, GetEnabledFeatures],
    }
  );

export const useBulkUpdateDigitalClassVenue = () =>
  useMutation<
    BulkUpdateDigitalClassVenueMutation,
    BulkUpdateDigitalClassVenueMutationVariables
  >(BulkUpdateDigitalClassVenue);
