import {
  CompleteTasks,
  CompleteTasksMutation,
  CompleteTasksMutationVariables,
  CopyTaskList,
  CopyTaskListMutation,
  CopyTaskListMutationVariables,
  CreateTask,
  CreateTaskChecklist,
  CreateTaskChecklistItem,
  CreateTaskChecklistItemMutation,
  CreateTaskChecklistItemMutationVariables,
  CreateTaskChecklistMutation,
  CreateTaskChecklistMutationVariables,
  CreateTaskList,
  CreateTaskListMutation,
  CreateTaskListMutationVariables,
  CreateTaskMutation,
  CreateTaskMutationVariables,
  DeleteTaskChecklist,
  DeleteTaskChecklistItem,
  DeleteTaskChecklistItemMutation,
  DeleteTaskChecklistItemMutationVariables,
  DeleteTaskChecklistMutation,
  DeleteTaskChecklistMutationVariables,
  DeleteTaskList,
  DeleteTaskListMutation,
  DeleteTaskListMutationVariables,
  GetIndexTasks,
  GetIndexTasksQuery,
  GetIndexTasksQueryVariables,
  GetPendingOverdueTaskCount,
  GetPendingOverdueTaskCountQuery,
  GetPendingOverdueTaskCountQueryVariables,
  GetRemarks,
  GetRemarksQuery,
  GetRemarksQueryVariables,
  GetTask,
  GetTaskList,
  GetTaskListQuery,
  GetTaskListQueryVariables,
  GetTaskQuery,
  GetTaskQueryVariables,
  GetTasks,
  GetTasksQuery,
  GetTasksQueryVariables,
  PinTaskList,
  PinTaskListMutation,
  PinTaskListMutationVariables,
  ReopenTask,
  ReopenTaskMutation,
  ReopenTaskMutationVariables,
  SortDirection,
  TaskCount,
  TaskFilterInput,
  TaskOriginRecordType,
  TaskStatus,
  TaskType,
  UpdateTask,
  UpdateTaskChecklist,
  UpdateTaskChecklistItem,
  UpdateTaskChecklistItemMutation,
  UpdateTaskChecklistItemMutationVariables,
  UpdateTaskChecklistMutation,
  UpdateTaskChecklistMutationVariables,
  UpdateTaskDueDate,
  UpdateTaskDueDateMutation,
  UpdateTaskDueDateMutationVariables,
  UpdateTaskList,
  UpdateTaskListMutation,
  UpdateTaskListMutationVariables,
  UpdateTaskMutation,
  UpdateTaskMutationVariables,
  VoidTask,
  VoidTaskMutation,
  VoidTaskMutationVariables,
  VoidTasks,
  VoidTasksMutation,
  VoidTasksMutationVariables,
} from "@/graphql";
import {
  MutationTuple,
  QueryHookOptions,
  QueryTuple,
  useLazyQuery,
  useMutation,
} from "@apollo/client";
import { cloneDeep, isBoolean } from "lodash";
import { Moment } from "moment";
import { useCheckOverdueNotification } from "../hooks/useCheckOverdueNotification";

export const useLazyQueryTaskRemarks = (): QueryTuple<
  GetRemarksQuery,
  GetRemarksQueryVariables
> => useLazyQuery<GetRemarksQuery, GetRemarksQueryVariables>(GetRemarks);

export const useQueryTask = (
  options?: QueryHookOptions<GetTaskQuery, GetTaskQueryVariables>
): QueryTuple<GetTaskQuery, GetTaskQueryVariables> =>
  useLazyQuery<GetTaskQuery, GetTaskQueryVariables>(GetTask, options);

export const useQueryAllTasks = (
  options?: QueryHookOptions<GetTasksQuery, GetTasksQueryVariables>
): QueryTuple<GetTasksQuery, GetTasksQueryVariables> =>
  useLazyQuery<GetTasksQuery, GetTasksQueryVariables>(GetTasks, options);

export const useQueryAllIndexTasks = (
  options?: QueryHookOptions<GetIndexTasksQuery, GetIndexTasksQueryVariables>
): QueryTuple<GetIndexTasksQuery, GetIndexTasksQueryVariables> =>
  useLazyQuery<GetIndexTasksQuery, GetIndexTasksQueryVariables>(
    GetIndexTasks,
    options
  );

export const useGetPendingOverdueTaskCount = (
  options?: QueryHookOptions<
    GetPendingOverdueTaskCountQuery,
    GetPendingOverdueTaskCountQueryVariables
  >
): QueryTuple<
  GetPendingOverdueTaskCountQuery,
  GetPendingOverdueTaskCountQueryVariables
> =>
  useLazyQuery<
    GetPendingOverdueTaskCountQuery,
    GetPendingOverdueTaskCountQueryVariables
  >(GetPendingOverdueTaskCount, options);

export const useUpdateFollowTaskDueDate = (): MutationTuple<
  UpdateTaskDueDateMutation,
  UpdateTaskDueDateMutationVariables
> =>
  useMutation<UpdateTaskDueDateMutation, UpdateTaskDueDateMutationVariables>(
    UpdateTaskDueDate
  );

export const useCompleteTasks = (): MutationTuple<
  CompleteTasksMutation,
  CompleteTasksMutationVariables
> =>
  useMutation<CompleteTasksMutation, CompleteTasksMutationVariables>(
    CompleteTasks
  );

export const useVoidTask = (): MutationTuple<
  VoidTaskMutation,
  VoidTaskMutationVariables
> => useMutation<VoidTaskMutation, VoidTaskMutationVariables>(VoidTask);

export const useVoidTasks = (): MutationTuple<
  VoidTasksMutation,
  VoidTasksMutationVariables
> => useMutation<VoidTasksMutation, VoidTasksMutationVariables>(VoidTasks);

export const useReopenTask = (): MutationTuple<
  ReopenTaskMutation,
  ReopenTaskMutationVariables
> => useMutation<ReopenTaskMutation, ReopenTaskMutationVariables>(ReopenTask);

export const useCreateTaskList = (): MutationTuple<
  CreateTaskListMutation,
  CreateTaskListMutationVariables
> =>
  useMutation<CreateTaskListMutation, CreateTaskListMutationVariables>(
    CreateTaskList
  );

export const useUpdateTaskList = (): MutationTuple<
  UpdateTaskListMutation,
  UpdateTaskListMutationVariables
> =>
  useMutation<UpdateTaskListMutation, UpdateTaskListMutationVariables>(
    UpdateTaskList
  );
export const useCopyTaskList = (): MutationTuple<
  CopyTaskListMutation,
  CopyTaskListMutationVariables
> =>
  useMutation<CopyTaskListMutation, CopyTaskListMutationVariables>(
    CopyTaskList
  );

export const usePinTaskList = (): MutationTuple<
  PinTaskListMutation,
  PinTaskListMutationVariables
> =>
  useMutation<PinTaskListMutation, PinTaskListMutationVariables>(PinTaskList);

export const useCreateTask = (): MutationTuple<
  CreateTaskMutation,
  CreateTaskMutationVariables
> => useMutation<CreateTaskMutation, CreateTaskMutationVariables>(CreateTask);

export const useUpdateTask = (): MutationTuple<
  UpdateTaskMutation,
  UpdateTaskMutationVariables
> => useMutation<UpdateTaskMutation, UpdateTaskMutationVariables>(UpdateTask);

export const useDeleteTaskList = (): MutationTuple<
  DeleteTaskListMutation,
  DeleteTaskListMutationVariables
> =>
  useMutation<DeleteTaskListMutation, DeleteTaskListMutationVariables>(
    DeleteTaskList
  );

export const useQueryTaskList = (
  options?: QueryHookOptions<GetTaskListQuery, GetTaskListQueryVariables>
): QueryTuple<GetTaskListQuery, GetTaskListQueryVariables> =>
  useLazyQuery<GetTaskListQuery, GetTaskListQueryVariables>(
    GetTaskList,
    options
  );

export const useCreateTaskChecklist = (): MutationTuple<
  CreateTaskChecklistMutation,
  CreateTaskChecklistMutationVariables
> =>
  useMutation<
    CreateTaskChecklistMutation,
    CreateTaskChecklistMutationVariables
  >(CreateTaskChecklist);

export const useUpdateTaskChecklist = (): MutationTuple<
  UpdateTaskChecklistMutation,
  UpdateTaskChecklistMutationVariables
> =>
  useMutation<
    UpdateTaskChecklistMutation,
    UpdateTaskChecklistMutationVariables
  >(UpdateTaskChecklist);

export const useDeleteTaskChecklist = (): MutationTuple<
  DeleteTaskChecklistMutation,
  DeleteTaskChecklistMutationVariables
> =>
  useMutation<
    DeleteTaskChecklistMutation,
    DeleteTaskChecklistMutationVariables
  >(DeleteTaskChecklist);

export const useCreateTaskChecklistItem = (): MutationTuple<
  CreateTaskChecklistItemMutation,
  CreateTaskChecklistItemMutationVariables
> =>
  useMutation<
    CreateTaskChecklistItemMutation,
    CreateTaskChecklistItemMutationVariables
  >(CreateTaskChecklistItem);

export const useUpdateTaskChecklistItem = (): MutationTuple<
  UpdateTaskChecklistItemMutation,
  UpdateTaskChecklistItemMutationVariables
> =>
  useMutation<
    UpdateTaskChecklistItemMutation,
    UpdateTaskChecklistItemMutationVariables
  >(UpdateTaskChecklistItem);

export const useDeleteTaskChecklistItem = (): MutationTuple<
  DeleteTaskChecklistItemMutation,
  DeleteTaskChecklistItemMutationVariables
> =>
  useMutation<
    DeleteTaskChecklistItemMutation,
    DeleteTaskChecklistItemMutationVariables
  >(DeleteTaskChecklistItem);

export type TaskCountType = {
  [key in TaskType]?: TaskCount;
};
export const useQueryTasks = (): {
  getPendingOverdueTaskCount: () => void;
  fetchIndexTasks: (paramValue: {
    dueDateRange?: [Moment, Moment];
    lastModifiedDateRange?: [Moment, Moment];
    taskTypes?: TaskType[];
    taskStatus?: TaskStatus[];
    pageIndex?: number;
    pageSize?: number;
    whatsappAccountIds?: string[];
    sortKey?: string;
    taskIds?: string[];
    sortDirection?: SortDirection;
    keyword?: string;
    isWithoutWAline?: boolean;
    originRecordTypes?: TaskOriginRecordType[];
  }) => void;
  refetch: () => void;
  loading: boolean;
  data: GetIndexTasksQuery;
  count: TaskCountType;
  allCount: {
    pending: number;
    overdue: number;
  };
} => {
  const defaultFilterInput: TaskFilterInput = {
    types: [TaskType.FollowUpTrial],
    statuses: [],
  };

  const { checkOverdueNotification } = useCheckOverdueNotification();

  const [
    getPendingOverdueTaskCount,
    {
      data: pendingOverdueTaskCount,
      loading: pendingOverdueTaskCountLoading,
      refetch: refetchPendingOverdueTaskCount,
    },
  ] = useGetPendingOverdueTaskCount({
    fetchPolicy: "network-only",
    onCompleted(data) {
      checkOverdueNotification(data?.getPendingOverdueTask);
    },
  });

  const [
    queryAllIndexTasks,
    { data: indexData, loading: indexLoading, refetch: refetchAllIndexTasks },
  ] = useQueryAllIndexTasks({
    fetchPolicy: "no-cache",
  });

  const getKeywordParam = (
    result: TaskFilterInput,
    keyword: string
  ): TaskFilterInput => {
    const filterInput = cloneDeep(result);
    if (keyword) {
      filterInput.name = keyword;
      return filterInput;
    }
    return filterInput;
  };

  const getDueDateRangeParam = (
    result: TaskFilterInput,
    dueDateRange: [Moment, Moment]
  ): TaskFilterInput => {
    const filterInput = cloneDeep(result);
    if (dueDateRange) {
      filterInput.dueDateRange = {
        start: dueDateRange?.[0]?.startOf("day")?.toISOString(),
        end: dueDateRange?.[1]?.endOf("day")?.toISOString(),
      };
      return filterInput;
    }
    return filterInput;
  };

  const getLastModifiedDateRangeParam = (
    result: TaskFilterInput,
    lastModifiedDateRange: [Moment, Moment]
  ): TaskFilterInput => {
    const filterInput = cloneDeep(result);
    if (lastModifiedDateRange) {
      filterInput.lastModifiedDateRange = {
        start: lastModifiedDateRange?.[0]?.startOf("day")?.toISOString(),
        end: lastModifiedDateRange?.[1]?.endOf("day")?.toISOString(),
      };
      return filterInput;
    }
    return filterInput;
  };

  const getTypesParam = (
    result: TaskFilterInput,
    taskTypes: TaskType[]
  ): TaskFilterInput => {
    const filterInput = cloneDeep(result);
    if (taskTypes?.length) {
      filterInput.types = taskTypes;
      return filterInput;
    }
    filterInput.types = null;
    return filterInput;
  };

  const getStatusParam = (
    result: TaskFilterInput,
    taskStatus: TaskStatus[]
  ): TaskFilterInput => {
    const filterInput = cloneDeep(result);
    if (taskStatus?.length) {
      filterInput.statuses = taskStatus;
      return filterInput;
    }

    filterInput.statuses = null;
    return filterInput;
  };

  const getWhatsappAccountIdsParam = (
    result: TaskFilterInput,
    whatsappAccountIds: string[]
  ): TaskFilterInput => {
    const filterInput = cloneDeep(result);
    if (whatsappAccountIds?.length) {
      filterInput.whatsappAccountIds = whatsappAccountIds;
      return filterInput;
    }

    filterInput.whatsappAccountIds = null;
    return filterInput;
  };

  const getTaskIdsParam = (
    result: TaskFilterInput,
    taskIds: string[]
  ): TaskFilterInput => {
    const filterInput = cloneDeep(result);
    if (taskIds?.length) {
      filterInput.taskIds = taskIds;
      return filterInput;
    }

    filterInput.taskIds = null;
    return filterInput;
  };

  const getWithoutWAlineParam = (
    result: TaskFilterInput,
    isWithoutWAline: boolean
  ): TaskFilterInput => {
    const filterInput = cloneDeep(result);
    if (isWithoutWAline) {
      filterInput.isWithoutWAline = isWithoutWAline;
      filterInput.whatsappAccountIds = undefined;

      return filterInput;
    }

    filterInput.isWithoutWAline = undefined;
    return filterInput;
  };

  const getOriginRecordTypesParam = (
    result: TaskFilterInput,
    originRecordTypes: TaskOriginRecordType[]
  ): TaskFilterInput => {
    const filterInput = cloneDeep(result);
    if (originRecordTypes?.length) {
      filterInput.originRecordTypes = originRecordTypes;
      return filterInput;
    }

    filterInput.originRecordTypes = undefined;
    return filterInput;
  };

  const getIsTransferLessonReminderParam = (
    result: TaskFilterInput,
    isTransferLessonReminder: boolean
  ): TaskFilterInput => {
    const filterInput = cloneDeep(result);
    if (isBoolean(isTransferLessonReminder)) {
      filterInput.isTransferLessonReminder = isTransferLessonReminder;
      return filterInput;
    }

    filterInput.isTransferLessonReminder = undefined;
    return filterInput;
  };

  const queryIndexTasks = ({
    dueDateRange,
    lastModifiedDateRange,
    taskTypes,
    taskStatus,
    whatsappAccountIds,
    pageIndex = 0,
    pageSize = 20,
    sortKey = "",
    sortDirection = null,
    keyword = "",
    isWithoutWAline,
    taskIds,
    originRecordTypes,
    isTransferLessonReminder,
  }: {
    dueDateRange?: [Moment, Moment];
    lastModifiedDateRange?: [Moment, Moment];
    taskTypes?: TaskType[];
    taskStatus?: TaskStatus[];
    pageIndex?: number;
    pageSize?: number;
    taskIds?: string[];
    whatsappAccountIds?: string[];
    sortKey?: string;
    sortDirection?: SortDirection;
    keyword?: string;
    isWithoutWAline?: boolean;
    originRecordTypes?: TaskOriginRecordType[];
    isTransferLessonReminder?: boolean;
  }): void => {
    let queryParams = cloneDeep(defaultFilterInput);

    queryParams = getKeywordParam(queryParams, keyword);
    queryParams = getDueDateRangeParam(queryParams, dueDateRange);
    queryParams = getLastModifiedDateRangeParam(
      queryParams,
      lastModifiedDateRange
    );
    queryParams = getTypesParam(queryParams, taskTypes);
    queryParams = getStatusParam(queryParams, taskStatus);
    queryParams = getTaskIdsParam(queryParams, taskIds);
    queryParams = getWhatsappAccountIdsParam(queryParams, whatsappAccountIds);
    queryParams = getWithoutWAlineParam(queryParams, isWithoutWAline);
    queryParams = getOriginRecordTypesParam(queryParams, originRecordTypes);
    queryParams = getIsTransferLessonReminderParam(
      queryParams,
      isTransferLessonReminder
    );

    // show all data if it's operational task.
    const isQueryAllPage = taskTypes?.includes(TaskType.Operational);

    /**
     * Pending and overdue task count
     */
    getPendingOverdueTaskCount();

    /**
     * All tasks match the query criteria.
     */
    queryAllIndexTasks({
      variables: {
        filterInput: {
          ...queryParams,
        },
        pageInfo: {
          pageIndex: isQueryAllPage ? 0 : pageIndex,
          pageSize: isQueryAllPage ? 0 : pageSize,
        },
        sortInfo:
          sortKey && sortDirection
            ? {
                field: sortKey,
                direction: sortDirection,
              }
            : undefined,
      },
    });
  };

  return {
    getPendingOverdueTaskCount,
    fetchIndexTasks: queryIndexTasks,
    refetch: () => {
      refetchAllIndexTasks();
      refetchPendingOverdueTaskCount();
    },
    loading: indexLoading,
    data: indexData,
    count: pendingOverdueTaskCount?.getPendingOverdueTask?.reduce(
      (temp: TaskCountType, item: TaskCount) => ({
        ...temp,
        [item.type]: item,
      }),
      {} as TaskCountType
    ),
    allCount: {
      pending:
        pendingOverdueTaskCount?.getPendingOverdueTask?.reduce(
          (result: number, item: TaskCount) => {
            result += item?.pending || 0;
            return result;
          },
          0
        ) || 0,
      overdue:
        pendingOverdueTaskCount?.getPendingOverdueTask?.reduce(
          (result: number, item: TaskCount) => {
            result += item?.overdue || 0;
            return result;
          },
          0
        ) || 0,
    },
  };
};
