import { enumToLabelArray } from "@/utils/enumUnit";
import formatErrorMessage from "@/utils/formatErrorMessage";
import formatLabel from "@/utils/formatLabel";
import { WeekDay } from "@thepiquelab/archus-library/dist/utils/mockUtils";
import { Form, Modal, Select, message } from "antd";
import React from "react";
import { useCreateTask } from "../hooks";

interface TaskCreateModalProps {
  listId: string;
  title?: string;
  callback?: () => Promise<void> | void;
  onClose: () => void;
}

const TaskCreateModal: React.FC<TaskCreateModalProps> = (props) => {
  const { title = "Add Task", listId, callback, onClose } = props;
  const [createTask, { loading: createTaskLoading }] = useCreateTask();

  const [form] = Form.useForm();

  const WeekDays = enumToLabelArray(WeekDay).map((item) => item.value);

  // const createNewTask = (values) => {};
  const addTask = async (value: any) => {
    try {
      await createTask({
        variables: {
          input: {
            name: value.name,
            listId,
          },
        },
      });
      form.resetFields();
      message.success("Task added successfully!");
      onClose();
      callback?.();
    } catch (error) {
      message.error(formatErrorMessage(error));
    }
  };
  return (
    <>
      <Modal
        title={title}
        onCancel={() => {
          onClose();
        }}
        onOk={() => {
          form.submit();
        }}
        visible={!!listId}
        okButtonProps={{
          loading: createTaskLoading,
        }}
      >
        <Form
          onFinish={(values) => {
            addTask(values);
          }}
          colon={false}
          form={form}
        >
          <Form.Item
            label="Task Name"
            name="name"
            className="w-full no-margin"
            rules={[
              { required: true, message: formatLabel("Field is required.") },
            ]}
            labelCol={{
              span: 24,
            }}
          >
            <Select
              options={enumToLabelArray(WeekDay).map((item) => ({
                ...item,
                value: formatLabel(item.value, "title"),
              }))}
              dropdownMatchSelectWidth={false}
              style={{ minWidth: 200 }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default TaskCreateModal;
