// import { useAuth0 } from "@/Auth0Wrapper";
import { EnumPermissions } from "@/components/authorize/Permissions";
import { useAccessControl } from "./useAccessControl";

/**
 * to check if it has all of required permissions
 * @param permissions {EnumPermission[]} - permissions to check
 * @param findType {"some" | "all"} - all or some, optional, default all
 * @returns {boolean}
 */
export default function useAuth(
  permission: EnumPermissions[],
  findType: "some" | "all" = "all"
): boolean {
  const { hasAllPermissions, hasSomePermissions } = useAccessControl();
  if (findType === "all") {
    return hasAllPermissions(permission);
  }

  return hasSomePermissions(permission);
}
