import { CommonCacheContext } from "@/components/common/CacheProvider";
import formatErrorMessage from "@/utils/formatErrorMessage";
import {
  LazyQueryHookOptions,
  MutationTuple,
  QueryHookOptions,
  QueryTuple,
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
  WatchQueryFetchPolicy,
} from "@apollo/client";
import { message } from "@thepiquelab/archus-components-web";
import { useContext, useEffect, useState } from "react";
import {
  AcademicYears,
  AcademicYearsQuery,
  AcademicYearsQueryVariables,
  CancelPromotion,
  CancelPromotionMutation,
  CancelPromotionMutationVariables,
  ChangeAcademicLevelArchive,
  ChangeAcademicLevelArchiveMutationVariables,
  ChangeAcademicLevelVisibility,
  ChangeAcademicLevelVisibilityMutation,
  ChangeAcademicLevelVisibilityMutationVariables,
  ChangeBranchArchive,
  ChangeBranchArchiveMutation,
  ChangeBranchArchiveMutationVariables,
  ChangeDepartmentArchive,
  ChangeDepartmentArchiveMutation,
  ChangeDepartmentArchiveMutationVariables,
  ChangeSchoolArchive,
  ChangeSchoolArchiveMutation,
  ChangeSchoolArchiveMutationVariables,
  ChangeSchoolVisibility,
  ChangeSchoolVisibilityMutation,
  ChangeSchoolVisibilityMutationVariables,
  CompleteMailMerge,
  CompleteMailMergeMutation,
  CompleteMailMergeMutationVariables,
  CreateBranch,
  CreateBranchMutation,
  CreateBranchMutationVariables,
  CreateCourierPickupAddress,
  CreateCourierPickupAddressMutation,
  CreateCourierPickupAddressMutationVariables,
  CreateDepartment,
  CreateDepartmentMutation,
  CreateDepartmentMutationVariables,
  CreateLevel,
  CreateLevelMutation,
  CreateLevelMutationVariables,
  CreateMailMerge,
  CreateMailMergeMutation,
  CreateMailMergeMutationVariables,
  CreatePromotion,
  CreatePromotionMutation,
  CreatePromotionMutationVariables,
  CreateSchool,
  CreateSchoolMutation,
  CreateSchoolMutationVariables,
  CreateTemplate,
  CreateTemplateMutation,
  CreateTemplateMutationVariables,
  CreateVenue,
  CreateVenueMutation,
  CreateVenueMutationVariables,
  DeleteBranch,
  DeleteBranchMutation,
  DeleteBranchMutationVariables,
  DeleteDepartment,
  DeleteDepartmentMutation,
  DeleteDepartmentMutationVariables,
  DeleteLevel,
  DeleteLevelMutation,
  DeleteLevelMutationVariables,
  DeletePromotion,
  DeletePromotionMutation,
  DeletePromotionMutationVariables,
  DeleteSchool,
  DeleteSchoolMutation,
  DeleteSchoolMutationVariables,
  DownloadMailMerge,
  DownloadMailMergeMutation,
  DownloadMailMergeMutationVariables,
  GetAcademicYearsOptions,
  GetAcademicYearsOptionsQuery,
  GetAcademicYearsOptionsQueryVariables,
  GetAllBranches,
  GetAllBranchesQueryVariables,
  GetAllCourierPickupAddress,
  GetAllCourierPickupAddressQuery,
  GetAllCourierPickupAddressQueryVariables,
  GetAllVenue,
  GetAllVenueOptions,
  GetAllVenueOptionsQuery,
  GetAllVenueOptionsQueryVariables,
  GetAllVenueQuery,
  GetAllVenueQueryVariables,
  GetAllVenues,
  GetAllVenuesQueryVariables,
  GetBranches,
  GetBranchesQuery,
  GetBranchesQueryVariables,
  GetCentreId,
  GetCentreIdQuery,
  GetCentreIdQueryVariables,
  GetClassWithAcademicYears,
  GetClassWithAcademicYearsQuery,
  GetClassWithAcademicYearsQueryVariables,
  GetDepartment,
  GetDepartmentQuery,
  GetDepartmentQueryVariables,
  GetDepartments,
  GetDepartmentsQueryVariables,
  GetLevel,
  GetLevels,
  GetLevelsQuery,
  GetLevelsQueryVariables,
  GetMailMerge,
  GetMailMergeQuery,
  GetMailMergeQueryVariables,
  GetNotificationConfigurations,
  GetNotificationConfigurationsQuery,
  GetNotificationConfigurationsQueryVariables,
  GetNotificationsOfTemplates,
  GetNotificationsOfTemplatesQuery,
  GetNotificationsOfTemplatesQueryVariables,
  GetPromotion,
  GetPromotionQuery,
  GetPromotionQueryVariables,
  GetPromotions,
  GetPromotionsQuery,
  GetPromotionsQueryVariables,
  GetSchools,
  GetSchoolsQuery,
  GetSchoolsQueryVariables,
  GetTemplate,
  GetTemplateQuery,
  GetTemplateQueryVariables,
  GetTemplates,
  GetTemplatesQuery,
  GetTemplatesQueryVariables,
  NotificationType,
  TemplateFilterInput,
  TemplateStatus,
  UpdateBranch,
  UpdateBranchMutation,
  UpdateBranchMutationVariables,
  UpdateCourierPickupAddress,
  UpdateCourierPickupAddressArchive,
  UpdateCourierPickupAddressArchiveMutation,
  UpdateCourierPickupAddressArchiveMutationVariables,
  UpdateCourierPickupAddressMutation,
  UpdateCourierPickupAddressMutationVariables,
  UpdateDepartment,
  UpdateDepartmentMutation,
  UpdateDepartmentMutationVariables,
  UpdateLevel,
  UpdateLevelMutation,
  UpdateLevelMutationVariables,
  UpdateMailMerge,
  UpdateMailMergeMutation,
  UpdateMailMergeMutationVariables,
  UpdateNotificationItem,
  UpdateNotificationItemMutation,
  UpdateNotificationItemMutationVariables,
  UpdatePromotion,
  UpdatePromotionMutation,
  UpdatePromotionMutationVariables,
  UpdateSchool,
  UpdateSchoolMutation,
  UpdateSchoolMutationVariables,
  UpdateTemplate,
  UpdateTemplateArchive,
  UpdateTemplateArchiveMutation,
  UpdateTemplateArchiveMutationVariables,
  UpdateTemplateMutation,
  UpdateTemplateMutationVariables,
  UpdateVenue,
  UpdateVenueMutation,
  UpdateVenueMutationVariables,
  UploadMailMergeMergeList,
  UploadMailMergeMergeListMutation,
  UploadMailMergeMergeListMutationVariables,
} from "../../../graphql";

export const useCentreId = (variables?: GetCentreIdQueryVariables) =>
  useQuery<GetCentreIdQuery, GetCentreIdQueryVariables>(GetCentreId, {
    variables,
    fetchPolicy: "cache-and-network",
  });

export const useDepartments = (variables?: GetDepartmentsQueryVariables) => {
  const { departmentsData, centreDataLoading } = useContext(CommonCacheContext);

  if (departmentsData && !variables?.showArchive) {
    return {
      data: {
        departments: departmentsData?.departments?.filter((d) => !d.isArchived),
      },
      loading: centreDataLoading,
    };
  }

  return {
    data: departmentsData,
    loading: centreDataLoading,
  };
};

export const useQueryDepartment = () => {
  const client = useApolloClient();
  return (variables: GetDepartmentQueryVariables) =>
    client.query<GetDepartmentQuery, GetDepartmentQueryVariables>({
      query: GetDepartment,
      variables,
      fetchPolicy: "network-only",
    });
};

export const useBranches = (variables?: GetAllBranchesQueryVariables) => {
  const { branchesData, centreDataLoading } = useContext(CommonCacheContext);

  if (!variables?.showArchive) {
    return {
      data: {
        branches: branchesData?.branches?.filter((d) => !d.isArchived),
      },
      loading: centreDataLoading,
    };
  }
  return {
    data: {
      branches: branchesData?.branches,
    },
    loading: centreDataLoading,
  };
};

export const useVenues = (variables?: GetAllVenuesQueryVariables) => {
  const { venuesData, centreDataLoading } = useContext(CommonCacheContext);
  if (!variables?.showArchive) {
    return {
      data: {
        venues: venuesData?.venues?.filter((d) => !d.isArchived),
      },
      loading: centreDataLoading,
    };
  }
  return {
    data: {
      venues: venuesData?.venues,
    },
    loading: centreDataLoading,
  };
};

export const useOptionVenues = (variables?: GetAllVenueOptionsQueryVariables) =>
  useQuery<GetAllVenueOptionsQuery, GetAllVenueOptionsQueryVariables>(
    GetAllVenueOptions,
    {
      variables,
      fetchPolicy: "network-only",
    }
  );

export const useCreateDepartment = () =>
  useMutation<CreateDepartmentMutation, CreateDepartmentMutationVariables>(
    CreateDepartment
  );

export const useUpdateDepartment = () =>
  useMutation<UpdateDepartmentMutation, UpdateDepartmentMutationVariables>(
    UpdateDepartment,
    {
      fetchPolicy: "network-only",
      refetchQueries: [{ query: GetDepartments }],
    }
  );

export const useDeleteDepartment = () =>
  useMutation<DeleteDepartmentMutation, DeleteDepartmentMutationVariables>(
    DeleteDepartment
  );

export const useCreateBranch = () =>
  useMutation<CreateBranchMutation, CreateBranchMutationVariables>(
    CreateBranch
  );

export const useUpdateBranch = () =>
  useMutation<UpdateBranchMutation, UpdateBranchMutationVariables>(
    UpdateBranch,
    {
      refetchQueries: [{ query: GetAllBranches, fetchPolicy: "network-only" }],
    }
  );

export const useDeleteBranch = () =>
  useMutation<DeleteBranchMutation, DeleteBranchMutationVariables>(
    DeleteBranch
  );

export const useCreateVenue = () =>
  useMutation<CreateVenueMutation, CreateVenueMutationVariables>(CreateVenue);

export const useUpdateVenue = () =>
  useMutation<UpdateVenueMutation, UpdateVenueMutationVariables>(UpdateVenue, {
    refetchQueries: [{ query: GetAllVenues }],
  });

export const useSchools = () =>
  useQuery<GetSchoolsQuery, GetSchoolsQueryVariables>(GetSchools);

export const useCreateSchool = () =>
  useMutation<CreateSchoolMutation, CreateSchoolMutationVariables>(
    CreateSchool
  );

export const useUpdateSchool = () =>
  useMutation<UpdateSchoolMutation, UpdateSchoolMutationVariables>(
    UpdateSchool
  );

export const useDeleteSchool = () =>
  useMutation<DeleteSchoolMutation, DeleteSchoolMutationVariables>(
    DeleteSchool,
    {
      refetchQueries: [{ query: GetSchools, fetchPolicy: "network-only" }],
      onError(e) {
        message.error(formatErrorMessage(e));
      },
    }
  );

export const useLevels = (
  options?: QueryHookOptions<GetLevelsQuery, GetLevelsQueryVariables>
) => useQuery<GetLevelsQuery, GetLevelsQueryVariables>(GetLevels, options);

export const useLevel = (id: string) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [data, setData] = useState();
  const client = useApolloClient();
  useEffect(() => {
    if (id === "") {
      setLoading(false);
    } else {
      client
        .query({
          query: GetLevel,
          variables: { id },
        })
        .then((res) => setData(res.data))
        .catch((err) => setError(err))
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return { loading, error, data };
};

export const useCreateLevel = () =>
  useMutation<CreateLevelMutation, CreateLevelMutationVariables>(CreateLevel);

export const useUpdateLevel = () =>
  useMutation<UpdateLevelMutation, UpdateLevelMutationVariables>(UpdateLevel);

export const useDeleteLevel = () =>
  useMutation<DeleteLevelMutation, DeleteLevelMutationVariables>(DeleteLevel, {
    onError(e) {
      message.error(formatErrorMessage(e));
    },
    refetchQueries: [{ query: GetLevels }],
  });

export const useArchiveDepartment = () =>
  useMutation<
    ChangeDepartmentArchiveMutation,
    ChangeDepartmentArchiveMutationVariables
  >(ChangeDepartmentArchive, {
    refetchQueries: [
      {
        query: GetDepartments,
        variables: { showArchive: true },
        fetchPolicy: "network-only",
      },
    ],
  });

export const useArchiveBranch = () =>
  useMutation<
    ChangeBranchArchiveMutation,
    ChangeBranchArchiveMutationVariables
  >(ChangeBranchArchive);

export const useArchiveSchool = () =>
  useMutation<
    ChangeSchoolArchiveMutation,
    ChangeSchoolArchiveMutationVariables
  >(ChangeSchoolArchive, {
    onError(e) {
      message.error(formatErrorMessage(e));
    },
  });

export const useVisibilitySchool = () =>
  useMutation<
    ChangeSchoolVisibilityMutation,
    ChangeSchoolVisibilityMutationVariables
  >(ChangeSchoolVisibility, {
    onError(e) {
      message.error(formatErrorMessage(e));
    },
  });

export const useArchiveLevel = () =>
  useMutation<
    ChangeAcademicLevelArchive,
    ChangeAcademicLevelArchiveMutationVariables
  >(ChangeAcademicLevelArchive, {
    onError(e) {
      message.error(formatErrorMessage(e));
    },
  });

export const useVisibilityLevel = () =>
  useMutation<
    ChangeAcademicLevelVisibilityMutation,
    ChangeAcademicLevelVisibilityMutationVariables
  >(ChangeAcademicLevelVisibility, {
    onError(e) {
      message.error(formatErrorMessage(e));
    },
  });

export const useQueryBranches = () =>
  useQuery<GetBranchesQuery, GetBranchesQueryVariables>(GetBranches);

export const useQueryPromotions = () =>
  useQuery<GetPromotionsQuery, GetPromotionsQueryVariables>(GetPromotions);

export const useCreatePromotion = () =>
  useMutation<CreatePromotionMutation, CreatePromotionMutationVariables>(
    CreatePromotion
  );

export const useUpdatePromotion = () =>
  useMutation<UpdatePromotionMutation, UpdatePromotionMutationVariables>(
    UpdatePromotion
  );

export const useDeletePromotion = () =>
  useMutation<DeletePromotionMutation, DeletePromotionMutationVariables>(
    DeletePromotion,
    { refetchQueries: [{ query: GetPromotions }] }
  );

export const useCancelPromotion = () =>
  useMutation<CancelPromotionMutation, CancelPromotionMutationVariables>(
    CancelPromotion,
    { refetchQueries: [{ query: GetPromotions }] }
  );

export const useLazyQueryPromotion = () =>
  useLazyQuery<GetPromotionQuery, GetPromotionQueryVariables>(GetPromotion);

export const useQueryAcademicYears = () =>
  useQuery<AcademicYearsQuery, AcademicYearsQueryVariables>(AcademicYears);

export const useQueryClassWithAcademicYears = () =>
  useQuery<
    GetClassWithAcademicYearsQuery,
    GetClassWithAcademicYearsQueryVariables
  >(GetClassWithAcademicYears);

export const useQueryAcademicYearOptions = () =>
  useQuery<GetAcademicYearsOptionsQuery, GetAcademicYearsOptionsQueryVariables>(
    GetAcademicYearsOptions
  );

export const useLazyQueryAcademicYears = () =>
  useLazyQuery<AcademicYearsQuery, AcademicYearsQueryVariables>(AcademicYears);

export const useLazyQueryVenues = () =>
  useLazyQuery<GetAllVenueQuery, GetAllVenueQueryVariables>(GetAllVenue);

export const useQueryVenues = () =>
  useQuery<GetAllVenueQuery, GetAllVenueQueryVariables>(GetAllVenue);

export const useQueryTemplates = (
  filterInput?: TemplateFilterInput,
  skip = false,
  fetchPolicy?: WatchQueryFetchPolicy
) =>
  useQuery<GetTemplatesQuery, GetTemplatesQueryVariables>(GetTemplates, {
    variables: {
      filterInput: {
        ...filterInput,
        status: TemplateStatus.Complete,
        isArchived: false,
      },
      pageInfo: { pageIndex: 0, pageSize: 0 },
    },
    skip,
    fetchPolicy,
  });

export const useLazyQueryTemplates = (
  options?: LazyQueryHookOptions<GetTemplatesQuery, GetTemplatesQueryVariables>
) =>
  useLazyQuery<GetTemplatesQuery, GetTemplatesQueryVariables>(
    GetTemplates,
    options
  );

export const useLazyQueryTemplate = () =>
  useLazyQuery<GetTemplateQuery, GetTemplateQueryVariables>(GetTemplate);

export const useCreateTemplate = () =>
  useMutation<CreateTemplateMutation, CreateTemplateMutationVariables>(
    CreateTemplate
  );

export const useUpdateTemplate = () =>
  useMutation<UpdateTemplateMutation, UpdateTemplateMutationVariables>(
    UpdateTemplate
  );

export const useUpdateTemplateArchive = (): MutationTuple<
  UpdateTemplateArchiveMutation,
  UpdateTemplateArchiveMutationVariables
> =>
  useMutation<
    UpdateTemplateArchiveMutation,
    UpdateTemplateArchiveMutationVariables
  >(UpdateTemplateArchive);

export const useGetNotificationConfigurations = (type: NotificationType) =>
  useQuery<
    GetNotificationConfigurationsQuery,
    GetNotificationConfigurationsQueryVariables
  >(GetNotificationConfigurations, {
    variables: {
      type,
    },
  });

export const useGetNotificationsOfTemplates = (
  variables?: GetNotificationsOfTemplatesQueryVariables
) =>
  useQuery<
    GetNotificationsOfTemplatesQuery,
    GetNotificationsOfTemplatesQueryVariables
  >(GetNotificationsOfTemplates, {
    variables,
    fetchPolicy: "network-only",
  });

export const useUpdateNotificationItem = (): MutationTuple<
  UpdateNotificationItemMutation,
  UpdateNotificationItemMutationVariables
> =>
  useMutation<
    UpdateNotificationItemMutation,
    UpdateNotificationItemMutationVariables
  >(UpdateNotificationItem);

export const useCreateMailMerge = (): MutationTuple<
  CreateMailMergeMutation,
  CreateMailMergeMutationVariables
> =>
  useMutation<CreateMailMergeMutation, CreateMailMergeMutationVariables>(
    CreateMailMerge
  );

export const useUpdateMailMerge = (): MutationTuple<
  UpdateMailMergeMutation,
  UpdateMailMergeMutationVariables
> =>
  useMutation<UpdateMailMergeMutation, UpdateMailMergeMutationVariables>(
    UpdateMailMerge
  );

export const useCompleteMailMerge = (): MutationTuple<
  CompleteMailMergeMutation,
  CompleteMailMergeMutationVariables
> =>
  useMutation<CompleteMailMergeMutation, CompleteMailMergeMutationVariables>(
    CompleteMailMerge
  );

export const useUploadMailMergeMergeList = (): MutationTuple<
  UploadMailMergeMergeListMutation,
  UploadMailMergeMergeListMutationVariables
> =>
  useMutation<
    UploadMailMergeMergeListMutation,
    UploadMailMergeMergeListMutationVariables
  >(UploadMailMergeMergeList);

export const useGetMailMerge = (): QueryTuple<
  GetMailMergeQuery,
  GetMailMergeQueryVariables
> =>
  useLazyQuery<GetMailMergeQuery, GetMailMergeQueryVariables>(GetMailMerge, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });
export const useDownloadMailMerge = (): MutationTuple<
  DownloadMailMergeMutation,
  DownloadMailMergeMutationVariables
> =>
  useMutation<DownloadMailMergeMutation, DownloadMailMergeMutationVariables>(
    DownloadMailMerge
  );

export const useCreateCourierPickupAddress = () =>
  useMutation<
    CreateCourierPickupAddressMutation,
    CreateCourierPickupAddressMutationVariables
  >(CreateCourierPickupAddress);

export const useUpdateCourierPickupAddress = () =>
  useMutation<
    UpdateCourierPickupAddressMutation,
    UpdateCourierPickupAddressMutationVariables
  >(UpdateCourierPickupAddress);

export const useCourierPickupAddresses = (
  variables?: GetAllCourierPickupAddressQueryVariables
) =>
  useQuery<
    GetAllCourierPickupAddressQuery,
    GetAllCourierPickupAddressQueryVariables
  >(GetAllCourierPickupAddress, {
    variables,
    fetchPolicy: "network-only",
  });

export const useArchiveCourierPickupAddress = () =>
  useMutation<
    UpdateCourierPickupAddressArchiveMutation,
    UpdateCourierPickupAddressArchiveMutationVariables
  >(UpdateCourierPickupAddressArchive, {
    onError(e) {
      message.error(formatErrorMessage(e));
    },
  });
