import { GetPaymentRecordModalDataQuery } from "@/graphql";
import { FormInstance } from "@thepiquelab/archus-components-web";
import BigNumber from "bignumber.js";
import moment from "moment";
import { useState } from "react";
import { PaymentInfo } from "../types";

export const setFormInitialValues = (
  data: GetPaymentRecordModalDataQuery,
  isEditMode: boolean
) => {
  const amountDue = new BigNumber(data?.invoice?.total || 0).minus(
    data?.invoice?.amountPaid || 0
  );

  const balanceAmount =
    data?.invoice?.billedTo?.financeStatement?.creditRemain || 0;

  const creditRemain = new BigNumber(balanceAmount);

  let balanceDeducted;
  if (new BigNumber(balanceAmount).lte(0)) {
    balanceDeducted = new BigNumber(0);
  }
  balanceDeducted = creditRemain.gte(amountDue) ? amountDue : creditRemain;

  const amountPayable = amountDue.minus(balanceDeducted).gte(0)
    ? amountDue.minus(balanceDeducted)
    : new BigNumber(0);

  if (!isEditMode) {
    return {
      amount: amountPayable.lte(0) ? null : amountPayable.toNumber(),
      balanceAmount: balanceDeducted.toNumber(),
    };
  }
  return {};
};

export const usePaymentInfo = (form: FormInstance<any>) => {
  const [activeItem, setActiveItem] = useState<PaymentInfo>();

  const setActivePaymentInfo = (info: PaymentInfo) => {
    setActiveItem(info);

    form.setFieldsValue({
      amount: info?.amount,
      paymentDate: moment(info?.paymentDate),
      depositDate: info?.depositDate ? moment(info.depositDate) : null,
      method: info?.method || null,
      reference: info?.reference || null,
      remarks: info?.remarks || null,
      chequeNumber: info?.chequeNumber || null,
      bankName: info?.bankName || null,
      lastDigits: info?.lastDigits || null,
      paymentProcessor: info?.paymentProcessor || null,
      balanceAmount: info?.balanceAmount || null,
    });
  };

  return {
    activePaymentInfo: activeItem,
    setActivePaymentInfo,
  };
};
