import { enumToRecord } from "@/utils/enumUnit";

export enum EnumPermissions {
  /**
   * Access to the application.
   */
  ARCHUS_ACCESS = "archus:access",
  ARCHUS_ADMINISTRATION_ACCESS = "archus-administration:access",
  ARCHUS_CRM_ACCESS = "archus-crm:access",
  ARCHUS_ACADEMIC_ACCESS = "archus-academic:access",
  ARCHUS_CCC_ACCESS = "archus-ccc:access",
  ARCHUS_NOTES_ACCESS = "archus-notes:access",

  // Balance
  BALANCE_UPDATE = "balance:update",

  // Invoice
  INVOICE_VIEW = "invoice:view",
  INVOICE_VIEW_EXPORT = "invoice:view:export",
  INVOICE_UPDATE = "invoice:update",

  // Receipt
  RECEIPT_VIEW = "receipt:view",
  RECEIPT_VIEW_EXPORT = "receipt:view:export",

  // Refund
  REFUND_VIEW = "refund:view",
  REFUND_VIEW_EXPORT = "refund:view:export",
  REFUND_UPDATE = "refund:update",

  // StatementOfAccount
  STATEMENT_OF_ACCOUNT_VIEW = "statement-of-account:view",
  STATEMENT_OF_ACCOUNT_VIEW_EXPORT = "statement-of-account:view:export",

  // Payment
  PAYMENT_VIEW = "payment:view",
  PAYMENT_UPDATE = "payment:update",

  // Worksheet
  WORKSHEET_VIEW = "worksheet:view",
  WORKSHEET_VIEW_EXPORT = "worksheet:view:export",
  WORKSHEET_UPDATE = "worksheet:update",

  // course
  COURSE_VIEW = "course:view",
  COURSE_VIEW_EXPORT = "course:view:export",
  COURSE_UPDATE = "course:update",

  // class
  CLASS_VIEW = "class:view",
  CLASS_VIEW_EXPORT = "class:view:export",
  CLASS_UPDATE = "class:update",

  // Lesson
  LESSON_VIEW = "lesson:view",
  LESSON_VIEW_EXPORT = "lesson:view:export",
  LESSON_UPDATE = "lesson:update",

  // Topic
  TOPIC_CREATE = "topic:create",

  // Homework
  HOMEWORK_VIEW = "homework:view",
  HOMEWORK_UPDATE = "homework:update",

  // Exercise
  EXERCISE_VIEW = "exercise:view",
  EXERCISE_UPDATE = "exercise:update",

  // Collect
  COLLECT_VIEW = "collect:view",
  COLLECT_UPDATE = "collect:update",

  // Users
  SYSTEM_USER_VIEW = "system-user:view",
  SYSTEM_USER_VIEW_EXPORT = "system-user:view:export",
  SYSTEM_USER_UPDATE = "system-user:update",

  // Parent
  PARENT_VIEW = "parent:view",
  PARENT_EXPORT = "parent:view:export",
  PARENT_UPDATE = "parent:update",

  // Student
  STUDENT_VIEW = "student:view",
  STUDENT_EXPORT = "student:view:export",
  STUDENT_UPDATE = "student:update",

  // Enrollment
  ENROLLMENT_VIEW = "enrollment:view",

  // Attendance
  ATTENDANCE_VIEW = "attendance:view",
  ATTENDANCE_UPDATE = "attendance:update",

  // Registration
  REGISTRATION_VIEW = "registration:view",
  REGISTRATION_EXPORT = "registration:view:export",
  REGISTRATION_UPDATE = "registration:update",

  // Arrangement
  ARRANGEMENT_VIEW = "arrangement:view",
  ARRANGEMENT_EXPORT = "arrangement:view:export",
  ARRANGEMENT_UPDATE = "arrangement:update",

  // GroupAssignment
  GROUP_ASSIGNMENT_VIEW = "group-assignment:view",
  GROUP_ASSIGNMENT_UPDATE = "group-assignment:update",

  // Integrations
  INTEGRATION_VIEW = "integration:view",
  INTEGRATION_UPDATE = "integration:update",

  // Features
  FEATURE_VIEW = "feature:view",
  FEATURE_UPDATE = "feature:update",

  // TASK
  TASK_VIEW = "task:view",
  TASK_UPDATE = "task:update",

  // Whatsapp
  WHATSAPP_ACCOUNT_VIEW = "whatsapp-account:view",
  WHATSAPP_ACCOUNT_UPDATE = "whatsapp-account:update",

  WHATSAPP_MESSAGE_VIEW = "whatsapp-message:view",
  WHATSAPP_MESSAGE_UPDATE = "whatsapp-message:update",

  // Feedback
  FEEDBACK_VIEW = "feedback:view",
  FEEDBACK_UPDATE = "feedback:update",

  // Report
  REPORT_INVOICE_VIEW = "report-invoice:view",
  REPORT_PAYMENT_VIEW = "report-payment:view",
  REPORT_REVENUE_VIEW = "report-revenue:view",
  REPORT_ATTENDANCE_VIEW = "report-attendance:view",
  REPORT_INVOICE_VIEW_EXPORT = "report-invoice:view:export",
  REPORT_PAYMENT_VIEW_EXPORT = "report-payment:view:export",
  REPORT_REVENUE_VIEW_EXPORT = "report-revenue:view:export",
  REPORT_ATTENDANCE_VIEW_EXPORT = "report-attendance:view:export",

  // Course Notification List
  COURSE_NOTIFICATION_LIST_VIEW = "course-notification-list:view",
  COURSE_NOTIFICATION_LIST_UPDATE = "course-notification-list:update",

  // Remarks
  REMARKS_VIEW = "remarks:view",
  REMARKS_UPDATE = "remarks:update",

  // Role
  ROLE_VIEW = "role:view",
  ROLE_UPDATE = "role:update",

  // Permission
  PERMISSION_VIEW = "permission:view",

  // Material
  MATERIAL_VIEW = "material:view",
  MATERIAL_UPDATE = "material:update",

  // Delivery
  DELIVERY_VIEW = "delivery:view",
  DELIVERY_UPDATE = "delivery:update",

  // Lesson Plan
  LESSON_PLAN_VIEW = "lesson-plan:view",
  LESSON_PLAN_UPDATE = "lesson-plan:update",

  // Packing List
  PACKING_LIST_VIEW = "packing-list:view",
  PACKING_LIST_UPDATE = "packing-list:update",

  // Reward
  REWARD_VIEW = "reward:view",
  REWARD_UPDATE = "reward:update",

  // Department
  DEPARTMENT_VIEW = "department:view",
  DEPARTMENT_UPDATE = "department:update",

  // Branch
  BRANCH_VIEW = "branch:view",
  BRANCH_UPDATE = "branch:update",

  // Venue
  VENUE_VIEW = "venue:view",
  VENUE_UPDATE = "venue:update",

  // AcademicLevel
  ACADEMIC_LEVEL_VIEW = "academic-level:view",
  ACADEMIC_LEVEL_UPDATE = "academic-level:update",

  // School
  SCHOOL_VIEW = "school:view",
  SCHOOL_UPDATE = "school:update",

  // AcademicYear
  ACADEMIC_YEAR_VIEW = "academic-year:view",
  ACADEMIC_YEAR_UPDATE = "academic-year:update",

  // Term Billing Due Date
  TERM_BILLING_DUE_DATE_VIEW = "term-billing-due-date:view",
  TERM_BILLING_DUE_DATE_UPDATE = "term-billing-due-date:update",

  // Holiday
  HOLIDAY_VIEW = "holiday:view",
  HOLIDAY_UPDATE = "holiday:update",

  // Reference Data
  REFERENCE_DATA_VIEW = "reference-data:view",
  REFERENCE_DATA_UPDATE = "reference-data:update",

  // Campaign
  CAMPAIGN_VIEW = "campaign:view",
  CAMPAIGN_VIEW_EXPORT = "campaign:view:export",
  CAMPAIGN_UPDATE = "campaign:update",

  // Notification Log
  NOTIFICATION_LOG_VIEW = "notification-log:view",
  NOTIFICATION_LOG_UPDATE = "notification-log:update",

  // Template
  TEMPLATE_VIEW = "template:view",
  TEMPLATE_UPDATE = "template:update",

  // Promotion
  PROMOTION_VIEW = "promotion:view",
  PROMOTION_UPDATE = "promotion:update",

  // Suitability
  SUITABILITY_UPDATE = "suitability:update",

  // Course Eligibility
  COURSE_ELIGIBILITY_VIEW = "course-eligibility:view",
  COURSE_ELIGIBILITY_UPDATE = "course-eligibility:update",

  // view-term-billing-due-date:view
  VIEW_TERM_BILLING_DUE_DATE_VIEW = "view-term-billing-due-date:view",
  // view-term-billing-due-date:update
  VIEW_TERM_BILLING_DUE_DATE_UPDATE = "view-term-billing-due-date:update",

  USER_REQUEST_VIEW = "user-request:view",
  USER_REQUEST_UPDATE = "user-request:update",
  USER_REQUEST_PAYMENT_VIEW = "user-request-payment:view",
  USER_REQUEST_PAYMENT_UPDATE = "user-request-payment:update",

  // Feature Flag
  DEVICE_VIEW = "device:view",
  DEVICE_UPDATE = "device:update",
}

export const formatPermissions = (): void => {
  const toCamelCase = (str: string): string =>
    str
      .toLowerCase()
      .replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());

  const toPascalCase = (str: string): string => {
    const camelCaseStr = toCamelCase(str);
    return camelCaseStr.charAt(0).toUpperCase() + camelCaseStr.slice(1);
  };

  const record = enumToRecord(EnumPermissions);

  const result: { [key: string]: string } = {};

  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const key in record) {
    const outputString = toPascalCase(record[key]);
    result[outputString] = key;
  }
};
