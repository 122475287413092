import { EnumPermissions } from "@/components/authorize/Permissions";
import {
  calcCapacityTotal,
  ClassSeatDetailDTO,
  ClassSeatDTO,
} from "@/components/class/NewClassCapacity/classUtils";
import useAuth from "@/hooks/useAuth";
import { Tabs } from "@thepiquelab/archus-components-web";
import React, { memo, useMemo, useState } from "react";
import {
  CapacitySeatType,
  Enrollment,
  GetClassEnrollmentsQuery,
  GetSelectRegistrationTypeDataQuery,
  Lesson,
} from "../../../../graphql";
import { filterSeatsOrEnrollments } from "../../RegistrationDetail/common/StatusBtn/utils";
import NewEnrollmentItem from "./EnrollmentItem";
import WaitingListItem from "./WaitingListItem";

const { TabPane } = Tabs;

export const handleFlatCapacity = (
  seats?: ClassSeatDTO[]
): ClassSeatDetailDTO[] => seats?.map((i) => i.seatDetails)?.flat(2) || [];

interface ListEnrollmentsInModalProps {
  classData: GetSelectRegistrationTypeDataQuery["class"];
}

const ListEnrollmentsInModal: React.FC<ListEnrollmentsInModalProps> = ({
  classData,
}) => {
  const [tabKey, setTabKey] = useState("enrolled");

  const seats = useMemo(
    () => handleFlatCapacity(classData?.capacity?.seats),
    [classData?.capacity?.seats]
  );

  const seatsEmpty = useMemo(
    () =>
      calcCapacityTotal(classData?.capacity) - seats?.length > 0
        ? calcCapacityTotal(classData?.capacity) - seats?.length
        : 0,
    [classData?.capacity, seats?.length]
  );

  const enrollmentViewAuth = useAuth([EnumPermissions.ENROLLMENT_VIEW]);

  if (!classData) {
    return null;
  }

  const availableSeats = seats?.filter(
    (i) =>
      ![CapacitySeatType.WaitingList, CapacitySeatType.UserRequest].includes(
        i?.type
      )
  );

  return (
    <div className="border-b">
      <Tabs defaultActiveKey={tabKey} onChange={setTabKey}>
        {enrollmentViewAuth && (
          <TabPane
            tab={
              <div className="flex flex-row items-center justify-center">
                <div
                  className={`font_semibold text-4 leading-tight ${
                    tabKey === "enrolled"
                      ? "text-primary-blue"
                      : "text-primary-navyLight"
                  }`}
                >
                  Enrolled
                </div>
                <div
                  className={`font_bold text-3 leading-tight text-white ${
                    tabKey === "enrolled"
                      ? "bg-primary-blue"
                      : "bg-primary-navyLight"
                  } flex items-center justify-center ml-2 py-1 px-2 rounded-sm`}
                >
                  {availableSeats?.length}
                </div>
              </div>
            }
            key="enrolled"
            className="divide-y divide-gray-400"
          >
            <div className="min-h-40 pt-6">
              {availableSeats?.map((i, index) => (
                <NewEnrollmentItem
                  key={`NewEnrollmentItem_${index.toString()}`}
                  data={i}
                  lesson={classData?.lessons as Lesson[]}
                  currentEnrollment={filterSeatsOrEnrollments(
                    i,
                    classData?.enrollments as unknown as Enrollment[] &
                      GetClassEnrollmentsQuery["class"]["enrollments"]
                  )}
                />
              ))}
              {[...Array(seatsEmpty)]
                .map(() => 1)
                .map((w, index) => (
                  <WaitingListItem
                    isEmpty
                    key={`WaitingListItemEmpty_${index.toString()}`}
                  />
                ))}
            </div>
          </TabPane>
        )}
        <TabPane
          tab={
            <div className="flex flex-row items-center justify-center">
              <div
                className={`font_semibold text-4 leading-tight ${
                  tabKey === "waitingList"
                    ? "text-primary-blue"
                    : "text-primary-navyLight"
                }`}
              >
                Waiting List
              </div>
              <div
                className={`font_bold text-3 leading-tight text-white ${
                  tabKey === "waitingList"
                    ? "bg-primary-blue"
                    : "bg-primary-navyLight"
                } flex items-center justify-center ml-2 py-1 px-2 rounded-sm`}
              >
                {classData?.registrations?.length}
              </div>
            </div>
          }
          key="waitingList"
          className="divide-y divide-gray-400 overscroll-y-auto"
        >
          <div className="min-h-40 pt-6">
            {classData?.registrations?.map((w, i) => (
              <WaitingListItem
                key={`WaitingListItem_${i.toString()}`}
                enrollmentsData={classData}
                profile={w.student}
              />
            ))}
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default memo(ListEnrollmentsInModal);
