export interface Devices {
  _id: string;
  room: string;
  status: DeviceStatus;
  ip: string;
  port: string;
  createDateTime: string;
  lastModifiedDateTime: string;
}

export enum DeviceStatus {
  Connected = "CONNECTED",
  Connecting = "CONNECTING",
  Disconnected = "DISCONNECTED",
}
