import { TaskType } from "@/graphql";

export interface QueryTaskIndexProps {
  pagination?: {
    pageIndex?: number;
    pageSize?: number;
  };
  sorter?: {
    sortKey?: string;
    sort?: "ascend" | "descend";
  };
}

export const checkIsFollowUpTask = (type: TaskType): boolean =>
  type?.startsWith("FollowUp");

export const checkIsReminderTask = (type: TaskType): boolean =>
  type?.startsWith("Reminder");

export const checkIsOperationalTask = (type: TaskType): boolean =>
  type === TaskType.Operational;
