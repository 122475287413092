import { ApolloProvider } from "@apollo/client";
import * as Sentry from "@sentry/react";
import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import createApolloClient from "./ApolloClient";
import { useAuth0 } from "./Auth0Wrapper";
import { EnumPermissions } from "./components/authorize/Permissions";
import CacheProvider from "./components/common/CacheProvider";
import { Auth0Config } from "./config";
import { useAccessControl } from "./hooks/useAccessControl";
import useCheckVersion from "./hooks/useCheckVersion";
import { useCurrentUser } from "./hooks/useCurrentUser";
import PageNotFound from "./pages/PageNotFound";
import AdminIndexPage from "./pages/admin";
import ErrorsIndexPage from "./pages/errors";
import InsufficientCredentialsPage from "./pages/errors/InsufficientCredentialsPage";

const App: React.FC = () => {
  const { getToken } = useCurrentUser();
  const { userInOrganization, logout } = useAuth0();

  const { hasAllPermissions } = useAccessControl();

  const { removeWorker } = useCheckVersion();

  useEffect(() => {
    return () => {
      removeWorker();
    };
  }, []);

  return (
    <Sentry.ErrorBoundary>
      <ApolloProvider
        client={createApolloClient(getToken, () =>
          logout({ returnTo: Auth0Config.redirect_uri })
        )}
      >
        <CacheProvider>
          <BrowserRouter>
            <Switch>
              {hasAllPermissions([EnumPermissions.ARCHUS_CRM_ACCESS]) &&
              userInOrganization ? (
                <Route
                  path={"/"}
                  render={({ location }) => {
                    if ((location.state as any)?.is404) {
                      return <PageNotFound />;
                    }
                    if ((location.state as any)?.isErrorScreen) {
                      return <InsufficientCredentialsPage />;
                    }
                    return <AdminIndexPage />;
                  }}
                />
              ) : (
                <Route path={"/"}>
                  <ErrorsIndexPage />
                </Route>
              )}
            </Switch>
          </BrowserRouter>
        </CacheProvider>
      </ApolloProvider>
    </Sentry.ErrorBoundary>
  );
};

export default App;
