import * as Sentry from "@sentry/react";
import ReactDOM from "react-dom";
import Helmet from "react-helmet";
import Package from "../package.json";
import App from "./App";
import { Auth0Provider } from "./Auth0Wrapper";
import { actions } from "./MicroAppStateActions";
import {
  AppConfig,
  Auth0Config,
  SentryConfig,
  SystemUserBaseUrl,
} from "./config";
import * as serviceWorker from "./serviceWorker";
import history from "./utils/history";

/** do not delete the line between the style file
 *  to avoid the order of the style files be changed */
import "./assets/style/customKendoGrid.scss";

import "./assets/style/tailwind.css";

import "./theme.less";

import { prefetchApps } from "qiankun";
import { AxiosClient } from "./apis/clients";
import "./index.scss";

const onRedirectCallback = (appState?: any): void => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

const showOrganizationTitle = (type: string): string => {
  switch (type) {
    case "TPL":
      return "The Pique Lab | Archus School Management System";
    case "Pilab":
      return "The Pi Lab | Archus School Management System";
    default:
      return "The Pique Lab | Archus School Management System";
  }
};

const title = showOrganizationTitle(AppConfig.OrganizationName);

if (!process?.env?.REACT_APP_QIANKUN_PREFETCH) {
  prefetchApps([
    {
      name: "archus-administration",
      entry: SystemUserBaseUrl,
    },
  ]);
}

actions.onGlobalStateChange((state, prev) => {
  // console.info("onGlobalStateChange: ", state, prev);
});

Sentry.init({
  dsn: SentryConfig.DSN,
  tracesSampleRate: SentryConfig.tracesSampleRate,
  integrations: [new Sentry.Replay(), new Sentry.BrowserTracing()],
  release: `${Package.name}@${Package.version}`,
  environment: SentryConfig.Environment ? SentryConfig.Environment : "local",
});

AxiosClient.init();

ReactDOM.render(
  <Sentry.ErrorBoundary>
    <Auth0Provider
      clientOptions={Auth0Config}
      onRedirectCallback={onRedirectCallback}
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <App />
    </Auth0Provider>
  </Sentry.ErrorBoundary>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
