import { RegistrationStage } from "@/apis/enrollment/types";
import {
  Checkbox,
  CheckboxChangeEvent,
  DatePicker,
  Input,
  Row,
} from "@thepiquelab/archus-components-web";
import { formatDate, formatTimeRange } from "@thepiquelab/archus-library";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { SelectedLessonInput } from "../../../../graphql";
import formatLabel from "../../../../utils/formatLabel";
import styles from "../TrialModal/TrialModal.module.scss";

interface LessonsData {
  id?: string;
  lessonNumber?: number;
  lessonStart?: string;
  lessonEnd?: string;
  maximumClassCapacity?: number;
  attendance?: Array<any>;
}

interface Props {
  stage?: RegistrationStage;
  lessonsData: LessonsData[];
  availableLessonIds: string[];
  onAttendingLessonsChange: (v: Array<SelectedLessonInput>) => void;
  onDueDateChange: (v: Date) => void;
  onRegistrationDateChange: (v: Date) => void;
  onTextAreaChange: (v: string) => void;
  selectedLessons?: SelectedLessonInput[];
  hideRemarks?: boolean;
}

const WorkshopModal: React.FC<Props> = ({
  stage = RegistrationStage.Regular,
  lessonsData,
  availableLessonIds,
  onAttendingLessonsChange,
  onDueDateChange,
  onRegistrationDateChange,
  onTextAreaChange,
  selectedLessons,
  hideRemarks,
}) => {
  const [selectData, setSelectData] = useState<Array<SelectedLessonInput>>(
    selectedLessons?.length
      ? selectedLessons
      : lessonsData
          ?.filter((l) => availableLessonIds.includes(l.id))
          ?.map((i) => ({ lessonId: i.id }))
  );
  const [dueDate, setDueDate] = useState(moment(new Date()).add(7, "day"));
  const [indeterminate, setIndeterminate] = useState(
    selectData.length < lessonsData?.length
  );
  const [checkAll, setCheckAll] = useState(
    selectData?.length ===
      lessonsData?.filter((l) => availableLessonIds.includes(l.id))?.length
  );
  const [isSameAsStartDate, setIsSameAsStartDate] = useState(false);

  const sortedLessons = useMemo(
    () =>
      lessonsData?.length
        ? [...lessonsData].sort((a, b) => a.lessonNumber - b.lessonNumber)
        : [],
    [lessonsData]
  );

  const firstLessonDate: Date = useMemo(() => {
    const selectedLessons = sortedLessons.filter((item) =>
      selectData.some((data) => data.lessonId === item.id)
    );
    return selectedLessons?.length
      ? new Date(selectedLessons[0].lessonStart)
      : new Date();
  }, [sortedLessons]);

  const handleChange = (checkedValues: any[]): void => {
    const selectedLessons = sortedLessons.filter((item) =>
      checkedValues.includes(item.id)
    );

    const checkData = selectedLessons.map((item) => ({
      lessonId: item.id,
    }));

    if (stage === RegistrationStage.Regular) {
      onDueDateChange(dueDate.toDate());
    }

    setSelectData(checkData);
    onRegistrationDateChange(
      new Date(selectedLessons?.[0]?.lessonStart) || firstLessonDate
    );

    setIndeterminate(
      !!selectedLessons.length && selectedLessons.length < sortedLessons.length
    );
    setCheckAll(checkedValues?.length === selectedLessons?.length);
  };

  const handleCheckAllChange = (e: CheckboxChangeEvent): void => {
    setSelectData(
      e.target.checked
        ? sortedLessons
            .filter((l) => availableLessonIds?.includes(l.id))
            .map((l) => ({ lessonId: l.id }))
        : []
    );

    if (stage === RegistrationStage.Regular) {
      onDueDateChange(dueDate.toDate());
    }
    setIndeterminate(
      e.target.checked && availableLessonIds?.length < sortedLessons.length
    );
    onRegistrationDateChange(firstLessonDate);
    setCheckAll(e.target.checked);
  };

  useEffect(() => {
    onAttendingLessonsChange(selectData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectData]);

  useEffect(() => {
    if (dueDate) {
      onDueDateChange(dueDate.toDate());
    }
  }, [dueDate]);

  useEffect(() => {
    if (
      isSameAsStartDate &&
      [RegistrationStage.Reservation, RegistrationStage.WaitingList].includes(
        stage
      )
    ) {
      setDueDate(moment(firstLessonDate));
    }
    onRegistrationDateChange(firstLessonDate);
  }, [firstLessonDate]);

  return (
    <div className="flex flex-col">
      <div className="overflow-auto h-40vh">
        <Checkbox
          indeterminate={indeterminate}
          onChange={handleCheckAllChange}
          checked={checkAll}
        >
          <span className="ml-2 font-semibold">
            {formatLabel("Select All")}
          </span>
        </Checkbox>
        <Checkbox.Group
          style={{ width: "100%" }}
          onChange={handleChange}
          value={selectData && selectData.map((item) => item.lessonId)}
        >
          {sortedLessons.map((item) => (
            <Row className="my-6" key={item.id}>
              <Checkbox
                value={item.id}
                className="inline-flex items-start h-maxcontent"
                disabled={!availableLessonIds?.includes(item.id)}
              >
                <span className="ml-2 flex flex-col">
                  <span
                    className={`${
                      item.attendance &&
                      item.maximumClassCapacity <= item.attendance.length
                        ? styles.TrialModal__full
                        : "font_semibold"
                    } `}
                  >
                    {`Lesson ${item.lessonNumber}, ${formatDate(
                      item.lessonStart
                    )}`}
                  </span>
                  <span style={{ color: "#8C9297" }}>
                    {formatTimeRange(item.lessonStart, item.lessonEnd, {
                      showDayOfWeek: true,
                    })}
                  </span>
                </span>
              </Checkbox>
            </Row>
          ))}
        </Checkbox.Group>
      </div>
      {[RegistrationStage.Reservation].includes(stage) && (
        <Row className="my-4">
          <div className="my-2 font-bold">{formatLabel("Due date")}</div>
          <DatePicker
            className="w-full"
            getPopupContainer={(triggerNode: any) => triggerNode.parentNode}
            value={dueDate}
            onChange={(value) => {
              setDueDate(value);
              setIsSameAsStartDate(false);
            }}
            showToday={false}
          />
          <Checkbox
            className="mt-6 h-maxcontent"
            onChange={(e) => {
              setIsSameAsStartDate(!isSameAsStartDate);
              if (!isSameAsStartDate) {
                setDueDate(moment(firstLessonDate));
              }
            }}
            checked={isSameAsStartDate}
          >
            <span className="ml-2">{formatLabel("Same as start date")}</span>
          </Checkbox>
        </Row>
      )}

      {!hideRemarks && (
        <>
          <Row className={"my-2 font-bold"}>Remarks</Row>
          <Row>
            <Input.TextArea
              rows={2}
              allowClear
              onChange={(e) => onTextAreaChange(e.target.value)}
            />
          </Row>
        </>
      )}
    </div>
  );
};

export default WorkshopModal;
