import HybridGroupsModalSkeleton from "@/components/common/Skeleton/ClassArrangementSkeleton/Modal/HybridGroupsModalSkeleton";
import { StudentCell } from "@/components/widget/UserCell/StudentCell";
import {
  ClassType,
  EnrollmentStatus,
  EnrollmentType,
  GetHybridGroupsClass,
  GetHybridGroupsClassQuery,
  GetHybridGroupsClassQueryVariables,
  LearningArrangementType,
} from "@/graphql";
import { enumToArray } from "@/utils/enumUnit";
import formatErrorMessage from "@/utils/formatErrorMessage";
import formatLabel from "@/utils/formatLabel";
import { useLazyQuery } from "@apollo/client";
import {
  Badge,
  Empty,
  Form,
  FormInstance,
  Select,
  Store,
  Tabs,
  Typography,
  message,
} from "@thepiquelab/archus-components-web";
import { formatDate, formatTimeRange } from "@thepiquelab/archus-library";
import moment from "moment";
import React, { memo, useEffect, useMemo, useState } from "react";
import { useUpdateEnrollmentPreferredLearningArrangement } from "../../RegistrationHooks";

interface Props {
  classId: string;
  form?: FormInstance<any>;
  onSubmit?: (value: Store) => void;
  selectedLessonIds?: string[];
}

interface LearningArrangementCountType {
  color: string;
  label: string;
  count: number;
}

const HybridGroups: React.FC<Props> = (props) => {
  const { classId, form: formInstance, onSubmit, selectedLessonIds } = props;
  const [form] = Form.useForm(formInstance);
  const [updatingEnrollmentId, setUpdatingEnrollmentId] = useState<string>();

  const [queryClass, { data, loading, refetch }] = useLazyQuery<
    GetHybridGroupsClassQuery,
    GetHybridGroupsClassQueryVariables
  >(GetHybridGroupsClass, { fetchPolicy: "no-cache" });

  const preferredLearningArrangement = Form.useWatch(
    "preferredLearningArrangement",
    form
  );

  const [updatePreferredLearningArrangement] =
    useUpdateEnrollmentPreferredLearningArrangement();

  const handleUpdatePreferredLearningArrangement = async (
    id: string,
    learningArrangement: LearningArrangementType
  ): Promise<void> => {
    try {
      setUpdatingEnrollmentId(id);
      await updatePreferredLearningArrangement({
        variables: {
          input: {
            id,
            preferredLearningArrangement: learningArrangement || null,
          },
        },
      });
      refetch({
        id: classId,
        types: [EnrollmentType.Regular],
        status: [EnrollmentStatus.Active],
      });
    } catch (error) {
      message.error(formatErrorMessage(error));
    } finally {
      setUpdatingEnrollmentId(null);
    }
  };

  const isWorkshop = useMemo(
    () => data?.class?.type === ClassType.Workshop,
    [data]
  );

  const learningArrangementOptions = useMemo(() => {
    const learningArrangementTypes = enumToArray(LearningArrangementType)
      .filter(
        (option) =>
          isWorkshop || option.value !== LearningArrangementType.Hybrid
      )
      .map((option) => (
        <Select.Option value={option.value}>
          {formatLabel(option.key)}
        </Select.Option>
      ));

    return learningArrangementTypes;
  }, [isWorkshop]);

  const lessonModeOptions = useMemo(() => {
    const learningArrangementTypes = enumToArray(LearningArrangementType)
      .filter((option) => option.value !== LearningArrangementType.Hybrid)
      .map((option) => (
        <Select.Option value={option.value}>
          {formatLabel(option.key)}
        </Select.Option>
      ));

    return learningArrangementTypes;
  }, []);

  const learningArrangementCountGroup = useMemo(() => {
    const group: LearningArrangementCountType[] = [];
    const onsite: LearningArrangementCountType = {
      color: "#5B9DFF",
      label: "Onsite",
      count:
        data?.class?.enrollments?.filter(
          (e) =>
            e.preferredLearningArrangement === LearningArrangementType.Onsite
        ).length || 0,
    };
    const digital: LearningArrangementCountType = {
      color: "#64DD17",
      label: "Digital",
      count:
        data?.class?.enrollments?.filter(
          (e) =>
            e.preferredLearningArrangement === LearningArrangementType.Digital
        ).length || 0,
    };
    group.push(onsite, digital);
    if (isWorkshop) {
      const hybrid: LearningArrangementCountType = {
        color: "#FF7324",
        label: "Hybrid",
        count:
          data?.class?.enrollments?.filter(
            (e) =>
              e.preferredLearningArrangement === LearningArrangementType.Hybrid
          ).length || 0,
      };
      group.push(hybrid);
    }
    return group;
  }, [data, isWorkshop]);

  const selectedLessons = useMemo(() => {
    const lessons =
      data?.class?.lessons?.filter((l) => selectedLessonIds?.includes(l.id)) ||
      [];
    return lessons;
  }, [data, selectedLessonIds]);

  const tabItems = [
    {
      label: "Select Preferred Learning Arrangement",
      key: "1",
      children: (
        <div className="flex flex-col gap-y-6 mt-6">
          <div className="inline-flex gap-x-2">
            {learningArrangementCountGroup.map((count) => (
              <Badge
                color={count.color}
                text={`${count.label}: ${count.count}`}
              />
            ))}
          </div>
          <Form.Item name="preferredLearningArrangement" noStyle>
            <Select
              className="w-40"
              allowClear
              onChange={(e) => {
                if (e !== LearningArrangementType.Hybrid) {
                  form.resetFields(["lessonModes"]);
                }
              }}
            >
              {learningArrangementOptions}
            </Select>
          </Form.Item>
          {preferredLearningArrangement === LearningArrangementType.Hybrid &&
            isWorkshop && (
              <Form.Item noStyle className="flex flex-col gap-y-6">
                <div className="font-semibold">
                  {formatLabel("Lesson Mode")}
                </div>
                {selectedLessons.map((lesson, index) => (
                  <div className="inline-flex items-center justify-between w-full">
                    <Form.Item
                      noStyle
                      name={["lessonModes", index, "lessonId"]}
                      initialValue={lesson.id}
                    >
                      {`Session ${lesson.lessonNumber} ${formatTimeRange(
                        lesson.lessonStart,
                        lesson.lessonEnd
                      )}, ${formatDate(lesson.lessonStart)} ${moment(
                        lesson.lessonStart
                      ).format("ddd")}`}
                    </Form.Item>
                    <Form.Item
                      noStyle
                      name={["lessonModes", index, "lessonMode"]}
                    >
                      <Select className="w-40" allowClear>
                        {lessonModeOptions}
                      </Select>
                    </Form.Item>
                  </div>
                ))}
              </Form.Item>
            )}
        </div>
      ),
    },
    {
      label: "Other Students",
      key: "2",
      children: (
        <div className="flex flex-col mb-0 mt-6 gap-6">
          <Typography.Heading level={3}>
            {formatLabel("Students")}
          </Typography.Heading>
          {data?.class?.enrollments?.length ? (
            data?.class?.enrollments?.map((enrollment) => (
              <div className="inline-flex items-center justify-between">
                <StudentCell profile={enrollment?.student} />
                <Select<LearningArrangementType>
                  className="w-40"
                  allowClear
                  value={enrollment.preferredLearningArrangement}
                  onChange={(value) => {
                    handleUpdatePreferredLearningArrangement(
                      enrollment.id,
                      value
                    );
                  }}
                  loading={updatingEnrollmentId === enrollment.id}
                >
                  {learningArrangementOptions}
                </Select>
              </div>
            ))
          ) : (
            <Empty />
          )}
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (classId) {
      queryClass({
        variables: {
          id: classId,
          types: [EnrollmentType.Regular],
          status: [EnrollmentStatus.Active],
        },
      });
    }
  }, []);

  if (loading) {
    return <HybridGroupsModalSkeleton />;
  }

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <Tabs>
        {tabItems.map((item) => (
          <Tabs.TabPane tabKey={item.key} key={item.key} tab={item.label}>
            {item.children}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </Form>
  );
};

export default memo(HybridGroups);
