/* eslint-disable max-lines */
interface DefaultUserCustomiseColumnSeed {
  isRequired: boolean;
  isVisible: boolean;
  tableName: string;
  tableShowName?: string;
  columnShowName?: string;
  /**
   * CSV Column name map
   * Useful to link customised columns to the Exported CSV columns
   * */
  csvColumnName?: string;
  columnName: string;
  weight: number;
}

const courses: DefaultUserCustomiseColumnSeed[] = [
  {
    tableName: "courses",
    columnName: "name",
    tableShowName: "Course List",
    columnShowName: "Course",
    isVisible: true,
    isRequired: true,
    weight: 0,
  },
  {
    tableName: "courses",
    columnName: "nameShort",
    tableShowName: "Course List",
    columnShowName: "Course Shorthand",
    isVisible: true,
    isRequired: false,
    weight: 1,
  },
  {
    tableName: "courses",
    columnName: "type",
    tableShowName: "Course List",
    columnShowName: "Course Type",
    isVisible: true,
    isRequired: false,
    weight: 2,
  },
  {
    tableName: "courses",
    columnName: "academicLevelsWithSort",
    tableShowName: "Course List",
    columnShowName: "Level",
    isVisible: true,
    isRequired: false,
    weight: 3,
  },
  {
    tableName: "courses",
    columnName: "ongoingClasses",
    tableShowName: "Course List",
    columnShowName: "Ongoing Classes",
    isVisible: true,
    isRequired: false,
    weight: 13,
  },
  {
    tableName: "courses",
    columnName: "status",
    tableShowName: "Course List",
    columnShowName: "Status",
    isVisible: true,
    isRequired: false,
    weight: 15,
  },
  {
    tableName: "courses",
    columnName: "academicStreams",
    tableShowName: "Course List",
    columnShowName: "Academic Stream",
    isVisible: true,
    isRequired: false,
    weight: 4,
  },
  {
    tableName: "courses",
    columnName: "courseFee",
    tableShowName: "Course List",
    columnShowName: "Course Fee",
    isVisible: true,
    isRequired: false,
    weight: 7,
  },
  {
    tableName: "courses",
    columnName: "courseFee.billingFrequency",
    tableShowName: "Course List",
    columnShowName: "Billing FREQ",
    isVisible: true,
    isRequired: false,
    weight: 8,
  },
  {
    tableName: "courses",
    columnName: "trialFee",
    tableShowName: "Course List",
    columnShowName: "Trial Fee",
    isVisible: true,
    isRequired: false,
    weight: 9,
  },
  {
    tableName: "courses",
    columnName: "additionalLessonFee",
    tableShowName: "Course List",
    columnShowName: "AL Fee",
    isVisible: true,
    isRequired: false,
    weight: 10,
  },
  {
    tableName: "courses",
    columnName: "depositFee",
    tableShowName: "Course List",
    columnShowName: "Deposit Fee",
    isVisible: true,
    isRequired: false,
    weight: 10,
  },
  {
    tableName: "courses",
    columnName: "materialsFee",
    tableShowName: "Course List",
    columnShowName: "Materials Fee",
    isVisible: true,
    isRequired: false,
    weight: 11,
  },
  {
    tableName: "courses",
    columnName: "registrationFee",
    tableShowName: "Course List",
    columnShowName: "REG Fee",
    isVisible: true,
    isRequired: false,
    weight: 12,
  },
  {
    tableName: "courses",
    columnName: "registrations",
    tableShowName: "Course List",
    columnShowName: "Registrations",
    isVisible: true,
    isRequired: false,
    weight: 14,
  },
  {
    tableName: "courses",
    columnName: "trials",
    tableShowName: "Course List",
    columnShowName: "Trials",
    isVisible: true,
    isRequired: false,
    weight: 16,
  },
  {
    tableName: "courses",
    columnName: "reservations",
    tableShowName: "Course List",
    columnShowName: "Reservations",
    isVisible: true,
    isRequired: false,
    weight: 17,
  },
  {
    tableName: "courses",
    columnName: "withdrawing",
    tableShowName: "Course List",
    columnShowName: "Withdrawing",
    isVisible: true,
    isRequired: false,
    weight: 18,
  },
  {
    tableName: "courses",
    columnName: "withdrawals",
    tableShowName: "Course List",
    columnShowName: "Withdrawals",
    isVisible: true,
    isRequired: false,
    weight: 18,
  },
  {
    tableName: "courses",
    columnName: "courseCompleted",
    tableShowName: "Course List",
    columnShowName: "Course Completed",
    isVisible: true,
    isRequired: false,
    weight: 18,
  },
  {
    tableName: "courses",
    columnName: "createdBy",
    tableShowName: "Course List",
    columnShowName: "Created By",
    isVisible: true,
    isRequired: false,
    weight: 18,
  },
  {
    tableName: "courses",
    columnName: "lastModifiedBy",
    tableShowName: "Course List",
    columnShowName: "Last Modified By",
    isVisible: true,
    isRequired: false,
    weight: 18,
  },
  {
    tableName: "courses",
    columnName: "makeUpCredits.term1",
    tableShowName: "Course List",
    columnShowName: "RL Credit",
    isVisible: true,
    isRequired: false,
    weight: 18,
  },
  {
    tableName: "courses",
    columnName: "description",
    tableShowName: "Course List",
    columnShowName: "Description",
    isVisible: true,
    isRequired: false,
    weight: 18,
  },
  {
    tableName: "courses",
    columnName: "academicStreamWithSort",
    tableShowName: "Course List",
    columnShowName: "Academic Streams",
    isVisible: true,
    isRequired: false,
    weight: 19,
  },
].map((item, index) => ({ ...item, weight: index }));

const classes: DefaultUserCustomiseColumnSeed[] = [
  {
    tableName: "classes",
    columnName: "name",
    tableShowName: "Classes",
    columnShowName: "Class",
    isVisible: true,
    isRequired: false,
    weight: 0,
  },
  {
    tableName: "classes",
    columnName: "classCode",
    tableShowName: "Classes",
    columnShowName: "Class Code",
    isVisible: true,
    isRequired: false,
    weight: 1,
  },
  {
    tableName: "classes",
    columnName: "type",
    tableShowName: "Classes",
    columnShowName: "Class Type",
    isVisible: true,
    isRequired: false,
    weight: 2,
  },
  {
    tableName: "classes",
    columnName: "shorthandWithSort",
    tableShowName: "Classes",
    columnShowName: "Class Shorthand",
    isVisible: true,
    isRequired: false,
    weight: 3,
  },
  {
    tableName: "classes",
    columnName: "course.name",
    tableShowName: "Classes",
    columnShowName: "Course",
    isVisible: true,
    isRequired: false,
    weight: 4,
  },
  {
    tableName: "classes",
    columnName: "course.nameShort",
    tableShowName: "Classes",
    columnShowName: "Course Shorthand",
    isVisible: true,
    isRequired: false,
    weight: 5,
  },
  {
    tableName: "classes",
    columnName: "learningArrangement",
    tableShowName: "Classes",
    columnShowName: "LA",
    isVisible: true,
    isRequired: false,
    weight: 6,
  },
  {
    tableName: "classes",
    columnName: "levelWithSort",
    tableShowName: "Classes",
    columnShowName: "Level",
    isVisible: true,
    isRequired: false,
    weight: 7,
  },
  {
    tableName: "classes",
    columnName: "academicYear.year",
    tableShowName: "Classes",
    columnShowName: "Year",
    isVisible: true,
    isRequired: false,
    weight: 8,
  },
  {
    tableName: "classes",
    columnName: "defaultLessonDays",
    tableShowName: "Classes",
    columnShowName: "Lesson Day",
    isVisible: true,
    isRequired: false,
    weight: 9,
  },
  {
    tableName: "classes",
    columnName: "teacher.fullName",
    tableShowName: "Classes",
    columnShowName: "Teacher",
    isVisible: true,
    isRequired: false,
    weight: 10,
  },
  {
    tableName: "classes",
    columnName: "venue.name",
    tableShowName: "Classes",
    columnShowName: "Venue",
    isVisible: true,
    isRequired: false,
    weight: 11,
  },
  {
    tableName: "classes",
    columnName: "digitalCredentials",
    tableShowName: "Classes",
    columnShowName: "Digital Credentials",
    isVisible: true,
    isRequired: false,
    weight: 12,
  },
  {
    tableName: "classes",
    columnName: "enrollmentCapacity",
    tableShowName: "Classes",
    columnShowName: "Enrollment Capacity",
    isVisible: true,
    isRequired: false,
    weight: 13,
  },
  {
    tableName: "classes",
    columnName: "createdBy",
    tableShowName: "Classes",
    columnShowName: "Created By",
    isVisible: true,
    isRequired: false,
    weight: 14,
  },
  {
    tableName: "classes",
    columnName: "lastModifiedBy",
    tableShowName: "Classes",
    columnShowName: "Last Modified By",
    isVisible: true,
    isRequired: false,
    weight: 16,
  },
  {
    tableName: "classes",
    columnName: "maximumClassCapacity",
    tableShowName: "Classes",
    columnShowName: "Class Capacity",
    isVisible: true,
    isRequired: false,
    weight: 17,
  },
  {
    tableName: "classes",
    columnName: "maximumMakeUpCapacity",
    tableShowName: "Classes",
    columnShowName: "RL Capacity",
    isVisible: true,
    isRequired: false,
    weight: 18,
  },
  {
    tableName: "classes",
    columnName: "regularStudents",
    tableShowName: "Classes",
    columnShowName: "Regular Student",
    isVisible: true,
    isRequired: false,
    weight: 19,
  },
  {
    tableName: "classes",
    columnName: "trialStudents",
    tableShowName: "Classes",
    columnShowName: "Trial Student",
    isVisible: true,
    isRequired: false,
    weight: 20,
  },
  {
    tableName: "classes",
    columnName: "reservedStudents",
    tableShowName: "Classes",
    columnShowName: "Reserved Student",
    isVisible: true,
    isRequired: false,
    weight: 21,
  },
  {
    tableName: "classes",
    columnName: "withdrawnStudents",
    tableShowName: "Classes",
    columnShowName: "Withdrawn Student",
    isVisible: true,
    isRequired: false,
    weight: 22,
  },
  {
    tableName: "classes",
    columnName: "transferredStudents",
    tableShowName: "Classes",
    columnShowName: "Transferred Student",
    isVisible: true,
    isRequired: false,
    weight: 23,
  },
  {
    tableName: "classes",
    columnName: "status",
    tableShowName: "Classes",
    columnShowName: "Class Status",
    isVisible: true,
    isRequired: false,
    weight: 24,
  },
  {
    tableName: "classes",
    columnName: "durationWithSort",
    tableShowName: "Classes",
    columnShowName: "Duration",
    isVisible: true,
    isRequired: false,
    weight: 25,
  },
  {
    tableName: "classes",
    columnName: "startTimeWithSort",
    tableShowName: "Classes",
    columnShowName: "Start Time",
    isVisible: true,
    isRequired: false,
    weight: 26,
  },
  {
    tableName: "classes",
    columnName: "endTimeWithSort",
    tableShowName: "Classes",
    columnShowName: "End Time",
    isVisible: true,
    isRequired: false,
    weight: 27,
  },
];

const lessons: DefaultUserCustomiseColumnSeed[] = [
  {
    tableName: "lessons",
    columnName: "course.name",
    tableShowName: "Lessons",
    columnShowName: "Course",
    isVisible: true,
    isRequired: false,
    weight: 0,
  },
  {
    tableName: "lessons",
    columnName: "class.name",
    tableShowName: "Lessons",
    columnShowName: "Class",
    isVisible: true,
    isRequired: false,
    weight: 1,
  },
  {
    tableName: "lessons",
    columnName: "course.nameShort",
    tableShowName: "Lessons",
    columnShowName: "Course Shorthand",
    isVisible: true,
    isRequired: false,
    weight: 2,
  },
  {
    tableName: "lessons",
    columnName: "class.shorthand",
    tableShowName: "Lessons",
    columnShowName: "Class Shorthand",
    isVisible: true,
    isRequired: false,
    weight: 3,
  },
  {
    tableName: "lessons",
    columnName: "lessonDateWithSort",
    tableShowName: "Lessons",
    columnShowName: "Lesson Date",
    isVisible: true,
    isRequired: false,
    weight: 4,
  },
  {
    tableName: "lessons",
    columnName: "teacher.fullName",
    tableShowName: "Lessons",
    columnShowName: "Teacher",
    isVisible: true,
    isRequired: false,
    weight: 5,
  },
  {
    tableName: "lessons",
    columnName: "venueWithSort",
    tableShowName: "Lessons",
    columnShowName: "Venue",
    isVisible: true,
    isRequired: false,
    weight: 6,
  },
  {
    tableName: "lessons",
    columnName: "totalStudents",
    tableShowName: "Lessons",
    columnShowName: "Total Attending",
    isVisible: true,
    isRequired: false,
    weight: 7,
  },
  {
    tableName: "lessons",
    columnName: "absentStudents",
    tableShowName: "Lessons",
    columnShowName: "Absent Students",
    isVisible: true,
    isRequired: false,
    weight: 8,
  },
  {
    tableName: "lessons",
    columnName: "noStatusStudents",
    tableShowName: "Lessons",
    columnShowName: "No Status",
    isVisible: true,
    isRequired: false,
    weight: 9,
  },
  {
    tableName: "lessons",
    columnName: "academicWeek.weekNumber",
    tableShowName: "Lessons",
    columnShowName: "Academic Week",
    isVisible: true,
    isRequired: false,
    weight: 10,
  },
  {
    tableName: "lessons",
    columnName: "lessonNumber",
    tableShowName: "Lessons",
    columnShowName: "Lessons No.",
    isVisible: true,
    isRequired: false,
    weight: 11,
  },
  {
    tableName: "lessons",
    columnName: "durationWithSort",
    tableShowName: "Lessons",
    columnShowName: "Duration",
    isVisible: true,
    isRequired: false,
    weight: 12,
  },
  {
    tableName: "lessons",
    columnName: "startTimeWithSort",
    tableShowName: "Lessons",
    columnShowName: "Start Time",
    isVisible: true,
    isRequired: false,
    weight: 13,
  },
  {
    tableName: "lessons",
    columnName: "endTimeWithSort",
    tableShowName: "Lessons",
    columnShowName: "End Time",
    isVisible: true,
    isRequired: false,
    weight: 14,
  },
  {
    tableName: "lessons",
    columnName: "createDateTime",
    tableShowName: "Lessons",
    columnShowName: "Date Created",
    isVisible: true,
    isRequired: false,
    weight: 15,
  },
  {
    tableName: "lessons",
    columnName: "lastModifiedDateTime",
    tableShowName: "Lessons",
    columnShowName: "Last Modified",
    isVisible: true,
    isRequired: false,
    weight: 16,
  },
  {
    tableName: "lessons",
    columnName: "lastModifyAdmin.fullName",
    tableShowName: "Lessons",
    columnShowName: "Last Modified By",
    isVisible: true,
    isRequired: false,
    weight: 17,
  },
];

const registrations: DefaultUserCustomiseColumnSeed[] = [
  {
    tableName: "registrations",
    columnName: "enrollment",
    tableShowName: "Registration List",
    columnShowName: "Enrollment No.",
    isVisible: true,
    isRequired: true,
    weight: 0,
  },
  {
    tableName: "registrations",
    columnName: "type",
    tableShowName: "Registration List",
    columnShowName: "Registration Type",
    isVisible: false,
    isRequired: false,
    weight: 1,
  },
  {
    tableName: "registrations",
    columnName: "studentName",
    tableShowName: "Registration List",
    columnShowName: "Student Name",
    isVisible: true,
    isRequired: true,
    weight: 2,
  },
  {
    tableName: "registrations",
    columnName: "studentID",
    tableShowName: "Registration List",
    columnShowName: "Student ID",
    isVisible: true,
    isRequired: false,
    weight: 3,
  },
  {
    tableName: "registrations",
    columnName: "parentID",
    tableShowName: "Registration List",
    columnShowName: "Parent ID",
    isVisible: false,
    isRequired: false,
    weight: 4,
  },
  {
    tableName: "registrations",
    columnName: "parentName",
    tableShowName: "Registration List",
    columnShowName: "Parent Name",
    isVisible: false,
    isRequired: false,
    weight: 5,
  },
  {
    tableName: "registrations",
    columnName: "parentMobile",
    tableShowName: "Registration List",
    columnShowName: "Parent Mobile",
    isVisible: false,
    isRequired: false,
    weight: 6,
  },
  {
    tableName: "registrations",
    columnName: "parentEmail",
    tableShowName: "Registration List",
    columnShowName: "Parent Email",
    isVisible: false,
    isRequired: false,
    weight: 7,
  },
  {
    tableName: "registrations",
    columnName: "classFullCourseName",
    tableShowName: "Registration List",
    columnShowName: "Course & Class",
    isVisible: true,
    isRequired: false,
    weight: 8,
  },
  {
    tableName: "registrations",
    columnName: "enrollmentStartDate",
    tableShowName: "Registration List",
    columnShowName: "Enrollment Date",
    isVisible: true,
    isRequired: false,
    weight: 9,
  },
  {
    tableName: "registrations",
    columnName: "lastModifiedBy",
    tableShowName: "Registration List",
    columnShowName: "Last Modified By",
    isVisible: true,
    isRequired: false,
    weight: 10,
  },
  {
    tableName: "registrations",
    columnName: "whatsAppConfirm",
    tableShowName: "Registration List",
    columnShowName: "WA Confirmation",
    isVisible: true,
    isRequired: false,
    weight: 11,
  },
  {
    tableName: "registrations",
    columnName: "emailConfirm",
    tableShowName: "Registration List",
    columnShowName: "Email Confirmation",
    isVisible: true,
    isRequired: false,
    weight: 12,
  },
  {
    tableName: "registrations",
    columnName: "startDate",
    tableShowName: "Registration List",
    columnShowName: "Start On",
    isVisible: false,
    isRequired: false,
    weight: 13,
  },
  {
    tableName: "registrations",
    columnName: "dueDate",
    tableShowName: "Registration List",
    columnShowName: "Due On",
    isVisible: false,
    isRequired: false,
    weight: 14,
  },
  {
    tableName: "registrations",
    columnName: "learningArrangement",
    tableShowName: "Registration List",
    columnShowName: "Learning Arrangement",
    isVisible: false,
    isRequired: false,
    weight: 15,
  },
  {
    tableName: "registrations",
    columnName: "enrollment.followUpStatus",
    tableShowName: "Registration List",
    columnShowName: "Reservation F/U Status",
    isVisible: true,
    isRequired: false,
    weight: 16,
  },
  {
    tableName: "registrations",
    columnName: "status",
    tableShowName: "Registration List",
    columnShowName: "Status",
    isVisible: true,
    isRequired: false,
    weight: 17,
  },
  {
    tableName: "registrations",
    columnName: "class.capacity.seatLeft",
    tableShowName: "Registration List",
    columnShowName: "Seats Left",
    isVisible: true,
    isRequired: false,
    weight: 18,
  },
  {
    tableName: "registrations",
    columnName: "class.capacity.enrolled",
    tableShowName: "Registration List",
    columnShowName: "Enrolled",
    isVisible: true,
    isRequired: false,
    weight: 19,
  },
  {
    tableName: "registrations",
    columnName: "invoice.status",
    tableShowName: "Registration List",
    columnShowName: "Payment Status",
    isVisible: true,
    isRequired: false,
    weight: 20,
  },
];

const arrangementMakeups: DefaultUserCustomiseColumnSeed[] = [
  {
    tableName: "arrangement-makeups",
    columnName: "lesson.LessonNumber",
    tableShowName: "Replacement Lesson List",
    columnShowName: "Replacement Lesson No.",
    isVisible: true,
    isRequired: true,
    weight: 0,
  },
  {
    tableName: "arrangement-makeups",
    columnName: "student.fullName",
    tableShowName: "Replacement Lesson List",
    columnShowName: "Student",
    isVisible: true,
    isRequired: true,
    weight: 1,
  },

  {
    tableName: "arrangement-makeups",
    columnName: "student.userId",
    tableShowName: "Replacement Lesson List",
    columnShowName: "Student ID",
    isVisible: true,
    isRequired: false,
    weight: 2,
  },
  {
    tableName: "arrangement-makeups",
    columnName: "course.type",
    tableShowName: "Replacement Lesson List",
    columnShowName: "Course Type",
    isVisible: false,
    isRequired: false,
    weight: 3,
  },
  {
    tableName: "arrangement-makeups",
    columnName: "fromClass.name",
    tableShowName: "Replacement Lesson List",
    columnShowName: "Class to Skip",
    isVisible: true,
    isRequired: true,
    weight: 4,
  },
  {
    tableName: "arrangement-makeups",
    columnName: "fromLesson.lessonStart",
    tableShowName: "Replacement Lesson List",
    columnShowName: "Date to Skip",
    isVisible: true,
    isRequired: true,
    weight: 5,
  },
  {
    tableName: "arrangement-makeups",
    columnName: "fromClassTeacher.fullName",
    tableShowName: "Replacement Lesson List",
    columnShowName: "Skipped Lesson Teacher",
    isVisible: false,
    isRequired: false,
    weight: 6,
  },
  {
    tableName: "arrangement-makeups",
    columnName: "toClass.name",
    tableShowName: "Replacement Lesson List",
    columnShowName: "Replacement Class",
    isVisible: true,
    isRequired: true,
    weight: 7,
  },
  {
    tableName: "arrangement-makeups",
    columnName: "toLesson.lessonStart",
    tableShowName: "Replacement Lesson List",
    columnShowName: "Replacement Date",
    isVisible: true,
    isRequired: true,
    weight: 8,
  },
  {
    tableName: "arrangement-makeups",
    columnName: "toClassTeacher.fullName",
    tableShowName: "Replacement Lesson List",
    columnShowName: "Replacement Lesson Teacher",
    isVisible: false,
    isRequired: false,
    weight: 9,
  },
  {
    tableName: "arrangement-makeups",
    columnName: "remainReplacementLessonCredit",
    tableShowName: "Replacement Lesson List",
    columnShowName: "Remaining Replacement Lesson Credit",
    isVisible: false,
    isRequired: false,
    weight: 10,
  },
  {
    tableName: "arrangement-makeups",
    columnName: "lastModifiedBy",
    tableShowName: "Replacement Lesson List",
    columnShowName: "Last Modified By",
    isVisible: false,
    isRequired: false,
    weight: 11,
  },
  {
    tableName: "arrangement-makeups",
    columnName: "lastModifiedDateTime",
    tableShowName: "Replacement Lesson List",
    columnShowName: "Processed Date",
    isVisible: false,
    isRequired: false,
    weight: 12,
  },
  {
    tableName: "arrangement-makeups",
    columnName: "waStatus",
    tableShowName: "Replacement Lesson List",
    columnShowName: "WA Confirmation",
    isVisible: true,
    isRequired: false,
    weight: 13,
  },
  {
    tableName: "arrangement-makeups",
    columnName: "emailStatus",
    tableShowName: "Replacement Lesson List",
    columnShowName: "Email Confirmation",
    isVisible: true,
    isRequired: false,
    weight: 14,
  },
  {
    tableName: "arrangement-makeups",
    columnName: "status",
    tableShowName: "Replacement Lesson List",
    columnShowName: "Status",
    isVisible: true,
    isRequired: false,
    weight: 15,
  },
];

const arrangementAdditionals: DefaultUserCustomiseColumnSeed[] = [
  {
    tableName: "arrangement-additionals",
    columnName: "lessonLessonNumber",
    tableShowName: "Additional Lesson List",
    columnShowName: "Additional Lesson No.",
    isVisible: true,
    isRequired: true,
    weight: 0,
  },
  {
    tableName: "arrangement-additionals",
    columnName: "student",
    tableShowName: "Additional Lesson List",
    columnShowName: "Student",
    isVisible: true,
    isRequired: true,
    weight: 1,
  },

  {
    tableName: "arrangement-additionals",
    columnName: "fromClass.name",
    tableShowName: "Additional Lesson List",
    columnShowName: "Affected Class",
    isVisible: true,
    isRequired: false,
    weight: 2,
  },
  {
    tableName: "arrangement-additionals",
    columnName: "fromLesson.lessonStart",
    tableShowName: "Additional Lesson List",
    columnShowName: "Affected Date",
    isVisible: false,
    isRequired: false,
    weight: 3,
  },
  {
    tableName: "arrangement-additionals",
    columnName: "toClass.name",
    tableShowName: "Additional Lesson List",
    columnShowName: "Additional Lesson",
    isVisible: true,
    isRequired: true,
    weight: 4,
  },
  {
    tableName: "arrangement-additionals",
    columnName: "toLesson.lessonStart",
    tableShowName: "Additional Lesson List",
    columnShowName: "Additional Lesson Date",
    isVisible: true,
    isRequired: true,
    weight: 5,
  },
  {
    tableName: "arrangement-additionals",
    columnName: "whatAppConfirm",
    tableShowName: "Additional Lesson List",
    columnShowName: "WA Confirmation",
    isVisible: true,
    isRequired: false,
    weight: 6,
  },
  {
    tableName: "arrangement-additionals",
    columnName: "emailConfirm",
    tableShowName: "Additional Lesson List",
    columnShowName: "Email Confirmation",
    isVisible: true,
    isRequired: false,
    weight: 7,
  },
  {
    tableName: "arrangement-additionals",
    columnName: "status",
    tableShowName: "Additional Lesson List",
    columnShowName: "Status",
    isVisible: true,
    isRequired: false,
    weight: 8,
  },
  {
    tableName: "arrangement-additionals",
    columnName: "paymentStatus",
    tableShowName: "Additional Lesson List",
    columnShowName: "Payment Status",
    isVisible: true,
    isRequired: false,
    weight: 9,
  },
  {
    tableName: "arrangement-additionals",
    columnName: "lastModifiedBy",
    tableShowName: "Additional Lesson List",
    columnShowName: "Last Modified By",
    isVisible: false,
    isRequired: false,
    weight: 10,
  },
];

const arrangementTransfers: DefaultUserCustomiseColumnSeed[] = [
  {
    tableName: "arrangement-transfers",
    columnName: "transferNumber",
    tableShowName: "Transfer List",
    columnShowName: "Transfer No.",
    isVisible: true,
    isRequired: true,
    weight: 0,
  },
  {
    tableName: "arrangement-transfers",
    columnName: "student.name",
    tableShowName: "Transfer List",
    columnShowName: "Student",
    isVisible: true,
    isRequired: true,
    weight: 1,
  },
  {
    tableName: "arrangement-transfers",
    columnName: "studen.id",
    tableShowName: "Transfer List",
    columnShowName: "Student ID",
    isVisible: true,
    isRequired: false,
    weight: 2,
  },
  {
    tableName: "arrangement-transfers",
    columnName: "transferFrom",
    tableShowName: "Transfer List",
    columnShowName: "Transfer From",
    isVisible: true,
    isRequired: true,
    weight: 3,
  },
  {
    tableName: "arrangement-transfers",
    columnName: "lastLessonOn",
    tableShowName: "Transfer List",
    columnShowName: "Last Lesson On",
    isVisible: true,
    isRequired: true,
    weight: 4,
  },
  {
    tableName: "arrangement-transfers",
    columnName: "tranferTo",
    tableShowName: "Transfer List",
    columnShowName: "Transfer To",
    isVisible: true,
    isRequired: true,
    weight: 5,
  },
  {
    tableName: "arrangement-transfers",
    columnName: "startLesson",
    tableShowName: "Transfer List",
    columnShowName: "Start On",
    isVisible: true,
    isRequired: true,
    weight: 6,
  },
  {
    tableName: "arrangement-transfers",
    columnName: "lastModifiedBy",
    tableShowName: "Transfer List",
    columnShowName: "Last Modified By",
    isVisible: false,
    isRequired: false,
    weight: 7,
  },
  {
    tableName: "arrangement-transfers",
    columnName: "whatsAppConfirm",
    tableShowName: "Transfer List",
    columnShowName: "WA Confirmation",
    isVisible: true,
    isRequired: false,
    weight: 8,
  },
  {
    tableName: "arrangement-transfers",
    columnName: "emailConfirm",
    tableShowName: "Transfer List",
    columnShowName: "Email Confirmation",
    isVisible: true,
    isRequired: false,
    weight: 9,
  },
  {
    tableName: "arrangement-transfers",
    columnName: "status",
    tableShowName: "Transfer List",
    columnShowName: "Status",
    isVisible: true,
    isRequired: false,
    weight: 10,
  },
];

const arrangementWithdraws: DefaultUserCustomiseColumnSeed[] = [
  {
    tableName: "arrangement-withdraws",
    columnName: "withDrawNumber",
    tableShowName: "Withdrawals List",
    columnShowName: "Withdraw No.",
    isVisible: true,
    isRequired: true,
    weight: 1,
  },
  {
    tableName: "arrangement-withdraws",
    columnName: "student",
    tableShowName: "Withdrawals List",
    columnShowName: "Student",
    isVisible: true,
    isRequired: true,
    weight: 2,
  },
  {
    tableName: "arrangement-withdraws",
    columnName: "student.ID",
    tableShowName: "Withdrawals List",
    columnShowName: "Student ID",
    isVisible: true,
    isRequired: false,
    weight: 3,
  },
  {
    tableName: "arrangement-withdraws",
    columnName: "class.fullCourseName",
    tableShowName: "Withdrawals List",
    columnShowName: "Course & Class",
    isVisible: true,
    isRequired: true,
    weight: 4,
  },
  {
    tableName: "arrangement-withdraws",
    columnName: "teacher",
    tableShowName: "Withdrawals List",
    columnShowName: "Teacher",
    isVisible: false,
    isRequired: false,
    weight: 5,
  },
  {
    tableName: "arrangement-withdraws",
    columnName: "dateWithDrawn",
    tableShowName: "Withdrawals List",
    columnShowName: "Date Withdrawn",
    isVisible: true,
    isRequired: true,
    weight: 6,
  },
  {
    tableName: "arrangement-withdraws",
    columnName: "lastModifiedBy",
    tableShowName: "Withdrawals List",
    columnShowName: "Processed By",
    isVisible: true,
    isRequired: false,
    weight: 7,
  },
  {
    tableName: "arrangement-withdraws",
    columnName: "whatsAppConfirm",
    tableShowName: "Withdrawals List",
    columnShowName: "WA Confirmation",
    isVisible: true,
    isRequired: false,
    weight: 8,
  },
  {
    tableName: "arrangement-withdraws",
    columnName: "emailConfirm",
    tableShowName: "Withdrawals List",
    columnShowName: "Email Confirmation",
    isVisible: true,
    isRequired: false,
    weight: 9,
  },
  {
    tableName: "arrangement-withdraws",
    columnName: "status",
    tableShowName: "Withdrawals List",
    columnShowName: "Status",
    isVisible: true,
    isRequired: false,
    weight: 10,
  },
  {
    tableName: "arrangement-withdraws",
    columnName: "lastModifiedBy",
    tableShowName: "Withdrawals List",
    columnShowName: "Last Modified By",
    isVisible: true,
    isRequired: false,
    weight: 11,
  },
];

const invoices: DefaultUserCustomiseColumnSeed[] = [
  {
    tableName: "invoices",
    columnName: "referenceNumber",
    tableShowName: "Invoice List",
    columnShowName: "Invoice No.",
    isVisible: true,
    isRequired: true,
    weight: 0,
  },
  {
    tableName: "invoices",
    columnName: "student.userId",
    tableShowName: "Invoice List",
    columnShowName: "Student ID",
    isVisible: false,
    isRequired: false,
    weight: 1,
  },

  {
    tableName: "invoices",
    columnName: "student.fullName",
    tableShowName: "Invoice List",
    columnShowName: "Student Name",
    isVisible: false,
    isRequired: false,
    weight: 2,
  },
  {
    tableName: "invoices",
    columnName: "primaryParent.userId",
    tableShowName: "Invoice List",
    columnShowName: "Primary Parent ID",
    isVisible: false,
    isRequired: false,
    weight: 3,
  },
  {
    tableName: "invoices",
    columnName: "primaryParent.fullName",
    tableShowName: "Invoice List",
    columnShowName: "Primary Parent Name",
    isVisible: true,
    isRequired: false,
    weight: 4,
  },
  {
    tableName: "invoices",
    columnName: "primaryParent.mobile",
    tableShowName: "Invoice List",
    columnShowName: "Primary Parent Mobile",
    isVisible: true,
    isRequired: false,
    weight: 5,
  },
  {
    tableName: "invoices",
    columnName: "primaryParent.email",
    tableShowName: "Invoice List",
    columnShowName: "Primary Parent Email Address",
    isVisible: false,
    isRequired: false,
    weight: 6,
  },
  {
    tableName: "invoices",
    columnName: "secondaryParent.userId",
    tableShowName: "Invoice List",
    columnShowName: "Secondary Parent ID",
    isVisible: false,
    isRequired: false,
    weight: 7,
  },
  {
    tableName: "invoices",
    columnName: "secondaryParent.name",
    tableShowName: "Invoice List",
    columnShowName: "Secondary Parent Name",
    isVisible: true,
    isRequired: false,
    weight: 8,
  },
  {
    tableName: "invoices",
    columnName: "secondaryParent.mobile",
    tableShowName: "Invoice List",
    columnShowName: "Secondary Parent Mobile",
    isVisible: true,
    isRequired: false,
    weight: 9,
  },
  {
    tableName: "invoices",
    columnName: "secondaryParent.email",
    tableShowName: "Invoice List",
    columnShowName: "Secondary Parent Email Address",
    isVisible: false,
    isRequired: false,
    weight: 10,
  },
  {
    tableName: "invoices",
    columnName: "student.fullName",
    tableShowName: "Invoice List",
    columnShowName: "Billed For",
    isVisible: true,
    isRequired: true,
    weight: 11,
  },
  {
    tableName: "invoices",
    columnName: "billedTo",
    tableShowName: "Invoice List",
    columnShowName: "Billed To",
    isVisible: false,
    isRequired: false,
    weight: 12,
  },
  {
    tableName: "invoices",
    columnName: "class.name",
    tableShowName: "Invoice List",
    columnShowName: "Course & Class",
    isVisible: true,
    isRequired: false,
    weight: 13,
  },
  {
    tableName: "invoices",
    columnName: "amountDue",
    tableShowName: "Invoice List",
    columnShowName: "Amount Due",
    isVisible: true,
    isRequired: true,
    weight: 14,
  },
  {
    tableName: "invoices",
    columnName: "paid",
    tableShowName: "Invoice List",
    columnShowName: "Paid",
    isVisible: true,
    isRequired: false,
    weight: 15,
  },
  {
    tableName: "invoices",
    columnName: "dueDate",
    tableShowName: "Invoice List",
    columnShowName: "Due On",
    isVisible: true,
    isRequired: false,
    weight: 16,
  },
  {
    tableName: "invoices",
    columnName: "waStatus",
    tableShowName: "Invoice List",
    columnShowName: "WA",
    isVisible: true,
    isRequired: false,
    weight: 18,
  },
  {
    tableName: "invoices",
    columnName: "emailStatus",
    tableShowName: "Invoice List",
    columnShowName: "Email",
    isVisible: true,
    isRequired: false,
    weight: 19,
  },
];

const receipts: DefaultUserCustomiseColumnSeed[] = [
  {
    tableName: "receipts",
    columnName: "receiptNumber",
    tableShowName: "Receipt List",
    columnShowName: "Receipt No.",
    isVisible: true,
    isRequired: true,
    weight: 0,
  },
  {
    tableName: "receipts",
    columnName: "student.ID",
    tableShowName: "Receipt List",
    columnShowName: "Student ID",
    isVisible: false,
    isRequired: false,
    weight: 0,
  },
  {
    tableName: "receipts",
    columnName: "student.name",
    tableShowName: "Receipt List",
    columnShowName: "Student Name",
    isVisible: false,
    isRequired: false,
    weight: 1,
  },
  {
    tableName: "receipts",
    columnName: "primaryParent.ID",
    tableShowName: "Receipt List",
    columnShowName: "Primary Parent ID",
    isVisible: false,
    isRequired: false,
    weight: 2,
  },
  {
    tableName: "receipts",
    columnName: "primaryParent.name",
    tableShowName: "Receipt List",
    columnShowName: "Primary Parent Name",
    isVisible: true,
    isRequired: false,
    weight: 3,
  },
  {
    tableName: "receipts",
    columnName: "primaryParent.mobile",
    tableShowName: "Receipt List",
    columnShowName: "Primary Parent Mobile",
    isVisible: true,
    isRequired: false,
    weight: 4,
  },
  {
    tableName: "receipts",
    columnName: "primaryParent.email",
    tableShowName: "Receipt List",
    columnShowName: "Primary Parent Email Address",
    isVisible: false,
    isRequired: false,
    weight: 5,
  },
  {
    tableName: "receipts",
    columnName: "secondaryParent.ID",
    tableShowName: "Receipt List",
    columnShowName: "Secondary Parent ID",
    isVisible: false,
    isRequired: false,
    weight: 6,
  },
  {
    tableName: "receipts",
    columnName: "secondaryParent.name",
    tableShowName: "Receipt List",
    columnShowName: "Secondary Parent Name",
    isVisible: true,
    isRequired: false,
    weight: 7,
  },
  {
    tableName: "receipts",
    columnName: "secondaryParent.mobile",
    tableShowName: "Receipt List",
    columnShowName: "Secondary Parent Mobile",
    isVisible: true,
    isRequired: false,
    weight: 8,
  },
  {
    tableName: "receipts",
    columnName: "secondaryParent.email",
    tableShowName: "Receipt List",
    columnShowName: "Secondary Parent Email Address",
    isVisible: false,
    isRequired: false,
    weight: 9,
  },
  {
    tableName: "receipts",
    columnName: "billedFor",
    tableShowName: "Receipt List",
    columnShowName: "Billed For",
    isVisible: false,
    isRequired: false,
    weight: 10,
  },
  {
    tableName: "receipts",
    columnName: "billedTo",
    tableShowName: "Receipt List",
    columnShowName: "Billed To",
    isVisible: false,
    isRequired: false,
    weight: 11,
  },
  {
    tableName: "receipts",
    columnName: "paymentDate",
    tableShowName: "Receipt List",
    columnShowName: "Payment Date",
    isVisible: true,
    isRequired: false,
    weight: 12,
  },
  {
    tableName: "receipts",
    columnName: "class.fullCourseName",
    tableShowName: "Receipt List",
    columnShowName: "Course & Class",
    isVisible: true,
    isRequired: true,
    weight: 13,
  },
  {
    tableName: "receipts",
    columnName: "amountDue",
    tableShowName: "Receipt List",
    columnShowName: "Amount Due",
    isVisible: true,
    isRequired: false,
    weight: 14,
  },
  {
    tableName: "receipts",
    columnName: "paid",
    tableShowName: "Receipt List",
    columnShowName: "Paid",
    isVisible: true,
    isRequired: true,
    weight: 15,
  },
  {
    tableName: "receipts",
    columnName: "referenceNumber",
    tableShowName: "Receipt List",
    columnShowName: "Reference",
    isVisible: true,
    isRequired: true,
    weight: 16,
  },
  {
    tableName: "receipts",
    columnName: "paymentRecord",
    tableShowName: "Receipt List",
    columnShowName: "Payment Remarks",
    isVisible: true,
    isRequired: false,
    weight: 17,
  },
  {
    tableName: "receipts",
    columnName: "status",
    tableShowName: "Receipt List",
    columnShowName: "Status",
    isVisible: true,
    isRequired: false,
    weight: 18,
  },
  {
    tableName: "receipts",
    columnName: "waStatus",
    tableShowName: "Receipt List",
    columnShowName: "WA",
    isVisible: true,
    isRequired: false,
    weight: 19,
  },
  {
    tableName: "receipts",
    columnName: "emailStatus",
    tableShowName: "Receipt List",
    columnShowName: "Email",
    isVisible: true,
    isRequired: false,
    weight: 20,
  },
  {
    tableName: "receipts",
    columnName: "outstandingAmount",
    tableShowName: "Receipt List",
    columnShowName: "Outstanding Amount",
    isVisible: true,
    isRequired: false,
    weight: 21,
  },
];

const refunds: DefaultUserCustomiseColumnSeed[] = [
  {
    tableName: "refunds",
    columnName: "referenceNumber",
    tableShowName: "Refund List",
    columnShowName: "Refund No.",
    isVisible: true,
    isRequired: true,
    weight: 1,
  },
  {
    tableName: "refunds",
    columnName: "student.ID",
    tableShowName: "Refund List",
    columnShowName: "Student ID",
    isVisible: false,
    isRequired: false,
    weight: 2,
  },
  {
    tableName: "refunds",
    columnName: "student.Name",
    tableShowName: "Refund List",
    columnShowName: "Student Name",
    isVisible: false,
    isRequired: false,
    weight: 3,
  },
  {
    tableName: "refunds",
    columnName: "primaryParent.ID",
    tableShowName: "Refund List",
    columnShowName: "Primary Parent ID",
    isVisible: false,
    isRequired: false,
    weight: 4,
  },
  {
    tableName: "refunds",
    columnName: "parent.fullName",
    tableShowName: "Refund List",
    columnShowName: "Refund To",
    isVisible: true,
    isRequired: false,
    weight: 5,
  },
  {
    tableName: "refunds",
    columnName: "primaryParent.mobile",
    tableShowName: "Refund List",
    columnShowName: "Primary Parent Mobile",
    isVisible: false,
    isRequired: false,
    weight: 6,
  },
  {
    tableName: "refunds",
    columnName: "primaryParent.email",
    tableShowName: "Refund List",
    columnShowName: "Primary Parent Email Address",
    isVisible: false,
    isRequired: false,
    weight: 7,
  },
  {
    tableName: "refunds",
    columnName: "secondaryParent.ID",
    tableShowName: "Refund List",
    columnShowName: "Secondary Parent ID",
    isVisible: false,
    isRequired: false,
    weight: 8,
  },
  {
    tableName: "refunds",
    columnName: "secondaryParent",
    tableShowName: "Refund List",
    columnShowName: "Secondary Parent",
    isVisible: false,
    isRequired: false,
    weight: 9,
  },
  {
    tableName: "refunds",
    columnName: "secondaryParent.mobile",
    tableShowName: "Refund List",
    columnShowName: "Secondary Parent Mobile",
    isVisible: false,
    isRequired: false,
    weight: 10,
  },
  {
    tableName: "refunds",
    columnName: "secondaryParent.email",
    tableShowName: "Refund List",
    columnShowName: "Secondary Parent Email Address",
    isVisible: false,
    isRequired: false,
    weight: 11,
  },
  {
    tableName: "refunds",
    columnName: "refundable",
    tableShowName: "Refund List",
    columnShowName: "Amount Refundable",
    isVisible: true,
    isRequired: false,
    weight: 12,
  },
  {
    tableName: "refunds",
    columnName: "adminFee",
    tableShowName: "Refund List",
    columnShowName: "Admin Fee",
    isVisible: true,
    isRequired: false,
    weight: 13,
  },
  {
    tableName: "refunds",
    columnName: "amount",
    tableShowName: "Refund List",
    columnShowName: "Amount Refunded",
    isVisible: true,
    isRequired: false,
    weight: 14,
  },
  {
    tableName: "refunds",
    columnName: "refundedDate",
    tableShowName: "Refund List",
    columnShowName: "Refund Date",
    isVisible: true,
    isRequired: false,
    weight: 15,
  },
  {
    tableName: "refunds",
    columnName: "paymentMethod",
    tableShowName: "Refund List",
    columnShowName: "Refund Mode",
    isVisible: true,
    isRequired: false,
    weight: 16,
  },
  {
    tableName: "refunds",
    columnName: "refundedOn",
    tableShowName: "Refund List",
    columnShowName: "Refunded On",
    isVisible: true,
    isRequired: false,
    weight: 17,
  },
  {
    tableName: "refunds",
    columnName: "refundedTo",
    tableShowName: "Refund List",
    columnShowName: "Refund By",
    isVisible: true,
    isRequired: false,
    weight: 18,
  },
  {
    tableName: "refunds",
    columnName: "balance",
    tableShowName: "Refund List",
    columnShowName: "Credit Balance",
    isVisible: true,
    isRequired: false,
    weight: 19,
  },
  {
    tableName: "refunds",
    columnName: "processedDate",
    tableShowName: "Refund List",
    columnShowName: "Processed Date",
    isVisible: true,
    isRequired: false,
    weight: 20,
  },
  {
    tableName: "refunds",
    columnName: "lastModifiedBy",
    tableShowName: "Refund List",
    columnShowName: "Last Modified By",
    isVisible: true,
    isRequired: false,
    weight: 20,
  },
  {
    tableName: "refunds",
    columnName: "reference",
    tableShowName: "Refund List",
    columnShowName: "Reference",
    isVisible: true,
    isRequired: false,
    weight: 21,
  },
  {
    tableName: "refunds",
    columnName: "reason",
    tableShowName: "Refund List",
    columnShowName: "Remarks",
    isVisible: true,
    isRequired: false,
    weight: 22,
  },
  {
    tableName: "refunds",
    columnName: "waStatus",
    tableShowName: "Refund List",
    columnShowName: "WA",
    isVisible: true,
    isRequired: false,
    weight: 23,
  },
  {
    tableName: "refunds",
    columnName: "emailStatus",
    tableShowName: "Refund List",
    columnShowName: "Email",
    isVisible: true,
    isRequired: false,
    weight: 24,
  },
];

const statementOfAccounts: DefaultUserCustomiseColumnSeed[] = [
  {
    tableName: "statementOfAccounts",
    columnName: "student.ID",
    tableShowName: "Statement of Accounts",
    columnShowName: "Student ID",
    isVisible: false,
    isRequired: false,
    weight: 1,
  },
  {
    tableName: "statementOfAccounts",
    columnName: "student.name",
    tableShowName: "Statement of Accounts",
    columnShowName: "Student Name",
    isVisible: false,
    isRequired: false,
    weight: 2,
  },
  {
    tableName: "statementOfAccounts",
    columnName: "primaryParent",
    tableShowName: "Statement of Accounts",
    columnShowName: "Primary Parent",
    isVisible: true,
    isRequired: false,
    weight: 3,
  },
  {
    tableName: "statementOfAccounts",
    columnName: "primaryParent.mobile",
    tableShowName: "Statement of Accounts",
    columnShowName: "Primary Parent Mobile",
    isVisible: true,
    isRequired: false,
    weight: 4,
  },
  {
    tableName: "statementOfAccounts",
    columnName: "primaryParent.email",
    tableShowName: "Statement of Accounts",
    columnShowName: "Primary Parent Email Address",
    isVisible: true,
    isRequired: false,
    weight: 5,
  },
  {
    tableName: "statementOfAccounts",
    columnName: "primaryParent.ID",
    tableShowName: "Statement of Accounts",
    columnShowName: "Primary Parent ID",
    isVisible: true,
    isRequired: false,
    weight: 6,
  },
  {
    tableName: "statementOfAccounts",
    columnName: "secondaryParent.ID",
    tableShowName: "Statement of Accounts",
    columnShowName: "Secondary Parent ID",
    isVisible: false,
    isRequired: false,
    weight: 7,
  },
  {
    tableName: "statementOfAccounts",
    columnName: "secondaryParent",
    tableShowName: "Statement of Accounts",
    columnShowName: "Secondary Parent",
    isVisible: false,
    isRequired: false,
    weight: 8,
  },
  {
    tableName: "statementOfAccounts",
    columnName: "secondaryParent.mobile",
    tableShowName: "Statement of Accounts",
    columnShowName: "Secondary Parent Mobile",
    isVisible: false,
    isRequired: false,
    weight: 9,
  },
  {
    tableName: "statementOfAccounts",
    columnName: "secondaryParent.email",
    tableShowName: "Statement of Accounts",
    columnShowName: "Secondary Parent Email Address",
    isVisible: false,
    isRequired: false,
    weight: 10,
  },
  {
    tableName: "statementOfAccounts",
    columnName: "unpaid",
    tableShowName: "Statement of Accounts",
    columnShowName: "Unpaid",
    isVisible: true,
    isRequired: true,
    weight: 11,
  },
  {
    tableName: "statementOfAccounts",
    columnName: "overdue",
    tableShowName: "Statement of Accounts",
    columnShowName: "Overdue",
    isVisible: true,
    isRequired: true,
    weight: 12,
  },
  {
    tableName: "statementOfAccounts",
    columnName: "creditBalance",
    tableShowName: "Statement of Accounts",
    columnShowName: "Credit Bal.",
    isVisible: true,
    isRequired: true,
    weight: 13,
  },
  {
    tableName: "statementOfAccounts",
    columnName: "creditUsed",
    tableShowName: "Statement of Accounts",
    columnShowName: "Credit Used",
    isVisible: true,
    isRequired: true,
    weight: 14,
  },
  {
    tableName: "statementOfAccounts",
    columnName: "creditRefund",
    tableShowName: "Statement of Accounts",
    columnShowName: "Credit Refund",
    isVisible: true,
    isRequired: true,
    weight: 15,
  },
  {
    tableName: "statementOfAccounts",
    columnName: "pending",
    tableShowName: "Statement of Accounts",
    columnShowName: "Pending",
    isVisible: true,
    isRequired: true,
    weight: 16,
  },
  {
    tableName: "statementOfAccounts",
    columnName: "onHold",
    tableShowName: "Statement of Accounts",
    columnShowName: "On Hold",
    isVisible: true,
    isRequired: false,
    weight: 17,
  },
];

const parent: DefaultUserCustomiseColumnSeed[] = [
  {
    tableName: "parent",
    columnName: "parent",
    tableShowName: "Parent/Guardians List",
    columnShowName: "Parent/Guardians",
    isVisible: true,
    isRequired: true,
    weight: 0,
  },
  {
    tableName: "parent",
    columnName: "gender",
    tableShowName: "Parent/Guardians List",
    columnShowName: "Gender",
    isVisible: true,
    isRequired: false,
    weight: 1,
  },
  {
    tableName: "parent",
    columnName: "alas",
    tableShowName: "Parent/Guardians List",
    columnShowName: "Preferred Name",
    isVisible: true,
    isRequired: false,
    weight: 2,
  },
  {
    tableName: "parent",
    columnName: "mobile",
    tableShowName: "Parent/Guardians List",
    columnShowName: "Mobile No.",
    isVisible: true,
    isRequired: false,
    weight: 3,
  },
  {
    tableName: "parent",
    columnName: "homePhone",
    tableShowName: "Parent/Guardians List",
    columnShowName: "Home No.",
    isVisible: false,
    isRequired: false,
    weight: 4,
  },
  {
    tableName: "parent",
    columnName: "officePhone",
    tableShowName: "Parent/Guardians List",
    columnShowName: "Office No.",
    isVisible: false,
    isRequired: false,
    weight: 5,
  },
  {
    tableName: "parent",
    columnName: "email",
    tableShowName: "Parent/Guardians List",
    columnShowName: "Email",
    isVisible: true,
    isRequired: false,
    weight: 6,
  },
  {
    tableName: "parent",
    columnName: "alternativeEmails",
    tableShowName: "Parent/Guardians List",
    columnShowName: "Alternate Email",
    isVisible: false,
    isRequired: false,
    weight: 7,
  },
  {
    tableName: "parent",
    columnName: "building",
    tableShowName: "Parent/Guardians List",
    columnShowName: "Building/Estate",
    isVisible: false,
    isRequired: false,
    weight: 8,
  },

  {
    tableName: "parent",
    columnName: "buildingUnit",
    tableShowName: "Parent/Guardians List",
    columnShowName: "Unit No.",
    isVisible: false,
    isRequired: false,
    weight: 9,
  },
  {
    tableName: "parent",
    columnName: "postal",
    tableShowName: "Parent/Guardians List",
    columnShowName: "Postal Code",
    isVisible: false,
    isRequired: false,
    weight: 10,
  },
  {
    tableName: "parent",
    columnName: "country",
    tableShowName: "Parent/Guardians List",
    columnShowName: "Country",
    isVisible: false,
    isRequired: false,
    weight: 11,
  },
  {
    tableName: "parent",
    columnName: "relationshipManager",
    tableShowName: "Parent/Guardians List",
    columnShowName: "Relationship Manager",
    isVisible: true,
    isRequired: false,
    weight: 12,
  },
  {
    tableName: "parent",
    columnName: "whatsAppName",
    tableShowName: "Parent/Guardians List",
    columnShowName: "WhatsApp Name",
    isVisible: false,
    isRequired: false,
    weight: 13,
  },
  {
    tableName: "parent",
    columnName: "alternateUserId",
    tableShowName: "Parent/Guardians List",
    columnShowName: "Alternate ID",
    isVisible: false,
    isRequired: false,
    weight: 14,
  },
  {
    tableName: "parent",
    columnName: "relatedStudents",
    tableShowName: "Parent/Guardians List",
    columnShowName: "Related Student/s",
    isVisible: true,
    isRequired: true,
    weight: 15,
  },
  {
    tableName: "parent",
    columnName: "linkWhatsAppAccount",
    tableShowName: "Parent/Guardians List",
    columnShowName: "WhatsApp Line",
    isVisible: true,
    isRequired: false,
    weight: 16,
  },
];

export const students: DefaultUserCustomiseColumnSeed[] = [
  {
    tableName: "students",
    columnName: "student",
    tableShowName: "Student List",
    columnShowName: "Student",
    csvColumnName: "Name",
    isVisible: true,
    isRequired: true,
    weight: 0,
  },
  {
    tableName: "students",
    columnName: "alias",
    tableShowName: "Student List",
    columnShowName: "Preferred Name",
    csvColumnName: "Preferred Name",
    isVisible: false,
    isRequired: false,
    weight: 1,
  },
  {
    tableName: "students",
    columnName: "enrollmentType",
    tableShowName: "Student List",
    columnShowName: "Enrollment Type",
    csvColumnName: "Enrollment Type",
    isVisible: true,
    isRequired: false,
    weight: 2,
  },
  {
    tableName: "students",
    columnName: "scores",
    tableShowName: "Student List",
    columnShowName: "Scores",
    csvColumnName: "Scores",
    isVisible: true,
    isRequired: false,
    weight: 3,
  },
  {
    tableName: "students",
    columnName: "startDate",
    tableShowName: "Student List",
    columnShowName: "Trial Date/s",
    csvColumnName: "Trial Date/s",
    isVisible: true,
    isRequired: false,
    weight: 4,
  },
  {
    tableName: "students",
    columnName: "gender",
    tableShowName: "Student List",
    columnShowName: "Gender",
    csvColumnName: "Gender",
    isVisible: false,
    isRequired: false,
    weight: 5,
  },
  {
    tableName: "students",
    columnName: "userId",
    tableShowName: "Student List",
    columnShowName: "Student ID",
    csvColumnName: "Student ID",
    isVisible: false,
    isRequired: false,
    weight: 6,
  },
  {
    tableName: "students",
    columnName: "birthday",
    tableShowName: "Student List",
    columnShowName: "DOB",
    csvColumnName: "Date of Birth",
    isVisible: false,
    isRequired: false,
    weight: 7,
  },
  {
    tableName: "students",
    columnName: "academicLevel",
    tableShowName: "Student List",
    columnShowName: "Academic Level",
    csvColumnName: "Current Academic Level",
    isVisible: false,
    isRequired: false,
    weight: 8,
  },
  {
    tableName: "students",
    columnName: "primarySchool",
    tableShowName: "Student List",
    columnShowName: "Primary School",
    csvColumnName: "Primary School",
    isVisible: false,
    isRequired: false,
    weight: 9,
  },
  {
    tableName: "students",
    columnName: "secondarySchool",
    tableShowName: "Student List",
    columnShowName: "Secondary School",
    csvColumnName: "Secondary School",
    isVisible: false,
    isRequired: false,
    weight: 10,
  },
  {
    tableName: "students",
    columnName: "address",
    tableShowName: "Student List",
    columnShowName: "Address",
    csvColumnName: "Address",
    isVisible: false,
    isRequired: false,
    weight: 11,
  },
  {
    tableName: "students",
    columnName: "building",
    tableShowName: "Student List",
    columnShowName: "Building/Estate",
    csvColumnName: "Building/Estate",
    isVisible: false,
    isRequired: false,
    weight: 12,
  },
  {
    tableName: "students",
    columnName: "streetAddress",
    tableShowName: "Student List",
    columnShowName: "Street Address",
    csvColumnName: "Street Address",
    isVisible: false,
    isRequired: false,
    weight: 13,
  },
  {
    tableName: "students",
    columnName: "buildingUnitNumber",
    tableShowName: "Student List",
    columnShowName: "Unit No.",
    csvColumnName: "Unit No",
    isVisible: false,
    isRequired: false,
    weight: 14,
  },
  {
    tableName: "students",
    columnName: "postal",
    tableShowName: "Student List",
    columnShowName: "Postal Code",
    csvColumnName: "Postal Code",
    isVisible: false,
    isRequired: false,
    weight: 15,
  },
  {
    tableName: "students",
    columnName: "country",
    tableShowName: "Student List",
    columnShowName: "Country",
    csvColumnName: "Country",
    isVisible: false,
    isRequired: false,
    weight: 16,
  },
  {
    tableName: "students",
    columnName: "mobile",
    tableShowName: "Student List",
    columnShowName: "Mobile No.",
    csvColumnName: "Mobile No.",
    isVisible: false,
    isRequired: false,
    weight: 17,
  },
  {
    tableName: "students",
    columnName: "homePhone",
    tableShowName: "Student List",
    columnShowName: "Home No.",
    csvColumnName: "Home No.",
    isVisible: false,
    isRequired: false,
    weight: 18,
  },
  {
    tableName: "students",
    columnName: "email",
    tableShowName: "Student List",
    columnShowName: "Email",
    csvColumnName: "Email",
    isVisible: false,
    isRequired: false,
    weight: 19,
  },
  {
    tableName: "students",
    columnName: "alternativeEmails",
    tableShowName: "Student List",
    columnShowName: "Alternate Email",
    csvColumnName: "Alternate Email(s)",
    isVisible: false,
    isRequired: false,
    weight: 20,
  },
  {
    tableName: "students",
    columnName: "alternateUserId",
    tableShowName: "Student List",
    columnShowName: "Alternate ID",
    isVisible: false,
    isRequired: false,
    weight: 21,
  },
  {
    tableName: "students",
    columnName: "familyRelation",
    tableShowName: "Student List",
    columnShowName: "Family Relations",
    csvColumnName: "Relation(s)",
    isVisible: false,
    isRequired: false,
    weight: 22,
  },
  {
    tableName: "students",
    columnName: "primaryParent.Id",
    tableShowName: "Student List",
    columnShowName: "Primary Parent ID",
    csvColumnName: "Primary Parent ID",
    isVisible: false,
    isRequired: false,
    weight: 23,
  },
  {
    tableName: "students",
    columnName: "primaryParent",
    tableShowName: "Student List",
    columnShowName: "Primary Parent Name",
    csvColumnName: "Primary Parent Name",
    isVisible: true,
    isRequired: false,
    weight: 24,
  },
  {
    tableName: "students",
    columnName: "primaryParent.mobile",
    tableShowName: "Student List",
    columnShowName: "Primary Parent Mobile",
    csvColumnName: "Primary Parent Mobile",
    isVisible: true,
    isRequired: false,
    weight: 25,
  },
  {
    tableName: "students",
    columnName: "primaryParent.address",
    tableShowName: "Student List",
    columnShowName: "Primary Parent Address",
    csvColumnName: "Primary Parent Email",
    isVisible: false,
    isRequired: false,
    weight: 26,
  },
  {
    tableName: "students",
    columnName: "primaryParent.email",
    tableShowName: "Student List",
    columnShowName: "Primary Parent Email",
    csvColumnName: "Primary Parent Email",
    isVisible: false,
    isRequired: false,
    weight: 27,
  },
  {
    tableName: "students",
    columnName: "parents",
    tableShowName: "Student List",
    columnShowName: "Secondary Parent ID",
    csvColumnName: "Secondary Parent ID",
    isVisible: false,
    isRequired: false,
    weight: 28,
  },
  {
    tableName: "students",
    columnName: "parents.email",
    tableShowName: "Student List",
    columnShowName: "Secondary Parent",
    csvColumnName: "Secondary Parent Email",
    isVisible: false,
    isRequired: false,
    weight: 29,
  },
  {
    tableName: "students",
    columnName: "parents.mobile",
    tableShowName: "Student List",
    columnShowName: "Secondary Parent Mobile",
    csvColumnName: "Secondary Parent Mobile",
    isVisible: true,
    isRequired: false,
    weight: 30,
  },
  {
    tableName: "students",
    columnName: "parents.address",
    tableShowName: "Student List",
    columnShowName: "Secondary Parent Address",
    csvColumnName: "Secondary Parent Address",
    isVisible: false,
    isRequired: false,
    weight: 31,
  },
  /**
   *  The columns below are present in Teacher / Classes / Students.
   * (`Group`, `GAP Start on`, `GAP Last Day`, `Preferred Learning Arrangement`)
   */
  {
    tableName: "students",
    columnName: "group",
    tableShowName: "Student List",
    columnShowName: "Group",
    isVisible: true,
    isRequired: false,
    weight: 34,
  },
  {
    tableName: "students",
    columnName: "gapStartDate",
    tableShowName: "Student List",
    columnShowName: "GAP Start on",
    isVisible: true,
    isRequired: false,
    weight: 35,
  },
  {
    tableName: "students",
    columnName: "gapEndDate",
    tableShowName: "Student List",
    columnShowName: "GAP Last Day",
    isVisible: true,
    isRequired: false,
    weight: 36,
  },
  {
    tableName: "students",
    columnName: "learningArrangement",
    tableShowName: "Student List",
    columnShowName: "Preferred Learning Arrangement",
    isVisible: true,
    isRequired: false,
    weight: 37,
  },
  {
    tableName: "students",
    columnName: "remarks",
    tableShowName: "Student List",
    columnShowName: "Teacher Remarks",
    csvColumnName: "Teacher Remarks",
    isVisible: true,
    isRequired: false,
    weight: 38,
  },
  {
    tableName: "students",
    columnName: "parents.name",
    tableShowName: "Student List",
    columnShowName: "Secondary Parent Name",
    isVisible: true,
    isRequired: false,
    weight: 39,
  },
  {
    tableName: "students",
    columnName: "primaryParent.whatAppLine",
    tableShowName: "Student List",
    columnShowName: "Primary Parent WhatsApp Line",
    isVisible: true,
    isRequired: false,
    weight: 40,
  },
  {
    tableName: "students",
    columnName: "secondaryParent.whatAppLine",
    tableShowName: "Student List",
    columnShowName: "Secondary Parent WhatsApp Line",
    isVisible: true,
    isRequired: false,
    weight: 41,
  },
  {
    tableName: "students",
    columnName: "alias",
    tableShowName: "Student List",
    columnShowName: "Preferred Name",
    isVisible: true,
    isRequired: false,
    weight: 41,
  },
  {
    tableName: "students",
    columnName: "secondaryParent.email",
    tableShowName: "Student List",
    columnShowName: "Secondary Parent Email",
    isVisible: true,
    isRequired: false,
    weight: 42,
  },
];

const admins: DefaultUserCustomiseColumnSeed[] = [
  {
    tableName: "admins",
    columnName: "name",
    tableShowName: "Admin List",
    columnShowName: "Administrator",
    isVisible: true,
    isRequired: true,
    weight: 0,
  },
  {
    tableName: "admins",
    columnName: "gender",
    tableShowName: "Admin List",
    columnShowName: "Gender",
    isVisible: false,
    isRequired: false,
    weight: 1,
  },
  {
    tableName: "admins",
    columnName: "initials",
    tableShowName: "Admin List",
    columnShowName: "Initialism",
    isVisible: true,
    isRequired: false,
    weight: 2,
  },
  // {
  //   tableName: "admins",
  //   columnName: "userId",
  //   tableShowName: "Admin List",
  //   columnShowName: "User ID",
  //   isVisible: true,
  //   isRequired: false,
  //   weight: 3,
  // },
  {
    tableName: "admins",
    columnName: "mobile",
    tableShowName: "Admin List",
    columnShowName: "Mobile No.",
    isVisible: true,
    isRequired: false,
    weight: 4,
  },
  {
    tableName: "admins",
    columnName: "email",
    tableShowName: "Admin List",
    columnShowName: "Email",
    isVisible: true,
    isRequired: false,
    weight: 5,
  },
  {
    tableName: "admins",
    columnName: "department",
    tableShowName: "Admin List",
    columnShowName: "Department",
    isVisible: true,
    isRequired: false,
    weight: 6,
  },
  {
    tableName: "admins",
    columnName: "salutation",
    tableShowName: "Admin List",
    columnShowName: "Designation",
    isVisible: true,
    isRequired: false,
    weight: 7,
  },
  {
    tableName: "admins",
    columnName: "branch",
    tableShowName: "Admin List",
    columnShowName: "Branch",
    isVisible: true,
    isRequired: false,
    weight: 8,
  },
];

const teachers: DefaultUserCustomiseColumnSeed[] = [
  {
    tableName: "teachers",
    columnName: "name",
    tableShowName: "Teacher List",
    columnShowName: "Teacher",
    isVisible: true,
    isRequired: true,
    weight: 0,
  },
  {
    tableName: "teachers",
    columnName: "gender",
    tableShowName: "Teacher List",
    columnShowName: "Gender",
    isVisible: true,
    isRequired: false,
    weight: 1,
  },
  {
    tableName: "teachers",
    columnName: "initials",
    tableShowName: "Teacher List",
    columnShowName: "Initialism",
    isVisible: true,
    isRequired: false,
    weight: 2,
  },
  // {
  //   tableName: "teachers",
  //   columnName: "userId",
  //   tableShowName: "Teacher List",
  //   columnShowName: "User ID",
  //   isVisible: true,
  //   isRequired: false,
  //   weight: 3,
  // },
  {
    tableName: "teachers",
    columnName: "mobile",
    tableShowName: "Teacher List",
    columnShowName: "Mobile No.",
    isVisible: true,
    isRequired: false,
    weight: 4,
  },

  {
    tableName: "teachers",
    columnName: "email",
    tableShowName: "Teacher List",
    columnShowName: "Email",
    isVisible: true,
    isRequired: false,
    weight: 5,
  },
  {
    tableName: "teachers",
    columnName: "department",
    tableShowName: "Teacher List",
    columnShowName: "Department",
    isVisible: true,
    isRequired: false,
    weight: 6,
  },
  {
    tableName: "teachers",
    columnName: "salution",
    tableShowName: "Teacher List",
    columnShowName: "Designation",
    isVisible: true,
    isRequired: false,
    weight: 7,
  },
  {
    tableName: "teachers",
    columnName: "branch",
    tableShowName: "Teacher List",
    columnShowName: "Branch",
    isVisible: true,
    isRequired: false,
    weight: 8,
  },
];

const whatsAppAccounts: DefaultUserCustomiseColumnSeed[] = [
  {
    tableName: "whatsAppAccounts",
    columnName: "name",
    tableShowName: "WhatsApp Accounts",
    columnShowName: "Account Name",
    isVisible: true,
    isRequired: false,
    weight: 0,
  },
  {
    tableName: "whatsAppAccounts",
    columnName: "number",
    tableShowName: "WhatsApp Accounts",
    columnShowName: "WA Number",
    isVisible: true,
    isRequired: false,
    weight: 0,
  },
  {
    tableName: "whatsAppAccounts",
    columnName: "status",
    tableShowName: "WhatsApp Accounts",
    columnShowName: "Line Status",
    isVisible: true,
    isRequired: false,
    weight: 1,
  },
  {
    tableName: "whatsAppAccounts",
    columnName: "processingStatus",
    tableShowName: "WhatsApp Accounts",
    columnShowName: "Queue Status",
    isVisible: true,
    isRequired: false,
    weight: 2,
  },
  {
    tableName: "whatsAppAccounts",
    columnName: "lastSentDateTime",
    tableShowName: "WhatsApp Accounts",
    columnShowName: "Last Message",
    isVisible: true,
    isRequired: false,
    weight: 3,
  },
  {
    tableName: "whatsAppAccounts",
    columnName: "middleLarge",
    tableShowName: "WhatsApp Accounts",
    columnShowName: "Connectivity",
    isVisible: true,
    isRequired: false,
    weight: 4,
  },
];

const invoiceReport: DefaultUserCustomiseColumnSeed[] = [
  {
    tableName: "invoiceReport",
    columnName: "referenceNumber",
    tableShowName: "Invoice Report",
    columnShowName: "Invoice No.",
    isVisible: true,
    isRequired: false,
    weight: 0,
  },
  {
    tableName: "invoiceReport",
    columnName: "invoiceDate",
    tableShowName: "Invoice Report",
    columnShowName: "Invoice Date",
    isVisible: true,
    isRequired: false,
    weight: 0,
  },
  {
    tableName: "invoiceReport",
    columnName: "studentUserId",
    tableShowName: "Invoice Report",
    columnShowName: "Student ID",
    isVisible: true,
    isRequired: false,
    weight: 0,
  },
  {
    tableName: "invoiceReport",
    columnName: "studentFullName",
    tableShowName: "Invoice Report",
    columnShowName: "Student Name",
    isVisible: true,
    isRequired: false,
    weight: 0,
  },
  {
    tableName: "invoiceReport",
    columnName: "description",
    tableShowName: "Invoice Report",
    columnShowName: "Class Name",
    isVisible: true,
    isRequired: false,
    weight: 0,
  },
  {
    tableName: "invoiceReport",
    columnName: "fullLessonDate",
    tableShowName: "Invoice Report",
    columnShowName: "Full Lesson Dates",
    isVisible: true,
    isRequired: false,
    weight: 0,
  },
  {
    tableName: "invoiceReport",
    columnName: "lessonDate",
    tableShowName: "Invoice Report",
    columnShowName: "Lesson Date",
    isVisible: true,
    isRequired: false,
    weight: 0,
  },
  {
    tableName: "invoiceReport",
    columnName: "invoiceTotal",
    tableShowName: "Invoice Report",
    columnShowName: "Total Invoice With GST",
    isVisible: true,
    isRequired: false,
    weight: 0,
  },
  {
    tableName: "invoiceReport",
    columnName: "lineItemFee",
    tableShowName: "Invoice Report",
    columnShowName: "Total Item Fee With GST",
    isVisible: true,
    isRequired: false,
    weight: 0,
  },
  {
    tableName: "invoiceReport",
    columnName: "lineItemFeeWithoutGST",
    tableShowName: "Invoice Report",
    columnShowName: "Total Item Fee Without GST",
    isVisible: true,
    isRequired: false,
    weight: 0,
  },
  {
    tableName: "invoiceReport",
    columnName: "feeWithoutGST",
    tableShowName: "Invoice Report",
    columnShowName: "Item Fee Without GST",
    isVisible: true,
    isRequired: false,
    weight: 0,
  },
  {
    tableName: "invoiceReport",
    columnName: "remarks",
    tableShowName: "Invoice Report",
    columnShowName: "System Remarks",
    isVisible: true,
    isRequired: false,
    weight: 0,
  },
];

const studentsOfCourse: DefaultUserCustomiseColumnSeed[] = [
  {
    tableName: "studentsOfCourse",
    columnName: "student",
    tableShowName: "Students",
    columnShowName: "Student",
    isVisible: true,
    isRequired: false,
    weight: 0,
  },
  {
    tableName: "studentsOfCourse",
    columnName: "studentId",
    tableShowName: "Students",
    columnShowName: "Student ID",
    isVisible: true,
    isRequired: false,
    weight: 1,
  },
  {
    tableName: "studentsOfCourse",
    columnName: "class",
    tableShowName: "Students",
    columnShowName: "Class",
    isVisible: true,
    isRequired: false,
    weight: 2,
  },
  {
    tableName: "studentsOfCourse",
    columnName: "classCode",
    tableShowName: "Students",
    columnShowName: "Class Code",
    isVisible: true,
    isRequired: false,
    weight: 3,
  },
  {
    tableName: "studentsOfCourse",
    columnName: "classShorthand",
    tableShowName: "Students",
    columnShowName: "Class Shorthand",
    isVisible: true,
    isRequired: false,
    weight: 4,
  },
  {
    tableName: "studentsOfCourse",
    columnName: "lessonDay",
    tableShowName: "Students",
    columnShowName: "Lesson Day",
    isVisible: true,
    isRequired: false,
    weight: 5,
  },
  {
    tableName: "studentsOfCourse",
    columnName: "teacher",
    tableShowName: "Students",
    columnShowName: "Teacher",
    isVisible: true,
    isRequired: false,
    weight: 6,
  },
  {
    tableName: "studentsOfCourse",
    columnName: "venue",
    tableShowName: "Students",
    columnShowName: "Venue",
    isVisible: true,
    isRequired: false,
    weight: 7,
  },
  {
    tableName: "studentsOfCourse",
    columnName: "gapStartOn",
    tableShowName: "Students",
    columnShowName: "GAP Start On",
    isVisible: true,
    isRequired: false,
    weight: 8,
  },
  {
    tableName: "studentsOfCourse",
    columnName: "gapLastDay",
    tableShowName: "Students",
    columnShowName: "GAP Last Day",
    isVisible: true,
    isRequired: false,
    weight: 9,
  },
  {
    tableName: "studentsOfCourse",
    columnName: "trialDates",
    tableShowName: "Students",
    columnShowName: "Trial Date/s",
    isVisible: true,
    isRequired: false,
    weight: 10,
  },
  {
    tableName: "studentsOfCourse",
    columnName: "lastModified",
    tableShowName: "Students",
    columnShowName: "Last Modified",
    isVisible: true,
    isRequired: false,
    weight: 11,
  },
  {
    tableName: "studentsOfCourse",
    columnName: "lastModifiedBy",
    tableShowName: "Students",
    columnShowName: "Last Modified By",
    isVisible: true,
    isRequired: false,
    weight: 12,
  },
];

const paymentReport: DefaultUserCustomiseColumnSeed[] = [
  {
    tableName: "paymentReport",
    columnName: "invoiceNumbers",
    tableShowName: "Payment Report",
    columnShowName: "Invoice No.",
    isVisible: true,
    isRequired: false,
    weight: 0,
  },
  {
    tableName: "paymentReport",
    columnName: "paymentNumbers",
    tableShowName: "Payment Report",
    columnShowName: "Payment No.",
    isVisible: true,
    isRequired: false,
    weight: 1,
  },
  {
    tableName: "paymentReport",
    columnName: "receiptNumbers",
    tableShowName: "Payment Report",
    columnShowName: "Receipt No.",
    isVisible: true,
    isRequired: false,
    weight: 2,
  },
  {
    tableName: "paymentReport",
    columnName: "originalInvoiceAmount",
    tableShowName: "Payment Report",
    columnShowName: "Original Invoice Amount",
    isVisible: true,
    isRequired: false,
    weight: 3,
  },
  {
    tableName: "paymentReport",
    columnName: "amountPayable",
    tableShowName: "Payment Report",
    columnShowName: "Amount Payable",
    isVisible: true,
    isRequired: false,
    weight: 4,
  },
  {
    tableName: "paymentReport",
    columnName: "paymentMethod",
    tableShowName: "Payment Report",
    columnShowName: "Payment Method",
    isVisible: true,
    isRequired: false,
    weight: 5,
  },
  {
    tableName: "paymentReport",
    columnName: "paymentDate",
    tableShowName: "Payment Report",
    columnShowName: "Payment Date",
    isVisible: true,
    isRequired: false,
    weight: 6,
  },
  {
    tableName: "paymentReport",
    columnName: "amountPaid",
    tableShowName: "Payment Report",
    columnShowName: "Amount Paid",
    isVisible: true,
    isRequired: false,
    weight: 7,
  },
  {
    tableName: "paymentReport",
    columnName: "outstandingPayable",
    tableShowName: "Payment Report",
    columnShowName: "Outstanding Payable",
    isVisible: true,
    isRequired: false,
    weight: 8,
  },
  {
    tableName: "paymentReport",
    columnName: "actionType",
    tableShowName: "Payment Report",
    columnShowName: "Balance Action",
    isVisible: true,
    isRequired: false,
    weight: 9,
  },
  {
    tableName: "paymentReport",
    columnName: "amount",
    tableShowName: "Payment Report",
    columnShowName: "Balance",
    isVisible: true,
    isRequired: false,
    weight: 10,
  },
  {
    tableName: "paymentReport",
    columnName: "balance",
    tableShowName: "Payment Report",
    columnShowName: "Remaining Balance",
    isVisible: true,
    isRequired: false,
    weight: 11,
  },
  {
    tableName: "paymentReport",
    columnName: "type",
    tableShowName: "Payment Report",
    columnShowName: "System Remarks",
    isVisible: true,
    isRequired: false,
    weight: 12,
  },
];

export const userRequestColumn: DefaultUserCustomiseColumnSeed[] = [
  {
    tableName: "userRequest",
    columnName: "number",
    tableShowName: "userRequest List",
    columnShowName: "Request No.",
    isVisible: true,
    isRequired: true,
    weight: 0,
  },
  {
    tableName: "userRequest",
    columnName: "parent.fullName",
    tableShowName: "userRequest List",
    columnShowName: "Parent Name",
    isVisible: true,
    isRequired: false,
    weight: 1,
  },
  {
    tableName: "userRequest",
    columnName: "parent.mobile",
    tableShowName: "userRequest List",
    columnShowName: "Parent Mobile No.",
    isVisible: true,
    isRequired: false,
    weight: 2,
  },
  {
    tableName: "userRequest",
    columnName: "parent.email",
    tableShowName: "userRequest List",
    columnShowName: "Parent Email",
    isVisible: true,
    isRequired: false,
    weight: 4,
  },
  {
    tableName: "userRequest",
    columnName: "student.fullName",
    tableShowName: "userRequest List",
    columnShowName: "Student Name",
    isVisible: true,
    isRequired: false,
    weight: 5,
  },
  {
    tableName: "userRequest",
    columnName: "class",
    tableShowName: "userRequest List",
    columnShowName: "Course & Class",
    isVisible: true,
    isRequired: false,
    weight: 6,
  },
  {
    tableName: "userRequest",
    columnName: "startDate",
    tableShowName: "userRequest List",
    columnShowName: "Date Submitted",
    isVisible: true,
    isRequired: false,
    weight: 7,
  },
  {
    tableName: "userRequest",
    columnName: "status",
    tableShowName: "userRequest List",
    columnShowName: "Status",
    isVisible: true,
    isRequired: false,
    weight: 8,
  },
  {
    tableName: "userRequest",
    columnName: "source",
    tableShowName: "userRequest List",
    columnShowName: "Source",
    isVisible: true,
    isRequired: false,
    weight: 9,
  },
  {
    tableName: "userRequest",
    columnName: "type",
    tableShowName: "userRequest List",
    columnShowName: "Arrangement Type",
    isVisible: true,
    isRequired: false,
    weight: 10,
  },
  {
    tableName: "userRequest",
    columnName: "processedUser.fullName",
    tableShowName: "userRequest List",
    columnShowName: "Processed By",
    isVisible: true,
    isRequired: false,
    weight: 11,
  },
];

export const DefaultUserCustomiseColumns: DefaultUserCustomiseColumnSeed[] = [
  ...courses,
  ...classes,
  ...lessons,
  ...registrations,
  ...arrangementMakeups,
  ...arrangementAdditionals,
  ...arrangementTransfers,
  ...arrangementWithdraws,
  ...invoices,
  ...receipts,
  ...refunds,
  ...statementOfAccounts,
  ...parent,
  ...students,
  ...admins,
  ...teachers,
  ...whatsAppAccounts,
  ...invoiceReport,
  ...studentsOfCourse,
  ...paymentReport,
  ...userRequestColumn,
];
