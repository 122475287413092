import { ReactComponent as IconSend } from "@/assets/images/IconSend.svg";
import AutoHeightContainer from "@/components/common/Container/AutoHeightContainer";
import DataPageHeader from "@/components/common/DataPage/DataPageHeader";
import { useSendTaskReminder } from "@/components/widget/Reminder/hooks/useReminder.hook";
import { TaskType } from "@/graphql";
import formatLabel from "@/utils/formatLabel";
import { Button } from "@thepiquelab/archus-components-web";
import { useHistory, useLocation } from "react-router-dom";
import { formatSendLabel } from "../Task/TaskTable";
import CompleteTaskModalButton from "./CompleteTaskModalButton";

const SendTaskReminder: React.FC = () => {
  const history = useHistory();
  const {
    state: {
      registrationIds,
      taskIds,
      attendanceIds,
      invoiceIds,
      enrollmentIds,
      taskType,
    },
  } = useLocation<{
    registrationIds: string[];
    taskIds: string[];
    attendanceIds: string[];
    invoiceIds: string[];
    enrollmentIds: string[];
    taskType: TaskType;
  }>();
  const { reminder, loading, submit } = useSendTaskReminder({
    callback: () => history.push("/dashboard", { taskType }),
    registrationIds,
    taskIds,
    attendanceIds,
    invoiceIds,
    enrollmentIds,
    taskType,
  });

  const actions = (
    <div className="flex justify-end">
      <Button
        className="mr-2"
        onClick={() => history.push("/dashboard", { taskType })}
      >
        {formatLabel("Back")}
      </Button>
      <CompleteTaskModalButton taskType={taskType} />
      <Button
        loading={loading}
        type="primary"
        className="inline-flex items-center justify-center"
        onClick={() => submit()}
      >
        <IconSend className="mr-2" />
        Send
      </Button>
    </div>
  );

  return (
    <AutoHeightContainer>
      <AutoHeightContainer.Header>
        <DataPageHeader
          title={formatLabel(formatSendLabel(taskType))}
          rightBar={actions}
        />
      </AutoHeightContainer.Header>
      <AutoHeightContainer.Content className="h-full overflow-auto">
        {reminder}
      </AutoHeightContainer.Content>
    </AutoHeightContainer>
  );
};

export default SendTaskReminder;
