import {
  AuthorizedRouteProps,
  EnumCheckPermissionType,
} from "@/components/authorize/AuthorizedRoute";
import { useAccessControl } from "@/hooks/useAccessControl";

const useRedirectPath = (routes: AuthorizedRouteProps[]): string => {
  const { hasAllPermissions, hasSomePermissions } = useAccessControl();

  const checkPermissionMethodMap = {
    [EnumCheckPermissionType.ALL]: hasAllPermissions,
    [EnumCheckPermissionType.SOME]: hasSomePermissions,
  };
  return routes
    .filter((route) => !route.disableRedirectTo)
    .filter((route) => !route.hide)
    .filter((route) =>
      route.permissions
        ? (
            checkPermissionMethodMap[route.permissionCheckType] ||
            hasAllPermissions
          )(route.permissions)
        : true
    )?.[0]?.path as string;
};

export const useGetPaths = (
  routes: AuthorizedRouteProps[]
): AuthorizedRouteProps[] => {
  const { hasAllPermissions, hasSomePermissions } = useAccessControl();

  const checkPermissionMethodMap = {
    [EnumCheckPermissionType.ALL]: hasAllPermissions,
    [EnumCheckPermissionType.SOME]: hasSomePermissions,
  };
  return routes
    .filter((route) => !route.hide)
    .filter((route) =>
      route.permissions
        ? (
            checkPermissionMethodMap[route.permissionCheckType] ||
            hasAllPermissions
          )(route.permissions)
        : true
    );
};

export default useRedirectPath;
