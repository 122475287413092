import { ReactComponent as IconArrowRightAlt } from "@/assets/images/IconArrowRightAlt.svg";
import { setProcessSessionInfo } from "@/components/registration/RegistrationDetail/common/SMS/utils";
import { useQueryAcademicYearOptions } from "@/components/settings/SettingHooks";
import { StudentCell } from "@/components/widget/UserCell/StudentCell";

import { onOpenConfirmModal } from "@/components/common/ConfirmModal/NewConfirmModal";
import ModalButtonGroup from "@/components/common/ModalButtonGroup";
import {
  CourseType,
  CreateCourseNotificationInput,
  GetAcademicYearsOptionsQuery,
  GetMaterialStudents,
  GetMaterialStudentsQuery,
  GetMaterialStudentsQueryVariables,
  SortDirection,
  Student,
} from "@/graphql";
import { useLoadMore } from "@/hooks/useLoadMore";
import formatErrorMessage from "@/utils/formatErrorMessage";
import formatLabel from "@/utils/formatLabel";
import {
  Form,
  Modal,
  Row,
  Select,
  Spin,
  Typography,
  message,
} from "@thepiquelab/archus-components-web";
import { debounce, throttle } from "lodash";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  useCreateCourseNotification,
  useQueryStudentWithEnrollments,
} from "../hooks";

interface OperationalTaskListModalProps {
  visible?: boolean;
  onCancel?: () => void;
  refetch?: () => void;
  courseData?: {
    id: string;
    type?: CourseType;
  };
}
const getCurrentYearId = (
  academicYearsData: GetAcademicYearsOptionsQuery
): string => {
  const currentYear = academicYearsData?.academicYears?.find(
    (year) => year.year === moment().year()
  );
  if (currentYear?.id) {
    return currentYear?.id;
  }
  return undefined;
};
const CourseNotificationListModal: FC<OperationalTaskListModalProps> = (
  props
) => {
  const { visible, courseData, onCancel, refetch } = props;
  const [form] = Form.useForm();
  const [
    queryStudents,
    { data: studentsData, loading: studentsLoading },
    { loadMore, hasMore, loadMoreLoading },
  ] = useLoadMore<GetMaterialStudentsQuery, GetMaterialStudentsQueryVariables>(
    GetMaterialStudents,
    "students",
    true,
    false,
    true,
    {
      fetchPolicy: "network-only",
    }
  );

  const { refetch: refetchStudentWithEnrollments } =
    useQueryStudentWithEnrollments();

  const [studentWithEnrollmentsLoading, setStudentWithEnrollmentsLoading] =
    useState(false);

  const { data: academicYearsData } = useQueryAcademicYearOptions();

  const [createCourseNotification, { loading }] = useCreateCourseNotification();

  useEffect(() => {
    queryStudents({
      variables: {
        sortInfo: {
          direction: SortDirection.Asc,
          field: "fullName",
        },
      },
    });
  }, []);

  const history = useHistory();

  const handleClose = (): void => {
    form?.resetFields();
    onCancel?.();
  };

  const handleSearch = (value: string): void => {
    queryStudents({
      variables: {
        filterInput: { name: value },
        sortInfo: {
          direction: SortDirection.Asc,
          field: "fullName",
        },
      },
    });
  };
  const handleScroll = (event: any): void => {
    const isScrollBottom =
      event.target.scrollTop + event.target.offsetHeight >
      event.target.scrollHeight - 10;

    if (isScrollBottom && !loadMoreLoading && hasMore) {
      loadMore();
    }
  };

  const handleCreateCourseNotification = async (
    value: CreateCourseNotificationInput
  ): Promise<void> => {
    try {
      await createCourseNotification({
        variables: { input: { ...value, courseId: courseData?.id } },
      });
      refetch?.();
      handleClose();
    } catch (error) {
      message.error(formatErrorMessage(error));
    }
  };

  const handleFinish = async (
    value: CreateCourseNotificationInput
  ): Promise<void> => {
    const isWorkshop = courseData?.type === CourseType.Workshop;

    if (isWorkshop) {
      setStudentWithEnrollmentsLoading(true);
      try {
        const result = await refetchStudentWithEnrollments({
          id: value?.studentId,
          filterInput: { courseIds: [courseData?.id] },
        });
        setStudentWithEnrollmentsLoading(false);
        if (result?.data?.student?.enrollments?.length) {
          onOpenConfirmModal({
            contentDesc:
              "Are you sure you want to proceed? The selected student already has or had enrollment in this course.",
            onConfirm: async () => {
              handleCreateCourseNotification(value);
            },
          });
          return;
        }
        handleCreateCourseNotification(value);
      } catch (error) {
        setStudentWithEnrollmentsLoading(false);
        message.error(formatErrorMessage(error));
      }
      return;
    }
    handleCreateCourseNotification(value);
  };

  return (
    <Modal
      visible={visible}
      onClose={handleClose}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      destroyOnClose
    >
      <Modal.Header title={"Add Student to Course"} />
      <Modal.Content>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleFinish}
          validateMessages={{ required: "Field is required." }}
        >
          <Form.Item
            name="studentId"
            label={
              <Typography.Text className="font_semibold">
                {formatLabel("Student")}
              </Typography.Text>
            }
            className="no-form-item-label-span-24-padding-bottom"
            rules={[{ required: true }]}
          >
            <Select
              allowClear
              loading={studentsLoading}
              filterOption={false}
              showSearch
              dropdownRender={(menu) => (
                <>
                  {menu}{" "}
                  {loadMoreLoading ? (
                    <Row justify={"center"} key={0}>
                      <Spin />
                    </Row>
                  ) : null}
                </>
              )}
              notFoundContent={
                studentsLoading ? <Spin className="w-full" /> : undefined
              }
              onSearch={debounce((value) => handleSearch(value), 300)}
              onPopupScroll={throttle(handleScroll, 300)}
              optionLabelProp="label"
              onClear={() => {
                handleSearch("");
              }}
            >
              {studentsData?.students?.items?.map((i) => (
                <Select.Option
                  key={i?.id}
                  label={i.fullName}
                  value={i?.id}
                  className="py-2"
                >
                  <StudentCell
                    profile={i as unknown as Student}
                    allowJump={false}
                  />
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name={"academicYearId"}
            label="Year"
            className="no-margin"
            rules={[{ required: true }]}
            initialValue={getCurrentYearId(academicYearsData)}
          >
            <Select
              options={academicYearsData?.academicYears?.map((i) => ({
                label: i?.year,
                value: i?.id,
                disabled: i?.year < moment().year(),
              }))}
            />
          </Form.Item>
        </Form>
      </Modal.Content>
      <Modal.Footer>
        <ModalButtonGroup
          okText="Add"
          cancelText="Add New Student"
          onOK={form.submit}
          cancelButtonProps={{
            iconAlign: "right",
            icon: <IconArrowRightAlt className="ml-2" />,
          }}
          onCancel={() => {
            setProcessSessionInfo([
              {
                process: "student",
                source: "add_student",
                sourceDetails: {
                  isDelete: "false",
                  url: "/dashboard/waiting-list-overview",
                  tabKey: "CourseNotificationList",
                },
              },
            ]);
            history.push(`/customers/students/create`);
          }}
          confirmLoading={loading || studentWithEnrollmentsLoading}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default CourseNotificationListModal;
