import { Space, Typography } from "@thepiquelab/archus-components-web";
import { Modal } from "antd";
import React, { useState } from "react";
import formatLabel from "../../../utils/formatLabel";
import ModalButtonGroup from "../ModalButtonGroup";

type CustomModalArgsTypes = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
};
export interface ConfirmModalProps {
  visible: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
  onCancel?: () => void;
  setCustomModal?: (modalProps: CustomModalArgsTypes) => React.ReactNode;

  title?: string;
  width?: number;
  confirmText?: string;
  cancelText?: string;
  children?: React.ReactNode;
  contentTitle?: string;
  contentDesc?: React.ReactNode;
  loading?: boolean;
  forceRender?: boolean;

  noFooter?: boolean;

  /**
   * A boolean to determine wether the header of the confirmModal should be
   * displayed.
   */
  noHeader?: boolean;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  visible,
  onConfirm,
  onClose,
  onCancel,
  title,
  confirmText,
  cancelText,
  children,
  contentTitle,
  contentDesc = "All progress for this registration session will be lost.",
  noFooter,
  loading,
  noHeader,
  width,
  forceRender,
}) => {
  const defaultContent = (
    <Space
      direction="vertical"
      data-testid="ConfirmModalContent"
      className="w-full"
    >
      {contentTitle && (
        <Typography.Heading level={3} data-testid="ConfirmModalContent">
          {formatLabel(contentTitle)}
        </Typography.Heading>
      )}
      {/**
       * Did not apply the same logic above as it would change the spacing.
       */}
      <Typography.Text
        style={{ color: contentTitle?.trim().length ? "#94959A" : "#4F537B" }}
      >
        {contentDesc}
      </Typography.Text>
    </Space>
  );

  return (
    <Modal
      forceRender={forceRender}
      width={width ?? 600}
      title={
        !noHeader && (
          <Typography.Heading level={3}>
            {formatLabel(title || "Are you sure?")}
          </Typography.Heading>
        )
      }
      visible={visible}
      onCancel={() => onClose()}
      footer={
        !noFooter && (
          <ModalButtonGroup
            onCancel={onCancel}
            cancelText={cancelText || "Cancel"}
            onOK={onConfirm}
            okText={confirmText || "Confirm"}
            confirmLoading={loading}
          />
        )
      }
    >
      <div style={{ color: "#94959A" }}>{children || defaultContent}</div>
    </Modal>
  );
};

/**
 * @return {
 *   modal: ReactNode, // - this must place in you view
 *   setModalProps(props: Omit<ConfirmModalProps, "visible" | "onClose">):void,
 *   show():void,
 *   close():void
 * }
 */
export const useConfirmModal = (
  modalProps: Omit<ConfirmModalProps, "visible"> = {}
): {
  modal: any;
  setModalProps: (
    newProps: Omit<ConfirmModalProps, "visible" | "onClose">
  ) => void;
  close: () => void;
  show: () => void;
  visible: boolean;
} => {
  const [visible, setVisible] = useState(false);

  const close = (): void => setVisible(false);
  const show = (): void => setVisible(true);

  const [props, setProps] = useState(modalProps);

  const setModalProps = (
    newProps: Omit<ConfirmModalProps, "visible" | "onClose">
  ): void => {
    setProps({ ...props, ...newProps });
  };

  return {
    modal: props.setCustomModal ? (
      props.setCustomModal({
        visible,
        setVisible,
      })
    ) : (
      <ConfirmModal
        visible={visible}
        onClose={modalProps?.onClose || close}
        {...props}
      />
    ),

    setModalProps,
    close,
    show,
    visible,
  };
};

export default ConfirmModal;
